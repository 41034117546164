import {
  DocumentDriver,
  DocumentDriverFile,
  DriverInfo,
  ICreateDriver,
} from "../../types/Shipment";
import { IShipmentDocument } from "../../types/ShipmentDocument";

const createShipmentDriver = (values: DriverInfo): DriverInfo => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.phone,
    email: values.email ?? undefined,
  };
};

export const generateAssignDriver = (
  shipmentId: string,
  values: DriverInfo,
): ICreateDriver => {
  const iShipmentDriver = createShipmentDriver(values);

  return {
    iShipmentDriver,
    shipmentId,
  };
};

export const initialFileData: DocumentDriver = {
  hazmat: "",
  overdimensional: "",
  other: "",
};

export const initialIdDocs: DocumentDriver = {
  hazmat: "",
  overdimensional: "",
  other: "",
};

export const findFileByType = (
  docs: IShipmentDocument[],
  type: string,
): IShipmentDocument | undefined => {
  return docs.find((x) => x.documentType.includes(type));
};

export const getFileName = (documentName: string): string => {
  return documentName.split("/").slice(-1)[0];
};

export const initializeDocuments = (
  docs: IShipmentDocument[],
): DocumentDriverFile => {
  const hazmatFile = findFileByType(docs, "Hazmat");
  const overDimensionalFile = findFileByType(docs, "Overdimensional");
  const otherFile = findFileByType(docs, "Other");

  const fileData = {
    hazmat: hazmatFile ? getFileName(hazmatFile.documentName) : "",
    overdimensional: overDimensionalFile
      ? getFileName(overDimensionalFile.documentName)
      : "",
    other: otherFile ? getFileName(otherFile.documentName) : "",
  };

  const idDocs = {
    hazmat: hazmatFile?.id ?? "",
    overdimensional: overDimensionalFile?.id ?? "",
    other: otherFile?.id ?? "",
  };

  return {
    fileData,
    idDocs,
  };
};

export const getUpdateFileId = (
  event: React.ChangeEvent<HTMLInputElement>,
  idDocs: DocumentDriver,
): string => {
  const name = event.target.name;
  if (name === "hazmat") {
    return idDocs.hazmat;
  }
  if (name === "overdimensional") {
    return idDocs.overdimensional;
  }
  return idDocs.other;
};
