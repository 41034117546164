import { createContext, useContext } from "react";

interface Shipment {
  selectedShipment: unknown | undefined;
  setSelectedShipment: (shipment?: unknown) => void;
  shipmentToCopy: unknown | undefined;
  setShipmentToCopy: (shipment?: unknown) => void;
}

const ShipmentContext = createContext<Shipment | null>(null);

const useShipmentContext = (): Shipment | null => useContext(ShipmentContext);

export { ShipmentContext, useShipmentContext };
