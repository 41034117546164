import { VStack, Button } from "@chakra-ui/react";
import { useActiveRfpContext } from "../../pages/ActiveRfp/ActiveRfpContext";
import {
  createCsvFromObjectsArray,
  createWaterfallDataToExport,
  exportFileResults,
} from "../../reuse/Files";
import { RfpButtonsPosition } from "../../types/Rfp";
import AbsoluteRightButtons from "../AbsoluteRightButtons";
import { mapShipperLanesToRow } from "../LanesProposalDetail/utils";
import WaterfullTable from "./WaterfallTable";

export const WaterfallProposalDetail = (): JSX.Element => {
  const rfpContext = useActiveRfpContext();
  const { largerThan1620, selectedRfp } = rfpContext!;

  const downloadWaterfull = (): void => {
    const mappedExportData = createWaterfallDataToExport(
      mapShipperLanesToRow(selectedRfp!.lanes!),
    );
    const csvFileUrl = createCsvFromObjectsArray(mappedExportData);
    exportFileResults(csvFileUrl, "waterfall.csv");
  };

  return (
    <VStack h="100%" w="100%">
      <AbsoluteRightButtons
        top={
          largerThan1620
            ? RfpButtonsPosition.Primary
            : RfpButtonsPosition.Secondary
        }
        left={
          largerThan1620
            ? RfpButtonsPosition.Infimum
            : RfpButtonsPosition.Primary
        }
        right={
          largerThan1620
            ? RfpButtonsPosition.Primary
            : RfpButtonsPosition.Infimum
        }
      >
        <Button
          layerStyle="red"
          onClick={downloadWaterfull}
          isDisabled={selectedRfp?.lanes!.length === 0}
        >
          DOWNLOAD WATERFALL
        </Button>
      </AbsoluteRightButtons>
      <WaterfullTable />
    </VStack>
  );
};

export default WaterfallProposalDetail;
