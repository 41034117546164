import { createApi } from "@reduxjs/toolkit/query/react";
import { MarketPlaceSearch } from "../types/MarketPlace";
import {
  ShipmentCreate,
  Shipment,
  ShipmentStatus,
  ShipmentUpdate,
  TenderInfo,
  ShipmentCancelReason,
  DriverInfo,
} from "../types/Shipment";
import {
  ShipmentCreateBulkResult,
  ShipmentRowToAdd,
} from "../types/ShipmentUpload";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";

export interface ShipmentStatusBody {
  status: ShipmentStatus;
  id: string;
}

interface ShipmentInfoRequest {
  shipmentId: string;
  message: string;
}

export const shipmentApi = createApi({
  reducerPath: "shipmentApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}/shipments`),
  tagTypes: [
    "Shipment",
    "MarketPlace",
    "QuoteRequests",
    "Tracking",
    "PendingCancel",
  ],
  endpoints: (builder) => ({
    getShipmentsByShipperId: builder.query<Shipment[], void>({
      query: () => "",
      providesTags: ["Shipment"],
    }),
    getTrackingShipmentsById: builder.query<Shipment[], void>({
      query: (body) => ({
        url: "/tracking",
        method: "GET",
        body,
      }),
      providesTags: ["Tracking"],
    }),
    getPendingCancellationShipmentsById: builder.query<Shipment[], void>({
      query: () => ({
        url: "/pending-cancel",
        method: "GET",
      }),
      providesTags: ["PendingCancel"],
    }),
    getPastShipments: builder.query<Shipment[], void>({
      query: (body) => ({
        url: "/past",
        method: "GET",
        body,
      }),
      providesTags: ["Tracking"],
    }),
    getShipmentDriver: builder.query<
      { driver: DriverInfo | null },
      { shipmentId: string }
    >({
      query: (body) => ({
        url: `/${body.shipmentId}/driver`,
        method: "GET",
      }),
    }),
    requestQuote: builder.mutation<Shipment, ShipmentCreate>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Shipment"],
    }),
    updateShipmentDetails: builder.mutation<Shipment, ShipmentUpdate>({
      query: (shipmentUpdate) => {
        const { id, ...body } = shipmentUpdate;
        return {
          url: `/details/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Shipment", "Tracking"],
    }),
    tender: builder.mutation<void, TenderInfo>({
      query: (body) => {
        return {
          url: "/tender",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Shipment"],
    }),
    getShipments: builder.query<Shipment[], MarketPlaceSearch>({
      query: (params) => {
        let url = "/quote?";
        Object.entries(params).forEach((searchOption) => {
          const [k, v] = searchOption;
          if (v) {
            if (v instanceof Array) {
              v.forEach((filter: string) => {
                url += `${k}=${encodeURIComponent(filter)}&`;
              });
            } else if (v instanceof Date) {
              url += `${k}=${encodeURIComponent(v.toISOString())}&`;
            } else {
              url += `${k}=${encodeURIComponent(v)}&`;
            }
          }
        });
        return url.substring(0, url.length - 1);
      },
      providesTags: ["MarketPlace"],
    }),
    updateShipmentStatus: builder.mutation<Shipment, ShipmentStatusBody>({
      query: (body) => ({
        url: `/status/${body.id}`,
        method: "PUT",
        body: { status: body.status },
      }),
      invalidatesTags: ["Shipment", "Tracking"],
    }),
    cancelShipment: builder.mutation<
      void,
      { shipmentId: string; reason?: ShipmentCancelReason }
    >({
      query: (body) => ({
        url: `/${body.shipmentId}/cancel`,
        method: "PUT",
        body: { reason: body.reason },
      }),
      invalidatesTags: ["Shipment", "Tracking", "PendingCancel"],
    }),
    confirmCancelShipment: builder.mutation<void, { shipmentId: string }>({
      query: (body) => ({
        url: `/${body.shipmentId}/confirm-cancel`,
        method: "PUT",
      }),
      invalidatesTags: ["Shipment", "Tracking", "PendingCancel"],
    }),
    getQuoteRequests: builder.query<Shipment[], void>({
      query: () => "/quote-requests",
      providesTags: ["QuoteRequests"],
    }),
    requestInformation: builder.mutation<void, ShipmentInfoRequest>({
      query: (body) => ({
        url: "/information",
        method: "POST",
        body: body,
      }),
    }),
    validateShipmentUpload: builder.mutation<ShipmentCreateBulkResult, File>({
      query: (file) => {
        const body = new FormData();
        body.append("file", file);
        return {
          url: "/validate-bulk",
          method: "POST",
          body,
        };
      },
    }),
    bulkUploadShipments: builder.mutation<void, ShipmentRowToAdd[]>({
      query: (body) => ({
        url: "/upload-bulk",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Shipment"],
    }),
  }),
});

export const {
  useGetTrackingShipmentsByIdQuery,
  useGetPendingCancellationShipmentsByIdQuery,
  useGetShipmentsByShipperIdQuery,
  useGetPastShipmentsQuery,
  useRequestQuoteMutation,
  useLazyGetShipmentsQuery,
  useUpdateShipmentStatusMutation,
  useUpdateShipmentDetailsMutation,
  useGetQuoteRequestsQuery,
  useRequestInformationMutation,
  useValidateShipmentUploadMutation,
  useBulkUploadShipmentsMutation,
  useTenderMutation,
  useLazyGetShipmentDriverQuery,
  useCancelShipmentMutation,
  useConfirmCancelShipmentMutation,
} = shipmentApi;

export const { invalidateTags } = shipmentApi.util;
