import { createApi } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";
import { Invoice } from "../types/Invoice";

export const invoiceApi = createApi({
  reducerPath: "invoiceApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}`),
  tagTypes: ["Invoices"],
  endpoints: (builder) => ({
    getAllInvoices: builder.query<Invoice[], void>({
      query: () => ({
        url: "/invoices",
        method: "GET",
      }),
      providesTags: ["Invoices"],
    }),
  }),
});

export const { useGetAllInvoicesQuery } = invoiceApi;

export const { invalidateTags } = invoiceApi.util;
