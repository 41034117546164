import { Address } from "./Address";
import { EquipmentType } from "./Equipment";
import { ShipmentCreate } from "./Shipment";
import {
  AppointmentType,
  LoadType,
  MaterialType,
  StopType,
} from "./ShipmentForm";

export enum LoadStatus {
  Pending = "Pending",
  Tendering = "Tendering",
  Sourcing = "Sourcing",
  Booked = "Booked",
  Dispatched = "Dispatched",
  ArrivedAtOrigin = "Arrived at Origin",
  Loading = "Loading",
  InTransit = "In-Transit",
  ArrivedAtDestination = "Arrived at Destination",
  Unloading = "Unloading",
  Delivered = "Delivered",
}

export enum LoadStopOrder {
  First = "First",
  Middle = "Middle",
  Last = "Last",
}

export enum TrackingStatus {
  Pending = "Pending", // default, when no tracking is set up yet
  EnRoute = "En Route",
  Arrived = "Arrived",
  Completed = "Completed",
}

export enum LoadInvoiceTerms {
  Net30 = "Net 30",
  Net60 = "Net 60",
  Net90 = "Net 90",
  Net180 = "Net 180",
  Net270 = "Net 270",
  Net270Plus = "Net 270+",
}

export interface ILoad {
  id: string;
  shipperId: string;
  mvmntLoadId: number;
  status: LoadStatus;
  origin?: ILoadAddress;
  destination?: ILoadAddress;
  customerName: string;
  assignedReps: string[];
  bolNotes: string;
  externalLoadId: string | null;
  billingNote: string | null;
  billingAddress: Address;
  unitType: string;
  unitCount: number | null;
  materialTypes: MaterialType[] | null;
  equipmentType: EquipmentType;
  refrigerationTemperature: number | null;
  length: number | null;
  width: number | null;
  height: number | null;
  weight: number;
  accessorials: string[];
  value: number | null;
  totalMiles: number;
  createDate: Date;
  atRisk: boolean;
  highPriority: boolean;
}

export interface ILoadAddress {
  id: string;
  addressName?: string;
  address1: string;
  address2?: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  stopOrder: LoadStopOrder;
  trackingStatus: TrackingStatus;
  stopType: StopType;
  referenceNumber?: string;
  receivingName?: string;
  date: Date;
  allDayDock: boolean;
  dockOpen: string;
  dockClose: string;
  loadType: LoadType;
  keyMarketArea?: string;
  appointmentType: AppointmentType;
  appointmentTime: string | null;
  stopPosition: number;
  notes: string | null;
  arrivalTimestamp: Date | null;
  departureTimestamp: Date | null;
}

interface IStartEndDateStrings {
  start: string;
  end: string;
}

export enum LoadSortOptions {
  LoadId = "Load Id",
  PickupDate = "Pickup Date",
  DeliveryDate = "Delivery Date",
  UpdateDate = "Update Date",
  StatusAlpha = "Status",
}

export interface ILoadGetParams {
  id: string;
  status?: string[];
  customerName?: string[];
  assignedReps?: string[];
  originDate?: IStartEndDateStrings;
  destinationDate?: IStartEndDateStrings;
  sortOption?: LoadSortOptions;
  sortDirection?: "ASC" | "DESC";
  searchTerm?: string;
}

export interface LoadCreate extends ShipmentCreate {
  customerName: string;
  invoiceTerms: LoadInvoiceTerms;
  bolNotes: string;
}

export interface LoadCommentCreate {
  comment: string;
  loadId: string;
}

interface UserComment {
  firstName: string;
  lastName: string;
}

export interface ILoadComment {
  id?: string;
  createDate: Date;
  comment: string;
  loadId: string;
  user: UserComment;
}
export interface ILoadDocument {
  id: string;
  documentName: string;
  loadId: string;
  customLabel: string;
  getUrl: string;
}

export enum LoadUpdateOptions {
  OnHighPriorityLoad = "OnHighPriorityLoad",
  OnAtRiskLoad = "OnAtRiskLoad",
}
