export enum ReferenceType {
  Bank = "BANK",
  Business = "BUSINESS",
}

export interface References {
  type: ReferenceType;
  companyName: string;
  contactName: string;
  phoneNumber: string;
  email: string;
}
