import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface AuthReducerState {
  currentUserToken: string | null;
}

const initialState: AuthReducerState = {
  currentUserToken: null,
};

export const authSlice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    setCurrentUserToken: (
      state,
      { payload: token }: PayloadAction<string | null>,
    ) => {
      state.currentUserToken = token;
    },
  },
});
export const selectCurrentUserToken = (state: RootState): string | null =>
  state.authReducer.currentUserToken;

export const { setCurrentUserToken } = authSlice.actions;

export default authSlice.reducer;
