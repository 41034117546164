export function toTitleCase(str: string): string {
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

export const getFirstCapitalLetter = (str: string): string =>
  str.charAt(0).toUpperCase();

export const paddingZerosLeft = (
  val: string | number,
  zeros?: number,
): string => val.toString().padStart(zeros ?? 3, "0");

export function pluralize(
  str: string,
  length: number,
  singleSuffix = "",
  pluralSuffix = "s",
): string {
  if (!length || length > 1) return `${length} ${str}${pluralSuffix}`;
  return `${length} ${str}${singleSuffix}`;
}
