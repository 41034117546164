import { createApi } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";
import { LocationHistoryCreate } from "../types/Shipment";
import { invalidateTags } from "./shipment";

export const locationHistoryApi = createApi({
  reducerPath: "locationHistoryApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}/location-history`),
  tagTypes: ["LocationHistory"],
  endpoints: (builder) => ({
    provideTrackingUpdate: builder.mutation<
      void,
      Partial<LocationHistoryCreate>
    >({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["LocationHistory"],
      onQueryStarted: async (_arg, api): Promise<void> => {
        try {
          const { dispatch, queryFulfilled } = api;
          await queryFulfilled;
          dispatch(invalidateTags(["Tracking"]));
        } catch (err) {}
      },
    }),
  }),
});

export const { useProvideTrackingUpdateMutation } = locationHistoryApi;
