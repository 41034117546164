import {
  CheckboxGroup,
  Checkbox,
  Flex,
  VStack,
  FormControl,
  Text,
  Button,
  HStack,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { CheckBoxCarrier } from "../../types/Rfp";
import { IRfpForm } from "./types";

interface CarriersFormProps {
  formHook: UseFormReturn<IRfpForm>;
  carriers: CheckBoxCarrier[] | undefined;
  isReadOnly?: boolean;
}

export const RfpSecondStep = ({
  formHook,
  carriers,
}: CarriersFormProps): JSX.Element => {
  const { setValue, getValues, watch } = formHook;
  watch();

  const selectAll = (): void => {
    setValue(
      "carriers",
      carriers!.map((c) => String(c.carrierId ?? c.fmcsaCarrierId)),
    );
  };

  const clearAll = (): void => setValue("carriers", []);

  const values = getValues();

  return (
    <VStack w="100%">
      <HStack w="100%" alignItems="flex-start" mb="1.5rem">
        <Button layerStyle="yellow" px="2rem" onClick={selectAll}>
          SELECT ALL
        </Button>
        <Button layerStyle="yellow" px="2rem" onClick={clearAll}>
          CLEAR ALL
        </Button>
      </HStack>
      <FormControl>
        <CheckboxGroup
          onChange={(v) => {
            setValue(
              "carriers",
              v.map((val) => String(val)),
            );
          }}
          value={values.carriers}
        >
          <Flex display="grid" gridTemplateColumns={"1fr 1fr 1fr"} ml="1.2rem">
            {carriers?.map(({ carrierId, fmcsaCarrierId, companyName }) => {
              const id = String(carrierId ?? fmcsaCarrierId);
              return (
                <Checkbox
                  key={`rfp carrier checkbox:${id}`}
                  my="0.5rem"
                  value={id}
                >
                  {companyName}
                </Checkbox>
              );
            })}
          </Flex>
        </CheckboxGroup>
      </FormControl>
      <Text textAlign="center">
        ADDITIONAL CARRIERS CAN BE ADDED VIA BULK UPLOAD AFTER THE RFP IS
        CREATED
      </Text>
    </VStack>
  );
};
