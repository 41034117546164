import { VStack, FormControl, Input, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CarrierAccountFormProps } from "../../types/CarrierAccountForm";
import { Coverage } from "../../types/Coverage";
import ErrorMessage from "../ErrorMessage";

export const CoveragePreviewForm = ({
  carrierUser,
  handleUpdate,
  isUpdating,
}: CarrierAccountFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm<Coverage>();

  useEffect(() => {
    if (carrierUser) {
      reset(carrierUser.coverage);
    }
  }, [carrierUser]);

  const onSubmit = handleSubmit(
    async (coverage: Coverage) => await handleUpdate({ coverage }),
  );

  return (
    <form onSubmit={onSubmit}>
      <VStack alignItems="center">
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Insurance policy ID"
            {...register("insurancePolicyId", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            type="number"
            placeholder="Commercial General liability"
            {...register("generalLiability", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            type="number"
            placeholder="Auto liability"
            {...register("autoLiability", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            type="number"
            placeholder="Uninsured motorist liability"
            {...register("motoristLiability", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            type="number"
            placeholder="Motor truck cargo policy"
            {...register("cargoPolicy", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            type="number"
            placeholder="Umbrella Policy"
            {...register("umbrellaPolicy", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            type="number"
            placeholder="E&O"
            {...register("errorsOmissions", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            type="number"
            placeholder="Gap Insurance"
            {...register("gapInsurance", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <Button
          layerStyle="red"
          w="20%"
          onClick={onSubmit}
          isLoading={isUpdating}
        >
          Update
        </Button>
        <ErrorMessage errors={errors} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default CoveragePreviewForm;
