import { DownloadIcon } from "@chakra-ui/icons";
import { Divider, Flex, Text, VStack, Link, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useUploadLoadDocumentMutation } from "../../../api/documents";
import { useGetLoadDocumentsQuery } from "../../../api/load";
import { useNotificationBanner } from "../../../components/useNotificationBanner";
import { parseValueToMoney } from "../../../reuse/Money";
import { ILoad, ILoadDocument } from "../../../types/Load";
import { FileUploadButton } from "../../../ui/components/FileUploadButton";
import { FileUploadProgress } from "../../../ui/components/FileUploadProgress";
import "./LoadBoardDetails.scss";

interface LoadDetailsOverviewProps {
  load: ILoad;
}

const DEFAULT_LOAD_PROGRESS = 0.1;

export const LoadDetailsOverview = ({
  load,
}: LoadDetailsOverviewProps): JSX.Element => {
  const notificationBanner = useNotificationBanner();
  const [uploadLoadFile] = useUploadLoadDocumentMutation();
  const { data: documents, refetch } = useGetLoadDocumentsQuery({
    loadId: load.id,
  });
  const [uploadProgress, setUploadProgress] = useState(DEFAULT_LOAD_PROGRESS);

  async function uploadDocument(file: File | null): Promise<void> {
    if (!file) return;
    const response = await uploadLoadFile({
      file,
      loadId: load.id,
      onUploadProgress(progressEvent) {
        setUploadProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    });
    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: (
          <p>There was a problem uploading your file. Please try again.</p>
        ),
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>File upload complete.</p>,
      });
    }
    setUploadProgress(DEFAULT_LOAD_PROGRESS);
    refetch();
  }

  const downloadDoc = (doc: ILoadDocument): void => {
    // The default behavior for link elements wont allow us to download directly if the browser knows
    // how to open the file, this works around that
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        const blobUrl = window.URL.createObjectURL(xmlHttp.response);
        const temporaryAnchor = document.createElement("a");
        temporaryAnchor.href = blobUrl;
        temporaryAnchor.download = doc.customLabel;
        document.body.appendChild(temporaryAnchor);
        temporaryAnchor.click();
        document.body.removeChild(temporaryAnchor);
      }
    };
    xmlHttp.responseType = "blob";
    xmlHttp.open("GET", doc.getUrl, true);
    xmlHttp.send(null);
  };

  return (
    <VStack px={0} alignItems="flex-start" overflowY="scroll" bg="realWhite">
      <Text as="h3" fontSize="20px" fontWeight="600">
        Overview
      </Text>
      <VStack w="100%">
        <Flex w="100%">
          <Text w="50%" className="load-board-drawer-field-title">
            CUSTOMER
          </Text>
          <Text w="50%" className="load-board-drawer-field-value">
            {load.customerName}
          </Text>
        </Flex>
        <Flex w="100%">
          <Text w="50%" className="load-board-drawer-field-title">
            REQUIRED EQUIPMENT
          </Text>
          <Text w="50%" className="load-board-drawer-field-value">
            {load.equipmentType}
          </Text>
        </Flex>
        <Flex w="100%">
          <Text w="50%" className="load-board-drawer-field-title">
            WEIGHT
          </Text>
          <Text w="50%" className="load-board-drawer-field-value">
            {load.weight.toLocaleString()}
          </Text>
        </Flex>
        <Flex w="100%">
          <Text w="50%" className="load-board-drawer-field-title">
            TOTAL MILES
          </Text>
          <Text w="50%" className="load-board-drawer-field-value">
            {load.totalMiles.toLocaleString()}
          </Text>
        </Flex>
        <Flex w="100%">
          <Text w="50%" className="load-board-drawer-field-title">
            PO / INTERNAL ID
          </Text>
          <Text w="50%" className="load-board-drawer-field-value">
            {load.externalLoadId}
          </Text>
        </Flex>
        {load.value && (
          <Flex w="100%">
            <Text w="50%" className="load-board-drawer-field-title">
              SHIPMENT VALUE
            </Text>
            <Text w="50%" className="load-board-drawer-field-value">
              {parseValueToMoney(load.value)}
            </Text>
          </Flex>
        )}
        <Flex w="100%">
          <Text w="50%" className="load-board-drawer-field-title">
            BOL NOTES
          </Text>
          <Text w="50%" className="load-board-drawer-field-value">
            {load.bolNotes}
          </Text>
        </Flex>
      </VStack>
      <Divider borderColor={"grey5"} marginBottom={5} />
      <Text as="h3" fontSize="20px" fontWeight="600">
        Documents({documents?.length ?? "0"})
      </Text>
      {documents?.map((doc) => {
        return (
          <Flex
            justifyContent="space-between"
            w="100%"
            key={`load documents list:${doc.id}`}
          >
            <Link
              href={doc.getUrl}
              isExternal
              color="mvmntBlue"
              fontSize="15px"
            >
              {doc.customLabel}
            </Link>
            <Link>
              <Button
                leftIcon={<DownloadIcon />}
                layerStyle="gray"
                size="small"
                onClick={() => downloadDoc(doc)}
              >
                Download
              </Button>
            </Link>
          </Flex>
        );
      })}
      {uploadProgress > DEFAULT_LOAD_PROGRESS && uploadProgress < 100 ? (
        <FileUploadProgress value={uploadProgress} fileName={"File Upload"} />
      ) : (
        <FileUploadButton layerStyle="black" onChange={uploadDocument}>
          Upload File
        </FileUploadButton>
      )}
    </VStack>
  );
};

export default LoadDetailsOverview;
