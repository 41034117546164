import { HStack, Input } from "@chakra-ui/react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { borderStyling } from "../../reuse/Forms";
import { ShipmentCreate } from "../../types/Shipment";
import { StopType } from "../../types/ShipmentForm";

export const PurchaseRow = ({
  formHook,
  index,
  isReadOnly,
}: {
  formHook: UseFormReturn<ShipmentCreate>;
  index: number;
  isReadOnly: boolean;
}): JSX.Element | null => {
  const {
    control,
    register,
    formState: { errors },
  } = formHook;
  const { shipmentStops: shipmentStopsError } = errors;
  const stopType = useWatch({
    name: `shipmentStops.${index}.stopType`,
    control,
  });

  return stopType === StopType.Delivery ? (
    <HStack w="100%">
      <Input
        placeholder="Purchase order or special reference numbers"
        {...register(`shipmentStops.${index}.referenceNumber` as const, {
          required: false,
        })}
        disabled={isReadOnly}
      />
      <Input
        placeholder="Name of Consignee"
        {...register(`shipmentStops.${index}.receivingName` as const, {
          required: {
            value: true,
            message: "You must enter the name of consignee",
          },
        })}
        disabled={isReadOnly}
        style={{
          borderColor: borderStyling(
            shipmentStopsError?.[index]?.receivingName,
          ),
        }}
      />
    </HStack>
  ) : null;
};
