import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { HStack, VStack, Text, Button, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { parseDateToLocal } from "../../reuse/Dates";
import { Shipment, ShipmentStop } from "../../types/Shipment";
import ChevronIcon from "../ChevronIcon";
import SpotShipmentDetails from "../SpotShipmentDetails";

export const SpotShipmentCard = (shipment: Shipment): JSX.Element => {
  const {
    shipmentStops,
    equipmentType,
    length,
    weight,
    bids,
    shipper,
    shipmentTotalMiles,
  } = shipment;
  const [isExpanded, setExpanded] = useState(false);

  return (
    <VStack
      alignItems="start"
      w="100%"
      borderBottom="1px solid white"
      pb="1rem"
      textAlign="center"
    >
      <HStack w="100%" justifyContent="space-evenly" py="1rem">
        {shipmentStops.length > 0 && (
          <>
            <ShipmentStopOverview stop={shipmentStops[0]} />
            <ChevronIcon />
            <ShipmentStopOverview
              stop={shipmentStops[shipmentStops.length - 1]}
            />
          </>
        )}
        <Text minW="10%">{equipmentType}</Text>
        <Text>{length} ft</Text>
        <Text>{weight} lbs</Text>
        <Text minW="10%">{shipper?.contactInformation.companyName}</Text>
        <Text>{shipmentTotalMiles} Miles</Text>
        {bids && bids.length > 0 ? (
          <HStack w="15%">
            <Text>
              ${bids[0].totalCost} on {parseDateToLocal(bids[0].createDate)}
            </Text>
            <IconButton
              color="mvmntRed"
              onClick={() => setExpanded(!isExpanded)}
              aria-label="details"
              icon={isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
            />
          </HStack>
        ) : (
          <Button
            w="15%"
            rightIcon={isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
            color="mvmntRed"
            variant="link"
            onClick={() => setExpanded(!isExpanded)}
          >
            Details
          </Button>
        )}
      </HStack>
      {isExpanded && <SpotShipmentDetails {...shipment} />}
    </VStack>
  );
};

const ShipmentStopOverview = ({
  stop,
}: {
  stop: ShipmentStop;
}): JSX.Element => {
  return (
    <VStack minW="25%">
      <Text>
        {stop.address.city}, {stop.address.state}
      </Text>
      <Text>{stop.keyMarketArea}</Text>
      <Text>{parseDateToLocal(stop.date)}</Text>
      <Text>{stop.dockOpen}</Text>
    </VStack>
  );
};

export default SpotShipmentCard;
