import { HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import NetworkPartners from "../../components/NetworkPartners";
import PartnerDetail from "../../components/PartnerDetail";
import PartnerPreviewDetail from "../../components/PartnerPreviewDetail";
import ShipperPartnerDetail from "../../components/ShipperPartnerDetail";
import { NetworkPartner } from "../../types/NetworkPartner";

interface NetworkProps {
  partners: NetworkPartner[];
  toggleAddModal: (isOpen: boolean) => void;
  sortPartners: (asc: boolean) => void;
}

export const Network = ({
  partners,
  toggleAddModal,
  sortPartners,
}: NetworkProps): JSX.Element => {
  const [selectedPartner, setSelectedPartner] = useState<NetworkPartner>();

  useEffect(() => {
    setSelectedPartner(partners[0]);
  }, [partners]);

  return (
    <HStack h="100%" alignItems="start" spacing={0}>
      <NetworkPartners
        partners={partners}
        onSelectPartner={setSelectedPartner}
        toggleAddModal={toggleAddModal}
        sortPartners={sortPartners}
        selectedPartner={selectedPartner}
      />
      {selectedPartner?.shipper ? (
        <PartnerDetail {...selectedPartner} />
      ) : selectedPartner?.carrier ? (
        <ShipperPartnerDetail {...selectedPartner} />
      ) : selectedPartner?.fmcsaCarrier ? (
        <PartnerPreviewDetail {...selectedPartner} />
      ) : null}
    </HStack>
  );
};

export default Network;
