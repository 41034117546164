import {
  HStack,
  VStack,
  Text,
  Button,
  Skeleton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useState } from "react";
import { useGetShipperCarrierAgreementQuery } from "../../api/documents";
import { useDeleteShipperFromNetworkMutation } from "../../api/network";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { getUserName } from "../../reuse/UserData";
import { ConnectionStatus, NetworkPartner } from "../../types/NetworkPartner";
import ChevronIcon from "../ChevronIcon";
import ConfirmationModal from "../ConfirmationModal";
import ConnectionStatusAlert from "../ConnectionStatusAlert";
import DocumentUpload from "../DocumentUpload";
import SelectAgreement from "../SelectAgreement";
import ShipperCarrierAgreement from "../ShipperCarrierAgreement/ShipperCarrierAgreement";
import { DocumentType } from "../../types/Files";
import useToastHook from "../../components/useToastHook";

export const PartnerDetail = ({
  id: networkId,
  shipper,
  connectionStatus,
  agreement,
}: NetworkPartner): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isAgreementOpen, toggleAgreementModal] = useState(false);
  const [isDeleteOpen, toggleDeleteModal] = useState(false);
  const [isSelectOpen, toggleSelectModal] = useState(false);
  const [isUploadOpen, toggleUploadModal] = useState(false);
  const [deletePartner, { isLoading: isDeleting }] =
    useDeleteShipperFromNetworkMutation();
  const { data: agreementDocument, isFetching } =
    useGetShipperCarrierAgreementQuery(
      agreement ? { networkId, documentId: agreement.id } : skipToken,
    );
  const toast = useToastHook();

  const {
    id: shipperId,
    contactInformation,
    accountsReceivable,
    insuranceRequirements,
    employees,
  } = shipper!;
  const {
    companyName,
    companyEmail,
    companyPhoneNumber,
    website,
    dunsCode,
    naicsCode,
    email,
  } = contactInformation;

  const onRemovePartner = async (): Promise<void> => {
    toggleDeleteModal(false);
    const response = await deletePartner(shipperId);
    if ("data" in response) {
      toast.success({ description: "Partner removed successfully" });
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onClickUpload = (): void => {
    toggleSelectModal(false);
    toggleUploadModal(true);
  };

  return (
    <Skeleton
      w="100%"
      isLoaded={!isDeleting && !isFetching}
      display="flex"
      justifyContent="center"
    >
      <VStack alignItems="start" w="50%" spacing="3%">
        <Tabs mt="4vh" w="100%" onChange={setSelectedTab}>
          <TabList>
            <Tab>
              Contact Information
              {selectedTab === 0 && <ChevronIcon ml="10px" />}
            </Tab>
            <Tab>Contacts {selectedTab === 1 && <ChevronIcon ml="10px" />}</Tab>
            <Tab>
              Insurance {selectedTab === 2 && <ChevronIcon ml="10px" />}
            </Tab>
            <Tab>
              Accounts Receivable
              {selectedTab === 3 && <ChevronIcon ml="10px" />}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <VStack alignItems="start" spacing="3%">
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Company Name</Text>
                  <Text>{companyName}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Company Email</Text>
                  <Text>{companyEmail}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Company Phone</Text>
                  <Text>{companyPhoneNumber}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Company Website</Text>
                  <Text>{website}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Company DUNS code</Text>
                  <Text>{dunsCode}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Company NAICS code</Text>
                  <Text>{naicsCode}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Contact Name</Text>
                  <Text>{getUserName(contactInformation)}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Contact Email</Text>
                  <Text>{email}</Text>
                </HStack>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack alignItems="start" spacing="3%">
                {employees?.map((employee) => (
                  <>
                    <HStack w="100%" justifyContent="space-between">
                      <Text textStyle="label">Employee Name</Text>
                      <Text>{getUserName(employee)}</Text>
                    </HStack>
                    <HStack w="100%" justifyContent="space-between">
                      <Text textStyle="label">Employee Email</Text>
                      <Text>{employee.email}</Text>
                    </HStack>
                  </>
                ))}
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack alignItems="start" spacing="3%">
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Auto liability</Text>
                  <Text>{insuranceRequirements.autoLiability}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">General Liability Name</Text>
                  <Text>{insuranceRequirements.generalLiability}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Cargo Insurance</Text>
                  <Text>{insuranceRequirements.cargoInsurance}</Text>
                </HStack>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack alignItems="start" spacing="3%">
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Company Name</Text>
                  <Text>{accountsReceivable.companyName}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Contact Name</Text>
                  <Text>{accountsReceivable.contactName}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Phone Number</Text>
                  <Text>{accountsReceivable.phoneNumber}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Email</Text>
                  <Text>{accountsReceivable.email}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Address 1</Text>
                  <Text>{accountsReceivable.address1}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Address 2</Text>
                  <Text>{accountsReceivable.address2}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">City</Text>
                  <Text>{accountsReceivable.city}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">State</Text>
                  <Text>{accountsReceivable.state}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Postal Code</Text>
                  <Text>{accountsReceivable.postalCode}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text textStyle="label">Country</Text>
                  <Text>{accountsReceivable.country}</Text>
                </HStack>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <ConnectionStatusAlert connectionStatus={connectionStatus} />
        <HStack justifyContent="center" w="100%">
          {connectionStatus === ConnectionStatus.PENDING && (
            <Button
              layerStyle="yellow"
              onClick={() => toggleAgreementModal(true)}
            >
              Sign agreement
            </Button>
          )}
          {(connectionStatus === ConnectionStatus.SIGNEDOFFLINEUPLOADED ||
            connectionStatus === ConnectionStatus.CONNECTED) && (
            <Button
              size="small"
              layerStyle="yellow"
              onClick={() => window.open(agreementDocument, "_blank")}
            >
              View Agreement
            </Button>
          )}
          {connectionStatus === ConnectionStatus.SIGNEDOFFLINEUPLOADED && (
            <Button
              size="small"
              layerStyle="yellow"
              onClick={() => toggleUploadModal(true)}
            >
              Re Upload Agreement
            </Button>
          )}
          {connectionStatus === ConnectionStatus.SIGNEDOFFLINE && (
            <Button
              size="small"
              layerStyle="yellow"
              onClick={() => toggleSelectModal(true)}
            >
              Select Agreement
            </Button>
          )}
          <Button
            size="small"
            layerStyle="red"
            onClick={() => toggleDeleteModal(true)}
          >
            Remove Partner
          </Button>
        </HStack>
        {agreementDocument && (
          <ShipperCarrierAgreement
            shipperId={shipperId}
            onClose={() => toggleAgreementModal(false)}
            isOpen={isAgreementOpen}
            agreementLink={agreementDocument}
          />
        )}
        <ConfirmationModal
          isOpen={isDeleteOpen}
          onClose={() => toggleDeleteModal(false)}
          onConfirm={onRemovePartner}
          title="Confirm Partner Removal"
          content="You're about to remove a network partner. Are you sure?"
        />
        <DocumentUpload
          isOpen={isUploadOpen}
          onClose={() => toggleUploadModal(false)}
          documentOptions={[
            {
              label: "Shipper-Carrier Agreement",
              value: DocumentType.ShipperAgreement,
            },
          ]}
          networkId={networkId}
        />
        <SelectAgreement
          isOpen={isSelectOpen}
          onClose={() => toggleSelectModal(false)}
          networkId={networkId}
          onClickUpload={onClickUpload}
        />
      </VStack>
    </Skeleton>
  );
};

export default PartnerDetail;
