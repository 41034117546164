import { createContext, useContext } from "react";
import { Shipment } from "../../types/Shipment";

interface QuoteRequests {
  selectedQuote?: Shipment;
  setSelectedQuote: (quote: Shipment) => void;
}

const QuoteRequestsContext = createContext<QuoteRequests | null>(null);

const useQuoteRequestsContext = (): QuoteRequests | null =>
  useContext(QuoteRequestsContext);

export { QuoteRequestsContext, useQuoteRequestsContext };
