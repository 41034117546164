export const MANAGE_USER_TABLE_HEADER = ["Name", "Email", "Role", "Status"];

export const appearanceApproveBtn = {
  textColor: "mvmntGreen",
  border: "1px solid green",
};

export const appearanceRevokeBtn = {
  textColor: "mvmntRed",
  border: "1px solid red",
};
