import { createApi } from "@reduxjs/toolkit/query/react";
import { NetworkPartner } from "../types/NetworkPartner";
import {
  AddableCarrier,
  NetworkCarrierUploadResult,
} from "../types/NetworkUpload";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";

export const networkApi = createApi({
  reducerPath: "networkApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}/network`),
  tagTypes: ["Network"],
  endpoints: (builder) => ({
    getNetworkCarriers: builder.query<NetworkPartner[], void>({
      query: () => "/carrier",
      providesTags: ["Network"],
    }),
    getNetworkShippers: builder.query<NetworkPartner[], void>({
      query: () => "/shipper",
      providesTags: ["Network"],
    }),
    addShipperToNetwork: builder.mutation<void, string>({
      query: (shipperId) => ({
        url: "/shipper",
        method: "POST",
        body: { shipperId },
      }),
      invalidatesTags: ["Network"],
    }),
    addCarrierToNetwork: builder.mutation<string, string>({
      query: (dotNumber) => ({
        url: "/carrier",
        method: "POST",
        body: { dotNumber },
      }),
      invalidatesTags: ["Network"],
    }),
    confirmAgreement: builder.mutation<void, string>({
      query: (shipperId) => ({
        url: "/confirm",
        method: "PUT",
        body: { shipperId },
      }),
      invalidatesTags: ["Network"],
    }),
    sendReminder: builder.mutation<boolean, string>({
      query: (carrierId) => ({
        url: "/remind",
        method: "PUT",
        body: { carrierId },
      }),
    }),
    deleteCarrierFromNetwork: builder.mutation<void, string>({
      query: (carrierId) => ({
        url: "/carrier",
        method: "DELETE",
        body: { carrierId },
      }),
      invalidatesTags: ["Network"],
    }),
    deleteShipperFromNetwork: builder.mutation<void, string>({
      query: (shipperId) => ({
        url: "/shipper",
        method: "DELETE",
        body: { shipperId },
      }),
      invalidatesTags: ["Network"],
    }),
    selectAgreementFile: builder.mutation<
      void,
      { networkId: string; documentId: string }
    >({
      query: (body) => ({
        url: "/agreement",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Network"],
    }),
    validateUploadFile: builder.mutation<NetworkCarrierUploadResult, File>({
      query: (file) => {
        const body = new FormData();
        body.append("file", file);
        return {
          url: "/upload",
          method: "POST",
          body,
        };
      },
    }),
    bulkAddCarriersToNetwork: builder.mutation<void, AddableCarrier[]>({
      query: (body) => ({
        url: "/upload",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Network"],
    }),
  }),
});

export const {
  useGetNetworkCarriersQuery,
  useGetNetworkShippersQuery,
  useAddCarrierToNetworkMutation,
  useAddShipperToNetworkMutation,
  useConfirmAgreementMutation,
  useDeleteCarrierFromNetworkMutation,
  useDeleteShipperFromNetworkMutation,
  useSendReminderMutation,
  useSelectAgreementFileMutation,
  useValidateUploadFileMutation,
  useBulkAddCarriersToNetworkMutation,
} = networkApi;

export const { invalidateTags } = networkApi.util;
