import { Countries, states } from "../resources/regions";

export const getCountryEnumFromString = (
  name: string,
): Countries | undefined => {
  return Object.values(Countries).find(
    (value) => value.toUpperCase() === name.toUpperCase(),
  );
};

export const getStateEnumFromStringAndCountryEnum = (
  name: string,
  country: Countries,
): { name: string; abbreviation: string } | undefined => {
  return Object.values(states[country]).find(
    (value) => value.name.toUpperCase() === name.toUpperCase(),
  );
};

export const getStateAbbrFromStateCountryPair = (
  state: string,
  country: string,
): string => {
  const countryKey = getCountryEnumFromString(country);
  if (countryKey) {
    const stateKey = getStateEnumFromStringAndCountryEnum(state, countryKey);

    if (stateKey) {
      return stateKey.abbreviation;
    }
  }

  return state;
};
