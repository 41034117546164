import { VStack, Text, HStack, Image, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertModal from "../../components/AlertModal";
import TruckFull from "../../resources/svg/truck_full.svg";
import TruckHalf from "../../resources/svg/truck_half.svg";
import TruckLtl from "../../resources/svg/truck_ltl.svg";
import AppRoutes from "../../routes";
import useToastHook from "../../components/useToastHook";
import ReusableBulkUploadModal from "../../components/ReusableBulkUploadModal";
import ReusableBulkUploadFile from "../../components/ReusableBulkUploadFile";
import { useGetShipmentUploadTemplateQuery } from "../../api/documents";
import { OneMegaByte } from "../../reuse/Files";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import {
  useBulkUploadShipmentsMutation,
  useValidateShipmentUploadMutation,
} from "../../api/shipment";
import UploadShipmentsSummary from "./UploadShipmentsSummary";
import { FooterCmp } from "../../components/ReusableUploadSumary/ReusableUploadSumary";

enum ShipmentType {
  Full = "Full",
  Partial = "Partial",
  Ltl = "Ltl",
}

interface ShipmentTypeCardProps {
  onClick?: () => void;
  type: ShipmentType;
  src: string;
}

export const ShipmentSelect = (): JSX.Element => {
  const { data: template, isFetching } = useGetShipmentUploadTemplateQuery();
  const [fileName, setFileName] = useState("");
  const [validateFile, { data: validationResult, isLoading: isValidating }] =
    useValidateShipmentUploadMutation();
  const [uploadShipments, { isLoading: isAdding }] =
    useBulkUploadShipmentsMutation();

  const [selectedShipmentType, setSelectedType] = useState<ShipmentType>();
  const [isDisabledOpen, toggleDisabledModal] = useState(false);
  const [isUploadOpen, toggleUploadModal] = useState(false);
  const [isUploadFileOpen, toggleUploadFile] = useState(false);
  const navigate = useNavigate();
  const toast = useToastHook();

  const onClickCreate = (): void => {
    if (!selectedShipmentType) {
      toast.error({ description: "Please select a shipment type" });
      return;
    }
    navigate(AppRoutes.CREATE_LOAD);
  };

  const ShipmentTypeCard = ({
    onClick,
    type,
    src,
  }: ShipmentTypeCardProps): JSX.Element => {
    return (
      <HStack
        onClick={onClick ?? (() => setSelectedType(type))}
        cursor="pointer"
        border="1px"
        borderRadius="lg"
        borderColor={selectedShipmentType === type ? "yellow" : "white"}
        justifyContent="center"
        h="7vh"
        w="12vw"
      >
        <Text color="white" textStyle="uppercase">
          {type}
        </Text>
        <Image src={src} w="5vw" />
      </HStack>
    );
  };

  const uploadFileOpen = (): void => {
    toggleUploadModal(false);
    toggleUploadFile(true);
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files![0];
    if (file.size >= 100 * OneMegaByte) {
      toast.error({ description: "File size is too large." });
      return;
    }
    setFileName(file.name);
    const response = await validateFile(file);
    if ("error" in response) {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onClickUpload = async (): Promise<void> => {
    if (!validationResult) return;
    const response = await uploadShipments(validationResult.readyToAdd);
    if ("data" in response) {
      toast.success({ description: "Shipments added successfully" });
      toggleUploadModal(false);
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  return (
    <VStack h="100%" w="100%" bgColor="black" spacing="2vh">
      <Text fontWeight="bold" fontSize="30px" mb="20vh" color="white" mt="20vh">
        Build a Load.
      </Text>
      <HStack mb="3vh !important" spacing="1.5vw">
        <ShipmentTypeCard type={ShipmentType.Full} src={TruckFull} />
        <ShipmentTypeCard
          type={ShipmentType.Partial}
          src={TruckHalf}
          onClick={() => toggleDisabledModal(true)}
        />
        <ShipmentTypeCard
          type={ShipmentType.Ltl}
          src={TruckLtl}
          onClick={() => toggleDisabledModal(true)}
        />
      </HStack>
      <Button layerStyle="yellow" w="13vw" onClick={onClickCreate}>
        Create Load
      </Button>
      <Button color="white" w="13vw" onClick={() => toggleUploadModal(true)}>
        Bulk Upload
      </Button>
      <AlertModal
        title="Coming Soon"
        content="We are only serving FTL at this time"
        onClose={() => toggleDisabledModal(false)}
        isOpen={isDisabledOpen}
      />
      <ReusableBulkUploadModal
        title="BULK UPLOAD SHIPMENTS"
        isOpen={isUploadOpen}
        onClose={() => toggleUploadModal(false)}
        downloadButtonTxt="DOWNLOAD TEMPLATE"
        uploadButtonTxt="UPLOAD FORMATTED SHIPMENTS"
        toggleFileSelectorFn={uploadFileOpen}
        downloadFileName="shipments_template"
        templateHref={template}
        footerCpm={FooterCmp}
      />
      <ReusableBulkUploadFile
        isOpen={isUploadFileOpen}
        onClose={() => toggleUploadFile(false)}
        title="BULK UPLOAD SHIPMENTS"
        extensionFile=".xlsx"
        confirmBtnText="Upload"
        fileName={fileName}
        handleFileInput={handleFileInput}
        isLoaded={!isAdding && !isValidating && !isFetching}
        onClickUpload={onClickUpload}
        SummaryCpm={() =>
          validationResult ? (
            <UploadShipmentsSummary validationResult={validationResult} />
          ) : (
            <></>
          )
        }
      />
    </VStack>
  );
};

export default ShipmentSelect;
