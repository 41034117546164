import { Text, VStack, Box } from "@chakra-ui/react";
import { useGetLoadEventsQuery } from "../../../../api/load";
import { getTimeSinceString } from "../../../../reuse/Dates";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  mapEventLogTypeToSentenceFragment,
  IEventLog,
  EventLogType,
} from "../../../../types/EventLog";

interface LoadChangelogTabProps {
  loadId: string;
}
export const LoadChangelogTab = ({
  loadId,
}: LoadChangelogTabProps): JSX.Element => {
  const { data: loadEvents } = useGetLoadEventsQuery({ loadId });

  const renderEventLog = (event: IEventLog): JSX.Element => {
    const displayChange =
      event.type !== EventLogType.UpdateLoadAtRisk &&
      event.type !== EventLogType.UpdateLoadHighPriority;
    return (
      <Box key={`load changelog event:${event.id}`} w="100%" alignItems="start">
        <Text textAlign="left" color="gray70" fontSize="13px">
          {event.userDisplayName}{" "}
          {mapEventLogTypeToSentenceFragment(event, event.id).map(
            (fragment) => fragment,
          )}{" "}
          {getTimeSinceString(new Date(event.createDate))} ago
        </Text>
        {displayChange && (
          <Text textAlign="left" fontSize="13px">
            <Text as="span" color="gray50">
              {event.oldValue ?? "None"}
            </Text>{" "}
            <ArrowForwardIcon /> {event.newValue ?? "None"}
          </Text>
        )}
      </Box>
    );
  };

  return (
    <VStack
      px={0}
      py={1}
      alignItems="flex-start"
      overflowY="scroll"
      bg="realWhite"
      spacing="20px"
    >
      <Text as="h3" fontSize="20px" fontWeight="600" mb="">
        Change Log
      </Text>
      {loadEvents?.map((event) => renderEventLog(event))}
    </VStack>
  );
};

export default LoadChangelogTab;
