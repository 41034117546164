import { Button, FormControl, Input, Stack, VStack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  useForgotPasswordMutation,
  useLoginUserMutation,
} from "../../api/auth";
import ErrorMessage from "../../components/ErrorMessage";
import SignInPageHOC from "../../components/SignInPageHOC/SignInPageHOC";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { borderStyling } from "../../reuse/Forms";
import { validateEmail } from "../../reuse/UserData";
import AppRoutes from "../../routes";
import useToastHook from "../../components/useToastHook";

interface LoginFormData {
  username: string;
  password: string;
}

const Login = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<LoginFormData>();
  const navigate = useNavigate();
  const [loginUser, { isLoading: isLogingIn }] = useLoginUserMutation();
  const [forgotPassword, { isLoading: isResetting }] =
    useForgotPasswordMutation();

  const toast = useToastHook();

  const isLoading = isLogingIn || isResetting;

  const handleLogin = async (loginData: LoginFormData): Promise<void> => {
    const response = await loginUser(loginData);
    if ("data" in response) {
      if (response.data === "NEW_PASSWORD_REQUIRED") {
        navigate(AppRoutes.UPDATE_PASSWORD);
      } else {
        navigate(AppRoutes.BASE);
      }
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onClickBrokerSingUp = (): void => {
    navigate(AppRoutes.BROKER_ACCOUNT_REQUEST);
  };

  const onClickShipperSignUp = (): void => {
    navigate(AppRoutes.SHIPPER_ACCOUNT_REQUEST);
  };

  const onClickCarrierSignUp = (): void => {
    navigate(AppRoutes.CREATE_ACCOUNT);
  };

  const onClickReset = async (): Promise<void> => {
    const username = getValues("username");
    if (username.length === 0) {
      toast.error({ description: "Please enter your username" });
      return;
    }
    const response = await forgotPassword(username);
    if ("data" in response) {
      toast.success({ description: "A password reset email was sent" });
      navigate(AppRoutes.RESET_PASSWORD);
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onSubmit = handleSubmit(handleLogin);

  return (
    <Stack w="80%" mt="10vh">
      <form onSubmit={onSubmit}>
        <VStack spacing="1rem" minH="40vh" mx="10px">
          <FormControl isDisabled={isLoading}>
            <Input
              placeholder="Username"
              {...register("username", {
                required: {
                  value: true,
                  message: "You must enter your username",
                },
                validate: validateEmail,
              })}
              style={{ borderColor: borderStyling(errors?.username) }}
            ></Input>
          </FormControl>
          <FormControl isDisabled={isLoading}>
            <Input
              placeholder="Password"
              type="password"
              {...register("password", {
                required: {
                  value: true,
                  message: "You must enter your password",
                },
              })}
              style={{ borderColor: borderStyling(errors?.password) }}
            ></Input>
          </FormControl>

          <Button
            isLoading={isLoading}
            layerStyle="red"
            w="100%"
            borderRadius="lg"
            onClick={onSubmit}
            mb="auto !important"
          >
            Login
          </Button>

          <Button
            isDisabled={isLoading}
            layerStyle="yellow"
            w="100%"
            borderRadius="lg"
            onClick={onClickBrokerSingUp}
          >
            Broker Sign Up
          </Button>

          <Button
            isDisabled={isLoading}
            layerStyle="yellow"
            w="100%"
            borderRadius="lg"
            onClick={onClickShipperSignUp}
          >
            Shipper Sign Up
          </Button>

          <Button
            isDisabled={isLoading}
            layerStyle="yellow"
            w="100%"
            borderRadius="lg"
            onClick={onClickCarrierSignUp}
          >
            Carrier Sign Up
          </Button>

          <Button
            onClick={onClickReset}
            variant="link"
            isLoading={isLoading}
            color="white"
          >
            Forgot Password
          </Button>
          <ErrorMessage errors={errors} clearErrors={clearErrors} />
        </VStack>
      </form>
    </Stack>
  );
};

export default SignInPageHOC(Login);
