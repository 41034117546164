import { HStack, Skeleton } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { DriverFormMode, Shipment } from "../../types/Shipment";
import { TrackingContext } from "./TrackingContext";
import {
  useGetPendingCancellationShipmentsByIdQuery,
  useGetTrackingShipmentsByIdQuery,
} from "../../api/shipment";
import ShortAlertModal from "../../components/ShortAlertModal";
import DriverModal from "../../components/DriverModal";
import LocationFormModal from "../../components/LocationFormModal";
import TrackingShipmentContent from "../../components/TrackingShipmentContent";
import TrackingSidebar from "../../components/TrackingSidebar";
import { MapLocations } from "../SourcingSearch/SourcingSearchContext";
import {
  setLocationsFromTracking,
  setMarkerFromTracking,
} from "../../reuse/Maps";
import { MarkerInfo } from "../../components/SharedMap/SharedMap";

export const Tracking = (): JSX.Element => {
  const [currentTrackingShipment, setCurrentTrackingShipment] = useState<
    Shipment | undefined
  >();
  const [driverFormMode, setDriverFormMode] = useState<DriverFormMode>(
    DriverFormMode.CREATE,
  );

  const { data: trackingShipments, isFetching } =
    useGetTrackingShipmentsByIdQuery();

  const { data: pendingCancellationShipments } =
    useGetPendingCancellationShipmentsByIdQuery();

  const mounted = useRef(false);

  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const [showDriverModal, setShowDriverModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [showDriverInfo, setShowDriverInfo] = useState(false);
  const [locations, setLocations] = useState<MapLocations | undefined>();
  const [markerInfo, setMarkerInfo] = useState<MarkerInfo>();

  const onCloseDispatchModal = (): void => setShowDispatchModal(false);
  const onCloseModalDriver = (): void => setShowDriverModal(false);
  const onCloseLocationModal = (): void => setShowLocationModal(false);

  const driverModalTitle =
    driverFormMode === DriverFormMode.CREATE
      ? "ADD DRIVER INFO"
      : "UPDATE DRIVER INFO";

  const onCloseLocationModalAfterProvide = (): void => {
    setLocations(undefined);
    setShowLocationModal(false);
  };

  useEffect(() => {
    mounted.current = true;

    if (trackingShipments && trackingShipments.length > 0) {
      setCurrentTrackingShipment(trackingShipments?.[0]);
    }

    return () => {
      mounted.current = false;
    };
  }, [trackingShipments]);

  useEffect(() => {
    const updateMapInfo = async (): Promise<void> => {
      await setLocationsFromTracking(currentTrackingShipment!, setLocations);
      await setMarkerFromTracking(currentTrackingShipment!, setMarkerInfo);
    };

    void updateMapInfo();
  }, [currentTrackingShipment]);

  return (
    <TrackingContext.Provider
      value={{
        currentTrackingShipment,
        setCurrentTrackingShipment,
        showDispatchModal,
        setShowDispatchModal,
        showDriverModal,
        setShowDriverModal,
        showLocationModal,
        setShowLocationModal,
        driverFormMode,
        setDriverFormMode,
        showContactInfo,
        setShowContactInfo,
        showDriverInfo,
        setShowDriverInfo,
        locations,
        setLocations,
        markerInfo,
        setMarkerInfo,
        setLocationsFromTracking,
        setMarkerFromTracking,
      }}
    >
      <Skeleton h="100%" isLoaded={!isFetching}>
        <HStack h="100%">
          <TrackingSidebar
            trackingShipments={
              trackingShipments && pendingCancellationShipments
                ? [...pendingCancellationShipments, ...trackingShipments]
                : undefined
            }
          />
          <TrackingShipmentContent title="TRACKING" />
        </HStack>
        <ShortAlertModal
          content="DRIVER DISPATCHED"
          isOpen={showDispatchModal}
          onClose={onCloseDispatchModal}
        />
        <DriverModal
          title={driverModalTitle}
          openDriverForm={showDriverModal}
          onClose={onCloseModalDriver}
          shipmentId={currentTrackingShipment?.id}
        />
        <LocationFormModal
          onAfterSubmit={onCloseLocationModalAfterProvide}
          onClose={onCloseLocationModal}
          isOpen={showLocationModal}
        />
      </Skeleton>
    </TrackingContext.Provider>
  );
};

export default Tracking;
