import { DownloadIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  HStack,
  Button,
  Text,
  Link,
} from "@chakra-ui/react";
import { useGetLaneUploadTemplateQuery } from "../../api/documents";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { UserType } from "../../types/User";

interface BulkUploadProps {
  isOpen: boolean;
  title: string;
  downloadButtonText: string;
  uploadButtonText: string;
  footer?: string;
  onClose: () => void;
  downloadLaneFile?: () => void;
  uploadFileOpen: () => void;
}

export const BulkUploadModal = ({
  isOpen,
  title,
  downloadButtonText,
  uploadButtonText,
  footer,
  onClose,
  downloadLaneFile,
  uploadFileOpen,
}: BulkUploadProps): JSX.Element => {
  const { type } = useAppSelector(selectUserData);
  const shipperType = UserType.SHIPPER === type || UserType.BROKER === type;

  const { data: template } = useGetLaneUploadTemplateQuery(undefined, {
    skip: !shipperType,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
      isCentered
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent p="1rem">
        <ModalHeader textStyle="uppercase" px="1.5rem" fontSize="25px">
          {title}
        </ModalHeader>
        <ModalBody>
          <HStack w="100%" justifyContent="space-between">
            <Link href={template} download="network_template">
              <Button
                layerStyle="yellow"
                rightIcon={<DownloadIcon />}
                onClick={downloadLaneFile}
              >
                {downloadButtonText}
              </Button>
            </Link>
            <Button layerStyle="red" fontSize="15px" onClick={uploadFileOpen}>
              {uploadButtonText}
            </Button>
          </HStack>
        </ModalBody>
        {footer && (
          <ModalFooter justifyContent="start">
            <Text textAlign="center">{footer}</Text>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BulkUploadModal;
