import { Skeleton } from "@chakra-ui/react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useGetShipperQuery } from "../../api/shipper";
import AppRoutes from "../../routes";
import { useCrispChatbotHook } from "../../components/useCrispChatbotHook/useCrispChatbotHook";
import LoadsBoardPage from "../LoadsBoard/LoadsBoardPage";
import ShipperAccount from "../ShipperAccount";
import ShipperDocuments from "../ShipperDocuments";
import ShipperNetwork from "../ShipperNetwork";
import ShipmentSelect from "../ShipmentSelect";
import ComingSoon from "../../components/ComingSoon";
import ShipmentCreate from "../ShipmentCreate";
import SourcingSearch from "../SourcingSearch";
import ShipperRFP from "../ShipperRFP";
import Tracking from "../Tracking";
import PastShipment from "../PastShipment";
import { AccountsPayable } from "../AccountsPayable/AccountsPayable";

export const BrokerShell = (): JSX.Element => {
  const { isLoading } = useGetShipperQuery();
  useCrispChatbotHook();

  const showRfpPage = process.env.REACT_APP_SHOW_RFP_PAGE === "true";
  const showTrackingPage = process.env.REACT_APP_SHOW_TRACKING_PAGE === "true";
  const showPaymentsPage = process.env.REACT_APP_SHOW_PAYMENTS_PAGE === "true";

  return (
    <Skeleton isLoaded={!isLoading} w="100%" h="100%" d="flex" flexDir="column">
      <Routes>
        <Route path={AppRoutes.LOADS_BOARD} element={<LoadsBoardPage />} />
        <Route path={AppRoutes.ACCOUNT_DETAILS} element={<ShipperAccount />} />
        <Route
          path={AppRoutes.MANAGE_DOCUMENTS}
          element={<ShipperDocuments />}
        />
        <Route path={AppRoutes.MANAGE_NETWORK} element={<ShipperNetwork />} />
        <Route path={AppRoutes.SOURCING_SEARCH} element={<SourcingSearch />} />
        <Route path={AppRoutes.LOAD_BUILD} element={<ShipmentSelect />} />
        <Route path={AppRoutes.CREATE_LOAD} element={<ShipmentCreate />} />
        <Route
          path={AppRoutes.ACTIVE_QUOTES}
          element={<Navigate to={AppRoutes.LOADS_BOARD} />}
        />
        <Route
          path={AppRoutes.ACTIVE_RFP}
          element={showRfpPage ? <ShipperRFP /> : <ComingSoon />}
        />
        <Route
          path={AppRoutes.TRACKING}
          element={showTrackingPage ? <Tracking /> : <ComingSoon />}
        />
        <Route path={AppRoutes.PAST} element={<PastShipment />} />
        <Route
          path={AppRoutes.ACCOUNTS_PAYABLE}
          element={showPaymentsPage ? <AccountsPayable /> : <ComingSoon />}
        />
        <Route path="/*" element={<ComingSoon />} />
      </Routes>
    </Skeleton>
  );
};

export default BrokerShell;
