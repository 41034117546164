import { Skeleton } from "@chakra-ui/react";
import { useGetUserDocumentsQuery } from "../../api/documents";
import ManageDocuments from "../ManageDocuments";
import { documentTypes } from "./options";

export const ShipperDocuments = (): JSX.Element => {
  const { data, isLoading } = useGetUserDocumentsQuery();

  return (
    <Skeleton w="100%" isLoaded={!isLoading}>
      {data && (
        <ManageDocuments documents={data} documentTypes={documentTypes} />
      )}
    </Skeleton>
  );
};

export default ShipperDocuments;
