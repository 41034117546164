import { Text, VStack } from "@chakra-ui/react";
import { useGetLoadCommentsQuery } from "../../../../api/load";
import { LoadNoteList } from "./LoadNoteList";
import { LoadNotesForm } from "./LoadNotesForm";
import "../LoadBoardDetails.scss";

interface LoadDetailsNotesProps {
  loadId: string;
}
export const LoadDetailsNotes = ({
  loadId,
}: LoadDetailsNotesProps): JSX.Element => {
  const { data: loadComments } = useGetLoadCommentsQuery({ loadId });

  return (
    <VStack
      px={0}
      py={1}
      alignItems="flex-start"
      overflowY="scroll"
      bg="realWhite"
    >
      <Text as="h3" fontSize="20px" fontWeight="600">
        Notes
      </Text>
      <LoadNoteList commentList={loadComments ?? []} />
      <LoadNotesForm loadId={loadId} />
    </VStack>
  );
};

export default LoadDetailsNotes;
