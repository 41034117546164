import { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import GoogleMapReact from "google-map-react";
import MapStyle from "../../styles/mapStyle";
import { getDirections } from "../../reuse/Maps";
import { geoJson } from "../../resources/marketAreas";
import useToastHook from "../useToastHook";
import { MarkerPin } from "./MarkerPin";
import { MapLocations } from "../../pages/SourcingSearch/SourcingSearchContext";

export const Marker = (_: google.maps.LatLngLiteral): JSX.Element => {
  return <MarkerPin />;
};

export interface MarkerInfo {
  id: string;
  latitude: number;
  longitude: number;
}

interface MapProps {
  locations?: MapLocations;
  kma?: string;
  marker?: MarkerInfo;
  hideRoute?: boolean;
}

export const SharedMap = ({
  kma,
  locations,
  marker,
  hideRoute,
}: MapProps): JSX.Element => {
  const [map, setMap] = useState<google.maps.Map<Element>>();
  const [features, setFeatures] = useState<google.maps.Data.Feature[]>();
  const [directionsRenderer, setDirectionsRenderer] =
    useState<google.maps.DirectionsRenderer>();

  const toast = useToastHook();

  useEffect(() => {
    void setRouteInMap();
  }, [locations, map, directionsRenderer]);

  useEffect(() => {
    if (!map) return;
    map.data.setStyle({ fillColor: "yellow" });
  }, [map]);

  useEffect(() => {
    setKmaOverlayInMap();
  }, [kma, map]);

  const getDirectionsRenderer = (): google.maps.DirectionsRenderer => {
    const renderer = directionsRenderer ?? new google.maps.DirectionsRenderer();
    if (!directionsRenderer) setDirectionsRenderer(renderer);
    return renderer;
  };

  const setKmaOverlayInMap = (): void => {
    if (!map) return;
    removeFeaturesInMap(map);
    removeDirectionsInMap();
    if (kma) {
      const newFeatures = map.data.addGeoJson(geoJson[kma]);
      setFeatures(newFeatures);
    }
  };

  const setRouteInMap = async (): Promise<void> => {
    if (!locations || !map) return;

    const renderer = getDirectionsRenderer();
    removeFeaturesInMap(map);

    const directions = await getDirections(
      locations.pickup,
      locations.destination,
    );

    if (directions) {
      renderer.setMap(map);

      if (hideRoute) {
        renderer.setDirections({
          ...directions,
          routes: directions.routes.map(
            (r): google.maps.DirectionsRoute => ({
              ...r,
              legs: r.legs.map(
                (l): google.maps.DirectionsLeg => ({
                  ...l,
                  steps: [],
                }),
              ),
            }),
          ),
        });
      } else renderer.setDirections(directions);
    } else {
      toast.error({ description: "Error fetching directions" });
    }
  };

  const removeDirectionsInMap = (): void => {
    const renderer = getDirectionsRenderer();
    renderer.setMap(null);
  };

  const removeFeaturesInMap = (map: google.maps.Map): void => {
    if (features) {
      features.forEach((feature) => map.data.remove(feature));
      setFeatures(undefined);
    }
  };

  const haveMarkerToShow = !!(marker?.latitude && marker?.longitude);

  return (
    <Box h="100%" w="100%">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY!,
          libraries: ["places"],
        }}
        defaultCenter={{
          lat: 37,
          lng: -95,
        }}
        defaultZoom={4}
        options={{
          styles: MapStyle,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => setMap(map)}
      >
        {haveMarkerToShow && (
          <Marker
            key={marker?.id}
            lat={marker?.latitude}
            lng={marker?.longitude}
          />
        )}
      </GoogleMapReact>
    </Box>
  );
};

export default SharedMap;
