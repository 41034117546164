import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalBody,
  Text,
  Select,
  Heading,
} from "@chakra-ui/react";
import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { DefaultModalProps } from "../../types/DefaultModal";
import {
  CarrierCancelReason,
  DriverInfo,
  DriverStatus,
  ShipmentCancelReason,
  ShipperCancelReason,
} from "../../types/Shipment";
import { UserType } from "../../types/User";

interface ShipmentCancelModalProps extends DefaultModalProps {
  onConfirm: (reason?: ShipmentCancelReason) => void;
  driver?: DriverInfo;
  isLoading: boolean;
}

export const ShipmentCancelModal = ({
  isOpen,
  onClose,
  onConfirm,
  driver,
  isLoading,
}: ShipmentCancelModalProps): JSX.Element => {
  const [cancelReason, setCancelReason] = useState<
    ShipmentCancelReason | undefined
  >();
  const user = useAppSelector(selectUserData);
  const userIsCarrier = user.type === UserType.CARRIER;

  const isDriverDispatched =
    !!driver && driver.driverStatus === DriverStatus.Dispatched;
  const cancelDisabled: boolean =
    (isDriverDispatched && !cancelReason) || (userIsCarrier && !cancelReason);

  const renderSelectReasonOptions = (): JSX.Element | undefined => {
    if (!userIsCarrier) {
      return (
        <>
          {Object.values(ShipperCancelReason).map((value) => (
            <option key={`cancel reason:${value}`} value={value}>
              {value}
            </option>
          ))}
        </>
      );
    } else {
      return (
        <>
          {Object.values(CarrierCancelReason).map((value) => (
            <option key={`cancel reason:${value}`} value={value}>
              {value}
            </option>
          ))}
        </>
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="uppercase">Confirm Cancellation</ModalHeader>
        <ModalBody>
          <Text fontSize={18} mb={"10px"}>
            Are you sure you want to cancel this shipment?
          </Text>
          {(isDriverDispatched || userIsCarrier) && (
            <>
              <Heading as="h5" fontSize={18} mb={"10px"}>
                Please Provide a reason below
              </Heading>
              <Select
                value={cancelReason}
                onChange={(event) => {
                  if (event.target.value) {
                    setCancelReason(
                      event.target.value as ShipmentCancelReason | undefined,
                    );
                  } else {
                    setCancelReason(undefined);
                  }
                }}
                w={"65%"}
              >
                <option value={""}>Select</option>
                {renderSelectReasonOptions()}
              </Select>
            </>
          )}
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <Button layerStyle="inverseRed" onClick={onClose}>
            close
          </Button>
          <Button
            isLoading={isLoading}
            disabled={cancelDisabled}
            layerStyle="red"
            onClick={() => onConfirm(cancelReason)}
          >
            Cancel Shipment
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShipmentCancelModal;
