import { createApi } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";
import { ManageUserComment } from "../types/Admin";

export const shipperCommentsApi = createApi({
  reducerPath: "shipperCommentsApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}/shipper-comments`),
  tagTypes: ["ShipperComments"],
  endpoints: (builder) => ({
    addComment: builder.mutation<void, ManageUserComment>({
      query: (body) => ({
        url: `/${body.shipperId}/comments`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ShipperComments"],
    }),
    getShipperComments: builder.query<ManageUserComment[], string>({
      query: (body) => ({
        url: `/${body}`,
        method: "GET",
      }),
      providesTags: ["ShipperComments"],
    }),
  }),
});

export const { useAddCommentMutation, useGetShipperCommentsQuery } =
  shipperCommentsApi;
