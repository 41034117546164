export const sortText = (a: string, b: string, asc: boolean): number => {
  const textA = a.toUpperCase();
  const textB = b.toUpperCase();
  if (textA > textB) return asc ? 1 : -1;
  if (textA < textB) return asc ? -1 : 1;
  return 0;
};

export const sortTextElements = (list: string[]): string[] => {
  return list?.sort((a: string, b: string) => (a > b ? 1 : -1));
};

export const sortDate = (a: Date, b: Date, asc: boolean): number => {
  const dateA = new Date(a).getTime() || 0;
  const dateB = new Date(b).getTime() || 0;
  if (dateA > dateB) return asc ? 1 : -1;
  if (dateA < dateB) return asc ? -1 : 1;
  return 0;
};
