import { ButtonProps } from "@chakra-ui/react";
import { toTitleCase } from "../../reuse/Strings";
import SharedButton from "../SharedButton";

export enum BadgeColors {
  Green = "Green",
  Grey = "Grey",
  Blue = "Blue",
  Red = "Red",
}

interface BadgeProps<T> {
  currentStatus: T;
  badgeColor: BadgeColors;
  btnProps?: ButtonProps;
}

export function ReusableBadge<T>({
  currentStatus,
  badgeColor,
  btnProps,
}: BadgeProps<T>): JSX.Element {
  const getTextColor = (): string => {
    if (badgeColor === BadgeColors.Green) {
      return "mvmntGreen";
    } else if (badgeColor === BadgeColors.Grey) {
      return "grey3";
    } else if (badgeColor === BadgeColors.Blue) {
      return "mvmntBlue";
    }
    // rejected
    return "pink";
  };
  const getBackgroundColor = (): string => {
    if (badgeColor === BadgeColors.Green) {
      return "greenOpacity15";
    } else if (badgeColor === BadgeColors.Grey) {
      return "grey6";
    } else if (badgeColor === BadgeColors.Blue) {
      return "lightBlue";
    }
    // rejected
    return "pinkOpacity15";
  };
  return (
    <SharedButton
      title={toTitleCase(String(currentStatus).toLowerCase())}
      size="xs"
      textTransform="none"
      color={getTextColor()}
      bgColor={getBackgroundColor()}
      cursor="default"
      {...btnProps}
    />
  );
}

export default ReusableBadge;
