import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  VStack,
  Input,
  Select,
  Text,
  Image,
  HStack,
  Link,
} from "@chakra-ui/react";
import { Control, Controller, useForm } from "react-hook-form";
import {
  OnboardingForm,
  TermsAndConditionLink,
  useSubmitOnboardingFormMutation,
  useUpdateOnboardingFormMutation,
} from "../../api/tilled";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { validatePhoneNumber } from "../../reuse/UserData";
import { CarrierUser } from "../../types/Carrier";
import React, { useEffect, useState } from "react";
import useToastHook from "../useToastHook";
import { Countries } from "../../resources/regions";
import {
  getCountryEnumFromString,
  getStateEnumFromStringAndCountryEnum,
} from "../../reuse/RegionKeys";
import "./TilledOnboardingForm.scss";
import PaymentsSampleCheck from "../../resources/png/payments-sample-check.png";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Address, Addresses } from "../../types/Address";
import ErrorMessage from "../../components/ErrorMessage";
import CarrierPaymentsAddressField from "../CarrierPaymentsAddressField";
import { parseErrorResponse } from "../../reuse/ErrorHandler";

enum BusinessTypeOption {
  SOLEPROP = "Sole Proprietor",
  LLC = "LLC",
  LLP = "LLP",
  CORP = "Corporation",
}
type BusinessTypeOptionKey = keyof typeof BusinessTypeOption;

interface OnboardingFormInputs {
  dob: string;
  phone: string;
  addPhone: string;
  street: string;
  street2: string;
  addStreet: string;
  addStreet2: string;
  businessType: BusinessTypeOptionKey;
  ssn: string;
  tin: string;
  website: string;
  accountNumber: string;
  verifyAccountNumber: string;
  routingNumber: string;
}

function TermsLinks({
  links = [],
}: {
  links?: TermsAndConditionLink[];
}): JSX.Element {
  const nameMap: Record<string, string> = {
    "Card Terms and Conditions": "Terms & Conditions",
    "Portal Terms and Conditions": "Terms of Use",
    "ACH Debit Terms and Conditions": "ACH Terms",
  };
  return (
    <>
      {links.map(({ link, name }, i) => (
        <React.Fragment key={link}>
          {i + 1 === links.length && <>and </>}
          <Link href={link} color="mvmntBlue" isExternal>
            {/* We want to use our own custom names, but can't guarantee tilled won't changed this on us */}
            {nameMap[name] ?? name}
          </Link>
          {i + 1 < links.length && <>, </>}
        </React.Fragment>
      ))}
    </>
  );
}

export const TilledOnboardingForm = ({
  isUpdating,
  formData,
  carrierUser,
}: {
  isUpdating: boolean;
  formData: OnboardingForm;
  carrierUser: CarrierUser;
}): JSX.Element => {
  const toast = useToastHook();

  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    formState,
    clearErrors,
  } = useForm<OnboardingFormInputs>();

  const addressFormHook = useForm<Addresses>();
  const businessAddressFormHook = useForm<Addresses>();

  const watchBusinessType = watch("businessType");

  const [updateOnboardingForm] = useUpdateOnboardingFormMutation();
  const [submitOnboardingForm] = useSubmitOnboardingFormMutation();

  const [showAddAddress, setShowAddAddress] = useState(false);
  const [showAddPhone, setShowAddPhone] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const untypedControl = control as Control<any>;

  const companyBillingAddress =
    carrierUser.contactInformation.companyBillingAddresses[0];

  useEffect(() => {
    if (carrierUser) {
      addressFormHook.reset({
        address: carrierUser.contactInformation.companyBillingAddresses,
      });
      businessAddressFormHook.reset({
        address: carrierUser.contactInformation.companyMailingAddresses,
      });
    }
  }, [carrierUser]);

  const onSubmit = handleSubmit(async (inputs: OnboardingFormInputs) => {
    const addressFormSuccess = await addressFormHook.trigger();
    const businessAddressFormSuccess = await businessAddressFormHook.trigger();
    if (!addressFormSuccess || !businessAddressFormSuccess) return;
    const { address: billingAddress } = addressFormHook.getValues();
    const { address: additionalAddress } = businessAddressFormHook.getValues();
    if (billingAddress.length > 0) {
      inputs.street = billingAddress[0].address1;
      inputs.street2 = billingAddress[0].address2;
    }
    if (additionalAddress.length > 0) {
      inputs.addStreet = additionalAddress[0].address1;
      inputs.addStreet2 = additionalAddress[0].address2;
    }

    const validContactInformation =
      companyBillingAddress?.city &&
      companyBillingAddress?.state &&
      companyBillingAddress?.postalCode &&
      companyBillingAddress?.country;
    if (!validContactInformation) {
      toast.error({
        description:
          "Incomplete contact information. Please go to the account Contact Information tab and ensure a company billing address is fully filled out.",
      });
      return;
    }
    try {
      const mapped = billingAddress[0]
        ? mapTypeToForm(inputs, billingAddress[0])
        : mapTypeToForm(inputs, companyBillingAddress);
      const response = await updateOnboardingForm({
        id: carrierUser.tilledId,
        body: mapped,
      });
      if ("data" in response && "validation_errors" in response.data) {
        if (response.data.validation_errors!.length > 0) {
          const errorMessage = `Validation Errors: \n ${response.data!.validation_errors!.map(
            (s) => `${s} \n`,
          )}`;
          toast.error({ description: errorMessage });
        } else {
          const response = await submitOnboardingForm(carrierUser.tilledId);
          if ("data" in response) {
            toast.success({
              description: `Payment Account added successfully. `,
            });
          } else {
            toast.error({
              description: `${parseErrorResponse(response.error)}`,
            });
          }
        }
      }
    } catch (error) {
      alert("Failed to submit onboarding form!");
    }
  });

  const mapTypeToForm = (
    inputs: OnboardingFormInputs,
    companyBillingAddress: Address,
  ): OnboardingForm => {
    // NOTE: both account and routing number are necessary or it will break the onboarding form (will return a 500 for all onboarding endpoints)
    const countryString = getCountryEnumFromString(
      companyBillingAddress.country,
    );
    const countryCode =
      Object.keys(Countries)[Object.values(Countries).indexOf(countryString!)];
    let stateCode;
    if (countryString) {
      stateCode = getStateEnumFromStringAndCountryEnum(
        companyBillingAddress.state,
        countryString!,
      );
    } else {
      stateCode = getStateEnumFromStringAndCountryEnum(
        companyBillingAddress.state,
        Countries.US,
      );
    }
    const taxId = inputs.businessType === "SOLEPROP" ? inputs.ssn : inputs.tin;
    return {
      accept_terms_and_conditions: true,
      business_legal_entity: {
        type: inputs.businessType,
        name: carrierUser.companyName,
        legal_name: "",
        tax_identification_number: taxId,
        address: {
          street: inputs.addStreet ? inputs.addStreet : inputs.street,
          street2: inputs.addStreet2 ? inputs.addStreet2 : inputs.street2,
          city: companyBillingAddress.city,
          state: stateCode?.abbreviation,
          zip: companyBillingAddress.postalCode,
          country: countryCode,
        },
        category: "MISC_SERV",
        phone: inputs.addPhone ? inputs.addPhone : inputs.phone,
        currency: "usd",
        yearly_volume_range: "MEDIUM",
        average_transaction_amount: 80000,
        region: "US",
        locale: "en_US",
        statement_descriptor: "",
        principals: [
          {
            is_applicant: true,
            job_title: "Owner",
            first_name: user.firstName,
            last_name: user.lastName,
            phone: inputs.phone,
            date_of_birth: inputs.dob,
            address: {
              street: inputs.street,
              street2: inputs.street2,
              city: companyBillingAddress.city,
              state: stateCode?.abbreviation,
              zip: companyBillingAddress.postalCode,
              country: countryCode,
            },
            percentage_shareholding: 100,
            is_control_prong: true,
            ssn: taxId,
          },
        ],
        bank_account: {
          account_number: inputs.accountNumber ? inputs.accountNumber : "",
          routing_number: inputs.routingNumber ?? "",
        },
      },
    };
  };

  const user = carrierUser.users[0];

  return (
    <form onSubmit={onSubmit} className="tilled-onboarding-form">
      <Box p="30px 40px" bg="#fff" borderRadius="5px" marginTop="4">
        <Heading as="h2" marginBottom="5">
          Set-up payment account
        </Heading>
        <VStack spacing={8}>
          <FormControl>
            <FormLabel>Your Name</FormLabel>
            <Text>
              {user.firstName} {user.lastName}
            </Text>
          </FormControl>
          <FormControl>
            <FormLabel>*Birthdate</FormLabel>
            <Input
              size="lg"
              defaultValue={
                carrierUser.companyContacts.contactPhoneNumber ?? ""
              }
              {...register("dob", {
                required: {
                  value: true,
                  message: "You must enter your date of birth",
                },
              })}
              backgroundColor="#ffffff"
              type="date"
              width="auto"
            />
          </FormControl>
          <FormControl>
            <FormLabel>*Phone Number</FormLabel>
            <Controller
              control={control}
              name="phone"
              defaultValue={
                carrierUser.contactInformation.companyPhoneNumber ?? ""
              }
              rules={{
                required: false,
                validate: validatePhoneNumber,
              }}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <PhoneInput
                  defaultValue={
                    carrierUser.contactInformation.companyPhoneNumber ?? ""
                  }
                  placeholder="Phone Number"
                  control={untypedControl}
                  name={name}
                  value={value}
                  onChange={onChange}
                  country="US"
                />
              )}
            />
          </FormControl>
          <FormControl>
            {showAddPhone ? (
              <>
                <Controller
                  control={control}
                  name="addPhone"
                  rules={{
                    required: false,
                    validate: validatePhoneNumber,
                  }}
                  render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                  }) => (
                    <PhoneInput
                      size="lg"
                      placeholder="Phone Number"
                      control={untypedControl}
                      name={name}
                      value={value}
                      onChange={onChange}
                      country="US"
                    />
                  )}
                />
              </>
            ) : (
              <Button
                layerStyle="black"
                color="white"
                textTransform="none"
                onClick={() => setShowAddPhone(true)}
              >
                + Add business phone number
              </Button>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>*Address</FormLabel>
            <CarrierPaymentsAddressField
              label="Payment Billing Address"
              formHook={addressFormHook}
              index={0}
              isReadOnly={false}
            />
          </FormControl>
          <FormControl>
            {showAddAddress ? (
              <CarrierPaymentsAddressField
                label="Business Address"
                formHook={businessAddressFormHook}
                index={0}
                isReadOnly={false}
              />
            ) : (
              <Button
                layerStyle="black"
                color="white"
                textTransform="none"
                onClick={() => setShowAddAddress(true)}
              >
                + Add business address
              </Button>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Company Name</FormLabel>
            <Text>{carrierUser.companyName}</Text>
          </FormControl>
          <FormControl>
            <FormLabel>*Business Type</FormLabel>
            <Box w="50%">
              <Select
                size="lg"
                {...register("businessType", {
                  required: {
                    value: true,
                    message: "You must enter your business type",
                  },
                })}
              >
                {(
                  Object.keys(BusinessTypeOption) as BusinessTypeOptionKey[]
                ).map((key) => (
                  <option key={key} value={key}>
                    {BusinessTypeOption[key]}
                  </option>
                ))}
              </Select>
            </Box>
          </FormControl>
          <FormControl>
            {watchBusinessType === "SOLEPROP" ? (
              <>
                <FormLabel>*Social Security Number (SSN)</FormLabel>
                <Input
                  size="lg"
                  w="auto"
                  placeholder="SSN"
                  maxLength={9}
                  {...register("ssn", {
                    required: {
                      value: true,
                      message: "You must enter your ssn",
                    },
                    validate: () =>
                      /\D/.test(getValues("ssn"))
                        ? "SSN must be a valid ssn number"
                        : true,
                  })}
                />
              </>
            ) : (
              <>
                <FormLabel>*Taxpayer Identification Number (TIN)</FormLabel>
                <Input
                  size="lg"
                  w="auto"
                  placeholder="TIN"
                  maxLength={9}
                  {...register("tin", {
                    required: {
                      value: true,
                      message: "You must enter your tin",
                    },
                    validate: () =>
                      /\D/.test(getValues("tin"))
                        ? "TIN must be a valid tin number"
                        : true,
                  })}
                />
              </>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Business Website</FormLabel>
            <Input
              size="lg"
              w="auto"
              defaultValue={carrierUser.companyInformation.website ?? ""}
              {...register("website")}
            />
          </FormControl>
        </VStack>
      </Box>
      <Box p="30px 40px" bg="#fff" borderRadius="5px" marginTop="8">
        <Heading as="h2" marginBottom="5">
          Payment information
        </Heading>
        <VStack spacing={8} align="flex-start">
          <Image src={PaymentsSampleCheck} />
          <FormControl>
            <HStack>
              <div>
                <FormLabel>*Account number</FormLabel>
                <Input
                  size="lg"
                  maxLength={9}
                  {...register("accountNumber", {
                    required: {
                      value: true,
                      message:
                        "ACH Account Number must be a valid account number",
                    },
                    validate: () =>
                      /\D/.test(getValues("accountNumber"))
                        ? "ACH Account Number must be a valid account number"
                        : getValues("accountNumber") ===
                          getValues("verifyAccountNumber")
                        ? true
                        : "Account numbers must match",
                  })}
                  placeholder="9 digit account number"
                />
              </div>
              <div>
                <FormLabel>*Re-type Account number</FormLabel>
                <Input
                  size="lg"
                  maxLength={9}
                  {...register("verifyAccountNumber")}
                />
              </div>
            </HStack>
          </FormControl>
          <FormControl>
            <FormLabel>*Routing number</FormLabel>
            <Input
              size="lg"
              width="auto"
              maxLength={9}
              {...register("routingNumber", {
                required: {
                  value: true,
                  message: "ACH Routing Number must be a valid routing number",
                },
                validate: () =>
                  /\D/.test(getValues("routingNumber"))
                    ? "ACH Routing Number must be a valid routing number"
                    : true,
              })}
              placeholder="9 digit routing number"
            />
          </FormControl>
        </VStack>
      </Box>
      <VStack marginTop="5" marginBottom="5">
        <Text
          textAlign="center"
          size="xSmall"
          w="70%"
          color="#7d7d7d"
          marginBottom="4"
        >
          By clicking Set-Up Payment Account, you agree to the{" "}
          <TermsLinks links={formData.terms_and_conditions_links} />.
        </Text>
        <Button layerStyle="yellow" textTransform="none" onClick={onSubmit}>
          Add payment account <ChevronRightIcon />
        </Button>
      </VStack>
      <ErrorMessage errors={formState.errors} clearErrors={clearErrors} />
    </form>
  );
};

export default TilledOnboardingForm;
