import { Document, Page, View, Text } from "@react-pdf/renderer";
import { parseDateToLocal } from "../../../reuse/Dates";
import { EquipmentType } from "../../../types/Equipment";
import { TenderDocumentData } from "./TenderPDFModal";
import { styles } from "./styles";
import TenderPDFFooter from "./TenderPDFFooter";

export interface TenderPDFProps extends TenderDocumentData {}

export const TenderPDF = ({
  carrierData,
  shipmentData,
}: TenderPDFProps): JSX.Element => {
  // Bill To Variables
  const { companyName, companyPhoneNumber, companyEmail, website } =
    shipmentData.shipper.contactInformation;
  const { address1, city, state, country, postalCode } =
    shipmentData.shipper.accountsReceivable;
  const billsectionHeader = `Bill to: ${companyName}`;
  const columnAValuesBillTo = [companyPhoneNumber, companyEmail, website];
  const columnBValuesBillTo = [
    address1,
    `${city}, ${state}`,
    `${country}, ${postalCode}`,
  ];

  // Pay To Variables
  const { contactName, contactEmail, contactPhoneNumber } =
    carrierData.companyContacts;
  const payToHeader = `Pay to: ${contactName}`;
  const columnAValuesPayTo = [
    contactEmail,
    contactPhoneNumber,
    carrierData.companyInformation.website,
  ];

  // Shipment Information
  const {
    equipmentType,
    materialTypes,
    refrigerationTemperature,
    shipmentTotalMiles,
    weight,
  } = shipmentData;

  const shipmentInfoHeaders = [
    "SHIPMENT INFORMATION",
    `TOTAL MILES: ${shipmentTotalMiles} TOTAL WEIGHT: ${weight}`,
  ];
  const columnAValuesShipmentInfo = [
    `Equipment: ${equipmentType}`,
    `Hazmat: ${materialTypes?.join(", ")}`,
  ];

  if (equipmentType === EquipmentType.Refrigerated)
    columnAValuesShipmentInfo.push(`Temperature: ${refrigerationTemperature}`);

  const renderMainHeaderSection = (): JSX.Element => {
    return (
      <View style={styles.mainHeaderContainer}>
        <View style={styles.mainHeader}>
          <Text>{parseDateToLocal(new Date())}</Text>
          <Text style={styles.mainHeaderText}>
            Rate Confirmation #{shipmentData.externalShipmentId}
          </Text>
        </View>
        <View style={styles.mainHeaderBoxesContainer}>
          <View style={styles.brokerInfoBox}>
            <Text>{carrierData.companyContacts?.contactName}</Text>
            <Text>{carrierData.accountsPayable?.addresses?.[0]?.address1}</Text>
            <Text>{carrierData.accountsPayable?.addresses?.[0]?.address2}</Text>
            <Text>{carrierData.accountsPayable?.addresses?.[0]?.city}</Text>
            <Text>{carrierData.accountsPayable?.addresses?.[0]?.state}</Text>
            <Text>
              {carrierData.accountsPayable?.addresses?.[0]?.postalCode}
            </Text>
          </View>
          <View style={styles.logoBox}>ADD LOGO</View>
        </View>
      </View>
    );
  };

  const renderBoxSection = (
    headers: string[],
    columnAValues?: string[],
    columnBValues?: string[],
    key?: string,
  ): JSX.Element => {
    return (
      <View style={styles.boxWrapper} key={key}>
        <View style={styles.boxHeadersWrapper}>
          {headers.map((header) => (
            <Text style={styles.boxHeader} key={header}>
              {header}
            </Text>
          ))}
        </View>
        <View style={styles.boxColumnsWrapper}>
          <View style={styles.boxColumn}>
            {columnAValues?.map((value) => (
              <Text key={value}>{value}</Text>
            ))}
          </View>
          <View style={styles.boxColumn}>
            {columnBValues?.map((value) => (
              <Text key={value}>{value}</Text>
            ))}
          </View>
        </View>
      </View>
    );
  };

  const renderPkgUnitTable = (): JSX.Element => (
    <View style={styles.pkgUnitTableContainer}>
      <View style={styles.pkgUnitTableHeaders}>
        <Text style={styles.pkgUnitTableCell}>HU</Text>
        <Text style={styles.pkgUnitTableCell}>HU TYPE</Text>
      </View>
      <View style={styles.pkgUnitTableData}>
        <Text style={styles.pkgUnitTableCell}>
          {shipmentData.unitCount ?? 0}
        </Text>
        <Text style={styles.pkgUnitTableCell}>{shipmentData.unitType}</Text>
      </View>
    </View>
  );

  const renderShipmentStopsSection = (): JSX.Element[] => {
    return shipmentData.shipmentStops.map((stop, i) => {
      const {
        stopType,
        address: { address1, address2, city, state, country, postalCode },
        notes,
        appointmentType,
        appointmentTime,
        date,
      } = stop;

      const stopHeaders = [
        `STOP ${i + 1}: ${stopType}`,
        `${city}/${state}`,
        `${parseDateToLocal(date)}`,
      ];
      const columnAValuesStop = [
        `${address1} ${address2}`,
        `${city}, ${state}`,
        `${country}/${postalCode}`,
        `${notes}`,
        `Appointment: ${appointmentType} ${appointmentTime}`,
      ];

      return (
        <View key={i} style={styles.stopContainer}>
          {renderBoxSection(stopHeaders, columnAValuesStop, [], i.toString())}
          {renderPkgUnitTable()}
        </View>
      );
    });
  };

  const renderBillToSection = (): JSX.Element =>
    renderBoxSection(
      [billsectionHeader],
      columnAValuesBillTo,
      columnBValuesBillTo,
    );

  const renderPayToSection = (): JSX.Element =>
    renderBoxSection([payToHeader], columnAValuesPayTo);

  const renderShipmentInfoSection = (): JSX.Element =>
    renderBoxSection(shipmentInfoHeaders, columnAValuesShipmentInfo);

  return (
    <Document>
      <Page size="A3" style={styles.page}>
        {renderMainHeaderSection()}
        <View style={styles.mainContainer}>
          {renderBillToSection()}
          {renderPayToSection()}
        </View>
        {renderShipmentInfoSection()}
        {renderShipmentStopsSection()}
        <TenderPDFFooter
          companyEmail={companyEmail}
          companyName={companyName}
          contactInformation={shipmentData.shipper.contactInformation}
          contactName={contactName}
          externalShipmentId={shipmentData.externalShipmentId!}
          insuranceRequirements={shipmentData.shipper.insuranceRequirements}
          shipmentStops={shipmentData.shipmentStops}
        />
      </Page>
    </Document>
  );
};

export default TenderPDF;
