import { SearchIcon } from "@chakra-ui/icons";
import {
  HStack,
  Text,
  Select,
  InputLeftElement,
  InputGroup,
  Input,
} from "@chakra-ui/react";
import React from "react";
import "../../styles/LoadBoardSubhead.scss";
import { LoadSortOptions } from "../../types/Load";
interface LoadBoardSubheadProps {
  totalDisplayedLoads: number | undefined;
  sortBy: LoadSortOptions | undefined;
  setSortBy: React.Dispatch<React.SetStateAction<LoadSortOptions | undefined>>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

export const LoadBoardSubhead = ({
  totalDisplayedLoads,
  sortBy,
  setSortBy,
  searchTerm,
  setSearchTerm,
}: LoadBoardSubheadProps): JSX.Element => {
  return (
    <HStack
      w="100%"
      h="60px"
      backgroundColor="grey6"
      padding="1rem 1rem"
      justify="space-between"
      minW="740px"
    >
      <div className="subHeadLeft">
        <div className="totalDisplayedLoadsContainer">
          <Text color="gray70">
            Showing {totalDisplayedLoads} load
            {totalDisplayedLoads !== 1 && "s"}
          </Text>
        </div>
        <div className="sortContainer">
          <Text color="gray70" mr="4px">
            {" "}
            Sort By:{" "}
          </Text>
          <Select
            backgroundColor="inherit"
            maxWidth="150px"
            value={sortBy}
            onChange={(e) => {
              setSortBy(e?.target?.value as LoadSortOptions | undefined);
            }}
            variant="unstyled"
          >
            <option value={""}>Select</option>
            {Object.values(LoadSortOptions).map((option, index) => (
              <option key={`load board sort select:${option}`} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <div className="searchContainer">
        <InputGroup w="100%" borderRadius="8px">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.400" />
          </InputLeftElement>
          <Input
            type="number"
            placeholder="Search by Load ID"
            borderRadius="4px"
            borderColor="grey5"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target?.value);
            }}
          />
        </InputGroup>
      </div>
    </HStack>
  );
};

export default LoadBoardSubhead;
