import { Th } from "@chakra-ui/react";

export const CellHeader = ({
  color,
  data,
}: {
  color?: string;
  data: string | number | undefined;
}): JSX.Element => {
  return (
    <Th textAlign="center" color={color}>
      {data}
    </Th>
  );
};
