import { NetworkPartner } from "../types/NetworkPartner";
import {
  HeaderProps,
  IRfp,
  IRFPAddCarrier,
  ModalCarriersProps,
  TabElementProps,
} from "../types/Rfp";
import { UserType } from "../types/User";

export const tabList: TabElementProps[] = [
  {
    id: 0,
    name: "LANES",
    types: [UserType.SHIPPER, UserType.BROKER, UserType.CARRIER],
  },
  { id: 1, name: "CARRIERS", types: [UserType.SHIPPER, UserType.BROKER] },
  {
    id: 2,
    name: "DOCS",
    types: [UserType.SHIPPER, UserType.BROKER, UserType.CARRIER],
  },
  { id: 3, name: "WATERFALL", types: [UserType.SHIPPER, UserType.BROKER] },
  {
    id: 4,
    name: "ANALYTICS",
    types: [UserType.SHIPPER, UserType.BROKER, UserType.CARRIER],
  },
];

export const headers: HeaderProps[] = [
  { id: 1, header: "Carrier Name" },
  { id: 2, header: "DOT#" },
  { id: 3, header: "MC#" },
  { id: 4, header: "Email Address" },
  { id: 5, header: "Minimum" },
  { id: 6, header: "Effective Bid" },
  { id: 7, header: "Actual Bid" },
  { id: 8, header: "Volume" },
];

export const headerBids: HeaderProps[] = [
  { id: 1, header: "Carrier Name" },
  { id: 2, header: "DOT#" },
  { id: 3, header: "MC#" },
  { id: 4, header: "Email Address" },
  { id: 5, header: "Minimum" },
  { id: 6, header: "Effective Bid" },
];

export const createCarriers = (data: NetworkPartner[]): IRFPAddCarrier[] => {
  return data!.map((element) => ({
    carrierId: element.fmcsaCarrier?.carrierId ?? null,
    fmcsaCarrierId: element.fmcsaCarrier?.id,
  }));
};

export const mapNetworkCarriers = (
  list: NetworkPartner[],
): ModalCarriersProps[] => {
  return list?.map((element) => {
    return {
      dotNumber: element.fmcsaCarrier!.DOTNumber!,
      carrierName: element.fmcsaCarrier!.legalName!,
    };
  });
};

export const mapReadyToAddCarriers = (
  list: IRFPAddCarrier[],
): ModalCarriersProps[] => {
  return list?.map((element) => {
    return {
      dotNumber: element.dotNumber!,
      carrierName: element.carrierName!,
    };
  });
};

export const rfpIsInBiddingPeriod = (rfp: IRfp): boolean => {
  const now = new Date().getTime();
  const startBidding = new Date(rfp.biddingStartDate).getTime();
  const endBidding = new Date(rfp.biddingEndDate).getTime();

  return now > startBidding && now < endBidding;
};
