import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";

interface SigninData {
  message: string;
}
interface SignInSerializedError extends SerializedError {
  data?: SigninData;
}

const isSigninError = (
  err: FetchBaseQueryError | SerializedError | SignInSerializedError,
): err is SignInSerializedError => true;

export const parseErrorResponse = (
  error: FetchBaseQueryError | SerializedError | SignInSerializedError,
): string => {
  if ("data" in error && error.data) {
    if (typeof error.data === "string") return error.data;
    if (typeof error.data === "object") {
      const data = error.data as Record<string, unknown>;
      if ("message" in data && typeof data.message === "string")
        return data.message;
    }
  } else if ("message" in error && typeof error.message === "string") {
    return error.message;
  } else if (isSigninError(error)) return error.data!.message;
  return "Something went wrong.";
};

export const parseSigninErrorResponse = (
  error: FetchBaseQueryError | SerializedError | SignInSerializedError,
): string => {
  return parseErrorResponse(error).includes(
    "duplicate key value violates unique constraint",
  )
    ? "Email address is already in use"
    : parseErrorResponse(error);
};
