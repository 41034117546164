import { HStack, VStack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { getShipmentDates } from "../../reuse/Shipment";
import { Shipment, ShipmentStatus } from "../../types/Shipment";
import { ButtonGridButtons } from "../ButtonGrid/ButtonGrid";
import DeliveredPendingButton from "../DeliveredPendingButton";
import ShipmentStopsOrBidsQuantity from "../ShipmentStopsOrBidsQuantity";
import {
  AdditionalInfo,
  ShipmentFirstLeg,
  ShipmentMainData,
  InfoButton,
} from "./components";
import PendingCancelButton from "./components/PendingCancelButton";

interface ShipmentCardProps {
  shipment: Shipment;
  counterValue: number;
  valueColor: string;
  onClick: () => void;
  backgroundStyle?: Record<string, never> | undefined | string;
  showTrackingInfoButton?: boolean;
  buttons?: ButtonGridButtons[];
}

const ShipperShipmentCard = ({
  shipment,
  counterValue,
  valueColor,
  onClick,
  backgroundStyle,
  showTrackingInfoButton,
  buttons,
}: ShipmentCardProps): JSX.Element => {
  const [showTrackingInfo, setShowTrackingInfo] = useState<boolean>(false);

  return (
    <VStack
      onClick={onClick}
      backgroundColor={backgroundStyle}
      style={{ marginTop: 0 }}
      w="inherit"
    >
      <VStack
        alignItems="start"
        justifyContent="center"
        px="20px"
        py="0.25rem"
        h="fit-content"
        w="100%"
        layerStyle="shadow"
        spacing="0.1rem"
        cursor="pointer"
      >
        <HStack minH="32px" w="100%" justifyContent="space-between">
          <ShipmentFirstLeg shipment={shipment} />
          <ShipmentStopsOrBidsQuantity
            value={counterValue}
            color={valueColor}
          />
        </HStack>
        {shipment.shipmentStops.length > 2 ? (
          <Text textStyle="card" fontSize="16px" color="mvmntRed">
            {`+ ${shipment.shipmentStops.length - 2} ${
              shipment.shipmentStops.length - 2 > 1 ? "stops" : "stop"
            }`}
          </Text>
        ) : null}
        <Text textStyle="card">{getShipmentDates(shipment)}</Text>
        <ShipmentMainData shipment={shipment} />
        <HStack
          w="100%"
          h="25px"
          justifyContent={"space-between"}
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          <InfoButton
            setShowTrackingInfo={setShowTrackingInfo}
            showTrackingInfo={showTrackingInfo}
            showTrackingInfoButton={showTrackingInfoButton!}
          />
          {shipment.status === ShipmentStatus.CancelPending && (
            <PendingCancelButton shipment={shipment} />
          )}
          {shipment.status === ShipmentStatus.DeliveredPending && (
            <DeliveredPendingButton shipment={shipment} />
          )}
        </HStack>
      </VStack>
      <AdditionalInfo
        shipment={shipment}
        showTrackingInfo={showTrackingInfo}
        buttons={buttons}
      />
    </VStack>
  );
};

export default ShipperShipmentCard;
