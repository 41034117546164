import { Icon } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

interface SortButtonProps {
  sort?: () => void;
  asc: boolean;
}

export const SortButton = ({ asc, sort }: SortButtonProps): JSX.Element => {
  return (
    <Icon
      cursor="pointer"
      fontSize={18}
      as={asc ? ChevronUpIcon : ChevronDownIcon}
      onClick={sort}
    />
  );
};

export default SortButton;
