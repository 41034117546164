import { Skeleton } from "@chakra-ui/react";
import { useGetCarrierActiveRfpQuery } from "../../api/rfp";
import ActiveRfp from "../ActiveRfp";

export const CarrierActiveRFP = (): JSX.Element => {
  const { data, isFetching } = useGetCarrierActiveRfpQuery();

  return (
    <Skeleton isLoaded={!isFetching} w="100%" h="100%">
      <ActiveRfp data={data} />
    </Skeleton>
  );
};

export default CarrierActiveRFP;
