import { Text, VStack } from "@chakra-ui/react";
import { LaneCreateBulkResult } from "../../types/LaneUpload";

export const UploadSummary = ({
  misFormattedRows,
  duplicated,
  alreadyAdded,
  readyToAdd,
}: LaneCreateBulkResult): JSX.Element => {
  return (
    <VStack
      alignItems="start"
      maxH="30vh"
      overflowY="auto"
      layerStyle="cardShadow"
      p="2%"
      mb="2%"
    >
      {misFormattedRows.length > 0 && (
        <>
          <Text>
            Please review the template. The following row numbers are not
            formatted correctly:
          </Text>
          <Text>{misFormattedRows.join(", ")}</Text>
        </>
      )}
      {duplicated.length > 0 && (
        <>
          <Text>
            Please upload only one row per lane. The following records will not
            be uploaded:
          </Text>
          <Text>{duplicated.join(", ")}</Text>
        </>
      )}
      {alreadyAdded.length > 0 && (
        <>
          <Text>
            The following are existing lanes. Overwrite existing data?
          </Text>
          {alreadyAdded.map((lane) => (
            <Text key={lane.laneReferenceNumber}>
              Origin: {lane.laneStops[0].stopCity} -{" "}
              {lane.laneStops[0].stopState} - {lane.laneStops[0].stopPostalCode}
              Destination: {lane.laneStops[1].stopCity} -{" "}
              {lane.laneStops[1].stopState} - {lane.laneStops[1].stopPostalCode}{" "}
              Volume: {lane.volume}
            </Text>
          ))}
        </>
      )}
      {readyToAdd.length > 0 && (
        <>
          <Text>The following lanes will be added to your network:</Text>
          {readyToAdd.map((lane) => (
            <Text key={lane.laneReferenceNumber}>
              Origin: {lane.laneStops[0].stopCity} -{" "}
              {lane.laneStops[0].stopState} - {lane.laneStops[0].stopPostalCode}
              Destination: {lane.laneStops[1].stopCity} -{" "}
              {lane.laneStops[1].stopState} - {lane.laneStops[1].stopPostalCode}{" "}
              Volume: {lane.volume}
            </Text>
          ))}
        </>
      )}
    </VStack>
  );
};

export default UploadSummary;
