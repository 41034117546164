import { HStack, Stack, Text, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { Bid } from "../../../types/Bid";
import InfoLogo from "../../../resources/svg/info.svg";
import BidsGroup from "../BidsGroup";
import { useQuoteRequestsContext } from "../QuoteRequestsContext";
import { BidCarrierInfo } from "../../../api/carrier";
import { isRegularBid } from "../../../reuse/Bids";
import { useBidContext } from "../BidCard/BidContext";

interface InfoSectionProps {
  bid: Bid;
  marketBids?: Bid[];
  isShowingCards: boolean;
}

const MarketplaceInfoSection = (
  marketBids: Bid[],
  isShowingCards: boolean,
): JSX.Element => {
  const context = useBidContext();
  const { onClickToggle } = context!;
  return (
    <HStack
      w="100%"
      justifyContent="space-between"
      mt={isShowingCards ? 4 : 0}
      mb={isShowingCards ? 4 : 0}
    >
      <Stack direction="row" w="15%" />
      <Text textColor="#707070" fontSize={18}>
        {`${marketBids.length} Providers Bidding`}
      </Text>
      <Button
        bgColor="#232323"
        fontSize={12}
        textColor="white"
        w={100}
        h={8}
        style={{ marginRight: "6px" }}
        onClick={onClickToggle}
      >
        {isShowingCards ? "Close" : "View"}
      </Button>
    </HStack>
  );
};

const RatingText = (text: string): JSX.Element => (
  <Text fontSize="2xl" textColor="#707070">
    {text}
  </Text>
);

const BidInfoSection = (bid: Bid, onClickInfo: () => void): JSX.Element => {
  const bidContext = useBidContext();
  const { onClickSetup, onClickTender } = bidContext!;

  const quoteReqContext = useQuoteRequestsContext();
  const { selectedShipment } = quoteReqContext!;

  const isSetup = (b: Bid): boolean => !isRegularBid(b);

  const isSetupCallback = (): void =>
    isSetup(bid) ? onClickSetup() : onClickTender();

  return (
    <HStack w="100%" justifyContent="space-between">
      <HStack ml={2}>
        <Button
          bgImg={InfoLogo}
          bgPos="center"
          bgRepeat="no-repeat"
          onClick={onClickInfo}
        />
      </HStack>
      {bid.connectionStatus && (
        <HStack justifyContent="space-between">
          {RatingText(`Response: ${bid.responseRate ?? 0}%`)}
          {RatingText(`Acceptance: ${bid.acceptanceRate ?? 0}%`)}
          {RatingText(`Win: ${bid.winRate ?? 0}%`)}
        </HStack>
      )}
      <Button
        size="small"
        w={100}
        h={8}
        style={{ marginRight: "6px" }}
        layerStyle="yellow"
        onClick={isSetupCallback}
        disabled={
          selectedShipment?.winningBidId !== undefined ||
          selectedShipment?.tendered
        }
      >
        {isSetup(bid) ? "Setup" : "Tender"}
      </Button>
    </HStack>
  );
};

const InfoSection = ({
  bid,
  marketBids,
  isShowingCards,
}: InfoSectionProps): JSX.Element => {
  const context = useQuoteRequestsContext();
  const { setBidCarrierInfo, setIsInfoModalOpen } = context!;

  const onClickInfo = (): void => {
    const carrierInfo: BidCarrierInfo = bid.carrier;
    setBidCarrierInfo(carrierInfo);
    setIsInfoModalOpen(true);
  };

  return (
    <VStack
      h={isShowingCards ? "fit-content" : "100%"}
      w="100%"
      bgColor={isShowingCards ? "#EDF6FF" : undefined}
      margin={isShowingCards ? "0 !important" : "2% 0 2% 0 !important"}
    >
      {marketBids
        ? MarketplaceInfoSection(marketBids, isShowingCards)
        : BidInfoSection(bid, onClickInfo)}
      {isShowingCards && marketBids ? (
        <VStack display="flex" flexWrap="wrap-reverse">
          <BidsGroup bids={marketBids} />
        </VStack>
      ) : null}
    </VStack>
  );
};

export default InfoSection;
