import { ComponentType, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { setCurrentUserToken } from "../../app/authSlice";
import AppRoutes from "../../routes";

enum AuthState {
  pending,
  authenticated,
  unauthenticated,
}

export function AuthenticatedPage<T>(Component: ComponentType<T>) {
  return function WrapComponent(props: T): JSX.Element | null {
    const [isAuth, setIsAuth] = useState<AuthState>(AuthState.pending);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          const userSession = user.getSignInUserSession();
          const accessToken = userSession.getIdToken();
          const jwt = accessToken.getJwtToken();
          dispatch(setCurrentUserToken(jwt));
          setIsAuth(AuthState.authenticated);
        })
        .catch(() => {
          setIsAuth(AuthState.unauthenticated);
        });
    }, []);

    switch (isAuth) {
      case AuthState.authenticated:
        return <Component {...props} />;
      case AuthState.pending:
        return null;
      case AuthState.unauthenticated:
        navigate(AppRoutes.LOGIN);
        return null;
    }
  };
}

export default AuthenticatedPage;
