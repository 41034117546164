import { Heading, HStack, VStack } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { getStopsSize, setBackgroundStyle } from "../../reuse/Shipment";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { useTrackingContext } from "../../pages/Tracking/TrackingContext";
import { useDispatchDriverMutation } from "../../api/driver";
import {
  DriverFormMode,
  DriverStatus,
  Shipment,
  ShipmentStatus,
} from "../../types/Shipment";
import { ButtonGridButtons } from "../ButtonGrid/ButtonGrid";
import ShipperShipmentCard from "../ShipperShipmentCard";
import useToastHook from "../useToastHook";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { UserType } from "../../types/User";
import CarrierInfoModal from "../CarrierInfoModal";
import DriverInfoModal from "../DriverInfoModal";
import MarkDeliveredModal from "../MarkDeliveredModal";
import { useState } from "react";

interface ActiveJobsProps {
  trackingShipments: Shipment[] | undefined;
}

export const TrackingSidebar = ({
  trackingShipments,
}: ActiveJobsProps): JSX.Element => {
  const toast = useToastHook();
  const trackingContext = useTrackingContext();
  const {
    currentTrackingShipment,
    setCurrentTrackingShipment,
    setShowDispatchModal,
    setShowLocationModal,
    setShowDriverModal,
    setDriverFormMode,
    showContactInfo,
    setShowContactInfo,
    showDriverInfo,
    setShowDriverInfo,
  } = trackingContext!;

  const shipmentExists = trackingShipments! && trackingShipments.length > 0;

  const onAssignDriver = (): void => {
    setDriverFormMode?.(DriverFormMode.CREATE);
    setShowDriverModal?.(true);
  };
  const onUpdateDriver = (): void => {
    setDriverFormMode?.(DriverFormMode.EDIT);
    setShowDriverModal?.(true);
  };
  const onProvideTrackingUpdate = (): void => setShowLocationModal?.(true);

  const [shipmentToConfirmDelivery, setShipmentToConfirmDelivery] = useState<
    Shipment | undefined
  >(undefined);

  const [dispatchDriver] = useDispatchDriverMutation();

  const onDispatchDriver = async (shipment: Shipment): Promise<void> => {
    const { id } = shipment;
    const response = await dispatchDriver(id);

    if ("data" in response) {
      setShowDispatchModal?.(true);
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const canDispatchDriver = (shipment: Shipment): boolean => {
    return !!(
      shipment.assignedDriver &&
      shipment.assignedDriver.driverStatus === DriverStatus.Assigned
    );
  };
  const canProvideTrackingUpdate = (shipment: Shipment): boolean => {
    return !!(
      shipment.assignedDriver &&
      shipment.assignedDriver.driverStatus === DriverStatus.Dispatched
    );
  };

  const driverBtnText = (shipment: Shipment): string => {
    const returnValue = canProvideTrackingUpdate(shipment)
      ? "Provide Tracking Update"
      : shipment.assignedDriver
      ? "Update Driver"
      : "Assign Driver";
    return returnValue;
  };

  const driverBtnOnClick = (shipment: Shipment): void => {
    return canProvideTrackingUpdate(shipment)
      ? onProvideTrackingUpdate()
      : shipment.assignedDriver
      ? onUpdateDriver()
      : onAssignDriver();
  };

  const { type } = useAppSelector(selectUserData);
  const getButtons = (shipment: Shipment): ButtonGridButtons[] =>
    type === UserType.CARRIER
      ? getButtonsCarrier(shipment)
      : getButtonsShipper(shipment);

  const getButtonsCarrier = (shipment: Shipment): ButtonGridButtons[] => {
    return [
      // {
      //   color: "yellow",
      //   text: "Contact Shipper",
      //   onClick: () => onClickContactShipper(),
      // },
      {
        color: "red",
        text: () => driverBtnText(shipment),
        onClick: () => driverBtnOnClick(shipment),
      },
      {
        color: "red",
        text: "Dispatch Driver",
        onClick: async () => await onDispatchDriver(shipment),
        condition: () => canDispatchDriver(shipment),
      },
      {
        color: "red",
        text: "Mark As Delivered",
        onClick: () => setShipmentToConfirmDelivery(shipment),
        condition: () =>
          shipment.assignedDriver?.driverStatus === DriverStatus.Dispatched &&
          shipment.status === ShipmentStatus.TrackingShipment,
      },
    ];
  };

  const getButtonsShipper = (shipment: Shipment): ButtonGridButtons[] => {
    return [
      {
        color: "yellow",
        text: "Contact Carrier",
        fontSize: "xs",
        onClick: () => setShowContactInfo?.(true),
      },
      {
        color: "yellow",
        text: "Driver Details",
        fontSize: "xs",
        onClick: () => setShowDriverInfo?.(true),
      },
      // {
      //   color: "red",
      //   text: "Mark As Delivered",
      //   onClick: () => setShipmentToConfirmDelivery(shipment),
      //   condition: () =>
      //     shipment.assignedDriver?.driverStatus === DriverStatus.Dispatched,
      // },
      // {
      //   color: "red",
      //   text: "Send Ping",
      //   fontSize: "xs",
      // },
    ];
  };

  const getButtonsContactModal = (): ButtonGridButtons[] => {
    return [
      {
        color: "white",
        text: "Close",
        minW: "120px",
        h: "30px",
        fontSize: 14,
        textColor: "mvmntRed",
        onClick: () => setShowContactInfo?.(false),
      },
      // {
      //   color: "mvmntRed",
      //   text: "Chat",
      //   minW: "120px",
      //   h: "30px",
      //   fontSize: 12,
      //   textColor: "white",
      //   onClick: () => onClickChat(),
      // },
    ];
  };

  const renderContactInfoModal = (): JSX.Element => {
    if (!currentTrackingShipment?.winningBidId && currentTrackingShipment) {
      console.error(
        "Invalid tracking shipment, no winning bid id found on " +
          currentTrackingShipment.id,
      );
    }
    return showContactInfo ? (
      <CarrierInfoModal
        isOpen={showContactInfo}
        onClose={() => setShowContactInfo?.(false)}
        buttons={getButtonsContactModal()}
        carrier={
          currentTrackingShipment!.bids!.find(
            (x) => x.id === currentTrackingShipment!.winningBidId,
          )!.carrier
        }
        columns={2}
      />
    ) : (
      <></>
    );
  };

  const renderDriverInfoModal = (): JSX.Element => {
    return showDriverInfo ? (
      <DriverInfoModal
        isOpen={showDriverInfo}
        onClose={() => setShowDriverInfo?.(false)}
        driver={currentTrackingShipment!.assignedDriver}
        shipmentDocs={currentTrackingShipment!.shipmentDocuments}
        location={currentTrackingShipment!.shipmentLocations?.[0]}
      />
    ) : (
      <></>
    );
  };

  return (
    <Skeleton minW="20%" maxW="26%" h="100%" isLoaded>
      <VStack bgColor="white !important" h="100%">
        <HStack
          layerStyle="yellow"
          minH="32px"
          w="100%"
          justifyContent="start"
          px="15px"
        >
          <Heading size="md" textStyle="uppercase">
            Job Details
          </Heading>
          <Heading ml="auto !important" size="md">
            {trackingShipments?.length ?? 0}
          </Heading>
        </HStack>
        <VStack w="100%" overflowY="auto" style={{ marginTop: 0 }}>
          {shipmentExists &&
            trackingShipments.map((shipment) => (
              <ShipperShipmentCard
                shipment={shipment}
                key={shipment.id}
                onClick={() => setCurrentTrackingShipment(shipment)}
                backgroundStyle={setBackgroundStyle(
                  shipment.id,
                  currentTrackingShipment?.id,
                )}
                showTrackingInfoButton
                counterValue={getStopsSize(shipment)}
                valueColor="white"
                buttons={getButtons(shipment)}
              />
            ))}
        </VStack>
        <MarkDeliveredModal
          isOpen={shipmentToConfirmDelivery !== undefined}
          onClose={() => setShipmentToConfirmDelivery(undefined)}
          title="Mark As Delivered"
          shipmentId={currentTrackingShipment?.id}
        />
      </VStack>
      {renderContactInfoModal()}
      {renderDriverInfoModal()}
    </Skeleton>
  );
};

export default TrackingSidebar;
