import {
  HStack,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Input,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useState, useRef, useEffect } from "react";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { FormError } from "../../types/FormError";
import { OneMegaByte } from "../../reuse/Files";
import {
  useAssignDriverMutation,
  useUpdateDriverMutation,
} from "../../api/driver";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import useToastHook from "../useToastHook";
import {
  generateAssignDriver,
  initialFileData,
  initialIdDocs,
  initializeDocuments,
  getUpdateFileId,
} from "./reuse";
import {
  DocumentDriver,
  DriverFormMode,
  DriverInfo,
} from "../../types/Shipment";
import DriverDocument from "../DriverDocument";
import {
  useDeleteTrackingDocumentMutation,
  useUploadDriverDocumentMutation,
  useUploadS3DriverFileMutation,
} from "../../api/documents";
import { ShipmentDocumentType } from "../../types/Files";
import { useTrackingContext } from "../../pages/Tracking/TrackingContext";

interface ModalDriverProps {
  title: string;
  openDriverForm: boolean;
  shipmentId: string | undefined;
  onClose: () => void;
}

export const DriverModal = ({
  title,
  openDriverForm,
  shipmentId,
  onClose,
}: ModalDriverProps): JSX.Element => {
  const toast = useToastHook();
  const trackingContext = useTrackingContext();
  const {
    setShowDriverModal,
    driverFormMode,
    currentTrackingShipment,
    setDriverFormMode,
  } = trackingContext!;

  const fileHazmatInputRef = useRef<HTMLInputElement | null>(null);
  const fileOverdimensionalInputRef = useRef<HTMLInputElement | null>(null);
  const fileOtherInputRef = useRef<HTMLInputElement | null>(null);

  const [fileData, setFileData] = useState<DocumentDriver>(initialFileData);
  const [idDocs, setIdDocs] = useState<DocumentDriver>(initialIdDocs);

  const [handlingType, setHandlingTpye] = useState<string>("");

  const [assignDriver, { isLoading: isSubmiting }] = useAssignDriverMutation();
  const [updateDriver, { isLoading: isUpdating }] = useUpdateDriverMutation();
  const [uploadDoc, { isLoading: isUploading }] =
    useUploadDriverDocumentMutation();
  const [uploadS3Doc, { isLoading: isUploadingS3 }] =
    useUploadS3DriverFileMutation();

  const [deleteTrackingDoc, { isLoading: isDeleting }] =
    useDeleteTrackingDocumentMutation();

  const formHook = useForm<DriverInfo>();
  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = formHook;

  const determinateDocumentType = (fileType: string): ShipmentDocumentType => {
    return fileType === "hazmat"
      ? ShipmentDocumentType.HazmatCertificate
      : fileType === "overdimensional"
      ? ShipmentDocumentType.OverdimensionalPermit
      : ShipmentDocumentType.OtherPermit;
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files![0];
    const { name, type, size } = file;
    if (size >= 100 * OneMegaByte) {
      alert("File size is too large.");
      return;
    }
    setFileData({ ...fileData, [event.target.name]: name });
    const fileType = event.target.name;
    const documentType = determinateDocumentType(fileType);
    setHandlingTpye(event.target.name);

    const idDoc = getUpdateFileId(event, idDocs);

    if (DriverFormMode.EDIT === driverFormMode && idDoc !== "") {
      const responseDelete = await deleteTrackingDoc(idDoc!);
      if ("error" in responseDelete) {
        toast.error({
          description: parseErrorResponse(responseDelete.error),
        });
        setFileData(initialFileData);
        setHandlingTpye("");
        return;
      }
    }

    const documentUpload = await uploadDoc({
      id: shipmentId!,
      fileName: name,
      contentType: type,
      documentType,
    });
    if ("data" in documentUpload) {
      await uploadS3Doc({ url: documentUpload.data, file });
      toast.success({
        description: "Document uploaded successfully !",
      });
    } else {
      toast.error({
        description: parseErrorResponse(documentUpload.error),
      });
    }
  };

  const closeAndInit = (): void => {
    reset();
    setDriverFormMode?.(DriverFormMode.CREATE);
    setFileData(initialFileData);
    setHandlingTpye("");
    setShowDriverModal?.(false);
  };

  const onSubmit = handleSubmit(async (): Promise<void> => {
    const values = getValues();
    const assignData = generateAssignDriver(shipmentId!, values);

    const response =
      driverFormMode === DriverFormMode.CREATE
        ? await assignDriver(assignData)
        : await updateDriver({
            body: assignData,
            id: currentTrackingShipment!.id,
          });

    if ("data" in response) {
      toast.success({
        description: "Driver assigned",
      });
      reset();
      setDriverFormMode?.(DriverFormMode.CREATE);
      onClose();
    } else {
      toast.error({
        description: parseErrorResponse(response.error),
      });
    }
  });

  const initializeDriver = (assignedDriver: DriverInfo): void => {
    for (const key in assignedDriver) {
      setValue(
        key as keyof DriverInfo,
        assignedDriver[key as keyof DriverInfo],
      );
    }
  };

  const proccesingFile: boolean = isUploading || isUploadingS3 || isDeleting;
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    if (driverFormMode === DriverFormMode.EDIT) {
      const assignedDriver = currentTrackingShipment!.assignedDriver;
      const shipmentDocuments = currentTrackingShipment!.shipmentDocuments;
      if (shipmentDocuments && shipmentDocuments.length !== 0) {
        const { fileData, idDocs } = initializeDocuments(shipmentDocuments);
        setFileData(fileData);
        setIdDocs(idDocs);
      }
      initializeDriver(assignedDriver!);
    }

    return () => {
      mounted.current = false;
    };
  }, [driverFormMode]);

  return (
    <Modal
      isOpen={openDriverForm}
      onClose={onClose}
      size={"2xl"}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent w="100%">
        <form onSubmit={onSubmit}>
          <ModalHeader textStyle="uppercase">{title}</ModalHeader>
          <ModalBody>
            <HStack justifyContent="space-evenly" mb="1rem">
              <Input
                type="text"
                placeholder="First Name"
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "You must enter a first name",
                  },
                })}
              />
              <Input
                type="text"
                placeholder="Last Name"
                {...register("lastName", {
                  required: {
                    value: true,
                    message: "You must enter a last name",
                  },
                })}
              />
            </HStack>
            <HStack justifyContent="space-evenly">
              <Input
                type="number"
                placeholder="Phone Number"
                {...register("phone", {
                  required: {
                    value: true,
                    message: "You must enter a phone number",
                  },
                })}
              />
              <Input
                type="email"
                placeholder="Email"
                {...register("email", {
                  required: {
                    value: false,
                    message: "You must enter an email",
                  },
                })}
              />
            </HStack>
            <DriverDocument
              title="UPLOAD HAZMAT CERTIFIFATE"
              name="hazmat"
              reference={fileHazmatInputRef}
              value={fileData.hazmat}
              handleFileInput={handleFileInput}
              proccesingDocument={proccesingFile && handlingType === "hazmat"}
            />
            <DriverDocument
              title="UPLOAD OVERDIMEMSIONAL PERMIT"
              name="overdimensional"
              reference={fileOverdimensionalInputRef}
              value={fileData.overdimensional}
              handleFileInput={handleFileInput}
              proccesingDocument={
                proccesingFile && handlingType === "overdimensional"
              }
            />
            <DriverDocument
              title="UPLOAD OTHER PERMIT"
              name="other"
              reference={fileOtherInputRef}
              value={fileData.other}
              handleFileInput={handleFileInput}
              proccesingDocument={proccesingFile && handlingType === "other"}
            />
          </ModalBody>
          <ErrorMessage
            errors={errors as FormError}
            clearErrors={clearErrors}
          />
          <ModalFooter mb="0.6rem">
            <HStack w="100%" justifyContent={"space-between"}>
              <Button
                ml="20px"
                size="sm"
                color="mvmntRed"
                variant="link"
                onClick={closeAndInit}
              >
                CANCEL
              </Button>
              {isSubmiting || isUpdating ? (
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Spinner color="mvmntRed" />
                </Flex>
              ) : (
                <Button layerStyle="yellow" px="2rem" type="submit">
                  {driverFormMode === DriverFormMode.EDIT
                    ? "UPDATE DRIVER"
                    : "ASSIGN DRIVER"}
                </Button>
              )}
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default DriverModal;
