import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  Skeleton,
  ModalBody,
  Checkbox,
} from "@chakra-ui/react";
import { useState } from "react";
import { useConfirmAgreementMutation } from "../../api/network";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { DefaultModalProps } from "../../types/DefaultModal";
import useToastHook from "../../components/useToastHook";

interface ShipperCarrierAgreementProps extends DefaultModalProps {
  shipperId: string;
  agreementLink: string;
}

export const ShipperCarrierAgreement = ({
  isOpen,
  onClose,
  shipperId,
  agreementLink,
}: ShipperCarrierAgreementProps): JSX.Element => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [confirmAgreement, { isLoading }] = useConfirmAgreementMutation();
  const toast = useToastHook();

  const onClickAgree = async (): Promise<void> => {
    const response = await confirmAgreement(shipperId);
    if ("data" in response) {
      toast.success({ description: "Network Partnership confirmed" });
      onClose();
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <Skeleton isLoaded={!isLoading} w="100%">
          <ModalHeader textStyle="uppercase">
            Shipper Carrier Agreement
          </ModalHeader>
          <ModalBody>
            <Button
              variant="link"
              textDecor="underline"
              onClick={() => window.open(agreementLink, "_blank")}
              mb="2vh"
            >
              Click to view agreement
            </Button>
            <Checkbox
              borderColor="mvmntRed"
              isChecked={isAgreed}
              onChange={(e) => setIsAgreed(e.target.checked)}
            >
              I have read and agreed to the terms of the document above
            </Checkbox>
          </ModalBody>
          <ModalFooter justifyContent="space-around">
            <Button
              layerStyle="red"
              onClick={onClickAgree}
              disabled={!isAgreed}
            >
              Add to Network
            </Button>
            <Button size="sm" color="mvmntRed" variant="link" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Skeleton>
      </ModalContent>
    </Modal>
  );
};

export default ShipperCarrierAgreement;
