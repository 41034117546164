import {
  Divider,
  HStack,
  VStack,
  Text,
  Button,
  Skeleton,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useState } from "react";
import { useGetShipperCarrierAgreementQuery } from "../../api/documents";
import {
  useDeleteCarrierFromNetworkMutation,
  useSendReminderMutation,
} from "../../api/network";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { DocumentType } from "../../types/Files";
import { ConnectionStatus, NetworkPartner } from "../../types/NetworkPartner";
import ChevronIcon from "../ChevronIcon";
import ConfirmationModal from "../ConfirmationModal";
import ConnectionStatusAlert from "../ConnectionStatusAlert";
import DocumentUpload from "../DocumentUpload";
import SelectAgreement from "../SelectAgreement";
import useToastHook from "../../components/useToastHook";

export const ShipperPartnerDetail = ({
  id: networkId,
  carrier,
  connectionStatus,
  agreement,
}: NetworkPartner): JSX.Element => {
  const [isDeleteOpen, toggleDeleteModal] = useState(false);
  const [isUploadOpen, toggleUploadModal] = useState(false);
  const [isSelectOpen, toggleSelectModal] = useState(false);
  const [deletePartner, { isLoading: isDeleting }] =
    useDeleteCarrierFromNetworkMutation();
  const [sendReminder, { isLoading: isReminding }] = useSendReminderMutation();
  const { data: agreementDocument, isFetching } =
    useGetShipperCarrierAgreementQuery(
      agreement ? { networkId, documentId: agreement.id } : skipToken,
    );
  const toast = useToastHook();

  const {
    id: carrierId,
    companyName,
    companyContacts,
    companyInformation,
    contactInformation,
  } = carrier!;
  const { contactName, contactPhoneNumber, contactEmail } = companyContacts;
  const { companyMailingAddresses } = contactInformation;
  const { dotNumber, mcNumber } = companyInformation;

  const onRemovePartner = async (): Promise<void> => {
    toggleDeleteModal(false);
    const response = await deletePartner(carrierId);
    if ("data" in response) {
      toast.success({ description: "Partner removed successfully" });
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onClickSendReminder = async (): Promise<void> => {
    const response = await sendReminder(carrierId);
    if ("data" in response) {
      if (response.data) {
        toast.success({
          description: `A new email was sent to ${companyName}`,
        });
      } else {
        toast.warning({ description: "Reminders can only be sent every 24h" });
      }
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onClickUpload = (): void => {
    toggleSelectModal(false);
    toggleUploadModal(true);
  };

  return (
    <Skeleton
      w="100%"
      isLoaded={!isDeleting && !isFetching && !isReminding}
      display="flex"
      justifyContent="center"
    >
      <VStack alignItems="start" w="50%" spacing="3%">
        <HStack mt="4vh">
          <Text textStyle="subTitle">Contact</Text>
          <ChevronIcon />
        </HStack>
        <Divider />
        <HStack w="100%" justifyContent="space-between">
          <Text textStyle="label">Name</Text>
          <Text>{contactName}</Text>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Text textStyle="label">Email</Text>
          <Text>{contactEmail}</Text>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Text textStyle="label">Phone</Text>
          <Text>{contactPhoneNumber}</Text>
        </HStack>
        <Text textStyle="subTitle">{companyName}</Text>
        <Divider />
        <HStack w="100%" justifyContent="space-between">
          <Text textStyle="label">Address</Text>
          <Text>{companyMailingAddresses[0]?.state}</Text>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Text textStyle="label">MC #</Text>
          <Text>{mcNumber}</Text>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Text textStyle="label">DOT #</Text>
          <Text>{dotNumber}</Text>
        </HStack>
        <ConnectionStatusAlert connectionStatus={connectionStatus} />
        <HStack justifyContent="center" w="100%">
          {connectionStatus === ConnectionStatus.PENDING && (
            <Button layerStyle="yellow" onClick={onClickSendReminder}>
              Send reminder
            </Button>
          )}
          {(connectionStatus === ConnectionStatus.SIGNEDOFFLINEUPLOADED ||
            connectionStatus === ConnectionStatus.CONNECTED) && (
            <Button
              layerStyle="yellow"
              onClick={() => window.open(agreementDocument, "_blank")}
            >
              View Agreement
            </Button>
          )}
          {connectionStatus === ConnectionStatus.SIGNEDOFFLINEUPLOADED && (
            <Button layerStyle="yellow" onClick={() => toggleUploadModal(true)}>
              Re Upload Agreement
            </Button>
          )}
          {(connectionStatus === ConnectionStatus.SIGNEDOFFLINE ||
            connectionStatus === ConnectionStatus.NOTSENT) && (
            <Button layerStyle="yellow" onClick={() => toggleSelectModal(true)}>
              Select Agreement
            </Button>
          )}
          <Button layerStyle="red" onClick={() => toggleDeleteModal(true)}>
            Remove Partner
          </Button>
        </HStack>
        <ConfirmationModal
          isOpen={isDeleteOpen}
          onClose={() => toggleDeleteModal(false)}
          onConfirm={onRemovePartner}
          title="Confirm Partner Removal"
          content="You're about to remove a network partner. Are you sure?"
        />
        <DocumentUpload
          isOpen={isUploadOpen}
          onClose={() => toggleUploadModal(false)}
          documentOptions={[
            {
              label: "Shipper-Carrier Agreement",
              value: DocumentType.ShipperAgreement,
            },
          ]}
          networkId={networkId}
        />
        <SelectAgreement
          isOpen={isSelectOpen}
          onClose={() => toggleSelectModal(false)}
          networkId={networkId}
          onClickUpload={onClickUpload}
        />
      </VStack>
    </Skeleton>
  );
};

export default ShipperPartnerDetail;
