import { Fragment } from "react";
import { Text, VStack, HStack, Stack } from "@chakra-ui/react";
import { getDayNameFromDate, parseDateToShortDate } from "../../reuse/Dates";
import { CarrierUser } from "../../types/Carrier";
import { ShipmentCreate } from "../../types/Shipment";
import TenderContentStop from "./TenderContentStop";

interface TenderContentProps {
  carrier: CarrierUser;
  shipment: ShipmentCreate;
}

interface RectangleSectionProps {
  children: JSX.Element | JSX.Element[];
}

const TenderContent = ({
  carrier,
  shipment,
}: TenderContentProps): JSX.Element => {
  const getFormattedDate = (): string =>
    `${getDayNameFromDate(new Date())}, ${parseDateToShortDate(new Date())}`;

  const renderCarrierRefSection = (): JSX.Element => (
    <VStack ml={5}>
      <HStack alignSelf="start" marginTop="2vh !important">
        <Text fontWeight={600} fontSize={11}>
          Date:
        </Text>
        <Text fontWeight={400} fontSize={11}>
          {getFormattedDate()}
        </Text>
      </HStack>
    </VStack>
  );

  const renderCarrierContactSection = (): JSX.Element => (
    <VStack marginRight="20px !important">
      <HStack alignItems="baseline" w="100%">
        <Text fontWeight={600} fontSize={14}>
          Contact
        </Text>
        <Text fontWeight={400} fontSize={11}>
          {carrier.companyContacts.contactName}
        </Text>
      </HStack>
      <HStack alignItems="baseline" w="100%">
        <Text fontWeight={600} fontSize={14}>
          Phone
        </Text>
        <Text fontWeight={400} fontSize={11}>
          {carrier.companyContacts.contactPhoneNumber}
        </Text>
      </HStack>
      <HStack alignItems="baseline" w="100%">
        <Text fontWeight={600} fontSize={14}>
          Email
        </Text>
        <Text fontWeight={400} fontSize={11}>
          {carrier.companyContacts.contactEmail}
        </Text>
      </HStack>
    </VStack>
  );

  const renderManifestSection = (): JSX.Element => (
    <VStack alignItems="start" ml={5} mt={2}>
      <VStack alignItems="start">
        <Text fontWeight={600} fontSize={10} textStyle="uppercase">
          Manifest
        </Text>
      </VStack>
      <HStack alignItems="baseline">
        <Text fontWeight={600} fontSize={11} mt={8} style={{ marginBottom: 6 }}>
          Service:
        </Text>
        <Text fontWeight={400} fontSize={9}>
          {shipment?.unitType}
        </Text>
      </HStack>
    </VStack>
  );

  const renderCompanyInfoSection = (): JSX.Element => (
    <VStack mt={2} alignItems="baseline" w="100%">
      <Text fontWeight={600} fontSize={10} textStyle="uppercase">
        Carrier
      </Text>
      <Text fontWeight={600} fontSize={14}>
        {carrier?.companyName}
      </Text>
      <Text fontWeight={600} fontSize={11} mt={6}>
        {carrier?.contactInformation?.companyBillingAddresses}
      </Text>
    </VStack>
  );

  const renderInsuranceSection = (): JSX.Element => (
    <VStack alignSelf="end">
      <HStack w="100%">
        <Text fontWeight={600} fontSize={9}>
          MCC
        </Text>
        <Text fontWeight={400} fontSize={9}>
          {carrier?.companyInformation?.mcNumber}
        </Text>
      </HStack>
      <HStack w="100%" style={{ marginTop: 0 }}>
        <Text style={{ whiteSpace: "nowrap" }} fontWeight={600} fontSize={9}>
          Cargo Ins
        </Text>
        <Text style={{ whiteSpace: "nowrap" }} fontWeight={400} fontSize={9}>
          {carrier?.coverage?.cargoPolicy}
        </Text>
      </HStack>
    </VStack>
  );

  const RectangleSection = ({
    children,
  }: RectangleSectionProps): JSX.Element => {
    return (
      <HStack
        alignItems="flex-start"
        border="1px solid #232323"
        alignSelf="center"
        w="95%"
        justifyContent="space-between"
      >
        {children}
      </HStack>
    );
  };

  const renderShipmentTotals = (): JSX.Element => (
    <Fragment>
      <VStack w="100%">
        <Text fontWeight={600} fontSize={12} textStyle="uppercase">
          Weight
        </Text>
        <Text fontWeight={600} fontSize={11}>
          {shipment.weight}
        </Text>
      </VStack>
      <VStack w="100%">
        <Text fontWeight={600} fontSize={12} textStyle="uppercase">
          Mileage
        </Text>
        <Text fontWeight={600} fontSize={11}>
          ---
        </Text>
      </VStack>
      <VStack w="100%">
        <Text fontWeight={600} fontSize={12} textStyle="uppercase">
          Value
        </Text>
        <Text fontWeight={600} fontSize={11}>
          {shipment.value}
        </Text>
      </VStack>
    </Fragment>
  );

  const shipmentHasIntermediateStops = ({
    shipmentStops,
  }: ShipmentCreate): boolean => {
    return shipmentStops.length > 2;
  };

  const hasIntermediateStops: boolean = shipmentHasIntermediateStops(shipment);

  return (
    <VStack
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      borderBottomRadius={8}
      alignItems="start"
      justifyContent="space-between"
      w="100%"
    >
      <Text
        fontWeight={600}
        textStyle="uppercase"
        fontSize={14}
        marginLeft={5}
        marginTop={5}
      >
        Rate Confirmation
      </Text>
      <HStack
        justifyContent="space-between"
        alignItems="flex-start"
        width="inherit"
      >
        {renderCarrierRefSection()}
        {renderCarrierContactSection()}
      </HStack>
      <RectangleSection>
        {renderManifestSection()}
        <HStack style={{ marginInlineEnd: 15 }}>
          {renderCompanyInfoSection()}
          {renderInsuranceSection()}
        </HStack>
      </RectangleSection>
      <RectangleSection>{renderShipmentTotals()}</RectangleSection>
      <VStack w="95%" alignSelf="center" alignItems="flex-start">
        <Text fontWeight={600} fontSize={10} textStyle="uppercase">
          Tender Notes
        </Text>
        <Text fontSize={10}>{shipment.tenderNotes}</Text>
      </VStack>
      <VStack w="100%" style={{ marginBottom: 12 }}>
        {shipment.shipmentStops.map((stop, idx) => (
          <Stack
            key={idx}
            borderTop={idx !== 0 ? "1px dashed #232323" : ""}
            paddingTop={4}
            w="100%"
            alignItems="center"
          >
            <TenderContentStop
              stop={stop}
              index={idx}
              accesorials={shipment.accessorials}
              equipmentType={shipment.equipmentType}
              totalWeight={shipment.weight}
              displayStopPosition={hasIntermediateStops}
            />
          </Stack>
        ))}
      </VStack>
    </VStack>
  );
};

export default TenderContent;
