import { HStack, Input } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { borderStyling } from "../../reuse/Forms";
import { ShipmentCreate } from "../../types/Shipment";
import ShipmentAddressField from "../ShipmentAddressField";

export const Address1Row = ({
  formHook,
  index,
  isReadOnly,
}: {
  formHook: UseFormReturn<ShipmentCreate>;
  index: number;
  isReadOnly: boolean;
}): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = formHook;
  const { shipmentStops: shipmentStopsError } = errors;
  return (
    <HStack w="100%" spacing="1%">
      <ShipmentAddressField
        formHook={formHook}
        index={index}
        isReadOnly={isReadOnly}
      />
      ,
      <Input
        w="24%"
        placeholder="Zip"
        {...register(`shipmentStops.${index}.address.postalCode` as const, {
          required: {
            value: true,
            message: "You must enter a postal code",
          },
          validate: (value) =>
            value.length > 0 || "You must enter a postal code",
        })}
        disabled
        style={{
          borderColor: borderStyling(
            shipmentStopsError?.[index]?.address?.postalCode,
          ),
        }}
      />
      <Input
        w="25%"
        placeholder="City"
        {...register(`shipmentStops.${index}.address.city` as const, {
          required: {
            value: true,
            message: "You must enter the city",
          },
        })}
        disabled
        style={{
          borderColor: borderStyling(
            shipmentStopsError?.[index]?.address?.city,
          ),
        }}
      />
    </HStack>
  );
};
