import { HStack, VStack } from "@chakra-ui/react";
import { useState } from "react";
import SourcingSearchForm from "../../components/SourcingSearchForm";
import SharedMap, { MarkerInfo } from "../../components/SharedMap/SharedMap";
import SourcingSearchResults from "../../components/SourcingSearchResults";
import { CarrierSearchResult } from "../../types/Carrier";
import { MapLocations, SourcingSearchContext } from "./SourcingSearchContext";

export const SourcingSearch = (): JSX.Element => {
  const [searchResults, setSearchResults] = useState<CarrierSearchResult[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [locations, setLocations] = useState<MapLocations>();
  const [highlightedCarrier, setHighlightedCarrier] =
    useState<CarrierSearchResult>();
  const [highlightedKma, setHighlightedKma] = useState<string>();
  const [offset, setOffset] = useState(0);

  return (
    <SourcingSearchContext.Provider
      value={{
        searchResults,
        setSearchResults,
        totalCount,
        setTotalCount,
        locations,
        setLocations,
        highlightedCarrier,
        setHighlightedCarrier,
        highlightedKma,
        setHighlightedKma,
        offset,
        setOffset,
      }}
    >
      <HStack h="100%" alignItems="start" spacing={0}>
        <SourcingSearchResults />
        <VStack h="100%" w="100%" bgColor="grey">
          <SourcingSearchForm />
          <SharedMap
            locations={locations}
            kma={highlightedKma}
            marker={highlightedCarrier as MarkerInfo}
          />
        </VStack>
      </HStack>
    </SourcingSearchContext.Provider>
  );
};

export default SourcingSearch;
