import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ManageUserBtns from "../../components/SharedTable/ManageUserBtns";
import { useManageUserContext } from "../../pages/ManageUsers/ManageUserContext";
import { toTitleCase } from "../../reuse/Strings";
import { ShipperStatus, ShipperUser } from "../../types/Shipper";
import { parseDateToLocal } from "../../reuse/Dates";
import { sortDate } from "../../reuse/Sorting";
import SortButton from "../../components/SortButton";
import ReusableBadge from "../../components/ReusableBadge";
import { getBadgeColorForShipperStatus } from "../../reuse/Styles";

export interface AdminManageShippersTableProps {
  shippers: ShipperUser[];
}

export const AdminManageShippersTable = ({
  shippers,
}: AdminManageShippersTableProps): JSX.Element => {
  const context = useManageUserContext();
  const { term, setSelectedShipper, currentStatus } = context!;

  const [filteredData, setFilteredData] = useState<ShipperUser[]>(shippers);
  const [descOrder, setDescOrder] = useState<boolean>(true);

  const sortByDate = (): void => {
    const sort = (a: ShipperUser, b: ShipperUser): number =>
      sortDate(a.createDate!, b.createDate!, descOrder);
    setDescOrder((currentOrder) => !currentOrder);
    setFilteredData([...filteredData].sort(sort));
  };

  useEffect(() => {
    setFilteredData(
      shippers.filter(
        (row) =>
          row.contactInformation.firstName
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          row.contactInformation.lastName
            .toLowerCase()
            .includes(term.toLowerCase()),
      ),
    );
  }, [term]);

  const renderTableRow = (
    tabStatus: ShipperStatus,
    shipper: ShipperUser,
    idx: number,
  ): JSX.Element => (
    <Tr key={`shipper user table row:${idx}`} border="0.8px solid grey">
      <Td
        color="mvmntBlue"
        onClick={() => setSelectedShipper(shipper)}
        cursor="pointer"
      >
        {shipper.contactInformation.firstName}{" "}
        {shipper.contactInformation.lastName}
      </Td>
      <Td>{shipper.contactInformation.email}</Td>
      <Td>{parseDateToLocal(shipper.createDate!)}</Td>
      <Td>{toTitleCase(shipper.userType)}</Td>
      <Td>
        <ReusableBadge<ShipperStatus>
          currentStatus={shipper.status}
          badgeColor={getBadgeColorForShipperStatus(shipper.status)}
        />
      </Td>
      <Td>
        {ShipperStatus.PENDING === shipper.status &&
        ShipperStatus.PENDING === tabStatus ? (
          <ManageUserBtns
            id={shipper.id}
            email={shipper.contactInformation.email}
            userType={shipper.userType}
          />
        ) : (
          <></> // TODO: There are three dots here in the design, not sure what they do yet
        )}
      </Td>
    </Tr>
  );

  return (
    <Table
      variant="unstyled"
      border="0.8px solid grey"
      borderRadius="4"
      overflow="hidden"
      paddingInline={0}
    >
      <Thead bgColor="lightgray" border="0.8px solid grey">
        <Tr border="0.8px solid grey">
          <Th textTransform="uppercase">Name</Th>
          <Th textTransform="uppercase">Email Address</Th>
          <Th textTransform="uppercase" cursor="pointer" onClick={sortByDate}>
            Date Submitted
            <SortButton asc={!descOrder} />
          </Th>
          <Th textTransform="uppercase">Role</Th>
          <Th textTransform="uppercase">Status</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {filteredData.map((shipper, idx) =>
          renderTableRow(currentStatus, shipper, idx),
        )}
      </Tbody>
    </Table>
  );
};

export default AdminManageShippersTable;
