import { useState } from "react";
import { VStack, HStack, Button, Textarea, Text } from "@chakra-ui/react";
import { useCreateLoadCommentMutation } from "../../../../api/load";
import { useNotificationBanner } from "../../../../components/useNotificationBanner";

interface LoadNotesFormProps {
  loadId: string;
}

export const LoadNotesForm = ({ loadId }: LoadNotesFormProps): JSX.Element => {
  const notificationBanner = useNotificationBanner();
  const [createLoadComment] = useCreateLoadCommentMutation();
  const [activeForm, setActiveForm] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");

  const openNoteForm = (): void => setActiveForm((form) => !form);
  const cancel = (): void => {
    setNote("");
    setActiveForm((form) => !form);
  };

  const onSaveNote = async (): Promise<void> => {
    const response = await createLoadComment({
      comment: note,
      loadId,
    });

    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: (
          <p>There was a problem creating your comment. Please try again.</p>
        ),
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>Create comment successful.</p>,
      });
      cancel();
    }
  };

  return (
    <VStack w="100%" px="0 0.5rem" alignItems="flex-start">
      {activeForm ? (
        <VStack w="100%" background="#F6F6F6" p="1rem" alignItems="flex-start">
          <Text variant="Title" fontWeight={600}>
            Add new note
          </Text>
          <Textarea
            w="100%"
            mb="0.8rem"
            placeholder="Add notes here..."
            resize="none"
            maxLength={250}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <HStack justifyContent="flex-start" w="100%">
            <Button
              fontSize={13}
              color="white"
              borderRadius="22px"
              bgColor="gray90"
              p="8px 16px"
              marginRight="8px"
              onClick={onSaveNote}
              isDisabled={note.length === 0}
            >
              Save
            </Button>
            <Button
              fontSize={13}
              color="gray70"
              bgColor="grey6"
              borderRadius="22px"
              p="8px 16px"
              onClick={cancel}
            >
              Cancel
            </Button>
          </HStack>
        </VStack>
      ) : (
        <Button
          fontSize={13}
          color="white"
          borderRadius="22px"
          bgColor="gray90"
          p="8px 16px"
          marginRight="8px"
          onClick={openNoteForm}
        >
          <Text variant="Title" fontSize="16px" fontWeight={600}>
            + Add new note
          </Text>
        </Button>
      )}
    </VStack>
  );
};
