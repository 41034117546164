import { VStack, FormControl, Input, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { validateEmail, validatePhoneNumber } from "../../reuse/UserData";
import { CarrierAccountFormProps } from "../../types/CarrierAccountForm";
import { SalesRates } from "../../types/SalesRates";
import ErrorMessage from "../ErrorMessage";

export const SalesRatesForm = ({
  carrierUser,
  handleUpdate,
  isUpdating,
}: CarrierAccountFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm<SalesRates>();

  useEffect(() => {
    if (carrierUser) {
      reset(carrierUser.salesRates);
    }
  }, [carrierUser]);

  const onSubmit = handleSubmit(
    async (salesRates: SalesRates) => await handleUpdate({ salesRates }),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const untypedControl = control as Control<any>;

  return (
    <form onSubmit={onSubmit}>
      <VStack alignItems="center">
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Sales Contact Name"
            {...register("contactName", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              required: false,
              validate: validatePhoneNumber,
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <PhoneInput
                placeholder="Sales Contact Phone"
                control={untypedControl}
                name={name}
                value={value}
                onChange={onChange}
                country="US"
              />
            )}
          />
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Sales Contact Email"
            {...register("email", {
              required: false,
              validate: validateEmail,
            })}
          ></Input>
        </FormControl>
        <Button
          layerStyle="red"
          w="20%"
          onClick={onSubmit}
          isLoading={isUpdating}
        >
          Update
        </Button>
        <ErrorMessage errors={errors} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default SalesRatesForm;
