import { VStack, FormControl, Input, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { validateEmail, validatePhoneNumber } from "../../reuse/UserData";
import { Addresses } from "../../types/Address";
import { CarrierAccountFormProps } from "../../types/CarrierAccountForm";
import { ContactInformation } from "../../types/ContactInformation";
import { FormError } from "../../types/FormError";
import ErrorMessage from "../ErrorMessage";
import FormAddress from "../FormAddress";

export const ContactInformationForm = ({
  carrierUser,
  handleUpdate,
  isUpdating,
}: CarrierAccountFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm<ContactInformation>();
  const billingFormHook = useForm<Addresses>();
  const mailingFormHook = useForm<Addresses>();

  useEffect(() => {
    if (carrierUser) {
      reset(carrierUser.contactInformation);
      billingFormHook.reset({
        address: carrierUser.contactInformation.companyBillingAddresses,
      });
      mailingFormHook.reset({
        address: carrierUser.contactInformation.companyMailingAddresses,
      });
    }
  }, [carrierUser]);

  const onSubmit = handleSubmit(async (data: ContactInformation) => {
    const billingFormSuccess = await billingFormHook.trigger();
    const mailingFormSuccess = await mailingFormHook.trigger();
    if (!billingFormSuccess || !mailingFormSuccess) return;
    const { address: companyBillingAddresses } = billingFormHook.getValues();
    const { address: companyMailingAddresses } = mailingFormHook.getValues();
    await handleUpdate({
      contactInformation: {
        ...data,
        companyBillingAddresses,
        companyMailingAddresses,
      },
    });
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const untypedControl = control as Control<any>;

  return (
    <form onSubmit={onSubmit}>
      <VStack alignItems="center">
        <FormControl isDisabled={isUpdating}>
          <Controller
            control={control}
            name="companyPhoneNumber"
            rules={{
              required: false,
              validate: validatePhoneNumber,
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <PhoneInput
                placeholder="Company Phone Number"
                control={untypedControl}
                name={name}
                value={value}
                onChange={onChange}
                country="US"
              />
            )}
          />
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Company Email"
            {...register("companyEmail", {
              required: false,
              validate: validateEmail,
            })}
          ></Input>
        </FormControl>
        <FormAddress
          label="Company Billing Address"
          formHook={billingFormHook}
          index={0}
          isReadOnly={false}
        />
        <FormAddress
          label="Company Mailing Address"
          formHook={mailingFormHook}
          index={0}
          isReadOnly={false}
        />
        <FormControl isDisabled={isUpdating}>
          <Controller
            control={control}
            name="poPhoneNumber"
            rules={{
              required: false,
              validate: validatePhoneNumber,
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <PhoneInput
                placeholder="Purchase Orders Phone Number (if applicable)"
                control={untypedControl}
                name={name}
                value={value}
                onChange={onChange}
                country="US"
              />
            )}
          />
        </FormControl>
        <Button
          layerStyle="red"
          w="20%"
          onClick={onSubmit}
          isLoading={isUpdating}
        >
          Update
        </Button>
        <ErrorMessage errors={errors as FormError} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default ContactInformationForm;
