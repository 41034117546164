import { VStack } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { useMemo, useState } from "react";
import Header from "../../components/Header";
import TabsContainer from "../../components/TabsContainer";
import TabAdditionalContent from "./TabAdditionalContent";
import {
  ShipperStatus,
  ShipperStatusLabel,
  ShipperUser,
} from "../../types/Shipper";
import { ManageUserContext } from "./ManageUserContext";
import { useGetAllShipperUsersQuery } from "../../api/shipper";
import AdminShipperAccountPage from "./ShipperAccountSummary/AdminShipperAccountPage";
import AdminManageShippersTable from "./AdminManageShippersTable";

export const ManageUsers = (): JSX.Element => {
  const { data, isLoading } = useGetAllShipperUsersQuery();

  const [statusCellStyle, setStatusCellStyle] = useState<React.CSSProperties>({
    color: "darkergrey",
    backgroundColor: "lightgrey",
  });
  const [currentStatus, setCurrentStatus] = useState<ShipperStatus>(
    ShipperStatus.PENDING,
  );

  const handleClickTab = (title: string): void =>
    setCurrentStatus(title as ShipperStatus);

  const [selectedShipper, setSelectedShipper] = useState<
    ShipperUser | undefined
  >();

  const [term, setTerm] = useState("");

  const setStylingBasedOnStatus = (): void => {
    switch (currentStatus) {
      case ShipperStatus.PENDING:
        setStatusCellStyle({
          color: "darkergrey",
          backgroundColor: "lightgrey",
        });
        return;
      case ShipperStatus.APPROVED:
        setStatusCellStyle({
          color: "mvmntGreen",
          backgroundColor: "lightgreen",
        });
        return;
      default:
        setStatusCellStyle({
          color: "tomato",
          backgroundColor: "pink",
        });
    }
  };

  const PendingTable = useMemo(() => {
    const pendingShippers =
      data?.filter((user) => user.status === ShipperStatus.PENDING) ?? [];
    return <AdminManageShippersTable shippers={pendingShippers} />;
  }, [data]);

  const ApprovedTable = useMemo(() => {
    const approvedShippers =
      data?.filter((user) => user.status === ShipperStatus.APPROVED) ?? [];
    return <AdminManageShippersTable shippers={approvedShippers} />;
  }, [data]);

  const RejectedTable = useMemo(() => {
    const rejectedShippers =
      data?.filter((user) => user.status === ShipperStatus.REJECTED) ?? [];
    return <AdminManageShippersTable shippers={rejectedShippers} />;
  }, [data]);

  const RevokedTable = useMemo(() => {
    const revokedShippers =
      data?.filter((user) => user.status === ShipperStatus.REVOKED) ?? [];
    return <AdminManageShippersTable shippers={revokedShippers} />;
  }, [data]);

  const AllTable = useMemo(() => {
    const allShippers = data ?? [];
    return <AdminManageShippersTable shippers={allShippers} />;
  }, [data]);

  const tabList = [...Object.values(ShipperStatusLabel), "All"];
  const tabPanels = [
    PendingTable,
    ApprovedTable,
    RejectedTable,
    RevokedTable,
    AllTable,
  ];

  return (
    <ManageUserContext.Provider
      value={{
        statusCellStyle,
        setStatusCellStyle,
        currentStatus,
        setCurrentStatus,
        setStylingBasedOnStatus,
        term,
        selectedShipper,
        setSelectedShipper,
      }}
    >
      <Skeleton isLoaded={!isLoading} bgColor="#F6F6F6" w="100%" h="100%">
        {selectedShipper ? (
          <AdminShipperAccountPage />
        ) : (
          <>
            <VStack mt={8}>
              <VStack alignItems="start" w="100%" pl="4rem">
                <Header headingText="Manage Users" />
              </VStack>
              <TabsContainer
                containerBg="white"
                tabList={tabList}
                tabPanels={tabPanels}
                tabPanelHeader={
                  <TabAdditionalContent term={term} setTerm={setTerm} />
                }
                onClickTab={handleClickTab}
              />
            </VStack>
          </>
        )}
      </Skeleton>
    </ManageUserContext.Provider>
  );
};

export default ManageUsers;
