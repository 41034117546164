import { HStack, Text, Image } from "@chakra-ui/react";
import ChevronSourcing from "../../../resources/svg/chevron_sourcing.svg";
import { MainSectionProps } from ".";

const ShipmentFirstLeg = ({ shipment }: MainSectionProps): JSX.Element => (
  <HStack textAlign="center">
    <Text fontSize="20px" color="black">
      {shipment.shipmentStops[0].address.city +
        `, ` +
        shipment.shipmentStops[0].address.state}
    </Text>
    <Image width={4} height={4} src={ChevronSourcing} />
    <Text fontSize="20px" color="black">
      {shipment.shipmentStops[shipment.shipmentStops.length - 1].address.city +
        `, ` +
        shipment.shipmentStops[shipment.shipmentStops.length - 1].address.state}
    </Text>
  </HStack>
);

export default ShipmentFirstLeg;
