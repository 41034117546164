import {
  Skeleton,
  VStack,
  HStack,
  Heading,
  Button,
  Link,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useAddCarrierToNetworkMutation } from "../../api/network";
import { useSourcingSearchContext } from "../../pages/SourcingSearch/SourcingSearchContext";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import {
  createCsvFromObjectsArray,
  mapExportDataFromSearch,
} from "../../reuse/Files";
import ConfirmationModal from "../ConfirmationModal";
import SourcingCard from "../SourcingCard";
import useToastHook from "../../components/useToastHook";

export const SourcingSearchResults = (): JSX.Element => {
  const context = useSourcingSearchContext();
  const results = context?.searchResults;
  const totalCount = context?.totalCount;
  const [selectedCarrier, setSelectedCarrier] = useState<string>();
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  const [addNetworkCarrier, { isLoading: isAdding }] =
    useAddCarrierToNetworkMutation();
  const toast = useToastHook();

  const onAddCarrier = async (): Promise<void> => {
    toggleConfirmationModal(false);
    if (!selectedCarrier) return;
    const response = await addNetworkCarrier(selectedCarrier);
    if ("data" in response) {
      toast.success({ description: "Carrier added successfully" });
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onClickLoadMore = (): void => {
    if (context && results) context.setOffset(results.length);
  };

  const carrierExportData = useMemo(() => {
    return mapExportDataFromSearch(results!);
  }, [results]);

  return (
    <Skeleton minW="20%" h="100%" isLoaded={!isAdding} overflowY="auto">
      <VStack bgColor="white !important" h="100%" spacing={0}>
        <HStack
          layerStyle="yellow"
          minH="32px"
          w="100%"
          justifyContent="space-between"
          px="15px"
        >
          <Heading size="md" textStyle="uppercase">
            Available Carriers
          </Heading>
          <Heading size="md">{totalCount!.toLocaleString()}</Heading>
        </HStack>
        <VStack
          alignItems="start"
          w="100%"
          h="calc(100% - 155px)"
          overflowY="auto"
          spacing={0}
        >
          {results?.map((carrier, index) => (
            <SourcingCard
              key={index}
              carrier={carrier}
              isExpanded={carrier.DOTNumber === selectedCarrier}
              onExpand={() => setSelectedCarrier(carrier.DOTNumber)}
              onClickAdd={() => toggleConfirmationModal(true)}
            />
          ))}
        </VStack>
        <VStack minH="100px" py="1rem" spacing={3}>
          {results && results.length > 0 && (
            <Button
              w="14rem"
              layerStyle="black"
              alignSelf="center"
              onClick={onClickLoadMore}
              size="small"
            >
              Load more
            </Button>
          )}
          {carrierExportData && carrierExportData.length > 0 && (
            <Link
              href={createCsvFromObjectsArray(carrierExportData)}
              download="carrier_search.csv"
            >
              <Button layerStyle="yellow" w="14rem" size="small">
                Export Results
              </Button>
            </Link>
          )}
        </VStack>
      </VStack>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => toggleConfirmationModal(false)}
        onConfirm={onAddCarrier}
        title="Confirm Add to Network"
        content="You're about to add a network partner. Are you sure?"
      />
    </Skeleton>
  );
};

export default SourcingSearchResults;
