import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalBody,
  Input,
  Skeleton,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";
import { DefaultModalProps } from "../../types/DefaultModal";
import {
  LaneCreateBulkResult,
  LaneBidBulkResult,
} from "../../types/LaneUpload";
import UploadSummary from "./UploadSumary";

interface BulkUploadFileProps extends DefaultModalProps {
  title: string;
  extensionFile: string;
  handleFileInput: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<void>;
  onClickUpload?: () => Promise<void>;
  isValidating: boolean;
  isAdding: boolean;
  fileName: string;
  validationResult?: LaneCreateBulkResult | undefined;
  validationResultLane?: LaneCreateBulkResult | undefined;
  validationResultBid?: LaneBidBulkResult | undefined;
}

export const BulkUploadFile = ({
  isOpen,
  onClose,
  title,
  extensionFile,
  confirmBtnText,
  handleFileInput,
  onClickUpload,
  isAdding,
  isValidating,
  fileName,
  validationResult,
  validationResultLane,
  validationResultBid,
}: BulkUploadFileProps): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const alreadyAdded = validationResultLane
    ? validationResultLane.alreadyAdded.length
    : 0;

  const totalAddableLanes =
    validationResultLane &&
    validationResultLane.readyToAdd.length + Number(alreadyAdded);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <Skeleton w="100%" isLoaded={!isValidating && !isAdding}>
          <ModalHeader textStyle="uppercase">{title}</ModalHeader>
          <ModalBody>
            {validationResultLane && (
              <UploadSummary {...validationResultLane} />
            )}
            <Input
              readOnly
              placeholder="Click to select file"
              onClick={() => fileInputRef.current?.click()}
              value={fileName}
            />
            <Input
              accept={extensionFile}
              type="file"
              hidden
              ref={fileInputRef}
              onChange={handleFileInput}
            />
            {validationResultBid && (
              <Text
                textAlign="center"
                w="100%"
                p="1.5rem 2rem"
                fontSize="14px"
                letterSpacing="1.5px"
                fontWeight="bolder"
              >
                VALUES PROVIDED IN THIS FILE WILL OVERRIDE ANY EXISTING BIDS.
                BIDS PROVIDED IN THE FILE WILL SAVE, BUT NOT SUBMIT YOUR BIDS
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <HStack w="100%" justifyContent="flex-start">
              <Button
                layerStyle="red"
                mr="0.5rem"
                onClick={onClickUpload}
                isDisabled={totalAddableLanes === 0}
              >
                {confirmBtnText}
              </Button>
              <Button
                size="sm"
                color="mvmntRed"
                variant="link"
                onClick={onClose}
              >
                Cancel
              </Button>
            </HStack>
          </ModalFooter>
        </Skeleton>
      </ModalContent>
    </Modal>
  );
};

export default BulkUploadFile;
