import {
  Modal,
  ModalContent,
  ModalOverlay,
  Button,
  Text,
  ModalBody,
  HStack,
} from "@chakra-ui/react";
import { DefaultModalProps } from "../../types/DefaultModal";

interface ShortAlertModalProps extends DefaultModalProps {
  content: string;
}

export const ShortAlertModal = ({
  isOpen,
  onClose,
  content,
}: ShortAlertModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <HStack justifyContent="space-around" my="0.5rem">
            <Text>{content}</Text>
            <Button layerStyle="red" onClick={onClose}>
              Close
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShortAlertModal;
