import {
  Skeleton,
  Button,
  Divider,
  Heading,
  HStack,
  VStack,
  Text,
  Select,
  Input,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useDeleteDocumentMutation } from "../../api/documents";
import ConfirmationModal from "../../components/ConfirmationModal";
import DocumentCard from "../../components/DocumentCard";
import DocumentUpload from "../../components/DocumentUpload";
import { IframeModalDocument } from "../../components/IframeModalDocument/IframeModalDocument";
import SortButton from "../../components/SortButton";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { sortText, sortDate } from "../../reuse/Sorting";
import { DataDocProps, DocumentType, MvmntDocument } from "../../types/Files";
import useToastHook from "../../components/useToastHook";

const initialSortValue = {
  documentName: false,
  uploadDate: false,
};

const filterList: Record<string, string> = {
  documentType: "Document Type",
  contentType: "File format",
  networkPartner: "Associated network partner",
  shipmentId: "Associated Shipment ID",
};

interface FilterOption {
  label: string;
  value: string;
}

interface ManageDocumentsProps {
  documents: MvmntDocument[];
  documentTypes: Array<{ label: string; value: DocumentType }>;
}

export const ManageDocuments = ({
  documents,
  documentTypes,
}: ManageDocumentsProps): JSX.Element => {
  const [contentDoc, setContentDoc] = useState<DataDocProps | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [isUploadOpen, toggleUploadOpen] = useState(false);
  const [isDocModalOpen, toggleDocModalOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<null | MvmntDocument>(null);
  const [documentList, setDocuments] = useState<MvmntDocument[]>([]);
  const [sortState, setSortState] = useState(initialSortValue);
  const [filterField, setFilterField] = useState("documentType");
  const [filterValue, setFilterValue] = useState<string>(
    documentTypes[0].value,
  );
  const [deleteDocument, { isLoading }] = useDeleteDocumentMutation();

  const toast = useToastHook();

  const contentTypeOptions = useMemo((): FilterOption[] => {
    const filterOptionsSet = new Set(
      documents.map((document) => document.contentType),
    );
    return Array.from(filterOptionsSet).map((option) => ({
      label: option,
      value: option,
    }));
  }, [documents]);

  const getFilterOptions = (filterField: string): FilterOption[] =>
    filterField === "documentType" ? documentTypes : contentTypeOptions;

  const isDropdownFilter = (filterField: string): boolean =>
    filterField === "documentType" || filterField === "contentType";

  const sortByName = (): void => {
    const sort = (a: MvmntDocument, b: MvmntDocument): number =>
      sortText(a.documentName, b.documentName, sortState.documentName);
    setDocuments([...documentList].sort(sort));
    setSortState({ ...sortState, documentName: !sortState.documentName });
  };

  const sortByDate = (): void => {
    const sort = (a: MvmntDocument, b: MvmntDocument): number =>
      sortDate(a.uploadDate, b.uploadDate, sortState.uploadDate);
    setDocuments([...documentList].sort(sort));
    setSortState({ ...sortState, uploadDate: !sortState.uploadDate });
  };

  const filterDocuments = (): void => {
    const field = filterField as keyof MvmntDocument;
    if (!filterField) return;
    setDocuments([...documents].filter((v) => v[field] === filterValue));
  };

  const onChangeFilter = (filterField: string): void => {
    setFilterField(filterField);
    if (isDropdownFilter(filterField)) {
      const filterOption = getFilterOptions(filterField)[0];
      setFilterValue(filterOption?.value);
    } else {
      setFilterValue("");
    }
  };

  const chooseDocument = (document: MvmntDocument): void => {
    toggleDocModalOpen(true);
    setSelectedDoc(document);
  };

  const closeModalDocument = (): void => {
    toggleDocModalOpen(false);
    setSelectedDoc(null);
  };

  const onConfirmRemoveDocument = async (): Promise<void> => {
    if (selectedDoc) {
      toggleDocModalOpen(false);
      const response = await deleteDocument(selectedDoc.id);
      if ("data" in response) {
        toast.success({ description: "Document removed successfully" });
      } else {
        toast.error({ description: `${parseErrorResponse(response.error)}` });
      }
      setSelectedDoc(null);
    }
  };

  const clearOptions = (): void => {
    setDocuments(documents);
    setFilterField("documentType");
    setFilterValue(documentTypes[0].value);
    setSortState(initialSortValue);
  };

  const getNameAndExtension = (doc: string): DataDocProps => {
    const splitFullName: string[] = doc.split(".");
    const name: string = splitFullName[0];
    const extension: string = splitFullName.pop()!;
    return { name, extension };
  };

  const openSignedDocument = (documentName: string, urlDoc: string): void => {
    const { name, extension } = getNameAndExtension(documentName);
    setContentDoc({ name, extension, url: urlDoc });
    setOpenModal(true);
  };

  const onCloseDocument = (): void => {
    setContentDoc(null);
    setOpenModal(false);
  };

  useEffect(() => {
    setDocuments(documents);
  }, [documents]);

  return (
    <>
      <VStack
        bgColor="realWhite"
        m="2%"
        alignItems="start"
        h="90vh"
        layerStyle="cardShadow"
      >
        {/* Manage Documents Filters & Buttons */}
        <HStack p="1%" w="100%" justifyContent="space-between">
          <Heading textStyle="uppercase" fontSize="xl">
            Manage Documents
          </Heading>
          <HStack>
            <HStack>
              <Text>Sort by Name</Text>
              <SortButton sort={sortByName} asc={sortState.documentName} />
            </HStack>
            <HStack>
              <Text>Sort by Date</Text>
              <SortButton sort={sortByDate} asc={sortState.uploadDate} />
            </HStack>
          </HStack>
          <HStack>
            <Text>Filter on</Text>
            <Select
              w="15vw"
              onChange={(e) => onChangeFilter(e.target.value)}
              value={filterField}
            >
              {Object.entries(filterList).map((filter) => (
                <option value={filter[0]} key={filter[0]}>
                  {filter[1]}
                </option>
              ))}
            </Select>
            <Text>Filter by</Text>
            {isDropdownFilter(filterField) ? (
              <Select
                w="20vw"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              >
                {getFilterOptions(filterField).map((filterOption) => (
                  <option key={filterOption.value} value={filterOption.value}>
                    {filterOption.label}
                  </option>
                ))}
              </Select>
            ) : (
              <Input
                w="20vw"
                placeholder={`Filter by ${filterList[filterField] ?? "..."}`}
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            )}
            <Button size="small" layerStyle="yellow" onClick={filterDocuments}>
              Filter
            </Button>
            <Button size="small" layerStyle="yellow" onClick={clearOptions}>
              Clear
            </Button>
          </HStack>
          <Button
            size="small"
            layerStyle="red"
            onClick={() => toggleUploadOpen(true)}
          >
            Upload Document
          </Button>
        </HStack>
        <Divider alignSelf="center" w="80%" color="grey" />
        <Skeleton w="100%" isLoaded={!isLoading}>
          <VStack w="100%" overflowY="auto" pb="20px" pt="10px">
            <VStack w="90%">
              {documentList.map((document) => (
                <DocumentCard
                  key={document.id}
                  documentTypes={documentTypes}
                  chooseDocument={() => chooseDocument(document)}
                  openSignedDocument={openSignedDocument}
                  {...document}
                />
              ))}
            </VStack>
          </VStack>
        </Skeleton>
      </VStack>
      <IframeModalDocument
        isOpen={openModal}
        contentDoc={contentDoc!}
        close={onCloseDocument}
      />

      <DocumentUpload
        isOpen={isUploadOpen}
        onClose={() => toggleUploadOpen(false)}
        documentOptions={documentTypes}
      />
      <ConfirmationModal
        isOpen={isDocModalOpen}
        onClose={closeModalDocument}
        onConfirm={onConfirmRemoveDocument}
        title="Document"
        content={`Are you sure you want to delete ${selectedDoc?.documentName} ?`}
      />
    </>
  );
};

export default ManageDocuments;
