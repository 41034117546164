import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalBody,
  Text,
  Select,
  CloseButton,
  FormLabel,
  Input,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useCreateLoadLocationMutation } from "../../../../api/load";
import { useNotificationBanner } from "../../../../components/useNotificationBanner";
import { Countries, states } from "../../../../resources/regions";
import { getDateTimeInputString } from "../../../../reuse/Dates";
import { DefaultModalProps } from "../../../../types/DefaultModal";

interface AddLoadLocationModalProps extends DefaultModalProps {
  loadId: string;
  defaultDate?: Date;
}

export const AddLoadLocationModal = ({
  onClose,
  loadId,
  defaultDate,
}: AddLoadLocationModalProps): JSX.Element => {
  const notificationBanner = useNotificationBanner();
  const [postLocation, { isLoading }] = useCreateLoadLocationMutation();
  const [cityName, setCityName] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [dateTime, setDateTime] = useState<string>(
    defaultDate
      ? getDateTimeInputString(defaultDate)
      : getDateTimeInputString(new Date()),
  );
  useEffect(() => {
    setDateTime(
      defaultDate
        ? getDateTimeInputString(defaultDate)
        : getDateTimeInputString(new Date()),
    );
  }, [defaultDate]);

  const date = new Date();
  const dateAsString = date.toString();
  const timeZoneShortName = date
    .toLocaleTimeString("en-US", { timeZoneName: "short" })
    .split(" ")[2];

  const timezone = dateAsString.match(/\(([^)]+)\)$/);
  const timeZoneLongName = timezone ? timezone[1] : "";

  const onSave = async (): Promise<void> => {
    const response = await postLocation({
      loadId,
      city: cityName,
      state: selectedState,
      timeStamp: new Date(dateTime).toString(),
    });

    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: (
          <p>There was a problem posting this location. Please try again.</p>
        ),
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>Location created successfully.</p>,
      });
      onClose();
    }
  };

  // TODO: future iterations will likely be able to choose country as well
  const stateOptions = states[Countries.US];

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size="xl"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" justifyContent="space-between">
          Provide tracking update{" "}
          <CloseButton onClick={onClose} justifySelf="end" />
        </ModalHeader>
        <ModalBody p="12px">
          <Text
            as="h4"
            color="gray70"
            fontSize={16}
            mb={"10px"}
            fontWeight="600"
          >
            Current Location
          </Text>
          <FormLabel color="gray70" fontSize="13px" textTransform="capitalize">
            City
          </FormLabel>
          <Input
            maxW="320px"
            placeholder="City Name"
            value={cityName}
            onChange={(e) => setCityName(e.target.value)}
          />
          <FormLabel color="gray70" fontSize="13px" textTransform="capitalize">
            State
          </FormLabel>
          <Select
            maxW="320px"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            textAlign="left"
          >
            <option value="">Select State</option>
            {stateOptions.map((state) => (
              <option key={state.name} value={state.name}>
                {state.name}
              </option>
            ))}
          </Select>
          <Text
            as="h4"
            color="gray70"
            fontSize={16}
            margin={"10px 0px"}
            fontWeight="600"
          >
            Time at location
          </Text>
          <HStack mb="5px">
            <Input
              maxW="320px"
              type="datetime-local"
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
            />
            <Text alignSelf="end" color="gray70" fontSize="13px">
              {timeZoneLongName} ({timeZoneShortName})
            </Text>
          </HStack>
          <Text color="gray50" fontSize="11px">
            All updates will be recorded in your local time
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <Button layerStyle="gray" onClick={onClose}>
            Close
          </Button>
          <Button
            ml={5}
            isLoading={isLoading}
            disabled={isLoading}
            layerStyle="yellow"
            onClick={onSave}
          >
            Save update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddLoadLocationModal;
