import { CheckboxGroup, Checkbox, Flex } from "@chakra-ui/react";
import { RegexPoliceProp } from "../../types/Regex";
import { regexPasswordPolices } from "./RegexPasswordPolices";

interface RegexPolicesListProps {
  regexList: RegexPoliceProp[] | [];
  columns: string;
}

export const RegexPolicesList = ({
  regexList,
  columns,
}: RegexPolicesListProps): JSX.Element => {
  const values = regexList.map((reg) => reg.value);

  return (
    <CheckboxGroup colorScheme="green" value={values} defaultValue={[]}>
      <Flex
        display="grid"
        gridTemplateColumns={columns}
        gap="10px"
        color="white"
      >
        {regexPasswordPolices.map((regex) => (
          <Checkbox mx="0.9rem" key={regex.id} value={regex.value}>
            {regex.title}
          </Checkbox>
        ))}
      </Flex>
    </CheckboxGroup>
  );
};

export default RegexPolicesList;
