import { HStack, Text } from "@chakra-ui/react";

interface ShipmentStopsOrBidsQuantityProps {
  color: string;
  value: number;
}

export const ShipmentStopsOrBidsQuantity = ({
  color,
  value,
}: ShipmentStopsOrBidsQuantityProps): JSX.Element => (
  <HStack backgroundColor="yellow" borderRadius={16} w="3rem">
    <Text
      textStyle="card"
      textAlign="center"
      fontSize="20px"
      color={color}
      w="100%"
    >
      {value}
    </Text>
  </HStack>
);

export default ShipmentStopsOrBidsQuantity;
