import { HStack, Button, Text, Flex, Spinner } from "@chakra-ui/react";
import { useLazyGetDriverDocumentsUrlQuery } from "../../api/documents";
import { exportFileResults } from "../../reuse/Files";
import { IShipmentDocument } from "../../types/ShipmentDocument";
import useToastHook from "../useToastHook";

interface DeliveredDocumentProps {
  document: IShipmentDocument;
}

export const DeliveredDocument = ({
  document,
}: DeliveredDocumentProps): JSX.Element => {
  const toast = useToastHook();
  const [getUrlDocument, { isLoading }] = useLazyGetDriverDocumentsUrlQuery();
  const { id, documentName, documentType } = document;
  const name = documentName.split("/")[2];

  const downloadDocument = async (): Promise<void> => {
    const response = await getUrlDocument(id!);
    if ("error" in response) {
      toast.error({ description: "File not available" });
    } else {
      exportFileResults(response.data!, name);
    }
  };

  return (
    <HStack w="100%" justifyContent="space-between">
      <Text>{documentType}</Text>
      {isLoading ? (
        <Flex w="150px" alignItems="center" justifyContent="center">
          <Spinner color="mvmntRed" size="lg" />
        </Flex>
      ) : (
        <Button
          layerStyle="white"
          textColor="mvmntRed"
          onClick={downloadDocument}
        >
          {name}
        </Button>
      )}
    </HStack>
  );
};

export default DeliveredDocument;
