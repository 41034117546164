import { CarrierUser } from "./Carrier";
import { Invoice } from "./Invoice";

export enum QuickPayRequestStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface QuickPayRequest {
  id: string;
  createDate: Date;
  invoiceId: string;
  invoice: Invoice;
  status: QuickPayRequestStatus;
  decisionDate: Date | null;
  reason: string | null;
  decidingAdmin: string | null;
  tilledPaymentIntentId: string | null;
  carrier?: CarrierUser;
}

export interface TimeProps {
  elapsedTime: number;
  timeRemaining: number;
}

export enum QuickPayToDropdownEnum {
  load_id = "load_id",
  shipper = "shipper",
  carrier = "carrier",
}

export type QuickPayToFilterDropdown = {
  [key in QuickPayToDropdownEnum]: string[];
};

export enum QuickPayToFilterEnum {
  load_id = "load_id",
  shipper = "shipper",
  carrier = "carrier",
  decisionAdmin = "decisionAdmin",
  decisionDate = "decisionDate",
  status = "status",
}

export type QuickPayToFilter = {
  [key in QuickPayToFilterEnum]: string;
};

export type QuickPayToFilterMultiSelect = {
  [key in QuickPayToFilterEnum]: string[];
};

export enum CommomColumns {
  Load_Id = "Load ID",
  Shipper = "Shipper",
  Carrier = "Carrier",
}

export enum RequestedColumns {
  Elapsed_Time = "Elapsed Time",
  Time_Remaining = "Time Remaining",
  Amount = "Amount",
  Action = "",
}

export enum ApprovedColumns {
  Approved_By = "Approved By",
  Approval_Date = "Approval Date",
  Amount = "Amount",
  Invoice_Status = "Invoice Status",
  Action = "",
}

export enum RejectedColumns {
  Rejected_By = "Rejected By",
  Rejection_Date = "Rejection Date",
  Amount = "Amount",
  Reason = "Reason",
}

export interface ColumnProps {
  columns:
    | CommomColumns[]
    | RequestedColumns[]
    | ApprovedColumns[]
    | RejectedColumns[];
}

export interface QuickPayRequestUpdate {
  id: string;
  status: QuickPayInvoiceStatus;
}

export enum QuickPayExtraActions {
  Mark_Not_Paid = "Mark as NOT paid",
  Contact_Shipper = "Contact Shipper",
}

export enum QuickPayInvoiceStatus {
  DELIVERED = "DELIVERED",
  OVERDUE = "OVERDUE",
  NOT_PAID = "NOT PAID",
  SHIPPER_PAID = "PAID",
}

export interface ShipperQuickPayInvoice {
  id: string;
  invoice: Invoice;
  status: QuickPayInvoiceStatus;
}

export interface QuickPayRequestPost {
  quickpayIds: string[];
}

export enum QPResponseEnum {
  rejected = "rejected",
  approved = "approved",
}
