import { Icon, IconProps } from "@chakra-ui/react";

export const PDFIcon = (props: IconProps): JSX.Element => (
  <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M4.6 19H2.2C1.88174 19 1.57652 18.8736 1.35147 18.6485C1.12643 18.4235 1 18.1183 1 17.8V2.2C1 1.88174 1.12643 1.57652 1.35147 1.35147C1.57652 1.12643 1.88174 1 2.2 1H10.7032C10.8609 1.00003 11.017 1.03114 11.1626 1.09154C11.3083 1.15193 11.4406 1.24044 11.552 1.352L16.2488 6.0488C16.3602 6.16028 16.4486 6.29263 16.5088 6.43826C16.5691 6.5839 16.6001 6.73999 16.6 6.8976V10.6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 19V13"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 13H7.6032C8.08059 13 8.53843 13.1896 8.87599 13.5272C9.21356 13.8648 9.4032 14.3226 9.4032 14.8C9.4032 15.2774 9.21356 15.7352 8.87599 16.0728C8.53843 16.4104 8.08059 16.6 7.6032 16.6H7.0032"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7998 19C12.4363 19 13.0468 18.7471 13.4969 18.2971C13.9469 17.847 14.1998 17.2365 14.1998 16.6V15.4C14.1998 14.7635 13.9469 14.153 13.4969 13.7029C13.0468 13.2529 12.4363 13 11.7998 13V19Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5996 19V14.2C16.5996 13.8817 16.726 13.5765 16.9511 13.3515C17.1761 13.1264 17.4813 13 17.7996 13H18.9996"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5996 16.6H18.3996"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
