import React from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  StackProps,
  HStack,
  ProgressProps,
  Progress,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { CloseCircleIcon, PDFIcon } from "../../icons";

interface IFileUploadState extends StackProps, Pick<ProgressProps, "value"> {
  fileName: string;
  onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function FileUploadProgress({
  fileName,
  value,
  onCancel,
  onDelete,
  ...stackProps
}: IFileUploadState): JSX.Element {
  const name = fileName.split("/").pop();

  return (
    <HStack
      bg="gray.100"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="4px"
      padding="20px 10px 20px 20px"
      height="66px"
      {...stackProps}
    >
      <PDFIcon color="gray.400" />
      <Box w="100%">
        <Text color="gray.700" fontSize="13px" fontWeight="bold">
          {name}
        </Text>
        {value && value !== 100 && <Progress value={value} size="xs" />}
      </Box>
      <Box color="gray.600" marginLeft="auto" cursor="pointer">
        {value === 100 ? (
          <IconButton
            aria-label="Delete file"
            icon={<DeleteIcon />}
            onClick={onDelete}
          />
        ) : (
          <IconButton
            aria-label="Cancel file upload"
            icon={<CloseCircleIcon />}
            onClick={onCancel}
          />
        )}
      </Box>
    </HStack>
  );
}
