import { HStack } from "@chakra-ui/react";
import { AbsoluteRightButtonsProps } from "../../types/Rfp";

export const AbsoluteRightButtons = ({
  children,
  top,
  right,
  bottom,
  left,
}: AbsoluteRightButtonsProps): JSX.Element => {
  return (
    <HStack pos="absolute" top={top} right={right} bottom={bottom} left={left}>
      {children}
    </HStack>
  );
};

export default AbsoluteRightButtons;
