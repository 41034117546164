import { VStack, HStack, Text, Button } from "@chakra-ui/react";
import { useSourcingSearchContext } from "../../pages/SourcingSearch/SourcingSearchContext";
import { CarrierSearchResult } from "../../types/Carrier";
import mvmntTheme from "../../styles/theme";
import { phoneFormat } from "../../reuse/Format";

interface SourcingCardProps {
  carrier: CarrierSearchResult;
  onClickAdd: (id: string) => void;
  isExpanded: boolean;
  onExpand: () => void;
}

export const SourcingCard = ({
  carrier,
  onClickAdd,
  isExpanded,
  onExpand,
}: SourcingCardProps): JSX.Element => {
  const context = useSourcingSearchContext();
  const {
    id,
    telephoneNumber,
    legalName,
    mcNumber,
    DOTNumber,
    totalTrucks,
    physicalCity,
    physicalStateCode,
  } = carrier;

  return (
    <VStack
      alignItems="start"
      justifyContent="center"
      px="34px"
      py="0.25rem"
      h="fit-content"
      w="100%"
      layerStyle="shadow"
      spacing="0.1rem"
      onClick={onExpand}
      cursor="pointer"
      onMouseEnter={() => context?.setHighlightedCarrier(carrier)}
      onMouseLeave={() => context?.setHighlightedCarrier()}
      backgroundColor={isExpanded ? mvmntTheme.colors.selectedBlue : ""}
    >
      <Text textStyle="card" fontSize="22px">
        {legalName}
      </Text>
      <Text textStyle="card">
        {physicalCity}, {physicalStateCode}
      </Text>
      <HStack justifyContent="space-between" w="100%">
        <Text textStyle="card"># of Trucks: {totalTrucks}</Text>
        <Text textStyle="card">MC#: {mcNumber}</Text>
      </HStack>
      <HStack justifyContent="space-between" w="100%">
        <Text textStyle="card">{phoneFormat(telephoneNumber ?? "")}</Text>
        <Text textStyle="card">DOT#: {DOTNumber}</Text>
      </HStack>
      {isExpanded && (
        <Button
          layerStyle="red"
          size="xs"
          alignSelf="center"
          onClick={() => onClickAdd(id)}
          my="2vh !important"
        >
          Add to Network
        </Button>
      )}
    </VStack>
  );
};

export default SourcingCard;
