export enum EventLogType {
  UpdateLoadStopNotes = "UpdateLoadStopNotes",
  UpdateLoadStopAppointmentType = "UpdateLoadStopAppointmentType",
  UpdateLoadStopAppointmentTime = "UpdateLoadStopAppointmentTime",
  UpdateLoadStopReceivingName = "UpdateLoadStopReceivingName",
  UpdateLoadStopDate = "UpdateLoadStopDate",
  CreateLoadTrackedLocations = "CreateLoadTrackedLocations",
  UpdateLoadAtRisk = "UpdateLoadAtRisk",
  UpdateLoadHighPriority = "UpdateLoadHighPriority",
}

export const mapEventLogTypeToSentenceFragment = (
  log: IEventLog,
  keyBase: string,
): Array<string | JSX.Element> => {
  switch (log.type) {
    case EventLogType.UpdateLoadStopNotes:
      return ["changed the ", <b key={`${keyBase} bold`}>stop notes</b>];
    case EventLogType.UpdateLoadStopAppointmentType:
      return [
        "changed the ",
        <b key={`${keyBase} bold`}>Drop-Off Appointment Type</b>,
      ];
    case EventLogType.UpdateLoadStopAppointmentTime:
      return [
        "changed the ",
        <b key={`${keyBase} bold`}>stop appointment time</b>,
      ];
    case EventLogType.UpdateLoadStopReceivingName:
      return [
        "changed the ",
        <b key={`${keyBase} bold`}>stop consignee name</b>,
      ];
    case EventLogType.UpdateLoadStopDate:
      return ["changed the ", <b key={`${keyBase} bold`}>stop date</b>];
    case EventLogType.CreateLoadTrackedLocations:
      return ["tracked a new location"];
    case EventLogType.UpdateLoadAtRisk: {
      const flaggedString = log.newValue === "true" ? "flagged" : "unflagged";
      return [`${flaggedString} the load`];
    }
    case EventLogType.UpdateLoadHighPriority: {
      const starredString = log.newValue === "true" ? "starred" : "unstarred";
      return [`${starredString} the load`];
    }
    default:
      return ["updated"];
  }
};

export interface IEventLog {
  id: string;
  createDate: Date;
  userId: string;
  userDisplayName: string;
  sourceObjectId: string;
  newValue: string | null;
  oldValue: string | null;
  type: EventLogType;
}
