import { VStack, HStack } from "@chakra-ui/layout";
import { Textarea, Text } from "@chakra-ui/react";
import SharedButton from "../../../components/SharedButton";
import { getDateDiffInMinutes } from "../../../reuse/Dates";
import { getFirstCapitalLetter } from "../../../reuse/Strings";
import { ManageUserComment } from "../../../types/Admin";
import { useManageUserContext } from "../ManageUserContext";

interface AddCommentProps {
  formComment: ManageUserComment;
  onChangeTextArea: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  isReadonly?: boolean;
  isLoading?: boolean;
}

export const AddCommentForm = ({
  formComment,
  onChangeTextArea,
  onSubmit,
  isReadonly,
  isLoading,
}: AddCommentProps): JSX.Element => {
  const context = useManageUserContext();
  const { selectedShipper } = context!;

  const getShipperInitials = (): string =>
    getFirstCapitalLetter(selectedShipper?.contactInformation.firstName ?? "") +
    getFirstCapitalLetter(selectedShipper?.contactInformation.lastName ?? "");

  const renderComment = (): JSX.Element =>
    isReadonly ? (
      <Text color="grey2">{formComment.commentMsg}</Text>
    ) : (
      <Textarea
        w="200%"
        placeholder="Type your comment here..."
        value={formComment.commentMsg}
        onChange={onChangeTextArea}
        readOnly={isReadonly}
        name="commentMsg"
      />
    );

  const renderCommentFooter = (): JSX.Element =>
    isReadonly ? (
      <Text color="grey3" fontSize="11" textTransform="uppercase">
        {`${getDateDiffInMinutes(
          formComment.createDate ?? new Date(),
          false,
        )} ago`}
      </Text>
    ) : (
      <SharedButton
        textTransform="none"
        title="Add comment"
        layerStyle="blackAndWhite"
        onClick={onSubmit}
        isLoading={isLoading}
        isDisabled={formComment.commentMsg.length < 1}
      />
    );

  return (
    <VStack alignItems="start" mb={8}>
      <form>
        <HStack spacing={4} alignItems="start">
          <SharedButton
            title={getShipperInitials()}
            layerStyle="yellow"
            borderRadius="24px"
            w="44px"
            h="44px"
          />
          <VStack spacing={4} alignItems="start">
            {isReadonly && <Text>{formComment.author}</Text>}
            {renderComment()}
            {renderCommentFooter()}
          </VStack>
        </HStack>
      </form>
    </VStack>
  );
};

export default AddCommentForm;
