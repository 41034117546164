import { useToast } from "@chakra-ui/react";

const DURATION_DEFAULT = 3000;

interface ToastFunction {
  description: string;
  title?: string;
  duration?: number;
}

const useToastHook = (): {
  error: ({ description, title, duration }: ToastFunction) => void;
  warning: ({ description, title, duration }: ToastFunction) => void;
  success: ({ description, title, duration }: ToastFunction) => void;
} => {
  const toast = useToast();

  const error = ({
    description,
    duration = DURATION_DEFAULT,
    title = "Error",
  }: ToastFunction): void => {
    toast({
      status: "error",
      description,
      title,
      duration,
    });
  };

  const warning = ({
    description,
    duration = DURATION_DEFAULT,
    title = "Warning",
  }: ToastFunction): void => {
    toast({
      status: "warning",
      description,
      title,
      duration,
    });
  };

  const success = ({
    description,
    duration = DURATION_DEFAULT,
    title = "Success",
  }: ToastFunction): void => {
    toast({
      status: "success",
      description,
      title,
      duration,
    });
  };

  return { error, warning, success };
};

export default useToastHook;
