import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {},
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  mainHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 6,
  },
  mainHeader: {
    display: "flex",
    flexDirection: "column",
  },
  mainHeaderText: {
    fontSize: 24,
    fontWeight: 700,
  },
  mainHeaderBoxesContainer: {
    display: "flex",
    flexDirection: "row",
  },
  brokerInfoBox: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 24px",
    padding: "5px",
    border: "1px solid",
    borderRadius: 6,
    backgroundColor: "#D3D3D3",
  },
  logoBox: {
    padding: "5px",
    border: "1px solid",
    borderRadius: 6,
    backgroundColor: "#D3D3D3",
  },
  boxWrapper: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid",
    borderRadius: 6,
    margin: "16px 6px",
    flexGrow: 1,
  },
  boxHeadersWrapper: {
    color: "white",
    backgroundColor: "black",
    padding: 6,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  boxHeader: {
    textTransform: "uppercase",
  },
  boxColumnsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 6,
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
  },
  stopContainer: {
    display: "flex",
    flexDirection: "column",
    height: 260,
  },
  pkgUnitTableContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid",
    margin: "0px 5px 50px",
    borderRadius: 6,
  },
  pkgUnitTableHeaders: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid",
  },
  pkgUnitTableData: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
  },
  pkgUnitTableCell: {
    textAlign: "center",
    flex: 1,
  },
  signatureTableContainer: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid",
    borderRadius: 3,
    margin: "24px 0px",
  },
  signatureTableDivider: { border: "1px solid", margin: "0px 6px" },
  signatureTable: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid",
    borderRadius: 3,
    margin: "8px",
    width: "100%",
    fontSize: 14,
    fontWeight: 700,
  },
  signatureTableHeaders: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signatureTableHeader: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid",
    width: "100%",
    minHeight: 70,
    padding: 6,
  },
  signatureTableData: {
    padding: 6,
    minHeight: 85,
  },
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    margin: 12,
  },
  footerText: {
    margin: "20px 0px",
  },
  footerImportantText: {
    fontSize: 20,
    textTransform: "uppercase",
  },
  footerLegend: {
    fontSize: 10,
  },
});
