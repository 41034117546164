import { VStack, Text } from "@chakra-ui/react";
import mvmntTheme from "../../styles/theme";
import { CarrierUser } from "../../types/Carrier";

interface PartnerCardProps {
  onClick: () => void;
  carrier: CarrierUser;
  isSelected: boolean;
}

export const ShipperPartnerCard = ({
  carrier,
  isSelected,
  onClick,
}: PartnerCardProps): JSX.Element => {
  const { contactName, contactPhoneNumber, contactEmail } =
    carrier.companyContacts;
  const { dotNumber } = carrier.companyInformation;

  return (
    <VStack
      alignItems="start"
      justifyContent="center"
      px="34px"
      py="0.25rem"
      h="fit-content"
      w="100%"
      layerStyle="shadow"
      spacing="0.1rem"
      onClick={onClick}
      cursor="pointer"
      backgroundColor={isSelected ? mvmntTheme.colors.selectedBlue : ""}
    >
      <Text textStyle="card" fontSize="22px">
        {carrier.companyName}
      </Text>
      <Text textStyle="card">{contactName}</Text>
      <Text textStyle="card">{contactEmail}</Text>
      <Text textStyle="card">{contactPhoneNumber}</Text>
      <Text textStyle="card">DOT #: {dotNumber}</Text>
    </VStack>
  );
};

export default ShipperPartnerCard;
