import { createContext, useContext } from "react";
import { BidCarrierInfo } from "../../api/carrier";
import { Shipment } from "../../types/Shipment";

interface QuoteRequests {
  selectedShipment: Shipment | undefined;
  setSelectedShipment: React.Dispatch<
    React.SetStateAction<Shipment | undefined>
  >;
  isCreateShipmentModalOpen: boolean;
  toggleCreateShipmentModal: React.Dispatch<React.SetStateAction<boolean>>;
  isInfoModalOpen: boolean;
  setIsInfoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  bidCarrierInfo: BidCarrierInfo | undefined;
  setBidCarrierInfo: React.Dispatch<
    React.SetStateAction<BidCarrierInfo | undefined>
  >;
}

const QuoteRequestsContext = createContext<QuoteRequests | null>(null);

const useQuoteRequestsContext = (): QuoteRequests | null =>
  useContext(QuoteRequestsContext);

export { QuoteRequestsContext, useQuoteRequestsContext };
