import { createApi } from "@reduxjs/toolkit/query/react";
import {
  CarrierSearchResult,
  CarrierSignUp,
  CarrierUser,
} from "../types/Carrier";
import { CompanyContacts } from "../types/CompanyContacts";
import { CompanyInformation } from "../types/CompanyInformation";
import { ContactInformation } from "../types/ContactInformation";
import { FmcsaCarrier } from "../types/FmcsaCarrier";
import { SearchFormData } from "../types/SourcingSearchOptions";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";
import type { Account } from "tilled";
import { QuickPayRequestStatus } from "../types/QuickPayRequest";

export interface BidCarrierInfo {
  companyInformation: CompanyInformation;
  contactInformation: ContactInformation;
  companyName: string;
  companyContacts: CompanyContacts;
}

export type CarrierInvoiceType = "uninvoiced" | "paid" | "unpaid" | "rejected";
interface Address {
  addressCity: string;
  addressState: string;
  addressCountry: string;
  date: string;
}
export interface CarrierInvoice {
  id: string;
  dueDate: string | null;
  shipment: {
    id: string;
    mvmntShipmentId: number;
    shipper: {
      companyName: string;
    };
    winningBid: {
      totalAmount: number;
    };
    shipmentStops: {
      origin: Address;
      destination: Address;
    };
  };
  directPaymentRequest: {
    id: string;
    paidDate: string | null;
    amount: number;
  } | null;
  quickPayRequest: {
    id: string;
    status: QuickPayRequestStatus;
    decisionDate: string | null;
  } | null;
  type: CarrierInvoiceType;
}

interface SourcingCarriersProps {
  fmcsaCarriers: CarrierSearchResult[];
  totalCount: number;
}

export const carrierApi = createApi({
  reducerPath: "carrierApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}`),
  tagTypes: ["Carrier", "CarrierInvoices"],
  endpoints: (builder) => ({
    getCarrier: builder.query<CarrierUser, void>({
      query: () => "/user/carriers",
      providesTags: ["Carrier"],
    }),
    createCarrier: builder.mutation<CarrierUser, CarrierSignUp>({
      query: (body) => ({
        url: "/carriers",
        method: "POST",
        body,
      }),
    }),
    validateDotNumberCarrier: builder.mutation<void, CarrierSignUp>({
      query: (body) => ({
        url: "/carriers/validations",
        method: "POST",
        body,
      }),
    }),
    updateCarrier: builder.mutation<CarrierUser, Partial<CarrierUser>>({
      query: (body) => ({
        url: "/carriers",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Carrier"],
    }),
    dismissCarrierSignUpBanner: builder.mutation<void, void>({
      query: () => ({
        url: "/carriers/dismiss-carrier-sign-up-banner",
        method: "PUT",
      }),
    }),
    getCarrierContact: builder.query<FmcsaCarrier, string>({
      query: (dotNumber) => `/fmcsacarriers/contact?dotNumber=${dotNumber}`,
    }),
    searchCarriers: builder.query<SourcingCarriersProps, SearchFormData>({
      query: (params) => {
        let url = "/carriers?";
        Object.entries(params.singleValue).forEach((input) => {
          if (
            input[1] &&
            (typeof input[1] === "boolean" || input[1].length > 0)
          )
            url += `${input[0]}=${encodeURIComponent(input[1])}&`;
        });
        Object.entries(params.multipleValues).forEach((options) => {
          if (options[1] && options[1].length > 0)
            options[1].forEach((filter) => {
              url += `${options[0]}=${encodeURIComponent(filter)}&`;
            });
        });

        url += `limit=${params.limit.toString()}&`;
        return url.substring(0, url.length - 1);
      },
    }),
    searchExportCarriers: builder.query<SourcingCarriersProps, SearchFormData>({
      query: (params) => {
        let url = "/carriers/export?";
        Object.entries(params.singleValue).forEach((input) => {
          if (
            input[1] &&
            (typeof input[1] === "boolean" || input[1].length > 0)
          )
            url += `${input[0]}=${encodeURIComponent(input[1])}&`;
        });
        Object.entries(params.multipleValues).forEach((options) => {
          if (options[1] && options[1].length > 0)
            options[1].forEach((filter) => {
              url += `${options[0]}=${encodeURIComponent(filter)}&`;
            });
        });

        url += `limit=${params.limit.toString()}&`;
        return url.substring(0, url.length - 1);
      },
    }),
    createTilledCarrier: builder.mutation<string, string>({
      query: (id) => ({
        url: `/carriers/${id}/merchant-create`,
        method: "POST",
      }),
      invalidatesTags: ["Carrier"],
    }),
    getTilledCarrierAccount: builder.query<Account, string>({
      query: (id) => ({
        url: `/carriers/${id}/merchant`,
        method: "GET",
      }),
    }),
    getCarrierInvoices: builder.query({
      query: (id: string) => ({
        url: `/carriers/${id}/invoices`,
        method: "GET",
      }),
      transformResponse(response: CarrierInvoice[], meta, arg) {
        return response.reduce<Record<CarrierInvoiceType, CarrierInvoice[]>>(
          (acc, carrierInvoice) => {
            acc[carrierInvoice.type].push(carrierInvoice);
            return acc;
          },
          {
            paid: [],
            unpaid: [],
            rejected: [],
            uninvoiced: [],
          },
        );
      },
      providesTags: ["CarrierInvoices"],
    }),
    // Somewhat out of place, but RTK is setup incorrectly as we should only have a single
    // API slice: https://redux-toolkit.js.org/tutorials/rtk-query#create-an-api-service
    // Needed to be able to invalidate the cache properly
    // TODO: refactor RTK into single API slice?
    createQuickPayRequest: builder.mutation<void, { invoiceId: string }>({
      query: (body) => {
        return {
          url: "/quickpay",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CarrierInvoices"],
    }),
    createDirectPaymentRequest: builder.mutation<
      void,
      { invoiceId: string; amount: number }
    >({
      query: (body) => {
        return {
          url: `/direct-payment-request`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CarrierInvoices"],
    }),
    updateDirectPaymentToPaid: builder.mutation<void, string>({
      query: (id) => ({
        url: `/direct-payment-request/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["CarrierInvoices"],
    }),
  }),
});

export const {
  useGetCarrierQuery,
  useLazyGetCarrierQuery,
  useCreateCarrierMutation,
  useValidateDotNumberCarrierMutation,
  useUpdateCarrierMutation,
  useDismissCarrierSignUpBannerMutation,
  useLazySearchCarriersQuery,
  useLazySearchExportCarriersQuery,
  useLazyGetCarrierContactQuery,
  useCreateTilledCarrierMutation,
  useLazyGetTilledCarrierAccountQuery,
  useGetCarrierInvoicesQuery,
  useCreateQuickPayRequestMutation,
  useCreateDirectPaymentRequestMutation,
  useUpdateDirectPaymentToPaidMutation,
} = carrierApi;

export const { useQueryState } = carrierApi.endpoints.getCarrier;
