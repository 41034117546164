import { useNavigate } from "react-router-dom";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { useDismissCarrierSignUpBannerMutation } from "../../api/carrier";
import { Link } from "@chakra-ui/react";
import AppRoutes from "../../routes";
import type { CarrierUser } from "../../types/Carrier";

type useCarrierSignUpBannerReturn = (carrier?: CarrierUser) => void;

export const useCarrierSignUpBanner = (): useCarrierSignUpBannerReturn => {
  const notificationBanner = useNotificationBanner();
  const navigate = useNavigate();
  const [dismissCarrierSignUpBanner] = useDismissCarrierSignUpBannerMutation();
  let closedByPaymentsLink = false;

  return function (carrier) {
    if (!carrier || (carrier?.tilledId ?? carrier?.signUpBannerDismissedDate))
      return;
    notificationBanner({
      status: "success",
      onCloseComplete() {
        // avoid persisting the dismissal if the set-up payments link was clicked
        if (closedByPaymentsLink) {
          closedByPaymentsLink = false;
          return;
        }
        void dismissCarrierSignUpBanner();
      },
      description: (
        <div>
          You successfully created an account!{" "}
          <Link
            fontSize="small"
            marginLeft="10px"
            textDecor="underline"
            onClick={() => {
              navigate(AppRoutes.ACCOUNT_DETAILS);
              closedByPaymentsLink = true;
              notificationBanner.closeAll();
            }}
          >
            Set-up payments
          </Link>
        </div>
      ),
      duration: null,
    });
  };
};
