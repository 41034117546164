import { VStack, Text } from "@chakra-ui/react";

export interface SummaryCmpProps {
  text: string;
  children?: () => React.ReactChild | React.ReactChild[];
  array?: Array<string | number>;
}

export const SummaryCmp = ({
  text,
  children,
  array,
}: SummaryCmpProps): JSX.Element => (
  <>
    {array && array.length > 0 && <Text>{text}</Text>}
    {array && array.length > 0 ? <Text>{array.join(", ")}</Text> : children?.()}
  </>
);

export const FooterCmp = (): JSX.Element => (
  <Text textAlign="center">
    UPLOADED FILES MUST FOLLOW THE PROVIDED DATA FORMAT. FOR HEP, PLEASE EMAIL
    SUPPORT@MVMNT.IO.
  </Text>
);

export interface SummaryProps {
  DuplicatedCmp?: () => JSX.Element;
  AlreadyAddedCmp?: () => JSX.Element;
  ReadyToUpdateCmp?: () => JSX.Element;
  ReadyToAddCmp?: () => JSX.Element;
  MisFormattedRowsCmp?: () => JSX.Element;
  NonExistentCmp?: () => JSX.Element;
}

export const ReusableUploadSumary = ({
  DuplicatedCmp,
  AlreadyAddedCmp,
  ReadyToUpdateCmp,
  ReadyToAddCmp,
  MisFormattedRowsCmp,
  NonExistentCmp,
}: SummaryProps): JSX.Element => {
  return (
    <VStack
      alignItems="start"
      maxH="30vh"
      overflowY="auto"
      layerStyle="cardShadow"
      p="2%"
      mb="2%"
    >
      {DuplicatedCmp?.()}
      {AlreadyAddedCmp?.()}
      {ReadyToUpdateCmp?.()}
      {ReadyToAddCmp?.()}
      {MisFormattedRowsCmp?.()}
      {NonExistentCmp?.()}
    </VStack>
  );
};

export default ReusableUploadSumary;
