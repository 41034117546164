import { CarrierSearchResult } from "../types/Carrier";
import { ILane, RowLaneProps, RowShipperLaneProps } from "../types/Rfp";
import { Invoice } from "../types/Invoice";
import { getLaneMinimunBidValues, MinimunBidInfo } from "./Lane";
import { parseDateToLocal } from "./Dates";
import {
  QuickPayRequest,
  QuickPayRequestStatus,
} from "../types/QuickPayRequest";
import { generateData } from "../pages/QuickPayRequest/reuse";
import axios from "axios";
import JSZip from "jszip";
import saveAs from "file-saver";
import invariant from "tiny-invariant";

export const OneMegaByte = 1048576;

export const createWaterfallDataToExport = (
  lanes: RowShipperLaneProps[],
): Array<Record<string, string | number>> => {
  const finalResult: Array<Record<string, string | number>> = [];
  lanes.forEach((lane) => {
    const origin = lane.origin ? lane.origin.split(",") : ["", ""];
    const destiny = lane.destiny ? lane.destiny.split(",") : ["", ""];
    lane.laneCurrentBids?.forEach((bid) => {
      finalResult.push({
        [`LaneID`]: lane.laneId!,
        [`Origin City`]: origin[0],
        [`Origin State`]: origin[1],
        [`Pickup Origin`]: lane.pickupOrigin!,
        [`Destination City`]: destiny[0],
        [`Destination State`]: destiny[1],
        [` Pickup Destination`]: lane.pickupDestiny!,
        Mileage: lane.mileage!,
        Volume: lane.volume!,
        Frequency: lane.frequency!,
        Mode: lane.mode!,
        EquipmentType: lane.equipmentType!,
        Length: lane.length ?? lane.equipmentSize!,
        Carrier: bid.carrierInfo.carrierName ?? "---",
        DOT: bid.carrierInfo.carrierDOTNumber ?? "---",
        MC: bid.carrierInfo.carrierMCNumber ?? "---",
        Email: bid.carrierInfo.carrierEmailAddress ?? "---",
        Minimum: bid.minimumBid ?? "---",
        EfectiveBid: bid.effectiveBid ?? "---",
      });
    });
  });
  return finalResult;
};

export const createCsvFromObjectsArray = function (
  data: Array<Record<string, string | number | undefined>>,
): string {
  const dataWithHeader = [Object.keys(data[0] ?? []), ...data];
  const content = dataWithHeader
    .map((e) => Object.values(e).join(","))
    .join("\n");

  const csvData = new Blob([content], {
    type: "text/csv;charset=utf-8",
  });
  const csvUrl = URL.createObjectURL(csvData);
  return csvUrl;
};

export const mapExportDataFromSearch = (
  results: CarrierSearchResult[],
): Array<Record<string, string | number>> => {
  return results?.map((carrier) => {
    const {
      telephoneNumber,
      legalName,
      mcNumber,
      DOTNumber,
      totalTrucks,
      physicalCity,
      physicalStateCode,
      physicalNationality,
      physicalZipCode,
      physicalStreet,
    } = carrier;
    return {
      mcNumber: mcNumber!,
      dotNumber: DOTNumber,
      numberOfTrucks: totalTrucks!,
      phoneNumber: telephoneNumber!,
      name: legalName!,
      street: physicalStreet!,
      postalCode: physicalZipCode!,
      city: physicalCity!,
      state: physicalStateCode!,
      country: physicalNationality!,
    };
  });
};

export const mapLanesDataToExport = (
  lanes: ILane[],
): Array<Record<string, string | number>> => {
  return lanes?.map((lane: ILane) => {
    const {
      equipmentType,
      equipmentSize,
      frequency,
      mileage,
      mode,
      volume,
      laneStops,
      laneId,
    } = lane;

    const minimunBidInfo: MinimunBidInfo = getLaneMinimunBidValues(
      lane.laneBids,
    )!;

    return {
      laneId: laneId ?? 0,
      origin: `${laneStops?.[0].city ?? ""} - ${laneStops?.[0].state ?? ""}`,
      pickupType: laneStops?.[0].loadType ?? "",
      destination: `${laneStops?.[laneStops.length - 1].city ?? ""} - ${
        laneStops?.[laneStops.length - 1].state ?? ""
      }`,
      destinationType: laneStops?.[1].loadType ?? "",
      mileage: mileage ?? 0,
      volume: volume ?? "",
      frequency: frequency ?? "",
      mode: mode ?? "",
      equipType: equipmentType ?? "",
      length: equipmentSize ?? "",
      rpm: minimunBidInfo?.ratePerMile ?? 0,
      minimunBid: minimunBidInfo?.minimumBid ?? 0,
      volumeCommitment: minimunBidInfo?.volumeCommitment ?? 0,
    };
  });
};

export const exportFileResults = (csvUrl: string, fileName: string): void => {
  const link = document.createElement("a");
  link.href = csvUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const createDataToExport = (
  lanes: RowLaneProps[],
): Array<Record<string, string | number>> => {
  return lanes.map((lane) => {
    const origin = lane.origin ? lane.origin.split(",") : ["", ""];
    const destiny = lane.destiny ? lane.destiny.split(",") : ["", ""];
    return {
      [`LaneID`]: lane.laneId!,
      [`Origin City`]: origin[0],
      [`Origin State`]: origin[1],
      [`Pickup`]: lane.pickupOrigin!,
      [`Destination City`]: destiny[0],
      [`Destination State`]: destiny[1],
      [` Pickup`]: lane.pickupDestiny!,
      Mileage: lane.mileage!,
      Volume: lane.volume!,
      Frequency: lane.frequency!,
      Mode: lane.mode!,
      EquipmentType: lane.equipmentType!,
      Length: lane.equipmentSize!,
      RPM: lane.rpm!,
      Minimum: lane.minimum!,
      Commitment: lane.commitment!,
    };
  });
};

export const createInvoiceDataToExport = (
  invoices: Invoice[],
): Array<Record<string, string | number>> => {
  return invoices.map((invoice) => {
    const deliveryDate = parseDateToLocal(invoice.deliveryDate).split(",");
    const invoiceDate = parseDateToLocal(invoice.invoiceDate).split(",");
    const dueDate = parseDateToLocal(invoice.dueDate).split(",");

    return {
      [`Invoice ID`]: invoice.mvmntInvoiceId!,
      [`Customer`]: invoice.shipper?.contactInformation.companyName,
      [`Delivery Date`]: `${deliveryDate[1]} ${deliveryDate[0]}`,
      [`Invoice Date`]: `${invoiceDate[1]} ${invoiceDate[0]}`,
      [`Due Date`]: `${dueDate[1]} ${dueDate[0]}`,
      [`Amount`]: invoice.invoiceAmount,
      [`Docs`]: invoice.documentName!,
    };
  });
};

export const createQuickPayRequestedToExport = (
  requests: QuickPayRequest[],
): Array<Record<string, string | number>> => {
  return requests.map((request) => {
    const { elapsedTime, timeRemaining } = generateData(request);

    return {
      [`Invoice ID`]: request.invoice.mvmntInvoiceId ?? "",
      [`Shipper`]: request.invoice.shipper.contactInformation.companyName,
      [`Carrier`]: request.carrier!.companyName,
      [`Elapsed Time`]: `${elapsedTime} Hours`,
      [`Time Remaining`]: `${timeRemaining} Hours`,
      [`Amount`]: request.invoice.invoiceAmount,
    };
  });
};

export const createQuickPayDecisionToExport = (
  requests: QuickPayRequest[],
  status: QuickPayRequestStatus,
): Array<Record<string, string | number>> => {
  return requests.map((request) => {
    const approved = status === QuickPayRequestStatus.APPROVED;
    const decisionDate = parseDateToLocal(request.decisionDate!).split(",");

    return {
      [`Invoice ID`]: request.invoice.mvmntInvoiceId ?? "",
      [`Shipper`]: request.invoice.shipper.contactInformation.companyName,
      [`Carrier`]: request.carrier!.companyName,
      [approved ? `Approved By` : `Rejected By`]: request.decidingAdmin ?? "",
      [approved
        ? `Approval Date`
        : `Rejected Date`]: `${decisionDate[1]} ${decisionDate[0]}`,
      [`Amount`]: request.invoice.invoiceAmount,
      [approved ? `Status` : `Reason`]: approved
        ? request.status
        : request.reason ?? "",
    };
  });
};

export function getFileExtensionFromUrl(url: string): string {
  const { pathname } = new URL(url);
  const extension = pathname.split(".").pop();
  invariant(pathname.includes("."));
  invariant(extension);
  return extension;
}

export async function downloadFiles(
  fileUrls: string[],
  zipFilename = "attachments.zip",
): Promise<void> {
  const zip = new JSZip();
  const zipFilePromises = fileUrls.map(async (url, i) => {
    const fileName = `file-${i}.${getFileExtensionFromUrl(url)}`;
    return await axios.get(url).then((file) => ({
      file,
      fileName,
    }));
  });

  (await Promise.all(zipFilePromises)).forEach(({ file, fileName }) => {
    zip.file(fileName, file.data, { binary: true });
  });

  const zipFile = await zip.generateAsync({ type: "blob" });
  saveAs(zipFile, zipFilename);
}
