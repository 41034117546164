import { Text } from "@chakra-ui/react";
import ReusableUploadSumary from "../../components/ReusableUploadSumary";
import { SummaryCmp } from "../../components/ReusableUploadSumary/ReusableUploadSumary";
import { ValidationResults } from "../../types/BulkUpload";
import { AddableCarrier } from "../../types/NetworkUpload";

interface UploadCarriersSummaryProps {
  validationResult: ValidationResults<AddableCarrier>;
}

const AlreadyAddedSummary = (
  validationResult: ValidationResults<AddableCarrier>,
): JSX.Element => (
  <>
    {validationResult.alreadyAdded?.map((carrier) => (
      <Text key={carrier.dotNumber}>
        {carrier.companyName} - {carrier.dotNumber}
      </Text>
    ))}
  </>
);

const ReadyToAddSummary = (
  validationResult: ValidationResults<AddableCarrier>,
): JSX.Element => (
  <>
    {validationResult.readyToAdd?.map((carrier) => (
      <Text key={carrier.dotNumber}>
        {carrier.companyName} - {carrier.dotNumber}
      </Text>
    ))}
  </>
);

export const UploadCarriersSummary = ({
  validationResult,
}: UploadCarriersSummaryProps): JSX.Element => {
  const {
    misFormattedRows,
    duplicated,
    nonExistent,
    alreadyAdded,
    readyToAdd,
  } = validationResult;

  return (
    <ReusableUploadSumary
      MisFormattedRowsCmp={() =>
        misFormattedRows!.length > 0 ? (
          <SummaryCmp
            text="Please review the template. The following row numbers are not formatted correctly:"
            array={misFormattedRows}
          />
        ) : (
          <></>
        )
      }
      DuplicatedCmp={() =>
        duplicated!.length > 0 ? (
          <SummaryCmp
            text="Please upload only one contact per carrier. The following records will not be uploaded:"
            array={duplicated}
          />
        ) : (
          <></>
        )
      }
      NonExistentCmp={() =>
        nonExistent!.length > 0 ? (
          <SummaryCmp
            text="The following DOT numbers were not found in FMCSA records:"
            array={nonExistent}
          />
        ) : (
          <></>
        )
      }
      AlreadyAddedCmp={() =>
        alreadyAdded!.length ? (
          <SummaryCmp text="The following are existing network partners. Overwrite existing data?">
            {() => AlreadyAddedSummary(validationResult)}
          </SummaryCmp>
        ) : (
          <></>
        )
      }
      ReadyToAddCmp={() =>
        readyToAdd!.length ? (
          <SummaryCmp text="The following partners will be added to your network:">
            {() => ReadyToAddSummary(validationResult)}
          </SummaryCmp>
        ) : (
          <></>
        )
      }
    />
  );
};

export default UploadCarriersSummary;
