/* eslint react/prop-types: 0 */
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { useEffect, useState, Fragment } from "react";
import { ILane, IRfp, RowShipperLaneProps } from "../../types/Rfp";
import EmptySection from "../EmptySection";
import mvmntTheme from "../../styles/theme";
import { activeRfpShipperHeaders } from "./constants";
import { mapShipperLanesToRow } from "./utils";
import { HeadersLanesTable } from "./HeadersLanesTable";
import AwardCarriers from "../AwardCarriers";
import { TableData } from "./LanesProposalDetail";
import { parseValueToMoney } from "../../reuse/Money";

interface LanesProps {
  lanes?: ILane[] | null;
  shipperType: boolean;
  selectedRfp: IRfp | null;
}

export const ShipperLanesTable = ({
  lanes,
  shipperType,
}: LanesProps): JSX.Element => {
  const [selectedLane, setSelectedLane] = useState<RowShipperLaneProps | null>(
    null,
  );
  const [lanesTable, setLanesTable] = useState<RowShipperLaneProps[]>([]);
  const [openAwarded, setOpenAwarded] = useState<boolean>(false);
  const selectedBlue = mvmntTheme.colors.selectedBlue;

  const selectLane = (lane: RowShipperLaneProps): void => {
    if (shipperType && lane.laneCurrentBids?.length !== 0) {
      setSelectedLane(lane);
      setOpenAwarded(true);
    }
  };

  const closeForm = (): void => {
    setOpenAwarded(false);
    setSelectedLane(null);
  };

  useEffect(() => {
    if (lanes && lanes.length > 0) {
      setLanesTable(mapShipperLanesToRow(lanes));
    }
  }, [lanes]);

  if (lanes!.length > 0) {
    return (
      <Fragment>
        <Table variant="unstyled">
          <HeadersLanesTable data={activeRfpShipperHeaders} />
          <Tbody>
            {lanesTable.map((lane, index) => (
              <Tr
                key={index}
                cursor={
                  shipperType && lane.laneCurrentBids!.length > 0
                    ? "pointer"
                    : ""
                }
                bgColor={selectedLane?.id === lane.id ? selectedBlue : ""}
                onClick={() => selectLane(lane)}
              >
                {TableData(lane)}
                <Td textAlign="center">{lane.bids}</Td>
                <Td textAlign="center">
                  {lane.low ? parseValueToMoney(Number(lane.low)) : "--"}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <AwardCarriers
          isOpen={openAwarded}
          closeForm={closeForm}
          selectedLane={selectedLane}
          isFetching={false}
        />
      </Fragment>
    );
  } else {
    return <EmptySection title="No lanes available." />;
  }
};

export default ShipperLanesTable;
