import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User, UserType } from "../types/User";
import { RootState } from "./store";

export interface UserReducerState {
  userData: User;
}

const userData: User = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  type: UserType.CARRIER,
  authToken: "",
};

const initialState: UserReducerState = {
  userData,
};

export const userSlice = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setUserData: (state, { payload: userData }: PayloadAction<User>) => {
      state.userData = userData;
    },
  },
});
export const selectUserData = (state: RootState): User =>
  state.userReducer.userData;

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
