import {
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { useQueryState, useUpdateCarrierMutation } from "../../api/carrier";
import CompanyInformationForm from "../../components/CompanyInformationForm";
import CompanyContactsForm from "../../components/CompanyContactsForm";
import ContactInformationForm from "../../components/ContactInformationForm";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { CarrierAccountData } from "../../types/CarrierAccountForm";
import TrackingDispatchForm from "../../components/TrackingDispatchForm";
import SalesRatesForm from "../../components/SalesRatesForm";
import ReferencesForm from "../../components/ReferencesForm";
import InsuranceForm from "../../components/InsuranceForm";
import CoveragePreviewForm from "../../components/CoveragePreviewForm";
import ChangePassword from "../../components/ChangePassword";
import useToastHook from "../../components/useToastHook";
import PaymentsForm from "../../components/PaymentsForm";

export const CarrierAccount = (): JSX.Element => {
  const { data: carrierUser } = useQueryState();
  const [updateCarrier, { isLoading: isUpdating }] = useUpdateCarrierMutation();
  const toast = useToastHook();

  const handleUpdateAccount = async (
    accountData: CarrierAccountData,
  ): Promise<void> => {
    const response = await updateCarrier(accountData);
    if ("data" in response) {
      toast.success({ description: "Account updated" });
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  return (
    <VStack alignItems="start" width="50%">
      <Tabs>
        <TabList>
          <Tab style={{ marginBottom: "5px" }} isDisabled={isUpdating}>
            Company Information
          </Tab>
          <Tab style={{ marginBottom: "5px" }} isDisabled={isUpdating}>
            Contact Information
          </Tab>
          <Tab style={{ marginBottom: "5px" }} isDisabled={isUpdating}>
            Company Contacts
          </Tab>
          <Tab style={{ marginBottom: "5px" }} isDisabled={isUpdating}>
            Payments
          </Tab>
          <Tab style={{ marginBottom: "5px" }} isDisabled={isUpdating}>
            Tracking/Dispatch
          </Tab>
          <Tab style={{ marginBottom: "5px" }} isDisabled={isUpdating}>
            Sales/Rates
          </Tab>
          <Tab style={{ marginBottom: "5px" }} isDisabled={isUpdating}>
            References
          </Tab>
          <Tab style={{ marginBottom: "5px" }} isDisabled={isUpdating}>
            Insurance
          </Tab>
          <Tab style={{ marginBottom: "5px" }} isDisabled={isUpdating}>
            Coverage Preview
          </Tab>
          <Tab style={{ marginBottom: "5px" }} isDisabled={isUpdating}>
            Password
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel w="50vw">
            <CompanyInformationForm
              carrierUser={carrierUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>
          <TabPanel w="50vw">
            <ContactInformationForm
              carrierUser={carrierUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>
          <TabPanel w="50vw">
            <CompanyContactsForm
              carrierUser={carrierUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>
          <TabPanel w="50vw">
            <PaymentsForm
              carrierUser={carrierUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>
          <TabPanel w="50vw">
            <TrackingDispatchForm
              carrierUser={carrierUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>
          <TabPanel w="50vw">
            <SalesRatesForm
              carrierUser={carrierUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>
          <TabPanel w="50vw">
            <ReferencesForm
              carrierUser={carrierUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>
          <TabPanel w="50vw">
            <InsuranceForm
              carrierUser={carrierUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>
          <TabPanel w="50vw">
            <CoveragePreviewForm
              carrierUser={carrierUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>
          <TabPanel w="50vw">
            <ChangePassword />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default CarrierAccount;
