import { HStack, VStack } from "@chakra-ui/react";
import { useState } from "react";
import NoDataContainer from "../../components/NoDataContainer";
import SpotShipmentCard from "../../components/SpotShipmentCard";
import SpotShipmentSearch from "../../components/SpotShipmentSearch";
import SpotShipmentTopBar from "../../components/SpotShipmentTopBar";
import { Shipment } from "../../types/Shipment";
import { MarketPlaceContext } from "./MarketPlaceContext";

export const CarrierMarketPlace = (): JSX.Element => {
  const [shipments, setShipments] = useState<Shipment[]>([]);

  return (
    <MarketPlaceContext.Provider value={{ shipments, setShipments }}>
      <HStack h="100%" alignItems="start" spacing={0}>
        <SpotShipmentSearch />
        <VStack h="100%" w="100%" bgColor="grey" overflowY="auto" spacing={0}>
          <SpotShipmentTopBar />
          {shipments.length > 0 ? (
            shipments.map((shipment) => (
              <SpotShipmentCard key={shipment.id} {...shipment} />
            ))
          ) : (
            <NoDataContainer />
          )}
        </VStack>
      </HStack>
    </MarketPlaceContext.Provider>
  );
};

export default CarrierMarketPlace;
