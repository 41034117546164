import { ComponentType } from "react";
import { Flex, Heading, Image, Stack } from "@chakra-ui/react";
import Background from "../../resources/svg/login_chevron.svg";
import Logo from "../../resources/png/login_logo.png";

export default function SignInPageHOC<T>(
  Component: ComponentType<T>,
  HeaderCmp?: ComponentType<T>,
) {
  return function WrapComponent(props: T): JSX.Element {
    return (
      <Stack
        w="100vw"
        minH="100vh"
        maxH="100vh"
        bgColor="black"
        bgImage={Background}
        bgPos="right"
        bgRepeat="no-repeat"
        justifyContent="center"
        alignItems="center"
      >
        <Image position="absolute" top="5%" left="5%" w="169px" src={Logo} />
        <Flex flexDir="column" alignItems="center" maxH="90vh" overflowY="auto">
          <Heading fontSize="40px" color="white">
            JOIN THE MVMNT.
          </Heading>
          {HeaderCmp && <HeaderCmp {...props} />}
          <Component {...props} />
        </Flex>
      </Stack>
    );
  };
}
