/**
 * DEPRECATED:
 * Not used anymore due to a change in requirements
 */
import { Search2Icon } from "@chakra-ui/icons";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  Input,
  Skeleton,
  ModalBody,
  VStack,
  InputGroup,
  InputRightElement,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAddShipperToNetworkMutation } from "../../api/network";
import {
  useLazyGetShipperByCompanyQuery,
  useLazyGetShipperByIdQuery,
} from "../../api/shipper";
// import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { DefaultModalProps } from "../../types/DefaultModal";
import { ShipperUser } from "../../types/Shipper";

export const AddNetworkShipper = ({
  isOpen,
  onClose,
}: DefaultModalProps): JSX.Element => {
  const [shipper, setShipper] = useState<Partial<ShipperUser>>();
  const [idNumber, setIdNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [addNetworkShipper, { isLoading: isAdding }] =
    useAddShipperToNetworkMutation();
  const [
    searchByCompanyName,
    { data: shipperByCompany, isFetching: isSearchingByCompany },
  ] = useLazyGetShipperByCompanyQuery();
  const [searchByMvmntId, { data: shipperById, isFetching: isSearchingById }] =
    useLazyGetShipperByIdQuery();

  useEffect(() => {
    setShipper(shipperByCompany);
    if (shipperByCompany?.id) setIdNumber(shipperByCompany.id);
  }, [shipperByCompany]);

  useEffect(() => {
    setShipper(shipperById);
    if (shipperById?.contactInformation)
      setCompanyName(shipperById.contactInformation.companyName);
  }, [shipperById]);

  const onClickAddCarrier = async (): Promise<void> => {
    if (shipper?.id) {
      const response = await addNetworkShipper(shipper.id);
      if ("data" in response) {
        /**
         * toast.success({description: "Shipper added successfully"})
         */
        onClose();
      } else {
        /**
         * toast.error({description: `parseErrorResponse(response.error)`})
         */
      }
    }
  };

  const onClickSearchById = async (): Promise<void> => {
    if (idNumber.length === 0) return;
    await searchByMvmntId(idNumber);
  };

  const onClickSearchByCompany = async (): Promise<void> => {
    if (companyName.length === 0) return;
    await searchByCompanyName(companyName);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <Skeleton
          isLoaded={!isAdding && !isSearchingByCompany && !isSearchingById}
          w="100%"
        >
          <ModalHeader textStyle="uppercase">Add Network Partner</ModalHeader>
          <ModalBody>
            <VStack>
              <InputGroup>
                <Input
                  placeholder="MVMNT ID Number"
                  value={idNumber}
                  onChange={(e) => setIdNumber(e.target.value)}
                />
                <InputRightElement onClick={onClickSearchById} cursor="pointer">
                  <Search2Icon />
                </InputRightElement>
              </InputGroup>
              <InputGroup>
                <Input
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <InputRightElement
                  onClick={onClickSearchByCompany}
                  cursor="pointer"
                >
                  <Search2Icon />
                </InputRightElement>
              </InputGroup>
              <HStack w="100%">
                <Input
                  readOnly
                  placeholder="Contact First Name"
                  value={shipper?.contactInformation?.firstName}
                />
                <Input
                  readOnly
                  placeholder="Contact Last Name"
                  value={shipper?.contactInformation?.lastName}
                />
              </HStack>
              <HStack w="100%">
                <Input
                  readOnly
                  placeholder="Contact Phone"
                  value={shipper?.contactInformation?.companyPhoneNumber}
                />
                <Input
                  readOnly
                  placeholder="Contact Email Address"
                  value={shipper?.contactInformation?.companyEmail}
                />
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button layerStyle="red" onClick={onClickAddCarrier}>
              Add Shipper
            </Button>
            <Button
              ml="10px"
              size="sm"
              color="mvmntRed"
              variant="link"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button layerStyle="yellow" ml="auto">
              Bulk Upload
            </Button>
          </ModalFooter>
        </Skeleton>
      </ModalContent>
    </Modal>
  );
};

export default AddNetworkShipper;
