export enum EquipmentType {
  DryVan = "Dry Van",
  Refrigerated = "Refrigerated",
  Flatbed = "Flatbed",
  Stepdeck = "Flatbed - Stepdeck",
  DoubleDrop = "Flatbed - Double Drop",
  Lowboy = "Flatbed - Lowboy",
  RGN = "Flatbed - RGN",
  CargoVan = "Cargo Van",
  SprinterVan = "Sprinter Van",
}
