import AppRoutes from "../routes";

export const getNetworkInvite = (
  name: string,
): { subject: string; body: string } => {
  const subject = `MVMNT - Connect with ${name}`;
  const firstParagraph = `${name} is using MVMNT to bid on shipments.
 MVMNT is an all-in-one freight platform designed by and for trucking industry professionals.
 Join our network - create an account by clicking here ${location.origin}${AppRoutes.CREATE_ACCOUNT}
 and get bids from thousands of carriers across the U.S for free.`;
  const secondParagraph = `To learn more, visit mvmnt.io. Already on the platform? Click here to
 connect with ${name} ${location.origin}${AppRoutes.MANAGE_NETWORK}`;
  const body =
    firstParagraph + encodeURIComponent("\r\n\r\n") + secondParagraph;

  return { subject, body };
};
