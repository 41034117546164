import { CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { HStack } from "@chakra-ui/layout";
import {
  useRejectShipperMutation,
  useApproveUserMutation,
} from "../../api/shipper";
import { ShipperType } from "../../types/Shipper";
import { UserType } from "../../types/User";
import { toTitleCase } from "../../reuse/Strings";
import SharedButton from "../SharedButton";
import useToastHook from "../useToastHook";

interface ManageUserBtnsProps {
  id: string;
  email: string;
  userType: UserType | ShipperType;
}

export const ManageUserBtns = ({
  id,
  email,
  userType,
}: ManageUserBtnsProps): JSX.Element => {
  const userLabel = toTitleCase(userType);
  const [rejectShipper] = useRejectShipperMutation();
  const toast = useToastHook();

  const [approveUser, { isLoading }] = useApproveUserMutation();

  const handleApprove = async (): Promise<void> => {
    const response = await approveUser({ id: id, email: email });

    if ("data" in response) {
      toast.success({
        description: `${userLabel} has been approved. They will receive an email with a temporary password.`,
      });
      setTimeout(() => window.location.reload(), 500);
    } else {
      toast.error({ description: "Unexpected error" });
    }
  };

  const handleReject = async (): Promise<void> => {
    const response = await rejectShipper(id);

    if ("data" in response) {
      toast.success({ description: `${userLabel} rejected successfully` });
      setTimeout(() => window.location.reload(), 500);
    } else {
      toast.error({ description: "Unexpected error" });
    }
  };

  return (
    <HStack>
      <SharedButton
        title="Reject"
        textTransform="none"
        textColor="mvmntRed"
        border="1px solid red"
        leftIcon={<CloseIcon />}
        isDisabled={isLoading}
        onClick={handleReject}
      />
      <SharedButton
        title="Approve"
        textTransform="none"
        textColor="mvmntGreen"
        border="1px solid green"
        leftIcon={<CheckIcon />}
        isDisabled={isLoading}
        onClick={handleApprove}
      />
    </HStack>
  );
};

export default ManageUserBtns;
