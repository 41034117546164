import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { HStack, Text, VStack, IconButton } from "@chakra-ui/react";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { IRfpDocument } from "../../types/Rfp";
import { UserType } from "../../types/User";
import DocumentCardType from "../DocumentCardType";

interface RfpDocumentCardProps {
  document: IRfpDocument;
  chooseDocument?: () => void;
}

export const RfpDocumentCard = ({
  document,
  chooseDocument,
}: RfpDocumentCardProps): JSX.Element => {
  const { id, type } = useAppSelector(selectUserData);
  const [, fileName]: string[] = document.documentName.split("/");
  const [name, extension]: string[] = fileName.split(".");

  const carrierUser = id && type === UserType.CARRIER;

  return (
    <HStack
      borderRadius="15px"
      w="100%"
      h="90px"
      mb="10px"
      layerStyle="cardShadow"
      justifyContent="space-between"
    >
      <HStack h="100%">
        <DocumentCardType type={extension} />
        <VStack px="2rem">
          <Text fontSize={20}>{name}</Text>
        </VStack>
      </HStack>
      <HStack px="2rem">
        <IconButton
          ml="1rem"
          icon={carrierUser ? <DownloadIcon /> : <DeleteIcon />}
          aria-label={carrierUser ? "Download" : "Remove document"}
          onClick={chooseDocument}
        />
      </HStack>
    </HStack>
  );
};

export default RfpDocumentCard;
