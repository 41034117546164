import { Icon, IconProps } from "@chakra-ui/react";

export const CloseCircleIcon = (props: IconProps): JSX.Element => (
  <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M6.4 13.6L13.6 6.40003M13.6 13.6L6.4 6.40003M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02947 5.02944 1.00003 10 1.00003C14.9706 1.00003 19 5.02947 19 10Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
