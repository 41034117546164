import {
  VStack,
  HStack,
  Box,
  Text,
  Button,
  Input,
  FormControl,
  Divider,
  Skeleton,
  ButtonGroup,
  Checkbox,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import {
  useLazySearchCarriersQuery,
  useLazySearchExportCarriersQuery,
} from "../../api/carrier";
import { useSourcingSearchContext } from "../../pages/SourcingSearch/SourcingSearchContext";
import { getLatLngFromAddress } from "../../reuse/Maps";
import {
  parseValues,
  toMultiselectOptions,
  Option,
} from "../../reuse/MultiSelect";
import { validateZipCode } from "../../reuse/UserData";
import { zipCodes, geoJson } from "../../resources/marketAreas";
import { SearchInputs, SearchOptions } from "../../types/SourcingSearchOptions";
import ChevronIcon from "../ChevronIcon";
import ErrorMessage from "../ErrorMessage";
import { sortText } from "../../reuse/Sorting";
import useToastHook from "../../components/useToastHook";
import { borderStyling } from "../../reuse/Forms";
import {
  createCsvFromObjectsArray,
  exportFileResults,
  mapExportDataFromSearch,
} from "../../reuse/Files";
import { FmcsaCarrier } from "../../types/FmcsaCarrier";
import {
  cargoCarried,
  carrierOperation,
  entityTypes,
  operationClass,
} from "./options";

const DEFAULT_LIMIT = 50;
const EXPORT_LIMIT = 5000;

enum SearchType {
  LaneSearch,
  AreaSearch,
}

export const SourcingSearchForm = (): JSX.Element => {
  const [searchType, setSearchType] = useState(SearchType.LaneSearch);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<SearchInputs>({ defaultValues: { offset: "0" } });
  const [fetchCarriers, { data: carrierData, isFetching, originalArgs }] =
    useLazySearchCarriersQuery();
  const [
    fetchExportCarriers,
    { data: carrierExportData, isFetching: isExporting },
  ] = useLazySearchExportCarriersQuery();
  const context = useSourcingSearchContext();
  const {
    offset,
    setOffset,
    searchResults,
    setSearchResults,
    setTotalCount,
    setLocations,
    setHighlightedKma,
  } = context!;
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({
    entityTypes: [],
    operationClasses: [],
    carrierOperation: [],
    cargoCarried: [],
    kma: [],
  });
  const toast = useToastHook();
  const isExportingRef = useRef(false);

  useEffect(() => {
    if (offset && originalArgs) {
      void handleSearch(
        {
          ...(originalArgs.singleValue as SearchInputs),
          offset: offset.toString(),
        },
        DEFAULT_LIMIT,
      );
    }
  }, [offset]);

  useEffect(() => {
    if (carrierData) {
      const { fmcsaCarriers, totalCount } = carrierData;
      const currentResults = !context || offset === 0 ? [] : searchResults;
      setSearchResults([...currentResults, ...fmcsaCarriers]);
      setTotalCount(totalCount);
    }
  }, [carrierData]);

  useEffect(() => {
    isExportingRef.current = true;

    if (carrierExportData && !isExporting) {
      const { fmcsaCarriers } = carrierExportData;
      exportCarrierResults(fmcsaCarriers);
    }

    return () => {
      isExportingRef.current = false;
    };
  }, [carrierExportData]);

  const handleSelectChange = (
    values: Option[],
    field: keyof SearchOptions,
  ): void => {
    const parsedValues = parseValues(values);
    setSearchOptions({ ...searchOptions, [field]: parsedValues });
  };

  const handleSearch = async (
    formData: SearchInputs,
    limit: number,
  ): Promise<void> => {
    // HANDLE SEARCH - AREA SEARCH
    if (searchType === SearchType.AreaSearch) {
      const { pickupLocation, destinationLocation, ...singleValue } = formData;
      const multipleValues = {
        ...searchOptions,
        kma: searchOptions.kma.length > 0 ? zipCodes[searchOptions.kma[0]] : [],
        countryCode:
          searchOptions.kma.length > 0
            ? geoJson[searchOptions.kma[0]].countryCode
            : undefined,
      };

      await handleCarriersFetching(limit, multipleValues, singleValue);
      return;
    }

    // HANDLE SEARCH - LANE SEARCH
    const pickup = await getLatLngFromAddress(formData.pickupLocation);
    if (!pickup) {
      toast.error({ description: "Invalid pickup address" });
      return;
    }
    const destination = await getLatLngFromAddress(
      formData.destinationLocation,
    );
    if (!destination) {
      toast.error({ description: "Invalid destination address" });
      return;
    }
    setLocations({
      pickup,
      destination,
    });
    const { kma, ...multipleValues } = searchOptions;
    const { zipCode, ...formValues } = formData;
    const singleValue: Partial<SearchInputs> = {
      ...formValues,
      pickupLocation: `${pickup.lat},${pickup.lng}`,
      destinationLocation: `${destination.lat},${destination.lng}`,
    };

    await handleCarriersFetching(limit, multipleValues, singleValue);
  };

  const handleCarriersFetching = async (
    limit: number,
    multipleValues: Partial<SearchOptions>,
    singleValue: Partial<SearchInputs>,
  ): Promise<void> => {
    if (limit === DEFAULT_LIMIT) {
      await fetchCarriers({
        multipleValues,
        singleValue,
        limit,
      });
    } else {
      await fetchExportCarriers({
        multipleValues,
        singleValue,
        limit,
      });
    }
  };

  const exportCarrierResults = (fmcsaCarriers: FmcsaCarrier[]): void => {
    const carriers = mapExportDataFromSearch(fmcsaCarriers);
    const csvUrl = createCsvFromObjectsArray(carriers);

    exportFileResults(csvUrl, "carrier_search.csv");
  };

  const onChangeKma = (values: Option[]): void => {
    const kma = values[values.length - 1];
    handleSelectChange(kma ? [kma] : [], "kma");
    setHighlightedKma(kma?.value);
  };

  const kmaOptions = useMemo(
    (): Option[] =>
      Object.keys(zipCodes)
        .sort((a, b) => sortText(a, b, true))
        .map((kma) => ({ label: kma, value: kma })),
    [zipCodes],
  );

  const onSubmit = handleSubmit(async (formData) => {
    setOffset(0);
    await handleSearch(formData, DEFAULT_LIMIT);
  });

  const onSubmitExport = handleSubmit(async (formData) => {
    await handleSearch(formData, EXPORT_LIMIT);
  });

  return (
    <Skeleton
      w="100%"
      isLoaded={!isFetching && !isExporting}
      display="flex"
      justifyContent="end"
    >
      <Box mt="3vh" mb="1.5vh" me="1.5vw" w="80%">
        <form onSubmit={onSubmit}>
          <VStack alignItems="start">
            <HStack w="100%" justifyContent="space-between">
              <Text textStyle="subTitle">Carrier Sourcing</Text>
              <ButtonGroup
                spacing={0}
                w="19%"
                border="1px"
                bgColor="realWhite"
                borderRadius="lg"
              >
                <Button
                  w="50%"
                  transition="background-color .5s"
                  bgColor={
                    searchType === SearchType.LaneSearch
                      ? "mvmntRed"
                      : "realWhite"
                  }
                  color={
                    searchType === SearchType.LaneSearch ? "white" : "black"
                  }
                  size="sm"
                  onClick={() => {
                    clearErrors();
                    setSearchType(SearchType.LaneSearch);
                  }}
                >
                  Lane
                </Button>
                <Button
                  w="50%"
                  transition="background-color .5s"
                  bgColor={
                    searchType === SearchType.AreaSearch
                      ? "mvmntRed"
                      : "realWhite"
                  }
                  color={
                    searchType === SearchType.AreaSearch ? "white" : "black"
                  }
                  size="sm"
                  onClick={() => {
                    clearErrors();
                    setSearchType(SearchType.AreaSearch);
                  }}
                >
                  Area
                </Button>
              </ButtonGroup>
            </HStack>
            <HStack w="100%" justifyContent="space-between">
              {searchType === SearchType.LaneSearch ? (
                <HStack w="80%">
                  <FormControl>
                    <Input
                      key="pickup"
                      size="sm"
                      placeholder="Pickup location"
                      style={{
                        borderColor: borderStyling(errors.pickupLocation),
                      }}
                      {...register("pickupLocation", {
                        required: {
                          value: true,
                          message: "Please enter a pickup location",
                        },
                      })}
                    ></Input>
                  </FormControl>
                  <ChevronIcon />
                  <FormControl>
                    <Input
                      key="destination"
                      size="sm"
                      placeholder="Destination location"
                      style={{
                        borderColor: borderStyling(errors.destinationLocation),
                      }}
                      {...register("destinationLocation", {
                        required: {
                          value: true,
                          message: "Please enter a destination",
                        },
                      })}
                    ></Input>
                  </FormControl>
                </HStack>
              ) : (
                <HStack w="80%">
                  <FormControl w="50%" maxW="33vw">
                    <MultiSelect
                      key="market area"
                      overrideStrings={{
                        selectSomeItems: "Key Market Area",
                      }}
                      hasSelectAll={false}
                      value={toMultiselectOptions(searchOptions.kma)}
                      onChange={onChangeKma}
                      options={kmaOptions}
                      labelledBy="Select"
                      className="small-dropdown"
                    />
                  </FormControl>
                  <FormControl w="50%">
                    <Input
                      key="zip code"
                      size="sm"
                      placeholder="Zip Code (3, 4 or 5 digits)"
                      {...register("zipCode", {
                        validate: (v) =>
                          !v && searchOptions.kma.length === 0
                            ? "Please input a kma and/or zip code"
                            : validateZipCode(v),
                      })}
                    ></Input>
                  </FormControl>
                </HStack>
              )}
            </HStack>
            <Divider w="80%" />
            <HStack w="80%">
              <FormControl w="25%" maxW="33vw">
                <MultiSelect
                  overrideStrings={{ selectSomeItems: "Entity type" }}
                  disableSearch
                  hasSelectAll={false}
                  value={toMultiselectOptions(searchOptions.entityTypes)}
                  onChange={(values: Option[]) =>
                    handleSelectChange(values, "entityTypes")
                  }
                  options={entityTypes}
                  labelledBy="Select"
                  className="small-dropdown"
                />
              </FormControl>
              <FormControl w="25%">
                <Input
                  size="sm"
                  placeholder="Legal name"
                  {...register("name")}
                ></Input>
              </FormControl>
              <FormControl w="25%">
                <Input
                  size="sm"
                  placeholder="DBA name"
                  {...register("dbaName")}
                ></Input>
              </FormControl>
              <FormControl w="38%">
                <Checkbox {...register("isMcNumberRequired")}>
                  MC number required?
                </Checkbox>
              </FormControl>
            </HStack>
            <HStack w="80%">
              <FormControl>
                <Input
                  size="sm"
                  placeholder="USDOT #"
                  {...register("dotNumber")}
                ></Input>
              </FormControl>
              <FormControl>
                <Input
                  size="sm"
                  placeholder="MC/MX/FF#"
                  {...register("mcNumber")}
                ></Input>
              </FormControl>
              <FormControl>
                <Input
                  type="number"
                  size="sm"
                  placeholder="Number of Trucks"
                  {...register("numberTrucks")}
                ></Input>
              </FormControl>
              <FormControl>
                <Input
                  type="number"
                  size="sm"
                  placeholder="Drivers"
                  {...register("drivers")}
                ></Input>
              </FormControl>
            </HStack>
            <Divider w="80%" />
            <HStack w="80%">
              <FormControl w="33%" maxW="30vw">
                <MultiSelect
                  overrideStrings={{
                    selectSomeItems: "Operation classification",
                  }}
                  disableSearch
                  hasSelectAll={false}
                  value={toMultiselectOptions(searchOptions.operationClasses)}
                  onChange={(values: Option[]) =>
                    handleSelectChange(values, "operationClasses")
                  }
                  options={operationClass}
                  labelledBy="Select"
                  className="small-dropdown"
                />
              </FormControl>
              <FormControl w="33%" maxW="33vw">
                <MultiSelect
                  overrideStrings={{
                    selectSomeItems: "Carrier operation",
                  }}
                  disableSearch
                  hasSelectAll={false}
                  value={toMultiselectOptions(searchOptions.carrierOperation)}
                  onChange={(values: Option[]) =>
                    handleSelectChange(values, "carrierOperation")
                  }
                  options={carrierOperation}
                  labelledBy="Select"
                  className="small-dropdown"
                />
              </FormControl>
              <FormControl w="33%" maxW="33vw">
                <MultiSelect
                  overrideStrings={{
                    selectSomeItems: "Cargo carried",
                  }}
                  hasSelectAll={false}
                  value={toMultiselectOptions(searchOptions.cargoCarried)}
                  onChange={(values: Option[]) =>
                    handleSelectChange(values, "cargoCarried")
                  }
                  options={cargoCarried}
                  labelledBy="Select"
                  className="small-dropdown"
                />
              </FormControl>
            </HStack>
            <HStack w="100%">
              <Button
                w="19%"
                size="small"
                layerStyle="yellow"
                onClick={onSubmit}
              >
                Search Carriers
              </Button>
              <Button
                w="19%"
                size="small"
                layerStyle="yellow"
                onClick={onSubmitExport}
              >
                Export Carriers
              </Button>
            </HStack>
            <ErrorMessage errors={errors} clearErrors={clearErrors} />
          </VStack>
        </form>
      </Box>
    </Skeleton>
  );
};

export default SourcingSearchForm;
