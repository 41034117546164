import {
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Divider,
  HStack,
  VStack,
  Select,
} from "@chakra-ui/react";
import { removeDuplicatesArr } from "../AccountsPayable/reuse";
import { Fragment, useMemo } from "react";
import { getQuickPayToFilter, getFilteredTableData } from "./reuse";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { useQuickPayContext } from "./QuickPayContext";
import MultipleSelectFilter from "../../components/MultipleSelectFilter";
import SharedButton from "../../components/SharedButton";
import {
  QuickPayRequest,
  QuickPayRequestStatus,
  QuickPayToFilterEnum,
  QPResponseEnum,
  QuickPayToDropdownEnum,
} from "../../types/QuickPayRequest";
import { useApproveMutation, useRejectMutation } from "../../api/quickpay";
import useToastHook from "../../components/useToastHook";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { toTitleCase } from "../../reuse/Strings";

interface AdditionalContentProps {
  term: string;
  setTerm: (t: string) => void;
}

interface ShowingRowsProps {
  availableList: QuickPayRequest[];
}

const TabAdditionalContent = ({
  term,
  setTerm,
}: AdditionalContentProps): JSX.Element => {
  const toast = useToastHook();
  const context = useQuickPayContext();
  const {
    quickPays,
    currentStatus,
    selectedQuickPay,
    setMultiselectFilters,
    sortByCriteria,
  } = context!;

  const [approve] = useApproveMutation();
  const [reject] = useRejectMutation();

  const filterdPays = getFilteredTableData(quickPays ?? [], currentStatus);

  const shippers = useMemo(
    () => filterdPays.map((i) => getQuickPayToFilter(i).shipper),
    [filterdPays],
  );

  const carriers = useMemo(
    () => filterdPays.map((i) => getQuickPayToFilter(i).carrier),
    [filterdPays],
  );

  const admins = useMemo(
    () => filterdPays.map((i) => getQuickPayToFilter(i).decisionAdmin),
    [filterdPays],
  );

  const dates = useMemo(
    () => filterdPays.map((i) => getQuickPayToFilter(i).decisionDate),
    [filterdPays],
  );

  const statusList = useMemo(
    () => filterdPays.map((i) => getQuickPayToFilter(i).status),
    [filterdPays],
  );

  const onChangeMultiple = (
    val: string[],
    filterKey: QuickPayToFilterEnum,
  ): void => {
    setMultiselectFilters((prev) => ({
      ...prev,
      [filterKey]: val,
    }));
  };

  const postQuickPays = async (type: string): Promise<void> => {
    const body = { quickpayIds: selectedQuickPay };
    const response =
      type === QPResponseEnum.rejected
        ? await reject(body)
        : await approve(body);
    if ("data" in response) {
      toast.success({ description: `Quickpays ${type} successfully` });
      setTimeout(() => window.location.reload(), 1000);
    } else
      toast.error({ description: `${parseErrorResponse(response.error)}` });
  };

  const ShowingRows = ({ availableList }: ShowingRowsProps): JSX.Element => (
    <HStack px="1rem" h="40px">
      <Text>{`Showing ${availableList?.length ?? 0}`} Requests</Text>
      <Divider orientation="vertical" color="gray30" w={1} h={6} />
      <Text>Sorting by:</Text>
      <Select
        placeholder="Select a criteria..."
        border="0.5px solid gray"
        bgColor="grey30"
        w="250px"
        onChange={(e) => {
          const criteria = e.target.value.toLowerCase().replace(" ", "_");
          sortByCriteria(criteria as QuickPayToDropdownEnum);
        }}
      >
        {Object.keys(QuickPayToDropdownEnum).map((option) => (
          <option key={option}>{toTitleCase(option.replace("_", " "))}</option>
        ))}
      </Select>
    </HStack>
  );

  const InputSearch = (): JSX.Element => (
    <HStack px="0.8rem" w="400px">
      <InputGroup w="100%">
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.40" />
        </InputLeftElement>
        <Input
          mr="0.5rem"
          placeholder="Search by Load ID, Shipper or Customer"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
        />
      </InputGroup>
      <CloseIcon cursor="pointer" onClick={() => setTerm("")} />
    </HStack>
  );

  const MultiselectsGroup = (): JSX.Element => (
    <HStack w="inherit">
      <MultipleSelectFilter<QuickPayToFilterEnum>
        filterKey={QuickPayToFilterEnum.shipper}
        options={removeDuplicatesArr(shippers!) ?? []}
        onChangeMultiple={onChangeMultiple}
      />
      <MultipleSelectFilter<QuickPayToFilterEnum>
        filterKey={QuickPayToFilterEnum.carrier}
        options={removeDuplicatesArr(carriers!) ?? []}
        onChangeMultiple={onChangeMultiple}
      />
      {!currentStatus.includes(QuickPayRequestStatus.REQUESTED) && (
        <Fragment>
          <MultipleSelectFilter<QuickPayToFilterEnum>
            filterKey={QuickPayToFilterEnum.decisionAdmin}
            options={removeDuplicatesArr(admins!) ?? []}
            onChangeMultiple={onChangeMultiple}
          />
          <MultipleSelectFilter<QuickPayToFilterEnum>
            filterKey={QuickPayToFilterEnum.decisionDate}
            options={removeDuplicatesArr(dates!) ?? []}
            onChangeMultiple={onChangeMultiple}
          />
        </Fragment>
      )}
      {currentStatus.includes(QuickPayRequestStatus.APPROVED) && (
        <MultipleSelectFilter<QuickPayToFilterEnum>
          filterKey={QuickPayToFilterEnum.status}
          options={removeDuplicatesArr(statusList!) ?? []}
          onChangeMultiple={onChangeMultiple}
        />
      )}
    </HStack>
  );

  const ButtonsGroup = (): JSX.Element => (
    <HStack>
      <SharedButton
        title="Reject"
        textTransform="none"
        textColor="mvmntRed"
        border="1px solid red"
        disabled={selectedQuickPay.length === 0}
        onClick={async () => await postQuickPays(QPResponseEnum.rejected)}
      />
      <SharedButton
        title="Approve"
        textTransform="none"
        textColor="mvmntGreen"
        border="1px solid green"
        disabled={selectedQuickPay.length === 0}
        onClick={async () => await postQuickPays(QPResponseEnum.approved)}
      />
    </HStack>
  );

  const availableList = getFilteredTableData(quickPays ?? [], currentStatus);

  return (
    <VStack w="100%">
      <HStack
        justifyContent="space-between"
        py="1rem"
        m="1rem 0.5rem"
        bgColor="#F6F6F6"
        w="100%"
      >
        <ShowingRows availableList={availableList} />
        <InputSearch />
      </HStack>
      <HStack
        justifyContent="space-between"
        p="1rem 0.5rem"
        m="1rem 0.5rem"
        bgColor="#F6F6F6"
        w="100%"
      >
        <MultiselectsGroup />
        {currentStatus.includes(QuickPayRequestStatus.REQUESTED) && (
          <ButtonsGroup />
        )}
      </HStack>
    </VStack>
  );
};

export default TabAdditionalContent;
