import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  Input,
  ModalBody,
  Select,
  FormLabel,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useRef, useState, Fragment } from "react";
import {
  useUploadDocumentMutation,
  useUploadS3FileMutation,
} from "../../api/documents";
import {
  invalidateTags as invalidateNetwork,
  useAddCarrierToNetworkMutation,
} from "../../api/network";
import { useAppDispatch } from "../../app/hooks";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { OneMegaByte } from "../../reuse/Files";
import { DefaultModalProps } from "../../types/DefaultModal";
import { Documents, DocumentType } from "../../types/Files";
import useToastHook from "../../components/useToastHook";

interface DocumentUploadProps extends DefaultModalProps {
  documentOptions: Documents[];
  networkId?: string;
  carrierDotNumber?: string;
  onSuccess?: () => void;
}

export const DocumentUpload = ({
  documentOptions,
  isOpen,
  onClose,
  networkId,
  carrierDotNumber,
  onSuccess,
}: DocumentUploadProps): JSX.Element => {
  const [documentType, setDocumentType] = useState(documentOptions[0].value);
  const [hiddenButtons, setHiddenButtons] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [addCarrierToNetwork, { isLoading: isAdding }] =
    useAddCarrierToNetworkMutation();
  const [uploadDocument, { isLoading: isUploadingDocument }] =
    useUploadDocumentMutation();
  const [uploadS3File, { isLoading: isUploadingS3File }] =
    useUploadS3FileMutation();
  const dispatch = useAppDispatch();

  const toast = useToastHook();

  const isBusy = isAdding || isUploadingDocument || isUploadingS3File;

  const handleS3Upload = async (url: string, file: File): Promise<boolean> => {
    const fileUpload = await uploadS3File({
      file,
      url,
    });
    if ("error" in fileUpload) {
      toast.error({ description: `${parseErrorResponse(fileUpload.error)}` });
    } else if (fileUpload.data) {
      toast.success({ description: "File uploaded successfully" });

      return true;
    } else {
      toast.error({ description: "Could not upload file" });
    }

    return false;
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setHiddenButtons(true);
    const file = event?.target?.files![0];
    const { name, type, size } = file;
    if (name.length > 50) {
      toast.error({ description: "File name is too long." });
      setHiddenButtons(false);
      return;
    }
    if (size >= 100 * OneMegaByte) {
      toast.error({ description: "File size is too large." });
      setHiddenButtons(false);
      return;
    }

    let newNetworkId = "";
    if (!networkId && carrierDotNumber) {
      const response = await addCarrierToNetwork(carrierDotNumber);
      if ("data" in response) newNetworkId = response.data;
      else {
        setHiddenButtons(false);
        toast.error({ description: `${parseErrorResponse(response.error)}` });
        return;
      }
    }

    const documentUpload = await uploadDocument({
      fileName: name,
      contentType: type,
      documentType,
      networkId: networkId ?? newNetworkId,
    });
    if ("data" in documentUpload) {
      onClose();
      await handleS3Upload(documentUpload.data, file);

      if (networkId) dispatch(invalidateNetwork(["Network"]));
      onSuccess?.();
    } else {
      setHiddenButtons(false);
      toast.error({
        description: `${parseErrorResponse(documentUpload.error)}`,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setHiddenButtons(false);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader textStyle="uppercase">Upload Documents</ModalHeader>

        <ModalBody>
          <FormLabel>Document Type</FormLabel>
          <Select
            onChange={(e) => setDocumentType(e.target.value as DocumentType)}
            value={documentType}
            disabled={documentOptions.length <= 1}
          >
            {documentOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </ModalBody>

        <ModalFooter justifyContent="space-around">
          {!hiddenButtons ? (
            <Fragment>
              <Input
                accept=".pdf,.xls,.xlsx,.csv,.doc,.txt,.xlsm,.jpg,.png,.tif,.heif,.heifs,.heic,.heics,.webp"
                type="file"
                hidden
                ref={fileInputRef}
                onChange={handleFileInput}
              />

              <Button
                layerStyle="red"
                w="200px"
                onClick={() => fileInputRef.current?.click()}
                isLoading={isBusy}
                disabled={isBusy}
              >
                Select File
              </Button>
              <Button
                size="sm"
                color="mvmntRed"
                variant="link"
                onClick={onClose}
                isLoading={isBusy}
              >
                Cancel
              </Button>
            </Fragment>
          ) : (
            <Spinner color="mvmntRed" mb="1rem" />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DocumentUpload;
