import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  Text,
  ModalBody,
} from "@chakra-ui/react";
import { DefaultModalProps } from "../../types/DefaultModal";

interface ConfirmationModalProps extends DefaultModalProps {
  onConfirm: () => void;
  title: string;
  content: string;
}

export const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  content,
}: ConfirmationModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="uppercase">{title}</ModalHeader>
        <ModalBody>
          <Text>{content}</Text>
        </ModalBody>
        <ModalFooter justifyContent="space-around">
          <Button layerStyle="red" onClick={onConfirm}>
            Confirm
          </Button>
          <Button size="sm" color="mvmntRed" variant="link" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
