import {
  ColumnDef,
  createColumnHelper,
  SortingState,
  AccessorFn,
} from "@tanstack/react-table";
import {
  DataTable,
  useDataTable,
  DataTableMultiselectFilter,
} from "../../../ui/components/DataTable";
import {
  HStack,
  Text,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  InputRightElement,
} from "@chakra-ui/react";
import { parseValueToMoney } from "../../../reuse/Money";
import type { CarrierInvoice } from "../../../api/carrier";
import { useMemo, useState } from "react";
import { getStateEnumFromStringAndCountryEnum as getStateAbbrev } from "../../../reuse/RegionKeys";
import { pluralize, toTitleCase } from "../../../reuse/Strings";
import { Countries } from "../../../resources/regions";
import { parseDateToShortDate } from "../../../reuse/Dates";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";

const DATE_COLUMN = "DATE_COLUMN";
export const columnHelper = createColumnHelper<CarrierInvoice>();
type ColumnHelperAccessorFn = AccessorFn<
  CarrierInvoice,
  string | null | undefined
>;

export function InvoicesTable({
  invoices = [],
  isLoading = true,
  dateColumn,
  addColumns = [],
}: {
  invoices?: CarrierInvoice[];
  isLoading: boolean;
  dateColumn: {
    accessorFn: ColumnHelperAccessorFn;
    header: string;
  };
  addColumns?: Array<ColumnDef<CarrierInvoice, number>>;
}): JSX.Element {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([
    { id: DATE_COLUMN, desc: false },
  ]);
  const columns = [
    columnHelper.accessor(
      (cell) => cell.shipment.mvmntShipmentId.toString().padStart(3, "0"),
      {
        header: "Load ID",
        id: "loadId",
        cell: (cell) => <Text color="mvmntBlue">#{cell.getValue()}</Text>,
      },
    ),
    columnHelper.accessor(
      (cell) => {
        const origin = cell.shipment.shipmentStops.origin;
        const city = toTitleCase(origin.addressCity);
        const stateAbbrev = getStateAbbrev(
          origin.addressState,
          toTitleCase(origin.addressCountry) as Countries,
        )?.abbreviation;
        return `${city}, ${stateAbbrev}`;
      },
      {
        header: "Origin",
        id: "origin",
        filterFn: "arrIncludesSome",
      },
    ),
    columnHelper.accessor(
      (cell) => {
        const destination = cell.shipment.shipmentStops.destination;
        const city = toTitleCase(destination.addressCity);
        const stateAbbrev = getStateAbbrev(
          destination.addressState,
          toTitleCase(destination.addressCountry) as Countries,
        )?.abbreviation;
        return `${city}, ${stateAbbrev}`;
      },
      {
        header: "Destination",
        id: "destination",
        filterFn: "arrIncludesSome",
      },
    ),
    columnHelper.accessor("shipment.shipper.companyName", {
      header: "Customer",
      id: "customer",
      filterFn: "arrIncludesSome",
    }),
    columnHelper.accessor(dateColumn.accessorFn, {
      header: dateColumn.header,
      cell: (cell) => {
        const dateString = cell.getValue();
        return dateString ? parseDateToShortDate(new Date(dateString)) : "N/A";
      },
      id: DATE_COLUMN,
      filterFn: "arrIncludesSome",
      enableGlobalFilter: false,
      sortingFn: "datetime",
    }),
    columnHelper.accessor(
      (cell) =>
        Number(
          cell?.directPaymentRequest
            ? cell.directPaymentRequest.amount
            : cell.shipment.winningBid.totalAmount,
        ),
      {
        header: "Amount",
        enableGlobalFilter: false,
        cell: (cell) => {
          return (
            <>
              {cell.getValue() !== null
                ? parseValueToMoney(Number(cell.getValue()))
                : null}
            </>
          );
        },
      },
    ),
  ];
  const table = useDataTable({
    data: invoices,
    state: {
      globalFilter,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    columns: useMemo(() => [...columns, ...addColumns], []),
  });

  return (
    <div>
      <Flex
        alignItems="center"
        flexWrap="wrap"
        bgColor="grey6"
        marginTop="20px"
        marginBottom="20px"
        padding="8px 8px 8px 20px"
        fontFamily="Montserrat Regular"
        fontSize="13px"
      >
        <HStack height="20px" spacing="13px">
          <Text>
            {isLoading
              ? "Loading invoices..."
              : `Showing ${pluralize(
                  "invoice",
                  table.getPrePaginationRowModel().rows.length,
                )}`}
          </Text>
          <Divider orientation="vertical" color="gray30" />
          <Text>Sort by: </Text>
          <Select
            value={table.getState().sorting[0]?.id}
            onChange={(event) =>
              table.setSorting([{ id: event.currentTarget.value, desc: false }])
            }
            variant="unstyled"
            bgColor="none"
            fontSize="13px"
            textAlign="left"
            fontFamily="Montserrat Bold"
            width="fit-content"
          >
            {table
              .getAllColumns()
              .filter((col) => col.getCanSort())
              .map((col) => {
                return (
                  <option value={col.id} key={col.id}>
                    {col.columnDef.header}
                  </option>
                );
              })}
          </Select>
        </HStack>

        <InputGroup marginLeft="auto" width="auto">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray30" />
          </InputLeftElement>
          {table.getState().globalFilter && (
            <InputRightElement
              cursor="pointer"
              onClick={() => table.resetGlobalFilter()}
            >
              <CloseIcon color="gray30" />
            </InputRightElement>
          )}
          <Input
            onChange={(event) => setGlobalFilter(event.target.value)}
            value={table.getState().globalFilter || ""}
            placeholder="Search by Load ID, Origin, Destination or Customer"
            width="480px"
            borderRadius="4px"
            borderColor="grey5"
            bgColor="#fff"
          />
        </InputGroup>
      </Flex>
      <HStack marginBottom="10px">
        <DataTableMultiselectFilter table={table} columnId="origin" />
        <DataTableMultiselectFilter table={table} columnId="destination" />
        <DataTableMultiselectFilter table={table} columnId="customer" />
        <DataTableMultiselectFilter
          table={table}
          columnId={DATE_COLUMN}
          labelFormatter={(label) => {
            if (!label || label === "N/A") return "N/A";
            return parseDateToShortDate(new Date(label));
          }}
        />
      </HStack>
      <DataTable table={table} key={invoices.length} />
    </div>
  );
}
