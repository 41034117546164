import { Alert, AlertIcon, AlertStatus } from "@chakra-ui/react";
import { ConnectionStatus } from "../../types/NetworkPartner";

interface AlertValue {
  text: string;
  type: AlertStatus;
}

export const ConnectionStatusMap: { [key in ConnectionStatus]: AlertValue } = {
  [ConnectionStatus.PENDING]: { text: "is pending", type: "warning" },
  [ConnectionStatus.NOTSENT]: { text: "was not sent", type: "warning" },
  [ConnectionStatus.SIGNEDOFFLINE]: {
    text: "was not uploaded",
    type: "warning",
  },
  [ConnectionStatus.SIGNEDOFFLINEUPLOADED]: {
    text: "is uploaded",
    type: "success",
  },
  [ConnectionStatus.CONNECTED]: { text: "signed on MVMNT", type: "success" },
};

export const ConnectionStatusAlert = ({
  connectionStatus,
}: {
  connectionStatus: ConnectionStatus;
}): JSX.Element => {
  return (
    <Alert status={ConnectionStatusMap[connectionStatus].type}>
      <AlertIcon />
      Agreement {ConnectionStatusMap[connectionStatus].text}
    </Alert>
  );
};

export default ConnectionStatusAlert;
