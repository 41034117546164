import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useTrackingContext } from "../../pages/Tracking/TrackingContext";
import SharedMap from "../SharedMap";
import ShipmentForm from "../ShipmentForm";

interface TrackingShipmentContentProps {
  title: string;
}

export const TrackingShipmentContent = ({
  title,
}: TrackingShipmentContentProps): JSX.Element => {
  const trackingContext = useTrackingContext();
  const {
    setCurrentTrackingShipment,
    currentTrackingShipment,
    locations,
    markerInfo,
  } = trackingContext!;

  const memoizedMap = useMemo(
    () => (
      <SharedMap locations={locations} marker={markerInfo} hideRoute={true} />
    ),
    [locations, markerInfo],
  );

  return (
    <VStack
      alignItems="center"
      w="100%"
      h="100%"
      spacing="3%"
      style={{ marginInlineStart: 0 }}
    >
      <Tabs isFitted variant="enclosed" h="100%" w="100%">
        <TabList bgColor="darkgray" textColor="white">
          <Tab _selected={{ color: "yellow", bg: "black" }}>{title}</Tab>
          <Tab _selected={{ color: "yellow", bg: "black" }}>
            SHIPMENT DETAILS
          </Tab>
        </TabList>
        <TabPanels h="calc(100% - 50px)">
          <TabPanel h="100%" w="100%" overflowY="auto" p={0}>
            <VStack alignItems="start" w="100%" h="100%" spacing="3%">
              {memoizedMap}
            </VStack>
          </TabPanel>
          <TabPanel h="100%" w="100%" overflowY="auto">
            <VStack w="100%">
              <ShipmentForm
                initialValues={currentTrackingShipment}
                setSelectedShipment={setCurrentTrackingShipment}
              />
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default TrackingShipmentContent;
