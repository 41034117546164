import { VStack, HStack, Text, Box, Button } from "@chakra-ui/react";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { useActiveRfpContext } from "../../pages/ActiveRfp/ActiveRfpContext";
import { transformDate } from "../../reuse/Dates";
import mvmntTheme from "../../styles/theme";
import { IRfp } from "../../types/Rfp";
import { UserType } from "../../types/User";
import { RfpFormMode } from "../RfpModalForm/RfpModalForm";

interface RfpCardProps {
  onClick: () => void;
  rfp: IRfp;
  isSelected: boolean;
}

interface RoundBoxProps {
  size: string;
  value: number | string;
}

export const RfpCard = ({
  rfp,
  isSelected,
  onClick,
}: RfpCardProps): JSX.Element => {
  const { type } = useAppSelector(selectUserData);
  const context = useActiveRfpContext();
  const { toggleRfpForm, setFormMode } = context!;
  const onOpenEdit = (): void => {
    setFormMode(RfpFormMode.EDIT);
    toggleRfpForm(true);
  };

  const RoundedBoxValue = ({ size, value }: RoundBoxProps): JSX.Element => {
    return (
      <Box
        h={size}
        w={size}
        color="white"
        bgColor="yellow"
        rounded="lg"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {value}
      </Box>
    );
  };

  const initialDate = transformDate(rfp.biddingStartDate);
  const finalDate = transformDate(rfp.biddingEndDate);
  const dates = `(${initialDate} - ${finalDate})`;
  const shipperUser = type === UserType.SHIPPER || type === UserType.BROKER;

  return (
    <VStack
      alignItems="start"
      justifyContent="center"
      px="1.5rem"
      py="1rem"
      w="100%"
      layerStyle="shadow"
      spacing="0.4rem"
      onClick={onClick}
      cursor="pointer"
      backgroundColor={isSelected ? mvmntTheme.colors.selectedBlue : ""}
    >
      <HStack minH="32px" w="100%" justifyContent="space-between">
        <Text fontSize="23px" fontWeight={isSelected ? "bold" : ""}>
          {rfp.rfpName}
        </Text>
        <RoundedBoxValue size="30px" value={rfp.lanes?.length ?? 0} />
      </HStack>
      <Text fontSize="15px">{dates}</Text>
      <HStack w="100%" justifyContent="space-between" fontWeight="bold">
        <Text>{rfp.lanes?.length} Lanes</Text>
        <Text>{rfp.totalVolume} Volume</Text>
        <Text>{rfp.countOfKeyMarketPairs} Market Pairs</Text>
      </HStack>
      {isSelected && shipperUser && (
        <Button size="sm" color="mvmntRed" variant="link" onClick={onOpenEdit}>
          Edit RFP
        </Button>
      )}
    </VStack>
  );
};

export default RfpCard;
