import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { authApi } from "../api/auth";
import { carrierApi } from "../api/carrier";
import { documentsApi } from "../api/documents";
import { networkApi } from "../api/network";
import { shipperApi } from "../api/shipper";
import { invoiceApi } from "../api/invoices";
import { shipmentApi } from "../api/shipment";
import { loadApi } from "../api/load";
import { driverApi } from "../api/driver";
import { locationHistoryApi } from "../api/locationHistory";
import { bidApi } from "../api/bid";
import { userApi } from "../api/user";
import { rfpApi } from "../api/rfp";
import { shipperCommentsApi } from "../api/shipperComment";
import authReducer, { authSlice } from "./authSlice";
import userReducer, { userSlice } from "./userSlice";
import { tilledApi } from "../api/tilled";
import { quickpayApi } from "../api/quickpay";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [authSlice.name]: authReducer,
    [userApi.reducerPath]: userApi.reducer,
    [userSlice.name]: userReducer,
    [carrierApi.reducerPath]: carrierApi.reducer,
    [shipperApi.reducerPath]: shipperApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [shipmentApi.reducerPath]: shipmentApi.reducer,
    [loadApi.reducerPath]: loadApi.reducer,
    [driverApi.reducerPath]: driverApi.reducer,
    [locationHistoryApi.reducerPath]: locationHistoryApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [networkApi.reducerPath]: networkApi.reducer,
    [bidApi.reducerPath]: bidApi.reducer,
    [rfpApi.reducerPath]: rfpApi.reducer,
    [shipperCommentsApi.reducerPath]: shipperCommentsApi.reducer,
    [tilledApi.reducerPath]: tilledApi.reducer,
    [quickpayApi.reducerPath]: quickpayApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authApi.middleware)
      .concat(userApi.middleware)
      .concat(carrierApi.middleware)
      .concat(shipperApi.middleware)
      .concat(invoiceApi.middleware)
      .concat(shipmentApi.middleware)
      .concat(loadApi.middleware)
      .concat(driverApi.middleware)
      .concat(locationHistoryApi.middleware)
      .concat(documentsApi.middleware)
      .concat(networkApi.middleware)
      .concat(bidApi.middleware)
      .concat(rfpApi.middleware)
      .concat(shipperCommentsApi.middleware)
      .concat(tilledApi.middleware)
      .concat(quickpayApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
