import { Button, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CarrierAccountFormProps } from "../../types/CarrierAccountForm";
import {
  useCreateTilledCarrierMutation,
  useLazyGetTilledCarrierAccountQuery,
} from "../../api/carrier";
import { TilledOnboardingForm } from "./TilledOnboardingForm";
import { OnboardingForm, useGetOnboardingFormMutation } from "../../api/tilled";
import { PaymentAccountView } from "./PaymentAccountView";
import ComingSoon from "../../components/ComingSoon";

const showPaymentsPage = process.env.REACT_APP_SHOW_PAYMENTS_PAGE === "true";

export const PaymentsForm = ({
  carrierUser,
  handleUpdate,
  isUpdating,
}: CarrierAccountFormProps): JSX.Element => {
  const [getFormData, setFormData] = useState<OnboardingForm>({});
  const [createTilledCarrier] = useCreateTilledCarrierMutation();
  const [getOnboardingForm] = useGetOnboardingFormMutation();
  const [getTilledCarrier, { data: tilledCarrierAccount }] =
    useLazyGetTilledCarrierAccountQuery();

  const isTilledIdPresent = !(
    carrierUser?.tilledId === undefined || carrierUser?.tilledId === null
  );

  useEffect(() => {
    if (isTilledIdPresent) {
      const getFormData = async (): Promise<void> => {
        const { data: tilledCarrier } = await getTilledCarrier(carrierUser.id);
        if (tilledCarrier?.bank_accounts.length) return;
        const formData = await getOnboardingForm(carrierUser!.tilledId!);
        if ("data" in formData) {
          setFormData(formData.data);
        }
      };
      void getFormData();
    }
  }, [carrierUser?.tilledId]);

  const onButtonClick = (): void => {
    void createTilledCarrier(carrierUser!.id);
  };

  // TODO: refactor to its own file
  const AddPaymentAccountButton = (): JSX.Element => (
    <Button layerStyle="yellow" onClick={onButtonClick} isLoading={isUpdating}>
      + Add payment account
    </Button>
  );

  const PaymentsTabBody = (): JSX.Element => {
    if (!isTilledIdPresent) return <AddPaymentAccountButton />;
    if (tilledCarrierAccount?.bank_accounts.length) {
      return <PaymentAccountView tilledCarrierAccount={tilledCarrierAccount} />;
    }
    return (
      <TilledOnboardingForm
        isUpdating
        formData={getFormData}
        carrierUser={carrierUser}
      />
    );
  };
  return (
    <>
      <Heading as="h1" marginBottom="4">
        Payment Account
      </Heading>
      {showPaymentsPage ? <PaymentsTabBody /> : <ComingSoon />}
    </>
  );
};

export default PaymentsForm;
