import { HStack, VStack, Text, Image, Button, Icon } from "@chakra-ui/react";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { Shipment } from "../../types/Shipment";
import ChevronSourcing from "../../resources/svg/chevron_sourcing.svg";
import { parseDateToLocalShort } from "../../reuse/Dates";
import "./ShipmentHistorySidebarCard.scss";
import {
  getShipmentWinningBid,
  getShipmentLowestBid,
} from "../../reuse/Shipment";
import GoldRibbon from "../../resources/svg/gold_ribbon.svg";
import { useShipmentContext } from "../../pages/ShipmentCreate/ShipmentContext";

interface ShipmentHistorySidebarCardProps {
  shipment: Shipment;
}

const ShipmentHistorySidebarCard = ({
  shipment,
}: ShipmentHistorySidebarCardProps): JSX.Element => {
  const shipmentContext = useShipmentContext();
  const { setShipmentToCopy } = shipmentContext!;

  const lowestBid = getShipmentLowestBid(shipment);
  const winningBid = getShipmentWinningBid(shipment);

  const ShipmentFirstLeg = (): JSX.Element => (
    <HStack textAlign="center">
      <Text fontSize="20px" color="black">
        {shipment.shipmentStops[0].address.city +
          `, ` +
          shipment.shipmentStops[0].address.state}
      </Text>
      <Image width={4} height={4} src={ChevronSourcing} />
      <Text fontSize="20px" color="black">
        {shipment.shipmentStops[shipment.shipmentStops.length - 1].address
          .city +
          `, ` +
          shipment.shipmentStops[shipment.shipmentStops.length - 1].address
            .state}
      </Text>
    </HStack>
  );

  return (
    <VStack
      alignItems="start"
      justifyContent="center"
      px="20px"
      py="0.25rem"
      h="fit-content"
      w="100%"
      layerStyle="shadow"
      spacing="0.1rem"
      className="shipment-history-sidebar-card"
    >
      <HStack minH="32px" w="100%" justifyContent="space-between">
        {ShipmentFirstLeg()}
        <Text textStyle="card" fontSize="20px" color="mvmntRed">
          {shipment.bids ? shipment.bids.length : 0}
        </Text>
      </HStack>
      <Text textStyle="card">
        Created on {parseDateToLocalShort(shipment.createDate)}
      </Text>

      <HStack>
        <Icon as={ArrowDownIcon} color="mvmntRed" />
        <Text>
          {lowestBid
            ? `$${lowestBid.totalCost.toLocaleString()} by ${
                lowestBid.carrier.companyName
              }`
            : "-"}
        </Text>
      </HStack>

      <HStack>
        <Image src={GoldRibbon} />
        <Text>{winningBid ? `${winningBid.carrier.companyName}` : "-"}</Text>
      </HStack>

      <Button
        fontSize={12}
        h={"20px"}
        alignSelf="end"
        onClick={() => setShipmentToCopy(shipment)}
      >
        Copy Shipment
      </Button>
    </VStack>
  );
};

export default ShipmentHistorySidebarCard;
