import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalBody,
  HStack,
  Input,
  Select,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useGetNetworkCarriersQuery } from "../../../api/network";
import { CarrierUser } from "../../../types/Carrier";
import { DefaultModalProps } from "../../../types/DefaultModal";
import {
  ConfirmedStatuses,
  NetworkPartner,
} from "../../../types/NetworkPartner";
import useToastHook from "../../../components/useToastHook";

export interface TenderSelectionModalProps extends DefaultModalProps {
  onContinue: (carrier: CarrierUser, flatRate: number) => void;
}

export const TenderSelectionModal = ({
  isOpen,
  onClose,
  onContinue,
}: TenderSelectionModalProps): JSX.Element => {
  const [rate, setRate] = useState("");
  const [carrierId, setCarrierId] = useState<string>();
  const { data: network, isFetching } = useGetNetworkCarriersQuery();

  const canCarrierBeTendered = (partner: NetworkPartner): boolean =>
    ConfirmedStatuses.includes(partner.connectionStatus) && !!partner.carrier;

  const tenderableCarriers =
    useMemo(
      () => network?.filter((partner) => canCarrierBeTendered(partner)),
      [network],
    ) ?? [];

  const networkPartnersExists = (): boolean => tenderableCarriers.length > 0;

  const toast = useToastHook();

  useEffect(() => {
    if (tenderableCarriers.length > 0) {
      setCarrierId(tenderableCarriers[0].carrier?.id);
    }
  }, [tenderableCarriers]);

  const onClickContinue = (): void => {
    const parsedRate = parseFloat(rate);
    if (!parsedRate || parsedRate < 0) {
      toast.error({ description: "Invalid rate" });
      return;
    }
    const partner = tenderableCarriers?.find(
      (partner) => partner.carrier?.id === carrierId,
    );
    if (!partner?.carrier) {
      toast.error({ description: "Please select a carrier" });
      return;
    }
    onContinue(partner.carrier, parsedRate);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        {/* TODO: Change skeleton for Loading state in form */}
        <Skeleton w="100%" isLoaded={!isFetching} p="1rem">
          <ModalHeader textStyle="uppercase">Select a Carrier</ModalHeader>
          <ModalBody>
            {networkPartnersExists() ? (
              <Select
                value={carrierId}
                onChange={(e) => setCarrierId(e.target.value)}
              >
                {tenderableCarriers?.map((partner) => (
                  <option key={partner.id} value={partner.carrier?.id}>
                    {partner.carrier?.companyName}
                  </option>
                ))}
              </Select>
            ) : (
              <Text>There are no in-network carriers</Text>
            )}
          </ModalBody>
          <ModalHeader textStyle="uppercase">Contracted Rate</ModalHeader>
          <ModalBody>
            <HStack>
              <Input
                value={rate}
                onChange={(e) => setRate(e.target.value)}
                placeholder="$ Rate"
                disabled={!networkPartnersExists()}
              />
            </HStack>
          </ModalBody>
          <ModalFooter justifyContent="space-around">
            <Button
              layerStyle="red"
              onClick={onClickContinue}
              disabled={!networkPartnersExists()}
            >
              Confirm
            </Button>
            <Button size="sm" color="mvmntRed" variant="link" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Skeleton>
      </ModalContent>
    </Modal>
  );
};

export default TenderSelectionModal;
