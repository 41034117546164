import { VStack, Text } from "@chakra-ui/react";
import { getUserName } from "../../reuse/UserData";
import mvmntTheme from "../../styles/theme";
import { ShipperUser } from "../../types/Shipper";

interface PartnerCardProps {
  onClick: () => void;
  shipper: ShipperUser;
  isSelected: boolean;
}

export const PartnerCard = ({
  shipper,
  isSelected,
  onClick,
}: PartnerCardProps): JSX.Element => {
  const { companyName, companyEmail, companyPhoneNumber } =
    shipper.contactInformation;

  return (
    <VStack
      alignItems="start"
      justifyContent="center"
      px="34px"
      py="0.25rem"
      h="fit-content"
      w="100%"
      layerStyle="shadow"
      spacing="0.1rem"
      onClick={onClick}
      cursor="pointer"
      backgroundColor={isSelected ? mvmntTheme.colors.selectedBlue : ""}
    >
      <Text textStyle="card" fontSize="22px">
        {companyName}
      </Text>
      <Text textStyle="card">{getUserName(shipper.contactInformation)}</Text>
      <Text textStyle="card">{companyEmail}</Text>
      <Text textStyle="card">{companyPhoneNumber}</Text>
      <Text textStyle="card">ID: {shipper.shipperCode}</Text>
      <Text textStyle="card">Type: Shipper</Text>
    </VStack>
  );
};

export default PartnerCard;
