import { VStack, HStack, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { sortShipmentBidsByPrice } from "../../../reuse/Shipment";
import { Bid } from "../../../types/Bid";
import { ShipmentStatus } from "../../../types/Shipment";
import BidCard from "../BidCard";
import BidsGroup from "../BidsGroup";
import { useUpdateShipmentStatusMutation } from "../../../api/shipment";
import { parseErrorResponse } from "../../../reuse/ErrorHandler";
import { useQuoteRequestsContext } from "../QuoteRequestsContext";
import { isRegularBid } from "../../../reuse/Bids";
import useToastHook from "../../../components/useToastHook";
import NoDataContainer from "../../../components/NoDataContainer";

const BidsTabContent = (): JSX.Element => {
  const context = useQuoteRequestsContext();
  const shipment = context?.selectedShipment;
  const bids = context?.selectedShipment?.bids ?? [];
  const toast = useToastHook();

  const [updateShipmentStatus] = useUpdateShipmentStatusMutation();

  const marketPlaceBids = (bids: Bid[]): Bid[] =>
    bids?.filter((b) => !isRegularBid(b));

  const marketPlaceBidsSorted = sortShipmentBidsByPrice(marketPlaceBids(bids));

  const regularBids = (bids: Bid[]): Bid[] =>
    bids?.filter((b) => isRegularBid(b));

  const onUpdateStatus = async (): Promise<void> => {
    if (!shipment) return;

    const newStatus =
      shipment.status === ShipmentStatus.ActiveQuote
        ? ShipmentStatus.InactiveQuote
        : ShipmentStatus.ActiveQuote;

    const response = await updateShipmentStatus({
      status: newStatus,
      id: shipment.id,
    });

    if ("data" in response) {
      context?.setSelectedShipment({
        ...shipment,
        status: newStatus,
      });
      toast.success({ description: "Status updated successfully" });
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  return shipment ? (
    <>
      {bids.length ? (
        <VStack alignSelf="center" w="60%" h="100%" padding="4">
          <HStack w="100%">
            <VStack spacing={0} alignItems="start" width="inherit">
              <Text fontSize={18}>{bids.length} bids</Text>
              <Text color="mvmntRed" fontSize={14}>
                Currently active
              </Text>
            </VStack>
            <Button
              textColor="white"
              bgColor="mvmntRed"
              size="small"
              w="60%"
              h={8}
              onClick={onUpdateStatus}
              disabled={
                shipment.winningBidId !== undefined || shipment.tendered
              }
            >
              {shipment.status === ShipmentStatus.ActiveQuote
                ? "Deactivate"
                : "Activate"}
            </Button>
          </HStack>
          {marketPlaceBids(bids).length > 0 && (
            <BidCard
              bid={marketPlaceBidsSorted[0]}
              marketBids={marketPlaceBids(bids)}
            />
          )}
          <BidsGroup bids={regularBids(bids)} />
        </VStack>
      ) : (
        <NoDataContainer text="No bids received for this shipment" />
      )}
    </>
  ) : (
    <></>
  );
};

export default BidsTabContent;
