import { Button } from "@chakra-ui/react";
import { selectUserData } from "../../../app/userSlice";
import { Shipment } from "../../../types/Shipment";
import { UserType } from "../../../types/User";
import { useAppSelector } from "../../../app/hooks";
import { useState } from "react";
import { ShipmentConfirmCancelModal } from "../../ShipmentCancelModal/ShipmentConfirmCancelModal";
import { useConfirmCancelShipmentMutation } from "../../../api/shipment";

interface InfoButtonProps {
  shipment: Shipment;
}

const InfoButton = ({ shipment }: InfoButtonProps): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const user = useAppSelector(selectUserData);
  const userIsCarrier = user.type === UserType.CARRIER;

  const [cancelShipment, { isLoading: isCancelLoading }] =
    useConfirmCancelShipmentMutation();

  return (
    <>
      <Button
        layerStyle={userIsCarrier ? "inverseRed" : "red"}
        disabled={userIsCarrier}
        onClick={() => setOpenModal(true)}
        size="small"
      >
        Pending Cancellation
      </Button>
      {openModal && (
        <ShipmentConfirmCancelModal
          shipment={shipment}
          onConfirm={async () => {
            await cancelShipment({
              shipmentId: shipment.id,
            });
            // TODO handle responses when we move away from the
            // invalidate tags pattern
          }}
          onClose={() => setOpenModal(false)}
          isOpen={true}
          isLoading={isCancelLoading}
        />
      )}
    </>
  );
};

export default InfoButton;
