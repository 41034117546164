import { VStack, HStack, Skeleton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useGetQuoteRequestsQuery } from "../../api/shipment";
import NoDataContainer from "../../components/NoDataContainer";
import { Shipment } from "../../types/Shipment";
import { TopBarHeight } from "../Shell/Shell";
import QuoteRequestDetail from "./QuoteRequestDetail";
import { QuoteRequestsContext } from "./QuoteRequestsContext";
import QuoteRequestSidebar from "./QuoteRequestSidebar";

export const CarrierQuoteRequests = (): JSX.Element => {
  const [selectedQuote, setSelectedQuote] = useState<Shipment>();
  const { data: quotes, isFetching } = useGetQuoteRequestsQuery();

  useEffect(() => {
    if (quotes && quotes.length > 0) {
      setSelectedQuote(quotes[0]);
    }
  }, [quotes]);

  return (
    <QuoteRequestsContext.Provider value={{ selectedQuote, setSelectedQuote }}>
      <Skeleton w="100%" isLoaded={!isFetching}>
        <HStack
          h={`calc(100vh - ${TopBarHeight})`}
          w="100%"
          alignItems="start"
          spacing={0}
        >
          <QuoteRequestSidebar quotes={quotes} />
          <VStack h="100%" w="100%" bgColor="grey" overflowY="auto" spacing={0}>
            {selectedQuote ? (
              <QuoteRequestDetail quote={selectedQuote} />
            ) : (
              <NoDataContainer />
            )}
          </VStack>
        </HStack>
      </Skeleton>
    </QuoteRequestsContext.Provider>
  );
};

export default CarrierQuoteRequests;
