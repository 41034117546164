import { Heading, HeadingProps, Text } from "@chakra-ui/react";

export type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
export interface HeaderProps extends HeadingProps {
  headingLevel?: HeadingLevel;
  headingText: string;
  textClassName?: string;
}

export const Header = ({
  headingLevel,
  headingText,
  textClassName,
  ...props
}: HeaderProps): JSX.Element => (
  <Heading {...props} as={headingLevel}>
    <Text className={textClassName}>{headingText}</Text>
  </Heading>
);

export default Header;
