import { EntityType } from "../../types/EntityType";
import { OperationClass } from "../../types/OperationClass";
import { CarrierOperation } from "../../types/CarrierOperation";
import { CargoType } from "../../types/CargoType";
import { FmcsaCarrier } from "../../types/FmcsaCarrier";

interface DataProps {
  field: string;
  value: string | number | undefined | Date | boolean | string[];
}

function getEntityTypeKeyByValue<EnumType>(
  enumObj: EnumType,
  str: string,
): EnumType {
  const indexOf = Object.values(enumObj).indexOf(str as unknown as EnumType);
  const key = Object.keys(enumObj)[indexOf] as unknown as EnumType;
  return key;
}

export const exportNames = (fmcsaCarrier: FmcsaCarrier): DataProps[] => {
  const entityTypes = [];
  for (const et of fmcsaCarrier.entityType!) {
    const etVal = getEntityTypeKeyByValue(EntityType, et);
    entityTypes.push(etVal);
  }
  const entityType = entityTypes.join(", ");
  const active = fmcsaCarrier.isActive ? "Active " : "Inactive";

  return [
    { field: "Type", value: entityType.replace(/([A-Z])/g, " $1").trim() },
    { field: "Status", value: active },
    { field: "Legal Name", value: fmcsaCarrier.legalName },
    { field: "DNA Name", value: fmcsaCarrier.dbaName },
    { field: "Phone", value: fmcsaCarrier.telephoneNumber },
  ];
};

export const exportPhysicalAddress = (
  fmcsaCarrier: FmcsaCarrier,
): DataProps[] => {
  return [
    { field: "City", value: fmcsaCarrier.physicalCity },
    { field: "Street", value: fmcsaCarrier.physicalStreet },
    { field: "Nationality", value: fmcsaCarrier.physicalNationality },
    { field: "State Code", value: fmcsaCarrier.physicalStateCode },
    { field: "Zip Code", value: fmcsaCarrier.physicalZipCode },
  ];
};

export const exportMailingAddress = (
  fmcsaCarrier: FmcsaCarrier,
): DataProps[] => {
  return [
    { field: "City", value: fmcsaCarrier.mailingCity },
    { field: "Street", value: fmcsaCarrier.mailingStreet },
    { field: "Nationality", value: fmcsaCarrier.mailingNationality },
    { field: "State Code", value: fmcsaCarrier.mailingStateCode },
    { field: "Zip Code", value: fmcsaCarrier.mailingZipCode },
  ];
};

const getValue = (compare: string, value?: Date | number): string => {
  if (!value) return "---";
  const currentValue = value?.toString() !== compare;
  const data = currentValue ? value?.toString() : " --- ";
  return data;
};

export const exportOperations = (fmcsaCarrier: FmcsaCarrier): DataProps[] => {
  const mcs150Date = getValue("1899-12-31", fmcsaCarrier.mcs150Date);
  const mcs150MilDate = getValue("0", fmcsaCarrier.mcs150MileageYear);

  const classifications = [];
  for (const et of fmcsaCarrier.classification!) {
    const etVal = getEntityTypeKeyByValue(OperationClass, et);
    classifications.push(etVal);
  }
  const classification = classifications.join(", ");

  const operations = [];
  for (const et of fmcsaCarrier.operation!) {
    const etVal = getEntityTypeKeyByValue(CarrierOperation, et);
    operations.push(etVal);
  }
  const operation = operations.join(", ");

  const cargos = [];
  for (const et of fmcsaCarrier.cargoCarried!) {
    const etVal = getEntityTypeKeyByValue(CargoType, et);
    cargos.push(etVal);
  }
  const cargoCarried = cargos.join(", ");

  return [
    { field: "MCS 150", value: mcs150Date },
    { field: "MC 150 Mileage", value: mcs150MilDate },
    {
      field: "Classification",
      value: classification.replace(/([A-Z])/g, " $1").trim(),
    },
    { field: "Operation", value: operation.replace(/([A-Z])/g, " $1").trim() },
    { field: "Cargo", value: cargoCarried.replace(/([A-Z])/g, " $1").trim() },
  ];
};

export const exportTotals = (fmcsaCarrier: FmcsaCarrier): DataProps[] => {
  return [
    { field: "US DOT", value: fmcsaCarrier.DOTNumber },
    { field: "MC/MX/FF", value: fmcsaCarrier.mcNumber },
    { field: "Power Units", value: fmcsaCarrier.totalPowerUnits },
    { field: "DUNS", value: fmcsaCarrier.dunBradstreetnumber },
    { field: "DRIVERS", value: fmcsaCarrier.totalDrivers },
  ];
};
