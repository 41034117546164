import { Text, Flex } from "@chakra-ui/react";

export const EmptySection = ({ title }: { title: string }): JSX.Element => {
  return (
    <Flex h="100%" w="100%" alignItems="center" justifyContent="center">
      <Text>{title}</Text>
    </Flex>
  );
};

export default EmptySection;
