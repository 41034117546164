import { Button, SimpleGrid, VStack } from "@chakra-ui/react";

export interface ButtonGridButtons {
  color: string;
  text: string | (() => string);
  minW?: string;
  h?: string;
  fontSize?: number | string;
  textColor?: string;
  onClick?: (() => void) | (() => Promise<void>);
  condition?: boolean | (() => boolean);
}

interface ButtonGridProps {
  buttons: ButtonGridButtons[];
  parent: string; // here to specify key easier
  columns: number;
}

export const ButtonGrid = ({
  buttons,
  parent,
  columns,
}: ButtonGridProps): JSX.Element => {
  return (
    <VStack paddingLeft="0.8rem">
      <SimpleGrid columns={columns} spacingX={2} spacingY={5}>
        {buttons.map((button, index) => {
          return (
            (typeof button.condition === "boolean"
              ? button.condition
              : button.condition?.() ?? true) && (
              <Button
                key={`${parent}-ButtonGrid-${index}`}
                layerStyle={button.color}
                minW={button.minW}
                w="90%"
                h={button.h}
                size="xSmall"
                textColor={button.textColor}
                onClick={() => button.onClick?.()}
              >
                {typeof button.text === "string" ||
                button.text instanceof String
                  ? button.text
                  : button.text()}
              </Button>
            )
          );
        })}
      </SimpleGrid>
    </VStack>
  );
};

export default ButtonGrid;
