import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Auth } from "aws-amplify";
import { setCurrentUserToken } from "../app/authSlice";

interface UserSignUp {
  password: string;
  email: string;
  companyPhoneNumber: string;
}

interface UserSignIn {
  username: string;
  password: string;
}

export interface UserChangePassword {
  password: string;
  newPassword: string;
  confirmPassword?: string;
}

export interface UserResetPassword {
  username: string;
  code: string;
  newPassword: string;
}

export interface UserUpdatePassword {
  username: string;
  tempPassword: string;
  newPassword: string;
}

export interface ResponseAuthError {
  status: number;
  data: string;
}

const generateErrorResponse = (
  e: unknown,
): {
  error: ResponseAuthError;
} => {
  const usernameExistsException = "UsernameExistsException";
  const nameReponse = JSON.parse(JSON.stringify(e));
  const duplicateUser = nameReponse.name === usernameExistsException;
  const userAlreadyExists = "User already exists.";
  const contactSuport =
    "Please use a new email address or contact support@mvmnt.io for additional help.";

  return {
    error: {
      status: duplicateUser ? 400 : 500,
      data: duplicateUser ? userAlreadyExists : contactSuport,
    },
  };
};

export const authApi = createApi({
  reducerPath: "authApiReducer",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    signUpUser: builder.mutation<string, UserSignUp>({
      queryFn: async (args, { dispatch }) => {
        const { password, email, companyPhoneNumber } = args;
        try {
          const response = await Auth.signUp({
            username: email,
            password,
            attributes: { email, phone_number: companyPhoneNumber },
          });
          const { userSub } = response;
          return { data: userSub };
        } catch (e) {
          return generateErrorResponse(e);
        }
      },
    }),
    loginUser: builder.mutation<null | string, UserSignIn>({
      queryFn: async (args, { dispatch }) => {
        const { username, password } = args;
        const signInResponse = await Auth.signIn(username, password);
        if (signInResponse.challengeName === "NEW_PASSWORD_REQUIRED") {
          return { data: signInResponse.challengeName };
        } else {
          const userSession = await Auth.currentSession();
          const accessToken = userSession.getIdToken();
          const jwt = accessToken.getJwtToken();
          dispatch(setCurrentUserToken(jwt));
          return { data: null };
        }
      },
    }),
    changePassword: builder.mutation<string, UserChangePassword>({
      queryFn: async (args) => {
        const { password, newPassword } = args;
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          await Auth.changePassword(cognitoUser, password, newPassword);
          return { data: "Password changed successfully" };
        } catch (e) {
          const { message } = e as { message: string };
          return { data: message };
        }
      },
    }),
    logoutUser: builder.mutation<null, void>({
      queryFn: async (_, { dispatch }) => {
        void Auth.signOut();
        dispatch(setCurrentUserToken(null));
        return { data: null };
      },
    }),
    forgotPassword: builder.mutation<null, string>({
      queryFn: async (username) => {
        await Auth.forgotPassword(username);
        return { data: null };
      },
    }),
    resetPassword: builder.mutation<boolean, UserResetPassword>({
      queryFn: async (args) => {
        const { username, code, newPassword } = args;
        try {
          await Auth.forgotPasswordSubmit(username, code, newPassword);
          return { data: true };
        } catch (e) {
          return { data: false };
        }
      },
    }),
    updatePassword: builder.mutation<boolean, UserUpdatePassword>({
      queryFn: async (args) => {
        const { username, tempPassword, newPassword } = args;
        try {
          // could be refactored to take user from the first sign in as an arg so you don't have to sign in twice but couldn't get it to work
          const user = await Auth.signIn(username, tempPassword);
          await Auth.completeNewPassword(user, newPassword);
          return { data: true };
        } catch (e) {
          return { data: false };
        }
      },
    }),
  }),
});

export const {
  useSignUpUserMutation,
  useLoginUserMutation,
  useChangePasswordMutation,
  useLogoutUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUpdatePasswordMutation,
} = authApi;
