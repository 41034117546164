import { Skeleton } from "@chakra-ui/react";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useGetCarrierQuery } from "../../api/carrier";
import { useCarrierSignUpBanner } from "./useCarrierSignUpBanner";
import ComingSoon from "../../components/ComingSoon";
import AppRoutes from "../../routes";
import CarrierAccount from "../CarrierAccount";
import CarrierActiveRFP from "../CarrierActiveRFP";
import CarrierDocuments from "../CarrierDocuments";
import CarrierHistoryRFP from "../CarrierHistoryRFP";
import CarrierMarketPlace from "../CarrierMarketPlace";
import CarrierNetwork from "../CarrierNetwork";
import CarrierQuoteRequests from "../CarrierQuoteRequests";
import CarrierRFPPage from "../CarrierRFP/CarrierRFPPage";
import PastShipment from "../PastShipment";
import Tracking from "../Tracking";
import { Invoices } from "../Invoices/Invoices";

const showRfpPage = process.env.REACT_APP_SHOW_RFP_PAGE === "true";
// TODO: use these when adding tracking and payment pages
const showTrackingPage = process.env.REACT_APP_SHOW_TRACKING_PAGE === "true";
const showPaymentsPage = process.env.REACT_APP_SHOW_PAYMENTS_PAGE === "true";
const useRFPRedesign = process.env.REACT_APP_USE_REDESIGNED_RFP === "true";

export const CarrierShell = (): JSX.Element => {
  const { isLoading, data: carrier } = useGetCarrierQuery();
  const carrierSignUpBanner = useCarrierSignUpBanner();
  useEffect(() => {
    if (!isLoading) carrierSignUpBanner(carrier);
  }, [isLoading]);
  return (
    <Skeleton isLoaded={!isLoading} w="100%" h="100%" d="flex" flexDir="column">
      <Routes>
        <Route path={AppRoutes.ACCOUNT_DETAILS} element={<CarrierAccount />} />
        <Route
          path={AppRoutes.MANAGE_DOCUMENTS}
          element={<CarrierDocuments />}
        />
        <Route path={AppRoutes.MANAGE_NETWORK} element={<CarrierNetwork />} />
        <Route
          path={AppRoutes.SOURCING_SEARCH}
          element={<CarrierMarketPlace />}
        />
        <Route
          path={AppRoutes.QUOTE_REQUESTS}
          element={<CarrierQuoteRequests />}
        />
        <Route
          path={AppRoutes.ACTIVE_RFP}
          element={showRfpPage ? <CarrierActiveRFP /> : <ComingSoon />}
        />
        <Route
          path={AppRoutes.HISTORY_RFP}
          element={showRfpPage ? <CarrierHistoryRFP /> : <ComingSoon />}
        />
        {useRFPRedesign && (
          <Route path={AppRoutes.RFP} element={<CarrierRFPPage />} />
        )}
        <Route
          path={AppRoutes.TRACKING}
          element={showTrackingPage ? <Tracking /> : <ComingSoon />}
        />
        <Route path={AppRoutes.PAST} element={<PastShipment />} />
        <Route
          path="invoices/*"
          element={showPaymentsPage ? <Invoices /> : <ComingSoon />}
        />
        <Route path="/*" element={<ComingSoon />} />
      </Routes>
    </Skeleton>
  );
};

export default CarrierShell;
