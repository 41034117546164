import { CrispWindow } from "../../types/CrispWindow";
import { useEffect } from "react";

declare let window: CrispWindow;
export const useCrispChatbotHook = (): void => {
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_COOKIE_EXPIRE = 3600;
    window.CRISP_WEBSITE_ID = "e555087f-f0ac-4686-a4c3-19adbd9414a7";

    const d = document;
    const s = d.createElement("script");

    s.src = "https://client.crisp.chat/l.js";
    s.async = true;
    d.getElementsByTagName("head")[0].appendChild(s);
  }, []);
};
