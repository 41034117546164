import mvmntTheme from "../styles/theme";
import { Bid } from "../types/Bid";
import { Shipment, ShipmentStop, ShipmentVisibility } from "../types/Shipment";
import { parseDateToLocal, transformDate } from "./Dates";

export const sortShipmentsByDate = (shipments: Shipment[]): Shipment[] =>
  shipments?.sort((shipmentA, shipmentB) =>
    new Date(shipmentA.shipmentStops[0].date) >
    new Date(shipmentB.shipmentStops[0].date)
      ? 1
      : -1,
  );

export const getShipmentStopsFirstLeg = (shipment: Shipment): string =>
  `${parseDateToLocal(shipment.shipmentStops[0].date)} - ${parseDateToLocal(
    shipment.shipmentStops[shipment.shipmentStops.length - 1].date,
  )}`;

export const sortShipmentBidsByPrice = (bids: Bid[]): Bid[] =>
  bids.sort((bidA: Bid, bidB: Bid) => bidA.totalCost - bidB.totalCost);

export const setBackgroundStyle = (
  id: string,
  seletedItemId?: string,
): string => {
  const selectedBlue: string = mvmntTheme.colors.selectedBlue;
  return id === seletedItemId ? selectedBlue : "";
};

export const getShipmentVisiblityLabel = (
  visiblity: ShipmentVisibility,
): string => {
  const map = {
    [ShipmentVisibility.All]: "Everyone",
    [ShipmentVisibility.InNetwork]: "Network Partners",
    [ShipmentVisibility.OutOfNetwork]: "Marketplace",
  };

  return map[visiblity];
};

export const getShipmentLowestBid = (shipment: Shipment): Bid | undefined => {
  if (shipment.bids && shipment.bids.length > 0) {
    return shipment.bids.reduce((prev, curr) => {
      return prev.totalCost < curr.totalCost ? prev : curr;
    });
  }
  return undefined;
};

export const getShipmentWinningBid = (shipment: Shipment): Bid | undefined => {
  if (shipment.bids && shipment.winningBidId && shipment.bids.length > 0) {
    return shipment.bids.find((bid) => bid.id === shipment.winningBidId);
  }
  return undefined;
};

export const getRatePerMileWinningBid = (
  shipment: Shipment,
): number | undefined | null => {
  return getShipmentWinningBid(shipment)?.ratePerMile;
};

export const getBidsSize = (shipment: Shipment): number => {
  return shipment.bids ? shipment.bids.length : 0;
};

export const getStopsSize = (shipment: Shipment): number => {
  return shipment.shipmentStops ? shipment.shipmentStops.length : 0;
};

export const getShipmentDates = ({ shipmentStops }: Shipment): string => {
  const { length } = shipmentStops;
  const { date: firstStopDate }: ShipmentStop = shipmentStops[0];
  const { date: lastStopDate }: ShipmentStop = shipmentStops[length - 1];
  return `(${transformDate(firstStopDate)} - ${transformDate(lastStopDate)})`;
};
