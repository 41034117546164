import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from "@chakra-ui/react";
import { toTitleCase } from "../../reuse/Strings";

export interface MultipleSelectProps<T> {
  options: string[];
  onChangeMultiple: (val: string[], filterKey: T) => void;
  filterKey: T;
}

export function MultipleSelectFilter<T>({
  options,
  onChangeMultiple,
  filterKey,
}: MultipleSelectProps<T>): JSX.Element {
  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            disabled={options.length === 0}
            as={Button}
            borderRadius="md"
            borderWidth="1px"
            fontWeight={200}
            rightIcon={<ChevronDownIcon />}
            textTransform="none"
            style={{ backgroundColor: "white" }}
          >
            {toTitleCase(String(filterKey))}
          </MenuButton>
          <MenuList minWidth="240px">
            <MenuOptionGroup
              type="checkbox"
              onChange={(val) => onChangeMultiple(val as string[], filterKey)}
            >
              {options.length > 0 &&
                options.map((o: string) => {
                  return (
                    <MenuItemOption
                      key={o}
                      value={o}
                      style={{ backgroundColor: "white" }}
                    >
                      {toTitleCase(o.toLowerCase())}
                    </MenuItemOption>
                  );
                })}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
}

export default MultipleSelectFilter;
