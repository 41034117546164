import { HStack, Button, Input, Skeleton, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  useAcceptTenderMutation,
  useCreateQuoteRequestBidMutation,
  useRejectTenderMutation,
  useUpdateBidMutation,
} from "../../../api/bid";
import RequestInformationModal from "../../../components/RequestInformationModal";
import {
  bidCanBeAccepted,
  bidCanBeUpdated,
  bidWasAccepted,
  bidWasRejected,
  quoteIsNew,
} from "../../../reuse/Bids";
import { parseErrorResponse } from "../../../reuse/ErrorHandler";
import { Shipment } from "../../../types/Shipment";
import useToastHook from "../../../components/useToastHook";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../../routes";

export const QuoteDetailsFooter = (quote: Shipment): JSX.Element => {
  const { bids } = quote;
  const [submitBid, { isLoading: isSubmitting }] =
    useCreateQuoteRequestBidMutation();
  const [acceptTender, { isLoading: isAccepting }] = useAcceptTenderMutation();
  const [rejectTender, { isLoading: isRejecting }] = useRejectTenderMutation();
  const [updateBid, { isLoading: isUpdating }] = useUpdateBidMutation();
  const [bid, setBid] = useState("");
  const [isInformationOpen, toggleInformationOpen] = useState(false);
  const toast = useToastHook();
  const navigate = useNavigate();

  useEffect(() => {
    if (bids?.[0]?.flatRate) {
      setBid(bids[0].flatRate.toString());
    } else {
      setBid("");
    }
  }, [bids]);

  const onSubmitBid = async (): Promise<void> => {
    const parsedBid = parseFloat(bid);
    if (!parsedBid || parsedBid < 0) {
      toast.error({ description: "Invalid rate" });
      return;
    }
    const response = await submitBid({
      shipmentId: quote.id,
      flatRate: parsedBid,
    });
    if ("data" in response) {
      toast.success({ description: "Bid submitted successfuly" });
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onUpdateBid = async (): Promise<void> => {
    if (!bids || bids.length === 0) return;
    const parsedBid = parseFloat(bid);
    if (!parsedBid || parsedBid < 0) {
      toast.error({ description: "Invalid rate" });
      return;
    }
    const response = await updateBid({
      bidId: bids[0].id,
      flatRate: parsedBid,
    });
    if ("data" in response) {
      toast.success({ description: "Bid updated successfuly" });
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onAcceptTender = async (): Promise<void> => {
    if (!bids || bids.length === 0) return;
    const response = await acceptTender({
      shipmentId: quote.id,
      bidId: bids[0].id,
    });
    if ("data" in response) {
      toast.success({
        description: "Tender accepted successfuly. Redirecting to Tracking",
      });

      setTimeout(() => navigate(AppRoutes.TRACKING), 3000);
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onRejectTender = async (): Promise<void> => {
    if (!bids || bids.length === 0) return;
    const response = await rejectTender({
      shipmentId: quote.id,
      bidId: bids[0].id,
    });
    if ("data" in response) {
      toast.success({ description: "Tender rejected successfuly" });
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  return (
    /* TODO: Don't use skeleton here, change the form to have a loading state */
    <Skeleton
      w="100%"
      isLoaded={!isAccepting && !isRejecting && !isUpdating && !isSubmitting}
    >
      <HStack w="100%" mt="3vh">
        {(bidCanBeUpdated(quote, bids) ||
          quoteIsNew(bids) ||
          bidCanBeAccepted(quote, bids)) && (
          <Button
            layerStyle="yellow"
            me="auto !important"
            size="small"
            onClick={() => toggleInformationOpen(true)}
          >
            Request More Info
          </Button>
        )}
        {(bidCanBeUpdated(quote, bids) || quoteIsNew(bids)) && (
          <Input
            placeholder="Bid: "
            value={bid}
            onChange={(e) => setBid(e.target.value)}
            w="10rem"
            type="number"
          />
        )}
        {bidCanBeAccepted(quote, bids) && (
          <>
            <Input
              value={`Bid: $${bids?.[0].totalCost}`}
              isDisabled
              w="10rem"
            />
            <Button layerStyle="black" size="small" onClick={onRejectTender}>
              Reject Tender
            </Button>
          </>
        )}
        {bidCanBeUpdated(quote, bids) ? (
          <Button layerStyle="red" size="small" onClick={onUpdateBid}>
            Update Bid
          </Button>
        ) : bidCanBeAccepted(quote, bids) ? (
          <Button layerStyle="red" size="small" onClick={onAcceptTender}>
            Accept Tender
          </Button>
        ) : quoteIsNew(bids) ? (
          <Button layerStyle="red" size="small" onClick={onSubmitBid}>
            Submit Bid
          </Button>
        ) : null}
        {bidWasAccepted(bids) ? (
          <Text color="mvmntGreen" textStyle="title" textTransform="uppercase">
            Bid Accepted
          </Text>
        ) : bidWasRejected(bids) ? (
          <Text color="mvmntRed" textStyle="title" textTransform="uppercase">
            Bid Rejected
          </Text>
        ) : null}
      </HStack>
      <RequestInformationModal
        isOpen={isInformationOpen}
        onClose={() => toggleInformationOpen(false)}
        shipmentId={quote.id}
      />
    </Skeleton>
  );
};

export default QuoteDetailsFooter;
