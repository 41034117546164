import {
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  Image,
  Text,
  Button,
  ModalFooter,
} from "@chakra-ui/react";
import { DefaultModalProps } from "../../types/DefaultModal";
import ChevronSourcing from "../../resources/svg/chevron_sourcing.svg";
import { DriverInfo, LocationHistory } from "../../types/Shipment";
import { useLazyGetDriverDocumentsUrlQuery } from "../../api/documents";
import {
  IShipmentDocument,
  ShipmentDocumentType,
} from "../../types/ShipmentDocument";
import { useTrackingContext } from "../../pages/Tracking/TrackingContext";
import mvmntTheme from "../../styles/theme";

interface DriverInfoModalProps extends DefaultModalProps {
  driver?: DriverInfo;
  shipmentDocs?: IShipmentDocument[];
  location?: LocationHistory;
}

export const DriverInfoModal = ({
  onClose,
  isOpen,
  driver,
  shipmentDocs,
  location,
}: DriverInfoModalProps): JSX.Element => {
  const trackingContext = useTrackingContext();
  const { setShowDriverInfo } = trackingContext!;

  const [getDriverDocumentsUrlById] = useLazyGetDriverDocumentsUrlQuery();

  const isDocumentPresent = (docType: ShipmentDocumentType): boolean => {
    return !!shipmentDocs?.some((x) => x.documentType === docType);
  };

  const showHazmatDownload = isDocumentPresent(
    ShipmentDocumentType.HazmatCertificate,
  );
  const showOverdimensionalDownload = isDocumentPresent(
    ShipmentDocumentType.OverdimensionalPermit,
  );
  const showOtherDownload = isDocumentPresent(ShipmentDocumentType.OtherPermit);

  const DriverInfoHeader = (header: string): JSX.Element => (
    <HStack
      borderBottomWidth={1}
      borderBottomStyle="solid"
      borderBottomColor="#707070"
      marginLeft={5}
      marginRight={5}
    >
      <VStack>
        <ModalHeader
          paddingBottom="0px !important"
          paddingLeft="0px !important"
          textStyle="uppercase"
        >
          {header}
        </ModalHeader>
      </VStack>
      <VStack alignSelf="end">
        <Image width={8} height={8} src={ChevronSourcing} />
      </VStack>
    </HStack>
  );

  const DriverInfoDetails = (): JSX.Element => {
    const hasAddress = !!location?.city && !!location?.state;
    const address = `${location?.city}, ${location?.state}`;
    const fullname = driver ? driver.firstName + " " + driver.lastName : "";
    const titleColor = mvmntTheme.textStyles.card.color;

    return (
      <VStack w="100%">
        <HStack w="100%" justifyContent="space-between">
          <Text textStyle="subTitle" fontSize={18}>
            Name
          </Text>
          <Text color={titleColor}>{fullname}</Text>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Text textStyle="subTitle" fontSize={18}>
            Email
          </Text>
          <Text color={titleColor}>{driver?.email ?? "---"}</Text>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Text textStyle="subTitle" fontSize={18}>
            Phone
          </Text>
          <Text color={titleColor}>{driver?.phone ?? "---"}</Text>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Text textStyle="subTitle" fontSize={18}>
            Current Location
          </Text>
          <Text color={titleColor}>{hasAddress ? address : "---"}</Text>
        </HStack>
        <HStack w="100%" justifyContent="space-between">
          <Text textStyle="subTitle" fontSize={18}>
            ETA to Pickup
          </Text>
          <Text color={titleColor}>{location?.etaToStop ?? "---"}</Text>
        </HStack>
      </VStack>
    );
  };

  const handleOnClick = async (
    docType: ShipmentDocumentType,
  ): Promise<void> => {
    const id = shipmentDocs?.find((x) => x.documentType === docType)?.id;
    if (id) {
      const response = await getDriverDocumentsUrlById(id);
      if ("data" in response) {
        window.open(response.data!);
      }
    }
  };

  const downloadHazmat = (): JSX.Element =>
    showHazmatDownload ? (
      <Button
        layerStyle="white"
        textColor="mvmntRed"
        onClick={async () =>
          await handleOnClick(ShipmentDocumentType.HazmatCertificate)
        }
      >
        Download Hazmat Certificate
      </Button>
    ) : (
      <></>
    );

  const downloadOverdimensional = (): JSX.Element =>
    showOverdimensionalDownload ? (
      <Button
        layerStyle="white"
        textColor="mvmntRed"
        onClick={async () =>
          await handleOnClick(ShipmentDocumentType.OverdimensionalPermit)
        }
      >
        Download Overdimensional Permit
      </Button>
    ) : (
      <></>
    );

  const downloadOther = (): JSX.Element =>
    showOtherDownload ? (
      <Button
        layerStyle="white"
        textColor="mvmntRed"
        onClick={async () =>
          await handleOnClick(ShipmentDocumentType.OtherPermit)
        }
      >
        Download Other Permit
      </Button>
    ) : (
      <></>
    );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {DriverInfoHeader("DRIVER INFORMATION")}
        <ModalBody>
          <HStack spacing={24}>{DriverInfoDetails()}</HStack>
        </ModalBody>
        {DriverInfoHeader("Documents")}
        <ModalBody>
          {downloadHazmat()}
          {downloadOverdimensional()}
          {downloadOther()}
        </ModalBody>
        <ModalFooter justifyContent="end">
          <HStack>
            <Button
              layerStyle="white"
              textColor="mvmntRed"
              minW="120px"
              h="30px"
              fontSize={12}
              onClick={() => setShowDriverInfo?.(false)}
            >
              Close
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DriverInfoModal;
