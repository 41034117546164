import { createContext, useContext } from "react";
import { Shipment } from "../../types/Shipment";

interface MarketPlace {
  shipments: Shipment[];
  setShipments: (shipments: Shipment[]) => void;
}

const MarketPlaceContext = createContext<MarketPlace | null>(null);

const useMarketPlaceContext = (): MarketPlace | null =>
  useContext(MarketPlaceContext);

export { MarketPlaceContext, useMarketPlaceContext };
