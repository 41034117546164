import { Divider } from "@chakra-ui/layout";
import { Text, VStack, HStack } from "@chakra-ui/react";
import ChevronIcon from "../ChevronIcon";

interface DataProps {
  field: string;
  value: string | number | undefined | Date | boolean | string[];
}

interface TableProps {
  title: string;
  data: DataProps[];
}

export const FmcsaCarrierTable = ({ title, data }: TableProps): JSX.Element => {
  return (
    <VStack w="100%">
      <HStack w="100%">
        <Text textStyle="subTitle" textAlign="start">
          {title}
        </Text>
        <ChevronIcon />
      </HStack>
      <Divider />
      {data.map((element, index) => (
        <HStack key={index} w="100%" justifyContent="space-between">
          <Text textStyle="label">{element.field}</Text>
          <Text>{element.value}</Text>
        </HStack>
      ))}
    </VStack>
  );
};

export default FmcsaCarrierTable;
