import {
  ArrowDownIcon,
  ArrowForwardIcon,
  EmailIcon,
  SettingsIcon,
} from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useLogoutUserMutation } from "../../api/auth";
import AppRoutes from "../../routes";

export const TopBarDropdown = (): JSX.Element => {
  const [signOut] = useLogoutUserMutation();
  const navigate = useNavigate();

  const onClickLogout = async (): Promise<void> => {
    await signOut();
    window.location.href = AppRoutes.LOGIN;
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Account Options"
        icon={<ArrowDownIcon />}
        size="sm"
      />
      <MenuList>
        <MenuItem
          icon={<SettingsIcon />}
          onClick={() => navigate(AppRoutes.ACCOUNT_DETAILS)}
        >
          Account Details
        </MenuItem>
        <MenuItem
          icon={<EmailIcon />}
          onClick={() => navigate(AppRoutes.MANAGE_DOCUMENTS)}
        >
          Manage Documents
        </MenuItem>
        <MenuItem icon={<ArrowForwardIcon />} onClick={onClickLogout}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default TopBarDropdown;
