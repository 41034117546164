import { Skeleton } from "@chakra-ui/react";
import { useGetCarrierHistoryRfpQuery } from "../../api/rfp";
import ActiveRfp from "../ActiveRfp";

export const CarrierHistoryRFP = (): JSX.Element => {
  const { data, isFetching } = useGetCarrierHistoryRfpQuery();

  return (
    <Skeleton isLoaded={!isFetching} w="100%" h="100%">
      <ActiveRfp data={data} />
    </Skeleton>
  );
};

export default CarrierHistoryRFP;
