import { HStack, VStack, Text } from "@chakra-ui/react";
import { useMemo, Fragment } from "react";
import ChevronIcon from "../../../components/ChevronIcon";
import { bidIsPending, bidWasSubmitted, quoteIsNew } from "../../../reuse/Bids";
import { parseDateToShortDate, transformDate } from "../../../reuse/Dates";
import {
  Shipment,
  ShipmentStopOrder,
  ShipmentStop,
} from "../../../types/Shipment";
import { useQuoteRequestsContext } from "../QuoteRequestsContext";

interface QuoteRequestCardProps {
  quote: Shipment;
}

interface StopProps {
  firstStop: ShipmentStop;
  lastStop: ShipmentStop;
}

const QuoteStops = ({ firstStop, lastStop }: StopProps): JSX.Element => {
  const { date: firstDate } = firstStop;
  const { date: lastDate } = lastStop;
  const initialDate = transformDate(firstDate);
  const finalDate = transformDate(lastDate);

  return (
    <Fragment>
      <Text fontSize="0.9rem">{`(${initialDate} - ${finalDate})`}</Text>
      <HStack w="100%" textAlign="center">
        <VStack>
          {" "}
          <Text>{firstStop.address.city}</Text>
          <Text>{firstStop.keyMarketArea}</Text>
        </VStack>
        <ChevronIcon />
        <VStack>
          {" "}
          <Text>{lastStop.address.city}</Text>
          <Text>{lastStop.keyMarketArea}</Text>
        </VStack>
      </HStack>
    </Fragment>
  );
};

export const QuoteRequestCard = ({
  quote,
}: QuoteRequestCardProps): JSX.Element => {
  const context = useQuoteRequestsContext();
  const { shipper, shipmentStops, bids } = quote;

  const firstStop = useMemo(
    () =>
      shipmentStops.find((stop) => stop.stopOrder === ShipmentStopOrder.First),
    [quote],
  );

  const lastStop = useMemo(
    () =>
      shipmentStops.find((stop) => stop.stopOrder === ShipmentStopOrder.Last),
    [quote],
  );

  return (
    <VStack
      alignItems="start"
      px="0.5rem"
      w="100%"
      borderBottom="1px solid white"
      pb="1rem"
      justifyContent="center"
      layerStyle="shadow"
      cursor="pointer"
      onClick={() => context?.setSelectedQuote(quote)}
      bgColor={quote === context?.selectedQuote ? "selectedBlue" : undefined}
    >
      <HStack w="100%" py="1rem" alignItems="baseline">
        <Text fontWeight="bold" fontSize="1.3rem" mr="auto">
          {shipper.contactInformation.companyName}
        </Text>

        {bidWasSubmitted(quote, bids) ? (
          <Text textStyle="card">
            {parseDateToShortDate(bids![0].createDate)}
          </Text>
        ) : bidIsPending(quote, bids) ? (
          <Text fontWeight="bold" textTransform="uppercase" color="yellow">
            Pending
          </Text>
        ) : quoteIsNew(bids) ? (
          <Text fontWeight="bold" textTransform="uppercase" color="mvmntRed">
            New
          </Text>
        ) : null}
      </HStack>
      <Text fontSize="1.2rem">{quote.equipmentType}</Text>
      <Text>Received on {parseDateToShortDate(quote.createDate)}</Text>
      {firstStop && lastStop && (
        <QuoteStops firstStop={firstStop} lastStop={lastStop} />
      )}
    </VStack>
  );
};

export default QuoteRequestCard;
