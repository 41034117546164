import { DocumentType } from "../../types/Files";

export const documentTypes = [
  {
    label: "FMCSA Motor Carrier Safety Rating Letter",
    value: DocumentType.MCSRL,
  },
  { label: "Signed W-9", value: DocumentType.W9 },
  {
    label: "Certificate of Liability Insurance",
    value: DocumentType.LiabilityInsurance,
  },
  {
    label: "FMCSA Carrier Name Change Decision",
    value: DocumentType.NameChange,
  },
  {
    label: "Certificate of Standard Carrier Alpha Code (SCAC) Renewal",
    value: DocumentType.SCACRenewal,
  },
  { label: "Change in Remittance Address", value: DocumentType.AddressChange },
  {
    label: "Interstate Commerce Commission Certificate",
    value: DocumentType.CommerceCert,
  },
  {
    label:
      "Alliance for Uniform HazMat Transportation Procedures Uniform Program Credentials",
    value: DocumentType.TransportCreds,
  },
  {
    label: "Factoring/Accounts Payable Assignment Notice or Letter",
    value: DocumentType.FactoringLetter,
  },
  { label: "Common Carrier Certificate", value: DocumentType.CommonCert },
  { label: "State Incorporation Certificate", value: DocumentType.StateIncorp },
  {
    label: "Contract Carrier of Property Permit",
    value: DocumentType.PropertyPerm,
  },
  { label: "SmartWay Partnership", value: DocumentType.SmartPart },
  { label: "Other (open text)", value: DocumentType.Other },
];
