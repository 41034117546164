import {
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { useState } from "react";
import { useQueryState, useUpdateShipperMutation } from "../../api/shipper";
import ChangePassword from "../../components/ChangePassword";
import ChevronIcon from "../../components/ChevronIcon";
import ShipperAccountsReceivable from "../../components/ShipperAccountsReceivable";
import ShipperContactInformation from "../../components/ShipperContactInformation";
import ShipperEmployees from "../../components/ShipperEmployees";
import ShipperInsurance from "../../components/ShipperInsurance";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { ShipperAccountData } from "../../types/ShipperAccountForm";
import useToastHook from "../../components/useToastHook";

export const ShipperAccount = (): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { data: shipperUser } = useQueryState();
  const [updateShipper, { isLoading: isUpdating }] = useUpdateShipperMutation();

  const toast = useToastHook();

  const handleUpdateAccount = async (
    accountData: ShipperAccountData,
  ): Promise<void> => {
    const response = await updateShipper(accountData);
    if ("data" in response) {
      toast.success({ description: "Account updated" });
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  return (
    <VStack alignItems="start" width="50%">
      <Tabs onChange={setSelectedTab}>
        <TabList>
          <Tab isDisabled={isUpdating}>
            Contact Information
            {selectedTab === 0 && <ChevronIcon ml="10px" />}
          </Tab>
          <Tab isDisabled={isUpdating}>
            Carrier Sourcing
            {selectedTab === 1 && <ChevronIcon ml="10px" />}
          </Tab>
          <Tab isDisabled={isUpdating}>
            Insurance Requirements
            {selectedTab === 2 && <ChevronIcon ml="10px" />}
          </Tab>
          <Tab isDisabled={isUpdating}>
            Accounts Receivable
            {selectedTab === 3 && <ChevronIcon ml="10px" />}
          </Tab>
          <Tab isDisabled={isUpdating}>
            Password
            {selectedTab === 4 && <ChevronIcon ml="10px" />}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel w="50vw">
            <ShipperContactInformation
              shipperUser={shipperUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>

          <TabPanel w="50vw">
            <ShipperEmployees
              shipperUser={shipperUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>

          <TabPanel w="50vw">
            <ShipperInsurance
              shipperUser={shipperUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>

          <TabPanel w="50vw">
            <ShipperAccountsReceivable
              shipperUser={shipperUser}
              handleUpdate={handleUpdateAccount}
              isUpdating={isUpdating}
            />
          </TabPanel>

          <TabPanel w="50vw">
            <ChangePassword />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default ShipperAccount;
