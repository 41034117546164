import { DownloadIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  HStack,
  Button,
  Link,
} from "@chakra-ui/react";

interface ReusableUploadProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  templateHref?: string | undefined;
  downloadFileName: string;
  exportFn?: () => void; // when exporting instead of downloading a template
  downloadButtonTxt: string;
  toggleFileSelectorFn: () => void;
  uploadButtonTxt: string;
  footerCpm?: () => JSX.Element;
}

export const BulkUploadModal = ({
  title,
  isOpen,
  onClose,
  templateHref,
  downloadButtonTxt,
  downloadFileName,
  footerCpm,
  toggleFileSelectorFn,
  uploadButtonTxt,
  exportFn,
}: ReusableUploadProps): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
      isCentered
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent p="1rem">
        <ModalHeader textStyle="uppercase" px="1.5rem" fontSize="25px">
          {title}
        </ModalHeader>
        <ModalBody>
          <HStack w="100%" justifyContent="space-between">
            <Link href={templateHref} download={downloadFileName}>
              <Button
                layerStyle="yellow"
                rightIcon={<DownloadIcon />}
                onClick={exportFn}
              >
                {downloadButtonTxt}
              </Button>
            </Link>
            <Button
              layerStyle="red"
              fontSize="15px"
              onClick={toggleFileSelectorFn}
            >
              {uploadButtonTxt}
            </Button>
          </HStack>
        </ModalBody>
        {footerCpm && (
          <ModalFooter justifyContent="start">{footerCpm()}</ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BulkUploadModal;
