import { VStack, Text } from "@chakra-ui/react";
import mvmntTheme from "../../styles/theme";
import { FmcsaCarrier } from "../../types/FmcsaCarrier";

interface PartnerPreviewCardProps {
  onClick: () => void;
  fmcsaCarrier: FmcsaCarrier;
  isSelected: boolean;
}

export const PartnerPreviewCard = ({
  fmcsaCarrier,
  isSelected,
  onClick,
}: PartnerPreviewCardProps): JSX.Element => {
  return (
    <VStack
      alignItems="start"
      justifyContent="center"
      px="34px"
      py="0.25rem"
      h="fit-content"
      w="100%"
      layerStyle="shadow"
      spacing="0.1rem"
      onClick={onClick}
      cursor="pointer"
      backgroundColor={isSelected ? mvmntTheme.colors.selectedBlue : ""}
    >
      <Text textStyle="card" fontSize="22px">
        {fmcsaCarrier.legalName}
      </Text>
      <Text textStyle="card">Contact: {fmcsaCarrier.companyRep1}</Text>
      <Text textStyle="card">DOT #: {fmcsaCarrier.DOTNumber}</Text>
    </VStack>
  );
};

export default PartnerPreviewCard;
