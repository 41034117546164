import { AppRoutesProps } from "../routes";

export const shipperDefaultView = (
  routes: AppRoutesProps,
  pathname: string,
): boolean => {
  const paths: string[] = [
    routes.ACCOUNT_DETAILS,
    routes.MANAGE_DOCUMENTS,
    routes.MANAGE_NETWORK,
    routes.SOURCING_SEARCH,
    routes.LOAD_BUILD,
    routes.CREATE_LOAD,
    routes.ACTIVE_QUOTES,
    routes.ACCOUNTS_PAYABLE,
  ];
  const currentPath = pathname;
  return paths.includes(currentPath);
};
