import { Divider, VStack } from "@chakra-ui/layout";
import { useState } from "react";
import DocumentUpload from "../../../components/DocumentUpload";
import SelectAgreement from "../../../components/SelectAgreement";
import { useTenderMutation, invalidateTags } from "../../../api/shipment";
import { Bid } from "../../../types/Bid";
import { parseErrorResponse } from "../../../reuse/ErrorHandler";
import InfoSection from "../InfoSection";
import MainSection from "../MainSection";
import { DocumentType } from "../../../types/Files";
import AlertModal from "../../../components/AlertModal";
import { ConnectionStatus } from "../../../types/NetworkPartner";
import TenderModal from "../../../components/TenderModal";
import QueueTenderModal from "../QueueTenderModal";
import { useQuoteRequestsContext } from "../QuoteRequestsContext";
import { useAppDispatch } from "../../../app/hooks";
import useToastHook from "../../../components/useToastHook";
import { BidContext } from "./BidContext";

interface BidProps {
  bid: Bid;
  marketBids?: Bid[];
}

const BidCard = ({ bid, marketBids }: BidProps): JSX.Element => {
  const [tender] = useTenderMutation();

  const dispatch = useAppDispatch();
  const [isShowingCards, setIsShowingCards] = useState(false);
  const onClickToggle = (): void => setIsShowingCards(!isShowingCards);

  const shouldInvalidateTags = (): void => {
    if (
      !bid.connectionStatus ||
      bid.connectionStatus === ConnectionStatus.NOTSENT ||
      bid.connectionStatus === ConnectionStatus.SIGNEDOFFLINE
    )
      dispatch(invalidateTags(["Shipment"]));
  };

  const context = useQuoteRequestsContext();
  const { selectedShipment } = context!;

  const [isAlertOpen, toggleAlertModal] = useState(false);
  const [isUploadOpen, toggleUploadModal] = useState(false);
  const [isSelectOpen, toggleSelectModal] = useState(false);
  const [isQueueOpen, toggleQueueModal] = useState(false);
  const [isTenderOpen, toggleTenderModal] = useState(false);

  const toast = useToastHook();

  const onSuccess = (): void => {
    if (!selectedShipment?.tendered) {
      toggleQueueModal(true);
    }
  };

  const onClickUpload = (): void => {
    toggleSelectModal(false);
    toggleUploadModal(true);
  };

  const onClickSetup = (): void => {
    if (bid.connectionStatus === ConnectionStatus.PENDING) {
      toggleAlertModal(true);
    } else if (
      !bid.connectionStatus ||
      bid.connectionStatus === ConnectionStatus.NOTSENT ||
      bid.connectionStatus === ConnectionStatus.SIGNEDOFFLINE
    ) {
      toggleSelectModal(true);
    }
  };

  const onCancelQueue = (): void => {
    toggleQueueModal(false);
    shouldInvalidateTags();
  };

  const onClickTender = (): void => {
    toggleQueueModal(false);
    toggleTenderModal(true);
  };

  const onCancelTender = (): void => {
    toggleTenderModal(false);
    shouldInvalidateTags();
  };

  const onConfirmTender = async (): Promise<void> => {
    const response = await tender({
      carrierId: bid.carrier.id,
      shipmentId: bid.shipmentId,
    });

    if ("data" in response) {
      toast.success({ description: "Tendered successfully" });
      toggleTenderModal(false);
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  return (
    <BidContext.Provider
      value={{
        onClickToggle,
        onClickSetup,
        onClickTender,
      }}
    >
      <VStack
        h="auto"
        bgColor={isShowingCards ? "#EDF6FF" : "#FFF"}
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.156257)"
        borderRadius="7px"
        mb={2}
        w="100%"
      >
        <MainSection bid={bid} marketBids={marketBids} />
        <Divider
          borderColor="#EDEDED"
          borderStyle="solid"
          borderWidth={1}
          w="full"
        />
        <InfoSection
          bid={bid}
          marketBids={marketBids}
          isShowingCards={isShowingCards}
        />
        <AlertModal
          title="Agreement Uploaded"
          content="An agreement has been uploaded already. Once it's been signed you will be able to tender"
          onClose={() => toggleAlertModal(false)}
          isOpen={isAlertOpen}
        />
        <DocumentUpload
          isOpen={isUploadOpen}
          onClose={() => toggleUploadModal(false)}
          documentOptions={[
            {
              label: "Shipper-Carrier Agreement",
              value: DocumentType.ShipperAgreement,
            },
          ]}
          networkId={bid.networkId}
          carrierDotNumber={bid.carrier.companyInformation.dotNumber}
          onSuccess={onSuccess}
        />
        <SelectAgreement
          isOpen={isSelectOpen}
          onClose={() => toggleSelectModal(false)}
          onClickUpload={onClickUpload}
          networkId={bid.networkId}
          carrierDotNumber={bid.carrier.companyInformation.dotNumber}
          onSuccess={onSuccess}
        />
        <QueueTenderModal
          isOpen={isQueueOpen}
          onClose={onCancelQueue}
          onTenderLater={onCancelQueue}
          onTender={onClickTender}
        />
        <TenderModal
          isOpen={isTenderOpen}
          onClose={onCancelTender}
          onConfirm={onConfirmTender}
          carrier={bid.carrier}
          shipment={selectedShipment!}
        />
      </VStack>
    </BidContext.Provider>
  );
};
export default BidCard;
