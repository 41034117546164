import { HStack, Text } from "@chakra-ui/react";
import ChevronIcon from "../ChevronIcon";

export const ComingSoon = (): JSX.Element => {
  return (
    <HStack p="2%">
      <ChevronIcon h="2rem" />
      <Text
        fontSize="3rem"
        textTransform="uppercase"
        color="yellow"
        fontWeight="bold"
      >
        Coming Soon
      </Text>
    </HStack>
  );
};

export default ComingSoon;
