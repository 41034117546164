import { Td, Input } from "@chakra-ui/react";

interface CellEditProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
  idx: number;
  field: string;
  disabled?: boolean;
  width?: string;
}

export const CellEdit = ({
  register,
  idx,
  field,
  disabled = false,
}: CellEditProps): JSX.Element => {
  return (
    <Td textAlign="center">
      <Input
        maxLength={3}
        type="number"
        w="90px"
        isDisabled={disabled}
        textAlign="center"
        {...register(`fieldArray.${idx}.${field}` as const)}
      />
    </Td>
  );
};
