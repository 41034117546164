import { useState } from "react";
import { CloseIcon, EmailIcon } from "@chakra-ui/icons";
import { Text, HStack, Box } from "@chakra-ui/react";
import Header from "../../../components/Header";
import { toTitleCase } from "../../../reuse/Strings";
import ShipperDetailsBreadcrumbs from "./ShipperDetailsBreadcrumbs";
import { getAccountIcon } from "../../../resources/svg/CustomIcons";
import AdminShipperAccountTabContainter from "../AdminShipperAccountTabContainter";
import { useManageUserContext } from "../ManageUserContext";
import { ShipperStatus, ShipperUser } from "../../../types/Shipper";
import ManageUserBtns from "../../../components/SharedTable/ManageUserBtns";
import SharedButton from "../../../components/SharedButton";
import {
  useRevokeShipperMutation,
  useApproveUserMutation,
} from "../../../api/shipper";
import useToastHook from "../../../components/useToastHook";
import { parseErrorResponse } from "../../../reuse/ErrorHandler";
import ReusableConfirmModal from "../../../components/ReusableConfirmModal";
import { appearanceApproveBtn, appearanceRevokeBtn } from "../constants";
import ReusableBadge from "../../../components/ReusableBadge";
import { getBadgeColorForShipperStatus } from "../../../reuse/Styles";

export const AdminShipperAccountPage = (): JSX.Element => {
  const toast = useToastHook();
  const context = useManageUserContext();
  const { selectedShipper } = context!;
  const [revokeShipper] = useRevokeShipperMutation();
  const [approveUser] = useApproveUserMutation();
  const [approveOrRejectModal, setApproveOrRejectModal] =
    useState<boolean>(false);

  const AccountIcon = getAccountIcon("#7B7B7B");

  const confirmApproveOrRevoke = async (): Promise<void> => {
    const response =
      ShipperStatus.APPROVED === selectedShipper?.status
        ? await revokeShipper(selectedShipper!.id)
        : await approveUser({
            id: selectedShipper!.id,
            email: selectedShipper!.contactInformation.companyEmail,
          });
    if ("data" in response) {
      toast.success({ description: `Shipper revoked successfully` });
      setTimeout(() => window.location.reload(), 500);
    } else {
      toast.error({ description: parseErrorResponse(response.error) });
    }
  };

  const openApproveOrRejectModal = (): void => setApproveOrRejectModal(true);

  const renderCTAs = (
    selectedShipper: ShipperUser | undefined,
    openApproveOrRejectModal: () => void,
  ): JSX.Element => {
    if (!selectedShipper) return <></>;

    const { status } = selectedShipper;
    switch (status) {
      case ShipperStatus.PENDING:
        return (
          <ManageUserBtns
            id={selectedShipper.id}
            email={selectedShipper.contactInformation.email}
            userType={selectedShipper.userType}
          />
        );
      case ShipperStatus.APPROVED:
        return (
          <SharedButton
            title="Revoke Access"
            textTransform="none"
            textColor="red"
            border="1px solid red"
            fontSize="0.8vw"
            leftIcon={<CloseIcon fontSize="0.6vw" />}
            onClick={openApproveOrRejectModal}
          />
        );
      case ShipperStatus.REJECTED:
        return (
          <SharedButton
            title="Approve"
            textTransform="none"
            textColor="mvmntGreen"
            border="1px solid green"
            fontSize="0.8vw"
            onClick={openApproveOrRejectModal}
            size="small"
            leftIcon={<CloseIcon fontSize="10" />}
          />
        );
      default:
        return <></>;
    }
  };

  const approvedUser: boolean =
    ShipperStatus.APPROVED === selectedShipper?.status;

  return (
    <Box w="100%" pl="4rem" mt={8}>
      <ShipperDetailsBreadcrumbs />
      <HStack justifyContent="space-between" mr={8}>
        <Header
          marginY="20px"
          color="grey1"
          headingText={`${selectedShipper?.contactInformation.firstName} ${selectedShipper?.contactInformation.lastName}`}
        />
        {renderCTAs(selectedShipper, openApproveOrRejectModal)}
      </HStack>
      {selectedShipper && (
        <HStack w="100%">
          <Text color="grey3" mr="20px">
            <EmailIcon mr="5px" />
            {selectedShipper.contactInformation.email}
          </Text>
          <Text color="grey3" display={"flex"} mr="20px">
            <AccountIcon
              mr="5px"
              h="100%"
              justifySelf={"center"}
              alignSelf="center"
            />
            {toTitleCase(selectedShipper.userType)}
          </Text>
          <ReusableBadge<ShipperStatus>
            currentStatus={selectedShipper.status}
            badgeColor={getBadgeColorForShipperStatus(selectedShipper.status)}
          />
        </HStack>
      )}
      <AdminShipperAccountTabContainter />
      <ReusableConfirmModal
        isOpen={approveOrRejectModal}
        onClose={() => setApproveOrRejectModal(false)}
        onClickCTA={confirmApproveOrRevoke}
        title={approvedUser ? "Revoke Access" : "Approve User"}
        content={`Are you sure you want to ${
          approvedUser ? "revoke" : "approve this previously rejected account"
        } : ${selectedShipper?.contactInformation.companyName} ?`}
        cancelText="Cancel"
        confirmText={approvedUser ? "Revoke Access" : "Approve Access"}
        appearanceConfirmBtn={
          approvedUser ? appearanceRevokeBtn : appearanceApproveBtn
        }
      />
    </Box>
  );
};

export default AdminShipperAccountPage;
