import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { headerBids } from "../../reuse/Rfp";
import { ILaneBid } from "../../types/Rfp";
import { HeadersLanesTable } from "../LanesProposalDetail/HeadersLanesTable";

interface LaneBidsTableProps {
  currentBids: ILaneBid[];
}

export const LaneBidsTable = ({
  currentBids,
}: LaneBidsTableProps): JSX.Element => {
  return (
    <Table variant="unstyled">
      <HeadersLanesTable backgroundColor={"#f6d306"} data={headerBids} />
      <Tbody>
        {currentBids?.map((bid) => (
          <Tr key={bid.id}>
            <Td textAlign="center" color="mvmntRed">
              {bid.carrierInfo.carrierName ?? "---"}
            </Td>
            <Td textAlign="center">
              {bid.carrierInfo.carrierDOTNumber ?? "---"}
            </Td>
            <Td textAlign="center">
              {bid.carrierInfo.carrierMCNumber ?? "---"}
            </Td>
            <Td textAlign="center">
              {bid.carrierInfo.carrierEmailAddress ?? "---"}
            </Td>
            <Td textAlign="center">{bid.minimumBid}</Td>
            <Td textAlign="center">{bid.effectiveBid}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default LaneBidsTable;
