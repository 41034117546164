import { HStack, VStack, Heading, Button } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/skeleton";
import { useNavigate } from "react-router-dom";
import { Shipment, ShipmentStatus } from "../../types/Shipment";
import AppRoutes from "../../routes";
import ShipperShipmentCard from "../ShipperShipmentCard";
import {
  getBidsSize,
  setBackgroundStyle,
  sortShipmentsByDate,
} from "../../reuse/Shipment";
import { useQuoteRequestsContext } from "../../pages/ShipperActiveQuoteRequests/QuoteRequestsContext";

interface SidebarProps {
  shipments: Shipment[];
}

const ShipperShipmentSidebar = ({ shipments }: SidebarProps): JSX.Element => {
  const context = useQuoteRequestsContext();
  const { selectedShipment } = context!;

  const navigate = useNavigate();
  const gotToCreateShipment = (): void => navigate(AppRoutes.LOAD_BUILD);

  const activeShipment = (ship: Shipment): boolean =>
    ship.status === ShipmentStatus.ActiveQuote;

  const activeQuotes = (): number =>
    shipments?.filter((ship) => activeShipment(ship)).length || 0;

  const shipmentsToDisplay = shipments.filter(
    (shipment) =>
      shipment.status === ShipmentStatus.ActiveQuote ||
      shipment.status === ShipmentStatus.InactiveQuote,
  );

  return (
    <Skeleton minW="20%" maxW="26%" h="100%" isLoaded={shipments.length > 0}>
      <VStack bgColor="white !important" h="100%">
        <HStack
          layerStyle="yellow"
          minH="32px"
          w="100%"
          justifyContent="start"
          px="15px"
        >
          <Heading size="md" textStyle="uppercase">
            Active Quotes
          </Heading>
          <Heading ml="auto !important" size="md">
            {activeQuotes()}
          </Heading>
        </HStack>
        <VStack
          w="100%"
          h="calc(100% - 120px)"
          overflowY="auto"
          mt="0 !important"
        >
          {sortShipmentsByDate(shipmentsToDisplay).map((shipment) => (
            <ShipperShipmentCard
              shipment={shipment}
              key={shipment.id}
              onClick={() => context?.setSelectedShipment(shipment)}
              backgroundStyle={setBackgroundStyle(
                shipment.id,
                selectedShipment?.id,
              )}
              counterValue={getBidsSize(shipment)}
              valueColor="mvmntRed"
            />
          ))}
        </VStack>
        <Button
          w="90%"
          style={{ marginTop: "1rem" }}
          size="medium"
          layerStyle="yellow"
          onClick={gotToCreateShipment}
        >
          Create New Shipment
        </Button>
      </VStack>
    </Skeleton>
  );
};

export default ShipperShipmentSidebar;
