import { CarrierUser } from "./Carrier";
import { ConnectionStatus } from "./NetworkPartner";

export enum BidStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
}

export enum BidSource {
  InNetwork = "In Network",
  Marketplace = "Marketplace",
  FromShipper = "From Shipper",
}

export interface Bid {
  id: string;
  shipmentId: string;
  carrier: CarrierUser;
  status: BidStatus;
  createDate: Date;
  ratePerMile?: number | null;
  gasSurchargePerMile?: number | null;
  flatRate?: number | null;
  totalCost: number;
  source: BidSource;
  connectionStatus?: ConnectionStatus;
  networkId?: string;
  responseRate?: number | null;
  winRate?: number | null;
  acceptanceRate?: number | null;
}

export interface InitialBid {
  flatRate: number;
  carrierId: string;
}

export interface BidCreate {
  shipmentId: string;
  flatRate: number;
}

export interface BidUpdate {
  bidId: string;
  flatRate: number;
}

export interface TenderResponse {
  shipmentId: string;
  bidId: string;
}
