import { useAppSelector } from "../../../app/hooks";
import { selectUserData } from "../../../app/userSlice";
import { ManageUserComment } from "../../../types/Admin";
import AddCommentForm from "./AddCommentForm";
import {
  useAddCommentMutation,
  useGetShipperCommentsQuery,
} from "../../../api/shipperComment";
import { Skeleton } from "@chakra-ui/skeleton";
import { useToast } from "@chakra-ui/toast";
import { parseErrorResponse } from "../../../reuse/ErrorHandler";
import { Divider } from "@chakra-ui/react";
import Header from "../../../components/Header";
import { useState } from "react";

interface ShipperCommentsProps {
  shipperId: string;
}

export const AdminShipperAccountComments = ({
  shipperId,
}: ShipperCommentsProps): JSX.Element => {
  const toast = useToast();

  const { data, isLoading } = useGetShipperCommentsQuery(shipperId);
  const [addCommentRequest, { isLoading: isCreating }] =
    useAddCommentMutation();

  const { firstName, lastName } = useAppSelector(selectUserData);

  const [formComment, setFormComment] = useState<ManageUserComment>({
    author: firstName + " " + lastName,
    shipperId,
    commentMsg: "",
  });

  const onChangeTextArea = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    const { value, name } = e.target;
    setFormComment({ ...formComment, [name]: value });
  };

  const onSubmit = async (): Promise<void> => {
    const response = await addCommentRequest(formComment);

    if ("data" in response) {
      toast({
        status: "success",
        description: "Comment Added",
      });

      setFormComment({
        ...formComment,
        commentMsg: "",
      });
    } else {
      toast({
        status: "error",
        description: parseErrorResponse(response.error),
      });
    }
  };

  return (
    <Skeleton isLoaded={!isLoading}>
      <Header fontSize="20" headingText={`Comments (${data?.length})`} mb={8} />
      <AddCommentForm
        formComment={formComment}
        onChangeTextArea={onChangeTextArea}
        onSubmit={onSubmit}
        isLoading={isCreating}
      />
      <Divider my={8} color="grey3" />
      {data &&
        data.length > 0 &&
        data.map((comment) => (
          <AddCommentForm
            key={Math.random()}
            formComment={comment}
            onChangeTextArea={onChangeTextArea}
            onSubmit={onSubmit}
            isReadonly={true}
          />
        ))}
    </Skeleton>
  );
};

export default AdminShipperAccountComments;
