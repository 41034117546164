import { UseFormReturn } from "react-hook-form";
import { ShipmentCreate } from "./Shipment";

export interface ShipmentFormProps {
  formHook: UseFormReturn<ShipmentCreate>;
  accessorialsHook?: UseFormReturn<CustomAccessorials>;
  isReadOnly?: boolean;
}

export interface CustomAccessorials {
  accessorials: Array<{ label: string }>;
}

export enum StopType {
  Pickup = "Pickup",
  Delivery = "Delivery",
}

export enum LoadType {
  Live = "Live",
  Drop = "Drop",
  DropHook = "Drop Hook",
}

export enum AppointmentType {
  Appointment = "By Appointment",
  FCFS = "First Come First Serve",
}

export enum MaterialType {
  Explosives = "Class 1 - Explosives",
  Gas = "Class 2 - Gasses",
  FlammableLiquids = "Class 3 - Flammable/Combustible Liquids",
  FlammableSolids = "Class 4 - Flammable/Combustible solids",
  Oxidizing = "Class 5 - Oxidizing substances, Organic peroxides",
  Toxic = "Class 6 - Toxic or Infectious",
  Radioactive = "Class 7 - Radioactive",
  Corrosive = "Class 8 - Corrosive",
  Dangerous = "Class 9 - Misc. dangerous or hazardous",
}

export enum AccesorialsDistribution {
  extendedView = "1fr 1fr 1fr 1fr 1fr 1fr",
  defaultView = "1fr 1fr 1fr",
}
