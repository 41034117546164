import { HStack, IconButton, Image } from "@chakra-ui/react";
import { LoadUpdateOptions } from "../../types/Load";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import {
  useUpdateHighPriorityLoadMutation,
  useUpdateAtRiskLoadMutation,
} from "../../api/load";
import SelectedFlag from "../../resources/svg/flag_selected.svg";
import UnSelectedFlag from "../../resources/svg/flag_unselected.svg";
import { useState } from "react";
import { getStarIcon } from "../../resources/svg/CustomIcons";

interface LoadBoardIconsProps {
  id: string;
  highPriority: boolean;
  atRisk: boolean;
}

const SelectedFlagIcon = (): JSX.Element => {
  return <Image src={SelectedFlag} />;
};

const UnselectedFlagIcon = (): JSX.Element => {
  return <Image src={UnSelectedFlag} />;
};

export const LoadBoardIcons = ({
  id,
  highPriority,
  atRisk,
}: LoadBoardIconsProps): JSX.Element => {
  const notificationBanner = useNotificationBanner();
  const [updateHighPriority] = useUpdateHighPriorityLoadMutation();
  const [updateAtRisk] = useUpdateAtRiskLoadMutation();
  const [flagged, setFlagged] = useState(atRisk);
  const [starred, setStarred] = useState(highPriority);
  const StarIconFilled = getStarIcon("#F6D306", "#F6D306");
  const StarIconUnFilled = getStarIcon("none", "#656565");

  const onUpdateLoad = async (loadUpdate: LoadUpdateOptions): Promise<void> => {
    const object = { id };
    const response =
      loadUpdate === LoadUpdateOptions.OnHighPriorityLoad
        ? await updateHighPriority(object)
        : await updateAtRisk(object);
    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: (
          <p>There was a problem updating load. Please try again.</p>
        ),
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>Load updated successful.</p>,
      });
    }
  };

  return (
    <HStack>
      <IconButton
        icon={flagged ? <SelectedFlagIcon /> : <UnselectedFlagIcon />}
        fontSize="25px"
        aria-label="At Risk"
        cursor="pointer"
        onClick={async () => {
          await onUpdateLoad(LoadUpdateOptions.OnAtRiskLoad);
          setFlagged(!flagged);
        }}
      />
      <IconButton
        icon={starred ? <StarIconFilled /> : <StarIconUnFilled />}
        fontSize="25px"
        aria-label="High Priority"
        cursor="pointer"
        onClick={async () => {
          await onUpdateLoad(LoadUpdateOptions.OnHighPriorityLoad);
          setStarred(!starred);
        }}
      />
    </HStack>
  );
};

export default LoadBoardIcons;
