import { CheckBoxCarrier, IRFPAddCarrier } from "../../types/Rfp";
import { NetworkPartner } from "../../types/NetworkPartner";

export const getActiveCarriers = (
  carriers: NetworkPartner[] | undefined,
): NetworkPartner[] => {
  return (
    carriers?.filter((element) => element.carrier ?? element.fmcsaCarrier) ?? []
  );
};

export const mapCarriersToCheck = (
  networkPartners: NetworkPartner[],
): CheckBoxCarrier[] => {
  return networkPartners.map(({ carrier, fmcsaCarrier }) => {
    const companyName = carrier?.companyName ?? fmcsaCarrier!.legalName ?? "";
    return {
      carrierId: carrier?.id ?? undefined,
      fmcsaCarrierId: fmcsaCarrier?.id ?? undefined,
      companyName: companyName,
    };
  });
};

export const processIdCarriers = (
  carrierList: CheckBoxCarrier[],
  carrierIds: string[],
): IRFPAddCarrier[] => {
  return carrierList
    .filter(({ carrierId, fmcsaCarrierId }) => {
      return (
        (carrierId && carrierIds.includes(carrierId!)) ??
        (fmcsaCarrierId && carrierIds.includes(fmcsaCarrierId!))
      );
    })
    .map(({ carrierId, fmcsaCarrierId }) => ({ carrierId, fmcsaCarrierId }));
};
