import { Address } from "./Address";
import { Bid, InitialBid } from "./Bid";
import { EquipmentType } from "./Equipment";
import { IShipmentDocument } from "./ShipmentDocument";
import {
  AppointmentType,
  MaterialType,
  StopType,
  LoadType,
} from "./ShipmentForm";
import { ShipperUserData } from "./Shipper";

export enum ShipmentVisibility {
  All = "All",
  InNetwork = "In Network",
  OutOfNetwork = "Out Network",
}

export interface ShipmentStopUpdate {
  id?: string;
  appointmentType?: AppointmentType;
  appointmentTime?: Date;
  notes?: string;
}

export enum ShipmentStopOrder {
  First = "First",
  Middle = "Middle",
  Last = "Last",
}

export enum CarrierCancelReason {
  Breakdown = "Breakdown",
  EquipmentIssue = "Equipment Issue",
  HoursOfServiceIssue = "Hours of Service Issue",
  PriorLoadCanceled = "Prior Load Canceled",
  ActOfGod = "Act of God",
  AppointmentNoShow = "Appointment No Show",
}

export enum ShipperCancelReason {
  LoadCanceled = "Load Canceled",
  ProductLoadingDelayed = "Product Loading Delayed",
  ShipmentDateChange = "Shipment Date Change",
  ShipmentModeChange = "Shipment Mode Change",
}

export const ShipmentCancelReasons = {
  ...CarrierCancelReason,
  ...ShipperCancelReason,
};
export type ShipmentCancelReason = ShipperCancelReason | CarrierCancelReason;

export interface ShipmentStop extends ShipmentStopUpdate {
  stopType: StopType;
  referenceNumber?: string;
  receivingName?: string;
  date: Date;
  allDayDock: boolean;
  dockOpen: string;
  dockClose: string;
  address: Address;
  loadType: LoadType;
  appointmentType: AppointmentType;
  stopOrder: ShipmentStopOrder;
  stopPosition: number;
  keyMarketArea: string;
}

interface ShipmentEquipment {
  equipmentType: EquipmentType;
  refrigerationTemperature?: number;
  length: number;
  width: number;
  height: number;
  weight: number;
  accessorials: string[];
}

export enum ShipmentStatus {
  ActiveQuote = "Active",
  InactiveQuote = "Inactive",
  TrackingShipment = "Tracking",
  CanceledShipment = "Canceled",
  DeliveredShipment = "Delivered",
  InvoicedShipment = "Invoiced",
  CancelPending = "CancelPending",
  DeliveredPending = "DeliveredPending",
}

export interface ShipmentUpdate {
  id?: string;
  bidNotes?: string;
  tenderNotes?: string;
  shipmentStops?: ShipmentStopUpdate[];
}

export interface TenderInfo {
  shipmentId: string;
  carrierId: string;
}

export interface ShipmentCreate extends ShipmentEquipment, ShipmentUpdate {
  visibility: ShipmentVisibility;
  externalShipmentId?: string;
  billingNote?: string;
  billingAddress?: Address;
  unitType: string;
  unitCount: number;
  materialTypes?: MaterialType[];
  value: number;
  shipmentStops: ShipmentStop[];
  initialBid?: InitialBid;
}

export enum DriverStatus {
  Unassigned = "Unassigned",
  Assigned = "Assigned",
  Dispatched = "Dispatched", // driver is en route
}

export interface DriverInfo {
  firstName: string;
  lastName: string;
  phone: string;
  email?: string;
  driverStatus?: DriverStatus;
}

export interface LocationOptionalFields {
  etaToStop?: Date;
  milesToDestination?: number;
}

export interface BaseLocation extends LocationOptionalFields {
  city: string;
  state: string;
  postalCode: string;
}

export interface LocationHistory extends BaseLocation {
  id: string;
  timeStampTimeZone: Date;
}

export interface LocationHistoryCreate extends LocationHistory {
  shipmentId: string;
}

export interface Shipment extends ShipmentCreate {
  id: string;
  shipper: ShipperUserData;
  winningBidId: string | null;
  status: ShipmentStatus;
  shipmentTotalMiles: number;
  createDate: Date;
  tendered: boolean;
  bids?: Bid[];
  assignedDriver?: DriverInfo;
  shipmentLocations?: LocationHistory[];
  shipmentDocuments?: IShipmentDocument[];
  cancelReason: ShipmentCancelReason | null;
}

export interface ICreateDriver {
  iShipmentDriver: DriverInfo;
  shipmentId: string;
}

export enum DriverFormMode {
  CREATE = "CREATE",
  EDIT = "EDIT",
}

export interface UpdateShipmentProps {
  body: ICreateDriver;
  id: string;
}

export interface DocumentDriver {
  hazmat: string;
  overdimensional: string;
  other: string;
}

export interface DocumentDriverFile {
  fileData: DocumentDriver;
  idDocs: DocumentDriver;
}
