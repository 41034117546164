import { View, Text } from "@react-pdf/renderer";
import { parseDateToLocal } from "../../../reuse/Dates";
import { Option } from "../../../reuse/MultiSelect";
import { ShipmentStop } from "../../../types/Shipment";
import { ContactInformation } from "../../../types/ShipperContactInformation";
import { Insurance } from "../../../types/ShipperInsurance";
import { styles } from "./styles";

interface PDFFooterProps {
  companyName: string;
  contactName: string;
  externalShipmentId: string;
  shipmentStops: ShipmentStop[];
  contactInformation: ContactInformation;
  insuranceRequirements: Insurance;
  companyEmail: string;
}

export const TenderPDFFooter = ({
  companyName,
  contactName,
  externalShipmentId,
  shipmentStops,
  contactInformation,
  insuranceRequirements,
  companyEmail,
}: PDFFooterProps): JSX.Element => {
  const renderFooterFirstSection = (): JSX.Element => (
    <Text style={styles.footerText}>
      Pursuant to our verbal agreement of {parseDateToLocal(new Date())} between{" "}
      {companyName}, hereafter referred to as BROKER and {contactName} ,
      hereafter referred to as CARRIER. Both parties agree that Broker’s load
      number #{externalShipmentId}, moving on{" "}
      {parseDateToLocal(shipmentStops[0].date)} from{" "}
      {shipmentStops[0].address.city}, {shipmentStops[0].address.state} /{" "}
      {shipmentStops[0].address.country} to{" "}
      {shipmentStops[shipmentStops.length - 1].address.city},{" "}
      {shipmentStops[shipmentStops.length - 1].address.state} /{" "}
      {shipmentStops[shipmentStops.length - 1].address.country} (
      {shipmentStops.length} stops) will move at the following rate:
    </Text>
  );

  const renderFooterFirstImportantSection = (): JSX.Element => (
    <>
      <Text
        style={{
          ...styles.footerImportantText,
          fontWeight: 700,
          ...styles.footerText,
        }}
      >
        ALL TRAVEL DIRECTIONS PROVIDED BY {companyName} ARE FOR INFORMATIONAL
        PURPOSES ONLY. IT IS THE CARRIER’S SOLE RESPONSIBILITY TO LAWFULLY AND
        SAFELY OPERATE ALL VEHICLES AND THEIR CONTENTS OVER ANY ROAD, HIGHWAY,
        BRIDGE AND/OR ROUTE IN STRICT COMPLIANCE WITH ALL APPLICABLE LAWS,
        RULES, AND REGULATIONS.
      </Text>
      <Text
        style={{
          ...styles.footerImportantText,
          fontWeight: 700,
          ...styles.footerText,
        }}
      >
        CARRIER MUST ADVISE IF ANY DELIVERY SCHEDULES, SPECIFICATIONS,
        INSTRUCTIONS, OR REQUIREMENTS CAN NOT BE LEGALLY ACCOMPLISHED OR IF THE
        AVOIDANCE OF ANY FINES, PENALTIES, OR DEDUCTIONS WOULD REQUIRE OR RESULT
        IN THE VIOLATION OF ANY LAWS OR REGULATIONS.
      </Text>
      <Text
        style={{
          ...styles.footerImportantText,
          ...styles.footerText,
          textAlign: "center",
        }}
      >
        COMPENSATION MAY BE WITHHELD IF THIS SHIPMENT IS DOUBLE-BROKERED, MOVED
        BY RAIL, CONSOLIDATED WITH ANY OTHER FREIGHT OR IF THE AGREED SERVICES
        ARE NOT FULFILLED.
      </Text>
    </>
  );

  const renderFooterNormalSection = (): JSX.Element => (
    <>
      <Text style={styles.footerText}>
        This confirmation governs the rate for the movement of the
        above-referenced freight as of the date specified and hereby amends, and
        is incorporated by reference and becomes part of that certain Agreement
        by and between BROKER and CARRIER. By means of either its signature on
        the Confirmation or its provisions of service: i) CARRIER shall perform
        its services in accordance with all applicable laws, rules, and
        regulations (including, if applicable, the requirements to record hours
        of service via electronic logging device); and ii) CARRIER shall be
        conclusively presumed to have agreed to the rates and conditions set
        forth herein. CARRIER shall be conclusively presumed to have agreed to
        the rates and conditions set
      </Text>
      <Text style={{ ...styles.footerLegend, ...styles.footerText }}>
        *Subject to terms and conditions outlined in {companyName} carrier
        packet
      </Text>
      <Text style={styles.footerText}>
        forth herein. CARRIER further represents, and warrants that said
        mutually agreed upon rates are reasonable and compensatory, that the
        freight would not have been tendered to CARRIER at higher rates, and
        that no shipments handled under such rates will subsequently be subject
        to a later claim of undercharges.
      </Text>
      <Text style={styles.footerText}>
        CARRIER hereby confirms current and valid insurance coverage without
        exclusions in conflict with load {externalShipmentId}, in amounts no
        less than the following; {insuranceRequirements.autoLiability} auto
        liability coverage,
        {insuranceRequirements.generalLiability} general liability coverage,{" "}
        {insuranceRequirements.cargoInsurance} cargo coverage. If carrier’s
        insurance policy contains a schedule of covered vehicles, the carrier
        will only transport this shipment using a vehicle that is listed as a
        scheduled vehicle on their insurance policy.
      </Text>
    </>
  );

  const renderFooterSignatureTable = (headers: Option[]): JSX.Element => (
    <View style={styles.signatureTable}>
      <View style={styles.signatureTableHeaders}>
        {headers.map((header) => (
          <View key={header.label} style={styles.signatureTableHeader}>
            <Text>{header.label}</Text>
            <Text>{header.value}</Text>
          </View>
        ))}
      </View>
      <View style={styles.signatureTableData}>
        <Text>Signature</Text>
      </View>
    </View>
  );

  const renderFooterSingatureTables = (): JSX.Element => (
    <View style={styles.signatureTableContainer}>
      {renderFooterSignatureTable([
        { label: "Party", value: companyName },
        {
          label: "Name",
          value: `${contactInformation.firstName}, ${contactInformation.lastName}`,
        },
        {
          label: "Date",
          value: parseDateToLocal(new Date()),
        },
      ])}
      <View style={styles.signatureTableDivider} />
      {renderFooterSignatureTable([
        {
          label: "Party",
          value: contactName,
        },
        {
          label: "Name",
          value: "",
        },
        {
          label: "Date",
          value: "",
        },
      ])}
    </View>
  );

  const renderFooterPaymentReqs = (): JSX.Element => (
    <>
      <Text
        style={{
          ...styles.footerImportantText,
          fontWeight: 700,
          ...styles.footerText,
        }}
      >
        PAYMENT REQUIREMENTS:
      </Text>
      <Text style={{ ...styles.footerText, margin: "0px 36px 20px" }}>
        {companyName}
      </Text>
      <Text style={styles.footerImportantText}>
        ● SIGNED BOL / SIGNED DELIVERY RECEIPT / SIGNED RATE CONFIRMATION SHEET.
      </Text>
      <Text style={styles.footerImportantText}>
        ● LOAD / UNLOAD / LUMPER RECEIPTS MUST ACCOMPANY INVOICING, OR THEY WILL
        NOT BE PAID.
      </Text>
      <Text style={styles.footerImportantText}>
        ● MUST REFERENCE LOAD # ON ALL CORRESPONDENCES.
      </Text>
      <Text style={styles.footerImportantText}>
        ● ALL ACCESSORIAL CHARGES MUST BE PRE-APPROVED & BILLED WITH RECEIPT &
        POD.
      </Text>
    </>
  );

  return (
    <View style={styles.footerContainer}>
      {renderFooterFirstSection()}
      {renderFooterFirstImportantSection()}
      <Text style={styles.footerText}>
        Carrier must be CARB compliant when traveling to, from, or through
        California and Carrier agrees to and indemnifies {companyName} and all
        other parties from any loss or damage resulting from Carrier’s failure
        to comply.
      </Text>
      <Text style={{ ...styles.footerImportantText, ...styles.footerText }}>
        TRAILER SEALS: SEAL MUST BE APPLIED, WITH THE SEAL NUMBER NOTED ON THE
        BILL OF LADING, PRIOR TO DEPARTURE FROM THE SHIPPER. SEALS MUST NOT BE
        BROKEN WITHOUT PRIOR WRITTEN APPROVAL FROM {companyName} MANAGEMENT.
        FAILURE TO DELIVER AT THE DESIGNATED CONSIGNEE WITH THE PROPER SEAL
        INTACT WILL RESULT IN A CLAIM.
      </Text>
      {renderFooterNormalSection()}
      <Text
        style={{
          ...styles.footerImportantText,
          fontWeight: 700,
          ...styles.footerText,
          textAlign: "center",
        }}
      >
        DRIVERS ARE RESPONSIBLE FOR AN ACCURATE COUNT OF CRATES, PALLETS/SKIDS &
        PIECES
      </Text>
      {renderFooterPaymentReqs()}
      {renderFooterSingatureTables()}
      <Text style={{ ...styles.footerImportantText, textAlign: "center" }}>
        PLEASE SIGN
      </Text>
      <Text style={styles.footerText}>Load Number: {externalShipmentId}</Text>
      <Text style={styles.footerText}>
        Invoice, bill of lading, and any supporting documentation (weight
        tickets, lumper receipt, etc. may be faxed to Accounts Payable at Broker
        Fax Number or emailed to {companyEmail}
      </Text>
    </View>
  );
};

export default TenderPDFFooter;
