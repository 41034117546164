import { VStack, Text, Divider, Flex, HStack, Grid } from "@chakra-ui/react";
import ChevronIcon from "../../../components/ChevronIcon";
import QuoteDetailsFooter from "../QuoteDetailsFooter";
import {
  Shipment,
  ShipmentStop,
  ShipmentStopOrder,
} from "../../../types/Shipment";

interface QuoteRequestDetailProps {
  quote: Shipment;
}

export const QuoteRequestDetail = ({
  quote,
}: QuoteRequestDetailProps): JSX.Element => {
  const {
    shipper,
    shipmentStops,
    externalShipmentId,
    equipmentType,
    accessorials,
    materialTypes,
    value,
    bidNotes,
  } = quote;

  const stops = [...shipmentStops].sort(
    (a, b) => a.stopPosition - b.stopPosition,
  );

  return (
    <VStack
      my="2vh"
      w="100%"
      maxW="60vw"
      bgColor="realWhite"
      p="3%"
      borderRadius="sm"
      alignItems="start"
      spacing="1rem"
    >
      <Text textStyle="title">{shipper.contactInformation.companyName}</Text>
      <Text>{externalShipmentId}</Text>
      <Divider />
      <Text textStyle="title">Stops</Text>
      <VStack w="100%">
        {stops.map((stop) => (
          <ShipmentStopDetail key={stop.id} {...stop} />
        ))}
      </VStack>
      <Text textStyle="title">Equipment</Text>
      <Text>{equipmentType}</Text>
      <Flex flexWrap="wrap">
        {accessorials.map((accessorial, index) => (
          <Text key={accessorial}>
            {index === 0 ? "" : ", "}
            {accessorial}
          </Text>
        ))}
      </Flex>
      <Divider />
      <Text textStyle="title">Shipment Content Details</Text>
      <Grid w="100%" gridTemplateColumns="1fr 4fr">
        <Text fontWeight={500} fontSize={18} textDecoration="underline">
          Material Types:
        </Text>
        <Grid w="100%" gridTemplateColumns="1fr 1fr 1fr">
          {materialTypes?.map((material) => (
            <Text key={material}>{material}</Text>
          ))}
        </Grid>
      </Grid>
      <HStack>
        <Text>Weight: </Text>
        <Text> {quote.weight} lbs</Text>
      </HStack>
      <HStack>
        <Text>Mileage: </Text>
        <Text>{quote.shipmentTotalMiles} miles</Text>
      </HStack>
      <HStack>
        <Text>Shipment Value: </Text>
        <Text>{value ?? "---"}</Text>
      </HStack>
      <Divider />
      <Text textStyle="title">Additional Notes</Text>
      <Text>{bidNotes}</Text>
      <QuoteDetailsFooter {...quote} />
    </VStack>
  );
};

const ShipmentStopDetail = (stop: ShipmentStop): JSX.Element => {
  return (
    <VStack w="100%" borderBottom="1px solid black" py=".5rem">
      <HStack w="100%">
        <ChevronIcon />
        <Text>
          {stop.stopOrder === ShipmentStopOrder.First
            ? "Origin"
            : stop.stopOrder === ShipmentStopOrder.Last
            ? "Destination"
            : `Stop ${stop.stopPosition}`}
        </Text>
        <Text> - {stop.stopType}</Text>
      </HStack>
      <HStack w="100%" justifyContent="space-between">
        <HStack>
          <Text textStyle="card">Appointment Type:</Text>
          <Text>{stop.appointmentType}</Text>
        </HStack>
        {stop.appointmentTime && (
          <HStack>
            <Text textStyle="card">Appointment Time:</Text>
            <Text>{stop.appointmentTime}</Text>
          </HStack>
        )}
        <HStack>
          <Text textStyle="card">Load Type:</Text>
          <Text>{stop.loadType}</Text>
        </HStack>
      </HStack>
      <HStack w="100%" justifyContent="space-between">
        <HStack>
          <Text textStyle="card">Stop Date:</Text>
          <Text>{stop.date}</Text>
        </HStack>
        <HStack>
          <Text textStyle="card">Dock Open:</Text>
          <Text>{stop.dockOpen}</Text>
        </HStack>
        <HStack>
          <Text textStyle="card">Dock Close:</Text>
          <Text>{stop.dockClose}</Text>
        </HStack>
      </HStack>
      <HStack w="100%" justifyContent="space-between">
        <HStack>
          <Text textStyle="card">City:</Text>
          <Text>{stop.address.city}</Text>
        </HStack>
        <HStack>
          <Text textStyle="card">State:</Text>
          <Text>{stop.address.state}</Text>
        </HStack>
        <HStack>
          <Text textStyle="card">Zip:</Text>
          <Text>{stop.address.postalCode}</Text>
        </HStack>
        <HStack>
          <Text textStyle="card">Country:</Text>
          <Text>{stop.address.country}</Text>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default QuoteRequestDetail;
