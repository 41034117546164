import { BadgeColors } from "../components/ReusableBadge/ReusableBadge";
import { QuickPayInvoiceStatus } from "../types/QuickPayRequest";
import { ShipperStatus } from "../types/Shipper";

export const getBadgeColorForQPInvoiceStatus = (
  status: QuickPayInvoiceStatus,
): BadgeColors =>
  status === QuickPayInvoiceStatus.SHIPPER_PAID
    ? BadgeColors.Green
    : status === QuickPayInvoiceStatus.DELIVERED
    ? BadgeColors.Grey
    : status === QuickPayInvoiceStatus.NOT_PAID
    ? BadgeColors.Blue
    : BadgeColors.Red;

export const getBadgeColorForShipperStatus = (
  status: ShipperStatus,
): BadgeColors =>
  status === ShipperStatus.APPROVED
    ? BadgeColors.Green
    : status === ShipperStatus.PENDING
    ? BadgeColors.Grey
    : BadgeColors.Red;
