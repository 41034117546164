import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Button,
  HStack,
  Spinner,
  Flex,
  useToast,
} from "@chakra-ui/react";
import ErrorMessage from "../ErrorMessage";
import { useCreateRfpMutation, useEditRfpMutation } from "../../api/rfp";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { CheckBoxCarrier, IRFPCreate, RfpType } from "../../types/Rfp";
import { FormError } from "../../types/FormError";
import { useActiveRfpContext } from "../../pages/ActiveRfp/ActiveRfpContext";
import { mapCarriersToCheck, processIdCarriers } from "./utils";
import { RfpSecondStep } from "./RfpSecondStep";
import { IRfpForm } from "./types";
import { RfpFirstStep } from "./RfpFirstStep";
import { useGetNetworkCarriersQuery } from "../../api/network";
import { UserType } from "../../types/User";
import { selectUserData } from "../../app/userSlice";
import { useAppSelector } from "../../app/hooks";

export enum RfpFormMode {
  EDIT = "EDIT",
  CREATE = "CREATE",
}

interface RfpModalFormProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RfpModalForm = ({
  isOpen,
  onClose,
}: RfpModalFormProps): JSX.Element => {
  const context = useActiveRfpContext();
  const { formMode, selectedRfp } = context!;

  const { type: typeUser } = useAppSelector(selectUserData);
  const shipperType =
    UserType.SHIPPER === typeUser || UserType.BROKER === typeUser;

  const { data } = useGetNetworkCarriersQuery(undefined, {
    skip: !shipperType,
  });

  const toast = useToast();
  const [createRfp, { isLoading: isSubmiting }] = useCreateRfpMutation();
  const [editRfp, { isLoading: isEditing }] = useEditRfpMutation();
  const [step, setStep] = useState(0);
  const [carrierList, setCarrierList] = useState<CheckBoxCarrier[]>();
  const formHook = useForm<IRfpForm>({
    defaultValues: {
      rfpType: RfpType.Annual,
      carriers: [],
    },
  });

  const {
    setValue,
    handleSubmit,
    getValues,
    reset,
    clearErrors,
    formState: { errors },
    watch,
  } = formHook;

  const closeForm = (): void => {
    setStep(0);
    setValue("rfpType", "Annual");
    reset();
    onClose();
  };

  const goBack = (): void => setStep(0);

  const values = getValues();
  const rfpType = watch("rfpType");

  const onSubmitRfp = async (): Promise<void> => {
    const rfpCreate: IRFPCreate = {
      rfpName: values.rfpName,
      biddingStartDate: values.biddingStartDate,
      biddingEndDate: values.biddingEndDate,
      notes: values.notes,
      carrierIds: processIdCarriers(carrierList ?? [], values.carriers),
      rfpType: values.rfpType as RfpType,
    };
    if (values.contractStartDate) {
      rfpCreate.contractStartDate = values.contractStartDate!;
    }
    if (values.contractEndDate) {
      rfpCreate.contractEndDate = values.contractEndDate!;
    }

    const response =
      formMode === RfpFormMode.CREATE
        ? await createRfp(rfpCreate)
        : await editRfp({ ...rfpCreate, rfpId: selectedRfp?.id ?? "" });

    if ("data" in response) {
      toast({
        status: "success",
        description:
          formMode === RfpFormMode.CREATE ? "RFP Created." : "RFP Updated",
        duration: 3000,
      });
    } else {
      toast({
        status: "error",
        description: parseErrorResponse(response.error),
        duration: 3000,
      });
    }
  };

  const submitRfp = handleSubmit(onSubmitRfp);

  const handleNext = (): void => setStep(1);
  const onSubmitNext = handleSubmit(handleNext);

  useEffect(() => {
    if (formMode === RfpFormMode.EDIT && selectedRfp) {
      const initialValues: IRfpForm = {
        rfpName: selectedRfp?.rfpName,
        rfpType: selectedRfp?.rfpType as RfpType,
        notes: selectedRfp?.notes,
        biddingEndDate: selectedRfp?.biddingEndDate as Date,
        biddingStartDate: selectedRfp?.biddingStartDate as Date,
        contractEndDate: selectedRfp?.contractEndDate,
        contractStartDate: selectedRfp?.contractStartDate,
        carriers: selectedRfp?.carriersInfo
          ? selectedRfp.carriersInfo.map((c) => c.id)
          : [],
      };
      for (const key in initialValues) {
        setValue(key as keyof IRfpForm, initialValues[key as keyof IRfpForm]);
      }
    }
  }, [selectedRfp]);

  useEffect(() => {
    if (data) {
      setCarrierList(mapCarriersToCheck(data));
    } else {
      setCarrierList([]);
    }
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={step === 0 ? "lg" : "2xl"}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent w="100%">
        <form>
          <ModalHeader textStyle="uppercase">
            {formMode === RfpFormMode.CREATE
              ? step === 0
                ? "CREATE RFP"
                : "CREATE RFP - SELECT CARRIERS"
              : "EDIT RFP"}
          </ModalHeader>
          <ModalBody>
            {formMode === RfpFormMode.CREATE ? (
              step === 0 ? (
                <RfpFirstStep formHook={formHook} type={rfpType} />
              ) : (
                <RfpSecondStep formHook={formHook} carriers={carrierList} />
              )
            ) : (
              <RfpFirstStep formHook={formHook} type={rfpType} />
            )}
          </ModalBody>
          <ErrorMessage
            errors={errors as FormError}
            clearErrors={clearErrors}
          />
          <ModalFooter w="100%" mb="0.6rem">
            {isSubmiting || isEditing ? (
              <Flex w="100%" alignItems="center" justifyContent="center">
                <Spinner color="mvmntRed" />
              </Flex>
            ) : (
              <Fragment>
                <HStack
                  w="100%"
                  alignItems="center"
                  justifyContent={step === 0 ? "flex-end" : "space-between"}
                >
                  {step === 1 && (
                    <Button
                      p="1.3rem 2rem"
                      color="white"
                      layerStyle="black"
                      onClick={closeForm}
                    >
                      CANCEL
                    </Button>
                  )}
                  <HStack>
                    <Button
                      color="mvmntRed"
                      variant="link"
                      onClick={step === 0 ? closeForm : goBack}
                    >
                      {step === 0 ? "CANCEL" : "GO BACK"}
                    </Button>
                    <Button
                      isDisabled={
                        formMode === RfpFormMode.CREATE &&
                        carrierList?.length === 0
                      }
                      layerStyle="red"
                      px="3rem"
                      onClick={
                        step === 0 && formMode === RfpFormMode.CREATE
                          ? onSubmitNext
                          : submitRfp
                      }
                    >
                      {formMode === RfpFormMode.CREATE
                        ? step === 0
                          ? "NEXT"
                          : "CREATE RFP"
                        : "EDIT RFP"}
                    </Button>
                  </HStack>
                </HStack>
              </Fragment>
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default RfpModalForm;
