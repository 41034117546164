import { createContext, useContext } from "react";
import { MarkerInfo } from "../../components/SharedMap/SharedMap";
import { DriverFormMode, Shipment } from "../../types/Shipment";
import { MapLocations } from "../SourcingSearch/SourcingSearchContext";

interface Tracking {
  currentTrackingShipment: Shipment | undefined;
  setCurrentTrackingShipment: (shipment?: Shipment) => void;

  showDispatchModal?: boolean;
  setShowDispatchModal?: React.Dispatch<React.SetStateAction<boolean>>;

  showDriverModal?: boolean;
  setShowDriverModal?: React.Dispatch<React.SetStateAction<boolean>>;
  driverFormMode?: DriverFormMode;
  setDriverFormMode?: React.Dispatch<React.SetStateAction<DriverFormMode>>;

  showLocationModal?: boolean;
  setShowLocationModal?: React.Dispatch<React.SetStateAction<boolean>>;

  showContactInfo?: boolean;
  setShowContactInfo?: React.Dispatch<React.SetStateAction<boolean>>;

  showDriverInfo?: boolean;
  setShowDriverInfo?: React.Dispatch<React.SetStateAction<boolean>>;

  locations?: MapLocations;
  setLocations: (locations: MapLocations) => void;

  markerInfo: MarkerInfo | undefined;
  setMarkerInfo: (markerInfo: MarkerInfo | undefined) => void;

  setLocationsFromTracking: (
    currentTrackingShipment: Shipment,
    setLocations: (p: MapLocations | undefined) => void,
  ) => Promise<void>;

  setMarkerFromTracking: (
    currentTrackingShipment: Shipment,
    setMarkerInfo: (markerInfo: MarkerInfo | undefined) => void,
  ) => Promise<void>;
}

const TrackingContext = createContext<Tracking | null>(null);

const useTrackingContext = (): Tracking | null => useContext(TrackingContext);

export { TrackingContext, useTrackingContext };
