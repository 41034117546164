import { Skeleton } from "@chakra-ui/react";
import { useGetShipperRfpQuery } from "../../api/rfp";
import ActiveRfp from "../ActiveRfp";

export const ShipperRFP = (): JSX.Element => {
  const { data: rfp, isFetching } = useGetShipperRfpQuery();

  return (
    <Skeleton isLoaded={!isFetching} w="100%" h="100%">
      <ActiveRfp data={rfp} />
    </Skeleton>
  );
};

export default ShipperRFP;
