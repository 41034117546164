import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";

interface LoadDropdownProps {
  onClickAddSingle: () => void;
  onClickBulk: () => void;
}

export const CreateLoadDropdown = ({
  onClickAddSingle,
  onClickBulk,
}: LoadDropdownProps): JSX.Element => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        size="sm"
        backgroundColor="yellow40"
        color="gray90"
        padding={5}
      >
        + Add Load
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onClickAddSingle}>Add a single load</MenuItem>
        <MenuItem value="Bulk upload" onClick={onClickBulk}>
          Bulk upload
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default CreateLoadDropdown;
