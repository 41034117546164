import { RegexPoliceProp } from "../../types/Regex";

const regexMinimumLength = /^.{11,}$/;
const regexDigitRequired = /[0-9]/;
const regexSpecialCharacter = /(?=.*[!@#$%^&*_])/;
const regexUpperCaseLetters = /(?=.*[A-Z])/;

export const regexPasswordPolices: RegexPoliceProp[] = [
  {
    id: 0,
    expression: regexMinimumLength,
    value: "minCharacters",
    title: "Min 11 Characters",
  },
  {
    id: 1,
    expression: regexDigitRequired,
    value: "minOneDigit",
    title: "Min 1 Digit",
  },
  {
    id: 2,
    expression: regexSpecialCharacter,
    value: "minSepcial",
    title: "Min 1 Special Character",
  },
  {
    id: 3,
    expression: regexUpperCaseLetters,
    value: "minUppercase",
    title: "Min 1 Uppercase",
  },
];
