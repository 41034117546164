import { DocumentType } from "../../types/Files";

export const documentTypes = [
  {
    label: "Shipper-Carrier Agreement",
    value: DocumentType.ShipperAgreement,
  },
  {
    label: "Facility Requirements",
    value: DocumentType.FacilityRequirements,
  },
  { label: "Other (open text)", value: DocumentType.Other },
];
