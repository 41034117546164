import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ILane,
  ILaneCreate,
  IRfp,
  IRFPAddCarrier,
  IRFPCreate,
  IRFPEdit,
  LaneBidCreate,
  RfpCarrierBulkUpload,
} from "../types/Rfp";
import {
  LaneCreateBulkResult,
  LaneRowToAdd,
  LaneBidBulkResult,
  BidRowToAdd,
} from "../types/LaneUpload";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";

export interface BulkUploadLanesProps {
  lanesToAdd: LaneRowToAdd[];
  lanesToUpdate: LaneRowToAdd[];
}

export interface BulkUploadProps {
  bulkUploadLanes: BulkUploadLanesProps;
  rfpId: string;
}

export interface AwardProps {
  laneBidId: string;
  awardedVolume: string;
}

export interface SendLanesProps {
  rfpId: string;
}

export interface ValidateProps {
  id: string;
  file: File;
}

export interface BidsUploadProps {
  id: string;
  bidRowToAdd: BidRowToAdd[];
}

export interface RfpCarriersFile {
  rfpId: string;
  file: File;
}

export interface RfpCarriersProps {
  rfpId: string;
  carriers: IRFPAddCarrier[];
}

export const rfpApi = createApi({
  reducerPath: "rfpApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}`),
  tagTypes: ["proposals", "lanes"],
  endpoints: (builder) => ({
    getShipperRfp: builder.query<IRfp[], void>({
      query: () => "/shipper/rfp",
      providesTags: ["proposals"],
    }),
    getCarrierActiveRfp: builder.query<IRfp[], void>({
      query: () => "/carrier/active-rfp",
      providesTags: ["proposals"],
    }),
    getCarrierHistoryRfp: builder.query<IRfp[], void>({
      query: () => "/carrier/inactive-rfp",
      providesTags: ["proposals"],
    }),
    createRfp: builder.mutation<void, IRFPCreate>({
      query: (body) => ({
        url: "/rfp",
        method: "POST",
        body,
      }),
      invalidatesTags: ["proposals"],
    }),
    editRfp: builder.mutation<void, IRFPEdit>({
      query: (rfpEdit) => {
        const { rfpId, ...body } = rfpEdit;
        return {
          url: `/rfp/${rfpId}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["proposals"],
    }),
    addCarriersToRfp: builder.mutation<
      void,
      { rfpId: string; carriers: IRFPAddCarrier[] }
    >({
      query: (addCarriers) => {
        return {
          url: `/rfp/${addCarriers.rfpId}/add-carriers`,
          method: "PUT",
          body: addCarriers.carriers,
        };
      },
      invalidatesTags: ["proposals"],
    }),
    createRfpLane: builder.mutation<
      ILane,
      { createLane: ILaneCreate; rfpId: string }
    >({
      query: (params) => {
        const { rfpId, createLane } = params;
        return {
          url: `rfp/${rfpId}/lane`,
          method: "POST",
          body: createLane,
        };
      },
      invalidatesTags: ["proposals"],
    }),
    validateLaneUpload: builder.mutation<LaneCreateBulkResult, ValidateProps>({
      query: ({ id, file }) => {
        const body = new FormData();
        body.append("file", file);
        return {
          url: `/rfp/${id}/upload`,
          method: "POST",
          body,
        };
      },
    }),
    bulkUploadLanes: builder.mutation<void, BulkUploadProps>({
      query: (body) => ({
        url: `/rfp/${body.rfpId}/upload/`,
        method: "PUT",
        body: {
          lanesToAdd: body.bulkUploadLanes.lanesToAdd,
          lanesToUpdate: body.bulkUploadLanes.lanesToUpdate,
        },
      }),
      invalidatesTags: ["proposals"],
    }),
    saveLanesBid: builder.mutation<void, LaneBidCreate>({
      query: (body) => ({
        url: "/lane-bids/save",
        method: "POST",
        body,
      }),
      invalidatesTags: ["proposals"],
    }),
    submitLanesBid: builder.mutation<void, LaneBidCreate>({
      query: (body) => ({
        url: "/lane-bids/submit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["proposals"],
    }),
    awardCarriers: builder.mutation<void, AwardProps[]>({
      query: (body) => ({
        url: "/lane-bids/award",
        method: "POST",
        body,
      }),
      invalidatesTags: ["proposals"],
    }),
    sendLanesToCarriers: builder.mutation<void, SendLanesProps>({
      query: (body) => ({
        url: `/rfp/${body.rfpId}`,
        method: "POST",
      }),
      invalidatesTags: ["lanes"],
    }),
    validateBidsUpload: builder.mutation<LaneBidBulkResult, ValidateProps>({
      query: ({ id, file }) => {
        const body = new FormData();
        body.append("file", file);
        return {
          url: `/rfp/${id}/lane-bids/upload`,
          method: "POST",
          body,
        };
      },
    }),
    bidsUpload: builder.mutation<void, BidsUploadProps>({
      query: ({ id, bidRowToAdd }) => ({
        url: `/rfp/${id}/lane-bids/upload`,
        method: "PUT",
        body: bidRowToAdd,
      }),
      invalidatesTags: ["proposals"],
    }),
    validateRfpCarriersUpload: builder.mutation<
      RfpCarrierBulkUpload,
      RfpCarriersFile
    >({
      query: ({ rfpId, file }) => {
        const body = new FormData();
        body.append("file", file);
        return {
          url: `rfp/${rfpId}/add-carriers/upload`,
          method: "POST",
          body,
        };
      },
    }),
    uploadRfpCarriersUpload: builder.mutation<void, RfpCarriersProps>({
      query: ({ rfpId, carriers }) => ({
        url: `/rfp/${rfpId}/add-carriers/upload`,
        method: "PUT",
        body: carriers,
      }),
      invalidatesTags: ["proposals"],
    }),
  }),
});

export const {
  useSendLanesToCarriersMutation,
  useEditRfpMutation,
  useBulkUploadLanesMutation,
  useValidateLaneUploadMutation,
  useCreateRfpLaneMutation,
  useCreateRfpMutation,
  useGetShipperRfpQuery,
  useGetCarrierActiveRfpQuery,
  useGetCarrierHistoryRfpQuery,
  useSaveLanesBidMutation,
  useSubmitLanesBidMutation,
  useAwardCarriersMutation,
  useValidateBidsUploadMutation,
  useBidsUploadMutation,
  useAddCarriersToRfpMutation,
  useValidateRfpCarriersUploadMutation,
  useUploadRfpCarriersUploadMutation,
} = rfpApi;

export const { invalidateTags } = rfpApi.util;
