import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalBody,
  Select,
  Input,
  HStack,
  Text,
  RadioGroup,
  Radio,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import SortButton from "../../../components/SortButton";
import { sortDate, sortText } from "../../../reuse/Sorting";
import { DefaultModalProps } from "../../../types/DefaultModal";
import { Shipment, ShipmentStatus } from "../../../types/Shipment";

enum SortOption {
  ShipperName = "name",
  Date = "date",
}

const initialSortValue = {
  [SortOption.ShipperName]: false,
  [SortOption.Date]: false,
};

const initialFilterValue = {
  status: "",
  date: "",
};

interface QuoteSortModalProps extends DefaultModalProps {
  setQuotes: (quotes: Shipment[]) => void;
  quotes?: Shipment[];
}

export const QuoteSortModal = ({
  isOpen,
  onClose,
  quotes,
  setQuotes,
}: QuoteSortModalProps): JSX.Element => {
  const [sortState, setSortState] = useState(initialSortValue);
  const [filterState, setFilterState] = useState(initialFilterValue);
  const [selectedSort, setSelectedSort] = useState<SortOption>();

  const sortByName = (a: Shipment, b: Shipment): number =>
    sortText(
      a.shipper.contactInformation.companyEmail,
      b.shipper.contactInformation.companyEmail,
      sortState[SortOption.ShipperName],
    );

  const sortByDate = (a: Shipment, b: Shipment): number =>
    sortDate(a.createDate, b.createDate, sortState[SortOption.Date]);

  const onClickSortAndFilter = (): void => {
    if (quotes) {
      const { status, date } = filterState;
      const filteredQuotes = [...quotes].filter(
        (quote) =>
          (!status || quote.status === status) &&
          (!date ||
            new Date(quote.createDate).getTime() === new Date(date).getTime()),
      );
      if (selectedSort === SortOption.ShipperName)
        filteredQuotes.sort(sortByName);
      else if (selectedSort === SortOption.Date)
        filteredQuotes.sort(sortByDate);

      setQuotes(filteredQuotes);
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="uppercase">Sort / Filter Quotes</ModalHeader>
        <ModalBody>
          <VStack w="100%" p=".5rem">
            <HStack w="100%" justifyContent="space-between">
              <Text>Filter by Status</Text>
              <Select
                value={filterState.status}
                onChange={(e) =>
                  setFilterState({ ...filterState, status: e.target.value })
                }
                w="50%"
              >
                <option value="">All</option>
                <option value={ShipmentStatus.ActiveQuote}>Active</option>
                <option value={ShipmentStatus.InactiveQuote}>Inactive</option>
              </Select>
            </HStack>
            <HStack w="100%" justifyContent="space-between">
              <Text>Filter by Date</Text>
              <Input
                type="date"
                value={filterState.date}
                onChange={(e) =>
                  setFilterState({ ...filterState, date: e.target.value })
                }
                w="50%"
              />
            </HStack>
            <RadioGroup
              value={selectedSort}
              onChange={(e) => setSelectedSort(e as SortOption)}
              w="100%"
            >
              <HStack w="100%" justifyContent="space-between">
                <Radio value={SortOption.ShipperName} w="50%">
                  Sort by Name
                </Radio>
                <SortButton
                  sort={() =>
                    setSortState({
                      ...sortState,
                      [SortOption.ShipperName]:
                        !sortState[SortOption.ShipperName],
                    })
                  }
                  asc={sortState[SortOption.ShipperName]}
                />
              </HStack>
              <HStack w="100%" justifyContent="space-between">
                <Radio value={SortOption.Date} w="50%">
                  Sort by Date
                </Radio>
                <SortButton
                  sort={() =>
                    setSortState({
                      ...sortState,
                      [SortOption.Date]: !sortState[SortOption.Date],
                    })
                  }
                  asc={sortState[SortOption.Date]}
                />
              </HStack>
            </RadioGroup>
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button layerStyle="red" onClick={onClickSortAndFilter}>
            Sort
          </Button>
          <Button size="sm" color="mvmntRed" variant="link" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QuoteSortModal;
