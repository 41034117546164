import { VStack, Flex } from "@chakra-ui/layout";
import Header from "../../components/Header";
import TabsContainer from "../../components/TabsContainer";
import { useEffect, useState } from "react";
import LoadBoardTable from "./LoadBoardTable";
import LoadForm from "../../components/LoadForm/LoadForm";
import CreateLoadDropdown from "./CreateLoadDropdown";
import ReusableBulkUploadModal from "../../components/ReusableBulkUploadModal";
import ReusableBulkUploadFile from "../../components/ReusableBulkUploadFile";
import { useGetLoadUploadTemplateQuery } from "../../api/documents";
import { OneMegaByte } from "../../reuse/Files";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import useToastHook from "../../components/useToastHook";
import {
  useBulkUploadLoadsMutation,
  useValidateLoadUploadMutation,
} from "../../api/load";
import UploadLoadsSummary from "./UploadLoadsSummary";

export const LoadsBoardPage = (): JSX.Element => {
  const [tabs, setTabs] = useState<string[]>([]);
  const [showLoadForm, setShowLoadForm] = useState<boolean>(false);

  const toast = useToastHook();
  const { data: template, isFetching } = useGetLoadUploadTemplateQuery();
  const [validateFile, { data: validationResult, isLoading: isValidating }] =
    useValidateLoadUploadMutation();
  const [uploadLoads, { isLoading: isAdding }] = useBulkUploadLoadsMutation();

  const [fileName, setFileName] = useState("");

  const [isUploadOpen, toggleUploadModal] = useState(false);
  const [isUploadFileOpen, toggleUploadFile] = useState(false);

  const uploadFileOpen = (): void => {
    toggleUploadModal(false);
    toggleUploadFile(true);
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files![0];
    if (file.size >= 100 * OneMegaByte) {
      toast.error({ description: "File size is too large." });
      return;
    }
    setFileName(file.name);
    const response = await validateFile(file);
    if ("error" in response) {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onClickUpload = async (): Promise<void> => {
    if (!validationResult) return;

    const response = await uploadLoads(validationResult.readyToAdd);
    if ("data" in response) {
      toast.success({ description: "Loads added successfully" });
      toggleUploadModal(false);
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  useEffect(() => {
    // TODO: when we have saved views, we will add them here
    // This may require a little refactoring
    setTabs(["All loads"]);
  }, []);

  return (
    <VStack backgroundColor="grey6" w="100%">
      {showLoadForm ? (
        <LoadForm onClose={() => setShowLoadForm(false)} />
      ) : (
        <>
          <Flex
            w="100%"
            padding="1rem 4rem 0rem"
            justifyContent="space-between"
          >
            <Header headingText="Load Board" />
            <CreateLoadDropdown
              onClickAddSingle={() => setShowLoadForm(true)}
              onClickBulk={() => toggleUploadModal(true)}
            />
          </Flex>
          <TabsContainer
            containerBg="realWhite"
            tabList={tabs}
            tabPanels={[<LoadBoardTable key={`default load table`} />]}
          />
          <ReusableBulkUploadModal
            title="BULK UPLOAD LOADS"
            isOpen={isUploadOpen}
            onClose={() => toggleUploadModal(false)}
            downloadButtonTxt="DOWNLOAD TEMPLATE"
            uploadButtonTxt="UPLOAD LOADS"
            toggleFileSelectorFn={uploadFileOpen}
            downloadFileName="load_template"
            templateHref={template}
          />
          <ReusableBulkUploadFile
            isOpen={isUploadFileOpen}
            onClose={() => toggleUploadFile(false)}
            title="BULK UPLOAD LOADS"
            extensionFile=".xlsx"
            confirmBtnText="Upload"
            fileName={fileName}
            handleFileInput={handleFileInput}
            isLoaded={!isAdding && !isValidating && !isFetching}
            onClickUpload={onClickUpload}
            SummaryCpm={() =>
              validationResult ? (
                <UploadLoadsSummary validationResult={validationResult} />
              ) : (
                <></>
              )
            }
          />
        </>
      )}
    </VStack>
  );
};

export default LoadsBoardPage;
