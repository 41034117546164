import { InfoIcon } from "@chakra-ui/icons";
import { HStack, VStack, Text, Button, Tooltip } from "@chakra-ui/react";
import { useQueryState } from "../../api/shipper";
import { getNetworkInvite } from "../../reuse/Mail";
import { NetworkPartner } from "../../types/NetworkPartner";
import FmcsaCarrierTable from "../FmcsaCarrierTable";
import * as Actions from "../FmcsaCarrierTable/TableData";

export const PartnerPreviewDetail = ({
  fmcsaCarrier,
}: NetworkPartner): JSX.Element => {
  const shipperUser = useQueryState().data;

  const onClickInvite = (): void => {
    const { subject, body } = getNetworkInvite(
      shipperUser!.contactInformation.companyName,
    );
    const emailTo =
      fmcsaCarrier?.emailAddress === null ? "" : fmcsaCarrier?.emailAddress;
    window.open(`mailto:${emailTo}?subject=${subject}&body=${body}`);
  };

  const nameFmcsaCarrier = Actions.exportNames(fmcsaCarrier!);
  const physicalAdress = Actions.exportPhysicalAddress(fmcsaCarrier!);
  const mailingAdress = Actions.exportMailingAddress(fmcsaCarrier!);
  const totals = Actions.exportTotals(fmcsaCarrier!);
  const operations = Actions.exportOperations(fmcsaCarrier!);

  return (
    <VStack
      alignItems="center"
      h="100%"
      w="100%"
      p="1rem 3rem"
      overflowY="auto"
    >
      <HStack w="100%">
        <VStack
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          h="100%"
          w="50%"
          px="3rem"
        >
          <FmcsaCarrierTable title={"Info"} data={nameFmcsaCarrier} />
          <FmcsaCarrierTable title={"Mailing Address"} data={mailingAdress} />
          <FmcsaCarrierTable title={"Details"} data={totals} />
        </VStack>
        <VStack
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          h="100%"
          w="50%"
          px="3rem"
        >
          <FmcsaCarrierTable title={"Physical Address"} data={physicalAdress} />
          <FmcsaCarrierTable title={"Operations"} data={operations} />
        </VStack>
      </HStack>
      <HStack>
        <Text mt="4vh" textStyle="subTitle">
          This Carrier is not on MVMNT
        </Text>
        <Tooltip
          label={`Any requests for bids sent to this carrier will go to their FMCSA registered email address. 
          The carrier will not be able to provide their bid to you until they create a MVMNT account.`}
          fontSize="md"
          textColor="black"
          placement="right-end"
        >
          <InfoIcon />
        </Tooltip>
      </HStack>
      <Button onClick={onClickInvite} layerStyle="yellow" size="small">
        Invite to MVMNT
      </Button>
    </VStack>
  );
};

export default PartnerPreviewDetail;
