import { Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { getRatePerMileWinningBid } from "../../../reuse/Shipment";
import {
  Shipment,
  ShipmentStop,
  LocationHistory,
} from "../../../types/Shipment";
import ButtonGrid from "../../ButtonGrid";
import { ButtonGridButtons } from "../../ButtonGrid/ButtonGrid";
import AppRoutes from "../../../routes";
import { getCurrentTime, parseDateToShortDate } from "../../../reuse/Dates";

interface AdditionalInfoProps {
  showTrackingInfo: boolean;
  shipment: Shipment;
  buttons?: ButtonGridButtons[];
}

const DriverInfoSection = (
  shipmentPrice: number,
  shipmentRate: number | undefined | null,
  location?: LocationHistory,
  buttons?: ButtonGridButtons[],
): JSX.Element => {
  const CardButtons = (): JSX.Element => (
    <>
      {buttons && !window.location.href.includes(AppRoutes.PAST) && (
        <ButtonGrid
          buttons={buttons}
          parent="AdditionalTrackingInfo"
          columns={2}
        />
      )}
    </>
  );

  if (!location) return CardButtons();

  const { city, state, milesToDestination } = location!;
  const hasAddress = !!city && !!state;
  const address = `${location?.city}, ${location?.state}`;

  return (
    <VStack alignItems="start" mx="2rem" w="90%">
      {hasAddress && (
        <HStack>
          <VStack fontWeight="800">
            <Text fontSize={12} textStyle="uppercase">
              current location
            </Text>
            <Text>{hasAddress ? address : "N/A"}</Text>
            <Text fontSize={12} textStyle="card">
              {hasAddress ? milesToDestination : "N/A"}
              miles from Delivery
            </Text>
          </VStack>
          <VStack fontWeight="800">
            <Text fontSize={12} textStyle="uppercase">
              current price
            </Text>
            <Text>{shipmentPrice ?? 0}</Text>
            <Text fontSize={10} textStyle="card">
              {shipmentRate ? `$${shipmentRate}/mile` : "N/A"}
            </Text>
          </VStack>
        </HStack>
      )}
      {CardButtons()}
    </VStack>
  );
};

const StopCell = (
  title: string,
  value: string | undefined = "---",
): JSX.Element => (
  <HStack>
    <Text fontWeight="800">{title}</Text>
    <Text>{value ?? "---"}</Text>
  </HStack>
);

const StopsSection = (shipmentStops: ShipmentStop[]): JSX.Element => (
  <VStack alignItems="start" mx="2rem" w="90%" pb="1rem" layerStyle="shadow">
    {shipmentStops.map((stop, i) => (
      <VStack key={stop.id} alignItems="start" fontSize={14}>
        <Text fontWeight="800">{i % 2 === 0 ? "Pickup" : "Dropoff"} 1</Text>
        {StopCell("Location: ", `${stop.address.city}, ${stop.address.state}`)}
        {StopCell("Appointment Date: ", stop.date.toString())}
        {StopCell("Time: ", stop.appointmentTime?.toString())}
        {StopCell("Type: ", stop.appointmentType)}
        <Divider w="100%"></Divider>
      </VStack>
    ))}
  </VStack>
);

const LocationHistorySection = (
  locationHistory: LocationHistory[] | undefined,
): JSX.Element => (
  <VStack alignItems="start" w="90%" pb="1rem">
    <Text fontWeight="800">LOCATION HISTORY</Text>
    {locationHistory && locationHistory.length > 0 ? (
      locationHistory?.map((location, i) => {
        const { city, state, postalCode, timeStampTimeZone } = location!;
        const address = city && state ? `${city}, ${state}` : "---";
        const dateLocation = parseDateToShortDate(timeStampTimeZone);
        const currentTime = getCurrentTime(timeStampTimeZone);

        return (
          <VStack key={i} alignItems="start" fontSize={14}>
            {StopCell("Date: ", dateLocation + " " + currentTime)}
            {StopCell("Address: ", address)}
            {StopCell("Postal Code: ", postalCode)}
            <Divider w="100%"></Divider>
          </VStack>
        );
      })
    ) : (
      <Text fontSize={10}>No Location History</Text>
    )}
  </VStack>
);

const AdditionalInfo = ({
  shipment,
  showTrackingInfo,
  buttons,
}: AdditionalInfoProps): JSX.Element => (
  <>
    {showTrackingInfo && (
      <>
        {DriverInfoSection(
          shipment.value,
          getRatePerMileWinningBid(shipment),
          shipment.shipmentLocations?.[0],
          buttons,
        )}
        {StopsSection(shipment.shipmentStops)}
        {LocationHistorySection(shipment.shipmentLocations)}
      </>
    )}
  </>
);

export default AdditionalInfo;
