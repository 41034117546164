import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  RadioGroup,
  Radio,
  VStack,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import { DefaultModalProps } from "../../types/DefaultModal";
import { ShipmentVisibility } from "../../types/Shipment";

interface RequestQuoteModalProps extends DefaultModalProps {
  onClick: (visibility: ShipmentVisibility) => void;
}

export const RequestQuoteModal = ({
  isOpen,
  onClose,
  onClick,
}: RequestQuoteModalProps): JSX.Element => {
  const [selectedVisibility, setSelectedVisibility] = useState(
    ShipmentVisibility.InNetwork,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="uppercase">Request Quote</ModalHeader>
        <ModalBody>
          <RadioGroup
            value={selectedVisibility}
            onChange={(value) =>
              setSelectedVisibility(value as ShipmentVisibility)
            }
          >
            <VStack alignItems="start" spacing="1rem">
              <Radio value={ShipmentVisibility.InNetwork}>
                In-Network Partners
              </Radio>
              <Radio value={ShipmentVisibility.OutOfNetwork}>
                MVMNT Marketplace
              </Radio>
              <Radio value={ShipmentVisibility.All}>Everyone</Radio>
            </VStack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter justifyContent="start">
          <Button layerStyle="red" onClick={() => onClick(selectedVisibility)}>
            Request Quote
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RequestQuoteModal;
