export enum DocumentType {
  MCSRL = "MCSRL",
  W9 = "W9",
  LiabilityInsurance = "Liability",
  CertRegistration = "Registration",
  NameChange = "NameChange",
  SCACRenewal = "SCAC",
  AddressChange = "Address",
  CommerceCert = "Commerce",
  TransportCreds = "TranspCreds",
  FactoringLetter = "FactLetter",
  CommonCert = "Certificate",
  StateIncorp = "Incorp",
  PropertyPerm = "Property",
  SmartPart = "Smartway",
  Other = "Other",
  ShipperAgreement = "ShipperAgreement",
  FacilityRequirements = "FacilityRequirements",
}

export interface S3File {
  file: File;
  url: string;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

export interface DocumentUpload {
  fileName: string;
  contentType: string;
  documentType: DocumentType;
  networkId?: string;
}

export interface MvmntDocument {
  id: string;
  documentName: string;
  uploadDate: Date;
  documentType: DocumentType;
  contentType: string;
  networkPartner?: string;
  shipmentId?: string;
}

export interface Documents {
  label: string;
  value: DocumentType;
}

export interface DataDocProps {
  name: string | null;
  extension: string;
  url?: string | null;
}

export enum ShipmentDocumentType {
  HazmatCertificate = "Hazmat Certificate",
  OverdimensionalPermit = "Overdimensional Permit",
  OtherPermit = "Other Permit",
  BillOfLading = "Bill Of Lading",
  OtherDoc = "Other Doc",
}
export interface DriverDocumentUpload {
  id: string;
  fileName: string;
  contentType: string;
  documentType: ShipmentDocumentType;
  customLabel?: string;
}

export interface IRfpDocumentUpload {
  rfpId: string;
  fileName: string;
  contentType: string;
}
