import { useMemo, useState } from "react";
import {
  HStack,
  Input,
  VStack,
  Select,
  Text,
  FormControl,
  FormLabel,
  Divider,
  Skeleton,
  Box,
  Textarea,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { MultiSelect } from "react-multi-select-component";
import ShipmentEquipmentsForm from "../ShipmentEquipmentsForm";
import ShipmentStopsForm from "../ShipmentStopsForm";
import { toMultiselectOptions, Option } from "../../reuse/MultiSelect";
import {
  CustomAccessorials,
  MaterialType,
  StopType,
} from "../../types/ShipmentForm";
import ErrorMessage from "../ErrorMessage";
import { FormError } from "../../types/FormError";
import FormState from "../FormState";
import FormCountry from "../FormCountry";
import { borderStyling } from "../../reuse/Forms";
import useToastHook from "../../components/useToastHook";
import { shipmentContent } from "../ShipmentForm/options";
import { CloseIcon, InfoIcon } from "@chakra-ui/icons";
import { Countries } from "../../resources/regions";
import SharedButton from "../SharedButton";
import { LoadCreate, LoadInvoiceTerms } from "../../types/Load";
import { ShipmentCreate } from "../../types/Shipment";
import { useCreateLoadMutation } from "../../api/load";
import { parseErrorResponse } from "../../reuse/ErrorHandler";

const materialOptions = toMultiselectOptions(Object.values(MaterialType));

interface LoadFormProps {
  onClose: () => void;
}

export const LoadForm = ({ onClose }: LoadFormProps): JSX.Element => {
  const toast = useToastHook();

  const [createLoad, { isLoading }] = useCreateLoadMutation();

  const [hazardousMaterial, setHazardousMaterial] = useState<Option[]>([]);

  // This is part of a separate hook to allow us to reuse the other parts of the shipment
  // form e.g. ShipmentEquipmentsForm
  const loadFormHook =
    useForm<Pick<LoadCreate, "customerName" | "invoiceTerms" | "bolNotes">>();

  const formHook = useForm<ShipmentCreate>({
    defaultValues: {
      shipmentStops: [
        {
          stopType: StopType.Pickup,
          address: {
            country: Countries.US,
          },
        },
        {
          stopType: StopType.Delivery,
          address: {
            country: Countries.US,
          },
        },
      ],
    },
    mode: "all",
  });

  const customAccessorialsHook = useForm<CustomAccessorials>();
  const {
    register,
    trigger,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
    control: formControl,
  } = formHook;

  const {
    trigger: loadTrigger,
    register: loadRegister,
    getValues: getLoadValues,
    formState: { errors: loadErrors },
    clearErrors: clearLoadErrors,
  } = loadFormHook;

  const { getValues: getAccessorials, control: accControl } =
    customAccessorialsHook;
  const customAccessorialsFieldArray = useFieldArray({
    control: accControl,
    name: "accessorials",
  });
  const shipmentStopsFieldArray = useFieldArray({
    name: "shipmentStops",
    control: formControl,
  });

  const { shipmentStops, ...shipmentErrors } = errors;

  const getMaterialTypesFromOptions = (list: Option[]): MaterialType[] => {
    return list.map((option) => option.value) as MaterialType[];
  };

  const onChangeMaterial = (materials: Option[]): void => {
    const values = getMaterialTypesFromOptions(materials);
    setValue("materialTypes", values);
    setHazardousMaterial(materials);
  };

  const getLoadData = (): LoadCreate => {
    const formData = getValues();
    const loadData = getLoadValues();
    const defaultAccessorials = formData.accessorials ?? [];
    const customAccessorials = getAccessorials("accessorials").map(
      (acc) => acc.label,
    );
    const accessorials = [...defaultAccessorials, ...customAccessorials];
    const load = { ...formData, accessorials, ...loadData };
    return load;
  };

  const onCreateOrder = async (): Promise<void> => {
    const loadData = getLoadData();

    const shipmentValid = await trigger();
    const loadValid = await loadTrigger();

    if (shipmentValid && loadValid) {
      const response = await createLoad(loadData);
      if ("data" in response) {
        toast.success({ description: "Load created successfully" });
        onClose();
      } else {
        toast.error({ description: `${parseErrorResponse(response.error)}` });
      }
    }
  };

  register("materialTypes", {
    required: {
      value: false,
      message: "You must enter the DOT hazardous material designation",
    },
  });

  const onChangeDollarValue = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    // Strips out $ , and - characters from number display so we can store it as type number
    setValue("value", Number(event.target.value.replace(/[$,-]/g, "")));
  };

  const ShipmentEquipment = useMemo(
    () => (
      <ShipmentEquipmentsForm
        formHook={formHook}
        accessorialsHook={customAccessorialsHook}
        isReadOnly={false}
        customAccessorialsFieldArray={customAccessorialsFieldArray}
      />
    ),
    [
      formHook,
      customAccessorialsHook,
      getValues().accessorials,
      getAccessorials().accessorials,
      customAccessorialsFieldArray,
    ],
  );

  return (
    <Skeleton
      isLoaded={!isLoading}
      alignSelf="center"
      w="60%"
      minW="fit-content"
      my="2%"
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <VStack>
          <ErrorMessage
            errors={shipmentErrors as FormError}
            clearErrors={clearErrors}
          />
          <ErrorMessage
            errors={loadErrors as FormError}
            clearErrors={clearLoadErrors}
          />
          <VStack
            w="100%"
            maxW="60vw"
            bgColor="realWhite"
            p="3%"
            borderRadius="sm"
            alignItems="start"
            spacing="1rem"
          >
            <IconButton
              aria-label="Close"
              icon={<CloseIcon color="gray60" />}
              backgroundColor="realWhite"
              onClick={onClose}
              alignSelf="flex-end"
            />
            <Text textStyle="title">Internal Shipment ID</Text>
            <Input
              placeholder="Internal Shipment ID"
              {...register("externalShipmentId", {
                required: false,
              })}
              disabled={false}
              autoFocus
            />
            <Text textStyle="title">Customer Name</Text>
            <Input
              placeholder="Customer Name"
              {...loadRegister("customerName", {
                required: true,
              })}
              disabled={false}
              autoFocus
            />
            <Text textStyle="title">Invoice Terms</Text>
            <Select
              w="25%"
              {...loadRegister(`invoiceTerms`, {
                required: {
                  value: true,
                  message: "You must choose the invoice terms",
                },
              })}
            >
              {Object.values(LoadInvoiceTerms).map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
            <Divider />
            <ShipmentStopsForm
              formHook={formHook}
              isReadOnly={false}
              shipmentStopsFieldArray={shipmentStopsFieldArray}
              isCopying={false}
            />{" "}
            <Divider />
            {ShipmentEquipment}
            <Divider />
            <Text textStyle="title">Shipment Content Details</Text>
            <HStack w="100%" alignItems="end">
              <FormControl>
                <FormLabel>Packaging Unit Type</FormLabel>
                <Select
                  {...register("unitType", {
                    required: {
                      value: true,
                      message: "You must enter a unit type",
                    },
                  })}
                  disabled={false}
                  style={{ borderColor: borderStyling(errors.unitType) }}
                >
                  {shipmentContent.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Input
                type="number"
                placeholder="Count of units"
                {...register("unitCount", {
                  required: {
                    value: false,
                    message: "You must enter the unit count",
                  },
                  min: 0,
                  valueAsNumber: true,
                })}
                disabled={false}
              />
            </HStack>
            <HStack w="100%">
              <Box w="100%" maxW="49%">
                <MultiSelect
                  overrideStrings={{
                    selectSomeItems: "DOT Hazardous Material Designation",
                  }}
                  disableSearch
                  hasSelectAll={false}
                  value={hazardousMaterial}
                  options={materialOptions}
                  labelledBy="Select"
                  onChange={onChangeMaterial}
                  disabled={false}
                />
              </Box>
              <HStack w="100%">
                <Controller
                  render={({ field }) => {
                    return (
                      <NumberFormat
                        decimalScale={2}
                        allowEmptyFormatting={false}
                        prefix={"$"}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        {...field}
                        disabled={false}
                        min={0}
                        style={{
                          border: "1px solid",
                          borderColor: errors.value ? "tomato" : "",
                          borderRadius: "8px",
                          width: "100%",
                          minWidth: 0,
                          outline: "2px solid transparent",
                          outlineOffset: "2px",
                          appearance: "none",
                          color: "black",
                          textAlign: "center",
                          height: "2.5rem",
                        }}
                        placeholder="Shipment Value"
                        {...register("value", {
                          valueAsNumber: true,
                        })}
                        onChange={onChangeDollarValue}
                      />
                    );
                  }}
                  name="value"
                  control={formControl}
                />
              </HStack>
            </HStack>
            <Divider />
            <Text textStyle="title">Third Party Billing Information</Text>
            <HStack w="100%">
              <Input
                w="49%"
                placeholder="Attn: Accounts Payable Handler"
                {...register("billingNote", {
                  required: false,
                })}
                disabled={false}
              />
              <Input
                w="50%"
                placeholder="Business Name of Accounts Payable Handler"
                {...register("billingAddress.addressName", {
                  required: false,
                })}
                disabled={false}
              />
            </HStack>
            <HStack w="100%">
              <Input
                w="49%"
                placeholder="Address 1"
                {...register("billingAddress.address1", {
                  required: false,
                })}
                disabled={false}
              />
              <Input
                w="24%"
                placeholder="Zip"
                {...register("billingAddress.postalCode", {
                  required: false,
                })}
                disabled={false}
              />
              <Input
                w="25%"
                placeholder="City"
                {...register("billingAddress.city", {
                  required: false,
                })}
                disabled={false}
              />
            </HStack>
            <HStack w="100%">
              <Input
                w="49%"
                placeholder="Address 2"
                {...register("billingAddress.address2", {
                  required: false,
                })}
                disabled={false}
              />
              <FormCountry
                w="25%"
                isDisabled={false}
                formHook={formHook}
                property="billingAddress"
              />
              <FormState
                w="24%"
                isDisabled={false}
                formHook={formHook}
                property="billingAddress"
              />
            </HStack>
            <Divider />
            <Text textStyle="title">
              Additional Notes{" "}
              <Tooltip
                label={`The additional notes will display on the Carrier Rate Confirmation and Customer BOL. The additional notes are Order level details`}
                fontSize="md"
                textColor="black"
                placement="right-end"
                layerStyle={"yellow"}
              >
                <InfoIcon h={5} w={5} />
              </Tooltip>
            </Text>
            <HStack w="100%">
              <Textarea
                placeholder="Additional Notes"
                resize="none"
                maxLength={250}
                defaultValue=""
                {...loadRegister(`bolNotes`, {})}
                isDisabled={false}
              />
            </HStack>
            <SharedButton
              title="Create order and quote"
              backgroundColor="yellow40"
              color="gray90"
              onClick={onCreateOrder}
            />
          </VStack>
        </VStack>
      </form>
    </Skeleton>
  );
};

export default LoadForm;
