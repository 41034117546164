import {
  VStack,
  FormControl,
  Input,
  Button,
  FormLabel,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { ShipperAccountFormProps } from "../../types/ShipperAccountForm";
import ErrorMessage from "../ErrorMessage";
import { Employee } from "../../types/ShipperEmployees";
import { validateEmail } from "../../reuse/UserData";
import { FormError } from "../../types/FormError";

interface FormEmployees {
  employees: Employee[];
}

export const ShipperEmployees = ({
  shipperUser,
  handleUpdate,
  isUpdating,
}: ShipperAccountFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    control,
    reset,
  } = useForm<FormEmployees>();
  const { fields, append, remove } = useFieldArray({
    name: "employees",
    control,
    keyName: "key",
  });

  useEffect(() => {
    if (shipperUser) {
      reset({ employees: shipperUser.employees });
    }
  }, [shipperUser]);

  const onSubmit = handleSubmit(async (data: FormEmployees) => {
    await handleUpdate({
      employees: data.employees.map((employee) => {
        return {
          firstName: employee.firstName,
          lastName: employee.lastName,
          email: employee.email,
        };
      }),
    });
  });

  const onAddEmployee = (): void => {
    append({
      firstName: "",
      lastName: "",
      email: "",
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <VStack alignItems="center">
        <FormControl isDisabled={isUpdating}>
          <HStack alignItems="end">
            <FormLabel>Add carrier-facing employee</FormLabel>
            <IconButton
              isLoading={isUpdating}
              icon={<AddIcon />}
              aria-label="Add employee"
              onClick={onAddEmployee}
            />
          </HStack>
          {fields.map((employee, index) => (
            <VStack key={employee.email ?? index}>
              <FormControl isDisabled={isUpdating}>
                <Input
                  placeholder="First Name"
                  {...register(`employees.${index}.firstName` as const, {
                    required: true,
                  })}
                />
              </FormControl>
              <FormControl isDisabled={isUpdating}>
                <Input
                  placeholder="Last Name"
                  {...register(`employees.${index}.lastName` as const, {
                    required: false,
                  })}
                />
              </FormControl>
              <FormControl isDisabled={isUpdating}>
                <Input
                  placeholder="Email"
                  {...register(`employees.${index}.email` as const, {
                    required: false,
                    validate: validateEmail,
                  })}
                />
              </FormControl>
              <IconButton
                isLoading={isUpdating}
                icon={<SmallCloseIcon />}
                aria-label="Remove employee"
                onClick={() => remove(index)}
              />
            </VStack>
          ))}
        </FormControl>

        <Button
          layerStyle="red"
          w="20%"
          onClick={onSubmit}
          isLoading={isUpdating}
        >
          Update
        </Button>

        <ErrorMessage errors={errors as FormError} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default ShipperEmployees;
