import { HStack, Text, VStack } from "@chakra-ui/react";
import ReusableUploadSumary from "../../components/ReusableUploadSumary";
import { SummaryCmp } from "../../components/ReusableUploadSumary/ReusableUploadSumary";
import { parseDateToShortDate } from "../../reuse/Dates";
import { ValidationResults } from "../../types/BulkUpload";
import { LoadRowToAdd } from "../../types/LoadUpload";

interface UploadLoadsSummaryProps {
  validationResult: ValidationResults<LoadRowToAdd>;
}

const ReadyToAddSummary = (
  validationResult: ValidationResults<LoadRowToAdd>,
): JSX.Element => (
  <>
    {validationResult.readyToAdd?.slice(0, 10).map((load, index) => (
      <HStack key={index} alignItems="baseline" minW="max-content">
        <VStack m={1}>
          <Text>Load Id</Text>
          <Text>{load.externalShipmentId ?? "---"}</Text>
        </VStack>
        <VStack m={1}>
          <Text>Origin</Text>
          <Text>{load.shipmentStops[0].city}</Text>
        </VStack>
        <VStack m={1}>
          <Text>Pickup Date</Text>
          <Text>{parseDateToShortDate(load.shipmentStops[0].date)}</Text>
        </VStack>
        <VStack m={1}>
          <Text>Destination</Text>
          <Text>{load.shipmentStops[load.shipmentStops.length - 1].city}</Text>
        </VStack>
        <VStack m={1}>
          <Text>Delivery Date</Text>
          <Text>
            {parseDateToShortDate(
              load.shipmentStops[load.shipmentStops.length - 1].date,
            )}
          </Text>
        </VStack>
      </HStack>
    ))}
  </>
);

export const UploadLoadsSummary = ({
  validationResult,
}: UploadLoadsSummaryProps): JSX.Element => {
  const { misFormattedRows, readyToAdd } = validationResult;
  const hiddenRowsAmount = readyToAdd!.length - 10;

  return (
    <>
      <ReusableUploadSumary
        MisFormattedRowsCmp={() =>
          misFormattedRows!.length > 0 ? (
            <SummaryCmp
              text="The following rows are not correctly formatted and will be ignored:"
              array={misFormattedRows}
            />
          ) : (
            <></>
          )
        }
        ReadyToAddCmp={() =>
          readyToAdd!.length > 0 ? (
            <SummaryCmp text="The following rows will be added:">
              {() => ReadyToAddSummary(validationResult)}
            </SummaryCmp>
          ) : (
            <></>
          )
        }
      />
      {hiddenRowsAmount > 0 && <Text>{hiddenRowsAmount} more rows</Text>}
    </>
  );
};

export default UploadLoadsSummary;
