import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Text,
  ModalBody,
  Image,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { BidCarrierInfo } from "../../api/carrier";
import { DefaultModalProps } from "../../types/DefaultModal";
import ChevronSourcing from "../../resources/svg/chevron_sourcing.svg";
import { Address } from "../../types/Address";
import ButtonGrid from "../ButtonGrid";
import { ButtonGridButtons } from "../ButtonGrid/ButtonGrid";

interface CarrierInfoModalProps extends DefaultModalProps {
  buttons: ButtonGridButtons[];
  carrier: BidCarrierInfo;
  columns: number;
}

const CarrierInfoHeader = (header: string): JSX.Element => (
  <HStack
    borderBottomWidth={1}
    borderBottomStyle="solid"
    borderBottomColor="#707070"
    marginLeft={5}
    marginRight={5}
  >
    <VStack>
      <ModalHeader
        paddingBottom="0px !important"
        paddingLeft="0px !important"
        textStyle="uppercase"
      >
        {header}
      </ModalHeader>
    </VStack>
    <VStack alignSelf="end">
      <Image width={8} height={8} src={ChevronSourcing} />
    </VStack>
  </HStack>
);

// CONTACT INFORMATION
const ContacInfoLabels = (): JSX.Element => (
  <VStack alignItems="start">
    <Text textStyle="subTitle" fontSize={18}>
      Name
    </Text>
    <Text textStyle="subTitle" fontSize={18}>
      Email
    </Text>
    <Text textStyle="subTitle" fontSize={18}>
      Phone
    </Text>
  </VStack>
);

const ContactInfoValues = (carrier: BidCarrierInfo): JSX.Element => (
  <VStack alignItems="start">
    <Text color="#707070">{carrier.companyContacts.contactName ?? "---"}</Text>
    <Text color="#707070">{carrier.companyContacts.contactEmail ?? "---"}</Text>
    <Text color="#707070">
      {carrier.companyContacts.contactPhoneNumber ?? "---"}
    </Text>
  </VStack>
);

// COMPANY DETAILS
const CompanyInfoLabels = (): JSX.Element => (
  <VStack alignItems="start">
    <Text textStyle="subTitle" fontSize={18}>
      Address
    </Text>
    <Text textStyle="subTitle" fontSize={18}>
      Docket
    </Text>
    <Text textStyle="subTitle" fontSize={18}>
      DOT#
    </Text>
  </VStack>
);

const CompanyInfoValues = (carrier: BidCarrierInfo): JSX.Element => {
  const address: Address | undefined =
    carrier.contactInformation.companyBillingAddresses[0];

  return (
    <VStack alignItems="start">
      <Text color="#707070">{address ? address.address1 : "---"}</Text>
      <Text color="#707070">
        {carrier.companyInformation.mcNumber ?? "---"}
      </Text>
      <Text color="#707070">
        {carrier.companyInformation.dotNumber ?? "---"}
      </Text>
    </VStack>
  );
};

export const CarrierInfoModal = ({
  isOpen,
  onClose,
  buttons,
  carrier,
  columns,
}: CarrierInfoModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        {CarrierInfoHeader("CONTACT INFORMATION")}
        <ModalBody>
          <HStack spacing={24}>
            {ContacInfoLabels()}
            {ContactInfoValues(carrier)}
          </HStack>
        </ModalBody>
        {CarrierInfoHeader(carrier.companyName)}
        <ModalBody>
          <HStack spacing={24}>
            {CompanyInfoLabels()}
            {CompanyInfoValues(carrier)}
          </HStack>
        </ModalBody>
        <ModalFooter justifyContent="end">
          <ButtonGrid
            buttons={buttons}
            parent="BidInfoModal"
            columns={columns}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CarrierInfoModal;
