import { VStack } from "@chakra-ui/react";
import { Tabs, TabPanels, TabPanel } from "@chakra-ui/tabs";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { tabList } from "../../reuse/Rfp";
import { IRfp } from "../../types/Rfp";
import { UserType } from "../../types/User";
import ComingSoon from "../ComingSoon";
import LanesProposalDetail from "../LanesProposalDetail";
// import AnalyticsProposalDetail from "../AnalyticsProposalDetail";
import CarriersProposalDetail from "../CarriersProposalDetail";
import TabListElements from "../TabListElements";
import DocsProposalDetail from "../DocsProposalDetail";
import WaterfallProposalDetail from "../WaterfallProposalDetail";

interface RfpDetailProps {
  selectedRfp?: IRfp | null;
}

export const RfpDetail = ({ selectedRfp }: RfpDetailProps): JSX.Element => {
  const { type } = useAppSelector(selectUserData);

  const shipperType = UserType.SHIPPER === type || UserType.BROKER === type;
  const tabListByUserType = tabList.filter(({ types }) => types.includes(type));

  return (
    <VStack h="100%" w="100%" p="4rem" bgColor="grey">
      <Tabs
        isFitted
        variant="unstyled"
        w="100%"
        h="100%"
        pt="2rem"
        bgColor="white"
        pos="relative"
        rounded="10px"
      >
        <TabListElements tabListByUserType={tabListByUserType} />
        <TabPanels
          h={`${shipperType ? "calc(100% - 40px)" : "calc(100% - 120px)"}`}
          overflow="auto"
          p="2rem 0 1rem 0"
        >
          <TabPanel>
            <LanesProposalDetail />
          </TabPanel>
          {shipperType && (
            <TabPanel>
              <CarriersProposalDetail />
            </TabPanel>
          )}
          <TabPanel>
            <DocsProposalDetail />
          </TabPanel>
          {shipperType && (
            <TabPanel>
              <WaterfallProposalDetail />
            </TabPanel>
          )}
          <TabPanel>
            <ComingSoon />
            {/* <AnalyticsProposalDetail /> */}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default RfpDetail;
