import { createApi } from "@reduxjs/toolkit/query/react";
import { setUserData } from "../app/userSlice";
import { User } from "../types/User";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";

export const userApi = createApi({
  reducerPath: "userApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}/user`),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => "/",
      onQueryStarted: async (_arg, api): Promise<void> => {
        try {
          const { dispatch, queryFulfilled } = api;
          const { data } = await queryFulfilled;
          dispatch(setUserData(data));
        } catch (err) {
          alert("Failed to set user data!");
        }
      },
    }),
  }),
});

export const { useGetUserQuery } = userApi;
