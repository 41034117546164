import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  Text,
  ModalBody,
} from "@chakra-ui/react";
import { DefaultModalProps } from "../../types/DefaultModal";

interface AlertModalProps extends DefaultModalProps {
  title: string;
  content: string;
}

export const AlertModal = ({
  isOpen,
  onClose,
  title,
  content,
}: AlertModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="uppercase">{title}</ModalHeader>
        <ModalBody>
          <Text>{content}</Text>
        </ModalBody>
        <ModalFooter justifyContent="space-around">
          <Button layerStyle="yellow" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AlertModal;
