import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppRoutes from "./routes";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Shell from "./pages/Shell";
import ResetPassword from "./pages/ResetPassword";
import AccountRequest from "./pages/AccountRequest";
import { UserType } from "./types/User";
import UpdatePassword from "./pages/UpdatePassword";

function App(): JSX.Element {
  document.addEventListener("wheel", function (event) {
    if (
      document?.activeElement &&
      "type" in document.activeElement &&
      (document.activeElement as any).type === "number" // eslint-disable-line @typescript-eslint/no-explicit-any
    ) {
      if ("blur" in document.activeElement)
        (document.activeElement as any).blur(); // eslint-disable-line @typescript-eslint/no-explicit-any
    }
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route path={AppRoutes.LOGIN} element={<Login />} />
        <Route path={AppRoutes.CREATE_ACCOUNT} element={<SignUp />} />
        <Route path={AppRoutes.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={AppRoutes.UPDATE_PASSWORD} element={<UpdatePassword />} />
        <Route
          path={AppRoutes.SHIPPER_ACCOUNT_REQUEST}
          element={<AccountRequest userType={UserType.SHIPPER} />}
        />
        <Route
          path={AppRoutes.BROKER_ACCOUNT_REQUEST}
          element={<AccountRequest userType={UserType.BROKER} />}
        />
        <Route path="/*" element={<Shell />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
