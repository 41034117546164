import { TimeIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  Spinner,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { ILoadAddress } from "../../../types/Load";
import "./LoadBoardDetails.scss";
import { locationIcon } from "../../../resources/svg/CustomIcons";
import { getStateAbbrFromStateCountryPair } from "../../../reuse/RegionKeys";
import { toTitleCase } from "../../../reuse/Strings";
import { AppointmentType, StopType } from "../../../types/ShipmentForm";
import { useUpdateLoadStopMutation } from "../../../api/load";
import { useState } from "react";
import { useNotificationBanner } from "../../../components/useNotificationBanner";

interface LoadDetailsStopTabProps {
  stop: ILoadAddress;
}
export const LoadDetailsStopTab = ({
  stop,
}: LoadDetailsStopTabProps): JSX.Element => {
  const notificationBanner = useNotificationBanner();
  const [updateStop, { isLoading }] = useUpdateLoadStopMutation();
  const [currentNotes, setCurrentNotes] = useState<string>(stop.notes ?? "");
  const [appointmentType, setAppointmentType] = useState<AppointmentType>(
    stop.appointmentType,
  );
  const [appointmentDate, setAppointmentDate] = useState<string>(
    String(stop.date),
  );
  const [appointmentTime, setAppointmentTime] = useState<string>(
    stop.appointmentTime ?? "",
  );
  const [consigneeName, setConsigneeName] = useState<string | undefined>(
    stop.receivingName,
  );
  const LocationIcon = locationIcon("gray.400");
  const stateAbbr = getStateAbbrFromStateCountryPair(stop.state, stop.country);

  const onSave = async (): Promise<void> => {
    const apptTime =
      appointmentType === AppointmentType.FCFS ? null : appointmentTime;
    if (!apptTime && appointmentType === AppointmentType.Appointment) {
      alert("Appointment time required for appointment type stops");
      return;
    }
    if (stop.stopType === StopType.Delivery && !consigneeName) {
      alert("Consignee name required for delivery stops");
      return;
    }
    const response = await updateStop({
      id: stop.id,
      notes: currentNotes,
      apptType: appointmentType,
      appointmentTime: apptTime,
      receivingName: consigneeName !== "" ? consigneeName : undefined,
      date: appointmentDate,
    });

    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: (
          <p>There was a problem updating this stop. Please try again.</p>
        ),
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>Update successful.</p>,
      });
    }
  };

  return (
    <>
      {stop && (
        <Box overflowY="auto">
          <VStack
            w="100%"
            paddingBottom="1rem"
            borderBottom="1px solid grey5"
            alignItems="flex-start"
          >
            <HStack justifyContent="space-between" w="100%">
              <Text as="h3" fontSize="20px" fontWeight="600">
                Location
              </Text>
              <Button
                fontSize={13}
                color="white"
                borderRadius="22px"
                bgColor="gray90"
                p="8px 16px"
                marginRight="8px"
                disabled={isLoading}
                onClick={onSave}
              >
                {isLoading ? <Spinner /> : "Save"}
              </Button>
            </HStack>
            <Flex w="100%" p="1rem 0rem">
              <Text
                w="50%"
                className="load-board-drawer-field-title"
                display="flex"
                alignItems="center"
              >
                ADDRESS
              </Text>
              <HStack alignItems="center" w="50%">
                <LocationIcon
                  w="20px"
                  h="20px"
                  display="block"
                  viewBox="0 0 23 21"
                />
                <Box alignItems="start">
                  <Text className="load-board-drawer-field-value">
                    {stop.address1}
                    {stop.address2 ? `, ${stop.address2}` : ""}
                  </Text>
                  <Text className="load-board-drawer-field-value">
                    {toTitleCase(stop.city)}, {stateAbbr}, {stop.postalCode}
                  </Text>
                </Box>
              </HStack>
            </Flex>
            <VStack
              w="100%"
              alignItems="flexStart"
              marginTop="1rem !important"
              marginBottom="1rem !important"
            >
              <FormLabel
                color="gray50"
                className="load-board-drawer-field-title"
              >
                STOP NOTES
              </FormLabel>
              <Textarea
                value={currentNotes}
                onChange={(e) => setCurrentNotes(e.target.value)}
                placeholder="Stop notes"
                resize="none"
                borderRadius="4px"
              />
            </VStack>
          </VStack>
          <Divider borderColor={"grey5"} marginBottom={5} />
          <VStack w="100%" alignItems="flex-start" paddingBottom="3rem">
            <Text fontSize="20px" as="h3" fontWeight="600">
              Appointment
            </Text>
            <VStack w="100%">
              <Flex w="100%">
                <Text w="50%" className="load-board-drawer-field-title">
                  TYPE
                </Text>
                <FormControl w="50%">
                  <Select
                    onChange={(e) =>
                      setAppointmentType(e.target.value as AppointmentType)
                    }
                    value={appointmentType}
                  >
                    {Object.values(AppointmentType).map((type) => (
                      <option
                        key={`select load stop appt type option:${type}`}
                        value={type}
                      >
                        {type}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
              <Flex w="100%">
                <Text w="50%" className="load-board-drawer-field-title">
                  DATE
                </Text>
                <Input
                  w="50%"
                  type="date"
                  value={appointmentDate}
                  onChange={(e) => setAppointmentDate(e.target.value)}
                />
              </Flex>

              <Flex w="100%">
                <Text w="50%" className="load-board-drawer-field-title">
                  TIME
                </Text>
                {appointmentType === AppointmentType.Appointment ? (
                  <Input
                    w="50%"
                    type="time"
                    value={appointmentTime}
                    onChange={(e) => setAppointmentTime(e.target.value)}
                  />
                ) : (
                  <Text w="50%" className="load-board-drawer-field-value">
                    <Icon
                      mr="5px"
                      as={TimeIcon}
                      color="gray40"
                      h="100%"
                      justifySelf={"center"}
                      alignSelf="center"
                    />
                    First come first served
                  </Text>
                )}
              </Flex>
              <Flex w="100%">
                <Text w="50%" className="load-board-drawer-field-title">
                  HOURS OF OPERAATION
                </Text>
                <Text w="50%" className="load-board-drawer-field-value">
                  {!stop.allDayDock
                    ? `${stop.dockOpen} - ${stop.dockClose}`
                    : "24 hour dock"}
                </Text>
              </Flex>
            </VStack>
          </VStack>
          {stop.stopType === StopType.Delivery && (
            <>
              <Divider borderColor={"grey5"} marginBottom={5} />
              <VStack w="100%" alignItems="flex-start" paddingBottom="3rem">
                <Text fontSize="20px" as="h3" fontWeight="600">
                  Consignee Contact
                </Text>
                <VStack w="100%">
                  <Flex w="100%">
                    <Text w="50%" className="load-board-drawer-field-title">
                      Name
                    </Text>
                    <Input
                      value={consigneeName}
                      onChange={(e) => setConsigneeName(e.target.value)}
                      w="50%"
                      placeholder="Consignee Name"
                    />
                  </Flex>
                </VStack>
              </VStack>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default LoadDetailsStopTab;
