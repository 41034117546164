import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  Text,
  Grid,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import mvmntTheme from "../../styles/theme";
import { activeRfpShipperHeaders } from "../LanesProposalDetail/constants";
import { RowLaneProps, RowShipperLaneProps } from "../../types/Rfp";
import {
  filterCommonKeys,
  mapShipperLanesToRow,
  rowLaneEnum,
} from "../LanesProposalDetail/utils";
import LaneBidsTable from "../LaneBidsTable";
import { useActiveRfpContext } from "../../pages/ActiveRfp/ActiveRfpContext";

export const WaterfullTable = (): JSX.Element => {
  const rfpContext = useActiveRfpContext();
  const { selectedRfp } = rfpContext!;

  const [lanesTable, setLanesTable] = useState<RowShipperLaneProps[]>([]);

  const TableData = (field: RowLaneProps): JSX.Element => {
    return (
      <>
        {filterCommonKeys(Object.keys(field), rowLaneEnum).map((key) => {
          return (
            <Text textAlign="center" key={key}>
              {field[key as keyof RowLaneProps] ?? "---"}
            </Text>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    if (selectedRfp!.lanes!.length > 0) {
      const list = mapShipperLanesToRow(selectedRfp!.lanes!);
      setLanesTable(list);
    } else {
      setLanesTable([]);
    }
  }, [selectedRfp?.id]);

  return (
    <VStack w="100%" overflow="auto">
      <Grid gridTemplateColumns="repeat(13, 1fr)" w="100%" px="15px">
        {activeRfpShipperHeaders.map(({ id, header, color }) => (
          <Text key={id} textAlign="center" color={color}>
            {header}
          </Text>
        ))}
      </Grid>
      {lanesTable.map((lane) => (
        <Accordion
          key={lane.id}
          w="100%"
          rounded="15px"
          allowToggle
          bgColor={mvmntTheme.colors.selectedBlue}
        >
          <AccordionItem borderStyle="none" p="10px 0">
            <AccordionButton>
              <Grid gridTemplateColumns="repeat(13, 1fr)" w="100%">
                {TableData(lane)}
                <Text textAlign="center">{lane.bids}</Text>
                <Text textAlign="center">{lane.low}</Text>
              </Grid>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel bgColor="white">
              <LaneBidsTable currentBids={lane.laneCurrentBids!} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}
    </VStack>
  );
};

export default WaterfullTable;
