import { Skeleton, VStack, HStack, Heading } from "@chakra-ui/react";
import { useGetShipmentsByShipperIdQuery } from "../../api/shipment";
import { sortShipmentsByDate } from "../../reuse/Shipment";
import ShipmentHistorySidebarCard from "../ShipmentHistorySidebarCard/ShipmentHistorySidebarCard";

export const ShipmentHistory = (): JSX.Element => {
  const { data: responseShipments, isFetching } =
    useGetShipmentsByShipperIdQuery();
  // make a copy to allow us to assign to what would otherwise be read only attributes
  const shipments = responseShipments ? [...responseShipments] : [];

  return (
    <Skeleton minW="20%" h="100%" isLoaded={!isFetching} overflowY="auto">
      <VStack bgColor="white !important" h="100%" spacing={0}>
        <HStack
          layerStyle="yellow"
          minH="32px"
          w="100%"
          justifyContent="space-between"
          px="15px"
        >
          <Heading size="md" textStyle="uppercase">
            Shipment History
          </Heading>
        </HStack>
        <VStack
          alignItems="start"
          w="100%"
          maxH="85vh"
          overflowY="auto"
          spacing={0}
        >
          {sortShipmentsByDate(shipments).map((shipment) => (
            <ShipmentHistorySidebarCard shipment={shipment} key={shipment.id} />
          ))}
        </VStack>
      </VStack>
    </Skeleton>
  );
};

export default ShipmentHistory;
