import { Text, Flex } from "@chakra-ui/react";

interface DocumentCardTypeProps {
  type: string;
}

export const DocumentCardType = ({
  type,
}: DocumentCardTypeProps): JSX.Element => {
  return (
    <Flex
      h="100%"
      w="130px"
      alignItems="center"
      justifyContent="center"
      bgColor="yellow"
      borderRadius="8px"
    >
      <Flex
        bgColor="realWhite"
        w="50%"
        h="60%"
        alignItems="center"
        justifyContent="center"
        borderRadius="8px"
      >
        <Text fontSize={20}>{type}</Text>
      </Flex>
    </Flex>
  );
};

export default DocumentCardType;
