import { createApi } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";
import { invalidateTags } from "./shipment";
import { ICreateDriver, UpdateShipmentProps } from "../types/Shipment";

export const driverApi = createApi({
  reducerPath: "driverApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}/driver`),
  tagTypes: ["Driver"],
  endpoints: (builder) => ({
    dispatchDriver: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "PUT",
      }),
      onQueryStarted: async (_arg, api): Promise<void> => {
        try {
          const { dispatch, queryFulfilled } = api;
          await queryFulfilled;
          dispatch(invalidateTags(["Tracking"]));
        } catch (err) {}
      },
    }),
    assignDriver: builder.mutation<void, ICreateDriver>({
      query: (body) => ({
        url: `/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Driver"],
      onQueryStarted: async (_arg, api): Promise<void> => {
        try {
          const { dispatch, queryFulfilled } = api;
          await queryFulfilled;
          dispatch(invalidateTags(["Tracking"]));
        } catch (err) {}
      },
    }),
    updateDriver: builder.mutation<void, UpdateShipmentProps>({
      query: ({ body, id }) => ({
        url: `/update/${id}`,
        method: "PUT",
        body,
      }),
      onQueryStarted: async (_arg, api): Promise<void> => {
        try {
          const { dispatch, queryFulfilled } = api;
          await queryFulfilled;
          dispatch(invalidateTags(["Tracking"]));
        } catch (err) {}
      },
    }),
  }),
});

export const {
  useDispatchDriverMutation,
  useAssignDriverMutation,
  useUpdateDriverMutation,
} = driverApi;
