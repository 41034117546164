import { Alert, AlertIcon, AlertTitle, CloseButton } from "@chakra-ui/react";
import {
  FormError,
  FormErrorValue,
  NestedFormError,
  NestedFormArrayError,
} from "../../types/FormError";

interface ErrorMessageProps {
  errors: FormError;
  clearErrors: () => void;
}

const isNestedFormArrayError = (
  error: FormErrorValue,
): error is NestedFormArrayError => {
  return (error as NestedFormArrayError).length !== undefined;
};

const isNestedFormError = (error: FormErrorValue): error is NestedFormError => {
  return (
    (error as NestedFormError).length === undefined &&
    typeof Object.values(error)[0] === "object"
  );
};

export const ErrorMessage = ({
  errors,
  clearErrors,
}: ErrorMessageProps): JSX.Element | null => {
  const errorValues = Object.values(errors);
  const firstError = errorValues[0];
  let error;

  if (!firstError) return null;
  else if (isNestedFormError(firstError)) error = Object.values(firstError)[0];
  else if (isNestedFormArrayError(firstError))
    error = Object.values(firstError[0])[0];
  else error = firstError;

  return error?.message ? (
    <Alert status="error">
      <AlertIcon color="white" />
      <AlertTitle mr={2} color="white" maxW="fit-content">
        {error.message}
      </AlertTitle>
      <CloseButton
        onClick={() => clearErrors()}
        position="absolute"
        right="8px"
        top="8px"
        color="white"
      />
    </Alert>
  ) : null;
};

export default ErrorMessage;
