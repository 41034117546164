import { VStack, HStack, Heading, Button } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { bidIsPending, bidWasSubmitted, quoteIsNew } from "../../../reuse/Bids";
import { Shipment } from "../../../types/Shipment";
import QuoteRequestCard from "../QuoteRequestCard";
import QuoteSortModal from "../QuoteSortModal";

interface QuoteRequestSidebarProps {
  quotes?: Shipment[];
}

export const QuoteRequestSidebar = ({
  quotes,
}: QuoteRequestSidebarProps): JSX.Element => {
  const [sortedQuotes, setSortedQuotes] = useState<Shipment[]>();
  const [isSortOpen, toggleSortOpen] = useState(false);

  useEffect(() => setSortedQuotes(quotes), [quotes]);

  const [pendingQuotes, submittedQuotes] = useMemo(() => {
    const pendingQuotes: Shipment[] = [];
    const submittedQuotes: Shipment[] = [];

    sortedQuotes?.forEach((quote) => {
      const { bids } = quote;

      if (bidWasSubmitted(quote, bids)) submittedQuotes.push(quote);
      else if (quoteIsNew(bids) || bidIsPending(quote, bids))
        pendingQuotes.push(quote);
    });

    return [pendingQuotes, submittedQuotes];
  }, [sortedQuotes]);

  return (
    <VStack bgColor="white" minW="20%" h="100%" spacing={0}>
      <HStack
        layerStyle="yellow"
        minH="32px"
        w="100%"
        justifyContent="space-between"
        px="15px"
      >
        <Heading size="md" textStyle="uppercase">
          Quote Requests
        </Heading>
        <Heading size="md">
          {pendingQuotes.length + submittedQuotes.length}
        </Heading>
      </HStack>
      <HStack
        layerStyle="black"
        minH="32px"
        w="100%"
        justifyContent="space-between"
        px="15px"
      >
        <Heading size="md" textStyle="uppercase" color="white">
          Pending Response
        </Heading>
        <Heading size="md" color="white">
          {pendingQuotes.length}
        </Heading>
      </HStack>
      <VStack w="100%" h="40%" spacing={0} overflowY="auto">
        {pendingQuotes.map((quote) => (
          <QuoteRequestCard key={quote.id} quote={quote} />
        ))}
      </VStack>
      <HStack
        layerStyle="black"
        minH="32px"
        w="100%"
        justifyContent="space-between"
        px="15px"
      >
        <Heading size="md" textStyle="uppercase" color="white">
          Submitted
        </Heading>
        <Heading size="md" color="white">
          {submittedQuotes.length}
        </Heading>
      </HStack>
      <VStack w="100%" h="40%" spacing={0} overflowY="auto">
        {submittedQuotes.map((quote) => (
          <QuoteRequestCard key={quote.id} quote={quote} />
        ))}
      </VStack>
      <Button
        my="2vh !important"
        w="90%"
        size="small"
        layerStyle="yellow"
        onClick={() => toggleSortOpen(true)}
      >
        Sort / Filter
      </Button>
      <QuoteSortModal
        isOpen={isSortOpen}
        onClose={() => toggleSortOpen(false)}
        setQuotes={setSortedQuotes}
        quotes={quotes}
      />
    </VStack>
  );
};

export default QuoteRequestSidebar;
