import { Skeleton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useGetNetworkUploadTemplateQuery } from "../../api/documents";
import {
  useBulkAddCarriersToNetworkMutation,
  useGetNetworkCarriersQuery,
  useValidateUploadFileMutation,
} from "../../api/network";
import AddNetworkCarrier from "../../components/AddNetworkCarrier";
import ReusableBulkUploadFile from "../../components/ReusableBulkUploadFile";
import ReusableBulkUploadModal from "../../components/ReusableBulkUploadModal";
import { FooterCmp } from "../../components/ReusableUploadSumary/ReusableUploadSumary";
import useToastHook from "../../components/useToastHook";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { OneMegaByte } from "../../reuse/Files";
import { sortText } from "../../reuse/Sorting";
import { NetworkCarrierUploadResult } from "../../types/NetworkUpload";
import Network from "../Network";
import UploadCarriersSummary from "./UploadCarriersSummary";

export const ShipperNetwork = (): JSX.Element => {
  const [isAddModalOpen, toggleAddModal] = useState(false);

  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [isUploadFileOpen, toggleUploadFile] = useState(false);
  const [fileName, setFileName] = useState("");

  const { data: network, isLoading } = useGetNetworkCarriersQuery();
  const { data: template } = useGetNetworkUploadTemplateQuery();
  const [validateFile, { data: validationResult, isLoading: isValidating }] =
    useValidateUploadFileMutation();
  const [addCarriers, { isLoading: isAdding }] =
    useBulkAddCarriersToNetworkMutation();

  const toast = useToastHook();

  const partners = network ? [...network] : [];

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files![0];
    if (file.size >= 100 * OneMegaByte) {
      toast.error({ description: "File size is too large." });
      return;
    }
    setFileName(file.name);
    const response = await validateFile(file);
    if ("error" in response) {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onClickUpload = async (): Promise<void> => {
    if (!validationResult) return;
    const response = await addCarriers([
      ...validationResult.readyToAdd,
      ...validationResult.alreadyAdded,
    ]);
    if ("data" in response) {
      toast.success({ description: "Carriers added successfully" });
      toggleUploadModal(false);
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  useEffect(() => {
    sortPartners(true);
  }, [network]);

  const sortPartners = (asc: boolean): void => {
    partners.sort((a, b) =>
      sortText(
        (a.carrier?.companyName ?? a.fmcsaCarrier?.legalName ?? "")!,
        (b.carrier?.companyName ?? b.fmcsaCarrier?.legalName ?? "")!,
        asc,
      ),
    );
  };

  const onClickBulkUpload = (): void => {
    toggleAddModal(false);
    toggleUploadModal(true);
  };

  const uploadFileOpen = (): void => {
    toggleUploadModal(false);
    toggleUploadFile(true);
  };

  return (
    <Skeleton isLoaded={!isLoading} w="100%" h="100%">
      <Network
        partners={partners}
        toggleAddModal={toggleAddModal}
        sortPartners={sortPartners}
      />
      <AddNetworkCarrier
        isOpen={isAddModalOpen}
        onClose={() => toggleAddModal(false)}
        onClickBulkUpload={onClickBulkUpload}
      />
      <ReusableBulkUploadModal
        title="BULK UPLOAD CARRIERS"
        isOpen={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        downloadButtonTxt="DOWNLOAD TEMPLATE"
        uploadButtonTxt="UPLOAD FILE"
        toggleFileSelectorFn={uploadFileOpen}
        downloadFileName="network_template"
        templateHref={template}
        footerCpm={FooterCmp}
      />
      <ReusableBulkUploadFile
        isOpen={isUploadFileOpen}
        onClose={() => toggleUploadFile(false)}
        title="BULK UPLOAD CARRIERS"
        extensionFile=".xlsx"
        confirmBtnText="Add Carriers"
        fileName={fileName}
        handleFileInput={handleFileInput}
        isLoaded={!isAdding && !isValidating}
        onClickUpload={onClickUpload}
        SummaryCpm={() =>
          validationResult ? (
            <UploadCarriersSummary
              validationResult={validationResult as NetworkCarrierUploadResult}
            />
          ) : (
            <></>
          )
        }
      />
    </Skeleton>
  );
};

export default ShipperNetwork;
