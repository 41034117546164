import { useEffect, useState, useMemo } from "react";
import { VStack, Button, Td, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { UserType } from "../../types/User";
import { selectUserData } from "../../app/userSlice";
import { useAppSelector } from "../../app/hooks";
import AddLaneForm from "../AddLaneForm";
import AbsoluteRightButtons from "../AbsoluteRightButtons";
import AppRoutes from "../../routes";
import { RfpButtonsPosition, RowLaneProps } from "../../types/Rfp";
import {
  useValidateLaneUploadMutation,
  useBulkUploadLanesMutation,
  useSendLanesToCarriersMutation,
} from "../../api/rfp";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import useToastHook from "../useToastHook";
import CarrierLanesTable from "./CarrierLanesTable";
import ShipperLanesTable from "./ShipperLanesTable";
import { filterCommonKeys, rowLaneEnum } from "./utils";
import {
  createCsvFromObjectsArray,
  exportFileResults,
  mapLanesDataToExport,
  OneMegaByte,
} from "../../reuse/Files";
import ReusableBulkUploadModal from "../ReusableBulkUploadModal";
import { useLazyGetLaneUploadTemplateQuery } from "../../api/documents";
import ReusableBulkUploadFile from "../ReusableBulkUploadFile";
import UploadLanesSummary from "./UploadLanesSummary";
import { FooterCmp } from "../ReusableUploadSumary/ReusableUploadSumary";
import { LaneCreateBulkResult } from "../../types/LaneUpload";
import { useActiveRfpContext } from "../../pages/ActiveRfp/ActiveRfpContext";

export const TableData = (field: RowLaneProps): JSX.Element => {
  return (
    <>
      {filterCommonKeys(Object.keys(field), rowLaneEnum).map((key) => {
        return (
          <Td textAlign="center" key={key}>
            {field[key as keyof RowLaneProps] ?? "---"}
          </Td>
        );
      })}
    </>
  );
};

const PromptCpm = (validationResult: LaneCreateBulkResult): JSX.Element =>
  validationResult && (
    <Text
      textAlign="center"
      w="100%"
      p="1.5rem 2rem"
      fontSize="14px"
      letterSpacing="1.5px"
      fontWeight="bolder"
    >
      VALUES PROVIDED IN THIS FILE WILL OVERRIDE ANY EXISTING BIDS. BIDS
      PROVIDED IN THE FILE WILL SAVE, BUT NOT SUBMIT YOUR BIDS
    </Text>
  );

export const LanesProposalDetail = (): JSX.Element => {
  const rfpContext = useActiveRfpContext();
  const { selectedRfp, largerThan1620 } = rfpContext!;
  const location = useLocation();
  const toast = useToastHook();
  const { type } = useAppSelector(selectUserData);

  const shipperType = UserType.SHIPPER === type || UserType.BROKER === type;

  const [downloadLanesTemplate] = useLazyGetLaneUploadTemplateQuery();
  const [
    validateFile,
    { data: validationResult, isLoading: isValidating, reset },
  ] = useValidateLaneUploadMutation();
  const [sendCarrierEmails, { isLoading: isSendingLanes }] =
    useSendLanesToCarriersMutation();
  const [uploadLanes, { isLoading: isAdding }] = useBulkUploadLanesMutation();

  const [fileName, setFileName] = useState("");
  const [addLaneModal, toggleLaneModal] = useState<boolean>(false);
  const [bulkUploadModal, toggleBulkUploadModal] = useState<boolean>(false);
  const [bulkUploadModalFile, toggleBulkUploadModalFile] =
    useState<boolean>(false);

  const sendToCarriers = async (): Promise<void> => {
    const response = await sendCarrierEmails({ rfpId: selectedRfp!.id! });
    if ("data" in response) {
      toast.success({ description: "Carriers notified successfully" });
    } else {
      toast.error({ description: parseErrorResponse(response.error) });
    }
  };

  const downloadLanes = (): void => {
    const lanes = selectedRfp?.lanes ?? [];
    const mappedLanes = mapLanesDataToExport(lanes);
    const csvUrl = createCsvFromObjectsArray(mappedLanes);
    exportFileResults(csvUrl, "rfp_lanes.csv");
  };

  const addLane = (): void => toggleLaneModal(true);

  const toggleBulkUpload = (): void => {
    toggleLaneModal(false);
    toggleBulkUploadModal(true);
  };

  const uploadFileOpen = (): void => {
    toggleBulkUploadModal(false);
    toggleBulkUploadModalFile(true);
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files![0];
    if (file.size >= 100 * OneMegaByte) {
      alert("File size is too large.");
      return;
    }
    setFileName(file.name);
    const response = await validateFile({ id: selectedRfp!.id!, file });
    if ("data" in response) {
      toast.success({ description: "Upload successful." });
    } else {
      toast.error({ description: parseErrorResponse(response.error) });
    }
  };

  const onClickUpload = async (): Promise<void> => {
    if (!validationResult) return;
    const response = await uploadLanes({
      bulkUploadLanes: {
        lanesToAdd: validationResult.readyToAdd,
        lanesToUpdate: validationResult.readyToUpdate,
      },
      rfpId: selectedRfp!.id!,
    });

    if ("data" in response) {
      toast.success({ description: "Lanes added successfully" });
      toggleBulkUploadModalFile(false);
    } else {
      toast.error({ description: parseErrorResponse(response.error) });
    }
  };

  const MemoizedShipperLanesTable = useMemo(
    () => (
      <ShipperLanesTable
        lanes={selectedRfp?.lanes}
        shipperType={shipperType}
        selectedRfp={selectedRfp!}
      />
    ),
    [selectedRfp],
  );

  const MemoizedCarrierLanesTable = useMemo(
    () => <CarrierLanesTable />,
    [selectedRfp],
  );

  const exportFn = async (): Promise<void> => {
    const response = await downloadLanesTemplate();
    if ("error" in response) {
      toast.error({ description: "Template not available" });
    } else {
      exportFileResults(response.data!, "lanes_template");
    }
  };

  useEffect(() => {
    setFileName("");
    reset();
  }, [bulkUploadModalFile]);

  return (
    <VStack h="100%" w="100%">
      {selectedRfp && shipperType && (
        <AbsoluteRightButtons
          top={
            largerThan1620
              ? RfpButtonsPosition.Primary
              : RfpButtonsPosition.Secondary
          }
          left={
            largerThan1620
              ? RfpButtonsPosition.Infimum
              : RfpButtonsPosition.Primary
          }
          right={
            largerThan1620
              ? RfpButtonsPosition.Primary
              : RfpButtonsPosition.Infimum
          }
        >
          <Button
            layerStyle="yellow"
            color="black"
            onClick={shipperType ? sendToCarriers : downloadLanes}
            isLoading={isSendingLanes}
          >
            {shipperType ? "SEND LANES TO CARRIERS" : "DOWNLOAD LANES"}
          </Button>
          {location.pathname === AppRoutes.ACTIVE_RFP ? (
            <Button
              layerStyle="red"
              onClick={shipperType ? addLane : undefined} // TODO does undefined need to be an action?
            >
              {shipperType ? "ADD LANE" : "UPLOAD BIDS"}
            </Button>
          ) : (
            <></>
          )}
        </AbsoluteRightButtons>
      )}
      <VStack>
        {shipperType ? MemoizedShipperLanesTable : MemoizedCarrierLanesTable}
      </VStack>
      <AddLaneForm
        isOpen={addLaneModal}
        onClose={() => toggleLaneModal(false)}
        toggleBulkUpload={toggleBulkUpload}
        rfpId={selectedRfp?.id}
      />
      <ReusableBulkUploadModal
        title={shipperType ? "BULK UPLOAD LANES" : "BULK UPLOAD BIDS"}
        isOpen={bulkUploadModal}
        onClose={() => toggleBulkUploadModal(false)}
        downloadButtonTxt="DOWNLOAD LANES TEMPLATE"
        uploadButtonTxt="UPLOAD FORMATTED LANES"
        toggleFileSelectorFn={uploadFileOpen}
        downloadFileName="lanes_template"
        footerCpm={FooterCmp}
        exportFn={exportFn}
      />
      <ReusableBulkUploadFile
        isOpen={bulkUploadModalFile}
        onClose={() => toggleBulkUploadModalFile(false)}
        title="BULK UPLOAD LANES"
        extensionFile=".xlsx"
        confirmBtnText="Add Lanes"
        fileName={fileName}
        handleFileInput={handleFileInput}
        isLoaded={!isAdding && !isValidating}
        onClickUpload={onClickUpload}
        PromptCpm={() => (!shipperType ? PromptCpm(validationResult!) : <></>)}
        SummaryCpm={() =>
          validationResult ? (
            <UploadLanesSummary validationResult={validationResult} />
          ) : (
            <></>
          )
        }
      />
    </VStack>
  );
};

export default LanesProposalDetail;
