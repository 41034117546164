import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  Select,
  ModalBody,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useGetUserDocumentsQuery } from "../../api/documents";
import {
  useSelectAgreementFileMutation,
  useAddCarrierToNetworkMutation,
} from "../../api/network";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { DefaultModalProps } from "../../types/DefaultModal";
import { DocumentType } from "../../types/Files";
import useToastHook from "../../components/useToastHook";

interface SelectAgreementProps extends DefaultModalProps {
  networkId?: string;
  onClickUpload: () => void;
  carrierDotNumber?: string;
  onSuccess?: () => void;
}

export const SelectAgreement = ({
  networkId,
  onClose,
  onClickUpload,
  isOpen,
  carrierDotNumber,
  onSuccess,
}: SelectAgreementProps): JSX.Element => {
  const { data, isFetching } = useGetUserDocumentsQuery();
  const [addCarrierToNetwork, { isLoading: isAdding }] =
    useAddCarrierToNetworkMutation();
  const [selectFile, { isLoading }] = useSelectAgreementFileMutation();
  const [selectedDocument, setSelectedDocument] = useState<string>();
  const toast = useToastHook();

  const availableAgreements = useMemo(
    () =>
      data?.filter(
        (doc) => doc.documentType === DocumentType.ShipperAgreement,
      ) ?? [],
    [data],
  );

  useEffect(
    () => setSelectedDocument(availableAgreements[0]?.id),
    [availableAgreements],
  );

  const onConfirm = async (): Promise<void> => {
    if (!selectedDocument) return;

    let newNetworkId = "";
    if (!networkId && carrierDotNumber) {
      const response = await addCarrierToNetwork(carrierDotNumber);
      if ("data" in response) {
        newNetworkId = response.data;
      } else {
        toast.error({ description: `${parseErrorResponse(response.error)}` });
        return;
      }
    }

    const response = await selectFile({
      networkId: networkId ?? newNetworkId,
      documentId: selectedDocument,
    });
    if ("data" in response) {
      toast.success({ description: "Agreement selected successfully" });
      onClose();
      onSuccess?.();
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const renderConfirmationHeader = (): JSX.Element =>
    !networkId && carrierDotNumber ? (
      <Text fontSize={18} mb={4}>
        This action will add this carrier to your network if not already. Do you
        want to proceed?
      </Text>
    ) : (
      <></>
    );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <Skeleton w="100%" isLoaded={!isFetching && !isLoading && !isAdding}>
        <ModalContent>
          <ModalHeader textStyle="uppercase">
            Select Shipper-Carrier Agreement
          </ModalHeader>
          <ModalBody>
            {renderConfirmationHeader()}
            <Select
              onChange={(e) => setSelectedDocument(e.target.value)}
              value={selectedDocument}
            >
              {availableAgreements.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.documentName}
                </option>
              ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button
              layerStyle="red"
              onClick={onConfirm}
              disabled={!selectedDocument}
            >
              Confirm
            </Button>
            <Button
              size="sm"
              color="mvmntRed"
              variant="link"
              onClick={onClose}
              ml="1vw"
            >
              Cancel
            </Button>
            <Button layerStyle="yellow" onClick={onClickUpload} ml="auto">
              Upload File
            </Button>
          </ModalFooter>
        </ModalContent>
      </Skeleton>
    </Modal>
  );
};

export default SelectAgreement;
