import {
  RowLaneProps,
  ILane,
  LaneBidCreate,
  IRfp,
  ILaneBid,
  IRowBidProp,
  ILaneBidCreate,
} from "../../types/Rfp";

const createInitialRowLane = (lane: ILane): RowLaneProps => {
  const stops = lane.laneStops;
  const origin = stops[0];
  const destiny = stops[stops.length - 1];
  return {
    id: lane.id!,
    laneId: lane.laneId,
    origin: `${origin.city}, ${origin.state}`,
    pickupOrigin: origin.loadType,
    destiny: `${destiny.city}, ${destiny.state}`,
    pickupDestiny: destiny.loadType,
    mileage: lane.mileage,
    volume: lane.volume,
    frequency: lane.frequency,
    mode: lane.mode,
    equipmentType: lane.equipmentType,
    equipmentSize: lane.equipmentSize,
  };
};

const orderLaneBidsByCriteria = (lane: ILane, field: string): ILaneBid[] => {
  if (lane.laneBids && lane.laneBids.length > 0) {
    const bids = [...lane.laneBids];
    const ordered = bids.sort((a: ILaneBid, b: ILaneBid) =>
      a[field as keyof ILaneBid]! < b[field as keyof ILaneBid]! ? 1 : -1,
    );
    return ordered;
  }
  return [];
};

export const mapShipperLanesToRow = (lanes: ILane[]): RowLaneProps[] => {
  return lanes.map((lane) => {
    const initial = createInitialRowLane(lane);
    const length = lane.laneBids?.length ?? 0;
    const orderedBids = orderLaneBidsByCriteria(lane, "effectiveBid");
    const reverseOrdered = orderedBids.reverse();
    const low = reverseOrdered[0]
      ? reverseOrdered[0].effectiveBid.toString()
      : undefined;
    return {
      ...initial,
      bids: length.toString(),
      low,
      laneCurrentBids: lane.laneBids,
    };
  });
};

export const mapCarrierLanesToRow = (lanes: ILane[]): RowLaneProps[] => {
  return lanes.map((lane) => {
    const initial = createInitialRowLane(lane);
    const orderedBids = orderLaneBidsByCriteria(lane, "updateDate");
    return {
      ...initial,
      laneBidId: orderedBids[0]?.id,
      rpm: orderedBids[0]?.ratePerMile.toString() ?? "",
      minimum: orderedBids[0]?.minimumBid.toString() ?? "",
      commitment: orderedBids[0]?.volumeCommitment.toString() ?? "",
    };
  });
};

export const createPostData = (
  controlledFields: RowLaneProps[],
  selectedRfp: IRfp,
  type: string,
): LaneBidCreate => {
  const rfpId = selectedRfp.id!;
  const laneBids = controlledFields
    .filter((laneBid) => laneBid.rpm && laneBid.commitment && laneBid.minimum)
    .map((laneBid) => {
      const submitRightAwayPayload: ILaneBidCreate = {
        laneId: String(laneBid.id),
        ratePerMile: Number(laneBid.rpm),
        minimumBid: Number(laneBid.minimum),
        commitment: Number(laneBid.commitment),
      };

      if (
        type === "SUBMIT" &&
        laneBid.rpm &&
        laneBid.minimum &&
        laneBid.commitment &&
        laneBid.laneBidId
      ) {
        submitRightAwayPayload.laneBidId = String(laneBid.laneBidId);
      }
      return submitRightAwayPayload;
    });

  return {
    rfpId,
    laneBids,
  };
};

export const rowLaneEnum: string[] = [
  "laneId",
  "origin",
  "pickupOrigin",
  "destiny",
  "pickupDestiny",
  "mileage",
  "volume",
  "frequency",
  "mode",
  "equipmentType",
  "equipmentSize",
];

export const filterCommonKeys = (
  keys: string[],
  commonKeys: string[],
): string[] => keys.filter((key) => commonKeys.includes(key));

export const mapCurrentBidsToAwardProps = (bids: ILaneBid[]): IRowBidProp[] => {
  return bids.map((bid) => {
    const carrierInfo = bid.carrierInfo;
    return {
      id: bid.id!,
      carrierId: carrierInfo.carrierId,
      carrierName: carrierInfo.carrierName,
      carrierDOTNumber: carrierInfo.carrierDOTNumber,
      carrierMCNumber: carrierInfo.carrierMCNumber,
      carrierEmailAddress: carrierInfo.carrierEmailAddress,
      minimumBid: bid.minimumBid ?? "",
      effectiveBid: bid.effectiveBid ?? "",
      actualBid: bid.actualBid ?? "",
      awardedVolume: bid.awardedVolume ?? "",
    };
  });
};
