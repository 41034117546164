const SECONDS_IN_A_YEAR = 31536000;
const SECONDS_IN_A_MONTH = 2592000; // roughly
const SECONDS_IN_A_DAY = 86400;
const SECONDS_IN_A_HOUR = 3600;
const SECONDS_IN_A_MINUTE = 60;

export const parseDateToLocal = (date: Date): string =>
  new Date(date).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

export const parseDateToLocalShort = (date: Date): string =>
  new Date(date).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

export const parseStopDateToLocal = (date: Date): string =>
  new Date(date).toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

// e.g Saturday, Aug 27
export const parseToWeekdayMonthDate = (date: Date): string =>
  new Date(date).toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    weekday: "long",
  });

export const getDayNameFromDate = (date: Date): string =>
  new Date(date).toLocaleDateString("en-US", {
    weekday: "short",
  });

export const parseDateToShortDate = (date: Date): string => {
  const asDate = new Date(date);
  const month = `${asDate.getMonth() + 1}`;
  return `${month.padStart(
    2,
    "0",
  )}/${asDate.getDate()}/${asDate.getFullYear()}`;
};

// Date -> YYYY-MM-DD string
export const getDateYYYYMMDDWithDashes = (date: Date): string => {
  const asDate = new Date(date);
  return asDate.toISOString().slice(0, 10);
};

export const getMinimumDate = (): string =>
  new Date().toISOString().split("T")[0];

export const getDateDiffInMinutes = (
  date: Date,
  seccondsDetail?: boolean,
): string => {
  const seconds = (new Date().getTime() - new Date(date).getTime()) / 1000;

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? `${d} ${d === 1 ? " day, " : " days, "}` : "";
  const hDisplay = h > 0 ? `${h} ${h === 1 ? " hour, " : " hours, "}` : "";
  const mDisplay = m > 0 ? `${m} ${m === 1 ? " minute, " : " minutes"}` : "";
  const sDisplay = s > 0 ? `${s} ${s === 1 ? ", second" : " seconds"}` : "";

  let finalDiff: string = dDisplay + hDisplay + mDisplay;
  if (seccondsDetail) {
    finalDiff = finalDiff + sDisplay;
  }

  return finalDiff;
};

export const getDateDiffInDays = (
  dateA: Date | undefined,
  dateB: Date | undefined,
): number => {
  if (!dateA || !dateB) return 0;

  const differenceInTime = dateB.getTime() - dateA.getTime();
  return differenceInTime / (1000 * 3600 * 24);
};

export const transformDate = (date: Date): string =>
  date.toString().replaceAll("-", "/");

export const getCurrentTime = (timeStampTimeZone: Date): string => {
  return new Date(timeStampTimeZone).toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const sortDate = (a: Date, b: Date, asc: boolean): number => {
  const dateA = new Date(a).getTime() || 0;
  const dateB = new Date(b).getTime() || 0;
  if (dateA > dateB) return asc ? 1 : -1;
  if (dateA < dateB) return asc ? -1 : 1;
  return 0;
};

// a few dates are stored in the DB as YYYY-MM-DD, unfortunately javascript doesn't have a Date
// type with no time, so the timezone can shift what the date reads as
// This is an attempt to mitigate that
// This should return a matching date in the current timezone and time
// for display purposes
export const createDateFromYYYYMMDDString = (dateString: string): Date => {
  const dateComponents = dateString.split("-");
  if (dateComponents.length !== 3) throw new Error("Malformed date string");
  const returnDate = new Date();
  returnDate.setFullYear(
    Number(dateComponents[0]),
    Number(dateComponents[1]),
    Number(dateComponents[2]),
  );
  return returnDate;
};

// the html input for a datetime-local takes the format "1991-08-24T22:00" as a string
export const getDateTimeInputString = (date: Date): string => {
  const newDate = new Date(date);
  newDate.setSeconds(0);
  const dateString = getDateYYYYMMDDWithDashes(newDate);

  return (
    dateString + "T" + newDate.toLocaleTimeString(undefined, { hour12: false })
  );
};

export const getUniqueDatesInTimezone = (dates: Date[]): Date[] => {
  const uniqueDatesStrings: string[] = [];
  const uniqueDates: Date[] = [];

  for (const date of dates) {
    if (!uniqueDatesStrings.includes(date.toLocaleDateString())) {
      uniqueDatesStrings.push(date.toLocaleDateString());
      uniqueDates.push(date);
    }
  }

  return uniqueDates;
};

// gets only the largest scale time since returns "integer scale"
// More or less stolen from:https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site
export const getTimeSinceString = (date: Date): string => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let interval = seconds / SECONDS_IN_A_YEAR;

  if (interval > 1) {
    return `${Math.floor(interval)} year${interval >= 2 ? "s" : ""}`;
  }
  interval = seconds / SECONDS_IN_A_MONTH;
  if (interval > 1) {
    return `${Math.floor(interval)} month${interval >= 2 ? "s" : ""}`;
  }
  interval = seconds / SECONDS_IN_A_DAY;
  if (interval > 1) {
    return `${Math.floor(interval)} day${interval >= 2 ? "s" : ""}`;
  }
  interval = seconds / SECONDS_IN_A_HOUR;
  if (interval > 1) {
    return `${Math.floor(interval)} hour${interval >= 2 ? "s" : ""}`;
  }
  interval = seconds / SECONDS_IN_A_MINUTE;
  if (interval > 1) {
    return `${Math.floor(interval)} minute${interval >= 2 ? "s" : ""}`;
  }

  return `${Math.floor(interval * SECONDS_IN_A_MINUTE)} seconds`;
};
