import { VStack, FormControl, Input, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { validateEmail, validatePhoneNumber } from "../../reuse/UserData";
import { CarrierAccountFormProps } from "../../types/CarrierAccountForm";
import { CompanyContacts } from "../../types/CompanyContacts";
import ErrorMessage from "../ErrorMessage";

export const CompanyContactsForm = ({
  carrierUser,
  handleUpdate,
  isUpdating,
}: CarrierAccountFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm<CompanyContacts>();

  useEffect(() => {
    if (carrierUser) {
      reset(carrierUser.companyContacts);
    }
  }, [carrierUser]);

  const onSubmit = handleSubmit(
    async (companyContacts: CompanyContacts) =>
      await handleUpdate({ companyContacts }),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const untypedControl = control as Control<any>;

  return (
    <form onSubmit={onSubmit}>
      <VStack alignItems="center">
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Company Contact Name"
            {...register("contactName", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Company Contact Title"
            {...register("contactTitle", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Controller
            control={control}
            name="contactPhoneNumber"
            rules={{
              required: false,
              validate: validatePhoneNumber,
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <PhoneInput
                placeholder="Company Contact Phone Number"
                control={untypedControl}
                name={name}
                value={value}
                onChange={onChange}
                country="US"
              />
            )}
          />
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Company Contact Email Address"
            {...register("contactEmail", {
              required: false,
              validate: validateEmail,
            })}
          ></Input>
        </FormControl>
        <Button
          layerStyle="red"
          w="20%"
          onClick={onSubmit}
          isLoading={isUpdating}
        >
          Update
        </Button>
        <ErrorMessage errors={errors} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default CompanyContactsForm;
