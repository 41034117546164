import { VStack, Text } from "@chakra-ui/layout";
import { useGetLoadsQuery, useGetLoadCustomersQuery } from "../../api/load";
import {
  DataTable,
  useDataTable,
} from "../../ui/components/DataTable/DataTable";
import {
  ILoad,
  ILoadAddress,
  LoadSortOptions,
  LoadStatus,
} from "../../types/Load";
import LoadBoardStatusBadge from "./LoadBoardStatusBadge";
import { AppointmentType } from "../../types/ShipmentForm";
import { getStateAbbrFromStateCountryPair } from "../../reuse/RegionKeys";
import { HStack } from "@chakra-ui/react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Option } from "../../reuse/MultiSelect";
import DatePicker from "react-datepicker";
import LoadBoardSubhead from "./LoadBoardSubhead";
import NoDataDisplayed from "./NoDataDisplayed";
import { useDebounce } from "../../components/useDebounceHook";
import LoadBoardDetailsDrawer from "./LoadBoardDetails/LoadBoardDetailsDrawer";
import LoadBoardIcons from "./LoadBoardIcons";

interface IOptionslStartEndDateStrings {
  start: string | undefined;
  end: string | undefined;
}

const getSortDirectionForSortOption = (
  option: LoadSortOptions,
): "ASC" | "DESC" => {
  switch (option) {
    case LoadSortOptions.LoadId:
    case LoadSortOptions.UpdateDate:
      return "DESC";
    default:
      return "ASC";
  }
};

export const LoadBoardTable = (): JSX.Element => {
  const { data: allCustomerNames } = useGetLoadCustomersQuery();
  const statusOptions = Object.values(LoadStatus).map((status) => {
    return { label: status, value: status };
  });
  const [statusFilters, setStatusFilters] = useState<Option[]>([]);
  const [sortBy, setSortBy] = useState<LoadSortOptions | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const customerNameOptions =
    allCustomerNames?.map((customerName) => {
      return { label: customerName, value: customerName };
    }) ?? [];
  const [customerFilters, setCustomerFilters] = useState<Option[]>([]);

  const [pickupDateFilter, setPickupDateFilter] =
    useState<IOptionslStartEndDateStrings>({
      start: undefined,
      end: undefined,
    });
  const [deliveryDateFilter, setDeliveryDateFilter] =
    useState<IOptionslStartEndDateStrings>({
      start: undefined,
      end: undefined,
    });

  // These display variables only exist so we can avoid converting between timezones when dealing
  // with the datepickers and the date format from the db (YYYY-MM-DD)
  const [pickupDateStartDisplay, setPickupDateStartDisplay] =
    useState<Date | null>(null);
  const [deliveryDateStartDisplay, setDeliveryDateStartDisplay] =
    useState<Date | null>(null);
  const [pickupDateEndDisplay, setPickupDateEndDisplay] = useState<Date | null>(
    null,
  );
  const [deliveryDateEndDisplay, setDeliveryDateEndDisplay] =
    useState<Date | null>(null);

  const { data } = useGetLoadsQuery({
    id: "DEFAULT",
    status:
      statusFilters.length > 0
        ? statusFilters.map((sf) => sf.value)
        : undefined,
    customerName:
      customerFilters.length > 0
        ? customerFilters.map((cf) => cf.value)
        : undefined,
    originDate:
      pickupDateFilter.start && pickupDateFilter.end
        ? { start: pickupDateFilter.start, end: pickupDateFilter.end }
        : undefined,
    destinationDate:
      deliveryDateFilter.start && deliveryDateFilter.end
        ? { start: deliveryDateFilter.start, end: deliveryDateFilter.end }
        : undefined,
    sortOption: sortBy,
    sortDirection: sortBy ? getSortDirectionForSortOption(sortBy) : undefined,
    searchTerm: searchTerm ? debouncedSearchTerm : undefined,
  });

  const renderStopCell = (stop: ILoadAddress): JSX.Element => {
    const state = getStateAbbrFromStateCountryPair(stop.state, stop.country);
    let time = stop.appointmentTime;
    if (stop.appointmentType === AppointmentType.FCFS) {
      time = stop.appointmentType;
    }
    return (
      <VStack alignItems="start" fontSize="13px">
        <Text color="grey2" fontSize="15px">
          {stop.city}, {state}
        </Text>
        <Text color="gray60">{stop.date}</Text>
        <Text color="gray60">{time}</Text>
        <Text color="gray50">{stop.loadType}</Text>
      </VStack>
    );
  };

  const table = useDataTable<ILoad>({
    data: data?.loads ?? [],
    // TODO: Add carrier column
    // TODO: Add assigned Reps
    columns: [
      {
        header: "",
        accessorKey: "mvmntLoadId",
        accessorFn: (load) => String(load.mvmntLoadId),
        filterFn: "arrIncludesSome",
        cell: (cellInfo) => {
          const { id, highPriority, atRisk } = cellInfo.row.original;
          return (
            <LoadBoardIcons
              id={id}
              highPriority={highPriority}
              atRisk={atRisk}
            />
          );
        },
      },
      {
        header: "LOAD ID",
        accessorKey: "id",
        accessorFn: (load) => String(load.id),
        filterFn: "arrIncludesSome",
        cell: (cellInfo) => {
          return <LoadBoardDetailsDrawer load={cellInfo.row.original} />;
        },
      },
      {
        header: "STATUS",
        accessorKey: "status",
        accessorFn: (load) => String(load.status),
        filterFn: "arrIncludesSome",
        cell: (cellInfo) => {
          const status = cellInfo.getValue();
          return <LoadBoardStatusBadge status={status} />;
        },
      },
      {
        header: "CUSTOMER",
        accessorKey: "customerName",
        filterFn: "arrIncludesSome",
        cell: (cellInfo) => {
          const customerName = cellInfo.getValue();
          return (
            <Text color="grey2" fontSize="15px">
              {customerName}
            </Text>
          );
        },
      },
      {
        header: "ORIGIN",
        accessorKey: "origin",
        filterFn: "arrIncludesSome",
        cell: (cellInfo) => {
          const origin = cellInfo.getValue();
          return renderStopCell(origin);
        },
      },
      {
        header: "DESTINATION",
        accessorKey: "destination",
        cell: (cellInfo) => {
          const destination = cellInfo.getValue();
          return renderStopCell(destination);
        },
      },
    ],
  });

  const renderLoadFilters = (): JSX.Element => {
    return (
      <HStack m="10px 0px" w="66vmax" minW="660px" alignItems="flex-start">
        <MultiSelect
          value={statusFilters}
          labelledBy="STATUS"
          onChange={(options: Option[]) => {
            setStatusFilters(options);
          }}
          options={statusOptions}
          overrideStrings={{ selectSomeItems: "Status" }}
        />
        <MultiSelect
          value={customerFilters}
          labelledBy="Customer Name"
          onChange={(options: Option[]) => {
            setCustomerFilters(options);
          }}
          options={customerNameOptions}
          overrideStrings={{ selectSomeItems: "Customer" }}
        />
        <DatePicker
          placeholderText="Pickup Date"
          selectsRange={true}
          startDate={pickupDateStartDisplay}
          endDate={pickupDateEndDisplay}
          onChange={(update) => {
            // we format the dates the same way the dafault chakra input formats them
            // That is how shipment and load stops save them in the db so we match it here
            setPickupDateStartDisplay(update[0]);
            setPickupDateEndDisplay(update[1]);
            setPickupDateFilter({
              start: update[0]?.toISOString().split("T")[0] ?? undefined,
              end: update[1]?.toISOString().split("T")[0] ?? undefined,
            });
          }}
          isClearable={true}
        />
        <DatePicker
          placeholderText="Delivery Date"
          selectsRange={true}
          startDate={deliveryDateStartDisplay}
          endDate={deliveryDateEndDisplay}
          onChange={(update) => {
            // we format the dates the same way the dafault chakra input formats them
            // That is how shipment and load stops save them in the db so we match it here
            setDeliveryDateStartDisplay(update[0]);
            setDeliveryDateEndDisplay(update[1]);
            setDeliveryDateFilter({
              start: update[0]?.toISOString().split("T")[0] ?? undefined,
              end: update[1]?.toISOString().split("T")[0] ?? undefined,
            });
          }}
          isClearable={true}
        />
      </HStack>
    );
  };

  return (
    <>
      <LoadBoardSubhead
        sortBy={sortBy}
        setSortBy={setSortBy}
        totalDisplayedLoads={data?.loads?.length}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      {renderLoadFilters()}
      {data && data.loads?.length > 0 ? (
        <DataTable table={table} />
      ) : (
        <NoDataDisplayed searching={searchTerm.length > 0} />
      )}
    </>
  );
};

export default LoadBoardTable;
