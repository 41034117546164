import { ILaneBid } from "../types/Rfp";

export interface MinimunBidInfo {
  ratePerMile: number;
  minimumBid: number;
  volumeCommitment: number;
}

export const getLaneMinimunBidValues = (
  laneBids: ILaneBid[] | undefined,
): MinimunBidInfo | undefined => {
  const lowestBid: ILaneBid | undefined = laneBids?.sort(
    (bidA: ILaneBid, bidB: ILaneBid) =>
      bidA.ratePerMile < bidB.ratePerMile ? 1 : -1,
  )[0];

  if (lowestBid) {
    const { ratePerMile, minimumBid, volumeCommitment } = lowestBid;
    const minimunBidInfo: MinimunBidInfo = {
      ratePerMile,
      minimumBid,
      volumeCommitment,
    };

    return minimunBidInfo;
  }
};
