import { VStack } from "@chakra-ui/layout";
import { sortShipmentBidsByPrice } from "../../../reuse/Shipment";
import { Bid } from "../../../types/Bid";
import BidCard from "../BidCard";

interface GroupProps {
  bids: Bid[];
}

const BidsGroup = ({ bids }: GroupProps): JSX.Element => {
  const parseBids = (bids: Bid[]): JSX.Element[] =>
    sortShipmentBidsByPrice([...bids]).map((bid: Bid) => (
      <BidCard key={bid.id} bid={bid} />
    ));

  return (
    <VStack alignItems="center" w="100%" mb={4} ml={8}>
      {parseBids(bids)}
    </VStack>
  );
};

export default BidsGroup;
