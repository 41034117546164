/* eslint react/prop-types: 0 */
import {
  Table,
  Tbody,
  Tr,
  Td,
  HStack,
  Button,
  useToast,
  Flex,
  Spinner,
  Skeleton,
  VStack,
} from "@chakra-ui/react";
import { useForm, useFieldArray } from "react-hook-form";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RfpButtonsPosition, RowLaneProps as RLProps } from "../../types/Rfp";
import {
  useBidsUploadMutation,
  useSaveLanesBidMutation,
  useSubmitLanesBidMutation,
  useValidateBidsUploadMutation,
} from "../../api/rfp";
import AbsoluteRightButtons from "../AbsoluteRightButtons";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import AppRoutes from "../../routes";
import EmptySection from "../EmptySection";
import { UserType } from "../../types/User";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { activeRfpCarrierHeaders } from "./constants";
import { HeadersLanesTable } from "./HeadersLanesTable";
import { CellEdit } from "./CellEdit";
import { mapCarrierLanesToRow, createPostData } from "./utils";
import { TableData } from "./LanesProposalDetail";
import BulkUploadModal from "../BulkUploadModal";
import {
  createCsvFromObjectsArray,
  createDataToExport,
  exportFileResults,
  OneMegaByte,
} from "../../reuse/Files";
import BulkUploadFile from "../BulkUploadFile";
import useToastHook from "../useToastHook";
import { useActiveRfpContext } from "../../pages/ActiveRfp/ActiveRfpContext";

export interface FormValues {
  fieldArray: RLProps[];
}

export const CarrierLanesTable = (): JSX.Element => {
  const rfpContext = useActiveRfpContext();
  const { largerThan1620, selectedRfp } = rfpContext!;

  const toast = useToast();
  const toastHook = useToastHook();
  const location = useLocation();
  const { type } = useAppSelector(selectUserData);
  const [fileName, setFileName] = useState("");
  const [bulkUploadModal, toggleBulkUploadModal] = useState<boolean>(false);
  const [bulkUploadModalFile, toggleBulkUploadModalFile] =
    useState<boolean>(false);
  const [saveLanesBid, { isLoading: isSaving }] = useSaveLanesBidMutation();
  const [submitLanesBid, { isLoading: isSubmiting }] =
    useSubmitLanesBidMutation();

  const [
    validateBidsUploads,
    { data: validationResult, isLoading: isValidatingBids },
  ] = useValidateBidsUploadMutation();
  const [uploadBids, { isLoading: isUploadingBids }] = useBidsUploadMutation();

  const { register, control, watch } = useForm<FormValues>();
  const { fields, replace, prepend } = useFieldArray({
    control,
    name: "fieldArray",
  });
  const watchFieldArray = watch("fieldArray");
  const laneBidsData = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const uploadFileOpen = (): void => {
    toggleBulkUploadModal(false);
    toggleBulkUploadModalFile(true);
  };

  const downloadLaneFile = (): void => {
    toggleBulkUploadModal(false);
    const mappedExportData = createDataToExport(laneBidsData);
    const csvFileUrl = createCsvFromObjectsArray(mappedExportData);
    exportFileResults(csvFileUrl, "rfp_carrier_lanes.csv");
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files![0];
    if (file.size >= 100 * OneMegaByte) {
      alert("File size is too large.");
      return;
    }
    setFileName(file.name);
    const response = await validateBidsUploads({ id: selectedRfp!.id!, file });
    if ("data" in response) {
      toastHook.success({ description: "Upload Bids successful." });
    } else {
      toastHook.error({ description: parseErrorResponse(response.error) });
    }
  };

  const onClickUpload = async (): Promise<void> => {
    if (!validationResult) return;
    const response = await uploadBids({
      id: selectedRfp!.id!,
      bidRowToAdd: validationResult.readyToAdd,
    });

    if ("data" in response) {
      toast({
        status: "success",
        description: "Bids added successfully.",
        duration: 3000,
      });
      toggleBulkUploadModalFile(false);
    } else {
      toast({
        status: "error",
        description: parseErrorResponse(response.error),
        duration: 3000,
      });
    }
  };

  const applyFormTable = async (param: string): Promise<void> => {
    let response;
    const postData = createPostData(laneBidsData, selectedRfp!, param);
    if (param === "SAVE") {
      response = await saveLanesBid(postData);
    } else {
      response = await submitLanesBid(postData);
    }
    if ("data" in response) {
      toast({
        status: "success",
        description: "Bids added successfully.",
        duration: 3000,
      });
    } else {
      toast({
        status: "error",
        description: parseErrorResponse(response.error),
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    replace([]);
    if (selectedRfp!.lanes!.length > 0) {
      const laneList = mapCarrierLanesToRow(selectedRfp!.lanes!);
      prepend(laneList);
    }
  }, [selectedRfp]);

  return (
    <VStack h="100%" w="100%">
      <Skeleton isLoaded={!isSaving && !isSubmiting}>
        {laneBidsData.length !== 0 ? (
          <form>
            <Table variant="unstyled">
              <HeadersLanesTable data={activeRfpCarrierHeaders} />
              <Tbody>
                {laneBidsData.map((field, i) => (
                  <Tr key={i}>
                    {TableData(field)}
                    {type === UserType.CARRIER &&
                    location.pathname === AppRoutes.ACTIVE_RFP ? (
                      <Fragment>
                        <CellEdit register={register} idx={i} field="rpm" />
                        <CellEdit register={register} idx={i} field="minimum" />
                        <CellEdit
                          register={register}
                          idx={i}
                          field="commitment"
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Td textAlign="center">{field.rpm ?? "---"}</Td>
                        <Td textAlign="center">{field.minimum ?? "---"}</Td>
                        <Td textAlign="center">{field.commitment ?? "---"}</Td>
                      </Fragment>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </form>
        ) : (
          <EmptySection title="No lanes available." />
        )}
      </Skeleton>
      <AbsoluteRightButtons
        top={
          largerThan1620
            ? RfpButtonsPosition.Primary
            : RfpButtonsPosition.Secondary
        }
        left={
          largerThan1620
            ? RfpButtonsPosition.Infimum
            : RfpButtonsPosition.Primary
        }
        right={
          largerThan1620
            ? RfpButtonsPosition.Primary
            : RfpButtonsPosition.Infimum
        }
      >
        <Button
          layerStyle="red"
          color="white"
          onClick={() => toggleBulkUploadModal((x) => !x)}
          hidden={laneBidsData.length === 0}
        >
          + UPLOAD BIDS
        </Button>
      </AbsoluteRightButtons>
      {type === UserType.CARRIER &&
        location.pathname === AppRoutes.ACTIVE_RFP &&
        selectedRfp &&
        selectedRfp.lanes!.length > 0 && (
          <AbsoluteRightButtons
            right={RfpButtonsPosition.Primary}
            bottom={RfpButtonsPosition.Primary}
          >
            <HStack>
              {isSaving || isSubmiting ? (
                <Flex w="20vw" alignItems="center" justifyContent="center">
                  <Spinner color="mvmntRed" size="lg" />
                </Flex>
              ) : (
                <HStack w="100%" justifyContent="flex-end">
                  <Button
                    layerStyle="black"
                    color="white"
                    onClick={async () => await applyFormTable("SAVE")}
                  >
                    SAVE WITHOUT BIDDING
                  </Button>
                  <Button
                    layerStyle="black"
                    color="white"
                    onClick={async () => await applyFormTable("SUBMIT")}
                  >
                    SUBMIT BIDS
                  </Button>
                </HStack>
              )}
            </HStack>
          </AbsoluteRightButtons>
        )}
      <BulkUploadModal
        isOpen={bulkUploadModal}
        onClose={() => toggleBulkUploadModal(false)}
        title="BULK UPLOAD BIDS"
        downloadButtonText="DOWNLOAD LANE FILE"
        uploadButtonText="UPLOAD FILE"
        footer=""
        downloadLaneFile={downloadLaneFile}
        uploadFileOpen={uploadFileOpen}
      />
      <BulkUploadFile
        isOpen={bulkUploadModalFile}
        onClose={() => toggleBulkUploadModalFile(false)}
        title="BULK UPLOAD BIDS"
        extensionFile={".csv"}
        confirmBtnText="ADD BIDS"
        fileName={fileName}
        handleFileInput={handleFileInput}
        onClickUpload={onClickUpload}
        isAdding={isUploadingBids}
        isValidating={isValidatingBids}
        validationResultBid={validationResult}
      />
    </VStack>
  );
};

export default CarrierLanesTable;
