import { TabList, Tab, HStack } from "@chakra-ui/react";
import { TabElementProps } from "../../types/Rfp";

interface TabListElementsProps {
  tabListByUserType: TabElementProps[];
}

export const TabListElements = ({
  tabListByUserType,
}: TabListElementsProps): JSX.Element => {
  const TabElement = ({ name }: { name: string }): JSX.Element => {
    return (
      <Tab
        _selected={{ fontWeight: "bold" }}
        fontSize="20px"
        whiteSpace="nowrap"
      >
        {name}
      </Tab>
    );
  };

  return (
    <TabList h="40px" w="50%">
      <HStack alignItems="center" justifyContent="flex-start" pl="1.5rem">
        {tabListByUserType.map((element) => (
          <TabElement key={element.id} name={element.name} />
        ))}
      </HStack>
    </TabList>
  );
};

export default TabListElements;
