import {
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  Image,
} from "@chakra-ui/react";
import { Fragment } from "react";
import Logo from "../../resources/svg/mvmnt_logo.svg";
import { DataDocProps } from "../../types/Files";

interface IframeProps {
  isOpen: boolean;
  close: () => void;
  contentDoc: DataDocProps;
}

export const IframeModalDocument = ({
  isOpen,
  contentDoc,
  close,
}: IframeProps): JSX.Element => {
  const availableExtensions: string[] = ["txt", "pdf", "jpg", "png"];
  const readables: string[] = ["txt", "pdf", "doc", "csv"];
  const images: string[] = ["jpg", "png"];

  const availableContent = availableExtensions.includes(contentDoc?.extension);
  const readDownload = readables.includes(contentDoc?.extension);
  const imageView = images.includes(contentDoc?.extension);

  return (
    <Fragment>
      {contentDoc && (
        <Modal isOpen={isOpen} onClose={close} size={"6xl"} isCentered>
          <ModalOverlay />
          <ModalContent h={readDownload ? "90vh" : ""} position="relative">
            {imageView && (
              <Image src={contentDoc.url!} alt={contentDoc.name!} />
            )}
            {readDownload && (
              <iframe width="100%" height="100%" src={contentDoc.url!}></iframe>
            )}

            {!availableContent && (
              <VStack
                position="absolute"
                top="0"
                left="0"
                height="100%"
                width="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Image w="600px" src={Logo} />
                <Text fontSize="2rem">
                  {`File: ${contentDoc.name} downloaded.`}
                </Text>
              </VStack>
            )}
          </ModalContent>
        </Modal>
      )}
    </Fragment>
  );
};
