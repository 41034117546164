import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  Table,
  Tbody,
  Tr,
  HStack,
  Button,
  Td,
  useToast,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { useForm, useFieldArray } from "react-hook-form";
import { useEffect, Fragment } from "react";
import { headers } from "../../reuse/Rfp";
import { RowShipperLaneProps, IRowBidProp } from "../../types/Rfp";
import { HeadersLanesTable } from "../LanesProposalDetail/HeadersLanesTable";
import { CellEdit } from "../LanesProposalDetail/CellEdit";
import { useAwardCarriersMutation } from "../../api/rfp";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { mapCurrentBidsToAwardProps } from "../LanesProposalDetail/utils";
import { parseValueToMoney } from "../../reuse/Money";

interface AwardCarriersProps {
  isOpen: boolean;
  isFetching: boolean;
  selectedLane?: RowShipperLaneProps | null;
  closeForm: () => void;
}

export interface FormValues {
  fieldArray: IRowBidProp[];
}

export const AwardCarriers = ({
  isOpen,
  closeForm,
  selectedLane,
}: AwardCarriersProps): JSX.Element => {
  const toast = useToast();
  const [awardCarriers, { isLoading }] = useAwardCarriersMutation();
  const { register, control, watch } = useForm<FormValues>();
  const { fields, replace, prepend } = useFieldArray({
    control,
    name: "fieldArray",
  });

  const watchFieldArray = watch("fieldArray");

  const awardCarrierBidsData = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const onAwardSubmit = async (): Promise<void> => {
    const postData = awardCarrierBidsData.map((element) => {
      return {
        laneBidId: element.id,
        awardedVolume: element.awardedVolume!,
      };
    });

    const response = await awardCarriers(postData);
    if ("data" in response) {
      toast({
        status: "success",
        description: "Awarded Success !!!",
        duration: 3000,
      });
    } else {
      toast({
        status: "error",
        description: parseErrorResponse(response.error),
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    replace([]);
    if (selectedLane && selectedLane.laneCurrentBids?.length !== 0) {
      prepend(mapCurrentBidsToAwardProps(selectedLane.laneCurrentBids!));
    }
  }, [selectedLane]);

  watch();

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeForm}
      size={"6xl"}
      isCentered
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent w="100%" p="1.5rem 0 0 0">
        <ModalBody>
          <form>
            <Table variant="unstyled">
              <HeadersLanesTable backgroundColor={"#f6d306"} data={headers} />
              <Tbody>
                {awardCarrierBidsData?.map((element, i) => {
                  return (
                    <Tr key={element.id}>
                      <Td textAlign="center">{element.carrierName ?? "---"}</Td>
                      <Td textAlign="center">
                        {element.carrierDOTNumber ?? "---"}
                      </Td>
                      <Td textAlign="center">
                        {element.carrierMCNumber ?? "---"}
                      </Td>
                      <Td textAlign="center">
                        {element.carrierEmailAddress ?? "---"}
                      </Td>
                      <Td textAlign="center">
                        {parseValueToMoney(Number(element.minimumBid))}
                      </Td>
                      <Td textAlign="center">
                        {parseValueToMoney(Number(element.effectiveBid))}
                      </Td>
                      <Td textAlign="center">
                        {parseValueToMoney(Number(element.actualBid))}
                      </Td>
                      <CellEdit
                        width="5rem"
                        register={register}
                        idx={i}
                        field="awardedVolume"
                      />
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <HStack w="100%" py="1rem" justifyContent="flex-start">
              {isLoading ? (
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Spinner color="mvmntRed" size="lg" />
                </Flex>
              ) : (
                <Fragment>
                  <Button
                    layerStyle="red"
                    px="2rem"
                    mr="1rem"
                    onClick={onAwardSubmit}
                  >
                    AWARD SELECTED CARRIERS
                  </Button>
                  <Button
                    size="sm"
                    color="mvmntRed"
                    variant="link"
                    onClick={closeForm}
                  >
                    CANCEL
                  </Button>
                </Fragment>
              )}
            </HStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AwardCarriers;
