import { Select, SelectProps } from "@chakra-ui/react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { Countries, states } from "../../resources/regions";

interface FormStateProps extends SelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formHook: UseFormReturn<any>;
  property?: string;
  required?: boolean;
}

export const FormState = (props: FormStateProps): JSX.Element => {
  const { formHook, required, property, ...selectProps } = props;
  const stateField = property ? `${property}.state` : "state";
  const countryField = property ? `${property}.country` : "country";

  const { watch } = formHook;
  watch(stateField);

  const country: Countries = formHook.getValues(countryField);
  const state = formHook.getValues(stateField);
  const stateOptions = states[country] ?? [];

  useWatch({ control: formHook.control, name: countryField });
  return (
    <Select
      {...selectProps}
      {...formHook.register(stateField, {
        required: required
          ? { value: true, message: "Please enter the state" }
          : false,
      })}
      value={state ?? ""}
    >
      <option value="">Select State</option>
      {stateOptions.map((state) => (
        <option key={state.name} value={state.name}>
          {state.name}
        </option>
      ))}
    </Select>
  );
};

export default FormState;
