export enum OperationClass {
  Authorized = "A",
  Exempt = "B",
  PrivateProperty = "C",
  PrivateBusiness = "D",
  PrivateNonBusiness = "E",
  Migrant = "F",
  USMail = "G",
  FederalGovt = "H",
  StateGovt = "I",
  LocalGovt = "J",
  IndianTribe = "K",
  Other = "L",
}
