import { GooglePlacesAddress } from "../types/Address";
import { Shipment } from "../types/Shipment";
import { MapLocations } from "../pages/SourcingSearch/SourcingSearchContext";
import { MarkerInfo } from "../components/SharedMap/SharedMap";

export const getLatLngFromAddress = async (
  address: string,
): Promise<google.maps.LatLngLiteral | undefined> => {
  return await new Promise((resolve) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        resolve(results[0].geometry.location.toJSON());
      } else {
        resolve(undefined);
      }
    });
  });
};

export const getDirections = async (
  origin: google.maps.LatLngLiteral,
  destination: google.maps.LatLngLiteral,
): Promise<google.maps.DirectionsResult | undefined> => {
  return await new Promise((resolve) => {
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin,
        destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          resolve(result);
        } else {
          resolve(undefined);
        }
      },
    );
  });
};

export const getAddressFromLatLng = async (
  latLng: google.maps.LatLng,
): Promise<google.maps.GeocoderAddressComponent[] | undefined> => {
  return await new Promise((resolve) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        location: latLng,
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve(results[0].address_components);
        } else {
          resolve(undefined);
        }
      },
    );
  });
};

export const getValuesFromAddressComponents = (
  address: google.maps.GeocoderAddressComponent[],
): GooglePlacesAddress => {
  const values = {
    streetNumber: "",
    route: "",
    postalCode: "",
    city: "",
    state: "",
    country: "",
  };
  address.forEach((component) => {
    const { types, long_name: name } = component;
    if (types.includes("street_number")) {
      values.streetNumber = name;
    }
    if (types.includes("route")) {
      values.route = name;
    }
    if (
      types.includes("locality") ||
      (!values.city && types.includes("administrative_area_level_2"))
    ) {
      values.city = name;
    }
    if (types.includes("administrative_area_level_1")) {
      values.state = name;
    }
    if (types.includes("country")) {
      values.country = name;
    }
    if (types.includes("postal_code")) {
      values.postalCode = name;
    }
  });
  return values;
};

export const getPlaceLatLng = async (
  placesService: google.maps.places.PlacesService,
  placeId: string,
): Promise<google.maps.LatLng | undefined> => {
  return await new Promise((resolve) => {
    placesService.getDetails(
      {
        placeId,
      },
      (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(result.geometry?.location);
        } else {
          resolve(undefined);
        }
      },
    );
  });
};

export const setLocationsFromTracking = async (
  currentTrackingShipment: Shipment,
  setLocations: (p: MapLocations | undefined) => void,
): Promise<void> => {
  if (!currentTrackingShipment) return;

  const { shipmentStops } = currentTrackingShipment!;
  if (!shipmentStops) return;

  const lastIdx = shipmentStops.length - 1;
  const concatedPickupAddress =
    shipmentStops[0].address.address1 +
    " " +
    shipmentStops[0].address.city +
    " " +
    shipmentStops[0].address.state;

  const concatedDestinationAddress =
    shipmentStops[lastIdx].address.address1 +
    " " +
    shipmentStops[lastIdx].address.city +
    " " +
    shipmentStops[lastIdx].address.state;

  const pickup = await getLatLngFromAddress(concatedPickupAddress);
  const destination = await getLatLngFromAddress(concatedDestinationAddress);

  if (pickup && destination) {
    setLocations({
      pickup,
      destination,
    });
  }
};

export const setMarkerFromTracking = async (
  currentTrackingShipment: Shipment,
  setMarkerInfo: (markerInfo: MarkerInfo | undefined) => void,
): Promise<void> => {
  if (!currentTrackingShipment) return;
  const { assignedDriver, shipmentLocations } = currentTrackingShipment!;
  if (!assignedDriver || !shipmentLocations) return;

  if (!assignedDriver || !shipmentLocations) return;
  if (!shipmentLocations[0]?.state) return;

  const markerInfo = await getLatLngFromAddress(
    `${shipmentLocations[0].city} ${shipmentLocations[0].state} ${shipmentLocations[0].postalCode}`,
  );
  const { lat, lng } = markerInfo!;

  setMarkerInfo({
    id: shipmentLocations[0].id!,
    latitude: lat!,
    longitude: lng!,
  });
};
