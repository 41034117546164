import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  Button,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { ILoad } from "../../../types/Load";
import { unionIcon } from "../../../resources/svg/CustomIcons";
import LoadBoardStatusBadge from "../LoadBoardStatusBadge";
import { paddingZerosLeft, toTitleCase } from "../../../reuse/Strings";
import TabsContainer from "../../../components/TabsContainer";
import LoadDetailsOverview from "./LoadDetailsOverview";
import LoadDetailsStopTab from "./LoadDetailsStopTab";
import { getStateAbbrFromStateCountryPair } from "../../../reuse/RegionKeys";
import { useEffect, useState } from "react";
import { LoadDetailsNotes } from "./LoadNotes/LoadDetailsNotes";
import LoadDetailsHistoryTab from "./LoadHistory/LoadDetailsHistoryTab";
import LoadChangelogTab from "./Changelog/LoadDetailsChangelogTab";

interface LoadBoardDetailsDrawerProps {
  load: ILoad;
}

export const LoadBoardDetailsDrawer = ({
  load,
}: LoadBoardDetailsDrawerProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const UnionIcon = unionIcon("gray.400");
  const [tabs, setTabs] = useState<JSX.Element[]>([]);
  const [tabList, setTabList] = useState<string[]>([]);

  const originState = getStateAbbrFromStateCountryPair(
    load.origin?.state ?? "",
    load.origin?.country ?? "",
  );

  const destinationState = getStateAbbrFromStateCountryPair(
    load.destination?.state ?? "",
    load.destination?.country ?? "",
  );

  useEffect(() => {
    const tabTitles = ["Overview"];
    const tabPanels = [
      <LoadDetailsOverview
        key={`load board details panel:${load.id}`}
        load={load}
      />,
    ];

    if (load.origin) {
      tabTitles.push("Origin");
      tabPanels.push(
        <LoadDetailsStopTab
          key={`load board origin panel:${load.id}`}
          stop={load.origin}
        />,
      );
    }
    if (load.destination) {
      tabTitles.push("Destination");
      tabPanels.push(
        <LoadDetailsStopTab
          key={`load board destination panel:${load.id}`}
          stop={load.destination}
        />,
      );
    }

    tabTitles.push("Notes");
    tabPanels.push(
      <LoadDetailsNotes
        key={`load board notes panel:${load.id}`}
        loadId={load.id}
      />,
    );
    tabTitles.push("History");
    tabPanels.push(
      <LoadDetailsHistoryTab
        key={`load board history panel:${load.id}`}
        loadId={load.id}
      />,
    );
    tabTitles.push("Log");
    tabPanels.push(
      <LoadChangelogTab
        key={`load board changelog panel:${load.id}`}
        loadId={load.id}
      />,
    );

    setTabList(tabTitles);
    setTabs(tabPanels);
  }, [load]);

  return (
    <>
      <Button variant="link" color="mvmntBlue" onClick={onOpen}>
        #{paddingZerosLeft(load.mvmntLoadId, 3)}
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader w="100%" p="1rem 0" bgColor="grey6">
            <DrawerCloseButton />
            <HStack
              h="fit-content"
              w="100%"
              p="0 20px"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Text variant="Title" lineHeight="24px">
                Load #{paddingZerosLeft(load.mvmntLoadId, 3)}
              </Text>
              <LoadBoardStatusBadge status={load.status} />
            </HStack>
            <HStack padding="0 20px" marginTop="10px">
              <Text fontSize="13px" color="gray70">
                {toTitleCase(load.origin?.city ?? "")}, {originState}
              </Text>
              <UnionIcon
                w="14px"
                h="12px"
                display="block"
                viewBox="0 0 23 21"
              />
              <Text fontSize="13px" color="gray70">
                {toTitleCase(load.destination?.city ?? "")}, {destinationState}
              </Text>
            </HStack>
          </DrawerHeader>
          <TabsContainer
            p={0}
            bg="realWhite"
            tabListProps={{ bg: "grey6", padding: "0px 0px" }}
            tabList={tabList}
            tabProps={{
              paddingLeft: "0px",
              paddingRight: "0px",
              marginLeft: "20px",
              _selected: {
                color: "gray90",
                fontWeight: "bold",
                borderBottom: "2px solid var(--chakra-colors-mvmntBlue)",
              },
            }}
            tabPanels={tabs}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default LoadBoardDetailsDrawer;
