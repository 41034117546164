import { VStack, Text } from "@chakra-ui/react";
import { ValidationResults } from "../../types/BulkUpload";
import { LaneRowToAdd } from "../../types/LaneUpload";
import ReusableUploadSumary from "../ReusableUploadSumary";
import { SummaryCmp } from "../ReusableUploadSumary/ReusableUploadSumary";

interface UploadLanesSummaryProps {
  validationResult: ValidationResults<LaneRowToAdd>;
}

const AlreadyAddedSummary = (
  validationResult: ValidationResults<LaneRowToAdd>,
): JSX.Element => (
  <>
    {validationResult.alreadyAdded?.map((lane) => (
      <VStack key={lane.laneReferenceNumber} alignItems="flex-start">
        <Text>
          Origin: {lane.laneStops[0].stopCity} - {lane.laneStops[0].stopState} -{" "}
          {lane.laneStops[0].stopPostalCode}
        </Text>
        <Text>
          Destination: {lane.laneStops[lane.laneStops.length - 1].stopCity} -{" "}
          {lane.laneStops[lane.laneStops.length - 1].stopState} -{" "}
          {lane.laneStops[lane.laneStops.length - 1].stopPostalCode}
        </Text>
        <Text>Volume: {lane.volume}</Text>
      </VStack>
    ))}
  </>
);

const ReadyToAddSummary = (
  validationResult: ValidationResults<LaneRowToAdd>,
): JSX.Element => (
  <>
    {validationResult.readyToAdd?.map((lane) => (
      <VStack key={lane.laneReferenceNumber} alignItems="flex-start">
        <Text>
          Origin: {lane.laneStops[0].stopCity} - {lane.laneStops[0].stopState} -{" "}
          {lane.laneStops[0].stopPostalCode}
        </Text>
        <Text>
          Destination: {lane.laneStops[1].stopCity} -{" "}
          {lane.laneStops[1].stopState} - {lane.laneStops[1].stopPostalCode}{" "}
        </Text>
        <Text>Volume: {lane.volume}</Text>
      </VStack>
    ))}
  </>
);

export const UploadLanesSummary = ({
  validationResult,
}: UploadLanesSummaryProps): JSX.Element => {
  const { misFormattedRows, duplicated, alreadyAdded, readyToAdd } =
    validationResult;

  return (
    <ReusableUploadSumary
      MisFormattedRowsCmp={() =>
        misFormattedRows!.length !== 0 ? (
          <SummaryCmp
            text="Please review the template. The following row numbers are not formatted correctly:"
            array={misFormattedRows}
          />
        ) : (
          <></>
        )
      }
      DuplicatedCmp={() =>
        duplicated!.length > 0 ? (
          <SummaryCmp
            text="Please upload only one row per lane. The following records will not be uploaded:"
            array={duplicated}
          />
        ) : (
          <></>
        )
      }
      AlreadyAddedCmp={() =>
        alreadyAdded!.length > 0 ? (
          <SummaryCmp text="The following are existing lanes. Overwrite existing data?">
            {() => AlreadyAddedSummary(validationResult)}
          </SummaryCmp>
        ) : (
          <></>
        )
      }
      ReadyToAddCmp={() =>
        readyToAdd!.length > 0 ? (
          <SummaryCmp text="The following lanes will be added:">
            {() => ReadyToAddSummary(validationResult)}
          </SummaryCmp>
        ) : (
          <></>
        )
      }
    />
  );
};

export default UploadLanesSummary;
