import { Text, HStack, VStack } from "@chakra-ui/react";
import { Countries } from "../../../../resources/regions";
import { getStateAbbrFromStateCountryPair } from "../../../../reuse/RegionKeys";
import { ILoadLocation } from "../../../../types/LoadLocation";
import "../LoadBoardDetails.scss";

interface LoadHistoryListProps {
  loadLocations: ILoadLocation[];
}
export const LoadHistoryList = ({
  loadLocations,
}: LoadHistoryListProps): JSX.Element => {
  const renderLoadHistoryItem = (location: ILoadLocation): JSX.Element => {
    const { city, state, timeStampTimeZone, createDate } = location;
    const time = `${new Date(timeStampTimeZone).toLocaleTimeString(undefined, {
      hour12: false,
      timeStyle: "short",
    })}`;
    const stateAbbreviation = getStateAbbrFromStateCountryPair(
      state,
      Countries.US,
    );

    const createDateDisplay = `${new Date(createDate).toLocaleString(
      undefined,
      {
        hour12: false,
        dateStyle: "short",
        timeStyle: "short",
      },
    )}`;
    return (
      <HStack key={`location history item:${location.id}`} w="100%">
        <VStack
          w="50%"
          color="gray50"
          fontSize="11px"
          alignItems="start"
          spacing={0}
        >
          <Text fontWeight={600}>Driver Check-in</Text>
          <Text>Added {createDateDisplay}</Text>
        </VStack>
        <Text w="50%" color="gray70" fontSize="15px">
          {time} | {city}, {stateAbbreviation}
        </Text>
      </HStack>
    );
  };

  return (
    <VStack>
      {loadLocations.map((location) => renderLoadHistoryItem(location))}
    </VStack>
  );
};

export default LoadHistoryList;
