import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  VStack,
  Button,
  Text,
  Flex,
  Spinner,
  Select,
} from "@chakra-ui/react";
import { useEffect, useRef, useState, Fragment } from "react";
import {
  useGetUserDocumentsQuery,
  useLazyGetDocumentUrlQuery,
  useUploadRfpDocumentMutation,
  useUploadS3RfpFileMutation,
} from "../../api/documents";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { OneMegaByte } from "../../reuse/Files";
import { MvmntDocument } from "../../types/Files";
import useToastHook from "../useToastHook";

interface RfpDocumentModalProps {
  rfpId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const RfpDocumentModal = ({
  rfpId,
  isOpen,
  onClose,
}: RfpDocumentModalProps): JSX.Element => {
  const { data, isLoading } = useGetUserDocumentsQuery();
  const [getUrlDocumentById, { isFetching }] = useLazyGetDocumentUrlQuery();
  const [uploadDocument, { isLoading: isUploading }] =
    useUploadRfpDocumentMutation();
  const [uploadS3Document, { isLoading: isUploadingS3 }] =
    useUploadS3RfpFileMutation();

  const toast = useToastHook();
  const [step, setStep] = useState<number>(0);
  const [accountUserDocs, setAccountUserDocs] = useState<MvmntDocument[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | undefined>();
  const [type, setType] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const forward = (): void => setStep(1);
  const goBack = (): void => {
    setFile(undefined);
    setFileName("");
    setType("");
    setStep(0);
  };

  const analyzeFile = (file: File): void => {
    const { name, type, size } = file;
    if (size >= 100 * OneMegaByte) {
      alert("File size is too large.");
      return;
    }

    setFileName(name);
    setFile(file);
    setType(type);
  };

  const handleFileInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const file = event?.target?.files![0];
    analyzeFile(file);
  };

  const findUserAccountFile = (value: string): MvmntDocument | undefined =>
    accountUserDocs.find(({ documentName }) => documentName === value);

  const onChangeAccountDocument = async ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const { value } = target;
    const fileAccount = findUserAccountFile(value);
    if (!fileAccount) return;
    const response = await getUrlDocumentById(fileAccount!.id!);
    if ("data" in response) {
      const file: File = new File([response.data!], fileAccount!.documentName!);
      analyzeFile(file);
    }
  };

  const upload = async (): Promise<void> => {
    const documentUpload = await uploadDocument({
      rfpId,
      fileName,
      contentType: type,
    });

    if ("data" in documentUpload) {
      await uploadS3Document({
        file: file!,
        url: documentUpload.data,
      });
      toast.success({
        description: "Document uploaded successfully !",
      });
    } else {
      toast.error({
        description: parseErrorResponse(documentUpload.error),
      });
    }
  };

  const footerDisposition: string = step === 0 ? "space-between" : "flex-start";
  const loadingProcess: boolean =
    isLoading || isFetching || isUploading || isUploadingS3;

  useEffect(() => {
    if (isOpen && data) {
      setAccountUserDocs(data);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent p="1rem">
        <ModalHeader textStyle="uppercase" fontSize="25px">
          UPLOAD DOCUMENTS
        </ModalHeader>
        <ModalBody>
          <VStack alignItems="flex-start">
            {step === 0 ? (
              <Fragment>
                <VStack
                  alignItems="flex-start"
                  h="10rem"
                  w="80%"
                  justifyContent="space-between"
                  mb="2rem"
                >
                  <Text>ADD AN EXISTING DOCUMENT</Text>
                  <Select
                    isDisabled={accountUserDocs.length === 0}
                    onChange={onChangeAccountDocument}
                  >
                    <option value="">Select Document</option>
                    {accountUserDocs.map((doc) => (
                      <option key={doc.id} value={doc.documentName}>
                        {doc.documentName}
                      </option>
                    ))}
                  </Select>
                  <Button
                    layerStyle="red"
                    px="2rem"
                    mr="20px"
                    w="12rem"
                    isDisabled={!file}
                    onClick={upload}
                  >
                    {loadingProcess ? (
                      <Spinner color="white" />
                    ) : (
                      "ADD DOCUMENT"
                    )}
                  </Button>
                </VStack>
                <VStack alignItems="flex-start" w="100%">
                  <Text>OR UPLOAD NEW DOCUMENTS</Text>
                </VStack>
              </Fragment>
            ) : (
              <VStack w="100%" alignItems="flex-start">
                <Input
                  readOnly
                  placeholder="Click to select file"
                  onClick={() => fileInputRef.current?.click()}
                  value={fileName}
                  isDisabled={loadingProcess}
                  mb="1rem"
                />
                <Input
                  type="file"
                  hidden
                  ref={fileInputRef}
                  onChange={handleFileInput}
                />
              </VStack>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent={footerDisposition}>
          {loadingProcess ? (
            <Flex w="100%" alignItems="center" justifyContent="center">
              <Spinner color="mvmntRed" />
            </Flex>
          ) : (
            <Fragment>
              <Button
                layerStyle="red"
                px="2rem"
                mr="20px"
                w="12rem"
                isDisabled={step === 1 && !file}
                onClick={step === 0 ? forward : upload}
              >
                SELECT FILE
              </Button>
              <Button
                color="mvmntRed"
                variant="link"
                onClick={step === 0 ? onClose : goBack}
              >
                {step === 0 ? "CANCEL" : "GO BACK"}
              </Button>
            </Fragment>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RfpDocumentModal;
