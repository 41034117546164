import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { HStack, Text, VStack, IconButton, Spinner } from "@chakra-ui/react";
import { useLazyGetDocumentUrlQuery } from "../../api/documents";
import { parseDate } from "../../reuse/UserData";
import { Documents, MvmntDocument } from "../../types/Files";
import useToastHook from "../../components/useToastHook";

interface DocumentCardProps extends MvmntDocument {
  documentTypes: Documents[];
  chooseDocument?: () => void;
  openSignedDocument: (ext: string, url: string) => void;
}

export const DocumentCard = ({
  id,
  documentTypes,
  documentName,
  documentType,
  uploadDate,
  contentType,
  networkPartner,
  shipmentId,
  chooseDocument,
  openSignedDocument,
}: DocumentCardProps): JSX.Element => {
  const document = documentTypes.find(
    (document) => document.value === documentType,
  );

  const [getUrlDocumentById, { isFetching }] = useLazyGetDocumentUrlQuery();

  const toast = useToastHook();

  const getUrlDocument = async (): Promise<void> => {
    const response = await getUrlDocumentById(id);
    if ("data" in response) {
      openSignedDocument(documentName, response.data!);
    } else {
      toast.error({ description: "Try later please" });
    }
  };

  return (
    <HStack
      borderRadius="15px"
      w="100%"
      h="90px"
      layerStyle="cardShadow"
      spacing="30px"
      pe="15px"
    >
      <HStack
        w="8%"
        h="100%"
        layerStyle="yellow"
        justifyContent="center"
        borderRadius="8px"
      >
        <HStack
          bgColor="realWhite"
          w="50%"
          h="70%"
          justifyContent="center"
          borderRadius="8px"
        >
          <Text w="80%" maxH="100%" overflow="hidden" textTransform="uppercase">
            {contentType}
          </Text>
        </HStack>
      </HStack>
      <VStack alignItems="start">
        <Text>{document?.label}</Text>
        <Text>{documentName}</Text>
      </VStack>
      <VStack alignItems="start">
        {networkPartner && <Text>Network Partner: {networkPartner}</Text>}
        {shipmentId && <Text>Shipment ID: {shipmentId}</Text>}
      </VStack>
      <Text ml="auto !important">{parseDate(uploadDate)}</Text>

      <IconButton
        ml="1rem"
        icon={<DeleteIcon />}
        aria-label="Remove document"
        onClick={chooseDocument}
      />

      <IconButton
        icon={isFetching ? <Spinner /> : <DownloadIcon />}
        aria-label="Document URL"
        onClick={getUrlDocument}
        disabled={isFetching}
      />
    </HStack>
  );
};

export default DocumentCard;
