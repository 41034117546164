export enum ShipmentDocumentType {
  HazmatCertificate = "Hazmat Certificate",
  OverdimensionalPermit = "Overdimensional Permit",
  OtherPermit = "Other Permit",
  BillOfLading = "Bill Of Lading",
  OtherDoc = "Other Doc",
  Invoice = "Invoice",
}

export interface IShipmentDocument {
  id?: string;
  documentName: string;
  shipmentId: string;
  documentType: ShipmentDocumentType;
}
