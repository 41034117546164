import { Button, FormControl, Input, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUpdatePasswordMutation, UserUpdatePassword } from "../../api/auth";
import ErrorMessage from "../../components/ErrorMessage";
import SignInPageHOC from "../../components/SignInPageHOC";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { RegexPoliceProp } from "../../types/Regex";
import {
  noContent,
  validateEmail,
  validatePassword,
} from "../../reuse/UserData";
import RegexPolicesList from "../../components/RegexPolicesList";
import AppRoutes from "../../routes";
import useToastHook from "../../components/useToastHook";

export const UpdatePassword = (): JSX.Element => {
  const [regexList, setRegexList] = useState<[] | RegexPoliceProp[]>([]);
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const navigate = useNavigate();
  const {
    register,
    watch,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<UserUpdatePassword>();

  const toast = useToastHook();
  const newPassword = watch("newPassword");
  const passwordNoContent = noContent(newPassword);

  const handleReset = async (formValues: UserUpdatePassword): Promise<void> => {
    const response = await updatePassword(formValues);
    if ("data" in response) {
      if (response.data) {
        toast.success({ description: "Password reset successfully" });
        navigate(AppRoutes.LOGIN);
      } else {
        toast.error({ description: "Password reset failed" });
      }
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onSubmit = handleSubmit(handleReset);

  useEffect(() => {
    setRegexList(validatePassword(newPassword!));
  }, [newPassword]);

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing="24px">
        <FormControl isDisabled={isLoading}>
          <Input
            placeholder="Username"
            {...register("username", {
              required: {
                value: true,
                message: "You must enter your username",
              },
              validate: validateEmail,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isLoading}>
          <Input
            placeholder="Temporary Password"
            type="password"
            {...register("tempPassword", {
              required: {
                value: true,
                message: "You must enter the temporary password sent by email",
              },
            })}
          />
        </FormControl>

        <FormControl isDisabled={isLoading}>
          <Input
            placeholder="New Password"
            type="password"
            {...register("newPassword", {
              required: {
                value: true,
                message: "You must enter your password",
              },
            })}
          />
        </FormControl>

        {!passwordNoContent && (
          <RegexPolicesList regexList={regexList} columns={"1fr"} />
        )}

        <Button
          isLoading={isLoading}
          layerStyle="red"
          w="100%"
          borderRadius="lg"
          onClick={onSubmit}
        >
          Reset Password
        </Button>

        <ErrorMessage errors={errors} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default SignInPageHOC(UpdatePassword);
