import { Button, ButtonProps } from "@chakra-ui/react";

interface BtnProps extends ButtonProps {
  icon?: JSX.Element;
  title?: string;
}

export const SharedButton = ({
  icon,
  title,
  ...props
}: BtnProps): JSX.Element => (
  <Button leftIcon={icon} {...props}>
    {title}
  </Button>
);

export default SharedButton;
