import { useState, Fragment } from "react";
import { Button } from "@chakra-ui/react";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { Shipment } from "../../types/Shipment";
import { UserType } from "../../types/User";
import ConfirmationDeliveredModal from "../ConfirmationDeliveredModal";

interface DeliveredPendingButtonProps {
  shipment: Shipment;
}

const DeliveredPendingButton = ({
  shipment,
}: DeliveredPendingButtonProps): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const user = useAppSelector(selectUserData);
  const userIsCarrier = user.type === UserType.CARRIER;

  return (
    <Fragment>
      <Button
        layerStyle={"blue"}
        color="white"
        bgColor="blue"
        disabled={userIsCarrier}
        borderRadius="0px !important"
        padding="3px 10px !important"
        onClick={() => setOpenModal(true)}
        size="small"
      >
        Delivered - Pending Review
      </Button>
      <ConfirmationDeliveredModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title="Confirm Shipment Delivery"
        shipment={shipment}
      />
    </Fragment>
  );
};

export default DeliveredPendingButton;
