import { createApi } from "@reduxjs/toolkit/query/react";
import { ShipperAccountRequestData, ShipperUser } from "../types/Shipper";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";
import { PaymentMethodType } from "../types/Tilled";
import { PaymentMethod } from "tilled";
import { PaymentIntentCreateParams } from "../types/Invoice";

interface ApproveUserParams {
  id: string;
  email: string;
}

interface Address {
  addressCity: string;
  addressState: string;
  addressCountry: string;
  date: string;
  receivingName?: string;
}
export interface ShipperInvoice {
  id: string;
  dueDate: string | null;
  shipment: {
    id: string;
    mvmntShipmentId: number;
    winningBid: {
      totalAmount: number;
    };
    shipmentStops: {
      origin: Address;
      destination: Address;
    };
  };
  payee: string;
  directPaymentRequest: {
    id: string;
    amount: number;
  } | null;
}

export const shipperApi = createApi({
  reducerPath: "shipperApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}`),
  tagTypes: ["Shipper", "ManageUsers"],
  endpoints: (builder) => ({
    getShipper: builder.query<ShipperUser, void>({
      query: () => "/user/shippers",
      providesTags: ["Shipper"],
    }),
    updateShipper: builder.mutation<ShipperUser, Partial<ShipperUser>>({
      query: (body) => ({
        url: "/shippers",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Shipper"],
    }),
    getShipperByCompany: builder.query<Partial<ShipperUser>, string>({
      query: (companyName) => `/shippers/contact?companyName=${companyName}`,
    }),
    getShipperById: builder.query<Partial<ShipperUser>, string>({
      query: (mvmntIdNumber) =>
        `/shippers/contact?mvmntIdNumber=${mvmntIdNumber}`,
    }),
    sendShipperAccountForm: builder.mutation<
      ShipperUser,
      ShipperAccountRequestData
    >({
      query: (body) => ({
        url: "/shippers",
        method: "POST",
        body,
      }),
    }),
    getAllShipperUsers: builder.query<ShipperUser[], void>({
      query: (body) => ({
        url: "/shippers",
        method: "GET",
      }),
      providesTags: ["ManageUsers"],
    }),
    rejectShipper: builder.mutation<void, string>({
      query: (body) => ({
        url: `/shippers/${body}/reject`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ManageUsers"],
    }),
    approveUser: builder.mutation<void, ApproveUserParams>({
      query: (body) => ({
        url: `/shippers/${body.id}/approve`,
        method: "PUT",
        body: { email: body.email },
      }),
      invalidatesTags: ["ManageUsers"],
    }),
    revokeShipper: builder.mutation<void, string>({
      query: (id) => ({
        url: `/shippers/${id}/revoke`,
        method: "PUT",
      }),
      invalidatesTags: ["ManageUsers"],
    }),
    attachPaymentMethodToShipper: builder.mutation<
      void,
      { paymentToken: string }
    >({
      query: (body) => ({
        url: `/shippers/attach-payment-method`,
        method: "POST",
        body,
      }),
    }),
    listPaymentMethodsByType: builder.query<
      PaymentMethod[],
      { type: PaymentMethodType }
    >({
      query: ({ type }) => ({
        url: `/shippers/payment-methods/${type}`,
        method: "GET",
      }),
    }),
    createPaymentIntent: builder.mutation<void, PaymentIntentCreateParams>({
      query: (body) => ({
        url: "/shippers/payment-intents",
        method: "POST",
      }),
    }),
    getShipperInvoices: builder.query<ShipperInvoice[], string>({
      query: (id: string) => ({
        url: `/shippers/${id}/invoices`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCreatePaymentIntentMutation,
  useLazyListPaymentMethodsByTypeQuery,
  useListPaymentMethodsByTypeQuery,
  useAttachPaymentMethodToShipperMutation,
  useGetShipperQuery,
  useUpdateShipperMutation,
  useSendShipperAccountFormMutation,
  useLazyGetShipperByCompanyQuery,
  useLazyGetShipperByIdQuery,
  useGetAllShipperUsersQuery,
  useGetShipperByIdQuery,
  useRejectShipperMutation,
  useApproveUserMutation,
  useRevokeShipperMutation,
  useGetShipperInvoicesQuery,
} = shipperApi;

export const { useQueryState } = shipperApi.endpoints.getShipper;

export const { invalidateTags } = shipperApi.util;
