import { useEffect, useState } from "react";
import { VStack, Button } from "@chakra-ui/react";
import AbsoluteRightButtons from "../AbsoluteRightButtons";
import AddToRfpForm from "../AddToRfpForm";
import { exportFileResults, OneMegaByte } from "../../reuse/Files";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import CarrierTable from "./CarrierTable";
import { useActiveRfpContext } from "../../pages/ActiveRfp/ActiveRfpContext";
import ConfirmCarriersModal from "../ConfirmCarriersModal";
import {
  CarriersProcessType,
  ModalCarriersProps,
  RfpButtonsPosition,
  IRFPAddCarrier,
} from "../../types/Rfp";
import {
  useValidateRfpCarriersUploadMutation,
  useUploadRfpCarriersUploadMutation,
  useAddCarriersToRfpMutation,
} from "../../api/rfp";
import { useLazyGetCarriersRfpTemplateQuery } from "../../api/documents";
import ReusableBulkUploadModal from "../ReusableBulkUploadModal";
import ReusableBulkUploadFile from "../ReusableBulkUploadFile";
import useToastHook from "../useToastHook";
import { useGetNetworkCarriersQuery } from "../../api/network";
import {
  createCarriers,
  mapNetworkCarriers,
  mapReadyToAddCarriers,
} from "../../reuse/Rfp";
import { selectUserData } from "../../app/userSlice";
import { useAppSelector } from "../../app/hooks";
import { UserType } from "../../types/User";

export const CarriersProposalDetail = (): JSX.Element => {
  const toast = useToastHook();
  const rfpContext = useActiveRfpContext();
  const { largerThan1620, selectedRfp } = rfpContext!;
  const { type: typeUser } = useAppSelector(selectUserData);

  const shipperType =
    UserType.SHIPPER === typeUser || UserType.BROKER === typeUser;

  const { data: carriersData } = useGetNetworkCarriersQuery(undefined, {
    skip: !shipperType,
  });

  const [downloadCarriersTemplate] = useLazyGetCarriersRfpTemplateQuery();

  const [addCarriersToRfp, { isLoading: isAddingCarrier }] =
    useAddCarriersToRfpMutation();

  const [
    validateFile,
    { data: validationResult, isLoading: isValidating, reset },
  ] = useValidateRfpCarriersUploadMutation();

  const [uploadRfpCarriersUpload, { isLoading: isAdding }] =
    useUploadRfpCarriersUploadMutation();

  const [processType, setProcessType] = useState<CarriersProcessType>(
    CarriersProcessType.AddNetwork,
  );
  const [carriersModalList, setCarriersModalList] = useState<
    ModalCarriersProps[]
  >([]);

  const [rfpAddCarriers, setRfpAddCarriers] = useState<IRFPAddCarrier[]>([]);
  const [addRfpModal, toggleAddRfpModal] = useState<boolean>(false);
  const [bulkUploadModal, toggleBulkUploadModal] = useState<boolean>(false);
  const [bulkUploadModalFile, toggleBulkUploadModalFile] =
    useState<boolean>(false);
  const [openCarriersModal, setOpenCarriersModal] = useState<boolean>(false);

  const [fileName, setFileName] = useState("");

  const addCarriers = (): void => toggleAddRfpModal(true);

  const uploadFileOpen = (): void => {
    toggleBulkUploadModal(false);
    toggleBulkUploadModalFile(true);
  };

  const addFromNetwork = (): void => {
    setProcessType(CarriersProcessType.AddNetwork);
    const modalCarriers = mapNetworkCarriers(carriersData!);
    setCarriersModalList(modalCarriers);
    setOpenCarriersModal(true);
    toggleAddRfpModal(false);
  };

  const toggleBulkUpload = (): void => {
    setProcessType(CarriersProcessType.BulkUpload);
    toggleAddRfpModal(false);
    toggleBulkUploadModal(true);
  };

  const goBackFromCarriersModal = (): void => {
    toggleBulkUploadModalFile(true);
    setOpenCarriersModal(false);
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files![0];
    if (file.size >= 100 * OneMegaByte) {
      alert("File size is too large.");
      return;
    }
    setFileName(file.name);
    const response = await validateFile({ rfpId: selectedRfp!.id!, file });
    if ("data" in response) {
      toast.success({ description: "Upload successful." });
    } else {
      toast.error({ description: parseErrorResponse(response.error) });
    }
  };

  const onClickUpload = async (): Promise<void> => {
    if (!validationResult) return;
    setRfpAddCarriers(validationResult.readyToAdd);
    const modalCarriers = mapReadyToAddCarriers(validationResult.readyToAdd);
    setCarriersModalList(modalCarriers);
    setOpenCarriersModal(true);
    toggleBulkUploadModalFile(false);
    toggleBulkUploadModal(false);
  };

  const addToRfp = async (): Promise<void> => {
    const carriers = createCarriers(carriersData!);
    const response = await addCarriersToRfp({
      rfpId: selectedRfp!.id,
      carriers,
    });
    if ("data" in response) {
      toast.success({ description: "Carriers added successfully." });
    } else {
      toast.error({ description: parseErrorResponse(response.error) });
    }
  };

  const confirmBulkUpload = async (): Promise<void> => {
    const response = await uploadRfpCarriersUpload({
      rfpId: selectedRfp!.id!,
      carriers: rfpAddCarriers,
    });
    if ("data" in response) {
      toast.success({ description: "Carriers added successfully." });
      toggleBulkUploadModalFile(false);
      setOpenCarriersModal(false);
    } else {
      toast.error({ description: parseErrorResponse(response.error) });
    }
  };

  const exportFn = async (): Promise<void> => {
    const response = await downloadCarriersTemplate();
    if ("error" in response) {
      toast.error({ description: "Template not available" });
    } else {
      exportFileResults(response.data!, "carriers_template");
    }
  };

  const submitProcess = async (): Promise<void> => {
    processType === CarriersProcessType.AddNetwork
      ? await addToRfp()
      : await confirmBulkUpload();
  };

  useEffect(() => {
    setFileName("");
    reset();
  }, [bulkUploadModalFile]);

  return (
    <VStack h="100%" w="100%">
      <AbsoluteRightButtons
        top={
          largerThan1620
            ? RfpButtonsPosition.Primary
            : RfpButtonsPosition.Secondary
        }
        left={
          largerThan1620
            ? RfpButtonsPosition.Infimum
            : RfpButtonsPosition.Primary
        }
        right={
          largerThan1620
            ? RfpButtonsPosition.Primary
            : RfpButtonsPosition.Infimum
        }
      >
        <Button layerStyle="red" onClick={addCarriers}>
          + ADD CARRIERS
        </Button>
      </AbsoluteRightButtons>

      <CarrierTable data={rfpContext?.selectedRfp?.carriersInfo ?? []} />

      <AddToRfpForm
        isOpen={addRfpModal}
        onClose={() => toggleAddRfpModal(false)}
        toggleBulkUpload={toggleBulkUpload}
        addFromNetwork={addFromNetwork}
      />

      <ReusableBulkUploadModal
        title={"BULK UPLOAD CARRIERS"}
        isOpen={bulkUploadModal}
        onClose={() => toggleBulkUploadModal(false)}
        downloadButtonTxt="DOWNLOAD CVS TEMPLATE"
        uploadButtonTxt="UPLOAD TEMPLATE"
        toggleFileSelectorFn={uploadFileOpen}
        downloadFileName="carriers_template"
        exportFn={exportFn}
      />

      <ReusableBulkUploadFile
        isOpen={bulkUploadModalFile}
        onClose={() => toggleBulkUploadModalFile(false)}
        title="BULK UPLOAD CARRIERS"
        extensionFile={".xlsx"}
        confirmBtnText="Add Carriers"
        fileName={fileName}
        handleFileInput={handleFileInput}
        onClickUpload={onClickUpload}
        isLoaded={!isValidating && !isAdding}
      />

      <ConfirmCarriersModal
        isOpen={openCarriersModal}
        onClose={goBackFromCarriersModal}
        carriersData={carriersModalList}
        accept={submitProcess}
        processing={isAddingCarrier || isAdding}
      />
    </VStack>
  );
};

export default CarriersProposalDetail;
