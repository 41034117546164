import { ChevronDownIcon } from "@chakra-ui/icons";
import SharedButton from "../../components/SharedButton";
import { LoadStatus } from "../../types/Load";

interface LoadBoardStatusBadgeProps {
  status: LoadStatus;
}

export const LoadBoardStatusBadge = ({
  status,
}: LoadBoardStatusBadgeProps): JSX.Element => {
  // TODO: handle colors of all statuses
  const getTextColor = (): string => {
    if (status === LoadStatus.Delivered) {
      return "successMedium";
    }

    return "mvmntBlue";
  };
  const getBackgroundColor = (): string => {
    if (status === LoadStatus.Delivered) {
      return "successMediumBg";
    }

    return "mvmntBlueBg";
  };
  return (
    <SharedButton
      title={status}
      size="xs"
      color={getTextColor()}
      bgColor={getBackgroundColor()}
      cursor="default"
      rightIcon={<ChevronDownIcon />}
    />
  );
};

export default LoadBoardStatusBadge;
