import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";
import { DefaultModalProps } from "../../../types/DefaultModal";

interface QueueTenderModalProps extends DefaultModalProps {
  onTender: () => void;
  onTenderLater: () => void;
}

const QueueTenderModal = ({
  isOpen,
  onClose,
  onTender,
  onTenderLater,
}: QueueTenderModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={24} textStyle="uppercase" justifySelf="start">
          Queue Tender?
        </ModalHeader>
        <ModalBody mb={6}>
          <Text fontSize={18}>
            Do you want to send the order tender to this carrier
          </Text>
          <Text fontSize={18}>
            automatically after they sign your shipper-carrier
          </Text>
          <Text fontSize={18}>agreement?</Text>
        </ModalBody>
        <ModalFooter justifyContent="space-around" mb={4}>
          <Button
            fontSize={12}
            layerStyle="black"
            textColor="white"
            onClick={onTenderLater}
          >
            No, I will tender later
          </Button>
          <Button fontSize={12} layerStyle="red" onClick={onTender}>
            Yes, tender to this carrier
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QueueTenderModal;
