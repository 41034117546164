/* eslint react/prop-types: 0 */
import {
  HStack,
  Input,
  VStack,
  Text,
  Select,
  Button,
  Textarea,
  FormControl,
  FormLabel,
  Checkbox,
} from "@chakra-ui/react";
import {
  FieldValues,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import { getMinimumDate } from "../../reuse/Dates";
import { borderStyling } from "../../reuse/Forms";
import { AppointmentType, LoadType, StopType } from "../../types/ShipmentForm";
import ChevronIcon from "../ChevronIcon";
import ErrorMessage from "../ErrorMessage";
import FormCountry from "../FormCountry";
import FormState from "../FormState";
import useToastHook from "../../components/useToastHook";
import { ShipmentCreate } from "../../types/Shipment";
import AppRoutes from "../../routes";
import { Address1Row } from "./Address1Row";
import { PurchaseRow } from "./PurchaseRow";
import { FormError } from "../../types/FormError";

interface StopFieldValues extends FieldValues, ShipmentCreate {}

interface ShipmentStopsFormProps {
  isCopying: boolean;
  formHook: UseFormReturn<ShipmentCreate>;
  isReadOnly: boolean;
  shipmentStopsFieldArray: UseFieldArrayReturn<
    StopFieldValues,
    "shipmentStops",
    "id"
  >;
}

export const ShipmentStopsForm = ({
  formHook,
  isReadOnly,
  shipmentStopsFieldArray,
  isCopying,
}: ShipmentStopsFormProps): JSX.Element => {
  const {
    control,
    register,
    getValues,
    clearErrors,
    formState: { errors },
  } = formHook;

  const { fields, insert } = shipmentStopsFieldArray;
  const toast = useToastHook();

  const { shipmentStops: shipmentStopsError } = errors;

  const isTrackingPage = window.location.href.includes(AppRoutes.TRACKING);
  const isPastShipmentsPage = window.location.href.includes(AppRoutes.PAST);

  const onAddStop = (): void => {
    if (fields.length < 10) {
      insert(fields.length - 1, {});
    } else {
      toast.error({ description: "You can add 10 stops at most per shipment" });
    }
  };

  const HeaderRow = ({ index }: { index: number }): JSX.Element => {
    return (
      <HStack w="100%" spacing="1%">
        <HStack w="20%" minW="10rem">
          <ChevronIcon />
          <Text textStyle="subTitle">
            {index === 0
              ? "Origin"
              : index === fields.length - 1
              ? "Destination"
              : `Stop ${index}`}
          </Text>
        </HStack>
        <FormLabel ml="auto !important">Stop Type</FormLabel>
        <Select
          w="25%"
          isDisabled={index === 0 || index === fields.length - 1}
          {...register(`shipmentStops.${index}.stopType` as const, {
            required: {
              value: true,
              message: "You must enter the stop type",
            },
          })}
          style={{
            borderColor: borderStyling(shipmentStopsError?.[index]?.stopType),
          }}
        >
          {Object.values(StopType).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
      </HStack>
    );
  };

  const AppointmentRow = ({ index }: { index: number }): JSX.Element => {
    useWatch({
      name: `shipmentStops.${index}.appointmentType`,
      control,
    });
    const isByAppointment =
      getValues(`shipmentStops.${index}.appointmentType` as const) ===
      AppointmentType.Appointment;

    return (
      <HStack w="100%" alignItems="end" spacing="1%">
        <FormControl w="24%">
          <FormLabel>Appointment Type</FormLabel>
          <Select
            {...register(`shipmentStops.${index}.appointmentType` as const, {
              required: {
                value: true,
                message: "You must enter the appointment type",
              },
            })}
            isDisabled={isPastShipmentsPage || isTrackingPage}
          >
            {Object.values(AppointmentType).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl w="25%">
          <FormLabel>Appointment Time</FormLabel>
          <Input
            isDisabled={
              !isByAppointment || isPastShipmentsPage || isTrackingPage
            }
            type="time"
            {...register(`shipmentStops.${index}.appointmentTime` as const, {
              required: isByAppointment
                ? {
                    value: true,
                    message: "You must enter the appointment time",
                  }
                : false,
            })}
            style={{
              borderColor: borderStyling(
                shipmentStopsError?.[index]?.appointmentTime,
              ),
            }}
          />
        </FormControl>
        <FormControl w="50%">
          <FormLabel>{index > 0 ? "Unload Type" : "Load Type"}</FormLabel>
          <Select
            {...register(`shipmentStops.${index}.loadType` as const, {
              required: {
                value: true,
                message: `You must enter the ${
                  index > 0 ? "unload" : "load"
                } type`,
              },
            })}
            disabled={isReadOnly}
            style={{
              borderColor: borderStyling(shipmentStopsError?.[index]?.loadType),
            }}
          >
            {Object.values(LoadType).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
        </FormControl>
      </HStack>
    );
  };

  const StopRow = ({ index }: { index: number }): JSX.Element => {
    useWatch({
      name: `shipmentStops.${index}.allDayDock`,
      control,
    });

    const isAllDayDock = getValues(
      `shipmentStops.${index}.allDayDock` as const,
    );

    const checked = getValues(`shipmentStops.${index}.allDayDock`);
    const isDockDisabled = isAllDayDock || isReadOnly || isTrackingPage;

    return (
      <VStack w="100%" alignItems="end">
        <HStack>
          <FormLabel>24 Hour Dock</FormLabel>
          <Checkbox
            {...register(`shipmentStops.${index}.allDayDock` as const, {
              required: false,
            })}
            isChecked={checked}
            isDisabled={isReadOnly}
            style={{ marginBottom: "0.5rem" }}
          />
        </HStack>

        <HStack w="100%" alignItems="end" spacing="1%">
          <FormControl w="50%">
            <FormLabel>Stop Date</FormLabel>
            <Input
              type="date"
              min={getMinimumDate()}
              {...register(`shipmentStops.${index}.date` as const, {
                required: {
                  value: true,
                  message: "You must enter the date",
                },
              })}
              disabled={isReadOnly}
              style={{
                borderColor: borderStyling(shipmentStopsError?.[index]?.date),
              }}
            />
          </FormControl>
          <FormControl w="24%">
            <FormLabel>Dock Open</FormLabel>
            <Input
              type="time"
              {...register(`shipmentStops.${index}.dockOpen` as const, {
                required: false,
              })}
              disabled={isDockDisabled}
            />
          </FormControl>
          <FormControl w="25%">
            <FormLabel>Dock Close</FormLabel>
            <Input
              type="time"
              {...register(`shipmentStops.${index}.dockClose` as const, {
                required: false,
              })}
              disabled={isDockDisabled}
            />
          </FormControl>
        </HStack>
      </VStack>
    );
  };

  const Address2Row = ({ index }: { index: number }): JSX.Element => {
    return (
      <HStack w="100%" spacing="1%">
        <Input
          w="50%"
          placeholder="Address 2"
          {...register(`shipmentStops.${index}.address.address2` as const, {
            required: false,
          })}
          disabled={isReadOnly}
        />
        <FormCountry
          w="25%"
          isDisabled={isReadOnly}
          formHook={formHook}
          property={`shipmentStops.${index}.address`}
          style={{
            borderColor: borderStyling(shipmentStopsError?.[index]?.address),
          }}
          required
        />
        <FormState
          w="24%"
          isDisabled={isReadOnly}
          formHook={formHook}
          style={{
            borderColor: borderStyling(shipmentStopsError?.[index]?.address),
          }}
          property={`shipmentStops.${index}.address`}
          required
        />
      </HStack>
    );
  };

  return (
    <VStack alignItems="start" spacing="5vh" w="100%">
      {fields.map((field, index) => (
        <VStack
          key={`key for shipment form stop fields ${field.id} ${index}`}
          alignItems="start"
          w="100%"
          spacing="1rem"
        >
          <HeaderRow index={index} />
          <AppointmentRow index={index} />
          <StopRow index={index} />
          <Address1Row
            formHook={formHook}
            index={index}
            isReadOnly={isReadOnly}
          />
          <Address2Row index={index} />
          <PurchaseRow
            formHook={formHook}
            index={index}
            isReadOnly={isReadOnly}
          />
          <HStack w="100%">
            <Textarea
              placeholder="Stop Notes"
              resize="none"
              maxLength={250}
              {...register(`shipmentStops.${index}.notes` as const, {
                required: false,
              })}
              isDisabled={isCopying || isTrackingPage || isPastShipmentsPage}
            />
          </HStack>
          {errors?.shipmentStops?.[index] && (
            <ErrorMessage
              errors={errors?.shipmentStops?.[index] as FormError}
              clearErrors={clearErrors}
            />
          )}
          {index === fields.length - 2 && (
            <Button
              alignSelf="start"
              variant="link"
              color="tomato"
              onClick={onAddStop}
              isDisabled={isReadOnly}
            >
              + Additional Stop
            </Button>
          )}
        </VStack>
      ))}
    </VStack>
  );
};

export default ShipmentStopsForm;
