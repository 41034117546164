import { Tr, Td, Checkbox, Text, HStack, Select } from "@chakra-ui/react";
import QuickPayActions from "./QuickPayActions";
import { parseDateToLocal } from "../../reuse/Dates";
import {
  generateData,
  getBadgeColorWheterIsOverdueOrNot,
  getStatusWheterIsOverdueOrNot,
} from "./reuse";
import {
  QuickPayExtraActions,
  QuickPayInvoiceStatus,
  QuickPayRequest,
  QuickPayRequestStatus,
} from "../../types/QuickPayRequest";
import { useSetQuickPayInvoiceStatusMutation } from "../../api/quickpay";
import useToastHook from "../../components/useToastHook";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import ReusableBadge from "../../components/ReusableBadge";
import { useGetShipmentDocsUrlsByShipmentIdQuery } from "../../api/documents";
import { useQuickPayContext } from "./QuickPayContext";

interface RowProps {
  request: QuickPayRequest;
}
interface RemainingProps {
  value: number;
}

interface QuickPayRowProps {
  typeTable: QuickPayRequestStatus;
  request: QuickPayRequest;
}

export const QuickPayRow = ({
  typeTable,
  request,
}: QuickPayRowProps): JSX.Element => {
  const context = useQuickPayContext();
  const { selectedQuickPay, setSelectedQuickPay } = context!;

  const [setQuickPayInvoiceStatus] = useSetQuickPayInvoiceStatusMutation();
  const { data: docs } = useGetShipmentDocsUrlsByShipmentIdQuery(
    request.invoice.shipmentId,
  );
  const toast = useToastHook();

  const updateStatus = async (status: QuickPayInvoiceStatus): Promise<void> => {
    const response = await setQuickPayInvoiceStatus({
      id: request.invoice.qpInvoice.id,
      status,
    });

    if ("data" in response) {
      toast.success({ description: "Status updated successfully" });
      setTimeout(() => window.location.reload(), 1000);
    } else
      toast.error({ description: `${parseErrorResponse(response.error)}` });
  };

  const onChange = (): void => {
    if (selectedQuickPay.includes(request.id)) {
      setSelectedQuickPay(
        selectedQuickPay.filter((element) => element !== request.id),
      );
    } else {
      setSelectedQuickPay([...selectedQuickPay, request.id]);
    }
  };

  const Remaining = ({ value }: RemainingProps): JSX.Element => {
    return (
      <HStack>
        <Text>{`${value} hours`}</Text>
        {value < 2 && (
          <Text
            fontSize="11px"
            px="0.4rem"
            color="#ED3054"
            fontWeight={700}
            bgColor="rgba(237, 48, 84, 0.05)"
            borderRadius="10px"
            letterSpacing="0.04rem"
          >
            Overdue
          </Text>
        )}
      </HStack>
    );
  };

  const CommonRow = ({ request }: RowProps): JSX.Element => (
    <>
      <Td textTransform="uppercase">{request.invoice.mvmntInvoiceId}</Td>
      <Td textTransform="uppercase">
        {request.invoice.shipper.contactInformation.companyName}
      </Td>
      <Td textTransform="uppercase">{request.carrier!.companyName}</Td>
    </>
  );

  const viewInvoice = (): void => {
    if (docs) {
      const invoiceDoc: string | undefined = docs.find((doc) =>
        doc.includes("/Invoice/"),
      );
      if (invoiceDoc) {
        window.open(invoiceDoc);
      } else {
        toast.error({
          description: "no invoice found for this quickpay request",
        });
      }
    } else {
      toast.error({ description: "no documents uploaded for this shipment" });
    }
  };

  const RequestedRow = ({ request }: RowProps): JSX.Element => {
    const { elapsedTime, timeRemaining } = generateData(request);

    return (
      <>
        <Td textTransform="uppercase">{`${elapsedTime} hours`}</Td>
        <Td textTransform="uppercase">
          <Remaining value={timeRemaining} />
        </Td>
        <Td textTransform="uppercase">{`$ ${request.invoice.invoiceAmount}`}</Td>
        <Td>
          <QuickPayActions title="View Invoice" onClick={viewInvoice} />
        </Td>
      </>
    );
  };

  const renderBadge = ({
    invoice: {
      dueDate,
      qpInvoice: { status },
    },
  }: QuickPayRequest): JSX.Element => {
    // TODO: Shipment delivery date + invoice terms = date invoice is due (include invoice terms on validation)
    const currentStatus = getStatusWheterIsOverdueOrNot(status, dueDate);
    const badgeColor = getBadgeColorWheterIsOverdueOrNot(status, dueDate);

    return (
      <ReusableBadge<QuickPayInvoiceStatus>
        currentStatus={currentStatus}
        badgeColor={badgeColor}
      />
    );
  };

  const renderRowActions = (request: QuickPayRequest): JSX.Element => (
    <>
      {request.invoice.qpInvoice.status === QuickPayInvoiceStatus.NOT_PAID && (
        <QuickPayActions
          title="Mark as paid"
          onClick={async () =>
            await updateStatus(QuickPayInvoiceStatus.SHIPPER_PAID)
          }
        />
      )}
      {request.invoice.qpInvoice.status ===
        QuickPayInvoiceStatus.SHIPPER_PAID && (
        <Select
          iconSize="0px"
          placeholder="..."
          border="none"
          bgColor="grey30"
          width={20}
          onChange={(e) =>
            e.target.value === QuickPayExtraActions.Mark_Not_Paid &&
            updateStatus(QuickPayInvoiceStatus.NOT_PAID)
          }
        >
          {Object.values(QuickPayExtraActions).map((action) => (
            <option key={action}>{action}</option>
          ))}
        </Select>
      )}
    </>
  );

  const ApprovedRow = ({ request }: RowProps): JSX.Element => (
    <>
      <Td textTransform="uppercase">{request.decidingAdmin ?? ""}</Td>
      <Td textTransform="uppercase">
        {parseDateToLocal(request.decisionDate!)}
      </Td>
      <Td textTransform="uppercase">{`$ ${request.invoice.invoiceAmount}`}</Td>
      <Td textTransform="uppercase">{renderBadge(request)}</Td>
      <Td>{renderRowActions(request)}</Td>
    </>
  );

  const RejectedRow = ({ request }: RowProps): JSX.Element => (
    <>
      <Td textTransform="uppercase">{request.decidingAdmin ?? ""}</Td>
      <Td textTransform="uppercase">
        {parseDateToLocal(request.decisionDate!)}
      </Td>
      <Td textTransform="uppercase">{`$ ${request.invoice.invoiceAmount}`}</Td>
      <Td textTransform="uppercase">{request.reason ?? ""}</Td>
    </>
  );

  return (
    <Tr border="0.8px solid grey">
      {typeTable === QuickPayRequestStatus.REQUESTED && (
        <Td>
          <Checkbox
            colorScheme="green"
            borderColor="grey"
            isChecked={selectedQuickPay.includes(request.id)}
            onChange={onChange}
          ></Checkbox>
        </Td>
      )}
      <CommonRow request={request} />
      {typeTable === QuickPayRequestStatus.REQUESTED && (
        <RequestedRow request={request} />
      )}
      {typeTable === QuickPayRequestStatus.APPROVED && (
        <ApprovedRow request={request} />
      )}
      {typeTable === QuickPayRequestStatus.REJECTED && (
        <RejectedRow request={request} />
      )}
    </Tr>
  );
};
