import { useState } from "react";
import { VStack, Button, Skeleton } from "@chakra-ui/react";
import AbsoluteRightButtons from "../AbsoluteRightButtons";
import { useActiveRfpContext } from "../../pages/ActiveRfp/ActiveRfpContext";
import RfpDocumentCard from "./RfpDocumentCard";
import RfpDocumentModal from "./RfpDocumentModal";
import { IRfpDocument, RfpButtonsPosition } from "../../types/Rfp";
import ConfirmationModal from "../ConfirmationModal";
import {
  useDeleteRfpDocumentMutation,
  useLazyGetRfpDocumentUrlQuery,
} from "../../api/documents";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import useToastHook from "../../components/useToastHook";
import { selectUserData } from "../../app/userSlice";
import { useAppSelector } from "../../app/hooks";
import { UserType } from "../../types/User";

export const DocsProposalDetail = (): JSX.Element => {
  const toast = useToastHook();
  const rfpContext = useActiveRfpContext();
  const { selectedRfp, largerThan1620 } = rfpContext!;
  const { id, type } = useAppSelector(selectUserData);

  const [rfpDocModal, setRfpDocModal] = useState<boolean>(false);
  const [isDocModalOpen, toggleDocModalOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<IRfpDocument | null>(null);

  const carrierUser = id && type === UserType.CARRIER;

  const [deleteRfpDocument, { isLoading }] = useDeleteRfpDocumentMutation();
  const [getUrlRfpDocumentById] = useLazyGetRfpDocumentUrlQuery();

  const addDocument = (): void => setRfpDocModal(true);

  const chooseDocument = async (doc: IRfpDocument): Promise<void> => {
    if (carrierUser) {
      const response = await getUrlRfpDocumentById(doc.id!);
      if ("data" in response) {
        window.open(response.data!, "_blank");
      } else {
        toast.error({ description: `File is not available now to download.` });
      }
      return;
    }
    toggleDocModalOpen(true);
    setSelectedDoc(doc);
  };

  const closeModalDocument = (): void => {
    toggleDocModalOpen(false);
    setSelectedDoc(null);
  };

  const onConfirmRemoveDocument = async (): Promise<void> => {
    if (selectedDoc) {
      toggleDocModalOpen(false);
      const response = await deleteRfpDocument(selectedDoc.id!);
      if ("data" in response) {
        toast.success({ description: "Document removed successfully" });
      } else {
        toast.error({ description: `${parseErrorResponse(response.error)}` });
      }
      setSelectedDoc(null);
    }
  };

  return (
    <VStack h="100%" w="100%">
      {!carrierUser && (
        <AbsoluteRightButtons
          top={
            largerThan1620
              ? RfpButtonsPosition.Primary
              : RfpButtonsPosition.Secondary
          }
          left={
            largerThan1620
              ? RfpButtonsPosition.Infimum
              : RfpButtonsPosition.Primary
          }
          right={
            largerThan1620
              ? RfpButtonsPosition.Primary
              : RfpButtonsPosition.Infimum
          }
        >
          <Button layerStyle="red" px="2rem" onClick={addDocument}>
            ADD DOCUMENT
          </Button>
        </AbsoluteRightButtons>
      )}
      <Skeleton w="100%" isLoaded={!isLoading}>
        {selectedRfp?.rfpDocuments?.map((document) => (
          <RfpDocumentCard
            key={document.id}
            document={document}
            chooseDocument={async () => await chooseDocument(document)}
          />
        ))}
      </Skeleton>
      <RfpDocumentModal
        rfpId={selectedRfp?.id ?? ""}
        isOpen={rfpDocModal}
        onClose={() => setRfpDocModal(false)}
      />
      <ConfirmationModal
        isOpen={isDocModalOpen}
        onClose={closeModalDocument}
        onConfirm={onConfirmRemoveDocument}
        title="Document"
        content={`Are you sure you want to delete file:  ${
          selectedDoc?.documentName.split("/")[1]
        } ?`}
      />
    </VStack>
  );
};

export default DocsProposalDetail;
