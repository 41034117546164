import { Skeleton, VStack, HStack } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import Header from "../../components/Header";
import TabsContainer from "../../components/TabsContainer";
import { QuickPayContext } from "./QuickPayContext";
import QuickPayTable from "./QuickPayTable";
import { getFilteredTableData, getRequests } from "./reuse";
import TabAdditionalContent from "./TabAdditionalContent";
import {
  QuickPayRequestStatus,
  QuickPayToDropdownEnum,
  QuickPayToFilterMultiSelect,
} from "../../types/QuickPayRequest";
import { DownloadOption } from "./DownloadOption";
import { useGetAllQuickPayRequestsQuery } from "../../api/quickpay";
import {
  createCsvFromObjectsArray,
  createQuickPayRequestedToExport,
  createQuickPayDecisionToExport,
  exportFileResults,
} from "../../reuse/Files";

export const QuickPay = (): JSX.Element => {
  const { data: quickPays, isLoading } = useGetAllQuickPayRequestsQuery();

  const [selectedQuickPay, setSelectedQuickPay] = useState<string[]>([]);
  const [term, setTerm] = useState<string>("");

  const [sortCriteria, setSortCriteria] = useState<string>("");

  const [currentStatus, setCurrentStatus] = useState<QuickPayRequestStatus>(
    QuickPayRequestStatus.REQUESTED,
  );

  const [multiselectFilters, setMultiselectFilters] =
    useState<QuickPayToFilterMultiSelect>({
      load_id: [],
      shipper: [],
      carrier: [],
      decisionAdmin: [],
      decisionDate: [],
      status: [],
    });

  const sortByCriteria = (criteria: QuickPayToDropdownEnum): void =>
    setSortCriteria(criteria);

  const handleClickTab = (title: string): void =>
    setCurrentStatus(title as QuickPayRequestStatus);

  const requestedData = getRequests(
    quickPays ?? [],
    QuickPayRequestStatus.REQUESTED,
  );

  const Requested = useMemo(
    () => (
      <QuickPayTable
        requests={requestedData}
        typeTable={QuickPayRequestStatus.REQUESTED}
      />
    ),
    [quickPays],
  );

  const Approved = useMemo(
    () => (
      <QuickPayTable
        requests={getRequests(quickPays ?? [], QuickPayRequestStatus.APPROVED)}
        typeTable={QuickPayRequestStatus.APPROVED}
      />
    ),
    [quickPays],
  );

  const Rejected = useMemo(
    () => (
      <QuickPayTable
        requests={getRequests(quickPays ?? [], QuickPayRequestStatus.REJECTED)}
        typeTable={QuickPayRequestStatus.REJECTED}
      />
    ),
    [quickPays],
  );

  const requestedTab = `${QuickPayRequestStatus.REQUESTED} (${requestedData.length})`;

  const tabList = [
    requestedTab,
    QuickPayRequestStatus.APPROVED,
    QuickPayRequestStatus.REJECTED,
  ];
  const tabPanels = [Requested, Approved, Rejected];

  const downloadTable = (): void => {
    const availableList = getFilteredTableData(quickPays ?? [], currentStatus);
    let mappedExportData;
    if (availableList.length !== 0) {
      if (currentStatus.includes(QuickPayRequestStatus.REQUESTED)) {
        mappedExportData = createQuickPayRequestedToExport(availableList);
      } else {
        mappedExportData = createQuickPayDecisionToExport(
          availableList,
          currentStatus,
        );
      }
      const csvFileUrl = createCsvFromObjectsArray(mappedExportData);
      exportFileResults(csvFileUrl, "quickpay.csv");
    }
  };

  return (
    <QuickPayContext.Provider
      value={{
        currentStatus,
        setCurrentStatus,
        selectedQuickPay,
        setSelectedQuickPay,
        term,
        quickPays,
        multiselectFilters,
        setMultiselectFilters,
        sortByCriteria,
        sortCriteria,
      }}
    >
      <Skeleton isLoaded={!isLoading} bgColor="#F6F6F6" w="100%" h="100%">
        <VStack my={6}>
          <HStack
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            p="0 5rem 0 4.5rem"
          >
            <Header headingText="QuickPay Requests" />
            <DownloadOption downloadTable={downloadTable} />
          </HStack>
          <TabsContainer
            containerBg="white"
            tabList={tabList}
            tabPanels={tabPanels}
            tabPanelHeader={
              <TabAdditionalContent term={term} setTerm={setTerm} />
            }
            onClickTab={handleClickTab}
          />
        </VStack>
      </Skeleton>
    </QuickPayContext.Provider>
  );
};

export default QuickPay;
