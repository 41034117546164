import { Fragment } from "react";
import {
  VStack,
  HStack,
  Button,
  Input,
  Text,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

interface MarkDeliveredDocumentProps {
  title: string;
  proccesingDocument: boolean;
  name: string;
  valueDocument: string;
  reference?: React.RefObject<HTMLInputElement>;
  handleFileInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeDocument: () => void;
}

export const MarkDeliveredDocument = ({
  title,
  name,
  reference,
  proccesingDocument,
  valueDocument,
  handleFileInput,
  removeDocument,
}: MarkDeliveredDocumentProps): JSX.Element => {
  const SelectFileButton = (): JSX.Element => (
    <Button
      placeholder="SELECT FILE"
      bgColor="#F6D306"
      color="black"
      fontWeight={600}
      fontSize={15}
      w="175px"
      overflow="hidden"
      onClick={() => reference?.current?.click()}
    >
      SELECT FILE
    </Button>
  );

  const ManageFile = (): JSX.Element => (
    <VStack pos="relative">
      <Input readOnly value={valueDocument} textAlign="left" />
      <CloseIcon
        pos="absolute"
        right="0.8rem"
        top="0.4rem"
        fontSize="12px"
        cursor="pointer"
        onClick={removeDocument}
      />
    </VStack>
  );

  const HiddenInputFile = (): JSX.Element => (
    <Input
      name={name}
      type="file"
      hidden
      ref={reference}
      onChange={handleFileInput}
    />
  );

  return (
    <HStack w="100%" m="1.5rem 0" justifyContent="space-between">
      <VStack alignItems="flex-start">
        <Text>{title}</Text>
      </VStack>
      <VStack>
        {proccesingDocument ? (
          <Flex w="250px" alignItems="center" justifyContent="center">
            <Spinner color="mvmntRed" size="lg" />
          </Flex>
        ) : (
          <Fragment>
            {valueDocument === "" ? <SelectFileButton /> : <ManageFile />}
            <HiddenInputFile />
          </Fragment>
        )}
      </VStack>
    </HStack>
  );
};

export default MarkDeliveredDocument;
