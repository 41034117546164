import { EquipmentType } from "./Equipment";
import { LoadType } from "./ShipmentForm";
import { ShipperUserData } from "./Shipper";

export interface TabElementProps {
  id: number;
  name: string;
  types: string[];
}

export interface AbsoluteRightButtonsProps {
  children: JSX.Element | JSX.Element[];
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export interface HeaderProps {
  id: number;
  header: string;
}

interface MarketPair {
  origin: string;
  destination: string;
  majorCity: string;
}
export interface Proposal {
  id: string;
  name?: string;
  startDate?: Date;
  deadlineDate?: Date;
  lanes?: number;
  volume?: number;
  marketPairs?: MarketPair[];
  lanesBadge?: number;
  email: string;
  phone: string;
  username: string;
  website: string;
}

export interface ILaneStopCreate {
  city?: string;
  state?: string;
  postalCode?: string;
  loadType: LoadType;
}

export interface ILaneCreate {
  laneStops: ILaneStopCreate[];
  mileage?: number;
  volume?: string;
  frequency?: string;
  mode?: string;
  equipmentType: EquipmentType;
  equipmentSize?: string;
  notes?: string;
  additionalStops?: string;
  additionalParameters?: AdditionalParameter[];
  externalLaneId?: string;
}

export interface ILane extends ILaneCreate {
  id?: string;
  laneId?: number;
  laneBids?: ILaneBid[];
}

export interface LaneForm {
  origin: ILaneStopCreate;
  destination: ILaneStopCreate;
  additionalStops?: string;
  mileage: number;
  volume: string;
  frequency: string;
  mode: string;
  equipmentType: EquipmentType;
  equipmentSize: string;
  additionalParameters?: AdditionalParameter[];
  additionalNotes?: string;
  externalLaneId?: string;
}

export interface AdditionalParameter {
  label: string;
  value: string;
}

export enum RfpType {
  Annual = "Annual",
  Seasonal = "Seasonal",
}
export interface IRFPCreate {
  rfpName?: string;
  biddingStartDate: Date;
  biddingEndDate: Date;
  contractStartDate?: Date;
  contractEndDate?: Date;
  notes?: string;
  carrierIds: IRFPAddCarrier[];
  rfpType: RfpType;
}

export interface CarrierInformation {
  id: string;
  companyName: string;
  companyContactName: string;
  companyEmail?: string | null;
  companyPhoneNumber?: string | null;
  dotNumber: string;
  mcNumber: string;
}

export interface IRFPEdit extends IRFPCreate {
  rfpId: string;
}

export interface IRFPAddCarrier {
  carrierId?: string | null;
  fmcsaCarrierId?: string;
  emailAddressForNotifications?: string;
  carrierName?: string;
  dotNumber?: string;
}

export interface IRfpDocument {
  id?: string;
  documentName: string;
  rfpId: string;
}

export interface IRfp {
  id: string;
  lanes?: ILane[];
  shipper?: ShipperUserData;
  shipperId: string;
  totalVolume?: number;
  countOfKeyMarketPairs?: number;
  rfpName: string;
  biddingStartDate: Date;
  biddingEndDate: Date;
  contractStartDate?: Date;
  contractEndDate?: Date;
  notes?: string;
  carriersInfo: CarrierInformation[];
  rfpType: RfpType;
  rfpDocuments?: IRfpDocument[];
}

export interface CheckBoxCarrier {
  carrierId?: string;
  companyName: string;
  fmcsaCarrierId?: string;
}

export interface ILaneBidCreate {
  laneBidId?: string;
  laneId: string;
  ratePerMile: number;
  minimumBid: number;
  commitment: number;
}

export interface IRowBidProp {
  id?: string;
  carrierId?: string;
  carrierName?: string;
  carrierDOTNumber?: string;
  carrierMCNumber?: string;
  carrierEmailAddress?: string;
  minimumBid?: number;
  effectiveBid?: number;
  actualBid?: number;
  awardedVolume: string;
}

export interface LaneBidCreate {
  rfpId: string;
  laneBids: ILaneBidCreate[];
}

interface LaneBidCarrierDetails {
  carrierId: string;
  carrierName?: string;
  carrierDOTNumber?: string;
  carrierMCNumber?: string;
  carrierEmailAddress?: string;
}

export interface ILaneBid {
  id?: string;
  carrierInfo: LaneBidCarrierDetails;
  lane: ILane;
  ratePerMile: number;
  minimumBid: number;
  volumeCommitment: number;
  effectiveBid: number;
  actualBid: number;
  isSubmitted: boolean;
  isAwarded: boolean;
  awardedVolume?: string;
  updateDate: Date;
}

export interface RowLaneProps {
  id: string | number;
  laneBidId?: string | number;
  laneId?: string | number;
  origin?: string;
  pickupOrigin?: string;
  destiny?: string;
  pickupDestiny?: string;
  mileage?: number;
  volume?: string;
  frequency?: string;
  mode?: string;
  equipmentType?: string;
  equipmentSize?: string;
  length?: string;
  rpm?: string;
  minimum?: string;
  commitment?: string;
  bids?: string;
  low?: string;
}

export interface RowShipperLaneProps {
  id: string | number;
  laneBidId?: string | number;
  laneId?: string | number;
  origin?: string;
  pickupOrigin?: string;
  destiny?: string;
  pickupDestiny?: string;
  mileage?: number;
  volume?: string;
  frequency?: string;
  mode?: string;
  equipmentType?: string;
  equipmentSize?: string;
  length?: string;
  bids?: string;
  low?: string;
  laneCurrentBids?: ILaneBid[];
}
export interface LanesProps {
  lanes?: ILane[] | null;
  shipperType: boolean;
  selectedRfp: IRfp | null;
}

export enum RfpButtonsPosition {
  Infimum = "",
  Primary = "2rem",
  Secondary = "5rem",
}

export interface RfpCarrierBulkUpload {
  duplicated: string[];
  alreadyAdded: IRFPAddCarrier[];
  readyToUpdate: IRFPAddCarrier[];
  readyToAdd: IRFPAddCarrier[];
  misFormattedRows: number[];
}

export enum CarriersProcessType {
  AddNetwork = "ADDNETWORK",
  BulkUpload = "BULKUPLOAD",
}

export interface ModalCarriersProps {
  dotNumber: string;
  carrierName: string;
}
