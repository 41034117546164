import {
  Text,
  VStack,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { useState } from "react";
import { useGetLoadLocationsQuery } from "../../../../api/load";
import {
  getUniqueDatesInTimezone,
  parseToWeekdayMonthDate,
} from "../../../../reuse/Dates";
import { ILoadLocation } from "../../../../types/LoadLocation";
import AddLoadLocationModal from "./AddLoadLocationModal";
import LoadHistoryList from "./LoadHistoryList";

interface LoadDetailsHistoryTabProps {
  loadId: string;
}
export const LoadDetailsHistoryTab = ({
  loadId,
}: LoadDetailsHistoryTabProps): JSX.Element => {
  const todaysDate = new Date();
  const yesterdaysDate = new Date();
  yesterdaysDate.setDate(yesterdaysDate.getDate() - 1);

  const { data: loadLocations } = useGetLoadLocationsQuery({ loadId });
  const [showNewLocationModal, setShowNewLocationModal] =
    useState<boolean>(false);
  const [modalDefaultDate, setModalDefaultDate] = useState<Date>(todaysDate);

  const datesWithLocations = loadLocations?.map(
    (location) => new Date(location.timeStampTimeZone),
  );

  const uniqueDates = getUniqueDatesInTimezone(datesWithLocations ?? []);

  const todaysLocations: ILoadLocation[] =
    loadLocations?.filter((location) => {
      return (
        new Date(location.timeStampTimeZone).toLocaleDateString() ===
        todaysDate.toLocaleDateString()
      );
    }) ?? [];

  const renderLocationsForDate = (date: Date): JSX.Element => {
    let title = parseToWeekdayMonthDate(date);
    if (date.toLocaleDateString() === todaysDate.toLocaleDateString()) {
      title = "Today";
    } else if (
      date.toLocaleDateString() === yesterdaysDate.toLocaleDateString()
    ) {
      title = "Yesterday";
    }

    const dateLocations: ILoadLocation[] =
      loadLocations?.filter((location) => {
        return (
          new Date(location.timeStampTimeZone).toLocaleDateString() ===
          date.toLocaleDateString()
        );
      }) ?? [];

    return (
      <AccordionItem
        key={`history date accordion:${date.toLocaleDateString()}`}
      >
        <Text fontSize="15px" fontWeight={600}>
          <AccordionButton>
            <AccordionIcon mr={5} />
            <Text flex="1" textAlign="left">
              {title}
            </Text>
          </AccordionButton>
        </Text>
        <AccordionPanel pb={4}>
          <Link
            alignSelf="start"
            color="mvmntBlue"
            onClick={() => {
              setModalDefaultDate(date);
              setShowNewLocationModal(true);
            }}
          >
            <Text mb="5px">+ Add location update</Text>
          </Link>
          {<LoadHistoryList loadLocations={dateLocations} />}
        </AccordionPanel>
      </AccordionItem>
    );
  };

  return (
    <VStack
      px={0}
      py={1}
      alignItems="flex-start"
      overflowY="scroll"
      bg="realWhite"
    >
      {showNewLocationModal && (
        <AddLoadLocationModal
          loadId={loadId}
          onClose={() => setShowNewLocationModal(false)}
          isOpen={true}
          defaultDate={modalDefaultDate}
        />
      )}
      <Text as="h3" fontSize="20px" fontWeight="600" mb="">
        Location History
      </Text>
      <Accordion allowToggle w="100%">
        {!todaysLocations.length && (
          <AccordionItem>
            <Text fontSize="15px" fontWeight={600}>
              <AccordionButton>
                <AccordionIcon mr={5} />
                <Text flex="1" textAlign="left">
                  Today
                </Text>
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              <Link
                alignSelf="start"
                color="mvmntBlue"
                onClick={() => {
                  setModalDefaultDate(todaysDate);
                  setShowNewLocationModal(true);
                }}
              >
                + Add location update
              </Link>
            </AccordionPanel>
          </AccordionItem>
        )}
        {uniqueDates.map((date) => renderLocationsForDate(date))}
      </Accordion>
    </VStack>
  );
};

export default LoadDetailsHistoryTab;
