import { Thead, Tr } from "@chakra-ui/react";
import { CellHeader } from "./CellHeader";
import { HeaderProps } from "./constants";

export const HeadersLanesTable = ({
  data,
  backgroundColor,
}: {
  backgroundColor?: string;
  data: HeaderProps[];
}): JSX.Element => {
  return (
    <Thead backgroundColor={backgroundColor}>
      <Tr>
        {data.map(({ id, header, color }) => (
          <CellHeader key={id} data={header} color={color} />
        ))}
      </Tr>
    </Thead>
  );
};
