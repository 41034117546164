import { HStack, Text } from "@chakra-ui/react";
import { useState } from "react";
import SortButton from "../../SortButton";

export enum TrackingInfo {
  HIDE = "View Less",
  SHOW = "View More",
}

interface InfoButtonProps {
  showTrackingInfoButton: boolean;
  showTrackingInfo: boolean;
  setShowTrackingInfo: (toggle: boolean) => void;
}

const InfoButton = ({
  showTrackingInfoButton,
  showTrackingInfo,
  setShowTrackingInfo,
}: InfoButtonProps): JSX.Element => {
  const [showMore, setShowMore] = useState(true);
  const onClick = (): void => {
    setShowTrackingInfo(!showTrackingInfo);
    setShowMore(!showMore);
  };

  return showTrackingInfoButton ? (
    <HStack onClick={onClick}>
      <Text textStyle="card" fontSize="12px" color="mvmntRed">
        {showTrackingInfo ? TrackingInfo.HIDE : TrackingInfo.SHOW}
        <SortButton asc={showMore} />
      </Text>
    </HStack>
  ) : (
    <></>
  );
};

export default InfoButton;
