import {
  Button,
  FormControl,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { useProvideTrackingUpdateMutation } from "../../api/locationHistory";
import { useTrackingContext } from "../../pages/Tracking/TrackingContext";
import { Countries, states } from "../../resources/regions";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { DefaultModalProps } from "../../types/DefaultModal";
import { FormError } from "../../types/FormError";
import {
  BaseLocation,
  LocationHistoryCreate,
  LocationOptionalFields,
} from "../../types/Shipment";
import ErrorMessage from "../ErrorMessage";
import LocationAddressField from "../LocationAddressField";

interface LocationModalProps extends LocationOptionalFields, DefaultModalProps {
  onAfterSubmit: () => void;
}

const LocationFormModal = ({
  isOpen,
  onClose,
  onAfterSubmit,
}: LocationModalProps): JSX.Element => {
  const trackingContext = useTrackingContext();
  const { currentTrackingShipment } = trackingContext!;

  const toast = useToast();
  const [provideTrackingUpdate, { isLoading: isSubmiting }] =
    useProvideTrackingUpdateMutation();

  const formHook = useForm<BaseLocation>();

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    getValues,
    control,
  } = formHook;

  const onSubmitForm = async (): Promise<void> => {
    const { id: shipmentId } = currentTrackingShipment!;

    const location: Omit<LocationHistoryCreate, "id"> = {
      ...getValues(),
      shipmentId,
      timeStampTimeZone: new Date(),
    };

    const response = await provideTrackingUpdate(location);

    if ("data" in response) {
      toast({
        status: "success",
        description: "Location Update Submitted.",
        duration: 3000,
      });
      onAfterSubmit();
    } else {
      toast({
        status: "error",
        description: parseErrorResponse(response.error),
        duration: 3000,
      });
    }
  };

  const onSubmit = handleSubmit(onSubmitForm);

  const stateOptions = states[Countries.US] ?? [];
  useWatch({ control });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="uppercase">Provide Tracking Update</ModalHeader>
        <form onSubmit={onSubmit}>
          <ModalHeader textStyle="uppercase" fontSize={14}>
            Current Location
          </ModalHeader>
          <ModalBody>
            <HStack alignItems="baseline">
              <VStack>
                <FormControl m="0.4rem 0" isDisabled={isSubmiting}>
                  <Input
                    w="100%"
                    h="75%"
                    {...register("city", {
                      required: {
                        value: !getValues("postalCode"),
                        message: "You must enter city and state or postal code",
                      },
                    })}
                    placeholder="City"
                  />
                </FormControl>
                <Select
                  isDisabled={isSubmiting}
                  w="100%"
                  h="75%"
                  placeholder="State"
                  {...register("state", {
                    required: {
                      value: !getValues("postalCode"),
                      message: "You must enter city and state or postal code",
                    },
                  })}
                >
                  <option value="">Select State</option>
                  {stateOptions.map((state) => (
                    <option key={state.name} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </Select>
              </VStack>
              <VStack>
                <ModalHeader textStyle="uppercase" fontSize={14}>
                  OR
                </ModalHeader>
              </VStack>
              <VStack w="60%">
                <FormControl>
                  <LocationAddressField
                    formHook={formHook}
                    isReadOnly={isSubmiting}
                  />
                </FormControl>
              </VStack>
            </HStack>
          </ModalBody>
          <ModalFooter justifyContent="space-around">
            <Button size="sm" color="mvmntRed" variant="link" onClick={onClose}>
              Cancel
            </Button>
            <Button layerStyle="yellow" onClick={onSubmit}>
              Update
            </Button>
          </ModalFooter>
          <ErrorMessage
            errors={errors as FormError}
            clearErrors={clearErrors}
          />
        </form>
      </ModalContent>
    </Modal>
  );
};

export default LocationFormModal;
