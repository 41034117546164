import { useState } from "react";
import { Skeleton, VStack, HStack, Heading, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PartnerCard from "../PartnerCard.tsx";
import AppRoutes from "../../routes";
import ShipperPartnerCard from "../ShipperPartnerCard";
import { NetworkPartner } from "../../types/NetworkPartner";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { UserType } from "../../types/User";
import { useQueryState } from "../../api/carrier";
import SortButton from "../SortButton";
import PartnerPreviewCard from "../PartnerPreviewCard";
import { getNetworkInvite } from "../../reuse/Mail";

interface NetworkPartnersProps {
  partners: NetworkPartner[];
  selectedPartner: NetworkPartner | undefined;
  toggleAddModal: (isOpen: boolean) => void;
  onSelectPartner: (partner: NetworkPartner) => void;
  sortPartners: (asc: boolean) => void;
}

export const NetworkPartners = ({
  partners,
  toggleAddModal,
  onSelectPartner,
  sortPartners,
  selectedPartner,
}: NetworkPartnersProps): JSX.Element => {
  const [sortAsc, setSortAsc] = useState(true);
  const { type } = useAppSelector(selectUserData);
  const carrierUser =
    type === UserType.CARRIER ? useQueryState().data : undefined;
  const navigate = useNavigate();

  const onClickFindCarriers = (): void => {
    navigate(AppRoutes.SOURCING_SEARCH);
  };

  const onClickInvite = (): void => {
    const { subject, body } = getNetworkInvite(carrierUser!.companyName);
    window.open(`mailto:?subject=${subject}&body=${body}`);
  };

  const onClickSort = (): void => {
    setSortAsc(!sortAsc);
    sortPartners(!sortAsc);
  };

  return (
    <Skeleton minW="20%" h="100%" isLoaded overflowY="auto">
      <VStack bgColor="white !important" h="100%" spacing="1vh">
        <HStack
          layerStyle="yellow"
          minH="32px"
          w="100%"
          justifyContent="start"
          px="15px"
        >
          <Heading size="md" textStyle="uppercase">
            Network Partners
          </Heading>
          <SortButton sort={onClickSort} asc={sortAsc} />
          <Heading ml="auto !important" size="md">
            {partners.length}
          </Heading>
        </HStack>
        <VStack
          mt="0 !important"
          alignItems="start"
          w="100%"
          h="calc(100% - 150px)"
          overflowY="auto"
          spacing={0}
        >
          {partners.map((partner) =>
            partner.shipper ? (
              <PartnerCard
                key={partner.id}
                onClick={() => onSelectPartner(partner)}
                shipper={partner.shipper}
                isSelected={selectedPartner?.shipper?.id === partner.shipper.id}
              />
            ) : partner.carrier ? (
              <ShipperPartnerCard
                key={partner.id}
                onClick={() => onSelectPartner(partner)}
                carrier={partner.carrier}
                isSelected={selectedPartner?.carrier?.id === partner.carrier.id}
              />
            ) : partner.fmcsaCarrier ? (
              <PartnerPreviewCard
                key={partner.id}
                onClick={() => onSelectPartner(partner)}
                fmcsaCarrier={partner.fmcsaCarrier}
                isSelected={
                  selectedPartner?.fmcsaCarrier?.id === partner.fmcsaCarrier.id
                }
              />
            ) : null,
          )}
        </VStack>
        <VStack minH="100px" w="100%" py="1rem" spacing={3}>
          {(type === UserType.SHIPPER || type === UserType.BROKER) && (
            <VStack alignItems="center" w="100%" spacing={3}>
              <Button
                layerStyle="red"
                size="small"
                w="90%"
                onClick={onClickFindCarriers}
              >
                Find Carriers
              </Button>
              <Button
                layerStyle="yellow"
                w="90%"
                size="small"
                onClick={() => toggleAddModal(true)}
              >
                Add Network Partner
              </Button>
            </VStack>
          )}
          {type === UserType.CARRIER && (
            <Button
              layerStyle="yellow"
              size="xSmall"
              w="90%"
              onClick={onClickInvite}
            >
              Invite Shipper/Broker
            </Button>
          )}
        </VStack>
      </VStack>
    </Skeleton>
  );
};

export default NetworkPartners;
