import { Skeleton } from "@chakra-ui/react";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useGetShipperQuery } from "../../api/shipper";
import { shipperDefaultView } from "../../reuse/Shipper";
import AppRoutes from "../../routes";
import ShipmentCreate from "../ShipmentCreate";
import ShipmentSelect from "../ShipmentSelect";
import ShipperAccount from "../ShipperAccount";
import ShipperDocuments from "../ShipperDocuments";
import ShipperNetwork from "../ShipperNetwork";
import SourcingSearch from "../SourcingSearch";
import ShipperRFP from "../ShipperRFP";
import ShipperActiveQuoteRequests from "../ShipperActiveQuoteRequests";
import { useGetNetworkCarriersQuery } from "../../api/network";
import ComingSoon from "../../components/ComingSoon";
import PastShipment from "../PastShipment";
import Tracking from "../Tracking";
import { AccountsPayable } from "../AccountsPayable/AccountsPayable";
import { useCrispChatbotHook } from "../../components/useCrispChatbotHook";

const showRfpPage = process.env.REACT_APP_SHOW_RFP_PAGE === "true";
const showTrackingPage = process.env.REACT_APP_SHOW_TRACKING_PAGE === "true";
// TODO: use these when adding payment page
const showPaymentsPage = process.env.REACT_APP_SHOW_PAYMENTS_PAGE === "true";

export const CommonShipperRoutes = (): JSX.Element => (
  <Routes>
    <Route path={AppRoutes.ACCOUNT_DETAILS} element={<ShipperAccount />} />
    <Route path={AppRoutes.MANAGE_DOCUMENTS} element={<ShipperDocuments />} />
    <Route path={AppRoutes.MANAGE_NETWORK} element={<ShipperNetwork />} />
    <Route path={AppRoutes.SOURCING_SEARCH} element={<SourcingSearch />} />
    <Route path={AppRoutes.LOAD_BUILD} element={<ShipmentSelect />} />
    <Route path={AppRoutes.CREATE_LOAD} element={<ShipmentCreate />} />
    <Route
      path={AppRoutes.ACTIVE_QUOTES}
      element={<ShipperActiveQuoteRequests />}
    />
    <Route
      path={AppRoutes.ACTIVE_RFP}
      element={showRfpPage ? <ShipperRFP /> : <ComingSoon />}
    />
    <Route
      path={AppRoutes.TRACKING}
      element={showTrackingPage ? <Tracking /> : <ComingSoon />}
    />
    <Route path={AppRoutes.PAST} element={<PastShipment />} />
    <Route
      path={AppRoutes.ACCOUNTS_PAYABLE}
      element={showPaymentsPage ? <AccountsPayable /> : <ComingSoon />}
    />
    <Route path="/*" element={<ComingSoon />} />
  </Routes>
);

export const ShipperShell = (): JSX.Element => {
  useGetNetworkCarriersQuery();
  const { isLoading } = useGetShipperQuery();
  const location = useLocation();
  const navigate = useNavigate();
  useCrispChatbotHook();

  useEffect(() => {
    if (!shipperDefaultView(AppRoutes, location.pathname)) {
      navigate(AppRoutes.ACTIVE_QUOTES);
    }
  }, []);

  return (
    <Skeleton isLoaded={!isLoading} w="100%" h="100%" d="flex" flexDir="column">
      <CommonShipperRoutes />
    </Skeleton>
  );
};

export default ShipperShell;
