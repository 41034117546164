import { Bid, BidStatus } from "../types/Bid";
import { ConnectionStatus } from "../types/NetworkPartner";
import { Shipment } from "../types/Shipment";

export const aBidExists = (bids?: Bid[]): boolean => !!bids && bids.length > 0;

export const bidIsPending = (quote: Shipment, bids?: Bid[]): boolean =>
  aBidExists(bids) && quote.tendered && !bidWasCompleted(bids);

export const bidWasSubmitted = (quote: Shipment, bids?: Bid[]): boolean =>
  aBidExists(bids) && (!quote.tendered || bidWasCompleted(bids));

export const quoteIsNew = (bids?: Bid[]): boolean => !aBidExists(bids);

export const bidCanBeUpdated = (quote: Shipment, bids?: Bid[]): boolean =>
  bidWasSubmitted(quote, bids) && bids?.[0].status === BidStatus.Pending;

export const bidCanBeAccepted = (quote: Shipment, bids?: Bid[]): boolean =>
  bidIsPending(quote, bids);

export const bidWasAccepted = (bids?: Bid[]): boolean =>
  aBidExists(bids) && bids?.[0].status === BidStatus.Accepted;

export const bidWasRejected = (bids?: Bid[]): boolean =>
  aBidExists(bids) && bids?.[0].status === BidStatus.Rejected;

export const bidWasCompleted = (bids?: Bid[]): boolean =>
  bidWasAccepted(bids) || bidWasRejected(bids);

export const isRegularBid = (b: Bid): boolean =>
  b.connectionStatus !== undefined &&
  [ConnectionStatus.CONNECTED, ConnectionStatus.SIGNEDOFFLINEUPLOADED].includes(
    b.connectionStatus,
  );
