import { useState, useRef } from "react";
import { VStack, HStack, Button, Input } from "@chakra-ui/react";
import { OneMegaByte } from "../../reuse/Files";
import { ShipmentDocumentType } from "../../types/Files";
import { CloseIcon } from "@chakra-ui/icons";
import {
  useUploadDriverDocumentMutation,
  useUploadS3DriverFileMutation,
} from "../../api/documents";
import useToastHook from "../useToastHook";
import { parseErrorResponse } from "../../reuse/ErrorHandler";

const initialFileData = { otherDoc: "" };
interface OtherDocumentFileProps {
  id: number;
  shipmentId: string | undefined;
  removeDoc: (id: number) => void;
}

export const OtherDocumentFile = ({
  id,
  shipmentId,
  removeDoc,
}: OtherDocumentFileProps): JSX.Element => {
  const toast = useToastHook();
  const [
    uploadDoc,
    { isLoading: isUploading, isSuccess: isSusccessUploading },
  ] = useUploadDriverDocumentMutation();
  const [
    uploadS3Doc,
    { isLoading: isUploadingS3, isSuccess: isSusccessUploadingS3 },
  ] = useUploadS3DriverFileMutation();

  const reference = useRef<HTMLInputElement | null>(null);
  const [fileData, setFileData] = useState(initialFileData);
  const [description, setDescription] = useState<string>("");

  const removeDocument = (): void => {
    setDescription("");
    removeDoc(id);
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files![0];
    const { name, type, size } = file;
    if (size >= 100 * OneMegaByte) {
      alert("File size is too large.");
      return;
    }

    const documentUpload = await uploadDoc({
      id: shipmentId!,
      fileName: name,
      contentType: type,
      documentType: ShipmentDocumentType.OtherDoc,
      customLabel: description,
    });
    if ("data" in documentUpload) {
      await uploadS3Doc({ url: documentUpload.data, file });
      setFileData({ ...fileData, [event.target.name]: name });
      toast.success({
        description: "Document uploaded successfully !",
      });
    } else {
      toast.error({
        description: parseErrorResponse(documentUpload.error),
      });
    }
  };

  return (
    <HStack w="100%" justifyContent="space-between" my="1rem">
      <Input
        w="225px"
        type="text"
        placeholder="Description file"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        isDisabled={isSusccessUploading && isSusccessUploadingS3}
      />
      <HStack>
        {fileData.otherDoc === "" ? (
          <Button
            bgColor="#F6D306"
            color="black"
            fontWeight={600}
            fontSize={15}
            w="175px"
            overflow="hidden"
            isDisabled={description === "" || isUploading || isUploadingS3}
            onClick={() => reference?.current?.click()}
          >
            SELECT DOC FILE
          </Button>
        ) : (
          <VStack pos="relative">
            <Input readOnly value={fileData.otherDoc} textAlign="left" />
            <CloseIcon
              pos="absolute"
              right="0.8rem"
              top="0.4rem"
              fontSize="12px"
              cursor="pointer"
              onClick={removeDocument}
            />
          </VStack>
        )}
        <Input
          name="otherDoc"
          type="file"
          hidden
          ref={reference}
          onChange={handleFileInput}
        />
      </HStack>
    </HStack>
  );
};

export default OtherDocumentFile;
