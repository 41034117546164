import {
  DataTable,
  DataTableMultiselectFilter,
  useDataTable,
} from "../../ui/components/DataTable";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { DownloadIcon } from "../../ui/icons";
import {
  Flex,
  HStack,
  Divider,
  Select,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Text,
  Link,
  Tooltip,
  VStack,
  Tag,
} from "@chakra-ui/react";
import { pluralize, toTitleCase } from "../../reuse/Strings";
import { ShipperInvoice } from "../../api/shipper";
import { useState } from "react";
import { Countries } from "../../resources/regions";
import { getStateEnumFromStringAndCountryEnum as getStateAbbrev } from "../../reuse/RegionKeys";
import { parseDateToShortDate } from "../../reuse/Dates";
import { parseValueToMoney } from "../../reuse/Money";
import { useLazyGetShipmentDocsUrlsByShipmentIdQuery } from "../../api/documents";
import { downloadFiles } from "../../reuse/Files";

const columnHelper = createColumnHelper<ShipperInvoice>();

export function InvoicesTable({
  invoices,
}: {
  invoices: ShipperInvoice[];
}): JSX.Element {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([
    { id: "dueDate", desc: false },
  ]);
  const table = useDataTable({
    data: invoices,
    state: {
      globalFilter,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    columns: [
      columnHelper.accessor(
        (cell) => cell.shipment.mvmntShipmentId.toString().padStart(3, "0"),
        {
          header: "Load ID",
          id: "loadId",
          cell: (cell) => <Text color="mvmntBlue">#{cell.getValue()}</Text>,
        },
      ),
      columnHelper.accessor(
        "shipment.shipmentStops.destination.receivingName",
        {
          header: "Customer",
          id: "customer",
          filterFn: "arrIncludesSome",
          sortingFn: "alphanumeric",
        },
      ),
      columnHelper.accessor(
        (cell) => {
          const origin = cell.shipment.shipmentStops.origin;
          const city = toTitleCase(origin.addressCity);
          const stateAbbrev = getStateAbbrev(
            origin.addressState,
            toTitleCase(origin.addressCountry) as Countries,
          )?.abbreviation;
          return `${city}, ${stateAbbrev}`;
        },
        {
          header: "Origin",
          id: "origin",
          filterFn: "arrIncludesSome",
        },
      ),
      columnHelper.accessor(
        (cell) => {
          const destination = cell.shipment.shipmentStops.destination;
          const city = toTitleCase(destination.addressCity);
          const stateAbbrev = getStateAbbrev(
            destination.addressState,
            toTitleCase(destination.addressCountry) as Countries,
          )?.abbreviation;
          return `${city}, ${stateAbbrev}`;
        },
        {
          header: "Destination",
          id: "destination",
          filterFn: "arrIncludesSome",
        },
      ),
      columnHelper.accessor("dueDate", {
        header: "Due date",
        cell: (cell) => {
          const dateString = cell.getValue();
          if (!dateString) return "N/A";
          return (
            <>
              {parseDateToShortDate(new Date(dateString))}
              {new Date(dateString) < new Date() && (
                <Tag
                  size="sm"
                  color="pink"
                  bgColor="pinkOpacity15"
                  ml="10px"
                  fontWeight="bold"
                  borderRadius="12px"
                  padding="4px 8px"
                >
                  Overdue
                </Tag>
              )}
            </>
          );
        },
        id: "dueDate",
        filterFn: "arrIncludesSome",
        sortingFn: "datetime",
      }),
      columnHelper.accessor(
        (cell) =>
          Number(
            cell?.directPaymentRequest
              ? cell.directPaymentRequest.amount
              : cell.shipment.winningBid.totalAmount,
          ),
        {
          header: "Amount",
          cell: (cell) => {
            return (
              <>
                {cell.getValue() !== null
                  ? parseValueToMoney(Number(cell.getValue()))
                  : null}
              </>
            );
          },
        },
      ),
      columnHelper.accessor("payee", {
        header: "Payee",
        id: "payee",
        filterFn: "arrIncludesSome",
      }),
      columnHelper.accessor("shipment.id", {
        header: "",
        id: "downloadDocuments",
        enableSorting: false,
        cell: (cell) => {
          return (
            <Tooltip
              hasArrow
              label={
                <VStack spacing="0">
                  <Text>Download</Text> <Text>attachments</Text>
                </VStack>
              }
              placement="top"
            >
              <Link
                float="right"
                onClick={async () => await downloadAttachments(cell.getValue())}
              >
                <DownloadIcon />
              </Link>
            </Tooltip>
          );
        },
      }),
    ],
  });

  const [getShipmentDocsUrls] = useLazyGetShipmentDocsUrlsByShipmentIdQuery();
  async function downloadAttachments(shipperId: string): Promise<void> {
    const { data: attachmentUrls } = await getShipmentDocsUrls(shipperId);
    if (!attachmentUrls?.length) return;
    void downloadFiles(attachmentUrls);
  }

  return (
    <>
      <Flex
        alignItems="center"
        flexWrap="wrap"
        bgColor="grey6"
        marginTop="20px"
        marginBottom="20px"
        padding="8px 8px 8px 20px"
        fontFamily="Montserrat Regular"
        fontSize="13px"
      >
        <HStack height="20px" spacing="13px">
          <Text>
            Showing{" "}
            {pluralize("invoice", table.getPrePaginationRowModel().rows.length)}
          </Text>
          <Divider orientation="vertical" color="gray30" />
          <Text>Sort by: </Text>
          <Select
            value={table.getState().sorting[0]?.id}
            onChange={(event) =>
              table.setSorting([{ id: event.currentTarget.value, desc: false }])
            }
            variant="unstyled"
            bgColor="none"
            fontSize="13px"
            textAlign="left"
            fontFamily="Montserrat Bold"
            width="fit-content"
          >
            {table
              .getAllColumns()
              .filter((col) => col.getCanSort())
              .map((col) => {
                return (
                  <option value={col.id} key={col.id}>
                    {col.columnDef.header}
                  </option>
                );
              })}
          </Select>
        </HStack>

        <InputGroup marginLeft="auto" width="auto">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray30" />
          </InputLeftElement>
          {table.getState().globalFilter && (
            <InputRightElement
              cursor="pointer"
              onClick={() => table.resetGlobalFilter()}
            >
              <CloseIcon color="gray30" />
            </InputRightElement>
          )}
          <Input
            onChange={(event) => setGlobalFilter(event.target.value)}
            value={table.getState().globalFilter || ""}
            placeholder="Search"
            width="480px"
            borderRadius="4px"
            borderColor="grey5"
            bgColor="#fff"
          />
        </InputGroup>
      </Flex>
      <HStack marginBottom="10px">
        <DataTableMultiselectFilter table={table} columnId="origin" />
        <DataTableMultiselectFilter table={table} columnId="destination" />
        <DataTableMultiselectFilter table={table} columnId="customer" />
        <DataTableMultiselectFilter
          table={table}
          columnId="dueDate"
          labelFormatter={(label) => {
            if (!label || label === "N/A") return "N/A";
            return parseDateToShortDate(new Date(label));
          }}
        />
        <DataTableMultiselectFilter table={table} columnId="payee" />
      </HStack>
      <DataTable table={table} />
    </>
  );
}
