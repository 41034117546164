import { createContext, useContext } from "react";
import { CarrierSearchResult } from "../../types/Carrier";

export interface MapLocations {
  pickup: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
}
interface SourcingResult {
  searchResults: CarrierSearchResult[];
  setSearchResults: (results: CarrierSearchResult[]) => void;
  totalCount: number;
  setTotalCount: (totalCount: number) => void;
  locations?: MapLocations;
  setLocations: (locations: MapLocations) => void;
  highlightedCarrier: CarrierSearchResult | undefined;
  setHighlightedCarrier: (carrier?: CarrierSearchResult) => void;
  highlightedKma: string | undefined;
  setHighlightedKma: (kma?: string) => void;
  offset: number;
  setOffset: (offset: number) => void;
}

const SourcingSearchContext = createContext<SourcingResult | null>(null);

const useSourcingSearchContext = (): SourcingResult | null =>
  useContext(SourcingSearchContext);

export { SourcingSearchContext, useSourcingSearchContext };
