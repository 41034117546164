import { Text, HStack } from "@chakra-ui/react";
import { useManageUserContext } from "../ManageUserContext";

export const ShipperDetailsBreadcrumbs = (): JSX.Element => {
  const context = useManageUserContext();
  const { selectedShipper, setSelectedShipper } = context!;
  return (
    <HStack>
      <Text
        cursor="pointer"
        as="u"
        color={"grey3"}
        onClick={() => setSelectedShipper(undefined)}
      >
        Manage Users
      </Text>
      <Text color={"grey5"}> / </Text>
      <Text color={"grey2"}>
        {selectedShipper?.contactInformation.firstName}{" "}
        {selectedShipper?.contactInformation.lastName}
      </Text>
    </HStack>
  );
};

export default ShipperDetailsBreadcrumbs;
