import { useState } from "react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  HStack,
  Input,
  Select,
  Text,
  FormControl,
  FormLabel,
  CheckboxGroup,
  Checkbox,
  Flex,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import {
  AccesorialsDistribution,
  CustomAccessorials,
} from "../../types/ShipmentForm";
import { EquipmentType } from "../../types/Equipment";
import useToastHook from "../../components/useToastHook";
import { borderStyling } from "../../reuse/Forms";
import { sortTextElements } from "../../reuse/Sorting";
import { defaultAccessorials, extraAccessorials } from "./options";
import { ShipmentCreate } from "../../types/Shipment";

interface ShipmentEquipmentsFormProps {
  formHook: UseFormReturn<ShipmentCreate>;
  accessorialsHook: UseFormReturn<CustomAccessorials>;
  isReadOnly: boolean;
  customAccessorialsFieldArray: UseFieldArrayReturn<
    CustomAccessorials,
    "accessorials",
    "id"
  >;
}

export const ShipmentEquipmentsForm = ({
  formHook,
  accessorialsHook,
  isReadOnly,
  customAccessorialsFieldArray,
}: ShipmentEquipmentsFormProps): JSX.Element => {
  const [isViewMore, setViewMore] = useState(false);
  const {
    register,
    setValue,
    formState: { errors },
  } = formHook;
  const { register: accRegister } = accessorialsHook;
  const { fields, append, remove } = customAccessorialsFieldArray;

  const toast = useToastHook();

  const onAddAccessorial = (): void => {
    if (fields.length < 10) {
      append({});
    } else {
      toast.error({
        description: "You can add 10 custom accessorials at most per shipment",
      });
    }
  };

  const [showTemperature, setShowTemperature] = useState(false);

  const onChangeType = (t: EquipmentType): void =>
    t === EquipmentType.Refrigerated
      ? setShowTemperature(true)
      : setShowTemperature(false);

  const accessorials = isViewMore
    ? [...defaultAccessorials, ...extraAccessorials]
    : defaultAccessorials;

  const accessorialsDist = !isViewMore
    ? AccesorialsDistribution.extendedView
    : AccesorialsDistribution.defaultView;

  return (
    <>
      <Text textStyle="title">Equipment</Text>
      <HStack w="100%" alignItems="end">
        <FormControl w="40%">
          <FormLabel>Equipment Type</FormLabel>
          <Select
            {...register("equipmentType", {
              required: {
                value: true,
                message: "You must enter the equipment type",
              },
            })}
            disabled={isReadOnly}
            style={{ borderColor: borderStyling(errors.equipmentType) }}
            onChange={(e) => onChangeType(e.target.value as EquipmentType)}
          >
            {Object.values(EquipmentType).map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Select>
        </FormControl>
        <Input
          w="15%"
          type="number"
          placeholder="Length (ft)"
          {...register("length", {
            required: {
              value: false,
              message: "You must enter the length",
            },
            min: 0,
            valueAsNumber: true,
          })}
          disabled={isReadOnly}
        />
        <Input
          w="15%"
          type="number"
          placeholder="Width (ft)"
          {...register("width", {
            required: {
              value: false,
              message: "You must enter the width",
            },
            min: 0,
            valueAsNumber: true,
          })}
          disabled={isReadOnly}
        />
        <Input
          w="15%"
          type="number"
          placeholder="Height (ft)"
          {...register("height", {
            required: {
              value: false,
              message: "You must enter the height",
            },
            min: 0,
            valueAsNumber: true,
          })}
          disabled={isReadOnly}
        />
        <Input
          w="15%"
          type="number"
          placeholder="Weight (lbs)"
          {...register("weight", {
            required: {
              value: true,
              message: "You must enter the weight",
            },
            min: 0,
            valueAsNumber: true,
          })}
          disabled={isReadOnly}
          style={{ borderColor: borderStyling(errors.weight) }}
        />
      </HStack>
      {showTemperature && (
        <Input
          w="38%"
          type="number"
          placeholder="Temperature (°F)"
          {...register("refrigerationTemperature", {
            required: showTemperature,
            min: 0,
            valueAsNumber: true,
          })}
          style={
            showTemperature && {
              borderColor: borderStyling(errors.equipmentType),
            }
          }
        />
      )}
      <CheckboxGroup
        onChange={(v) => setValue("accessorials", v as string[])}
        isDisabled={isReadOnly}
      >
        <Flex display="grid" gridTemplateColumns={accessorialsDist} gap="10px">
          {sortTextElements(accessorials).map((accessorial) => (
            <Checkbox key={accessorial} mr="3%" value={accessorial}>
              {accessorial}
            </Checkbox>
          ))}
          {isViewMore && (
            <Button variant="link" color="mvmntRed" onClick={onAddAccessorial}>
              + Custom Accessorial
            </Button>
          )}
        </Flex>
      </CheckboxGroup>
      <Button
        variant="link"
        color="mvmntRed"
        onClick={() => setViewMore(!isViewMore)}
        disabled={isReadOnly}
      >
        {isViewMore ? "Hide" : "See"} More Accessorials
      </Button>
      {fields.map((accessorial, index) => (
        <HStack w="50%" key={accessorial.id}>
          <Input
            size="sm"
            {...accRegister(`accessorials.${index}.label` as const)}
          />
          <IconButton
            icon={<SmallCloseIcon />}
            aria-label="Remove Accessorial"
            onClick={() => remove(index)}
          />
        </HStack>
      ))}
    </>
  );
};

export default ShipmentEquipmentsForm;
