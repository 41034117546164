import { CargoType } from "../../types/CargoType";
import { CarrierOperation } from "../../types/CarrierOperation";
import { EntityType } from "../../types/EntityType";
import { OperationClass } from "../../types/OperationClass";

export const entityTypes = [
  { label: "Carrier", value: EntityType.Carrier },
  { label: "Shipper Only", value: EntityType.Shipper },
  { label: "Broker", value: EntityType.Broker },
  { label: "Registrant", value: EntityType.Registrant },
  { label: "Freight Forwarder", value: EntityType.FreightForwarder },
  { label: "Cargo Tank", value: EntityType.CargoTank },
  {
    label: "Intermodal Equipment Provider",
    value: EntityType.IntermodalEquipmentProvider,
  },
];

export const operationClass = [
  { label: "Authorized", value: OperationClass.Authorized },
  { label: "Exempt", value: OperationClass.Exempt },
  { label: "Private Property", value: OperationClass.PrivateProperty },
  {
    label: "Private Passenger Business",
    value: OperationClass.PrivateBusiness,
  },
  {
    label: "Private Passenger Non-Business",
    value: OperationClass.PrivateNonBusiness,
  },
  { label: "Migrant", value: OperationClass.Migrant },
  { label: "U.S. Mail", value: OperationClass.USMail },
  { label: "Federal Gov't", value: OperationClass.FederalGovt },
  { label: "State Gov't", value: OperationClass.StateGovt },
  { label: "Local Gov't", value: OperationClass.LocalGovt },
  { label: "Indian Tribe", value: OperationClass.IndianTribe },
  { label: "Other", value: OperationClass.Other },
];

export const carrierOperation = [
  { label: "Interstate Carrier", value: CarrierOperation.InterstateCarrier },
  {
    label: "Intrastate NonHaz Carrier",
    value: CarrierOperation.IntrastateNonhazCarrier,
  },
  {
    label: "Intrastate Hazmat Carrier",
    value: CarrierOperation.IntrastateHazmatCarrier,
  },
  { label: "Interstate Shipper", value: CarrierOperation.InterstateShipper },
  { label: "Intrastate Shipper", value: CarrierOperation.IntrastateShipper },
  { label: "Vehicle Registrant", value: CarrierOperation.VehicleRegistrant },
];

export const cargoCarried = [
  { label: "General Freight", value: CargoType.GeneralFreight },
  { label: "Household Goods", value: CargoType.HouseholdGoods },
  { label: "Metal: Sheets, Coils, Rolls", value: CargoType.Metals },
  { label: "Motor Vehicles", value: CargoType.Vehicles },
  { label: "Driveaway / Towaway", value: CargoType.Driveaway },
  { label: "Logs, Poles, Beams, Lumber", value: CargoType.Logs },
  { label: "Building Materials", value: CargoType.BuildingMaterial },
  { label: "Mobile Homes", value: CargoType.MobileHomes },
  { label: "Machinery, Large Objects", value: CargoType.Machinery },
  { label: "Fresh Produce", value: CargoType.FreshProduce },
  { label: "Liquids/Gases", value: CargoType.LiquidsAndGases },
  { label: "Intermodal Containers", value: CargoType.Containers },
  { label: "Passengers", value: CargoType.Passengers },
  { label: "Oilfield Equipment", value: CargoType.OilfieldEquipment },
  { label: "Livestock", value: CargoType.Livestock },
  { label: "Grain, Feed, Hay", value: CargoType.Grain },
  { label: "Coal/Coke", value: CargoType.CoalAndCoke },
  { label: "Meat", value: CargoType.Meat },
  { label: "Garbage, Refuse, Trash", value: CargoType.Garbage },
  { label: "U.S. Mail", value: CargoType.USMail },
  { label: "Chemicals", value: CargoType.Chemicals },
  { label: "Commodities Dry Bulk", value: CargoType.DryBulk },
  { label: "Refrigerated Food", value: CargoType.Food },
  { label: "Beverages", value: CargoType.Beverages },
  { label: "Paper Products", value: CargoType.Paper },
  { label: "Utility", value: CargoType.Utility },
  { label: "Farm Supplies", value: CargoType.FarmSupplies },
  { label: "Construction", value: CargoType.Construction },
  { label: "Water-Well", value: CargoType.WaterWell },
  { label: "Other", value: CargoType.Other },
  { label: "Other Defined", value: CargoType.OtherDefinied },
];
