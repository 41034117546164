import { createContext, useContext } from "react";
import { RfpFormMode } from "../../components/RfpModalForm/RfpModalForm";
import { IRfp } from "../../types/Rfp";

interface ActiveRfp {
  largerThan1620: boolean;
  selectedRfp: IRfp | undefined;
  setSelectedRfp: React.Dispatch<React.SetStateAction<IRfp | undefined>>;
  openRfpForm: boolean;
  toggleRfpForm: React.Dispatch<React.SetStateAction<boolean>>;
  formMode: RfpFormMode;
  setFormMode: React.Dispatch<React.SetStateAction<RfpFormMode>>;
}

const ActiveRfpContext = createContext<ActiveRfp | null>(null);

const useActiveRfpContext = (): ActiveRfp | null =>
  useContext(ActiveRfpContext);

export { ActiveRfpContext, useActiveRfpContext };
