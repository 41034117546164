import { cssVar } from "@chakra-ui/react";

export const mvmntTheme = {
  colors: {
    realWhite: "#FFFFFF",
    black: "#232323",
    white: "#FFF",
    blue: {
      100: "#F7FBFF",
      500: "#0A81FF",
    },
    yellow: "#F6D306",
    yellow40: "#F7DB37",
    red: { 500: "#ED3054" }, // override toast alert which uses red.500
    mvmntRed: "#ED3054",
    gray: {
      100: "#F6F6F6",
      200: "#D3D3D3",
      300: "#BDBDBD",
      400: "#A7A7A7",
      500: "#7B7B7B",
      600: "#656565",
      700: "#4E4E4E",
      800: "#383838",
      900: "#232323",
    },
    grey: "#EDEDED",
    grey1: "#232323",
    grey2: "#4E4E4E",
    grey3: "#7D7D7D",
    grey5: "#D3D3D3",
    grey6: "#F6F6F6",
    gray30: "#BDBDBD",
    gray50: "#7B7B7B",
    gray60: "#656565",
    gray70: "#4E4E4E",
    gray90: "#232323",
    mvmntGreen: "#1CAC78",
    greenOpacity15: "#1CAC7815",
    lightBlue: "#e1f0ff",
    successMedium: "#1BA774",
    successMediumBg: "#F3FBF8",
    pink: "#ED3054",
    pinkOpacity15: "#ED305415",
    selectedBlue: "#DCEDFF",
    title: "#707070",
    mvmntBlue: "#0A81FF",
    mvmntBlueBg: "#f3fbf8",
    alertsInfo: "#880AFF",
    alertsInfoOpacity15: "#880AFF15",
  },
  styles: {
    global: {
      body: {
        bg: "#fff",
        color: "gray.900",
      },
    },
  },
  fonts: {
    heading: "Montserrat Bold",
    body: "Montserrat Regular",
  },
  radii: {
    sm: "8px",
    md: "8px",
    lg: "16.5px",
  },
  textStyles: {
    uppercase: {
      textTransform: "uppercase",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
    },
    subTitle: {
      fontSize: "24px",
    },
    card: {
      color: "#707070",
    },
    label: {
      fontWeight: "bold",
      width: "30%",
    },
  },
  layerStyles: {
    shadow: {
      boxShadow: "0px 1px 0px #EDEDED",
    },
    cardShadow: {
      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    },
    red: {
      color: "white",
      bgColor: "mvmntRed",
    },
    inverseRed: {
      color: "mvmntRed",
      bgColor: "white",
    },
    yellow: {
      color: "black",
      bgColor: "yellow",
    },
    black: {
      color: "white",
      bgColor: "black",
    },
    blackAndWhite: {
      color: "white",
      bgColor: "black",
    },
    gray: {
      color: "black",
      bgColor: "grey6",
    },
  },
  components: {
    Heading: {
      variants: {
        montserrat: {
          fontFamily: "Montserrat Bold",
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontFamily: "Saira Body",
        fontWeight: "bold",
        fontSize: "11px",
        color: "gray.700",
      },
    },
    Tooltip: {
      baseStyle: {
        bg: "gray.900",
        color: "white",
        borderRadius: "4px",
        padding: "8px",
        [cssVar("popper-arrow-bg").variable]: "colors.gray.900",
        boxShadow: "none",
      },
    },
    Button: {
      variants: {
        solid: {
          /**
           * At the beginning of the project, colors.gray was overriden to be a string rather than a color palette.
           * Chakra expects it to be a palette and uses it as a palette (i.e. gray.200) to set hover states on buttons.
           * The result was invalid CSS being output, leading to there being no hover state in effect.
           * Many buttons were added under this assumption, so fixing this (by removing it) requires auditing every button.
           */
          _hover: {
            bg: "",
          },
          _active: {
            bg: "",
          },
        },
        black: {
          layerStyle: "black",
          color: "white",
        },
      },
      baseStyle: {
        borderRadius: "22px",
        fontFamily: "Montserrat Bold",
      },
      sizes: {
        xSmall: { fontSize: "8px", padding: "10px 24px" },
        small: { fontSize: "12px", padding: "8px 16px", lineHeight: "18px" },
        medium: { fontSize: "14px", padding: "12px 24px", lineHeight: "20px" },
        large: { fontSize: "16px", padding: "10px 24px" },
        larger: { fontSize: "18px", padding: "10px 24px" },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderColor: "mvmntRed",
          borderRadius: "2px",
        },
      },
    },
    Input: {
      baseStyle: {
        borderRadius: "4px",
        field: {
          borderRadius: "4px",
          bg: null,
          bgColor: "white",
          _placeholder: {
            color: "gray30",
          },
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          color: "black",
          bg: null,
          bgColor: "white",
          textAlign: "center",
        },
      },
    },
    Textarea: {
      baseStyle: {
        bg: null,
        bgColor: "white",
      },
    },
    Skeleton: {
      defaultProps: {
        startColor: "realWhite",
        endColor: "#A9A9A9",
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: "4px",
        },
        header: {
          borderBottom: "1px solid",
          borderColor: "grey5",
        },
        closeButton: {
          top: 4,
        },
        body: {
          paddingTop: "40px",
          paddingBottom: "40px",
        },
        footer: {
          borderTop: "1px solid",
          borderColor: "grey5",
        },
      },
    },
    Table: {
      variants: {
        simple: {
          parts: ["th", "td"],
          th: {
            color: "grey1",
            borderColor: "grey5",
            fontFamily: "Saira Body",
            paddingLeft: "10px",
            paddingRight: "10px",
          },
          td: {
            borderColor: "grey5",
            fontFamily: "Montserrat Regular",
            lineHeight: "inherit",
            paddingLeft: "10px",
            paddingRight: "10px",
          },
        },
      },
      sizes: {
        md: {
          td: {
            paddingTop: "10px",
            paddingBottom: "10px",
          },
        },
      },
    },
  },
};

export default mvmntTheme;
