import { DefaultModalProps } from "../../types/DefaultModal";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  HStack,
  Button,
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Th,
  Flex,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { ModalCarriersProps } from "../../types/Rfp";

interface ConfirmCarriersModalProps extends DefaultModalProps {
  carriersData: ModalCarriersProps[];
  accept: () => void;
  processing: boolean;
}

export const ConfirmCarriersModal = ({
  isOpen,
  onClose,
  accept,
  carriersData,
  processing,
}: ConfirmCarriersModalProps): JSX.Element => {
  const disableContent: boolean = !carriersData || carriersData?.length === 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"xl"} isCentered>
      <ModalOverlay />
      <ModalContent p="1rem">
        <ModalHeader textStyle="uppercase" fontSize="25px">
          CONFIRM CARRIERS
        </ModalHeader>
        <ModalBody pl="2rem">
          {disableContent ? (
            <Text w="100%" textAlign="center">
              Not network partners available
            </Text>
          ) : (
            <Table variant="unstyled">
              <Thead>
                <Tr>
                  <Th>DOT NUMBER</Th>
                  <Th>CARRIER NAME</Th>
                </Tr>
              </Thead>
              <Tbody>
                {carriersData?.map((element, i) => (
                  <Tr key={i}>
                    <Td>{element.dotNumber}</Td>
                    <Td>{element.carrierName}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </ModalBody>
        <ModalFooter mb="1rem" justifyContent="start">
          <HStack w="100%" justifyContent="space-between">
            {processing ? (
              <Flex w="100%" alignItems="center" justifyContent="center">
                <Spinner color="mvmntRed" size="lg" />
              </Flex>
            ) : (
              <Fragment>
                <Button layerStyle="black" color="white" onClick={onClose}>
                  GO BACK
                </Button>
                <Button
                  isDisabled={disableContent}
                  layerStyle="red"
                  color="white"
                  onClick={accept}
                >
                  ADD TO RFP
                </Button>
              </Fragment>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmCarriersModal;
