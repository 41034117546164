import { VStack, FormControl, Input, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { validateEmail, validatePhoneNumber } from "../../reuse/UserData";
import { ShipperAccountFormProps } from "../../types/ShipperAccountForm";
import { AccountsReceivable } from "../../types/ShipperAccountsReceivable";
import ErrorMessage from "../ErrorMessage";
import FormCountry from "../FormCountry";
import FormState from "../FormState";

export const ShipperAccountsReceivable = ({
  shipperUser,
  handleUpdate,
  isUpdating,
}: ShipperAccountFormProps): JSX.Element => {
  const formHook = useForm<AccountsReceivable>();

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
    watch,
  } = formHook;

  watch();

  useEffect(() => {
    if (shipperUser) {
      reset(shipperUser.accountsReceivable);
    }
  }, [shipperUser]);

  const onSubmit = handleSubmit(async (accountsPayable: AccountsReceivable) => {
    await handleUpdate({ accountsReceivable: accountsPayable });
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const untypedControl = control as Control<any>;

  return (
    <form onSubmit={onSubmit}>
      <VStack alignItems="center">
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Company Name"
            {...register("companyName", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Contact Name"
            {...register("contactName", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              required: false,
              validate: validatePhoneNumber,
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <PhoneInput
                placeholder="Phone Number"
                control={untypedControl}
                name={name}
                value={value}
                onChange={onChange}
                country="US"
              />
            )}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Email"
            {...register("email", {
              required: false,
              validate: validateEmail,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Address 1"
            {...register("address1", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Address 2"
            {...register("address2", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="City"
            {...register("city", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <FormState formHook={formHook} />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Postal Code"
            {...register("postalCode", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <FormCountry formHook={formHook} />
        </FormControl>

        <Button
          layerStyle="red"
          w="20%"
          onClick={onSubmit}
          isLoading={isUpdating}
        >
          Update
        </Button>

        <ErrorMessage errors={errors} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default ShipperAccountsReceivable;
