import { Fragment } from "react";
import {
  VStack,
  HStack,
  Button,
  Input,
  Text,
  Flex,
  Spinner,
} from "@chakra-ui/react";

interface DriverDocumentProps {
  title: string;
  optional?: string;
  proccesingDocument: boolean;
  name: string;
  value: string;
  reference?: React.RefObject<HTMLInputElement>;
  handleFileInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DriverDocument = ({
  title,
  optional,
  name,
  reference,
  proccesingDocument,
  value,
  handleFileInput,
}: DriverDocumentProps): JSX.Element => {
  return (
    <HStack w="100%" m="1.5rem 0" justifyContent="space-between">
      <VStack alignItems="flex-start">
        <Text>{title}</Text>
        <Text fontSize="12px">(OPTIONAL)</Text>
      </VStack>
      <VStack>
        {proccesingDocument ? (
          <Flex w="250px" alignItems="center" justifyContent="center">
            <Spinner color="mvmntRed" size="lg" />
          </Flex>
        ) : (
          <Fragment>
            <Button
              placeholder="SELECT FILE"
              bgColor="mvmntRed"
              color="white"
              fontWeight={600}
              fontSize={15}
              w="250px"
              maxW="250px"
              overflow="hidden"
              onClick={() => reference?.current?.click()}
            >
              {value === "" ? "SELECT FILE" : value}
            </Button>
            <Input
              name={name}
              type="file"
              hidden
              ref={reference}
              onChange={handleFileInput}
            />
          </Fragment>
        )}
      </VStack>
    </HStack>
  );
};

export default DriverDocument;
