import {
  VStack,
  IconButton,
  Image,
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useLocation, useNavigate, Location } from "react-router-dom";
import AppRoutes from "../../routes";
import RFPLogo from "../../resources/svg/new-rfp.svg";
import SourcingLogo from "../../resources/svg/new-search.svg";
import LoadsLogo from "../../resources/svg/new-loads.svg";
import NetworkLogo from "../../resources/svg/new-network.svg";
import InvoicesLogo from "../../resources/svg/new-invoices.svg";
import ReportLogo from "../../resources/svg/new-reporting.svg";
import PerformanceLogo from "../../resources/svg/new-performance.svg";
import AccountLogo from "../../resources/svg/new-account.svg";
import ManageUsers from "../../resources/svg/new-users.svg";
import MvmntSmallLogo from "../../resources/svg/new-logo-small.svg";
import MvmntLogo from "../../resources/svg/new-logo.svg";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { UserType } from "../../types/User";

const useRFPRedesign = process.env.REACT_APP_USE_REDESIGNED_RFP === "true";

interface SidebarButtonProps extends ButtonProps {
  isCollapsed: boolean;
  isActiveLink?: (location: Location) => boolean | undefined;
  icon: JSX.Element;
  title: string;
  route?: string[];
  options?: Array<{ route: string; title: string }>;
}

const ManageUsersIcon = (): JSX.Element => {
  return <Image src={ManageUsers} />;
};

const SourcingIcon = (): JSX.Element => {
  return <Image src={SourcingLogo} />;
};

const LoadsIcon = (): JSX.Element => {
  return <Image src={LoadsLogo} />;
};

const RFPIcon = (): JSX.Element => {
  return <Image src={RFPLogo} />;
};

const NetworkIcon = (): JSX.Element => {
  return <Image src={NetworkLogo} />;
};

const InvoicesIcon = (): JSX.Element => {
  return <Image src={InvoicesLogo} />;
};

const ReportIcon = (): JSX.Element => {
  return <Image src={ReportLogo} />;
};

const PerformanceIcon = (): JSX.Element => {
  return <Image src={PerformanceLogo} />;
};

const AccountIcon = (): JSX.Element => {
  return <Image src={AccountLogo} />;
};

const MvmntSmallIcon = (): JSX.Element => {
  return <Image src={MvmntSmallLogo} />;
};

const MvmntIcon = (): JSX.Element => {
  return <Image src={MvmntLogo} />;
};

const ExpandButton = ({
  title,
  icon,
  isCollapsed,
  route,
  ...props
}: SidebarButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const onClick = route ? () => navigate(route[0]) : undefined;

  return (
    <IconButton
      icon={icon}
      aria-label={title}
      bgColor="grey2"
      onClick={onClick}
      isRound={true}
      size="xs"
      position="absolute"
      left={isCollapsed ? "48px" : "188px"}
      top=".25vh"
      transition={isCollapsed ? "none" : "left .5s"}
      {...props}
    />
  );
};

const SidebarButton = ({
  title,
  icon,
  isCollapsed,
  isActiveLink,
  route,
  ...props
}: SidebarButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const onClick = route ? () => navigate(route[0]) : undefined;
  const isActive =
    isActiveLink?.(location) ?? route?.includes(location.pathname);
  const bgColor = isActive ? "grey2" : "transparent";
  const borderLeft = bgColor === "grey2" ? "3px solid yellow" : "transparent";
  const color = bgColor === "grey2" ? "white" : "grey5";

  return isCollapsed ? (
    <Tooltip
      hasArrow
      arrowSize={6}
      placement="right"
      label={title}
      bg={"grey1"}
      color="white"
      isDisabled={!isCollapsed}
    >
      <IconButton
        icon={icon}
        aria-label={title}
        bgColor={bgColor}
        w="100%"
        borderRadius="0px"
        textTransform="capitalize"
        borderLeft={borderLeft}
        onClick={onClick}
        {...props}
      />
    </Tooltip>
  ) : (
    <Button
      leftIcon={icon}
      color={color}
      w="100%"
      borderRadius="0px"
      textTransform="capitalize"
      justifyContent="start"
      bgColor={bgColor}
      borderLeft={borderLeft}
      onClick={onClick}
      {...props}
    >
      {title}
    </Button>
  );
};

const ExpandableSidebarButton = ({
  title,
  options,
  icon,
  isCollapsed,
  ...props
}: SidebarButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const bgColor = options?.find((option) => option.route === location.pathname)
    ? "grey2"
    : "transparent";
  const borderLeft = bgColor === "grey2" ? "3px solid yellow" : "transparent";
  const color = bgColor === "grey2" ? "white" : "grey5";

  return (
    <Menu placement="right">
      <Tooltip
        hasArrow
        placement="right"
        label={title}
        arrowSize={6}
        bg={"grey1"}
        color="white"
        isDisabled={!isCollapsed}
      >
        <MenuButton
          as={isCollapsed ? IconButton : Button}
          icon={isCollapsed ? icon : undefined}
          leftIcon={isCollapsed ? undefined : icon}
          bgColor={bgColor}
          color={color}
          textAlign="left"
          w="100%"
          borderRadius="0px"
          textTransform="capitalize"
          borderLeft={borderLeft}
          {...props}
        >
          {title}
        </MenuButton>
      </Tooltip>
      <MenuList bgColor="black">
        {options?.map((option) => (
          <MenuItem
            key={option.route}
            bgColor="black"
            _hover={{
              bgColor: "black",
            }}
            _focus={{
              bgColor: "black",
            }}
            onClick={() => navigate(option.route)}
            color="white"
            textTransform="uppercase"
          >
            {option.title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export const ShellSideBar = (): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { type } = useAppSelector(selectUserData);

  return (
    <VStack
      h="100%"
      flexShrink={0}
      flexBasis={isCollapsed ? "60px" : "200px"}
      bgColor="black"
      spacing="1vh"
      alignItems="center"
      overflowX="hidden"
      transition={isCollapsed ? "none" : "flex-basis .5s"}
    >
      <ExpandButton
        isCollapsed={isCollapsed}
        icon={
          isCollapsed ? (
            <ChevronRightIcon h={8} w={4} color="white" />
          ) : (
            <ChevronLeftIcon h={8} w={4} color="white" />
          )
        }
        title=""
        onClick={() => setIsCollapsed(!isCollapsed)}
      />

      <SidebarButton
        isCollapsed={isCollapsed}
        icon={isCollapsed ? <MvmntSmallIcon /> : <MvmntIcon />}
        title=""
        mt="1vh"
        bgColor="transparent"
      />

      {type === UserType.ADMIN && (
        <>
          <SidebarButton
            isCollapsed={isCollapsed}
            icon={<ManageUsersIcon />}
            title="Manage Users"
            route={[AppRoutes.MANAGE_USERS]}
          />
          <SidebarButton
            isCollapsed={isCollapsed}
            icon={<InvoicesIcon />}
            title="QuickPay Requests"
            route={[AppRoutes.QUICKPAY]}
          />
        </>
      )}

      {(type === UserType.SHIPPER || type === UserType.BROKER) && (
        <SidebarButton
          isCollapsed={isCollapsed}
          icon={<SourcingIcon />}
          title="Sourcing"
          route={[AppRoutes.SOURCING_SEARCH]}
        />
      )}

      {type === UserType.CARRIER ? (
        <ExpandableSidebarButton
          isCollapsed={isCollapsed}
          icon={<SourcingIcon />}
          title="Sourcing"
          options={[
            { title: "Marketplace", route: AppRoutes.SOURCING_SEARCH },
            {
              title: "Spot Quotes Received",
              route: AppRoutes.QUOTE_REQUESTS,
            },
          ]}
        />
      ) : (
        type !== UserType.ADMIN && (
          <SidebarButton
            isCollapsed={isCollapsed}
            icon={<LoadsIcon />}
            title="Load Board"
            route={[
              AppRoutes.ACTIVE_QUOTES,
              AppRoutes.LOAD_BUILD,
              AppRoutes.LOADS_BOARD,
              AppRoutes.CREATE_LOAD,
            ]}
          />
        )
      )}

      {type === UserType.CARRIER ? (
        <>
          {useRFPRedesign ? (
            <SidebarButton
              icon={<RFPIcon />}
              title="RFP"
              route={[AppRoutes.RFP]}
              isCollapsed={isCollapsed}
            />
          ) : (
            <ExpandableSidebarButton
              isCollapsed={isCollapsed}
              icon={<RFPIcon />}
              title="RFP"
              options={[
                { title: "Active RFPS", route: AppRoutes.ACTIVE_RFP },
                {
                  title: "RFP History",
                  route: AppRoutes.HISTORY_RFP,
                },
              ]}
            />
          )}
        </>
      ) : (
        type !== UserType.ADMIN && (
          <SidebarButton
            isCollapsed={isCollapsed}
            icon={<RFPIcon />}
            title="RFP Hosting"
            route={[AppRoutes.ACTIVE_RFP]}
          />
        )
      )}

      {type !== UserType.ADMIN && (
        <>
          <ExpandableSidebarButton
            isCollapsed={isCollapsed}
            icon={<PerformanceIcon />}
            title="Tracking"
            options={[
              { title: "Active Shipments", route: AppRoutes.TRACKING },
              {
                title: "Past Shipments",
                route: AppRoutes.PAST,
              },
            ]}
          />

          <SidebarButton
            isCollapsed={isCollapsed}
            icon={<InvoicesIcon />}
            title="Invoices"
            route={[
              type === UserType.CARRIER
                ? AppRoutes.INVOICES
                : AppRoutes.ACCOUNTS_PAYABLE,
            ]}
            isActiveLink={(location) => {
              if (type !== UserType.CARRIER) return;
              return location.pathname.startsWith(AppRoutes.INVOICES);
            }}
          />
          <SidebarButton
            isCollapsed={isCollapsed}
            icon={<NetworkIcon />}
            title="Network"
            route={[AppRoutes.MANAGE_NETWORK]}
          />
          <SidebarButton
            isCollapsed={isCollapsed}
            icon={<ReportIcon />}
            title="Reporting"
            route={[AppRoutes.REPORTING]}
          />

          <SidebarButton
            isCollapsed={isCollapsed}
            mt="auto !important"
            icon={<AccountIcon />}
            title="Account"
            route={[AppRoutes.ACCOUNT_DETAILS]}
          />
        </>
      )}
    </VStack>
  );
};

export default ShellSideBar;
