import {
  Skeleton,
  VStack,
  HStack,
  Heading,
  Button,
  Input,
  FormLabel,
  FormControl,
  Divider,
  Select,
  Box,
  ButtonGroup,
} from "@chakra-ui/react";
import { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import { useLazyGetShipmentsQuery } from "../../api/shipment";
import { EquipmentType } from "../../types/Equipment";
import { MarketPlaceSearch } from "../../types/MarketPlace";
import {
  defaultAccessorials,
  extraAccessorials,
} from "../ShipmentEquipmentsForm/options";
import { toMultiselectOptions, Option } from "../../reuse/MultiSelect";
import { getMinimumDate } from "../../reuse/Dates";
import { useMarketPlaceContext } from "../../pages/CarrierMarketPlace/MarketPlaceContext";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import useToastHook from "../../components/useToastHook";
import { sortText } from "../../reuse/Sorting";
import { zipCodes } from "../../resources/marketAreas";

const accessorials = [...defaultAccessorials, ...extraAccessorials];
const accessorialOptions = toMultiselectOptions(accessorials);

// this can be prob be refactored since its used in SourcingSearchForm too
enum SearchType {
  LaneSearch,
  AreaSearch,
}

export const SpotShipmentSearch = (): JSX.Element => {
  const [accessorials, setAccessorials] = useState<Option[]>([]);
  const [searchType, setSearchType] = useState(SearchType.LaneSearch);
  const [selectedKmaOrigin, setSelectedKmaOrigin] = useState<Option[]>([]);
  const [selectedKmaDestination, setSelectedKmaDestination] = useState<
    Option[]
  >([]);
  const [getShipments, { isFetching, data: shipments }] =
    useLazyGetShipmentsQuery();
  const { register, handleSubmit, setValue, getValues, watch } =
    useForm<MarketPlaceSearch>();
  const context = useMarketPlaceContext();
  const toast = useToastHook();

  useEffect(() => {
    if (shipments) {
      context?.setShipments(shipments);
    }
  }, [shipments]);

  const handleShipmentSearch = async (
    formData: MarketPlaceSearch,
  ): Promise<void> => {
    // for some reason after form submit useForm methods don't work so reseting fields manually here
    if (searchType === SearchType.AreaSearch) {
      formData.origin = "";
      formData.destination = "";
    } else {
      formData.kmaOrigin = "";
      formData.kmaDestination = "";
    }
    const response = await getShipments(formData);
    if ("error" in response && response.error) {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const onChangeAccessorials = (accessorials: Option[]): void => {
    setValue(
      "accessorials",
      accessorials.map((option) => option.value),
    );
    setAccessorials(accessorials);
  };

  const onSubmit = handleSubmit(handleShipmentSearch);

  const onChangeKma =
    (type: string) =>
    (values: Option[]): void => {
      const kma = values[values.length - 1];
      if (type === "origin") {
        setValue("kmaOrigin", kma ? kma.value : "");
        setSelectedKmaOrigin(kma ? [kma] : []);
      } else {
        setValue("kmaDestination", kma ? kma.value : "");
        setSelectedKmaDestination(kma ? [kma] : []);
      }
    };

  // this can be prob be refactored since its used in SourcingSearchForm too
  const kmaOptions = useMemo(
    (): Option[] =>
      Object.keys(zipCodes)
        .sort((a, b) => sortText(a, b, true))
        .map((kma) => ({ label: kma, value: kma })),
    [zipCodes],
  );

  register("equipmentType", { required: false });
  register("kmaOrigin", { required: false });
  register("kmaDestination", { required: false });

  watch("pickupDateEarliest");
  watch("pickupDateLatest");
  watch("deliveryDateEarliest");
  watch("deliveryDateLatest");

  return (
    <VStack
      minW="20%"
      bgColor="white !important"
      h="100%"
      spacing={0}
      overflowY="auto"
    >
      <HStack
        layerStyle="yellow"
        minH="32px"
        w="100%"
        justifyContent="space-between"
        px="15px"
        overflowX="hidden"
        whiteSpace="nowrap"
      >
        <Heading size="md" textStyle="uppercase">
          Spot Shipment Search
        </Heading>
      </HStack>
      <Skeleton h="100%" w="100%" isLoaded={!isFetching} py="1rem">
        <VStack minH="90%" justifyContent="space-between">
          <form>
            <VStack w="17vw" spacing="1rem">
              <ButtonGroup bgColor="realWhite" borderRadius="lg" border="1px">
                <Button
                  w="50%"
                  transition="background-color .5s"
                  bgColor={
                    searchType === SearchType.LaneSearch
                      ? "mvmntRed"
                      : "realWhite"
                  }
                  color={
                    searchType === SearchType.LaneSearch ? "white" : "black"
                  }
                  size="sm"
                  onClick={() => {
                    setSearchType(SearchType.LaneSearch);
                  }}
                >
                  Lane
                </Button>
                <Button
                  w="50%"
                  transition="background-color .5s"
                  bgColor={
                    searchType === SearchType.AreaSearch
                      ? "mvmntRed"
                      : "realWhite"
                  }
                  color={
                    searchType === SearchType.AreaSearch ? "white" : "black"
                  }
                  size="sm"
                  onClick={() => {
                    setSearchType(SearchType.AreaSearch);
                  }}
                >
                  Area
                </Button>
              </ButtonGroup>
              <FormControl>
                <FormLabel>
                  {searchType === SearchType.LaneSearch
                    ? "Lane"
                    : "Key Market Area"}
                </FormLabel>
                {searchType === SearchType.LaneSearch ? (
                  <VStack>
                    <Input
                      w="100%"
                      size="sm"
                      placeholder="Origin"
                      {...register("origin", { required: false })}
                    />
                    <Input
                      w="100%"
                      size="sm"
                      placeholder="Destination"
                      {...register("destination", { required: false })}
                    />
                  </VStack>
                ) : (
                  <VStack>
                    <Box w="100%" maxW="100%">
                      <MultiSelect
                        key="Origin Market area"
                        overrideStrings={{
                          selectSomeItems: "Origin",
                        }}
                        hasSelectAll={false}
                        value={selectedKmaOrigin}
                        onChange={onChangeKma("origin")}
                        options={kmaOptions}
                        labelledBy="Select"
                        className="small-dropdown"
                      />
                    </Box>
                    <Box w="100%" maxW="100%">
                      <MultiSelect
                        key="Destination Market area"
                        overrideStrings={{
                          selectSomeItems: "Destination",
                        }}
                        hasSelectAll={false}
                        value={selectedKmaDestination}
                        onChange={onChangeKma("destination")}
                        options={kmaOptions}
                        labelledBy="Select"
                        className="small-dropdown"
                      />
                    </Box>
                  </VStack>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Pickup</FormLabel>
                <DatePicker
                  placeholderText="Pickup Date Range"
                  minDate={new Date(getMinimumDate())}
                  selectsRange={true}
                  startDate={getValues("pickupDateEarliest")}
                  endDate={getValues("pickupDateLatest")}
                  onChange={(update) => {
                    setValue("pickupDateEarliest", update[0] ?? undefined);
                    setValue("pickupDateLatest", update[1] ?? undefined);
                  }}
                  isClearable={true}
                />
              </FormControl>
              <HStack w="100%" spacing="2%" alignItems="flex-end">
                <FormControl w="49%">
                  <Input
                    size="sm"
                    type="time"
                    placeholder="Pickup Time - Earlist"
                    {...register("pickupTimeEarliest", { required: false })}
                  />
                </FormControl>
                <FormControl w="49%">
                  <Input
                    size="sm"
                    type="time"
                    placeholder="Pickup Time - Latest"
                    {...register("pickupTimeLatest", { required: false })}
                  />
                </FormControl>
              </HStack>
              <FormControl>
                <FormLabel>Delivery</FormLabel>
                <DatePicker
                  placeholderText="Delivery Date Range"
                  minDate={new Date(getMinimumDate())}
                  selectsRange={true}
                  startDate={getValues("deliveryDateEarliest")}
                  endDate={getValues("deliveryDateLatest")}
                  onChange={(update) => {
                    setValue("deliveryDateEarliest", update[0] ?? undefined);
                    setValue("deliveryDateLatest", update[1] ?? undefined);
                  }}
                  isClearable={true}
                />
              </FormControl>
              <HStack w="100%" spacing="2%" alignItems="flex-end">
                <FormControl w="49%">
                  <Input
                    size="sm"
                    type="time"
                    placeholder="Delivery Time - Earlist"
                    {...register("deliveryTimeEarliest", { required: false })}
                  />
                </FormControl>
                <FormControl w="49%">
                  <Input
                    size="sm"
                    type="time"
                    placeholder="Delivery Time - Latest"
                    {...register("deliveryTimeLatest", { required: false })}
                  />
                </FormControl>
              </HStack>
              <Divider />
              <FormControl w="100%">
                <FormLabel>Equipment Type</FormLabel>
                <Select
                  size="sm"
                  {...register("equipmentType", { required: false })}
                >
                  <option value="">All</option>
                  {Object.values(EquipmentType).map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Box w="100%" maxW="100%">
                <MultiSelect
                  overrideStrings={{
                    selectSomeItems: "Accessorials",
                  }}
                  disableSearch
                  hasSelectAll={false}
                  value={accessorials}
                  options={accessorialOptions}
                  labelledBy="Select"
                  onChange={onChangeAccessorials}
                  className="small-dropdown"
                />
              </Box>
              <HStack w="100%" spacing="2%">
                <Input
                  w="49%"
                  size="sm"
                  type="number"
                  placeholder="Weight Max (lbs)"
                  {...register("weight", { required: false })}
                />
                <Input
                  w="49%"
                  size="sm"
                  type="number"
                  placeholder="Length (ft)"
                  {...register("length", { required: false })}
                />
              </HStack>
              <HStack w="100%" spacing="2%">
                <Input
                  w="49%"
                  size="sm"
                  type="number"
                  placeholder="Width (ft)"
                  {...register("width", { required: false })}
                />
                <Input
                  w="49%"
                  size="sm"
                  type="number"
                  placeholder="Height (ft)"
                  {...register("height", { required: false })}
                />
              </HStack>
            </VStack>
          </form>
          <Button
            size="large"
            minH="2rem"
            w="80%"
            layerStyle="yellow"
            onClick={onSubmit}
          >
            Search
          </Button>
        </VStack>
      </Skeleton>
    </VStack>
  );
};

export default SpotShipmentSearch;
