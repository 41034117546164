import { useState } from "react";
import { VStack, HStack, Text, Button } from "@chakra-ui/react";
import OtherDocumentFile from "../OtherDocumentFile";

interface MarkDeliveredOtherDocumentsProps {
  shipmentId: string | undefined;
}

export const MarkDeliveredOtherDocuments = ({
  shipmentId,
}: MarkDeliveredOtherDocumentsProps): JSX.Element => {
  const [countFiles, setCountFiles] = useState<number[]>([]);
  const onClickAddFile = (): void =>
    setCountFiles([...countFiles, Math.random()]);

  const removeDoc = (id: number): void => {
    const newFiles = countFiles.filter((x) => x !== id);
    setCountFiles(newFiles);
  };

  return (
    <VStack w="100%" mb="0.5rem">
      <HStack w="100%" justifyContent="space-between">
        <Text>OTHER DOCUMENTS</Text>
        <Button
          placeholder="SELECT FILE"
          bgColor="#F6D306"
          color="black"
          fontWeight={600}
          fontSize={15}
          w="175px"
          overflow="hidden"
          onClick={onClickAddFile}
        >
          ADD FILE
        </Button>
      </HStack>
      <VStack w="100%">
        {countFiles.map((element) => (
          <OtherDocumentFile
            key={element}
            shipmentId={shipmentId}
            id={element}
            removeDoc={removeDoc}
          />
        ))}
      </VStack>
    </VStack>
  );
};

export default MarkDeliveredOtherDocuments;
