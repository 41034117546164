import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";

import { Auth } from "aws-amplify";
import { setCurrentUserToken } from "../../app/authSlice";
import { RootState } from "../../app/store";

export const createBaseQueryWithReauth = (
  baseUrl: string,
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => {
  // Declare the baseQuery, with baseURL from params
  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authReducer.currentUserToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

  // baseQuery wrapper that handles the token refresh
  const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
      const { dispatch } = api;

      try {
        const user = await Auth.currentAuthenticatedUser();
        const userSession = user.getSignInUserSession();
        const accessToken = userSession.getIdToken();
        const jwt = accessToken.getJwtToken();

        dispatch(setCurrentUserToken(jwt));

        // Retry the original query, now with the new token
        result = await baseQuery(args, api, extraOptions);
      } catch (error) {
        void Auth.signOut();
        dispatch(setCurrentUserToken(null));

        // Tried to use `history` from `history` package, but it required
        // big changes in the way the router is used and it needed the store
        // to save the history too, so decided on a "less nice" approach
        // that still works perfectly thanks to the `AuthenticatedPageHOC.tsx`
        // User is successfully taken to the `/login` page when the refresh token expires
        window.location.reload();
      }
    }

    /**
     * Left this here for maybe future handling of other non 401 errors
     *
     * if (result.error && result.error.status === 500) {
     *
     * TODO: Show a toast if the server chokes up?
     * TODO: Retry the query automatically?
     *
     * }
     */

    return result;
  };

  return baseQueryWithReauth;
};
