import { createApi } from "@reduxjs/toolkit/query/react";
import {
  QuickPayRequest,
  QuickPayRequestUpdate,
  QuickPayRequestPost,
} from "../types/QuickPayRequest";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";
import { baseUrl } from "./utils/baseUrl";

export const quickpayApi = createApi({
  reducerPath: "quickpayApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}`),
  tagTypes: ["QuickPay"],
  endpoints: (builder) => ({
    getAllQuickPayRequests: builder.query<QuickPayRequest[], void>({
      query: () => ({
        url: "/quickpay",
        method: "GET",
      }),
      providesTags: ["QuickPay"],
    }),
    setQuickPayInvoiceStatus: builder.mutation<void, QuickPayRequestUpdate>({
      query: ({ id, status }) => ({
        url: `quickpay-invoice/${id}`,
        method: "PUT",
        body: { status },
      }),
      invalidatesTags: ["QuickPay"],
    }),
    approve: builder.mutation<void, QuickPayRequestPost>({
      query: ({ quickpayIds }) => ({
        url: `/quickpay/approve`,
        method: "POST",
        body: { quickpayIds },
      }),
      invalidatesTags: ["QuickPay"],
    }),
    reject: builder.mutation<void, QuickPayRequestPost>({
      query: ({ quickpayIds }) => ({
        url: `/quickpay/reject`,
        method: "POST",
        body: { quickpayIds },
      }),
      invalidatesTags: ["QuickPay"],
    }),
  }),
});

export const {
  useGetAllQuickPayRequestsQuery,
  useSetQuickPayInvoiceStatusMutation,
  useApproveMutation,
  useRejectMutation,
} = quickpayApi;

export const { invalidateTags } = quickpayApi.util;
