export enum CargoType {
  GeneralFreight = "GENFREIGHT",
  HouseholdGoods = "HOUSEHOLD",
  Metals = "METALSHEET",
  Vehicles = "MOTOVEH",
  Driveaway = "DRIVETOW",
  Logs = "LOGPOLE",
  BuildingMaterial = "BLDGMAT",
  MobileHomes = "MOBILEHOME",
  Machinery = "MACHLRG",
  FreshProduce = "PRODUCE",
  LiquidsAndGases = "LIQGAS",
  Containers = "INTERMODAL",
  Passengers = "PASSENGERS",
  OilfieldEquipment = "OILFIELD",
  Livestock = "LIVESTOCK",
  Grain = "GRAINFEED",
  CoalAndCoke = "COALCOKE",
  Meat = "MEAT",
  Garbage = "GARBAGE",
  USMail = "USMAIL",
  Chemicals = "CHEM",
  DryBulk = "DRYBULK",
  Food = "COLDFOOD",
  Beverages = "BEVERAGES",
  Paper = "PAPERPROD",
  Utility = "UTILITY",
  FarmSupplies = "FARMSUPP",
  Construction = "CONSTRUCT",
  WaterWell = "WATERWELL",
  Other = "CARGOOTHR",
  OtherDefinied = "OTHERCARGO",
}
