import { Select, SelectProps } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { Countries } from "../../resources/regions";

interface FormCountryProps extends SelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formHook: UseFormReturn<any>;
  property?: string;
  required?: boolean;
}

export const FormCountry = (props: FormCountryProps): JSX.Element => {
  const { formHook, required, property, ...selectProps } = props;
  const stateField = property ? `${property}.state` : "state";
  const countryField = property ? `${property}.country` : "country";
  const { getValues, setValue } = formHook;

  const onChangeCountry = (): void => {
    setValue(stateField, "");
  };

  const country = getValues(countryField);

  return (
    <Select
      {...selectProps}
      {...formHook.register(countryField, {
        onChange: onChangeCountry,
        required: required
          ? { value: true, message: "Please enter the country" }
          : false,
      })}
      value={country ?? ""}
    >
      <option value={""}>Select Country</option>
      {Object.values(Countries).map((country) => (
        <option key={country} value={country}>
          {country}
        </option>
      ))}
    </Select>
  );
};

export default FormCountry;
