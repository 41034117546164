import {
  Input,
  VStack,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Skeleton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {
  getAddressFromLatLng,
  getPlaceLatLng,
  getValuesFromAddressComponents,
} from "../../reuse/Maps";
import { BaseLocation, LocationOptionalFields } from "../../types/Shipment";

interface LocationAddressFieldProps extends LocationOptionalFields {
  formHook: UseFormReturn<BaseLocation>;
  isReadOnly: boolean;
}

export const LocationAddressField = ({
  formHook,
  isReadOnly,
}: LocationAddressFieldProps): JSX.Element => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    language: "en",
  });
  const { register, setValue, getValues, control } = formHook;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      placePredictions.length > 0 &&
      !isMenuOpen &&
      !isPlacePredictionsLoading
    ) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  }, [placePredictions]);

  const autoCompleteAddess = async (placeId: string): Promise<void> => {
    if (!placesService) return;
    setIsLoading(true);
    const latLng = await getPlaceLatLng(placesService, placeId);
    if (latLng) {
      const address = await getAddressFromLatLng(latLng);
      if (address) {
        const { city, state } = getValuesFromAddressComponents(address);

        setValue("city" as const, city as never);
        setValue("state" as const, state as never);
      }
    }
    setIsLoading(false);
  };

  const onSelectAddress = (
    place: google.maps.places.AutocompletePrediction,
  ): void => {
    setMenuOpen(false);
    void autoCompleteAddess(place.place_id);
  };

  useWatch({ control });

  return (
    <VStack
      alignItems="start"
      spacing={0}
      onMouseLeave={() => setMenuOpen(false)}
    >
      <Skeleton w="100%" isLoaded={!isLoading}>
        <Input
          h="50%"
          placeholder="Postal Code"
          autoComplete="new-password"
          {...register("postalCode" as const, {
            required: {
              value: !getValues("state") && !getValues("city"),
              message: "You must enter city and state or postal code  ",
            },
            onChange: (e) => getPlacePredictions({ input: e.target.value }),
          })}
          disabled={isReadOnly}
        />
      </Skeleton>
      <Menu isOpen={isMenuOpen} flip={false} gutter={0}>
        <MenuButton aria-label="Account Options" visibility="hidden" />
        <MenuList maxW="25vw">
          {placePredictions.map((item) => (
            <MenuItem key={item.place_id} onClick={() => onSelectAddress(item)}>
              {item.description}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </VStack>
  );
};

export default LocationAddressField;
