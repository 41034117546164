import { Table, Thead, Tbody, Tr, Th, Checkbox } from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { useQuickPayContext } from "./QuickPayContext";
import {
  filterRowsByMultiSelect,
  filterRowsByTerm,
  getQuickPayToFilter,
} from "./reuse";
import { QuickPayRow } from "./QuickPayRow";
import {
  ApprovedColumns,
  ColumnProps,
  CommomColumns,
  QuickPayRequest,
  QuickPayRequestStatus,
  QuickPayToDropdownEnum,
  RejectedColumns,
  RequestedColumns,
} from "../../types/QuickPayRequest";

export interface QuickPayTableProps {
  typeTable: QuickPayRequestStatus;
  requests: QuickPayRequest[];
}

export const QuickPayTable = ({
  typeTable,
  requests,
}: QuickPayTableProps): JSX.Element => {
  const context = useQuickPayContext();
  const {
    term,
    multiselectFilters,
    selectedQuickPay,
    setSelectedQuickPay,
    sortCriteria,
  } = context!;

  const [filteredData, setFilteredData] = useState<QuickPayRequest[]>([]);

  const sortByCriteria = (criteria: QuickPayToDropdownEnum): void => {
    const sort = (a: QuickPayRequest, b: QuickPayRequest): number => {
      const quickpayA = getQuickPayToFilter(a);
      const quickpayB = getQuickPayToFilter(b);
      return quickpayA[criteria] > quickpayB[criteria] ? 1 : -1;
    };

    setFilteredData([...filteredData].sort(sort));
  };

  const onChange = (): void => {
    if (selectedQuickPay.length === filteredData.length) {
      setSelectedQuickPay([]);
    } else {
      setSelectedQuickPay(filteredData.map((element) => element.id));
    }
  };

  const CustomColumns = ({ columns }: ColumnProps): JSX.Element => (
    <Fragment>
      {columns.map((column) => {
        return (
          <Th textTransform="uppercase" key={column}>
            {column}
          </Th>
        );
      })}
    </Fragment>
  );

  useEffect(() => {
    setFilteredData(filterRowsByTerm(requests, term));
    if (
      Object.values(multiselectFilters).some((v) => v.length > 0 && v[0] !== "")
    ) {
      setFilteredData(filterRowsByMultiSelect(requests, multiselectFilters));
    }
  }, [term, multiselectFilters]);

  useEffect(() => {
    setFilteredData(filterRowsByTerm(requests, term));
  }, [requests]);

  useEffect(() => {
    if (sortCriteria !== "") {
      sortByCriteria(sortCriteria as QuickPayToDropdownEnum);
    }
  }, [sortCriteria]);

  return (
    <Table
      variant="unstyled"
      borderRadius="4"
      overflow="hidden"
      paddingInline={0}
    >
      <Thead>
        <Tr>
          {typeTable === QuickPayRequestStatus.REQUESTED && (
            <Th>
              <Checkbox
                colorScheme="green"
                borderColor="grey"
                isChecked={selectedQuickPay.length === filteredData.length}
                onChange={onChange}
              ></Checkbox>
            </Th>
          )}
          <CustomColumns columns={Object.values(CommomColumns)} />
          {typeTable === QuickPayRequestStatus.REQUESTED && (
            <CustomColumns columns={Object.values(RequestedColumns)} />
          )}
          {typeTable === QuickPayRequestStatus.APPROVED && (
            <CustomColumns columns={Object.values(ApprovedColumns)} />
          )}
          {typeTable === QuickPayRequestStatus.REJECTED && (
            <CustomColumns columns={Object.values(RejectedColumns)} />
          )}
        </Tr>
      </Thead>
      <Tbody>
        {filteredData.map((request) => (
          <QuickPayRow
            key={request.id}
            request={request}
            typeTable={typeTable}
          />
        ))}
      </Tbody>
    </Table>
  );
};

export default QuickPayTable;
