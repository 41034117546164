import { HStack, VStack } from "@chakra-ui/react";
import { useState } from "react";
import ShipmentForm from "../../components/ShipmentForm";
import ShipmentHistory from "../../components/ShipmentHistory";
import { ShipmentContext } from "./ShipmentContext";

export const ShipmentCreate = (): JSX.Element => {
  const [shipmentToCopy, setShipmentToCopy] = useState<unknown>();
  const [selectedShipment, setSelectedShipment] = useState<unknown>();

  return (
    <ShipmentContext.Provider
      value={{
        shipmentToCopy,
        setShipmentToCopy,
        selectedShipment,
        setSelectedShipment,
      }}
    >
      <HStack h="100%" alignItems="start" spacing={0}>
        <ShipmentHistory />
        <VStack h="100%" w="100%" bgColor="grey" overflowY="auto">
          <ShipmentForm />
        </VStack>
      </HStack>
    </ShipmentContext.Provider>
  );
};

export default ShipmentCreate;
