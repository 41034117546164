import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalBody,
  Textarea,
  Skeleton,
} from "@chakra-ui/react";
import { useState } from "react";
import { DefaultModalProps } from "../../types/DefaultModal";
import { useRequestInformationMutation } from "../../api/shipment";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import useToastHook from "../../components/useToastHook";

interface InformationModalProps extends DefaultModalProps {
  shipmentId: string;
}

export const RequestInformationModal = ({
  isOpen,
  onClose,
  shipmentId,
}: InformationModalProps): JSX.Element => {
  const [requestInformation, { isLoading: isSending }] =
    useRequestInformationMutation();
  const [message, setMessage] = useState("");
  const toast = useToastHook();

  const onClickSend = async (): Promise<void> => {
    if (message.length < 5) {
      toast.error({ description: "Please enter a valid message" });
      return;
    }
    const response = await requestInformation({
      shipmentId,
      message,
    });
    if ("data" in response) {
      toast.success({ description: "Information requested successfuly" });
      onClose();
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <Skeleton w="100%" isLoaded={!isSending}>
          <ModalHeader textStyle="uppercase">
            Request More Information from Shipper
          </ModalHeader>
          <ModalBody>
            <Textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              maxLength={250}
              resize="none"
              placeholder="Enter your questions here"
            />
          </ModalBody>
          <ModalFooter justifyContent="space-around">
            <Button layerStyle="red" onClick={onClickSend}>
              Send Message
            </Button>
            <Button size="sm" color="mvmntRed" variant="link" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Skeleton>
      </ModalContent>
    </Modal>
  );
};

export default RequestInformationModal;
