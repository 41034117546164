import { VStack, FormControl, Input, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { validateEmail, validatePhoneNumber } from "../../reuse/UserData";
import { Addresses } from "../../types/Address";
import { CarrierAccountFormProps } from "../../types/CarrierAccountForm";
import { FormError } from "../../types/FormError";
import { Insurance } from "../../types/Insurance";
import ErrorMessage from "../ErrorMessage";
import FormAddress from "../FormAddress";

export const InsuranceForm = ({
  carrierUser,
  handleUpdate,
  isUpdating,
}: CarrierAccountFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm<Insurance>();
  const formHook = useForm<Addresses>();

  useEffect(() => {
    if (carrierUser) {
      reset(carrierUser.insurance);
      formHook.reset({ address: carrierUser.insurance.addresses });
    }
  }, [carrierUser]);

  const onSubmit = handleSubmit(async (data: Insurance) => {
    const addressFormSuccess = await formHook.trigger();
    if (!addressFormSuccess) return;
    const { address: addresses } = formHook.getValues();
    await handleUpdate({ insurance: { ...data, addresses } });
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const untypedControl = control as Control<any>;

  return (
    <form onSubmit={onSubmit}>
      <VStack alignItems="center">
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Insurance Company Name"
            {...register("companyName", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              required: false,
              validate: validatePhoneNumber,
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <PhoneInput
                placeholder="Insurance Company Phone"
                control={untypedControl}
                name={name}
                value={value}
                onChange={onChange}
                country="US"
              />
            )}
          />
        </FormControl>
        <FormAddress
          label="Insurance Company Address"
          formHook={formHook}
          index={0}
          isReadOnly={false}
        />
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Insurance Company Email"
            {...register("email", {
              required: false,
              validate: validateEmail,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Insurance Company Fax"
            {...register("fax", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <Button
          layerStyle="red"
          w="20%"
          onClick={onSubmit}
          isLoading={isUpdating}
        >
          Update
        </Button>
        <ErrorMessage errors={errors as FormError} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default InsuranceForm;
