import { createApi } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";
import {
  ILoad,
  ILoadAddress,
  LoadCommentCreate,
  ILoadComment,
  ILoadDocument,
  ILoadGetParams,
  LoadCreate,
} from "../types/Load";
import { AppointmentType } from "../types/ShipmentForm";
import { ICreateLoadLocation, ILoadLocation } from "../types/LoadLocation";
import { IEventLog } from "../types/EventLog";
import { LoadCreateBulkResult, LoadRowToAdd } from "../types/LoadUpload";

export const loadApi = createApi({
  reducerPath: "loadApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}/loadboards/`),
  tagTypes: [
    "Loads",
    "LoadCustomers",
    "LoadHistory",
    "LoadChangelog",
    "loadComments",
  ],
  endpoints: (builder) => ({
    getLoads: builder.query<{ loads: ILoad[]; name: string }, ILoadGetParams>({
      query: (params) => {
        const statusQuery = params.status
          ? `&status=${encodeURIComponent(JSON.stringify(params.status))}`
          : "";
        const customerQuery = params.customerName
          ? `&customerName=${encodeURIComponent(
              JSON.stringify(params.customerName),
            )}`
          : "";
        const assignedRepsQuery = params.assignedReps
          ? `&assignedReps=${encodeURIComponent(
              JSON.stringify(params.assignedReps),
            )}`
          : "";
        const originDateQuery =
          params.originDate?.start && params.originDate?.end
            ? `&originStartDate=${params.originDate.start}&originEndDate=${params.originDate.end}`
            : "";
        const destinationDateQuery =
          params.destinationDate?.start && params.destinationDate?.end
            ? `&destinationStartDate=${params.destinationDate.start}&destinationEndDate=${params.destinationDate.end}`
            : "";
        const sortOptionQuery = params.sortOption
          ? `&sortOption=${encodeURIComponent(params.sortOption)}`
          : "";
        const sortDirectionQuery = params.sortDirection
          ? `&sortDirection=${encodeURIComponent(params.sortDirection)}`
          : "";
        const searchTermQuery = params.searchTerm
          ? `&searchTerm=${encodeURIComponent(params.searchTerm)}`
          : "";
        const queryParams = [
          statusQuery,
          customerQuery,
          assignedRepsQuery,
          originDateQuery,
          destinationDateQuery,
          sortOptionQuery,
          sortDirectionQuery,
          searchTermQuery,
        ].join("");
        return `/shipper/loads/boards/${params.id}?${queryParams}`;
      },
      providesTags: ["Loads"],
    }),
    getLoadCustomers: builder.query<string[], void>({
      query: () => `/shipper/loads/customers`,
      providesTags: ["LoadCustomers"],
    }),
    getLoadDocuments: builder.query<ILoadDocument[], { loadId: string }>({
      query: ({ loadId }) => `/${loadId}/documents`,
      providesTags: [],
    }),
    createLoad: builder.mutation<void, LoadCreate>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Loads", "LoadCustomers"],
    }),
    updateLoadStop: builder.mutation<
      ILoadAddress,
      {
        id: string;
        notes?: string;
        apptType?: AppointmentType;
        appointmentTime?: string | null;
        date?: string;
        receivingName?: string | null;
      }
    >({
      query: ({ id, ...updateFields }) => ({
        url: `/stop/${id}`,
        method: "PUT",
        body: { ...updateFields },
      }),
      invalidatesTags: ["Loads", "LoadChangelog"],
    }),
    createLoadComment: builder.mutation<void, LoadCommentCreate>({
      query: (body) => ({
        url: "/comments",
        method: "POST",
        body,
      }),
      invalidatesTags: ["loadComments"],
    }),
    getLoadComments: builder.query<ILoadComment[], { loadId: string }>({
      query: ({ loadId }) => `/${loadId}/comments`,
      providesTags: ["loadComments"],
    }),
    updateHighPriorityLoad: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}/high-priority`,
        method: "PUT",
      }),
      invalidatesTags: ["Loads", "LoadChangelog"],
    }),
    updateAtRiskLoad: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}/at-risk`,
        method: "PUT",
      }),
      invalidatesTags: ["Loads", "LoadChangelog"],
    }),
    getLoadLocations: builder.query<ILoadLocation[], { loadId: string }>({
      query: ({ loadId }) => `/${loadId}/locations`,
      providesTags: ["LoadHistory"],
    }),
    getLoadEvents: builder.query<IEventLog[], { loadId: string }>({
      query: ({ loadId }) => `/${loadId}/events`,
      providesTags: ["LoadChangelog"],
    }),
    createLoadLocation: builder.mutation<ILoadLocation, ICreateLoadLocation>({
      query: ({ loadId, ...body }) => ({
        url: `/${loadId}/locations`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["LoadHistory", "LoadChangelog"],
    }),
    validateLoadUpload: builder.mutation<LoadCreateBulkResult, File>({
      query: (file) => {
        const body = new FormData();
        body.append("file", file);
        return {
          url: "/validate-bulk",
          method: "POST",
          body,
        };
      },
    }),
    bulkUploadLoads: builder.mutation<void, LoadRowToAdd[]>({
      query: (body) => ({
        url: "/upload-bulk",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Loads"],
    }),
  }),
});

export const {
  useGetLoadsQuery,
  useGetLoadCustomersQuery,
  useCreateLoadMutation,
  useGetLoadCommentsQuery,
  useUpdateLoadStopMutation,
  useCreateLoadCommentMutation,
  useGetLoadDocumentsQuery,
  useUpdateHighPriorityLoadMutation,
  useUpdateAtRiskLoadMutation,
  useGetLoadLocationsQuery,
  useCreateLoadLocationMutation,
  useGetLoadEventsQuery,
  useValidateLoadUploadMutation,
  useBulkUploadLoadsMutation,
} = loadApi;

export const { invalidateTags } = loadApi.util;
