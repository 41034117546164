import { useEffect, useState, useMemo } from "react";
import { HStack, useMediaQuery } from "@chakra-ui/react";
import RfpList from "../../components/RfpList";
import RfpDetail from "../../components/RfpDetail";
import { IRfp } from "../../types/Rfp";
import RfpModalForm from "../../components/RfpModalForm";
import { NetworkPartner } from "../../types/NetworkPartner";
import { RfpFormMode } from "../../components/RfpModalForm/RfpModalForm";
import { ActiveRfpContext } from "./ActiveRfpContext";

interface ActiveRfpProps {
  data?: IRfp[] | null;
  carriers?: NetworkPartner[];
}

export const ActiveRfp = ({ data, carriers }: ActiveRfpProps): JSX.Element => {
  const [largerThan1620] = useMediaQuery(["(min-width: 1620px)"]);
  const [formMode, setFormMode] = useState<RfpFormMode>(RfpFormMode.CREATE);
  const [openRfpForm, toggleRfpForm] = useState<boolean>(false);
  const [selectedRfp, setSelectedRfp] = useState<IRfp | undefined>();

  const onClose = (): void => toggleRfpForm(false);

  const MemoizedRfpList = useMemo(
    () => <RfpList data={data ?? []} />,
    [selectedRfp, data],
  );

  const MemoizedRfpDetail = useMemo(
    () => (selectedRfp ? <RfpDetail selectedRfp={selectedRfp} /> : null),
    [selectedRfp],
  );

  useEffect(() => {
    if (data) {
      setSelectedRfp(data[0]);
    }
  }, [data]);

  return (
    <ActiveRfpContext.Provider
      value={{
        largerThan1620,
        openRfpForm,
        toggleRfpForm,
        selectedRfp,
        setSelectedRfp,
        formMode,
        setFormMode,
      }}
    >
      <HStack h="100%" alignItems="start" spacing={0}>
        {MemoizedRfpList}
        {MemoizedRfpDetail}
        {openRfpForm && <RfpModalForm isOpen={true} onClose={onClose} />}
      </HStack>
    </ActiveRfpContext.Provider>
  );
};

export default ActiveRfp;
