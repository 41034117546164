import { HStack, Text, Image } from "@chakra-ui/react";
import Truck from "../../../resources/svg/truck_logo.svg";
import Distance from "../../../resources/svg/distance.svg";
import Weight from "../../../resources/svg/weight.svg";
import { MainSectionProps } from ".";

const ShipmentMainData = ({ shipment }: MainSectionProps): JSX.Element => (
  <HStack minH="32px" w="100%" spacing="16%">
    <HStack>
      <Image src={Truck} />
      <Text>{shipment.equipmentType}</Text>
    </HStack>
    <HStack>
      <Image src={Weight} />
      <Text>{shipment.weight}</Text>
    </HStack>
    <HStack>
      <Image src={Distance} />
      <Text>{`${shipment.shipmentTotalMiles ?? 0} miles`}</Text>
    </HStack>
  </HStack>
);

export default ShipmentMainData;
