import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalBody,
  Text,
  Heading,
} from "@chakra-ui/react";
import { DefaultModalProps } from "../../types/DefaultModal";
import { Shipment } from "../../types/Shipment";

interface ShipmentConfirmCancelModalProps extends DefaultModalProps {
  onConfirm: () => void;
  isLoading: boolean;
  shipment: Shipment;
}

export const ShipmentConfirmCancelModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  shipment,
}: ShipmentConfirmCancelModalProps): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="uppercase">Confirm Cancellation</ModalHeader>
        <ModalBody>
          <Text fontSize={18} mb={"10px"}>
            The Carrier has requested to cancel this shipment
          </Text>
          {shipment.cancelReason && (
            <>
              <Heading as="h5" fontSize={18} mb={"10px"}>
                Reason:
              </Heading>
              <Text fontSize={18} mb={"10px"}>
                {shipment.cancelReason}
              </Text>
            </>
          )}
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <Button layerStyle="inverseRed" onClick={onClose}>
            close
          </Button>
          <Button isLoading={isLoading} layerStyle="red" onClick={onConfirm}>
            Confirm Cancellation
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShipmentConfirmCancelModal;
