import { createContext, useContext } from "react";
import { ShipperStatus, ShipperUser } from "../../types/Shipper";

interface ManageUser {
  statusCellStyle: React.CSSProperties | undefined;
  setStatusCellStyle: React.Dispatch<React.SetStateAction<React.CSSProperties>>;
  currentStatus: ShipperStatus;
  setCurrentStatus: React.Dispatch<React.SetStateAction<ShipperStatus>>;
  setStylingBasedOnStatus: () => void;
  term: string;
  selectedShipper: ShipperUser | undefined;
  setSelectedShipper: React.Dispatch<
    React.SetStateAction<ShipperUser | undefined>
  >;
}

const ManageUserContext = createContext<ManageUser | null>(null);

const useManageUserContext = (): ManageUser | null =>
  useContext(ManageUserContext);

export { ManageUserContext, useManageUserContext };
