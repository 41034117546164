import { HStack, VStack, Text, Button, Input, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { useCreateMarketPlaceBidMutation } from "../../api/bid";
import { invalidateTags } from "../../api/shipment";
import { useAppDispatch } from "../../app/hooks";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { Shipment } from "../../types/Shipment";
import ChevronIcon from "../ChevronIcon";
import RequestInformationModal from "../RequestInformationModal";
import useToastHook from "../../components/useToastHook";

const SpotShipmentDetails = ({
  id: shipmentId,
  shipmentStops,
  accessorials,
  bids,
  bidNotes,
}: Shipment): JSX.Element => {
  const [rate, setRate] = useState("");
  const [createBid, { isLoading }] = useCreateMarketPlaceBidMutation();
  const dispatch = useAppDispatch();
  const [isInformationOpen, toggleInformationOpen] = useState(false);
  const toast = useToastHook();

  const onClickBid = async (): Promise<void> => {
    const flatRate = parseFloat(rate);
    if (!flatRate || flatRate < 0) {
      toast.error({ description: "Invalid rate" });
      return;
    }
    const response = await createBid({
      shipmentId,
      flatRate,
    });
    if ("data" in response) {
      toast.success({ description: "Bid posted successfully" });
      dispatch(invalidateTags(["MarketPlace"]));
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  return (
    <VStack
      w="98%"
      bgColor="white"
      alignSelf="center"
      borderTopRadius="sm"
      pb="1%"
    >
      <HStack bgColor="black" w="100%" h="2rem" borderTopRadius="inherit">
        <Text ml="2rem" w="40%" color="yellow">
          Additional Stops
        </Text>
        <Text w="60%" color="yellow">
          Accessorials
        </Text>
        <Text w="60%" color="yellow">
          Bid Notes
        </Text>
      </HStack>
      <HStack w="100%" alignItems="start">
        <Flex w="40%" flexWrap="wrap" mx="1rem" overflowY="auto" maxH="10vh">
          {shipmentStops.map((stop, index) => (
            <>
              {index > 0 && <ChevronIcon />}
              <Text mx="1rem" key={stop.address.address1}>
                {stop.address.city}, {stop.address.state}
              </Text>
            </>
          ))}
        </Flex>
        <Flex w="60%" flexWrap="wrap" overflowY="auto" maxH="10vh">
          {accessorials.map((accessorial) => (
            <Text mr="1rem" key={accessorial}>
              {accessorial}
            </Text>
          ))}
        </Flex>
        <Flex w="60%" flexWrap="wrap" overflowY="auto" maxH="10vh">
          <Text mr="1rem">{bidNotes}</Text>
        </Flex>
      </HStack>
      {bids?.length === 0 && (
        <HStack w="100%" px="2%">
          <Button
            layerStyle="yellow"
            w="10rem"
            size="sm"
            onClick={() => toggleInformationOpen(true)}
          >
            Request More Info
          </Button>
          <Input
            ml="auto !important"
            w="10rem"
            size="sm"
            placeholder="Bidding Rate"
            type="number"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
          />
          <Button
            isLoading={isLoading}
            layerStyle="red"
            w="10rem"
            size="sm"
            onClick={onClickBid}
          >
            Submit Bid
          </Button>
        </HStack>
      )}
      <RequestInformationModal
        isOpen={isInformationOpen}
        onClose={() => toggleInformationOpen(false)}
        shipmentId={shipmentId}
      />
    </VStack>
  );
};

export default SpotShipmentDetails;
