import { ReactNode } from "react";
import { Box, Heading, Container, HStack } from "@chakra-ui/react";
import {
  NavLink,
  To,
  Navigate,
  useRoutes,
  generatePath,
  useOutletContext,
} from "react-router-dom";
import { mvmntTheme } from "../../styles/theme";
import {
  useGetCarrierQuery,
  useGetCarrierInvoicesQuery,
} from "../../api/carrier";
import { CarrierUser } from "../../types/Carrier";
import { NotInvoiced } from "./Invoices/NotInvoiced";
import { Unpaid } from "./Invoices/Unpaid";
import { Paid } from "./Invoices/Paid";
import { Rejected } from "./Invoices/Rejected";
import { RequestPayment } from "./Invoices/RequestPayment";
import { QuickPay } from "./Invoices/RequestPayment/QuickPay";
import { DirectPayment } from "./Invoices/RequestPayment/DirectPayment";
import { DataTableSkeleton } from "../../ui/components/DataTable";
import { QuickPayUnavailable } from "./Invoices/RequestPayment/QuickPayUnavailable";

function StyledNavLink(props: { to: To; children: ReactNode }): JSX.Element {
  return (
    <NavLink
      to={props.to}
      style={({ isActive }) => ({
        borderBottom: "2px solid",
        paddingBottom: "5px",
        borderColor: isActive ? mvmntTheme.colors.mvmntBlue : "transparent",
        fontFamily: isActive ? "Montserrat Bold" : "Montserrat Regular",
      })}
    >
      {props.children}
    </NavLink>
  );
}

export enum PAGE_ROUTES {
  NOT_INVOICED = "not-invoiced",
  UNPAID = "unpaid",
  PAID = "paid",
  REJECTED = "rejected",
}
export enum MODAL_ROUTES {
  REQUEST_PAYMENT = ":mvmntShipmentId/request-payment",
  REQUEST_PAYMENT_QUICKPAY = ":mvmntShipmentId/request-payment/quick-pay",
  REQUEST_PAYMENT_QUICK_PAY_UNAVAILABLE = ":mvmntShipmentId/request-payment/quick-pay/unavailable",
  REQUEST_PAYMENT_DIRECT_PAYMENT = ":mvmntShipmentId/request-payment/direct-payment",
}

export function generateModalRoutePath(
  path: `${MODAL_ROUTES}`,
  mvmntShipmentId?: number | string,
): string {
  if (!mvmntShipmentId) return "";
  return generatePath(path, { mvmntShipmentId: `${mvmntShipmentId}` });
}

function InvoicesPage({ carrier }: { carrier: CarrierUser }): JSX.Element {
  const { data: carrierInvoices, isLoading } = useGetCarrierInvoicesQuery(
    carrier.id,
  );
  const requestPaymentRoutes = [
    {
      path: MODAL_ROUTES.REQUEST_PAYMENT,
      element: <RequestPayment carrier={carrier} />,
    },
    {
      path: MODAL_ROUTES.REQUEST_PAYMENT_QUICKPAY,
      element: <QuickPay />,
    },
    {
      path: MODAL_ROUTES.REQUEST_PAYMENT_QUICK_PAY_UNAVAILABLE,
      element: <QuickPayUnavailable />,
    },
    {
      path: MODAL_ROUTES.REQUEST_PAYMENT_DIRECT_PAYMENT,
      element: <DirectPayment />,
    },
  ];
  const routes = useRoutes([
    {
      path: PAGE_ROUTES.NOT_INVOICED,
      element: (
        <NotInvoiced
          invoices={carrierInvoices?.uninvoiced}
          isLoading={isLoading}
        />
      ),
      children: requestPaymentRoutes,
    },
    {
      path: PAGE_ROUTES.UNPAID,
      element: (
        <Unpaid invoices={carrierInvoices?.unpaid} isLoading={isLoading} />
      ),
    },
    {
      path: PAGE_ROUTES.PAID,
      element: <Paid invoices={carrierInvoices?.paid} isLoading={isLoading} />,
    },
    {
      path: PAGE_ROUTES.REJECTED,
      element: (
        <Rejected invoices={carrierInvoices?.rejected} isLoading={isLoading} />
      ),
      children: requestPaymentRoutes,
    },
    { path: "*", element: <Navigate to={PAGE_ROUTES.NOT_INVOICED} replace /> },
  ]);
  return (
    <>
      <Box
        bgColor="grey6"
        padding="22px 40px 0px 40px"
        fontFamily="Montserrat Regular"
        fontSize="13px"
        borderRadius="4px"
      >
        <Heading variant="montserrat" marginBottom="20px">
          Invoices
        </Heading>
        <HStack spacing="20px">
          <StyledNavLink to={PAGE_ROUTES.NOT_INVOICED}>
            Not invoiced{" "}
            {!isLoading && <>({carrierInvoices?.uninvoiced.length})</>}
          </StyledNavLink>
          <StyledNavLink to={PAGE_ROUTES.UNPAID}>
            Unpaid {!isLoading && <>({carrierInvoices?.unpaid.length})</>}
          </StyledNavLink>
          <StyledNavLink to={PAGE_ROUTES.PAID}>
            Paid {!isLoading && <>({carrierInvoices?.paid.length})</>}
          </StyledNavLink>
          <StyledNavLink to={PAGE_ROUTES.REJECTED}>
            Rejected {!isLoading && <>({carrierInvoices?.rejected.length})</>}
          </StyledNavLink>
        </HStack>
      </Box>
      <Container maxW="none" padding="0px 40px">
        {!isLoading ? <>{routes}</> : <DataTableSkeleton />}
      </Container>
    </>
  );
}

export const Invoices = (): JSX.Element => {
  const { data: carrier } = useGetCarrierQuery();
  if (!carrier) return <DataTableSkeleton />;
  return <InvoicesPage carrier={carrier} />;
};

interface CloseRouteContext {
  closeRoute: `${PAGE_ROUTES}`;
}
export function useCloseRoute(): CloseRouteContext {
  return useOutletContext<{ closeRoute: `${PAGE_ROUTES}` }>();
}
