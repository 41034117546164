import { VStack, HStack, Text, Icon } from "@chakra-ui/react";
import { ILoadComment } from "../../../../types/Load";
import { parseDateToLocal } from "../../../../reuse/Dates";
import { BiUser } from "react-icons/bi";
import { MdOutlineDateRange } from "react-icons/md";

interface LoadNoteListProps {
  commentList: ILoadComment[];
}

export const LoadNoteList = ({
  commentList,
}: LoadNoteListProps): JSX.Element => (
  <VStack w="100%">
    {commentList.map(({ id, ...rest }) => (
      <LoadNote key={id} note={rest} />
    ))}
  </VStack>
);

interface LoadNoteProps {
  note: ILoadComment;
}

const LoadNote = ({ note }: LoadNoteProps): JSX.Element => {
  const { createDate, comment, user } = note;
  const { firstName, lastName } = user;
  return (
    <VStack w="100%" textAlign="left" py="0.5rem">
      <Text w="100%" fontSize="16px">
        {comment}
      </Text>
      <HStack w="100%">
        <Icon as={BiUser} fontSize="17px" aria-label="High Priority" />
        <Text fontSize="16px">{firstName + " " + lastName}</Text>
        <Icon
          as={MdOutlineDateRange}
          fontSize="17px"
          aria-label="High Priority"
        />
        <Text fontSize="16px">{parseDateToLocal(createDate)}</Text>
      </HStack>
    </VStack>
  );
};
