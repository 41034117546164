export const defaultAccessorials = [
  "Blankets",
  "Straps",
  "Driver Assist",
  "Block & Brace",
  "Dunnage",
  "Load Locks",
  "Bulk Heads",
  "Edge Protectors",
  "Team Drivers",
  "Tarps (6)",
  "Tarps (8)",
];

export const extraAccessorials = [
  "Additional Stops",
  "Advance Notification",
  "After-hour/before-hour Delivery",
  "Blind Shipment",
  "Cross-border Processing Fee",
  "Customs or In-Bond Freight",
  "Exhibition Shipment",
  "Extra Labor/Helper/Lumper",
  "Hazardous Materials",
  "Specialized Limited Access Pickup or Delivery",
  "Liftgate service",
  "Packaging",
  "Pallet Jack",
  "Protective Service - Winter Program",
  "Tolls",
];
