import { useMemo, useState, useEffect } from "react";
import {
  Button,
  HStack,
  Input,
  VStack,
  Select,
  Textarea,
  Text,
  FormControl,
  FormLabel,
  Divider,
  Skeleton,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { MultiSelect } from "react-multi-select-component";
import {
  Shipment,
  ShipmentCancelReason,
  ShipmentCreate,
  ShipmentStop,
  ShipmentStopUpdate,
  ShipmentUpdate,
  ShipmentVisibility,
} from "../../types/Shipment";
import ShipmentEquipmentsForm from "../ShipmentEquipmentsForm";
import ShipmentStopsForm from "../ShipmentStopsForm";
import { toMultiselectOptions, Option } from "../../reuse/MultiSelect";
import {
  CustomAccessorials,
  MaterialType,
  StopType,
} from "../../types/ShipmentForm";
import {
  useCancelShipmentMutation,
  useLazyGetShipmentDriverQuery,
  useRequestQuoteMutation,
  useUpdateShipmentDetailsMutation,
} from "../../api/shipment";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import ErrorMessage from "../ErrorMessage";
import { FormError } from "../../types/FormError";
import RequestQuoteModal from "../RequestQuoteModal";
import TenderSelectionModal from "../../pages/ShipmentCreate/TenderSelectionModal";
import FormState from "../FormState";
import FormCountry from "../FormCountry";
import { getShipmentVisiblityLabel } from "../../reuse/Shipment";
import { borderStyling } from "../../reuse/Forms";
import { CarrierUser } from "../../types/Carrier";
import useToastHook from "../../components/useToastHook";
import { Countries } from "../../resources/regions";
import AppRoutes from "../../routes";
import { shipmentContent } from "./options";
import { useShipmentContext } from "../../pages/ShipmentCreate/ShipmentContext";
import {
  defaultAccessorials,
  extraAccessorials,
} from "../ShipmentEquipmentsForm/options";
import {
  getCountryEnumFromString,
  getStateEnumFromStringAndCountryEnum,
} from "../../reuse/RegionKeys";
import ShipmentCancelModal from "../ShipmentCancelModal/ShipmentCancelModal";
import TenderPDFModal from "./TenderPDFModal";
import { useQueryState } from "../../api/shipper";
import { ShipperUserData } from "../../types/Shipper";

const materialOptions = toMultiselectOptions(Object.values(MaterialType));

interface FormProps {
  initialValues?: Shipment;
  setSelectedShipment?: (s: Shipment) => void;
}

export const ShipmentForm = ({
  initialValues,
  setSelectedShipment,
}: FormProps): JSX.Element => {
  const [createdShipment, setCreatedShipment] = useState<Shipment>();
  const { data: shipperUser } = useQueryState();
  const [showPDF, setShowPDF] = useState(false);

  const toast = useToastHook();
  const navigate = useNavigate();
  const shipmentContext = useShipmentContext();

  const isTrackingPage = window.location.href.includes(AppRoutes.TRACKING);
  const isPastShipmentsPage = window.location.href.includes(AppRoutes.PAST);
  const showFormButtons = !isPastShipmentsPage;

  const isReadOnly = !!initialValues || isPastShipmentsPage;

  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const [tenderCarrier, setTenderCarrier] = useState<CarrierUser>();
  const [tenderRate, setTenderRate] = useState<number>();
  const [requestQuote, { isLoading }] = useRequestQuoteMutation();
  const [updateShipmentDetails, { isLoading: isUpdating }] =
    useUpdateShipmentDetailsMutation();
  const [getDriver, { isFetching, data: driverResponse }] =
    useLazyGetShipmentDriverQuery();
  const [cancelShipment, { isLoading: isCancelLoading }] =
    useCancelShipmentMutation();

  const [hazardousMaterial, setHazardousMaterial] = useState<Option[]>([]);
  const [isRequestOpen, toggleRequestOpen] = useState(false);
  const [isTenderOpen, toggleTenderOpen] = useState(false);

  const formHook = useForm<ShipmentCreate>({
    defaultValues: useMemo(() => {
      return (
        initialValues ?? {
          shipmentStops: [
            {
              stopType: StopType.Pickup,
              address: {
                country: Countries.US,
              },
            },
            {
              stopType: StopType.Delivery,
              address: {
                country: Countries.US,
              },
            },
          ],
        }
      );
    }, [initialValues]),
    mode: "all",
  });

  const customAccessorialsHook = useForm<CustomAccessorials>();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
    reset,
    unregister: formUnregister,
    control: formControl,
  } = formHook;

  const {
    getValues: getAccessorials,
    reset: resetAccessorials,
    unregister: accUnRegister,
    control: accControl,
  } = customAccessorialsHook;
  const customAccessorialsFieldArray = useFieldArray({
    control: accControl,
    name: "accessorials",
  });
  const shipmentStopsFieldArray = useFieldArray({
    name: "shipmentStops",
    control: formControl,
  });
  const { replace: setCustomAccessorials } = customAccessorialsFieldArray;
  const { insert: insertStopField, remove: removeStopField } =
    shipmentStopsFieldArray;

  const [isCopying, setIsCopying] = useState(false);

  useEffect(() => {
    if (shipmentContext) {
      const { shipmentToCopy } = shipmentContext;

      if (shipmentToCopy) {
        setIsCopying(true);
        const copy = shipmentToCopy as Shipment;

        if (copy.materialTypes) {
          const selectedOptions = toMultiselectOptions(copy.materialTypes);
          setValue("materialTypes", copy.materialTypes);
          setHazardousMaterial(selectedOptions);
        }
        setValue("unitType", copy.unitType);
        setValue("unitCount", copy.unitCount);
        setValue("value", copy.value);
        setValue("billingNote", copy.billingNote);
        setValue(
          "billingAddress.addressName",
          copy.billingAddress?.addressName ?? "",
        );
        setValue(
          "billingAddress.address1",
          copy.billingAddress?.address1 ?? "",
        );
        setValue(
          "billingAddress.postalCode",
          copy.billingAddress?.postalCode ?? "",
        );
        setValue("billingAddress.city", copy.billingAddress?.city ?? "");
        setValue(
          "billingAddress.address2",
          copy.billingAddress?.address2 ?? "",
        );

        // just a note: this isn't technically necessary since the casing for the billing address in the db matches
        // the casing used for the form, but since the db capitalizes all the states and country names for the stops entities
        // I wanted this to be consistent with how it was done below/ in case that changes
        const billingCountry = getCountryEnumFromString(
          copy.billingAddress?.country ?? "",
        );
        let billingState = "";
        if (billingCountry) {
          const billingStateObj = getStateEnumFromStringAndCountryEnum(
            copy.billingAddress?.state ?? "",
            billingCountry,
          );
          billingState = billingStateObj?.name ?? "";
        }

        setValue("billingAddress.state", billingState ?? "");
        setValue("billingAddress.country", billingCountry ?? "");
        setValue("bidNotes", copy.bidNotes);
        setValue("tenderNotes", copy.tenderNotes);
        setValue("equipmentType", copy.equipmentType);
        setValue("length", copy.length);
        setValue("width", copy.width);
        setValue("height", copy.height);
        setValue("weight", copy.weight);
        if (copy.refrigerationTemperature)
          setValue("refrigerationTemperature", copy.refrigerationTemperature);
        const allAccessorials = [...defaultAccessorials, ...extraAccessorials];
        const customAcc = copy.accessorials.filter(
          (acc) => !allAccessorials.includes(acc),
        );
        const defaultAcc = copy.accessorials.filter((acc) =>
          allAccessorials.includes(acc),
        );
        accUnRegister("accessorials");
        setCustomAccessorials([]);
        setValue("accessorials", defaultAcc); // only add the defaults as the custom ones are registered where they are rendered
        setCustomAccessorials(
          customAcc.map((acc, index) => {
            return { label: acc, id: index };
          }),
        );

        formUnregister("shipmentStops");
        removeStopField();

        copy.shipmentStops.forEach((stop, index) => {
          const country = getCountryEnumFromString(stop.address.country ?? "");
          let state = "";
          if (country) {
            const stateObj = getStateEnumFromStringAndCountryEnum(
              stop.address.state,
              country,
            );
            state = stateObj?.name ?? "";
          }

          insertStopField(index, {
            stopType: stop.stopType,
            appointmentType: stop.appointmentType,
            // appointmentTime: stop.appointmentTime,
            loadType: stop.loadType,
            allDayDock: stop.allDayDock,
            // date: stop.date,
            dockOpen: stop.dockOpen,
            dockClose: stop.dockClose,
            referenceNumber: stop.referenceNumber ?? undefined,
            receivingName: stop.receivingName ?? undefined,
            notes: stop.notes,
            address: {
              postalCode: stop.address.postalCode,
              city: stop.address.city,
              address1: stop.address.address1,
              address2: stop.address.address2,
              state: state,
              country: country ?? "",
            },
          });
        });

        setTimeout(() => setIsCopying(false), 500);
      }
    }
  }, [shipmentContext]);

  const { shipmentStops, ...shipmentErrors } = errors;

  const mapValues = (list: Option[]): MaterialType[] => {
    return list.map((option) => option.value) as MaterialType[];
  };

  const onChangeMaterial = (materials: Option[]): void => {
    const values = mapValues(materials);
    setValue("materialTypes", values);
    setHazardousMaterial(materials);
  };

  useEffect(() => {
    const confirmTender = async (): Promise<void> => {
      if (!tenderCarrier || !tenderRate) return;

      setValue("visibility", ShipmentVisibility.All);
      setValue("initialBid", {
        carrierId: tenderCarrier.id,
        flatRate: tenderRate,
      });

      const shipment = getShipmentData();

      const response = await requestQuote(shipment);
      if ("data" in response) {
        const newShipment: Shipment = response.data;
        setCreatedShipment({
          ...newShipment,
          shipper: shipperUser! as ShipperUserData,
        });

        toast.success({ description: "Shipment tendered successfully" });
        setTimeout(() => setShowPDF(true), 500);
      } else {
        toast.error({ description: `${parseErrorResponse(response.error)}` });
      }
    };

    void confirmTender();
  }, [tenderRate, tenderCarrier]);

  const handleTenderSelect = (carrier: CarrierUser, flatRate: number): void => {
    toggleTenderOpen(false);
    setTenderCarrier(carrier);
    setTenderRate(flatRate);
  };

  const handleQuoteRequest = async (
    visibility: ShipmentVisibility,
  ): Promise<void> => {
    toggleRequestOpen(false);
    setValue("visibility", visibility);

    const shipment = getShipmentData();
    const response = await requestQuote(shipment);
    if ("data" in response) {
      toast.success({ description: "Shipment quote requested successfully" });
      clearForm();
      navigate(AppRoutes.ACTIVE_QUOTES);
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  const clearForm = (): void => {
    resetAccessorials();
    reset();
  };

  const handleUpdateDetailsRequest = async (): Promise<void> => {
    if (initialValues) {
      const shipment = getShipmentData();

      const updatedShipment: ShipmentUpdate = {
        id: initialValues.id,
        bidNotes: shipment.bidNotes,
        tenderNotes: shipment.tenderNotes,
        shipmentStops: shipment.shipmentStops.map(
          (stop: ShipmentStop): ShipmentStopUpdate => ({
            appointmentType: stop.appointmentType,
            appointmentTime: stop.appointmentTime,
            notes: stop.notes ?? "",
            id: stop.id,
          }),
        ),
      };
      const response = await updateShipmentDetails(updatedShipment);
      if ("data" in response) {
        setSelectedShipment?.({
          ...initialValues,
          ...response.data,
        });
        toast.success({ description: "Shipment details updated successfully" });
      } else {
        toast.error({ description: `${parseErrorResponse(response.error)}` });
      }
    }
  };

  const getShipmentData = (): ShipmentCreate => {
    const formData = getValues();
    const defaultAccessorials = formData.accessorials ?? [];
    const customAccessorials = getAccessorials("accessorials").map(
      (acc) => acc.label,
    );
    const accessorials = [...defaultAccessorials, ...customAccessorials];
    const shipment = { ...formData, accessorials };
    return shipment;
  };

  const onClickTender = handleSubmit(() => toggleTenderOpen(true));
  const onClickRequestQuote = handleSubmit(() => toggleRequestOpen(true));

  register("materialTypes", {
    required: {
      value: false,
      message: "You must enter the DOT hazardous material designation",
    },
  });

  const ShipmentEquipment = useMemo(
    () => (
      <ShipmentEquipmentsForm
        formHook={formHook}
        accessorialsHook={customAccessorialsHook}
        isReadOnly={isReadOnly}
        customAccessorialsFieldArray={customAccessorialsFieldArray}
      />
    ),
    [
      formHook,
      customAccessorialsHook,
      isReadOnly,
      getValues().accessorials,
      getAccessorials().accessorials,
      customAccessorialsFieldArray,
    ],
  );

  const onConfirmCancelShipment = async (
    reason?: ShipmentCancelReason,
  ): Promise<void> => {
    const response = await cancelShipment({
      shipmentId: initialValues!.id,
      reason,
    });
    if ("error" in response) {
      toast.error({ description: parseErrorResponse(response.error) });
    } else {
      toast.success({ description: "Shipment Canceled successfuly." });
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues]);

  const onChangeDollarValue = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    // Strips out $ , and - characters from number display so we can store it as type number
    setValue("value", Number(event.target.value.replace(/[$,-]/g, "")));
  };

  const FormButtons = (): JSX.Element => (
    <>
      {initialValues && (
        <>
          <Button
            onClick={() => {
              setIsCanceling(true);
              void getDriver({
                shipmentId: initialValues!.id,
              });
            }}
            alignSelf="end"
            layerStyle="red"
            w="14rem"
            isLoading={isFetching}
          >
            Cancel
          </Button>
          {isCanceling && (
            <ShipmentCancelModal
              onConfirm={onConfirmCancelShipment}
              onClose={() => setIsCanceling(false)}
              isOpen={true}
              driver={driverResponse?.driver ?? undefined}
              isLoading={isCancelLoading}
            />
          )}
        </>
      )}
      {!isTrackingPage && (
        <>
          <Button
            onClick={onClickTender}
            layerStyle="red"
            w="14rem"
            alignSelf="end"
            display={initialValues ? "none" : "block"}
          >
            Tender
          </Button>
          <Button
            onClick={
              initialValues ? handleUpdateDetailsRequest : onClickRequestQuote
            }
            layerStyle="yellow"
            w="14rem"
            alignSelf="end"
          >
            {initialValues ? "Update Details" : "Request Quote"}
          </Button>
        </>
      )}
    </>
  );

  return (
    <Skeleton
      isLoaded={!isLoading && !isUpdating}
      alignSelf="center"
      w="60%"
      minW="fit-content"
      my="2%"
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <VStack>
          {showFormButtons && FormButtons()}
          {isCanceling && (
            <ShipmentCancelModal
              onConfirm={onConfirmCancelShipment}
              onClose={() => setIsCanceling(false)}
              isOpen={true}
              driver={driverResponse?.driver ?? undefined}
              isLoading={isCancelLoading}
            />
          )}
          <ErrorMessage
            errors={shipmentErrors as FormError}
            clearErrors={clearErrors}
          />
          <VStack
            w="100%"
            maxW="60vw"
            bgColor="realWhite"
            p="3%"
            borderRadius="sm"
            alignItems="start"
            spacing="1rem"
          >
            {initialValues && (
              <>
                <Text textStyle="card" fontSize="1.2rem">
                  Visible to{" "}
                  {getShipmentVisiblityLabel(initialValues.visibility)}
                </Text>
                <Divider />
              </>
            )}
            <Text textStyle="title">Internal Shipment ID</Text>
            <Input
              placeholder="Internal Shipment ID"
              {...register("externalShipmentId", {
                required: false,
              })}
              disabled={isReadOnly}
              autoFocus
            />
            <Divider />
            <ShipmentStopsForm
              formHook={formHook}
              isReadOnly={isReadOnly}
              shipmentStopsFieldArray={shipmentStopsFieldArray}
              isCopying={isCopying}
            />{" "}
            <Divider />
            {ShipmentEquipment}
            <Divider />
            <Text textStyle="title">Shipment Content Details</Text>
            <HStack w="100%" alignItems="end">
              <FormControl>
                <FormLabel>Packaging Unit Type</FormLabel>
                <Select
                  {...register("unitType", {
                    required: {
                      value: true,
                      message: "You must enter a unit type",
                    },
                  })}
                  disabled={isReadOnly}
                  style={{ borderColor: borderStyling(errors.unitType) }}
                >
                  {shipmentContent.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Input
                type="number"
                placeholder="Count of units"
                {...register("unitCount", {
                  required: {
                    value: false,
                    message: "You must enter the unit count",
                  },
                  min: 0,
                  valueAsNumber: true,
                })}
                disabled={isReadOnly}
              />
            </HStack>
            <HStack w="100%">
              <Box w="100%" maxW="49%">
                <MultiSelect
                  overrideStrings={{
                    selectSomeItems: "DOT Hazardous Material Designation",
                  }}
                  disableSearch
                  hasSelectAll={false}
                  value={hazardousMaterial}
                  options={materialOptions}
                  labelledBy="Select"
                  onChange={onChangeMaterial}
                  disabled={isReadOnly}
                />
              </Box>
              <HStack w="100%">
                <Controller
                  render={({ field }) => {
                    return (
                      <NumberFormat
                        decimalScale={2}
                        allowEmptyFormatting={false}
                        prefix={"$"}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        {...field}
                        min={0}
                        disabled={isReadOnly}
                        style={{
                          border: "1px solid",
                          borderColor: errors.value ? "tomato" : "",
                          borderRadius: "8px",
                          width: "100%",
                          minWidth: 0,
                          outline: "2px solid transparent",
                          outlineOffset: "2px",
                          appearance: "none",
                          color: "black",
                          textAlign: "center",
                          height: "2.5rem",
                        }}
                        placeholder="Shipment Value"
                        {...register("value", {
                          valueAsNumber: true,
                        })}
                        onChange={onChangeDollarValue}
                      />
                    );
                  }}
                  name="value"
                  control={formControl}
                />
              </HStack>
            </HStack>
            <Divider />
            <Text textStyle="title">Third Party Billing Information</Text>
            <HStack w="100%">
              <Input
                w="49%"
                placeholder="Attn: Accounts Payable Handler"
                {...register("billingNote", {
                  required: false,
                })}
                disabled={isReadOnly}
              />
              <Input
                w="50%"
                placeholder="Business Name of Accounts Payable Handler"
                {...register("billingAddress.addressName", {
                  required: false,
                })}
                disabled={isReadOnly}
              />
            </HStack>
            <HStack w="100%">
              <Input
                w="49%"
                placeholder="Address 1"
                {...register("billingAddress.address1", {
                  required: false,
                })}
                disabled={isReadOnly}
              />
              <Input
                w="24%"
                placeholder="Zip"
                {...register("billingAddress.postalCode", {
                  required: false,
                })}
                disabled={isReadOnly}
              />
              <Input
                w="25%"
                placeholder="City"
                {...register("billingAddress.city", {
                  required: false,
                })}
                disabled={isReadOnly}
              />
            </HStack>
            <HStack w="100%">
              <Input
                w="49%"
                placeholder="Address 2"
                {...register("billingAddress.address2", {
                  required: false,
                })}
                disabled={isReadOnly}
              />
              <FormCountry
                w="25%"
                isDisabled={isReadOnly}
                formHook={formHook}
                property="billingAddress"
              />
              <FormState
                w="24%"
                isDisabled={isReadOnly}
                formHook={formHook}
                property="billingAddress"
              />
            </HStack>
            <Divider />
            <Text textStyle="title">
              Additional Notes{" "}
              <Tooltip
                label={`Bid Notes will be displayed in the marketplace for Carriers. 
                Tender Notes will be attached to the load tender (driver specific information).`}
                fontSize="md"
                textColor="black"
                placement="right-end"
                layerStyle={"yellow"}
              >
                <InfoIcon h={5} w={5} />
              </Tooltip>
            </Text>
            <HStack w="100%">
              <Textarea
                placeholder="Bid Notes"
                resize="none"
                maxLength={250}
                {...register("bidNotes", {
                  required: false,
                })}
                isDisabled={!showFormButtons || isTrackingPage}
              />
            </HStack>
            <HStack w="100%">
              <Textarea
                placeholder="Tender Notes"
                resize="none"
                maxLength={250}
                {...register("tenderNotes", {
                  required: false,
                })}
                isDisabled={!showFormButtons || isTrackingPage}
              />
            </HStack>
          </VStack>
        </VStack>
      </form>
      <RequestQuoteModal
        isOpen={isRequestOpen}
        onClose={() => toggleRequestOpen(false)}
        onClick={handleQuoteRequest}
      />
      <TenderSelectionModal
        isOpen={isTenderOpen}
        onClose={() => toggleTenderOpen(false)}
        onContinue={handleTenderSelect}
      />
      {tenderCarrier && createdShipment && (
        <TenderPDFModal
          isOpen={showPDF}
          onClose={() => setShowPDF(false)}
          docData={{
            carrierData: tenderCarrier,
            shipmentData: createdShipment,
          }}
        />
      )}
    </Skeleton>
  );
};

export default ShipmentForm;
