import { createContext, useContext } from "react";

interface BidRequests {
  onClickToggle: () => void;
  onClickSetup: () => void;
  onClickTender: () => void;
}

const BidContext = createContext<BidRequests | null>(null);

const useBidContext = (): BidRequests | null => useContext(BidContext);

export { BidContext, useBidContext };
