import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalBody,
  Input,
  Skeleton,
  HStack,
} from "@chakra-ui/react";
import { useRef } from "react";
import { DefaultModalProps } from "../../types/DefaultModal";

interface ReusableUploadFileProps extends DefaultModalProps {
  title: string;
  extensionFile: string;
  handleFileInput: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<void>;
  SummaryCpm?: () => JSX.Element;
  PromptCpm?: () => JSX.Element;
  onClickUpload?: () => Promise<void>;
  fileName: string;
  isLoaded: boolean;
}

export const ReusableBulkUploadFile = ({
  isOpen,
  onClose,
  title,
  extensionFile,
  confirmBtnText,
  handleFileInput,
  onClickUpload,
  isLoaded,
  fileName,
  SummaryCpm,
  PromptCpm,
}: ReusableUploadFileProps): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <Skeleton w="100%" isLoaded={isLoaded}>
          <ModalHeader textStyle="uppercase">{title}</ModalHeader>
          <ModalBody>
            {SummaryCpm?.()}
            <Input
              readOnly
              placeholder="Click to select file"
              onClick={() => fileInputRef.current?.click()}
              value={fileName}
            />
            <Input
              accept={extensionFile}
              type="file"
              hidden
              ref={fileInputRef}
              onChange={handleFileInput}
            />
            {PromptCpm?.()}
          </ModalBody>
          <ModalFooter>
            <HStack w="100%" justifyContent="flex-start">
              <Button layerStyle="red" mr="0.5rem" onClick={onClickUpload}>
                {confirmBtnText}
              </Button>
              <Button
                size="sm"
                color="mvmntRed"
                variant="link"
                onClick={onClose}
              >
                Cancel
              </Button>
            </HStack>
          </ModalFooter>
        </Skeleton>
      </ModalContent>
    </Modal>
  );
};

export default ReusableBulkUploadFile;
