import { CarrierUser } from "./Carrier";
import { MvmntDocument } from "./Files";
import { FmcsaCarrier } from "./FmcsaCarrier";
import { ShipperUser } from "./Shipper";

export enum ConnectionStatus {
  PENDING = "Pending",
  CONNECTED = "Connected",
  SIGNEDOFFLINE = "SignedOffline",
  SIGNEDOFFLINEUPLOADED = "SignedOfflineUploaded",
  NOTSENT = "NotSent",
}

export const ConfirmedStatuses = [
  ConnectionStatus.CONNECTED,
  ConnectionStatus.SIGNEDOFFLINE,
  ConnectionStatus.SIGNEDOFFLINEUPLOADED,
];

export interface NetworkPartner {
  id: string;
  connectionStatus: ConnectionStatus;
  shipper?: ShipperUser;
  carrier?: CarrierUser;
  fmcsaCarrier?: FmcsaCarrier;
  agreement?: MvmntDocument;
}
