import { Text, VStack, HStack, Grid } from "@chakra-ui/react";
import { parseStopDateToLocal } from "../../reuse/Dates";
import { EquipmentType } from "../../types/Equipment";
import { ShipmentStop } from "../../types/Shipment";

interface StopProps {
  stop: ShipmentStop;
  index: number;
  totalWeight: number;
  accesorials: string[];
  equipmentType: EquipmentType;
  displayStopPosition: boolean;
}

const TenderContentStop = ({
  stop,
  index,
  totalWeight,
  accesorials,
  equipmentType,
  displayStopPosition,
}: StopProps): JSX.Element => (
  <VStack w="100%" pl="1rem" alignItems="flex-start">
    {displayStopPosition && (
      <Text fontWeight={600} fontSize={15}>
        Stop {index}
      </Text>
    )}
    <Grid
      fontSize={9}
      fontWeight={400}
      w="100%"
      gridTemplateColumns="1.3fr 1fr 1fr"
    >
      <VStack alignItems="flex-start">
        <Text fontWeight={600} fontSize={11}>
          {stop.address.addressName}
        </Text>
        <Text>{stop.address.address1}</Text>
        <Text>{stop.address.address2}</Text>
        <Text>
          {stop.address.city} , {stop.address.state} {stop.address.postalCode}
        </Text>
        <HStack>
          <Text fontWeight={600} fontSize={11}>
            Total Pickup
          </Text>
          <Text>{totalWeight} lbs</Text>
        </HStack>
      </VStack>

      <VStack alignItems="flex-start">
        <Text fontWeight={600} fontSize={10}>
          PICKUP
        </Text>
        <Text fontWeight={600} fontSize={10}>
          Comodities
        </Text>
        {accesorials.map((accesorial) => (
          <Text key={accesorial}>{accesorial}</Text>
        ))}
        <VStack alignItems="flex-start" pt="1rem">
          <HStack>
            <Text fontWeight={600}>Accesorials</Text>
            <Text>{stop.stopType}</Text>
          </HStack>
          <HStack>
            <Text fontWeight={600}>Notes</Text>
            <Text>{stop.notes}</Text>
          </HStack>
        </VStack>
      </VStack>

      <VStack alignItems="flex-start">
        <HStack w="100%">
          <Text fontWeight={600}>Req Time</Text>
          <Text>
            {`${parseStopDateToLocal(stop.date)} ${stop.dockOpen} - ${
              stop.dockClose
            }`}
          </Text>
        </HStack>
        <VStack alignItems="start">
          <HStack w="100%">
            <Text fontWeight={600}>PO</Text>
          </HStack>
          <HStack w="100%">
            <Text fontWeight={600}>Ref</Text>
            <Text>{stop.referenceNumber}</Text>
          </HStack>
          <HStack w="100%">
            <Text fontWeight={600}>Trailer Type</Text>
            <Text>{equipmentType}</Text>
          </HStack>
          <HStack w="100%">
            <Text fontWeight={600}>Customs</Text>
          </HStack>
        </VStack>
      </VStack>
    </Grid>
  </VStack>
);

export default TenderContentStop;
