import {
  Button,
  FormControl,
  HStack,
  Input,
  VStack,
  Text,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSignUpUserMutation } from "../../api/auth";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import ErrorMessage from "../../components/ErrorMessage";
import SignInPageHOC from "../../components/SignInPageHOC/SignInPageHOC";
import { CarrierSignUp } from "../../types/Carrier";
import AppRoutes from "../../routes";
import {
  useCreateCarrierMutation,
  useValidateDotNumberCarrierMutation,
} from "../../api/carrier";
import {
  noContent,
  validateEmail,
  validatePassword,
  validatePhoneNumber,
} from "../../reuse/UserData";
import useToastHook from "../../components/useToastHook";
import { RegexPoliceProp } from "../../types/Regex";
import RegexPolicesList from "../../components/RegexPolicesList";
import { borderStyling } from "../../reuse/Forms";
import { FormError } from "../../types/FormError";

interface SignUpFormData extends CarrierSignUp {
  password: string;
  confirmPassword: string;
}

const FormHeader = (): JSX.Element => (
  <Text mt="4vh" fontSize="1.5rem" color="white">
    Carrier Sign Up
  </Text>
);

const SignUp = (): JSX.Element => {
  const {
    control,
    register,
    watch,
    handleSubmit,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<SignUpFormData>();
  const navigate = useNavigate();
  const [regexList, setRegexList] = useState<[] | RegexPoliceProp[]>([]);
  const [signUpCarrier, { isLoading: isSigningUp }] = useSignUpUserMutation();
  const [createCarrier, { isLoading: isCreatingCarrier }] =
    useCreateCarrierMutation();
  const [validateCarrier, { isLoading: isValidatingCarrier }] =
    useValidateDotNumberCarrierMutation();

  const isLoading = isSigningUp || isCreatingCarrier || isValidatingCarrier;

  const toast = useToastHook();

  const password = getValues().password;
  const passwordNoContent = noContent(password);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "password") {
        const regexList = validatePassword(value.password!);
        setRegexList(regexList);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleSignUp = async (signUpData: SignUpFormData): Promise<void> => {
    const {
      user,
      password,
      companyPhoneNumber,
      companyName,
      mcNumber,
      dotNumber,
    } = signUpData;

    const validationResponse = await validateCarrier({
      companyName,
      mcNumber,
      dotNumber,
      companyPhoneNumber,
      user: { ...user },
    });

    if ("error" in validationResponse) {
      toast.error({
        description:
          "DOT Number is not valid. Please check your DOT Number and try again.",
      });
    } else {
      const response = await signUpCarrier({
        email: user.email,
        password,
        companyPhoneNumber,
      });

      if ("data" in response) {
        const apiResponse = await createCarrier({
          companyName,
          mcNumber,
          dotNumber,
          companyPhoneNumber,
          user: { ...user, authToken: response.data },
        });
        if ("data" in apiResponse) {
          toast.success({
            description:
              "Confirmation email sent. Please confirm your account via email",
          });
          navigate(AppRoutes.LOGIN);
        } else {
          toast.error({
            description: `${parseErrorResponse(apiResponse.error)}`,
          });
        }
      } else {
        toast.error({
          description: `${parseErrorResponse(response.error)}`,
        });
      }
    }
  };

  const onSubmit = handleSubmit(handleSignUp);

  const onClickBack = (): void => {
    navigate(AppRoutes.LOGIN);
  };

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing="1rem">
        <FormControl isDisabled={isLoading}>
          <Input
            placeholder="Company Name"
            {...register("companyName", {
              required: {
                value: true,
                message: "You must enter the company's name",
              },
            })}
            style={{ borderColor: borderStyling(errors?.companyName) }}
          />
        </FormControl>

        <HStack>
          <FormControl isDisabled={isLoading}>
            <Input
              placeholder="MC Number"
              {...register("mcNumber", {
                required: {
                  value: true,
                  message: "You must enter the MC number",
                },
              })}
              style={{ borderColor: borderStyling(errors?.mcNumber) }}
            />
          </FormControl>

          <FormControl isDisabled={isLoading}>
            <Input
              placeholder="DOT Number"
              {...register("dotNumber", {
                required: {
                  value: true,
                  message: "You must enter the DOT number",
                },
              })}
              style={{ borderColor: borderStyling(errors?.dotNumber) }}
            />
          </FormControl>
        </HStack>

        <HStack>
          <FormControl isDisabled={isLoading}>
            <Input
              placeholder="Contact First Name"
              {...register("user.firstName", {
                required: {
                  value: true,
                  message: "You must enter the contact's first name",
                },
              })}
              style={{ borderColor: borderStyling(errors?.user?.firstName) }}
            />
          </FormControl>

          <FormControl isDisabled={isLoading}>
            <Input
              placeholder="Contact Last Name"
              {...register("user.lastName", {
                required: {
                  value: true,
                  message: "You must enter the contact's last name",
                },
              })}
              style={{ borderColor: borderStyling(errors?.user?.lastName) }}
            />
          </FormControl>
        </HStack>

        <FormControl isDisabled={isLoading}>
          <Controller
            control={control}
            name="companyPhoneNumber"
            rules={{
              required: {
                value: true,
                message: "You must enter the company phone number",
              },
              validate: (value) => validatePhoneNumber(value),
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <PhoneInput
                placeholder="Phone Number"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                control={control as any}
                name={name}
                value={value}
                onChange={onChange}
                country="US"
                style={{
                  borderColor: borderStyling(error),
                  opacity: isLoading ? 0.4 : 1,
                }}
              />
            )}
          />
        </FormControl>

        <FormControl isDisabled={isLoading}>
          <Input
            placeholder="Email Address"
            {...register("user.email", {
              required: {
                value: true,
                message: "You must enter your email",
              },
              validate: validateEmail,
            })}
            style={{ borderColor: borderStyling(errors?.user?.email) }}
          />
        </FormControl>

        <HStack>
          <FormControl isDisabled={isLoading}>
            <Input
              type="password"
              placeholder="Password"
              {...register("password", {
                required: {
                  value: true,
                  message: "You must enter your password",
                },
              })}
              style={{ borderColor: borderStyling(errors?.password) }}
            />
          </FormControl>

          <FormControl isDisabled={isLoading}>
            <Input
              type="password"
              placeholder="Confirm Password"
              {...register("confirmPassword", {
                required: {
                  value: true,
                  message: "You must confirm your password",
                },
                validate: (confirm) =>
                  confirm === getValues().password
                    ? true
                    : "Passwords don't match",
              })}
              style={{ borderColor: borderStyling(errors?.confirmPassword) }}
            />
          </FormControl>
        </HStack>

        {!passwordNoContent && (
          <RegexPolicesList regexList={regexList} columns={"1fr 1fr"} />
        )}

        <Button
          isDisabled={isLoading}
          mt="2rem !important"
          layerStyle="red"
          w="50%"
          onClick={onSubmit}
        >
          Sign Up
        </Button>

        <Button layerStyle="yellow" w="50%" onClick={onClickBack}>
          Back
        </Button>

        <ErrorMessage errors={errors as FormError} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default SignInPageHOC(SignUp, FormHeader);
