import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
} from "@chakra-ui/react";
import { DefaultModalProps } from "../../types/DefaultModal";

interface CreateShipmentModalProps extends DefaultModalProps {
  title: string;
  content: string;
  onClickCTA: () => void;
}

export const CreateShipmentModal = ({
  isOpen,
  onClose,
  onClickCTA,
  title,
  content,
}: CreateShipmentModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="uppercase">{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{content}</Text>
        </ModalBody>
        <ModalFooter justifyContent="space-around">
          <Button layerStyle="yellow" onClick={onClickCTA}>
            Create New Shipment
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateShipmentModal;
