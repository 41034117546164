import { useForm, useFieldArray, UseFormRegister } from "react-hook-form";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Input,
  Skeleton,
  ModalBody,
  Select,
  VStack,
  HStack,
  Button,
  FormControl,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { ILaneCreate, LaneForm } from "../../types/Rfp";
import ErrorMessage from "../../components/ErrorMessage";
import { FormError } from "../../types/FormError";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { useCreateRfpLaneMutation } from "../../api/rfp";
import { EquipmentType } from "../../types/Equipment";
import { LoadType } from "../../types/ShipmentForm";
import { FrequencyType } from "../../types/Frequencty";

const MandatoryStops = (register: UseFormRegister<LaneForm>): JSX.Element => (
  <>
    <HStack m="0.4rem 0">
      <Input
        w="45%"
        {...register("origin.city", {
          required: {
            value: true,
            message: "You must enter the city of origin",
          },
        })}
        placeholder="Origin City"
      />
      <Input
        w="20%"
        {...register("origin.state", {
          required: {
            value: true,
            message: "You must enter the state of origin",
          },
        })}
        placeholder="State"
      />
      <Input
        w="35%"
        {...register("origin.postalCode", {
          required: {
            value: true,
            message: "You must enter the zipcode of origin",
          },
        })}
        placeholder="Zip Code"
      />
    </HStack>
    <HStack>
      <Input
        w="45%"
        {...register("destination.city", {
          required: {
            value: true,
            message: "You must enter the city of destination",
          },
        })}
        placeholder="Destination City"
      />
      <Input
        w="20%"
        {...register("destination.state", {
          required: {
            value: true,
            message: "You must enter the state of destination",
          },
        })}
        placeholder="State"
      />
      <Input
        w="35%"
        {...register("destination.postalCode", {
          required: {
            value: true,
            message: "You must enter the zipcode of destination",
          },
        })}
        placeholder="Zip Code"
      />
    </HStack>
  </>
);
const LaneDetails = (register: UseFormRegister<LaneForm>): JSX.Element => (
  <>
    <HStack m="2rem 0 0.4rem 0">
      <Input
        w="35%"
        {...register("mileage", {
          required: {
            value: true,
            message: "You must enter the mileage",
          },
        })}
        placeholder="Mileage"
      />
      <Input
        w="40%"
        {...register("volume", {
          required: {
            value: true,
            message: "You must enter the volume",
          },
        })}
        placeholder="Volume"
      />
      <Select
        w="35%"
        {...register("frequency", {
          required: {
            value: true,
            message: "You must enter the frequency",
          },
        })}
      >
        {Object.values(FrequencyType).map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Select>
    </HStack>
    <HStack>
      <Input
        w="35%"
        {...register("mode", {
          required: {
            value: true,
            message: "You must enter the mode",
          },
        })}
        placeholder="Mode"
      />
      <Select
        w="40%"
        {...register("equipmentType", {
          required: {
            value: true,
            message: "You must enter the equipment type",
          },
        })}
      >
        {Object.values(EquipmentType).map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Select>
      <Input
        w="35%"
        {...register("equipmentSize", {
          required: {
            value: true,
            message: "You must enter the equipment size",
          },
        })}
        placeholder="Equipment Size"
      />
    </HStack>
  </>
);

interface AddLaneFormProps {
  isOpen: boolean;
  onClose: () => void;
  toggleBulkUpload: () => void;
  rfpId: string | undefined;
}

export const AddLaneForm = ({
  isOpen,
  onClose,
  toggleBulkUpload,
  rfpId,
}: AddLaneFormProps): JSX.Element => {
  const toast = useToast();
  const [createLane, { isLoading: isSubmiting }] = useCreateRfpLaneMutation();

  const {
    register,
    handleSubmit,
    control,
    watch,
    clearErrors,
    formState: { errors },
    getValues,
  } = useForm<LaneForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "additionalParameters",
  });

  const watchFieldArray = watch("additionalParameters");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray?.[index],
    };
  });

  const handleNext = async (): Promise<void> => {
    const {
      additionalStops,
      additionalNotes,
      additionalParameters,
      mileage,
      equipmentType,
      equipmentSize,
      frequency,
      mode,
      volume,
      origin,
      destination,
      externalLaneId,
    } = getValues();

    const laneToAddFormatted: ILaneCreate = {
      laneStops: [
        { ...origin, loadType: LoadType.Drop },
        { ...destination, loadType: LoadType.Drop },
      ],
      notes: additionalNotes,
      additionalStops,
      mileage,
      equipmentType,
      equipmentSize,
      mode,
      volume,
      frequency,
      additionalParameters,
      externalLaneId,
    };

    const response = await createLane({
      createLane: laneToAddFormatted,
      rfpId: rfpId!,
    });

    if ("data" in response) {
      toast({
        status: "success",
        description: "Lane Created.",
        duration: 3000,
      });
      onClose();
    } else {
      toast({
        status: "error",
        description: parseErrorResponse(response.error),
        duration: 3000,
      });
    }
  };

  const onSubmitNext = handleSubmit(handleNext);

  const renderAdditionalParameters = (): JSX.Element[] =>
    controlledFields.map((field, index) => {
      return (
        <FormControl key={index} m="0.4rem 0">
          <HStack m="0.4rem 0">
            <Input
              w="45%"
              {...register(`additionalParameters.${index}.label` as const)}
              placeholder="label"
            />
            <Input
              w="20%"
              {...register(`additionalParameters.${index}.value` as const)}
              placeholder="value"
            />
            <Button
              ml="25px"
              variant="link"
              color="mvmntRed"
              onClick={() => remove(index)}
            >
              remove
            </Button>
          </HStack>
        </FormControl>
      );
    });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
      isCentered
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent p="1rem">
        <Skeleton isLoaded={!false && !false} w="100%">
          <ModalHeader textStyle="uppercase" fontSize="25px">
            ADD LANE
          </ModalHeader>
          <form onSubmit={onSubmitNext}>
            <ModalBody>
              <VStack>
                <FormControl m="0.4rem 0" isDisabled={isSubmiting}>
                  <Input
                    w="43%"
                    {...register("externalLaneId", {
                      required: false,
                    })}
                    placeholder="Lane ID (optional)"
                  />
                  {MandatoryStops(register)}
                  <HStack m="0.6rem 0">
                    <Textarea
                      {...register("additionalStops")}
                      placeholder="Additional Stops"
                    />
                  </HStack>
                  {LaneDetails(register)}
                </FormControl>
                {renderAdditionalParameters()}
              </VStack>
              <Button
                alignSelf="start"
                variant="link"
                color="mvmntRed"
                onClick={() =>
                  append({
                    label: "",
                    value: "",
                  })
                }
              >
                + Additional Parameter
              </Button>
              <HStack m="2rem 0">
                <Textarea
                  {...register("additionalNotes")}
                  placeholder="Additional Notes"
                />
              </HStack>
            </ModalBody>
            <ModalFooter mb="1rem" justifyContent="start">
              <HStack w="100%" justifyContent="space-between">
                <HStack>
                  <Button
                    layerStyle="red"
                    mr="20px"
                    p="0 2rem"
                    onClick={onSubmitNext}
                  >
                    ADD
                  </Button>
                  <Button
                    size="sm"
                    color="mvmntRed"
                    variant="link"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </HStack>
                <Button
                  layerStyle="yellow"
                  color="black"
                  onClick={toggleBulkUpload}
                >
                  BULK UPLOAD
                </Button>
              </HStack>
            </ModalFooter>
            <ErrorMessage
              errors={errors as FormError}
              clearErrors={clearErrors}
            />
          </form>
        </Skeleton>
      </ModalContent>
    </Modal>
  );
};

export default AddLaneForm;
