import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface TermsAndConditionLink {
  name: string;
  description: string;
  link: string;
}

interface OnboardingPrincipal {
  is_applicant?: boolean;
  job_title?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  date_of_birth?: string;
  address?: {
    street?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
  };
  percentage_shareholding?: number;
  is_control_prong?: boolean;
  ssn?: string;
}

export interface OnboardingForm {
  validation_errors?: string[];
  accept_terms_and_conditions?: boolean;
  terms_and_conditions_links?: TermsAndConditionLink[];
  business_legal_entity?: {
    type?: string;
    name?: string;
    legal_name?: string;
    tax_identification_number?: string;
    address?: {
      street?: string;
      street2?: string;
      city?: string;
      state?: string;
      zip?: string;
      country?: string;
    };
    category?: string;
    phone?: string;
    currency?: string;
    yearly_volume_range?: string;
    average_transaction_amount?: number;
    region?: string;
    locale?: string;
    statement_descriptor?: string;
    website?: string;
    principals?: OnboardingPrincipal[];
    bank_account?: {
      account_number?: string;
      routing_number?: string;
    };
  };
}

interface UpdateOnboardingFormProps {
  id: string;
  body: OnboardingForm;
}

export const tilledApi = createApi({
  reducerPath: "tilledApiReducer",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_TILLED_URL }),
  tagTypes: ["Tilled"],
  endpoints: (builder) => ({
    getOnboardingForm: builder.mutation<OnboardingForm, string>({
      query: (id) => ({
        url: `/v1/applications/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Tilled"],
    }),
    updateOnboardingForm: builder.mutation<
      OnboardingForm,
      UpdateOnboardingFormProps
    >({
      query: (input) => ({
        url: `/v1/applications/${input.id}`,
        method: "PUT",
        body: input.body,
      }),
      invalidatesTags: ["Tilled"],
    }),
    submitOnboardingForm: builder.mutation<void, string>({
      query: (id) => ({
        url: `/v1/applications/${id}/submit`,
        method: "POST",
      }),
      invalidatesTags: ["Tilled"],
    }),
  }),
});

export const {
  useGetOnboardingFormMutation,
  useUpdateOnboardingFormMutation,
  useSubmitOnboardingFormMutation,
} = tilledApi;
