import { Skeleton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useGetNetworkShippersQuery } from "../../api/network";
import AddNetworkShipper from "../../components/AddNetworkShipper";
import { sortText } from "../../reuse/Sorting";
import Network from "../Network";

export const CarrierNetwork = (): JSX.Element => {
  const [isAddModalOpen, toggleAddModal] = useState(false);
  const { data: network, isLoading } = useGetNetworkShippersQuery();

  const partners = network ? [...network] : [];

  useEffect(() => {
    sortPartners(true);
  }, [network]);

  const sortPartners = (asc: boolean): void => {
    partners.sort((a, b) =>
      sortText(
        a.shipper!.contactInformation.companyName,
        b.shipper!.contactInformation.companyName,
        asc,
      ),
    );
  };

  return (
    <Skeleton isLoaded={!isLoading} w="100%" h="100%">
      <Network
        partners={partners}
        toggleAddModal={toggleAddModal}
        sortPartners={sortPartners}
      />
      <AddNetworkShipper
        isOpen={isAddModalOpen}
        onClose={() => toggleAddModal(false)}
      />
    </Skeleton>
  );
};

export default CarrierNetwork;
