import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  Table,
  TableOptions,
  getSortedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getFacetedRowModel,
  FilterFn,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  TableContainer,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Box,
} from "@chakra-ui/react";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import type { Optional } from "../../../reuse/Types";
interface IDataTable<T> {
  table: Table<T>;
  showFooter?: boolean;
}

/**
 * Similar to the default useReactTable, but provides defaults for getCoreRowModel, getSortedRowModel, and filtering.
 */
export function useDataTable<T>(
  options: Optional<TableOptions<T>, "getCoreRowModel">,
): Table<T> {
  const fuzzyFilter: FilterFn<T> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };
  return useReactTable({
    ...options,
    getCoreRowModel: options.getCoreRowModel ?? getCoreRowModel(),
    // Sorting
    getSortedRowModel: options.getSortedRowModel ?? getSortedRowModel(),
    // <Filtering></Filtering>
    getFacetedUniqueValues:
      options.getFacetedUniqueValues ?? getFacetedUniqueValues(),
    getFacetedRowModel: options.getFacetedRowModel ?? getFacetedRowModel(),
    getFilteredRowModel: options.getFilteredRowModel ?? getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
  });
}

export function DataTable<T>({
  table,
  showFooter = false,
}: IDataTable<T>): JSX.Element {
  return (
    <TableContainer w="100%">
      <ChakraTable variant="simple">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {header.isPlaceholder ? null : (
                    <Box
                      as="span"
                      onClick={header.column.getToggleSortingHandler()}
                      cursor={
                        header.column.getCanSort() ? "pointer" : undefined
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: (
                          <ArrowUpIcon
                            w="16px"
                            h="16px"
                            marginLeft="3px"
                            marginTop="-2px"
                          />
                        ),
                        desc: (
                          <ArrowDownIcon
                            w="16px"
                            h="16px"
                            marginLeft="3px"
                            marginTop="-2px"
                          />
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
        {showFooter && (
          <Tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <Tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Tfoot>
        )}
      </ChakraTable>
    </TableContainer>
  );
}
