export const zipCodes: { [key: string]: string[] } = {
  "Birmingham Mkt": ["350", "351", "352", "354", "362"],
  "Decatur Mkt": ["355", "356", "357", "358", "384", "388"],
  "Mobile Mkt": ["325", "364", "365", "366", "394", "395"],
  "Montgomery Mkt": ["318", "319", "360", "361", "363", "367", "368", "369"],
  "Fayetteville Mkt": ["726", "727", "729", "749"],
  "Little Rock Mkt": ["716", "717", "719", "720", "721", "722", "725", "728"],
  "Flagstaff Mkt": ["855", "859", "860", "863", "864", "865"],
  "Phoenix Mkt": ["850", "851", "852", "853"],
  "Tucson Mkt": ["856", "857"],
  "Fresno Mkt": ["931", "932", "933", "934", "935", "936", "937", "938"],
  "Los Angeles Mkt": [
    "900",
    "901",
    "902",
    "903",
    "904",
    "905",
    "906",
    "907",
    "908",
    "910",
    "911",
    "912",
    "913",
    "914",
    "915",
    "916",
    "918",
    "926",
    "927",
    "928",
    "930",
  ],
  "Ontario Mkt": ["917", "922", "923", "924", "925"],
  "San Diego Mkt": ["919", "920", "921"],
  "San Francisco Mkt": [
    "939",
    "940",
    "941",
    "945",
    "946",
    "947",
    "948",
    "949",
    "950",
    "951",
    "954",
  ],
  "Stockton Mkt": [
    "942",
    "943",
    "944",
    "952",
    "953",
    "956",
    "957",
    "958",
    "959",
  ],
  "Denver Mkt": [
    "800",
    "801",
    "802",
    "803",
    "804",
    "805",
    "806",
    "808",
    "809",
    "810",
    "820",
  ],
  "Grand Junction Mkt": ["812", "813", "814", "815", "816", "845"],
  "Hartford Mkt": [
    "060",
    "061",
    "062",
    "063",
    "064",
    "065",
    "066",
    "067",
    "068",
    "069",
    "105",
    "106",
    "107",
    "108",
    "109",
  ],
  "Alexandria Mkt": [
    "200",
    "201",
    "202",
    "203",
    "204",
    "205",
    "206",
    "220",
    "221",
    "222",
    "223",
    "227",
    "569",
  ],
  "Greensboro Mkt": ["270", "271", "272", "273", "274"],
  "Jacksonville Mkt": ["320", "321", "322", "326"],
  "Lakeland Mkt": [
    "327",
    "328",
    "329",
    "335",
    "336",
    "337",
    "338",
    "342",
    "344",
    "346",
    "347",
  ],
  "Miami Mkt": ["330", "331", "332", "333", "334", "339", "341", "349"],
  "Tallahassee Mkt": ["323", "324"],
  "Atlanta Mkt": ["300", "301", "302", "303", "305", "306", "311", "399"],
  "Macon Mkt": ["310", "312"],
  "Savannah Mkt": ["299", "304", "313", "314", "315"],
  "Tifton Mkt": ["316", "317", "398"],
  "Cedar Rapids Mkt": ["522", "523", "524"],
  "Des Moines Mkt": [
    "500",
    "501",
    "502",
    "503",
    "504",
    "505",
    "508",
    "509",
    "514",
  ],
  "Dubuque Mkt": ["506", "507", "520", "521", "538"],
  "Twin Falls Mkt": ["832", "833", "834", "836", "837", "979"],
  "Bloomington Mkt": ["609", "615", "616", "617", "618"],
  "Chicago Mkt": ["600", "601", "602", "603", "606", "607", "608"],
  "Joliet Mkt": ["604", "605"],
  "Quincy Mkt": ["623", "634", "635"],
  "Rockford Mkt": ["610", "611"],
  "Rock Island Mkt": ["525", "526", "527", "528", "612", "613", "614"],
  "Taylorville Mkt": ["625", "626", "627"],
  "Evansville Mkt": ["423", "424", "475", "476", "477", "628"],
  "Ft Wayne Mkt": ["467", "468", "469"],
  "Gary Mkt": ["463", "464"],
  "Indianapolis Mkt": ["460", "461", "462", "472", "473", "474", "479"],
  "S Bend Mkt": ["465", "466", "491"],
  "Terre Haute Mkt": ["478", "619", "624"],
  "Hutchinson Mkt": [
    "668",
    "669",
    "670",
    "671",
    "672",
    "673",
    "674",
    "675",
    "676",
    "677",
    "678",
    "679",
  ],
  "Bowling Green Mkt": ["421", "422", "427"],
  "Lexington Mkt": [
    "403",
    "404",
    "405",
    "406",
    "410",
    "413",
    "414",
    "417",
    "418",
    "419",
  ],
  "Louisville Mkt": ["400", "401", "402", "471"],
  "New Orleans Mkt": ["700", "701", "703", "704", "705", "706", "707", "708"],
  "Shreveport Mkt": ["710", "711", "712", "713", "714", "756", "759"],
  "Boston Mkt": [
    "014",
    "015",
    "016",
    "017",
    "018",
    "019",
    "020",
    "021",
    "022",
    "023",
    "024",
    "025",
    "026",
    "027",
    "028",
    "029",
    "055",
  ],
  "Springfield Mkt": ["010", "011", "012", "013"],
  "Baltimore Mkt": [
    "197",
    "198",
    "199",
    "207",
    "208",
    "209",
    "210",
    "211",
    "212",
    "214",
    "216",
    "218",
    "219",
  ],
  "Augusta Mkt": [
    "038",
    "039",
    "040",
    "041",
    "042",
    "043",
    "044",
    "045",
    "046",
    "047",
    "048",
    "049",
  ],
  "Detroit Mkt": ["480", "481", "482", "483", "484", "485", "492"],
  "Grand Rapids Mkt": ["488", "489", "490", "493", "494", "495", "496"],
  "Saginaw Mkt": ["486", "487", "497"],
  "Duluth Mkt": ["498", "499", "545", "548", "556", "557", "558"],
  "Minneapolis Mkt": ["550", "551", "553", "554", "555", "560"],
  "St Cloud Mkt": ["563", "564"],
  "Cape Girardeau Mkt": ["420", "629", "636", "637", "638", "639"],
  "Jefferson City Mkt": ["650", "651", "652", "653"],
  "Joplin Mkt": ["647", "648", "654", "655", "656", "657", "658", "667"],
  "Kansas City Mkt": [
    "640",
    "641",
    "644",
    "645",
    "646",
    "649",
    "660",
    "661",
    "662",
    "664",
    "665",
    "666",
  ],
  "St Louis Mkt": ["620", "622", "630", "631", "633"],
  "Jackson Mkt": ["387", "389", "390", "391", "392", "393", "396", "397"],
  "Billings Mkt": ["590", "592", "591", "593", "821", "824", "828", "830"],
  "Missoula Mkt": ["594", "595", "596", "597", "598", "599"],
  "Charlotte Mkt": ["280", "281", "282", "283", "286", "297"],
  "Raleigh Mkt": ["275", "276", "277", "278"],
  "Wilmington Mkt": ["284", "285"],
  "Fargo Mkt": ["565", "566", "567", "580", "581", "582"],
  "Bismarck Mkt": ["583", "584", "585", "586", "587", "588"],
  "N Platte Mkt": ["690", "691", "692", "693", "807", "822"],
  "Omaha Mkt": [
    "515",
    "516",
    "680",
    "681",
    "683",
    "684",
    "685",
    "686",
    "687",
    "688",
    "689",
  ],
  "Bristol Mkt": [
    "030",
    "031",
    "032",
    "033",
    "034",
    "035",
    "036",
    "037",
    "050",
    "051",
    "056",
    "057",
    "058",
    "059",
  ],
  "Elizabeth Mkt": [
    "070",
    "071",
    "072",
    "073",
    "074",
    "075",
    "076",
    "077",
    "078",
    "079",
    "085",
    "086",
    "087",
    "088",
    "089",
  ],
  "Albuquerque Mkt": [
    "811",
    "870",
    "871",
    "872",
    "873",
    "874",
    "875",
    "877",
    "878",
    "879",
    "883",
    "884",
  ],
  "Las Vegas Mkt": ["889", "890", "891", "893"],
  "Reno Mkt": ["894", "895", "897", "898", "961"],
  "Albany Mkt": [
    "052",
    "053",
    "054",
    "120",
    "121",
    "122",
    "123",
    "124",
    "125",
    "126",
    "127",
    "128",
    "129",
  ],
  "Brooklyn Mkt": [
    "100",
    "101",
    "102",
    "103",
    "104",
    "110",
    "111",
    "112",
    "113",
    "114",
    "115",
    "116",
    "117",
    "118",
    "119",
  ],
  "Buffalo Mkt": ["140", "141", "142", "143", "147"],
  "Elmira Mkt": ["137", "138", "139", "148", "149", "169", "188"],
  "Rochester Mkt": ["144", "145", "146"],
  "Syracuse Mkt": ["130", "131", "132", "133", "134", "135", "136"],
  "Cincinnati Mkt": ["450", "451", "452", "459", "470"],
  "Cleveland Mkt": [
    "440",
    "441",
    "442",
    "443",
    "444",
    "445",
    "446",
    "447",
    "448",
    "449",
  ],
  "Columbus Mkt": [
    "430",
    "431",
    "432",
    "433",
    "437",
    "438",
    "453",
    "454",
    "455",
  ],
  "Toledo Mkt": ["434", "435", "436", "458"],
  "Oklahoma City Mkt": [
    "730",
    "731",
    "734",
    "735",
    "736",
    "737",
    "738",
    "745",
    "747",
    "748",
  ],
  "Tulsa Mkt": ["740", "741", "743", "744", "746"],
  "Medford Mkt": ["955", "960", "974", "975", "976", "977"],
  "Pendleton Mkt": ["978", "989", "993"],
  "Portland Mkt": ["970", "971", "972", "973", "986"],
  "Allentown Mkt": [
    "180",
    "181",
    "182",
    "183",
    "184",
    "185",
    "186",
    "187",
    "189",
  ],
  "Erie Mkt": ["163", "164", "165", "167"],
  "Harrisburg Mkt": [
    "166",
    "168",
    "170",
    "171",
    "172",
    "173",
    "174",
    "175",
    "176",
    "177",
    "178",
    "179",
  ],
  "Philadelphia Mkt": [
    "080",
    "081",
    "082",
    "083",
    "084",
    "190",
    "191",
    "192",
    "193",
    "194",
    "195",
    "196",
  ],
  "Pittsburgh Mkt": [
    "150",
    "151",
    "152",
    "153",
    "154",
    "155",
    "156",
    "157",
    "158",
    "159",
    "160",
    "161",
    "162",
    "260",
    "265",
    "439",
  ],
  "N Charleston Mkt": ["294"],
  "Columbia Mkt": ["290", "291", "292", "295"],
  "Greenville Mkt": ["287", "288", "293", "296", "298", "308", "309"],
  "Rapid City Mkt": ["575", "576", "577", "827", "826"],
  "Sioux Falls Mkt": [
    "510",
    "511",
    "512",
    "513",
    "561",
    "562",
    "570",
    "571",
    "572",
    "573",
    "574",
  ],
  "Chattanooga Mkt": ["289", "307", "359", "373", "374"],
  "Knoxville Mkt": [
    "242",
    "376",
    "377",
    "378",
    "379",
    "407",
    "408",
    "409",
    "425",
    "426",
  ],
  "Memphis Mkt": ["375", "380", "381", "382", "383", "386", "723", "724"],
  "Nashville Mkt": ["370", "371", "372", "385"],
  "Amarillo Mkt": ["739", "790", "791"],
  "Austin Mkt": ["733", "765", "768", "786", "787", "789"],
  "Dallas Mkt": ["750", "751", "752", "753", "754", "757", "758"],
  "El Paso Mkt": ["798", "799", "880", "885"],
  "Ft Worth Mkt": ["760", "761", "762", "763", "764", "766", "767"],
  "Houston Mkt": ["770", "772", "773", "774", "775", "776", "777", "778"],
  "Laredo Mkt": ["780", "788"],
  "Lubbock Mkt": [
    "769",
    "792",
    "793",
    "794",
    "795",
    "796",
    "797",
    "881",
    "882",
  ],
  "McAllen Mkt": ["783", "784", "785"],
  "San Antonio Mkt": ["779", "781", "782"],
  "Texarkana Mkt": ["718", "755"],
  "Salt Lake City Mkt": ["840", "841", "842", "843", "844", "846", "847"],
  "Norfolk Mkt": ["233", "234", "235", "236", "237", "279"],
  "Richmond Mkt": ["224", "225", "229", "230", "231", "232", "238"],
  "Roanoke Mkt": ["239", "240", "241", "243", "244", "245"],
  "Winchester Mkt": ["215", "217", "226", "228", "254", "267", "268"],
  "Seattle Mkt": ["980", "981", "982", "983", "984", "985"],
  "Spokane Mkt": ["835", "838", "988", "990", "991", "992", "994"],
  "Eau Claire Mkt": ["540", "546", "547", "559"],
  "Green Bay Mkt": ["541", "542", "543", "544", "549"],
  "Madison Mkt": ["535", "537", "539"],
  "Milwaukee Mkt": ["530", "531", "532", "534"],
  "Charleston Mkt": [
    "246",
    "247",
    "248",
    "249",
    "250",
    "251",
    "252",
    "253",
    "256",
    "258",
    "259",
    "261",
    "262",
    "263",
    "264",
    "266",
    "415",
    "416",
    "457",
  ],
  "Huntington Mkt": ["255", "257", "411", "412", "456"],
  "Green River Mkt": ["823", "825", "829", "831"],
  "St Johns Mkt": ["A0", "A1", "A2", "A5", "A8"],
  "Halifax Mkt": ["B0", "B1", "B2", "B3", "B4", "B5", "B6", "B9"],
  "Moncton Mkt": [
    "E0",
    "E1",
    "E2",
    "E3",
    "E4",
    "E5",
    "E6",
    "E7",
    "E8",
    "E9",
    "C0",
    "C1",
  ],
  "Quebec Mkt": ["G0", "G1", "G2", "G3", "G4", "G5", "G6", "G7", "G8", "G9"],
  "Montreal Mkt": [
    "H0",
    "H1",
    "H2",
    "H3",
    "H4",
    "H5",
    "H7",
    "H8",
    "H9",
    "J0",
    "J1",
    "J2",
    "J3",
    "J4",
    "J5",
    "J6",
    "J7",
    "J8",
    "J9",
  ],
  "Ottawa Mkt": ["K0", "K1", "K2", "K4", "K6", "K7", "K8", "K9"],
  "Toronto Mkt": [
    "L0",
    "L1",
    "L2",
    "L3",
    "L4",
    "L5",
    "L6",
    "L7",
    "L8",
    "L9",
    "M0",
    "M1",
    "M2",
    "M3",
    "M4",
    "M5",
    "M6",
    "M7",
    "M8",
    "M9",
  ],
  "London Mkt": ["N0", "N1", "N2", "N3", "N4", "N5", "N6", "N7", "N8", "N9"],
  "Sudbury Mkt": ["P0", "P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9"],
  "Winnipeg Mkt": ["R0", "R1", "R2", "R3", "R4", "R5", "R6", "R7", "R8", "R9"],
  "Regina Mkt": ["S0", "S2", "S3", "S4", "S6", "S7", "S9"],
  "Calgary Mkt": ["T0", "T1", "T2", "T3", "T4", "T5", "T6", "T7", "T8", "T9"],
  "Vancouver Mkt": [
    "V0M",
    "V0N",
    "V0P",
    "V0R",
    "V0S",
    "V0X",
    "V1M",
    "V2P",
    "V2R",
    "V2S",
    "V2T",
    "V2V",
    "V2W",
    "V2X",
    "V2Y",
    "V2Z",
    "V3",
    "V4A",
    "V4B",
    "V4C",
    "V4E",
    "V4G",
    "V4K",
    "V4L",
    "V4M",
    "V4N",
    "V4P",
    "V4R",
    "V4S",
    "V4W",
    "V4X",
    "V4Z",
    "V5",
    "V6",
    "V7",
    "V8A",
    "V8B",
    "V8C",
    "V8K",
    "V8L",
    "V8M",
    "V8N",
    "V8P",
    "V8R",
    "V8S",
    "V8T",
    "V8V",
    "V8W",
    "V8X",
    "V8Y",
    "V8Z",
    "V9",
  ],
  "Prince George Mkt": [
    "V0A",
    "V0B",
    "V0C",
    "V0E",
    "V0G",
    "V0H",
    "V0J",
    "V0K",
    "V0L",
    "V0T",
    "V0V",
    "V0W",
    "V1A",
    "V1B",
    "V1C",
    "V1E",
    "V1G",
    "V1H",
    "V1J",
    "V1K",
    "V1L",
    "V1N",
    "V1P",
    "V1R",
    "V1S",
    "V1T",
    "V1V",
    "V1W",
    "V1X",
    "V1Y",
    "V1Z",
    "V2A",
    "V2B",
    "V2C",
    "V2E",
    "V2G",
    "V2H",
    "V2J",
    "V2K",
    "V2L",
    "V2M",
    "V2N",
    "V4T",
    "V4V",
    "V8G",
    "V8J",
  ],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const geoJson: { [key: string]: any } = {
  "Elizabeth Mkt": {
    type: "Feature",
    properties: { code: "NJ_ELI", name: "Elizabeth Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-75.1918, 40.7558],
          [-75.1716, 40.7777],
          [-75.1344, 40.7738],
          [-75.1085, 40.7911],
          [-75.0839, 40.8245],
          [-75.0958, 40.8471],
          [-75.0643, 40.8483],
          [-75.0508, 40.8681],
          [-75.0753, 40.8942],
          [-75.0793, 40.9139],
          [-75.1178, 40.953],
          [-75.1204, 40.9683],
          [-75.1338, 40.971],
          [-75.1306, 40.9911],
          [-75.0258, 41.0398],
          [-75.0111, 41.0675],
          [-74.9684, 41.0878],
          [-74.9694, 41.0961],
          [-74.9918, 41.0891],
          [-74.9822, 41.1082],
          [-74.9232, 41.1381],
          [-74.8821, 41.1808],
          [-74.8596, 41.2191],
          [-74.8674, 41.2278],
          [-74.8572, 41.249],
          [-74.8469, 41.2533],
          [-74.8301, 41.2872],
          [-74.7926, 41.3106],
          [-74.795, 41.3204],
          [-74.7716, 41.3251],
          [-74.7532, 41.3461],
          [-74.7209, 41.3474],
          [-74.6949, 41.3574],
          [-74.302, 41.1726],
          [-73.894, 40.9972],
          [-73.929, 40.8896],
          [-74.0138, 40.7566],
          [-74.0245, 40.7094],
          [-74.0824, 40.674],
          [-74.0941, 40.6497],
          [-74.186, 40.6461],
          [-74.2022, 40.6311],
          [-74.2148, 40.5606],
          [-74.2486, 40.5496],
          [-74.2462, 40.521],
          [-74.2727, 40.4884],
          [-74.2619, 40.4647],
          [-74.2098, 40.4474],
          [-74.1358, 40.4552],
          [-74.0479, 40.4189],
          [-73.9985, 40.4109],
          [-73.9714, 40.3717],
          [-73.9774, 40.2994],
          [-74.0641, 39.9792],
          [-74.097, 39.7657],
          [-74.1189, 39.7595],
          [-74.1179, 39.7728],
          [-74.125, 39.7726],
          [-74.1231, 39.7609],
          [-74.1362, 39.7771],
          [-74.1506, 39.7693],
          [-74.1527, 39.754],
          [-74.2199, 39.7767],
          [-74.2925, 39.7705],
          [-74.3149, 39.7755],
          [-74.3431, 39.8281],
          [-74.4172, 39.8231],
          [-74.5067, 39.9914],
          [-74.5938, 39.9893],
          [-74.5879, 39.98],
          [-74.603, 39.9663],
          [-74.6324, 39.9664],
          [-74.6399, 39.9867],
          [-74.6534, 39.9857],
          [-74.6492, 39.9934],
          [-74.6578, 39.9881],
          [-74.6486, 40.006],
          [-74.662, 40.0204],
          [-74.6415, 40.0307],
          [-74.6454, 40.0426],
          [-74.6374, 40.0522],
          [-74.653, 40.0646],
          [-74.6331, 40.0618],
          [-74.6465, 40.0684],
          [-74.6392, 40.0804],
          [-74.6519, 40.0829],
          [-74.6452, 40.1002],
          [-74.6529, 40.1023],
          [-74.6874, 40.1246],
          [-74.7059, 40.1151],
          [-74.7134, 40.0931],
          [-74.7346, 40.1093],
          [-74.7435, 40.1076],
          [-74.7565, 40.0647],
          [-74.7422, 40.0458],
          [-74.7426, 40.0226],
          [-74.761, 40.0244],
          [-74.7953, 40.0429],
          [-74.7721, 40.0626],
          [-74.797, 40.0619],
          [-74.7984, 40.072],
          [-74.7705, 40.0929],
          [-74.7918, 40.1025],
          [-74.785, 40.1092],
          [-74.8115, 40.0967],
          [-74.8177, 40.1134],
          [-74.8301, 40.117],
          [-74.8259, 40.1239],
          [-74.7821, 40.1208],
          [-74.7589, 40.134],
          [-74.7406, 40.1352],
          [-74.7243, 40.147],
          [-74.7223, 40.1606],
          [-74.7543, 40.1852],
          [-74.7714, 40.2154],
          [-74.8423, 40.2505],
          [-74.8806, 40.3056],
          [-74.9397, 40.338],
          [-74.9655, 40.3973],
          [-74.9964, 40.4105],
          [-75.0283, 40.4039],
          [-75.0588, 40.4181],
          [-75.0706, 40.4563],
          [-75.0619, 40.4864],
          [-75.0686, 40.5422],
          [-75.1173, 40.5732],
          [-75.1419, 40.5753],
          [-75.1629, 40.5641],
          [-75.194, 40.5763],
          [-75.1923, 40.6027],
          [-75.2018, 40.6172],
          [-75.1886, 40.6246],
          [-75.2005, 40.6492],
          [-75.1768, 40.6757],
          [-75.1969, 40.6813],
          [-75.2039, 40.6915],
          [-75.1821, 40.7315],
          [-75.1963, 40.7471],
          [-75.1918, 40.7558],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Chicago Mkt": {
    type: "Feature",
    properties: { code: "IL_CHI", name: "Chicago Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-88.9686, 41.9322],
            [-88.9627, 41.9352],
            [-88.9614, 41.9288],
            [-88.9686, 41.9322],
          ],
        ],
        [
          [
            [-88.9856, 42.0219],
            [-88.9904, 42.0364],
            [-88.9683, 42.0975],
            [-88.9392, 42.1015],
            [-88.9643, 42.1521],
            [-88.8871, 42.1601],
            [-88.9005, 42.189],
            [-88.8614, 42.1598],
            [-88.8771, 42.1602],
            [-88.87, 42.1528],
            [-88.8033, 42.157],
            [-88.7756, 42.168],
            [-88.7401, 42.168],
            [-88.7056, 42.1536],
            [-88.7055, 42.2564],
            [-88.7253, 42.2611],
            [-88.7056, 42.2695],
            [-88.7057, 42.3279],
            [-88.6572, 42.3279],
            [-88.6573, 42.3424],
            [-88.7057, 42.3424],
            [-88.7062, 42.4295],
            [-88.7355, 42.4585],
            [-88.7331, 42.4689],
            [-88.7453, 42.4729],
            [-88.746, 42.4926],
            [-87.8005, 42.4919],
            [-87.8054, 42.3847],
            [-87.8348, 42.3015],
            [-87.8001, 42.208],
            [-87.7417, 42.1282],
            [-87.6715, 42.0583],
            [-87.669, 42.0291],
            [-87.6094, 41.8452],
            [-87.5606, 41.766],
            [-87.5307, 41.7482],
            [-87.5247, 41.6306],
            [-87.5637, 41.63],
            [-87.5626, 41.636],
            [-87.5863, 41.6447],
            [-87.6171, 41.63],
            [-87.6482, 41.6285],
            [-87.6612, 41.6394],
            [-87.6515, 41.6554],
            [-87.6719, 41.6628],
            [-87.7097, 41.6695],
            [-87.6999, 41.6675],
            [-87.7138, 41.6518],
            [-87.7631, 41.6666],
            [-87.7678, 41.6609],
            [-87.7782, 41.6719],
            [-87.7789, 41.6905],
            [-87.7203, 41.6915],
            [-87.7216, 41.735],
            [-87.7412, 41.7347],
            [-87.742, 41.7591],
            [-87.8002, 41.7555],
            [-87.8019, 41.8014],
            [-87.7633, 41.8137],
            [-87.779, 41.8653],
            [-87.8087, 41.8496],
            [-87.8428, 41.8539],
            [-87.8586, 41.8427],
            [-87.8791, 41.8478],
            [-87.8818, 41.8338],
            [-87.9002, 41.8264],
            [-87.9186, 41.8264],
            [-87.9201, 41.8616],
            [-87.9298, 41.8567],
            [-87.9483, 41.8607],
            [-87.9604, 41.8562],
            [-87.9579, 41.8463],
            [-88.0479, 41.8316],
            [-88.0735, 41.8152],
            [-88.1056, 41.8291],
            [-88.2027, 41.8424],
            [-88.2045, 41.8564],
            [-88.241, 41.856],
            [-88.2615, 41.8453],
            [-88.2627, 41.871],
            [-88.3716, 41.8651],
            [-88.4067, 41.8465],
            [-88.414, 41.8313],
            [-88.4393, 41.8229],
            [-88.475, 41.8237],
            [-88.4799, 41.8077],
            [-88.5284, 41.8077],
            [-88.5404, 41.7934],
            [-88.5465, 41.8075],
            [-88.5862, 41.807],
            [-88.6062, 41.7998],
            [-88.6024, 41.8212],
            [-88.6409, 41.8498],
            [-88.6991, 41.8494],
            [-88.6996, 41.8058],
            [-88.7543, 41.8055],
            [-88.7543, 41.8347],
            [-88.8282, 41.8456],
            [-88.8317, 41.8342],
            [-88.848, 41.8486],
            [-88.9251, 41.8484],
            [-88.9415, 41.8629],
            [-88.942, 41.9439],
            [-88.9305, 41.9314],
            [-88.9066, 41.9307],
            [-88.9871, 41.9785],
            [-89.0225, 42.0017],
            [-88.9856, 42.0219],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Green Bay Mkt": {
    type: "Feature",
    properties: { code: "WI_GRE", name: "Green Bay Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-86.9552, 45.3837],
            [-86.9177, 45.4079],
            [-86.8245, 45.406],
            [-86.8636, 45.3649],
            [-86.869, 45.3332],
            [-86.9257, 45.3242],
            [-86.9561, 45.3423],
            [-86.9552, 45.3837],
          ],
        ],
        [
          [
            [-91.0116, 45.1655],
            [-90.9942, 45.1772],
            [-90.9632, 45.1774],
            [-90.9517, 45.1937],
            [-90.9259, 45.1916],
            [-90.9259, 45.2285],
            [-90.9569, 45.2485],
            [-90.9256, 45.2523],
            [-90.9254, 45.2771],
            [-90.9079, 45.2635],
            [-90.8853, 45.2781],
            [-90.8443, 45.278],
            [-90.8438, 45.2989],
            [-90.8024, 45.3133],
            [-90.7828, 45.317],
            [-90.8025, 45.3206],
            [-90.8024, 45.3504],
            [-90.835, 45.3478],
            [-90.7827, 45.356],
            [-90.7588, 45.3453],
            [-90.7484, 45.3306],
            [-90.7336, 45.3306],
            [-90.7334, 45.3202],
            [-90.751, 45.2988],
            [-90.7344, 45.2927],
            [-90.7323, 45.2778],
            [-90.7207, 45.2776],
            [-90.7077, 45.2963],
            [-90.6947, 45.2942],
            [-90.6792, 45.3031],
            [-90.6579, 45.3307],
            [-90.6563, 45.3491],
            [-90.6101, 45.3501],
            [-90.6202, 45.3665],
            [-90.6181, 45.3974],
            [-90.6175, 45.4375],
            [-90.5732, 45.4448],
            [-90.5036, 45.4633],
            [-90.4308, 45.463],
            [-90.4308, 45.4342],
            [-90.351, 45.445],
            [-90.3106, 45.488],
            [-90.1958, 45.4946],
            [-90.1668, 45.4634],
            [-90.0783, 45.4544],
            [-90.017, 45.4312],
            [-89.9702, 45.4554],
            [-89.9705, 45.5289],
            [-89.9352, 45.5553],
            [-89.8171, 45.5557],
            [-89.817, 45.5831],
            [-89.8792, 45.6163],
            [-89.9208, 45.6215],
            [-89.9212, 45.6632],
            [-89.9832, 45.7015],
            [-89.9273, 45.7133],
            [-89.8877, 45.681],
            [-89.7786, 45.6849],
            [-89.7196, 45.6455],
            [-89.6521, 45.6451],
            [-89.6296, 45.5945],
            [-89.6449, 45.5559],
            [-89.4783, 45.5556],
            [-89.4821, 45.5271],
            [-89.4255, 45.5],
            [-89.3052, 45.555],
            [-89.1953, 45.554],
            [-89.1882, 45.5671],
            [-89.1652, 45.5736],
            [-89.1618, 45.5193],
            [-89.0975, 45.5468],
            [-89.0467, 45.5515],
            [-89.0463, 45.4648],
            [-88.9231, 45.4651],
            [-88.8649, 45.4351],
            [-88.7704, 45.4112],
            [-88.7452, 45.3785],
            [-88.7465, 45.3365],
            [-88.6785, 45.337],
            [-88.6778, 45.3787],
            [-88.4281, 45.377],
            [-88.4295, 45.5175],
            [-88.4994, 45.569],
            [-88.5537, 45.5734],
            [-88.5934, 45.6114],
            [-88.5731, 45.6577],
            [-88.5726, 45.7227],
            [-88.676, 45.7228],
            [-88.6755, 45.7929],
            [-88.5604, 45.7956],
            [-88.5332, 45.8095],
            [-88.4522, 45.8096],
            [-88.4224, 45.8936],
            [-88.3963, 45.9282],
            [-88.4099, 45.9797],
            [-88.3802, 45.9917],
            [-88.2924, 45.9511],
            [-88.192, 45.9527],
            [-88.1264, 45.9215],
            [-88.0731, 45.872],
            [-88.1295, 45.8093],
            [-88.0721, 45.7803],
            [-87.9914, 45.7954],
            [-87.9635, 45.7582],
            [-87.8758, 45.7539],
            [-87.8555, 45.7269],
            [-87.8091, 45.6997],
            [-87.8232, 45.6627],
            [-87.7959, 45.6188],
            [-87.7772, 45.5885],
            [-87.8034, 45.5383],
            [-87.7928, 45.5],
            [-87.813, 45.4642],
            [-87.8617, 45.4345],
            [-87.8493, 45.4039],
            [-87.867, 45.3601],
            [-87.7541, 45.3494],
            [-87.694, 45.3899],
            [-87.6573, 45.3688],
            [-87.6481, 45.3394],
            [-87.6982, 45.2815],
            [-87.7113, 45.24],
            [-87.7262, 45.2094],
            [-87.7417, 45.1982],
            [-87.7351, 45.1715],
            [-87.6839, 45.1441],
            [-87.6612, 45.1083],
            [-87.6315, 45.1062],
            [-87.5871, 45.0895],
            [-87.6257, 45.0452],
            [-87.6303, 44.9769],
            [-87.7669, 44.9653],
            [-87.8195, 44.9511],
            [-87.8443, 44.9185],
            [-87.8278, 44.8912],
            [-87.8662, 44.8405],
            [-87.9011, 44.8274],
            [-87.9415, 44.7561],
            [-87.9835, 44.7202],
            [-88.0098, 44.6371],
            [-87.9987, 44.6093],
            [-88.0424, 44.5666],
            [-88.0055, 44.5392],
            [-87.929, 44.536],
            [-87.9012, 44.5845],
            [-87.8088, 44.6363],
            [-87.756, 44.6491],
            [-87.7198, 44.6932],
            [-87.7209, 44.7245],
            [-87.6101, 44.8384],
            [-87.5813, 44.8518],
            [-87.4785, 44.8636],
            [-87.405, 44.9118],
            [-87.3221, 45.0342],
            [-87.2649, 45.0814],
            [-87.2382, 45.1673],
            [-87.1952, 45.1631],
            [-87.12, 45.1911],
            [-87.1087, 45.257],
            [-87.017, 45.2993],
            [-86.9704, 45.2785],
            [-86.986, 45.2159],
            [-87.0325, 45.2223],
            [-87.0543, 45.12],
            [-87.0482, 45.0891],
            [-87.0819, 45.0591],
            [-87.1212, 45.0583],
            [-87.1394, 45.0126],
            [-87.1894, 44.9686],
            [-87.1717, 44.9315],
            [-87.216, 44.9066],
            [-87.2045, 44.8756],
            [-87.2674, 44.8469],
            [-87.3138, 44.7938],
            [-87.3538, 44.7019],
            [-87.4016, 44.6312],
            [-87.4375, 44.6051],
            [-87.4681, 44.5519],
            [-87.5176, 44.3757],
            [-87.5454, 44.3214],
            [-87.5084, 44.2297],
            [-87.5197, 44.1799],
            [-87.5632, 44.1442],
            [-87.6466, 44.1047],
            [-87.6561, 44.0518],
            [-87.6872, 44.0106],
            [-87.7815, 44.0009],
            [-87.7893, 44.0227],
            [-87.862, 43.979],
            [-87.8619, 43.9642],
            [-87.902, 43.9644],
            [-87.9225, 44.0052],
            [-87.983, 44.0081],
            [-87.9997, 44.0485],
            [-87.9932, 44.0663],
            [-87.9766, 44.0719],
            [-87.9927, 44.0909],
            [-88.0431, 44.0955],
            [-88.0465, 44.0811],
            [-88.0928, 44.0808],
            [-88.0934, 44.0736],
            [-88.1039, 44.0952],
            [-88.1596, 44.1094],
            [-88.1636, 44.0875],
            [-88.1837, 44.0877],
            [-88.1896, 44.0966],
            [-88.2066, 44.0878],
            [-88.2292, 44.0951],
            [-88.251, 44.0734],
            [-88.2979, 44.0718],
            [-88.2992, 44.0788],
            [-88.3214, 44.0717],
            [-88.4043, 44.071],
            [-88.4035, 43.8779],
            [-88.3503, 43.8738],
            [-88.2927, 43.8812],
            [-88.2941, 43.8666],
            [-88.3429, 43.863],
            [-88.3421, 43.834],
            [-88.3617, 43.8264],
            [-88.3616, 43.8148],
            [-88.3216, 43.8189],
            [-88.3216, 43.79],
            [-88.2813, 43.7823],
            [-88.2812, 43.7751],
            [-88.2512, 43.7823],
            [-88.2659, 43.754],
            [-88.267, 43.7323],
            [-88.2873, 43.7395],
            [-88.2912, 43.7323],
            [-88.3209, 43.7325],
            [-88.326, 43.7198],
            [-88.3744, 43.7173],
            [-88.3635, 43.7039],
            [-88.4019, 43.717],
            [-88.4013, 43.6601],
            [-88.4011, 43.6382],
            [-88.4133, 43.6311],
            [-88.4413, 43.6314],
            [-88.4514, 43.6752],
            [-88.4793, 43.6755],
            [-88.493, 43.7085],
            [-88.5224, 43.7084],
            [-88.5228, 43.6975],
            [-88.5578, 43.6978],
            [-88.5485, 43.7119],
            [-88.5164, 43.7231],
            [-88.5134, 43.7359],
            [-88.5634, 43.7334],
            [-88.6138, 43.7344],
            [-88.6125, 43.7198],
            [-88.6236, 43.7199],
            [-88.639, 43.7379],
            [-88.6442, 43.7201],
            [-88.6573, 43.7202],
            [-88.6632, 43.7398],
            [-88.6748, 43.7462],
            [-88.6753, 43.7773],
            [-88.7207, 43.7856],
            [-88.7251, 43.8078],
            [-88.7482, 43.7931],
            [-88.8057, 43.7931],
            [-88.7985, 43.7713],
            [-88.8054, 43.7786],
            [-88.9055, 43.7715],
            [-88.9557, 43.7714],
            [-88.9562, 43.8154],
            [-88.9722, 43.8126],
            [-88.956, 43.8173],
            [-88.9593, 43.8241],
            [-88.993, 43.831],
            [-89.0149, 43.8148],
            [-89.0359, 43.8148],
            [-89.026, 43.8051],
            [-89.0607, 43.8054],
            [-89.0658, 43.7841],
            [-89.1226, 43.8066],
            [-89.1096, 43.7744],
            [-89.1485, 43.8124],
            [-89.1906, 43.8048],
            [-89.1981, 43.8302],
            [-89.2215, 43.8232],
            [-89.2381, 43.8264],
            [-89.2384, 43.854],
            [-89.2581, 43.8616],
            [-89.3059, 43.8614],
            [-89.3159, 43.9343],
            [-89.3472, 43.9376],
            [-89.3471, 43.925],
            [-89.3289, 43.9182],
            [-89.3588, 43.9118],
            [-89.3653, 43.9534],
            [-89.3774, 43.969],
            [-89.4233, 43.97],
            [-89.4465, 43.976],
            [-89.4455, 43.9822],
            [-89.5119, 43.9821],
            [-89.5223, 43.9966],
            [-89.5424, 43.9966],
            [-89.5479, 43.9823],
            [-89.598, 43.9822],
            [-89.648, 43.9523],
            [-89.6431, 43.9704],
            [-89.6184, 44.0221],
            [-89.7482, 44.0251],
            [-89.7442, 44.0332],
            [-89.7177, 44.0387],
            [-89.748, 44.0396],
            [-89.7482, 44.055],
            [-89.6612, 44.0573],
            [-89.6585, 44.0684],
            [-89.7481, 44.0755],
            [-89.7297, 44.0858],
            [-89.6183, 44.0922],
            [-89.6183, 44.1195],
            [-89.6386, 44.112],
            [-89.758, 44.0999],
            [-89.7566, 44.112],
            [-89.7784, 44.0983],
            [-89.7778, 44.1265],
            [-89.8784, 44.1262],
            [-89.8984, 44.1408],
            [-89.8938, 44.1554],
            [-89.8967, 44.1734],
            [-89.9044, 44.1743],
            [-89.9047, 44.1604],
            [-89.9328, 44.1523],
            [-89.9415, 44.1641],
            [-89.9659, 44.1679],
            [-89.9761, 44.1625],
            [-89.9746, 44.1688],
            [-89.9442, 44.1696],
            [-89.9451, 44.176],
            [-89.9461, 44.1699],
            [-89.9754, 44.1732],
            [-89.9954, 44.1698],
            [-90.0156, 44.1605],
            [-90.0159, 44.1709],
            [-90.0361, 44.1696],
            [-90.0438, 44.1908],
            [-90.0618, 44.1672],
            [-90.0714, 44.1663],
            [-90.0679, 44.1989],
            [-90.0565, 44.1986],
            [-90.0001, 44.2275],
            [-89.996, 44.213],
            [-89.9764, 44.249],
            [-90.3183, 44.2488],
            [-90.318, 44.2602],
            [-90.3446, 44.2699],
            [-90.3444, 44.2775],
            [-90.3848, 44.2827],
            [-90.3984, 44.274],
            [-90.399, 44.2457],
            [-90.4276, 44.2368],
            [-90.4322, 44.2794],
            [-90.4541, 44.2938],
            [-90.553, 44.3104],
            [-90.5916, 44.3357],
            [-90.5884, 44.3401],
            [-90.6122, 44.3405],
            [-90.6755, 44.374],
            [-90.6773, 44.3786],
            [-90.637, 44.3898],
            [-90.5584, 44.3786],
            [-90.5587, 44.4222],
            [-90.7112, 44.4222],
            [-90.6852, 44.4552],
            [-90.6989, 44.4439],
            [-90.7412, 44.444],
            [-90.7437, 44.466],
            [-90.7619, 44.4642],
            [-90.7797, 44.4749],
            [-90.8018, 44.472],
            [-90.8019, 44.5095],
            [-90.8208, 44.5182],
            [-90.8003, 44.5384],
            [-90.7819, 44.5579],
            [-90.8034, 44.5607],
            [-90.7903, 44.5922],
            [-90.8723, 44.5962],
            [-90.8415, 44.6228],
            [-90.8365, 44.6663],
            [-90.8638, 44.6601],
            [-90.8989, 44.6363],
            [-90.9219, 44.669],
            [-90.9215, 44.7703],
            [-90.8612, 44.7712],
            [-90.8415, 44.786],
            [-90.8416, 44.7745],
            [-90.8009, 44.7671],
            [-90.7921, 44.7725],
            [-90.7834, 44.8184],
            [-90.7933, 44.829],
            [-90.7603, 44.8288],
            [-90.7603, 44.8827],
            [-90.714, 44.8862],
            [-90.7062, 44.8969],
            [-90.7013, 44.9267],
            [-90.7197, 44.9591],
            [-90.6789, 44.9624],
            [-90.6994, 45.0313],
            [-90.7809, 45.0316],
            [-90.7999, 45.0752],
            [-90.8405, 45.0754],
            [-90.8404, 45.1193],
            [-90.8947, 45.1195],
            [-90.8985, 45.1267],
            [-90.926, 45.1336],
            [-90.926, 45.123],
            [-90.9827, 45.1194],
            [-91.0005, 45.1097],
            [-91.0127, 45.134],
            [-90.9922, 45.1368],
            [-90.9881, 45.163],
            [-91.0116, 45.1655],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "St Johns Mkt": {
    type: "Feature",
    properties: { code: "NF_STJ", name: "St Johns Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-57.3294, 54.5635],
            [-57.2735, 54.546],
            [-57.2448, 54.5118],
            [-57.3483, 54.4956],
            [-57.3294, 54.5635],
          ],
        ],
        [
          [
            [-58.6923, 54.9997],
            [-58.645, 54.9434],
            [-58.6576, 54.8991],
            [-58.7474, 54.9124],
            [-58.7095, 54.9471],
            [-58.6923, 54.9997],
          ],
        ],
        [
          [
            [-60.0598, 55.3467],
            [-59.9789, 55.4041],
            [-59.9786, 55.3517],
            [-60.0598, 55.3467],
          ],
        ],
        [
          [
            [-60.1747, 55.3517],
            [-60.1074, 55.3761],
            [-60.0954, 55.3435],
            [-60.119, 55.3061],
            [-60.1969, 55.3093],
            [-60.1747, 55.3517],
          ],
        ],
        [
          [
            [-60.8498, 55.9386],
            [-60.7271, 55.9368],
            [-60.663, 55.91],
            [-60.7358, 55.8689],
            [-60.8446, 55.8588],
            [-60.8498, 55.9386],
          ],
        ],
        [
          [
            [-61.0467, 55.9403],
            [-60.9604, 55.9272],
            [-60.8794, 55.8956],
            [-60.8949, 55.8629],
            [-61.0154, 55.869],
            [-61.0446, 55.8877],
            [-61.0467, 55.9403],
          ],
        ],
        [
          [
            [-61.2462, 56.1037],
            [-61.1951, 56.1192],
            [-61.1481, 56.1613],
            [-61.086, 56.1603],
            [-61.0604, 56.1316],
            [-60.9658, 56.0952],
            [-61.0024, 56.0221],
            [-61.0659, 56.0087],
            [-61.2109, 56.0384],
            [-61.2462, 56.1037],
          ],
        ],
        [
          [
            [-61.2385, 56.6268],
            [-61.1618, 56.6931],
            [-61.0788, 56.6499],
            [-61.1154, 56.6029],
            [-61.2009, 56.6055],
            [-61.2385, 56.6268],
          ],
        ],
        [
          [
            [-61.5804, 56.2723],
            [-61.4815, 56.289],
            [-61.492, 56.2559],
            [-61.5804, 56.2723],
          ],
        ],
        [
          [
            [-54.7554, 49.6744],
            [-54.6983, 49.6643],
            [-54.7076, 49.6112],
            [-54.766, 49.6163],
            [-54.7554, 49.6744],
          ],
        ],
        [
          [
            [-54.7716, 49.4864],
            [-54.8363, 49.4801],
            [-54.8982, 49.571],
            [-54.8382, 49.5989],
            [-54.7719, 49.5386],
            [-54.6755, 49.5823],
            [-54.5581, 49.6491],
            [-54.5501, 49.5916],
            [-54.6239, 49.543],
            [-54.754, 49.5282],
            [-54.7716, 49.4864],
          ],
        ],
        [
          [
            [-55.198, 49.3247],
            [-55.1729, 49.3543],
            [-55.1227, 49.3024],
            [-55.1997, 49.2656],
            [-55.198, 49.3247],
          ],
        ],
        [
          [
            [-55.7431, 49.5613],
            [-55.6899, 49.5614],
            [-55.6733, 49.5159],
            [-55.7348, 49.5061],
            [-55.7431, 49.5613],
          ],
        ],
        [
          [
            [-55.6771, 49.6118],
            [-55.6201, 49.5778],
            [-55.7314, 49.5658],
            [-55.7189, 49.613],
            [-55.6771, 49.6118],
          ],
        ],
        [
          [
            [-55.638, 50.7905],
            [-55.5618, 50.7716],
            [-55.5123, 50.8054],
            [-55.4663, 50.7812],
            [-55.4814, 50.7522],
            [-55.567, 50.6921],
            [-55.6567, 50.7335],
            [-55.638, 50.7905],
          ],
        ],
        [
          [
            [-55.6387, 50.9682],
            [-55.5535, 50.987],
            [-55.5394, 50.9573],
            [-55.6354, 50.9165],
            [-55.6387, 50.9682],
          ],
        ],
        [
          [
            [-55.3921, 51.9627],
            [-55.3585, 51.9649],
            [-55.2774, 52.0021],
            [-55.354, 51.8905],
            [-55.4327, 51.8958],
            [-55.3921, 51.9627],
          ],
        ],
        [
          [
            [-55.8225, 52.5904],
            [-55.7062, 52.5688],
            [-55.7028, 52.5389],
            [-55.8225, 52.5904],
          ],
        ],
        [
          [
            [-55.9968, 52.5858],
            [-55.9599, 52.5922],
            [-55.9364, 52.6109],
            [-55.8749, 52.5966],
            [-55.8914, 52.553],
            [-55.9968, 52.5858],
          ],
        ],
        [
          [
            [-58.8512, 47.608],
            [-59.0374, 47.5683],
            [-59.065, 47.581],
            [-59.1963, 47.5959],
            [-59.3067, 47.6196],
            [-59.3159, 47.6944],
            [-59.3006, 47.7421],
            [-59.333, 47.7826],
            [-59.3277, 47.8283],
            [-59.4072, 47.8884],
            [-59.4002, 47.9179],
            [-59.3142, 47.9454],
            [-59.2691, 48.0031],
            [-59.087, 48.0991],
            [-58.9797, 48.1301],
            [-58.9287, 48.1813],
            [-58.6958, 48.3271],
            [-58.6887, 48.3629],
            [-58.6033, 48.4154],
            [-58.4773, 48.4312],
            [-58.438, 48.4519],
            [-58.4252, 48.4874],
            [-58.4663, 48.5179],
            [-58.583, 48.5423],
            [-58.726, 48.555],
            [-58.7766, 48.531],
            [-59.0099, 48.5142],
            [-59.0445, 48.5221],
            [-59.2625, 48.4645],
            [-59.233, 48.5293],
            [-59.1057, 48.6252],
            [-59.007, 48.6529],
            [-58.9527, 48.6047],
            [-58.7896, 48.5628],
            [-58.7134, 48.5614],
            [-58.6712, 48.6191],
            [-58.6879, 48.6816],
            [-58.6109, 48.808],
            [-58.5385, 48.8762],
            [-58.5048, 48.9436],
            [-58.5037, 48.9996],
            [-58.4623, 49.0242],
            [-58.4235, 49.0894],
            [-58.4314, 49.1058],
            [-58.37, 49.1472],
            [-58.3476, 49.105],
            [-58.3751, 49.0703],
            [-58.3462, 49.0519],
            [-58.2781, 49.0719],
            [-58.1642, 49.0619],
            [-58.147, 49.1331],
            [-58.0759, 49.1428],
            [-58.0315, 49.2063],
            [-58.1673, 49.2367],
            [-58.2162, 49.2363],
            [-58.2559, 49.2905],
            [-58.2263, 49.3661],
            [-58.2352, 49.3939],
            [-58.1705, 49.4625],
            [-58.0461, 49.5334],
            [-57.9424, 49.5829],
            [-57.9657, 49.6833],
            [-57.9119, 49.7709],
            [-57.8716, 49.7916],
            [-57.8572, 49.8278],
            [-57.7869, 49.8743],
            [-57.831, 49.8936],
            [-57.7607, 49.9407],
            [-57.773, 49.966],
            [-57.7105, 50.0323],
            [-57.6988, 50.0723],
            [-57.6468, 50.1469],
            [-57.6083, 50.1718],
            [-57.6114, 50.2057],
            [-57.5741, 50.2568],
            [-57.532, 50.3454],
            [-57.5391, 50.3628],
            [-57.4759, 50.4554],
            [-57.4747, 50.4765],
            [-57.3935, 50.5296],
            [-57.396, 50.5735],
            [-57.3702, 50.6046],
            [-57.3209, 50.6194],
            [-57.2647, 50.5962],
            [-57.1795, 50.6002],
            [-57.1698, 50.6343],
            [-57.269, 50.6077],
            [-57.3097, 50.6401],
            [-57.3123, 50.6694],
            [-57.3932, 50.7157],
            [-57.2386, 50.7318],
            [-57.1713, 50.7506],
            [-57.0756, 50.7962],
            [-57.0514, 50.8324],
            [-56.9695, 50.89],
            [-56.9968, 50.9009],
            [-57.0114, 50.9743],
            [-56.9401, 50.992],
            [-56.9156, 51.0243],
            [-56.9372, 51.0512],
            [-56.8724, 51.0863],
            [-56.8338, 51.1353],
            [-56.7977, 51.1348],
            [-56.7892, 51.2057],
            [-56.8004, 51.2434],
            [-56.7543, 51.2867],
            [-56.6414, 51.3669],
            [-56.471, 51.4199],
            [-56.4363, 51.4134],
            [-56.3735, 51.4468],
            [-56.2694, 51.4809],
            [-56.1999, 51.5148],
            [-56.0751, 51.544],
            [-56.0084, 51.586],
            [-55.8999, 51.6315],
            [-55.8702, 51.5991],
            [-55.9037, 51.5567],
            [-55.8428, 51.5028],
            [-55.7363, 51.4994],
            [-55.7037, 51.5199],
            [-55.7453, 51.5564],
            [-55.7019, 51.5868],
            [-55.6456, 51.5667],
            [-55.574, 51.59],
            [-55.471, 51.6004],
            [-55.4421, 51.5818],
            [-55.4413, 51.5819],
            [-55.4451, 51.5089],
            [-55.4693, 51.4695],
            [-55.4943, 51.3733],
            [-55.5565, 51.3597],
            [-55.6323, 51.3],
            [-55.6773, 51.3207],
            [-55.7478, 51.3256],
            [-55.8126, 51.3491],
            [-55.9801, 51.3359],
            [-56.0309, 51.3782],
            [-56.1011, 51.342],
            [-56.0821, 51.3055],
            [-56.0269, 51.2954],
            [-56.0309, 51.2226],
            [-56.005, 51.1897],
            [-56.0394, 51.1583],
            [-55.9659, 51.1663],
            [-55.9511, 51.2023],
            [-55.8612, 51.2308],
            [-55.7793, 51.2057],
            [-55.7269, 51.1252],
            [-55.7297, 51.0946],
            [-55.7786, 51.0525],
            [-55.8262, 51.0583],
            [-55.8293, 50.9259],
            [-55.8667, 50.9369],
            [-55.9334, 50.874],
            [-55.9685, 50.8616],
            [-55.997, 50.8042],
            [-56.0936, 50.7227],
            [-56.1516, 50.7598],
            [-56.1403, 50.8545],
            [-56.1204, 50.8799],
            [-56.2441, 50.8858],
            [-56.2175, 50.8547],
            [-56.1553, 50.8424],
            [-56.1574, 50.801],
            [-56.188, 50.7397],
            [-56.1606, 50.6975],
            [-56.1118, 50.6723],
            [-56.1316, 50.6374],
            [-56.2053, 50.6206],
            [-56.2524, 50.5758],
            [-56.2554, 50.5228],
            [-56.3452, 50.5224],
            [-56.2767, 50.5029],
            [-56.3857, 50.4111],
            [-56.4323, 50.3542],
            [-56.5141, 50.2261],
            [-56.5912, 50.1492],
            [-56.711, 50.0495],
            [-56.7523, 50.024],
            [-56.7726, 49.96],
            [-56.7323, 49.9575],
            [-56.7461, 49.8721],
            [-56.773, 49.8299],
            [-56.8686, 49.7798],
            [-56.8691, 49.779],
            [-56.7964, 49.7365],
            [-56.7853, 49.6998],
            [-56.8256, 49.606],
            [-56.7697, 49.6358],
            [-56.7485, 49.6731],
            [-56.5817, 49.8471],
            [-56.4528, 49.8942],
            [-56.3964, 49.9629],
            [-56.356, 49.9806],
            [-56.3336, 50.033],
            [-56.2812, 50.0622],
            [-56.2276, 50.1221],
            [-56.185, 50.1385],
            [-56.105, 50.1276],
            [-56.0987, 50.0532],
            [-56.1391, 50.0251],
            [-56.0161, 50.0074],
            [-55.9295, 50.0395],
            [-55.8812, 50.02],
            [-55.8731, 49.9772],
            [-55.7712, 49.9294],
            [-55.7084, 49.9383],
            [-55.6982, 49.9701],
            [-55.6094, 49.9718],
            [-55.5329, 50.0058],
            [-55.4811, 49.9417],
            [-55.4974, 49.9267],
            [-55.6562, 49.8686],
            [-55.6782, 49.8397],
            [-55.7546, 49.8249],
            [-55.8712, 49.7845],
            [-55.8953, 49.7597],
            [-55.9747, 49.7346],
            [-56.0225, 49.7514],
            [-56.045, 49.6915],
            [-56.1801, 49.6176],
            [-56.175, 49.5968],
            [-56.0367, 49.6821],
            [-55.9292, 49.6993],
            [-55.8554, 49.671],
            [-55.9469, 49.6041],
            [-55.864, 49.6193],
            [-55.8226, 49.6064],
            [-55.8422, 49.5726],
            [-55.9031, 49.5595],
            [-55.9517, 49.5283],
            [-56.0653, 49.5019],
            [-56.0824, 49.4337],
            [-56.0036, 49.4978],
            [-55.9398, 49.5],
            [-55.8482, 49.5311],
            [-55.7585, 49.4821],
            [-55.7257, 49.4215],
            [-55.6613, 49.4091],
            [-55.5769, 49.4891],
            [-55.5419, 49.4704],
            [-55.5409, 49.4373],
            [-55.4773, 49.4532],
            [-55.4613, 49.4934],
            [-55.4045, 49.5066],
            [-55.3646, 49.3997],
            [-55.3173, 49.396],
            [-55.2616, 49.3938],
            [-55.3247, 49.5303],
            [-55.2847, 49.5445],
            [-55.1597, 49.5435],
            [-55.1358, 49.4866],
            [-55.1672, 49.4242],
            [-55.2511, 49.3373],
            [-55.2432, 49.2568],
            [-55.3227, 49.2176],
            [-55.3524, 49.1846],
            [-55.3327, 49.1264],
            [-55.2739, 49.196],
            [-55.2201, 49.2378],
            [-55.0987, 49.2869],
            [-55.0438, 49.3234],
            [-55.0115, 49.2803],
            [-54.9641, 49.3094],
            [-54.9337, 49.2826],
            [-54.8909, 49.2926],
            [-54.8385, 49.2789],
            [-54.8017, 49.2999],
            [-54.7967, 49.3558],
            [-54.7025, 49.3708],
            [-54.6714, 49.3981],
            [-54.6581, 49.4563],
            [-54.6306, 49.4615],
            [-54.5457, 49.5462],
            [-54.4724, 49.5692],
            [-54.4668, 49.5352],
            [-54.5146, 49.5079],
            [-54.5095, 49.4687],
            [-54.4649, 49.4276],
            [-54.4958, 49.3958],
            [-54.4907, 49.348],
            [-54.4358, 49.3625],
            [-54.4256, 49.3901],
            [-54.319, 49.441],
            [-54.2532, 49.3968],
            [-54.1814, 49.4156],
            [-54.1629, 49.4583],
            [-54.0536, 49.4695],
            [-53.9659, 49.4599],
            [-53.8668, 49.4124],
            [-53.767, 49.3955],
            [-53.6758, 49.3596],
            [-53.6855, 49.3266],
            [-53.621, 49.3149],
            [-53.563, 49.2802],
            [-53.5043, 49.2828],
            [-53.4913, 49.2293],
            [-53.5543, 49.1895],
            [-53.5461, 49.1496],
            [-53.5814, 49.1393],
            [-53.6025, 49.0975],
            [-53.6029, 49.0359],
            [-53.6412, 49.0443],
            [-53.7612, 49.0152],
            [-53.7784, 48.9893],
            [-53.8317, 48.984],
            [-53.8668, 48.956],
            [-53.8149, 48.936],
            [-53.8862, 48.9122],
            [-53.9488, 48.9148],
            [-53.9982, 48.824],
            [-54.0812, 48.8333],
            [-54.1498, 48.8263],
            [-54.2081, 48.8055],
            [-54.2224, 48.7682],
            [-54.0834, 48.7975],
            [-54.0508, 48.8143],
            [-53.9931, 48.8093],
            [-53.946, 48.8414],
            [-53.9006, 48.8456],
            [-53.8438, 48.8068],
            [-53.9089, 48.7831],
            [-53.9103, 48.7616],
            [-53.9678, 48.6995],
            [-53.8099, 48.7164],
            [-53.7221, 48.6841],
            [-53.6402, 48.6961],
            [-53.661, 48.65],
            [-53.7636, 48.6345],
            [-53.856, 48.589],
            [-53.8026, 48.5682],
            [-53.8084, 48.529],
            [-53.7716, 48.5124],
            [-53.9245, 48.482],
            [-53.9477, 48.4551],
            [-54, 48.442],
            [-54.0127, 48.3966],
            [-53.8519, 48.476],
            [-53.7897, 48.4756],
            [-53.704, 48.5279],
            [-53.636, 48.4957],
            [-53.7083, 48.442],
            [-53.6543, 48.4343],
            [-53.6066, 48.4728],
            [-53.5474, 48.4403],
            [-53.5188, 48.5096],
            [-53.478, 48.5449],
            [-53.483, 48.5823],
            [-53.4219, 48.6038],
            [-53.341, 48.6003],
            [-53.324, 48.5413],
            [-53.2615, 48.5215],
            [-53.0822, 48.6749],
            [-53.0326, 48.657],
            [-53.0003, 48.6083],
            [-53.0026, 48.5467],
            [-53.0363, 48.5341],
            [-53.0722, 48.4777],
            [-53.0523, 48.4598],
            [-53.1121, 48.4046],
            [-53.1939, 48.38],
            [-53.2119, 48.3491],
            [-53.3023, 48.3704],
            [-53.3736, 48.3515],
            [-53.3933, 48.272],
            [-53.4682, 48.2823],
            [-53.5134, 48.2371],
            [-53.56, 48.2293],
            [-53.6423, 48.1742],
            [-53.7057, 48.1631],
            [-53.7469, 48.1927],
            [-53.8028, 48.1996],
            [-53.8448, 48.1862],
            [-53.897, 48.2233],
            [-53.962, 48.2371],
            [-53.953, 48.2069],
            [-53.9611, 48.1518],
            [-53.9302, 48.1294],
            [-53.9341, 48.0765],
            [-53.855, 48.0683],
            [-53.7092, 48.0854],
            [-53.6746, 48.0608],
            [-53.7466, 48.0284],
            [-53.7993, 48.027],
            [-53.8104, 47.996],
            [-53.6987, 48.0251],
            [-53.6665, 48.0148],
            [-53.6613, 47.9753],
            [-53.6844, 47.9263],
            [-53.7311, 47.9046],
            [-53.7434, 47.8394],
            [-53.7933, 47.7755],
            [-53.8699, 47.7841],
            [-53.8374, 47.7422],
            [-53.837, 47.7019],
            [-53.7825, 47.638],
            [-53.7417, 47.6352],
            [-53.6794, 47.6584],
            [-53.7214, 47.581],
            [-53.6803, 47.5817],
            [-53.6716, 47.5187],
            [-53.612, 47.5376],
            [-53.5563, 47.5176],
            [-53.5472, 47.5568],
            [-53.5687, 47.6282],
            [-53.5138, 47.6073],
            [-53.5099, 47.6673],
            [-53.4786, 47.6938],
            [-53.5069, 47.749],
            [-53.418, 47.8708],
            [-53.3561, 47.9093],
            [-53.3436, 47.9555],
            [-53.3063, 48.0048],
            [-53.2147, 48.0273],
            [-53.1691, 48.063],
            [-53.1149, 48.0342],
            [-53.0069, 48.0831],
            [-52.9461, 48.175],
            [-52.8875, 48.1535],
            [-52.8611, 48.0931],
            [-52.921, 48.0926],
            [-52.9823, 48.0405],
            [-52.9785, 47.9955],
            [-53.0781, 47.9382],
            [-53.0931, 47.8305],
            [-53.1475, 47.8041],
            [-53.1483, 47.778],
            [-53.1915, 47.7416],
            [-53.1753, 47.6747],
            [-53.2036, 47.645],
            [-53.2721, 47.6228],
            [-53.2765, 47.5846],
            [-53.1782, 47.5381],
            [-53.196, 47.4703],
            [-53.1612, 47.474],
            [-53.1268, 47.4288],
            [-53.0818, 47.4717],
            [-53.0233, 47.5042],
            [-52.9194, 47.5404],
            [-52.8775, 47.6111],
            [-52.8556, 47.6252],
            [-52.8457, 47.7049],
            [-52.8032, 47.8072],
            [-52.7104, 47.7557],
            [-52.6946, 47.7067],
            [-52.7113, 47.6642],
            [-52.6599, 47.6474],
            [-52.654, 47.5994],
            [-52.6892, 47.5301],
            [-52.6439, 47.533],
            [-52.6458, 47.4891],
            [-52.7106, 47.4644],
            [-52.6579, 47.4356],
            [-52.715, 47.3936],
            [-52.7658, 47.2878],
            [-52.8333, 47.2787],
            [-52.7983, 47.2439],
            [-52.8487, 47.21],
            [-52.8499, 47.1502],
            [-52.9068, 47.0836],
            [-52.8669, 47.0656],
            [-52.9025, 47.0001],
            [-52.8922, 46.9396],
            [-52.9437, 46.8598],
            [-52.9375, 46.8169],
            [-53.0352, 46.747],
            [-53.0723, 46.6582],
            [-53.1603, 46.6256],
            [-53.2217, 46.6456],
            [-53.26, 46.6855],
            [-53.3763, 46.747],
            [-53.4463, 46.6722],
            [-53.5233, 46.6173],
            [-53.5972, 46.6192],
            [-53.6424, 46.6809],
            [-53.6473, 46.7233],
            [-53.6107, 46.7593],
            [-53.6641, 46.7978],
            [-53.6531, 46.8338],
            [-53.6052, 46.8861],
            [-53.5762, 46.9385],
            [-53.6288, 46.9495],
            [-53.6166, 46.9937],
            [-53.6455, 47.0055],
            [-53.5691, 47.0679],
            [-53.58, 47.0941],
            [-53.5298, 47.1633],
            [-53.5662, 47.1811],
            [-53.5643, 47.1867],
            [-53.6078, 47.169],
            [-53.6257, 47.1193],
            [-53.6968, 47.0782],
            [-53.75, 47.034],
            [-53.7732, 47.0434],
            [-53.8105, 46.9837],
            [-53.8739, 46.9187],
            [-53.9881, 46.8335],
            [-54.0583, 46.8012],
            [-54.0985, 46.7966],
            [-54.1848, 46.8445],
            [-54.1996, 46.8865],
            [-54.1754, 46.918],
            [-54.1679, 46.9902],
            [-54.0757, 47.0967],
            [-54.0618, 47.1618],
            [-54.0178, 47.2239],
            [-53.9955, 47.2885],
            [-53.9709, 47.3146],
            [-53.8753, 47.3508],
            [-53.9298, 47.3828],
            [-53.8337, 47.4134],
            [-53.9374, 47.4523],
            [-53.9112, 47.4878],
            [-53.9142, 47.5305],
            [-53.894, 47.5874],
            [-53.9321, 47.614],
            [-53.9696, 47.6877],
            [-53.9694, 47.7643],
            [-54.0021, 47.7955],
            [-54.0436, 47.7952],
            [-54.1546, 47.844],
            [-54.1807, 47.8768],
            [-54.2641, 47.9103],
            [-54.1891, 47.8602],
            [-54.2074, 47.795],
            [-54.2485, 47.754],
            [-54.2669, 47.7077],
            [-54.3313, 47.6917],
            [-54.3156, 47.6625],
            [-54.4299, 47.5028],
            [-54.4047, 47.4517],
            [-54.5996, 47.348],
            [-54.6305, 47.3893],
            [-54.7138, 47.3619],
            [-54.7668, 47.3776],
            [-54.8275, 47.3642],
            [-54.8526, 47.3855],
            [-54.9158, 47.3578],
            [-54.93, 47.3181],
            [-54.9752, 47.2765],
            [-55.0455, 47.2364],
            [-55.0907, 47.1667],
            [-55.0759, 47.0785],
            [-55.1532, 47.045],
            [-55.2045, 47.009],
            [-55.2051, 46.9794],
            [-55.2627, 46.9171],
            [-55.4002, 46.8835],
            [-55.4785, 46.8774],
            [-55.5091, 46.914],
            [-55.5749, 46.9206],
            [-55.6306, 46.8758],
            [-55.717, 46.8809],
            [-55.8238, 46.8613],
            [-55.9686, 46.9093],
            [-55.989, 46.9534],
            [-55.9763, 46.9941],
            [-55.9222, 47.0235],
            [-55.8669, 47.0719],
            [-55.812, 47.0792],
            [-55.7736, 47.1101],
            [-55.6807, 47.0845],
            [-55.6295, 47.1096],
            [-55.5203, 47.143],
            [-55.4135, 47.2222],
            [-55.3864, 47.2188],
            [-55.3111, 47.2703],
            [-55.2866, 47.369],
            [-55.2948, 47.3895],
            [-55.1706, 47.472],
            [-55.0243, 47.5045],
            [-54.9581, 47.5037],
            [-54.8779, 47.5407],
            [-54.8531, 47.583],
            [-54.8743, 47.6027],
            [-54.7009, 47.6728],
            [-54.785, 47.6748],
            [-54.8846, 47.6324],
            [-54.9335, 47.6079],
            [-55.0277, 47.5911],
            [-55.0927, 47.5921],
            [-55.0709, 47.6403],
            [-55.1424, 47.6295],
            [-55.2697, 47.6447],
            [-55.3869, 47.676],
            [-55.3523, 47.7063],
            [-55.394, 47.7238],
            [-55.4474, 47.7034],
            [-55.4219, 47.6772],
            [-55.4764, 47.6243],
            [-55.4012, 47.6202],
            [-55.4246, 47.5473],
            [-55.4014, 47.5205],
            [-55.4129, 47.4826],
            [-55.4925, 47.4543],
            [-55.55, 47.455],
            [-55.5905, 47.4351],
            [-55.6163, 47.4788],
            [-55.6596, 47.4946],
            [-55.7781, 47.4491],
            [-55.9022, 47.4776],
            [-55.7465, 47.5867],
            [-55.7903, 47.5872],
            [-55.8755, 47.5363],
            [-55.9419, 47.5328],
            [-55.9555, 47.5092],
            [-56.1175, 47.4642],
            [-56.1885, 47.5059],
            [-55.9621, 47.5538],
            [-55.8628, 47.5993],
            [-55.882, 47.6325],
            [-55.9198, 47.6424],
            [-55.9281, 47.6777],
            [-55.8672, 47.7431],
            [-55.7358, 47.8144],
            [-55.822, 47.7827],
            [-55.8286, 47.8331],
            [-55.7509, 47.8634],
            [-55.7072, 47.8981],
            [-55.7568, 47.868],
            [-55.8068, 47.8859],
            [-55.8599, 47.8131],
            [-55.8415, 47.7908],
            [-55.9206, 47.7647],
            [-56.0053, 47.7573],
            [-56.0544, 47.6999],
            [-56.117, 47.6919],
            [-56.1565, 47.7231],
            [-56.1661, 47.6352],
            [-56.3235, 47.6272],
            [-56.3853, 47.6033],
            [-56.525, 47.6164],
            [-56.6251, 47.6148],
            [-56.6676, 47.5857],
            [-56.8057, 47.5323],
            [-56.8592, 47.5672],
            [-56.8708, 47.6231],
            [-56.8641, 47.5641],
            [-56.9354, 47.5646],
            [-57.0135, 47.5983],
            [-57.0815, 47.5742],
            [-57.1394, 47.5755],
            [-57.1793, 47.5967],
            [-57.342, 47.5881],
            [-57.3222, 47.6407],
            [-57.4152, 47.6428],
            [-57.4989, 47.6239],
            [-57.5856, 47.6392],
            [-57.6139, 47.6214],
            [-57.6143, 47.6214],
            [-57.6747, 47.6149],
            [-57.6743, 47.6155],
            [-57.7258, 47.6616],
            [-57.7572, 47.6588],
            [-57.7844, 47.6194],
            [-57.8494, 47.6608],
            [-57.9128, 47.6512],
            [-57.9672, 47.6599],
            [-58.0348, 47.6892],
            [-58.0793, 47.6738],
            [-58.1348, 47.6968],
            [-58.2406, 47.672],
            [-58.3029, 47.68],
            [-58.316, 47.7304],
            [-58.3756, 47.7259],
            [-58.3719, 47.6909],
            [-58.4179, 47.6686],
            [-58.4232, 47.6402],
            [-58.5054, 47.6649],
            [-58.6316, 47.6149],
            [-58.6529, 47.6227],
            [-58.801, 47.5972],
            [-58.8512, 47.608],
          ],
        ],
        [
          [
            [-55.8386, 53.0038],
            [-55.7779, 53.0124],
            [-55.7562, 52.9899],
            [-55.8037, 52.965],
            [-55.8386, 53.0038],
          ],
        ],
        [
          [
            [-55.8402, 53.0862],
            [-55.7911, 53.0948],
            [-55.7396, 53.0728],
            [-55.7584, 53.025],
            [-55.8226, 53.0282],
            [-55.871, 53.0505],
            [-55.8402, 53.0862],
          ],
        ],
        [
          [
            [-55.9742, 53.4695],
            [-55.8827, 53.4883],
            [-55.8217, 53.4688],
            [-55.7717, 53.3966],
            [-55.9555, 53.4243],
            [-55.9742, 53.4695],
          ],
        ],
        [
          [
            [-56.9864, 53.8051],
            [-56.8998, 53.797],
            [-56.9353, 53.743],
            [-56.9993, 53.7869],
            [-56.9864, 53.8051],
          ],
        ],
        [
          [
            [-57.1252, 53.6959],
            [-57.06, 53.6975],
            [-57.0939, 53.6414],
            [-57.1553, 53.6543],
            [-57.1252, 53.6959],
          ],
        ],
        [
          [
            [-53.0177, 47.6143],
            [-52.9555, 47.6532],
            [-52.9189, 47.6308],
            [-52.9837, 47.5967],
            [-53.0177, 47.6143],
          ],
        ],
        [
          [
            [-54.3367, 47.419],
            [-54.2702, 47.4657],
            [-54.1789, 47.5957],
            [-54.1426, 47.5835],
            [-54.1893, 47.4862],
            [-54.2433, 47.4037],
            [-54.3049, 47.3909],
            [-54.3367, 47.419],
          ],
        ],
        [
          [
            [-53.947, 48.2094],
            [-53.9031, 48.2039],
            [-53.8674, 48.1571],
            [-53.7767, 48.1787],
            [-53.7233, 48.1461],
            [-53.6882, 48.147],
            [-53.5847, 48.1857],
            [-53.5266, 48.1389],
            [-53.6171, 48.0774],
            [-53.8003, 48.0973],
            [-53.8574, 48.0903],
            [-53.8951, 48.1107],
            [-53.9108, 48.1531],
            [-53.9463, 48.174],
            [-53.947, 48.2094],
          ],
        ],
        [
          [
            [-53.7545, 48.8296],
            [-53.6657, 48.88],
            [-53.6276, 48.8647],
            [-53.7257, 48.828],
            [-53.7545, 48.8296],
          ],
        ],
        [
          [
            [-54.285, 49.7153],
            [-54.2171, 49.7069],
            [-54.1534, 49.7538],
            [-54.1021, 49.7473],
            [-54.0414, 49.6682],
            [-54.0689, 49.6424],
            [-54.17, 49.6149],
            [-54.196, 49.5932],
            [-54.2884, 49.5618],
            [-54.3197, 49.5764],
            [-54.3071, 49.6237],
            [-54.3246, 49.6666],
            [-54.285, 49.7153],
          ],
        ],
        [
          [
            [-56.0331, 47.7147],
            [-55.9738, 47.7513],
            [-55.9171, 47.757],
            [-55.8979, 47.7297],
            [-56.0047, 47.7096],
            [-56.0331, 47.7147],
          ],
        ],
        [
          [
            [-56.1115, 47.6512],
            [-56.0191, 47.6818],
            [-55.9636, 47.6833],
            [-55.9352, 47.6513],
            [-55.8781, 47.6136],
            [-55.9119, 47.5985],
            [-56.0159, 47.6094],
            [-56.1031, 47.6298],
            [-56.1115, 47.6512],
          ],
        ],
        [
          [
            [-54.786, 49.4333],
            [-54.7077, 49.4693],
            [-54.7008, 49.399],
            [-54.762, 49.4083],
            [-54.786, 49.4333],
          ],
        ],
        [
          [
            [-61.2051, 56.8176],
            [-61.1672, 56.8482],
            [-61.0945, 56.8152],
            [-61.1437, 56.7956],
            [-61.2051, 56.8176],
          ],
        ],
        [
          [
            [-61.5654, 56.5954],
            [-61.4457, 56.5871],
            [-61.4457, 56.5574],
            [-61.5643, 56.568],
            [-61.5654, 56.5954],
          ],
        ],
        [
          [
            [-61.6088, 56.5533],
            [-61.4749, 56.5384],
            [-61.402, 56.5063],
            [-61.3507, 56.5227],
            [-61.317, 56.4805],
            [-61.2295, 56.4677],
            [-61.2463, 56.437],
            [-61.4413, 56.4474],
            [-61.5014, 56.4444],
            [-61.6288, 56.4591],
            [-61.6088, 56.5533],
          ],
        ],
        [
          [
            [-61.7851, 56.4089],
            [-61.5361, 56.3799],
            [-61.4998, 56.401],
            [-61.42, 56.3666],
            [-61.4854, 56.3416],
            [-61.4863, 56.3067],
            [-61.7079, 56.3474],
            [-61.7851, 56.4089],
          ],
        ],
        [
          [
            [-61.876, 56.4596],
            [-61.779, 56.4597],
            [-61.7258, 56.4226],
            [-61.8536, 56.4318],
            [-61.876, 56.4596],
          ],
        ],
        [
          [
            [-61.6202, 56.8638],
            [-61.6017, 56.8868],
            [-61.5357, 56.8956],
            [-61.4981, 56.9514],
            [-61.4567, 56.9294],
            [-61.3433, 56.9264],
            [-61.3775, 56.8941],
            [-61.3689, 56.8434],
            [-61.4162, 56.8162],
            [-61.4738, 56.8458],
            [-61.4903, 56.8009],
            [-61.3924, 56.7842],
            [-61.3647, 56.679],
            [-61.4082, 56.606],
            [-61.4561, 56.6075],
            [-61.4587, 56.653],
            [-61.571, 56.6719],
            [-61.5727, 56.6936],
            [-61.6383, 56.7243],
            [-61.6453, 56.7654],
            [-61.6147, 56.8024],
            [-61.6202, 56.8638],
          ],
        ],
        [
          [
            [-61.7466, 57.3729],
            [-61.6426, 57.3575],
            [-61.6176, 57.3321],
            [-61.7333, 57.288],
            [-61.7779, 57.3126],
            [-61.7466, 57.3729],
          ],
        ],
        [
          [
            [-61.9223, 57.2421],
            [-61.8237, 57.2546],
            [-61.8418, 57.2124],
            [-61.9223, 57.2421],
          ],
        ],
        [
          [
            [-61.9214, 57.4402],
            [-61.8752, 57.4454],
            [-61.7754, 57.4976],
            [-61.7192, 57.5393],
            [-61.6368, 57.507],
            [-61.6988, 57.4397],
            [-61.6047, 57.4044],
            [-61.6667, 57.3808],
            [-61.7173, 57.4009],
            [-61.84, 57.4031],
            [-61.9214, 57.4402],
          ],
        ],
        [
          [
            [-62.0129, 57.556],
            [-61.9492, 57.5453],
            [-61.9038, 57.5825],
            [-61.8546, 57.5806],
            [-61.7811, 57.5464],
            [-61.7858, 57.5088],
            [-61.8707, 57.4526],
            [-61.9326, 57.4432],
            [-61.9772, 57.4578],
            [-62.0399, 57.5192],
            [-62.0129, 57.556],
          ],
        ],
        [
          [
            [-61.7366, 57.91],
            [-61.6516, 57.9223],
            [-61.645, 57.8878],
            [-61.7282, 57.8846],
            [-61.7366, 57.91],
          ],
        ],
        [
          [
            [-61.8857, 57.7905],
            [-61.8605, 57.8186],
            [-61.7845, 57.8431],
            [-61.6524, 57.7799],
            [-61.6852, 57.7017],
            [-61.744, 57.7024],
            [-61.8857, 57.7905],
          ],
        ],
        [
          [
            [-64.5003, 60.3666],
            [-64.4938, 60.34],
            [-64.4258, 60.3281],
            [-64.4316, 60.2874],
            [-64.515, 60.3076],
            [-64.5343, 60.3414],
            [-64.5003, 60.3666],
          ],
        ],
        [
          [
            [-57.3843, 54.1492],
            [-57.3571, 54.0936],
            [-57.2643, 53.9912],
            [-57.1957, 53.9505],
            [-57.2071, 53.9193],
            [-57.1786, 53.864],
            [-57.1413, 53.8393],
            [-57.071, 53.8273],
            [-57.1433, 53.753],
            [-57.1361, 53.7339],
            [-57.2557, 53.6784],
            [-57.4434, 53.6296],
            [-57.3998, 53.5896],
            [-57.3118, 53.589],
            [-57.3233, 53.541],
            [-57.2702, 53.5314],
            [-57.2952, 53.4826],
            [-57.1873, 53.4983],
            [-57.1262, 53.5334],
            [-57.109, 53.5659],
            [-57.1351, 53.6006],
            [-57.1114, 53.6265],
            [-57.0448, 53.6388],
            [-57.0521, 53.6664],
            [-57.0042, 53.6854],
            [-56.9538, 53.7282],
            [-56.848, 53.709],
            [-56.7161, 53.7255],
            [-56.6205, 53.711],
            [-56.6028, 53.7541],
            [-56.4411, 53.7734],
            [-56.407, 53.7468],
            [-56.4781, 53.7091],
            [-56.5061, 53.7145],
            [-56.6172, 53.6517],
            [-56.509, 53.619],
            [-56.4045, 53.6047],
            [-56.3296, 53.6299],
            [-56.2642, 53.6086],
            [-56.2206, 53.5663],
            [-56.1642, 53.5959],
            [-56.0919, 53.5704],
            [-56.0588, 53.5792],
            [-55.9902, 53.5531],
            [-55.9981, 53.4934],
            [-56.0646, 53.5286],
            [-56.1584, 53.5529],
            [-56.2453, 53.5564],
            [-56.1557, 53.4977],
            [-56.0263, 53.466],
            [-56.0398, 53.4401],
            [-55.9902, 53.4053],
            [-55.9153, 53.3922],
            [-55.8672, 53.3668],
            [-55.8522, 53.3129],
            [-55.7782, 53.2257],
            [-55.7889, 53.191],
            [-55.7421, 53.1676],
            [-55.7396, 53.1348],
            [-55.8536, 53.0888],
            [-55.9193, 53.0098],
            [-55.8987, 52.9704],
            [-55.8604, 52.9625],
            [-55.8024, 52.8853],
            [-55.7991, 52.833],
            [-55.8615, 52.8524],
            [-55.9059, 52.8192],
            [-55.971, 52.8219],
            [-56.006, 52.7778],
            [-56.0696, 52.7839],
            [-55.984, 52.7176],
            [-55.9505, 52.6768],
            [-55.8911, 52.6956],
            [-55.763, 52.6878],
            [-55.7546, 52.6087],
            [-55.7913, 52.5982],
            [-55.8442, 52.6193],
            [-55.8901, 52.6106],
            [-55.9226, 52.6376],
            [-55.985, 52.6503],
            [-55.9979, 52.6249],
            [-55.964, 52.6208],
            [-55.9647, 52.5974],
            [-56.0625, 52.58],
            [-56.1179, 52.5438],
            [-56.0374, 52.537],
            [-55.9728, 52.5018],
            [-55.8491, 52.5089],
            [-55.7404, 52.4922],
            [-55.7585, 52.4409],
            [-55.6847, 52.4563],
            [-55.6472, 52.4377],
            [-55.6194, 52.3634],
            [-55.6385, 52.3481],
            [-55.7817, 52.3673],
            [-55.8033, 52.3333],
            [-55.9031, 52.3529],
            [-55.9482, 52.3749],
            [-56.1202, 52.4256],
            [-56.0977, 52.3969],
            [-55.9642, 52.3676],
            [-55.8969, 52.3278],
            [-55.8156, 52.3182],
            [-55.7612, 52.2817],
            [-55.7851, 52.2274],
            [-55.7122, 52.248],
            [-55.6158, 52.2126],
            [-55.6821, 52.1631],
            [-55.6914, 52.0799],
            [-55.7598, 52.0603],
            [-55.7782, 52.0236],
            [-55.8984, 51.9962],
            [-55.8885, 51.9626],
            [-55.9307, 51.9342],
            [-56.1375, 51.8373],
            [-56.2142, 51.7847],
            [-56.3298, 51.7603],
            [-56.4198, 51.726],
            [-56.6109, 51.6391],
            [-56.6976, 51.6315],
            [-56.7103, 51.5765],
            [-56.7613, 51.5594],
            [-56.8391, 51.4693],
            [-56.9551, 51.473],
            [-56.9582, 51.4244],
            [-57.0346, 51.4128],
            [-57.1085, 51.4154],
            [-57.1078, 51.9985],
            [-57.9647, 51.9964],
            [-59.0344, 51.997],
            [-60.4417, 51.9965],
            [-61.8065, 51.9958],
            [-63.8082, 51.9978],
            [-63.8123, 52.0336],
            [-63.8402, 52.065],
            [-63.8241, 52.0847],
            [-63.7875, 52.0783],
            [-63.7794, 52.0836],
            [-63.7064, 52.0497],
            [-63.6782, 52.0547],
            [-63.6464, 52.0424],
            [-63.6906, 52.1298],
            [-63.7261, 52.2129],
            [-63.7707, 52.2838],
            [-63.8269, 52.3308],
            [-63.826, 52.3196],
            [-63.8733, 52.3225],
            [-63.9653, 52.3577],
            [-64.0128, 52.3626],
            [-64.0207, 52.401],
            [-64.0936, 52.4661],
            [-64.0327, 52.5683],
            [-63.8718, 52.6188],
            [-63.5835, 52.6487],
            [-63.4295, 52.6455],
            [-63.4081, 52.6617],
            [-63.3965, 52.6823],
            [-63.4501, 52.7136],
            [-63.5281, 52.7276],
            [-63.5964, 52.7956],
            [-63.693, 52.8146],
            [-63.7216, 52.7703],
            [-63.819, 52.7585],
            [-63.873, 52.774],
            [-63.9196, 52.7578],
            [-63.9375, 52.731],
            [-63.9828, 52.7254],
            [-64.0071, 52.737],
            [-64.0374, 52.7382],
            [-64.0394, 52.7259],
            [-64.0536, 52.7232],
            [-64.0796, 52.7366],
            [-64.1406, 52.7267],
            [-64.1277, 52.6864],
            [-64.1531, 52.6473],
            [-64.1546, 52.6034],
            [-64.2059, 52.5772],
            [-64.152, 52.5199],
            [-64.1093, 52.394],
            [-64.1584, 52.3389],
            [-64.2163, 52.313],
            [-64.2467, 52.2754],
            [-64.2176, 52.2571],
            [-64.2008, 52.177],
            [-64.1609, 52.146],
            [-64.1775, 52.1338],
            [-64.1664, 52.116],
            [-64.1804, 52.115],
            [-64.2364, 52.1293],
            [-64.2539, 52.1215],
            [-64.2516, 52.0797],
            [-64.2795, 52.0375],
            [-64.234, 51.9909],
            [-64.2372, 51.9779],
            [-64.2802, 51.9747],
            [-64.3231, 51.9944],
            [-64.323, 52.0048],
            [-64.3611, 52.0055],
            [-64.3398, 51.9891],
            [-64.3589, 51.9791],
            [-64.3431, 51.9676],
            [-64.3574, 51.9273],
            [-64.3528, 51.8493],
            [-64.3026, 51.8036],
            [-64.302, 51.757],
            [-64.2741, 51.7423],
            [-64.3372, 51.6653],
            [-64.359, 51.6715],
            [-64.3911, 51.6629],
            [-64.4231, 51.6761],
            [-64.4554, 51.6246],
            [-64.5227, 51.5976],
            [-64.5486, 51.5739],
            [-64.6666, 51.7006],
            [-64.7099, 51.7559],
            [-64.7395, 51.7614],
            [-64.7732, 51.7531],
            [-64.7942, 51.7611],
            [-64.8055, 51.7781],
            [-64.8479, 51.7625],
            [-64.9248, 51.7778],
            [-64.9388, 51.7644],
            [-64.92, 51.7608],
            [-64.9278, 51.7464],
            [-64.91, 51.7366],
            [-64.9308, 51.7142],
            [-64.951, 51.7154],
            [-64.9725, 51.7216],
            [-64.9994, 51.75],
            [-65.0207, 51.7585],
            [-65.0102, 51.7651],
            [-65.0304, 51.762],
            [-65.0494, 51.7696],
            [-65.0913, 51.7459],
            [-65.1806, 51.7656],
            [-65.2048, 51.8128],
            [-65.2646, 51.8367],
            [-65.254, 51.8614],
            [-65.3004, 51.8779],
            [-65.3235, 51.87],
            [-65.318, 51.8507],
            [-65.3317, 51.829],
            [-65.3463, 51.8197],
            [-65.3599, 51.8227],
            [-65.3628, 51.8642],
            [-65.3997, 51.9019],
            [-65.3495, 51.9424],
            [-65.4125, 51.9713],
            [-65.4234, 52.0021],
            [-65.4564, 52.0111],
            [-65.4962, 52.0502],
            [-65.4903, 52.1021],
            [-65.4995, 52.1104],
            [-65.5401, 52.0445],
            [-65.5795, 52.0328],
            [-65.6207, 52.043],
            [-65.6342, 51.9999],
            [-65.6504, 51.9868],
            [-65.6776, 52.0076],
            [-65.6514, 52.0552],
            [-65.6684, 52.1172],
            [-65.7341, 52.0828],
            [-65.7596, 52.1037],
            [-65.8422, 52.1164],
            [-65.8772, 52.1104],
            [-65.8979, 52.076],
            [-65.9615, 52.1046],
            [-65.9706, 52.0565],
            [-65.9964, 52.0547],
            [-66.0158, 52.0799],
            [-66.0966, 52.1078],
            [-66.0761, 52.155],
            [-66.1041, 52.1985],
            [-66.1523, 52.2305],
            [-66.1602, 52.2316],
            [-66.1725, 52.2134],
            [-66.201, 52.227],
            [-66.215, 52.2548],
            [-66.2837, 52.2853],
            [-66.3128, 52.2855],
            [-66.2644, 52.1651],
            [-66.2682, 52.1503],
            [-66.3238, 52.1318],
            [-66.3335, 52.1507],
            [-66.3582, 52.1465],
            [-66.38, 52.1626],
            [-66.3571, 52.1766],
            [-66.4029, 52.2169],
            [-66.4908, 52.3416],
            [-66.4368, 52.3588],
            [-66.4414, 52.3758],
            [-66.4201, 52.383],
            [-66.3813, 52.3675],
            [-66.3641, 52.3513],
            [-66.3443, 52.3604],
            [-66.342, 52.3972],
            [-66.3732, 52.413],
            [-66.3591, 52.4307],
            [-66.3933, 52.4761],
            [-66.3941, 52.4863],
            [-66.3609, 52.4664],
            [-66.3558, 52.4732],
            [-66.3666, 52.5097],
            [-66.3714, 52.552],
            [-66.4405, 52.6364],
            [-66.4166, 52.642],
            [-66.4025, 52.6745],
            [-66.3889, 52.6823],
            [-66.341, 52.6454],
            [-66.3095, 52.6046],
            [-66.2911, 52.6],
            [-66.2805, 52.6174],
            [-66.2779, 52.6344],
            [-66.3393, 52.6846],
            [-66.3089, 52.7286],
            [-66.3547, 52.8026],
            [-66.4168, 52.8583],
            [-66.2584, 52.8691],
            [-66.3225, 52.9389],
            [-66.3272, 52.9726],
            [-66.3708, 53.0248],
            [-66.3941, 53.0212],
            [-66.4324, 53.0482],
            [-66.4598, 53.0552],
            [-66.4756, 53.0425],
            [-66.5206, 52.9639],
            [-66.5381, 52.944],
            [-66.5705, 52.937],
            [-66.6261, 52.9542],
            [-66.6509, 52.9219],
            [-66.6639, 52.8903],
            [-66.6654, 52.8309],
            [-66.6477, 52.7836],
            [-66.7543, 52.7515],
            [-66.7482, 52.703],
            [-66.7727, 52.6962],
            [-66.7581, 52.6733],
            [-66.7653, 52.6662],
            [-66.7917, 52.6785],
            [-66.8016, 52.7086],
            [-66.8174, 52.7112],
            [-66.8605, 52.7684],
            [-66.8563, 52.7173],
            [-66.8729, 52.681],
            [-66.8951, 52.6767],
            [-66.9179, 52.7152],
            [-66.9445, 52.7369],
            [-66.9499, 52.7596],
            [-66.9793, 52.7531],
            [-66.9901, 52.7621],
            [-67.0164, 52.744],
            [-67.0346, 52.747],
            [-67.0399, 52.7675],
            [-67.0691, 52.7777],
            [-67.048, 52.8372],
            [-67.0635, 52.8754],
            [-67.1229, 52.8624],
            [-67.1345, 52.851],
            [-67.1498, 52.8544],
            [-67.1615, 52.8174],
            [-67.2278, 52.8418],
            [-67.2689, 52.8777],
            [-67.3002, 52.8845],
            [-67.3249, 52.8778],
            [-67.3422, 52.8962],
            [-67.3333, 52.9072],
            [-67.3561, 52.9286],
            [-67.3457, 52.9812],
            [-67.3909, 53.0768],
            [-67.3857, 53.132],
            [-67.2884, 53.1403],
            [-67.303, 53.1589],
            [-67.2633, 53.1813],
            [-67.218, 53.1497],
            [-67.1546, 53.1472],
            [-67.1165, 53.1051],
            [-67.0688, 53.0789],
            [-67.0419, 53.0761],
            [-66.9898, 53.0843],
            [-66.9912, 53.1175],
            [-66.959, 53.1371],
            [-66.9955, 53.1384],
            [-67.0138, 53.16],
            [-66.9599, 53.1817],
            [-66.9784, 53.2133],
            [-66.9587, 53.3064],
            [-67.0274, 53.3346],
            [-66.9664, 53.3646],
            [-66.9247, 53.4187],
            [-66.9353, 53.4415],
            [-66.9253, 53.4545],
            [-66.9382, 53.4731],
            [-66.9961, 53.4642],
            [-66.9859, 53.4962],
            [-67.007, 53.5],
            [-67.0362, 53.5288],
            [-67.0623, 53.5371],
            [-67.0951, 53.5271],
            [-67.1302, 53.5302],
            [-67.1803, 53.553],
            [-67.1895, 53.5508],
            [-67.1894, 53.5328],
            [-67.2191, 53.5303],
            [-67.2577, 53.5574],
            [-67.2866, 53.5435],
            [-67.319, 53.553],
            [-67.3139, 53.5842],
            [-67.3773, 53.5972],
            [-67.4259, 53.7042],
            [-67.5067, 53.7191],
            [-67.5004, 53.7573],
            [-67.5577, 53.7803],
            [-67.4982, 53.8367],
            [-67.5694, 53.8412],
            [-67.6095, 53.8693],
            [-67.5967, 53.9217],
            [-67.6696, 53.9303],
            [-67.6817, 53.9719],
            [-67.821, 54.02],
            [-67.7622, 54.0861],
            [-67.8107, 54.1282],
            [-67.7654, 54.157],
            [-67.6448, 54.1608],
            [-67.6235, 54.2085],
            [-67.6812, 54.247],
            [-67.6804, 54.312],
            [-67.7484, 54.3503],
            [-67.7251, 54.3869],
            [-67.7694, 54.4196],
            [-67.7279, 54.4574],
            [-67.6283, 54.4736],
            [-67.582, 54.4942],
            [-67.5008, 54.4816],
            [-67.4745, 54.5275],
            [-67.5187, 54.5523],
            [-67.4921, 54.585],
            [-67.4294, 54.5693],
            [-67.3582, 54.5174],
            [-67.33, 54.5182],
            [-67.3347, 54.5051],
            [-67.3187, 54.513],
            [-67.2901, 54.5088],
            [-67.287, 54.4903],
            [-67.2729, 54.4867],
            [-67.2692, 54.4951],
            [-67.2453, 54.5001],
            [-67.2807, 54.5918],
            [-67.2299, 54.6152],
            [-67.1389, 54.6246],
            [-67.1293, 54.6578],
            [-67.0632, 54.693],
            [-67.1252, 54.7422],
            [-67.1778, 54.764],
            [-67.1805, 54.7759],
            [-67.2225, 54.801],
            [-67.2217, 54.825],
            [-67.2595, 54.8339],
            [-67.2831, 54.8678],
            [-67.3355, 54.9036],
            [-67.3581, 54.9397],
            [-67.4001, 54.9579],
            [-67.4147, 54.9761],
            [-67.4023, 54.989],
            [-67.4462, 55.0086],
            [-67.44, 55.0326],
            [-67.471, 55.0491],
            [-67.4641, 55.0629],
            [-67.4436, 55.0649],
            [-67.4384, 55.0779],
            [-67.41, 55.0887],
            [-67.3232, 55.0891],
            [-67.2732, 55.0765],
            [-67.2343, 55.0513],
            [-67.2636, 55.0456],
            [-67.2731, 55.0243],
            [-67.2275, 55.0055],
            [-67.2096, 54.9842],
            [-67.1881, 54.9805],
            [-67.0568, 54.895],
            [-67.0334, 54.862],
            [-66.9482, 54.8224],
            [-66.9274, 54.8022],
            [-66.876, 54.8161],
            [-66.813, 54.7794],
            [-66.7735, 54.766],
            [-66.7541, 54.7456],
            [-66.7445, 54.7501],
            [-66.7145, 54.732],
            [-66.6959, 54.7331],
            [-66.6798, 54.745],
            [-66.7308, 54.7668],
            [-66.724, 54.7887],
            [-66.6597, 54.7703],
            [-66.6029, 54.8136],
            [-66.6899, 54.898],
            [-66.6817, 54.9237],
            [-66.7425, 54.9695],
            [-66.7405, 54.9852],
            [-66.7663, 55.0001],
            [-66.7497, 55.0001],
            [-66.7477, 55.0094],
            [-66.7372, 55.0001],
            [-66.7091, 55.0047],
            [-66.6504, 54.9747],
            [-66.6259, 54.9839],
            [-66.672, 55.0256],
            [-66.7353, 55.0441],
            [-66.7552, 55.1136],
            [-66.7384, 55.1911],
            [-66.7075, 55.2272],
            [-66.824, 55.307],
            [-66.8002, 55.3233],
            [-66.8186, 55.3485],
            [-66.8081, 55.3568],
            [-66.7788, 55.3525],
            [-66.7349, 55.3213],
            [-66.7006, 55.3121],
            [-66.7009, 55.29],
            [-66.6589, 55.2552],
            [-66.6447, 55.2672],
            [-66.6183, 55.2782],
            [-66.566, 55.2385],
            [-66.5459, 55.2354],
            [-66.5167, 55.1954],
            [-66.5077, 55.2048],
            [-66.493, 55.2034],
            [-66.4199, 55.1312],
            [-66.2682, 55.0149],
            [-66.2467, 54.9755],
            [-66.2358, 54.9919],
            [-66.2104, 54.9792],
            [-66.1888, 54.9808],
            [-66.1682, 54.9731],
            [-66.1618, 54.9878],
            [-66.1528, 54.9856],
            [-66.041, 54.9191],
            [-65.9307, 54.941],
            [-65.8882, 54.9254],
            [-65.8501, 54.9273],
            [-65.8388, 54.908],
            [-65.8574, 54.893],
            [-65.8366, 54.8747],
            [-65.8509, 54.8508],
            [-65.7572, 54.7868],
            [-65.6934, 54.7082],
            [-65.6487, 54.7353],
            [-65.6472, 54.7532],
            [-65.6323, 54.7545],
            [-65.6215, 54.7385],
            [-65.5623, 54.7398],
            [-65.5576, 54.7303],
            [-65.5041, 54.7363],
            [-65.4875, 54.7286],
            [-65.4557, 54.7286],
            [-65.4293, 54.7482],
            [-65.4348, 54.7842],
            [-65.4875, 54.8344],
            [-65.4565, 54.8493],
            [-65.4115, 54.8419],
            [-65.4077, 54.8199],
            [-65.3847, 54.83],
            [-65.356, 54.8188],
            [-65.336, 54.852],
            [-65.2965, 54.8176],
            [-65.2829, 54.8444],
            [-65.2098, 54.848],
            [-65.1738, 54.9143],
            [-65.1347, 54.9282],
            [-65.1266, 54.9615],
            [-65.086, 54.9696],
            [-65.0708, 54.9536],
            [-65.0483, 54.9555],
            [-65.0179, 54.9327],
            [-64.972, 54.9374],
            [-64.9152, 54.92],
            [-64.9027, 54.9017],
            [-64.816, 54.8491],
            [-64.7612, 54.8296],
            [-64.8012, 54.7558],
            [-64.7698, 54.7301],
            [-64.735, 54.736],
            [-64.7311, 54.7257],
            [-64.6995, 54.7236],
            [-64.6801, 54.7357],
            [-64.623, 54.7219],
            [-64.5977, 54.7287],
            [-64.557, 54.7137],
            [-64.5308, 54.7336],
            [-64.4749, 54.7522],
            [-64.4856, 54.771],
            [-64.4716, 54.7703],
            [-64.4929, 54.7911],
            [-64.3976, 54.7905],
            [-64.2948, 54.7534],
            [-64.2883, 54.7311],
            [-64.1966, 54.7299],
            [-64.1808, 54.6936],
            [-64.1029, 54.6556],
            [-64.1131, 54.6335],
            [-64.0544, 54.6009],
            [-64.0352, 54.6071],
            [-64.0041, 54.6021],
            [-63.9745, 54.6201],
            [-63.8979, 54.6007],
            [-63.8702, 54.6234],
            [-63.8347, 54.6299],
            [-63.8344, 54.6412],
            [-63.8197, 54.6407],
            [-63.8135, 54.6508],
            [-63.7327, 54.6231],
            [-63.7127, 54.625],
            [-63.7239, 54.6598],
            [-63.7632, 54.6737],
            [-63.803, 54.7257],
            [-63.8898, 54.7684],
            [-63.9241, 54.7784],
            [-63.9037, 54.8011],
            [-63.8719, 54.8005],
            [-63.8691, 54.8071],
            [-63.8172, 54.8169],
            [-63.8527, 54.8761],
            [-63.8299, 54.9421],
            [-63.7918, 54.9559],
            [-63.7161, 54.9256],
            [-63.6849, 54.9046],
            [-63.6614, 54.9],
            [-63.6635, 54.9105],
            [-63.6308, 54.9092],
            [-63.6197, 54.8996],
            [-63.5915, 54.8966],
            [-63.6124, 54.92],
            [-63.5779, 54.93],
            [-63.6114, 54.9489],
            [-63.6111, 54.9771],
            [-63.5732, 54.9788],
            [-63.5764, 54.9881],
            [-63.5576, 54.99],
            [-63.5577, 54.9999],
            [-63.6062, 55.0084],
            [-63.5565, 55.0345],
            [-63.6029, 55.0764],
            [-63.5652, 55.1403],
            [-63.5735, 55.1558],
            [-63.563, 55.1876],
            [-63.503, 55.2025],
            [-63.4527, 55.202],
            [-63.4126, 55.2337],
            [-63.4227, 55.2431],
            [-63.4029, 55.249],
            [-63.4026, 55.2578],
            [-63.446, 55.2514],
            [-63.4627, 55.2553],
            [-63.5085, 55.2321],
            [-63.5475, 55.2255],
            [-63.5634, 55.2434],
            [-63.6092, 55.2302],
            [-63.6226, 55.2426],
            [-63.6013, 55.2512],
            [-63.6306, 55.2562],
            [-63.6796, 55.2669],
            [-63.6442, 55.2878],
            [-63.5226, 55.3099],
            [-63.5745, 55.3396],
            [-63.4423, 55.3441],
            [-63.3249, 55.3661],
            [-63.3199, 55.4118],
            [-63.337, 55.4099],
            [-63.3519, 55.4184],
            [-63.435, 55.4004],
            [-63.4786, 55.4292],
            [-63.5107, 55.4203],
            [-63.5344, 55.4275],
            [-63.5491, 55.4208],
            [-63.5664, 55.4272],
            [-63.5785, 55.4205],
            [-63.6655, 55.4231],
            [-63.7368, 55.4383],
            [-63.7928, 55.4673],
            [-63.6916, 55.5087],
            [-63.6656, 55.546],
            [-63.6828, 55.6091],
            [-63.6686, 55.6523],
            [-63.7161, 55.6577],
            [-63.728, 55.6954],
            [-63.7055, 55.7556],
            [-63.7172, 55.7804],
            [-63.7805, 55.8131],
            [-63.753, 55.8465],
            [-63.8452, 55.8791],
            [-63.8252, 55.9045],
            [-63.8545, 55.9101],
            [-63.7927, 55.9315],
            [-63.7241, 55.9306],
            [-63.7037, 55.9461],
            [-63.6882, 55.9482],
            [-63.6866, 55.9618],
            [-63.5984, 55.9822],
            [-63.6059, 55.9879],
            [-63.5846, 55.9899],
            [-63.5631, 56.0059],
            [-63.5051, 56.0015],
            [-63.4874, 55.9875],
            [-63.4721, 55.9878],
            [-63.4703, 55.9984],
            [-63.4672, 56.0119],
            [-63.4436, 56.0296],
            [-63.4959, 56.0401],
            [-63.5777, 56.0251],
            [-63.6034, 56.0312],
            [-63.6368, 56.0236],
            [-63.6905, 56.0439],
            [-63.6831, 56.0596],
            [-63.7145, 56.0661],
            [-63.7149, 56.0717],
            [-63.7743, 56.0536],
            [-63.838, 56.0451],
            [-63.8111, 56.0847],
            [-63.8397, 56.1007],
            [-63.8384, 56.1226],
            [-63.8563, 56.1372],
            [-63.8802, 56.1361],
            [-63.9058, 56.1237],
            [-63.9482, 56.0912],
            [-63.9616, 56.0925],
            [-64.0182, 56.0673],
            [-64.0348, 56.0702],
            [-64.0359, 56.0822],
            [-64.0022, 56.1028],
            [-64.0048, 56.1734],
            [-63.863, 56.2126],
            [-63.8745, 56.2248],
            [-63.9116, 56.2309],
            [-63.913, 56.2636],
            [-63.935, 56.2712],
            [-63.9672, 56.2565],
            [-64.0223, 56.2679],
            [-64.0875, 56.2569],
            [-64.0906, 56.28],
            [-64.1305, 56.3024],
            [-64.0983, 56.3337],
            [-64.128, 56.37],
            [-64.1105, 56.386],
            [-64.1155, 56.4022],
            [-64.1438, 56.4055],
            [-64.1781, 56.4253],
            [-64.1529, 56.4412],
            [-64.1195, 56.4289],
            [-64.0207, 56.418],
            [-63.9931, 56.4083],
            [-63.9659, 56.4107],
            [-63.9019, 56.4408],
            [-63.9444, 56.4932],
            [-63.9312, 56.5338],
            [-64.0506, 56.6064],
            [-64.025, 56.6531],
            [-64.091, 56.6539],
            [-64.1454, 56.6998],
            [-64.079, 56.7671],
            [-64.0129, 56.7788],
            [-64.0036, 56.8618],
            [-63.8996, 56.8774],
            [-63.9182, 56.9042],
            [-63.9142, 56.9777],
            [-63.8921, 57.0129],
            [-63.8902, 57.0862],
            [-63.8119, 57.079],
            [-63.765, 57.14],
            [-63.7711, 57.1961],
            [-63.7373, 57.2217],
            [-63.7599, 57.2518],
            [-63.8093, 57.2622],
            [-63.8448, 57.226],
            [-63.8461, 57.2043],
            [-63.8725, 57.1926],
            [-63.8842, 57.1963],
            [-63.8671, 57.2262],
            [-63.8835, 57.2777],
            [-63.8559, 57.3344],
            [-63.7014, 57.3684],
            [-63.7104, 57.4227],
            [-63.7614, 57.4394],
            [-63.7753, 57.4937],
            [-63.7457, 57.5796],
            [-63.7307, 57.6012],
            [-63.6996, 57.6154],
            [-63.6847, 57.6035],
            [-63.6704, 57.6213],
            [-63.6501, 57.6202],
            [-63.645, 57.6344],
            [-63.6231, 57.6421],
            [-63.5988, 57.6639],
            [-63.5946, 57.6858],
            [-63.6096, 57.6922],
            [-63.5971, 57.7078],
            [-63.5969, 57.7414],
            [-63.6233, 57.7396],
            [-63.6327, 57.729],
            [-63.6526, 57.733],
            [-63.6682, 57.724],
            [-63.6595, 57.7019],
            [-63.6853, 57.697],
            [-63.7029, 57.6639],
            [-63.7354, 57.6629],
            [-63.7487, 57.6744],
            [-63.7538, 57.7208],
            [-63.8044, 57.6846],
            [-63.8836, 57.7127],
            [-63.9387, 57.7887],
            [-63.9974, 57.8122],
            [-64.021, 57.8096],
            [-64.0341, 57.7916],
            [-64.0261, 57.7645],
            [-64.0722, 57.7701],
            [-64.1168, 57.8113],
            [-64.1627, 57.9231],
            [-64.199, 57.9377],
            [-64.2048, 57.9738],
            [-64.2276, 57.9897],
            [-64.2383, 58.0162],
            [-64.2187, 58.0371],
            [-64.2779, 58.0471],
            [-64.2994, 58.0447],
            [-64.3207, 58.0616],
            [-64.4333, 58.07],
            [-64.4219, 58.1868],
            [-64.3737, 58.2085],
            [-64.2477, 58.2276],
            [-64.218, 58.2479],
            [-64.1869, 58.3369],
            [-64.1587, 58.3712],
            [-64.0982, 58.377],
            [-64.0618, 58.3611],
            [-64.0307, 58.3661],
            [-64.0134, 58.4241],
            [-63.9867, 58.4445],
            [-63.9244, 58.445],
            [-63.8813, 58.4296],
            [-63.808, 58.4899],
            [-63.8589, 58.5047],
            [-63.8584, 58.5639],
            [-63.8873, 58.5718],
            [-63.9075, 58.5736],
            [-63.9469, 58.559],
            [-63.9738, 58.5298],
            [-64.009, 58.5314],
            [-64.0343, 58.5236],
            [-64.0674, 58.5346],
            [-64.1051, 58.5634],
            [-64.1081, 58.6398],
            [-64.0474, 58.7028],
            [-63.9504, 58.6854],
            [-63.9101, 58.7104],
            [-63.8553, 58.6966],
            [-63.8358, 58.7103],
            [-63.8112, 58.7124],
            [-63.7944, 58.7034],
            [-63.7545, 58.7169],
            [-63.5619, 58.7279],
            [-63.4923, 58.7687],
            [-63.5504, 58.8225],
            [-63.5509, 58.8367],
            [-63.5955, 58.8573],
            [-63.6361, 58.8489],
            [-63.6613, 58.8549],
            [-63.6805, 58.8666],
            [-63.6801, 58.8787],
            [-63.7256, 58.8773],
            [-63.7779, 58.8842],
            [-63.8123, 58.8472],
            [-64.0455, 58.8105],
            [-64.0873, 58.7562],
            [-64.138, 58.7463],
            [-64.179, 58.7892],
            [-64.2003, 58.7796],
            [-64.2299, 58.7838],
            [-64.2339, 58.8643],
            [-64.2876, 58.8836],
            [-64.2878, 58.8969],
            [-64.3328, 58.9048],
            [-64.3505, 58.8921],
            [-64.3789, 58.904],
            [-64.4005, 58.9029],
            [-64.4061, 58.891],
            [-64.4208, 58.8905],
            [-64.4266, 58.9047],
            [-64.456, 58.9084],
            [-64.5091, 58.8878],
            [-64.5504, 58.8876],
            [-64.615, 58.9077],
            [-64.6519, 58.944],
            [-64.7309, 58.951],
            [-64.766, 58.924],
            [-64.821, 58.9171],
            [-64.8566, 58.9331],
            [-64.8797, 58.9521],
            [-64.8653, 59.0156],
            [-64.7795, 59.0752],
            [-64.6835, 59.0749],
            [-64.6705, 59.0388],
            [-64.596, 59.0352],
            [-64.523, 59.0028],
            [-64.498, 59.0196],
            [-64.4715, 59.0002],
            [-64.4815, 58.9885],
            [-64.4605, 58.9817],
            [-64.3879, 59.0064],
            [-64.262, 59.0131],
            [-64.3154, 59.0658],
            [-64.3611, 59.0675],
            [-64.4172, 59.0878],
            [-64.4507, 59.1031],
            [-64.4905, 59.0993],
            [-64.4983, 59.1066],
            [-64.4842, 59.1184],
            [-64.4915, 59.1366],
            [-64.473, 59.1806],
            [-64.5221, 59.197],
            [-64.4888, 59.2573],
            [-64.5438, 59.3107],
            [-64.4936, 59.3498],
            [-64.5386, 59.371],
            [-64.5119, 59.4232],
            [-64.4583, 59.4594],
            [-64.3872, 59.474],
            [-64.3499, 59.5032],
            [-64.3861, 59.524],
            [-64.434, 59.5422],
            [-64.4632, 59.5236],
            [-64.5034, 59.5216],
            [-64.5056, 59.5015],
            [-64.5899, 59.4664],
            [-64.627, 59.4822],
            [-64.6431, 59.4595],
            [-64.6801, 59.4471],
            [-64.7288, 59.4669],
            [-64.7644, 59.5151],
            [-64.8399, 59.5536],
            [-64.8986, 59.642],
            [-64.7497, 59.7175],
            [-64.8105, 59.73],
            [-64.7903, 59.7606],
            [-64.8255, 59.8259],
            [-64.7953, 59.8344],
            [-64.7669, 59.87],
            [-64.7565, 59.8863],
            [-64.7418, 59.8896],
            [-64.6841, 59.8775],
            [-64.6413, 59.9077],
            [-64.6697, 59.9304],
            [-64.6625, 59.9528],
            [-64.6934, 59.9592],
            [-64.7325, 59.9508],
            [-64.7477, 59.955],
            [-64.7474, 59.9748],
            [-64.7831, 59.9807],
            [-64.8071, 59.9711],
            [-64.8405, 59.9706],
            [-64.888, 60.0423],
            [-64.924, 60.0618],
            [-64.8962, 60.0665],
            [-64.8201, 60.0508],
            [-64.743, 60.0564],
            [-64.7083, 60.0849],
            [-64.594, 60.1173],
            [-64.6028, 60.1673],
            [-64.7502, 60.1885],
            [-64.8643, 60.2285],
            [-64.8737, 60.2376],
            [-64.842, 60.2725],
            [-64.7238, 60.2918],
            [-64.5815, 60.3087],
            [-64.5251, 60.3001],
            [-64.5066, 60.2897],
            [-64.486, 60.2948],
            [-64.3749, 60.2498],
            [-64.3716, 60.1989],
            [-64.4182, 60.1481],
            [-64.4886, 60.1204],
            [-64.5137, 60.0732],
            [-64.4408, 60.0655],
            [-64.4176, 60.1184],
            [-64.3657, 60.1303],
            [-64.3608, 60.0476],
            [-64.4143, 60.0018],
            [-64.3763, 59.9539],
            [-64.2687, 60.057],
            [-64.196, 60.0164],
            [-64.2265, 59.9468],
            [-64.1279, 59.904],
            [-64.1784, 59.8526],
            [-64.0335, 59.8504],
            [-64.0272, 59.7843],
            [-63.9349, 59.7558],
            [-63.9862, 59.7215],
            [-64.0449, 59.72],
            [-64.1338, 59.6945],
            [-64.2049, 59.7722],
            [-64.2612, 59.7564],
            [-64.1716, 59.6884],
            [-64.015, 59.6862],
            [-63.9105, 59.6624],
            [-63.9498, 59.6049],
            [-63.8832, 59.6139],
            [-63.8632, 59.569],
            [-63.7334, 59.5218],
            [-63.7742, 59.4324],
            [-63.8194, 59.3984],
            [-63.9337, 59.4131],
            [-64.0002, 59.396],
            [-63.7552, 59.3548],
            [-63.694, 59.3286],
            [-63.5418, 59.3394],
            [-63.5096, 59.3109],
            [-63.518, 59.2638],
            [-63.3904, 59.2786],
            [-63.4168, 59.2031],
            [-63.351, 59.1911],
            [-63.4489, 59.0994],
            [-63.5122, 59.0944],
            [-63.5844, 59.0578],
            [-63.6365, 59.0643],
            [-63.7536, 59.0448],
            [-63.7475, 59.0071],
            [-63.6527, 59.0373],
            [-63.5514, 59.0318],
            [-63.5045, 59.0594],
            [-63.3883, 59.0931],
            [-63.2551, 59.0744],
            [-63.1976, 59.0212],
            [-63.1578, 58.9482],
            [-63.2293, 58.8675],
            [-63.1854, 58.8461],
            [-63.0616, 58.9054],
            [-62.9926, 58.876],
            [-62.9697, 58.8251],
            [-62.9207, 58.8247],
            [-62.893, 58.7829],
            [-62.9634, 58.6952],
            [-62.8769, 58.7166],
            [-62.8459, 58.6642],
            [-62.9245, 58.6141],
            [-62.9902, 58.5556],
            [-63.1028, 58.5382],
            [-63.1151, 58.5133],
            [-63.2273, 58.4896],
            [-63.2878, 58.4939],
            [-63.3887, 58.4199],
            [-63.4127, 58.3622],
            [-63.2664, 58.46],
            [-63.1377, 58.4757],
            [-63.0307, 58.4459],
            [-63.0096, 58.4125],
            [-62.9357, 58.453],
            [-62.8021, 58.4957],
            [-62.5602, 58.4756],
            [-62.6561, 58.4014],
            [-62.6118, 58.3624],
            [-62.6248, 58.2952],
            [-62.7057, 58.2803],
            [-62.5798, 58.2247],
            [-62.6665, 58.161],
            [-62.7978, 58.1717],
            [-62.9339, 58.1522],
            [-63.0069, 58.1324],
            [-63.036, 58.1066],
            [-63.1451, 58.0852],
            [-63.0911, 58.0643],
            [-62.9948, 58.0801],
            [-62.9471, 58.1204],
            [-62.8313, 58.1337],
            [-62.7461, 58.1084],
            [-62.6984, 58.137],
            [-62.6268, 58.1344],
            [-62.5388, 58.1087],
            [-62.5349, 58.1746],
            [-62.4488, 58.1681],
            [-62.4602, 58.1377],
            [-62.5225, 58.1011],
            [-62.373, 58.102],
            [-62.3658, 58.0719],
            [-62.3108, 58.0406],
            [-62.3778, 57.9879],
            [-62.4984, 57.9932],
            [-62.5058, 57.9732],
            [-62.3919, 57.9436],
            [-62.2661, 57.9413],
            [-62.2269, 57.9208],
            [-62.1305, 57.9738],
            [-62.0861, 57.9289],
            [-62.0721, 57.8863],
            [-61.9622, 57.9199],
            [-61.8678, 57.8417],
            [-61.8899, 57.8013],
            [-61.9461, 57.7758],
            [-62.0572, 57.8334],
            [-62.0763, 57.7998],
            [-62.0221, 57.7877],
            [-61.9508, 57.7124],
            [-61.9436, 57.6633],
            [-61.8893, 57.6498],
            [-61.8985, 57.6091],
            [-62.0038, 57.6122],
            [-62.0821, 57.5778],
            [-62.1396, 57.5205],
            [-62.2262, 57.5426],
            [-62.2761, 57.5196],
            [-62.3582, 57.4449],
            [-62.3312, 57.4212],
            [-62.1899, 57.4655],
            [-62.0579, 57.4559],
            [-61.9549, 57.4264],
            [-61.8036, 57.3651],
            [-61.8918, 57.3108],
            [-61.8595, 57.2786],
            [-61.9479, 57.2428],
            [-61.8604, 57.1553],
            [-61.7837, 57.1941],
            [-61.7711, 57.1607],
            [-61.6515, 57.1398],
            [-61.4778, 57.1569],
            [-61.4125, 57.1404],
            [-61.3603, 57.0912],
            [-61.3834, 57.0462],
            [-61.3568, 57.0194],
            [-61.3781, 56.9752],
            [-61.4819, 56.9791],
            [-61.5621, 56.9467],
            [-61.5799, 56.901],
            [-61.653, 56.8748],
            [-61.6562, 56.805],
            [-61.722, 56.8055],
            [-61.7966, 56.7819],
            [-61.8896, 56.8036],
            [-61.8656, 56.7587],
            [-61.741, 56.7475],
            [-61.6995, 56.7196],
            [-61.7664, 56.6822],
            [-61.7409, 56.6573],
            [-61.6548, 56.6419],
            [-61.6858, 56.6108],
            [-61.7615, 56.6309],
            [-61.8702, 56.6254],
            [-61.9507, 56.6396],
            [-62.1499, 56.6999],
            [-62.1422, 56.6771],
            [-62.0462, 56.6224],
            [-62.1928, 56.6197],
            [-62.2334, 56.6035],
            [-62.0003, 56.5875],
            [-61.9406, 56.5732],
            [-61.8782, 56.5905],
            [-61.7644, 56.5575],
            [-61.7306, 56.5702],
            [-61.6553, 56.5375],
            [-61.6695, 56.5011],
            [-61.7858, 56.4802],
            [-61.9461, 56.5137],
            [-62.0771, 56.5036],
            [-62.0483, 56.4766],
            [-61.9668, 56.4834],
            [-61.9384, 56.4535],
            [-62.047, 56.4469],
            [-62.088, 56.4609],
            [-62.1356, 56.4331],
            [-61.9773, 56.4072],
            [-61.8232, 56.4022],
            [-61.7362, 56.3393],
            [-61.6588, 56.3175],
            [-61.6375, 56.2723],
            [-61.7058, 56.2483],
            [-61.7725, 56.2417],
            [-61.7783, 56.282],
            [-61.8513, 56.2785],
            [-61.9066, 56.2946],
            [-61.9468, 56.2713],
            [-62.0701, 56.2897],
            [-62.0111, 56.223],
            [-61.9206, 56.2074],
            [-61.6861, 56.2212],
            [-61.5378, 56.19],
            [-61.4914, 56.2073],
            [-61.3438, 56.232],
            [-61.3937, 56.1755],
            [-61.4415, 56.1471],
            [-61.4183, 56.0918],
            [-61.4505, 56.0421],
            [-61.3157, 56.0797],
            [-61.2627, 56.0472],
            [-61.2984, 56.0168],
            [-61.4096, 56.0057],
            [-61.359, 55.9684],
            [-61.1969, 55.9669],
            [-61.1334, 55.9788],
            [-61.0693, 55.9335],
            [-61.0608, 55.8862],
            [-61.1666, 55.8842],
            [-61.0937, 55.8392],
            [-60.8981, 55.8579],
            [-60.8658, 55.8458],
            [-60.7545, 55.8498],
            [-60.7142, 55.8355],
            [-60.7361, 55.7893],
            [-60.802, 55.7457],
            [-60.7171, 55.7391],
            [-60.6354, 55.8233],
            [-60.6107, 55.7908],
            [-60.5902, 55.7043],
            [-60.6501, 55.661],
            [-60.5818, 55.6177],
            [-60.5086, 55.7745],
            [-60.4256, 55.7647],
            [-60.3469, 55.7797],
            [-60.3071, 55.7454],
            [-60.3729, 55.6881],
            [-60.4695, 55.6625],
            [-60.3775, 55.6026],
            [-60.3546, 55.5575],
            [-60.2879, 55.567],
            [-60.3414, 55.5026],
            [-60.3332, 55.4885],
            [-60.438, 55.4436],
            [-60.2784, 55.456],
            [-60.2506, 55.505],
            [-60.1874, 55.4595],
            [-60.2121, 55.4262],
            [-60.2718, 55.4033],
            [-60.3324, 55.4174],
            [-60.3905, 55.2727],
            [-60.4588, 55.2671],
            [-60.47, 55.2292],
            [-60.3547, 55.2352],
            [-60.4437, 55.19],
            [-60.4266, 55.1612],
            [-60.3467, 55.1674],
            [-60.3095, 55.2102],
            [-60.2506, 55.247],
            [-60.2099, 55.2379],
            [-60.1413, 55.2774],
            [-60.126, 55.2279],
            [-60.0538, 55.248],
            [-60.0785, 55.1846],
            [-60.1406, 55.1285],
            [-60.2404, 55.0892],
            [-60.2497, 55.0388],
            [-60.0857, 55.1269],
            [-60.0538, 55.188],
            [-59.9544, 55.2356],
            [-59.9034, 55.2303],
            [-59.8351, 55.3056],
            [-59.7476, 55.3043],
            [-59.7029, 55.2554],
            [-59.7321, 55.1966],
            [-59.7739, 55.2033],
            [-59.8556, 55.1394],
            [-59.7839, 55.1347],
            [-59.7221, 55.148],
            [-59.6387, 55.1299],
            [-59.6225, 55.1903],
            [-59.4887, 55.1528],
            [-59.4785, 55.1078],
            [-59.5068, 55.0478],
            [-59.5593, 55.0389],
            [-59.6078, 55.0017],
            [-59.6827, 54.9707],
            [-59.7681, 54.8993],
            [-59.849, 54.865],
            [-59.8021, 54.8426],
            [-59.7689, 54.8868],
            [-59.6541, 54.9357],
            [-59.6029, 54.9458],
            [-59.5631, 54.9898],
            [-59.4969, 55.0142],
            [-59.4185, 55.0772],
            [-59.3715, 55.0628],
            [-59.2829, 55.1469],
            [-59.2584, 55.1951],
            [-59.189, 55.2185],
            [-59.1087, 55.2022],
            [-59.1441, 55.0985],
            [-59.0455, 55.1449],
            [-58.9396, 55.1289],
            [-58.9433, 55.0669],
            [-59.036, 54.997],
            [-58.9539, 54.9808],
            [-58.8842, 54.9359],
            [-58.8908, 54.8696],
            [-58.8723, 54.8254],
            [-58.8334, 54.8044],
            [-58.792, 54.8266],
            [-58.7204, 54.8274],
            [-58.6701, 54.7956],
            [-58.5895, 54.7851],
            [-58.5529, 54.7486],
            [-58.4978, 54.7669],
            [-58.4196, 54.7569],
            [-58.3276, 54.7865],
            [-58.2127, 54.7768],
            [-58.2056, 54.8756],
            [-58.1598, 54.8374],
            [-58.1263, 54.8702],
            [-57.9239, 54.9116],
            [-57.9275, 54.8731],
            [-57.902, 54.8293],
            [-57.8261, 54.8223],
            [-57.8807, 54.7765],
            [-57.9741, 54.7708],
            [-58.0029, 54.7471],
            [-58.1699, 54.7547],
            [-58.1786, 54.7373],
            [-57.9771, 54.7188],
            [-57.9301, 54.7442],
            [-57.8308, 54.7336],
            [-57.7685, 54.6763],
            [-57.7249, 54.6668],
            [-57.7063, 54.6331],
            [-57.6235, 54.6221],
            [-57.5249, 54.6581],
            [-57.4399, 54.6504],
            [-57.4451, 54.6231],
            [-57.3978, 54.5752],
            [-57.3211, 54.5754],
            [-57.3856, 54.5129],
            [-57.3915, 54.4874],
            [-57.5478, 54.4852],
            [-57.6844, 54.4756],
            [-57.6851, 54.4574],
            [-57.481, 54.4749],
            [-57.4293, 54.4355],
            [-57.5531, 54.4031],
            [-57.5858, 54.3782],
            [-57.6864, 54.364],
            [-57.769, 54.3767],
            [-57.8099, 54.3649],
            [-57.8592, 54.3955],
            [-57.9394, 54.3727],
            [-58.1641, 54.3577],
            [-58.2335, 54.327],
            [-58.1551, 54.2891],
            [-58.2559, 54.2767],
            [-58.2289, 54.2429],
            [-58.4242, 54.2348],
            [-58.4816, 54.2097],
            [-58.5672, 54.2007],
            [-58.6125, 54.1636],
            [-58.7821, 54.1276],
            [-58.833, 54.1416],
            [-59.017, 54.1068],
            [-59.2316, 54.0788],
            [-59.3886, 54.026],
            [-59.492, 54.0338],
            [-59.4568, 54.0001],
            [-59.3611, 53.9973],
            [-59.1095, 54.0542],
            [-58.9852, 54.0579],
            [-58.7925, 54.1035],
            [-58.6231, 54.1498],
            [-58.5464, 54.1845],
            [-58.4675, 54.1926],
            [-58.3988, 54.2266],
            [-58.3715, 54.1889],
            [-58.1679, 54.2304],
            [-58.1208, 54.2193],
            [-58.0442, 54.2316],
            [-57.8778, 54.2088],
            [-57.7926, 54.2139],
            [-57.7365, 54.1998],
            [-57.6167, 54.2028],
            [-57.5757, 54.178],
            [-57.4882, 54.1942],
            [-57.3982, 54.1763],
            [-57.3843, 54.1492],
          ],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "London Mkt": {
    type: "Feature",
    properties: { code: "ON_LON", name: "London Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-80.0011, 43.7056],
            [-80.0226, 43.6862],
            [-80.0705, 43.722],
            [-80.1231, 43.7079],
            [-80.1612, 43.7164],
            [-80.2125, 43.7522],
            [-80.191, 43.7715],
            [-80.2184, 43.7907],
            [-80.1398, 43.8616],
            [-79.9957, 43.7543],
            [-80.0279, 43.7254],
            [-80.0011, 43.7056],
          ],
        ],
        [
          [
            [-82.6362, 41.8199],
            [-82.6203, 41.7515],
            [-82.6889, 41.756],
            [-82.6858, 41.8156],
            [-82.6362, 41.8199],
          ],
        ],
        [
          [
            [-80.295, 42.5626],
            [-80.3839, 42.578],
            [-80.5421, 42.573],
            [-80.6142, 42.5799],
            [-80.7705, 42.6376],
            [-80.9083, 42.66],
            [-81.0083, 42.6527],
            [-81.1215, 42.6633],
            [-81.2471, 42.664],
            [-81.3369, 42.6464],
            [-81.3988, 42.6062],
            [-81.5409, 42.5598],
            [-81.6251, 42.4971],
            [-81.8044, 42.392],
            [-81.8406, 42.3471],
            [-81.841, 42.2784],
            [-81.8818, 42.2582],
            [-81.8479, 42.3212],
            [-81.9197, 42.2936],
            [-81.9373, 42.2628],
            [-82.0078, 42.2648],
            [-82.094, 42.2473],
            [-82.2219, 42.1873],
            [-82.3818, 42.1265],
            [-82.432, 42.0946],
            [-82.4828, 42.0391],
            [-82.5015, 41.9856],
            [-82.532, 41.9637],
            [-82.5736, 42.0102],
            [-82.6122, 42.032],
            [-82.7178, 42.0322],
            [-82.8124, 41.999],
            [-82.924, 41.9808],
            [-83.0131, 42.0229],
            [-83.1074, 42.0468],
            [-83.1173, 42.0688],
            [-83.1163, 42.1654],
            [-83.0976, 42.1891],
            [-83.1085, 42.2574],
            [-83.0724, 42.3101],
            [-82.9401, 42.343],
            [-82.7903, 42.3058],
            [-82.7264, 42.2966],
            [-82.6193, 42.3015],
            [-82.5548, 42.3157],
            [-82.4714, 42.3097],
            [-82.4242, 42.3498],
            [-82.4093, 42.3955],
            [-82.4195, 42.4973],
            [-82.522, 42.4884],
            [-82.6187, 42.5292],
            [-82.6774, 42.524],
            [-82.6412, 42.5548],
            [-82.624, 42.5569],
            [-82.6055, 42.5485],
            [-82.584, 42.5539],
            [-82.5186, 42.6141],
            [-82.5105, 42.6648],
            [-82.4674, 42.7625],
            [-82.482, 42.8082],
            [-82.4682, 42.8554],
            [-82.4703, 42.8875],
            [-82.4553, 42.9267],
            [-82.4167, 42.9669],
            [-82.424, 42.9981],
            [-82.3848, 43.0142],
            [-82.242, 43.0356],
            [-82.172, 43.0583],
            [-82.1496, 43.0927],
            [-82.0971, 43.1111],
            [-82.0194, 43.1738],
            [-82.0228, 43.2125],
            [-81.9723, 43.2118],
            [-81.8676, 43.251],
            [-81.7862, 43.298],
            [-81.727, 43.3556],
            [-81.7098, 43.3981],
            [-81.7185, 43.4463],
            [-81.7061, 43.531],
            [-81.7128, 43.6271],
            [-81.7315, 43.7466],
            [-81.7192, 43.7828],
            [-81.7279, 43.868],
            [-81.7214, 43.9304],
            [-81.7348, 44.0291],
            [-81.7589, 44.0737],
            [-81.6376, 44.1768],
            [-81.5915, 44.2881],
            [-81.6096, 44.3155],
            [-81.5333, 44.3665],
            [-81.4836, 44.4185],
            [-81.4058, 44.4359],
            [-81.3883, 44.4899],
            [-81.2967, 44.5784],
            [-81.2708, 44.6311],
            [-81.2763, 44.7564],
            [-81.3736, 44.9247],
            [-81.3645, 44.9867],
            [-81.4543, 45.0043],
            [-81.5107, 45.0947],
            [-81.5934, 45.1496],
            [-81.6213, 45.1944],
            [-81.7019, 45.1983],
            [-81.7295, 45.2213],
            [-81.6881, 45.2564],
            [-81.5868, 45.2524],
            [-81.4635, 45.2301],
            [-81.3875, 45.2421],
            [-81.2902, 45.2456],
            [-81.2842, 45.2256],
            [-81.3305, 45.1862],
            [-81.336, 45.1418],
            [-81.2806, 45.1175],
            [-81.2628, 45.0759],
            [-81.2774, 45.0225],
            [-81.2148, 44.9601],
            [-81.1206, 44.9608],
            [-81.1415, 44.9031],
            [-81.0567, 44.945],
            [-81.0356, 44.9666],
            [-80.9647, 44.9457],
            [-81.0209, 44.9141],
            [-81.0052, 44.8772],
            [-81.0814, 44.78],
            [-80.9908, 44.8099],
            [-80.9068, 44.7957],
            [-80.8881, 44.737],
            [-80.9464, 44.6145],
            [-80.8766, 44.6242],
            [-80.8239, 44.6888],
            [-80.7526, 44.7247],
            [-80.643, 44.7214],
            [-80.6106, 44.6232],
            [-80.5525, 44.5942],
            [-80.511, 44.5957],
            [-80.4041, 44.5406],
            [-80.3516, 44.5387],
            [-80.4145, 44.5392],
            [-80.4083, 44.5061],
            [-80.3244, 44.5185],
            [-80.3474, 44.4673],
            [-80.3399, 44.4343],
            [-80.2739, 44.4487],
            [-80.2614, 44.3946],
            [-80.2338, 44.35],
            [-80.2204, 44.2893],
            [-80.2611, 44.2807],
            [-80.2538, 44.2481],
            [-80.3003, 44.2205],
            [-80.2665, 44.1924],
            [-80.3095, 44.1546],
            [-80.2699, 44.1312],
            [-80.3014, 44.1038],
            [-80.2779, 44.0636],
            [-80.4384, 44.026],
            [-80.3986, 43.856],
            [-80.351, 43.8661],
            [-80.2844, 43.819],
            [-80.2401, 43.7713],
            [-80.2615, 43.7521],
            [-80.0856, 43.629],
            [-80.1066, 43.6102],
            [-80.0153, 43.5462],
            [-80.0029, 43.5542],
            [-79.9492, 43.516],
            [-79.9701, 43.4966],
            [-80.0791, 43.5736],
            [-80.129, 43.5903],
            [-80.1479, 43.5521],
            [-80.0343, 43.4711],
            [-80.0799, 43.4324],
            [-80.0701, 43.391],
            [-80.0909, 43.3509],
            [-80.079, 43.3164],
            [-80.1327, 43.289],
            [-80.2274, 43.2783],
            [-80.2005, 43.2107],
            [-80.1399, 43.2148],
            [-80.1345, 43.1865],
            [-79.7693, 43.0557],
            [-79.6847, 43.0465],
            [-79.6526, 43.0145],
            [-79.5832, 43.0017],
            [-79.5626, 42.9886],
            [-79.4814, 42.961],
            [-79.458, 42.8995],
            [-79.4525, 42.8746],
            [-79.4833, 42.8409],
            [-79.5445, 42.8404],
            [-79.5887, 42.8576],
            [-79.6392, 42.8357],
            [-79.704, 42.8612],
            [-79.8199, 42.8349],
            [-80.0504, 42.796],
            [-80.2278, 42.7793],
            [-80.3257, 42.694],
            [-80.3945, 42.6635],
            [-80.4547, 42.6136],
            [-80.4207, 42.5911],
            [-80.4198, 42.59],
            [-80.3466, 42.5767],
            [-80.295, 42.5626],
          ],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "Sudbury Mkt": {
    type: "Feature",
    properties: { code: "ON_SUD", name: "Sudbury Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-81.508, 46.0425],
            [-81.6323, 46.0926],
            [-81.6539, 46.085],
            [-81.7118, 46.0582],
            [-81.7465, 46.0151],
            [-81.7924, 46.0642],
            [-81.72, 46.1068],
            [-81.9714, 46.091],
            [-82.0755, 46.1131],
            [-82.1658, 46.1103],
            [-82.2238, 46.1441],
            [-82.3393, 46.1574],
            [-82.3829, 46.1818],
            [-82.4676, 46.2004],
            [-82.5252, 46.1688],
            [-82.6143, 46.1691],
            [-82.6413, 46.1573],
            [-82.7015, 46.1978],
            [-82.7937, 46.1914],
            [-82.8343, 46.1756],
            [-82.9154, 46.1838],
            [-83.0432, 46.1718],
            [-83.0957, 46.196],
            [-83.3158, 46.2143],
            [-83.4094, 46.2391],
            [-83.56, 46.254],
            [-83.6122, 46.2858],
            [-83.6778, 46.2685],
            [-83.8753, 46.303],
            [-83.9263, 46.2973],
            [-83.885, 46.2565],
            [-83.8103, 46.2182],
            [-83.787, 46.1832],
            [-83.8725, 46.1592],
            [-83.8915, 46.0834],
            [-83.9559, 46.0573],
            [-84.0083, 46.1504],
            [-84.0774, 46.187],
            [-84.1083, 46.2411],
            [-84.1194, 46.3149],
            [-84.1055, 46.3222],
            [-84.1386, 46.3714],
            [-84.1467, 46.4184],
            [-84.1113, 46.5041],
            [-84.1296, 46.5305],
            [-84.1795, 46.5275],
            [-84.2264, 46.5343],
            [-84.2548, 46.5008],
            [-84.2761, 46.4927],
            [-84.3602, 46.5085],
            [-84.3958, 46.4986],
            [-84.4228, 46.5008],
            [-84.4456, 46.4881],
            [-84.4764, 46.4531],
            [-84.5566, 46.4609],
            [-84.6595, 46.5474],
            [-84.598, 46.5696],
            [-84.4977, 46.6435],
            [-84.4912, 46.6612],
            [-84.4205, 46.6899],
            [-84.4354, 46.7412],
            [-84.5072, 46.7464],
            [-84.5205, 46.6979],
            [-84.5681, 46.6904],
            [-84.5777, 46.736],
            [-84.54, 46.8011],
            [-84.4868, 46.844],
            [-84.4494, 46.8457],
            [-84.4053, 46.816],
            [-84.3547, 46.8717],
            [-84.4023, 46.9142],
            [-84.4836, 46.9392],
            [-84.5174, 46.9201],
            [-84.552, 46.9442],
            [-84.6528, 46.9172],
            [-84.6661, 46.9618],
            [-84.7257, 46.9522],
            [-84.7793, 46.9745],
            [-84.7831, 47.0409],
            [-84.7119, 47.1019],
            [-84.7406, 47.1405],
            [-84.6945, 47.1661],
            [-84.6801, 47.2004],
            [-84.5903, 47.2824],
            [-84.624, 47.3424],
            [-84.7064, 47.3723],
            [-84.7093, 47.4095],
            [-84.793, 47.4737],
            [-84.826, 47.4772],
            [-84.9613, 47.5384],
            [-85.0033, 47.588],
            [-85.0224, 47.6357],
            [-84.9806, 47.6683],
            [-85.0123, 47.7069],
            [-84.9503, 47.7333],
            [-84.8971, 47.7847],
            [-84.9505, 47.8208],
            [-84.8864, 47.8855],
            [-84.8375, 47.9156],
            [-84.859, 47.9577],
            [-84.9511, 47.963],
            [-85.1961, 47.9601],
            [-85.3182, 47.9547],
            [-85.3832, 47.9235],
            [-85.5761, 47.9177],
            [-85.6829, 47.9236],
            [-85.7612, 47.9377],
            [-85.8752, 47.9828],
            [-85.9302, 48.0428],
            [-86.0181, 48.0787],
            [-86.0183, 48.0948],
            [-86.0847, 48.1593],
            [-86.1447, 48.2429],
            [-86.1522, 48.2925],
            [-86.1882, 48.3411],
            [-86.1827, 48.3932],
            [-86.229, 48.4113],
            [-86.2427, 48.5002],
            [-86.2892, 48.5895],
            [-86.3402, 48.6125],
            [-86.3042, 48.6514],
            [-86.3593, 48.661],
            [-86.3852, 48.7247],
            [-86.4286, 48.7739],
            [-86.4859, 48.774],
            [-86.5506, 48.7303],
            [-86.6369, 48.7298],
            [-86.6156, 48.7795],
            [-86.7264, 48.801],
            [-86.7954, 48.7808],
            [-86.8635, 48.7453],
            [-86.8958, 48.7727],
            [-87.0289, 48.7939],
            [-87.071, 48.7728],
            [-87.2046, 48.7561],
            [-87.3038, 48.7691],
            [-87.2895, 48.7928],
            [-87.3906, 48.8063],
            [-87.4178, 48.8361],
            [-87.4761, 48.8401],
            [-87.5479, 48.8246],
            [-87.5458, 48.878],
            [-87.6475, 48.8563],
            [-87.6741, 48.8975],
            [-87.7675, 48.9133],
            [-87.8357, 48.9085],
            [-87.8889, 48.9405],
            [-87.9292, 48.9288],
            [-88.0246, 48.9851],
            [-88.0426, 49.0172],
            [-88.1112, 49.0016],
            [-88.153, 48.976],
            [-88.2354, 48.9591],
            [-88.2246, 48.9103],
            [-88.2447, 48.874],
            [-88.2273, 48.8399],
            [-88.172, 48.8277],
            [-88.0905, 48.7751],
            [-88.0972, 48.6997],
            [-88.1627, 48.6308],
            [-88.1942, 48.6162],
            [-88.3316, 48.5889],
            [-88.3794, 48.5379],
            [-88.417, 48.5365],
            [-88.4977, 48.5009],
            [-88.5343, 48.4295],
            [-88.5955, 48.4805],
            [-88.5404, 48.535],
            [-88.4069, 48.6376],
            [-88.3398, 48.6725],
            [-88.3011, 48.7562],
            [-88.3424, 48.82],
            [-88.4373, 48.8472],
            [-88.5285, 48.7911],
            [-88.5514, 48.7407],
            [-88.5134, 48.7019],
            [-88.5548, 48.683],
            [-88.5346, 48.6448],
            [-88.6294, 48.607],
            [-88.6417, 48.5112],
            [-88.6732, 48.4868],
            [-88.7181, 48.4026],
            [-88.717, 48.3815],
            [-88.7711, 48.3473],
            [-88.8874, 48.3161],
            [-88.9015, 48.3624],
            [-88.873, 48.3754],
            [-88.8288, 48.4515],
            [-88.8378, 48.4616],
            [-88.7535, 48.5569],
            [-88.7718, 48.5748],
            [-88.9089, 48.5421],
            [-89.0585, 48.4898],
            [-89.1414, 48.4814],
            [-89.2109, 48.4396],
            [-89.202, 48.3316],
            [-89.2727, 48.2374],
            [-89.2833, 48.1754],
            [-89.3206, 48.1512],
            [-89.4027, 48.0697],
            [-89.4809, 48.0542],
            [-89.5795, 48.0086],
            [-89.6107, 48.0178],
            [-89.6512, 48.0036],
            [-89.6738, 48.0117],
            [-89.7164, 48.0096],
            [-89.7243, 48.0207],
            [-89.7757, 48.0226],
            [-89.8207, 48.0152],
            [-89.8712, 47.986],
            [-89.9224, 47.9968],
            [-89.9941, 48.0281],
            [-89.9981, 48.0578],
            [-90.0233, 48.0846],
            [-90.1359, 48.1126],
            [-90.2917, 48.099],
            [-90.3147, 48.1058],
            [-90.3736, 48.0918],
            [-90.4367, 48.0994],
            [-90.4665, 48.1095],
            [-90.4881, 48.0995],
            [-90.5597, 48.097],
            [-90.5696, 48.1073],
            [-90.5557, 48.1173],
            [-90.582, 48.1241],
            [-90.6417, 48.1034],
            [-90.7507, 48.0916],
            [-90.836, 48.1772],
            [-90.8414, 48.2424],
            [-90.8757, 48.2383],
            [-90.8862, 48.2465],
            [-90.9175, 48.2317],
            [-90.9774, 48.2198],
            [-91.0241, 48.191],
            [-91.0822, 48.1815],
            [-91.1428, 48.1553],
            [-91.1788, 48.125],
            [-91.1902, 48.1258],
            [-91.2513, 48.0835],
            [-91.2916, 48.0744],
            [-91.3715, 48.0704],
            [-91.3903, 48.0578],
            [-91.4324, 48.0494],
            [-91.4466, 48.0684],
            [-91.4892, 48.069],
            [-91.568, 48.0439],
            [-91.5757, 48.0681],
            [-91.5576, 48.1082],
            [-91.6409, 48.0974],
            [-91.6672, 48.1079],
            [-91.6867, 48.1121],
            [-91.6826, 48.1226],
            [-91.7127, 48.1149],
            [-91.6982, 48.1447],
            [-91.708, 48.1534],
            [-91.7063, 48.1717],
            [-91.7251, 48.1712],
            [-91.7151, 48.1998],
            [-91.7425, 48.2046],
            [-91.749, 48.1989],
            [-91.7562, 48.2062],
            [-91.7901, 48.1966],
            [-91.8159, 48.2124],
            [-91.8646, 48.2075],
            [-91.8938, 48.2387],
            [-92.0066, 48.2654],
            [-92.0006, 48.3211],
            [-92.0553, 48.359],
            [-92.2145, 48.3453],
            [-92.2623, 48.3545],
            [-92.2886, 48.3423],
            [-92.306, 48.3171],
            [-92.2949, 48.3061],
            [-92.2939, 48.2702],
            [-92.2697, 48.2476],
            [-92.3383, 48.2348],
            [-92.3702, 48.2211],
            [-92.4164, 48.2964],
            [-92.4376, 48.3089],
            [-92.4714, 48.3553],
            [-92.4769, 48.3703],
            [-92.4566, 48.4139],
            [-92.4744, 48.4181],
            [-92.5077, 48.4479],
            [-92.6553, 48.436],
            [-92.7128, 48.4627],
            [-92.699, 48.4948],
            [-92.6278, 48.5018],
            [-92.6356, 48.5425],
            [-92.73, 48.539],
            [-92.9315, 48.6072],
            [-92.9557, 48.6311],
            [-92.9833, 48.6238],
            [-93.1789, 48.6231],
            [-93.2061, 48.6423],
            [-93.2597, 48.6419],
            [-93.3476, 48.6264],
            [-93.3537, 48.6126],
            [-93.3707, 48.6059],
            [-93.3968, 48.6033],
            [-93.4061, 48.6103],
            [-93.4395, 48.5937],
            [-93.4653, 48.592],
            [-93.4628, 48.5497],
            [-93.5159, 48.5346],
            [-93.594, 48.5299],
            [-93.6072, 48.5227],
            [-93.629, 48.5313],
            [-93.659, 48.5163],
            [-93.7945, 48.5161],
            [-93.8044, 48.5667],
            [-93.8109, 48.5878],
            [-93.8346, 48.6257],
            [-93.8505, 48.6311],
            [-94.2474, 48.6531],
            [-94.2634, 48.696],
            [-94.3084, 48.7092],
            [-94.35, 48.7027],
            [-94.416, 48.71],
            [-94.451, 48.692],
            [-94.5343, 48.7032],
            [-94.5878, 48.7195],
            [-94.6875, 48.7786],
            [-94.695, 48.8271],
            [-94.6366, 48.8807],
            [-94.5802, 48.954],
            [-94.5042, 48.9539],
            [-94.3625, 49.0396],
            [-94.3428, 49.0766],
            [-94.3033, 49.0705],
            [-94.3399, 49.1143],
            [-94.2848, 49.1345],
            [-94.2322, 49.1208],
            [-94.1762, 49.0857],
            [-94.1118, 49.1083],
            [-94.0736, 49.1508],
            [-94.0177, 49.1377],
            [-94.0482, 49.1613],
            [-93.998, 49.1878],
            [-94.2134, 49.1752],
            [-94.2608, 49.1992],
            [-94.2908, 49.2592],
            [-94.326, 49.2882],
            [-94.3983, 49.273],
            [-94.4482, 49.2318],
            [-94.5345, 49.25],
            [-94.5369, 49.28],
            [-94.6108, 49.3106],
            [-94.5997, 49.3402],
            [-94.6648, 49.3764],
            [-94.7242, 49.4556],
            [-94.6986, 49.4966],
            [-94.6122, 49.4589],
            [-94.4639, 49.4771],
            [-94.3321, 49.4864],
            [-94.2757, 49.4444],
            [-94.3031, 49.384],
            [-94.2163, 49.422],
            [-94.203, 49.4012],
            [-94.2262, 49.3199],
            [-94.1675, 49.3157],
            [-94.0812, 49.2833],
            [-94.0592, 49.2562],
            [-94.1437, 49.2173],
            [-94.1026, 49.205],
            [-94.0504, 49.2411],
            [-94.0155, 49.3694],
            [-94.1116, 49.3974],
            [-94.1626, 49.4261],
            [-94.2484, 49.5086],
            [-94.2981, 49.5084],
            [-94.3289, 49.5411],
            [-94.3859, 49.5689],
            [-94.3974, 49.609],
            [-94.314, 49.6478],
            [-94.3092, 49.6792],
            [-94.362, 49.6914],
            [-94.3607, 49.7286],
            [-94.4339, 49.7152],
            [-94.5126, 49.7684],
            [-94.5754, 49.7483],
            [-94.5378, 49.7052],
            [-94.5894, 49.6555],
            [-94.6808, 49.6539],
            [-94.6363, 49.6902],
            [-94.5724, 49.699],
            [-94.677, 49.7116],
            [-94.821, 49.7091],
            [-94.8506, 49.659],
            [-94.8094, 49.6489],
            [-94.8148, 49.6165],
            [-94.854, 49.5831],
            [-94.9416, 49.557],
            [-94.9991, 49.5843],
            [-94.9858, 49.6347],
            [-95.1197, 49.6201],
            [-95.1011, 49.6457],
            [-95.1344, 49.6527],
            [-95.1401, 49.6449],
            [-95.1539, 49.6453],
            [-95.154, 50.8597],
            [-95.1536, 52.8406],
            [-93.6302, 53.7502],
            [-92.1889, 54.775],
            [-91.1384, 55.5002],
            [-88.9866, 56.8471],
            [-88.926, 56.8444],
            [-88.8905, 56.8591],
            [-88.8253, 56.8396],
            [-88.8308, 56.7989],
            [-88.6963, 56.7371],
            [-88.6605, 56.6988],
            [-88.4877, 56.6467],
            [-88.4394, 56.6079],
            [-88.3656, 56.5731],
            [-88.2199, 56.5219],
            [-88.0817, 56.4981],
            [-87.9727, 56.4625],
            [-87.9252, 56.3896],
            [-87.8778, 56.364],
            [-87.7782, 56.2686],
            [-87.7254, 56.2312],
            [-87.6942, 56.1734],
            [-87.6298, 56.096],
            [-87.4781, 56.0412],
            [-87.5, 56.0172],
            [-87.3865, 55.9896],
            [-87.2991, 55.9518],
            [-87.207, 55.9341],
            [-86.9081, 55.9161],
            [-86.6862, 55.8418],
            [-86.606, 55.8298],
            [-86.372, 55.7647],
            [-86.26, 55.7072],
            [-85.9028, 55.6555],
            [-85.86, 55.6265],
            [-85.7787, 55.5982],
            [-85.7284, 55.5967],
            [-85.6525, 55.5703],
            [-85.58, 55.5042],
            [-85.3993, 55.4054],
            [-85.3321, 55.3825],
            [-85.2292, 55.375],
            [-85.2067, 55.3459],
            [-85.1261, 55.3336],
            [-85.1091, 55.2605],
            [-84.9766, 55.2797],
            [-84.8135, 55.2523],
            [-84.7876, 55.2364],
            [-84.4921, 55.24],
            [-84.4409, 55.2588],
            [-84.2328, 55.2683],
            [-84.1977, 55.2814],
            [-84.0827, 55.2511],
            [-83.9587, 55.2908],
            [-83.8865, 55.295],
            [-83.7489, 55.2527],
            [-83.6512, 55.2648],
            [-83.4656, 55.2133],
            [-83.3387, 55.1986],
            [-83.2239, 55.2171],
            [-83.1423, 55.1979],
            [-82.9589, 55.222],
            [-82.9139, 55.2011],
            [-82.8848, 55.1467],
            [-82.8033, 55.1283],
            [-82.6334, 55.1592],
            [-82.5716, 55.139],
            [-82.517, 55.0905],
            [-82.4533, 55.1163],
            [-82.4045, 55.07],
            [-82.3538, 55.0677],
            [-82.3113, 55.1206],
            [-82.2499, 55.1052],
            [-82.2425, 55.0622],
            [-82.2752, 55.0391],
            [-82.2537, 54.9483],
            [-82.2672, 54.9129],
            [-82.1978, 54.8038],
            [-82.2884, 54.6554],
            [-82.3173, 54.5069],
            [-82.4023, 54.3543],
            [-82.4261, 54.3424],
            [-82.4366, 54.2547],
            [-82.419, 54.195],
            [-82.3493, 54.121],
            [-82.3062, 54.0961],
            [-82.2329, 54.0277],
            [-82.1263, 53.8198],
            [-82.1298, 53.7694],
            [-82.19, 53.6828],
            [-82.2156, 53.5876],
            [-82.1999, 53.5179],
            [-82.1401, 53.3877],
            [-82.1125, 53.274],
            [-82.2597, 53.1714],
            [-82.2929, 53.0784],
            [-82.2916, 52.971],
            [-82.2493, 52.9256],
            [-82.1115, 52.8814],
            [-81.9578, 52.7767],
            [-81.9249, 52.7019],
            [-81.8838, 52.6648],
            [-81.7689, 52.6095],
            [-81.6367, 52.4891],
            [-81.5457, 52.4432],
            [-81.5612, 52.3711],
            [-81.5199, 52.3259],
            [-81.5138, 52.2835],
            [-81.4845, 52.2616],
            [-81.4226, 52.1453],
            [-81.3495, 52.0981],
            [-81.1101, 52.0374],
            [-80.989, 52.0114],
            [-80.9118, 51.9202],
            [-80.8503, 51.9057],
            [-80.8237, 51.8803],
            [-80.7098, 51.8136],
            [-80.6553, 51.7918],
            [-80.5657, 51.7017],
            [-80.5701, 51.6655],
            [-80.5318, 51.6055],
            [-80.4959, 51.5218],
            [-80.4317, 51.4643],
            [-80.4349, 51.3752],
            [-80.3925, 51.3437],
            [-80.2924, 51.3085],
            [-80.1163, 51.2919],
            [-80.0599, 51.2587],
            [-79.9901, 51.2439],
            [-79.8876, 51.1678],
            [-79.8008, 51.1569],
            [-79.747, 51.1891],
            [-79.7068, 51.2458],
            [-79.6939, 51.2918],
            [-79.7444, 51.3295],
            [-79.7417, 51.3513],
            [-79.6733, 51.3951],
            [-79.5785, 51.4405],
            [-79.5739, 51.4659],
            [-79.5404, 51.457],
            [-79.5155, 51.4608],
            [-79.5177, 47.5582],
            [-79.5531, 47.5111],
            [-79.5606, 47.4776],
            [-79.5845, 47.4505],
            [-79.5867, 47.433],
            [-79.5483, 47.3756],
            [-79.4267, 47.25],
            [-79.442, 47.1384],
            [-79.4348, 47.0841],
            [-79.3466, 47.0077],
            [-79.3295, 46.9571],
            [-79.2137, 46.8332],
            [-79.1706, 46.8251],
            [-79.0941, 46.6882],
            [-79.0151, 46.6267],
            [-78.9897, 46.5487],
            [-78.8925, 46.4618],
            [-78.7269, 46.3827],
            [-78.728, 46.3422],
            [-78.6971, 46.3196],
            [-78.5968, 46.3187],
            [-78.5024, 46.2944],
            [-78.4033, 46.2926],
            [-78.3967, 46.2734],
            [-78.4122, 46.2731],
            [-78.328, 46.1213],
            [-78.5127, 46.0715],
            [-78.5888, 46.2119],
            [-78.7608, 46.1649],
            [-78.7676, 46.1777],
            [-78.7755, 46.1721],
            [-78.7191, 46.138],
            [-78.6838, 46.1017],
            [-78.6687, 46.0695],
            [-78.6918, 46.0587],
            [-78.6995, 46.0223],
            [-78.7521, 46.0094],
            [-78.8152, 46.0127],
            [-78.8388, 46.0021],
            [-78.8629, 46.0072],
            [-78.8743, 46.0343],
            [-78.9199, 46.0473],
            [-78.9544, 46.0815],
            [-78.9433, 46.1225],
            [-78.9151, 46.165],
            [-78.9173, 46.1848],
            [-78.9057, 46.2061],
            [-78.9769, 46.1874],
            [-79.108, 46.113],
            [-79.13, 46.108],
            [-79.0935, 46.0397],
            [-79.2578, 45.9942],
            [-79.227, 45.9247],
            [-79.2468, 45.8691],
            [-79.2194, 45.8644],
            [-79.1952, 45.88],
            [-79.1854, 45.8527],
            [-79.1873, 45.8624],
            [-79.167, 45.8739],
            [-79.1313, 45.8793],
            [-79.12, 45.8667],
            [-79.1397, 45.8518],
            [-79.179, 45.841],
            [-79.1728, 45.8296],
            [-79.145, 45.8276],
            [-79.1451, 45.8072],
            [-79.1558, 45.7982],
            [-79.1302, 45.7512],
            [-79.0628, 45.7692],
            [-79.0197, 45.7009],
            [-78.993, 45.6967],
            [-78.9542, 45.7099],
            [-78.9092, 45.7095],
            [-78.9092, 45.6947],
            [-78.9442, 45.6727],
            [-79.008, 45.6794],
            [-78.8554, 45.4153],
            [-78.8639, 45.4044],
            [-78.8418, 45.4133],
            [-78.8246, 45.4046],
            [-78.7768, 45.4366],
            [-78.4219, 45.5319],
            [-78.4252, 45.495],
            [-78.4551, 45.4547],
            [-78.428, 45.3927],
            [-78.7403, 45.3106],
            [-78.6879, 45.211],
            [-78.7417, 45.2357],
            [-78.7881, 45.2011],
            [-78.805, 45.202],
            [-78.8236, 45.1881],
            [-78.8228, 45.172],
            [-78.854, 45.1797],
            [-78.8423, 45.1448],
            [-79.0008, 45.1051],
            [-79.0168, 45.1361],
            [-79.0251, 45.1341],
            [-79.0356, 45.11],
            [-79.0233, 45.0906],
            [-79.034, 45.0767],
            [-78.9895, 45.0645],
            [-78.989, 45.0562],
            [-78.9763, 45.0616],
            [-78.9342, 44.9854],
            [-79.0934, 44.9428],
            [-79.1186, 44.9499],
            [-79.09, 44.893],
            [-79.0934, 44.8794],
            [-79.1051, 44.8663],
            [-79.1294, 44.8596],
            [-79.1282, 44.8416],
            [-79.1526, 44.8363],
            [-79.1583, 44.8141],
            [-79.2433, 44.7864],
            [-79.3501, 44.7723],
            [-79.3928, 44.7751],
            [-79.3987, 44.7788],
            [-79.4068, 44.7941],
            [-79.4119, 44.8155],
            [-79.5347, 44.7665],
            [-79.5225, 44.8035],
            [-79.5466, 44.8371],
            [-79.5076, 44.9388],
            [-79.5644, 44.9651],
            [-79.5705, 44.9909],
            [-79.6158, 45.0109],
            [-79.6808, 44.9979],
            [-79.6715, 44.9804],
            [-79.7231, 44.9665],
            [-79.7292, 44.984],
            [-79.7494, 44.9976],
            [-79.8296, 44.9851],
            [-79.8606, 45.0215],
            [-79.8882, 45.0143],
            [-79.8274, 44.9697],
            [-79.8644, 44.9658],
            [-79.8704, 44.9576],
            [-79.8768, 44.9527],
            [-79.8891, 44.9688],
            [-79.9025, 44.9701],
            [-79.8808, 44.949],
            [-79.9361, 44.9589],
            [-79.9269, 44.9979],
            [-79.9629, 45.0132],
            [-80.0001, 45.0579],
            [-80.0999, 45.0856],
            [-80.0153, 45.1254],
            [-80.0241, 45.183],
            [-80.0937, 45.2024],
            [-80.1917, 45.2451],
            [-80.2157, 45.3128],
            [-80.1685, 45.3363],
            [-80.2447, 45.3615],
            [-80.2989, 45.3554],
            [-80.3174, 45.4174],
            [-80.3859, 45.4388],
            [-80.4007, 45.4876],
            [-80.3782, 45.5461],
            [-80.3943, 45.5917],
            [-80.4929, 45.5585],
            [-80.5244, 45.5961],
            [-80.5104, 45.6184],
            [-80.5733, 45.6345],
            [-80.5927, 45.7001],
            [-80.6266, 45.7406],
            [-80.6537, 45.8096],
            [-80.7137, 45.816],
            [-80.7361, 45.8596],
            [-80.7312, 45.9122],
            [-80.7779, 45.9278],
            [-80.8055, 45.9104],
            [-80.9078, 45.944],
            [-80.9266, 45.9252],
            [-81.0532, 45.9516],
            [-81.0894, 45.9369],
            [-81.1853, 45.9442],
            [-81.1442, 45.9836],
            [-81.154, 46.0013],
            [-81.2335, 45.9916],
            [-81.4214, 45.9943],
            [-81.4872, 45.9665],
            [-81.5486, 46.0001],
            [-81.508, 46.0425],
          ],
          [
            [-94.5277, 49.7694],
            [-94.5345, 49.8233],
            [-94.5781, 49.8638],
            [-94.5428, 49.8875],
            [-94.5281, 49.9306],
            [-94.5891, 49.945],
            [-94.6233, 49.9753],
            [-94.6691, 49.9853],
            [-94.6826, 49.9124],
            [-94.6365, 49.9068],
            [-94.639, 49.8701],
            [-94.5712, 49.8266],
            [-94.5536, 49.7774],
            [-94.6009, 49.7604],
            [-94.5277, 49.7694],
          ],
        ],
        [
          [
            [-94.638, 49.9684],
            [-94.5867, 49.9287],
            [-94.553, 49.9251],
            [-94.5761, 49.8721],
            [-94.6487, 49.9151],
            [-94.638, 49.9684],
          ],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "Winnipeg Mkt": {
    type: "Feature",
    properties: { code: "MB_WIN", name: "Winnipeg Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-92.8063, 58.0406],
          [-92.8005, 57.9756],
          [-92.7284, 57.7997],
          [-92.628, 57.6617],
          [-92.564, 57.5925],
          [-92.5453, 57.5406],
          [-92.5025, 57.5252],
          [-92.4548, 57.4433],
          [-92.4255, 57.3614],
          [-92.4462, 57.242],
          [-92.5454, 57.0996],
          [-92.5943, 57.0511],
          [-92.7165, 56.9578],
          [-92.676, 56.9404],
          [-92.6113, 56.9883],
          [-92.4432, 57.0473],
          [-92.217, 57.0728],
          [-92.1231, 57.0496],
          [-91.9661, 57.0543],
          [-91.7268, 57.1196],
          [-91.1632, 57.2476],
          [-91.0159, 57.2621],
          [-90.8848, 57.2665],
          [-90.7055, 57.2287],
          [-90.6433, 57.2404],
          [-90.5584, 57.2124],
          [-90.3889, 57.1796],
          [-90.3864, 57.1576],
          [-90.3161, 57.1431],
          [-90.1692, 57.0774],
          [-89.9819, 57.0238],
          [-89.9631, 57.0077],
          [-89.5, 56.9301],
          [-89.3853, 56.9285],
          [-89.1931, 56.8741],
          [-88.9863, 56.8471],
          [-91.4399, 55.2992],
          [-92.1887, 54.7749],
          [-93.6299, 53.7501],
          [-95.1531, 52.8406],
          [-95.1534, 49.6453],
          [-95.1698, 49.644],
          [-95.2068, 49.6126],
          [-95.1539, 49.5884],
          [-95.1527, 49.4513],
          [-95.1531, 49.1746],
          [-95.1918, 49.1775],
          [-95.2518, 49.1599],
          [-95.2917, 49.1043],
          [-95.2317, 49],
          [-96.7846, 49],
          [-98.3891, 49],
          [-99.9491, 48.9999],
          [-101.3626, 49.0011],
          [-101.3627, 49.1781],
          [-101.3929, 49.1782],
          [-101.3929, 49.5323],
          [-101.4188, 49.5326],
          [-101.4194, 49.887],
          [-101.4483, 49.8871],
          [-101.4461, 50.2402],
          [-101.4776, 50.2401],
          [-101.4754, 50.5941],
          [-101.5094, 50.5932],
          [-101.5087, 50.9481],
          [-101.5382, 50.9481],
          [-101.5393, 51.3016],
          [-101.5706, 51.3018],
          [-101.5777, 51.9685],
          [-101.6096, 51.9684],
          [-101.6081, 52.3182],
          [-101.6379, 52.3182],
          [-101.6369, 52.6672],
          [-101.6708, 52.667],
          [-101.6721, 53.0113],
          [-101.7008, 53.0115],
          [-101.7023, 53.3643],
          [-101.7368, 53.3645],
          [-101.741, 53.7103],
          [-101.7704, 53.7101],
          [-101.7683, 54.0644],
          [-101.8113, 54.0642],
          [-101.8113, 54.4141],
          [-101.8504, 54.415],
          [-101.8435, 54.7431],
          [-101.8837, 54.762],
          [-101.8799, 55.1091],
          [-101.9203, 55.1117],
          [-101.9146, 55.4619],
          [-101.9555, 55.4633],
          [-101.951, 55.8141],
          [-102.0001, 55.8128],
          [-102.0002, 60.0002],
          [-100.2501, 60.0001],
          [-98.5001, 60.0001],
          [-96.75, 60.0001],
          [-94.8228, 60.0001],
          [-94.8513, 59.9631],
          [-94.8107, 59.9065],
          [-94.8126, 59.8232],
          [-94.773, 59.7873],
          [-94.8174, 59.7241],
          [-94.8378, 59.6143],
          [-94.7881, 59.5601],
          [-94.8112, 59.5437],
          [-94.7335, 59.4408],
          [-94.7203, 59.3527],
          [-94.7725, 59.3067],
          [-94.7774, 59.1655],
          [-94.8116, 59.1125],
          [-94.7859, 59.0212],
          [-94.6911, 58.9882],
          [-94.6766, 58.9612],
          [-94.5963, 58.9425],
          [-94.6024, 58.8867],
          [-94.5783, 58.8463],
          [-94.4734, 58.7928],
          [-94.4729, 58.7487],
          [-94.4303, 58.7071],
          [-94.3359, 58.7145],
          [-94.2629, 58.7914],
          [-94.1733, 58.7752],
          [-94.0216, 58.7645],
          [-93.7767, 58.7742],
          [-93.7439, 58.788],
          [-93.6574, 58.7659],
          [-93.4861, 58.7544],
          [-93.4322, 58.6986],
          [-93.3449, 58.7479],
          [-93.2069, 58.7647],
          [-93.1408, 58.6785],
          [-93.1607, 58.6366],
          [-93.1294, 58.6034],
          [-93.1354, 58.5344],
          [-93.1091, 58.4764],
          [-93.0528, 58.4147],
          [-93.03, 58.3479],
          [-92.9749, 58.3154],
          [-92.9745, 58.2825],
          [-92.8803, 58.1553],
          [-92.8063, 58.0406],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "Regina Mkt": {
    type: "Feature",
    properties: { code: "SK_REG", name: "Regina Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-110.0051, 49],
          [-110.0067, 53.6245],
          [-110.0001, 60.0001],
          [-108, 60.0001],
          [-106, 60.0001],
          [-103.9999, 60.0001],
          [-101.9999, 60.0002],
          [-102, 55.8128],
          [-101.9508, 55.8141],
          [-101.9553, 55.4633],
          [-101.9144, 55.4619],
          [-101.9201, 55.1117],
          [-101.8797, 55.1091],
          [-101.8835, 54.762],
          [-101.8433, 54.7431],
          [-101.8503, 54.415],
          [-101.8111, 54.4141],
          [-101.8112, 54.0642],
          [-101.7682, 54.0644],
          [-101.7703, 53.7102],
          [-101.7409, 53.7103],
          [-101.7366, 53.3645],
          [-101.7022, 53.3643],
          [-101.7006, 53.0115],
          [-101.6719, 53.0113],
          [-101.671, 53],
          [-101.6706, 52.667],
          [-101.6367, 52.6672],
          [-101.6377, 52.3182],
          [-101.6079, 52.3182],
          [-101.6094, 51.9684],
          [-101.5775, 51.9685],
          [-101.5704, 51.3018],
          [-101.5391, 51.3017],
          [-101.538, 50.9481],
          [-101.5085, 50.9481],
          [-101.5092, 50.5932],
          [-101.4752, 50.5941],
          [-101.4773, 50.2401],
          [-101.4459, 50.2402],
          [-101.4481, 49.8871],
          [-101.4192, 49.887],
          [-101.4185, 49.5326],
          [-101.3927, 49.5323],
          [-101.3926, 49.1782],
          [-101.3625, 49.1781],
          [-101.3624, 48.9999],
          [-102.4998, 48.9999],
          [-103.531, 49],
          [-104.5, 48.9999],
          [-105.7248, 49],
          [-106.7789, 49],
          [-107.7613, 49],
          [-109, 48.9999],
          [-110.0051, 49],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "Calgary Mkt": {
    type: "Feature",
    properties: { code: "AB_CAL", name: "Calgary Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-119.9005, 53.5329],
          [-119.8612, 53.5565],
          [-119.9306, 53.6104],
          [-119.9261, 53.6145],
          [-119.894, 53.6195],
          [-119.7594, 53.5909],
          [-119.7136, 53.6145],
          [-119.793, 53.706],
          [-119.9035, 53.7098],
          [-119.8876, 53.75],
          [-119.8884, 53.7795],
          [-119.9425, 53.7758],
          [-119.9832, 53.7971],
          [-119.9776, 53.8053],
          [-120.0002, 53.8062],
          [-120.0002, 56.8042],
          [-120.0004, 60.0003],
          [-118.7503, 60.0002],
          [-117.5002, 60.0002],
          [-116.2501, 60.0002],
          [-115, 60.0002],
          [-113.75, 60.0002],
          [-112.4999, 60.0002],
          [-111.2499, 60.0002],
          [-109.9998, 60.0002],
          [-110, 55.3312],
          [-110.0049, 48.9999],
          [-110.9999, 48.9998],
          [-111.9992, 48.9999],
          [-113.0105, 48.9999],
          [-114.0693, 49],
          [-114.0542, 49.0266],
          [-114.0639, 49.0453],
          [-114.0813, 49.0597],
          [-114.0968, 49.0598],
          [-114.1298, 49.0781],
          [-114.1533, 49.0995],
          [-114.1661, 49.1355],
          [-114.1491, 49.1436],
          [-114.1613, 49.1547],
          [-114.2127, 49.1733],
          [-114.2215, 49.189],
          [-114.2571, 49.1769],
          [-114.2873, 49.1779],
          [-114.3184, 49.1999],
          [-114.3456, 49.1935],
          [-114.399, 49.2103],
          [-114.4027, 49.2416],
          [-114.3814, 49.2507],
          [-114.4479, 49.2643],
          [-114.454, 49.298],
          [-114.4921, 49.3137],
          [-114.4922, 49.3221],
          [-114.4789, 49.3267],
          [-114.4876, 49.3472],
          [-114.5247, 49.3573],
          [-114.5263, 49.375],
          [-114.5669, 49.3768],
          [-114.5906, 49.396],
          [-114.6051, 49.4388],
          [-114.5965, 49.5018],
          [-114.5727, 49.5246],
          [-114.573, 49.5569],
          [-114.5883, 49.5653],
          [-114.6196, 49.5463],
          [-114.648, 49.5429],
          [-114.6833, 49.5565],
          [-114.6958, 49.5465],
          [-114.7326, 49.5765],
          [-114.7474, 49.6193],
          [-114.6978, 49.631],
          [-114.657, 49.6608],
          [-114.6766, 49.696],
          [-114.6337, 49.7303],
          [-114.6591, 49.765],
          [-114.6361, 49.7847],
          [-114.6399, 49.8276],
          [-114.6918, 49.8962],
          [-114.6949, 49.9418],
          [-114.6559, 49.968],
          [-114.6577, 50.0296],
          [-114.736, 50.1203],
          [-114.7247, 50.1901],
          [-114.7702, 50.2499],
          [-114.7528, 50.2938],
          [-114.8, 50.3297],
          [-114.7662, 50.3507],
          [-114.8598, 50.4237],
          [-114.9122, 50.4513],
          [-115.0112, 50.5673],
          [-115.0151, 50.5823],
          [-115.0521, 50.5714],
          [-115.0853, 50.5897],
          [-115.1175, 50.5697],
          [-115.1546, 50.5732],
          [-115.1899, 50.5551],
          [-115.1862, 50.5397],
          [-115.2081, 50.5278],
          [-115.231, 50.5448],
          [-115.2397, 50.5891],
          [-115.2969, 50.6262],
          [-115.2742, 50.6489],
          [-115.3123, 50.7239],
          [-115.3546, 50.7227],
          [-115.379, 50.7053],
          [-115.4138, 50.7112],
          [-115.4124, 50.7336],
          [-115.4363, 50.7557],
          [-115.4812, 50.7551],
          [-115.5605, 50.8047],
          [-115.5608, 50.8265],
          [-115.5731, 50.8377],
          [-115.6066, 50.8456],
          [-115.6236, 50.834],
          [-115.6394, 50.8379],
          [-115.6489, 50.8741],
          [-115.5927, 50.8926],
          [-115.6283, 50.9808],
          [-115.7671, 51.0393],
          [-115.7736, 51.0577],
          [-115.7534, 51.0744],
          [-115.7763, 51.0665],
          [-115.8028, 51.0696],
          [-115.8128, 51.0864],
          [-115.8425, 51.0764],
          [-115.8679, 51.0892],
          [-115.8948, 51.0861],
          [-115.9039, 51.0828],
          [-115.9236, 51.083],
          [-115.9683, 51.1221],
          [-116.0088, 51.126],
          [-116.0156, 51.138],
          [-116.0074, 51.1563],
          [-116.0368, 51.17],
          [-116.0214, 51.187],
          [-116, 51.1939],
          [-116.0076, 51.2219],
          [-116.0493, 51.2274],
          [-116.0652, 51.2479],
          [-116.1533, 51.2634],
          [-116.1516, 51.2871],
          [-116.1642, 51.2977],
          [-116.1885, 51.3014],
          [-116.2271, 51.3015],
          [-116.268, 51.3119],
          [-116.2901, 51.3431],
          [-116.2935, 51.3648],
          [-116.3115, 51.3849],
          [-116.2825, 51.4068],
          [-116.2943, 51.4299],
          [-116.2841, 51.4565],
          [-116.3607, 51.4708],
          [-116.3939, 51.5056],
          [-116.3954, 51.5255],
          [-116.3821, 51.531],
          [-116.3909, 51.5456],
          [-116.4648, 51.5658],
          [-116.4691, 51.6017],
          [-116.595, 51.6618],
          [-116.5855, 51.7127],
          [-116.6504, 51.7537],
          [-116.6432, 51.7867],
          [-116.6591, 51.8052],
          [-116.6796, 51.813],
          [-116.749, 51.805],
          [-116.8193, 51.7386],
          [-116.8098, 51.7053],
          [-116.896, 51.7027],
          [-116.9206, 51.7098],
          [-116.9266, 51.729],
          [-116.9764, 51.7601],
          [-116.9612, 51.7908],
          [-117.0335, 51.8563],
          [-117.0108, 51.8896],
          [-117.0977, 51.9397],
          [-117.1032, 51.9565],
          [-117.1202, 51.9557],
          [-117.1449, 51.9727],
          [-117.196, 51.9791],
          [-117.2337, 52.0117],
          [-117.2921, 52.0854],
          [-117.3173, 52.194],
          [-117.3272, 52.1899],
          [-117.3341, 52.1486],
          [-117.3813, 52.1377],
          [-117.5002, 52.1442],
          [-117.51, 52.1578],
          [-117.5232, 52.1582],
          [-117.6113, 52.1442],
          [-117.6294, 52.1747],
          [-117.6636, 52.1979],
          [-117.7414, 52.203],
          [-117.8184, 52.2263],
          [-117.8396, 52.2741],
          [-117.7529, 52.3164],
          [-117.7059, 52.3653],
          [-117.7666, 52.4177],
          [-117.887, 52.4261],
          [-117.9669, 52.4697],
          [-117.9881, 52.5002],
          [-118.0134, 52.4839],
          [-118.0212, 52.4555],
          [-118.053, 52.4495],
          [-118.0304, 52.438],
          [-118.0442, 52.3984],
          [-118.1381, 52.4075],
          [-118.1821, 52.3828],
          [-118.1802, 52.3726],
          [-118.2194, 52.3741],
          [-118.221, 52.3975],
          [-118.2445, 52.408],
          [-118.2554, 52.4495],
          [-118.2356, 52.4901],
          [-118.2892, 52.5385],
          [-118.2729, 52.5659],
          [-118.3327, 52.5801],
          [-118.3546, 52.6336],
          [-118.2902, 52.6777],
          [-118.3434, 52.7088],
          [-118.3429, 52.7385],
          [-118.4225, 52.7757],
          [-118.4024, 52.8425],
          [-118.4526, 52.8553],
          [-118.4484, 52.8852],
          [-118.5001, 52.9062],
          [-118.5223, 52.8981],
          [-118.5438, 52.9083],
          [-118.5755, 52.8852],
          [-118.6139, 52.8837],
          [-118.6135, 52.936],
          [-118.6713, 52.9783],
          [-118.6409, 52.9993],
          [-118.7699, 53.0745],
          [-118.7296, 53.1154],
          [-118.7866, 53.1592],
          [-118.9161, 53.2118],
          [-118.9476, 53.2389],
          [-118.9753, 53.2416],
          [-119.024, 53.2319],
          [-118.9996, 53.1927],
          [-119.0289, 53.1641],
          [-119, 53.1485],
          [-119.0252, 53.1345],
          [-119.0247, 53.1267],
          [-119.0481, 53.1268],
          [-119.047, 53.1449],
          [-119.1197, 53.1617],
          [-119.1758, 53.1846],
          [-119.2516, 53.1989],
          [-119.3407, 53.287],
          [-119.356, 53.3401],
          [-119.3909, 53.3608],
          [-119.4062, 53.3681],
          [-119.4594, 53.3572],
          [-119.5177, 53.37],
          [-119.5486, 53.3675],
          [-119.5985, 53.3835],
          [-119.5985, 53.3657],
          [-119.6243, 53.3596],
          [-119.6377, 53.369],
          [-119.6691, 53.3678],
          [-119.7256, 53.3887],
          [-119.8331, 53.5193],
          [-119.9005, 53.5329],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "Vancouver Mkt": {
    type: "Feature",
    properties: { code: "BC_VAN", name: "Vancouver Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-123.447, 49.435],
            [-123.4593, 49.4719],
            [-123.4347, 49.5219],
            [-123.3593, 49.5312],
            [-123.3182, 49.4746],
            [-123.3167, 49.4475],
            [-123.3712, 49.4317],
            [-123.4074, 49.4572],
            [-123.447, 49.435],
          ],
        ],
        [
          [
            [-123.4286, 49.3438],
            [-123.428, 49.3708],
            [-123.3589, 49.4138],
            [-123.3211, 49.3761],
            [-123.3602, 49.336],
            [-123.4286, 49.3438],
          ],
        ],
        [
          [
            [-123.213, 48.7958],
            [-123.1887, 48.8139],
            [-123.099, 48.7887],
            [-123.1544, 48.7633],
            [-123.213, 48.7958],
          ],
        ],
        [
          [
            [-123.3455, 48.8566],
            [-123.2462, 48.8562],
            [-123.2461, 48.8312],
            [-123.327, 48.8328],
            [-123.3455, 48.8566],
          ],
        ],
        [
          [
            [-123.5871, 48.9965],
            [-123.4061, 48.919],
            [-123.3447, 48.9048],
            [-123.3139, 48.8621],
            [-123.3821, 48.8654],
            [-123.4151, 48.906],
            [-123.5258, 48.9477],
            [-123.5871, 48.9965],
          ],
        ],
        [
          [
            [-124.1998, 49.6708],
            [-124.2016, 49.7116],
            [-124.1438, 49.7585],
            [-124.0153, 49.7843],
            [-124.0818, 49.7128],
            [-124.0898, 49.647],
            [-124.1998, 49.6708],
          ],
        ],
        [
          [
            [-123.8714, 49.17],
            [-123.8666, 49.1963],
            [-123.7721, 49.1677],
            [-123.7051, 49.1594],
            [-123.7038, 49.1297],
            [-123.7632, 49.1297],
            [-123.8714, 49.17],
          ],
        ],
        [
          [
            [-123.5625, 48.7596],
            [-123.5171, 48.7899],
            [-123.5711, 48.8211],
            [-123.5486, 48.866],
            [-123.5925, 48.8988],
            [-123.5952, 48.945],
            [-123.4222, 48.8492],
            [-123.499, 48.85],
            [-123.3664, 48.7753],
            [-123.4257, 48.7333],
            [-123.4872, 48.7136],
            [-123.5625, 48.7596],
          ],
        ],
        [
          [
            [-124.8139, 50.2324],
            [-124.7831, 50.2874],
            [-124.7359, 50.3093],
            [-124.6671, 50.2759],
            [-124.6491, 50.2078],
            [-124.6994, 50.1652],
            [-124.7568, 50.2101],
            [-124.8139, 50.2324],
          ],
        ],
        [
          [
            [-124.9811, 50.2484],
            [-124.921, 50.3046],
            [-124.8532, 50.2854],
            [-124.7868, 50.2859],
            [-124.8235, 50.2226],
            [-124.7594, 50.1876],
            [-124.7516, 50.1608],
            [-124.8112, 50.1137],
            [-124.8627, 50.1244],
            [-124.8816, 50.1678],
            [-124.9247, 50.1914],
            [-124.9811, 50.2484],
          ],
        ],
        [
          [
            [-124.2311, 49.4988],
            [-124.2839, 49.4552],
            [-124.3669, 49.4767],
            [-124.343, 49.5114],
            [-124.2908, 49.5147],
            [-124.2311, 49.4988],
          ],
        ],
        [
          [
            [-124.6371, 49.7362],
            [-124.6034, 49.8036],
            [-124.5649, 49.759],
            [-124.4996, 49.7348],
            [-124.3599, 49.7101],
            [-124.2648, 49.6168],
            [-124.1493, 49.5391],
            [-124.1451, 49.4901],
            [-124.3005, 49.5682],
            [-124.4338, 49.6208],
            [-124.4371, 49.6389],
            [-124.5219, 49.6916],
            [-124.6371, 49.7362],
          ],
        ],
        [
          [
            [-125.1915, 50.2678],
            [-125.2082, 50.2991],
            [-125.114, 50.3223],
            [-125.089, 50.2774],
            [-125.1052, 50.2483],
            [-125.1521, 50.2386],
            [-125.1915, 50.2678],
          ],
        ],
        [
          [
            [-125.0717, 50.115],
            [-125.0394, 50.1282],
            [-124.9737, 50.1981],
            [-124.9295, 50.1843],
            [-124.8953, 50.1402],
            [-124.9065, 50.1166],
            [-124.8826, 50.0641],
            [-124.9831, 50.0724],
            [-125.0717, 50.115],
          ],
        ],
        [
          [
            [-125.1544, 50.1412],
            [-125.1208, 50.1767],
            [-125.127, 50.2217],
            [-125.0748, 50.2764],
            [-125.0439, 50.2431],
            [-125.0411, 50.1903],
            [-125.0803, 50.1772],
            [-125.1296, 50.1273],
            [-125.1544, 50.1412],
          ],
        ],
        [
          [
            [-127.1393, 52.9461],
            [-127.1393, 53.0137],
            [-126.7899, 53.0121],
            [-126.7242, 52.9923],
            [-126.65, 53.0321],
            [-126.6243, 52.9712],
            [-126.6469, 52.9415],
            [-126.599, 52.9129],
            [-126.5888, 52.8787],
            [-126.641, 52.8452],
            [-126.6033, 52.8121],
            [-126.5204, 52.7731],
            [-126.4196, 52.7928],
            [-126.3395, 52.828],
            [-126.3455, 52.7014],
            [-126.4112, 52.6885],
            [-126.4166, 52.6614],
            [-126.4937, 52.6492],
            [-126.525, 52.6176],
            [-126.6445, 52.5792],
            [-126.6759, 52.5953],
            [-126.6986, 52.592],
            [-126.7394, 52.5842],
            [-126.8271, 52.4844],
            [-126.8682, 52.4933],
            [-126.8789, 52.3806],
            [-126.9581, 52.3656],
            [-126.9845, 52.3326],
            [-127.1084, 52.3338],
            [-127.1625, 52.3427],
            [-127.184, 52.3759],
            [-127.2346, 52.5306],
            [-127.1161, 52.5785],
            [-127.099, 52.6057],
            [-127.0382, 52.6286],
            [-126.9863, 52.63],
            [-126.9729, 52.681],
            [-126.9236, 52.7064],
            [-126.957, 52.7616],
            [-126.952, 52.7995],
            [-127.039, 52.8637],
            [-127.1324, 52.9165],
            [-127.1393, 52.9461],
          ],
        ],
        [
          [
            [-124.7084, 49.513],
            [-124.6799, 49.5516],
            [-124.6338, 49.5282],
            [-124.6658, 49.4911],
            [-124.7084, 49.513],
          ],
        ],
        [
          [
            [-125.4062, 50.3362],
            [-125.3348, 50.3859],
            [-125.2836, 50.4447],
            [-125.2059, 50.3995],
            [-125.1578, 50.3864],
            [-125.1292, 50.3322],
            [-125.2114, 50.2995],
            [-125.2672, 50.3281],
            [-125.3352, 50.3077],
            [-125.4001, 50.3033],
            [-125.4062, 50.3362],
          ],
        ],
        [
          [
            [-124.8321, 49.5417],
            [-124.8183, 49.5898],
            [-124.7735, 49.5667],
            [-124.7308, 49.5026],
            [-124.7565, 49.4895],
            [-124.8321, 49.5417],
          ],
        ],
        [
          [
            [-125.5504, 50.3763],
            [-125.4857, 50.3994],
            [-125.4656, 50.429],
            [-125.3934, 50.46],
            [-125.3228, 50.4454],
            [-125.3246, 50.4222],
            [-125.3871, 50.3643],
            [-125.4576, 50.3477],
            [-125.5504, 50.3763],
          ],
        ],
        [
          [
            [-125.3493, 50.1582],
            [-125.3252, 50.1777],
            [-125.3574, 50.2095],
            [-125.3831, 50.2776],
            [-125.2378, 50.3057],
            [-125.2211, 50.2647],
            [-125.1587, 50.2286],
            [-125.1393, 50.1916],
            [-125.1953, 50.1323],
            [-125.1466, 50.0111],
            [-125.1976, 50.0252],
            [-125.2676, 50.094],
            [-125.3493, 50.1582],
          ],
        ],
        [
          [
            [-125.7835, 50.3857],
            [-125.7224, 50.4144],
            [-125.6577, 50.4128],
            [-125.6055, 50.436],
            [-125.5202, 50.4454],
            [-125.4993, 50.4063],
            [-125.6256, 50.3811],
            [-125.7418, 50.3764],
            [-125.7835, 50.3857],
          ],
        ],
        [
          [
            [-125.9745, 50.4384],
            [-125.906, 50.4595],
            [-125.7953, 50.4761],
            [-125.7332, 50.4259],
            [-125.7893, 50.4095],
            [-125.8793, 50.4202],
            [-125.9261, 50.4145],
            [-125.9745, 50.4384],
          ],
        ],
        [
          [
            [-126.6191, 50.6604],
            [-126.5631, 50.7254],
            [-126.4033, 50.8132],
            [-126.3329, 50.8335],
            [-126.2303, 50.8228],
            [-126.2139, 50.7708],
            [-126.1544, 50.7543],
            [-126.2373, 50.72],
            [-126.2706, 50.6536],
            [-126.3495, 50.6445],
            [-126.4169, 50.6591],
            [-126.4585, 50.6421],
            [-126.5594, 50.6444],
            [-126.6191, 50.6604],
          ],
        ],
        [
          [
            [-127.8907, 52.0453],
            [-127.883, 52.095],
            [-127.8493, 52.162],
            [-127.7667, 52.2455],
            [-127.6566, 52.2689],
            [-127.6251, 52.2604],
            [-127.5648, 52.2983],
            [-127.4548, 52.3302],
            [-127.4438, 52.3757],
            [-127.2971, 52.411],
            [-127.2176, 52.3432],
            [-127.259, 52.2888],
            [-127.3691, 52.2487],
            [-127.4274, 52.2461],
            [-127.5179, 52.1844],
            [-127.5712, 52.1834],
            [-127.5763, 52.1508],
            [-127.64, 52.1398],
            [-127.6959, 52.0377],
            [-127.6924, 52.0001],
            [-127.8997, 52.0015],
            [-127.8907, 52.0453],
          ],
        ],
        [
          [
            [-126.5711, 50.5992],
            [-126.4695, 50.6293],
            [-126.3442, 50.6279],
            [-126.3483, 50.6123],
            [-126.4414, 50.5828],
            [-126.5711, 50.5992],
          ],
        ],
        [
          [
            [-126.9256, 50.8464],
            [-126.8615, 50.8826],
            [-126.8068, 50.8911],
            [-126.7339, 50.8627],
            [-126.8144, 50.8395],
            [-126.9256, 50.8464],
          ],
        ],
        [
          [
            [-126.6696, 50.5625],
            [-126.6114, 50.5907],
            [-126.5312, 50.5818],
            [-126.5339, 50.5569],
            [-126.6387, 50.5521],
            [-126.6696, 50.5625],
          ],
        ],
        [
          [
            [-126.744, 50.7774],
            [-126.7929, 50.7671],
            [-126.8488, 50.7833],
            [-126.9036, 50.837],
            [-126.7987, 50.8273],
            [-126.7592, 50.8469],
            [-126.6172, 50.8495],
            [-126.5303, 50.7878],
            [-126.6944, 50.7897],
            [-126.744, 50.7774],
          ],
        ],
        [
          [
            [-126.3059, 50.5851],
            [-126.2076, 50.5767],
            [-126.2216, 50.5466],
            [-126.4332, 50.5126],
            [-126.5441, 50.5266],
            [-126.3965, 50.5864],
            [-126.3059, 50.5851],
          ],
        ],
        [
          [
            [-127.6813, 51.497],
            [-127.6436, 51.5101],
            [-127.6103, 51.5537],
            [-127.5643, 51.5333],
            [-127.6396, 51.4893],
            [-127.6813, 51.497],
          ],
        ],
        [
          [
            [-127.6078, 51.2887],
            [-127.5269, 51.31],
            [-127.3779, 51.2951],
            [-127.5832, 51.2643],
            [-127.6078, 51.2887],
          ],
        ],
        [
          [
            [-127.1565, 50.6473],
            [-127.1065, 50.6755],
            [-127.0284, 50.6571],
            [-126.929, 50.6544],
            [-126.8886, 50.6192],
            [-127.0442, 50.6392],
            [-127.081, 50.6308],
            [-127.1565, 50.6473],
          ],
        ],
        [
          [
            [-125.9253, 49.1777],
            [-125.9334, 49.2231],
            [-125.8927, 49.2408],
            [-125.7763, 49.2279],
            [-125.8019, 49.1406],
            [-125.8529, 49.1335],
            [-125.868, 49.1815],
            [-125.9253, 49.1777],
          ],
        ],
        [
          [
            [-124.6953, 50.0675],
            [-124.7436, 50.0367],
            [-124.7872, 50.0674],
            [-124.7219, 50.0931],
            [-124.6326, 50.161],
            [-124.607, 50.1993],
            [-124.6337, 50.2938],
            [-124.6986, 50.3397],
            [-124.7123, 50.3732],
            [-124.8306, 50.3071],
            [-125.003, 50.3427],
            [-125.0567, 50.3156],
            [-125.0928, 50.3489],
            [-125.0538, 50.3723],
            [-125.0662, 50.4116],
            [-125.0522, 50.48],
            [-124.9716, 50.5004],
            [-124.922, 50.5514],
            [-124.8853, 50.5618],
            [-124.8646, 50.6883],
            [-124.8325, 50.7324],
            [-124.9168, 50.8012],
            [-124.8641, 50.8231],
            [-124.8377, 50.8722],
            [-124.8937, 50.8722],
            [-124.9125, 50.8363],
            [-124.9527, 50.8334],
            [-124.9802, 50.7965],
            [-124.9475, 50.7556],
            [-124.8864, 50.7295],
            [-124.9288, 50.6716],
            [-124.8981, 50.6389],
            [-124.9301, 50.5894],
            [-124.9878, 50.5695],
            [-124.9945, 50.5363],
            [-125.1087, 50.502],
            [-125.1148, 50.4301],
            [-125.1767, 50.4068],
            [-125.2264, 50.4425],
            [-125.3371, 50.4707],
            [-125.397, 50.4672],
            [-125.4775, 50.4412],
            [-125.5147, 50.4536],
            [-125.5933, 50.4494],
            [-125.522, 50.5523],
            [-125.5298, 50.6345],
            [-125.558, 50.6299],
            [-125.5493, 50.5507],
            [-125.5756, 50.5275],
            [-125.612, 50.4564],
            [-125.6558, 50.4286],
            [-125.707, 50.422],
            [-125.8095, 50.4876],
            [-125.9134, 50.4737],
            [-126.0358, 50.4727],
            [-126.1057, 50.4886],
            [-126.1544, 50.4843],
            [-126.2834, 50.5149],
            [-126.1906, 50.531],
            [-126.1997, 50.5784],
            [-126.2658, 50.5951],
            [-126.301, 50.6301],
            [-126.1063, 50.644],
            [-126.0079, 50.6674],
            [-125.8854, 50.6644],
            [-125.8353, 50.6816],
            [-125.7647, 50.6751],
            [-125.6994, 50.689],
            [-125.6836, 50.7395],
            [-125.6164, 50.7564],
            [-125.5911, 50.7958],
            [-125.6345, 50.8251],
            [-125.6143, 50.8748],
            [-125.5368, 50.8782],
            [-125.4956, 50.9381],
            [-125.5441, 51.0126],
            [-125.5427, 51.0569],
            [-125.5879, 51.0804],
            [-125.591, 50.9653],
            [-125.5447, 50.9359],
            [-125.5659, 50.9032],
            [-125.6203, 50.9047],
            [-125.6832, 50.8742],
            [-125.6821, 50.8191],
            [-125.6525, 50.8093],
            [-125.7225, 50.7454],
            [-125.7302, 50.7078],
            [-125.8497, 50.7024],
            [-125.9583, 50.6852],
            [-126.0262, 50.6933],
            [-126.0943, 50.6742],
            [-126.1822, 50.6675],
            [-126.1953, 50.6854],
            [-126.1963, 50.6934],
            [-126.0188, 50.8068],
            [-126.111, 50.7786],
            [-126.1898, 50.7798],
            [-126.204, 50.8096],
            [-126.2497, 50.8327],
            [-126.3289, 50.8502],
            [-126.3796, 50.8299],
            [-126.4749, 50.8133],
            [-126.5643, 50.8326],
            [-126.5494, 50.8967],
            [-126.4934, 50.925],
            [-126.418, 50.9077],
            [-126.3898, 50.9314],
            [-126.4846, 50.9631],
            [-126.5195, 51.0338],
            [-126.5509, 51.0031],
            [-126.5114, 50.9414],
            [-126.5618, 50.9093],
            [-126.6631, 50.8652],
            [-126.7195, 50.8726],
            [-126.7813, 50.9058],
            [-126.8114, 50.9496],
            [-126.94, 50.8981],
            [-126.9406, 50.8518],
            [-127.0153, 50.8184],
            [-127.1994, 50.8766],
            [-127.272, 50.913],
            [-127.3379, 50.9062],
            [-127.4049, 50.9249],
            [-127.524, 51.0221],
            [-127.4967, 51.0611],
            [-127.5005, 51.0981],
            [-127.2968, 51.0206],
            [-127.5045, 51.1078],
            [-127.6578, 51.0873],
            [-127.6809, 51.1316],
            [-127.7416, 51.1418],
            [-127.7714, 51.1831],
            [-127.7696, 51.2465],
            [-127.6308, 51.2809],
            [-127.5935, 51.2613],
            [-127.4255, 51.2689],
            [-127.3248, 51.3001],
            [-127.4379, 51.3163],
            [-127.5407, 51.3198],
            [-127.5165, 51.3513],
            [-127.755, 51.3197],
            [-127.787, 51.3346],
            [-127.7221, 51.4009],
            [-127.6682, 51.4025],
            [-127.5665, 51.4721],
            [-127.53, 51.4484],
            [-127.4734, 51.4379],
            [-127.3148, 51.4269],
            [-127.3186, 51.4426],
            [-127.4806, 51.4446],
            [-127.5353, 51.458],
            [-127.5031, 51.624],
            [-127.4078, 51.6369],
            [-127.3143, 51.6671],
            [-127.3324, 51.6916],
            [-127.3958, 51.6821],
            [-127.5497, 51.6354],
            [-127.5749, 51.5861],
            [-127.6608, 51.5174],
            [-127.6892, 51.5369],
            [-127.7798, 51.5301],
            [-127.8002, 51.5798],
            [-127.7156, 51.6221],
            [-127.7345, 51.6379],
            [-127.817, 51.6247],
            [-127.8774, 51.6698],
            [-127.8909, 51.7163],
            [-127.8726, 51.7557],
            [-127.8964, 51.8152],
            [-127.8578, 51.8852],
            [-127.8822, 51.8973],
            [-127.7468, 51.9533],
            [-127.705, 51.9414],
            [-127.6302, 52.0245],
            [-127.6563, 52.0284],
            [-127.6079, 52.1211],
            [-127.5557, 52.1236],
            [-127.3406, 52.2333],
            [-127.2923, 52.2313],
            [-127.2132, 52.2729],
            [-127.1544, 52.3169],
            [-127.0835, 52.2983],
            [-127.0324, 52.3104],
            [-126.9556, 52.2602],
            [-126.9277, 52.1891],
            [-126.8369, 52.1476],
            [-126.6936, 52.0227],
            [-126.6722, 52.0418],
            [-126.8105, 52.1584],
            [-126.9015, 52.1967],
            [-126.9165, 52.2521],
            [-126.9652, 52.3117],
            [-126.8783, 52.3628],
            [-126.9015, 52.2838],
            [-126.8715, 52.2497],
            [-126.8122, 52.2236],
            [-126.7712, 52.2326],
            [-126.7689, 52.2687],
            [-126.6506, 52.2449],
            [-126.5559, 52.283],
            [-126.5245, 52.2761],
            [-126.4777, 52.2145],
            [-126.3858, 52.2186],
            [-126.3396, 52.1838],
            [-126.3018, 52.1888],
            [-126.2614, 52.1818],
            [-126.2538, 52.1481],
            [-126.2026, 52.1211],
            [-126.2743, 52.0716],
            [-126.2252, 52.0394],
            [-126.2103, 52.0031],
            [-125.7771, 52.0001],
            [-125.7392, 52.012],
            [-125.7203, 52.0081],
            [-125.6954, 51.9646],
            [-125.6564, 51.9237],
            [-125.5977, 51.9005],
            [-125.5681, 51.9158],
            [-125.5354, 51.9123],
            [-125.4872, 51.9155],
            [-125.5134, 51.8679],
            [-125.3539, 51.7906],
            [-125.2546, 51.7648],
            [-125.2377, 51.7484],
            [-125.2565, 51.723],
            [-125.2484, 51.6755],
            [-125.2291, 51.6442],
            [-125.2921, 51.6091],
            [-125.2961, 51.5892],
            [-125.238, 51.5595],
            [-125.2419, 51.518],
            [-125.2891, 51.5046],
            [-125.2897, 51.4754],
            [-125.3415, 51.4261],
            [-125.3267, 51.3957],
            [-125.2511, 51.3935],
            [-125.1855, 51.4],
            [-125.1708, 51.3767],
            [-125.0759, 51.3523],
            [-125.0093, 51.3495],
            [-124.9656, 51.328],
            [-124.8794, 51.3207],
            [-124.7827, 51.2889],
            [-124.7624, 51.238],
            [-124.6771, 51.219],
            [-124.5811, 51.1808],
            [-124.434, 51.1481],
            [-124.43, 51.1686],
            [-124.3691, 51.1998],
            [-124.376, 51.1423],
            [-124.2972, 51.137],
            [-124.2451, 51.1481],
            [-124.1753, 51.1316],
            [-124.1594, 51.0772],
            [-124.1596, 51.0417],
            [-124.1132, 51.0143],
            [-124.0899, 50.9911],
            [-124.0413, 50.9421],
            [-124.0014, 50.9643],
            [-123.9358, 50.9358],
            [-123.8741, 50.9504],
            [-123.7651, 50.9106],
            [-123.7133, 50.8486],
            [-123.7288, 50.8427],
            [-123.7059, 50.7922],
            [-123.6395, 50.795],
            [-123.6122, 50.7761],
            [-123.5491, 50.8003],
            [-123.4963, 50.7789],
            [-123.4537, 50.798],
            [-123.4071, 50.7804],
            [-123.3578, 50.761],
            [-123.321, 50.7312],
            [-123.2745, 50.7325],
            [-123.2356, 50.7228],
            [-123.1674, 50.6366],
            [-123.1219, 50.629],
            [-123.0818, 50.5938],
            [-123.0321, 50.5839],
            [-123.0193, 50.6009],
            [-122.9901, 50.5689],
            [-122.9409, 50.5524],
            [-122.9274, 50.6008],
            [-122.8639, 50.6168],
            [-122.8312, 50.6459],
            [-122.778, 50.6651],
            [-122.7022, 50.6432],
            [-122.628, 50.6524],
            [-122.6105, 50.6353],
            [-122.6072, 50.6427],
            [-122.5817, 50.656],
            [-122.592, 50.6801],
            [-122.5876, 50.7019],
            [-122.4885, 50.7396],
            [-122.3912, 50.7546],
            [-122.2623, 50.739],
            [-122.2571, 50.7672],
            [-122.2211, 50.79],
            [-122.1762, 50.8177],
            [-122.192, 50.86],
            [-122.1475, 50.852],
            [-122.1622, 50.8192],
            [-122.1228, 50.7907],
            [-122.113, 50.7883],
            [-122.1072, 50.7954],
            [-122.0875, 50.7872],
            [-122.0836, 50.8043],
            [-122.043, 50.7938],
            [-122.0236, 50.7937],
            [-121.99, 50.7615],
            [-121.9348, 50.743],
            [-121.9283, 50.7044],
            [-121.951, 50.6944],
            [-121.968, 50.6964],
            [-121.9954, 50.6583],
            [-122.0266, 50.6421],
            [-122.0677, 50.6464],
            [-122.1043, 50.6143],
            [-122.0874, 50.5804],
            [-122.1137, 50.5403],
            [-122.1557, 50.5318],
            [-122.2448, 50.4645],
            [-122.2485, 50.4268],
            [-122.2049, 50.3734],
            [-122.1448, 50.3603],
            [-122.2009, 50.3379],
            [-122.2487, 50.2964],
            [-122.2628, 50.2587],
            [-122.303, 50.2491],
            [-122.3016, 50.2295],
            [-122.2678, 50.2227],
            [-122.2781, 50.2036],
            [-122.3032, 50.1989],
            [-122.3163, 50.1874],
            [-122.3136, 50.1726],
            [-122.2984, 50.174],
            [-122.2584, 50.1238],
            [-122.2212, 50.1194],
            [-122.2444, 50.0942],
            [-122.2883, 50.0518],
            [-122.2546, 50.0356],
            [-122.2484, 49.9799],
            [-122.2639, 49.9752],
            [-122.2703, 49.9633],
            [-122.2257, 49.9202],
            [-122.1587, 49.9069],
            [-122.0918, 49.8409],
            [-122.0392, 49.817],
            [-121.9918, 49.8199],
            [-121.9091, 49.873],
            [-121.8579, 49.88],
            [-121.7932, 49.8405],
            [-121.7727, 49.8705],
            [-121.729, 49.8485],
            [-121.7126, 49.8232],
            [-121.7296, 49.8156],
            [-121.7318, 49.8027],
            [-121.7201, 49.7917],
            [-121.7053, 49.7911],
            [-121.7148, 49.7642],
            [-121.7088, 49.7466],
            [-121.7337, 49.7351],
            [-121.7014, 49.701],
            [-121.5626, 49.7021],
            [-121.5376, 49.6825],
            [-121.4201, 49.6667],
            [-121.3998, 49.7016],
            [-121.1437, 49.6999],
            [-121.1183, 49.6774],
            [-121.1695, 49.6495],
            [-121.1687, 49.635],
            [-121.1567, 49.6176],
            [-121.1211, 49.6095],
            [-121.0624, 49.6267],
            [-120.9618, 49.6734],
            [-120.9473, 49.7603],
            [-120.7743, 49.7638],
            [-120.7345, 49.7498],
            [-120.6037, 49.7497],
            [-120.5633, 49.7877],
            [-120.5511, 49.8906],
            [-120.4529, 49.8813],
            [-120.3655, 49.8978],
            [-120.2981, 49.8676],
            [-120.1751, 49.8964],
            [-120.0288, 49.911],
            [-120.0347, 49.885],
            [-120.029, 49.8619],
            [-119.9988, 49.8293],
            [-119.9843, 49.7895],
            [-119.9749, 49.6765],
            [-119.9896, 49.6646],
            [-120.0516, 49.6779],
            [-120.0988, 49.6459],
            [-120.0314, 49.5947],
            [-120.0483, 49.5729],
            [-120.022, 49.5543],
            [-120.0343, 49.5396],
            [-120.0331, 49.5216],
            [-120.0009, 49.5059],
            [-119.9495, 49.5289],
            [-119.9203, 49.517],
            [-119.908, 49.5001],
            [-119.8964, 49.4086],
            [-119.9252, 49.4081],
            [-119.9412, 49.3959],
            [-119.941, 49.3768],
            [-119.8946, 49.3771],
            [-119.9064, 49.3982],
            [-119.8873, 49.4149],
            [-119.8665, 49.4224],
            [-119.8173, 49.4168],
            [-119.8275, 49.3968],
            [-119.8093, 49.3769],
            [-119.8055, 49.3433],
            [-119.818, 49.3276],
            [-119.8103, 49.3098],
            [-119.7978, 49.3096],
            [-119.7978, 49.3014],
            [-119.6704, 49.2995],
            [-119.6762, 49.2225],
            [-119.7263, 49.1908],
            [-119.7338, 49.1351],
            [-119.7169, 49.1354],
            [-119.7037, 49.0509],
            [-119.6869, 49.024],
            [-119.6687, 49.0204],
            [-119.6264, 49.0556],
            [-119.6261, 49.0001],
            [-121.25, 49],
            [-122.7578, 49.0022],
            [-122.8224, 49.0251],
            [-122.8725, 49.0272],
            [-122.8912, 49.0919],
            [-123.0997, 49.041],
            [-123.1557, 49.0626],
            [-123.2005, 49.1257],
            [-123.2019, 49.2156],
            [-123.2453, 49.237],
            [-123.2436, 49.2798],
            [-123.1723, 49.2717],
            [-123.1417, 49.3141],
            [-123.2063, 49.3404],
            [-123.2488, 49.3406],
            [-123.2725, 49.3747],
            [-123.2344, 49.4177],
            [-123.249, 49.5014],
            [-123.2054, 49.6203],
            [-123.2699, 49.5852],
            [-123.3916, 49.5604],
            [-123.4921, 49.518],
            [-123.4668, 49.4432],
            [-123.5307, 49.3844],
            [-123.6836, 49.4367],
            [-123.7249, 49.4404],
            [-123.7454, 49.4718],
            [-123.7901, 49.466],
            [-123.9078, 49.4853],
            [-123.9094, 49.512],
            [-123.9978, 49.554],
            [-124.032, 49.6173],
            [-124.0724, 49.6377],
            [-124.0659, 49.6934],
            [-124.0325, 49.7413],
            [-123.9724, 49.7669],
            [-123.9074, 49.7482],
            [-123.8413, 49.6209],
            [-123.7883, 49.6037],
            [-123.8262, 49.6772],
            [-123.8921, 49.75],
            [-123.9466, 49.7753],
            [-123.8728, 49.8441],
            [-123.867, 49.879],
            [-123.8979, 49.9161],
            [-123.9362, 49.9209],
            [-123.974, 49.9561],
            [-123.9586, 49.998],
            [-123.7899, 50.046],
            [-123.7431, 50.0874],
            [-123.8506, 50.1627],
            [-123.8573, 50.1375],
            [-123.8022, 50.092],
            [-123.8437, 50.0567],
            [-124.0123, 49.9968],
            [-124.0096, 49.9706],
            [-123.9621, 49.9051],
            [-123.9034, 49.8791],
            [-123.9198, 49.8279],
            [-123.9644, 49.8175],
            [-124.0324, 49.8773],
            [-124.0883, 49.8393],
            [-124.0775, 49.8009],
            [-124.1308, 49.7828],
            [-124.3488, 49.7764],
            [-124.3997, 49.768],
            [-124.5268, 49.8038],
            [-124.5305, 49.8461],
            [-124.6244, 49.9082],
            [-124.7519, 49.9634],
            [-124.8065, 50.0112],
            [-124.8088, 50.0646],
            [-124.7344, 50.0138],
            [-124.6953, 50.0675],
          ],
          [
            [-126.6717, 52.0043],
            [-126.5637, 52.0042],
            [-126.6159, 52.0155],
            [-126.661, 52.0199],
            [-126.6717, 52.0043],
          ],
        ],
        [
          [
            [-126.0205, 49.1648],
            [-126.0403, 49.2057],
            [-125.9536, 49.2074],
            [-125.9892, 49.148],
            [-126.0205, 49.1648],
          ],
        ],
        [
          [
            [-126.231, 49.2846],
            [-126.2258, 49.3615],
            [-126.1526, 49.3934],
            [-126.1066, 49.3933],
            [-126.0817, 49.3605],
            [-126.0848, 49.3189],
            [-126.0563, 49.2615],
            [-126.1891, 49.2627],
            [-126.231, 49.2846],
          ],
        ],
        [
          [
            [-127.8431, 50.8808],
            [-127.8002, 50.9028],
            [-127.7245, 50.9165],
            [-127.6802, 50.892],
            [-127.656, 50.8329],
            [-127.8431, 50.8808],
          ],
        ],
        [
          [
            [-127.997, 50.9147],
            [-127.9012, 50.9454],
            [-127.8325, 50.9057],
            [-127.8784, 50.8898],
            [-127.997, 50.9147],
          ],
        ],
        [
          [
            [-126.9476, 49.7978],
            [-126.9585, 49.8371],
            [-126.7733, 49.8782],
            [-126.7308, 49.8446],
            [-126.6758, 49.827],
            [-126.632, 49.7476],
            [-126.6502, 49.7222],
            [-126.6119, 49.6623],
            [-126.6189, 49.5908],
            [-126.6763, 49.5801],
            [-126.7177, 49.604],
            [-126.8254, 49.6182],
            [-126.84, 49.658],
            [-126.8978, 49.6755],
            [-126.9535, 49.7228],
            [-126.8872, 49.7797],
            [-126.9476, 49.7978],
          ],
        ],
        [
          [
            [-126.402, 49.4746],
            [-126.4355, 49.4745],
            [-126.4887, 49.42],
            [-126.4739, 49.3772],
            [-126.541, 49.3908],
            [-126.5772, 49.416],
            [-126.5513, 49.5108],
            [-126.5684, 49.5784],
            [-126.5004, 49.6054],
            [-126.4734, 49.6458],
            [-126.3707, 49.6278],
            [-126.1906, 49.6567],
            [-126.0861, 49.6577],
            [-126.1132, 49.6864],
            [-126.2628, 49.6506],
            [-126.3502, 49.6445],
            [-126.372, 49.6648],
            [-126.4438, 49.6536],
            [-126.4996, 49.6864],
            [-126.5871, 49.6944],
            [-126.6224, 49.7361],
            [-126.6732, 49.8593],
            [-126.751, 49.8729],
            [-126.8008, 49.9066],
            [-126.8254, 49.8808],
            [-126.942, 49.8597],
            [-127.0439, 49.8522],
            [-127.1691, 49.8706],
            [-127.2223, 49.9504],
            [-127.2547, 49.9655],
            [-127.1883, 50.0009],
            [-127.177, 50.0597],
            [-127.1337, 50.1053],
            [-127.2184, 50.094],
            [-127.2858, 50.0972],
            [-127.2689, 50.0576],
            [-127.3457, 50.0197],
            [-127.4291, 50.0362],
            [-127.5166, 50.1342],
            [-127.6279, 50.1177],
            [-127.6606, 50.1505],
            [-127.7129, 50.133],
            [-127.7996, 50.0807],
            [-127.8659, 50.0931],
            [-127.9227, 50.1301],
            [-127.8367, 50.1701],
            [-127.7617, 50.2372],
            [-127.8012, 50.2468],
            [-127.8657, 50.2896],
            [-127.8587, 50.3133],
            [-127.9015, 50.3409],
            [-127.9785, 50.3256],
            [-127.9389, 50.465],
            [-127.8542, 50.4698],
            [-127.8102, 50.4576],
            [-127.6852, 50.4907],
            [-127.583, 50.49],
            [-127.6099, 50.5248],
            [-127.6631, 50.534],
            [-127.7998, 50.4947],
            [-127.8967, 50.4794],
            [-127.9302, 50.4896],
            [-128.011, 50.4707],
            [-128.0515, 50.4446],
            [-128.1081, 50.4703],
            [-128.1418, 50.5054],
            [-128.2185, 50.5368],
            [-128.2322, 50.5847],
            [-128.3085, 50.6122],
            [-128.3151, 50.664],
            [-128.3708, 50.6729],
            [-128.3643, 50.7016],
            [-128.4099, 50.7418],
            [-128.403, 50.7704],
            [-128.3318, 50.806],
            [-128.25, 50.8031],
            [-128.2119, 50.834],
            [-128.1298, 50.838],
            [-128.1026, 50.8665],
            [-128.0546, 50.8759],
            [-128.0057, 50.8582],
            [-127.9057, 50.8753],
            [-127.5764, 50.7855],
            [-127.486, 50.7671],
            [-127.4834, 50.7163],
            [-127.4265, 50.7137],
            [-127.3389, 50.6725],
            [-127.282, 50.6593],
            [-127.1935, 50.6107],
            [-127.134, 50.5915],
            [-127.0028, 50.5864],
            [-126.9683, 50.5599],
            [-126.907, 50.5609],
            [-126.7849, 50.5438],
            [-126.6157, 50.4953],
            [-126.4256, 50.4756],
            [-126.362, 50.4828],
            [-126.1249, 50.457],
            [-125.9967, 50.4181],
            [-125.9535, 50.3948],
            [-125.8206, 50.3841],
            [-125.7213, 50.3569],
            [-125.5854, 50.3687],
            [-125.5188, 50.3368],
            [-125.4533, 50.3243],
            [-125.4407, 50.2806],
            [-125.4111, 50.2706],
            [-125.3592, 50.1296],
            [-125.2461, 50.0404],
            [-125.182, 49.9138],
            [-125.1224, 49.8886],
            [-125.1014, 49.8527],
            [-125.0635, 49.8396],
            [-124.9629, 49.7611],
            [-124.8931, 49.7281],
            [-124.8618, 49.6973],
            [-124.923, 49.6429],
            [-124.8807, 49.5933],
            [-124.8613, 49.5393],
            [-124.801, 49.4818],
            [-124.7248, 49.4675],
            [-124.5731, 49.3804],
            [-124.4583, 49.3543],
            [-124.3554, 49.3517],
            [-124.3195, 49.3233],
            [-124.2642, 49.3252],
            [-124.2067, 49.2937],
            [-124.1353, 49.2904],
            [-124.0822, 49.2574],
            [-123.9648, 49.2366],
            [-123.9277, 49.1452],
            [-123.8195, 49.1358],
            [-123.8051, 49.0951],
            [-123.7472, 49.0414],
            [-123.7685, 48.9812],
            [-123.756, 48.9583],
            [-123.6666, 48.8783],
            [-123.5922, 48.8479],
            [-123.5585, 48.78],
            [-123.6, 48.756],
            [-123.5345, 48.6935],
            [-123.5145, 48.5677],
            [-123.4461, 48.6561],
            [-123.3732, 48.5891],
            [-123.3648, 48.5149],
            [-123.3066, 48.4956],
            [-123.2918, 48.4379],
            [-123.3492, 48.4024],
            [-123.4551, 48.4297],
            [-123.5599, 48.3428],
            [-123.561, 48.309],
            [-123.6546, 48.3137],
            [-123.7154, 48.3389],
            [-123.7171, 48.3658],
            [-123.6352, 48.3631],
            [-123.6552, 48.3946],
            [-123.8229, 48.357],
            [-123.867, 48.3798],
            [-123.9211, 48.3766],
            [-124.0694, 48.4225],
            [-124.2515, 48.4706],
            [-124.3096, 48.5045],
            [-124.446, 48.526],
            [-124.6432, 48.5731],
            [-124.7628, 48.6182],
            [-124.813, 48.6629],
            [-124.91, 48.6816],
            [-125.0525, 48.7208],
            [-125.101, 48.7216],
            [-125.1326, 48.7573],
            [-125.1257, 48.7976],
            [-125.126, 48.8325],
            [-125.0899, 48.8773],
            [-125.0018, 48.8977],
            [-125.0272, 48.9275],
            [-125.0282, 48.9285],
            [-125.0374, 48.9742],
            [-125.1273, 48.9799],
            [-125.1939, 48.9719],
            [-125.1959, 48.9705],
            [-125.2426, 48.9442],
            [-125.2882, 48.9743],
            [-125.3551, 49.0425],
            [-125.3699, 48.9977],
            [-125.4856, 48.9203],
            [-125.5109, 48.922],
            [-125.5798, 48.9786],
            [-125.6801, 49.0094],
            [-125.6961, 49.0386],
            [-125.772, 49.0723],
            [-125.8231, 49.062],
            [-125.889, 49.0929],
            [-125.8658, 49.1241],
            [-125.7587, 49.1071],
            [-125.6497, 49.1553],
            [-125.6557, 49.2021],
            [-125.7805, 49.1317],
            [-125.7546, 49.2209],
            [-125.8141, 49.249],
            [-125.7916, 49.3115],
            [-125.8023, 49.3335],
            [-125.7618, 49.3811],
            [-125.8091, 49.3375],
            [-125.835, 49.2615],
            [-125.8864, 49.2782],
            [-125.9732, 49.2268],
            [-126.0137, 49.2442],
            [-126.0333, 49.2861],
            [-125.9474, 49.2999],
            [-125.9534, 49.3336],
            [-125.896, 49.411],
            [-125.9108, 49.4214],
            [-125.9583, 49.3601],
            [-126.0186, 49.3205],
            [-126.0605, 49.3413],
            [-126.0601, 49.4078],
            [-126.0786, 49.4389],
            [-126.1464, 49.4105],
            [-126.2266, 49.404],
            [-126.2704, 49.3615],
            [-126.3327, 49.3787],
            [-126.3518, 49.4019],
            [-126.4075, 49.4125],
            [-126.4322, 49.4471],
            [-126.402, 49.4746],
          ],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "Prince George Mkt": {
    type: "Feature",
    properties: { code: "BC_PRI", name: "Prince George Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-130.2233, 54.0562],
            [-130.1599, 54.0676],
            [-130.129, 54.0232],
            [-130.1499, 53.9878],
            [-130.2083, 54.007],
            [-130.2233, 54.0562],
          ],
        ],
        [
          [
            [-129.4156, 53.2954],
            [-129.4117, 53.3421],
            [-129.4581, 53.3715],
            [-129.4168, 53.411],
            [-129.3365, 53.3639],
            [-129.3367, 53.3014],
            [-129.4156, 53.2954],
          ],
        ],
        [
          [
            [-128.4584, 52.3632],
            [-128.4042, 52.456],
            [-128.345, 52.4733],
            [-128.2731, 52.4747],
            [-128.3631, 52.3711],
            [-128.4584, 52.3632],
          ],
        ],
        [
          [
            [-128.4581, 52.3501],
            [-128.384, 52.3592],
            [-128.3966, 52.3223],
            [-128.4581, 52.3501],
          ],
        ],
        [
          [
            [-129.3503, 53.1745],
            [-129.346, 53.2058],
            [-129.2574, 53.2667],
            [-129.2882, 53.3184],
            [-129.2246, 53.3151],
            [-129.1728, 53.284],
            [-129.178, 53.2505],
            [-129.1507, 53.2051],
            [-129.1731, 53.1525],
            [-129.1534, 53.0968],
            [-129.2088, 53.1059],
            [-129.2781, 53.0793],
            [-129.3281, 53.1135],
            [-129.3503, 53.1745],
          ],
        ],
        [
          [
            [-130.7932, 54.3786],
            [-130.7177, 54.4128],
            [-130.6982, 54.3785],
            [-130.7324, 54.3581],
            [-130.7932, 54.3786],
          ],
        ],
        [
          [
            [-128.33, 52.1517],
            [-128.3043, 52.2008],
            [-128.2458, 52.2246],
            [-128.2099, 52.2076],
            [-128.33, 52.1517],
          ],
        ],
        [
          [
            [-130.9643, 54.5032],
            [-130.9681, 54.5539],
            [-130.9275, 54.6211],
            [-130.774, 54.6275],
            [-130.7528, 54.6036],
            [-130.7759, 54.5526],
            [-130.8923, 54.5067],
            [-130.9643, 54.5032],
          ],
        ],
        [
          [
            [-128.586, 54.1459],
            [-128.6017, 54.1073],
            [-128.7263, 54.1071],
            [-128.7268, 53.9623],
            [-128.7856, 53.9328],
            [-128.9081, 53.9329],
            [-128.9078, 53.7984],
            [-129.1182, 53.7173],
            [-129.1522, 53.6745],
            [-129.2318, 53.6308],
            [-129.2536, 53.5881],
            [-129.2325, 53.5183],
            [-129.2379, 53.4248],
            [-129.3426, 53.3836],
            [-129.4781, 53.4756],
            [-129.4902, 53.474],
            [-129.6892, 53.5981],
            [-129.7847, 53.7053],
            [-129.9513, 53.8155],
            [-130.0723, 53.8913],
            [-130.1081, 53.9482],
            [-130.086, 54.0233],
            [-130.1026, 54.0669],
            [-130.0526, 54.1486],
            [-130.1307, 54.1488],
            [-130.2024, 54.1883],
            [-130.2779, 54.2045],
            [-130.3615, 54.2757],
            [-130.359, 54.3235],
            [-130.4231, 54.3458],
            [-130.4609, 54.3367],
            [-130.4893, 54.43],
            [-130.4485, 54.4577],
            [-130.4375, 54.5565],
            [-130.3813, 54.5611],
            [-130.4565, 54.6144],
            [-130.4311, 54.6383],
            [-130.3415, 54.5822],
            [-130.2661, 54.4953],
            [-130.1175, 54.3963],
            [-130.1111, 54.4182],
            [-130.2296, 54.4952],
            [-130.2927, 54.5745],
            [-130.3933, 54.6323],
            [-130.3499, 54.6949],
            [-130.2292, 54.717],
            [-130.1685, 54.8167],
            [-130.1757, 54.8512],
            [-130.0571, 54.955],
            [-130.0085, 54.9771],
            [-130.0109, 55.0113],
            [-129.9541, 55.0993],
            [-129.8592, 55.1717],
            [-129.858, 55.2073],
            [-129.811, 55.2614],
            [-129.7438, 55.2815],
            [-129.7174, 55.3501],
            [-129.683, 55.3677],
            [-129.7748, 55.526],
            [-129.7759, 55.43],
            [-129.8224, 55.4094],
            [-129.839, 55.3675],
            [-129.7938, 55.3448],
            [-129.8323, 55.3134],
            [-129.8157, 55.2795],
            [-129.8926, 55.1962],
            [-129.8973, 55.1671],
            [-130.0118, 55.0748],
            [-130.0352, 55.0229],
            [-130.1547, 54.9562],
            [-130.2779, 54.8326],
            [-130.4379, 54.759],
            [-130.4734, 54.7058],
            [-130.5328, 54.7026],
            [-130.6263, 54.7381],
            [-130.6591, 54.7639],
            [-130.568, 54.7923],
            [-130.4833, 54.8359],
            [-130.2838, 54.9663],
            [-130.2005, 55.0464],
            [-130.1052, 55.1907],
            [-129.9741, 55.2849],
            [-130.0258, 55.3385],
            [-130.0385, 55.4498],
            [-130.1118, 55.5001],
            [-130.1477, 55.5907],
            [-130.1222, 55.6705],
            [-130.1717, 55.7048],
            [-130.1222, 55.8016],
            [-130.0848, 55.8117],
            [-130.0205, 55.9101],
            [-130.0022, 56.0066],
            [-130.1038, 56.122],
            [-130.2413, 56.0958],
            [-130.4198, 56.1389],
            [-130.4652, 56.2415],
            [-130.5636, 56.2501],
            [-130.6335, 56.2716],
            [-130.7813, 56.3681],
            [-131.0873, 56.4064],
            [-131.1643, 56.4473],
            [-131.4675, 56.5511],
            [-131.5819, 56.6124],
            [-131.8338, 56.5988],
            [-131.8576, 56.7018],
            [-131.902, 56.7544],
            [-131.8709, 56.8063],
            [-132.1213, 56.8669],
            [-132.0461, 57.0441],
            [-132.3695, 57.0915],
            [-132.2448, 57.2121],
            [-132.3698, 57.3515],
            [-132.5638, 57.5069],
            [-132.7597, 57.7076],
            [-132.8683, 57.8447],
            [-133.0698, 58.0001],
            [-133.1727, 58.1517],
            [-133.3544, 58.2835],
            [-133.4616, 58.39],
            [-133.3821, 58.4327],
            [-133.6998, 58.6092],
            [-133.8417, 58.7303],
            [-134.003, 58.7742],
            [-134.2569, 58.8592],
            [-134.3379, 58.9205],
            [-134.3127, 58.9616],
            [-134.4081, 58.9786],
            [-134.3859, 59.0416],
            [-134.4853, 59.1336],
            [-134.5703, 59.1337],
            [-134.6785, 59.193],
            [-134.6998, 59.2486],
            [-134.9595, 59.2813],
            [-135.0308, 59.3486],
            [-134.9898, 59.3881],
            [-135.0981, 59.4272],
            [-135.0286, 59.4765],
            [-135.0283, 59.5642],
            [-135.1182, 59.6236],
            [-135.2225, 59.6644],
            [-135.2354, 59.6962],
            [-135.3777, 59.7432],
            [-135.4797, 59.7989],
            [-135.9465, 59.6639],
            [-136.1961, 59.6391],
            [-136.3566, 59.601],
            [-136.2422, 59.5594],
            [-136.2371, 59.527],
            [-136.3023, 59.4661],
            [-136.3986, 59.4474],
            [-136.4771, 59.4645],
            [-136.4683, 59.2848],
            [-136.5829, 59.1658],
            [-136.8256, 59.1601],
            [-137.0987, 59.0568],
            [-137.2894, 58.9997],
            [-137.4476, 58.9089],
            [-137.5278, 58.9069],
            [-137.5014, 58.9804],
            [-137.6032, 59.241],
            [-138.6068, 59.7581],
            [-138.6577, 59.7996],
            [-138.7072, 59.9066],
            [-138.7923, 59.9234],
            [-139.0437, 59.992],
            [-139.0538, 60.0005],
            [-138.0013, 60.0004],
            [-136.751, 60.0003],
            [-135.5007, 60.0003],
            [-134.2505, 60.0002],
            [-133.2504, 60.0002],
            [-132.0003, 60.0002],
            [-130.7502, 60.0001],
            [-129.5001, 60.0001],
            [-127.25, 60.0001],
            [-126, 60.0001],
            [-124.75, 60.0001],
            [-123.5, 60.0001],
            [-122.2499, 60.0001],
            [-119.9998, 60.0001],
            [-119.9999, 53.8062],
            [-119.9774, 53.8054],
            [-119.983, 53.7971],
            [-119.9423, 53.7758],
            [-119.8882, 53.7795],
            [-119.8873, 53.75],
            [-119.9033, 53.7098],
            [-119.7928, 53.706],
            [-119.7133, 53.6146],
            [-119.7591, 53.5909],
            [-119.8938, 53.6195],
            [-119.9258, 53.6145],
            [-119.9303, 53.6104],
            [-119.8609, 53.5565],
            [-119.7253, 53.3887],
            [-119.6954, 53.3906],
            [-119.6688, 53.3678],
            [-119.6374, 53.369],
            [-119.624, 53.3596],
            [-119.5982, 53.3657],
            [-119.5983, 53.3835],
            [-119.5484, 53.3675],
            [-119.5174, 53.37],
            [-119.4591, 53.3572],
            [-119.406, 53.3681],
            [-119.3906, 53.3608],
            [-119.3558, 53.3401],
            [-119.3404, 53.287],
            [-119.2514, 53.1989],
            [-119.1755, 53.1846],
            [-119.1194, 53.1617],
            [-119.0884, 53.1655],
            [-119.0468, 53.1449],
            [-119.0479, 53.1268],
            [-119.0244, 53.1267],
            [-119.025, 53.1345],
            [-118.9997, 53.1485],
            [-119.0287, 53.1641],
            [-118.9993, 53.1927],
            [-119.0237, 53.2319],
            [-118.9751, 53.2416],
            [-118.9473, 53.2389],
            [-118.9158, 53.2118],
            [-118.7864, 53.1592],
            [-118.7294, 53.1154],
            [-118.7697, 53.0744],
            [-118.6406, 52.9993],
            [-118.671, 52.9782],
            [-118.6133, 52.936],
            [-118.6137, 52.8837],
            [-118.5753, 52.8852],
            [-118.5436, 52.9083],
            [-118.4999, 52.9062],
            [-118.4481, 52.8852],
            [-118.4523, 52.8553],
            [-118.4021, 52.8425],
            [-118.4222, 52.7757],
            [-118.3426, 52.7385],
            [-118.3431, 52.7088],
            [-118.2899, 52.6776],
            [-118.3543, 52.6336],
            [-118.3324, 52.5801],
            [-118.2726, 52.5659],
            [-118.289, 52.5384],
            [-118.2353, 52.4901],
            [-118.2551, 52.4495],
            [-118.2442, 52.4079],
            [-118.2207, 52.3975],
            [-118.2191, 52.3741],
            [-118.1799, 52.3725],
            [-118.1819, 52.3827],
            [-118.1378, 52.4074],
            [-118.044, 52.3984],
            [-118.0209, 52.4554],
            [-118.0131, 52.4838],
            [-117.9878, 52.5002],
            [-117.9666, 52.4696],
            [-117.8867, 52.426],
            [-117.7663, 52.4176],
            [-117.7055, 52.3652],
            [-117.7526, 52.3163],
            [-117.8393, 52.274],
            [-117.8181, 52.2263],
            [-117.7411, 52.2029],
            [-117.743, 52.1939],
            [-117.6633, 52.1978],
            [-117.6291, 52.1747],
            [-117.611, 52.1441],
            [-117.5228, 52.1581],
            [-117.5096, 52.1577],
            [-117.4999, 52.1442],
            [-117.381, 52.1376],
            [-117.3338, 52.1485],
            [-117.3269, 52.1898],
            [-117.317, 52.1939],
            [-117.2917, 52.0853],
            [-117.2333, 52.0116],
            [-117.2014, 51.9927],
            [-117.1957, 51.979],
            [-117.1446, 51.9726],
            [-117.1199, 51.9556],
            [-117.1029, 51.9564],
            [-117.0973, 51.9396],
            [-117.0105, 51.8895],
            [-117.0332, 51.8562],
            [-116.9609, 51.7907],
            [-116.976, 51.76],
            [-116.9262, 51.7289],
            [-116.9202, 51.7096],
            [-116.8956, 51.7026],
            [-116.8094, 51.7052],
            [-116.819, 51.7385],
            [-116.7486, 51.8049],
            [-116.7151, 51.7985],
            [-116.6792, 51.8129],
            [-116.6587, 51.805],
            [-116.6428, 51.7865],
            [-116.65, 51.7535],
            [-116.585, 51.7126],
            [-116.5946, 51.6617],
            [-116.4687, 51.6016],
            [-116.4644, 51.5656],
            [-116.3905, 51.5454],
            [-116.3817, 51.5308],
            [-116.395, 51.5253],
            [-116.3935, 51.5055],
            [-116.3602, 51.4707],
            [-116.2836, 51.4563],
            [-116.2821, 51.4066],
            [-116.311, 51.3848],
            [-116.293, 51.3646],
            [-116.2896, 51.3429],
            [-116.2676, 51.3117],
            [-116.2267, 51.3013],
            [-116.2186, 51.2914],
            [-116.1881, 51.3013],
            [-116.1817, 51.2952],
            [-116.1637, 51.2976],
            [-116.1511, 51.2869],
            [-116.1528, 51.2632],
            [-116.0647, 51.2477],
            [-116.0071, 51.2218],
            [-115.9995, 51.1937],
            [-116.0209, 51.1868],
            [-116.0364, 51.1698],
            [-116.0069, 51.1561],
            [-116.0151, 51.1378],
            [-116.0083, 51.1258],
            [-115.9678, 51.1219],
            [-115.9231, 51.0829],
            [-115.9034, 51.0826],
            [-115.8943, 51.0859],
            [-115.8674, 51.089],
            [-115.842, 51.0762],
            [-115.8123, 51.0862],
            [-115.8023, 51.0693],
            [-115.7758, 51.0663],
            [-115.7529, 51.0742],
            [-115.7731, 51.0575],
            [-115.7665, 51.0391],
            [-115.6278, 50.9806],
            [-115.5921, 50.8924],
            [-115.6484, 50.8739],
            [-115.6388, 50.8377],
            [-115.6231, 50.8338],
            [-115.606, 50.8454],
            [-115.5726, 50.8375],
            [-115.5603, 50.8263],
            [-115.5599, 50.8045],
            [-115.5165, 50.7799],
            [-115.4971, 50.7821],
            [-115.4806, 50.7549],
            [-115.4358, 50.7554],
            [-115.4119, 50.7333],
            [-115.4133, 50.711],
            [-115.3784, 50.705],
            [-115.354, 50.7224],
            [-115.3117, 50.7236],
            [-115.2736, 50.6486],
            [-115.2963, 50.6259],
            [-115.2391, 50.5889],
            [-115.2304, 50.5446],
            [-115.2075, 50.5275],
            [-115.1856, 50.5395],
            [-115.1893, 50.5548],
            [-115.154, 50.573],
            [-115.1169, 50.5694],
            [-115.0847, 50.5894],
            [-115.0515, 50.5711],
            [-115.0145, 50.5821],
            [-115.0106, 50.567],
            [-114.9115, 50.451],
            [-114.8592, 50.4234],
            [-114.7655, 50.3504],
            [-114.7993, 50.3294],
            [-114.7522, 50.2935],
            [-114.7695, 50.2496],
            [-114.724, 50.1898],
            [-114.7353, 50.12],
            [-114.657, 50.0293],
            [-114.6552, 49.9676],
            [-114.6942, 49.9415],
            [-114.6911, 49.8959],
            [-114.6392, 49.8273],
            [-114.6353, 49.7844],
            [-114.6584, 49.7646],
            [-114.6329, 49.7299],
            [-114.6759, 49.6957],
            [-114.6563, 49.6604],
            [-114.6971, 49.6306],
            [-114.7466, 49.619],
            [-114.7319, 49.5761],
            [-114.6951, 49.5461],
            [-114.6826, 49.5561],
            [-114.6473, 49.5425],
            [-114.6189, 49.546],
            [-114.5875, 49.5649],
            [-114.5723, 49.5565],
            [-114.572, 49.5242],
            [-114.5957, 49.5014],
            [-114.6043, 49.4385],
            [-114.5898, 49.3956],
            [-114.5726, 49.3891],
            [-114.5662, 49.3764],
            [-114.5255, 49.3746],
            [-114.524, 49.3569],
            [-114.4869, 49.3468],
            [-114.4782, 49.3263],
            [-114.4914, 49.3217],
            [-114.4913, 49.3133],
            [-114.4532, 49.2976],
            [-114.4472, 49.2639],
            [-114.3806, 49.2502],
            [-114.4019, 49.2411],
            [-114.3982, 49.2099],
            [-114.3448, 49.1931],
            [-114.3176, 49.1995],
            [-114.2865, 49.1775],
            [-114.2118, 49.1729],
            [-114.1604, 49.1543],
            [-114.1482, 49.1431],
            [-114.1653, 49.135],
            [-114.1525, 49.099],
            [-114.129, 49.0776],
            [-114.096, 49.0593],
            [-114.0805, 49.0593],
            [-114.063, 49.0449],
            [-114.0534, 49.0261],
            [-114.0684, 48.9995],
            [-115.4838, 48.9996],
            [-116.7549, 48.9997],
            [-118.1917, 48.9999],
            [-119.626, 48.9999],
            [-119.6263, 49.0554],
            [-119.6686, 49.0202],
            [-119.6868, 49.0239],
            [-119.7036, 49.0508],
            [-119.7168, 49.1352],
            [-119.7337, 49.1349],
            [-119.7262, 49.1906],
            [-119.6761, 49.2223],
            [-119.6703, 49.2993],
            [-119.7977, 49.3012],
            [-119.8102, 49.3096],
            [-119.8005, 49.314],
            [-119.8106, 49.3132],
            [-119.8179, 49.3275],
            [-119.8054, 49.3432],
            [-119.8093, 49.3767],
            [-119.8274, 49.3966],
            [-119.8172, 49.4166],
            [-119.8664, 49.4222],
            [-119.8872, 49.4147],
            [-119.9064, 49.398],
            [-119.8945, 49.377],
            [-119.9409, 49.3766],
            [-119.9412, 49.3958],
            [-119.9251, 49.408],
            [-119.8963, 49.4084],
            [-119.9079, 49.4999],
            [-119.9202, 49.5169],
            [-119.9494, 49.5287],
            [-120.0008, 49.5057],
            [-120.033, 49.5214],
            [-120.0342, 49.5395],
            [-120.022, 49.5541],
            [-120.0482, 49.5728],
            [-120.0313, 49.5946],
            [-120.0987, 49.6457],
            [-120.0597, 49.6637],
            [-120.0515, 49.6778],
            [-119.9895, 49.6645],
            [-119.9749, 49.6763],
            [-119.9906, 49.6962],
            [-119.997, 49.7108],
            [-119.9787, 49.7103],
            [-119.9843, 49.7893],
            [-119.9988, 49.8292],
            [-120.0289, 49.8618],
            [-120.0288, 49.9109],
            [-120.175, 49.8963],
            [-120.298, 49.8675],
            [-120.3654, 49.8977],
            [-120.4528, 49.8812],
            [-120.551, 49.8905],
            [-120.5632, 49.7875],
            [-120.6036, 49.7496],
            [-120.7344, 49.7497],
            [-120.7743, 49.7637],
            [-120.9473, 49.7602],
            [-120.9618, 49.6733],
            [-121.0624, 49.6265],
            [-121.121, 49.6094],
            [-121.1567, 49.6175],
            [-121.1687, 49.6349],
            [-121.1694, 49.6494],
            [-121.1183, 49.6773],
            [-121.1437, 49.6997],
            [-121.3998, 49.7015],
            [-121.42, 49.6666],
            [-121.5376, 49.6824],
            [-121.5625, 49.702],
            [-121.7013, 49.7009],
            [-121.6993, 49.7149],
            [-121.7336, 49.735],
            [-121.7088, 49.7465],
            [-121.7053, 49.791],
            [-121.7201, 49.7916],
            [-121.7318, 49.8026],
            [-121.7295, 49.8155],
            [-121.7125, 49.8231],
            [-121.729, 49.8484],
            [-121.7727, 49.8705],
            [-121.7931, 49.8404],
            [-121.8579, 49.8799],
            [-121.9091, 49.8729],
            [-121.9918, 49.8198],
            [-122.0324, 49.816],
            [-122.0468, 49.8224],
            [-122.0429, 49.8356],
            [-122.0693, 49.8315],
            [-122.1044, 49.8484],
            [-122.163, 49.9094],
            [-122.2071, 49.9123],
            [-122.2345, 49.9262],
            [-122.2391, 49.9409],
            [-122.2702, 49.9632],
            [-122.2639, 49.9751],
            [-122.2484, 49.9798],
            [-122.2605, 50.0032],
            [-122.2546, 50.0355],
            [-122.2882, 50.0518],
            [-122.2444, 50.0942],
            [-122.2212, 50.1194],
            [-122.2584, 50.1237],
            [-122.2983, 50.174],
            [-122.3136, 50.1725],
            [-122.3163, 50.1873],
            [-122.3032, 50.1988],
            [-122.278, 50.2035],
            [-122.2678, 50.2226],
            [-122.3016, 50.2294],
            [-122.303, 50.249],
            [-122.2628, 50.2587],
            [-122.2486, 50.2964],
            [-122.2009, 50.3379],
            [-122.1448, 50.3603],
            [-122.2049, 50.3733],
            [-122.248, 50.4256],
            [-122.2473, 50.4599],
            [-122.1731, 50.5077],
            [-122.1556, 50.5317],
            [-122.1137, 50.5402],
            [-122.0874, 50.5804],
            [-122.1042, 50.6142],
            [-122.0676, 50.6463],
            [-122.0266, 50.6421],
            [-121.9954, 50.6583],
            [-121.968, 50.6964],
            [-121.951, 50.6944],
            [-121.9283, 50.7044],
            [-121.9347, 50.743],
            [-121.99, 50.7615],
            [-122.0236, 50.7937],
            [-122.043, 50.7938],
            [-122.0896, 50.7868],
            [-122.1362, 50.7982],
            [-122.1622, 50.8191],
            [-122.192, 50.86],
            [-122.1762, 50.8177],
            [-122.2211, 50.79],
            [-122.2571, 50.7672],
            [-122.2623, 50.7389],
            [-122.3911, 50.7545],
            [-122.4884, 50.7396],
            [-122.5876, 50.7018],
            [-122.5817, 50.656],
            [-122.6072, 50.6427],
            [-122.6105, 50.6353],
            [-122.6279, 50.6523],
            [-122.7022, 50.6431],
            [-122.778, 50.6651],
            [-122.8312, 50.6459],
            [-122.8639, 50.6167],
            [-122.9274, 50.6008],
            [-122.9409, 50.5524],
            [-122.9901, 50.5688],
            [-123.0193, 50.6009],
            [-123.0321, 50.5839],
            [-123.0818, 50.5938],
            [-123.1218, 50.6289],
            [-123.1674, 50.6365],
            [-123.2356, 50.7228],
            [-123.2745, 50.7325],
            [-123.321, 50.7311],
            [-123.3578, 50.761],
            [-123.4071, 50.7804],
            [-123.4537, 50.798],
            [-123.4963, 50.7789],
            [-123.5491, 50.8003],
            [-123.6122, 50.7761],
            [-123.6394, 50.795],
            [-123.6782, 50.8009],
            [-123.7059, 50.7922],
            [-123.7288, 50.8427],
            [-123.7133, 50.8486],
            [-123.7651, 50.9106],
            [-123.8741, 50.9504],
            [-123.9358, 50.9358],
            [-124.0014, 50.9643],
            [-124.0413, 50.9421],
            [-124.0899, 50.9911],
            [-124.1132, 51.0143],
            [-124.1596, 51.0417],
            [-124.1594, 51.0772],
            [-124.1753, 51.1316],
            [-124.245, 51.1481],
            [-124.2972, 51.137],
            [-124.376, 51.1423],
            [-124.3691, 51.1998],
            [-124.43, 51.1686],
            [-124.434, 51.1481],
            [-124.5811, 51.1808],
            [-124.6771, 51.219],
            [-124.7624, 51.238],
            [-124.7827, 51.2889],
            [-124.8794, 51.3207],
            [-124.9656, 51.328],
            [-125.0093, 51.3495],
            [-125.0759, 51.3523],
            [-125.1708, 51.3767],
            [-125.1855, 51.4],
            [-125.2511, 51.3935],
            [-125.3267, 51.3956],
            [-125.3415, 51.4261],
            [-125.2897, 51.4754],
            [-125.2891, 51.5046],
            [-125.2419, 51.5179],
            [-125.238, 51.5595],
            [-125.2961, 51.5892],
            [-125.2921, 51.6091],
            [-125.2291, 51.6441],
            [-125.2484, 51.6755],
            [-125.2565, 51.723],
            [-125.2377, 51.7483],
            [-125.2743, 51.7721],
            [-125.3539, 51.7906],
            [-125.5134, 51.8679],
            [-125.4872, 51.9155],
            [-125.5354, 51.9123],
            [-125.5681, 51.9158],
            [-125.5844, 51.9026],
            [-125.6114, 51.9029],
            [-125.6564, 51.9237],
            [-125.6954, 51.9646],
            [-125.7148, 52.004],
            [-125.7392, 52.0119],
            [-125.7771, 52.0001],
            [-126.2103, 52.0031],
            [-126.2252, 52.0394],
            [-126.2743, 52.0716],
            [-126.2026, 52.1211],
            [-126.2538, 52.1481],
            [-126.2614, 52.1818],
            [-126.3018, 52.1888],
            [-126.3396, 52.1837],
            [-126.3858, 52.2186],
            [-126.4777, 52.2145],
            [-126.5245, 52.2761],
            [-126.5559, 52.283],
            [-126.6506, 52.2449],
            [-126.7689, 52.2687],
            [-126.7712, 52.2326],
            [-126.8122, 52.2236],
            [-126.8715, 52.2497],
            [-126.9015, 52.2838],
            [-126.8783, 52.3628],
            [-126.8789, 52.3806],
            [-126.8682, 52.4933],
            [-126.8271, 52.4844],
            [-126.7394, 52.5842],
            [-126.6986, 52.592],
            [-126.6759, 52.5953],
            [-126.6445, 52.5792],
            [-126.525, 52.6176],
            [-126.4937, 52.6492],
            [-126.4166, 52.6613],
            [-126.4112, 52.6885],
            [-126.3455, 52.7014],
            [-126.3395, 52.828],
            [-126.4196, 52.7928],
            [-126.5204, 52.7731],
            [-126.6033, 52.8121],
            [-126.641, 52.8452],
            [-126.5888, 52.8787],
            [-126.599, 52.9129],
            [-126.6469, 52.9415],
            [-126.6243, 52.9712],
            [-126.65, 53.0321],
            [-126.7242, 52.9923],
            [-126.7899, 53.0121],
            [-127.1393, 53.0137],
            [-127.1393, 52.9461],
            [-127.1337, 52.9185],
            [-127.0976, 52.8995],
            [-127.0748, 52.8653],
            [-126.9958, 52.8136],
            [-127.0027, 52.7637],
            [-126.9626, 52.7163],
            [-127.0321, 52.6645],
            [-127.0385, 52.6422],
            [-127.1108, 52.6221],
            [-127.1313, 52.5947],
            [-127.2171, 52.5676],
            [-127.2692, 52.5206],
            [-127.261, 52.4758],
            [-127.3448, 52.4179],
            [-127.4285, 52.4268],
            [-127.4549, 52.407],
            [-127.4895, 52.3378],
            [-127.5991, 52.3198],
            [-127.6247, 52.285],
            [-127.6719, 52.2939],
            [-127.7191, 52.2715],
            [-127.7852, 52.2683],
            [-127.8567, 52.2057],
            [-127.8978, 52.2228],
            [-127.9011, 52.2583],
            [-127.9444, 52.3238],
            [-128.0263, 52.319],
            [-128.0841, 52.4314],
            [-128.0706, 52.5109],
            [-128.1494, 52.4311],
            [-128.1506, 52.41],
            [-128.2872, 52.2607],
            [-128.3563, 52.267],
            [-128.3685, 52.2918],
            [-128.334, 52.3333],
            [-128.3398, 52.3717],
            [-128.3068, 52.401],
            [-128.251, 52.4092],
            [-128.2232, 52.4847],
            [-128.2392, 52.5023],
            [-128.2312, 52.5697],
            [-128.1802, 52.6291],
            [-128.1699, 52.6769],
            [-128.1336, 52.7127],
            [-128.1222, 52.8325],
            [-128.1603, 52.8578],
            [-128.2411, 52.7993],
            [-128.3343, 52.7987],
            [-128.4002, 52.8238],
            [-128.4324, 52.8142],
            [-128.5, 52.8834],
            [-128.4975, 52.9276],
            [-128.5213, 53.0131],
            [-128.5951, 53.1521],
            [-128.6437, 53.1836],
            [-128.6965, 53.1896],
            [-128.8484, 53.2683],
            [-128.8904, 53.312],
            [-128.8822, 53.4012],
            [-128.9758, 53.4939],
            [-128.9605, 53.5345],
            [-128.8702, 53.5292],
            [-128.8176, 53.5689],
            [-128.7783, 53.5246],
            [-128.7692, 53.4857],
            [-128.6758, 53.4814],
            [-128.5732, 53.4165],
            [-128.5538, 53.449],
            [-128.6458, 53.5022],
            [-128.6879, 53.502],
            [-128.734, 53.5532],
            [-128.8162, 53.6075],
            [-128.8363, 53.6459],
            [-128.7788, 53.7142],
            [-128.8077, 53.7701],
            [-128.7058, 53.8225],
            [-128.6786, 53.8528],
            [-128.7157, 53.9009],
            [-128.6544, 53.9567],
            [-128.6535, 53.9949],
            [-128.5377, 53.9942],
            [-128.5374, 54.1073],
            [-128.5993, 54.1073],
            [-128.586, 54.1459],
          ],
          [
            [-128.586, 54.1459],
            [-128.5206, 54.2525],
            [-128.5361, 54.2957],
            [-128.6031, 54.2915],
            [-128.5658, 54.2476],
            [-128.6436, 54.151],
            [-128.586, 54.1459],
          ],
        ],
        [
          [
            [-128.1892, 52.0603],
            [-128.1842, 52.0098],
            [-128.2377, 52.0143],
            [-128.2232, 52.0571],
            [-128.282, 52.1023],
            [-128.2401, 52.1821],
            [-128.1429, 52.1862],
            [-128.1341, 52.0861],
            [-128.1892, 52.0603],
          ],
        ],
        [
          [
            [-128.3816, 52.1753],
            [-128.3816, 52.1757],
            [-128.3732, 52.2364],
            [-128.2991, 52.211],
            [-128.3816, 52.1753],
          ],
        ],
        [
          [
            [-128.63, 52.6403],
            [-128.6393, 52.6125],
            [-128.7591, 52.6049],
            [-128.7409, 52.7022],
            [-128.8268, 52.679],
            [-128.8489, 52.6545],
            [-128.927, 52.6632],
            [-129.0631, 52.7393],
            [-129.1039, 52.7847],
            [-129.1361, 52.8472],
            [-129.1153, 52.8988],
            [-129.1982, 52.9524],
            [-129.1979, 53.0215],
            [-129.1731, 53.0518],
            [-129.0827, 53.0975],
            [-129.0972, 53.1854],
            [-129.0528, 53.212],
            [-129.0958, 53.2871],
            [-129.036, 53.3077],
            [-128.9688, 53.2842],
            [-128.9076, 53.2984],
            [-128.871, 53.2592],
            [-128.7155, 53.1771],
            [-128.6311, 53.1521],
            [-128.5832, 53.1035],
            [-128.5394, 53.0263],
            [-128.523, 52.9657],
            [-128.5227, 52.9002],
            [-128.5421, 52.8771],
            [-128.5447, 52.8021],
            [-128.57, 52.6866],
            [-128.63, 52.6403],
          ],
        ],
        [
          [
            [-128.8252, 52.5423],
            [-128.7554, 52.5593],
            [-128.7538, 52.593],
            [-128.6672, 52.6075],
            [-128.5819, 52.5992],
            [-128.5457, 52.6757],
            [-128.5179, 52.5716],
            [-128.4669, 52.477],
            [-128.4993, 52.4309],
            [-128.6256, 52.4683],
            [-128.6814, 52.5071],
            [-128.7345, 52.4947],
            [-128.8275, 52.5144],
            [-128.8252, 52.5423],
          ],
        ],
        [
          [
            [-128.3816, 52.1757],
            [-128.3816, 52.1753],
            [-128.3955, 52.1605],
            [-128.5016, 52.1571],
            [-128.4371, 52.2372],
            [-128.378, 52.2373],
            [-128.3816, 52.1757],
          ],
        ],
        [
          [
            [-129.7996, 53.4508],
            [-129.9159, 53.4632],
            [-129.8766, 53.518],
            [-129.8942, 53.5452],
            [-129.9223, 53.5549],
            [-130.0065, 53.5656],
            [-130.0619, 53.5989],
            [-130.0594, 53.6257],
            [-130.0993, 53.666],
            [-130.1011, 53.6965],
            [-130.205, 53.7219],
            [-130.2189, 53.8087],
            [-130.2897, 53.8252],
            [-130.2863, 53.8688],
            [-130.2078, 53.9112],
            [-130.1121, 53.8831],
            [-130.0316, 53.8377],
            [-129.9287, 53.7618],
            [-129.8445, 53.7226],
            [-129.7418, 53.6399],
            [-129.7048, 53.5979],
            [-129.4197, 53.4133],
            [-129.4747, 53.371],
            [-129.465, 53.3095],
            [-129.5121, 53.2577],
            [-129.4887, 53.2288],
            [-129.589, 53.2114],
            [-129.6663, 53.2907],
            [-129.7793, 53.3671],
            [-129.8323, 53.3871],
            [-129.847, 53.4291],
            [-129.7996, 53.4508],
          ],
        ],
        [
          [
            [-129.5133, 53.1161],
            [-129.5601, 53.1705],
            [-129.4866, 53.19],
            [-129.4181, 53.1261],
            [-129.3961, 53.0894],
            [-129.3162, 53.0464],
            [-129.3027, 52.9668],
            [-129.3783, 52.9799],
            [-129.4314, 53.0124],
            [-129.4609, 53.0822],
            [-129.5133, 53.1161],
          ],
        ],
        [
          [
            [-128.1892, 51.9102],
            [-128.2049, 51.9623],
            [-128.1567, 52.0388],
            [-128.121, 52.0626],
            [-127.9876, 52.0749],
            [-127.9516, 52.0637],
            [-127.9464, 52.0029],
            [-127.9688, 51.9272],
            [-128.0062, 51.8951],
            [-128.0151, 51.843],
            [-128.0596, 51.8186],
            [-128.1332, 51.8183],
            [-128.1186, 51.8751],
            [-128.2319, 51.8624],
            [-128.1892, 51.9102],
          ],
        ],
        [
          [
            [-130.0266, 53.5074],
            [-129.9716, 53.5158],
            [-129.9437, 53.5465],
            [-129.894, 53.5401],
            [-129.8994, 53.5127],
            [-129.9304, 53.4688],
            [-130.0266, 53.5074],
          ],
        ],
        [
          [
            [-128.7333, 52.3151],
            [-128.735, 52.3517],
            [-128.7704, 52.4148],
            [-128.7672, 52.4725],
            [-128.6254, 52.4563],
            [-128.6313, 52.4148],
            [-128.6165, 52.3322],
            [-128.6305, 52.3084],
            [-128.691, 52.2866],
            [-128.7333, 52.3151],
          ],
        ],
        [
          [
            [-128.0864, 51.6618],
            [-128.0608, 51.7103],
            [-127.9964, 51.7334],
            [-127.9617, 51.6563],
            [-128.0864, 51.6618],
          ],
        ],
        [
          [
            [-130.7719, 54.1544],
            [-130.8216, 54.1758],
            [-130.7541, 54.2076],
            [-130.6642, 54.1591],
            [-130.6557, 54.1091],
            [-130.7445, 54.1216],
            [-130.7719, 54.1544],
          ],
        ],
        [
          [
            [-129.2106, 52.628],
            [-129.2419, 52.6953],
            [-129.2807, 52.7183],
            [-129.3047, 52.7623],
            [-129.2157, 52.8033],
            [-129.1759, 52.7672],
            [-129.1124, 52.7428],
            [-129.0651, 52.6937],
            [-129.0052, 52.6694],
            [-128.9269, 52.6061],
            [-128.9434, 52.4768],
            [-129.0658, 52.5227],
            [-129.1389, 52.5613],
            [-129.1559, 52.5995],
            [-129.2106, 52.628],
          ],
        ],
        [
          [
            [-130.4091, 53.676],
            [-130.3292, 53.7407],
            [-130.3117, 53.796],
            [-130.242, 53.7928],
            [-130.2303, 53.7255],
            [-130.1219, 53.6949],
            [-130.1287, 53.6701],
            [-130.1022, 53.5835],
            [-130.154, 53.5683],
            [-130.2279, 53.6005],
            [-130.3315, 53.6183],
            [-130.4091, 53.676],
          ],
        ],
        [
          [
            [-129.7359, 53.079],
            [-129.7513, 53.1322],
            [-129.6826, 53.1346],
            [-129.5948, 53.0696],
            [-129.6533, 53.0626],
            [-129.7359, 53.079],
          ],
        ],
        [
          [
            [-128.0929, 51.5065],
            [-128.1187, 51.5738],
            [-128.1508, 51.5985],
            [-128.1387, 51.6528],
            [-127.9562, 51.6466],
            [-127.9153, 51.6086],
            [-127.9079, 51.5345],
            [-127.8756, 51.4625],
            [-127.905, 51.417],
            [-128.0878, 51.4808],
            [-128.0929, 51.5065],
          ],
        ],
        [
          [
            [-129.6827, 52.9959],
            [-129.6424, 53.0205],
            [-129.5396, 52.971],
            [-129.5464, 52.952],
            [-129.6827, 52.9959],
          ],
        ],
        [
          [
            [-130.6411, 53.8109],
            [-130.5647, 53.8501],
            [-130.5003, 53.8202],
            [-130.5432, 53.7887],
            [-130.6411, 53.8109],
          ],
        ],
        [
          [
            [-130.7001, 53.8524],
            [-130.7317, 53.9091],
            [-130.5998, 54.0349],
            [-130.5549, 54.0571],
            [-130.4457, 54.0842],
            [-130.3819, 54.09],
            [-130.3343, 54.0672],
            [-130.241, 53.9863],
            [-130.2774, 53.9031],
            [-130.334, 53.8792],
            [-130.3423, 53.8284],
            [-130.3986, 53.8235],
            [-130.4837, 53.8477],
            [-130.5557, 53.8964],
            [-130.5792, 53.9283],
            [-130.6886, 53.9442],
            [-130.7001, 53.8524],
          ],
        ],
        [
          [
            [-130.1905, 53.3568],
            [-130.2904, 53.3799],
            [-130.4059, 53.4576],
            [-130.412, 53.4825],
            [-130.5464, 53.5568],
            [-130.5403, 53.6271],
            [-130.469, 53.6376],
            [-130.2078, 53.5558],
            [-130.1069, 53.5134],
            [-129.9937, 53.4529],
            [-129.9123, 53.3969],
            [-129.8668, 53.329],
            [-129.8031, 53.2909],
            [-129.7949, 53.2547],
            [-129.7538, 53.2145],
            [-129.7834, 53.1652],
            [-129.8714, 53.1873],
            [-129.9085, 53.1713],
            [-129.962, 53.1828],
            [-130.0215, 53.2518],
            [-130.0629, 53.2685],
            [-130.1231, 53.3192],
            [-130.1905, 53.3568],
          ],
        ],
        [
          [
            [-133.082, 54.2257],
            [-133.0558, 54.2579],
            [-132.9797, 54.2504],
            [-133.0008, 54.2123],
            [-133.082, 54.2257],
          ],
        ],
        [
          [
            [-131.8494, 52.8906],
            [-131.8864, 52.9219],
            [-131.9322, 53.0148],
            [-131.8778, 53.0378],
            [-131.8048, 53.0354],
            [-131.7545, 53.0104],
            [-131.6652, 52.9995],
            [-131.605, 52.9645],
            [-131.6705, 52.8785],
            [-131.7827, 52.8803],
            [-131.8494, 52.8906],
          ],
        ],
        [
          [
            [-131.7579, 52.7683],
            [-131.6404, 52.778],
            [-131.6096, 52.7514],
            [-131.7118, 52.746],
            [-131.7579, 52.7683],
          ],
        ],
        [
          [
            [-131.8426, 52.8305],
            [-131.7568, 52.8606],
            [-131.6454, 52.8341],
            [-131.7399, 52.8073],
            [-131.8426, 52.8305],
          ],
        ],
        [
          [
            [-131.634, 52.6112],
            [-131.6401, 52.65],
            [-131.7296, 52.7075],
            [-131.6906, 52.7331],
            [-131.5336, 52.7245],
            [-131.4952, 52.7385],
            [-131.4417, 52.7074],
            [-131.4636, 52.6732],
            [-131.4648, 52.6236],
            [-131.5203, 52.6168],
            [-131.6089, 52.5843],
            [-131.634, 52.6112],
          ],
        ],
        [
          [
            [-131.3348, 52.3415],
            [-131.3708, 52.4184],
            [-131.2844, 52.4493],
            [-131.2427, 52.4231],
            [-131.2922, 52.3948],
            [-131.2829, 52.364],
            [-131.3348, 52.3415],
          ],
        ],
        [
          [
            [-131.1096, 52.0018],
            [-131.1165, 52.1511],
            [-131.0501, 52.132],
            [-130.9409, 52.0767],
            [-131.0552, 52.0378],
            [-131.0331, 52],
            [-131.1096, 52.0018],
          ],
        ],
        [
          [
            [-132.3794, 53.0055],
            [-132.2915, 53.0057],
            [-132.1891, 52.9789],
            [-132.3247, 52.9626],
            [-132.3794, 53.0055],
          ],
        ],
        [
          [
            [-133.1141, 53.9457],
            [-133.1158, 54.0015],
            [-133.0435, 54.03],
            [-133.0672, 54.0552],
            [-133.0833, 54.1269],
            [-133.0262, 54.1727],
            [-132.9428, 54.1702],
            [-132.9166, 54.1483],
            [-132.7977, 54.1249],
            [-132.664, 54.1542],
            [-132.5811, 54.121],
            [-132.5643, 54.0532],
            [-132.4558, 54.0741],
            [-132.433, 54.101],
            [-132.3119, 54.1144],
            [-132.241, 54.0755],
            [-132.2458, 54.0325],
            [-132.1743, 54.0152],
            [-132.1212, 54.0302],
            [-131.9956, 54.0288],
            [-131.7913, 54.0714],
            [-131.7112, 54.1153],
            [-131.651, 54.1788],
            [-131.7059, 53.9788],
            [-131.7551, 53.9154],
            [-131.8624, 53.8075],
            [-131.8838, 53.7695],
            [-131.8724, 53.7106],
            [-131.9327, 53.6182],
            [-131.9257, 53.5624],
            [-131.9571, 53.5192],
            [-131.9107, 53.4342],
            [-131.926, 53.3574],
            [-131.9685, 53.2823],
            [-132.0156, 53.244],
            [-132.0905, 53.2555],
            [-132.1803, 53.2434],
            [-132.2429, 53.2208],
            [-132.2297, 53.189],
            [-132.1311, 53.1802],
            [-132.236, 53.1467],
            [-132.305, 53.1578],
            [-132.3606, 53.154],
            [-132.4008, 53.1379],
            [-132.4717, 53.1354],
            [-132.5639, 53.1768],
            [-132.5943, 53.2296],
            [-132.6827, 53.2604],
            [-132.6284, 53.2907],
            [-132.634, 53.3251],
            [-132.764, 53.3284],
            [-132.6671, 53.3703],
            [-132.538, 53.3657],
            [-132.4794, 53.321],
            [-132.4359, 53.3489],
            [-132.5237, 53.3794],
            [-132.5205, 53.4039],
            [-132.5864, 53.4247],
            [-132.6447, 53.4255],
            [-132.6964, 53.4457],
            [-132.8315, 53.4683],
            [-132.8992, 53.4677],
            [-132.892, 53.4982],
            [-132.9373, 53.5172],
            [-132.9795, 53.5943],
            [-132.9317, 53.6247],
            [-132.9562, 53.6633],
            [-133.0076, 53.6696],
            [-132.9986, 53.7505],
            [-133.1165, 53.7772],
            [-133.0788, 53.8265],
            [-133.1371, 53.8658],
            [-133.1522, 53.9149],
            [-133.1141, 53.9457],
          ],
        ],
        [
          [
            [-132.3956, 53.1102],
            [-132.3957, 53.1085],
            [-132.5441, 53.1063],
            [-132.5921, 53.1247],
            [-132.5835, 53.1528],
            [-132.4928, 53.1257],
            [-132.403, 53.1281],
            [-132.3956, 53.1102],
          ],
        ],
        [
          [
            [-132.2222, 52.8686],
            [-132.2608, 52.8637],
            [-132.3243, 52.892],
            [-132.3642, 52.927],
            [-132.3473, 52.9501],
            [-132.2461, 52.9562],
            [-132.1554, 52.9771],
            [-132.256, 53.0184],
            [-132.407, 53.026],
            [-132.4378, 53.0456],
            [-132.4962, 53.0284],
            [-132.5202, 53.0647],
            [-132.5744, 53.0983],
            [-132.4554, 53.0963],
            [-132.3957, 53.1085],
            [-132.3956, 53.1102],
            [-132.3512, 53.1491],
            [-132.2989, 53.1538],
            [-132.2332, 53.1401],
            [-132.1333, 53.1619],
            [-132.0806, 53.1495],
            [-132.0148, 53.2005],
            [-131.9813, 53.2015],
            [-131.9198, 53.2406],
            [-131.8056, 53.2337],
            [-131.7779, 53.1727],
            [-131.71, 53.1499],
            [-131.6924, 53.1126],
            [-131.6458, 53.11],
            [-131.6305, 53.0456],
            [-131.6922, 53.041],
            [-131.8017, 53.0676],
            [-131.8823, 53.0642],
            [-131.9553, 53.0372],
            [-131.9619, 53.0131],
            [-131.9133, 52.9689],
            [-131.9112, 52.8955],
            [-131.8584, 52.8732],
            [-131.8393, 52.8094],
            [-131.7907, 52.7741],
            [-131.8057, 52.7182],
            [-131.74, 52.6919],
            [-131.6881, 52.6214],
            [-131.6533, 52.5409],
            [-131.6082, 52.5167],
            [-131.575, 52.5365],
            [-131.4725, 52.5032],
            [-131.4271, 52.4069],
            [-131.3627, 52.3752],
            [-131.352, 52.3241],
            [-131.2664, 52.2862],
            [-131.1744, 52.3253],
            [-131.1672, 52.296],
            [-131.0922, 52.2895],
            [-131.0793, 52.215],
            [-131.0263, 52.2312],
            [-131.0147, 52.1808],
            [-131.0459, 52.1631],
            [-131.1206, 52.1659],
            [-131.1832, 52.1184],
            [-131.2326, 52.1581],
            [-131.2619, 52.1191],
            [-131.3249, 52.1942],
            [-131.3914, 52.1953],
            [-131.4297, 52.2217],
            [-131.5584, 52.3503],
            [-131.5987, 52.3776],
            [-131.6097, 52.4138],
            [-131.6699, 52.4764],
            [-131.7704, 52.5034],
            [-131.9066, 52.5869],
            [-131.9424, 52.6428],
            [-131.9772, 52.6461],
            [-132.0693, 52.6988],
            [-132.1086, 52.7342],
            [-132.0245, 52.7718],
            [-132.0954, 52.7873],
            [-132.1392, 52.7458],
            [-132.1713, 52.7845],
            [-132.2347, 52.8],
            [-132.2653, 52.8395],
            [-132.2222, 52.8686],
          ],
        ],
        [
          [
            [-126.6717, 52.0043],
            [-126.661, 52.0199],
            [-126.6159, 52.0155],
            [-126.5637, 52.0042],
            [-126.6717, 52.0043],
          ],
        ],
        [
          [
            [-130.3717, 54.7197],
            [-130.2007, 54.783],
            [-130.2319, 54.7231],
            [-130.3001, 54.7083],
            [-130.3717, 54.7197],
          ],
        ],
        [
          [
            [-128.3107, 52.7104],
            [-128.3192, 52.7192],
            [-128.3311, 52.773],
            [-128.2169, 52.7925],
            [-128.1457, 52.8444],
            [-128.154, 52.7704],
            [-128.2087, 52.6883],
            [-128.2464, 52.6025],
            [-128.2911, 52.5902],
            [-128.2725, 52.6712],
            [-128.3107, 52.7104],
          ],
        ],
        [
          [
            [-128.8863, 53.5959],
            [-128.889, 53.5915],
            [-128.8942, 53.5582],
            [-128.9685, 53.5731],
            [-129.0253, 53.5566],
            [-129.1039, 53.4925],
            [-129.0831, 53.4478],
            [-129.1487, 53.4154],
            [-129.1729, 53.4465],
            [-129.1682, 53.5517],
            [-129.1768, 53.6262],
            [-129.0872, 53.6733],
            [-128.9781, 53.7055],
            [-128.9336, 53.6942],
            [-128.876, 53.7167],
            [-128.8362, 53.688],
            [-128.8884, 53.6562],
            [-128.8863, 53.5959],
          ],
        ],
        [
          [
            [-129.1528, 53.3378],
            [-129.1116, 53.4038],
            [-129.0537, 53.4485],
            [-129.0643, 53.4952],
            [-129.0119, 53.5329],
            [-128.9823, 53.4561],
            [-128.9204, 53.3962],
            [-128.9287, 53.3267],
            [-129.0586, 53.3325],
            [-129.1122, 53.3123],
            [-129.1528, 53.3378],
          ],
        ],
        [
          [
            [-128.4992, 52.8732],
            [-128.4524, 52.8066],
            [-128.4811, 52.7683],
            [-128.4768, 52.7011],
            [-128.5261, 52.6457],
            [-128.5441, 52.7108],
            [-128.4992, 52.8732],
          ],
        ],
        [
          [
            [-128.3192, 52.7192],
            [-128.3107, 52.7104],
            [-128.291, 52.6828],
            [-128.3035, 52.6352],
            [-128.2848, 52.5399],
            [-128.2938, 52.5234],
            [-128.4415, 52.5478],
            [-128.4495, 52.6322],
            [-128.4235, 52.7389],
            [-128.451, 52.753],
            [-128.4109, 52.7907],
            [-128.3524, 52.7857],
            [-128.3496, 52.732],
            [-128.3192, 52.7192],
          ],
        ],
        [
          [
            [-127.9433, 52.1623],
            [-127.9482, 52.163],
            [-128.0389, 52.1752],
            [-128.0953, 52.2026],
            [-128.0201, 52.2535],
            [-127.9671, 52.3042],
            [-127.9174, 52.2522],
            [-127.9055, 52.1962],
            [-127.9433, 52.1623],
          ],
        ],
        [
          [
            [-128.2011, 52.2871],
            [-128.1697, 52.3007],
            [-128.1546, 52.369],
            [-128.1169, 52.4075],
            [-128.0791, 52.4138],
            [-128.0481, 52.3198],
            [-128.1271, 52.2835],
            [-128.2011, 52.2871],
          ],
        ],
        [
          [
            [-128.1764, 52.2483],
            [-128.0457, 52.3097],
            [-128.0542, 52.2529],
            [-128.1194, 52.2257],
            [-128.1764, 52.2483],
          ],
        ],
        [
          [
            [-128.4196, 52.511],
            [-128.301, 52.5241],
            [-128.2832, 52.487],
            [-128.4206, 52.464],
            [-128.4196, 52.511],
          ],
        ],
        [
          [
            [-130.2815, 54.1475],
            [-130.2591, 54.1951],
            [-130.1537, 54.1509],
            [-130.1715, 54.126],
            [-130.2448, 54.1178],
            [-130.2815, 54.1475],
          ],
        ],
        [
          [
            [-127.8786, 51.967],
            [-127.8999, 52],
            [-127.6924, 52],
            [-127.7123, 51.9651],
            [-127.8178, 51.9595],
            [-127.8786, 51.967],
          ],
        ],
        [
          [
            [-130.4592, 54.2856],
            [-130.4471, 54.3294],
            [-130.3889, 54.3079],
            [-130.3627, 54.2565],
            [-130.4592, 54.2856],
          ],
        ],
        [
          [
            [-127.9482, 52.163],
            [-127.9433, 52.1623],
            [-127.8833, 52.1847],
            [-127.8905, 52.1263],
            [-127.9423, 52.0761],
            [-128.056, 52.0803],
            [-128.1138, 52.0988],
            [-128.1342, 52.1477],
            [-128.0078, 52.1707],
            [-127.9482, 52.163],
          ],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "Ft Wayne Mkt": {
    type: "Feature",
    properties: { code: "IN_FTW", name: "Ft Wayne Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-86.6407, 41.1722],
          [-86.5632, 41.201],
          [-86.467, 41.1934],
          [-86.4671, 41.1631],
          [-86.3713, 41.149],
          [-86.357, 41.1721],
          [-86.1398, 41.1724],
          [-86.0918, 41.1576],
          [-86.0909, 41.1212],
          [-86.0381, 41.1147],
          [-85.9456, 41.0864],
          [-85.9354, 41.1099],
          [-85.8161, 41.0871],
          [-85.8153, 41.0582],
          [-85.7236, 41.0643],
          [-85.7052, 41.1397],
          [-85.6668, 41.14],
          [-85.6527, 41.1785],
          [-85.6514, 41.2729],
          [-85.6334, 41.3078],
          [-85.6529, 41.3337],
          [-85.6549, 41.5282],
          [-85.4623, 41.5254],
          [-85.4623, 41.5622],
          [-85.501, 41.5688],
          [-85.5207, 41.6001],
          [-85.5147, 41.6848],
          [-85.4856, 41.7322],
          [-85.5435, 41.7594],
          [-84.9423, 41.7596],
          [-84.8059, 41.7602],
          [-84.8061, 41.6961],
          [-84.8039, 41.4355],
          [-84.8033, 40.9892],
          [-84.8022, 40.5866],
          [-84.8396, 40.5721],
          [-85.1262, 40.5675],
          [-85.1835, 40.5818],
          [-85.2737, 40.5671],
          [-85.2781, 40.5961],
          [-85.4098, 40.5955],
          [-85.41, 40.5524],
          [-85.4465, 40.5382],
          [-85.4443, 40.3791],
          [-85.7422, 40.3789],
          [-85.7861, 40.3717],
          [-85.8622, 40.4069],
          [-86.203, 40.4032],
          [-86.243, 40.3522],
          [-86.3093, 40.3884],
          [-86.3095, 40.4317],
          [-86.3758, 40.4319],
          [-86.4114, 40.4605],
          [-86.4495, 40.4458],
          [-86.5127, 40.4458],
          [-86.5729, 40.4315],
          [-86.5833, 40.4675],
          [-86.6949, 40.4616],
          [-86.6954, 40.5619],
          [-86.7526, 40.562],
          [-86.7709, 40.5877],
          [-86.7355, 40.6747],
          [-86.6875, 40.7223],
          [-86.6205, 40.7073],
          [-86.6269, 40.673],
          [-86.5321, 40.7157],
          [-86.5817, 40.7358],
          [-86.5809, 40.8686],
          [-86.6394, 40.8905],
          [-86.6397, 40.9118],
          [-86.776, 40.9125],
          [-86.7768, 41.0124],
          [-86.758, 41.0418],
          [-86.7571, 41.0996],
          [-86.7763, 41.1286],
          [-86.7758, 41.1717],
          [-86.6407, 41.1722],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Gary Mkt": {
    type: "Feature",
    properties: { code: "IN_GRY", name: "Gary Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-86.8248, 41.7602],
          [-86.5698, 41.7595],
          [-86.5654, 41.6652],
          [-86.4876, 41.6493],
          [-86.4866, 41.5959],
          [-86.6031, 41.5558],
          [-86.5823, 41.4945],
          [-86.6853, 41.4912],
          [-86.6594, 41.4244],
          [-86.7008, 41.4025],
          [-86.7575, 41.2952],
          [-86.7178, 41.2882],
          [-86.6886, 41.2155],
          [-86.6407, 41.2012],
          [-86.6407, 41.1722],
          [-86.7758, 41.1717],
          [-86.7763, 41.1286],
          [-86.8437, 41.143],
          [-86.9206, 41.1362],
          [-86.9309, 41.0856],
          [-86.9982, 41.0856],
          [-87.0073, 41.1146],
          [-87.0843, 41.1151],
          [-87.1222, 41.1441],
          [-87.1989, 41.1444],
          [-87.2181, 41.1155],
          [-87.2653, 41.1039],
          [-87.3142, 41.1122],
          [-87.3143, 41.0603],
          [-87.4455, 41.0573],
          [-87.4685, 41.0429],
          [-87.5266, 41.0576],
          [-87.5268, 41.2982],
          [-87.5247, 41.6306],
          [-87.5249, 41.7026],
          [-87.4324, 41.6605],
          [-87.4234, 41.6428],
          [-87.2784, 41.6197],
          [-87.1203, 41.6457],
          [-86.9348, 41.7096],
          [-86.8248, 41.7602],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Indianapolis Mkt": {
    type: "Feature",
    properties: { code: "IN_IND", name: "Indianapolis Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-86.7763, 41.1286],
          [-86.7571, 41.0996],
          [-86.758, 41.0418],
          [-86.7768, 41.0124],
          [-86.776, 40.9125],
          [-86.6397, 40.9118],
          [-86.6394, 40.8905],
          [-86.5809, 40.8686],
          [-86.5817, 40.7358],
          [-86.5321, 40.7157],
          [-86.6269, 40.673],
          [-86.6205, 40.7073],
          [-86.6875, 40.7223],
          [-86.7355, 40.6747],
          [-86.7709, 40.5877],
          [-86.7526, 40.562],
          [-86.6954, 40.5619],
          [-86.6949, 40.4616],
          [-86.5833, 40.4675],
          [-86.5729, 40.4315],
          [-86.5127, 40.4458],
          [-86.4495, 40.4458],
          [-86.4114, 40.4605],
          [-86.3758, 40.4319],
          [-86.3095, 40.4317],
          [-86.3093, 40.3884],
          [-86.243, 40.3522],
          [-86.203, 40.4032],
          [-85.8622, 40.4069],
          [-85.7861, 40.3717],
          [-85.7422, 40.3789],
          [-85.4443, 40.3791],
          [-85.4465, 40.5382],
          [-85.41, 40.5524],
          [-85.4098, 40.5955],
          [-85.2781, 40.5961],
          [-85.2737, 40.5671],
          [-85.1835, 40.5818],
          [-85.1262, 40.5675],
          [-84.8396, 40.5721],
          [-84.8022, 40.5866],
          [-84.804, 40.331],
          [-84.8142, 39.8142],
          [-84.8148, 39.61],
          [-84.8528, 39.5667],
          [-84.8535, 39.5232],
          [-85.1284, 39.5267],
          [-85.2894, 39.5254],
          [-85.2793, 39.4606],
          [-85.2976, 39.4531],
          [-85.2965, 39.3921],
          [-85.2733, 39.362],
          [-85.2937, 39.3314],
          [-85.2976, 39.2677],
          [-85.4401, 39.1956],
          [-85.4448, 38.9565],
          [-85.2499, 38.956],
          [-85.203, 38.9285],
          [-85.1358, 38.9296],
          [-85.1403, 38.9032],
          [-85.1844, 38.8854],
          [-85.1866, 38.8341],
          [-85.1607, 38.7897],
          [-85.2026, 38.74],
          [-85.2015, 38.6909],
          [-85.2465, 38.7318],
          [-85.2892, 38.7424],
          [-85.333, 38.7361],
          [-85.4109, 38.7371],
          [-85.4521, 38.7093],
          [-85.4387, 38.6593],
          [-85.4392, 38.6013],
          [-85.5198, 38.6066],
          [-85.4974, 38.6654],
          [-85.5437, 38.6645],
          [-85.5167, 38.7302],
          [-85.5473, 38.7561],
          [-85.6693, 38.7368],
          [-85.6835, 38.755],
          [-85.6838, 38.8154],
          [-85.7155, 38.8282],
          [-85.7954, 38.8075],
          [-85.8048, 38.7807],
          [-85.8464, 38.7646],
          [-86.0433, 38.7624],
          [-86.1011, 38.7253],
          [-86.1504, 38.7336],
          [-86.2266, 38.778],
          [-86.2797, 38.7631],
          [-86.3096, 38.7307],
          [-86.3079, 38.5211],
          [-86.377, 38.4952],
          [-86.3797, 38.4701],
          [-86.4244, 38.4443],
          [-86.4573, 38.3964],
          [-86.4955, 38.4352],
          [-86.5699, 38.4403],
          [-86.5694, 38.4129],
          [-86.6424, 38.395],
          [-86.6875, 38.3955],
          [-86.682, 38.4739],
          [-86.722, 38.4704],
          [-86.7426, 38.5262],
          [-86.729, 38.569],
          [-86.6824, 38.5663],
          [-86.6833, 38.719],
          [-86.7525, 38.7085],
          [-86.6832, 38.8056],
          [-86.7802, 38.8101],
          [-86.7944, 38.9044],
          [-87.0358, 38.9025],
          [-87.1534, 38.9075],
          [-87.1673, 38.96],
          [-87.2407, 38.9654],
          [-87.2389, 39.2294],
          [-87.1624, 39.2207],
          [-87.0853, 39.1773],
          [-87.0878, 39.2271],
          [-87.0548, 39.2558],
          [-87.0445, 39.3206],
          [-86.9658, 39.314],
          [-86.8973, 39.3413],
          [-86.8657, 39.3126],
          [-86.8334, 39.3261],
          [-86.8422, 39.3658],
          [-86.8085, 39.3892],
          [-86.8129, 39.4335],
          [-86.8639, 39.4476],
          [-86.9541, 39.4997],
          [-86.9661, 39.4735],
          [-87.0149, 39.4738],
          [-87.0095, 39.8667],
          [-87.0913, 39.8818],
          [-87.091, 39.953],
          [-87.242, 39.953],
          [-87.2851, 39.9311],
          [-87.274, 39.8836],
          [-87.3235, 39.85],
          [-87.3581, 39.8991],
          [-87.4132, 39.8822],
          [-87.5331, 39.9104],
          [-87.5316, 40.133],
          [-87.5264, 40.4916],
          [-87.5266, 41.0576],
          [-87.4685, 41.0429],
          [-87.4455, 41.0573],
          [-87.3143, 41.0603],
          [-87.3142, 41.1122],
          [-87.2653, 41.1039],
          [-87.2181, 41.1155],
          [-87.1989, 41.1444],
          [-87.1222, 41.1441],
          [-87.0843, 41.1151],
          [-87.0073, 41.1146],
          [-86.9982, 41.0856],
          [-86.9309, 41.0856],
          [-86.9206, 41.1362],
          [-86.8437, 41.143],
          [-86.7763, 41.1286],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "S Bend Mkt": {
    type: "Feature",
    properties: { code: "IN_SBD", name: "S Bend Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-86.8248, 41.7602],
          [-86.717, 41.8193],
          [-86.6194, 41.8938],
          [-86.5822, 41.9422],
          [-86.5454, 42.0169],
          [-86.4352, 42.0002],
          [-86.3791, 42.0017],
          [-86.3786, 41.9914],
          [-86.3791, 42.0017],
          [-86.4091, 42.0323],
          [-86.4016, 42.0607],
          [-86.3589, 42.0797],
          [-86.2765, 42.072],
          [-86.2383, 42.0897],
          [-86.2229, 42.0223],
          [-86.2231, 41.9421],
          [-86.1274, 41.8653],
          [-86.0497, 41.84],
          [-86.001, 41.8394],
          [-85.9744, 41.7888],
          [-85.9159, 41.81],
          [-85.8135, 41.8043],
          [-85.8154, 41.7591],
          [-85.5435, 41.7594],
          [-85.4856, 41.7322],
          [-85.5147, 41.6848],
          [-85.5207, 41.6001],
          [-85.501, 41.5688],
          [-85.4623, 41.5622],
          [-85.4623, 41.5254],
          [-85.6549, 41.5282],
          [-85.6529, 41.3337],
          [-85.6334, 41.3078],
          [-85.6514, 41.2729],
          [-85.6527, 41.1785],
          [-85.6668, 41.14],
          [-85.7052, 41.1397],
          [-85.7236, 41.0643],
          [-85.8153, 41.0582],
          [-85.8161, 41.0871],
          [-85.9354, 41.1099],
          [-85.9456, 41.0864],
          [-86.0381, 41.1147],
          [-86.0909, 41.1212],
          [-86.0918, 41.1576],
          [-86.1398, 41.1724],
          [-86.357, 41.1721],
          [-86.3713, 41.149],
          [-86.4671, 41.1631],
          [-86.467, 41.1934],
          [-86.5632, 41.201],
          [-86.6407, 41.1722],
          [-86.6407, 41.2012],
          [-86.6886, 41.2155],
          [-86.7178, 41.2882],
          [-86.7575, 41.2952],
          [-86.7008, 41.4025],
          [-86.6594, 41.4244],
          [-86.6853, 41.4912],
          [-86.5823, 41.4945],
          [-86.6031, 41.5558],
          [-86.4866, 41.5959],
          [-86.4876, 41.6493],
          [-86.5654, 41.6652],
          [-86.5698, 41.7595],
          [-86.8248, 41.7602],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Terre Haute Mkt": {
    type: "Feature",
    properties: { code: "IN_TER", name: "Terre Haute Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-88.7461, 39.8142],
          [-88.7082, 39.8142],
          [-88.6939, 39.8141],
          [-88.6885, 39.8505],
          [-88.5566, 39.85],
          [-88.4624, 39.8791],
          [-88.1321, 39.8801],
          [-88.0847, 39.8652],
          [-87.9943, 39.8795],
          [-87.9846, 39.8505],
          [-87.9186, 39.8654],
          [-87.8808, 39.8509],
          [-87.7769, 39.8809],
          [-87.7103, 39.8811],
          [-87.6578, 39.8662],
          [-87.5575, 39.8687],
          [-87.5331, 39.9104],
          [-87.4132, 39.8822],
          [-87.3581, 39.8991],
          [-87.3235, 39.85],
          [-87.274, 39.8836],
          [-87.2851, 39.9311],
          [-87.242, 39.953],
          [-87.091, 39.953],
          [-87.0913, 39.8818],
          [-87.0095, 39.8667],
          [-87.0149, 39.4738],
          [-86.9661, 39.4735],
          [-86.9541, 39.4997],
          [-86.8639, 39.4476],
          [-86.8129, 39.4335],
          [-86.8085, 39.3892],
          [-86.8422, 39.3658],
          [-86.8334, 39.3261],
          [-86.8657, 39.3126],
          [-86.8973, 39.3413],
          [-86.9658, 39.314],
          [-87.0445, 39.3206],
          [-87.0548, 39.2558],
          [-87.0878, 39.2271],
          [-87.0853, 39.1773],
          [-87.1624, 39.2207],
          [-87.2389, 39.2294],
          [-87.2407, 38.9654],
          [-87.2408, 38.9017],
          [-87.4672, 38.9023],
          [-87.5441, 38.8951],
          [-87.5506, 38.8596],
          [-87.497, 38.7791],
          [-87.4965, 38.7427],
          [-87.5196, 38.6972],
          [-87.5937, 38.6674],
          [-87.6607, 38.5411],
          [-87.6542, 38.5119],
          [-87.714, 38.4799],
          [-87.7653, 38.499],
          [-87.8131, 38.5695],
          [-87.8645, 38.5701],
          [-87.8733, 38.4964],
          [-87.9299, 38.5043],
          [-87.9524, 38.4752],
          [-88.0081, 38.5042],
          [-88.1479, 38.4969],
          [-88.1941, 38.4385],
          [-88.1677, 38.403],
          [-88.2038, 38.3862],
          [-88.2351, 38.4011],
          [-88.2581, 38.4408],
          [-88.2755, 38.522],
          [-88.257, 38.5555],
          [-88.1479, 38.534],
          [-88.1492, 38.6131],
          [-88.1673, 38.6712],
          [-88.1682, 38.7372],
          [-88.2245, 38.7526],
          [-88.2245, 38.8178],
          [-88.2955, 38.8344],
          [-88.2996, 38.7904],
          [-88.3407, 38.7905],
          [-88.3801, 38.838],
          [-88.3803, 38.8968],
          [-88.4566, 38.9128],
          [-88.5754, 38.8784],
          [-88.644, 38.8451],
          [-88.6749, 38.8448],
          [-88.7596, 38.9063],
          [-88.8066, 38.9554],
          [-88.8511, 38.9793],
          [-88.9149, 38.9451],
          [-88.9762, 38.8782],
          [-89.0301, 38.8811],
          [-89.0485, 38.9252],
          [-89.0854, 38.9256],
          [-89.1123, 38.8923],
          [-89.1599, 38.8709],
          [-89.1898, 38.8024],
          [-89.2181, 38.8009],
          [-89.2313, 38.8255],
          [-89.2146, 38.8461],
          [-89.2312, 38.8476],
          [-89.2215, 38.9207],
          [-89.2002, 38.9236],
          [-89.2177, 38.9263],
          [-89.1938, 38.9434],
          [-89.1697, 38.9438],
          [-89.1708, 38.9564],
          [-89.2196, 38.94],
          [-89.2131, 38.9664],
          [-89.2445, 39.0012],
          [-89.2489, 39.0336],
          [-89.2432, 39.0491],
          [-89.2291, 39.0562],
          [-89.237, 39.079],
          [-89.195, 39.0715],
          [-89.1214, 39.0427],
          [-89.1167, 39.0339],
          [-89.0937, 39.0395],
          [-89.0984, 39.0176],
          [-89.0822, 39.0173],
          [-89.0855, 39.0277],
          [-89.0419, 39.0495],
          [-89.0294, 39.0747],
          [-89.0326, 39.0922],
          [-89.0139, 39.0883],
          [-88.9874, 39.1134],
          [-88.9551, 39.1143],
          [-88.9411, 39.1516],
          [-88.9639, 39.1652],
          [-88.9826, 39.1796],
          [-88.9846, 39.1576],
          [-89.0108, 39.1574],
          [-89.0086, 39.1833],
          [-89.023, 39.182],
          [-89.0263, 39.1721],
          [-89.0496, 39.1727],
          [-89.039, 39.2006],
          [-89.0584, 39.1981],
          [-89.063, 39.2163],
          [-89.0681, 39.2164],
          [-89.0727, 39.2382],
          [-89.0585, 39.2379],
          [-89.0536, 39.2542],
          [-89.0144, 39.2485],
          [-89.0185, 39.2604],
          [-89.0442, 39.2672],
          [-89.0483, 39.289],
          [-89.0336, 39.3058],
          [-89.0249, 39.2955],
          [-89.004, 39.2995],
          [-88.9983, 39.2887],
          [-88.9732, 39.2849],
          [-88.8985, 39.2963],
          [-88.8895, 39.3108],
          [-88.9358, 39.3178],
          [-88.9291, 39.3184],
          [-88.9319, 39.3253],
          [-88.8987, 39.3328],
          [-88.8992, 39.3709],
          [-88.8458, 39.3784],
          [-88.8359, 39.3655],
          [-88.8524, 39.3322],
          [-88.8163, 39.3402],
          [-88.8135, 39.3256],
          [-88.8059, 39.2997],
          [-88.7846, 39.2952],
          [-88.7316, 39.3031],
          [-88.7084, 39.3172],
          [-88.7085, 39.3463],
          [-88.6853, 39.3535],
          [-88.6865, 39.4044],
          [-88.7156, 39.4116],
          [-88.6966, 39.4184],
          [-88.6873, 39.4337],
          [-88.7308, 39.4484],
          [-88.697, 39.4619],
          [-88.7016, 39.4667],
          [-88.7091, 39.4593],
          [-88.7098, 39.47],
          [-88.6879, 39.4885],
          [-88.685, 39.5006],
          [-88.696, 39.5064],
          [-88.6751, 39.508],
          [-88.6996, 39.5077],
          [-88.6978, 39.5207],
          [-88.7169, 39.521],
          [-88.7175, 39.5793],
          [-88.8093, 39.5802],
          [-88.8101, 39.6236],
          [-88.8477, 39.6238],
          [-88.8671, 39.6529],
          [-88.8113, 39.67],
          [-88.8222, 39.6823],
          [-88.8869, 39.6818],
          [-88.8884, 39.6912],
          [-88.9014, 39.6966],
          [-88.9225, 39.6965],
          [-88.9233, 39.751],
          [-88.87, 39.7519],
          [-88.8692, 39.7115],
          [-88.8422, 39.7447],
          [-88.8513, 39.7631],
          [-88.8135, 39.7627],
          [-88.8149, 39.8057],
          [-88.7933, 39.7992],
          [-88.7966, 39.7807],
          [-88.7775, 39.7805],
          [-88.7835, 39.799],
          [-88.746, 39.7994],
          [-88.7461, 39.8142],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Bowling Green Mkt": {
    type: "Feature",
    properties: { code: "KY_BOW", name: "Bowling Green Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-87.877, 36.9601],
          [-87.7479, 36.9746],
          [-87.6836, 37.0609],
          [-87.6403, 37.0559],
          [-87.5971, 37.0755],
          [-87.625, 37.1361],
          [-87.5488, 37.1133],
          [-87.5247, 37.0833],
          [-87.4202, 37.0938],
          [-87.3969, 37.1211],
          [-87.3343, 37.0847],
          [-87.2844, 37.108],
          [-87.25, 37.0404],
          [-87.1178, 37.0447],
          [-86.9764, 37.0737],
          [-86.9413, 37.0691],
          [-86.8942, 37.0894],
          [-86.8704, 37.1385],
          [-86.9157, 37.1737],
          [-86.9064, 37.2085],
          [-86.8614, 37.2519],
          [-86.7862, 37.2399],
          [-86.8182, 37.3044],
          [-86.8156, 37.329],
          [-86.741, 37.316],
          [-86.612, 37.3953],
          [-86.6215, 37.4232],
          [-86.6009, 37.4595],
          [-86.6254, 37.4815],
          [-86.6066, 37.5191],
          [-86.501, 37.5077],
          [-86.4638, 37.5271],
          [-86.4238, 37.6137],
          [-86.3874, 37.6364],
          [-86.3245, 37.6224],
          [-86.2755, 37.5935],
          [-86.1645, 37.7772],
          [-86.1614, 37.7429],
          [-86.0749, 37.7135],
          [-85.9627, 37.7158],
          [-85.9113, 37.7527],
          [-85.9273, 37.7939],
          [-85.8462, 37.8054],
          [-85.8078, 37.7561],
          [-85.7465, 37.7652],
          [-85.6854, 37.7591],
          [-85.6897, 37.7192],
          [-85.7169, 37.6814],
          [-85.6364, 37.6607],
          [-85.585, 37.6255],
          [-85.6069, 37.5905],
          [-85.6128, 37.5411],
          [-85.4937, 37.5188],
          [-85.4661, 37.4661],
          [-85.4387, 37.4789],
          [-85.37, 37.4661],
          [-85.3008, 37.4884],
          [-85.2891, 37.5134],
          [-85.2374, 37.5005],
          [-85.1823, 37.4216],
          [-85.1335, 37.4109],
          [-85.0955, 37.4255],
          [-85.0448, 37.4122],
          [-85.0379, 37.4072],
          [-85.072, 37.3778],
          [-85.0911, 37.371],
          [-85.1271, 37.3221],
          [-85.1365, 37.3269],
          [-85.1203, 37.3631],
          [-85.1378, 37.329],
          [-85.1471, 37.3042],
          [-85.057, 37.2571],
          [-85.0544, 37.211],
          [-85.0878, 37.2121],
          [-85.0908, 37.1947],
          [-85.1134, 37.2032],
          [-85.129, 37.1919],
          [-85.1224, 37.1827],
          [-85.0911, 37.1886],
          [-85.0909, 37.1565],
          [-85.1055, 37.1486],
          [-85.118, 37.1236],
          [-85.1046, 37.108],
          [-85.1235, 37.1075],
          [-85.1195, 37.0993],
          [-85.1306, 37.0892],
          [-85.1724, 37.0869],
          [-85.176, 37.0789],
          [-85.1971, 37.0816],
          [-85.2169, 37.0713],
          [-85.1776, 37.0597],
          [-85.1903, 37.0528],
          [-85.1819, 37.0299],
          [-85.1733, 37.0272],
          [-85.2057, 36.9967],
          [-85.211, 36.9594],
          [-85.218, 36.9607],
          [-85.232, 36.9251],
          [-85.2751, 36.8978],
          [-85.2467, 36.8769],
          [-85.2435, 36.8575],
          [-85.2155, 36.8545],
          [-85.2266, 36.8145],
          [-85.2131, 36.8052],
          [-85.2314, 36.7974],
          [-85.2558, 36.7218],
          [-85.2354, 36.6905],
          [-85.2264, 36.6937],
          [-85.2456, 36.6846],
          [-85.2489, 36.6634],
          [-85.2667, 36.6502],
          [-85.2641, 36.6264],
          [-85.4884, 36.615],
          [-85.8322, 36.622],
          [-86.0328, 36.6304],
          [-86.3331, 36.6488],
          [-86.5078, 36.6524],
          [-86.5641, 36.6335],
          [-86.5899, 36.6525],
          [-87.115, 36.6424],
          [-87.4365, 36.6407],
          [-87.4281, 36.6023],
          [-87.464, 36.586],
          [-87.4795, 36.5512],
          [-87.5653, 36.5348],
          [-87.5828, 36.5612],
          [-87.585, 36.612],
          [-87.557, 36.6392],
          [-87.8532, 36.6332],
          [-87.8496, 36.6637],
          [-88.0705, 36.6781],
          [-88.1023, 36.7332],
          [-88.1573, 36.8672],
          [-88, 36.9112],
          [-87.877, 36.9601],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Lexington Mkt": {
    type: "Feature",
    properties: { code: "KY_LEX", name: "Lexington Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-84.6315, 37.2902],
            [-84.5915, 37.2705],
            [-84.5773, 37.2739],
            [-84.5825, 37.2568],
            [-84.6306, 37.253],
            [-84.6361, 37.2592],
            [-84.6315, 37.2902],
          ],
        ],
        [
          [
            [-85.2015, 38.6909],
            [-85.133, 38.7024],
            [-85.0719, 38.7416],
            [-84.9787, 38.7793],
            [-84.933, 38.7775],
            [-84.8879, 38.7947],
            [-84.8116, 38.7928],
            [-84.8234, 38.8392],
            [-84.7881, 38.8837],
            [-84.8608, 38.8977],
            [-84.8778, 38.9204],
            [-84.8352, 38.958],
            [-84.8371, 38.9881],
            [-84.8972, 39.0524],
            [-84.8312, 39.1019],
            [-84.784, 39.1181],
            [-84.7544, 39.1467],
            [-84.7185, 39.1371],
            [-84.6897, 39.1042],
            [-84.6324, 39.0768],
            [-84.5097, 39.0937],
            [-84.4705, 39.1215],
            [-84.4328, 39.0943],
            [-84.4298, 39.0583],
            [-84.3604, 39.0414],
            [-84.3047, 39.0065],
            [-84.2882, 38.9558],
            [-84.2345, 38.8932],
            [-84.2302, 38.8265],
            [-84.2129, 38.8057],
            [-84.0715, 38.7705],
            [-83.9788, 38.7871],
            [-83.859, 38.7568],
            [-83.8406, 38.7219],
            [-83.7836, 38.6956],
            [-83.7722, 38.6581],
            [-83.6681, 38.6281],
            [-83.6466, 38.6362],
            [-83.6245, 38.6114],
            [-83.642, 38.5254],
            [-83.5977, 38.5001],
            [-83.574, 38.4435],
            [-83.5235, 38.4088],
            [-83.4171, 38.396],
            [-83.3315, 38.3031],
            [-83.3069, 38.2612],
            [-83.2995, 38.2091],
            [-83.1732, 38.1562],
            [-83.2087, 38.1202],
            [-83.2342, 38.0646],
            [-83.2131, 38.0273],
            [-83.1224, 38.0024],
            [-83.0651, 38.0079],
            [-82.9934, 37.9615],
            [-82.9615, 37.9127],
            [-82.9879, 37.8709],
            [-82.9895, 37.8182],
            [-82.9507, 37.7947],
            [-82.9407, 37.7163],
            [-82.945, 37.6809],
            [-82.9089, 37.6492],
            [-82.8969, 37.5813],
            [-82.9302, 37.5732],
            [-82.9838, 37.6102],
            [-83.0181, 37.5679],
            [-82.9976, 37.5284],
            [-82.9505, 37.5108],
            [-82.9939, 37.4647],
            [-83.0379, 37.4755],
            [-83.0373, 37.4105],
            [-82.9968, 37.4277],
            [-82.9145, 37.42],
            [-82.8664, 37.4568],
            [-82.7626, 37.425],
            [-82.7528, 37.3107],
            [-82.6717, 37.2272],
            [-82.6437, 37.2218],
            [-82.6422, 37.1531],
            [-82.7618, 37.1046],
            [-82.8164, 37.0709],
            [-82.9654, 37.0001],
            [-83.0058, 36.9931],
            [-83.0205, 36.9779],
            [-83.0379, 36.9843],
            [-83.1505, 36.9542],
            [-83.1478, 36.9851],
            [-83.1265, 36.995],
            [-83.1239, 37.0057],
            [-83.1413, 37.0056],
            [-83.1646, 37.0212],
            [-83.1822, 37.0209],
            [-83.186, 37.0059],
            [-83.226, 37.0056],
            [-83.2251, 37.0241],
            [-83.2407, 37.0242],
            [-83.2549, 37.0368],
            [-83.3151, 37.0528],
            [-83.3195, 37.0819],
            [-83.3339, 37.0724],
            [-83.3698, 37.0706],
            [-83.3771, 37.0937],
            [-83.3494, 37.1178],
            [-83.3732, 37.1316],
            [-83.3831, 37.1159],
            [-83.4262, 37.1106],
            [-83.4309, 37.1005],
            [-83.46, 37.0874],
            [-83.4892, 37.1016],
            [-83.4949, 37.0902],
            [-83.5152, 37.0965],
            [-83.5262, 37.0854],
            [-83.5413, 37.0855],
            [-83.5354, 37.1031],
            [-83.5512, 37.1193],
            [-83.5515, 37.1676],
            [-83.5051, 37.2042],
            [-83.5077, 37.236],
            [-83.5134, 37.2604],
            [-83.4942, 37.2787],
            [-83.5235, 37.2778],
            [-83.5215, 37.2645],
            [-83.5304, 37.2558],
            [-83.5661, 37.2654],
            [-83.574, 37.2883],
            [-83.6093, 37.3013],
            [-83.6536, 37.3581],
            [-83.6674, 37.3558],
            [-83.6759, 37.3388],
            [-83.6895, 37.3368],
            [-83.683, 37.3591],
            [-83.6939, 37.3536],
            [-83.7075, 37.3571],
            [-83.7094, 37.3683],
            [-83.7382, 37.3698],
            [-83.7597, 37.3588],
            [-83.7637, 37.3467],
            [-83.773, 37.37],
            [-83.8105, 37.373],
            [-83.7819, 37.3436],
            [-83.7918, 37.3303],
            [-83.8013, 37.3301],
            [-83.7979, 37.3223],
            [-83.8174, 37.3171],
            [-83.8335, 37.3243],
            [-83.8632, 37.3066],
            [-83.8769, 37.2947],
            [-83.9, 37.2669],
            [-83.9225, 37.2617],
            [-83.9397, 37.2589],
            [-83.9435, 37.2506],
            [-84.0057, 37.2534],
            [-84.0563, 37.2706],
            [-84.0526, 37.2829],
            [-84.0728, 37.2853],
            [-84.1004, 37.3015],
            [-84.1086, 37.3101],
            [-84.0941, 37.3139],
            [-84.1071, 37.3157],
            [-84.1081, 37.3265],
            [-84.1242, 37.3357],
            [-84.137, 37.31],
            [-84.1526, 37.3106],
            [-84.1477, 37.3028],
            [-84.1802, 37.3041],
            [-84.1909, 37.2855],
            [-84.2149, 37.2815],
            [-84.2219, 37.2391],
            [-84.2334, 37.2481],
            [-84.2404, 37.2284],
            [-84.2547, 37.2388],
            [-84.2721, 37.2131],
            [-84.2592, 37.2055],
            [-84.2808, 37.1982],
            [-84.2786, 37.1874],
            [-84.2655, 37.1819],
            [-84.2997, 37.1705],
            [-84.2888, 37.162],
            [-84.289, 37.1517],
            [-84.292, 37.161],
            [-84.2948, 37.1616],
            [-84.3003, 37.1639],
            [-84.501, 37.3251],
            [-84.5168, 37.327],
            [-84.538, 37.3054],
            [-84.5394, 37.3282],
            [-84.5545, 37.3262],
            [-84.5741, 37.3432],
            [-84.6478, 37.2903],
            [-84.665, 37.3111],
            [-84.6745, 37.305],
            [-84.6781, 37.31],
            [-84.6727, 37.331],
            [-84.6868, 37.3305],
            [-84.6924, 37.3115],
            [-84.7227, 37.2823],
            [-84.7369, 37.2853],
            [-84.7725, 37.2992],
            [-84.7865, 37.3213],
            [-84.8165, 37.3345],
            [-84.7949, 37.3402],
            [-84.7639, 37.3636],
            [-84.7469, 37.345],
            [-84.7266, 37.3621],
            [-84.7961, 37.3905],
            [-84.8337, 37.4156],
            [-84.8337, 37.3891],
            [-84.863, 37.4007],
            [-84.8687, 37.3772],
            [-84.8913, 37.3759],
            [-84.8973, 37.3567],
            [-84.9168, 37.3519],
            [-84.9364, 37.3668],
            [-84.9267, 37.3798],
            [-84.9466, 37.4349],
            [-84.9205, 37.4512],
            [-84.9198, 37.4649],
            [-84.9639, 37.4716],
            [-84.9754, 37.4566],
            [-84.9826, 37.4571],
            [-85.0437, 37.498],
            [-85.0392, 37.5512],
            [-85.1058, 37.5212],
            [-85.122, 37.5536],
            [-85.0973, 37.5891],
            [-85.0975, 37.6311],
            [-85.0302, 37.6311],
            [-85.0235, 37.6946],
            [-85.0445, 37.7473],
            [-85.0073, 37.8036],
            [-85.0502, 37.8559],
            [-85.094, 37.888],
            [-85.1263, 37.9171],
            [-85.1527, 37.8978],
            [-85.1681, 37.9732],
            [-85.1385, 37.9689],
            [-85.0699, 38.0162],
            [-85.0836, 38.0406],
            [-85.0479, 38.0971],
            [-84.9721, 38.1216],
            [-85.007, 38.1582],
            [-85.0546, 38.1732],
            [-85.0418, 38.2063],
            [-85.0008, 38.2294],
            [-84.9987, 38.275],
            [-84.9467, 38.2938],
            [-84.9627, 38.3327],
            [-84.899, 38.3781],
            [-84.8805, 38.4155],
            [-84.9633, 38.4375],
            [-84.9562, 38.4732],
            [-84.9773, 38.5127],
            [-85.0142, 38.5373],
            [-85.0094, 38.561],
            [-85.0758, 38.5782],
            [-85.1049, 38.6273],
            [-85.1528, 38.6222],
            [-85.173, 38.5971],
            [-85.2587, 38.6325],
            [-85.2537, 38.6869],
            [-85.2344, 38.7071],
            [-85.2707, 38.7359],
            [-85.333, 38.7361],
            [-85.2892, 38.7424],
            [-85.2465, 38.7318],
            [-85.2015, 38.6909],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Louisville Mkt": {
    type: "Feature",
    properties: { code: "KY_LOU", name: "Louisville Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-86.6424, 38.395],
          [-86.5694, 38.4129],
          [-86.5699, 38.4403],
          [-86.4955, 38.4352],
          [-86.4573, 38.3964],
          [-86.4244, 38.4443],
          [-86.3797, 38.4701],
          [-86.377, 38.4952],
          [-86.3079, 38.5211],
          [-86.3096, 38.7307],
          [-86.2797, 38.7631],
          [-86.2266, 38.778],
          [-86.1504, 38.7336],
          [-86.1011, 38.7253],
          [-86.0433, 38.7624],
          [-85.8464, 38.7646],
          [-85.8048, 38.7807],
          [-85.7954, 38.8075],
          [-85.7155, 38.8282],
          [-85.6838, 38.8154],
          [-85.6835, 38.755],
          [-85.6693, 38.7368],
          [-85.5473, 38.7561],
          [-85.5167, 38.7302],
          [-85.5437, 38.6645],
          [-85.4974, 38.6654],
          [-85.5198, 38.6066],
          [-85.4392, 38.6013],
          [-85.4387, 38.6593],
          [-85.4521, 38.7093],
          [-85.4109, 38.7371],
          [-85.333, 38.7361],
          [-85.2707, 38.7359],
          [-85.2344, 38.7071],
          [-85.2537, 38.6869],
          [-85.2587, 38.6325],
          [-85.173, 38.5971],
          [-85.1528, 38.6222],
          [-85.1049, 38.6273],
          [-85.0758, 38.5782],
          [-85.0094, 38.561],
          [-85.0142, 38.5373],
          [-84.9773, 38.5127],
          [-84.9562, 38.4732],
          [-84.9633, 38.4375],
          [-84.8805, 38.4155],
          [-84.899, 38.3781],
          [-84.9627, 38.3327],
          [-84.9467, 38.2938],
          [-84.9987, 38.275],
          [-85.0008, 38.2294],
          [-85.0418, 38.2063],
          [-85.0546, 38.1732],
          [-85.007, 38.1582],
          [-84.9721, 38.1216],
          [-85.0479, 38.0971],
          [-85.0836, 38.0406],
          [-85.0699, 38.0162],
          [-85.1385, 37.9689],
          [-85.1681, 37.9732],
          [-85.1527, 37.8978],
          [-85.1263, 37.9171],
          [-85.094, 37.888],
          [-85.0502, 37.8559],
          [-85.0073, 37.8036],
          [-85.0445, 37.7473],
          [-85.0235, 37.6946],
          [-85.0302, 37.6311],
          [-85.0975, 37.6311],
          [-85.0973, 37.5891],
          [-85.122, 37.5536],
          [-85.1058, 37.5212],
          [-85.0392, 37.5512],
          [-85.0437, 37.498],
          [-84.9826, 37.4571],
          [-84.9993, 37.4461],
          [-85.0436, 37.4418],
          [-85.0448, 37.4122],
          [-85.0955, 37.4255],
          [-85.1335, 37.4109],
          [-85.1823, 37.4216],
          [-85.2374, 37.5005],
          [-85.2891, 37.5134],
          [-85.3008, 37.4884],
          [-85.37, 37.4661],
          [-85.4387, 37.4789],
          [-85.4661, 37.4661],
          [-85.4937, 37.5188],
          [-85.6128, 37.5411],
          [-85.6069, 37.5905],
          [-85.585, 37.6255],
          [-85.6364, 37.6607],
          [-85.7169, 37.6814],
          [-85.6897, 37.7192],
          [-85.6854, 37.7591],
          [-85.7465, 37.7652],
          [-85.8078, 37.7561],
          [-85.8462, 37.8054],
          [-85.9273, 37.7939],
          [-85.9113, 37.7527],
          [-85.9627, 37.7158],
          [-86.0749, 37.7135],
          [-86.1614, 37.7429],
          [-86.1645, 37.7772],
          [-86.2755, 37.5935],
          [-86.3245, 37.6224],
          [-86.3874, 37.6364],
          [-86.4238, 37.6137],
          [-86.4638, 37.5271],
          [-86.501, 37.5077],
          [-86.6066, 37.5191],
          [-86.6407, 37.6194],
          [-86.6222, 37.6551],
          [-86.678, 37.7928],
          [-86.6661, 37.8358],
          [-86.5981, 37.8674],
          [-86.5886, 37.9212],
          [-86.5342, 37.917],
          [-86.507, 37.9364],
          [-86.5252, 37.9682],
          [-86.5118, 38.0444],
          [-86.4522, 38.0505],
          [-86.434, 38.0868],
          [-86.4662, 38.1068],
          [-86.4604, 38.2068],
          [-86.5031, 38.2071],
          [-86.5283, 38.2318],
          [-86.6561, 38.2329],
          [-86.6795, 38.2631],
          [-86.6522, 38.3112],
          [-86.6424, 38.395],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "New Orleans Mkt": {
    type: "Feature",
    properties: { code: "LA_NEW", name: "New Orleans Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-92.0572, 30.8153],
            [-92.0051, 30.8199],
            [-91.9808, 30.7973],
            [-91.9407, 30.8013],
            [-91.904, 30.7408],
            [-91.9272, 30.7187],
            [-91.9862, 30.7362],
            [-92.041, 30.7849],
            [-92.0572, 30.8153],
          ],
        ],
        [
          [
            [-93.758, 30.3835],
            [-93.6978, 30.4438],
            [-93.7292, 30.5448],
            [-93.7278, 30.5741],
            [-93.6798, 30.5997],
            [-93.6831, 30.6408],
            [-93.6211, 30.6952],
            [-93.5894, 30.7867],
            [-93.5541, 30.8249],
            [-93.5678, 30.8883],
            [-93.5262, 30.9394],
            [-93.5678, 30.9778],
            [-93.566, 31.0046],
            [-93.5169, 31.0237],
            [-93.4977, 31.0524],
            [-93.4412, 31.0651],
            [-93.4035, 30.9927],
            [-93.4356, 30.9662],
            [-93.3941, 30.9091],
            [-93.3726, 30.926],
            [-93.2805, 30.9229],
            [-93.2347, 30.9416],
            [-93.1615, 30.9418],
            [-93.1594, 30.981],
            [-93.1895, 31.0174],
            [-93.2021, 31.0704],
            [-93.1546, 31.0768],
            [-93.1366, 31.1029],
            [-93.0116, 31.1586],
            [-92.9747, 31.1577],
            [-92.8677, 31.0672],
            [-92.7814, 31.0418],
            [-92.7283, 31.0424],
            [-92.731, 30.9912],
            [-92.7694, 30.9838],
            [-92.7661, 30.9409],
            [-92.7086, 30.9162],
            [-92.7056, 30.8992],
            [-92.7626, 30.854],
            [-92.7213, 30.826],
            [-92.7206, 30.7929],
            [-92.764, 30.7449],
            [-92.7351, 30.6765],
            [-92.5984, 30.6774],
            [-92.5977, 30.601],
            [-92.5566, 30.6303],
            [-92.4921, 30.641],
            [-92.4787, 30.7067],
            [-92.5058, 30.7932],
            [-92.4912, 30.8311],
            [-92.5169, 30.8659],
            [-92.4824, 30.914],
            [-92.446, 30.9031],
            [-92.3598, 30.8381],
            [-92.3224, 30.8293],
            [-92.2668, 30.8464],
            [-92.2666, 30.8169],
            [-92.2232, 30.8142],
            [-92.1884, 30.777],
            [-92.0821, 30.6943],
            [-92.0222, 30.6904],
            [-91.9812, 30.7288],
            [-91.9522, 30.7236],
            [-91.929, 30.6771],
            [-91.933, 30.6497],
            [-91.88, 30.6215],
            [-91.8135, 30.5458],
            [-91.7572, 30.5668],
            [-91.7337, 30.7017],
            [-91.7688, 30.7854],
            [-91.8073, 30.8111],
            [-91.8002, 30.9246],
            [-91.806, 30.9726],
            [-91.7198, 31.0407],
            [-91.6598, 31.044],
            [-91.6369, 30.9994],
            [-91.0646, 30.9989],
            [-90.4425, 30.9997],
            [-89.941, 31.0014],
            [-89.7526, 31.0019],
            [-89.7277, 30.9933],
            [-89.7563, 30.9435],
            [-89.7501, 30.9129],
            [-89.7686, 30.8666],
            [-89.8315, 30.7676],
            [-89.8281, 30.725],
            [-89.8472, 30.67],
            [-89.8247, 30.649],
            [-89.8197, 30.5968],
            [-89.7681, 30.515],
            [-89.7246, 30.4919],
            [-89.6834, 30.4518],
            [-89.6837, 30.4059],
            [-89.6572, 30.3565],
            [-89.6297, 30.3393],
            [-89.6417, 30.3038],
            [-89.6171, 30.2275],
            [-89.5702, 30.1803],
            [-89.5245, 30.1808],
            [-89.5871, 30.1506],
            [-89.6175, 30.1564],
            [-89.6782, 30.1081],
            [-89.6837, 30.076],
            [-89.7632, 30.0421],
            [-89.8186, 30.0433],
            [-89.8576, 30.0044],
            [-89.8385, 29.9458],
            [-89.7755, 29.9374],
            [-89.692, 29.8687],
            [-89.638, 29.8641],
            [-89.5981, 29.8814],
            [-89.575, 29.9595],
            [-89.5814, 29.9947],
            [-89.4941, 30.041],
            [-89.4819, 30.0791],
            [-89.3724, 30.0367],
            [-89.4328, 30.008],
            [-89.4328, 29.9788],
            [-89.3792, 29.9638],
            [-89.368, 29.9115],
            [-89.3155, 29.9232],
            [-89.2733, 29.9938],
            [-89.2181, 29.9728],
            [-89.2312, 29.9255],
            [-89.2801, 29.9249],
            [-89.3183, 29.8981],
            [-89.2545, 29.8646],
            [-89.363, 29.8458],
            [-89.3377, 29.7791],
            [-89.3861, 29.7888],
            [-89.4481, 29.7033],
            [-89.5303, 29.7437],
            [-89.6512, 29.7495],
            [-89.6446, 29.711],
            [-89.5573, 29.6702],
            [-89.6211, 29.6571],
            [-89.6473, 29.6254],
            [-89.6845, 29.6248],
            [-89.6686, 29.5803],
            [-89.6939, 29.5086],
            [-89.6353, 29.4893],
            [-89.5747, 29.4411],
            [-89.5487, 29.4657],
            [-89.5086, 29.3862],
            [-89.4873, 29.3933],
            [-89.38, 29.3918],
            [-89.3366, 29.3782],
            [-89.3232, 29.344],
            [-89.283, 29.3565],
            [-89.2409, 29.3101],
            [-89.2006, 29.3477],
            [-89.165, 29.303],
            [-89.1001, 29.2502],
            [-89.0907, 29.2],
            [-89.0291, 29.221],
            [-89.0007, 29.1801],
            [-89.0472, 29.1578],
            [-89.026, 29.1301],
            [-89.0623, 29.0702],
            [-89.1215, 29.0691],
            [-89.1648, 29.0087],
            [-89.2111, 29.0408],
            [-89.2172, 29.0673],
            [-89.3347, 29.0403],
            [-89.3548, 29.0725],
            [-89.4057, 29.0869],
            [-89.4094, 29.1279],
            [-89.4828, 29.2151],
            [-89.6064, 29.252],
            [-89.6718, 29.289],
            [-89.7262, 29.304],
            [-89.8503, 29.3118],
            [-89.8551, 29.335],
            [-89.8169, 29.3844],
            [-89.8451, 29.4346],
            [-89.8496, 29.478],
            [-89.9554, 29.4285],
            [-89.992, 29.4635],
            [-90.0323, 29.427],
            [-90.0343, 29.3227],
            [-90.0163, 29.2843],
            [-90.1012, 29.2598],
            [-90.0706, 29.2087],
            [-90.1042, 29.1504],
            [-90.1743, 29.1053],
            [-90.2236, 29.0851],
            [-90.2344, 29.1288],
            [-90.269, 29.1392],
            [-90.3028, 29.1751],
            [-90.2713, 29.2046],
            [-90.3117, 29.238],
            [-90.3328, 29.277],
            [-90.3672, 29.2741],
            [-90.3995, 29.201],
            [-90.4329, 29.1881],
            [-90.4858, 29.2098],
            [-90.4507, 29.2637],
            [-90.5106, 29.2909],
            [-90.5654, 29.2851],
            [-90.5897, 29.2485],
            [-90.5445, 29.2307],
            [-90.5609, 29.2043],
            [-90.6184, 29.2033],
            [-90.6456, 29.1759],
            [-90.647, 29.1286],
            [-90.7312, 29.1229],
            [-90.8446, 29.0673],
            [-90.8982, 29.1313],
            [-90.9613, 29.1808],
            [-91.0001, 29.1695],
            [-91.094, 29.1877],
            [-91.1291, 29.2159],
            [-91.1996, 29.2213],
            [-91.2788, 29.2478],
            [-91.3349, 29.2988],
            [-91.2918, 29.3114],
            [-91.2666, 29.3612],
            [-91.1975, 29.3699],
            [-91.2212, 29.4364],
            [-91.2656, 29.4724],
            [-91.3357, 29.4859],
            [-91.3566, 29.5152],
            [-91.4022, 29.5119],
            [-91.4473, 29.5447],
            [-91.5173, 29.5297],
            [-91.5374, 29.5659],
            [-91.5535, 29.6328],
            [-91.6251, 29.6262],
            [-91.6215, 29.7354],
            [-91.7373, 29.7494],
            [-91.7837, 29.7407],
            [-91.8586, 29.7031],
            [-91.8808, 29.7108],
            [-91.8547, 29.8074],
            [-91.8887, 29.8359],
            [-91.9839, 29.7945],
            [-92.0357, 29.7817],
            [-92.1075, 29.7444],
            [-92.1493, 29.6971],
            [-92.1118, 29.6218],
            [-92.0253, 29.6256],
            [-91.9399, 29.6103],
            [-91.9228, 29.6332],
            [-91.8413, 29.6296],
            [-91.7117, 29.5543],
            [-91.7654, 29.5208],
            [-91.7705, 29.489],
            [-91.8216, 29.4739],
            [-91.8632, 29.5024],
            [-91.9693, 29.5369],
            [-92.0463, 29.5844],
            [-92.159, 29.5815],
            [-92.2519, 29.5394],
            [-92.3113, 29.5334],
            [-92.4022, 29.5513],
            [-92.5572, 29.5698],
            [-92.6537, 29.5881],
            [-92.7441, 29.6176],
            [-92.9743, 29.714],
            [-93.0882, 29.7491],
            [-93.2269, 29.7775],
            [-93.3421, 29.7634],
            [-93.4766, 29.7691],
            [-93.6836, 29.7472],
            [-93.7993, 29.7153],
            [-93.838, 29.6906],
            [-93.87, 29.7355],
            [-93.8916, 29.7446],
            [-93.8939, 29.7673],
            [-93.9265, 29.7896],
            [-93.928, 29.8096],
            [-93.8384, 29.8829],
            [-93.7869, 29.9906],
            [-93.7411, 30.0216],
            [-93.7374, 30.0373],
            [-93.7208, 30.053],
            [-93.7008, 30.0563],
            [-93.7022, 30.0655],
            [-93.7341, 30.0861],
            [-93.7024, 30.1127],
            [-93.7013, 30.1374],
            [-93.6882, 30.1414],
            [-93.7209, 30.2099],
            [-93.7051, 30.2428],
            [-93.7086, 30.2883],
            [-93.7387, 30.3038],
            [-93.7658, 30.3333],
            [-93.7564, 30.3562],
            [-93.758, 30.3835],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Billings Mkt": {
    type: "Feature",
    properties: { code: "MT_BIL", name: "Billings Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-111.049, 44.4741],
          [-111.0552, 44.6249],
          [-111.0552, 45.0013],
          [-111.0446, 45.0016],
          [-111.0382, 45.3512],
          [-110.9177, 45.3513],
          [-110.9192, 45.5253],
          [-110.8582, 45.5264],
          [-110.8571, 45.5909],
          [-110.795, 45.5903],
          [-110.7953, 45.7862],
          [-110.7885, 45.9527],
          [-110.7904, 46.142],
          [-110.784, 46.1924],
          [-110.678, 46.1925],
          [-110.6853, 46.2508],
          [-110.6417, 46.2657],
          [-110.7632, 46.3308],
          [-110.8041, 46.3382],
          [-110.8204, 46.4],
          [-110.889, 46.4466],
          [-110.8951, 46.4779],
          [-110.8577, 46.4939],
          [-110.8521, 46.5427],
          [-110.7907, 46.5805],
          [-110.7443, 46.5485],
          [-110.6908, 46.5318],
          [-110.6593, 46.5643],
          [-110.681, 46.6388],
          [-110.639, 46.6618],
          [-110.5859, 46.7504],
          [-110.4663, 46.6784],
          [-110.3758, 46.6928],
          [-110.3331, 46.6722],
          [-110.2681, 46.712],
          [-110.1871, 46.7249],
          [-110.1208, 46.7231],
          [-110.103, 46.744],
          [-109.9936, 46.7458],
          [-109.9245, 46.6937],
          [-109.8512, 46.6646],
          [-109.8402, 46.621],
          [-109.7718, 46.621],
          [-109.7661, 46.5917],
          [-109.686, 46.5919],
          [-109.6606, 46.549],
          [-109.6168, 46.5193],
          [-109.4943, 46.5193],
          [-109.5147, 46.563],
          [-109.4225, 46.5323],
          [-109.3888, 46.5602],
          [-109.389, 46.751],
          [-108.6309, 46.7494],
          [-108.6308, 46.7962],
          [-108.7126, 46.7784],
          [-108.8266, 46.8066],
          [-108.9008, 46.8468],
          [-108.9397, 46.8535],
          [-109.0687, 46.9047],
          [-109.04, 46.9312],
          [-109.0246, 47.0004],
          [-108.9573, 46.9897],
          [-108.9063, 47.0006],
          [-108.9062, 47.0469],
          [-108.9783, 47.0685],
          [-108.919, 47.0908],
          [-108.7973, 47.0697],
          [-108.8015, 47.2854],
          [-108.7205, 47.2709],
          [-108.5905, 47.2705],
          [-108.5909, 47.3063],
          [-108.3365, 47.3078],
          [-108.315, 47.3149],
          [-108.3126, 47.5835],
          [-108.2824, 47.5796],
          [-108.0613, 47.598],
          [-107.9311, 47.5691],
          [-107.8972, 47.5041],
          [-107.9127, 47.4649],
          [-107.8627, 47.4562],
          [-107.8619, 47.5159],
          [-107.8231, 47.5448],
          [-107.7778, 47.5436],
          [-107.7318, 47.5727],
          [-107.6912, 47.6307],
          [-107.6369, 47.6485],
          [-107.5206, 47.6428],
          [-107.4502, 47.6221],
          [-107.4149, 47.6917],
          [-107.4143, 47.8705],
          [-107.4045, 47.8707],
          [-107.4046, 48.2183],
          [-107.3699, 48.2183],
          [-107.3695, 48.2846],
          [-107.4306, 48.3084],
          [-107.5756, 48.3048],
          [-107.5816, 48.3569],
          [-107.5407, 48.3787],
          [-107.5312, 48.4229],
          [-107.5626, 48.438],
          [-107.561, 48.4797],
          [-107.5232, 48.5018],
          [-107.5244, 48.5359],
          [-107.6108, 48.5551],
          [-107.6658, 48.6005],
          [-107.6381, 48.6592],
          [-107.5648, 48.6579],
          [-107.4906, 48.6843],
          [-107.4911, 48.8284],
          [-107.4277, 48.8892],
          [-107.3497, 48.8542],
          [-107.2909, 48.8516],
          [-107.2303, 48.8235],
          [-107.2433, 48.7957],
          [-107.2064, 48.754],
          [-107.2061, 48.9126],
          [-107.1801, 48.9126],
          [-107.1799, 48.9999],
          [-106.4796, 48.9994],
          [-106.0505, 48.9992],
          [-105.6597, 48.9995],
          [-105.067, 48.9992],
          [-104.5436, 48.9995],
          [-104.0487, 48.9999],
          [-104.047, 48.3907],
          [-104.0441, 47.9961],
          [-103.9585, 47.9532],
          [-103.9678, 47.8475],
          [-104.0054, 47.8035],
          [-104.0425, 47.8068],
          [-104.0455, 47.2157],
          [-104.0459, 46.8308],
          [-104.0461, 46.3839],
          [-104.0454, 45.9453],
          [-104.0401, 45.3742],
          [-104.0401, 45],
          [-104.0577, 44.9974],
          [-104.4704, 44.9985],
          [-104.9809, 45.0003],
          [-105.4811, 45.0004],
          [-105.9001, 45.0008],
          [-105.8729, 44.9548],
          [-105.8852, 44.9272],
          [-105.8312, 44.9127],
          [-105.7772, 44.8735],
          [-105.8127, 44.8372],
          [-105.8009, 44.7861],
          [-105.8377, 44.7621],
          [-105.823, 44.7084],
          [-105.7642, 44.6998],
          [-105.6891, 44.6123],
          [-105.7619, 44.6156],
          [-105.857, 44.6347],
          [-105.9127, 44.6757],
          [-105.9555, 44.6888],
          [-106.0107, 44.683],
          [-106.0145, 44.521],
          [-106.0165, 44.1697],
          [-106.0114, 44.1373],
          [-106.0679, 44.1104],
          [-106.1755, 44.1169],
          [-106.2006, 44.0657],
          [-106.2448, 44.055],
          [-106.2953, 44.0788],
          [-106.4208, 44.0373],
          [-106.461, 44.0118],
          [-106.5127, 44.0231],
          [-106.5799, 43.9877],
          [-106.6416, 43.9818],
          [-106.8073, 43.9282],
          [-106.8859, 43.8793],
          [-106.9434, 43.877],
          [-106.9722, 43.909],
          [-107.019, 43.9032],
          [-107.0751, 43.931],
          [-107.1277, 43.8989],
          [-107.1137, 43.8161],
          [-107.1107, 43.5003],
          [-107.5951, 43.5014],
          [-107.5951, 43.4723],
          [-108.1563, 43.4706],
          [-108.2017, 43.4596],
          [-108.2132, 43.4596],
          [-108.3282, 43.4597],
          [-108.3378, 43.4741],
          [-108.457, 43.4743],
          [-108.457, 43.5032],
          [-108.5762, 43.5033],
          [-108.5762, 43.5326],
          [-108.6951, 43.5323],
          [-108.6947, 43.5758],
          [-108.8143, 43.5756],
          [-108.8143, 43.6053],
          [-108.9338, 43.6047],
          [-108.9341, 43.6192],
          [-109.1716, 43.6198],
          [-109.1714, 43.6993],
          [-109.197, 43.7124],
          [-109.275, 43.7972],
          [-109.3095, 43.8055],
          [-109.336, 43.8421],
          [-109.3678, 43.8447],
          [-109.3802, 43.8828],
          [-109.4424, 43.9304],
          [-109.5106, 43.9386],
          [-109.5423, 43.9614],
          [-109.6418, 43.9228],
          [-109.6725, 43.8681],
          [-109.7892, 43.8027],
          [-109.8542, 43.8259],
          [-109.8166, 43.8805],
          [-109.8584, 43.942],
          [-109.9221, 43.9461],
          [-109.9589, 43.9646],
          [-109.9939, 43.9496],
          [-110.0532, 44.008],
          [-110.0516, 43.4648],
          [-110.0573, 43.3778],
          [-110.3442, 43.3779],
          [-110.344, 43.2912],
          [-110.5784, 43.2916],
          [-110.5787, 43.2352],
          [-110.814, 43.2352],
          [-110.814, 43.2995],
          [-110.9282, 43.2995],
          [-110.9279, 43.316],
          [-111.0446, 43.3157],
          [-111.0457, 43.6475],
          [-111.0473, 43.9999],
          [-111.0491, 44.0201],
          [-111.049, 44.4741],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Missoula Mkt": {
    type: "Feature",
    properties: { code: "MT_MIS", name: "Missoula Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-107.1799, 48.9999],
          [-107.1801, 48.9126],
          [-107.2061, 48.9126],
          [-107.2064, 48.754],
          [-107.2433, 48.7957],
          [-107.2303, 48.8235],
          [-107.2909, 48.8516],
          [-107.3497, 48.8542],
          [-107.4277, 48.8892],
          [-107.4911, 48.8284],
          [-107.4906, 48.6843],
          [-107.5648, 48.6579],
          [-107.6381, 48.6592],
          [-107.6658, 48.6005],
          [-107.6108, 48.5551],
          [-107.5244, 48.5359],
          [-107.5232, 48.5018],
          [-107.561, 48.4797],
          [-107.5626, 48.438],
          [-107.5312, 48.4229],
          [-107.5407, 48.3787],
          [-107.5816, 48.3569],
          [-107.5756, 48.3048],
          [-107.4306, 48.3084],
          [-107.3695, 48.2846],
          [-107.3699, 48.2183],
          [-107.4046, 48.2183],
          [-107.4045, 47.8707],
          [-107.4143, 47.8705],
          [-107.4149, 47.6917],
          [-107.4502, 47.6221],
          [-107.5206, 47.6428],
          [-107.6369, 47.6485],
          [-107.6912, 47.6307],
          [-107.7318, 47.5727],
          [-107.7778, 47.5436],
          [-107.8231, 47.5448],
          [-107.8619, 47.5159],
          [-107.8627, 47.4562],
          [-107.9127, 47.4649],
          [-107.8972, 47.5041],
          [-107.9311, 47.5691],
          [-108.0613, 47.598],
          [-108.2824, 47.5796],
          [-108.3126, 47.5835],
          [-108.315, 47.3149],
          [-108.3365, 47.3078],
          [-108.5909, 47.3063],
          [-108.5905, 47.2705],
          [-108.7205, 47.2709],
          [-108.8015, 47.2854],
          [-108.7973, 47.0697],
          [-108.919, 47.0908],
          [-108.9783, 47.0685],
          [-108.9062, 47.0469],
          [-108.9063, 47.0006],
          [-108.9573, 46.9897],
          [-109.0246, 47.0004],
          [-109.04, 46.9312],
          [-109.0687, 46.9047],
          [-108.9397, 46.8535],
          [-108.9008, 46.8468],
          [-108.8266, 46.8066],
          [-108.7126, 46.7784],
          [-108.6308, 46.7962],
          [-108.6309, 46.7494],
          [-109.389, 46.751],
          [-109.3888, 46.5602],
          [-109.4225, 46.5323],
          [-109.5147, 46.563],
          [-109.4943, 46.5193],
          [-109.6168, 46.5193],
          [-109.6606, 46.549],
          [-109.686, 46.5919],
          [-109.7661, 46.5917],
          [-109.7718, 46.621],
          [-109.8402, 46.621],
          [-109.8512, 46.6646],
          [-109.9245, 46.6937],
          [-109.9936, 46.7458],
          [-110.103, 46.744],
          [-110.1208, 46.7231],
          [-110.1871, 46.7249],
          [-110.2681, 46.712],
          [-110.3331, 46.6722],
          [-110.3758, 46.6928],
          [-110.4663, 46.6784],
          [-110.5859, 46.7504],
          [-110.639, 46.6618],
          [-110.681, 46.6388],
          [-110.6593, 46.5643],
          [-110.6908, 46.5318],
          [-110.7443, 46.5485],
          [-110.7907, 46.5805],
          [-110.8521, 46.5427],
          [-110.8577, 46.4939],
          [-110.8951, 46.4779],
          [-110.889, 46.4466],
          [-110.8204, 46.4],
          [-110.8041, 46.3382],
          [-110.7632, 46.3308],
          [-110.6417, 46.2657],
          [-110.6853, 46.2508],
          [-110.678, 46.1925],
          [-110.784, 46.1924],
          [-110.7904, 46.142],
          [-110.7885, 45.9527],
          [-110.7953, 45.7862],
          [-110.795, 45.5903],
          [-110.8571, 45.5909],
          [-110.8582, 45.5264],
          [-110.9192, 45.5253],
          [-110.9177, 45.3513],
          [-111.0382, 45.3512],
          [-111.0446, 45.0016],
          [-111.0552, 45.0013],
          [-111.0552, 44.6249],
          [-111.049, 44.4741],
          [-111.1314, 44.4999],
          [-111.1757, 44.5522],
          [-111.1896, 44.5711],
          [-111.2302, 44.587],
          [-111.2242, 44.6234],
          [-111.2527, 44.6511],
          [-111.2687, 44.6683],
          [-111.2963, 44.7023],
          [-111.3237, 44.7245],
          [-111.3558, 44.7276],
          [-111.385, 44.7551],
          [-111.3978, 44.7467],
          [-111.3986, 44.7233],
          [-111.4143, 44.7107],
          [-111.4388, 44.7205],
          [-111.4893, 44.7049],
          [-111.4849, 44.6876],
          [-111.4688, 44.6793],
          [-111.4732, 44.6655],
          [-111.5258, 44.6049],
          [-111.5191, 44.5829],
          [-111.4692, 44.552],
          [-111.4717, 44.5408],
          [-111.5008, 44.5401],
          [-111.5858, 44.5628],
          [-111.6144, 44.549],
          [-111.7042, 44.5602],
          [-111.7155, 44.5435],
          [-111.7464, 44.5408],
          [-111.8215, 44.5093],
          [-111.8705, 44.564],
          [-111.9479, 44.5568],
          [-111.9808, 44.5367],
          [-112.0327, 44.5466],
          [-112.0369, 44.5303],
          [-112.069, 44.5371],
          [-112.1068, 44.5208],
          [-112.1365, 44.5399],
          [-112.1646, 44.5417],
          [-112.1839, 44.5331],
          [-112.2217, 44.5435],
          [-112.2301, 44.5628],
          [-112.2428, 44.5681],
          [-112.2862, 44.5685],
          [-112.3129, 44.5535],
          [-112.3192, 44.5391],
          [-112.3542, 44.5356],
          [-112.3589, 44.4863],
          [-112.3874, 44.4481],
          [-112.4732, 44.48],
          [-112.5003, 44.4631],
          [-112.542, 44.484],
          [-112.5842, 44.4814],
          [-112.6019, 44.491],
          [-112.6607, 44.4858],
          [-112.7191, 44.5043],
          [-112.7813, 44.4849],
          [-112.8282, 44.4425],
          [-112.836, 44.4227],
          [-112.8126, 44.3923],
          [-112.8132, 44.3781],
          [-112.8449, 44.3582],
          [-112.8554, 44.36],
          [-112.8818, 44.3803],
          [-112.886, 44.3959],
          [-112.9511, 44.4167],
          [-113.0035, 44.4508],
          [-113.0209, 44.4938],
          [-113.0198, 44.5285],
          [-113.0428, 44.5468],
          [-113.0424, 44.5652],
          [-113.0838, 44.6022],
          [-113.0493, 44.6294],
          [-113.0683, 44.6564],
          [-113.0678, 44.6795],
          [-113.0981, 44.6975],
          [-113.1021, 44.729],
          [-113.1348, 44.7528],
          [-113.1315, 44.7728],
          [-113.1638, 44.7789],
          [-113.1944, 44.8022],
          [-113.2387, 44.8141],
          [-113.3417, 44.7849],
          [-113.354, 44.7917],
          [-113.3461, 44.8006],
          [-113.3772, 44.8349],
          [-113.4224, 44.8426],
          [-113.4551, 44.8654],
          [-113.4987, 44.9423],
          [-113.4438, 44.9599],
          [-113.4469, 44.9985],
          [-113.4377, 45.007],
          [-113.452, 45.0592],
          [-113.4853, 45.0635],
          [-113.5201, 45.093],
          [-113.5066, 45.1073],
          [-113.5133, 45.1152],
          [-113.5547, 45.1129],
          [-113.5747, 45.1284],
          [-113.5995, 45.1911],
          [-113.6369, 45.213],
          [-113.657, 45.2414],
          [-113.6849, 45.2537],
          [-113.6894, 45.2835],
          [-113.7387, 45.3297],
          [-113.7331, 45.3902],
          [-113.7652, 45.4106],
          [-113.7646, 45.4314],
          [-113.7842, 45.4549],
          [-113.76, 45.4807],
          [-113.766, 45.5206],
          [-113.8346, 45.5207],
          [-113.8199, 45.5663],
          [-113.8033, 45.5842],
          [-113.8067, 45.6021],
          [-113.8614, 45.6237],
          [-113.886, 45.617],
          [-113.9047, 45.622],
          [-113.9006, 45.6483],
          [-113.9198, 45.6585],
          [-113.9342, 45.6822],
          [-113.9867, 45.7046],
          [-114.0193, 45.6929],
          [-114.015, 45.654],
          [-114.0676, 45.6277],
          [-114.0866, 45.5912],
          [-114.1223, 45.5843],
          [-114.1352, 45.5575],
          [-114.18, 45.5514],
          [-114.1928, 45.5366],
          [-114.2481, 45.5459],
          [-114.2478, 45.5243],
          [-114.2707, 45.4861],
          [-114.3332, 45.4593],
          [-114.345, 45.4599],
          [-114.3607, 45.4741],
          [-114.3685, 45.4927],
          [-114.4158, 45.5098],
          [-114.4568, 45.544],
          [-114.4605, 45.5613],
          [-114.4982, 45.5555],
          [-114.5261, 45.5708],
          [-114.5495, 45.5606],
          [-114.558, 45.5652],
          [-114.5381, 45.6068],
          [-114.5633, 45.6316],
          [-114.5637, 45.6374],
          [-114.561, 45.6399],
          [-114.5076, 45.6589],
          [-114.4954, 45.7033],
          [-114.5049, 45.7222],
          [-114.5356, 45.7391],
          [-114.5662, 45.7739],
          [-114.513, 45.8288],
          [-114.5093, 45.8455],
          [-114.4487, 45.8589],
          [-114.4095, 45.8516],
          [-114.3882, 45.8823],
          [-114.3951, 45.9015],
          [-114.4312, 45.9357],
          [-114.4209, 45.9492],
          [-114.4251, 45.9727],
          [-114.4412, 45.9885],
          [-114.4773, 46.0008],
          [-114.4738, 46.0166],
          [-114.4934, 46.0372],
          [-114.4685, 46.0625],
          [-114.46, 46.0971],
          [-114.4744, 46.1125],
          [-114.5213, 46.1253],
          [-114.5271, 46.1462],
          [-114.5147, 46.1677],
          [-114.4783, 46.1609],
          [-114.4459, 46.1739],
          [-114.4498, 46.2371],
          [-114.4705, 46.2673],
          [-114.4413, 46.2738],
          [-114.4256, 46.2879],
          [-114.4335, 46.3055],
          [-114.4138, 46.3359],
          [-114.4107, 46.3607],
          [-114.4225, 46.3871],
          [-114.3848, 46.4118],
          [-114.3764, 46.443],
          [-114.4001, 46.4772],
          [-114.403, 46.4987],
          [-114.3587, 46.5053],
          [-114.3421, 46.5197],
          [-114.3487, 46.5338],
          [-114.3317, 46.5719],
          [-114.3207, 46.647],
          [-114.3329, 46.6608],
          [-114.3607, 46.6691],
          [-114.4109, 46.6575],
          [-114.4244, 46.6606],
          [-114.4532, 46.6493],
          [-114.4669, 46.6317],
          [-114.5473, 46.6445],
          [-114.5933, 46.6328],
          [-114.615, 46.6397],
          [-114.6147, 46.6553],
          [-114.6357, 46.6594],
          [-114.6427, 46.6731],
          [-114.6232, 46.6915],
          [-114.6209, 46.7074],
          [-114.6494, 46.7329],
          [-114.6967, 46.7406],
          [-114.7135, 46.7151],
          [-114.7401, 46.7118],
          [-114.7519, 46.6972],
          [-114.7669, 46.6969],
          [-114.7887, 46.714],
          [-114.7672, 46.7388],
          [-114.7651, 46.7582],
          [-114.79, 46.7787],
          [-114.8291, 46.7825],
          [-114.8643, 46.8139],
          [-114.8881, 46.8086],
          [-114.9205, 46.8277],
          [-114.9286, 46.8548],
          [-114.9474, 46.8593],
          [-114.9316, 46.8768],
          [-114.93, 46.9196],
          [-114.9606, 46.93],
          [-115.0013, 46.9719],
          [-115.0495, 46.9708],
          [-115.0878, 47.0455],
          [-115.1071, 47.049],
          [-115.1209, 47.0612],
          [-115.1404, 47.093],
          [-115.1704, 47.1063],
          [-115.2005, 47.1392],
          [-115.2437, 47.1503],
          [-115.2619, 47.1817],
          [-115.3005, 47.1881],
          [-115.2948, 47.2209],
          [-115.3171, 47.2333],
          [-115.3269, 47.2559],
          [-115.3718, 47.2652],
          [-115.4107, 47.2642],
          [-115.4284, 47.2787],
          [-115.4571, 47.2778],
          [-115.5119, 47.2952],
          [-115.5487, 47.3322],
          [-115.5511, 47.3499],
          [-115.5786, 47.367],
          [-115.6172, 47.3825],
          [-115.6392, 47.3786],
          [-115.6577, 47.4007],
          [-115.7288, 47.4289],
          [-115.7288, 47.4452],
          [-115.7182, 47.4532],
          [-115.6639, 47.4569],
          [-115.653, 47.476],
          [-115.6867, 47.4856],
          [-115.7087, 47.5126],
          [-115.7103, 47.5295],
          [-115.7473, 47.5432],
          [-115.7347, 47.5674],
          [-115.7065, 47.5773],
          [-115.6894, 47.5954],
          [-115.6943, 47.6235],
          [-115.7299, 47.6424],
          [-115.7363, 47.6548],
          [-115.7238, 47.6967],
          [-115.7523, 47.7167],
          [-115.7762, 47.7198],
          [-115.7804, 47.7434],
          [-115.7973, 47.7575],
          [-115.8318, 47.7558],
          [-115.8475, 47.7852],
          [-115.8523, 47.828],
          [-115.8709, 47.8349],
          [-115.8815, 47.8497],
          [-115.9064, 47.8463],
          [-115.9691, 47.9143],
          [-115.9937, 47.9262],
          [-116.0308, 47.9733],
          [-116.0489, 47.9772],
          [-116.0492, 48.4812],
          [-116.0492, 48.7927],
          [-116.0492, 49.0009],
          [-115.5505, 49.0007],
          [-115.501, 49.0007],
          [-115.3546, 49],
          [-115.2079, 48.9992],
          [-115.1217, 48.9995],
          [-114.9541, 48.9999],
          [-114.6744, 49.0007],
          [-114.376, 49.0014],
          [-114.251, 49.0009],
          [-114.1802, 48.9997],
          [-114.0682, 48.9989],
          [-113.693, 48.9976],
          [-113.0099, 48.9986],
          [-112.1936, 48.9989],
          [-111.5009, 48.997],
          [-111.0039, 48.9975],
          [-110.2161, 48.9992],
          [-109.9956, 48.9996],
          [-109.5821, 49.0003],
          [-109.3841, 49.0004],
          [-109.2507, 49],
          [-109.0606, 48.9997],
          [-108.9947, 48.9992],
          [-108.1921, 48.9996],
          [-107.441, 48.9994],
          [-107.1799, 48.9999],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Shreveport Mkt": {
    type: "Feature",
    properties: { code: "LA_SHR", name: "Shreveport Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-95.0379, 32.6146],
          [-95.0746, 32.6215],
          [-95.0805, 32.659],
          [-95.1189, 32.6987],
          [-95.0901, 32.7586],
          [-95.1245, 32.7666],
          [-95.1522, 32.83],
          [-95.1521, 32.9025],
          [-95.1047, 32.9026],
          [-95.0805, 32.992],
          [-95.0984, 33.035],
          [-95.0408, 33.0721],
          [-94.9362, 33.0739],
          [-94.9021, 33.0405],
          [-94.8169, 33.0742],
          [-94.8162, 33.0941],
          [-94.7408, 33.0721],
          [-94.7494, 33.1104],
          [-94.6175, 33.1109],
          [-94.5579, 33.0924],
          [-94.5243, 33.0381],
          [-94.4877, 33.0332],
          [-94.4897, 32.9239],
          [-94.4148, 32.8834],
          [-94.3875, 32.9007],
          [-94.3695, 32.9481],
          [-94.339, 32.9425],
          [-94.2805, 32.874],
          [-94.1216, 32.8809],
          [-94.1131, 32.8402],
          [-94.0453, 32.8442],
          [-94.043, 33.0192],
          [-93.3922, 33.0183],
          [-93.0707, 33.0178],
          [-92.692, 33.0141],
          [-92.2009, 33.0089],
          [-91.952, 33.0074],
          [-91.5001, 33.0068],
          [-91.1661, 33.0041],
          [-91.2018, 32.9612],
          [-91.2083, 32.9154],
          [-91.1702, 32.8994],
          [-91.134, 32.9177],
          [-91.1355, 32.9797],
          [-91.0868, 32.9763],
          [-91.0648, 32.9265],
          [-91.0706, 32.8887],
          [-91.1452, 32.8423],
          [-91.1617, 32.8125],
          [-91.1653, 32.7513],
          [-91.0608, 32.7275],
          [-91.0639, 32.7029],
          [-91.1183, 32.6741],
          [-91.1527, 32.6408],
          [-91.1441, 32.6006],
          [-91.1186, 32.5851],
          [-91.0498, 32.6072],
          [-91.0102, 32.6019],
          [-91.0937, 32.5491],
          [-91.1167, 32.5001],
          [-91.0953, 32.4587],
          [-91.0296, 32.4335],
          [-90.993, 32.4507],
          [-90.9663, 32.421],
          [-90.9941, 32.4039],
          [-90.9867, 32.3518],
          [-90.9212, 32.3421],
          [-90.9162, 32.3036],
          [-90.9478, 32.2835],
          [-90.9943, 32.2138],
          [-91.0465, 32.2411],
          [-91.1173, 32.2067],
          [-91.1642, 32.1969],
          [-91.1628, 32.1327],
          [-91.0532, 32.1242],
          [-91.0502, 32.1785],
          [-91.0062, 32.157],
          [-91.0386, 32.0983],
          [-91.08, 32.0792],
          [-91.0987, 32.0484],
          [-91.0759, 32.0168],
          [-91.1174, 31.9871],
          [-91.1638, 31.9826],
          [-91.1883, 31.961],
          [-91.201, 31.9092],
          [-91.2677, 31.8627],
          [-91.2456, 31.8332],
          [-91.2825, 31.8151],
          [-91.2947, 31.8605],
          [-91.3384, 31.8513],
          [-91.3718, 31.7429],
          [-91.3971, 31.7114],
          [-91.3957, 31.6442],
          [-91.4211, 31.6116],
          [-91.4638, 31.6204],
          [-91.4977, 31.6454],
          [-91.5166, 31.6118],
          [-91.4852, 31.5862],
          [-91.4227, 31.5971],
          [-91.4079, 31.5694],
          [-91.4797, 31.5304],
          [-91.5158, 31.5309],
          [-91.5151, 31.4492],
          [-91.465, 31.384],
          [-91.516, 31.3703],
          [-91.5485, 31.4327],
          [-91.5783, 31.3991],
          [-91.549, 31.3473],
          [-91.51, 31.3168],
          [-91.5186, 31.2753],
          [-91.5745, 31.2613],
          [-91.6377, 31.2677],
          [-91.6444, 31.2344],
          [-91.6016, 31.2086],
          [-91.589, 31.1786],
          [-91.6251, 31.1319],
          [-91.6215, 31.1103],
          [-91.5641, 31.0668],
          [-91.5644, 31.039],
          [-91.6369, 30.9994],
          [-91.6598, 31.044],
          [-91.7198, 31.0407],
          [-91.806, 30.9726],
          [-91.8002, 30.9246],
          [-91.8073, 30.8111],
          [-91.7688, 30.7854],
          [-91.7337, 30.7017],
          [-91.7572, 30.5668],
          [-91.8135, 30.5458],
          [-91.88, 30.6215],
          [-91.933, 30.6497],
          [-91.929, 30.6771],
          [-91.9522, 30.7236],
          [-91.9812, 30.7288],
          [-92.0222, 30.6904],
          [-92.0821, 30.6943],
          [-92.1884, 30.777],
          [-92.2232, 30.8142],
          [-92.2666, 30.8169],
          [-92.2668, 30.8464],
          [-92.3224, 30.8293],
          [-92.3598, 30.8381],
          [-92.446, 30.9031],
          [-92.4824, 30.914],
          [-92.5169, 30.8659],
          [-92.4912, 30.8311],
          [-92.5058, 30.7932],
          [-92.4787, 30.7067],
          [-92.4921, 30.641],
          [-92.5566, 30.6303],
          [-92.5977, 30.601],
          [-92.5984, 30.6774],
          [-92.7351, 30.6765],
          [-92.764, 30.7449],
          [-92.7206, 30.7929],
          [-92.7213, 30.826],
          [-92.7626, 30.854],
          [-92.7056, 30.8992],
          [-92.7086, 30.9162],
          [-92.7661, 30.9409],
          [-92.7694, 30.9838],
          [-92.731, 30.9912],
          [-92.7283, 31.0424],
          [-92.7814, 31.0418],
          [-92.8677, 31.0672],
          [-92.9747, 31.1577],
          [-93.0116, 31.1586],
          [-93.1366, 31.1029],
          [-93.1546, 31.0768],
          [-93.2021, 31.0704],
          [-93.1895, 31.0174],
          [-93.1594, 30.981],
          [-93.1615, 30.9418],
          [-93.2347, 30.9416],
          [-93.2805, 30.9229],
          [-93.3726, 30.926],
          [-93.3941, 30.9091],
          [-93.4356, 30.9662],
          [-93.4035, 30.9927],
          [-93.4412, 31.0651],
          [-93.4977, 31.0524],
          [-93.5169, 31.0237],
          [-93.566, 31.0046],
          [-93.5678, 30.9778],
          [-93.5262, 30.9394],
          [-93.5678, 30.8883],
          [-93.5541, 30.8249],
          [-93.5894, 30.7867],
          [-93.6211, 30.6952],
          [-93.6831, 30.6408],
          [-93.6798, 30.5997],
          [-93.7278, 30.5741],
          [-93.7292, 30.5448],
          [-93.6978, 30.4438],
          [-93.758, 30.3835],
          [-93.7842, 30.4229],
          [-93.8046, 30.4238],
          [-93.797, 30.4649],
          [-93.8056, 30.4638],
          [-93.814, 30.5177],
          [-93.8272, 30.5223],
          [-93.8203, 30.5329],
          [-93.8522, 30.5254],
          [-93.8829, 30.5411],
          [-93.8833, 30.5338],
          [-93.9166, 30.521],
          [-93.9373, 30.5327],
          [-93.9482, 30.5017],
          [-93.9498, 30.5213],
          [-93.9669, 30.5393],
          [-94.0002, 30.5457],
          [-94.0131, 30.5337],
          [-94.0359, 30.537],
          [-94.0481, 30.5561],
          [-94.0859, 30.5473],
          [-94.0931, 30.5844],
          [-94.0793, 30.5981],
          [-94.0812, 30.6072],
          [-94.0708, 30.6086],
          [-94.0758, 30.618],
          [-94.0583, 30.6247],
          [-94.0511, 30.6401],
          [-94.0591, 30.6442],
          [-94.0559, 30.6546],
          [-94.0742, 30.6678],
          [-94.0795, 30.6608],
          [-94.0732, 30.6559],
          [-94.0864, 30.6573],
          [-94.0823, 30.6669],
          [-94.0901, 30.6876],
          [-94.1258, 30.6841],
          [-94.123, 30.7009],
          [-94.1374, 30.7143],
          [-94.1295, 30.7194],
          [-94.1382, 30.7319],
          [-94.1283, 30.7457],
          [-94.1386, 30.7323],
          [-94.1299, 30.7195],
          [-94.1378, 30.7148],
          [-94.1347, 30.7061],
          [-94.1632, 30.7003],
          [-94.1644, 30.7083],
          [-94.1784, 30.6998],
          [-94.18, 30.7087],
          [-94.1991, 30.7092],
          [-94.1921, 30.6984],
          [-94.2012, 30.6978],
          [-94.2304, 30.7047],
          [-94.266, 30.7349],
          [-94.2825, 30.7348],
          [-94.2871, 30.7424],
          [-94.2947, 30.7379],
          [-94.303, 30.7446],
          [-94.2978, 30.758],
          [-94.3106, 30.7637],
          [-94.3194, 30.7458],
          [-94.3033, 30.7054],
          [-94.3156, 30.6998],
          [-94.3151, 30.6889],
          [-94.3276, 30.7014],
          [-94.3346, 30.6986],
          [-94.334, 30.6871],
          [-94.3427, 30.6979],
          [-94.3531, 30.6982],
          [-94.3501, 30.6758],
          [-94.3936, 30.6704],
          [-94.3922, 30.6613],
          [-94.4274, 30.6613],
          [-94.4722, 30.6791],
          [-94.4891, 30.7022],
          [-94.5014, 30.6886],
          [-94.4914, 30.6782],
          [-94.4972, 30.6565],
          [-94.5533, 30.6512],
          [-94.5767, 30.6605],
          [-94.616, 30.8301],
          [-94.6328, 30.8118],
          [-94.6594, 30.8508],
          [-94.6625, 30.844],
          [-94.6764, 30.8432],
          [-94.6758, 30.8277],
          [-94.7044, 30.8233],
          [-94.6889, 30.8318],
          [-94.68, 30.8513],
          [-94.6948, 30.8671],
          [-94.7146, 30.8711],
          [-94.7374, 30.8566],
          [-94.7354, 30.8485],
          [-94.7523, 30.8719],
          [-94.7585, 30.8651],
          [-94.7714, 30.8676],
          [-94.7862, 30.8605],
          [-94.7854, 30.8501],
          [-94.7992, 30.8501],
          [-94.7974, 30.8574],
          [-94.8208, 30.8619],
          [-94.8322, 30.8569],
          [-94.8451, 30.8709],
          [-94.8516, 30.8621],
          [-94.8683, 30.864],
          [-94.8655, 30.884],
          [-94.8871, 30.8886],
          [-94.8797, 30.874],
          [-94.897, 30.8421],
          [-94.9349, 30.8308],
          [-94.9525, 30.8364],
          [-94.9603, 30.8467],
          [-94.9535, 30.8644],
          [-94.9702, 30.8955],
          [-94.9598, 30.9106],
          [-94.9582, 30.9339],
          [-94.9467, 30.9441],
          [-94.9567, 30.9599],
          [-94.9707, 30.953],
          [-94.9746, 30.9604],
          [-95.0079, 30.9604],
          [-95.0152, 30.9797],
          [-94.9391, 31.042],
          [-94.8428, 31.1467],
          [-94.8849, 31.1738],
          [-95.0206, 31.1781],
          [-95.056, 31.1874],
          [-95.0646, 31.2465],
          [-95.0424, 31.2539],
          [-95.0224, 31.3417],
          [-94.958, 31.387],
          [-95.057, 31.4649],
          [-95.1007, 31.4684],
          [-95.1, 31.519],
          [-95.1435, 31.5398],
          [-95.1498, 31.5695],
          [-95.2058, 31.5946],
          [-95.2331, 31.637],
          [-95.1629, 31.6366],
          [-95.161, 31.6854],
          [-95.1225, 31.6769],
          [-95.0997, 31.7209],
          [-95.0453, 31.7326],
          [-95.0047, 31.759],
          [-94.9725, 31.7374],
          [-94.8366, 31.7022],
          [-94.7574, 31.7823],
          [-94.7453, 31.8385],
          [-94.7772, 31.844],
          [-94.7767, 31.8885],
          [-94.8143, 31.8894],
          [-94.8296, 31.9174],
          [-94.9056, 31.9108],
          [-94.9738, 31.9418],
          [-94.9854, 31.9884],
          [-94.9854, 32.1864],
          [-95.0153, 32.2535],
          [-94.9854, 32.2634],
          [-95.0356, 32.3374],
          [-95.0292, 32.4378],
          [-95.0761, 32.5375],
          [-94.9977, 32.539],
          [-94.9772, 32.563],
          [-94.9887, 32.6076],
          [-95.0379, 32.6146],
        ],
        [
          [-92.0572, 30.8153],
          [-92.041, 30.7849],
          [-91.9862, 30.7362],
          [-91.9272, 30.7187],
          [-91.904, 30.7408],
          [-91.9407, 30.8013],
          [-91.9808, 30.7973],
          [-92.0051, 30.8199],
          [-92.0572, 30.8153],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Boston Mkt": {
    type: "Feature",
    properties: { code: "MA_BOS", name: "Boston Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-71.1665, 42.8081],
            [-71.1325, 42.8214],
            [-71.0642, 42.8063],
            [-71.0312, 42.8591],
            [-70.9308, 42.8846],
            [-70.8486, 42.8609],
            [-70.8173, 42.8723],
            [-70.8052, 42.7818],
            [-70.7705, 42.7048],
            [-70.7288, 42.6639],
            [-70.6894, 42.6533],
            [-70.6451, 42.6894],
            [-70.6185, 42.6285],
            [-70.6547, 42.5822],
            [-70.8041, 42.5616],
            [-70.8714, 42.5464],
            [-70.8578, 42.4903],
            [-70.922, 42.4667],
            [-70.983, 42.424],
            [-70.9749, 42.3558],
            [-71.0157, 42.326],
            [-70.9891, 42.2674],
            [-70.9109, 42.2654],
            [-70.8828, 42.3089],
            [-70.8511, 42.2683],
            [-70.771, 42.2492],
            [-70.7223, 42.208],
            [-70.7143, 42.1688],
            [-70.6402, 42.0886],
            [-70.6676, 42.0121],
            [-70.7122, 42.0076],
            [-70.6625, 41.9606],
            [-70.5529, 41.9296],
            [-70.5256, 41.8587],
            [-70.541, 41.8158],
            [-70.4716, 41.7616],
            [-70.4125, 41.7444],
            [-70.2907, 41.7342],
            [-70.2592, 41.714],
            [-70.1893, 41.752],
            [-70.122, 41.7588],
            [-70.0247, 41.7874],
            [-70.0038, 41.8085],
            [-70.009, 41.8766],
            [-70.0305, 41.9292],
            [-70.0545, 41.9274],
            [-70.0896, 42.0249],
            [-70.1476, 42.0616],
            [-70.208, 42.0307],
            [-70.2454, 42.0637],
            [-70.1893, 42.0823],
            [-70.116, 42.0676],
            [-70.0335, 42.0177],
            [-69.9861, 41.9496],
            [-69.936, 41.8094],
            [-69.9331, 41.67],
            [-69.9512, 41.6408],
            [-69.9964, 41.6672],
            [-70.0892, 41.6628],
            [-70.2449, 41.6288],
            [-70.3604, 41.6311],
            [-70.4372, 41.6053],
            [-70.4932, 41.552],
            [-70.6336, 41.5383],
            [-70.6541, 41.519],
            [-70.7343, 41.4863],
            [-70.7451, 41.501],
            [-70.6587, 41.5434],
            [-70.6265, 41.713],
            [-70.7187, 41.7357],
            [-70.7196, 41.685],
            [-70.7553, 41.6943],
            [-70.7767, 41.6508],
            [-70.8103, 41.6249],
            [-70.8892, 41.6329],
            [-70.9297, 41.6095],
            [-70.9315, 41.5402],
            [-71.0355, 41.499],
            [-71.0857, 41.5093],
            [-71.1902, 41.4843],
            [-71.2136, 41.5458],
            [-71.2078, 41.6007],
            [-71.2405, 41.6193],
            [-71.2406, 41.5005],
            [-71.3165, 41.4776],
            [-71.3308, 41.5184],
            [-71.2851, 41.5773],
            [-71.2719, 41.624],
            [-71.2121, 41.6419],
            [-71.1956, 41.6751],
            [-71.2248, 41.7105],
            [-71.2597, 41.6427],
            [-71.2912, 41.7027],
            [-71.3501, 41.7278],
            [-71.4093, 41.6626],
            [-71.4038, 41.5893],
            [-71.4477, 41.5804],
            [-71.4174, 41.5345],
            [-71.4184, 41.4727],
            [-71.4558, 41.433],
            [-71.4833, 41.3717],
            [-71.5267, 41.3766],
            [-71.6245, 41.3609],
            [-71.7207, 41.3316],
            [-71.8338, 41.3156],
            [-71.8596, 41.3224],
            [-71.8312, 41.3446],
            [-71.8396, 41.4121],
            [-71.7977, 41.4167],
            [-71.787, 41.656],
            [-71.8007, 42.0236],
            [-72.1988, 42.031],
            [-72.1926, 42.0916],
            [-72.135, 42.0908],
            [-72.1348, 42.1617],
            [-72.1984, 42.2099],
            [-72.2161, 42.2561],
            [-72.1589, 42.3428],
            [-72.1237, 42.3638],
            [-72.0391, 42.3063],
            [-71.9981, 42.3281],
            [-72.0775, 42.4694],
            [-72.0953, 42.5355],
            [-72.1271, 42.5752],
            [-72.1067, 42.6514],
            [-72.1251, 42.7177],
            [-71.7458, 42.7073],
            [-71.2942, 42.697],
            [-71.2455, 42.7426],
            [-71.1818, 42.7376],
            [-71.1861, 42.7907],
            [-71.1665, 42.8081],
          ],
        ],
        [
          [
            [-70.2688, 41.3103],
            [-70.2295, 41.2902],
            [-70.0821, 41.2991],
            [-70.0461, 41.3217],
            [-70.0184, 41.3686],
            [-69.9603, 41.2787],
            [-69.9657, 41.2525],
            [-70.017, 41.2382],
            [-70.1187, 41.2424],
            [-70.2562, 41.2881],
            [-70.2688, 41.3103],
          ],
        ],
        [
          [
            [-70.8388, 41.3472],
            [-70.7689, 41.3532],
            [-70.6869, 41.4413],
            [-70.6036, 41.4824],
            [-70.5633, 41.4691],
            [-70.5476, 41.4158],
            [-70.4845, 41.3863],
            [-70.4513, 41.3482],
            [-70.5992, 41.3493],
            [-70.7098, 41.3417],
            [-70.8021, 41.3142],
            [-70.8388, 41.3472],
          ],
        ],
        [
          [
            [-71.606, 41.1808],
            [-71.5641, 41.1954],
            [-71.552, 41.1517],
            [-71.6117, 41.1532],
            [-71.606, 41.1808],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Springfield Mkt": {
    type: "Feature",
    properties: { code: "MA_SPR", name: "Springfield Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-72.4585, 42.7269],
          [-72.1251, 42.7177],
          [-72.1067, 42.6514],
          [-72.1271, 42.5752],
          [-72.0953, 42.5355],
          [-72.0775, 42.4694],
          [-71.9981, 42.3281],
          [-72.0391, 42.3063],
          [-72.1237, 42.3638],
          [-72.1589, 42.3428],
          [-72.2161, 42.2561],
          [-72.1984, 42.2099],
          [-72.1348, 42.1617],
          [-72.135, 42.0908],
          [-72.1926, 42.0916],
          [-72.1988, 42.031],
          [-72.6959, 42.0368],
          [-72.7517, 42.0302],
          [-72.7667, 42.003],
          [-72.8167, 41.9976],
          [-72.8135, 42.0365],
          [-72.9995, 42.0387],
          [-73.4328, 42.0506],
          [-73.4873, 42.0496],
          [-73.5081, 42.0863],
          [-73.3821, 42.4295],
          [-73.265, 42.7459],
          [-72.8091, 42.7366],
          [-72.4585, 42.7269],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Augusta Mkt": {
    type: "Feature",
    properties: { code: "ME_AUG", name: "Augusta Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-67.6172, 44.521],
            [-67.5627, 44.4721],
            [-67.5883, 44.4498],
            [-67.6172, 44.521],
          ],
        ],
        [
          [
            [-68.5008, 44.16],
            [-68.4242, 44.1906],
            [-68.3849, 44.155],
            [-68.4275, 44.1193],
            [-68.5008, 44.16],
          ],
        ],
        [
          [
            [-68.5127, 44.3848],
            [-68.4788, 44.3196],
            [-68.5304, 44.3336],
            [-68.5127, 44.3848],
          ],
        ],
        [
          [
            [-68.6614, 44.0759],
            [-68.6097, 44.0947],
            [-68.6011, 44.0584],
            [-68.6508, 44.0399],
            [-68.6614, 44.0759],
          ],
        ],
        [
          [
            [-68.7197, 44.2274],
            [-68.6805, 44.2621],
            [-68.6236, 44.2556],
            [-68.6059, 44.2308],
            [-68.643, 44.1577],
            [-68.7204, 44.1692],
            [-68.7197, 44.2274],
          ],
        ],
        [
          [
            [-68.9353, 44.1304],
            [-68.9173, 44.1482],
            [-68.8251, 44.1863],
            [-68.818, 44.1369],
            [-68.7703, 44.0696],
            [-68.8073, 44.0358],
            [-68.8897, 44.0325],
            [-68.9353, 44.1304],
          ],
        ],
        [
          [
            [-68.9537, 44.2723],
            [-68.9193, 44.3099],
            [-68.8875, 44.3031],
            [-68.9169, 44.2429],
            [-68.9537, 44.2723],
          ],
        ],
        [
          [
            [-70.8173, 42.8723],
            [-70.8486, 42.8609],
            [-70.9308, 42.8846],
            [-71.0312, 42.8591],
            [-71.0642, 42.8063],
            [-71.1325, 42.8214],
            [-71.1665, 42.8081],
            [-71.2061, 42.8508],
            [-71.2237, 42.9652],
            [-71.088, 42.9593],
            [-71.0804, 43.0209],
            [-71.0136, 43.0204],
            [-71.0122, 43.082],
            [-71.073, 43.0817],
            [-71.0267, 43.1381],
            [-71.2472, 43.2742],
            [-71.1601, 43.3559],
            [-71.2813, 43.4182],
            [-71.3497, 43.4142],
            [-71.3907, 43.4422],
            [-71.3888, 43.4866],
            [-71.317, 43.5094],
            [-71.3219, 43.5369],
            [-71.261, 43.5721],
            [-71.3311, 43.6198],
            [-71.3004, 43.6641],
            [-71.3417, 43.6954],
            [-71.3026, 43.7144],
            [-71.2495, 43.7672],
            [-71.3299, 43.783],
            [-71.3546, 43.8149],
            [-71.3507, 43.8696],
            [-71.3828, 44.0223],
            [-71.3667, 44.069],
            [-71.3876, 44.1555],
            [-71.3575, 44.166],
            [-71.3436, 44.0922],
            [-71.3119, 44.0917],
            [-71.306, 44.1372],
            [-71.2524, 44.2442],
            [-71.0977, 44.2353],
            [-71.0425, 44.2378],
            [-71.0445, 44.2837],
            [-71.0103, 44.2848],
            [-71.0375, 44.7556],
            [-71.059, 45.0049],
            [-71.0769, 45.2469],
            [-71.0843, 45.3053],
            [-71.0593, 45.3138],
            [-71.0425, 45.313],
            [-71.0382, 45.3119],
            [-71.0091, 45.319],
            [-70.9856, 45.3322],
            [-70.9672, 45.3324],
            [-70.9508, 45.3345],
            [-70.9179, 45.3119],
            [-70.9215, 45.2789],
            [-70.8986, 45.2585],
            [-70.8985, 45.2441],
            [-70.8969, 45.242],
            [-70.8928, 45.2392],
            [-70.885, 45.2349],
            [-70.8785, 45.2333],
            [-70.8619, 45.2301],
            [-70.857, 45.2292],
            [-70.8444, 45.2345],
            [-70.8388, 45.2376],
            [-70.8483, 45.2447],
            [-70.8493, 45.2564],
            [-70.8486, 45.2633],
            [-70.8433, 45.2657],
            [-70.839, 45.2691],
            [-70.8315, 45.2796],
            [-70.8297, 45.2904],
            [-70.8123, 45.302],
            [-70.8086, 45.3116],
            [-70.8071, 45.3225],
            [-70.8083, 45.3258],
            [-70.8166, 45.3306],
            [-70.8198, 45.3401],
            [-70.8027, 45.3666],
            [-70.8062, 45.3766],
            [-70.8124, 45.3833],
            [-70.8223, 45.3905],
            [-70.8252, 45.3933],
            [-70.826, 45.3984],
            [-70.8256, 45.4003],
            [-70.8128, 45.4114],
            [-70.8022, 45.418],
            [-70.7987, 45.4241],
            [-70.7981, 45.4266],
            [-70.798, 45.4267],
            [-70.7968, 45.4273],
            [-70.7956, 45.4279],
            [-70.795, 45.4281],
            [-70.7815, 45.4312],
            [-70.7556, 45.4284],
            [-70.754, 45.4278],
            [-70.7441, 45.4211],
            [-70.7448, 45.4172],
            [-70.7438, 45.4119],
            [-70.73, 45.3994],
            [-70.7227, 45.3953],
            [-70.7123, 45.3906],
            [-70.6925, 45.3926],
            [-70.6873, 45.3932],
            [-70.6851, 45.3948],
            [-70.6846, 45.3951],
            [-70.6835, 45.3952],
            [-70.678, 45.3944],
            [-70.6612, 45.386],
            [-70.6608, 45.3782],
            [-70.66, 45.3779],
            [-70.6512, 45.3771],
            [-70.646, 45.3787],
            [-70.6347, 45.3836],
            [-70.6332, 45.3983],
            [-70.6321, 45.4087],
            [-70.6314, 45.4163],
            [-70.6355, 45.4278],
            [-70.6497, 45.4428],
            [-70.663, 45.4465],
            [-70.6749, 45.4524],
            [-70.6811, 45.4584],
            [-70.6855, 45.4637],
            [-70.6918, 45.4712],
            [-70.717, 45.4877],
            [-70.7232, 45.5076],
            [-70.7234, 45.5104],
            [-70.7228, 45.5128],
            [-70.7216, 45.5151],
            [-70.645, 45.6067],
            [-70.5913, 45.6306],
            [-70.5584, 45.6667],
            [-70.5528, 45.6678],
            [-70.5258, 45.6666],
            [-70.5195, 45.67],
            [-70.5195, 45.6717],
            [-70.5189, 45.6723],
            [-70.5102, 45.6793],
            [-70.4966, 45.687],
            [-70.4699, 45.7016],
            [-70.4515, 45.7044],
            [-70.4469, 45.704],
            [-70.4389, 45.7044],
            [-70.4308, 45.7072],
            [-70.4004, 45.7198],
            [-70.3904, 45.7285],
            [-70.3836, 45.7349],
            [-70.3885, 45.7497],
            [-70.3961, 45.7563],
            [-70.4065, 45.7618],
            [-70.4157, 45.7862],
            [-70.4176, 45.7938],
            [-70.4177, 45.7946],
            [-70.4169, 45.7953],
            [-70.408, 45.7976],
            [-70.4063, 45.7975],
            [-70.4043, 45.7963],
            [-70.3996, 45.7962],
            [-70.3993, 45.7965],
            [-70.3961, 45.7987],
            [-70.3959, 45.7989],
            [-70.3962, 45.801],
            [-70.3963, 45.8026],
            [-70.3982, 45.8041],
            [-70.3966, 45.8085],
            [-70.3879, 45.819],
            [-70.3808, 45.8246],
            [-70.3709, 45.8321],
            [-70.367, 45.835],
            [-70.3424, 45.8522],
            [-70.3297, 45.8538],
            [-70.3172, 45.8564],
            [-70.3126, 45.8578],
            [-70.3068, 45.8596],
            [-70.3062, 45.8597],
            [-70.2842, 45.872],
            [-70.2627, 45.8879],
            [-70.2624, 45.888],
            [-70.2616, 45.8887],
            [-70.2591, 45.8908],
            [-70.2537, 45.903],
            [-70.2539, 45.9065],
            [-70.2579, 45.9181],
            [-70.258, 45.9182],
            [-70.2611, 45.9203],
            [-70.2633, 45.9202],
            [-70.2622, 45.9252],
            [-70.2402, 45.9437],
            [-70.253, 45.9552],
            [-70.2654, 45.9627],
            [-70.2743, 45.9643],
            [-70.2808, 45.9652],
            [-70.313, 45.9619],
            [-70.3163, 45.9631],
            [-70.3169, 45.9639],
            [-70.3097, 45.9802],
            [-70.3075, 45.9825],
            [-70.3013, 45.9854],
            [-70.296, 45.986],
            [-70.2846, 45.9954],
            [-70.2849, 45.9956],
            [-70.2929, 45.9974],
            [-70.3036, 45.9995],
            [-70.3176, 46.0195],
            [-70.2797, 46.0522],
            [-70.2789, 46.0607],
            [-70.3067, 46.0613],
            [-70.309, 46.0625],
            [-70.3097, 46.0634],
            [-70.3105, 46.0643],
            [-70.3106, 46.0645],
            [-70.3003, 46.0793],
            [-70.2964, 46.0849],
            [-70.2898, 46.0943],
            [-70.2846, 46.0987],
            [-70.2727, 46.1023],
            [-70.2665, 46.101],
            [-70.2663, 46.101],
            [-70.2573, 46.1],
            [-70.2543, 46.0996],
            [-70.2536, 46.0998],
            [-70.2526, 46.1005],
            [-70.2524, 46.1006],
            [-70.2524, 46.1018],
            [-70.2524, 46.1028],
            [-70.2524, 46.1062],
            [-70.2551, 46.1102],
            [-70.2436, 46.1288],
            [-70.2396, 46.1428],
            [-70.2379, 46.1474],
            [-70.2403, 46.1501],
            [-70.2663, 46.1694],
            [-70.278, 46.175],
            [-70.2909, 46.1858],
            [-70.2927, 46.1914],
            [-70.2927, 46.1916],
            [-70.2484, 46.2671],
            [-70.2402, 46.2761],
            [-70.2327, 46.2844],
            [-70.2057, 46.2999],
            [-70.2087, 46.329],
            [-70.1914, 46.3481],
            [-70.1887, 46.35],
            [-70.1747, 46.3585],
            [-70.1613, 46.361],
            [-70.1485, 46.3589],
            [-70.1412, 46.3627],
            [-70.1334, 46.3689],
            [-70.1276, 46.3719],
            [-70.1275, 46.3787],
            [-70.126, 46.3807],
            [-70.1255, 46.3814],
            [-70.1186, 46.3842],
            [-70.1104, 46.3861],
            [-70.0892, 46.4107],
            [-70.0761, 46.4094],
            [-70.0576, 46.4149],
            [-70.0564, 46.4156],
            [-70.0564, 46.4166],
            [-70.0416, 46.4863],
            [-70.0265, 46.5572],
            [-70.0238, 46.5711],
            [-70.0233, 46.574],
            [-70.0197, 46.5922],
            [-69.9971, 46.6952],
            [-69.9942, 46.6986],
            [-69.95, 46.7425],
            [-69.9431, 46.75],
            [-69.8745, 46.818],
            [-69.8186, 46.875],
            [-69.5664, 47.125],
            [-69.2244, 47.4597],
            [-69.2039, 47.4522],
            [-69.182, 47.4556],
            [-69.1784, 47.4566],
            [-69.1224, 47.4419],
            [-69.1082, 47.4358],
            [-69.0985, 47.4312],
            [-69.0968, 47.427],
            [-69.0825, 47.424],
            [-69.0807, 47.4241],
            [-69.0667, 47.4302],
            [-69.0612, 47.4331],
            [-69.0439, 47.4276],
            [-69.0427, 47.4267],
            [-69.0393, 47.4222],
            [-69.0369, 47.408],
            [-69.0391, 47.4041],
            [-69.0407, 47.4027],
            [-69.0423, 47.4012],
            [-69.0424, 47.4011],
            [-69.0464, 47.3839],
            [-69.0539, 47.3779],
            [-69.0533, 47.3276],
            [-69.0546, 47.3159],
            [-69.0491, 47.3065],
            [-69.0513, 47.2998],
            [-69.0526, 47.2983],
            [-69.0523, 47.2931],
            [-69.0507, 47.2926],
            [-69.0501, 47.2915],
            [-69.0505, 47.2806],
            [-69.0471, 47.2671],
            [-69.0503, 47.2566],
            [-69.0402, 47.2451],
            [-69.0335, 47.241],
            [-69.0238, 47.2384],
            [-68.9811, 47.2199],
            [-68.9664, 47.2127],
            [-68.9425, 47.2064],
            [-68.8957, 47.1829],
            [-68.8122, 47.2155],
            [-68.7179, 47.2409],
            [-68.6641, 47.2368],
            [-68.6539, 47.2395],
            [-68.639, 47.2414],
            [-68.625, 47.2424],
            [-68.6197, 47.2432],
            [-68.6079, 47.2475],
            [-68.6048, 47.2494],
            [-68.5941, 47.2593],
            [-68.595, 47.2632],
            [-68.5987, 47.2679],
            [-68.5987, 47.2699],
            [-68.5786, 47.2876],
            [-68.5711, 47.287],
            [-68.5604, 47.2837],
            [-68.5539, 47.2822],
            [-68.5517, 47.2822],
            [-68.5466, 47.283],
            [-68.5396, 47.2852],
            [-68.5291, 47.2926],
            [-68.518, 47.2961],
            [-68.5074, 47.2966],
            [-68.504, 47.2963],
            [-68.4967, 47.2945],
            [-68.4867, 47.2946],
            [-68.479, 47.2969],
            [-68.4749, 47.2975],
            [-68.4663, 47.2949],
            [-68.464, 47.2923],
            [-68.4601, 47.2861],
            [-68.4583, 47.2846],
            [-68.4488, 47.2825],
            [-68.4432, 47.2835],
            [-68.4326, 47.2819],
            [-68.4252, 47.2832],
            [-68.4159, 47.2878],
            [-68.4131, 47.2885],
            [-68.3899, 47.2867],
            [-68.3831, 47.2867],
            [-68.3787, 47.2876],
            [-68.3768, 47.2885],
            [-68.377, 47.2956],
            [-68.3787, 47.2974],
            [-68.3841, 47.3015],
            [-68.3846, 47.3223],
            [-68.3843, 47.3269],
            [-68.3803, 47.3402],
            [-68.3703, 47.3511],
            [-68.3616, 47.3556],
            [-68.3362, 47.3598],
            [-68.3232, 47.3599],
            [-68.3099, 47.3562],
            [-68.3007, 47.3561],
            [-68.2927, 47.3595],
            [-68.2893, 47.3602],
            [-68.2841, 47.3604],
            [-68.2651, 47.3525],
            [-68.248, 47.3525],
            [-68.2346, 47.355],
            [-68.2146, 47.3396],
            [-68.1525, 47.3215],
            [-68.1371, 47.2961],
            [-68.0197, 47.238],
            [-67.9919, 47.212],
            [-67.9902, 47.211],
            [-67.9861, 47.2093],
            [-67.9714, 47.2071],
            [-67.9557, 47.1995],
            [-67.9523, 47.1961],
            [-67.9258, 47.1542],
            [-67.9104, 47.1481],
            [-67.9012, 47.1351],
            [-67.8933, 47.1299],
            [-67.8922, 47.1286],
            [-67.89, 47.1247],
            [-67.8892, 47.1188],
            [-67.8887, 47.1117],
            [-67.8856, 47.1073],
            [-67.8838, 47.1058],
            [-67.8813, 47.1039],
            [-67.8781, 47.1024],
            [-67.8704, 47.1009],
            [-67.8646, 47.0992],
            [-67.849, 47.0935],
            [-67.8369, 47.087],
            [-67.8233, 47.0843],
            [-67.7905, 47.0679],
            [-67.79, 47.0664],
            [-67.7899, 47.0661],
            [-67.7898, 47.0657],
            [-67.7895, 47.0625],
            [-67.789, 47.0258],
            [-67.7896, 47.0154],
            [-67.7893, 47.0036],
            [-67.7898, 47],
            [-67.7892, 46.881],
            [-67.7898, 46.796],
            [-67.7884, 46.5998],
            [-67.7852, 46.4666],
            [-67.7845, 46.4376],
            [-67.7821, 46.2794],
            [-67.7804, 46.0385],
            [-67.7805, 46.001],
            [-67.7814, 45.9639],
            [-67.7813, 45.9577],
            [-67.7811, 45.943],
            [-67.7736, 45.9348],
            [-67.7575, 45.9259],
            [-67.7504, 45.9179],
            [-67.7519, 45.915],
            [-67.7557, 45.9117],
            [-67.765, 45.9098],
            [-67.7686, 45.9022],
            [-67.7687, 45.9019],
            [-67.7687, 45.898],
            [-67.8039, 45.8829],
            [-67.7551, 45.8237],
            [-67.7638, 45.8203],
            [-67.7668, 45.8185],
            [-67.7804, 45.8162],
            [-67.802, 45.8035],
            [-67.8066, 45.7947],
            [-67.8036, 45.7816],
            [-67.8055, 45.7698],
            [-67.8091, 45.7675],
            [-67.8063, 45.7554],
            [-67.7931, 45.7506],
            [-67.7819, 45.7312],
            [-67.8098, 45.7293],
            [-67.8031, 45.6961],
            [-67.8179, 45.6937],
            [-67.8033, 45.6779],
            [-67.7686, 45.6776],
            [-67.7542, 45.6678],
            [-67.7444, 45.6659],
            [-67.7416, 45.6654],
            [-67.7304, 45.6632],
            [-67.7208, 45.6625],
            [-67.7105, 45.6794],
            [-67.6926, 45.6504],
            [-67.6754, 45.631],
            [-67.6458, 45.6136],
            [-67.6402, 45.6162],
            [-67.6442, 45.6232],
            [-67.6397, 45.6248],
            [-67.6318, 45.6214],
            [-67.6062, 45.6065],
            [-67.5833, 45.6025],
            [-67.5614, 45.5949],
            [-67.5569, 45.5951],
            [-67.5563, 45.5973],
            [-67.5461, 45.5981],
            [-67.4994, 45.587],
            [-67.4767, 45.6042],
            [-67.4554, 45.6047],
            [-67.4497, 45.6029],
            [-67.4255, 45.5791],
            [-67.4236, 45.5722],
            [-67.421, 45.55],
            [-67.4322, 45.541],
            [-67.4353, 45.5308],
            [-67.4351, 45.5292],
            [-67.435, 45.5288],
            [-67.4344, 45.5267],
            [-67.4322, 45.52],
            [-67.4307, 45.5188],
            [-67.4277, 45.5166],
            [-67.4263, 45.5155],
            [-67.4205, 45.5111],
            [-67.4164, 45.5035],
            [-67.4165, 45.5021],
            [-67.4174, 45.502],
            [-67.421, 45.5051],
            [-67.4242, 45.5059],
            [-67.4251, 45.5042],
            [-67.4252, 45.5039],
            [-67.4257, 45.5029],
            [-67.4277, 45.5013],
            [-67.4297, 45.5016],
            [-67.45, 45.5049],
            [-67.4629, 45.5087],
            [-67.5031, 45.4897],
            [-67.5038, 45.4885],
            [-67.5032, 45.4854],
            [-67.4998, 45.4781],
            [-67.4944, 45.473],
            [-67.4869, 45.468],
            [-67.4824, 45.4608],
            [-67.4819, 45.4583],
            [-67.4849, 45.456],
            [-67.4843, 45.452],
            [-67.4772, 45.4316],
            [-67.4752, 45.4284],
            [-67.4745, 45.4272],
            [-67.4734, 45.4253],
            [-67.4713, 45.4235],
            [-67.4585, 45.416],
            [-67.4481, 45.4078],
            [-67.4386, 45.4],
            [-67.437, 45.3987],
            [-67.4313, 45.394],
            [-67.43, 45.393],
            [-67.4187, 45.3773],
            [-67.4215, 45.3741],
            [-67.4238, 45.3735],
            [-67.4272, 45.3737],
            [-67.4343, 45.3654],
            [-67.4335, 45.3613],
            [-67.4278, 45.3555],
            [-67.435, 45.3401],
            [-67.4563, 45.3264],
            [-67.4567, 45.3256],
            [-67.4564, 45.3239],
            [-67.4523, 45.3196],
            [-67.4533, 45.3143],
            [-67.4606, 45.3004],
            [-67.4665, 45.2938],
            [-67.4893, 45.2813],
            [-67.4406, 45.2277],
            [-67.44, 45.227],
            [-67.4371, 45.2227],
            [-67.4314, 45.21],
            [-67.4289, 45.1932],
            [-67.4046, 45.1599],
            [-67.3836, 45.1523],
            [-67.37, 45.1417],
            [-67.3637, 45.1369],
            [-67.3612, 45.1349],
            [-67.3571, 45.1318],
            [-67.3456, 45.1264],
            [-67.3408, 45.1254],
            [-67.3399, 45.1256],
            [-67.3298, 45.1317],
            [-67.3185, 45.1394],
            [-67.2982, 45.1467],
            [-67.2962, 45.148],
            [-67.2949, 45.1497],
            [-67.2944, 45.1535],
            [-67.297, 45.1553],
            [-67.3017, 45.1571],
            [-67.3026, 45.1613],
            [-67.2992, 45.1689],
            [-67.2906, 45.1876],
            [-67.2898, 45.1887],
            [-67.2836, 45.192],
            [-67.2711, 45.1911],
            [-67.2467, 45.1808],
            [-67.2423, 45.1722],
            [-67.2232, 45.1637],
            [-67.1579, 45.161],
            [-67.1124, 45.1123],
            [-67.0821, 45.0296],
            [-67.0383, 44.9454],
            [-66.9846, 44.9127],
            [-66.9965, 44.8447],
            [-66.9797, 44.8074],
            [-67.0734, 44.742],
            [-67.1894, 44.6455],
            [-67.2741, 44.6263],
            [-67.3096, 44.6593],
            [-67.3085, 44.7075],
            [-67.356, 44.6991],
            [-67.3811, 44.6695],
            [-67.3632, 44.6321],
            [-67.4577, 44.598],
            [-67.5058, 44.6368],
            [-67.5524, 44.6196],
            [-67.5751, 44.5607],
            [-67.5682, 44.5311],
            [-67.6485, 44.5254],
            [-67.6859, 44.5372],
            [-67.7143, 44.4953],
            [-67.7434, 44.4974],
            [-67.7539, 44.5437],
            [-67.8088, 44.5441],
            [-67.8567, 44.5239],
            [-67.8689, 44.4569],
            [-67.9857, 44.3869],
            [-68.0493, 44.3307],
            [-68.1123, 44.4016],
            [-68.1232, 44.4788],
            [-68.171, 44.4702],
            [-68.2139, 44.4925],
            [-68.2982, 44.4492],
            [-68.1845, 44.3691],
            [-68.1736, 44.3284],
            [-68.2334, 44.2886],
            [-68.2982, 44.2763],
            [-68.2909, 44.2476],
            [-68.3393, 44.2229],
            [-68.4013, 44.2522],
            [-68.4309, 44.3126],
            [-68.398, 44.3762],
            [-68.4296, 44.4391],
            [-68.4946, 44.4297],
            [-68.5345, 44.3978],
            [-68.5669, 44.3176],
            [-68.5195, 44.265],
            [-68.5508, 44.2365],
            [-68.6034, 44.2747],
            [-68.683, 44.2992],
            [-68.733, 44.3284],
            [-68.827, 44.3121],
            [-68.8148, 44.3622],
            [-68.8292, 44.4622],
            [-68.8802, 44.4282],
            [-68.9009, 44.4521],
            [-68.9908, 44.415],
            [-68.9482, 44.3559],
            [-68.9589, 44.3144],
            [-69.0402, 44.2337],
            [-69.0545, 44.1715],
            [-69.1008, 44.1046],
            [-69.0643, 44.0699],
            [-69.0942, 44.039],
            [-69.1938, 43.9755],
            [-69.2142, 43.9356],
            [-69.2598, 43.9214],
            [-69.2805, 43.9574],
            [-69.3314, 43.9743],
            [-69.4416, 43.9643],
            [-69.4596, 43.9033],
            [-69.5247, 43.8756],
            [-69.5526, 43.8413],
            [-69.6046, 43.858],
            [-69.6533, 43.791],
            [-69.7058, 43.823],
            [-69.7528, 43.7559],
            [-69.8353, 43.7211],
            [-69.8697, 43.7757],
            [-69.927, 43.7802],
            [-69.9891, 43.7432],
            [-70.0344, 43.759],
            [-69.9982, 43.7987],
            [-70.0536, 43.8284],
            [-70.19, 43.7719],
            [-70.1947, 43.7421],
            [-70.2518, 43.6833],
            [-70.2112, 43.6258],
            [-70.1969, 43.5651],
            [-70.2725, 43.5626],
            [-70.3612, 43.5292],
            [-70.3849, 43.496],
            [-70.3705, 43.4341],
            [-70.3909, 43.4026],
            [-70.466, 43.3402],
            [-70.5352, 43.3368],
            [-70.5628, 43.3106],
            [-70.5939, 43.2493],
            [-70.5758, 43.2219],
            [-70.6187, 43.164],
            [-70.6384, 43.1142],
            [-70.7615, 42.9867],
            [-70.8107, 42.9069],
            [-70.8173, 42.8723],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Detroit Mkt": {
    type: "Feature",
    properties: { code: "MI_DET", name: "Detroit Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-84.0695, 43.1258],
          [-84.0275, 43.1321],
          [-84.009, 43.1755],
          [-83.9704, 43.2076],
          [-83.9917, 43.2491],
          [-83.9922, 43.2923],
          [-83.9437, 43.3222],
          [-83.8494, 43.3218],
          [-83.8021, 43.2934],
          [-83.7648, 43.3071],
          [-83.6953, 43.3067],
          [-83.6812, 43.2364],
          [-83.6413, 43.2367],
          [-83.621, 43.1942],
          [-83.5603, 43.1947],
          [-83.5506, 43.2227],
          [-83.4607, 43.2231],
          [-83.4122, 43.2629],
          [-83.4129, 43.2919],
          [-83.3536, 43.2932],
          [-83.3529, 43.2654],
          [-83.1982, 43.2745],
          [-83.1383, 43.2542],
          [-83.1446, 43.3555],
          [-83.1048, 43.3564],
          [-83.1119, 43.5446],
          [-83.0745, 43.5456],
          [-83.0573, 43.6405],
          [-83.0172, 43.6344],
          [-83.018, 43.6785],
          [-83.0977, 43.676],
          [-83.1184, 43.6899],
          [-83.1204, 43.7768],
          [-83.1408, 43.805],
          [-83.0816, 43.8498],
          [-83.1033, 43.9068],
          [-83.1436, 43.9201],
          [-83.1445, 43.949],
          [-83.1812, 43.9822],
          [-83.0466, 44.0157],
          [-83.0246, 44.0452],
          [-82.916, 44.0705],
          [-82.8759, 44.045],
          [-82.7932, 44.0232],
          [-82.739, 43.9895],
          [-82.6786, 43.8837],
          [-82.6336, 43.8312],
          [-82.6122, 43.7398],
          [-82.6005, 43.6029],
          [-82.5857, 43.544],
          [-82.5395, 43.4375],
          [-82.5231, 43.2254],
          [-82.486, 43.1025],
          [-82.4228, 43.008],
          [-82.4159, 43.0056],
          [-82.4226, 43],
          [-82.4242, 42.9969],
          [-82.4245, 42.9934],
          [-82.4231, 42.9887],
          [-82.4228, 42.9883],
          [-82.421, 42.9855],
          [-82.4202, 42.9843],
          [-82.413, 42.977],
          [-82.4167, 42.9666],
          [-82.4286, 42.952],
          [-82.455, 42.9269],
          [-82.4699, 42.8875],
          [-82.4682, 42.8591],
          [-82.469, 42.8523],
          [-82.4727, 42.8368],
          [-82.4786, 42.8252],
          [-82.482, 42.8086],
          [-82.4816, 42.8055],
          [-82.4804, 42.8023],
          [-82.4712, 42.784],
          [-82.4674, 42.7693],
          [-82.4675, 42.7619],
          [-82.4839, 42.718],
          [-82.4945, 42.7008],
          [-82.5105, 42.6652],
          [-82.5099, 42.6373],
          [-82.5188, 42.6139],
          [-82.5233, 42.6075],
          [-82.5482, 42.5918],
          [-82.5792, 42.5653],
          [-82.584, 42.554],
          [-82.5898, 42.5507],
          [-82.6047, 42.5486],
          [-82.6071, 42.5488],
          [-82.6111, 42.5504],
          [-82.6168, 42.5546],
          [-82.6249, 42.5572],
          [-82.6335, 42.5571],
          [-82.6409, 42.555],
          [-82.6427, 42.5543],
          [-82.6617, 42.5419],
          [-82.6808, 42.5579],
          [-82.6898, 42.6271],
          [-82.6457, 42.6311],
          [-82.6238, 42.6654],
          [-82.6858, 42.69],
          [-82.7973, 42.654],
          [-82.819, 42.6163],
          [-82.7815, 42.5716],
          [-82.8459, 42.5606],
          [-82.8744, 42.5235],
          [-82.8839, 42.4718],
          [-82.8704, 42.4509],
          [-82.8884, 42.3982],
          [-82.9594, 42.3396],
          [-83.0641, 42.3177],
          [-83.0965, 42.2901],
          [-83.128, 42.2388],
          [-83.1339, 42.1747],
          [-83.1213, 42.1257],
          [-83.1335, 42.0881],
          [-83.1886, 42.0664],
          [-83.1872, 42.0076],
          [-83.2492, 41.9724],
          [-83.2695, 41.939],
          [-83.3263, 41.9244],
          [-83.3415, 41.8801],
          [-83.382, 41.8709],
          [-83.4434, 41.7891],
          [-83.4241, 41.7407],
          [-83.4538, 41.7326],
          [-84.2108, 41.7108],
          [-84.8061, 41.6961],
          [-84.8059, 41.7602],
          [-84.9423, 41.7596],
          [-84.9663, 41.7791],
          [-84.9322, 41.8257],
          [-84.8351, 41.8329],
          [-84.8253, 41.8691],
          [-84.7883, 41.8926],
          [-84.8258, 41.9446],
          [-84.8341, 42.0726],
          [-84.8911, 42.0726],
          [-84.9048, 42.1198],
          [-84.9346, 42.1595],
          [-84.9256, 42.2031],
          [-84.8474, 42.2326],
          [-84.8283, 42.2907],
          [-84.8291, 42.3489],
          [-84.7972, 42.378],
          [-84.797, 42.4508],
          [-84.7575, 42.4222],
          [-84.6105, 42.4217],
          [-84.5679, 42.4508],
          [-84.5617, 42.5094],
          [-84.463, 42.4949],
          [-84.2975, 42.51],
          [-84.2351, 42.5017],
          [-84.2214, 42.552],
          [-84.083, 42.5537],
          [-84.0123, 42.5103],
          [-83.8549, 42.5126],
          [-83.8478, 42.5536],
          [-83.8196, 42.5703],
          [-83.7968, 42.6166],
          [-83.7505, 42.6181],
          [-83.7576, 42.6553],
          [-83.8084, 42.6582],
          [-83.8469, 42.7194],
          [-83.881, 42.7181],
          [-83.8948, 42.7815],
          [-84.0887, 42.7775],
          [-84.1138, 42.8549],
          [-84.1674, 42.886],
          [-84.1102, 42.9422],
          [-84.0361, 42.9463],
          [-83.9868, 42.9705],
          [-83.9872, 43.0348],
          [-83.9475, 43.0589],
          [-83.9483, 43.0878],
          [-84.0273, 43.0877],
          [-84.0695, 43.1258],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Grand Rapids Mkt": {
    type: "Feature",
    properties: { code: "MI_RAP", name: "Grand Rapids Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-86.0594, 45.1523],
            [-85.9891, 45.1507],
            [-85.9841, 45.0871],
            [-86.0131, 45.0638],
            [-86.0604, 45.1046],
            [-86.0594, 45.1523],
          ],
        ],
        [
          [
            [-85.3737, 45.1306],
            [-85.3357, 45.1499],
            [-85.285, 45.1482],
            [-85.2784, 45.1194],
            [-85.1968, 45.0853],
            [-85.1246, 45.0233],
            [-85.0647, 45.0092],
            [-84.9676, 45.0205],
            [-84.9689, 44.9307],
            [-84.8722, 44.935],
            [-84.8485, 44.8985],
            [-84.8486, 44.75],
            [-84.898, 44.7031],
            [-84.9759, 44.7133],
            [-84.9777, 44.6765],
            [-85.0046, 44.6409],
            [-84.9868, 44.6189],
            [-84.9275, 44.621],
            [-84.8494, 44.6554],
            [-84.8558, 44.4298],
            [-84.8518, 44.1613],
            [-84.9542, 44.1588],
            [-84.9544, 44.1105],
            [-85.0229, 44.0761],
            [-85.0875, 43.9864],
            [-85.0886, 43.8352],
            [-85.1423, 43.8281],
            [-85.1483, 43.7849],
            [-85.0688, 43.7987],
            [-85.0283, 43.7553],
            [-85.0881, 43.7556],
            [-85.0676, 43.69],
            [-85.0079, 43.6974],
            [-85.008, 43.7264],
            [-84.9086, 43.7413],
            [-84.8571, 43.7125],
            [-84.8433, 43.7475],
            [-84.7678, 43.7496],
            [-84.6477, 43.7133],
            [-84.599, 43.6807],
            [-84.5276, 43.6837],
            [-84.5079, 43.6692],
            [-84.5083, 43.6113],
            [-84.4881, 43.5316],
            [-84.5087, 43.4989],
            [-84.5084, 43.4225],
            [-84.5283, 43.408],
            [-84.528, 43.3352],
            [-84.4495, 43.3211],
            [-84.4488, 43.2918],
            [-84.3805, 43.2776],
            [-84.3866, 43.23],
            [-84.3071, 43.2151],
            [-84.2668, 43.1725],
            [-84.2662, 43.1288],
            [-84.0695, 43.1258],
            [-84.0273, 43.0877],
            [-83.9483, 43.0878],
            [-83.9475, 43.0589],
            [-83.9872, 43.0348],
            [-83.9868, 42.9705],
            [-84.0361, 42.9463],
            [-84.1102, 42.9422],
            [-84.1674, 42.886],
            [-84.1138, 42.8549],
            [-84.0887, 42.7775],
            [-83.8948, 42.7815],
            [-83.881, 42.7181],
            [-83.8469, 42.7194],
            [-83.8084, 42.6582],
            [-83.7576, 42.6553],
            [-83.7505, 42.6181],
            [-83.7968, 42.6166],
            [-83.8196, 42.5703],
            [-83.8478, 42.5536],
            [-83.8549, 42.5126],
            [-84.0123, 42.5103],
            [-84.083, 42.5537],
            [-84.2214, 42.552],
            [-84.2351, 42.5017],
            [-84.2975, 42.51],
            [-84.463, 42.4949],
            [-84.5617, 42.5094],
            [-84.5679, 42.4508],
            [-84.6105, 42.4217],
            [-84.7575, 42.4222],
            [-84.797, 42.4508],
            [-84.7972, 42.378],
            [-84.8291, 42.3489],
            [-84.8283, 42.2907],
            [-84.8474, 42.2326],
            [-84.9256, 42.2031],
            [-84.9346, 42.1595],
            [-84.9048, 42.1198],
            [-84.8911, 42.0726],
            [-84.8341, 42.0726],
            [-84.8258, 41.9446],
            [-84.7883, 41.8926],
            [-84.8253, 41.8691],
            [-84.8351, 41.8329],
            [-84.9322, 41.8257],
            [-84.9663, 41.7791],
            [-84.9423, 41.7596],
            [-85.5435, 41.7594],
            [-85.8154, 41.7591],
            [-85.8135, 41.8043],
            [-85.9159, 41.81],
            [-85.9744, 41.7888],
            [-86.001, 41.8394],
            [-86.0497, 41.84],
            [-86.1274, 41.8653],
            [-86.2231, 41.9421],
            [-86.2229, 42.0223],
            [-86.2383, 42.0897],
            [-86.2765, 42.072],
            [-86.3589, 42.0797],
            [-86.4016, 42.0607],
            [-86.4091, 42.0323],
            [-86.3791, 42.0017],
            [-86.3786, 41.9914],
            [-86.3791, 42.0017],
            [-86.4352, 42.0002],
            [-86.5454, 42.0169],
            [-86.4852, 42.1182],
            [-86.4041, 42.1964],
            [-86.3562, 42.2542],
            [-86.2972, 42.3582],
            [-86.2498, 42.4798],
            [-86.2256, 42.5948],
            [-86.2291, 42.6377],
            [-86.2068, 42.7194],
            [-86.2107, 42.8591],
            [-86.2263, 42.9883],
            [-86.2546, 43.0834],
            [-86.3163, 43.1951],
            [-86.3958, 43.3162],
            [-86.4351, 43.3967],
            [-86.4793, 43.5153],
            [-86.5385, 43.6175],
            [-86.5292, 43.6779],
            [-86.4616, 43.7467],
            [-86.431, 43.816],
            [-86.4631, 43.971],
            [-86.5147, 44.0581],
            [-86.4299, 44.1198],
            [-86.3628, 44.2081],
            [-86.3438, 44.2496],
            [-86.2687, 44.3453],
            [-86.2481, 44.4211],
            [-86.2489, 44.483],
            [-86.2207, 44.5667],
            [-86.254, 44.6481],
            [-86.2568, 44.6868],
            [-86.2325, 44.706],
            [-86.1211, 44.728],
            [-86.0734, 44.7712],
            [-86.0667, 44.9057],
            [-85.9802, 44.9061],
            [-85.9316, 44.9688],
            [-85.8543, 44.9381],
            [-85.8074, 44.9498],
            [-85.7804, 44.9779],
            [-85.7464, 45.0512],
            [-85.6811, 45.0927],
            [-85.6557, 45.1462],
            [-85.6186, 45.1868],
            [-85.5315, 45.1772],
            [-85.6143, 45.1276],
            [-85.5661, 45.0436],
            [-85.6219, 45.0045],
            [-85.6024, 44.9743],
            [-85.6489, 44.874],
            [-85.6514, 44.8316],
            [-85.628, 44.7675],
            [-85.5936, 44.7688],
            [-85.5817, 44.8078],
            [-85.5306, 44.8898],
            [-85.5397, 44.9168],
            [-85.5201, 44.9739],
            [-85.4752, 44.991],
            [-85.498, 44.8655],
            [-85.5602, 44.8101],
            [-85.5765, 44.7603],
            [-85.5275, 44.7482],
            [-85.4997, 44.8037],
            [-85.4748, 44.815],
            [-85.3958, 44.931],
            [-85.3664, 45.069],
            [-85.3737, 45.1306],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Saginaw Mkt": {
    type: "Feature",
    properties: { code: "MI_SAG", name: "Saginaw Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-84.6513, 45.8628],
            [-84.5893, 45.8258],
            [-84.356, 45.7719],
            [-84.4189, 45.7217],
            [-84.5006, 45.7371],
            [-84.5499, 45.7899],
            [-84.5874, 45.8066],
            [-84.6513, 45.8628],
          ],
        ],
        [
          [
            [-83.1812, 43.9822],
            [-83.1445, 43.949],
            [-83.1436, 43.9201],
            [-83.1033, 43.9068],
            [-83.0816, 43.8498],
            [-83.1408, 43.805],
            [-83.1204, 43.7768],
            [-83.1184, 43.6899],
            [-83.0977, 43.676],
            [-83.018, 43.6785],
            [-83.0172, 43.6344],
            [-83.0573, 43.6405],
            [-83.0745, 43.5456],
            [-83.1119, 43.5446],
            [-83.1048, 43.3564],
            [-83.1446, 43.3555],
            [-83.1383, 43.2542],
            [-83.1982, 43.2745],
            [-83.3529, 43.2654],
            [-83.3536, 43.2932],
            [-83.4129, 43.2919],
            [-83.4122, 43.2629],
            [-83.4607, 43.2231],
            [-83.5506, 43.2227],
            [-83.5603, 43.1947],
            [-83.621, 43.1942],
            [-83.6413, 43.2367],
            [-83.6812, 43.2364],
            [-83.6953, 43.3067],
            [-83.7648, 43.3071],
            [-83.8021, 43.2934],
            [-83.8494, 43.3218],
            [-83.9437, 43.3222],
            [-83.9922, 43.2923],
            [-83.9917, 43.2491],
            [-83.9704, 43.2076],
            [-84.009, 43.1755],
            [-84.0275, 43.1321],
            [-84.0695, 43.1258],
            [-84.2662, 43.1288],
            [-84.2668, 43.1725],
            [-84.3071, 43.2151],
            [-84.3866, 43.23],
            [-84.3805, 43.2776],
            [-84.4488, 43.2918],
            [-84.4495, 43.3211],
            [-84.528, 43.3352],
            [-84.5283, 43.408],
            [-84.5084, 43.4225],
            [-84.5087, 43.4989],
            [-84.4881, 43.5316],
            [-84.5083, 43.6113],
            [-84.5079, 43.6692],
            [-84.5276, 43.6837],
            [-84.599, 43.6807],
            [-84.6477, 43.7133],
            [-84.7678, 43.7496],
            [-84.8433, 43.7475],
            [-84.8571, 43.7125],
            [-84.9086, 43.7413],
            [-85.008, 43.7264],
            [-85.0079, 43.6974],
            [-85.0676, 43.69],
            [-85.0881, 43.7556],
            [-85.0283, 43.7553],
            [-85.0688, 43.7987],
            [-85.1483, 43.7849],
            [-85.1423, 43.8281],
            [-85.0886, 43.8352],
            [-85.0875, 43.9864],
            [-85.0229, 44.0761],
            [-84.9544, 44.1105],
            [-84.9542, 44.1588],
            [-84.8518, 44.1613],
            [-84.8558, 44.4298],
            [-84.8494, 44.6554],
            [-84.9275, 44.621],
            [-84.9868, 44.6189],
            [-85.0046, 44.6409],
            [-84.9777, 44.6765],
            [-84.9759, 44.7133],
            [-84.898, 44.7031],
            [-84.8486, 44.75],
            [-84.8485, 44.8985],
            [-84.8722, 44.935],
            [-84.9689, 44.9307],
            [-84.9676, 45.0205],
            [-85.0647, 45.0092],
            [-85.1246, 45.0233],
            [-85.1968, 45.0853],
            [-85.2784, 45.1194],
            [-85.285, 45.1482],
            [-85.3357, 45.1499],
            [-85.3737, 45.1306],
            [-85.3886, 45.2352],
            [-85.3716, 45.2708],
            [-85.3076, 45.3131],
            [-85.2738, 45.3154],
            [-85.2097, 45.3569],
            [-85.1437, 45.3704],
            [-85.0328, 45.3613],
            [-84.9159, 45.3931],
            [-84.922, 45.4219],
            [-85.0409, 45.4367],
            [-85.0884, 45.4769],
            [-85.1197, 45.569],
            [-85.0749, 45.6292],
            [-85.0153, 45.6516],
            [-84.9104, 45.75],
            [-84.7996, 45.7471],
            [-84.7728, 45.7893],
            [-84.5555, 45.7023],
            [-84.4617, 45.6524],
            [-84.4136, 45.6694],
            [-84.3295, 45.6644],
            [-84.2153, 45.6348],
            [-84.1395, 45.5737],
            [-84.1092, 45.5052],
            [-83.9095, 45.4858],
            [-83.807, 45.4193],
            [-83.7218, 45.4133],
            [-83.5993, 45.3526],
            [-83.4967, 45.3575],
            [-83.4224, 45.2908],
            [-83.3817, 45.269],
            [-83.4126, 45.2458],
            [-83.3599, 45.163],
            [-83.3184, 45.1289],
            [-83.2715, 45.0234],
            [-83.3403, 45.0415],
            [-83.3993, 45.0704],
            [-83.4542, 45.0319],
            [-83.4437, 44.9522],
            [-83.394, 44.9031],
            [-83.3205, 44.8806],
            [-83.3006, 44.8298],
            [-83.2973, 44.7461],
            [-83.2734, 44.7139],
            [-83.3145, 44.6087],
            [-83.3085, 44.5399],
            [-83.3268, 44.4444],
            [-83.3326, 44.3403],
            [-83.3643, 44.3326],
            [-83.4258, 44.2725],
            [-83.5088, 44.2737],
            [-83.5491, 44.2273],
            [-83.5841, 44.0567],
            [-83.6501, 44.0524],
            [-83.6879, 44.0207],
            [-83.6801, 43.9942],
            [-83.7638, 43.9852],
            [-83.8291, 43.9891],
            [-83.8777, 43.9592],
            [-83.9168, 43.8991],
            [-83.9294, 43.7771],
            [-83.9543, 43.7506],
            [-83.9095, 43.6726],
            [-83.8521, 43.6449],
            [-83.7699, 43.6349],
            [-83.6698, 43.5908],
            [-83.5248, 43.7169],
            [-83.4801, 43.7146],
            [-83.4107, 43.8077],
            [-83.4076, 43.832],
            [-83.3323, 43.8805],
            [-83.3474, 43.9122],
            [-83.2823, 43.938],
            [-83.2615, 43.9735],
            [-83.1812, 43.9822],
          ],
        ],
        [
          [
            [-85.4318, 46.2379],
            [-85.4288, 46.2163],
            [-85.4258, 46.1923],
            [-85.447, 46.2051],
            [-85.467, 46.2231],
            [-85.4721, 46.2441],
            [-85.4318, 46.2379],
          ],
        ],
        [
          [
            [-85.4756, 46.1159],
            [-85.517, 46.1266],
            [-85.4801, 46.122],
            [-85.4518, 46.1308],
            [-85.3979, 46.1439],
            [-85.4041, 46.2146],
            [-85.3823, 46.2204],
            [-85.3842, 46.2316],
            [-85.4113, 46.2457],
            [-85.2378, 46.2454],
            [-85.2393, 46.5341],
            [-85.2372, 46.7558],
            [-85.173, 46.7636],
            [-85.0636, 46.7579],
            [-84.9516, 46.7695],
            [-85.0076, 46.7283],
            [-85.0277, 46.6967],
            [-85.0355, 46.5815],
            [-85.0271, 46.543],
            [-85.0561, 46.5265],
            [-85.0152, 46.4797],
            [-84.8614, 46.4699],
            [-84.8001, 46.4462],
            [-84.6784, 46.4877],
            [-84.631, 46.4849],
            [-84.5516, 46.4186],
            [-84.5037, 46.4392],
            [-84.4626, 46.4409],
            [-84.4633, 46.4674],
            [-84.4451, 46.489],
            [-84.4203, 46.5011],
            [-84.374, 46.5091],
            [-84.3436, 46.5077],
            [-84.3377, 46.5056],
            [-84.3254, 46.5],
            [-84.293, 46.4928],
            [-84.2758, 46.4928],
            [-84.2654, 46.4944],
            [-84.2544, 46.5008],
            [-84.2261, 46.5339],
            [-84.1774, 46.5269],
            [-84.166, 46.5262],
            [-84.1531, 46.5283],
            [-84.1518, 46.5288],
            [-84.1501, 46.5296],
            [-84.149, 46.5301],
            [-84.1458, 46.5312],
            [-84.1394, 46.5322],
            [-84.1289, 46.5301],
            [-84.1112, 46.5041],
            [-84.1462, 46.4185],
            [-84.1389, 46.3722],
            [-84.1191, 46.337],
            [-84.1062, 46.322],
            [-84.1196, 46.315],
            [-84.1182, 46.234],
            [-84.2195, 46.232],
            [-84.2492, 46.2065],
            [-84.221, 46.1631],
            [-84.125, 46.1802],
            [-84.1001, 46.1508],
            [-84.0266, 46.1317],
            [-84.0724, 46.0967],
            [-83.9895, 46.0328],
            [-83.9439, 46.0315],
            [-83.9005, 45.9989],
            [-83.8454, 46.0257],
            [-83.7941, 45.9958],
            [-83.7653, 46.0184],
            [-83.7738, 46.0515],
            [-83.8125, 46.0735],
            [-83.7039, 46.1034],
            [-83.635, 46.104],
            [-83.5816, 46.0896],
            [-83.5329, 46.0113],
            [-83.4806, 45.9962],
            [-83.5106, 45.9293],
            [-83.5618, 45.9126],
            [-83.6577, 45.9455],
            [-83.6877, 45.9354],
            [-83.7861, 45.9334],
            [-83.8811, 45.9682],
            [-83.9213, 45.9581],
            [-84.0176, 45.959],
            [-84.1112, 45.9787],
            [-84.33, 45.9562],
            [-84.3764, 45.932],
            [-84.5334, 46.0057],
            [-84.5639, 46.0325],
            [-84.6091, 46.0264],
            [-84.6566, 46.0527],
            [-84.6927, 46.027],
            [-84.6877, 45.9713],
            [-84.723, 45.9673],
            [-84.734, 45.907],
            [-84.7012, 45.8531],
            [-84.747, 45.8356],
            [-84.8314, 45.872],
            [-84.8422, 45.8982],
            [-84.9175, 45.9307],
            [-85.014, 46.0108],
            [-85.152, 46.0507],
            [-85.1975, 46.0449],
            [-85.3359, 46.0926],
            [-85.3814, 46.082],
            [-85.5303, 46.1007],
            [-85.4756, 46.1159],
          ],
        ],
        [
          [
            [-85.6198, 45.6245],
            [-85.6049, 45.6819],
            [-85.5723, 45.7114],
            [-85.5664, 45.7602],
            [-85.4988, 45.7263],
            [-85.5061, 45.6811],
            [-85.487, 45.6212],
            [-85.5341, 45.5782],
            [-85.618, 45.5826],
            [-85.6198, 45.6245],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Rapid City Mkt": {
    type: "Feature",
    properties: { code: "SD_RAP", name: "Rapid City Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-108.3282, 43.4597],
          [-108.2132, 43.4596],
          [-108.2017, 43.4596],
          [-108.1563, 43.4706],
          [-107.5951, 43.4723],
          [-107.5951, 43.5014],
          [-107.1107, 43.5003],
          [-107.1137, 43.8161],
          [-107.1277, 43.8989],
          [-107.0751, 43.931],
          [-107.019, 43.9032],
          [-106.9722, 43.909],
          [-106.9434, 43.877],
          [-106.8859, 43.8793],
          [-106.8073, 43.9282],
          [-106.6416, 43.9818],
          [-106.5799, 43.9877],
          [-106.5127, 44.0231],
          [-106.461, 44.0118],
          [-106.4208, 44.0373],
          [-106.2953, 44.0788],
          [-106.2448, 44.055],
          [-106.2006, 44.0657],
          [-106.1755, 44.1169],
          [-106.0679, 44.1104],
          [-106.0114, 44.1373],
          [-106.0165, 44.1697],
          [-106.0145, 44.521],
          [-106.0107, 44.683],
          [-105.9555, 44.6888],
          [-105.9127, 44.6757],
          [-105.857, 44.6347],
          [-105.7619, 44.6156],
          [-105.6891, 44.6123],
          [-105.7642, 44.6998],
          [-105.823, 44.7084],
          [-105.8377, 44.7621],
          [-105.8009, 44.7861],
          [-105.8127, 44.8372],
          [-105.7772, 44.8735],
          [-105.8312, 44.9127],
          [-105.8852, 44.9272],
          [-105.8729, 44.9548],
          [-105.9001, 45.0008],
          [-105.4811, 45.0004],
          [-104.9809, 45.0003],
          [-104.4704, 44.9985],
          [-104.0577, 44.9974],
          [-104.0401, 45],
          [-104.0401, 45.3742],
          [-104.0454, 45.9453],
          [-103.5587, 45.9451],
          [-102.9205, 45.945],
          [-102.3543, 45.9449],
          [-102.3521, 46.1043],
          [-102.3311, 46.1623],
          [-102.3091, 46.1771],
          [-102.2478, 46.1622],
          [-102.2478, 46.1332],
          [-102.1648, 46.1041],
          [-102.1647, 46.0752],
          [-101.9985, 46.0749],
          [-101.998, 46.1474],
          [-101.874, 46.1473],
          [-101.8741, 46.118],
          [-101.729, 46.1181],
          [-101.7285, 46.036],
          [-101.8001, 46.0321],
          [-101.8738, 46.0633],
          [-101.8744, 45.9445],
          [-101.4158, 45.9438],
          [-100.6277, 45.9436],
          [-100.0069, 45.942],
          [-100.0071, 45.8545],
          [-99.8824, 45.8546],
          [-99.924, 45.8255],
          [-99.9238, 45.7675],
          [-99.9656, 45.7676],
          [-99.9657, 45.7241],
          [-99.8826, 45.7239],
          [-99.8622, 45.6658],
          [-99.9659, 45.666],
          [-100.0688, 45.6518],
          [-100.0689, 45.5939],
          [-100.1819, 45.5942],
          [-100.1822, 45.5166],
          [-100.2229, 45.492],
          [-100.223, 45.3771],
          [-100.2742, 45.3818],
          [-100.3167, 45.3179],
          [-100.2575, 45.2395],
          [-100.2898, 45.1401],
          [-100.3242, 45.1016],
          [-100.3158, 45.0156],
          [-100.3918, 45.026],
          [-100.4234, 44.9921],
          [-100.4048, 44.8977],
          [-100.1836, 44.8977],
          [-100.1628, 44.8529],
          [-100.0813, 44.8527],
          [-100.102, 44.8976],
          [-99.5306, 44.8968],
          [-99.5308, 44.8668],
          [-99.582, 44.8524],
          [-99.6745, 44.8525],
          [-99.6755, 44.7074],
          [-99.6142, 44.7076],
          [-99.6146, 44.6785],
          [-99.5335, 44.6785],
          [-99.5348, 44.5482],
          [-99.563, 44.4979],
          [-99.5228, 44.4547],
          [-99.5629, 44.4254],
          [-99.5631, 44.3529],
          [-99.6035, 44.3529],
          [-99.6035, 44.28],
          [-99.5429, 44.2799],
          [-99.5225, 44.2512],
          [-99.5225, 44.1931],
          [-99.5767, 44.1925],
          [-99.5406, 44.1501],
          [-99.5625, 44.1015],
          [-99.5113, 44.055],
          [-99.4331, 44.0449],
          [-99.3537, 43.9953],
          [-99.3632, 43.9154],
          [-99.4863, 43.9049],
          [-99.4663, 43.8466],
          [-99.3958, 43.7918],
          [-99.419, 43.7448],
          [-99.407, 43.7053],
          [-99.4563, 43.6869],
          [-99.4602, 43.6566],
          [-99.3754, 43.6569],
          [-99.3556, 43.6423],
          [-99.3396, 43.5809],
          [-99.2833, 43.5312],
          [-99.3107, 43.474],
          [-99.2757, 43.4425],
          [-99.1777, 43.4379],
          [-99.1539, 43.4277],
          [-99.0845, 43.3242],
          [-99.0647, 43.2813],
          [-99.0843, 43.2442],
          [-99.0821, 43.1965],
          [-99.0606, 43.1492],
          [-99.0999, 43.0915],
          [-99.0803, 43.0824],
          [-99.08, 42.9984],
          [-99.7431, 42.9979],
          [-100.1984, 42.9985],
          [-101.0004, 42.9975],
          [-101.6254, 42.9962],
          [-101.85, 42.9993],
          [-102.3003, 42.9995],
          [-102.8109, 43],
          [-102.8421, 43.0314],
          [-102.8107, 43.0923],
          [-102.8288, 43.13],
          [-102.9382, 43.1259],
          [-103.001, 43.1585],
          [-103.0009, 43.0005],
          [-103.4046, 43.0007],
          [-104.0531, 43.0006],
          [-104.0548, 43.5031],
          [-104.5138, 43.5018],
          [-104.5935, 43.453],
          [-104.6594, 43.4306],
          [-104.6716, 43.3593],
          [-104.6717, 43.3242],
          [-104.7404, 43.2996],
          [-104.717, 43.2344],
          [-104.7581, 43.2316],
          [-104.7458, 43.1711],
          [-104.8158, 43.1462],
          [-104.8492, 43.119],
          [-104.898, 43.1236],
          [-104.898, 42.999],
          [-104.9413, 42.9687],
          [-105.1025, 42.9583],
          [-105.1003, 42.7998],
          [-105.044, 42.8035],
          [-104.992, 42.7816],
          [-105.01, 42.7363],
          [-105.0099, 42.6823],
          [-105.0749, 42.6082],
          [-105.2865, 42.6058],
          [-105.2823, 42.4313],
          [-105.3326, 42.4311],
          [-105.3617, 42.3763],
          [-105.3586, 42.3193],
          [-105.3755, 42.2901],
          [-105.5264, 42.2915],
          [-105.6139, 42.3045],
          [-105.6147, 42.3774],
          [-105.5756, 42.3773],
          [-105.556, 42.4306],
          [-105.6987, 42.4311],
          [-106.3222, 42.4332],
          [-106.625, 42.4305],
          [-107.2875, 42.4348],
          [-107.542, 42.4344],
          [-107.5435, 42.7816],
          [-107.5014, 42.7815],
          [-107.5021, 43.0246],
          [-107.6234, 42.9653],
          [-107.8083, 43.0108],
          [-107.8849, 43.0577],
          [-107.9828, 43.0402],
          [-108.0316, 43.1056],
          [-108.0712, 43.1151],
          [-108.1451, 43.0715],
          [-108.1449, 43.1241],
          [-108.1807, 43.119],
          [-108.2964, 43.1531],
          [-108.3883, 43.1541],
          [-108.3647, 43.196],
          [-108.2411, 43.2073],
          [-108.1925, 43.2379],
          [-108.2135, 43.3014],
          [-108.1832, 43.338],
          [-108.2534, 43.3461],
          [-108.1887, 43.3895],
          [-108.1884, 43.4162],
          [-108.3145, 43.4008],
          [-108.3571, 43.4367],
          [-108.3282, 43.4597],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Sioux Falls Mkt": {
    type: "Feature",
    properties: { code: "SD_SXF", name: "Sioux Falls Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-100.0069, 45.942],
          [-99.2577, 45.9401],
          [-98.4145, 45.9365],
          [-98.0082, 45.9361],
          [-97.3958, 45.9351],
          [-96.5633, 45.9352],
          [-96.5766, 46.0218],
          [-96.494, 46.0216],
          [-96.4523, 45.964],
          [-96.3284, 45.9639],
          [-96.2663, 45.9781],
          [-96.1626, 45.9781],
          [-96.1303, 45.9347],
          [-96.1303, 45.8759],
          [-95.9869, 45.8612],
          [-95.9861, 45.832],
          [-95.9239, 45.8321],
          [-95.9232, 45.7596],
          [-95.8415, 45.7599],
          [-95.8154, 45.7162],
          [-95.7588, 45.7163],
          [-95.7585, 45.6327],
          [-95.7891, 45.5715],
          [-95.6935, 45.5717],
          [-95.6861, 45.4922],
          [-95.6035, 45.4921],
          [-95.593, 45.4703],
          [-95.5312, 45.4665],
          [-95.521, 45.4121],
          [-95.2198, 45.4128],
          [-95.1935, 45.3693],
          [-95.1316, 45.3838],
          [-95.0498, 45.3835],
          [-94.9684, 45.3691],
          [-94.9518, 45.3488],
          [-94.8457, 45.3696],
          [-94.825, 45.3994],
          [-94.7838, 45.3886],
          [-94.7838, 45.3261],
          [-94.8048, 45.2829],
          [-94.7636, 45.2682],
          [-94.6426, 45.2723],
          [-94.6401, 45.2655],
          [-94.6159, 45.2598],
          [-94.6044, 45.2396],
          [-94.6045, 45.1669],
          [-94.6044, 45.124],
          [-94.6553, 45.1237],
          [-94.645, 45.0875],
          [-94.6757, 45.0805],
          [-94.676, 45.0369],
          [-94.676, 44.9935],
          [-94.6351, 44.9643],
          [-94.584, 44.9503],
          [-94.5844, 44.8922],
          [-94.6108, 44.8919],
          [-94.6918, 44.8919],
          [-94.865, 44.8922],
          [-94.865, 44.8772],
          [-94.8944, 44.8767],
          [-94.9154, 44.8328],
          [-94.9156, 44.771],
          [-94.9354, 44.7311],
          [-94.9761, 44.7307],
          [-94.976, 44.687],
          [-94.9555, 44.6437],
          [-94.9555, 44.614],
          [-94.9346, 44.5696],
          [-94.9571, 44.5672],
          [-94.9507, 44.5463],
          [-94.9668, 44.527],
          [-94.9265, 44.5091],
          [-94.9263, 44.4855],
          [-94.9052, 44.487],
          [-94.9061, 44.4959],
          [-94.8908, 44.4962],
          [-94.8759, 44.5068],
          [-94.8657, 44.4834],
          [-94.8331, 44.4735],
          [-94.8307, 44.4598],
          [-94.8202, 44.4661],
          [-94.7735, 44.4448],
          [-94.7853, 44.4272],
          [-94.7854, 44.404],
          [-94.8056, 44.4017],
          [-94.8661, 44.3907],
          [-94.8427, 44.3549],
          [-94.8363, 44.3405],
          [-94.8765, 44.3258],
          [-94.9473, 44.3261],
          [-94.9671, 44.3406],
          [-95.1069, 44.3398],
          [-95.1272, 44.3691],
          [-95.2079, 44.3257],
          [-95.2089, 44.317],
          [-95.2081, 44.2967],
          [-95.2086, 44.2689],
          [-95.1682, 44.2676],
          [-95.1689, 44.2245],
          [-95.189, 44.2242],
          [-95.2097, 44.2098],
          [-95.2017, 44.1806],
          [-95.197, 44.1662],
          [-95.1912, 44.1491],
          [-95.2014, 44.1373],
          [-95.2005, 44.0938],
          [-95.08, 44.0938],
          [-95.08, 44.0794],
          [-95.0199, 44.0791],
          [-94.9997, 44.0591],
          [-94.9801, 44.0498],
          [-94.8594, 44.0572],
          [-94.8496, 44.0363],
          [-94.8355, 44.0306],
          [-94.8295, 44.0457],
          [-94.8395, 44.065],
          [-94.8195, 44.0756],
          [-94.8395, 44.0794],
          [-94.7793, 44.0945],
          [-94.7793, 44.0654],
          [-94.739, 44.0655],
          [-94.759, 44.0508],
          [-94.739, 44.0364],
          [-94.7391, 43.9397],
          [-94.7222, 43.9322],
          [-94.6993, 43.9354],
          [-94.7028, 43.9191],
          [-94.713, 43.9204],
          [-94.706, 43.9069],
          [-94.7196, 43.9063],
          [-94.7196, 43.8698],
          [-94.7094, 43.848],
          [-94.6947, 43.8524],
          [-94.6794, 43.8771],
          [-94.6396, 43.8625],
          [-94.5923, 43.8333],
          [-94.5922, 43.7754],
          [-94.5722, 43.7608],
          [-94.5611, 43.7423],
          [-94.5721, 43.7428],
          [-94.5719, 43.7172],
          [-94.5323, 43.6734],
          [-94.5325, 43.6448],
          [-94.5725, 43.6503],
          [-94.5922, 43.6378],
          [-94.5916, 43.5728],
          [-94.5969, 43.5512],
          [-94.5818, 43.5471],
          [-94.5522, 43.5581],
          [-94.5324, 43.5438],
          [-94.5323, 43.5004],
          [-94.6984, 43.4933],
          [-94.6405, 43.4305],
          [-94.6202, 43.3708],
          [-94.6597, 43.3563],
          [-94.6595, 43.2916],
          [-94.6397, 43.2696],
          [-94.5799, 43.2695],
          [-94.5604, 43.2126],
          [-94.6395, 43.2268],
          [-94.7236, 43.2132],
          [-94.7247, 43.1837],
          [-94.8367, 43.1837],
          [-94.8366, 43.1121],
          [-94.8753, 43.024],
          [-94.973, 43.0513],
          [-94.9736, 42.9826],
          [-94.9141, 42.9823],
          [-94.9141, 42.9098],
          [-94.9537, 42.9244],
          [-95.0532, 42.9098],
          [-95.1514, 43.0103],
          [-95.2112, 43.0101],
          [-95.2035, 42.9098],
          [-95.21, 42.8366],
          [-95.2494, 42.822],
          [-95.2495, 42.7343],
          [-95.2697, 42.7343],
          [-95.2698, 42.6189],
          [-95.2893, 42.5607],
          [-95.1902, 42.561],
          [-95.2101, 42.5326],
          [-95.2095, 42.4747],
          [-95.2448, 42.4695],
          [-95.2449, 42.4146],
          [-95.3032, 42.4003],
          [-95.4008, 42.4139],
          [-95.4203, 42.4573],
          [-95.468, 42.4555],
          [-95.5549, 42.3992],
          [-95.5552, 42.3126],
          [-95.5371, 42.2836],
          [-95.5368, 42.2254],
          [-95.6281, 42.1833],
          [-95.634, 42.0579],
          [-95.6535, 42.0549],
          [-95.6539, 41.9792],
          [-95.6268, 41.9793],
          [-95.73, 41.9429],
          [-95.7415, 42.0255],
          [-95.777, 42.0522],
          [-95.8373, 42.0379],
          [-95.8471, 41.9938],
          [-95.9004, 42.0008],
          [-95.9202, 41.9793],
          [-96.1378, 41.946],
          [-96.1842, 41.9767],
          [-96.2384, 42.0411],
          [-96.2714, 42.045],
          [-96.2673, 42.1103],
          [-96.3478, 42.1668],
          [-96.3566, 42.2152],
          [-96.3237, 42.2299],
          [-96.37, 42.3109],
          [-96.4178, 42.3514],
          [-96.4118, 42.4109],
          [-96.3807, 42.4464],
          [-96.4094, 42.4876],
          [-96.5013, 42.4827],
          [-96.5316, 42.5152],
          [-96.626, 42.5136],
          [-96.638, 42.552],
          [-96.6857, 42.5779],
          [-96.7113, 42.6174],
          [-96.6891, 42.6441],
          [-96.728, 42.6669],
          [-96.8022, 42.6722],
          [-96.8062, 42.7042],
          [-96.9068, 42.7338],
          [-96.9489, 42.7195],
          [-96.9817, 42.7605],
          [-97.131, 42.7719],
          [-97.1508, 42.7956],
          [-97.2101, 42.8093],
          [-97.2187, 42.8457],
          [-97.3067, 42.8676],
          [-97.3596, 42.8548],
          [-97.4171, 42.8659],
          [-97.4522, 42.846],
          [-97.5048, 42.8585],
          [-97.5619, 42.8476],
          [-97.6038, 42.8583],
          [-97.6865, 42.8424],
          [-97.7745, 42.8498],
          [-97.8453, 42.8677],
          [-97.8753, 42.8587],
          [-97.8886, 42.8173],
          [-97.9501, 42.7696],
          [-98.035, 42.7642],
          [-98.1275, 42.8201],
          [-98.2583, 42.8744],
          [-98.4674, 42.9476],
          [-98.4985, 42.9986],
          [-99.08, 42.9984],
          [-99.0803, 43.0824],
          [-99.0999, 43.0915],
          [-99.0606, 43.1492],
          [-99.0821, 43.1965],
          [-99.0843, 43.2442],
          [-99.0647, 43.2813],
          [-99.0845, 43.3242],
          [-99.1539, 43.4277],
          [-99.1777, 43.4379],
          [-99.2757, 43.4425],
          [-99.3107, 43.474],
          [-99.2833, 43.5312],
          [-99.3396, 43.5809],
          [-99.3556, 43.6423],
          [-99.3754, 43.6569],
          [-99.4602, 43.6566],
          [-99.4563, 43.6869],
          [-99.407, 43.7053],
          [-99.419, 43.7448],
          [-99.3958, 43.7918],
          [-99.4663, 43.8466],
          [-99.4863, 43.9049],
          [-99.3632, 43.9154],
          [-99.3537, 43.9953],
          [-99.4331, 44.0449],
          [-99.5113, 44.055],
          [-99.5625, 44.1015],
          [-99.5406, 44.1501],
          [-99.5767, 44.1925],
          [-99.5225, 44.1931],
          [-99.5225, 44.2512],
          [-99.5429, 44.2799],
          [-99.6035, 44.28],
          [-99.6035, 44.3529],
          [-99.5631, 44.3529],
          [-99.5629, 44.4254],
          [-99.5228, 44.4547],
          [-99.563, 44.4979],
          [-99.5348, 44.5482],
          [-99.5335, 44.6785],
          [-99.6146, 44.6785],
          [-99.6142, 44.7076],
          [-99.6755, 44.7074],
          [-99.6745, 44.8525],
          [-99.582, 44.8524],
          [-99.5308, 44.8668],
          [-99.5306, 44.8968],
          [-100.102, 44.8976],
          [-100.0813, 44.8527],
          [-100.1628, 44.8529],
          [-100.1836, 44.8977],
          [-100.4048, 44.8977],
          [-100.4234, 44.9921],
          [-100.3918, 45.026],
          [-100.3158, 45.0156],
          [-100.3242, 45.1016],
          [-100.2898, 45.1401],
          [-100.2575, 45.2395],
          [-100.3167, 45.3179],
          [-100.2742, 45.3818],
          [-100.223, 45.3771],
          [-100.2229, 45.492],
          [-100.1822, 45.5166],
          [-100.1819, 45.5942],
          [-100.0689, 45.5939],
          [-100.0688, 45.6518],
          [-99.9659, 45.666],
          [-99.8622, 45.6658],
          [-99.8826, 45.7239],
          [-99.9657, 45.7241],
          [-99.9656, 45.7676],
          [-99.9238, 45.7675],
          [-99.924, 45.8255],
          [-99.8824, 45.8546],
          [-100.0071, 45.8545],
          [-100.0069, 45.942],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Memphis Mkt": {
    type: "Feature",
    properties: { code: "TN_MEM", name: "Memphis Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-91.4071, 36.4971],
          [-91.1725, 36.4976],
          [-90.7112, 36.4983],
          [-90.1939, 36.4978],
          [-90.1373, 36.4554],
          [-90.135, 36.4137],
          [-90.0645, 36.3821],
          [-90.0837, 36.2723],
          [-90.1149, 36.2656],
          [-90.1422, 36.2275],
          [-90.2204, 36.1848],
          [-90.2488, 36.1298],
          [-90.2931, 36.1144],
          [-90.3779, 35.9957],
          [-89.7069, 36.001],
          [-89.68, 36.0825],
          [-89.594, 36.1272],
          [-89.607, 36.1712],
          [-89.6926, 36.225],
          [-89.6952, 36.2528],
          [-89.5873, 36.2395],
          [-89.5355, 36.2705],
          [-89.6118, 36.3091],
          [-89.6107, 36.3404],
          [-89.5318, 36.3392],
          [-89.5097, 36.3736],
          [-89.5451, 36.4349],
          [-89.5197, 36.467],
          [-89.5712, 36.5473],
          [-89.5425, 36.5806],
          [-89.4848, 36.5718],
          [-89.4654, 36.5362],
          [-89.4935, 36.4787],
          [-89.4642, 36.4572],
          [-89.4173, 36.499],
          [-89.3003, 36.5071],
          [-89.1175, 36.5036],
          [-88.3308, 36.5005],
          [-88.0532, 36.4971],
          [-88.0571, 36.4378],
          [-88.036, 36.4203],
          [-87.9558, 36.3122],
          [-87.9443, 36.2642],
          [-87.9516, 36.2223],
          [-87.9159, 36.1624],
          [-87.9225, 36.1223],
          [-88.0005, 36.0288],
          [-88.0204, 35.98],
          [-88.0129, 35.9466],
          [-87.9258, 35.9304],
          [-87.9399, 35.8671],
          [-87.9679, 35.8323],
          [-87.99, 35.7617],
          [-88.0301, 35.7285],
          [-88.0176, 35.6831],
          [-88.0342, 35.6138],
          [-87.9746, 35.521],
          [-87.9891, 35.4827],
          [-88.0435, 35.4498],
          [-88.0077, 35.4234],
          [-88.0187, 35.3883],
          [-88.0662, 35.4167],
          [-88.0869, 35.4045],
          [-88.0837, 35.3551],
          [-88.118, 35.3073],
          [-88.0891, 35.256],
          [-87.9836, 35.1802],
          [-87.9849, 35.0059],
          [-88.203, 35.008],
          [-88.2001, 34.9956],
          [-88.7536, 34.9953],
          [-88.7343, 34.9583],
          [-88.7333, 34.8937],
          [-88.753, 34.8588],
          [-88.7531, 34.757],
          [-88.7185, 34.7568],
          [-88.7187, 34.6779],
          [-88.7466, 34.6732],
          [-88.7523, 34.611],
          [-88.774, 34.578],
          [-88.8579, 34.534],
          [-88.8592, 34.487],
          [-88.9238, 34.4854],
          [-88.9404, 34.457],
          [-88.9291, 34.4173],
          [-88.9775, 34.3797],
          [-89.0587, 34.3885],
          [-89.0873, 34.3801],
          [-89.2019, 34.3797],
          [-89.2342, 34.411],
          [-89.2993, 34.3816],
          [-89.2464, 34.351],
          [-89.2627, 34.2111],
          [-89.2455, 34.1612],
          [-89.3006, 34.1614],
          [-89.362, 34.1619],
          [-89.4341, 34.1832],
          [-89.47, 34.2193],
          [-89.523, 34.2486],
          [-89.5762, 34.2486],
          [-89.6449, 34.232],
          [-89.6789, 34.2041],
          [-89.7397, 34.2432],
          [-89.7723, 34.2147],
          [-89.7893, 34.1638],
          [-89.9522, 34.1644],
          [-89.9976, 34.185],
          [-90.0871, 34.1632],
          [-90.1355, 34.16],
          [-90.1357, 34.0729],
          [-90.3176, 34.0728],
          [-90.3765, 34.1114],
          [-90.3991, 34.1533],
          [-90.4507, 34.1443],
          [-90.4509, 34.073],
          [-90.5281, 34.0837],
          [-90.5281, 34.0467],
          [-90.5552, 34.0335],
          [-90.5649, 33.9866],
          [-90.6604, 33.9876],
          [-90.6599, 34.0422],
          [-90.7298, 34.0581],
          [-90.6869, 34.1305],
          [-90.7181, 34.157],
          [-90.7807, 34.1498],
          [-90.8192, 34.1208],
          [-90.956, 34.1201],
          [-90.9213, 34.094],
          [-90.8719, 34.0867],
          [-90.8995, 34.0238],
          [-90.9879, 34.019],
          [-90.9615, 33.9799],
          [-91.0001, 33.9664],
          [-91.0189, 34.0032],
          [-91.0754, 33.9836],
          [-91.079, 33.9542],
          [-91.1558, 33.9764],
          [-91.1969, 34.0494],
          [-91.1837, 34.0837],
          [-91.1479, 34.0888],
          [-91.0846, 34.1463],
          [-91.0938, 34.1777],
          [-91.0705, 34.2014],
          [-91.0631, 34.2542],
          [-91.0731, 34.2958],
          [-91.0563, 34.3366],
          [-91.0025, 34.3363],
          [-91.0025, 34.3745],
          [-91.0548, 34.4709],
          [-91.0493, 34.7341],
          [-91.1024, 34.7349],
          [-91.0998, 34.8673],
          [-91.1166, 34.9118],
          [-91.1524, 34.9119],
          [-91.1498, 35.0025],
          [-91.0453, 35.0009],
          [-91.0398, 35.3543],
          [-91.0917, 35.4287],
          [-91.074, 35.4942],
          [-91.0392, 35.4943],
          [-91.0389, 35.5458],
          [-91.0742, 35.561],
          [-91.0741, 35.62],
          [-91.0381, 35.655],
          [-91.0366, 35.7231],
          [-91.1384, 35.7254],
          [-91.2364, 35.6757],
          [-91.245, 35.7488],
          [-91.2927, 35.7495],
          [-91.2881, 35.7798],
          [-91.2389, 35.8191],
          [-91.1988, 35.8863],
          [-91.2032, 35.9192],
          [-91.3294, 35.9124],
          [-91.3572, 35.8906],
          [-91.4053, 35.8913],
          [-91.4241, 35.9209],
          [-91.3832, 35.9455],
          [-91.3954, 36.0336],
          [-91.4263, 36.0677],
          [-91.4036, 36.0979],
          [-91.4086, 36.1474],
          [-91.441, 36.2266],
          [-91.3999, 36.2385],
          [-91.4239, 36.2867],
          [-91.3919, 36.319],
          [-91.3907, 36.359],
          [-91.312, 36.3531],
          [-91.3189, 36.3874],
          [-91.3491, 36.4209],
          [-91.362, 36.4723],
          [-91.4071, 36.4971],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Nashville Mkt": {
    type: "Feature",
    properties: { code: "TN_NAS", name: "Nashville Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-85.2641, 36.6264],
          [-85.0246, 36.6194],
          [-84.7853, 36.6034],
          [-84.7151, 36.4989],
          [-84.6926, 36.4356],
          [-84.6744, 36.4138],
          [-84.6644, 36.413],
          [-84.6683, 36.4065],
          [-84.6591, 36.3953],
          [-84.6855, 36.3882],
          [-84.6905, 36.3741],
          [-84.7056, 36.3682],
          [-84.7246, 36.3764],
          [-84.7324, 36.3659],
          [-84.7269, 36.3527],
          [-84.7454, 36.3512],
          [-84.7399, 36.3365],
          [-84.7605, 36.3277],
          [-84.7741, 36.3347],
          [-84.7867, 36.3253],
          [-84.782, 36.3193],
          [-84.7954, 36.3091],
          [-84.7961, 36.2976],
          [-84.8139, 36.2935],
          [-84.8227, 36.2994],
          [-84.8434, 36.287],
          [-84.8658, 36.2931],
          [-84.8826, 36.2743],
          [-84.9042, 36.265],
          [-84.9007, 36.2497],
          [-84.9115, 36.2435],
          [-84.9135, 36.2228],
          [-84.9237, 36.2147],
          [-84.924, 36.1963],
          [-84.9421, 36.1784],
          [-84.9432, 36.1627],
          [-84.9531, 36.1657],
          [-84.9701, 36.158],
          [-84.959, 36.1478],
          [-84.9537, 36.1556],
          [-84.937, 36.1548],
          [-84.9336, 36.1462],
          [-84.9062, 36.1561],
          [-84.8709, 36.1369],
          [-84.8324, 36.084],
          [-84.8243, 36.0947],
          [-84.811, 36.087],
          [-84.8055, 36.0906],
          [-84.792, 36.0594],
          [-84.791, 36.0577],
          [-84.8382, 36.0465],
          [-84.8664, 36.0555],
          [-84.8936, 36.0551],
          [-84.8941, 36.0474],
          [-84.831, 36.0433],
          [-84.832, 36.0196],
          [-84.8503, 36.0077],
          [-84.8224, 35.9986],
          [-84.8519, 35.9857],
          [-84.8461, 35.9565],
          [-84.8606, 35.9574],
          [-84.8887, 35.9124],
          [-84.8872, 35.932],
          [-84.9081, 35.9331],
          [-84.9198, 35.9192],
          [-84.9061, 35.9093],
          [-84.892, 35.9097],
          [-84.913, 35.8916],
          [-84.8985, 35.8857],
          [-84.8841, 35.8896],
          [-84.879, 35.9024],
          [-84.8456, 35.8933],
          [-84.8587, 35.8889],
          [-84.853, 35.8643],
          [-84.8661, 35.8492],
          [-84.8497, 35.8182],
          [-84.8803, 35.8084],
          [-84.915, 35.8345],
          [-84.9283, 35.8325],
          [-84.9197, 35.8583],
          [-84.9344, 35.8769],
          [-84.9462, 35.8453],
          [-84.9662, 35.8353],
          [-84.9842, 35.805],
          [-84.9794, 35.7981],
          [-84.9622, 35.7997],
          [-84.9557, 35.786],
          [-84.9864, 35.7929],
          [-84.9918, 35.8036],
          [-84.9834, 35.8299],
          [-84.9897, 35.8352],
          [-85.0307, 35.7466],
          [-85.041, 35.7716],
          [-85.0738, 35.7605],
          [-85.1034, 35.7699],
          [-85.1777, 35.7679],
          [-85.1798, 35.7842],
          [-85.1873, 35.7676],
          [-85.2655, 35.7608],
          [-85.259, 35.7518],
          [-85.2703, 35.7475],
          [-85.2653, 35.7214],
          [-85.4238, 35.5674],
          [-85.4535, 35.5581],
          [-85.4595, 35.5476],
          [-85.4922, 35.5476],
          [-85.4944, 35.5339],
          [-85.5247, 35.5337],
          [-85.5345, 35.5212],
          [-85.5673, 35.5069],
          [-85.5606, 35.5016],
          [-85.5698, 35.4944],
          [-85.6054, 35.4929],
          [-85.5994, 35.4841],
          [-85.6157, 35.4792],
          [-85.6297, 35.4623],
          [-85.6735, 35.5176],
          [-85.7378, 35.492],
          [-85.7399, 35.4681],
          [-85.7811, 35.4724],
          [-85.7884, 35.4808],
          [-85.7983, 35.476],
          [-85.7938, 35.4855],
          [-85.8165, 35.5136],
          [-85.7913, 35.5179],
          [-85.7959, 35.5286],
          [-85.8217, 35.552],
          [-85.8291, 35.5503],
          [-85.8236, 35.5653],
          [-85.8345, 35.5733],
          [-85.8399, 35.5987],
          [-85.8841, 35.6167],
          [-85.8128, 35.6554],
          [-85.8192, 35.6613],
          [-85.8383, 35.6559],
          [-85.8346, 35.6644],
          [-85.8426, 35.6693],
          [-85.8617, 35.657],
          [-85.8864, 35.6701],
          [-85.8981, 35.6662],
          [-85.9078, 35.6762],
          [-85.9311, 35.6716],
          [-85.9545, 35.687],
          [-85.9642, 35.7199],
          [-85.9868, 35.7283],
          [-85.9896, 35.7114],
          [-86.0012, 35.7123],
          [-85.9993, 35.7321],
          [-86.0181, 35.7269],
          [-86.0134, 35.6877],
          [-86.0256, 35.6864],
          [-86.0185, 35.6762],
          [-86.0395, 35.6762],
          [-86.0338, 35.6525],
          [-86.0532, 35.644],
          [-86.0889, 35.6468],
          [-86.0979, 35.6267],
          [-86.1511, 35.6224],
          [-86.168, 35.591],
          [-86.1926, 35.5741],
          [-86.2314, 35.5765],
          [-86.2008, 35.5566],
          [-86.2294, 35.5707],
          [-86.2295, 35.5557],
          [-86.2154, 35.5412],
          [-86.2221, 35.5349],
          [-86.241, 35.5381],
          [-86.245, 35.5092],
          [-86.2687, 35.4837],
          [-86.2849, 35.4942],
          [-86.3022, 35.4823],
          [-86.303, 35.4434],
          [-86.2734, 35.4109],
          [-86.2832, 35.4089],
          [-86.2714, 35.3944],
          [-86.2799, 35.3875],
          [-86.2535, 35.3843],
          [-86.2575, 35.3709],
          [-86.3039, 35.3695],
          [-86.2999, 35.3979],
          [-86.329, 35.4061],
          [-86.3293, 35.3955],
          [-86.3621, 35.3802],
          [-86.3588, 35.3754],
          [-86.3387, 35.3807],
          [-86.3386, 35.3536],
          [-86.3469, 35.3455],
          [-86.3408, 35.3415],
          [-86.3497, 35.3314],
          [-86.362, 35.3441],
          [-86.3728, 35.3434],
          [-86.3658, 35.3232],
          [-86.3809, 35.3363],
          [-86.4027, 35.338],
          [-86.4007, 35.3282],
          [-86.379, 35.3212],
          [-86.3942, 35.3131],
          [-86.4144, 35.3245],
          [-86.4211, 35.3216],
          [-86.4154, 35.2983],
          [-86.4368, 35.2752],
          [-86.4614, 35.2846],
          [-86.4614, 35.2928],
          [-86.5273, 35.3115],
          [-86.531, 35.3187],
          [-86.5795, 35.2902],
          [-86.5828, 35.2773],
          [-86.5649, 35.2577],
          [-86.5663, 35.2347],
          [-86.5896, 35.2447],
          [-86.5965, 35.2293],
          [-86.6097, 35.2302],
          [-86.6087, 35.2222],
          [-86.6195, 35.2259],
          [-86.6294, 35.2132],
          [-86.6325, 35.2223],
          [-86.6598, 35.2215],
          [-86.6596, 35.2164],
          [-86.6838, 35.218],
          [-86.701, 35.2077],
          [-86.741, 35.2072],
          [-86.7527, 35.1979],
          [-86.7792, 35.2373],
          [-86.8287, 35.2388],
          [-86.8283, 35.27],
          [-86.901, 35.2787],
          [-86.9259, 35.4014],
          [-86.8839, 35.4499],
          [-86.8713, 35.5025],
          [-86.8381, 35.5509],
          [-86.8364, 35.6031],
          [-86.817, 35.6583],
          [-86.8307, 35.6918],
          [-86.8967, 35.644],
          [-86.9384, 35.651],
          [-86.9357, 35.6881],
          [-86.9801, 35.7204],
          [-87.0041, 35.7807],
          [-87.1638, 35.8384],
          [-87.1479, 35.8984],
          [-87.181, 35.9201],
          [-87.212, 35.869],
          [-87.2793, 35.8573],
          [-87.3136, 35.7995],
          [-87.341, 35.8189],
          [-87.3871, 35.7936],
          [-87.3902, 35.7694],
          [-87.329, 35.7544],
          [-87.3356, 35.7001],
          [-87.4099, 35.7536],
          [-87.4361, 35.6994],
          [-87.39, 35.6413],
          [-87.4596, 35.6151],
          [-87.5493, 35.6301],
          [-87.5869, 35.6533],
          [-87.6181, 35.6194],
          [-87.6582, 35.6093],
          [-87.6517, 35.565],
          [-87.6858, 35.5481],
          [-87.6907, 35.5053],
          [-87.7338, 35.4917],
          [-87.8644, 35.4758],
          [-87.8989, 35.4642],
          [-87.9619, 35.4926],
          [-88.0421, 35.4505],
          [-88.0077, 35.4234],
          [-88.0435, 35.4498],
          [-87.9891, 35.4827],
          [-87.9746, 35.521],
          [-88.0342, 35.6138],
          [-88.0176, 35.6831],
          [-88.0301, 35.7285],
          [-87.99, 35.7617],
          [-87.9679, 35.8323],
          [-87.9399, 35.8671],
          [-87.9258, 35.9304],
          [-88.0129, 35.9466],
          [-88.0204, 35.98],
          [-88.0005, 36.0288],
          [-87.9225, 36.1223],
          [-87.9159, 36.1624],
          [-87.9516, 36.2223],
          [-87.9443, 36.2642],
          [-87.9558, 36.3122],
          [-88.036, 36.4203],
          [-88.0571, 36.4378],
          [-88.0532, 36.4971],
          [-88.0325, 36.5407],
          [-88.0705, 36.6781],
          [-87.8496, 36.6637],
          [-87.8532, 36.6332],
          [-87.557, 36.6392],
          [-87.585, 36.612],
          [-87.5828, 36.5612],
          [-87.5653, 36.5348],
          [-87.4795, 36.5512],
          [-87.464, 36.586],
          [-87.4281, 36.6023],
          [-87.4365, 36.6407],
          [-87.115, 36.6424],
          [-86.5899, 36.6525],
          [-86.5641, 36.6335],
          [-86.5078, 36.6524],
          [-86.3331, 36.6488],
          [-86.0328, 36.6304],
          [-85.8322, 36.622],
          [-85.4884, 36.615],
          [-85.2641, 36.6264],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Jackson Mkt": {
    type: "Feature",
    properties: { code: "MS_JAC", name: "Jackson Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-91.1661, 33.0041],
          [-91.1257, 33.0383],
          [-91.1246, 33.0641],
          [-91.2015, 33.1096],
          [-91.1837, 33.1417],
          [-91.1043, 33.1316],
          [-91.0876, 33.1452],
          [-91.0917, 33.2208],
          [-91.0436, 33.2746],
          [-91.0785, 33.2833],
          [-91.1001, 33.2381],
          [-91.1416, 33.2995],
          [-91.1422, 33.349],
          [-91.0582, 33.4287],
          [-91.1663, 33.3797],
          [-91.209, 33.4036],
          [-91.1319, 33.4301],
          [-91.1694, 33.4526],
          [-91.2352, 33.439],
          [-91.1856, 33.4964],
          [-91.2323, 33.5528],
          [-91.2241, 33.5674],
          [-91.1316, 33.5994],
          [-91.1392, 33.6257],
          [-91.219, 33.6615],
          [-91.2206, 33.6929],
          [-91.1609, 33.7071],
          [-91.1304, 33.6745],
          [-91.0841, 33.6573],
          [-91.0346, 33.673],
          [-91.0683, 33.7165],
          [-91.1177, 33.7053],
          [-91.1447, 33.7268],
          [-91.1073, 33.7706],
          [-91.0539, 33.7787],
          [-91.0268, 33.7636],
          [-91.0001, 33.7995],
          [-91.0468, 33.8154],
          [-91.073, 33.8574],
          [-91.0108, 33.9256],
          [-91.079, 33.9542],
          [-91.0754, 33.9836],
          [-91.0189, 34.0032],
          [-91.0001, 33.9664],
          [-90.9615, 33.9799],
          [-90.9879, 34.019],
          [-90.8995, 34.0238],
          [-90.8719, 34.0867],
          [-90.9213, 34.094],
          [-90.956, 34.1201],
          [-90.8192, 34.1208],
          [-90.7807, 34.1498],
          [-90.7181, 34.157],
          [-90.6869, 34.1305],
          [-90.7298, 34.0581],
          [-90.6599, 34.0422],
          [-90.6604, 33.9876],
          [-90.5649, 33.9866],
          [-90.5552, 34.0335],
          [-90.5281, 34.0467],
          [-90.5281, 34.0837],
          [-90.4509, 34.073],
          [-90.4507, 34.1443],
          [-90.3991, 34.1533],
          [-90.3765, 34.1114],
          [-90.3176, 34.0728],
          [-90.1357, 34.0729],
          [-90.1355, 34.16],
          [-90.0871, 34.1632],
          [-89.9976, 34.185],
          [-89.9522, 34.1644],
          [-89.7893, 34.1638],
          [-89.7723, 34.2147],
          [-89.7397, 34.2432],
          [-89.6789, 34.2041],
          [-89.6449, 34.232],
          [-89.5762, 34.2486],
          [-89.523, 34.2486],
          [-89.47, 34.2193],
          [-89.4341, 34.1832],
          [-89.362, 34.1619],
          [-89.3006, 34.1614],
          [-89.3154, 34.1256],
          [-89.2682, 34.0941],
          [-89.2606, 34.0508],
          [-89.1903, 34.0152],
          [-89.1906, 33.9687],
          [-89.2534, 33.9482],
          [-89.2629, 33.9167],
          [-89.2431, 33.8826],
          [-89.2489, 33.8397],
          [-89.2246, 33.8246],
          [-89.2471, 33.7798],
          [-89.1906, 33.7891],
          [-89.0858, 33.848],
          [-89.0176, 33.8464],
          [-88.9551, 33.7966],
          [-88.9312, 33.8124],
          [-88.8266, 33.8117],
          [-88.7672, 33.8402],
          [-88.72, 33.811],
          [-88.7201, 33.9506],
          [-88.6572, 33.9378],
          [-88.6972, 34.0014],
          [-88.6212, 34.0106],
          [-88.5173, 33.9894],
          [-88.5497, 33.898],
          [-88.4798, 33.8829],
          [-88.3898, 33.9339],
          [-88.3588, 33.8763],
          [-88.3755, 33.8089],
          [-88.2927, 33.8408],
          [-88.283, 33.8066],
          [-88.2416, 33.8029],
          [-88.2717, 33.5573],
          [-88.3309, 33.0731],
          [-88.3854, 32.6072],
          [-88.4387, 32.1721],
          [-88.4732, 31.8939],
          [-88.4666, 31.7501],
          [-88.4388, 31.2527],
          [-88.4957, 31.2485],
          [-88.5321, 31.2203],
          [-88.5674, 31.2388],
          [-88.6114, 31.2287],
          [-88.6205, 31.2852],
          [-88.624, 31.4341],
          [-88.6733, 31.4649],
          [-88.7312, 31.4756],
          [-88.7671, 31.4351],
          [-88.7964, 31.4437],
          [-88.8307, 31.5064],
          [-88.8402, 31.554],
          [-88.877, 31.5539],
          [-88.8866, 31.6658],
          [-88.9037, 31.6728],
          [-88.9076, 31.7316],
          [-88.9281, 31.7537],
          [-88.8926, 31.8038],
          [-88.9018, 31.8272],
          [-88.8668, 31.8658],
          [-88.9341, 31.9021],
          [-88.9698, 31.9027],
          [-88.978, 31.9343],
          [-89.0298, 31.9407],
          [-89.1075, 31.9654],
          [-89.0876, 32.0288],
          [-89.1448, 32.0073],
          [-89.2045, 32.0252],
          [-89.238, 32.0047],
          [-89.2762, 32.0072],
          [-89.2922, 32.0401],
          [-89.3351, 32.0344],
          [-89.4162, 32.0005],
          [-89.4043, 31.9171],
          [-89.3712, 31.9245],
          [-89.3171, 31.8743],
          [-89.3133, 31.7647],
          [-89.3378, 31.7488],
          [-89.3537, 31.7059],
          [-89.3883, 31.694],
          [-89.4706, 31.7182],
          [-89.4566, 31.7373],
          [-89.4845, 31.7722],
          [-89.5412, 31.7339],
          [-89.5545, 31.7558],
          [-89.6104, 31.7537],
          [-89.6208, 31.7172],
          [-89.6754, 31.6478],
          [-89.7217, 31.6618],
          [-89.7554, 31.6897],
          [-89.8105, 31.6841],
          [-89.7906, 31.7247],
          [-89.8242, 31.7449],
          [-89.8479, 31.7122],
          [-89.9034, 31.6785],
          [-89.9101, 31.7054],
          [-89.9748, 31.6855],
          [-89.9374, 31.636],
          [-89.9762, 31.5417],
          [-89.9477, 31.4986],
          [-89.8911, 31.5287],
          [-89.857, 31.4926],
          [-89.8572, 31.4504],
          [-89.8754, 31.4212],
          [-89.9215, 31.3914],
          [-90.0405, 31.3946],
          [-90.0404, 31.2996],
          [-90.0235, 31.2886],
          [-90.0045, 31.2192],
          [-89.951, 31.2406],
          [-89.91, 31.2154],
          [-89.9727, 31.1982],
          [-89.9991, 31.1686],
          [-89.9506, 31.1431],
          [-89.9295, 31.0892],
          [-89.9634, 31.0671],
          [-89.941, 31.0014],
          [-90.4425, 30.9997],
          [-91.0646, 30.9989],
          [-91.6369, 30.9994],
          [-91.5644, 31.039],
          [-91.5641, 31.0668],
          [-91.6215, 31.1103],
          [-91.6251, 31.1319],
          [-91.589, 31.1786],
          [-91.6016, 31.2086],
          [-91.6444, 31.2344],
          [-91.6377, 31.2677],
          [-91.5745, 31.2613],
          [-91.5186, 31.2753],
          [-91.51, 31.3168],
          [-91.549, 31.3473],
          [-91.5783, 31.3991],
          [-91.5485, 31.4327],
          [-91.516, 31.3703],
          [-91.465, 31.384],
          [-91.5151, 31.4492],
          [-91.5158, 31.5309],
          [-91.4797, 31.5304],
          [-91.4079, 31.5694],
          [-91.4227, 31.5971],
          [-91.4852, 31.5862],
          [-91.5166, 31.6118],
          [-91.4977, 31.6454],
          [-91.4638, 31.6204],
          [-91.4211, 31.6116],
          [-91.3957, 31.6442],
          [-91.3971, 31.7114],
          [-91.3718, 31.7429],
          [-91.3384, 31.8513],
          [-91.2947, 31.8605],
          [-91.2825, 31.8151],
          [-91.2456, 31.8332],
          [-91.2677, 31.8627],
          [-91.201, 31.9092],
          [-91.1883, 31.961],
          [-91.1638, 31.9826],
          [-91.1174, 31.9871],
          [-91.0759, 32.0168],
          [-91.0987, 32.0484],
          [-91.08, 32.0792],
          [-91.0386, 32.0983],
          [-91.0062, 32.157],
          [-91.0502, 32.1785],
          [-91.0532, 32.1242],
          [-91.1628, 32.1327],
          [-91.1642, 32.1969],
          [-91.1173, 32.2067],
          [-91.0465, 32.2411],
          [-90.9943, 32.2138],
          [-90.9478, 32.2835],
          [-90.9162, 32.3036],
          [-90.9212, 32.3421],
          [-90.9867, 32.3518],
          [-90.9941, 32.4039],
          [-90.9663, 32.421],
          [-90.993, 32.4507],
          [-91.0296, 32.4335],
          [-91.0953, 32.4587],
          [-91.1167, 32.5001],
          [-91.0937, 32.5491],
          [-91.0102, 32.6019],
          [-91.0498, 32.6072],
          [-91.1186, 32.5851],
          [-91.1441, 32.6006],
          [-91.1527, 32.6408],
          [-91.1183, 32.6741],
          [-91.0639, 32.7029],
          [-91.0608, 32.7275],
          [-91.1653, 32.7513],
          [-91.1617, 32.8125],
          [-91.1452, 32.8423],
          [-91.0706, 32.8887],
          [-91.0648, 32.9265],
          [-91.0868, 32.9763],
          [-91.1355, 32.9797],
          [-91.134, 32.9177],
          [-91.1702, 32.8994],
          [-91.2083, 32.9154],
          [-91.2018, 32.9612],
          [-91.1661, 33.0041],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Charlotte Mkt": {
    type: "Feature",
    properties: { code: "NC_CHA", name: "Charlotte Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-79.1184, 35.3594],
            [-79.0923, 35.3645],
            [-79.0693, 35.348],
            [-79.0944, 35.351],
            [-79.0982, 35.3378],
            [-79.1215, 35.347],
            [-79.1184, 35.3594],
          ],
        ],
        [
          [
            [-77.9394, 35.268],
            [-77.96, 35.2428],
            [-77.9545, 35.1848],
            [-77.9123, 35.188],
            [-77.8839, 35.1622],
            [-77.8518, 35.0967],
            [-77.8789, 35.0801],
            [-77.8175, 35.0474],
            [-77.845, 35.03],
            [-77.8616, 34.9857],
            [-77.8075, 34.9587],
            [-77.8308, 34.9326],
            [-77.8927, 34.8978],
            [-77.946, 34.9431],
            [-78.0471, 34.9268],
            [-78.1003, 34.9297],
            [-78.1045, 34.895],
            [-78.2149, 34.8901],
            [-78.2598, 34.8453],
            [-78.2943, 34.8472],
            [-78.3144, 34.8167],
            [-78.3669, 34.8344],
            [-78.3564, 34.859],
            [-78.4174, 34.8692],
            [-78.4424, 34.8891],
            [-78.497, 34.8397],
            [-78.5393, 34.8639],
            [-78.5693, 34.8367],
            [-78.5595, 34.7457],
            [-78.4864, 34.7068],
            [-78.453, 34.6143],
            [-78.5111, 34.5624],
            [-78.6151, 34.5777],
            [-78.6899, 34.5334],
            [-78.6764, 34.4877],
            [-78.6929, 34.4684],
            [-78.8224, 34.4303],
            [-78.8252, 34.4618],
            [-78.8715, 34.4827],
            [-78.9709, 34.4277],
            [-78.9685, 34.3974],
            [-79.0118, 34.3808],
            [-79.0384, 34.3135],
            [-79.0712, 34.2992],
            [-79.2498, 34.4498],
            [-79.3583, 34.5454],
            [-79.6753, 34.8047],
            [-80.0013, 34.8082],
            [-79.9998, 34.8036],
            [-80.0183, 34.8018],
            [-80.0338, 34.7937],
            [-80.05, 34.7514],
            [-80.0214, 34.7061],
            [-79.994, 34.684],
            [-80.0034, 34.6644],
            [-80.0195, 34.6609],
            [-80.0262, 34.6409],
            [-80.0559, 34.6328],
            [-80.0609, 34.6373],
            [-80.0725, 34.6267],
            [-80.0669, 34.6358],
            [-80.072, 34.6396],
            [-80.0895, 34.6474],
            [-80.1061, 34.6252],
            [-80.1185, 34.6227],
            [-80.1176, 34.6488],
            [-80.1514, 34.6366],
            [-80.1241, 34.6546],
            [-80.1356, 34.6643],
            [-80.1474, 34.6533],
            [-80.1752, 34.6512],
            [-80.1706, 34.6417],
            [-80.1924, 34.6277],
            [-80.1995, 34.6506],
            [-80.2117, 34.6542],
            [-80.2122, 34.635],
            [-80.2293, 34.6382],
            [-80.218, 34.6256],
            [-80.2313, 34.6093],
            [-80.2364, 34.5733],
            [-80.2967, 34.5666],
            [-80.2959, 34.5582],
            [-80.3232, 34.5543],
            [-80.3236, 34.5455],
            [-80.3567, 34.5337],
            [-80.3717, 34.5459],
            [-80.3779, 34.574],
            [-80.3925, 34.5699],
            [-80.398, 34.5767],
            [-80.4206, 34.5783],
            [-80.4231, 34.5859],
            [-80.4422, 34.5773],
            [-80.4397, 34.5691],
            [-80.4707, 34.591],
            [-80.4084, 34.6148],
            [-80.4352, 34.6426],
            [-80.4317, 34.6599],
            [-80.4708, 34.687],
            [-80.4896, 34.7204],
            [-80.5155, 34.6907],
            [-80.5362, 34.69],
            [-80.5446, 34.679],
            [-80.5534, 34.6829],
            [-80.5614, 34.6771],
            [-80.5738, 34.684],
            [-80.5805, 34.6792],
            [-80.594, 34.6927],
            [-80.6344, 34.6884],
            [-80.6302, 34.6754],
            [-80.6433, 34.6841],
            [-80.663, 34.6732],
            [-80.6543, 34.6585],
            [-80.6692, 34.6526],
            [-80.6662, 34.6375],
            [-80.6887, 34.6523],
            [-80.7206, 34.6488],
            [-80.7194, 34.632],
            [-80.7328, 34.6218],
            [-80.7664, 34.6216],
            [-80.7974, 34.5921],
            [-80.8413, 34.5946],
            [-80.8468, 34.5841],
            [-80.8575, 34.5822],
            [-80.8714, 34.5919],
            [-80.8835, 34.5854],
            [-80.8984, 34.6446],
            [-80.9233, 34.6477],
            [-80.9307, 34.6355],
            [-80.9319, 34.6633],
            [-80.9425, 34.6666],
            [-80.9504, 34.665],
            [-80.9356, 34.6282],
            [-80.9627, 34.6101],
            [-80.948, 34.5975],
            [-80.9641, 34.5962],
            [-80.9741, 34.6138],
            [-80.9947, 34.6042],
            [-80.9741, 34.5898],
            [-80.9976, 34.5945],
            [-81.0191, 34.6101],
            [-81.0438, 34.6108],
            [-81.0904, 34.6302],
            [-81.1091, 34.6237],
            [-81.1168, 34.6279],
            [-81.1126, 34.6378],
            [-81.133, 34.6464],
            [-81.147, 34.6373],
            [-81.1776, 34.6371],
            [-81.1791, 34.6074],
            [-81.1912, 34.6034],
            [-81.193, 34.5819],
            [-81.2068, 34.585],
            [-81.2153, 34.6072],
            [-81.2472, 34.6156],
            [-81.2531, 34.5987],
            [-81.2399, 34.5712],
            [-81.2451, 34.5638],
            [-81.4221, 34.572],
            [-81.4201, 34.5951],
            [-81.3518, 34.6079],
            [-81.3241, 34.6249],
            [-81.3214, 34.6517],
            [-81.3389, 34.6937],
            [-81.387, 34.7127],
            [-81.3744, 34.7442],
            [-81.4151, 34.7448],
            [-81.4332, 34.7701],
            [-81.4239, 34.7717],
            [-81.4504, 34.7876],
            [-81.4592, 34.7975],
            [-81.469, 34.811],
            [-81.4806, 34.8084],
            [-81.4879, 34.8151],
            [-81.4618, 34.831],
            [-81.4533, 34.8467],
            [-81.4688, 34.8657],
            [-81.4788, 34.8984],
            [-81.4703, 34.9138],
            [-81.4738, 34.9359],
            [-81.4924, 34.9468],
            [-81.4761, 34.9813],
            [-81.4848, 34.9973],
            [-81.4808, 35.0073],
            [-81.4966, 35.0408],
            [-81.534, 35.051],
            [-81.5726, 35.0853],
            [-81.5714, 35.1146],
            [-81.6147, 35.1508],
            [-81.6079, 35.1571],
            [-81.6187, 35.1677],
            [-81.6096, 35.1743],
            [-82.0014, 35.1885],
            [-81.9927, 35.2553],
            [-82.0293, 35.2824],
            [-82.0343, 35.2754],
            [-82.0801, 35.2931],
            [-82.0709, 35.3018],
            [-82.0561, 35.3001],
            [-82.0574, 35.3217],
            [-82.0706, 35.3153],
            [-82.0736, 35.3245],
            [-82.0532, 35.3347],
            [-82.0778, 35.3427],
            [-82.0798, 35.353],
            [-82.1084, 35.3822],
            [-82.151, 35.4071],
            [-82.1685, 35.408],
            [-82.1848, 35.3965],
            [-82.1958, 35.4009],
            [-82.1751, 35.4217],
            [-82.1144, 35.4202],
            [-82.1202, 35.4324],
            [-82.1093, 35.4318],
            [-82.1149, 35.4432],
            [-82.1027, 35.4781],
            [-82.1053, 35.4996],
            [-82.1127, 35.5031],
            [-82.102, 35.5132],
            [-82.1279, 35.5237],
            [-82.0681, 35.5371],
            [-82.0226, 35.5371],
            [-82.0028, 35.5518],
            [-81.9736, 35.5246],
            [-81.8563, 35.5405],
            [-81.8516, 35.5659],
            [-81.858, 35.5712],
            [-81.8658, 35.5661],
            [-81.8553, 35.5931],
            [-81.8471, 35.5964],
            [-81.8551, 35.6063],
            [-81.8418, 35.6314],
            [-81.8694, 35.7196],
            [-81.8941, 35.7407],
            [-81.8819, 35.747],
            [-81.8898, 35.7525],
            [-81.874, 35.7506],
            [-81.8707, 35.757],
            [-81.8929, 35.773],
            [-81.9023, 35.7717],
            [-81.9384, 35.8063],
            [-81.9289, 35.8278],
            [-81.9286, 35.8372],
            [-81.9353, 35.8374],
            [-81.9196, 35.8481],
            [-81.9066, 35.8834],
            [-81.9148, 35.9163],
            [-81.9433, 35.9594],
            [-81.963, 35.9417],
            [-81.9545, 35.9267],
            [-81.9817, 35.9115],
            [-81.9995, 35.9223],
            [-81.9894, 35.9489],
            [-82.005, 35.9453],
            [-82.0136, 35.9543],
            [-82.0343, 35.9423],
            [-82.0263, 35.9645],
            [-82.041, 35.9574],
            [-82.0377, 35.9475],
            [-82.0588, 35.9873],
            [-82.0554, 35.9991],
            [-82.0388, 36.0079],
            [-82.0706, 36.0686],
            [-82.069, 36.0765],
            [-82.0718, 36.0811],
            [-82.0757, 36.0842],
            [-82.0763, 36.0853],
            [-82.0767, 36.0924],
            [-82.0789, 36.0987],
            [-82.0804, 36.1007],
            [-82.0804, 36.1057],
            [-82.0801, 36.1057],
            [-82.0613, 36.1131],
            [-82.0541, 36.1268],
            [-82.0331, 36.1204],
            [-81.9081, 36.302],
            [-81.8794, 36.3138],
            [-81.8413, 36.3433],
            [-81.8008, 36.3581],
            [-81.7661, 36.3385],
            [-81.731, 36.3412],
            [-81.7074, 36.3352],
            [-81.7078, 36.346],
            [-81.7213, 36.3531],
            [-81.7329, 36.3765],
            [-81.7396, 36.4067],
            [-81.7207, 36.4225],
            [-81.7149, 36.4572],
            [-81.6953, 36.4679],
            [-81.708, 36.5362],
            [-81.69, 36.5522],
            [-81.6902, 36.5687],
            [-81.677, 36.5707],
            [-81.6801, 36.5855],
            [-81.5223, 36.5806],
            [-80.9017, 36.5618],
            [-80.9085, 36.5566],
            [-80.9093, 36.5526],
            [-80.9186, 36.5404],
            [-80.929, 36.4863],
            [-80.9273, 36.4525],
            [-80.9114, 36.4356],
            [-80.8729, 36.435],
            [-80.8599, 36.4186],
            [-80.8577, 36.3951],
            [-80.8425, 36.3895],
            [-80.8393, 36.3977],
            [-80.8122, 36.3918],
            [-80.8028, 36.4006],
            [-80.7868, 36.3944],
            [-80.7718, 36.3978],
            [-80.7788, 36.3871],
            [-80.7599, 36.3603],
            [-80.7368, 36.3576],
            [-80.7432, 36.3486],
            [-80.7235, 36.3352],
            [-80.7351, 36.3306],
            [-80.7409, 36.3152],
            [-80.7178, 36.3003],
            [-80.731, 36.2902],
            [-80.7402, 36.2952],
            [-80.7533, 36.2773],
            [-80.7432, 36.276],
            [-80.7366, 36.28],
            [-80.734, 36.2675],
            [-80.7227, 36.2696],
            [-80.7225, 36.2603],
            [-80.7343, 36.26],
            [-80.7391, 36.271],
            [-80.7447, 36.2486],
            [-80.7366, 36.2432],
            [-80.7532, 36.2235],
            [-80.7751, 36.2048],
            [-80.8099, 36.1959],
            [-80.809, 36.1816],
            [-80.8386, 36.1815],
            [-80.8329, 36.1696],
            [-80.8417, 36.1581],
            [-80.8689, 36.172],
            [-80.8778, 36.1424],
            [-80.8922, 36.148],
            [-80.9062, 36.1283],
            [-80.9008, 36.1153],
            [-80.9069, 36.1059],
            [-80.9194, 36.112],
            [-80.8964, 36.0832],
            [-80.8882, 36.0813],
            [-80.8807, 36.0841],
            [-80.8641, 36.0843],
            [-80.8568, 36.0551],
            [-80.7681, 36.0529],
            [-80.7596, 36.0474],
            [-80.7621, 36.0221],
            [-80.7861, 36.0261],
            [-80.7888, 36.0001],
            [-80.7667, 36.0049],
            [-80.7612, 36.019],
            [-80.7543, 36.012],
            [-80.7491, 36.0163],
            [-80.7545, 36.0294],
            [-80.7452, 36.0443],
            [-80.7541, 36.0526],
            [-80.6927, 36.0511],
            [-80.7002, 35.9624],
            [-80.6864, 35.9628],
            [-80.6873, 35.9314],
            [-80.6721, 35.9163],
            [-80.6976, 35.9039],
            [-80.7058, 35.89],
            [-80.7114, 35.8431],
            [-80.7133, 35.8495],
            [-80.7277, 35.8507],
            [-80.7129, 35.8388],
            [-80.7232, 35.8102],
            [-80.739, 35.8042],
            [-80.7295, 35.7925],
            [-80.7525, 35.7286],
            [-80.7933, 35.7303],
            [-80.8065, 35.7126],
            [-80.7936, 35.6664],
            [-80.76, 35.6715],
            [-80.7599, 35.6617],
            [-80.7517, 35.6596],
            [-80.7472, 35.6679],
            [-80.7275, 35.6726],
            [-80.7227, 35.6753],
            [-80.7385, 35.6925],
            [-80.7099, 35.7148],
            [-80.6656, 35.6995],
            [-80.6496, 35.7018],
            [-80.6396, 35.7145],
            [-80.6423, 35.7247],
            [-80.6271, 35.7204],
            [-80.6132, 35.7253],
            [-80.608, 35.7385],
            [-80.5725, 35.7407],
            [-80.565, 35.7572],
            [-80.5355, 35.7713],
            [-80.534, 35.7864],
            [-80.5084, 35.7749],
            [-80.4958, 35.7823],
            [-80.4878, 35.7662],
            [-80.4786, 35.775],
            [-80.4537, 35.7346],
            [-80.3727, 35.7165],
            [-80.3322, 35.719],
            [-80.3241, 35.7081],
            [-80.334, 35.6932],
            [-80.3301, 35.6787],
            [-80.3078, 35.6671],
            [-80.2888, 35.6363],
            [-80.2588, 35.6258],
            [-80.2082, 35.5804],
            [-80.21, 35.5528],
            [-80.1991, 35.5454],
            [-80.1827, 35.5043],
            [-80.138, 35.505],
            [-80.1448, 35.5147],
            [-80.1207, 35.5372],
            [-80.0876, 35.5304],
            [-80.065, 35.5116],
            [-80.0554, 35.4882],
            [-80.0702, 35.4666],
            [-80.0694, 35.4522],
            [-80.0388, 35.4342],
            [-80.0432, 35.4283],
            [-80.0605, 35.4332],
            [-80.0716, 35.4204],
            [-80.0825, 35.4303],
            [-80.0887, 35.4213],
            [-80.0847, 35.3966],
            [-80.0688, 35.3941],
            [-80.0512, 35.3715],
            [-80.0654, 35.3605],
            [-80.0663, 35.3406],
            [-80.0819, 35.3215],
            [-80.0984, 35.2581],
            [-80.0533, 35.1786],
            [-80.0754, 35.1431],
            [-80.0678, 35.1119],
            [-80.0246, 35.0908],
            [-79.9939, 35.0855],
            [-79.9574, 35.1139],
            [-79.9279, 35.1177],
            [-79.9107, 35.0946],
            [-79.9091, 35.0711],
            [-79.8983, 35.093],
            [-79.8987, 35.1151],
            [-79.8884, 35.1159],
            [-79.8732, 35.1333],
            [-79.8478, 35.1301],
            [-79.8717, 35.1722],
            [-79.8358, 35.1772],
            [-79.7498, 35.173],
            [-79.6945, 35.1793],
            [-79.6422, 35.1577],
            [-79.6143, 35.1637],
            [-79.5748, 35.1213],
            [-79.5829, 35.0841],
            [-79.5713, 35.0659],
            [-79.5077, 35.063],
            [-79.5164, 35.0916],
            [-79.5419, 35.1179],
            [-79.5471, 35.1531],
            [-79.5118, 35.1632],
            [-79.5426, 35.1649],
            [-79.5538, 35.1938],
            [-79.5357, 35.2077],
            [-79.5371, 35.1979],
            [-79.5247, 35.1956],
            [-79.5172, 35.2138],
            [-79.4901, 35.2137],
            [-79.4862, 35.2039],
            [-79.4762, 35.2051],
            [-79.4785, 35.2192],
            [-79.4474, 35.2375],
            [-79.4425, 35.2675],
            [-79.4313, 35.2821],
            [-79.4254, 35.313],
            [-79.4255, 35.3257],
            [-79.4395, 35.3333],
            [-79.4571, 35.3174],
            [-79.5076, 35.3218],
            [-79.5112, 35.3048],
            [-79.5261, 35.2987],
            [-79.5488, 35.3272],
            [-79.5848, 35.325],
            [-79.5913, 35.3521],
            [-79.5946, 35.371],
            [-79.5723, 35.3952],
            [-79.5757, 35.4024],
            [-79.5749, 35.4046],
            [-79.5043, 35.3929],
            [-79.522, 35.4082],
            [-79.517, 35.4577],
            [-79.5201, 35.4789],
            [-79.5087, 35.4806],
            [-79.4838, 35.4667],
            [-79.4839, 35.4854],
            [-79.4713, 35.4811],
            [-79.4654, 35.4951],
            [-79.4451, 35.4841],
            [-79.4436, 35.4991],
            [-79.4166, 35.4825],
            [-79.4399, 35.4736],
            [-79.4366, 35.4672],
            [-79.4088, 35.4798],
            [-79.3915, 35.4731],
            [-79.3813, 35.4559],
            [-79.3647, 35.4588],
            [-79.3596, 35.4694],
            [-79.3485, 35.4636],
            [-79.3268, 35.4349],
            [-79.3298, 35.4236],
            [-79.2896, 35.4004],
            [-79.2656, 35.3454],
            [-79.2441, 35.3372],
            [-79.2163, 35.3682],
            [-79.2027, 35.3679],
            [-79.1876, 35.3606],
            [-79.2046, 35.3369],
            [-79.1911, 35.3214],
            [-79.193, 35.3115],
            [-79.1833, 35.307],
            [-79.1682, 35.322],
            [-79.159, 35.3113],
            [-79.1387, 35.3129],
            [-79.1323, 35.3334],
            [-79.1165, 35.3243],
            [-79.1125, 35.307],
            [-79.0832, 35.2997],
            [-79.0745, 35.3011],
            [-79.0724, 35.2784],
            [-79.052, 35.264],
            [-79.0418, 35.259],
            [-79.0267, 35.2712],
            [-79.0217, 35.2691],
            [-78.9994, 35.28],
            [-78.9865, 35.2973],
            [-78.9675, 35.2989],
            [-78.9419, 35.2865],
            [-78.913, 35.2889],
            [-78.9149, 35.2992],
            [-78.9324, 35.2987],
            [-78.9403, 35.3054],
            [-78.9357, 35.3211],
            [-78.9261, 35.3224],
            [-78.9311, 35.3064],
            [-78.9224, 35.3012],
            [-78.8922, 35.3128],
            [-78.8964, 35.3331],
            [-78.8879, 35.3486],
            [-78.8713, 35.3491],
            [-78.8653, 35.3564],
            [-78.8604, 35.3517],
            [-78.8505, 35.3682],
            [-78.8148, 35.3376],
            [-78.7842, 35.3353],
            [-78.7867, 35.3444],
            [-78.7826, 35.3296],
            [-78.7599, 35.326],
            [-78.7487, 35.3309],
            [-78.7245, 35.3287],
            [-78.7086, 35.3296],
            [-78.7543, 35.3821],
            [-78.7499, 35.3972],
            [-78.735, 35.4002],
            [-78.7046, 35.3665],
            [-78.6977, 35.3752],
            [-78.685, 35.3726],
            [-78.6806, 35.3819],
            [-78.6728, 35.3769],
            [-78.6704, 35.393],
            [-78.6593, 35.3745],
            [-78.6474, 35.3762],
            [-78.645, 35.3894],
            [-78.6252, 35.3759],
            [-78.6132, 35.3867],
            [-78.5796, 35.3855],
            [-78.581, 35.3668],
            [-78.5709, 35.3685],
            [-78.5672, 35.3596],
            [-78.563, 35.3645],
            [-78.5414, 35.3513],
            [-78.496, 35.2878],
            [-78.4587, 35.2956],
            [-78.4495, 35.2835],
            [-78.4319, 35.286],
            [-78.4518, 35.3055],
            [-78.4286, 35.3032],
            [-78.429, 35.3092],
            [-78.401, 35.2918],
            [-78.4037, 35.3055],
            [-78.3816, 35.3038],
            [-78.38, 35.3106],
            [-78.3671, 35.3039],
            [-78.3637, 35.3385],
            [-78.3462, 35.3438],
            [-78.3542, 35.3141],
            [-78.3261, 35.3056],
            [-78.3203, 35.2942],
            [-78.3145, 35.2991],
            [-78.3061, 35.295],
            [-78.3062, 35.3103],
            [-78.2851, 35.3178],
            [-78.3047, 35.2861],
            [-78.293, 35.2744],
            [-78.2917, 35.2878],
            [-78.2705, 35.2966],
            [-78.258, 35.2841],
            [-78.2356, 35.2926],
            [-78.2367, 35.2999],
            [-78.2235, 35.2986],
            [-78.2106, 35.2915],
            [-78.2216, 35.285],
            [-78.1656, 35.2718],
            [-78.1291, 35.2757],
            [-78.1154, 35.2925],
            [-78.1166, 35.2687],
            [-78.1235, 35.2661],
            [-78.1143, 35.254],
            [-78.0998, 35.2725],
            [-78.1071, 35.2872],
            [-78.0845, 35.2919],
            [-78.0799, 35.3102],
            [-78.0501, 35.3194],
            [-78.0362, 35.3399],
            [-78.0254, 35.3407],
            [-78.0103, 35.334],
            [-77.9873, 35.3368],
            [-77.9653, 35.3174],
            [-77.9436, 35.3186],
            [-77.9523, 35.3031],
            [-77.9323, 35.282],
            [-77.9404, 35.2797],
            [-77.9407, 35.2686],
            [-77.9394, 35.268],
          ],
          [
            [-81.8844, 35.7428],
            [-81.8558, 35.7275],
            [-81.8444, 35.7385],
            [-81.856, 35.7298],
            [-81.8616, 35.7434],
            [-81.8844, 35.7428],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Wilmington Mkt": {
    type: "Feature",
    properties: { code: "NC_WIL", name: "Wilmington Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-77.9394, 35.268],
          [-77.9061, 35.262],
          [-77.8948, 35.2751],
          [-77.8863, 35.2705],
          [-77.885, 35.2551],
          [-77.8773, 35.2587],
          [-77.8798, 35.2502],
          [-77.8518, 35.3048],
          [-77.8486, 35.3436],
          [-77.8927, 35.3641],
          [-77.8638, 35.3809],
          [-77.8607, 35.4032],
          [-77.8525, 35.4075],
          [-77.8427, 35.4014],
          [-77.831, 35.4096],
          [-77.8268, 35.4702],
          [-77.7834, 35.5005],
          [-77.7449, 35.5084],
          [-77.7395, 35.5195],
          [-77.709, 35.5063],
          [-77.6692, 35.5104],
          [-77.6628, 35.5319],
          [-77.6494, 35.5364],
          [-77.6115, 35.526],
          [-77.6151, 35.5162],
          [-77.5833, 35.5274],
          [-77.5634, 35.5262],
          [-77.5732, 35.484],
          [-77.5529, 35.4742],
          [-77.5523, 35.4822],
          [-77.5366, 35.4794],
          [-77.5237, 35.4992],
          [-77.5303, 35.5185],
          [-77.5226, 35.5249],
          [-77.5244, 35.519],
          [-77.5134, 35.5181],
          [-77.5221, 35.5251],
          [-77.4885, 35.5348],
          [-77.491, 35.5417],
          [-77.4801, 35.5428],
          [-77.484, 35.5486],
          [-77.4432, 35.5428],
          [-77.4428, 35.563],
          [-77.3885, 35.5626],
          [-77.394, 35.5484],
          [-77.3651, 35.559],
          [-77.3626, 35.5536],
          [-77.3511, 35.557],
          [-77.3466, 35.5247],
          [-77.3185, 35.5125],
          [-77.3268, 35.5037],
          [-77.3519, 35.4995],
          [-77.3202, 35.4834],
          [-77.3326, 35.4753],
          [-77.3039, 35.4719],
          [-77.2809, 35.4583],
          [-77.2746, 35.4452],
          [-77.2794, 35.4239],
          [-77.2393, 35.4179],
          [-77.2126, 35.4264],
          [-77.2181, 35.4086],
          [-77.2067, 35.4182],
          [-77.1968, 35.4105],
          [-77.1904, 35.419],
          [-77.1436, 35.395],
          [-77.1285, 35.4284],
          [-77.1259, 35.386],
          [-76.9591, 35.3009],
          [-76.8455, 35.216],
          [-76.6325, 35.2352],
          [-76.6122, 35.2794],
          [-76.617, 35.3309],
          [-76.6052, 35.3379],
          [-76.5004, 35.3219],
          [-76.4723, 35.2949],
          [-76.4678, 35.2612],
          [-76.4948, 35.2129],
          [-76.5397, 35.1668],
          [-76.5363, 35.1421],
          [-76.6004, 35.0679],
          [-76.6465, 35.042],
          [-76.6285, 34.9948],
          [-76.5881, 34.9914],
          [-76.5026, 35.0072],
          [-76.4745, 35.0701],
          [-76.4358, 35.0579],
          [-76.4255, 35.0015],
          [-76.3956, 34.9752],
          [-76.332, 34.9709],
          [-76.3523, 35.0331],
          [-76.2884, 35.0057],
          [-76.2965, 34.9762],
          [-76.2777, 34.94],
          [-76.3477, 34.8722],
          [-76.4002, 34.8555],
          [-76.4159, 34.8136],
          [-76.3838, 34.8079],
          [-76.3228, 34.8612],
          [-76.2337, 34.9259],
          [-76.0933, 35.0487],
          [-76.0699, 35.0757],
          [-76.0359, 35.059],
          [-76.1373, 34.9879],
          [-76.2331, 34.9055],
          [-76.3102, 34.8523],
          [-76.3892, 34.7819],
          [-76.4941, 34.662],
          [-76.5359, 34.5886],
          [-76.5493, 34.6455],
          [-76.6763, 34.6932],
          [-76.77, 34.6969],
          [-76.9063, 34.6828],
          [-77.0311, 34.6612],
          [-77.1368, 34.6329],
          [-77.2092, 34.605],
          [-77.3225, 34.5356],
          [-77.4629, 34.4714],
          [-77.5823, 34.4005],
          [-77.6617, 34.3419],
          [-77.7385, 34.2739],
          [-77.8292, 34.1626],
          [-77.8782, 34.068],
          [-77.9157, 33.9714],
          [-77.9466, 33.9123],
          [-77.9602, 33.8533],
          [-78.0068, 33.8587],
          [-78.0187, 33.8883],
          [-78.0954, 33.906],
          [-78.1777, 33.9143],
          [-78.2761, 33.9124],
          [-78.384, 33.9019],
          [-78.5411, 33.8511],
          [-79.0712, 34.2992],
          [-79.0384, 34.3135],
          [-79.0118, 34.3808],
          [-78.9685, 34.3974],
          [-78.9709, 34.4277],
          [-78.8715, 34.4827],
          [-78.8252, 34.4618],
          [-78.8224, 34.4303],
          [-78.6929, 34.4684],
          [-78.6764, 34.4877],
          [-78.6899, 34.5334],
          [-78.6151, 34.5777],
          [-78.5111, 34.5624],
          [-78.453, 34.6143],
          [-78.4864, 34.7068],
          [-78.5595, 34.7457],
          [-78.5693, 34.8367],
          [-78.5393, 34.8639],
          [-78.497, 34.8397],
          [-78.4424, 34.8891],
          [-78.4174, 34.8692],
          [-78.3564, 34.859],
          [-78.3669, 34.8344],
          [-78.3144, 34.8167],
          [-78.2943, 34.8472],
          [-78.2598, 34.8453],
          [-78.2149, 34.8901],
          [-78.1045, 34.895],
          [-78.1003, 34.9297],
          [-78.0471, 34.9268],
          [-77.946, 34.9431],
          [-77.8927, 34.8978],
          [-77.8308, 34.9326],
          [-77.8075, 34.9587],
          [-77.8616, 34.9857],
          [-77.845, 35.03],
          [-77.8175, 35.0474],
          [-77.8789, 35.0801],
          [-77.8518, 35.0967],
          [-77.8839, 35.1622],
          [-77.9123, 35.188],
          [-77.9545, 35.1848],
          [-77.96, 35.2428],
          [-77.9394, 35.268],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Fargo Mkt": {
    type: "Feature",
    properties: { code: "ND_FAR", name: "Fargo Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-98.7371, 49.0003],
          [-98.2066, 49.0004],
          [-97.4833, 49.0005],
          [-96.931, 49],
          [-95.978, 49],
          [-95.3687, 48.9987],
          [-95.1537, 48.9989],
          [-95.1533, 49.1755],
          [-95.1534, 49.3831],
          [-95.1533, 49.3844],
          [-95.1502, 49.383],
          [-95.1497, 49.3806],
          [-95.1453, 49.3783],
          [-95.1418, 49.3783],
          [-95.1265, 49.3694],
          [-95.0584, 49.3532],
          [-94.9575, 49.3702],
          [-94.8542, 49.3242],
          [-94.8252, 49.2943],
          [-94.7732, 49.1207],
          [-94.7502, 49.0998],
          [-94.7502, 49],
          [-94.7189, 49],
          [-94.6831, 48.8841],
          [-94.7043, 48.8243],
          [-94.6909, 48.7781],
          [-94.5871, 48.7176],
          [-94.5384, 48.7028],
          [-94.5331, 48.7013],
          [-94.5246, 48.7016],
          [-94.5089, 48.7004],
          [-94.4645, 48.6955],
          [-94.4523, 48.6924],
          [-94.4466, 48.6929],
          [-94.4387, 48.6949],
          [-94.4242, 48.7054],
          [-94.4214, 48.7088],
          [-94.4162, 48.7109],
          [-94.3888, 48.7119],
          [-94.3686, 48.7064],
          [-94.353, 48.7041],
          [-94.3428, 48.7034],
          [-94.3284, 48.7045],
          [-94.3084, 48.7102],
          [-94.2605, 48.6964],
          [-94.2512, 48.6835],
          [-94.2506, 48.6782],
          [-94.2546, 48.6639],
          [-94.2546, 48.6614],
          [-94.2502, 48.6563],
          [-94.2468, 48.6542],
          [-94.2444, 48.6534],
          [-94.2336, 48.6523],
          [-94.2243, 48.6495],
          [-94.0912, 48.6437],
          [-94.0767, 48.6442],
          [-94.0658, 48.6461],
          [-94.0069, 48.6432],
          [-94.0007, 48.6428],
          [-93.9901, 48.6397],
          [-93.9765, 48.6376],
          [-93.9634, 48.6372],
          [-93.9544, 48.6337],
          [-93.927, 48.6312],
          [-93.9166, 48.6322],
          [-93.9144, 48.6343],
          [-93.9115, 48.6347],
          [-93.8869, 48.6308],
          [-93.8516, 48.6301],
          [-93.844, 48.6294],
          [-93.8374, 48.6271],
          [-93.8343, 48.625],
          [-93.828, 48.613],
          [-93.8241, 48.6107],
          [-93.8226, 48.6091],
          [-93.8201, 48.6038],
          [-93.8185, 48.5953],
          [-93.812, 48.5849],
          [-93.808, 48.5803],
          [-93.8053, 48.5703],
          [-93.8054, 48.5684],
          [-93.8067, 48.5618],
          [-93.8121, 48.5507],
          [-93.8112, 48.5424],
          [-93.8176, 48.5358],
          [-93.7945, 48.516],
          [-93.7529, 48.5151],
          [-93.7418, 48.5173],
          [-93.7091, 48.518],
          [-93.7033, 48.5172],
          [-93.6947, 48.5148],
          [-93.6909, 48.5146],
          [-93.6746, 48.5163],
          [-93.6567, 48.5157],
          [-93.6454, 48.5173],
          [-93.6382, 48.5225],
          [-93.6355, 48.5277],
          [-93.6323, 48.5301],
          [-93.6289, 48.5312],
          [-93.6264, 48.531],
          [-93.6223, 48.5265],
          [-93.6183, 48.524],
          [-93.6106, 48.5217],
          [-93.6038, 48.5233],
          [-93.5944, 48.5288],
          [-93.5472, 48.5287],
          [-93.5321, 48.5325],
          [-93.5187, 48.534],
          [-93.5155, 48.5348],
          [-93.5002, 48.5412],
          [-93.4815, 48.5431],
          [-93.4675, 48.5457],
          [-93.4608, 48.5506],
          [-93.4567, 48.5618],
          [-93.4643, 48.5918],
          [-93.4385, 48.5934],
          [-93.4086, 48.6084],
          [-93.4037, 48.6076],
          [-93.399, 48.6039],
          [-93.3979, 48.6037],
          [-93.3957, 48.6034],
          [-93.395, 48.6033],
          [-93.3712, 48.6051],
          [-93.3554, 48.6116],
          [-93.3541, 48.6123],
          [-93.3532, 48.6134],
          [-93.3531, 48.6157],
          [-93.3491, 48.6249],
          [-93.3475, 48.6266],
          [-93.2549, 48.6428],
          [-93.2074, 48.6425],
          [-93.1841, 48.6284],
          [-93.1781, 48.6233],
          [-93.0884, 48.6276],
          [-93.0888, 48.62],
          [-93.0888, 48.6187],
          [-93.0897, 48.5955],
          [-93.0902, 48.5605],
          [-93.0905, 48.5181],
          [-93.0905, 48.5151],
          [-93.0907, 48.4999],
          [-93.0908, 48.4896],
          [-93.0666, 48.4748],
          [-93.0489, 48.4628],
          [-93.057, 48.4516],
          [-93.0291, 48.4473],
          [-93.0378, 48.4211],
          [-93.0487, 48.4213],
          [-93.0541, 48.4083],
          [-93.0408, 48.3994],
          [-93.0487, 48.3927],
          [-93.0937, 48.3995],
          [-93.0971, 48.1747],
          [-93.1032, 48.1653],
          [-93.1091, 48.1644],
          [-93.1179, 48.1648],
          [-93.1232, 48.1625],
          [-93.1277, 48.1598],
          [-93.1318, 48.1565],
          [-93.136, 48.156],
          [-93.1403, 48.1565],
          [-93.1428, 48.1533],
          [-93.1434, 48.1517],
          [-93.2002, 48.1418],
          [-93.2099, 48.1654],
          [-93.2788, 48.1675],
          [-93.3117, 48.1518],
          [-93.3374, 48.1511],
          [-93.3359, 48.0802],
          [-93.3628, 48.0554],
          [-93.368, 48.063],
          [-93.4175, 48.0661],
          [-93.4824, 48.1094],
          [-93.5814, 48.115],
          [-93.5936, 48.021],
          [-93.5574, 48.0249],
          [-93.5316, 48.0196],
          [-93.5347, 47.9954],
          [-93.5041, 47.9853],
          [-93.4755, 47.986],
          [-93.4481, 47.9693],
          [-93.4456, 47.9596],
          [-93.4201, 47.9587],
          [-93.4189, 47.9303],
          [-93.4431, 47.8946],
          [-93.2986, 47.893],
          [-93.2999, 47.8804],
          [-93.3154, 47.8798],
          [-93.3127, 47.869],
          [-93.2813, 47.8568],
          [-93.2869, 47.8438],
          [-93.2598, 47.8215],
          [-93.2467, 47.8106],
          [-93.3056, 47.796],
          [-93.2644, 47.7676],
          [-93.2633, 47.7572],
          [-93.3284, 47.7652],
          [-93.3558, 47.7099],
          [-93.3066, 47.7146],
          [-93.3028, 47.6914],
          [-93.324, 47.6802],
          [-93.3471, 47.6823],
          [-93.3504, 47.6719],
          [-93.338, 47.6564],
          [-93.3065, 47.6521],
          [-93.275, 47.6615],
          [-93.259, 47.6699],
          [-93.2676, 47.6628],
          [-93.2422, 47.6735],
          [-93.2082, 47.6563],
          [-93.1953, 47.659],
          [-93.1927, 47.5972],
          [-93.2221, 47.6112],
          [-93.2687, 47.5756],
          [-93.3053, 47.5852],
          [-93.3075, 47.5664],
          [-93.3683, 47.5801],
          [-93.3724, 47.5661],
          [-93.4357, 47.6064],
          [-93.4634, 47.6083],
          [-93.5048, 47.5964],
          [-93.5059, 47.6023],
          [-93.5279, 47.5818],
          [-93.5257, 47.5707],
          [-93.5404, 47.5672],
          [-93.5287, 47.5601],
          [-93.5417, 47.5465],
          [-93.5373, 47.5369],
          [-93.5439, 47.5495],
          [-93.5593, 47.5504],
          [-93.5647, 47.5362],
          [-93.5769, 47.5335],
          [-93.5783, 47.4006],
          [-93.6165, 47.3998],
          [-93.6404, 47.3647],
          [-93.6697, 47.3664],
          [-93.6729, 47.36],
          [-93.6898, 47.3677],
          [-93.694, 47.3583],
          [-93.7119, 47.3666],
          [-93.7093, 47.3787],
          [-93.7199, 47.3714],
          [-93.7555, 47.3714],
          [-93.7557, 47.3407],
          [-93.7233, 47.3393],
          [-93.7234, 47.3258],
          [-93.702, 47.3183],
          [-93.7455, 47.3129],
          [-93.7739, 47.2983],
          [-93.774, 47.305],
          [-93.7743, 47.2979],
          [-93.7797, 47.2988],
          [-93.7749, 47.3006],
          [-93.7753, 47.3024],
          [-93.7777, 47.3037],
          [-93.7836, 47.3054],
          [-93.7833, 47.2994],
          [-93.789, 47.2914],
          [-93.7763, 47.2781],
          [-93.7911, 47.2671],
          [-93.7829, 47.2613],
          [-93.7562, 47.1924],
          [-93.774, 47.1857],
          [-93.7742, 47.1597],
          [-93.7777, 47.1567],
          [-93.7778, 47.1497],
          [-93.7795, 47.1467],
          [-93.7759, 47.1466],
          [-93.7742, 47.149],
          [-93.774, 47.1009],
          [-93.7755, 47.0218],
          [-93.7748, 47],
          [-93.7752, 46.9519],
          [-93.7748, 46.9282],
          [-93.7756, 46.8494],
          [-93.7756, 46.8403],
          [-93.776, 46.8029],
          [-93.9408, 46.8036],
          [-93.9382, 46.8074],
          [-93.9411, 46.8088],
          [-93.9468, 46.8036],
          [-93.9568, 46.8036],
          [-94.0739, 46.8032],
          [-94.1592, 46.8047],
          [-94.1652, 46.8338],
          [-94.228, 46.8342],
          [-94.3212, 46.8634],
          [-94.3094, 46.9179],
          [-94.2439, 46.9511],
          [-94.2484, 46.9746],
          [-94.3044, 46.9909],
          [-94.3282, 47.021],
          [-94.2855, 47.0928],
          [-94.2502, 47.0969],
          [-94.2507, 47.1554],
          [-94.3223, 47.1748],
          [-94.3109, 47.2138],
          [-94.2483, 47.2618],
          [-94.2662, 47.294],
          [-94.3172, 47.2698],
          [-94.3753, 47.2235],
          [-94.434, 47.2544],
          [-94.5109, 47.2118],
          [-94.5462, 47.1702],
          [-94.5804, 47.1863],
          [-94.5651, 47.222],
          [-94.6637, 47.2246],
          [-94.6637, 47.3084],
          [-94.7371, 47.3097],
          [-94.6976, 47.3515],
          [-94.756, 47.3668],
          [-94.8628, 47.3672],
          [-94.9052, 47.3386],
          [-94.9736, 47.3682],
          [-95.0499, 47.3684],
          [-95.0677, 47.3163],
          [-95.115, 47.2829],
          [-95.1909, 47.2752],
          [-95.2124, 47.2536],
          [-95.2757, 47.2531],
          [-95.3191, 47.2243],
          [-95.2973, 47.1518],
          [-95.2917, 47.028],
          [-95.2547, 47.0286],
          [-95.2539, 46.9842],
          [-95.2249, 46.9781],
          [-95.2262, 46.9204],
          [-95.2677, 46.8621],
          [-95.2685, 46.8331],
          [-95.3288, 46.8322],
          [-95.3729, 46.8033],
          [-95.373, 46.7743],
          [-95.4111, 46.7478],
          [-95.3924, 46.7163],
          [-95.2887, 46.7167],
          [-95.2803, 46.5753],
          [-95.2398, 46.5721],
          [-95.2382, 46.5357],
          [-95.1973, 46.4852],
          [-95.2703, 46.4489],
          [-95.25, 46.4211],
          [-95.2506, 46.2664],
          [-95.3637, 46.2459],
          [-95.3903, 46.195],
          [-95.512, 46.2239],
          [-95.5112, 46.1479],
          [-95.5869, 46.1367],
          [-95.7083, 46.1367],
          [-95.8026, 46.2072],
          [-95.869, 46.2242],
          [-95.9372, 46.2243],
          [-96.0191, 46.1955],
          [-95.9984, 46.1083],
          [-95.8966, 46.1082],
          [-95.8925, 46.0502],
          [-95.8635, 46.0065],
          [-95.8111, 45.9628],
          [-95.9743, 45.9632],
          [-95.9869, 45.8612],
          [-96.1303, 45.8759],
          [-96.1303, 45.9347],
          [-96.1626, 45.9781],
          [-96.2663, 45.9781],
          [-96.3284, 45.9639],
          [-96.4523, 45.964],
          [-96.494, 46.0216],
          [-96.5766, 46.0218],
          [-96.5633, 45.9352],
          [-97.3958, 45.9351],
          [-98.0082, 45.9361],
          [-98.0076, 46.0661],
          [-97.9046, 46.0661],
          [-97.9037, 46.1676],
          [-97.9244, 46.1965],
          [-98.007, 46.1852],
          [-98.0063, 46.2826],
          [-97.9511, 46.2967],
          [-97.9092, 46.3259],
          [-97.9092, 46.355],
          [-97.9523, 46.3826],
          [-98.0349, 46.3697],
          [-98.0336, 46.5435],
          [-98.0546, 46.5871],
          [-98.0969, 46.6017],
          [-98.1025, 46.6599],
          [-98.1657, 46.7179],
          [-98.1022, 46.7905],
          [-98.228, 46.7906],
          [-98.2279, 46.8341],
          [-98.1654, 46.8341],
          [-98.1654, 46.8775],
          [-98.1964, 46.9052],
          [-98.1958, 46.9731],
          [-98.1713, 46.9942],
          [-98.1925, 47.0667],
          [-98.0861, 47.0666],
          [-98.0256, 47.0864],
          [-98.0249, 47.1509],
          [-97.9697, 47.1822],
          [-97.9664, 47.2261],
          [-98.0109, 47.2516],
          [-97.9892, 47.2914],
          [-97.9855, 47.5334],
          [-98.0429, 47.5384],
          [-98.0975, 47.5727],
          [-98.1768, 47.5726],
          [-98.1767, 47.6451],
          [-98.237, 47.6451],
          [-98.2257, 47.7157],
          [-98.2688, 47.7552],
          [-98.2472, 47.7736],
          [-98.2473, 47.8604],
          [-98.1613, 47.8606],
          [-98.1611, 47.8896],
          [-98.2259, 47.8895],
          [-98.2259, 48.021],
          [-98.2061, 48.0499],
          [-98.1629, 48.0498],
          [-98.1627, 48.1585],
          [-98.1414, 48.1656],
          [-98.1411, 48.2236],
          [-98.1624, 48.2962],
          [-98.2059, 48.3109],
          [-98.2278, 48.3545],
          [-98.2921, 48.3546],
          [-98.3401, 48.4131],
          [-98.3837, 48.4133],
          [-98.3836, 48.4566],
          [-98.3401, 48.4566],
          [-98.3182, 48.4857],
          [-98.318, 48.5728],
          [-98.2964, 48.5726],
          [-98.2968, 48.6597],
          [-98.3619, 48.6596],
          [-98.3618, 48.6306],
          [-98.4275, 48.6306],
          [-98.4276, 48.674],
          [-98.4705, 48.674],
          [-98.4704, 48.7173],
          [-98.6718, 48.7178],
          [-98.7156, 48.7323],
          [-98.7156, 48.8048],
          [-98.781, 48.8193],
          [-98.7591, 48.8773],
          [-98.8469, 48.9063],
          [-98.8908, 48.9064],
          [-98.8909, 48.9643],
          [-98.869, 48.9787],
          [-98.7372, 48.9786],
          [-98.7371, 49.0003],
        ],
        [
          [-94.4163, 47.444],
          [-94.3493, 47.4232],
          [-94.2449, 47.3582],
          [-94.1848, 47.3549],
          [-94.1525, 47.388],
          [-94.118, 47.4807],
          [-94.3226, 47.4156],
          [-94.4163, 47.444],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Bismarck Mkt": {
    type: "Feature",
    properties: { code: "ND_BIS", name: "Bismarck Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-104.0487, 48.9999],
          [-103.3755, 48.999],
          [-102.9388, 48.9995],
          [-102.2643, 48.9986],
          [-101.6268, 48.9992],
          [-101.1254, 48.9991],
          [-100.5558, 48.9995],
          [-99.9138, 48.999],
          [-99.2576, 48.9996],
          [-98.869, 49.0002],
          [-98.7371, 49.0003],
          [-98.7372, 48.9786],
          [-98.869, 48.9787],
          [-98.8909, 48.9643],
          [-98.8908, 48.9064],
          [-98.8469, 48.9063],
          [-98.7591, 48.8773],
          [-98.781, 48.8193],
          [-98.7156, 48.8048],
          [-98.7156, 48.7323],
          [-98.6718, 48.7178],
          [-98.4704, 48.7173],
          [-98.4705, 48.674],
          [-98.4276, 48.674],
          [-98.4275, 48.6306],
          [-98.3618, 48.6306],
          [-98.3619, 48.6596],
          [-98.2968, 48.6597],
          [-98.2964, 48.5726],
          [-98.318, 48.5728],
          [-98.3182, 48.4857],
          [-98.3401, 48.4566],
          [-98.3836, 48.4566],
          [-98.3837, 48.4133],
          [-98.3401, 48.4131],
          [-98.2921, 48.3546],
          [-98.2278, 48.3545],
          [-98.2059, 48.3109],
          [-98.1624, 48.2962],
          [-98.1411, 48.2236],
          [-98.1414, 48.1656],
          [-98.1627, 48.1585],
          [-98.1629, 48.0498],
          [-98.2061, 48.0499],
          [-98.2259, 48.021],
          [-98.2259, 47.8895],
          [-98.1611, 47.8896],
          [-98.1613, 47.8606],
          [-98.2473, 47.8604],
          [-98.2472, 47.7736],
          [-98.2688, 47.7552],
          [-98.2257, 47.7157],
          [-98.237, 47.6451],
          [-98.1767, 47.6451],
          [-98.1768, 47.5726],
          [-98.0975, 47.5727],
          [-98.0429, 47.5384],
          [-97.9855, 47.5334],
          [-97.9892, 47.2914],
          [-98.0109, 47.2516],
          [-97.9664, 47.2261],
          [-97.9697, 47.1822],
          [-98.0249, 47.1509],
          [-98.0256, 47.0864],
          [-98.0861, 47.0666],
          [-98.1925, 47.0667],
          [-98.1713, 46.9942],
          [-98.1958, 46.9731],
          [-98.1964, 46.9052],
          [-98.1654, 46.8775],
          [-98.1654, 46.8341],
          [-98.2279, 46.8341],
          [-98.228, 46.7906],
          [-98.1022, 46.7905],
          [-98.1657, 46.7179],
          [-98.1025, 46.6599],
          [-98.0969, 46.6017],
          [-98.0546, 46.5871],
          [-98.0336, 46.5435],
          [-98.0349, 46.3697],
          [-97.9523, 46.3826],
          [-97.9092, 46.355],
          [-97.9092, 46.3259],
          [-97.9511, 46.2967],
          [-98.0063, 46.2826],
          [-98.007, 46.1852],
          [-97.9244, 46.1965],
          [-97.9037, 46.1676],
          [-97.9046, 46.0661],
          [-98.0076, 46.0661],
          [-98.0082, 45.9361],
          [-98.4145, 45.9365],
          [-99.2577, 45.9401],
          [-100.0069, 45.942],
          [-100.6277, 45.9436],
          [-101.4158, 45.9438],
          [-101.8744, 45.9445],
          [-101.8738, 46.0633],
          [-101.8001, 46.0321],
          [-101.7285, 46.036],
          [-101.729, 46.1181],
          [-101.8741, 46.118],
          [-101.874, 46.1473],
          [-101.998, 46.1474],
          [-101.9985, 46.0749],
          [-102.1647, 46.0752],
          [-102.1648, 46.1041],
          [-102.2478, 46.1332],
          [-102.2478, 46.1622],
          [-102.3091, 46.1771],
          [-102.3311, 46.1623],
          [-102.3521, 46.1043],
          [-102.3543, 45.9449],
          [-102.9205, 45.945],
          [-103.5587, 45.9451],
          [-104.0454, 45.9453],
          [-104.0461, 46.3839],
          [-104.0459, 46.8308],
          [-104.0455, 47.2157],
          [-104.0425, 47.8068],
          [-104.0054, 47.8035],
          [-103.9678, 47.8475],
          [-103.9585, 47.9532],
          [-104.0441, 47.9961],
          [-104.047, 48.3907],
          [-104.0487, 48.9999],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "N Platte Mkt": {
    type: "Feature",
    properties: { code: "NE_NPL", name: "N Platte Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-105.5264, 42.2915],
          [-105.3755, 42.2901],
          [-105.3586, 42.3193],
          [-105.3617, 42.3763],
          [-105.3326, 42.4311],
          [-105.2823, 42.4313],
          [-105.2865, 42.6058],
          [-105.0749, 42.6082],
          [-105.0099, 42.6823],
          [-105.01, 42.7363],
          [-104.992, 42.7816],
          [-105.044, 42.8035],
          [-105.1003, 42.7998],
          [-105.1025, 42.9583],
          [-104.9413, 42.9687],
          [-104.898, 42.999],
          [-104.898, 43.1236],
          [-104.8492, 43.119],
          [-104.8158, 43.1462],
          [-104.7458, 43.1711],
          [-104.7581, 43.2316],
          [-104.717, 43.2344],
          [-104.7404, 43.2996],
          [-104.6717, 43.3242],
          [-104.6716, 43.3593],
          [-104.6594, 43.4306],
          [-104.5935, 43.453],
          [-104.5138, 43.5018],
          [-104.0548, 43.5031],
          [-104.0531, 43.0006],
          [-103.4046, 43.0007],
          [-103.0009, 43.0005],
          [-103.001, 43.1585],
          [-102.9382, 43.1259],
          [-102.8288, 43.13],
          [-102.8107, 43.0923],
          [-102.8421, 43.0314],
          [-102.8109, 43],
          [-102.3003, 42.9995],
          [-101.85, 42.9993],
          [-101.6254, 42.9962],
          [-101.0004, 42.9975],
          [-100.1984, 42.9985],
          [-100.1985, 42.8461],
          [-100.1504, 42.8325],
          [-100.134, 42.8078],
          [-100.056, 42.7852],
          [-100.0078, 42.7886],
          [-99.9689, 42.7725],
          [-99.898, 42.7655],
          [-99.8854, 42.8239],
          [-99.8553, 42.8237],
          [-99.8521, 42.7777],
          [-99.8193, 42.7433],
          [-99.7479, 42.727],
          [-99.7666, 42.6745],
          [-99.6771, 42.6423],
          [-99.676, 42.4344],
          [-99.6638, 42.4346],
          [-99.6624, 42.086],
          [-100.0913, 42.0855],
          [-100.092, 42.025],
          [-100.1515, 42.0268],
          [-100.1961, 42.0576],
          [-100.2678, 42.0443],
          [-100.2655, 41.7398],
          [-100.4908, 41.7394],
          [-100.5149, 41.6944],
          [-100.5027, 41.6562],
          [-100.3262, 41.6518],
          [-100.322, 41.6271],
          [-100.2529, 41.6177],
          [-100.2528, 41.5668],
          [-100.2191, 41.5667],
          [-100.1949, 41.5088],
          [-100.1167, 41.5092],
          [-100.0589, 41.4949],
          [-100.0588, 41.4589],
          [-99.9968, 41.4023],
          [-100.031, 41.3699],
          [-100.1244, 41.3935],
          [-100.1792, 41.3933],
          [-100.1602, 41.3531],
          [-100.1613, 41.1769],
          [-100.0931, 41.1629],
          [-100.007, 41.162],
          [-99.9944, 41.0904],
          [-100.0081, 41.0473],
          [-99.9761, 41.0115],
          [-99.8815, 40.9672],
          [-99.8455, 40.931],
          [-99.8455, 40.8733],
          [-99.8255, 40.8448],
          [-99.8254, 40.7968],
          [-99.8826, 40.7578],
          [-99.9017, 40.7144],
          [-99.9445, 40.6978],
          [-99.9466, 40.6554],
          [-99.9815, 40.6454],
          [-99.9812, 40.4382],
          [-100.0192, 40.4966],
          [-100.057, 40.4711],
          [-100.0759, 40.4237],
          [-100.0814, 40.2951],
          [-100.0058, 40.2925],
          [-100.0046, 40.1759],
          [-100.0424, 40.1761],
          [-100.0421, 40.1102],
          [-100.043, 40.0643],
          [-100.043, 40.0015],
          [-100.5153, 40.0018],
          [-100.9374, 40.0021],
          [-101.4884, 40.0025],
          [-101.7459, 40.0027],
          [-101.841, 40.0028],
          [-101.8682, 40.0029],
          [-101.9042, 40.0032],
          [-101.9167, 40.0031],
          [-102.0517, 40.0031],
          [-102.0516, 39.8498],
          [-102.0509, 39.7509],
          [-102.0506, 39.6748],
          [-102.0501, 39.6543],
          [-102.0504, 39.646],
          [-102.0597, 39.6452],
          [-102.0635, 39.6443],
          [-102.0821, 39.6316],
          [-102.0815, 39.5882],
          [-102.1375, 39.5877],
          [-102.1739, 39.6024],
          [-102.2796, 39.612],
          [-102.3439, 39.6071],
          [-102.3482, 39.6872],
          [-102.4058, 39.7015],
          [-102.4628, 39.7012],
          [-102.4633, 39.73],
          [-102.5153, 39.7515],
          [-102.5947, 39.7586],
          [-102.6142, 39.831],
          [-102.6512, 39.8305],
          [-102.6509, 39.7581],
          [-102.6323, 39.723],
          [-102.8001, 39.6881],
          [-102.8009, 39.8014],
          [-102.8389, 39.801],
          [-102.8393, 39.7571],
          [-102.8587, 39.7278],
          [-102.9349, 39.7276],
          [-102.9724, 39.7426],
          [-102.9723, 39.8009],
          [-103.0099, 39.8013],
          [-103.0287, 39.7721],
          [-103.076, 39.7861],
          [-103.1044, 39.7422],
          [-103.1427, 39.7708],
          [-103.2185, 39.7703],
          [-103.219, 39.8142],
          [-103.2931, 39.8128],
          [-103.2923, 39.7109],
          [-103.2735, 39.682],
          [-103.2744, 39.5664],
          [-103.4244, 39.566],
          [-103.4255, 39.6659],
          [-103.5934, 39.6672],
          [-103.598, 39.5661],
          [-103.7071, 39.5663],
          [-103.7065, 39.8575],
          [-103.7475, 39.8685],
          [-103.7945, 39.8519],
          [-103.7979, 39.7982],
          [-103.9246, 39.7977],
          [-103.9239, 39.8263],
          [-103.9808, 39.8264],
          [-103.986, 39.8683],
          [-103.9783, 39.972],
          [-104.0383, 39.9716],
          [-104.0389, 40.0012],
          [-103.9467, 40.0011],
          [-103.9809, 40.0736],
          [-103.9809, 40.1785],
          [-103.9996, 40.1891],
          [-103.9995, 40.2484],
          [-103.9227, 40.3268],
          [-103.848, 40.3198],
          [-103.8759, 40.4352],
          [-103.9562, 40.5035],
          [-103.9509, 40.5239],
          [-104.0324, 40.5243],
          [-104.0318, 40.5535],
          [-104.0697, 40.5679],
          [-104.0669, 40.695],
          [-104.1606, 40.7835],
          [-104.2399, 40.7841],
          [-104.2591, 40.7559],
          [-104.3348, 40.7548],
          [-104.3345, 40.8574],
          [-104.542, 40.8566],
          [-104.6009, 40.8697],
          [-104.6001, 40.9928],
          [-104.6222, 41.0012],
          [-104.0532, 41.0014],
          [-104.0531, 41.105],
          [-103.9885, 41.1053],
          [-103.962, 41.138],
          [-103.9762, 41.1748],
          [-103.9574, 41.2062],
          [-104.0532, 41.1979],
          [-104.0525, 41.5643],
          [-104.653, 41.565],
          [-104.6533, 41.653],
          [-105.2782, 41.6567],
          [-105.3032, 41.6943],
          [-105.376, 41.6733],
          [-105.4215, 41.6246],
          [-105.5219, 41.6671],
          [-105.5059, 41.6997],
          [-105.4446, 41.743],
          [-105.4828, 41.7699],
          [-105.5245, 41.7657],
          [-105.566, 41.7863],
          [-105.5601, 41.8305],
          [-105.5704, 41.9028],
          [-105.5695, 41.9809],
          [-105.4822, 42.058],
          [-105.5131, 42.0885],
          [-105.589, 42.0885],
          [-105.6122, 42.1265],
          [-105.5493, 42.1703],
          [-105.4956, 42.2523],
          [-105.5264, 42.2915],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Omaha Mkt": {
    type: "Feature",
    properties: { code: "NE_OMA", name: "Omaha Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-100.1984, 42.9985],
          [-99.7431, 42.9979],
          [-99.08, 42.9984],
          [-98.4985, 42.9986],
          [-98.4674, 42.9476],
          [-98.2583, 42.8744],
          [-98.1275, 42.8201],
          [-98.035, 42.7642],
          [-97.9501, 42.7696],
          [-97.8886, 42.8173],
          [-97.8753, 42.8587],
          [-97.8453, 42.8677],
          [-97.7745, 42.8498],
          [-97.6865, 42.8424],
          [-97.6038, 42.8583],
          [-97.5619, 42.8476],
          [-97.5048, 42.8585],
          [-97.4522, 42.846],
          [-97.4171, 42.8659],
          [-97.3596, 42.8548],
          [-97.3067, 42.8676],
          [-97.2187, 42.8457],
          [-97.2101, 42.8093],
          [-97.1508, 42.7956],
          [-97.131, 42.7719],
          [-96.9817, 42.7605],
          [-96.9489, 42.7195],
          [-96.9068, 42.7338],
          [-96.8062, 42.7042],
          [-96.8022, 42.6722],
          [-96.728, 42.6669],
          [-96.6891, 42.6441],
          [-96.7113, 42.6174],
          [-96.6857, 42.5779],
          [-96.638, 42.552],
          [-96.626, 42.5136],
          [-96.5316, 42.5152],
          [-96.5013, 42.4827],
          [-96.4094, 42.4876],
          [-96.3807, 42.4464],
          [-96.4118, 42.4109],
          [-96.4178, 42.3514],
          [-96.37, 42.3109],
          [-96.3237, 42.2299],
          [-96.3566, 42.2152],
          [-96.3478, 42.1668],
          [-96.2673, 42.1103],
          [-96.2714, 42.045],
          [-96.2384, 42.0411],
          [-96.1842, 41.9767],
          [-96.1378, 41.946],
          [-95.9202, 41.9793],
          [-95.9004, 42.0008],
          [-95.8471, 41.9938],
          [-95.8373, 42.0379],
          [-95.777, 42.0522],
          [-95.7415, 42.0255],
          [-95.73, 41.9429],
          [-95.6268, 41.9793],
          [-95.6136, 41.9937],
          [-95.5376, 41.9938],
          [-95.4988, 42.0084],
          [-95.4104, 41.9938],
          [-95.4551, 41.952],
          [-95.4213, 41.9356],
          [-95.3629, 41.9358],
          [-95.3633, 41.8782],
          [-95.3056, 41.8641],
          [-95.2865, 41.8197],
          [-95.2726, 41.7033],
          [-95.1514, 41.7133],
          [-95.1509, 41.688],
          [-95.0538, 41.6883],
          [-95.0343, 41.6258],
          [-95.041, 41.5483],
          [-95.0795, 41.5483],
          [-95.0794, 41.476],
          [-95.0986, 41.4319],
          [-95.1466, 41.3895],
          [-95.0986, 41.3461],
          [-95.0413, 41.3314],
          [-95.0411, 41.2954],
          [-94.9656, 41.2736],
          [-94.9664, 41.1728],
          [-94.9848, 41.1728],
          [-94.985, 41.0807],
          [-95.0993, 41.0714],
          [-95.0419, 41.034],
          [-95.0041, 41.0338],
          [-95.0043, 40.9731],
          [-95.08, 40.9153],
          [-95.1182, 40.9154],
          [-95.1188, 40.8428],
          [-94.986, 40.8463],
          [-94.9756, 40.8211],
          [-94.8336, 40.8279],
          [-94.8286, 40.6959],
          [-94.8768, 40.6855],
          [-94.915, 40.6497],
          [-94.9149, 40.5751],
          [-95.2024, 40.5785],
          [-95.2402, 40.5522],
          [-95.3083, 40.5796],
          [-95.6374, 40.5841],
          [-95.6617, 40.5173],
          [-95.6962, 40.4821],
          [-95.6446, 40.4584],
          [-95.6591, 40.4087],
          [-95.6104, 40.314],
          [-95.5533, 40.2912],
          [-95.5563, 40.2708],
          [-95.4697, 40.2279],
          [-95.4763, 40.182],
          [-95.3933, 40.1192],
          [-95.3959, 40.028],
          [-95.3466, 40.0283],
          [-95.3083, 40],
          [-95.9581, 40.0005],
          [-96.3046, 40.0006],
          [-96.8053, 40.0014],
          [-97.0497, 40.0013],
          [-97.6134, 40.0019],
          [-98.274, 40.0025],
          [-98.915, 40.0022],
          [-99.4807, 40.0019],
          [-99.8134, 40.0014],
          [-100.043, 40.0015],
          [-100.043, 40.0643],
          [-100.0421, 40.1102],
          [-100.0424, 40.1761],
          [-100.0046, 40.1759],
          [-100.0058, 40.2925],
          [-100.0814, 40.2951],
          [-100.0759, 40.4237],
          [-100.057, 40.4711],
          [-100.0192, 40.4966],
          [-99.9812, 40.4382],
          [-99.9815, 40.6454],
          [-99.9466, 40.6554],
          [-99.9445, 40.6978],
          [-99.9017, 40.7144],
          [-99.8826, 40.7578],
          [-99.8254, 40.7968],
          [-99.8255, 40.8448],
          [-99.8455, 40.8733],
          [-99.8455, 40.931],
          [-99.8815, 40.9672],
          [-99.9761, 41.0115],
          [-100.0081, 41.0473],
          [-99.9944, 41.0904],
          [-100.007, 41.162],
          [-100.0931, 41.1629],
          [-100.1613, 41.1769],
          [-100.1602, 41.3531],
          [-100.1792, 41.3933],
          [-100.1244, 41.3935],
          [-100.031, 41.3699],
          [-99.9968, 41.4023],
          [-100.0588, 41.4589],
          [-100.0589, 41.4949],
          [-100.1167, 41.5092],
          [-100.1949, 41.5088],
          [-100.2191, 41.5667],
          [-100.2528, 41.5668],
          [-100.2529, 41.6177],
          [-100.322, 41.6271],
          [-100.3262, 41.6518],
          [-100.5027, 41.6562],
          [-100.5149, 41.6944],
          [-100.4908, 41.7394],
          [-100.2655, 41.7398],
          [-100.2678, 42.0443],
          [-100.1961, 42.0576],
          [-100.1515, 42.0268],
          [-100.092, 42.025],
          [-100.0913, 42.0855],
          [-99.6624, 42.086],
          [-99.6638, 42.4346],
          [-99.676, 42.4344],
          [-99.6771, 42.6423],
          [-99.7666, 42.6745],
          [-99.7479, 42.727],
          [-99.8193, 42.7433],
          [-99.8521, 42.7777],
          [-99.8553, 42.8237],
          [-99.8854, 42.8239],
          [-99.898, 42.7655],
          [-99.9689, 42.7725],
          [-100.0078, 42.7886],
          [-100.056, 42.7852],
          [-100.134, 42.8078],
          [-100.1504, 42.8325],
          [-100.1985, 42.8461],
          [-100.1984, 42.9985],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Bristol Mkt": {
    type: "Feature",
    properties: { code: "NH_BRI", name: "Bristol Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-72.9111, 43.1153],
            [-72.8697, 43.0974],
            [-72.872, 43.061],
            [-72.9094, 43.0427],
            [-73.0006, 43.0617],
            [-72.9966, 43.1191],
            [-72.9111, 43.1153],
          ],
        ],
        [
          [
            [-72.5544, 45.0083],
            [-72.5325, 45.0079],
            [-72.481, 45.0089],
            [-72.3486, 45.0056],
            [-72.3299, 45.0048],
            [-72.3292, 45.0047],
            [-72.3101, 45.0038],
            [-71.915, 45.0078],
            [-71.5056, 45.0133],
            [-71.5025, 45.0134],
            [-71.5001, 45.0142],
            [-71.5002, 45.0154],
            [-71.501, 45.016],
            [-71.4999, 45.0263],
            [-71.494, 45.0343],
            [-71.4911, 45.0418],
            [-71.4911, 45.0437],
            [-71.4931, 45.0458],
            [-71.4972, 45.0446],
            [-71.5009, 45.0451],
            [-71.5052, 45.0488],
            [-71.5051, 45.0515],
            [-71.5045, 45.0524],
            [-71.5028, 45.0526],
            [-71.5005, 45.0519],
            [-71.4977, 45.0548],
            [-71.4955, 45.0692],
            [-71.4891, 45.0723],
            [-71.4891, 45.0736],
            [-71.4883, 45.0751],
            [-71.4863, 45.0785],
            [-71.4808, 45.0836],
            [-71.4714, 45.0842],
            [-71.4493, 45.1045],
            [-71.4456, 45.1134],
            [-71.4406, 45.1145],
            [-71.4324, 45.1205],
            [-71.4288, 45.1239],
            [-71.4268, 45.1297],
            [-71.4331, 45.1379],
            [-71.4358, 45.1397],
            [-71.4372, 45.1423],
            [-71.4056, 45.1981],
            [-71.4033, 45.2153],
            [-71.4318, 45.2287],
            [-71.407, 45.2415],
            [-71.3832, 45.2349],
            [-71.3364, 45.2731],
            [-71.2844, 45.3024],
            [-71.228, 45.2497],
            [-71.203, 45.2543],
            [-71.1983, 45.2543],
            [-71.1967, 45.2536],
            [-71.1949, 45.2505],
            [-71.1917, 45.2497],
            [-71.1874, 45.2477],
            [-71.1826, 45.2411],
            [-71.1628, 45.2503],
            [-71.1482, 45.2424],
            [-71.1448, 45.2423],
            [-71.1394, 45.243],
            [-71.134, 45.2442],
            [-71.132, 45.2454],
            [-71.128, 45.2537],
            [-71.1245, 45.2553],
            [-71.1199, 45.2623],
            [-71.1201, 45.2657],
            [-71.1163, 45.2723],
            [-71.1132, 45.2749],
            [-71.1107, 45.2755],
            [-71.1073, 45.2786],
            [-71.1057, 45.2825],
            [-71.1093, 45.2822],
            [-71.1107, 45.2846],
            [-71.0978, 45.3019],
            [-71.0843, 45.3053],
            [-71.0769, 45.2469],
            [-71.059, 45.0049],
            [-71.0375, 44.7556],
            [-71.0103, 44.2848],
            [-71.0445, 44.2837],
            [-71.0425, 44.2378],
            [-71.0977, 44.2353],
            [-71.2524, 44.2442],
            [-71.306, 44.1372],
            [-71.3119, 44.0917],
            [-71.3436, 44.0922],
            [-71.3575, 44.166],
            [-71.3876, 44.1555],
            [-71.3667, 44.069],
            [-71.3828, 44.0223],
            [-71.3507, 43.8696],
            [-71.3546, 43.8149],
            [-71.3299, 43.783],
            [-71.2495, 43.7672],
            [-71.3026, 43.7144],
            [-71.3417, 43.6954],
            [-71.3004, 43.6641],
            [-71.3311, 43.6198],
            [-71.261, 43.5721],
            [-71.3219, 43.5369],
            [-71.317, 43.5094],
            [-71.3888, 43.4866],
            [-71.3907, 43.4422],
            [-71.3497, 43.4142],
            [-71.2813, 43.4182],
            [-71.1601, 43.3559],
            [-71.2472, 43.2742],
            [-71.0267, 43.1381],
            [-71.073, 43.0817],
            [-71.0122, 43.082],
            [-71.0136, 43.0204],
            [-71.0804, 43.0209],
            [-71.088, 42.9593],
            [-71.2237, 42.9652],
            [-71.2061, 42.8508],
            [-71.1665, 42.8081],
            [-71.1861, 42.7907],
            [-71.1818, 42.7376],
            [-71.2455, 42.7426],
            [-71.2942, 42.697],
            [-71.7458, 42.7073],
            [-72.1251, 42.7177],
            [-72.4585, 42.7269],
            [-72.4759, 42.7577],
            [-72.5428, 42.8085],
            [-72.5528, 42.885],
            [-72.5242, 42.9185],
            [-72.5346, 42.9499],
            [-72.4616, 42.9829],
            [-72.4536, 43.0861],
            [-72.4893, 43.1264],
            [-72.563, 43.1282],
            [-72.5961, 43.1039],
            [-72.6404, 43.1061],
            [-72.6825, 43.1325],
            [-72.6786, 43.199],
            [-72.7427, 43.2028],
            [-72.7745, 43.1418],
            [-72.8276, 43.1561],
            [-72.8666, 43.1448],
            [-72.8643, 43.1981],
            [-72.9785, 43.2096],
            [-72.9762, 43.2916],
            [-72.9932, 43.3051],
            [-73.1648, 43.311],
            [-73.2366, 43.2738],
            [-73.2545, 43.3147],
            [-73.2468, 43.5258],
            [-73.3021, 43.6244],
            [-73.3586, 43.6251],
            [-73.3825, 43.5792],
            [-73.4267, 43.583],
            [-73.4279, 43.6344],
            [-73.4021, 43.6931],
            [-73.3506, 43.7719],
            [-73.3928, 43.8222],
            [-73.3742, 43.8742],
            [-73.4086, 43.9329],
            [-73.4074, 44.0203],
            [-73.2628, 44.0343],
            [-73.2509, 44.0804],
            [-73.1933, 44.0705],
            [-73.1799, 44.0461],
            [-73.0666, 44.057],
            [-73.0647, 44.0408],
            [-72.9082, 44.0358],
            [-72.9344, 44.1637],
            [-72.8791, 44.33],
            [-72.906, 44.3699],
            [-72.8259, 44.3595],
            [-72.8391, 44.4034],
            [-72.805, 44.4517],
            [-72.8386, 44.4592],
            [-72.7268, 44.6242],
            [-72.7635, 44.6372],
            [-72.7043, 44.7184],
            [-72.663, 44.7032],
            [-72.5935, 44.7906],
            [-72.5326, 44.8333],
            [-72.5357, 44.9258],
            [-72.5544, 45.0083],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Las Vegas Mkt": {
    type: "Feature",
    properties: { code: "NV_VEG", name: "Las Vegas Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-118.4278, 37.8962],
          [-118.3515, 37.8937],
          [-118.2344, 37.9999],
          [-117.691, 38.4738],
          [-117.7332, 38.5106],
          [-117.6859, 38.5475],
          [-117.628, 38.553],
          [-117.5977, 38.5367],
          [-117.544, 38.558],
          [-117.605, 38.6124],
          [-117.5756, 38.6382],
          [-117.5177, 38.6262],
          [-117.4979, 38.7291],
          [-117.5125, 38.7537],
          [-117.5059, 38.8367],
          [-117.4912, 38.8727],
          [-117.4703, 38.9984],
          [-117.4141, 39.0652],
          [-117.382, 39.1271],
          [-117.381, 39.1564],
          [-117.7755, 39.0934],
          [-117.7931, 39.1176],
          [-117.7894, 39.1696],
          [-117.7227, 39.217],
          [-117.7219, 39.2501],
          [-117.763, 39.3528],
          [-117.7469, 39.3857],
          [-117.6989, 39.385],
          [-117.646, 39.4266],
          [-117.5901, 39.518],
          [-117.5014, 39.5307],
          [-117.4753, 39.6082],
          [-117.4852, 39.6427],
          [-117.4644, 39.6791],
          [-117.4943, 39.7521],
          [-117.4871, 39.8183],
          [-117.3786, 39.8175],
          [-117.3519, 39.7899],
          [-117.3067, 39.7867],
          [-117.2462, 39.8357],
          [-117.2042, 39.8501],
          [-117.1909, 39.8848],
          [-117.1154, 39.921],
          [-116.9565, 40.0646],
          [-116.8997, 40.1427],
          [-116.8878, 40.2591],
          [-116.8574, 40.2569],
          [-116.832, 40.2925],
          [-116.7775, 40.3165],
          [-116.6214, 40.3598],
          [-116.5942, 40.3203],
          [-116.5975, 40.2237],
          [-116.5384, 40.2648],
          [-116.5058, 40.3114],
          [-116.4147, 40.3648],
          [-116.3187, 40.3937],
          [-116.2446, 40.4933],
          [-116.1941, 40.5945],
          [-116.1372, 40.5944],
          [-116.0009, 40.1274],
          [-115.6226, 40.1283],
          [-115.2637, 40.1225],
          [-114.8643, 40.1224],
          [-114.2516, 40.1167],
          [-114.0467, 40.1169],
          [-114.0473, 39.7594],
          [-114.0477, 39.3408],
          [-114.0495, 38.8749],
          [-114.0505, 38.5],
          [-114.0494, 38.2647],
          [-114.0497, 37.8236],
          [-114.053, 37.5928],
          [-114.0506, 37.0004],
          [-114.0506, 36.8168],
          [-113.9958, 36.8271],
          [-113.9831, 36.8439],
          [-113.931, 36.7973],
          [-113.9831, 36.7799],
          [-113.9614, 36.7382],
          [-113.9789, 36.7367],
          [-113.9806, 36.7192],
          [-113.9884, 36.7224],
          [-113.9959, 36.709],
          [-114.029, 36.714],
          [-114.0506, 36.7415],
          [-114.0458, 36.3911],
          [-114.0468, 36.1941],
          [-114.068, 36.1807],
          [-114.0999, 36.1217],
          [-114.1231, 36.1116],
          [-114.1145, 36.0952],
          [-114.1482, 36.028],
          [-114.1768, 36.0277],
          [-114.2137, 36.0156],
          [-114.2527, 36.0202],
          [-114.2802, 36.0464],
          [-114.3156, 36.0595],
          [-114.3084, 36.0824],
          [-114.3721, 36.1431],
          [-114.4055, 36.1474],
          [-114.4466, 36.126],
          [-114.4636, 36.1397],
          [-114.5022, 36.1288],
          [-114.5117, 36.151],
          [-114.572, 36.1516],
          [-114.6167, 36.1301],
          [-114.6317, 36.1423],
          [-114.6665, 36.1173],
          [-114.7362, 36.1044],
          [-114.7556, 36.0872],
          [-114.7363, 36.0585],
          [-114.7406, 36.041],
          [-114.7297, 36.0282],
          [-114.7428, 36.01],
          [-114.7438, 35.9851],
          [-114.7299, 35.9622],
          [-114.7312, 35.9439],
          [-114.7075, 35.9281],
          [-114.7085, 35.9123],
          [-114.6774, 35.8747],
          [-114.6978, 35.8548],
          [-114.6957, 35.8306],
          [-114.7121, 35.8062],
          [-114.6989, 35.7902],
          [-114.6957, 35.756],
          [-114.7054, 35.7083],
          [-114.6806, 35.6855],
          [-114.6894, 35.6514],
          [-114.6534, 35.6108],
          [-114.6662, 35.5776],
          [-114.6569, 35.5344],
          [-114.6772, 35.5135],
          [-114.6776, 35.4897],
          [-114.6621, 35.4442],
          [-114.6271, 35.4095],
          [-114.6043, 35.3536],
          [-114.58, 35.2096],
          [-114.5692, 35.1835],
          [-114.5727, 35.1387],
          [-114.5785, 35.1287],
          [-114.5991, 35.121],
          [-114.6299, 35.1183],
          [-114.6468, 35.1019],
          [-114.6131, 35.0831],
          [-114.6029, 35.0686],
          [-114.6319, 35.0338],
          [-114.633, 35.0021],
          [-114.9422, 35.25],
          [-115.2713, 35.5127],
          [-115.7579, 35.8948],
          [-116.0972, 36.1583],
          [-116.3759, 36.3726],
          [-116.928, 36.7925],
          [-117.2864, 37.0601],
          [-117.5001, 37.2204],
          [-117.7124, 37.3749],
          [-117.8759, 37.4973],
          [-118.4278, 37.8962],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Reno Mkt": {
    type: "Feature",
    properties: { code: "NV_REN", name: "Reno Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-120.1462, 38.7834],
            [-120.1154, 38.7667],
            [-120.1382, 38.7652],
            [-120.1475, 38.7728],
            [-120.1462, 38.7834],
          ],
        ],
        [
          [
            [-121.0706, 40.2045],
            [-121.0821, 40.2596],
            [-121.1173, 40.2769],
            [-121.1603, 40.2723],
            [-121.1758, 40.3905],
            [-121.1415, 40.3848],
            [-121.0808, 40.4086],
            [-121.0808, 40.4467],
            [-121.3278, 40.4454],
            [-121.323, 40.4976],
            [-121.3211, 40.7705],
            [-121.2344, 40.71],
            [-121.1667, 40.726],
            [-121.0964, 40.7055],
            [-121.0569, 40.7482],
            [-120.9803, 40.7621],
            [-120.9455, 40.8054],
            [-120.8418, 40.8066],
            [-120.841, 40.8625],
            [-120.8098, 40.8879],
            [-120.8096, 40.9381],
            [-120.7756, 40.9666],
            [-120.7937, 40.9957],
            [-120.7739, 41.0428],
            [-120.7241, 41.0585],
            [-120.7214, 41.0909],
            [-120.7537, 41.1198],
            [-120.7945, 41.2519],
            [-120.8199, 41.2867],
            [-120.7856, 41.3727],
            [-120.7894, 41.4139],
            [-120.746, 41.4138],
            [-120.7556, 41.4845],
            [-120.7139, 41.5751],
            [-120.7534, 41.6368],
            [-120.7895, 41.6531],
            [-120.7843, 41.6929],
            [-120.7514, 41.715],
            [-120.7631, 41.8197],
            [-120.7928, 41.7886],
            [-120.8584, 41.845],
            [-120.9297, 41.8343],
            [-120.9624, 41.8765],
            [-121.0163, 41.9097],
            [-121.0912, 41.9186],
            [-121.071, 41.8777],
            [-121.1112, 41.8698],
            [-121.1098, 41.8259],
            [-121.0598, 41.792],
            [-121.027, 41.7293],
            [-121.0484, 41.705],
            [-121.0431, 41.6664],
            [-121.1055, 41.6487],
            [-121.1057, 41.5752],
            [-121.1599, 41.5147],
            [-121.214, 41.5088],
            [-121.2511, 41.4781],
            [-121.2464, 41.4476],
            [-121.2999, 41.4311],
            [-121.3833, 41.4469],
            [-121.4125, 41.4655],
            [-121.4487, 41.438],
            [-121.4491, 41.6907],
            [-121.6121, 41.6916],
            [-121.6691, 41.7122],
            [-121.6645, 41.8143],
            [-121.709, 41.8303],
            [-121.6845, 41.8916],
            [-121.6831, 42.0005],
            [-121.0352, 41.9933],
            [-120.488, 41.9937],
            [-120.5137, 41.9106],
            [-120.4772, 41.8496],
            [-120.4668, 41.7886],
            [-120.419, 41.7894],
            [-120.3755, 41.8179],
            [-120.2552, 41.813],
            [-120.2136, 41.8309],
            [-120.2303, 41.911],
            [-120.1835, 41.8935],
            [-120.1905, 41.9727],
            [-120.1591, 41.9947],
            [-119.8761, 41.9972],
            [-119.2083, 41.9932],
            [-118.6964, 41.9918],
            [-118.1972, 41.997],
            [-117.626, 41.9981],
            [-117.0554, 41.9999],
            [-116.3685, 41.9963],
            [-115.8702, 41.9968],
            [-115.3968, 41.9963],
            [-115.0318, 41.996],
            [-114.8311, 42.0022],
            [-114.5983, 41.9945],
            [-114.0417, 41.9937],
            [-114.0396, 41.8848],
            [-114.0402, 41.4917],
            [-114.0414, 41.22],
            [-114.0432, 40.7717],
            [-114.0462, 40.3983],
            [-114.0467, 40.1169],
            [-114.2516, 40.1167],
            [-114.8643, 40.1224],
            [-115.2637, 40.1225],
            [-115.6226, 40.1283],
            [-116.0009, 40.1274],
            [-116.1372, 40.5944],
            [-116.1941, 40.5945],
            [-116.2446, 40.4933],
            [-116.3187, 40.3937],
            [-116.4147, 40.3648],
            [-116.5058, 40.3114],
            [-116.5384, 40.2648],
            [-116.5975, 40.2237],
            [-116.5942, 40.3203],
            [-116.6214, 40.3598],
            [-116.7775, 40.3165],
            [-116.832, 40.2925],
            [-116.8574, 40.2569],
            [-116.8878, 40.2591],
            [-116.8997, 40.1427],
            [-116.9565, 40.0646],
            [-117.1154, 39.921],
            [-117.1909, 39.8848],
            [-117.2042, 39.8501],
            [-117.2462, 39.8357],
            [-117.3067, 39.7867],
            [-117.3519, 39.7899],
            [-117.3786, 39.8175],
            [-117.4871, 39.8183],
            [-117.4943, 39.7521],
            [-117.4644, 39.6791],
            [-117.4852, 39.6427],
            [-117.4753, 39.6082],
            [-117.5014, 39.5307],
            [-117.5901, 39.518],
            [-117.646, 39.4266],
            [-117.6989, 39.385],
            [-117.7469, 39.3857],
            [-117.763, 39.3528],
            [-117.7219, 39.2501],
            [-117.7227, 39.217],
            [-117.7894, 39.1696],
            [-117.7931, 39.1176],
            [-117.7755, 39.0934],
            [-117.381, 39.1564],
            [-117.382, 39.1271],
            [-117.4141, 39.0652],
            [-117.4703, 38.9984],
            [-117.4912, 38.8727],
            [-117.5059, 38.8367],
            [-117.5125, 38.7537],
            [-117.4979, 38.7291],
            [-117.5177, 38.6262],
            [-117.5756, 38.6382],
            [-117.605, 38.6124],
            [-117.544, 38.558],
            [-117.5977, 38.5367],
            [-117.628, 38.553],
            [-117.6859, 38.5475],
            [-117.7332, 38.5106],
            [-117.691, 38.4738],
            [-118.2344, 37.9999],
            [-118.3515, 37.8937],
            [-118.4278, 37.8962],
            [-118.9497, 38.2689],
            [-119.3299, 38.5359],
            [-119.3502, 38.5082],
            [-119.3489, 38.4778],
            [-119.3575, 38.4675],
            [-119.3514, 38.4519],
            [-119.3641, 38.4403],
            [-119.3708, 38.4309],
            [-119.3685, 38.414],
            [-119.4195, 38.4061],
            [-119.4265, 38.4272],
            [-119.4437, 38.4435],
            [-119.4404, 38.4503],
            [-119.4594, 38.4545],
            [-119.4466, 38.4195],
            [-119.4548, 38.3894],
            [-119.4433, 38.36],
            [-119.4522, 38.3484],
            [-119.4785, 38.3656],
            [-119.5036, 38.3536],
            [-119.5196, 38.3729],
            [-119.5533, 38.3495],
            [-119.5474, 38.3416],
            [-119.5612, 38.3234],
            [-119.5877, 38.3078],
            [-119.6072, 38.3136],
            [-119.6205, 38.3276],
            [-119.6372, 38.3279],
            [-119.6392, 38.3269],
            [-119.7, 38.3652],
            [-119.6936, 38.3789],
            [-119.7053, 38.416],
            [-119.7502, 38.4173],
            [-119.8017, 38.4014],
            [-119.8147, 38.3875],
            [-119.8847, 38.3562],
            [-120.0536, 38.4556],
            [-120.0726, 38.4471],
            [-120.0724, 38.5741],
            [-120.0403, 38.5627],
            [-119.9906, 38.5833],
            [-119.9755, 38.6016],
            [-119.9452, 38.6117],
            [-119.9318, 38.607],
            [-119.9359, 38.6186],
            [-119.9353, 38.6197],
            [-119.9327, 38.6211],
            [-119.9254, 38.608],
            [-119.9331, 38.6253],
            [-119.943, 38.6348],
            [-119.9551, 38.6409],
            [-119.9679, 38.6629],
            [-119.9593, 38.6908],
            [-119.9663, 38.6991],
            [-119.9813, 38.691],
            [-120.0332, 38.697],
            [-120.0371, 38.6836],
            [-120.0619, 38.7009],
            [-120.0513, 38.7037],
            [-120.0825, 38.7006],
            [-120.0776, 38.709],
            [-120.0983, 38.709],
            [-120.1225, 38.6954],
            [-120.1169, 38.6792],
            [-120.2012, 38.6948],
            [-120.2145, 38.6622],
            [-120.2153, 38.6734],
            [-120.2296, 38.6814],
            [-120.22, 38.7032],
            [-120.2321, 38.7042],
            [-120.2358, 38.7158],
            [-120.246, 38.7129],
            [-120.2449, 38.7294],
            [-120.2219, 38.7111],
            [-120.162, 38.7225],
            [-120.1468, 38.7336],
            [-120.1467, 38.7054],
            [-120.1168, 38.7239],
            [-120.078, 38.7185],
            [-120.0479, 38.726],
            [-120.0479, 38.7194],
            [-120.0327, 38.7298],
            [-120.0506, 38.7406],
            [-120.0504, 38.7531],
            [-120.0672, 38.7681],
            [-120.1027, 38.7878],
            [-120.1112, 38.7995],
            [-120.1035, 38.8083],
            [-120.112, 38.8027],
            [-120.1228, 38.8057],
            [-120.1204, 38.8115],
            [-120.1368, 38.8083],
            [-120.1577, 38.7869],
            [-120.1949, 38.7907],
            [-120.2005, 38.8032],
            [-120.1873, 38.821],
            [-120.2344, 38.8018],
            [-120.2409, 38.8106],
            [-120.2619, 38.8153],
            [-120.2731, 38.8107],
            [-120.2984, 38.8249],
            [-120.3204, 38.8201],
            [-120.3087, 38.8298],
            [-120.2858, 38.829],
            [-120.3065, 38.8405],
            [-120.3003, 38.8462],
            [-120.3123, 38.8643],
            [-120.3341, 38.8745],
            [-120.322, 38.877],
            [-120.3463, 38.8819],
            [-120.3193, 38.8989],
            [-120.3098, 38.9223],
            [-120.2887, 38.9383],
            [-120.3103, 38.9374],
            [-120.3068, 38.9501],
            [-120.3336, 38.9578],
            [-120.3871, 38.9443],
            [-120.4008, 38.9524],
            [-120.3994, 38.9544],
            [-120.4483, 38.9542],
            [-120.4696, 38.9697],
            [-120.5051, 38.935],
            [-120.5448, 38.9271],
            [-120.5637, 38.9137],
            [-120.5793, 38.9169],
            [-120.5944, 38.9361],
            [-120.6059, 38.9346],
            [-120.6241, 38.9447],
            [-120.6128, 38.9536],
            [-120.5682, 38.9603],
            [-120.5475, 38.9468],
            [-120.5225, 38.957],
            [-120.5413, 38.9663],
            [-120.5411, 38.9805],
            [-120.5998, 38.9805],
            [-120.5685, 38.9901],
            [-120.557, 38.9995],
            [-120.5612, 39.0119],
            [-120.5288, 39.0284],
            [-120.5137, 39.0515],
            [-120.5172, 39.0647],
            [-120.5268, 39.0606],
            [-120.5259, 39.0654],
            [-120.4995, 39.1077],
            [-120.4376, 39.1413],
            [-120.4163, 39.1915],
            [-120.394, 39.2154],
            [-120.352, 39.2247],
            [-120.3637, 39.2417],
            [-120.3557, 39.2486],
            [-120.3719, 39.2543],
            [-120.3639, 39.2725],
            [-120.3713, 39.2685],
            [-120.3805, 39.277],
            [-120.379, 39.3164],
            [-120.4945, 39.316],
            [-120.4907, 39.3281],
            [-120.4729, 39.3364],
            [-120.4841, 39.3366],
            [-120.4563, 39.3606],
            [-120.4558, 39.3942],
            [-120.4704, 39.3949],
            [-120.4556, 39.4073],
            [-120.455, 39.4461],
            [-120.5054, 39.4461],
            [-120.5098, 39.4548],
            [-120.5343, 39.4642],
            [-120.5342, 39.4837],
            [-120.5491, 39.4945],
            [-120.5566, 39.5136],
            [-120.5879, 39.5234],
            [-120.5853, 39.5386],
            [-120.5892, 39.5458],
            [-120.5864, 39.5385],
            [-120.5874, 39.5376],
            [-120.5894, 39.537],
            [-120.599, 39.5361],
            [-120.6028, 39.5277],
            [-120.6191, 39.5278],
            [-120.6252, 39.5372],
            [-120.6787, 39.5372],
            [-120.6897, 39.5254],
            [-120.716, 39.5258],
            [-120.7089, 39.5483],
            [-120.7223, 39.5688],
            [-120.6781, 39.5857],
            [-120.6695, 39.6138],
            [-120.6974, 39.5966],
            [-120.7358, 39.593],
            [-120.7528, 39.6009],
            [-120.7581, 39.5921],
            [-120.7902, 39.595],
            [-120.8109, 39.5848],
            [-120.7736, 39.6135],
            [-120.7747, 39.6738],
            [-120.7868, 39.6912],
            [-120.8256, 39.7056],
            [-120.8156, 39.747],
            [-120.8263, 39.742],
            [-120.8394, 39.7463],
            [-120.8639, 39.7716],
            [-120.8655, 39.7783],
            [-120.8337, 39.7807],
            [-120.8335, 39.7926],
            [-120.8791, 39.7964],
            [-120.8881, 39.8055],
            [-120.8873, 39.8166],
            [-120.8767, 39.8135],
            [-120.8664, 39.8286],
            [-120.8482, 39.8358],
            [-120.8581, 39.8561],
            [-120.8269, 39.8783],
            [-120.78, 39.8769],
            [-120.7708, 39.8657],
            [-120.7362, 39.8667],
            [-120.7278, 39.881],
            [-120.7324, 39.8968],
            [-120.7112, 39.8995],
            [-120.6905, 39.9245],
            [-120.6454, 39.9107],
            [-120.6496, 39.9281],
            [-120.5987, 39.9334],
            [-120.5949, 39.9618],
            [-120.5747, 39.9559],
            [-120.5941, 39.9655],
            [-120.5903, 39.9809],
            [-120.5768, 39.9811],
            [-120.5833, 39.9707],
            [-120.5688, 39.9583],
            [-120.543, 39.9575],
            [-120.5332, 39.9681],
            [-120.507, 39.9785],
            [-120.4957, 39.9756],
            [-120.4708, 39.9893],
            [-120.4948, 40.035],
            [-120.5516, 40.0441],
            [-120.5458, 40.0492],
            [-120.5063, 40.048],
            [-120.5147, 40.0635],
            [-120.5529, 40.0722],
            [-120.5399, 40.0866],
            [-120.5548, 40.0864],
            [-120.5557, 40.1069],
            [-120.5634, 40.1104],
            [-120.5495, 40.1506],
            [-120.5286, 40.1673],
            [-120.5355, 40.1939],
            [-120.5145, 40.2059],
            [-120.5495, 40.2365],
            [-120.5523, 40.2556],
            [-120.5767, 40.2638],
            [-120.5768, 40.2854],
            [-120.6228, 40.2857],
            [-120.6227, 40.293],
            [-120.6425, 40.2932],
            [-120.6522, 40.3077],
            [-120.7264, 40.3084],
            [-120.7644, 40.316],
            [-120.7738, 40.3014],
            [-120.7968, 40.3009],
            [-120.796, 40.2777],
            [-120.8309, 40.2707],
            [-120.8341, 40.2562],
            [-120.8532, 40.2488],
            [-120.8718, 40.1994],
            [-120.9286, 40.1919],
            [-120.9281, 40.2065],
            [-120.9432, 40.2065],
            [-120.9458, 40.1973],
            [-120.9587, 40.1934],
            [-120.9651, 40.2004],
            [-120.9867, 40.1925],
            [-121.0421, 40.2093],
            [-121.0706, 40.2045],
          ],
          [
            [-120.0848, 38.8142],
            [-120.0554, 38.8242],
            [-120.031, 38.8143],
            [-120.0276, 38.8454],
            [-120.0425, 38.8408],
            [-120.0416, 38.8794],
            [-120.0641, 38.8675],
            [-120.0533, 38.8842],
            [-120.1113, 38.8676],
            [-120.1173, 38.8573],
            [-120.1078, 38.8394],
            [-120.0848, 38.8142],
          ],
          [
            [-120.2803, 39.4088],
            [-120.2701, 39.4161],
            [-120.2589, 39.4061],
            [-120.2589, 39.4119],
            [-120.246, 39.4071],
            [-120.1863, 39.4455],
            [-120.2356, 39.4457],
            [-120.2653, 39.4342],
            [-120.2794, 39.437],
            [-120.2807, 39.4171],
            [-120.2803, 39.4088],
          ],
        ],
        [
          [
            [-121.3632, 40.2681],
            [-121.337, 40.2771],
            [-121.2852, 40.255],
            [-121.2894, 40.2266],
            [-121.3632, 40.2681],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Albany Mkt": {
    type: "Feature",
    properties: { code: "NY_ALB", name: "Albany Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-74.5716, 44.9989],
          [-74.4575, 44.997],
          [-74.4242, 44.9956],
          [-74.3352, 44.9919],
          [-74.2341, 44.9921],
          [-74.2071, 44.9919],
          [-74.1468, 44.9915],
          [-73.8746, 45.0012],
          [-73.6397, 45.0035],
          [-73.086, 45.0155],
          [-72.8456, 45.0167],
          [-72.8254, 45.0164],
          [-72.8099, 45.0162],
          [-72.7773, 45.0159],
          [-72.6748, 45.0155],
          [-72.5899, 45.0132],
          [-72.5824, 45.0115],
          [-72.5559, 45.0083],
          [-72.5544, 45.0083],
          [-72.5357, 44.9258],
          [-72.5326, 44.8333],
          [-72.5935, 44.7906],
          [-72.663, 44.7032],
          [-72.7043, 44.7184],
          [-72.7635, 44.6372],
          [-72.7268, 44.6242],
          [-72.8386, 44.4592],
          [-72.805, 44.4517],
          [-72.8391, 44.4034],
          [-72.8259, 44.3595],
          [-72.906, 44.3699],
          [-72.8791, 44.33],
          [-72.9344, 44.1637],
          [-72.9082, 44.0358],
          [-73.0647, 44.0408],
          [-73.0666, 44.057],
          [-73.1799, 44.0461],
          [-73.1933, 44.0705],
          [-73.2509, 44.0804],
          [-73.2628, 44.0343],
          [-73.4074, 44.0203],
          [-73.4086, 43.9329],
          [-73.3742, 43.8742],
          [-73.3928, 43.8222],
          [-73.3506, 43.7719],
          [-73.4021, 43.6931],
          [-73.4279, 43.6344],
          [-73.4267, 43.583],
          [-73.3825, 43.5792],
          [-73.3586, 43.6251],
          [-73.3021, 43.6244],
          [-73.2468, 43.5258],
          [-73.2545, 43.3147],
          [-73.2366, 43.2738],
          [-73.1648, 43.311],
          [-72.9932, 43.3051],
          [-72.9762, 43.2916],
          [-72.9785, 43.2096],
          [-72.8643, 43.1981],
          [-72.8666, 43.1448],
          [-72.8276, 43.1561],
          [-72.7745, 43.1418],
          [-72.7427, 43.2028],
          [-72.6786, 43.199],
          [-72.6825, 43.1325],
          [-72.6404, 43.1061],
          [-72.5961, 43.1039],
          [-72.563, 43.1282],
          [-72.4893, 43.1264],
          [-72.4536, 43.0861],
          [-72.4616, 42.9829],
          [-72.5346, 42.9499],
          [-72.5242, 42.9185],
          [-72.5528, 42.885],
          [-72.5428, 42.8085],
          [-72.4759, 42.7577],
          [-72.4585, 42.7269],
          [-72.8091, 42.7366],
          [-73.265, 42.7459],
          [-73.3821, 42.4295],
          [-73.5081, 42.0863],
          [-73.4873, 42.0496],
          [-73.5341, 41.4789],
          [-73.6548, 41.4839],
          [-73.6837, 41.5133],
          [-73.9338, 41.4883],
          [-73.9815, 41.4389],
          [-73.9652, 41.4139],
          [-74.0442, 41.3566],
          [-74.1198, 41.4018],
          [-74.1419, 41.4511],
          [-74.1888, 41.4427],
          [-74.2023, 41.4809],
          [-74.2765, 41.4866],
          [-74.2871, 41.5231],
          [-74.4028, 41.55],
          [-74.4688, 41.5138],
          [-74.5556, 41.5013],
          [-74.5932, 41.4313],
          [-74.5634, 41.4192],
          [-74.5612, 41.3631],
          [-74.6073, 41.3178],
          [-74.6949, 41.3574],
          [-74.7361, 41.3984],
          [-74.7409, 41.4312],
          [-74.7904, 41.4217],
          [-74.8891, 41.4512],
          [-74.9844, 41.5066],
          [-75.0746, 41.6057],
          [-75.0442, 41.618],
          [-75.0534, 41.7525],
          [-75.1046, 41.7742],
          [-75.0858, 41.8116],
          [-75.1144, 41.8436],
          [-75.1693, 41.8704],
          [-75.1824, 41.8855],
          [-75.1357, 41.9745],
          [-75.056, 41.9834],
          [-75.0209, 42.0444],
          [-74.9288, 42.0299],
          [-74.9296, 42.0852],
          [-74.8215, 42.068],
          [-74.7882, 42.0129],
          [-74.6895, 42.0587],
          [-74.7433, 42.1181],
          [-74.6856, 42.1844],
          [-74.7263, 42.2231],
          [-74.6843, 42.2598],
          [-74.6418, 42.3196],
          [-74.6338, 42.3696],
          [-74.6667, 42.4188],
          [-74.657, 42.4629],
          [-74.6847, 42.4758],
          [-74.7505, 42.4515],
          [-74.794, 42.4963],
          [-74.8441, 42.5126],
          [-74.9365, 42.4713],
          [-74.9875, 42.5121],
          [-74.9326, 42.5908],
          [-74.8926, 42.6128],
          [-74.8542, 42.6774],
          [-74.6491, 42.6943],
          [-74.6603, 42.7303],
          [-74.5488, 42.7319],
          [-74.5079, 42.7614],
          [-74.4947, 42.8357],
          [-74.5353, 42.86],
          [-74.5205, 42.8951],
          [-74.4736, 42.8989],
          [-74.4969, 43.0369],
          [-74.5653, 43.048],
          [-74.5477, 43.1012],
          [-74.5555, 43.1729],
          [-74.5755, 43.2036],
          [-74.5347, 43.2281],
          [-74.6636, 43.2722],
          [-74.6903, 43.3096],
          [-74.6296, 43.4082],
          [-74.6529, 43.6155],
          [-74.6891, 43.6115],
          [-74.6923, 43.661],
          [-74.5965, 43.7013],
          [-74.5307, 43.7065],
          [-74.5301, 43.761],
          [-74.569, 43.7555],
          [-74.6546, 43.8093],
          [-74.6853, 43.8457],
          [-74.816, 43.7876],
          [-74.8542, 44.0701],
          [-74.8893, 44.0668],
          [-74.8972, 44.1494],
          [-74.8664, 44.2174],
          [-74.9169, 44.2739],
          [-74.9428, 44.4701],
          [-74.8475, 44.4777],
          [-74.8005, 44.4514],
          [-74.73, 44.3653],
          [-74.685, 44.3427],
          [-74.5535, 44.3519],
          [-74.5712, 44.4702],
          [-74.5991, 44.4406],
          [-74.6998, 44.4395],
          [-74.7161, 44.5697],
          [-74.6857, 44.6719],
          [-74.7459, 44.6897],
          [-74.7073, 44.731],
          [-74.731, 44.8069],
          [-74.6722, 44.8117],
          [-74.6703, 44.8541],
          [-74.6287, 44.857],
          [-74.6392, 44.9334],
          [-74.6851, 44.9593],
          [-74.5719, 44.9675],
          [-74.5716, 44.9989],
        ],
        [
          [-72.9111, 43.1153],
          [-72.9966, 43.1191],
          [-73.0006, 43.0617],
          [-72.9094, 43.0427],
          [-72.872, 43.061],
          [-72.8697, 43.0974],
          [-72.9111, 43.1153],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Brooklyn Mkt": {
    type: "Feature",
    properties: { code: "NY_BRN", name: "Brooklyn Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-73.9179, 40.9176],
            [-73.7836, 40.881],
            [-73.8156, 40.8351],
            [-73.7814, 40.7949],
            [-73.7137, 40.8701],
            [-73.6756, 40.857],
            [-73.6338, 40.8982],
            [-73.5662, 40.9158],
            [-73.4999, 40.9182],
            [-73.4918, 40.9421],
            [-73.4367, 40.9349],
            [-73.4009, 40.954],
            [-73.3528, 40.9267],
            [-73.2951, 40.9245],
            [-73.2293, 40.9051],
            [-73.149, 40.9289],
            [-73.1408, 40.9662],
            [-73.0816, 40.9731],
            [-73.0437, 40.9622],
            [-72.7741, 40.9653],
            [-72.6893, 40.9898],
            [-72.5852, 40.9976],
            [-72.5215, 41.0377],
            [-72.4773, 41.0522],
            [-72.4455, 41.0858],
            [-72.397, 41.0963],
            [-72.3565, 41.1333],
            [-72.2605, 41.0421],
            [-72.1906, 41.0326],
            [-72.1373, 41.0397],
            [-72.1164, 40.9998],
            [-72.0495, 41.0097],
            [-71.967, 41.0478],
            [-71.9194, 41.0805],
            [-71.8739, 41.0523],
            [-72.1144, 40.9721],
            [-72.3958, 40.8667],
            [-72.5734, 40.8133],
            [-72.7747, 40.7596],
            [-72.9232, 40.7133],
            [-73.055, 40.6664],
            [-73.2084, 40.6309],
            [-73.3064, 40.6208],
            [-73.3193, 40.6358],
            [-73.4504, 40.6035],
            [-73.5624, 40.5837],
            [-73.7548, 40.5844],
            [-73.7749, 40.5908],
            [-73.9345, 40.5452],
            [-73.9501, 40.5734],
            [-74.0016, 40.5907],
            [-74.0424, 40.6248],
            [-74.0183, 40.659],
            [-74.0245, 40.7094],
            [-74.0138, 40.7566],
            [-73.929, 40.8896],
            [-73.9179, 40.9176],
          ],
        ],
        [
          [
            [-74.2427, 40.5465],
            [-74.2109, 40.5609],
            [-74.2006, 40.6314],
            [-74.0865, 40.6486],
            [-74.0531, 40.6037],
            [-74.1115, 40.5469],
            [-74.2467, 40.4961],
            [-74.2427, 40.5465],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Buffalo Mkt": {
    type: "Feature",
    properties: { code: "NY_BUF", name: "Buffalo Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-78.2813, 43.3718],
          [-78.3101, 43.3233],
          [-78.3108, 43.2517],
          [-78.327, 43.2399],
          [-78.3103, 43.1704],
          [-78.284, 43.1311],
          [-78.1933, 43.1318],
          [-78.1157, 43.1705],
          [-78.1207, 43.0165],
          [-78.0352, 42.9919],
          [-78.0402, 42.9443],
          [-78.1035, 42.9053],
          [-78.1039, 42.8701],
          [-78.148, 42.8618],
          [-78.1484, 42.8157],
          [-78.1953, 42.8266],
          [-78.2347, 42.8156],
          [-78.2487, 42.738],
          [-78.2796, 42.7053],
          [-78.2503, 42.674],
          [-78.1914, 42.6524],
          [-78.1383, 42.6524],
          [-78.1037, 42.6301],
          [-78.1337, 42.5717],
          [-78.1281, 42.5214],
          [-78.0701, 42.4974],
          [-78.0387, 42.5185],
          [-78.0147, 42.4522],
          [-77.9682, 42.4522],
          [-77.9558, 42.3934],
          [-77.9176, 42.3576],
          [-77.9527, 42.3184],
          [-77.9565, 42.2867],
          [-78.0287, 42.2969],
          [-78.0891, 42.2881],
          [-78.0741, 42.2511],
          [-78.0742, 42.1403],
          [-78.0905, 42.1051],
          [-78.0622, 42.0864],
          [-78.0578, 42.0128],
          [-78.0296, 41.9994],
          [-78.5966, 41.9999],
          [-78.8748, 41.9976],
          [-79.449, 41.9983],
          [-79.7614, 41.9991],
          [-79.762, 42.2699],
          [-79.6456, 42.3155],
          [-79.5463, 42.3634],
          [-79.4291, 42.4284],
          [-79.352, 42.4889],
          [-79.2429, 42.5318],
          [-79.149, 42.5536],
          [-79.1114, 42.6134],
          [-79.0638, 42.6448],
          [-79.0489, 42.6892],
          [-78.9182, 42.7373],
          [-78.8535, 42.784],
          [-78.8595, 42.8414],
          [-78.9058, 42.9],
          [-78.9057, 42.9234],
          [-78.9324, 42.9559],
          [-79.0116, 42.9853],
          [-79.0105, 43.0644],
          [-79.0745, 43.0779],
          [-79.0424, 43.1437],
          [-79.0559, 43.2386],
          [-79.0614, 43.2513],
          [-79.0705, 43.2625],
          [-78.9719, 43.2813],
          [-78.8341, 43.3176],
          [-78.6343, 43.3576],
          [-78.4889, 43.3748],
          [-78.2813, 43.3718],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Elmira Mkt": {
    type: "Feature",
    properties: { code: "NY_ELM", name: "Elmira Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-78.0387, 42.5185],
          [-78.0487, 42.5789],
          [-78.0287, 42.6042],
          [-77.9799, 42.6099],
          [-77.927, 42.5596],
          [-77.8409, 42.5656],
          [-77.8406, 42.5032],
          [-77.7428, 42.4615],
          [-77.6935, 42.4702],
          [-77.5748, 42.4508],
          [-77.5722, 42.4941],
          [-77.5421, 42.5057],
          [-77.5407, 42.5453],
          [-77.4432, 42.5768],
          [-77.4177, 42.5528],
          [-77.3566, 42.5763],
          [-77.231, 42.5764],
          [-77.1762, 42.5622],
          [-77.1487, 42.5195],
          [-77.1049, 42.5413],
          [-77.0087, 42.5599],
          [-76.9988, 42.623],
          [-76.7854, 42.6293],
          [-76.7866, 42.6502],
          [-76.7303, 42.6685],
          [-76.6717, 42.6357],
          [-76.6665, 42.6235],
          [-76.5219, 42.6254],
          [-76.5166, 42.563],
          [-76.4715, 42.5391],
          [-76.4513, 42.5009],
          [-76.3932, 42.4953],
          [-76.351, 42.4144],
          [-76.254, 42.4174],
          [-76.2148, 42.4452],
          [-76.2063, 42.4798],
          [-76.0803, 42.4837],
          [-76.0941, 42.5467],
          [-76.0211, 42.5395],
          [-75.9331, 42.4539],
          [-75.8926, 42.4893],
          [-75.7966, 42.5123],
          [-75.8177, 42.5453],
          [-75.746, 42.6203],
          [-75.7436, 42.7024],
          [-75.6382, 42.6731],
          [-75.5621, 42.6194],
          [-75.511, 42.6169],
          [-75.3949, 42.6344],
          [-75.3696, 42.5704],
          [-75.2012, 42.5776],
          [-75.1921, 42.6085],
          [-75.0796, 42.6748],
          [-75.0738, 42.6385],
          [-74.9898, 42.6672],
          [-74.9504, 42.635],
          [-74.9326, 42.5908],
          [-74.9875, 42.5121],
          [-74.9365, 42.4713],
          [-74.8441, 42.5126],
          [-74.794, 42.4963],
          [-74.7505, 42.4515],
          [-74.6847, 42.4758],
          [-74.657, 42.4629],
          [-74.6667, 42.4188],
          [-74.6338, 42.3696],
          [-74.6418, 42.3196],
          [-74.6843, 42.2598],
          [-74.7263, 42.2231],
          [-74.6856, 42.1844],
          [-74.7433, 42.1181],
          [-74.6895, 42.0587],
          [-74.7882, 42.0129],
          [-74.8215, 42.068],
          [-74.9296, 42.0852],
          [-74.9288, 42.0299],
          [-75.0209, 42.0444],
          [-75.056, 41.9834],
          [-75.1357, 41.9745],
          [-75.1824, 41.8855],
          [-75.1693, 41.8704],
          [-75.2237, 41.8575],
          [-75.2638, 41.8708],
          [-75.2791, 41.9389],
          [-75.3482, 41.9611],
          [-75.4082, 41.9594],
          [-75.479, 41.9249],
          [-75.4795, 41.9003],
          [-75.5632, 41.8895],
          [-75.589, 41.8359],
          [-75.5854, 41.7784],
          [-75.7164, 41.6644],
          [-75.7778, 41.6679],
          [-75.9315, 41.6392],
          [-75.9535, 41.6488],
          [-75.9743, 41.7432],
          [-76.045, 41.7468],
          [-76.1247, 41.7695],
          [-76.1734, 41.7582],
          [-76.1701, 41.6965],
          [-76.1917, 41.665],
          [-76.22, 41.5421],
          [-76.4791, 41.5625],
          [-76.5545, 41.5481],
          [-76.6043, 41.5721],
          [-76.6793, 41.5779],
          [-76.6947, 41.5791],
          [-76.6373, 41.7001],
          [-76.7626, 41.6875],
          [-76.7868, 41.713],
          [-76.8467, 41.7125],
          [-76.9286, 41.6937],
          [-76.9571, 41.6645],
          [-77.0168, 41.6618],
          [-77.0517, 41.6261],
          [-77.0402, 41.5949],
          [-77.093, 41.5472],
          [-77.0968, 41.5222],
          [-77.1572, 41.5221],
          [-77.1914, 41.49],
          [-77.316, 41.4611],
          [-77.4538, 41.5077],
          [-77.4942, 41.5418],
          [-77.5996, 41.5423],
          [-77.6394, 41.5707],
          [-77.8275, 41.5654],
          [-77.8402, 41.6243],
          [-77.8956, 41.6238],
          [-77.9044, 41.6592],
          [-77.9686, 41.6492],
          [-78.1198, 41.6506],
          [-78.0882, 41.7004],
          [-78.0925, 41.7365],
          [-78.0899, 41.9131],
          [-78.0348, 41.9127],
          [-77.9629, 41.978],
          [-78.0296, 41.9994],
          [-78.0578, 42.0128],
          [-78.0622, 42.0864],
          [-78.0905, 42.1051],
          [-78.0742, 42.1403],
          [-78.0741, 42.2511],
          [-78.0891, 42.2881],
          [-78.0287, 42.2969],
          [-77.9565, 42.2867],
          [-77.9527, 42.3184],
          [-77.9176, 42.3576],
          [-77.9558, 42.3934],
          [-77.9682, 42.4522],
          [-78.0147, 42.4522],
          [-78.0387, 42.5185],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Rochester Mkt": {
    type: "Feature",
    properties: { code: "NY_ROC", name: "Rochester Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-78.2813, 43.3718],
          [-78.1045, 43.3756],
          [-77.9764, 43.3692],
          [-77.8753, 43.3497],
          [-77.7569, 43.3374],
          [-77.7141, 43.3236],
          [-77.6538, 43.2795],
          [-77.5772, 43.2433],
          [-77.5009, 43.2504],
          [-77.391, 43.2764],
          [-77.2642, 43.2774],
          [-77.1119, 43.2879],
          [-77.0339, 43.2712],
          [-76.9522, 43.2707],
          [-76.9043, 43.2918],
          [-76.7947, 43.3096],
          [-76.7593, 43.3242],
          [-76.7514, 43.2791],
          [-76.7721, 43.2635],
          [-76.7615, 43.1814],
          [-76.8278, 43.1622],
          [-76.8096, 43.1258],
          [-76.8082, 43.0578],
          [-76.7835, 43.0217],
          [-76.9049, 43.0163],
          [-76.9111, 42.9587],
          [-76.9637, 42.9557],
          [-76.937, 42.8692],
          [-76.8973, 42.8719],
          [-76.8935, 42.822],
          [-76.7401, 42.7894],
          [-76.7335, 42.7279],
          [-76.6853, 42.6703],
          [-76.6717, 42.6357],
          [-76.7303, 42.6685],
          [-76.7866, 42.6502],
          [-76.7854, 42.6293],
          [-76.9988, 42.623],
          [-77.0087, 42.5599],
          [-77.1049, 42.5413],
          [-77.1487, 42.5195],
          [-77.1762, 42.5622],
          [-77.231, 42.5764],
          [-77.3566, 42.5763],
          [-77.4177, 42.5528],
          [-77.4432, 42.5768],
          [-77.5407, 42.5453],
          [-77.5421, 42.5057],
          [-77.5722, 42.4941],
          [-77.5748, 42.4508],
          [-77.6935, 42.4702],
          [-77.7428, 42.4615],
          [-77.8406, 42.5032],
          [-77.8409, 42.5656],
          [-77.927, 42.5596],
          [-77.9799, 42.6099],
          [-78.0287, 42.6042],
          [-78.0487, 42.5789],
          [-78.0387, 42.5185],
          [-78.0701, 42.4974],
          [-78.1281, 42.5214],
          [-78.1337, 42.5717],
          [-78.1037, 42.6301],
          [-78.1383, 42.6524],
          [-78.1914, 42.6524],
          [-78.2503, 42.674],
          [-78.2796, 42.7053],
          [-78.2487, 42.738],
          [-78.2347, 42.8156],
          [-78.1953, 42.8266],
          [-78.1484, 42.8157],
          [-78.148, 42.8618],
          [-78.1039, 42.8701],
          [-78.1035, 42.9053],
          [-78.0402, 42.9443],
          [-78.0352, 42.9919],
          [-78.1207, 43.0165],
          [-78.1157, 43.1705],
          [-78.1933, 43.1318],
          [-78.284, 43.1311],
          [-78.3103, 43.1704],
          [-78.327, 43.2399],
          [-78.3108, 43.2517],
          [-78.3101, 43.3233],
          [-78.2813, 43.3718],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Syracuse Mkt": {
    type: "Feature",
    properties: { code: "NY_SYR", name: "Syracuse Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-76.7593, 43.3242],
          [-76.6984, 43.3444],
          [-76.6308, 43.4134],
          [-76.4106, 43.5232],
          [-76.2971, 43.5129],
          [-76.2358, 43.5293],
          [-76.2035, 43.575],
          [-76.1966, 43.6498],
          [-76.2054, 43.7188],
          [-76.2291, 43.8038],
          [-76.2833, 43.8439],
          [-76.2434, 43.878],
          [-76.2023, 43.8649],
          [-76.1273, 43.8979],
          [-76.1343, 43.9547],
          [-76.2667, 43.9956],
          [-76.2968, 44.0133],
          [-76.3042, 44.0594],
          [-76.3603, 44.0709],
          [-76.367, 44.1004],
          [-76.3126, 44.199],
          [-76.2455, 44.2037],
          [-76.1643, 44.2396],
          [-76.1618, 44.2808],
          [-76.0974, 44.2995],
          [-76.001, 44.3475],
          [-75.9702, 44.3428],
          [-75.913, 44.3681],
          [-75.8208, 44.4322],
          [-75.8078, 44.4716],
          [-75.7662, 44.5159],
          [-75.6184, 44.6196],
          [-75.5059, 44.7051],
          [-75.3723, 44.7831],
          [-75.3076, 44.8368],
          [-75.2413, 44.867],
          [-75.0662, 44.9302],
          [-74.9725, 44.9834],
          [-74.908, 44.9834],
          [-74.8266, 45.0159],
          [-74.7313, 44.9904],
          [-74.684, 44.9997],
          [-74.6673, 45.0016],
          [-74.6615, 44.9996],
          [-74.6379, 44.9994],
          [-74.5777, 44.9989],
          [-74.5716, 44.9989],
          [-74.5719, 44.9675],
          [-74.6851, 44.9593],
          [-74.6392, 44.9334],
          [-74.6287, 44.857],
          [-74.6703, 44.8541],
          [-74.6722, 44.8117],
          [-74.731, 44.8069],
          [-74.7073, 44.731],
          [-74.7459, 44.6897],
          [-74.6857, 44.6719],
          [-74.7161, 44.5697],
          [-74.6998, 44.4395],
          [-74.5991, 44.4406],
          [-74.5712, 44.4702],
          [-74.5535, 44.3519],
          [-74.685, 44.3427],
          [-74.73, 44.3653],
          [-74.8005, 44.4514],
          [-74.8475, 44.4777],
          [-74.9428, 44.4701],
          [-74.9169, 44.2739],
          [-74.8664, 44.2174],
          [-74.8972, 44.1494],
          [-74.8893, 44.0668],
          [-74.8542, 44.0701],
          [-74.816, 43.7876],
          [-74.6853, 43.8457],
          [-74.6546, 43.8093],
          [-74.569, 43.7555],
          [-74.5301, 43.761],
          [-74.5307, 43.7065],
          [-74.5965, 43.7013],
          [-74.6923, 43.661],
          [-74.6891, 43.6115],
          [-74.6529, 43.6155],
          [-74.6296, 43.4082],
          [-74.6903, 43.3096],
          [-74.6636, 43.2722],
          [-74.5347, 43.2281],
          [-74.5755, 43.2036],
          [-74.5555, 43.1729],
          [-74.5477, 43.1012],
          [-74.5653, 43.048],
          [-74.4969, 43.0369],
          [-74.4736, 42.8989],
          [-74.5205, 42.8951],
          [-74.5353, 42.86],
          [-74.4947, 42.8357],
          [-74.5079, 42.7614],
          [-74.5488, 42.7319],
          [-74.6603, 42.7303],
          [-74.6491, 42.6943],
          [-74.8542, 42.6774],
          [-74.8926, 42.6128],
          [-74.9326, 42.5908],
          [-74.9504, 42.635],
          [-74.9898, 42.6672],
          [-75.0738, 42.6385],
          [-75.0796, 42.6748],
          [-75.1921, 42.6085],
          [-75.2012, 42.5776],
          [-75.3696, 42.5704],
          [-75.3949, 42.6344],
          [-75.511, 42.6169],
          [-75.5621, 42.6194],
          [-75.6382, 42.6731],
          [-75.7436, 42.7024],
          [-75.746, 42.6203],
          [-75.8177, 42.5453],
          [-75.7966, 42.5123],
          [-75.8926, 42.4893],
          [-75.9331, 42.4539],
          [-76.0211, 42.5395],
          [-76.0941, 42.5467],
          [-76.0803, 42.4837],
          [-76.2063, 42.4798],
          [-76.2148, 42.4452],
          [-76.254, 42.4174],
          [-76.351, 42.4144],
          [-76.3932, 42.4953],
          [-76.4513, 42.5009],
          [-76.4715, 42.5391],
          [-76.5166, 42.563],
          [-76.5219, 42.6254],
          [-76.6665, 42.6235],
          [-76.6717, 42.6357],
          [-76.6853, 42.6703],
          [-76.7335, 42.7279],
          [-76.7401, 42.7894],
          [-76.8935, 42.822],
          [-76.8973, 42.8719],
          [-76.937, 42.8692],
          [-76.9637, 42.9557],
          [-76.9111, 42.9587],
          [-76.9049, 43.0163],
          [-76.7835, 43.0217],
          [-76.8082, 43.0578],
          [-76.8096, 43.1258],
          [-76.8278, 43.1622],
          [-76.7615, 43.1814],
          [-76.7721, 43.2635],
          [-76.7514, 43.2791],
          [-76.7593, 43.3242],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Cincinnati Mkt": {
    type: "Feature",
    properties: { code: "OH_CIN", name: "Cincinnati Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-84.8148, 39.61],
          [-84.7761, 39.6118],
          [-84.7397, 39.5673],
          [-84.6412, 39.5676],
          [-84.5926, 39.604],
          [-84.5322, 39.5895],
          [-84.274, 39.5886],
          [-84.2475, 39.5938],
          [-84.1308, 39.568],
          [-84.0325, 39.5729],
          [-83.7167, 39.5531],
          [-83.6675, 39.5779],
          [-83.6503, 39.6193],
          [-83.5763, 39.5445],
          [-83.5849, 39.4498],
          [-83.5428, 39.4502],
          [-83.4947, 39.4238],
          [-83.4937, 39.3779],
          [-83.4251, 39.3777],
          [-83.443, 39.424],
          [-83.4253, 39.4423],
          [-83.3531, 39.4027],
          [-83.3867, 39.3227],
          [-83.3806, 39.2444],
          [-83.4212, 39.2189],
          [-83.3561, 39.1863],
          [-83.3622, 39.1186],
          [-83.3823, 39.0692],
          [-83.4259, 39.073],
          [-83.4434, 39.0465],
          [-83.5697, 39.0264],
          [-83.623, 39.0311],
          [-83.6618, 39.0589],
          [-83.7035, 39.0624],
          [-83.7022, 39.0213],
          [-83.7282, 38.9657],
          [-83.7496, 38.954],
          [-83.7767, 38.8839],
          [-83.7437, 38.8525],
          [-83.69, 38.8279],
          [-83.6966, 38.7573],
          [-83.6422, 38.7437],
          [-83.5601, 38.7432],
          [-83.5242, 38.7566],
          [-83.455, 38.7249],
          [-83.4681, 38.6755],
          [-83.521, 38.703],
          [-83.615, 38.6843],
          [-83.6466, 38.6362],
          [-83.6681, 38.6281],
          [-83.7722, 38.6581],
          [-83.7836, 38.6956],
          [-83.8406, 38.7219],
          [-83.859, 38.7568],
          [-83.9788, 38.7871],
          [-84.0715, 38.7705],
          [-84.2129, 38.8057],
          [-84.2302, 38.8265],
          [-84.2345, 38.8932],
          [-84.2882, 38.9558],
          [-84.3047, 39.0065],
          [-84.3604, 39.0414],
          [-84.4298, 39.0583],
          [-84.4328, 39.0943],
          [-84.4705, 39.1215],
          [-84.5097, 39.0937],
          [-84.6324, 39.0768],
          [-84.6897, 39.1042],
          [-84.7185, 39.1371],
          [-84.7544, 39.1467],
          [-84.784, 39.1181],
          [-84.8312, 39.1019],
          [-84.8972, 39.0524],
          [-84.8371, 38.9881],
          [-84.8352, 38.958],
          [-84.8778, 38.9204],
          [-84.8608, 38.8977],
          [-84.7881, 38.8837],
          [-84.8234, 38.8392],
          [-84.8116, 38.7928],
          [-84.8879, 38.7947],
          [-84.933, 38.7775],
          [-84.9787, 38.7793],
          [-85.0719, 38.7416],
          [-85.133, 38.7024],
          [-85.2015, 38.6909],
          [-85.2026, 38.74],
          [-85.1607, 38.7897],
          [-85.1866, 38.8341],
          [-85.1844, 38.8854],
          [-85.1403, 38.9032],
          [-85.1358, 38.9296],
          [-85.203, 38.9285],
          [-85.2499, 38.956],
          [-85.4448, 38.9565],
          [-85.4401, 39.1956],
          [-85.2976, 39.2677],
          [-85.2937, 39.3314],
          [-85.2733, 39.362],
          [-85.2965, 39.3921],
          [-85.2976, 39.4531],
          [-85.2793, 39.4606],
          [-85.2894, 39.5254],
          [-85.1284, 39.5267],
          [-84.8535, 39.5232],
          [-84.8528, 39.5667],
          [-84.8148, 39.61],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Cleveland Mkt": {
    type: "Feature",
    properties: { code: "OH_CLE", name: "Cleveland Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-82.6947, 41.4939],
          [-82.617, 41.4284],
          [-82.5144, 41.3844],
          [-82.4812, 41.3813],
          [-82.3618, 41.4266],
          [-82.2547, 41.4344],
          [-82.1934, 41.4645],
          [-82.012, 41.5156],
          [-81.9379, 41.4914],
          [-81.8603, 41.4838],
          [-81.8108, 41.4956],
          [-81.7448, 41.4871],
          [-81.5997, 41.5606],
          [-81.5297, 41.6145],
          [-81.5004, 41.6234],
          [-81.4426, 41.6733],
          [-81.2869, 41.7602],
          [-81.2487, 41.7613],
          [-81.0105, 41.854],
          [-80.9003, 41.8689],
          [-80.5194, 41.9775],
          [-80.5193, 41.5785],
          [-80.5187, 41.2489],
          [-80.519, 40.7258],
          [-80.6361, 40.6989],
          [-80.6935, 40.6627],
          [-80.8768, 40.6921],
          [-80.9562, 40.6398],
          [-80.8907, 40.5952],
          [-80.8863, 40.5558],
          [-80.926, 40.5561],
          [-80.9351, 40.5253],
          [-80.9966, 40.5172],
          [-81.11, 40.4649],
          [-81.0993, 40.4459],
          [-81.1494, 40.4073],
          [-81.1989, 40.336],
          [-81.2138, 40.2529],
          [-81.3008, 40.2457],
          [-81.3224, 40.2171],
          [-81.3899, 40.2155],
          [-81.3632, 40.252],
          [-81.4019, 40.2802],
          [-81.4057, 40.3079],
          [-81.4828, 40.3218],
          [-81.4952, 40.3733],
          [-81.5251, 40.3865],
          [-81.5425, 40.4258],
          [-81.6399, 40.439],
          [-81.7151, 40.4919],
          [-81.827, 40.4773],
          [-81.8367, 40.4401],
          [-81.8681, 40.4112],
          [-81.8948, 40.4489],
          [-81.9604, 40.4505],
          [-82.0039, 40.428],
          [-82.0873, 40.4244],
          [-82.1304, 40.4542],
          [-82.1282, 40.495],
          [-82.174, 40.5123],
          [-82.2263, 40.5095],
          [-82.2586, 40.5333],
          [-82.2611, 40.5634],
          [-82.3465, 40.5539],
          [-82.3611, 40.5278],
          [-82.4137, 40.5127],
          [-82.4714, 40.5411],
          [-82.6047, 40.5573],
          [-82.6622, 40.5734],
          [-82.6633, 40.6232],
          [-82.709, 40.6383],
          [-82.8485, 40.6398],
          [-82.868, 40.6814],
          [-82.9193, 40.7046],
          [-82.9579, 40.7397],
          [-83.0004, 40.718],
          [-83.1502, 40.6915],
          [-83.1498, 40.7894],
          [-83.1698, 40.8261],
          [-83.17, 40.9119],
          [-83.237, 40.9204],
          [-83.3049, 40.9635],
          [-83.3093, 41.0288],
          [-83.3869, 41.0286],
          [-83.4539, 40.9917],
          [-83.4486, 41.0209],
          [-83.5146, 41.0654],
          [-83.5164, 41.0938],
          [-83.5927, 41.0801],
          [-83.5637, 41.2515],
          [-83.4999, 41.2574],
          [-83.4866, 41.2389],
          [-83.3943, 41.2398],
          [-83.3397, 41.2913],
          [-83.2827, 41.2826],
          [-83.2837, 41.2535],
          [-83.1168, 41.2565],
          [-83.0523, 41.2884],
          [-83.0147, 41.2536],
          [-82.927, 41.2559],
          [-82.9027, 41.2904],
          [-82.8922, 41.3468],
          [-82.8442, 41.3578],
          [-82.8478, 41.4375],
          [-82.8194, 41.4851],
          [-82.7499, 41.4773],
          [-82.6947, 41.4939],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Columbus Mkt": {
    type: "Feature",
    properties: { code: "OH_COL", name: "Columbus Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-83.4539, 40.9917],
          [-83.3869, 41.0286],
          [-83.3093, 41.0288],
          [-83.3049, 40.9635],
          [-83.237, 40.9204],
          [-83.17, 40.9119],
          [-83.1698, 40.8261],
          [-83.1498, 40.7894],
          [-83.1502, 40.6915],
          [-83.0004, 40.718],
          [-82.9579, 40.7397],
          [-82.9193, 40.7046],
          [-82.868, 40.6814],
          [-82.8485, 40.6398],
          [-82.709, 40.6383],
          [-82.6633, 40.6232],
          [-82.6622, 40.5734],
          [-82.6047, 40.5573],
          [-82.4714, 40.5411],
          [-82.4137, 40.5127],
          [-82.3611, 40.5278],
          [-82.3465, 40.5539],
          [-82.2611, 40.5634],
          [-82.2586, 40.5333],
          [-82.2263, 40.5095],
          [-82.174, 40.5123],
          [-82.1282, 40.495],
          [-82.1304, 40.4542],
          [-82.0873, 40.4244],
          [-82.0039, 40.428],
          [-81.9604, 40.4505],
          [-81.8948, 40.4489],
          [-81.8681, 40.4112],
          [-81.8367, 40.4401],
          [-81.827, 40.4773],
          [-81.7151, 40.4919],
          [-81.6399, 40.439],
          [-81.5425, 40.4258],
          [-81.5251, 40.3865],
          [-81.4952, 40.3733],
          [-81.4828, 40.3218],
          [-81.4057, 40.3079],
          [-81.4019, 40.2802],
          [-81.3632, 40.252],
          [-81.3899, 40.2155],
          [-81.3929, 40.1776],
          [-81.3587, 40.1208],
          [-81.2825, 40.1251],
          [-81.2449, 40.0959],
          [-81.1758, 40.1044],
          [-81.1172, 40.0831],
          [-81.0506, 40.0977],
          [-80.985, 40.0878],
          [-80.9841, 40.046],
          [-80.9378, 40.0224],
          [-80.9051, 40.0171],
          [-80.8959, 39.9767],
          [-80.9681, 39.9799],
          [-80.9901, 39.9083],
          [-80.9991, 39.8734],
          [-80.9543, 39.8555],
          [-80.9192, 39.8715],
          [-80.8896, 39.8462],
          [-80.9477, 39.7919],
          [-80.9845, 39.6973],
          [-81.0137, 39.6692],
          [-81.0545, 39.6844],
          [-81.0649, 39.6251],
          [-81.1349, 39.6675],
          [-81.1757, 39.6743],
          [-81.1971, 39.7044],
          [-81.2408, 39.7147],
          [-81.2307, 39.6608],
          [-81.2584, 39.641],
          [-81.2809, 39.7071],
          [-81.3357, 39.7127],
          [-81.3756, 39.6618],
          [-81.4658, 39.6889],
          [-81.4967, 39.6575],
          [-81.5508, 39.6453],
          [-81.6612, 39.6414],
          [-81.6785, 39.6691],
          [-81.7209, 39.652],
          [-81.6973, 39.5866],
          [-81.7235, 39.5476],
          [-81.7051, 39.531],
          [-81.7159, 39.4607],
          [-81.8017, 39.4483],
          [-81.9212, 39.4483],
          [-81.9592, 39.484],
          [-81.9897, 39.5645],
          [-82.0701, 39.5984],
          [-82.1592, 39.5563],
          [-82.2401, 39.5608],
          [-82.2755, 39.5404],
          [-82.2802, 39.4726],
          [-82.336, 39.4719],
          [-82.3473, 39.4461],
          [-82.4022, 39.4433],
          [-82.4461, 39.3924],
          [-82.5508, 39.3953],
          [-82.5531, 39.3651],
          [-82.6196, 39.3588],
          [-82.6416, 39.331],
          [-82.691, 39.3308],
          [-82.7249, 39.3123],
          [-82.7517, 39.3337],
          [-82.7472, 39.3856],
          [-82.8216, 39.5131],
          [-82.8725, 39.5146],
          [-82.9165, 39.4766],
          [-83.0371, 39.5078],
          [-83.0769, 39.4551],
          [-83.1104, 39.4702],
          [-83.234, 39.4491],
          [-83.2857, 39.4579],
          [-83.3258, 39.4391],
          [-83.3531, 39.4027],
          [-83.4253, 39.4423],
          [-83.443, 39.424],
          [-83.4251, 39.3777],
          [-83.4937, 39.3779],
          [-83.4947, 39.4238],
          [-83.5428, 39.4502],
          [-83.5849, 39.4498],
          [-83.5763, 39.5445],
          [-83.6503, 39.6193],
          [-83.6675, 39.5779],
          [-83.7167, 39.5531],
          [-84.0325, 39.5729],
          [-84.1308, 39.568],
          [-84.2475, 39.5938],
          [-84.274, 39.5886],
          [-84.5322, 39.5895],
          [-84.5926, 39.604],
          [-84.6412, 39.5676],
          [-84.7397, 39.5673],
          [-84.7761, 39.6118],
          [-84.8148, 39.61],
          [-84.8142, 39.8142],
          [-84.804, 40.331],
          [-84.7379, 40.3089],
          [-84.7277, 40.3522],
          [-84.4347, 40.3543],
          [-84.4342, 40.2994],
          [-84.3365, 40.2915],
          [-84.323, 40.3557],
          [-84.2783, 40.3717],
          [-84.3169, 40.4087],
          [-84.2982, 40.4668],
          [-84.2594, 40.4819],
          [-83.9937, 40.484],
          [-83.944, 40.5713],
          [-83.8675, 40.5576],
          [-83.861, 40.622],
          [-83.8152, 40.6159],
          [-83.7838, 40.6577],
          [-83.7238, 40.6435],
          [-83.7295, 40.7325],
          [-83.6716, 40.7468],
          [-83.6063, 40.7451],
          [-83.5741, 40.7024],
          [-83.4402, 40.7023],
          [-83.4118, 40.7164],
          [-83.4194, 40.7885],
          [-83.4478, 40.8324],
          [-83.5154, 40.8545],
          [-83.4864, 40.8904],
          [-83.4861, 40.9535],
          [-83.4539, 40.9917],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Toledo Mkt": {
    type: "Feature",
    properties: { code: "OH_TOL", name: "Toledo Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-83.4538, 41.7326],
          [-83.446, 41.7068],
          [-83.3571, 41.6878],
          [-83.3268, 41.7016],
          [-83.2317, 41.6442],
          [-83.0666, 41.5953],
          [-82.9999, 41.5385],
          [-82.9344, 41.5144],
          [-82.8568, 41.5483],
          [-82.8341, 41.5876],
          [-82.7855, 41.5407],
          [-82.719, 41.5421],
          [-82.6947, 41.4939],
          [-82.7499, 41.4773],
          [-82.8194, 41.4851],
          [-82.8478, 41.4375],
          [-82.8442, 41.3578],
          [-82.8922, 41.3468],
          [-82.9027, 41.2904],
          [-82.927, 41.2559],
          [-83.0147, 41.2536],
          [-83.0523, 41.2884],
          [-83.1168, 41.2565],
          [-83.2837, 41.2535],
          [-83.2827, 41.2826],
          [-83.3397, 41.2913],
          [-83.3943, 41.2398],
          [-83.4866, 41.2389],
          [-83.4999, 41.2574],
          [-83.5637, 41.2515],
          [-83.5927, 41.0801],
          [-83.5164, 41.0938],
          [-83.5146, 41.0654],
          [-83.4486, 41.0209],
          [-83.4539, 40.9917],
          [-83.4861, 40.9535],
          [-83.4864, 40.8904],
          [-83.5154, 40.8545],
          [-83.4478, 40.8324],
          [-83.4194, 40.7885],
          [-83.4118, 40.7164],
          [-83.4402, 40.7023],
          [-83.5741, 40.7024],
          [-83.6063, 40.7451],
          [-83.6716, 40.7468],
          [-83.7295, 40.7325],
          [-83.7238, 40.6435],
          [-83.7838, 40.6577],
          [-83.8152, 40.6159],
          [-83.861, 40.622],
          [-83.8675, 40.5576],
          [-83.944, 40.5713],
          [-83.9937, 40.484],
          [-84.2594, 40.4819],
          [-84.2982, 40.4668],
          [-84.3169, 40.4087],
          [-84.2783, 40.3717],
          [-84.323, 40.3557],
          [-84.3365, 40.2915],
          [-84.4342, 40.2994],
          [-84.4347, 40.3543],
          [-84.7277, 40.3522],
          [-84.7379, 40.3089],
          [-84.804, 40.331],
          [-84.8022, 40.5866],
          [-84.8033, 40.9892],
          [-84.8039, 41.4355],
          [-84.8061, 41.6961],
          [-84.2108, 41.7108],
          [-83.4538, 41.7326],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Medford Mkt": {
    type: "Feature",
    properties: { code: "OR_MED", name: "Medford Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-124.092, 44.371],
          [-124.0253, 44.3328],
          [-124.0106, 44.3654],
          [-123.9421, 44.3449],
          [-123.9165, 44.2903],
          [-123.8865, 44.2828],
          [-123.9007, 44.2389],
          [-123.8122, 44.2181],
          [-123.7825, 44.1994],
          [-123.7149, 44.2803],
          [-123.5935, 44.2766],
          [-123.5218, 44.2871],
          [-123.4845, 44.3185],
          [-123.485, 44.405],
          [-123.4385, 44.3948],
          [-123.3139, 44.4102],
          [-123.2875, 44.3822],
          [-123.1589, 44.3455],
          [-123.0149, 44.314],
          [-122.9847, 44.3635],
          [-122.944, 44.3594],
          [-122.9078, 44.285],
          [-122.7245, 44.2882],
          [-122.647, 44.2666],
          [-122.5771, 44.2281],
          [-122.4055, 44.2206],
          [-122.3675, 44.2091],
          [-122.3266, 44.2533],
          [-122.0286, 44.2542],
          [-121.8186, 44.262],
          [-121.8409, 44.2858],
          [-121.8458, 44.4914],
          [-121.7958, 44.5898],
          [-121.8, 44.7028],
          [-121.7907, 44.7467],
          [-121.8188, 44.7893],
          [-121.7421, 44.8453],
          [-121.7347, 44.8882],
          [-121.7598, 44.9395],
          [-121.7932, 44.9348],
          [-121.8046, 45.0124],
          [-121.7198, 45.0376],
          [-121.6981, 45.0596],
          [-121.3685, 45.0601],
          [-121.3475, 45.035],
          [-121.3044, 45.0462],
          [-121.2833, 45.0158],
          [-121.218, 44.9933],
          [-121.1803, 44.9624],
          [-121.1129, 44.9875],
          [-121.0769, 44.9513],
          [-121.0838, 44.913],
          [-121.0486, 44.8784],
          [-121.0897, 44.8234],
          [-120.7468, 44.8227],
          [-120.6777, 44.8232],
          [-120.5481, 44.8223],
          [-120.3717, 44.8217],
          [-120.3861, 44.8112],
          [-120.379, 44.806],
          [-120.3852, 44.7965],
          [-120.3953, 44.8042],
          [-120.4047, 44.7948],
          [-120.3879, 44.7624],
          [-120.3767, 44.7612],
          [-120.3824, 44.7528],
          [-120.3584, 44.7408],
          [-120.2972, 44.7355],
          [-120.2796, 44.7432],
          [-120.2658, 44.7379],
          [-120.2446, 44.7509],
          [-120.205, 44.7528],
          [-120.2048, 44.7761],
          [-120.2035, 44.7794],
          [-120.1993, 44.7807],
          [-120.191, 44.7873],
          [-120.1856, 44.7957],
          [-120.1849, 44.8001],
          [-120.1505, 44.8266],
          [-120.1471, 44.8668],
          [-120.1237, 44.8892],
          [-120.1122, 44.8892],
          [-120.108, 44.8812],
          [-120.1223, 44.8603],
          [-120.1053, 44.846],
          [-120.0923, 44.8579],
          [-120.059, 44.864],
          [-120.0486, 44.8775],
          [-120.043, 44.844],
          [-120.0196, 44.8046],
          [-120.0005, 44.7956],
          [-120.0055, 44.7893],
          [-119.9955, 44.7829],
          [-119.9902, 44.7594],
          [-120.002, 44.7391],
          [-120.0381, 44.7218],
          [-120.0647, 44.6832],
          [-120.1221, 44.6692],
          [-120.0969, 44.6387],
          [-120.0753, 44.639],
          [-120.0508, 44.6229],
          [-120.0185, 44.6253],
          [-119.9614, 44.6166],
          [-119.9365, 44.5971],
          [-119.915, 44.5955],
          [-119.8703, 44.6368],
          [-119.8275, 44.7395],
          [-119.7956, 44.7266],
          [-119.7889, 44.7128],
          [-119.7905, 44.6866],
          [-119.8084, 44.6649],
          [-119.7809, 44.6614],
          [-119.7724, 44.6434],
          [-119.7498, 44.6375],
          [-119.7528, 44.6198],
          [-119.8048, 44.6032],
          [-119.7212, 44.5855],
          [-119.7167, 44.5949],
          [-119.6923, 44.6052],
          [-119.6544, 44.5919],
          [-119.6539, 44.3984],
          [-119.6576, 43.9591],
          [-119.4978, 43.9574],
          [-119.3761, 43.9586],
          [-119.3463, 43.9596],
          [-119.291, 43.9603],
          [-118.8291, 43.96],
          [-118.8278, 43.9573],
          [-118.8276, 43.9553],
          [-118.8304, 43.9513],
          [-118.8329, 43.9489],
          [-118.826, 43.942],
          [-118.8494, 43.922],
          [-118.8242, 43.8844],
          [-118.7929, 43.8916],
          [-118.7883, 43.8802],
          [-118.7592, 43.8674],
          [-118.7644, 43.8553],
          [-118.755, 43.8373],
          [-118.7296, 43.8208],
          [-118.7405, 43.7855],
          [-118.7175, 43.7636],
          [-118.7122, 43.74],
          [-118.67, 43.7415],
          [-118.6619, 43.7079],
          [-118.653, 43.7141],
          [-118.624, 43.7072],
          [-118.6201, 43.6868],
          [-118.6019, 43.6866],
          [-118.5935, 43.6666],
          [-118.5359, 43.6936],
          [-118.5345, 43.6924],
          [-118.5339, 43.6912],
          [-118.5339, 43.6893],
          [-118.5363, 43.6815],
          [-118.5369, 43.6769],
          [-118.5365, 43.6647],
          [-118.5329, 43.6534],
          [-118.5333, 43.6472],
          [-118.5286, 43.6225],
          [-118.529, 43.5911],
          [-118.5196, 43.5897],
          [-118.516, 43.5644],
          [-118.4893, 43.5286],
          [-118.4615, 43.5224],
          [-118.414, 43.5577],
          [-118.3969, 43.5615],
          [-118.3701, 43.5482],
          [-118.3352, 43.502],
          [-118.3141, 43.503],
          [-118.3025, 43.4924],
          [-118.3035, 43.4802],
          [-118.2913, 43.4696],
          [-118.3097, 43.4593],
          [-118.2709, 43.4434],
          [-118.2584, 43.4428],
          [-118.2518, 43.4447],
          [-118.2379, 43.4471],
          [-118.232, 43.4488],
          [-118.228, 43.3491],
          [-118.229, 42.9146],
          [-118.2167, 42.9145],
          [-118.2147, 42.276],
          [-118.1954, 42.2759],
          [-118.1971, 42.1249],
          [-118.1972, 41.997],
          [-118.6964, 41.9918],
          [-119.2083, 41.9932],
          [-119.8761, 41.9972],
          [-120.1591, 41.9947],
          [-120.1905, 41.9727],
          [-120.1835, 41.8935],
          [-120.2303, 41.911],
          [-120.2136, 41.8309],
          [-120.2552, 41.813],
          [-120.3755, 41.8179],
          [-120.419, 41.7894],
          [-120.4668, 41.7886],
          [-120.4772, 41.8496],
          [-120.5137, 41.9106],
          [-120.488, 41.9937],
          [-121.0352, 41.9933],
          [-121.6831, 42.0005],
          [-121.6845, 41.8916],
          [-121.709, 41.8303],
          [-121.6645, 41.8143],
          [-121.6691, 41.7122],
          [-121.6121, 41.6916],
          [-121.4491, 41.6907],
          [-121.4487, 41.438],
          [-121.4125, 41.4655],
          [-121.3833, 41.4469],
          [-121.2999, 41.4311],
          [-121.2464, 41.4476],
          [-121.2511, 41.4781],
          [-121.214, 41.5088],
          [-121.1599, 41.5147],
          [-121.1057, 41.5752],
          [-121.1055, 41.6487],
          [-121.0431, 41.6664],
          [-121.0484, 41.705],
          [-121.027, 41.7293],
          [-121.0598, 41.792],
          [-121.1098, 41.8259],
          [-121.1112, 41.8698],
          [-121.071, 41.8777],
          [-121.0912, 41.9186],
          [-121.0163, 41.9097],
          [-120.9624, 41.8765],
          [-120.9297, 41.8343],
          [-120.8584, 41.845],
          [-120.7928, 41.7886],
          [-120.7631, 41.8197],
          [-120.7514, 41.715],
          [-120.7843, 41.6929],
          [-120.7895, 41.6531],
          [-120.7534, 41.6368],
          [-120.7139, 41.5751],
          [-120.7556, 41.4845],
          [-120.746, 41.4138],
          [-120.7894, 41.4139],
          [-120.7856, 41.3727],
          [-120.8199, 41.2867],
          [-120.7945, 41.2519],
          [-120.7537, 41.1198],
          [-120.7214, 41.0909],
          [-120.7241, 41.0585],
          [-120.7739, 41.0428],
          [-120.7937, 40.9957],
          [-120.7756, 40.9666],
          [-120.8096, 40.9381],
          [-120.8098, 40.8879],
          [-120.841, 40.8625],
          [-120.8418, 40.8066],
          [-120.9455, 40.8054],
          [-120.9803, 40.7621],
          [-121.0569, 40.7482],
          [-121.0964, 40.7055],
          [-121.1667, 40.726],
          [-121.2344, 40.71],
          [-121.3211, 40.7705],
          [-121.323, 40.4976],
          [-121.3278, 40.4454],
          [-121.0808, 40.4467],
          [-121.0808, 40.4086],
          [-121.1415, 40.3848],
          [-121.1758, 40.3905],
          [-121.1603, 40.2723],
          [-121.1173, 40.2769],
          [-121.0821, 40.2596],
          [-121.0706, 40.2045],
          [-121.0727, 40.2031],
          [-121.0873, 40.1747],
          [-121.1215, 40.2038],
          [-121.1484, 40.2062],
          [-121.1796, 40.2238],
          [-121.2012, 40.2172],
          [-121.1999, 40.1981],
          [-121.2058, 40.2005],
          [-121.1901, 40.1905],
          [-121.1933, 40.1814],
          [-121.1986, 40.1882],
          [-121.2093, 40.183],
          [-121.2062, 40.1751],
          [-121.2193, 40.1653],
          [-121.2342, 40.1719],
          [-121.2273, 40.1442],
          [-121.2874, 40.1654],
          [-121.2618, 40.1421],
          [-121.2721, 40.1414],
          [-121.2748, 40.1337],
          [-121.2754, 40.1403],
          [-121.3115, 40.1286],
          [-121.3232, 40.1381],
          [-121.3326, 40.1224],
          [-121.3463, 40.1282],
          [-121.3451, 40.1174],
          [-121.3602, 40.1154],
          [-121.3445, 40.1078],
          [-121.3573, 40.0836],
          [-121.3768, 40.0861],
          [-121.4367, 40.1519],
          [-121.453, 40.1218],
          [-121.481, 40.1224],
          [-121.4909, 40.108],
          [-121.5497, 40.1004],
          [-121.5417, 40.1193],
          [-121.5513, 40.1266],
          [-121.5749, 40.1173],
          [-121.5721, 40.1267],
          [-121.5888, 40.1493],
          [-121.6222, 40.1618],
          [-121.5941, 40.1659],
          [-121.583, 40.1956],
          [-121.6089, 40.201],
          [-121.6351, 40.1888],
          [-121.6354, 40.2167],
          [-121.6686, 40.1977],
          [-121.7263, 40.1801],
          [-121.7912, 40.1815],
          [-121.8041, 40.1759],
          [-121.8159, 40.1562],
          [-121.847, 40.1508],
          [-121.8604, 40.1245],
          [-121.8867, 40.1006],
          [-121.8066, 40.1002],
          [-121.9058, 40.0586],
          [-121.959, 40.0597],
          [-121.9821, 40.0375],
          [-122.007, 40.0321],
          [-121.9834, 40.0047],
          [-121.9654, 40.0096],
          [-121.9699, 39.9898],
          [-121.9626, 40.0097],
          [-121.953, 40.0118],
          [-121.9652, 39.9785],
          [-121.9852, 39.9677],
          [-121.9899, 39.916],
          [-122.0117, 39.9128],
          [-121.9794, 39.8858],
          [-122.0431, 39.8838],
          [-122.0693, 39.8405],
          [-122.0565, 39.8257],
          [-122.065, 39.8153],
          [-122.0715, 39.8261],
          [-122.1601, 39.8285],
          [-122.16, 39.8344],
          [-122.1787, 39.834],
          [-122.1788, 39.8444],
          [-122.1973, 39.8396],
          [-122.2051, 39.8524],
          [-122.2726, 39.8593],
          [-122.2791, 39.9],
          [-122.3043, 39.8925],
          [-122.3543, 39.8603],
          [-122.3659, 39.8409],
          [-122.3484, 39.8307],
          [-122.389, 39.8221],
          [-122.4053, 39.8312],
          [-122.4083, 39.8124],
          [-122.429, 39.7996],
          [-122.8444, 39.8005],
          [-122.9377, 39.7982],
          [-122.9467, 39.8335],
          [-122.9601, 39.8452],
          [-122.9515, 39.9063],
          [-122.9227, 39.9151],
          [-122.9098, 39.9364],
          [-122.9268, 39.9451],
          [-122.9255, 39.9689],
          [-122.934, 39.9781],
          [-123.5446, 39.977],
          [-123.5944, 40.0019],
          [-123.5945, 39.9873],
          [-123.6113, 39.9857],
          [-123.6241, 39.9756],
          [-123.5845, 39.9505],
          [-123.5729, 39.9355],
          [-123.5758, 39.9158],
          [-123.5939, 39.9162],
          [-123.5988, 39.9058],
          [-123.6515, 39.9079],
          [-123.6585, 39.9249],
          [-123.6702, 39.9208],
          [-123.6705, 39.9398],
          [-123.6835, 39.9562],
          [-123.7344, 39.9496],
          [-123.737, 39.9331],
          [-123.7673, 39.9187],
          [-123.7599, 39.9044],
          [-123.7465, 39.9119],
          [-123.7101, 39.9067],
          [-123.7269, 39.8841],
          [-123.7108, 39.8746],
          [-123.7182, 39.8517],
          [-123.6967, 39.8471],
          [-123.6893, 39.8569],
          [-123.6635, 39.8567],
          [-123.6912, 39.8447],
          [-123.6798, 39.834],
          [-123.7095, 39.8386],
          [-123.7256, 39.856],
          [-123.7271, 39.8455],
          [-123.742, 39.8431],
          [-123.7348, 39.8339],
          [-123.765, 39.8534],
          [-123.7761, 39.8179],
          [-123.8059, 39.8178],
          [-123.8398, 39.8199],
          [-123.8517, 39.832],
          [-123.9077, 39.863],
          [-123.93, 39.9097],
          [-124.0359, 40.0133],
          [-124.08, 40.0298],
          [-124.0807, 40.0661],
          [-124.1105, 40.1038],
          [-124.1879, 40.1305],
          [-124.3633, 40.2608],
          [-124.3482, 40.3137],
          [-124.3736, 40.3929],
          [-124.4096, 40.4381],
          [-124.387, 40.505],
          [-124.3294, 40.6164],
          [-124.1583, 40.8761],
          [-124.1371, 40.9257],
          [-124.1122, 41.0282],
          [-124.1536, 41.0536],
          [-124.1585, 41.143],
          [-124.1438, 41.1447],
          [-124.1073, 41.2288],
          [-124.0723, 41.3748],
          [-124.0631, 41.4396],
          [-124.082, 41.5478],
          [-124.1012, 41.6028],
          [-124.1356, 41.6573],
          [-124.1435, 41.7093],
          [-124.256, 41.783],
          [-124.2084, 41.8882],
          [-124.2049, 41.9834],
          [-124.2145, 42.0061],
          [-124.2996, 42.0517],
          [-124.341, 42.0929],
          [-124.3668, 42.1585],
          [-124.3778, 42.2188],
          [-124.411, 42.2505],
          [-124.4106, 42.3074],
          [-124.4293, 42.3317],
          [-124.4351, 42.4402],
          [-124.39, 42.5748],
          [-124.4168, 42.6616],
          [-124.4735, 42.732],
          [-124.5147, 42.7368],
          [-124.5244, 42.7898],
          [-124.5524, 42.8406],
          [-124.5001, 42.9175],
          [-124.4569, 43.0005],
          [-124.4345, 43.116],
          [-124.4017, 43.1849],
          [-124.3825, 43.2702],
          [-124.4028, 43.3059],
          [-124.3416, 43.3513],
          [-124.2869, 43.4363],
          [-124.2335, 43.5571],
          [-124.1684, 43.8089],
          [-124.1224, 44.1044],
          [-124.1094, 44.3148],
          [-124.092, 44.371],
        ],
        [
          [-121.3632, 40.2681],
          [-121.2894, 40.2266],
          [-121.2852, 40.255],
          [-121.337, 40.2771],
          [-121.3632, 40.2681],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Portland Mkt": {
    type: "Feature",
    properties: { code: "OR_POR", name: "Portland Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-124.092, 44.371],
          [-124.0836, 44.5011],
          [-124.0583, 44.6589],
          [-124.0704, 44.6835],
          [-124.0591, 44.7377],
          [-124.0755, 44.7714],
          [-124.0667, 44.8312],
          [-124.0323, 44.9008],
          [-124.0044, 45.0462],
          [-123.9754, 45.1455],
          [-123.9642, 45.3172],
          [-123.9797, 45.3477],
          [-123.9606, 45.4308],
          [-123.9765, 45.4897],
          [-123.9576, 45.5104],
          [-123.939, 45.6619],
          [-123.946, 45.7332],
          [-123.9826, 45.7618],
          [-123.9615, 45.8371],
          [-123.9676, 45.9078],
          [-123.9937, 45.9464],
          [-123.9375, 45.9773],
          [-123.9334, 46.0717],
          [-123.9741, 46.1688],
          [-123.9501, 46.2041],
          [-123.9042, 46.1693],
          [-123.8548, 46.1573],
          [-123.822, 46.1903],
          [-123.76, 46.2073],
          [-123.7061, 46.1999],
          [-123.6227, 46.2367],
          [-123.6695, 46.2668],
          [-123.6806, 46.296],
          [-123.7243, 46.3012],
          [-123.7596, 46.2751],
          [-123.8061, 46.2836],
          [-123.8755, 46.2398],
          [-123.9093, 46.2455],
          [-123.9861, 46.3093],
          [-124.0206, 46.3157],
          [-124.044, 46.2759],
          [-124.0817, 46.2747],
          [-124.0646, 46.3269],
          [-124.057, 46.4933],
          [-124.0696, 46.6307],
          [-124.0236, 46.5826],
          [-124.026, 46.463],
          [-123.9437, 46.4772],
          [-123.8657, 46.4313],
          [-123.8024, 46.4456],
          [-123.7925, 46.4839],
          [-123.7196, 46.4703],
          [-123.6847, 46.4428],
          [-123.6453, 46.4461],
          [-123.6473, 46.4881],
          [-123.5706, 46.5129],
          [-123.5343, 46.4761],
          [-123.4647, 46.4811],
          [-123.4631, 46.4301],
          [-123.5487, 46.4166],
          [-123.5607, 46.3839],
          [-123.2178, 46.3851],
          [-123.214, 46.341],
          [-123.1446, 46.331],
          [-123.1268, 46.3102],
          [-123.036, 46.3233],
          [-122.9887, 46.3533],
          [-122.9887, 46.3874],
          [-122.2417, 46.3864],
          [-121.5236, 46.3882],
          [-121.5239, 46.044],
          [-121.0705, 46.0426],
          [-120.4523, 46.0415],
          [-120.4577, 46.0228],
          [-120.4247, 46.0104],
          [-120.4392, 45.9916],
          [-120.4291, 45.9753],
          [-120.412, 45.9678],
          [-120.43, 45.9582],
          [-120.4377, 45.9649],
          [-120.45, 45.9361],
          [-120.4927, 45.9165],
          [-120.4961, 45.9028],
          [-120.5123, 45.8979],
          [-120.5238, 45.8826],
          [-120.5136, 45.8616],
          [-120.5193, 45.8456],
          [-120.5059, 45.8381],
          [-120.5185, 45.8136],
          [-120.5075, 45.8051],
          [-120.4641, 45.7965],
          [-120.4444, 45.7785],
          [-120.4153, 45.7711],
          [-120.4058, 45.7498],
          [-120.408, 45.7166],
          [-120.3779, 45.7141],
          [-120.3276, 45.7279],
          [-120.3176, 45.7204],
          [-120.2098, 45.7406],
          [-120.2357, 45.7276],
          [-120.4608, 45.7024],
          [-120.5059, 45.7],
          [-120.5595, 45.7383],
          [-120.6536, 45.7372],
          [-120.6437, 45.7223],
          [-120.6186, 45.7214],
          [-120.602, 45.7025],
          [-120.5767, 45.6983],
          [-120.5461, 45.669],
          [-120.5298, 45.6717],
          [-120.4887, 45.655],
          [-120.4917, 45.6435],
          [-120.4797, 45.645],
          [-120.4862, 45.6361],
          [-120.4681, 45.6194],
          [-120.4402, 45.6258],
          [-120.4444, 45.6081],
          [-120.4099, 45.5941],
          [-120.405, 45.5556],
          [-120.3813, 45.5384],
          [-120.3805, 45.5216],
          [-120.3604, 45.5093],
          [-120.3665, 45.496],
          [-120.41, 45.4704],
          [-120.4255, 45.4682],
          [-120.4545, 45.4852],
          [-120.4961, 45.4651],
          [-120.4821, 45.4589],
          [-120.4969, 45.4469],
          [-120.4835, 45.436],
          [-120.5062, 45.4285],
          [-120.483, 45.4205],
          [-120.5012, 45.4187],
          [-120.509, 45.4052],
          [-120.5276, 45.4034],
          [-120.5185, 45.3946],
          [-120.5326, 45.3815],
          [-120.5126, 45.3786],
          [-120.5345, 45.3702],
          [-120.5336, 45.353],
          [-120.5434, 45.346],
          [-120.5305, 45.3392],
          [-120.5408, 45.3283],
          [-120.5275, 45.325],
          [-120.5409, 45.3119],
          [-120.5347, 45.295],
          [-120.5445, 45.3035],
          [-120.5426, 45.2864],
          [-120.5556, 45.2812],
          [-120.5416, 45.2788],
          [-120.5471, 45.2629],
          [-120.5561, 45.2511],
          [-120.5417, 45.232],
          [-120.5449, 45.2224],
          [-120.5239, 45.2214],
          [-120.5403, 45.217],
          [-120.5416, 45.2101],
          [-120.4727, 45.1776],
          [-120.4843, 45.1711],
          [-120.4489, 45.1432],
          [-120.466, 45.1296],
          [-120.4632, 45.1003],
          [-120.4768, 45.1116],
          [-120.4778, 45.1114],
          [-120.4792, 45.1101],
          [-120.4828, 45.105],
          [-120.4869, 45.1043],
          [-120.4891, 45.1048],
          [-120.4919, 45.1058],
          [-120.4947, 45.1058],
          [-120.5112, 45.1003],
          [-120.5145, 45.0956],
          [-120.5148, 45.0947],
          [-120.5147, 45.0941],
          [-120.4824, 45.0558],
          [-120.489, 45.0279],
          [-120.4876, 45.0023],
          [-120.4766, 44.9679],
          [-120.4877, 44.953],
          [-120.4727, 44.9353],
          [-120.474, 44.9086],
          [-120.4583, 44.9022],
          [-120.4508, 44.9095],
          [-120.4365, 44.8948],
          [-120.4588, 44.8812],
          [-120.4552, 44.8656],
          [-120.4126, 44.8522],
          [-120.4005, 44.8302],
          [-120.3722, 44.8228],
          [-120.3717, 44.8217],
          [-120.5481, 44.8223],
          [-120.6777, 44.8232],
          [-120.7468, 44.8227],
          [-121.0897, 44.8234],
          [-121.0486, 44.8784],
          [-121.0838, 44.913],
          [-121.0769, 44.9513],
          [-121.1129, 44.9875],
          [-121.1803, 44.9624],
          [-121.218, 44.9933],
          [-121.2833, 45.0158],
          [-121.3044, 45.0462],
          [-121.3475, 45.035],
          [-121.3685, 45.0601],
          [-121.6981, 45.0596],
          [-121.7198, 45.0376],
          [-121.8046, 45.0124],
          [-121.7932, 44.9348],
          [-121.7598, 44.9395],
          [-121.7347, 44.8882],
          [-121.7421, 44.8453],
          [-121.8188, 44.7893],
          [-121.7907, 44.7467],
          [-121.8, 44.7028],
          [-121.7958, 44.5898],
          [-121.8458, 44.4914],
          [-121.8409, 44.2858],
          [-121.8186, 44.262],
          [-122.0286, 44.2542],
          [-122.3266, 44.2533],
          [-122.3675, 44.2091],
          [-122.4055, 44.2206],
          [-122.5771, 44.2281],
          [-122.647, 44.2666],
          [-122.7245, 44.2882],
          [-122.9078, 44.285],
          [-122.944, 44.3594],
          [-122.9847, 44.3635],
          [-123.0149, 44.314],
          [-123.1589, 44.3455],
          [-123.2875, 44.3822],
          [-123.3139, 44.4102],
          [-123.4385, 44.3948],
          [-123.485, 44.405],
          [-123.4845, 44.3185],
          [-123.5218, 44.2871],
          [-123.5935, 44.2766],
          [-123.7149, 44.2803],
          [-123.7825, 44.1994],
          [-123.8122, 44.2181],
          [-123.9007, 44.2389],
          [-123.8865, 44.2828],
          [-123.9165, 44.2903],
          [-123.9421, 44.3449],
          [-124.0106, 44.3654],
          [-124.0253, 44.3328],
          [-124.092, 44.371],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Allentown Mkt": {
    type: "Feature",
    properties: { code: "PA_ALL", name: "Allentown Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-76.6793, 41.5779],
          [-76.6043, 41.5721],
          [-76.5545, 41.5481],
          [-76.4791, 41.5625],
          [-76.22, 41.5421],
          [-76.1917, 41.665],
          [-76.1701, 41.6965],
          [-76.1734, 41.7582],
          [-76.1247, 41.7695],
          [-76.045, 41.7468],
          [-75.9743, 41.7432],
          [-75.9535, 41.6488],
          [-75.9315, 41.6392],
          [-75.7778, 41.6679],
          [-75.7164, 41.6644],
          [-75.5854, 41.7784],
          [-75.589, 41.8359],
          [-75.5632, 41.8895],
          [-75.4795, 41.9003],
          [-75.479, 41.9249],
          [-75.4082, 41.9594],
          [-75.3482, 41.9611],
          [-75.2791, 41.9389],
          [-75.2638, 41.8708],
          [-75.2237, 41.8575],
          [-75.1693, 41.8704],
          [-75.1144, 41.8436],
          [-75.0858, 41.8116],
          [-75.1046, 41.7742],
          [-75.0534, 41.7525],
          [-75.0442, 41.618],
          [-75.0746, 41.6057],
          [-74.9844, 41.5066],
          [-74.8891, 41.4512],
          [-74.7904, 41.4217],
          [-74.7409, 41.4312],
          [-74.7361, 41.3984],
          [-74.6949, 41.3574],
          [-74.7209, 41.3474],
          [-74.7532, 41.3461],
          [-74.7716, 41.3251],
          [-74.795, 41.3204],
          [-74.7926, 41.3106],
          [-74.8301, 41.2872],
          [-74.8469, 41.2533],
          [-74.8572, 41.249],
          [-74.8674, 41.2278],
          [-74.8596, 41.2191],
          [-74.8821, 41.1808],
          [-74.9232, 41.1381],
          [-74.9822, 41.1082],
          [-74.9918, 41.0891],
          [-74.9694, 41.0961],
          [-74.9684, 41.0878],
          [-75.0111, 41.0675],
          [-75.0258, 41.0398],
          [-75.1306, 40.9911],
          [-75.1338, 40.971],
          [-75.1204, 40.9683],
          [-75.1178, 40.953],
          [-75.0793, 40.9139],
          [-75.0753, 40.8942],
          [-75.0508, 40.8681],
          [-75.0643, 40.8483],
          [-75.0958, 40.8471],
          [-75.0839, 40.8245],
          [-75.1085, 40.7911],
          [-75.1344, 40.7738],
          [-75.1716, 40.7777],
          [-75.1918, 40.7558],
          [-75.1963, 40.7471],
          [-75.1821, 40.7315],
          [-75.2039, 40.6915],
          [-75.1969, 40.6813],
          [-75.1768, 40.6757],
          [-75.2005, 40.6492],
          [-75.1886, 40.6246],
          [-75.2018, 40.6172],
          [-75.1923, 40.6027],
          [-75.194, 40.5763],
          [-75.1629, 40.5641],
          [-75.1419, 40.5753],
          [-75.1173, 40.5732],
          [-75.0686, 40.5422],
          [-75.0619, 40.4864],
          [-75.0706, 40.4563],
          [-75.0588, 40.4181],
          [-75.0283, 40.4039],
          [-74.9964, 40.4105],
          [-74.9655, 40.3973],
          [-74.9397, 40.338],
          [-74.8806, 40.3056],
          [-74.8466, 40.2588],
          [-74.8483, 40.2575],
          [-74.8491, 40.2571],
          [-74.8502, 40.2575],
          [-74.8557, 40.2569],
          [-74.8604, 40.2685],
          [-74.8846, 40.2658],
          [-74.888, 40.2255],
          [-74.9436, 40.2138],
          [-74.9319, 40.1826],
          [-75.0527, 40.1603],
          [-75.2431, 40.2746],
          [-75.2917, 40.3194],
          [-75.3326, 40.2666],
          [-75.375, 40.2951],
          [-75.4731, 40.3159],
          [-75.4682, 40.2779],
          [-75.5525, 40.3159],
          [-75.5662, 40.3462],
          [-75.5359, 40.382],
          [-75.5727, 40.4169],
          [-75.5742, 40.4656],
          [-75.6246, 40.4374],
          [-75.6776, 40.4436],
          [-75.7011, 40.4699],
          [-75.647, 40.4864],
          [-75.6415, 40.5177],
          [-75.6942, 40.5511],
          [-75.7313, 40.6198],
          [-75.7999, 40.6445],
          [-75.8419, 40.6936],
          [-75.8562, 40.743],
          [-75.9791, 40.7404],
          [-75.9868, 40.7142],
          [-76.0908, 40.7082],
          [-76.0826, 40.7714],
          [-76.1237, 40.7961],
          [-76.0227, 40.8751],
          [-76.0394, 40.9049],
          [-76.0864, 40.869],
          [-76.1407, 40.8891],
          [-76.1589, 40.9369],
          [-76.2078, 40.9497],
          [-76.184, 40.9825],
          [-76.2195, 41.0049],
          [-76.335, 40.9794],
          [-76.3173, 41.0195],
          [-76.3297, 41.0541],
          [-76.373, 41.0745],
          [-76.3, 41.117],
          [-76.2666, 41.1703],
          [-76.2749, 41.2004],
          [-76.2451, 41.2282],
          [-76.2702, 41.2899],
          [-76.2938, 41.2971],
          [-76.304, 41.3489],
          [-76.3427, 41.3844],
          [-76.4278, 41.3963],
          [-76.463, 41.3756],
          [-76.5231, 41.427],
          [-76.5321, 41.4533],
          [-76.6512, 41.4386],
          [-76.6952, 41.3712],
          [-76.751, 41.4067],
          [-76.7775, 41.4871],
          [-76.7047, 41.5164],
          [-76.6793, 41.5779],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Erie Mkt": {
    type: "Feature",
    properties: { code: "PA_ERI", name: "Erie Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-79.762, 42.2699],
          [-79.7614, 41.9991],
          [-79.449, 41.9983],
          [-78.8748, 41.9976],
          [-78.5966, 41.9999],
          [-78.0296, 41.9994],
          [-77.9629, 41.978],
          [-78.0348, 41.9127],
          [-78.0899, 41.9131],
          [-78.0925, 41.7365],
          [-78.0882, 41.7004],
          [-78.1198, 41.6506],
          [-77.9686, 41.6492],
          [-77.9044, 41.6592],
          [-77.8956, 41.6238],
          [-77.8402, 41.6243],
          [-77.8275, 41.5654],
          [-77.828, 41.477],
          [-77.9892, 41.4748],
          [-78.0504, 41.4755],
          [-78.1808, 41.5972],
          [-78.2035, 41.5775],
          [-78.2544, 41.5931],
          [-78.27, 41.6181],
          [-78.4193, 41.6169],
          [-78.4828, 41.6024],
          [-78.4721, 41.6405],
          [-78.4976, 41.6802],
          [-78.5315, 41.6888],
          [-78.6492, 41.6884],
          [-78.6071, 41.6284],
          [-78.7445, 41.6247],
          [-78.7244, 41.5997],
          [-78.7318, 41.5635],
          [-78.7673, 41.5398],
          [-78.8152, 41.5511],
          [-78.8136, 41.454],
          [-78.959, 41.4529],
          [-78.9574, 41.5792],
          [-79.0009, 41.605],
          [-79.0765, 41.5946],
          [-79.1215, 41.572],
          [-79.1934, 41.5752],
          [-79.2064, 41.5385],
          [-79.29, 41.5304],
          [-79.3069, 41.5067],
          [-79.3646, 41.5001],
          [-79.3667, 41.4637],
          [-79.3182, 41.4297],
          [-79.3218, 41.3846],
          [-79.3584, 41.3403],
          [-79.5414, 41.3359],
          [-79.5252, 41.3026],
          [-79.6329, 41.2536],
          [-79.6141, 41.1955],
          [-79.5504, 41.1565],
          [-79.5786, 41.1303],
          [-79.649, 41.1643],
          [-79.7407, 41.1727],
          [-79.8836, 41.1725],
          [-79.9702, 41.2438],
          [-79.9795, 41.3223],
          [-80.0173, 41.3479],
          [-80.0081, 41.4147],
          [-80.0454, 41.4379],
          [-80.1251, 41.4406],
          [-80.1254, 41.4733],
          [-80.2142, 41.4823],
          [-80.2151, 41.5207],
          [-80.2585, 41.5361],
          [-80.2733, 41.5662],
          [-80.3304, 41.5494],
          [-80.3705, 41.5605],
          [-80.3815, 41.6053],
          [-80.4343, 41.5764],
          [-80.5193, 41.5785],
          [-80.5194, 41.9775],
          [-80.33, 42.0362],
          [-80.1881, 42.0943],
          [-80.1362, 42.1499],
          [-80.0885, 42.1732],
          [-80.0611, 42.1449],
          [-79.9313, 42.2067],
          [-79.762, 42.2699],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Harrisburg Mkt": {
    type: "Feature",
    properties: { code: "PA_HAR", name: "Harrisburg Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-77.9892, 41.4748],
          [-77.828, 41.477],
          [-77.8275, 41.5654],
          [-77.6394, 41.5707],
          [-77.5996, 41.5423],
          [-77.4942, 41.5418],
          [-77.4538, 41.5077],
          [-77.316, 41.4611],
          [-77.1914, 41.49],
          [-77.1572, 41.5221],
          [-77.0968, 41.5222],
          [-77.093, 41.5472],
          [-77.0402, 41.5949],
          [-77.0517, 41.6261],
          [-77.0168, 41.6618],
          [-76.9571, 41.6645],
          [-76.9286, 41.6937],
          [-76.8467, 41.7125],
          [-76.7868, 41.713],
          [-76.7626, 41.6875],
          [-76.6373, 41.7001],
          [-76.6947, 41.5791],
          [-76.6793, 41.5779],
          [-76.7047, 41.5164],
          [-76.7775, 41.4871],
          [-76.751, 41.4067],
          [-76.6952, 41.3712],
          [-76.6512, 41.4386],
          [-76.5321, 41.4533],
          [-76.5231, 41.427],
          [-76.463, 41.3756],
          [-76.4278, 41.3963],
          [-76.3427, 41.3844],
          [-76.304, 41.3489],
          [-76.2938, 41.2971],
          [-76.2702, 41.2899],
          [-76.2451, 41.2282],
          [-76.2749, 41.2004],
          [-76.2666, 41.1703],
          [-76.3, 41.117],
          [-76.373, 41.0745],
          [-76.3297, 41.0541],
          [-76.3173, 41.0195],
          [-76.335, 40.9794],
          [-76.2195, 41.0049],
          [-76.184, 40.9825],
          [-76.2078, 40.9497],
          [-76.1589, 40.9369],
          [-76.1407, 40.8891],
          [-76.0864, 40.869],
          [-76.0394, 40.9049],
          [-76.0227, 40.8751],
          [-76.1237, 40.7961],
          [-76.0826, 40.7714],
          [-76.0908, 40.7082],
          [-75.9868, 40.7142],
          [-75.9791, 40.7404],
          [-75.8562, 40.743],
          [-75.8419, 40.6936],
          [-75.9932, 40.6397],
          [-76.0226, 40.5879],
          [-76.0653, 40.5797],
          [-76.1716, 40.5349],
          [-76.256, 40.5171],
          [-76.3392, 40.5153],
          [-76.4183, 40.4958],
          [-76.3712, 40.4705],
          [-76.3021, 40.4543],
          [-76.2518, 40.4594],
          [-76.2662, 40.3923],
          [-76.2055, 40.3607],
          [-76.1601, 40.3124],
          [-76.1213, 40.3065],
          [-76.0799, 40.2712],
          [-76.0613, 40.234],
          [-75.991, 40.2134],
          [-75.9276, 40.1723],
          [-75.9435, 40.1084],
          [-75.9348, 40.0397],
          [-75.9946, 39.9522],
          [-75.9913, 39.8688],
          [-76.0399, 39.801],
          [-76.1409, 39.7769],
          [-76.1131, 39.7396],
          [-76.1247, 39.727],
          [-76.1339, 39.7273],
          [-76.1364, 39.7254],
          [-76.1355, 39.7216],
          [-76.4188, 39.7212],
          [-77.067, 39.7201],
          [-77.5348, 39.7201],
          [-78.0932, 39.7223],
          [-78.5181, 39.7226],
          [-78.4268, 39.7916],
          [-78.3608, 39.7535],
          [-78.2381, 39.8915],
          [-78.2113, 39.9037],
          [-78.1455, 39.9836],
          [-78.1351, 40.0329],
          [-78.2286, 40.0881],
          [-78.3038, 40.0535],
          [-78.2968, 40.0905],
          [-78.351, 40.1145],
          [-78.4005, 40.0993],
          [-78.4936, 40.1675],
          [-78.5605, 40.1481],
          [-78.5832, 40.1819],
          [-78.5794, 40.2457],
          [-78.6479, 40.2705],
          [-78.6204, 40.327],
          [-78.5769, 40.3103],
          [-78.5493, 40.4603],
          [-78.593, 40.4445],
          [-78.6181, 40.4744],
          [-78.5878, 40.4988],
          [-78.5941, 40.5544],
          [-78.6317, 40.5626],
          [-78.6823, 40.62],
          [-78.715, 40.6223],
          [-78.7502, 40.6968],
          [-78.7801, 40.7875],
          [-78.6965, 40.7805],
          [-78.6516, 40.753],
          [-78.6885, 40.7248],
          [-78.5896, 40.7254],
          [-78.6359, 40.8213],
          [-78.6209, 40.8573],
          [-78.6851, 40.8885],
          [-78.6143, 40.9081],
          [-78.7012, 40.9657],
          [-78.656, 41.0539],
          [-78.4889, 41.0543],
          [-78.4866, 41.1431],
          [-78.4506, 41.1619],
          [-78.4165, 41.1473],
          [-78.4152, 41.2338],
          [-78.3616, 41.2372],
          [-78.0934, 41.2169],
          [-77.9888, 41.3675],
          [-77.9892, 41.4748],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Philadelphia Mkt": {
    type: "Feature",
    properties: { code: "PA_PHI", name: "Philadelphia Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-75.8419, 40.6936],
          [-75.7999, 40.6445],
          [-75.7313, 40.6198],
          [-75.6942, 40.5511],
          [-75.6415, 40.5177],
          [-75.647, 40.4864],
          [-75.7011, 40.4699],
          [-75.6776, 40.4436],
          [-75.6246, 40.4374],
          [-75.5742, 40.4656],
          [-75.5727, 40.4169],
          [-75.5359, 40.382],
          [-75.5662, 40.3462],
          [-75.5525, 40.3159],
          [-75.4682, 40.2779],
          [-75.4731, 40.3159],
          [-75.375, 40.2951],
          [-75.3326, 40.2666],
          [-75.2917, 40.3194],
          [-75.2431, 40.2746],
          [-75.0527, 40.1603],
          [-74.9319, 40.1826],
          [-74.9436, 40.2138],
          [-74.888, 40.2255],
          [-74.8846, 40.2658],
          [-74.8604, 40.2685],
          [-74.8557, 40.2569],
          [-74.8502, 40.2575],
          [-74.8491, 40.2571],
          [-74.8483, 40.2575],
          [-74.8466, 40.2588],
          [-74.8423, 40.2505],
          [-74.7714, 40.2154],
          [-74.7543, 40.1852],
          [-74.7223, 40.1606],
          [-74.7243, 40.147],
          [-74.7406, 40.1352],
          [-74.7589, 40.134],
          [-74.7821, 40.1208],
          [-74.8259, 40.1239],
          [-74.8301, 40.117],
          [-74.8177, 40.1134],
          [-74.8115, 40.0967],
          [-74.785, 40.1092],
          [-74.7918, 40.1025],
          [-74.7705, 40.0929],
          [-74.7984, 40.072],
          [-74.797, 40.0619],
          [-74.7721, 40.0626],
          [-74.7953, 40.0429],
          [-74.761, 40.0244],
          [-74.7426, 40.0226],
          [-74.7422, 40.0458],
          [-74.7565, 40.0647],
          [-74.7435, 40.1076],
          [-74.7346, 40.1093],
          [-74.7134, 40.0931],
          [-74.7059, 40.1151],
          [-74.6874, 40.1246],
          [-74.6529, 40.1023],
          [-74.6452, 40.1002],
          [-74.6519, 40.0829],
          [-74.6392, 40.0804],
          [-74.6465, 40.0684],
          [-74.6331, 40.0618],
          [-74.653, 40.0646],
          [-74.6374, 40.0522],
          [-74.6454, 40.0426],
          [-74.6415, 40.0307],
          [-74.662, 40.0204],
          [-74.6486, 40.006],
          [-74.6578, 39.9881],
          [-74.6492, 39.9934],
          [-74.6534, 39.9857],
          [-74.6399, 39.9867],
          [-74.6324, 39.9664],
          [-74.603, 39.9663],
          [-74.5879, 39.98],
          [-74.5938, 39.9893],
          [-74.5066, 39.9912],
          [-74.4172, 39.8231],
          [-74.3431, 39.8281],
          [-74.3149, 39.7755],
          [-74.2925, 39.7705],
          [-74.2199, 39.7767],
          [-74.1527, 39.754],
          [-74.1506, 39.7693],
          [-74.1362, 39.7771],
          [-74.1231, 39.7609],
          [-74.125, 39.7726],
          [-74.1179, 39.7728],
          [-74.1189, 39.7595],
          [-74.097, 39.7657],
          [-74.1417, 39.6894],
          [-74.3043, 39.4714],
          [-74.4127, 39.3608],
          [-74.5218, 39.3138],
          [-74.6145, 39.2447],
          [-74.7143, 39.1198],
          [-74.7059, 39.1029],
          [-74.7788, 39.0231],
          [-74.7927, 38.992],
          [-74.8645, 38.9404],
          [-74.9336, 38.9285],
          [-74.972, 38.9404],
          [-74.9554, 39.0013],
          [-74.9037, 39.0874],
          [-74.8871, 39.1585],
          [-74.9606, 39.1898],
          [-75.0262, 39.1936],
          [-75.0357, 39.2154],
          [-75.166, 39.2018],
          [-75.1782, 39.2433],
          [-75.2889, 39.2896],
          [-75.3275, 39.3393],
          [-75.365, 39.3414],
          [-75.4318, 39.3916],
          [-75.4652, 39.4389],
          [-75.5364, 39.4606],
          [-75.5127, 39.578],
          [-75.5559, 39.6058],
          [-75.5594, 39.6298],
          [-75.5097, 39.6861],
          [-75.4886, 39.7148],
          [-75.4776, 39.715],
          [-75.4633, 39.7612],
          [-75.4473, 39.7733],
          [-75.4483, 39.7744],
          [-75.4053, 39.7962],
          [-75.428, 39.8092],
          [-75.5184, 39.8363],
          [-75.5705, 39.839],
          [-75.6347, 39.8302],
          [-75.717, 39.792],
          [-75.7531, 39.7576],
          [-75.7736, 39.7224],
          [-76.1355, 39.7216],
          [-76.1364, 39.7254],
          [-76.1339, 39.7273],
          [-76.1247, 39.727],
          [-76.1131, 39.7396],
          [-76.1409, 39.7769],
          [-76.0399, 39.801],
          [-75.9913, 39.8688],
          [-75.9946, 39.9522],
          [-75.9348, 40.0397],
          [-75.9435, 40.1084],
          [-75.9276, 40.1723],
          [-75.991, 40.2134],
          [-76.0613, 40.234],
          [-76.0799, 40.2712],
          [-76.1213, 40.3065],
          [-76.1601, 40.3124],
          [-76.2055, 40.3607],
          [-76.2662, 40.3923],
          [-76.2518, 40.4594],
          [-76.3021, 40.4543],
          [-76.3712, 40.4705],
          [-76.4183, 40.4958],
          [-76.3392, 40.5153],
          [-76.256, 40.5171],
          [-76.1716, 40.5349],
          [-76.0653, 40.5797],
          [-76.0226, 40.5879],
          [-75.9932, 40.6397],
          [-75.8419, 40.6936],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Pittsburgh Mkt": {
    type: "Feature",
    properties: { code: "PA_PIT", name: "Pittsburgh Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-81.3899, 40.2155],
          [-81.3224, 40.2171],
          [-81.3008, 40.2457],
          [-81.2138, 40.2529],
          [-81.1989, 40.336],
          [-81.1494, 40.4073],
          [-81.0993, 40.4459],
          [-81.11, 40.4649],
          [-80.9966, 40.5172],
          [-80.9351, 40.5253],
          [-80.926, 40.5561],
          [-80.8863, 40.5558],
          [-80.8907, 40.5952],
          [-80.9562, 40.6398],
          [-80.8768, 40.6921],
          [-80.6935, 40.6627],
          [-80.6361, 40.6989],
          [-80.519, 40.7258],
          [-80.5187, 41.2489],
          [-80.5193, 41.5785],
          [-80.4343, 41.5764],
          [-80.3815, 41.6053],
          [-80.3705, 41.5605],
          [-80.3304, 41.5494],
          [-80.2733, 41.5662],
          [-80.2585, 41.5361],
          [-80.2151, 41.5207],
          [-80.2142, 41.4823],
          [-80.1254, 41.4733],
          [-80.1251, 41.4406],
          [-80.0454, 41.4379],
          [-80.0081, 41.4147],
          [-80.0173, 41.3479],
          [-79.9795, 41.3223],
          [-79.9702, 41.2438],
          [-79.8836, 41.1725],
          [-79.7407, 41.1727],
          [-79.649, 41.1643],
          [-79.5786, 41.1303],
          [-79.5504, 41.1565],
          [-79.6141, 41.1955],
          [-79.6329, 41.2536],
          [-79.5252, 41.3026],
          [-79.5414, 41.3359],
          [-79.3584, 41.3403],
          [-79.3218, 41.3846],
          [-79.3182, 41.4297],
          [-79.3667, 41.4637],
          [-79.3646, 41.5001],
          [-79.3069, 41.5067],
          [-79.29, 41.5304],
          [-79.2064, 41.5385],
          [-79.1934, 41.5752],
          [-79.1215, 41.572],
          [-79.0765, 41.5946],
          [-79.0009, 41.605],
          [-78.9574, 41.5792],
          [-78.959, 41.4529],
          [-78.8136, 41.454],
          [-78.8152, 41.5511],
          [-78.7673, 41.5398],
          [-78.7318, 41.5635],
          [-78.7244, 41.5997],
          [-78.7445, 41.6247],
          [-78.6071, 41.6284],
          [-78.6492, 41.6884],
          [-78.5315, 41.6888],
          [-78.4976, 41.6802],
          [-78.4721, 41.6405],
          [-78.4828, 41.6024],
          [-78.4193, 41.6169],
          [-78.27, 41.6181],
          [-78.2544, 41.5931],
          [-78.2035, 41.5775],
          [-78.1808, 41.5972],
          [-78.0504, 41.4755],
          [-77.9892, 41.4748],
          [-77.9888, 41.3675],
          [-78.0934, 41.2169],
          [-78.3616, 41.2372],
          [-78.4152, 41.2338],
          [-78.4165, 41.1473],
          [-78.4506, 41.1619],
          [-78.4866, 41.1431],
          [-78.4889, 41.0543],
          [-78.656, 41.0539],
          [-78.7012, 40.9657],
          [-78.6143, 40.9081],
          [-78.6851, 40.8885],
          [-78.6209, 40.8573],
          [-78.6359, 40.8213],
          [-78.5896, 40.7254],
          [-78.6885, 40.7248],
          [-78.6516, 40.753],
          [-78.6965, 40.7805],
          [-78.7801, 40.7875],
          [-78.7502, 40.6968],
          [-78.715, 40.6223],
          [-78.6823, 40.62],
          [-78.6317, 40.5626],
          [-78.5941, 40.5544],
          [-78.5878, 40.4988],
          [-78.6181, 40.4744],
          [-78.593, 40.4445],
          [-78.5493, 40.4603],
          [-78.5769, 40.3103],
          [-78.6204, 40.327],
          [-78.6479, 40.2705],
          [-78.5794, 40.2457],
          [-78.5832, 40.1819],
          [-78.5605, 40.1481],
          [-78.4936, 40.1675],
          [-78.4005, 40.0993],
          [-78.351, 40.1145],
          [-78.2968, 40.0905],
          [-78.3038, 40.0535],
          [-78.2286, 40.0881],
          [-78.1351, 40.0329],
          [-78.1455, 39.9836],
          [-78.2113, 39.9037],
          [-78.2381, 39.8915],
          [-78.3608, 39.7535],
          [-78.4268, 39.7916],
          [-78.5181, 39.7226],
          [-79.0527, 39.7229],
          [-79.4767, 39.7211],
          [-79.4782, 39.5947],
          [-79.5411, 39.5313],
          [-79.5858, 39.5177],
          [-79.5924, 39.4619],
          [-79.636, 39.4753],
          [-79.6807, 39.4399],
          [-79.6798, 39.4023],
          [-79.7186, 39.4498],
          [-79.7918, 39.4519],
          [-79.8671, 39.5235],
          [-79.9136, 39.4633],
          [-80.0871, 39.3864],
          [-80.1054, 39.4088],
          [-80.1686, 39.3812],
          [-80.2425, 39.391],
          [-80.2584, 39.4241],
          [-80.3128, 39.4376],
          [-80.3651, 39.434],
          [-80.4352, 39.4796],
          [-80.4735, 39.4927],
          [-80.4982, 39.5633],
          [-80.5375, 39.5546],
          [-80.5923, 39.581],
          [-80.5778, 39.6237],
          [-80.5876, 39.7218],
          [-80.7104, 39.7213],
          [-80.6981, 39.6813],
          [-80.7445, 39.6674],
          [-80.814, 39.6982],
          [-80.8582, 39.6955],
          [-80.8804, 39.6207],
          [-80.994, 39.6084],
          [-81.0649, 39.6251],
          [-81.0545, 39.6844],
          [-81.0137, 39.6692],
          [-80.9845, 39.6973],
          [-80.9477, 39.7919],
          [-80.8896, 39.8462],
          [-80.9192, 39.8715],
          [-80.9543, 39.8555],
          [-80.9991, 39.8734],
          [-80.9901, 39.9083],
          [-80.9681, 39.9799],
          [-80.8959, 39.9767],
          [-80.9051, 40.0171],
          [-80.9378, 40.0224],
          [-80.9841, 40.046],
          [-80.985, 40.0878],
          [-81.0506, 40.0977],
          [-81.1172, 40.0831],
          [-81.1758, 40.1044],
          [-81.2449, 40.0959],
          [-81.2825, 40.1251],
          [-81.3587, 40.1208],
          [-81.3929, 40.1776],
          [-81.3899, 40.2155],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "N Charleston Mkt": {
    type: "Feature",
    properties: { code: "SC_CHA", name: "N Charleston Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-80.477, 32.4853],
          [-80.5365, 32.5292],
          [-80.5515, 32.5658],
          [-80.5828, 32.5662],
          [-80.6478, 32.5964],
          [-80.6845, 32.6546],
          [-80.7259, 32.6892],
          [-80.687, 32.7213],
          [-80.6682, 32.7755],
          [-80.7523, 32.8073],
          [-80.8798, 32.7919],
          [-80.8713, 32.8472],
          [-80.8747, 32.991],
          [-80.8673, 32.9996],
          [-80.8835, 33.0303],
          [-80.9165, 33.0535],
          [-80.9246, 33.0727],
          [-80.917, 33.0791],
          [-80.9367, 33.1009],
          [-80.9367, 33.1295],
          [-80.9568, 33.1593],
          [-80.9385, 33.1749],
          [-80.9297, 33.1754],
          [-80.9221, 33.1621],
          [-80.9027, 33.1703],
          [-80.8979, 33.1813],
          [-80.913, 33.1912],
          [-80.8885, 33.2013],
          [-80.8958, 33.2148],
          [-80.8878, 33.2262],
          [-80.8964, 33.2342],
          [-80.8689, 33.2373],
          [-80.8837, 33.2482],
          [-80.8825, 33.2793],
          [-80.8559, 33.2674],
          [-80.8607, 33.3094],
          [-80.8474, 33.3178],
          [-80.8453, 33.3313],
          [-80.8273, 33.3333],
          [-80.8268, 33.3272],
          [-80.7978, 33.3353],
          [-80.7764, 33.3244],
          [-80.7783, 33.3131],
          [-80.7637, 33.3179],
          [-80.7559, 33.3103],
          [-80.7403, 33.3108],
          [-80.7196, 33.2862],
          [-80.7032, 33.2903],
          [-80.6974, 33.2664],
          [-80.7058, 33.2613],
          [-80.6795, 33.2403],
          [-80.5777, 33.2946],
          [-80.5682, 33.2804],
          [-80.5474, 33.2914],
          [-80.5212, 33.2882],
          [-80.542, 33.3136],
          [-80.5028, 33.3345],
          [-80.4846, 33.28],
          [-80.4271, 33.2578],
          [-80.3919, 33.2646],
          [-80.36, 33.2565],
          [-80.3354, 33.2245],
          [-80.3308, 33.1984],
          [-80.3088, 33.1904],
          [-80.3089, 33.2101],
          [-80.3277, 33.2442],
          [-80.3183, 33.2521],
          [-80.3522, 33.2775],
          [-80.3116, 33.2646],
          [-80.2809, 33.282],
          [-80.2856, 33.2978],
          [-80.2527, 33.306],
          [-80.2385, 33.3912],
          [-80.2302, 33.3841],
          [-80.2299, 33.3916],
          [-80.216, 33.395],
          [-80.2252, 33.4093],
          [-80.2224, 33.4478],
          [-80.204, 33.4419],
          [-80.2074, 33.4526],
          [-80.1974, 33.4509],
          [-80.1991, 33.4434],
          [-80.1817, 33.4485],
          [-80.181, 33.4546],
          [-80.1725, 33.4489],
          [-80.1635, 33.4604],
          [-80.1611, 33.4488],
          [-80.1483, 33.4499],
          [-80.1507, 33.4603],
          [-80.1396, 33.4539],
          [-80.1442, 33.4641],
          [-80.1318, 33.4635],
          [-80.1522, 33.4851],
          [-80.1277, 33.4929],
          [-80.1253, 33.5025],
          [-80.0948, 33.4958],
          [-80.0934, 33.5033],
          [-80.0849, 33.4999],
          [-80.0799, 33.5069],
          [-80.0417, 33.5009],
          [-80.0239, 33.5063],
          [-80.0205, 33.4984],
          [-80.0121, 33.5052],
          [-80.0018, 33.4948],
          [-79.997, 33.5069],
          [-79.9898, 33.4983],
          [-79.9641, 33.4982],
          [-79.9031, 33.4504],
          [-79.8941, 33.4537],
          [-79.8682, 33.4345],
          [-79.8782, 33.4252],
          [-79.8613, 33.4178],
          [-79.861, 33.4095],
          [-79.8368, 33.3911],
          [-79.8066, 33.3727],
          [-79.7842, 33.3761],
          [-79.7562, 33.3297],
          [-79.7348, 33.3202],
          [-79.7096, 33.3212],
          [-79.6049, 33.2794],
          [-79.5623, 33.2733],
          [-79.5854, 33.2961],
          [-79.5702, 33.3108],
          [-79.5759, 33.3267],
          [-79.5402, 33.3472],
          [-79.5241, 33.3488],
          [-79.5279, 33.3573],
          [-79.515, 33.3644],
          [-79.4963, 33.3571],
          [-79.4998, 33.368],
          [-79.4836, 33.3687],
          [-79.5032, 33.3737],
          [-79.5119, 33.3661],
          [-79.5084, 33.3781],
          [-79.4973, 33.3818],
          [-79.4669, 33.3731],
          [-79.4796, 33.3987],
          [-79.4688, 33.3915],
          [-79.4512, 33.4058],
          [-79.4402, 33.4637],
          [-79.4572, 33.4662],
          [-79.4544, 33.4757],
          [-79.4565, 33.4811],
          [-79.4548, 33.4807],
          [-79.4494, 33.4821],
          [-79.4534, 33.4815],
          [-79.457, 33.4819],
          [-79.4582, 33.4816],
          [-79.4572, 33.4781],
          [-79.4551, 33.4763],
          [-79.4549, 33.4751],
          [-79.4579, 33.4706],
          [-79.458, 33.4681],
          [-79.4614, 33.468],
          [-79.4739, 33.4576],
          [-79.4864, 33.4609],
          [-79.4737, 33.458],
          [-79.471, 33.4635],
          [-79.4634, 33.4692],
          [-79.4621, 33.4691],
          [-79.4611, 33.4682],
          [-79.4587, 33.4702],
          [-79.4561, 33.4741],
          [-79.4555, 33.4762],
          [-79.4583, 33.4784],
          [-79.4589, 33.4811],
          [-79.4579, 33.4825],
          [-79.4563, 33.4823],
          [-79.469, 33.4866],
          [-79.4396, 33.5202],
          [-79.4639, 33.5526],
          [-79.4614, 33.5724],
          [-79.446, 33.5856],
          [-79.4346, 33.5773],
          [-79.4531, 33.617],
          [-79.435, 33.6174],
          [-79.4245, 33.6384],
          [-79.4078, 33.6269],
          [-79.4059, 33.6127],
          [-79.3788, 33.6179],
          [-79.3591, 33.6357],
          [-79.3389, 33.6136],
          [-79.3767, 33.5801],
          [-79.3709, 33.5776],
          [-79.362, 33.5892],
          [-79.3582, 33.5844],
          [-79.3304, 33.5894],
          [-79.2943, 33.5722],
          [-79.2821, 33.5936],
          [-79.2478, 33.63],
          [-79.2306, 33.6223],
          [-79.2106, 33.6248],
          [-79.1878, 33.6319],
          [-79.1861, 33.6526],
          [-79.1616, 33.6535],
          [-79.1621, 33.6282],
          [-79.1455, 33.6031],
          [-79.1486, 33.5938],
          [-79.1381, 33.5929],
          [-79.1653, 33.5742],
          [-79.1629, 33.5613],
          [-79.1713, 33.5601],
          [-79.1753, 33.548],
          [-79.1711, 33.5407],
          [-79.1543, 33.5392],
          [-79.1606, 33.522],
          [-79.1443, 33.5163],
          [-79.1467, 33.5049],
          [-79.1401, 33.5039],
          [-79.1693, 33.4507],
          [-79.2116, 33.4236],
          [-79.2534, 33.3658],
          [-79.2253, 33.3646],
          [-79.2055, 33.3848],
          [-79.1793, 33.419],
          [-79.1526, 33.4195],
          [-79.1373, 33.412],
          [-79.1387, 33.397],
          [-79.1803, 33.2541],
          [-79.1724, 33.2066],
          [-79.1879, 33.1737],
          [-79.2461, 33.1249],
          [-79.3299, 33.09],
          [-79.3599, 33.0069],
          [-79.4234, 33.0151],
          [-79.4835, 33.0013],
          [-79.5224, 33.0354],
          [-79.5807, 33.0064],
          [-79.6176, 32.9527],
          [-79.576, 32.9062],
          [-79.6305, 32.8888],
          [-79.6951, 32.8504],
          [-79.7272, 32.8057],
          [-79.8667, 32.7574],
          [-79.885, 32.6844],
          [-79.9685, 32.6397],
          [-79.9917, 32.6164],
          [-80.077, 32.6033],
          [-80.1484, 32.5785],
          [-80.1718, 32.5461],
          [-80.2052, 32.5555],
          [-80.3324, 32.4781],
          [-80.364, 32.4961],
          [-80.4135, 32.4707],
          [-80.4235, 32.498],
          [-80.477, 32.4853],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Twin Falls Mkt": {
    type: "Feature",
    properties: { code: "ID_TWI", name: "Twin Falls Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-118.1971, 42.1249],
          [-118.1954, 42.2759],
          [-118.2147, 42.276],
          [-118.2167, 42.9145],
          [-118.2291, 42.9146],
          [-118.2321, 43.4488],
          [-118.2709, 43.4434],
          [-118.3097, 43.4593],
          [-118.2913, 43.4696],
          [-118.3035, 43.4802],
          [-118.3025, 43.4924],
          [-118.3141, 43.503],
          [-118.3352, 43.502],
          [-118.3701, 43.5482],
          [-118.3969, 43.5615],
          [-118.414, 43.5577],
          [-118.4615, 43.5224],
          [-118.4893, 43.5286],
          [-118.516, 43.5644],
          [-118.5196, 43.5897],
          [-118.529, 43.5911],
          [-118.5346, 43.6924],
          [-118.5935, 43.6666],
          [-118.6019, 43.6866],
          [-118.6201, 43.6868],
          [-118.624, 43.7072],
          [-118.653, 43.7141],
          [-118.6619, 43.7079],
          [-118.67, 43.7415],
          [-118.7122, 43.74],
          [-118.7175, 43.7636],
          [-118.7405, 43.7855],
          [-118.7296, 43.8208],
          [-118.755, 43.8373],
          [-118.7644, 43.8553],
          [-118.7592, 43.8674],
          [-118.7883, 43.8802],
          [-118.7929, 43.8916],
          [-118.8242, 43.8844],
          [-118.8494, 43.922],
          [-118.826, 43.942],
          [-118.8291, 43.96],
          [-118.8163, 44.0477],
          [-118.5867, 44.0471],
          [-118.5868, 44.0401],
          [-118.2274, 44.04],
          [-118.2321, 44.2561],
          [-118.1526, 44.2707],
          [-118.1523, 44.2996],
          [-118.0922, 44.3287],
          [-118.0869, 44.3575],
          [-118.0317, 44.3899],
          [-118.0314, 44.4152],
          [-117.9716, 44.4433],
          [-117.591, 44.4445],
          [-117.5777, 44.4302],
          [-117.5961, 44.4759],
          [-117.5706, 44.5048],
          [-117.5775, 44.5309],
          [-117.6471, 44.5329],
          [-117.706, 44.4927],
          [-117.7815, 44.5175],
          [-117.7891, 44.535],
          [-117.7693, 44.5408],
          [-117.7201, 44.6179],
          [-117.6326, 44.6252],
          [-117.6581, 44.6792],
          [-117.5159, 44.6281],
          [-117.5236, 44.6831],
          [-117.5764, 44.7135],
          [-117.5446, 44.7463],
          [-117.5261, 44.7479],
          [-117.5258, 44.7569],
          [-117.4476, 44.7557],
          [-117.4264, 44.769],
          [-117.3819, 44.7654],
          [-117.3821, 44.8051],
          [-117.3228, 44.7683],
          [-117.2587, 44.7709],
          [-117.2465, 44.7679],
          [-117.2196, 44.7557],
          [-117.188, 44.7482],
          [-117.2069, 44.6985],
          [-117.2255, 44.6883],
          [-117.1283, 44.6885],
          [-117.1337, 44.7438],
          [-117.1272, 44.7477],
          [-117.0932, 44.7345],
          [-117.0818, 44.7236],
          [-117.1061, 44.6885],
          [-117.1125, 44.631],
          [-117.0618, 44.7067],
          [-117.0442, 44.7451],
          [-116.9347, 44.7839],
          [-116.9311, 44.8048],
          [-116.8524, 44.8876],
          [-116.832, 44.933],
          [-116.8583, 44.9788],
          [-116.8479, 45.0226],
          [-116.7973, 45.0603],
          [-116.7748, 45.1055],
          [-116.7296, 45.1421],
          [-116.6914, 45.2637],
          [-116.3429, 45.268],
          [-116.2539, 45.3001],
          [-116.2888, 45.3584],
          [-116.3612, 45.3589],
          [-116.3454, 45.3914],
          [-116.271, 45.3867],
          [-116.2099, 45.3989],
          [-116.1449, 45.4273],
          [-116.1224, 45.4025],
          [-115.9976, 45.4274],
          [-115.929, 45.46],
          [-115.8024, 45.468],
          [-115.7531, 45.4445],
          [-115.6668, 45.434],
          [-115.6144, 45.3978],
          [-115.5113, 45.3756],
          [-115.4691, 45.4122],
          [-115.4283, 45.3523],
          [-115.3463, 45.3545],
          [-115.396, 45.2921],
          [-115.4022, 45.2436],
          [-115.45, 45.2353],
          [-115.4659, 45.1965],
          [-114.6947, 45.1962],
          [-114.6594, 45.2487],
          [-114.5933, 45.2974],
          [-114.6434, 45.3206],
          [-114.7166, 45.3957],
          [-114.7935, 45.4365],
          [-114.7933, 45.5086],
          [-114.7685, 45.5117],
          [-114.7346, 45.4899],
          [-114.6635, 45.4712],
          [-114.6181, 45.5291],
          [-114.5883, 45.5378],
          [-114.558, 45.5652],
          [-114.5495, 45.5606],
          [-114.5261, 45.5708],
          [-114.4982, 45.5555],
          [-114.4605, 45.5613],
          [-114.4568, 45.544],
          [-114.4158, 45.5098],
          [-114.3685, 45.4927],
          [-114.3607, 45.4741],
          [-114.345, 45.4599],
          [-114.3332, 45.4593],
          [-114.2707, 45.4861],
          [-114.2478, 45.5243],
          [-114.2481, 45.5459],
          [-114.1928, 45.5366],
          [-114.18, 45.5514],
          [-114.1352, 45.5575],
          [-114.1223, 45.5843],
          [-114.0866, 45.5912],
          [-114.0676, 45.6277],
          [-114.015, 45.654],
          [-114.0193, 45.6929],
          [-113.9867, 45.7046],
          [-113.9342, 45.6822],
          [-113.9198, 45.6585],
          [-113.9006, 45.6483],
          [-113.9047, 45.622],
          [-113.886, 45.617],
          [-113.8614, 45.6237],
          [-113.8067, 45.6021],
          [-113.8033, 45.5842],
          [-113.8199, 45.5663],
          [-113.8346, 45.5207],
          [-113.766, 45.5206],
          [-113.76, 45.4807],
          [-113.7842, 45.4549],
          [-113.7646, 45.4314],
          [-113.7652, 45.4106],
          [-113.7331, 45.3902],
          [-113.7387, 45.3297],
          [-113.6894, 45.2835],
          [-113.6849, 45.2537],
          [-113.657, 45.2414],
          [-113.6369, 45.213],
          [-113.5995, 45.1911],
          [-113.5747, 45.1284],
          [-113.5547, 45.1129],
          [-113.5133, 45.1152],
          [-113.5066, 45.1073],
          [-113.5201, 45.093],
          [-113.4853, 45.0635],
          [-113.452, 45.0592],
          [-113.4377, 45.007],
          [-113.4469, 44.9985],
          [-113.4438, 44.9599],
          [-113.4987, 44.9423],
          [-113.4551, 44.8654],
          [-113.4224, 44.8426],
          [-113.3772, 44.8349],
          [-113.3461, 44.8006],
          [-113.354, 44.7917],
          [-113.3417, 44.7849],
          [-113.2387, 44.8141],
          [-113.1944, 44.8022],
          [-113.1638, 44.7789],
          [-113.1315, 44.7728],
          [-113.1348, 44.7528],
          [-113.1021, 44.729],
          [-113.0981, 44.6975],
          [-113.0678, 44.6795],
          [-113.0683, 44.6564],
          [-113.0493, 44.6294],
          [-113.0838, 44.6022],
          [-113.0424, 44.5652],
          [-113.0428, 44.5468],
          [-113.0198, 44.5285],
          [-113.0209, 44.4938],
          [-113.0035, 44.4508],
          [-112.9511, 44.4167],
          [-112.886, 44.3959],
          [-112.8818, 44.3803],
          [-112.8554, 44.36],
          [-112.8449, 44.3582],
          [-112.8132, 44.3781],
          [-112.8126, 44.3923],
          [-112.836, 44.4227],
          [-112.8282, 44.4425],
          [-112.7813, 44.4849],
          [-112.7191, 44.5043],
          [-112.6607, 44.4858],
          [-112.6019, 44.491],
          [-112.5842, 44.4814],
          [-112.542, 44.484],
          [-112.5003, 44.4631],
          [-112.4732, 44.48],
          [-112.3874, 44.4481],
          [-112.3589, 44.4863],
          [-112.3542, 44.5356],
          [-112.3192, 44.5391],
          [-112.3129, 44.5535],
          [-112.2862, 44.5685],
          [-112.2428, 44.5681],
          [-112.2301, 44.5628],
          [-112.2217, 44.5435],
          [-112.1839, 44.5331],
          [-112.1646, 44.5417],
          [-112.1365, 44.5399],
          [-112.1068, 44.5208],
          [-112.069, 44.5371],
          [-112.0369, 44.5303],
          [-112.0327, 44.5466],
          [-111.9808, 44.5367],
          [-111.9479, 44.5568],
          [-111.8705, 44.564],
          [-111.8215, 44.5093],
          [-111.7464, 44.5408],
          [-111.7155, 44.5435],
          [-111.7042, 44.5602],
          [-111.6144, 44.549],
          [-111.5858, 44.5628],
          [-111.5008, 44.5401],
          [-111.4717, 44.5408],
          [-111.4692, 44.552],
          [-111.5191, 44.5829],
          [-111.5258, 44.6049],
          [-111.4732, 44.6655],
          [-111.4688, 44.6793],
          [-111.4849, 44.6876],
          [-111.4893, 44.7049],
          [-111.4388, 44.7205],
          [-111.4143, 44.7107],
          [-111.3986, 44.7233],
          [-111.3978, 44.7467],
          [-111.385, 44.7551],
          [-111.3558, 44.7276],
          [-111.3237, 44.7245],
          [-111.2963, 44.7023],
          [-111.2687, 44.6683],
          [-111.2527, 44.6511],
          [-111.2242, 44.6234],
          [-111.2302, 44.587],
          [-111.1896, 44.5711],
          [-111.1757, 44.5522],
          [-111.1314, 44.4999],
          [-111.049, 44.4741],
          [-111.0441, 43.02],
          [-111.2525, 43.0206],
          [-111.2615, 43.0071],
          [-111.2878, 42.9878],
          [-111.2564, 42.9705],
          [-111.2774, 42.9653],
          [-111.2869, 42.9355],
          [-111.2572, 42.9277],
          [-111.2449, 42.8986],
          [-111.2225, 42.8786],
          [-111.2202, 42.8588],
          [-111.1803, 42.8341],
          [-111.1395, 42.846],
          [-111.125, 42.8424],
          [-111.1065, 42.834],
          [-111.0706, 42.785],
          [-111.0438, 42.7867],
          [-111.0467, 42.0016],
          [-112.1733, 41.9966],
          [-112.193, 42.0012],
          [-113.8933, 41.9881],
          [-114.5983, 41.9945],
          [-114.8311, 42.0022],
          [-115.0318, 41.996],
          [-116.3685, 41.9963],
          [-118.1972, 41.997],
          [-118.1971, 42.1249],
        ],
        [
          [-116.3048, 45.1089],
          [-116.2373, 45.1082],
          [-116.1747, 45.1492],
          [-116.1459, 45.1908],
          [-116.1819, 45.2279],
          [-116.3008, 45.1837],
          [-116.2821, 45.1349],
          [-116.3048, 45.1089],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Pendleton Mkt": {
    type: "Feature",
    properties: { code: "OR_PEN", name: "Pendleton Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-117.7384, 46.6952],
            [-117.7422, 46.6935],
            [-117.7823, 46.6397],
            [-117.8157, 46.6743],
            [-117.7532, 46.7363],
            [-117.7384, 46.6952],
          ],
        ],
        [
          [
            [-117.8655, 46.6212],
            [-117.8667, 46.6207],
            [-117.8733, 46.6179],
            [-117.8703, 46.6221],
            [-117.8655, 46.6212],
          ],
        ],
        [
          [
            [-117.8817, 46.606],
            [-117.8885, 46.5931],
            [-117.8906, 46.5935],
            [-117.8817, 46.606],
          ],
        ],
        [
          [
            [-121.392, 47.3819],
            [-121.3869, 47.3915],
            [-121.3716, 47.3679],
            [-121.3807, 47.3895],
            [-121.3488, 47.4459],
            [-121.3022, 47.4864],
            [-121.2832, 47.519],
            [-121.2425, 47.5359],
            [-121.2281, 47.5658],
            [-121.1804, 47.5648],
            [-121.1708, 47.5899],
            [-121.1383, 47.5955],
            [-121.1201, 47.5912],
            [-121.1158, 47.5977],
            [-121.092, 47.5852],
            [-121.0751, 47.5509],
            [-120.9308, 47.4579],
            [-120.9161, 47.4285],
            [-120.7331, 47.3934],
            [-120.7211, 47.37],
            [-120.6651, 47.3433],
            [-120.5787, 47.3371],
            [-120.5168, 47.3211],
            [-120.389, 47.2608],
            [-120.0945, 47.2622],
            [-120.0759, 47.2258],
            [-120.0126, 47.2246],
            [-120.0015, 47.1276],
            [-120.0428, 47.0716],
            [-120.0078, 47.0349],
            [-119.9866, 46.9718],
            [-119.9465, 46.9718],
            [-119.8772, 47.0047],
            [-119.8365, 46.9697],
            [-119.7487, 46.9718],
            [-119.6421, 47.0302],
            [-119.5569, 47.0306],
            [-119.5576, 46.9731],
            [-119.5215, 46.9615],
            [-119.4008, 46.9817],
            [-119.2573, 46.9813],
            [-119.2039, 46.9123],
            [-118.9825, 46.9113],
            [-118.9821, 46.9839],
            [-118.9404, 46.9842],
            [-118.9399, 47.028],
            [-118.8545, 47.028],
            [-118.8224, 47.0664],
            [-118.758, 47.0766],
            [-118.7583, 47.0578],
            [-118.6632, 47.058],
            [-118.6204, 47.0726],
            [-118.5341, 47.0726],
            [-118.5243, 47.0065],
            [-118.566, 46.9695],
            [-118.461, 46.9716],
            [-118.4506, 46.9424],
            [-118.3933, 46.9568],
            [-118.3967, 46.9133],
            [-118.2185, 46.9116],
            [-118.1726, 46.8853],
            [-118.1682, 46.8546],
            [-118.1256, 46.835],
            [-118.1247, 46.7947],
            [-118.1621, 46.7469],
            [-118.2493, 46.7335],
            [-118.2176, 46.6792],
            [-118.2309, 46.6567],
            [-118.1982, 46.6215],
            [-118.2157, 46.5889],
            [-118.1726, 46.5569],
            [-118.0929, 46.5745],
            [-117.9535, 46.5921],
            [-117.8876, 46.5863],
            [-117.8667, 46.6207],
            [-117.8655, 46.6212],
            [-117.7809, 46.6377],
            [-117.7422, 46.6935],
            [-117.7384, 46.6952],
            [-117.654, 46.7012],
            [-117.6012, 46.6727],
            [-117.512, 46.6782],
            [-117.4877, 46.6963],
            [-117.4309, 46.6585],
            [-117.3859, 46.6496],
            [-117.3859, 46.6186],
            [-117.3459, 46.5855],
            [-117.3057, 46.5792],
            [-117.2539, 46.5451],
            [-117.2397, 46.4763],
            [-117.2288, 46.4113],
            [-117.3561, 46.4122],
            [-117.3994, 46.3838],
            [-117.4197, 46.383],
            [-117.4196, 46.1219],
            [-117.48, 46.122],
            [-117.4801, 45.9979],
            [-116.916, 45.9954],
            [-116.8929, 45.9744],
            [-116.8598, 45.9073],
            [-116.796, 45.8585],
            [-116.7827, 45.8254],
            [-116.6972, 45.8201],
            [-116.6653, 45.782],
            [-116.593, 45.7785],
            [-116.5466, 45.751],
            [-116.5354, 45.6917],
            [-116.4635, 45.6158],
            [-116.4819, 45.5779],
            [-116.5236, 45.5466],
            [-116.5535, 45.4991],
            [-116.5548, 45.4629],
            [-116.5882, 45.4429],
            [-116.6738, 45.3215],
            [-116.6745, 45.2763],
            [-116.7036, 45.2398],
            [-116.7296, 45.1421],
            [-116.7748, 45.1055],
            [-116.7973, 45.0603],
            [-116.8479, 45.0226],
            [-116.8583, 44.9788],
            [-116.8322, 44.9314],
            [-116.857, 44.8808],
            [-116.9311, 44.8048],
            [-116.9347, 44.7839],
            [-117.0442, 44.7451],
            [-117.0618, 44.7067],
            [-117.1125, 44.631],
            [-117.1061, 44.6885],
            [-117.0854, 44.7111],
            [-117.0896, 44.732],
            [-117.1272, 44.7477],
            [-117.1337, 44.7438],
            [-117.1282, 44.6885],
            [-117.2255, 44.6883],
            [-117.2069, 44.6985],
            [-117.1862, 44.7469],
            [-117.2196, 44.7557],
            [-117.2465, 44.7679],
            [-117.2587, 44.7709],
            [-117.3228, 44.7683],
            [-117.3821, 44.8051],
            [-117.3819, 44.7654],
            [-117.4264, 44.769],
            [-117.4475, 44.7557],
            [-117.5056, 44.7597],
            [-117.544, 44.7467],
            [-117.5761, 44.712],
            [-117.5236, 44.6831],
            [-117.5159, 44.6281],
            [-117.6581, 44.6792],
            [-117.6326, 44.6252],
            [-117.7201, 44.6179],
            [-117.7693, 44.5408],
            [-117.7891, 44.535],
            [-117.7815, 44.5175],
            [-117.706, 44.4927],
            [-117.6471, 44.5329],
            [-117.5775, 44.5309],
            [-117.5706, 44.5048],
            [-117.596, 44.4759],
            [-117.5777, 44.4302],
            [-117.591, 44.4445],
            [-117.9716, 44.4433],
            [-118.0314, 44.4152],
            [-118.0317, 44.3899],
            [-118.0869, 44.3575],
            [-118.0922, 44.3287],
            [-118.1523, 44.2996],
            [-118.1526, 44.2707],
            [-118.2321, 44.2561],
            [-118.2274, 44.04],
            [-118.5867, 44.0401],
            [-118.5866, 44.0471],
            [-118.8163, 44.0477],
            [-118.8166, 43.96],
            [-119.6576, 43.9591],
            [-119.6544, 44.5919],
            [-119.6923, 44.6052],
            [-119.7167, 44.5949],
            [-119.7212, 44.5855],
            [-119.8048, 44.6032],
            [-119.7528, 44.6198],
            [-119.7498, 44.6375],
            [-119.7724, 44.6434],
            [-119.7809, 44.6614],
            [-119.8084, 44.6649],
            [-119.7905, 44.6866],
            [-119.7889, 44.7128],
            [-119.7956, 44.7266],
            [-119.8275, 44.7395],
            [-119.8703, 44.6368],
            [-119.915, 44.5955],
            [-119.9365, 44.5971],
            [-119.9614, 44.6166],
            [-120.0185, 44.6253],
            [-120.0508, 44.6229],
            [-120.0753, 44.639],
            [-120.0969, 44.6387],
            [-120.1221, 44.6692],
            [-120.0647, 44.6832],
            [-120.0381, 44.7218],
            [-120.002, 44.7391],
            [-119.9902, 44.7594],
            [-119.9955, 44.7829],
            [-120.0055, 44.7893],
            [-120.0005, 44.7956],
            [-120.0196, 44.8046],
            [-120.043, 44.844],
            [-120.0486, 44.8775],
            [-120.059, 44.864],
            [-120.0923, 44.8579],
            [-120.1053, 44.846],
            [-120.1223, 44.8603],
            [-120.108, 44.8812],
            [-120.1122, 44.8892],
            [-120.1237, 44.8892],
            [-120.1471, 44.8668],
            [-120.1505, 44.8266],
            [-120.2035, 44.7793],
            [-120.205, 44.7528],
            [-120.2446, 44.7509],
            [-120.2658, 44.7379],
            [-120.2796, 44.7432],
            [-120.2972, 44.7355],
            [-120.3584, 44.7408],
            [-120.3824, 44.7528],
            [-120.3767, 44.7612],
            [-120.3879, 44.7624],
            [-120.4047, 44.7948],
            [-120.3953, 44.8042],
            [-120.3852, 44.7965],
            [-120.379, 44.806],
            [-120.3861, 44.8112],
            [-120.3722, 44.8228],
            [-120.4005, 44.8302],
            [-120.4126, 44.8522],
            [-120.4552, 44.8656],
            [-120.4588, 44.8812],
            [-120.4365, 44.8948],
            [-120.4508, 44.9095],
            [-120.4583, 44.9022],
            [-120.474, 44.9086],
            [-120.4727, 44.9353],
            [-120.4877, 44.953],
            [-120.4766, 44.9679],
            [-120.4876, 45.0023],
            [-120.489, 45.0279],
            [-120.4824, 45.0558],
            [-120.5149, 45.0946],
            [-120.4769, 45.1116],
            [-120.4632, 45.1003],
            [-120.466, 45.1296],
            [-120.4489, 45.1432],
            [-120.4843, 45.1711],
            [-120.4727, 45.1776],
            [-120.5416, 45.2101],
            [-120.5403, 45.217],
            [-120.5239, 45.2214],
            [-120.5449, 45.2224],
            [-120.5417, 45.232],
            [-120.5561, 45.2511],
            [-120.5471, 45.2629],
            [-120.5416, 45.2788],
            [-120.5556, 45.2812],
            [-120.5426, 45.2864],
            [-120.5445, 45.3035],
            [-120.5347, 45.295],
            [-120.5409, 45.3119],
            [-120.5275, 45.325],
            [-120.5408, 45.3283],
            [-120.5305, 45.3392],
            [-120.5434, 45.346],
            [-120.5336, 45.353],
            [-120.5345, 45.3702],
            [-120.5126, 45.3786],
            [-120.5326, 45.3815],
            [-120.5185, 45.3946],
            [-120.5276, 45.4034],
            [-120.509, 45.4052],
            [-120.5012, 45.4187],
            [-120.483, 45.4205],
            [-120.5062, 45.4285],
            [-120.4835, 45.436],
            [-120.4969, 45.4469],
            [-120.4821, 45.4589],
            [-120.4961, 45.4651],
            [-120.4545, 45.4852],
            [-120.4255, 45.4682],
            [-120.41, 45.4704],
            [-120.3665, 45.496],
            [-120.3604, 45.5093],
            [-120.3805, 45.5216],
            [-120.3813, 45.5384],
            [-120.405, 45.5556],
            [-120.4099, 45.5941],
            [-120.4444, 45.6081],
            [-120.4402, 45.6258],
            [-120.4681, 45.6194],
            [-120.4862, 45.6361],
            [-120.4797, 45.645],
            [-120.4917, 45.6435],
            [-120.4887, 45.655],
            [-120.5298, 45.6717],
            [-120.5461, 45.669],
            [-120.5767, 45.6983],
            [-120.602, 45.7025],
            [-120.6186, 45.7214],
            [-120.6437, 45.7223],
            [-120.6536, 45.7372],
            [-120.5595, 45.7383],
            [-120.5059, 45.7],
            [-120.4608, 45.7024],
            [-120.2357, 45.7276],
            [-120.2098, 45.7406],
            [-120.3176, 45.7204],
            [-120.3276, 45.7279],
            [-120.3779, 45.7141],
            [-120.408, 45.7166],
            [-120.4058, 45.7498],
            [-120.4153, 45.7711],
            [-120.4444, 45.7785],
            [-120.4641, 45.7965],
            [-120.5075, 45.8051],
            [-120.5185, 45.8136],
            [-120.5059, 45.8381],
            [-120.5193, 45.8456],
            [-120.5136, 45.8616],
            [-120.5238, 45.8826],
            [-120.5123, 45.8979],
            [-120.4961, 45.9028],
            [-120.4927, 45.9165],
            [-120.45, 45.9361],
            [-120.4377, 45.9649],
            [-120.43, 45.9582],
            [-120.412, 45.9678],
            [-120.4291, 45.9753],
            [-120.4392, 45.9916],
            [-120.4247, 46.0104],
            [-120.4577, 46.0228],
            [-120.4523, 46.0415],
            [-121.5239, 46.044],
            [-121.5236, 46.3882],
            [-121.3939, 46.3898],
            [-121.3866, 46.4106],
            [-121.4073, 46.4299],
            [-121.412, 46.4595],
            [-121.4366, 46.4782],
            [-121.4232, 46.4927],
            [-121.4487, 46.5026],
            [-121.4558, 46.5147],
            [-121.4509, 46.5339],
            [-121.4167, 46.5468],
            [-121.4129, 46.5585],
            [-121.4243, 46.5702],
            [-121.4067, 46.5814],
            [-121.4078, 46.6022],
            [-121.3877, 46.6178],
            [-121.3947, 46.6457],
            [-121.4161, 46.6517],
            [-121.4136, 46.6753],
            [-121.4009, 46.6861],
            [-121.3762, 46.6859],
            [-121.3795, 46.7025],
            [-121.3523, 46.7124],
            [-121.3681, 46.7261],
            [-121.4296, 46.7395],
            [-121.4251, 46.7578],
            [-121.4555, 46.7807],
            [-121.4437, 46.7945],
            [-121.4709, 46.818],
            [-121.4954, 46.8629],
            [-121.5233, 46.8733],
            [-121.4966, 46.9088],
            [-121.4565, 46.9227],
            [-121.4493, 46.9344],
            [-121.4566, 46.9645],
            [-121.4419, 46.973],
            [-121.4454, 46.98],
            [-121.4072, 46.9983],
            [-121.3971, 47.0359],
            [-121.3735, 47.0569],
            [-121.3836, 47.083],
            [-121.3769, 47.0902],
            [-121.4029, 47.0978],
            [-121.4076, 47.1191],
            [-121.3939, 47.133],
            [-121.3647, 47.1418],
            [-121.315, 47.1345],
            [-121.298, 47.1482],
            [-121.318, 47.1735],
            [-121.306, 47.1877],
            [-121.3093, 47.2044],
            [-121.331, 47.2178],
            [-121.365, 47.2251],
            [-121.3591, 47.2402],
            [-121.3323, 47.2556],
            [-121.3411, 47.2816],
            [-121.362, 47.2917],
            [-121.3729, 47.2852],
            [-121.4157, 47.2934],
            [-121.4269, 47.2885],
            [-121.4336, 47.305],
            [-121.4455, 47.3096],
            [-121.4309, 47.3344],
            [-121.4638, 47.3536],
            [-121.4662, 47.368],
            [-121.4421, 47.3731],
            [-121.4408, 47.385],
            [-121.3993, 47.3896],
            [-121.392, 47.3819],
          ],
          [
            [-121.392, 47.3819],
            [-121.3973, 47.3719],
            [-121.3915, 47.3814],
            [-121.392, 47.3819],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Spokane Mkt": {
    type: "Feature",
    properties: { code: "WA_SPO", name: "Spokane Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-116.3048, 45.1089],
            [-116.2821, 45.1349],
            [-116.3008, 45.1837],
            [-116.1819, 45.2279],
            [-116.1459, 45.1908],
            [-116.1747, 45.1492],
            [-116.2373, 45.1082],
            [-116.3048, 45.1089],
          ],
        ],
        [
          [
            [-117.7384, 46.6952],
            [-117.7532, 46.7363],
            [-117.8157, 46.6743],
            [-117.7823, 46.6397],
            [-117.7422, 46.6935],
            [-117.7809, 46.6377],
            [-117.8655, 46.6212],
            [-117.8703, 46.6221],
            [-117.8733, 46.6179],
            [-117.8667, 46.6207],
            [-117.8876, 46.5863],
            [-117.9535, 46.5921],
            [-118.0929, 46.5745],
            [-118.1726, 46.5569],
            [-118.2157, 46.5889],
            [-118.1982, 46.6215],
            [-118.2309, 46.6567],
            [-118.2176, 46.6792],
            [-118.2493, 46.7335],
            [-118.1621, 46.7469],
            [-118.1247, 46.7947],
            [-118.1256, 46.835],
            [-118.1682, 46.8546],
            [-118.1726, 46.8853],
            [-118.2185, 46.9116],
            [-118.3967, 46.9133],
            [-118.3933, 46.9568],
            [-118.4506, 46.9424],
            [-118.461, 46.9716],
            [-118.566, 46.9695],
            [-118.5243, 47.0065],
            [-118.5341, 47.0726],
            [-118.6204, 47.0726],
            [-118.6632, 47.058],
            [-118.7583, 47.0578],
            [-118.758, 47.0766],
            [-118.8224, 47.0664],
            [-118.8545, 47.028],
            [-118.9399, 47.028],
            [-118.9404, 46.9842],
            [-118.982, 46.9839],
            [-118.9825, 46.9113],
            [-119.2039, 46.9123],
            [-119.2573, 46.9813],
            [-119.4008, 46.9817],
            [-119.5215, 46.9615],
            [-119.5576, 46.9731],
            [-119.5569, 47.0306],
            [-119.6421, 47.0302],
            [-119.7487, 46.9718],
            [-119.8365, 46.9697],
            [-119.8772, 47.0047],
            [-119.9465, 46.9718],
            [-119.9866, 46.9718],
            [-120.0078, 47.0349],
            [-120.0428, 47.0716],
            [-120.0015, 47.1276],
            [-120.0126, 47.2246],
            [-120.0759, 47.2258],
            [-120.0945, 47.2622],
            [-120.389, 47.2608],
            [-120.5168, 47.3211],
            [-120.5787, 47.3371],
            [-120.6651, 47.3433],
            [-120.7211, 47.37],
            [-120.7331, 47.3934],
            [-120.9161, 47.4285],
            [-120.9308, 47.4579],
            [-121.0751, 47.5509],
            [-121.092, 47.5852],
            [-121.1112, 47.5994],
            [-121.1204, 47.6128],
            [-121.1139, 47.6216],
            [-121.1334, 47.6456],
            [-121.123, 47.6638],
            [-121.1275, 47.6768],
            [-121.09, 47.703],
            [-121.0671, 47.7062],
            [-121.0661, 47.7141],
            [-121.0839, 47.7233],
            [-121.0789, 47.7392],
            [-121.094, 47.7583],
            [-121.1103, 47.7581],
            [-121.1204, 47.7841],
            [-121.0936, 47.7997],
            [-121.0701, 47.8301],
            [-121.1074, 47.8288],
            [-121.1537, 47.8458],
            [-121.1525, 47.8699],
            [-121.1751, 47.8857],
            [-121.1801, 47.8992],
            [-121.1712, 47.9126],
            [-121.1795, 47.9188],
            [-121.1619, 47.9447],
            [-121.1646, 47.9562],
            [-121.1468, 47.9815],
            [-121.1172, 47.9998],
            [-121.1535, 48.0407],
            [-121.1315, 48.044],
            [-121.0945, 48.0646],
            [-121.0806, 48.0618],
            [-121.0314, 48.0782],
            [-121.015, 48.0752],
            [-120.9809, 48.0944],
            [-120.971, 48.1092],
            [-120.9464, 48.1134],
            [-120.9641, 48.1333],
            [-120.9545, 48.1525],
            [-120.9062, 48.1639],
            [-120.9231, 48.1914],
            [-120.9639, 48.211],
            [-120.9596, 48.2283],
            [-121.0116, 48.2804],
            [-121.0035, 48.2992],
            [-121.0715, 48.3179],
            [-121.0694, 48.3389],
            [-121.0406, 48.3481],
            [-121.0663, 48.393],
            [-121.056, 48.4124],
            [-121.0406, 48.4458],
            [-121.06, 48.4538],
            [-121.0567, 48.4773],
            [-121.0358, 48.4948],
            [-120.9506, 48.4999],
            [-120.9499, 48.53],
            [-120.9055, 48.5233],
            [-120.9041, 48.5333],
            [-120.8628, 48.53],
            [-120.8576, 48.5485],
            [-120.824, 48.5452],
            [-120.8094, 48.5296],
            [-120.7877, 48.5065],
            [-120.7086, 48.5237],
            [-120.7016, 48.5316],
            [-120.7089, 48.542],
            [-120.6854, 48.5661],
            [-120.6886, 48.5755],
            [-120.7189, 48.5968],
            [-120.7349, 48.5811],
            [-120.7601, 48.583],
            [-120.7839, 48.6022],
            [-120.7524, 48.6487],
            [-120.7501, 48.6593],
            [-120.7168, 48.6649],
            [-120.7119, 48.6893],
            [-120.7033, 48.6972],
            [-120.678, 48.6953],
            [-120.6779, 48.7181],
            [-120.6547, 48.7242],
            [-120.7352, 48.7857],
            [-120.7434, 48.82],
            [-120.7325, 48.8532],
            [-120.7374, 48.8642],
            [-120.77, 48.8813],
            [-120.7635, 48.9001],
            [-120.7718, 48.9265],
            [-120.7558, 48.9316],
            [-120.756, 48.9426],
            [-120.7676, 48.9544],
            [-120.7981, 48.9587],
            [-120.8308, 48.938],
            [-120.8497, 48.9559],
            [-120.8811, 48.9661],
            [-120.864, 48.9807],
            [-120.8514, 49.0003],
            [-120.0012, 48.9994],
            [-118.3648, 49.0004],
            [-117.1261, 48.9989],
            [-116.0492, 49.0009],
            [-116.0488, 47.9772],
            [-116.0308, 47.9733],
            [-115.9937, 47.9262],
            [-115.9691, 47.9143],
            [-115.9064, 47.8463],
            [-115.8815, 47.8497],
            [-115.8709, 47.8349],
            [-115.8523, 47.828],
            [-115.8475, 47.7852],
            [-115.8318, 47.7558],
            [-115.7973, 47.7575],
            [-115.7804, 47.7434],
            [-115.7762, 47.7198],
            [-115.7523, 47.7167],
            [-115.7238, 47.6967],
            [-115.7363, 47.6548],
            [-115.7299, 47.6424],
            [-115.6943, 47.6235],
            [-115.6894, 47.5954],
            [-115.7065, 47.5773],
            [-115.7347, 47.5674],
            [-115.7473, 47.5432],
            [-115.7103, 47.5295],
            [-115.7087, 47.5126],
            [-115.6867, 47.4856],
            [-115.653, 47.476],
            [-115.6639, 47.4569],
            [-115.7182, 47.4532],
            [-115.7288, 47.4452],
            [-115.7288, 47.4289],
            [-115.6577, 47.4007],
            [-115.6392, 47.3786],
            [-115.6172, 47.3825],
            [-115.5786, 47.367],
            [-115.5511, 47.3499],
            [-115.5487, 47.3322],
            [-115.5119, 47.2952],
            [-115.4571, 47.2778],
            [-115.4284, 47.2787],
            [-115.4107, 47.2642],
            [-115.3718, 47.2652],
            [-115.3269, 47.2559],
            [-115.3171, 47.2333],
            [-115.2948, 47.2209],
            [-115.3005, 47.1881],
            [-115.2619, 47.1817],
            [-115.2437, 47.1503],
            [-115.2005, 47.1392],
            [-115.1704, 47.1063],
            [-115.1404, 47.093],
            [-115.1209, 47.0612],
            [-115.1071, 47.049],
            [-115.0878, 47.0455],
            [-115.0495, 46.9708],
            [-115.0013, 46.9719],
            [-114.9606, 46.93],
            [-114.93, 46.9196],
            [-114.9316, 46.8768],
            [-114.9474, 46.8593],
            [-114.9286, 46.8548],
            [-114.9205, 46.8277],
            [-114.8881, 46.8086],
            [-114.8643, 46.8139],
            [-114.8291, 46.7825],
            [-114.79, 46.7787],
            [-114.7651, 46.7582],
            [-114.7672, 46.7388],
            [-114.7887, 46.714],
            [-114.7669, 46.6969],
            [-114.7519, 46.6972],
            [-114.7401, 46.7118],
            [-114.7135, 46.7151],
            [-114.6967, 46.7406],
            [-114.6494, 46.7329],
            [-114.6209, 46.7074],
            [-114.6232, 46.6915],
            [-114.6427, 46.6731],
            [-114.6357, 46.6594],
            [-114.6147, 46.6553],
            [-114.615, 46.6397],
            [-114.5933, 46.6328],
            [-114.5473, 46.6445],
            [-114.4669, 46.6317],
            [-114.4532, 46.6493],
            [-114.4244, 46.6606],
            [-114.4109, 46.6575],
            [-114.3607, 46.6691],
            [-114.3329, 46.6608],
            [-114.3207, 46.647],
            [-114.3317, 46.5719],
            [-114.3487, 46.5338],
            [-114.3421, 46.5197],
            [-114.3587, 46.5053],
            [-114.403, 46.4987],
            [-114.4001, 46.4772],
            [-114.3764, 46.443],
            [-114.3847, 46.4118],
            [-114.4225, 46.3871],
            [-114.4107, 46.3607],
            [-114.4138, 46.3359],
            [-114.4335, 46.3055],
            [-114.4256, 46.2879],
            [-114.4413, 46.2738],
            [-114.4705, 46.2673],
            [-114.4498, 46.2371],
            [-114.4459, 46.1739],
            [-114.4783, 46.1609],
            [-114.5147, 46.1677],
            [-114.5271, 46.1462],
            [-114.5213, 46.1253],
            [-114.4744, 46.1125],
            [-114.46, 46.0971],
            [-114.4685, 46.0625],
            [-114.4934, 46.0372],
            [-114.4738, 46.0166],
            [-114.4773, 46.0008],
            [-114.4412, 45.9885],
            [-114.4251, 45.9727],
            [-114.4209, 45.9492],
            [-114.4312, 45.9357],
            [-114.3951, 45.9015],
            [-114.3882, 45.8823],
            [-114.4095, 45.8516],
            [-114.4487, 45.8589],
            [-114.5093, 45.8455],
            [-114.513, 45.8288],
            [-114.5662, 45.7739],
            [-114.5356, 45.7391],
            [-114.5049, 45.7222],
            [-114.4954, 45.7033],
            [-114.5076, 45.6589],
            [-114.5636, 45.6374],
            [-114.5381, 45.6068],
            [-114.5582, 45.5851],
            [-114.558, 45.5651],
            [-114.5713, 45.55],
            [-114.6308, 45.5196],
            [-114.6635, 45.4712],
            [-114.7346, 45.4899],
            [-114.7742, 45.5134],
            [-114.7877, 45.5126],
            [-114.7979, 45.4966],
            [-114.7935, 45.4365],
            [-114.7166, 45.3957],
            [-114.6434, 45.3206],
            [-114.5933, 45.2974],
            [-114.6752, 45.2332],
            [-114.6941, 45.1971],
            [-115.4659, 45.1965],
            [-115.45, 45.2353],
            [-115.4022, 45.2436],
            [-115.396, 45.2921],
            [-115.3463, 45.3545],
            [-115.4283, 45.3523],
            [-115.4691, 45.4122],
            [-115.5113, 45.3756],
            [-115.6144, 45.3978],
            [-115.6668, 45.434],
            [-115.7531, 45.4445],
            [-115.7808, 45.4614],
            [-115.81, 45.4681],
            [-115.929, 45.46],
            [-115.9976, 45.4274],
            [-116.1224, 45.4025],
            [-116.1449, 45.4273],
            [-116.2099, 45.3989],
            [-116.271, 45.3867],
            [-116.3454, 45.3914],
            [-116.3612, 45.3589],
            [-116.2888, 45.3584],
            [-116.2539, 45.3001],
            [-116.3429, 45.268],
            [-116.687, 45.2679],
            [-116.6738, 45.3215],
            [-116.5882, 45.4429],
            [-116.5548, 45.4629],
            [-116.5487, 45.5104],
            [-116.5236, 45.5466],
            [-116.4819, 45.5779],
            [-116.4635, 45.6158],
            [-116.5354, 45.6917],
            [-116.5466, 45.751],
            [-116.593, 45.7785],
            [-116.6653, 45.782],
            [-116.6972, 45.8201],
            [-116.7827, 45.8254],
            [-116.7961, 45.8585],
            [-116.8573, 45.9042],
            [-116.8929, 45.9744],
            [-116.9163, 45.9954],
            [-117.4801, 45.9979],
            [-117.48, 46.122],
            [-117.4196, 46.1219],
            [-117.4194, 46.3833],
            [-117.3994, 46.3838],
            [-117.3561, 46.4122],
            [-117.2288, 46.4113],
            [-117.2397, 46.4763],
            [-117.2539, 46.5451],
            [-117.3057, 46.5792],
            [-117.3459, 46.5855],
            [-117.3859, 46.6186],
            [-117.3859, 46.6496],
            [-117.4309, 46.6585],
            [-117.4877, 46.6963],
            [-117.512, 46.6782],
            [-117.6012, 46.6727],
            [-117.654, 46.7012],
            [-117.7384, 46.6952],
          ],
          [
            [-117.8817, 46.606],
            [-117.8906, 46.5935],
            [-117.8885, 46.5931],
            [-117.8817, 46.606],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Duluth Mkt": {
    type: "Feature",
    properties: { code: "MN_DUL", name: "Duluth Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-89.1792, 47.935],
            [-88.8357, 48.0568],
            [-88.6756, 48.1204],
            [-88.6569, 48.1392],
            [-88.547, 48.1749],
            [-88.4597, 48.1586],
            [-88.5669, 48.0937],
            [-88.5798, 48.0587],
            [-88.6701, 48.0114],
            [-88.8321, 47.9652],
            [-88.8992, 47.9533],
            [-88.9627, 47.9235],
            [-88.9117, 47.8913],
            [-89.1577, 47.824],
            [-89.2552, 47.8761],
            [-89.1792, 47.935],
          ],
        ],
        [
          [
            [-90.4575, 47.0128],
            [-90.4491, 47.066],
            [-90.3954, 47.0772],
            [-90.4134, 47.0132],
            [-90.4575, 47.0128],
          ],
        ],
        [
          [
            [-90.6518, 46.9253],
            [-90.6345, 46.9429],
            [-90.5288, 46.9685],
            [-90.5491, 46.9155],
            [-90.6371, 46.9067],
            [-90.6518, 46.9253],
          ],
        ],
        [
          [
            [-90.733, 46.8002],
            [-90.622, 46.8729],
            [-90.57, 46.8497],
            [-90.6761, 46.819],
            [-90.7636, 46.7549],
            [-90.7902, 46.7861],
            [-90.733, 46.8002],
          ],
        ],
        [
          [
            [-92.728, 45.882],
            [-92.717, 45.8937],
            [-92.7196, 45.8895],
            [-92.728, 45.882],
          ],
        ],
        [
          [
            [-92.7598, 45.9394],
            [-92.7429, 45.9051],
            [-92.7306, 45.903],
            [-92.7336, 45.8771],
            [-92.728, 45.882],
            [-92.7358, 45.8737],
            [-92.7312, 45.9023],
            [-92.7438, 45.9048],
            [-92.7598, 45.9394],
          ],
        ],
        [
          [
            [-92.7701, 45.9424],
            [-92.7608, 45.9415],
            [-92.7598, 45.9394],
            [-92.7701, 45.9424],
          ],
        ],
        [
          [
            [-92.8149, 45.9556],
            [-92.7701, 45.9424],
            [-92.7971, 45.9451],
            [-92.8149, 45.9556],
          ],
        ],
        [
          [
            [-92.7726, 45.8229],
            [-92.7748, 45.8213],
            [-92.8083, 45.8185],
            [-92.7726, 45.8229],
          ],
        ],
        [
          [
            [-92.8203, 45.9588],
            [-92.8149, 45.9556],
            [-92.8183, 45.9566],
            [-92.8203, 45.9588],
          ],
        ],
        [
          [
            [-92.8526, 46.0935],
            [-92.8504, 46.0924],
            [-92.8514, 46.0924],
            [-92.8526, 46.0935],
          ],
        ],
        [
          [
            [-92.8636, 46.1043],
            [-92.8526, 46.0935],
            [-92.8654, 46.1001],
            [-92.8636, 46.1043],
          ],
        ],
        [
          [
            [-93.0391, 45.8657],
            [-93.0423, 45.8214],
            [-93.0473, 45.8616],
            [-93.0391, 45.8657],
          ],
        ],
        [
          [
            [-93.0559, 45.8131],
            [-93.0792, 45.7908],
            [-93.0787, 45.7919],
            [-93.0559, 45.8131],
          ],
        ],
        [
          [
            [-93.566, 46.6798],
            [-93.5548, 46.688],
            [-93.5215, 46.6956],
            [-93.4971, 46.6967],
            [-93.4871, 46.7082],
            [-93.4976, 46.696],
            [-93.5127, 46.691],
            [-93.5211, 46.6952],
            [-93.5349, 46.6848],
            [-93.5548, 46.6874],
            [-93.566, 46.6798],
          ],
        ],
        [
          [
            [-93.6008, 46.6701],
            [-93.5925, 46.6677],
            [-93.5734, 46.6834],
            [-93.5923, 46.6671],
            [-93.6008, 46.6701],
          ],
        ],
        [
          [
            [-93.6008, 46.6701],
            [-93.6058, 46.6715],
            [-93.6056, 46.6718],
            [-93.6008, 46.6701],
          ],
        ],
        [
          [
            [-93.6121, 46.6627],
            [-93.5987, 46.6653],
            [-93.5995, 46.6646],
            [-93.6122, 46.6625],
            [-93.6121, 46.6627],
          ],
        ],
        [
          [
            [-93.6058, 46.6715],
            [-93.6121, 46.6627],
            [-93.6134, 46.6624],
            [-93.6068, 46.6718],
            [-93.6058, 46.6715],
          ],
        ],
        [
          [
            [-93.5995, 46.6646],
            [-93.598, 46.6649],
            [-93.6325, 46.635],
            [-93.6262, 46.6246],
            [-93.6272, 46.6249],
            [-93.633, 46.6358],
            [-93.5995, 46.6646],
          ],
        ],
        [
          [
            [-93.6434, 46.6253],
            [-93.6405, 46.6279],
            [-93.6272, 46.6249],
            [-93.6271, 46.6246],
            [-93.6434, 46.6253],
          ],
        ],
        [
          [
            [-93.6437, 46.6061],
            [-93.6448, 46.6192],
            [-93.6392, 46.609],
            [-93.6437, 46.6061],
          ],
        ],
        [
          [
            [-93.6434, 46.6253],
            [-93.6452, 46.6236],
            [-93.6454, 46.6254],
            [-93.6434, 46.6253],
          ],
        ],
        [
          [
            [-93.6452, 46.6236],
            [-93.6448, 46.6192],
            [-93.6466, 46.6224],
            [-93.6452, 46.6236],
          ],
        ],
        [
          [
            [-93.6437, 46.6061],
            [-93.6432, 46.6003],
            [-93.6458, 46.6046],
            [-93.6437, 46.6061],
          ],
        ],
        [
          [
            [-93.6422, 46.5893],
            [-93.6432, 46.6003],
            [-93.64, 46.595],
            [-93.5802, 46.6021],
            [-93.5596, 46.595],
            [-93.5802, 46.5492],
            [-93.6654, 46.5493],
            [-93.6613, 46.562],
            [-93.661, 46.5767],
            [-93.6452, 46.5807],
            [-93.663, 46.5897],
            [-93.6422, 46.5893],
          ],
          [
            [-93.6422, 46.5893],
            [-93.6422, 46.5891],
            [-93.6354, 46.5892],
            [-93.6422, 46.5893],
          ],
        ],
        [
          [
            [-92.7726, 45.8229],
            [-92.7401, 45.847],
            [-92.7381, 45.8592],
            [-92.7196, 45.8895],
            [-92.7057, 45.9018],
            [-92.6597, 45.9237],
            [-92.5528, 45.9522],
            [-92.532, 45.9821],
            [-92.4687, 45.9746],
            [-92.434, 46.0227],
            [-92.4146, 46.0284],
            [-92.3549, 46.0147],
            [-92.3343, 46.0625],
            [-92.294, 46.0744],
            [-92.2932, 46.2878],
            [-92.3132, 46.2582],
            [-92.3727, 46.2577],
            [-92.4074, 46.186],
            [-92.5923, 46.1865],
            [-92.6338, 46.1428],
            [-92.6976, 46.1576],
            [-92.7407, 46.1577],
            [-92.7418, 46.1286],
            [-92.8244, 46.1286],
            [-92.8662, 46.1798],
            [-92.8971, 46.1509],
            [-93.0326, 46.1508],
            [-93.0437, 46.1625],
            [-93.233, 46.1588],
            [-93.2202, 46.1669],
            [-93.2402, 46.1951],
            [-93.2407, 46.2224],
            [-93.2626, 46.2385],
            [-93.2615, 46.2736],
            [-93.2029, 46.2818],
            [-93.1994, 46.3098],
            [-93.1548, 46.3178],
            [-93.135, 46.3324],
            [-93.0538, 46.3321],
            [-93.0541, 46.3903],
            [-93.0966, 46.4191],
            [-93.1403, 46.4193],
            [-93.1711, 46.4051],
            [-93.1837, 46.3835],
            [-93.1991, 46.3834],
            [-93.2618, 46.3904],
            [-93.3106, 46.3623],
            [-93.3161, 46.4105],
            [-93.2694, 46.4444],
            [-93.2829, 46.4531],
            [-93.3059, 46.4524],
            [-93.3059, 46.4638],
            [-93.275, 46.4797],
            [-93.2894, 46.4997],
            [-93.2914, 46.5178],
            [-93.3197, 46.5317],
            [-93.3138, 46.5385],
            [-93.3662, 46.5386],
            [-93.3676, 46.5643],
            [-93.4238, 46.5506],
            [-93.4669, 46.5594],
            [-93.3955, 46.6065],
            [-93.4223, 46.6088],
            [-93.3971, 46.6237],
            [-93.4047, 46.6402],
            [-93.3482, 46.6666],
            [-93.3592, 46.7097],
            [-93.4015, 46.7215],
            [-93.4319, 46.7178],
            [-93.44, 46.7273],
            [-93.4319, 46.7353],
            [-93.4202, 46.7325],
            [-93.4224, 46.7415],
            [-93.4071, 46.7372],
            [-93.403, 46.7466],
            [-93.4218, 46.7419],
            [-93.4423, 46.7535],
            [-93.4481, 46.7729],
            [-93.4338, 46.7916],
            [-93.4424, 46.7963],
            [-93.4429, 46.7895],
            [-93.4848, 46.7894],
            [-93.4855, 46.7535],
            [-93.5275, 46.7547],
            [-93.5314, 46.7802],
            [-93.5711, 46.7866],
            [-93.5713, 46.8265],
            [-93.6142, 46.8261],
            [-93.614, 46.8815],
            [-93.6304, 46.8746],
            [-93.6779, 46.8754],
            [-93.6756, 46.7541],
            [-93.7072, 46.7441],
            [-93.6773, 46.7471],
            [-93.6737, 46.7396],
            [-93.7033, 46.7255],
            [-93.7665, 46.7224],
            [-93.7773, 46.7248],
            [-93.7749, 46.9282],
            [-93.7741, 47.1857],
            [-93.7562, 47.1924],
            [-93.7829, 47.2613],
            [-93.7911, 47.2671],
            [-93.7763, 47.2781],
            [-93.789, 47.2914],
            [-93.774, 47.305],
            [-93.7739, 47.2983],
            [-93.7455, 47.3129],
            [-93.702, 47.3183],
            [-93.7234, 47.3258],
            [-93.7233, 47.3393],
            [-93.7557, 47.3407],
            [-93.7555, 47.3714],
            [-93.7199, 47.3714],
            [-93.7093, 47.3787],
            [-93.7119, 47.3666],
            [-93.694, 47.3583],
            [-93.6898, 47.3677],
            [-93.6729, 47.36],
            [-93.6697, 47.3664],
            [-93.6404, 47.3647],
            [-93.6165, 47.3998],
            [-93.5783, 47.4006],
            [-93.5769, 47.5335],
            [-93.5647, 47.5362],
            [-93.5593, 47.5504],
            [-93.5439, 47.5495],
            [-93.5373, 47.5369],
            [-93.5417, 47.5465],
            [-93.5287, 47.5601],
            [-93.5404, 47.5672],
            [-93.5257, 47.5707],
            [-93.5279, 47.5818],
            [-93.5059, 47.6023],
            [-93.5048, 47.5964],
            [-93.4634, 47.6083],
            [-93.4357, 47.6064],
            [-93.3724, 47.5661],
            [-93.3683, 47.5801],
            [-93.3075, 47.5664],
            [-93.3053, 47.5852],
            [-93.2687, 47.5756],
            [-93.2221, 47.6112],
            [-93.1927, 47.5972],
            [-93.1953, 47.659],
            [-93.2082, 47.6563],
            [-93.2422, 47.6735],
            [-93.2676, 47.6628],
            [-93.259, 47.6699],
            [-93.275, 47.6615],
            [-93.3065, 47.6521],
            [-93.338, 47.6564],
            [-93.3504, 47.6719],
            [-93.3471, 47.6823],
            [-93.324, 47.6802],
            [-93.3028, 47.6914],
            [-93.3066, 47.7146],
            [-93.3558, 47.7099],
            [-93.3284, 47.7652],
            [-93.2633, 47.7572],
            [-93.2644, 47.7676],
            [-93.3056, 47.796],
            [-93.2467, 47.8106],
            [-93.2598, 47.8215],
            [-93.2869, 47.8438],
            [-93.2813, 47.8568],
            [-93.3127, 47.869],
            [-93.3154, 47.8798],
            [-93.2999, 47.8804],
            [-93.2986, 47.893],
            [-93.4431, 47.8946],
            [-93.4189, 47.9303],
            [-93.4201, 47.9587],
            [-93.4456, 47.9596],
            [-93.4481, 47.9693],
            [-93.4755, 47.986],
            [-93.5041, 47.9853],
            [-93.5347, 47.9954],
            [-93.5316, 48.0196],
            [-93.5574, 48.0249],
            [-93.5936, 48.021],
            [-93.593, 48.1203],
            [-93.4824, 48.1094],
            [-93.4175, 48.0661],
            [-93.368, 48.063],
            [-93.3628, 48.0554],
            [-93.3359, 48.0802],
            [-93.3374, 48.1511],
            [-93.3117, 48.1518],
            [-93.2788, 48.1675],
            [-93.2099, 48.1654],
            [-93.2002, 48.1418],
            [-93.1033, 48.1653],
            [-93.0937, 48.3995],
            [-93.0487, 48.3927],
            [-93.0408, 48.3994],
            [-93.0541, 48.4083],
            [-93.0487, 48.4213],
            [-93.0378, 48.4211],
            [-93.0291, 48.4473],
            [-93.057, 48.4516],
            [-93.0489, 48.4628],
            [-93.0666, 48.4748],
            [-93.0909, 48.4896],
            [-93.0884, 48.6276],
            [-92.985, 48.6237],
            [-92.9498, 48.6083],
            [-92.7281, 48.5393],
            [-92.6349, 48.5429],
            [-92.6272, 48.5034],
            [-92.6988, 48.4949],
            [-92.7126, 48.463],
            [-92.656, 48.4367],
            [-92.5073, 48.4479],
            [-92.4563, 48.4142],
            [-92.4768, 48.3718],
            [-92.47, 48.3518],
            [-92.4163, 48.2955],
            [-92.3692, 48.2203],
            [-92.3368, 48.2354],
            [-92.2697, 48.2482],
            [-92.2945, 48.2716],
            [-92.3063, 48.3164],
            [-92.289, 48.343],
            [-92.2623, 48.3549],
            [-92.217, 48.3451],
            [-92.1451, 48.3657],
            [-92.1436, 48.3561],
            [-92.0552, 48.3592],
            [-92.0001, 48.3214],
            [-92.0066, 48.2654],
            [-91.8935, 48.2377],
            [-91.8644, 48.207],
            [-91.8158, 48.2117],
            [-91.789, 48.1966],
            [-91.7566, 48.205],
            [-91.7491, 48.1988],
            [-91.7423, 48.2045],
            [-91.7149, 48.1991],
            [-91.7246, 48.1707],
            [-91.7053, 48.1708],
            [-91.7085, 48.1527],
            [-91.6982, 48.1416],
            [-91.712, 48.1147],
            [-91.6402, 48.0969],
            [-91.5593, 48.1083],
            [-91.553, 48.103],
            [-91.5697, 48.0933],
            [-91.5755, 48.0663],
            [-91.5673, 48.0437],
            [-91.4886, 48.0681],
            [-91.4503, 48.0688],
            [-91.4296, 48.0486],
            [-91.3911, 48.0571],
            [-91.3709, 48.0694],
            [-91.2664, 48.0787],
            [-91.2144, 48.1029],
            [-91.1762, 48.1258],
            [-91.1386, 48.1558],
            [-91.0827, 48.1808],
            [-91.0242, 48.1901],
            [-90.977, 48.2195],
            [-90.8751, 48.2378],
            [-90.8474, 48.2444],
            [-90.8392, 48.2395],
            [-90.8363, 48.177],
            [-90.7516, 48.091],
            [-90.6416, 48.1035],
            [-90.5799, 48.1239],
            [-90.5593, 48.1217],
            [-90.5698, 48.107],
            [-90.5568, 48.096],
            [-90.4384, 48.0987],
            [-90.3745, 48.0909],
            [-90.3124, 48.1053],
            [-90.2893, 48.099],
            [-90.1438, 48.1126],
            [-90.0236, 48.0847],
            [-90.0151, 48.0672],
            [-89.9979, 48.0576],
            [-89.993, 48.0284],
            [-89.9345, 48.0156],
            [-89.8974, 47.9876],
            [-89.8712, 47.9859],
            [-89.8318, 47.9994],
            [-89.8198, 48.0151],
            [-89.764, 48.023],
            [-89.7232, 48.0205],
            [-89.7171, 48.0172],
            [-89.7159, 48.0092],
            [-89.7025, 48.0063],
            [-89.6738, 48.0115],
            [-89.6511, 48.0036],
            [-89.6078, 48.0066],
            [-89.5415, 47.9928],
            [-89.5723, 47.9672],
            [-89.6246, 47.9832],
            [-89.6395, 47.9536],
            [-89.6976, 47.9413],
            [-89.7935, 47.8914],
            [-89.9236, 47.8621],
            [-89.9743, 47.8305],
            [-90.2488, 47.7728],
            [-90.3327, 47.7464],
            [-90.4377, 47.7316],
            [-90.7359, 47.6243],
            [-90.8683, 47.5569],
            [-90.9194, 47.5198],
            [-91.0456, 47.4565],
            [-91.1281, 47.3996],
            [-91.2625, 47.2793],
            [-91.4774, 47.1257],
            [-91.5738, 47.0899],
            [-91.6446, 47.0265],
            [-91.7047, 47.0052],
            [-91.7807, 46.9459],
            [-91.8832, 46.9057],
            [-92.0941, 46.7878],
            [-92.0258, 46.7108],
            [-91.9619, 46.6825],
            [-91.7901, 46.6947],
            [-91.5907, 46.7543],
            [-91.5111, 46.7575],
            [-91.3383, 46.8177],
            [-91.2567, 46.8369],
            [-91.2116, 46.8668],
            [-91.1783, 46.8443],
            [-91.1055, 46.8576],
            [-91.053, 46.8813],
            [-90.9684, 46.9439],
            [-90.9218, 46.9313],
            [-90.8559, 46.9622],
            [-90.7856, 46.9264],
            [-90.751, 46.888],
            [-90.7989, 46.8231],
            [-90.885, 46.7563],
            [-90.8538, 46.6935],
            [-90.9113, 46.6631],
            [-90.9515, 46.597],
            [-90.9098, 46.5827],
            [-90.7553, 46.6463],
            [-90.7373, 46.6923],
            [-90.5581, 46.5864],
            [-90.5059, 46.5896],
            [-90.4376, 46.5615],
            [-90.3276, 46.6077],
            [-90.3066, 46.6027],
            [-90.164, 46.6455],
            [-90.0284, 46.6744],
            [-89.9185, 46.7403],
            [-89.8487, 46.7957],
            [-89.7907, 46.8185],
            [-89.6734, 46.8332],
            [-89.6193, 46.8189],
            [-89.5139, 46.8418],
            [-89.4152, 46.844],
            [-89.2279, 46.913],
            [-89.1287, 46.9926],
            [-89.0867, 46.9853],
            [-88.9728, 47.0021],
            [-88.9245, 47.0422],
            [-88.8891, 47.1006],
            [-88.8148, 47.1414],
            [-88.7644, 47.1558],
            [-88.6724, 47.2191],
            [-88.574, 47.246],
            [-88.5008, 47.2935],
            [-88.4187, 47.3712],
            [-88.2178, 47.4487],
            [-88.0403, 47.476],
            [-87.8012, 47.4733],
            [-87.7159, 47.4398],
            [-87.7514, 47.4051],
            [-87.8154, 47.3848],
            [-87.8567, 47.3954],
            [-87.9571, 47.3873],
            [-87.9388, 47.3468],
            [-88.0165, 47.3063],
            [-88.0601, 47.2958],
            [-88.1631, 47.2163],
            [-88.2124, 47.2094],
            [-88.2817, 47.1382],
            [-88.2976, 47.0985],
            [-88.35, 47.0764],
            [-88.3676, 47.0192],
            [-88.4111, 46.978],
            [-88.4439, 46.9723],
            [-88.4759, 46.886],
            [-88.4837, 46.8317],
            [-88.4384, 46.7867],
            [-88.3814, 46.8385],
            [-88.2444, 46.9296],
            [-88.1752, 46.9046],
            [-88.0819, 46.9205],
            [-87.9861, 46.906],
            [-87.9003, 46.9097],
            [-87.8462, 46.8839],
            [-87.8168, 46.8912],
            [-87.741, 46.8652],
            [-87.7274, 46.8277],
            [-87.6333, 46.8121],
            [-87.5953, 46.7829],
            [-87.5827, 46.7305],
            [-87.5233, 46.6885],
            [-87.503, 46.6475],
            [-87.4514, 46.6059],
            [-87.384, 46.5931],
            [-87.394, 46.5332],
            [-87.3511, 46.5007],
            [-87.2587, 46.4883],
            [-87.1274, 46.494],
            [-87.0087, 46.5327],
            [-86.9645, 46.5165],
            [-86.9471, 46.4721],
            [-86.8839, 46.4415],
            [-86.816, 46.4379],
            [-86.7879, 46.4777],
            [-86.7359, 46.4752],
            [-86.6838, 46.4981],
            [-86.7093, 46.5439],
            [-86.6529, 46.5606],
            [-86.6274, 46.5337],
            [-86.6464, 46.4858],
            [-86.5862, 46.4633],
            [-86.495, 46.5249],
            [-86.3499, 46.578],
            [-86.1617, 46.6695],
            [-86.0998, 46.6546],
            [-85.8779, 46.6909],
            [-85.7506, 46.6774],
            [-85.5095, 46.6758],
            [-85.2372, 46.7558],
            [-85.2378, 46.2454],
            [-85.4113, 46.2457],
            [-85.3842, 46.2316],
            [-85.3823, 46.2204],
            [-85.4041, 46.2146],
            [-85.3979, 46.1439],
            [-85.4518, 46.1308],
            [-85.4801, 46.122],
            [-85.517, 46.1266],
            [-85.4756, 46.1159],
            [-85.5303, 46.1007],
            [-85.5303, 46.0859],
            [-85.6038, 46.0304],
            [-85.664, 45.967],
            [-85.6972, 45.9602],
            [-85.8104, 45.9801],
            [-85.8932, 45.9673],
            [-85.926, 45.9321],
            [-86.0721, 45.9653],
            [-86.1594, 45.9538],
            [-86.2082, 45.963],
            [-86.278, 45.9421],
            [-86.3242, 45.9061],
            [-86.3517, 45.7981],
            [-86.416, 45.7938],
            [-86.4397, 45.7607],
            [-86.5146, 45.7523],
            [-86.5373, 45.7084],
            [-86.5809, 45.7119],
            [-86.5875, 45.6665],
            [-86.6279, 45.6593],
            [-86.6169, 45.6068],
            [-86.6872, 45.6343],
            [-86.7182, 45.6773],
            [-86.6657, 45.7022],
            [-86.6331, 45.7477],
            [-86.631, 45.782],
            [-86.5834, 45.7782],
            [-86.5282, 45.857],
            [-86.5415, 45.8902],
            [-86.5833, 45.8988],
            [-86.646, 45.8339],
            [-86.7821, 45.8602],
            [-86.7733, 45.8114],
            [-86.8215, 45.7704],
            [-86.8387, 45.7223],
            [-86.9442, 45.6958],
            [-86.9846, 45.7058],
            [-86.9752, 45.7531],
            [-86.9884, 45.8106],
            [-87.0189, 45.8389],
            [-87.0574, 45.8125],
            [-87.0704, 45.7188],
            [-87.1116, 45.6859],
            [-87.1722, 45.6618],
            [-87.1968, 45.6363],
            [-87.3331, 45.4472],
            [-87.3277, 45.4253],
            [-87.3925, 45.369],
            [-87.4389, 45.2934],
            [-87.6008, 45.1468],
            [-87.612, 45.1234],
            [-87.5919, 45.0947],
            [-87.66, 45.1075],
            [-87.6839, 45.1441],
            [-87.7361, 45.1722],
            [-87.7418, 45.197],
            [-87.7262, 45.2094],
            [-87.7113, 45.24],
            [-87.6875, 45.2981],
            [-87.6481, 45.3394],
            [-87.6573, 45.3688],
            [-87.694, 45.3899],
            [-87.7541, 45.3494],
            [-87.867, 45.3601],
            [-87.8493, 45.4039],
            [-87.8617, 45.4345],
            [-87.813, 45.4642],
            [-87.7928, 45.5],
            [-87.8034, 45.5383],
            [-87.7772, 45.5885],
            [-87.7959, 45.6188],
            [-87.8232, 45.6627],
            [-87.8091, 45.6997],
            [-87.8555, 45.7269],
            [-87.8758, 45.7539],
            [-87.9635, 45.7582],
            [-87.9914, 45.7954],
            [-88.0721, 45.7803],
            [-88.1295, 45.8093],
            [-88.0731, 45.872],
            [-88.1264, 45.9215],
            [-88.192, 45.9527],
            [-88.2924, 45.9511],
            [-88.3802, 45.9917],
            [-88.4099, 45.9797],
            [-88.3963, 45.9282],
            [-88.4224, 45.8936],
            [-88.4522, 45.8096],
            [-88.5332, 45.8095],
            [-88.5604, 45.7956],
            [-88.6755, 45.7929],
            [-88.676, 45.7228],
            [-88.5726, 45.7227],
            [-88.5731, 45.6577],
            [-88.5934, 45.6114],
            [-88.5537, 45.5734],
            [-88.4994, 45.569],
            [-88.4295, 45.5175],
            [-88.4281, 45.377],
            [-88.6778, 45.3787],
            [-88.6785, 45.337],
            [-88.7465, 45.3365],
            [-88.7452, 45.3785],
            [-88.7704, 45.4112],
            [-88.8649, 45.4351],
            [-88.9231, 45.4651],
            [-89.0463, 45.4648],
            [-89.0467, 45.5515],
            [-89.0975, 45.5468],
            [-89.1618, 45.5193],
            [-89.1652, 45.5736],
            [-89.1882, 45.5671],
            [-89.1953, 45.554],
            [-89.3052, 45.555],
            [-89.4255, 45.5],
            [-89.4821, 45.5271],
            [-89.4783, 45.5556],
            [-89.6449, 45.5559],
            [-89.6296, 45.5945],
            [-89.6521, 45.6451],
            [-89.7196, 45.6455],
            [-89.7786, 45.6849],
            [-89.8877, 45.681],
            [-89.9273, 45.7133],
            [-89.9832, 45.7015],
            [-89.9212, 45.6632],
            [-89.9208, 45.6215],
            [-89.8792, 45.6163],
            [-89.817, 45.5831],
            [-89.8171, 45.5557],
            [-89.9352, 45.5553],
            [-89.9705, 45.5289],
            [-89.9702, 45.4554],
            [-90.017, 45.4312],
            [-90.0783, 45.4544],
            [-90.1668, 45.4634],
            [-90.1958, 45.4946],
            [-90.3106, 45.488],
            [-90.351, 45.445],
            [-90.4308, 45.4342],
            [-90.4308, 45.463],
            [-90.5036, 45.4633],
            [-90.5732, 45.4448],
            [-90.6175, 45.4375],
            [-90.6181, 45.3974],
            [-90.6209, 45.3774],
            [-90.7403, 45.3782],
            [-90.7199, 45.3838],
            [-90.7199, 45.3996],
            [-90.7303, 45.399],
            [-90.7302, 45.4294],
            [-90.8401, 45.407],
            [-90.8445, 45.3901],
            [-90.8597, 45.3842],
            [-90.9045, 45.3856],
            [-90.9042, 45.4058],
            [-90.9869, 45.3867],
            [-90.9871, 45.401],
            [-90.9969, 45.404],
            [-90.9825, 45.423],
            [-90.9975, 45.4229],
            [-90.9984, 45.4012],
            [-91.0283, 45.4009],
            [-91.0282, 45.3947],
            [-91.0404, 45.388],
            [-91.0487, 45.4044],
            [-91.0695, 45.3642],
            [-91.1101, 45.3931],
            [-91.1375, 45.375],
            [-91.1319, 45.3354],
            [-91.1934, 45.3356],
            [-91.1939, 45.3454],
            [-91.2063, 45.3424],
            [-91.213, 45.3497],
            [-91.2278, 45.3366],
            [-91.237, 45.298],
            [-91.2358, 45.3285],
            [-91.2613, 45.3281],
            [-91.2656, 45.3064],
            [-91.2892, 45.3279],
            [-91.3231, 45.3333],
            [-91.341, 45.3534],
            [-91.3502, 45.3521],
            [-91.3585, 45.3342],
            [-91.3729, 45.334],
            [-91.3731, 45.3179],
            [-91.4284, 45.3011],
            [-91.4086, 45.3104],
            [-91.4089, 45.3199],
            [-91.4211, 45.3137],
            [-91.4221, 45.3233],
            [-91.4077, 45.3363],
            [-91.3884, 45.3402],
            [-91.3883, 45.3487],
            [-91.4596, 45.3491],
            [-91.4741, 45.3384],
            [-91.4817, 45.3632],
            [-91.5176, 45.3637],
            [-91.5006, 45.3893],
            [-91.5208, 45.3898],
            [-91.555, 45.4079],
            [-91.5578, 45.3898],
            [-91.6232, 45.3899],
            [-91.6377, 45.3502],
            [-91.6588, 45.3604],
            [-91.6842, 45.3577],
            [-91.6842, 45.3685],
            [-91.6991, 45.3791],
            [-91.7112, 45.3792],
            [-91.7049, 45.3732],
            [-91.7222, 45.3805],
            [-91.7157, 45.3723],
            [-91.7253, 45.3724],
            [-91.7457, 45.3794],
            [-91.7254, 45.3693],
            [-91.7459, 45.3654],
            [-91.7468, 45.3509],
            [-91.7255, 45.3434],
            [-91.7413, 45.3222],
            [-91.7459, 45.3425],
            [-91.7648, 45.337],
            [-91.7714, 45.3511],
            [-91.8078, 45.3513],
            [-91.8588, 45.3517],
            [-91.93, 45.3591],
            [-91.9105, 45.3373],
            [-91.8952, 45.3229],
            [-91.9285, 45.3229],
            [-91.9412, 45.3131],
            [-91.9724, 45.3163],
            [-91.9718, 45.338],
            [-91.9979, 45.3388],
            [-91.9896, 45.3611],
            [-92.0747, 45.3599],
            [-92.0742, 45.3385],
            [-92.0946, 45.3527],
            [-92.1145, 45.3541],
            [-92.1152, 45.3674],
            [-92.1566, 45.3598],
            [-92.2087, 45.3648],
            [-92.2082, 45.3811],
            [-92.2603, 45.3745],
            [-92.26, 45.4082],
            [-92.305, 45.4029],
            [-92.3107, 45.4246],
            [-92.3663, 45.4243],
            [-92.3785, 45.4282],
            [-92.3779, 45.4383],
            [-92.387, 45.4348],
            [-92.387, 45.4176],
            [-92.4487, 45.409],
            [-92.4462, 45.3956],
            [-92.4694, 45.396],
            [-92.4714, 45.4244],
            [-92.5699, 45.4257],
            [-92.5705, 45.4689],
            [-92.6111, 45.4908],
            [-92.5998, 45.5123],
            [-92.5896, 45.5267],
            [-92.6099, 45.5286],
            [-92.61, 45.5535],
            [-92.5897, 45.5704],
            [-92.5902, 45.6069],
            [-92.6106, 45.6068],
            [-92.6104, 45.6429],
            [-92.7802, 45.6434],
            [-92.7723, 45.6288],
            [-92.7553, 45.6287],
            [-92.733, 45.6209],
            [-92.7717, 45.5964],
            [-92.7735, 45.5846],
            [-92.76, 45.571],
            [-92.769, 45.5678],
            [-92.7527, 45.5567],
            [-92.7734, 45.5682],
            [-92.8233, 45.5609],
            [-92.885, 45.5788],
            [-92.8879, 45.639],
            [-92.8692, 45.7176],
            [-92.7872, 45.7636],
            [-92.7585, 45.8066],
            [-92.7654, 45.8238],
            [-92.7726, 45.8229],
          ],
          [
            [-93.2836, 46.5808],
            [-93.2841, 46.5485],
            [-93.2644, 46.5586],
            [-93.2005, 46.5637],
            [-93.1798, 46.5651],
            [-93.1758, 46.5539],
            [-93.1791, 46.5778],
            [-93.1597, 46.5719],
            [-93.1582, 46.601],
            [-93.1802, 46.623],
            [-93.2429, 46.6232],
            [-93.2428, 46.6083],
            [-93.2789, 46.6132],
            [-93.2891, 46.5971],
            [-93.2836, 46.5808],
          ],
          [
            [-85.4721, 46.2441],
            [-85.467, 46.2231],
            [-85.447, 46.2051],
            [-85.4258, 46.1923],
            [-85.4288, 46.2163],
            [-85.4318, 46.2379],
            [-85.4721, 46.2441],
          ],
          [
            [-93.3963, 46.7564],
            [-93.3863, 46.7543],
            [-93.3908, 46.7629],
            [-93.3846, 46.7713],
            [-93.3783, 46.7663],
            [-93.3748, 46.7807],
            [-93.35, 46.7835],
            [-93.3462, 46.7961],
            [-93.3277, 46.7909],
            [-93.3406, 46.8202],
            [-93.3477, 46.8395],
            [-93.3565, 46.8493],
            [-93.3618, 46.8685],
            [-93.359, 46.8806],
            [-93.3694, 46.8814],
            [-93.3681, 46.889],
            [-93.3896, 46.9125],
            [-93.3896, 46.8695],
            [-93.4305, 46.8694],
            [-93.4323, 46.8266],
            [-93.3607, 46.825],
            [-93.3963, 46.7564],
          ],
          [
            [-93.403, 46.7466],
            [-93.4012, 46.747],
            [-93.3963, 46.7564],
            [-93.3983, 46.7569],
            [-93.403, 46.7466],
          ],
        ],
        [
          [
            [-93.8107, 46.3099],
            [-93.789, 46.3247],
            [-93.6933, 46.3553],
            [-93.5705, 46.363],
            [-93.5375, 46.3489],
            [-93.5258, 46.3334],
            [-93.5368, 46.2851],
            [-93.5307, 46.2471],
            [-93.7962, 46.2432],
            [-93.8104, 46.2508],
            [-93.8107, 46.3099],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Minneapolis Mkt": {
    type: "Feature",
    properties: { code: "MN_MIN", name: "Minneapolis Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-92.1777, 44.3024],
            [-92.1624, 44.3098],
            [-92.1687, 44.3272],
            [-92.15, 44.3319],
            [-92.1393, 44.3307],
            [-92.1231, 44.3095],
            [-92.132, 44.3024],
            [-92.147, 44.3142],
            [-92.1777, 44.3024],
          ],
        ],
        [
          [
            [-92.1777, 44.3024],
            [-92.178, 44.3022],
            [-92.178, 44.3023],
            [-92.1777, 44.3024],
          ],
        ],
        [
          [
            [-92.7598, 45.9394],
            [-92.7438, 45.9048],
            [-92.7312, 45.9023],
            [-92.7401, 45.847],
            [-92.7726, 45.823],
            [-92.7654, 45.8238],
            [-92.7585, 45.8066],
            [-92.7917, 45.7597],
            [-92.8692, 45.7176],
            [-92.8879, 45.639],
            [-92.885, 45.5788],
            [-92.8233, 45.5609],
            [-92.7734, 45.5682],
            [-92.7456, 45.553],
            [-92.7248, 45.5386],
            [-92.7267, 45.5145],
            [-92.6802, 45.4643],
            [-92.6535, 45.4553],
            [-92.6466, 45.4416],
            [-92.6504, 45.3985],
            [-92.7027, 45.3585],
            [-92.699, 45.3364],
            [-92.71, 45.3213],
            [-92.7619, 45.287],
            [-92.7517, 45.2659],
            [-92.7602, 45.2496],
            [-92.7517, 45.2187],
            [-92.7668, 45.1855],
            [-92.7524, 45.1739],
            [-92.7577, 45.1555],
            [-92.7396, 45.1156],
            [-92.7915, 45.0796],
            [-92.8031, 45.061],
            [-92.7621, 45.0243],
            [-92.7694, 44.9722],
            [-92.7506, 44.9373],
            [-92.7587, 44.909],
            [-92.7746, 44.8981],
            [-92.7634, 44.8742],
            [-92.7653, 44.8372],
            [-92.808, 44.7515],
            [-92.7373, 44.7172],
            [-92.661, 44.6609],
            [-92.6321, 44.649],
            [-92.6198, 44.6342],
            [-92.6215, 44.615],
            [-92.6015, 44.6121],
            [-92.5862, 44.6001],
            [-92.5694, 44.6035],
            [-92.5481, 44.5678],
            [-92.5184, 44.5752],
            [-92.4938, 44.5661],
            [-92.4311, 44.5658],
            [-92.329, 44.5509],
            [-92.2824, 44.4777],
            [-92.2211, 44.4404],
            [-92.1636, 44.4274],
            [-92.1637, 44.4097],
            [-92.1846, 44.3906],
            [-92.2171, 44.3877],
            [-92.1695, 44.3668],
            [-92.1556, 44.3444],
            [-92.1896, 44.3308],
            [-92.1998, 44.2951],
            [-92.1898, 44.2875],
            [-92.1851, 44.2988],
            [-92.178, 44.3022],
            [-92.1884, 44.2857],
            [-92.2117, 44.2969],
            [-92.2675, 44.3072],
            [-92.2906, 44.2985],
            [-92.3066, 44.2992],
            [-92.3232, 44.3103],
            [-92.3807, 44.2994],
            [-92.4111, 44.303],
            [-92.411, 44.3246],
            [-92.4296, 44.3273],
            [-92.4386, 44.3117],
            [-92.476, 44.3211],
            [-92.4913, 44.3538],
            [-92.5759, 44.3393],
            [-92.5861, 44.3399],
            [-92.5861, 44.3538],
            [-92.701, 44.3545],
            [-92.7222, 44.3692],
            [-92.7212, 44.3547],
            [-92.7619, 44.3552],
            [-92.7666, 44.3446],
            [-92.7965, 44.3698],
            [-92.7741, 44.3841],
            [-92.8103, 44.3844],
            [-92.8048, 44.3698],
            [-92.8507, 44.3699],
            [-92.9062, 44.3701],
            [-92.9225, 44.3895],
            [-92.935, 44.3738],
            [-92.9752, 44.3483],
            [-92.9794, 44.3125],
            [-93.0808, 44.3126],
            [-93.0818, 44.2982],
            [-93.1009, 44.298],
            [-93.1015, 44.2186],
            [-93.1115, 44.2181],
            [-93.1115, 44.2114],
            [-93.0914, 44.211],
            [-93.0862, 44.1749],
            [-93.1063, 44.1605],
            [-93.0658, 44.1459],
            [-93.0456, 44.146],
            [-93.0456, 44.1024],
            [-93.086, 44.0916],
            [-93.1059, 44.0663],
            [-93.1059, 43.9938],
            [-93.1037, 43.9814],
            [-93.1277, 43.9804],
            [-93.1171, 43.9683],
            [-93.1261, 43.9648],
            [-93.1659, 43.9646],
            [-93.1859, 43.9209],
            [-93.2353, 43.9211],
            [-93.2335, 43.9012],
            [-93.1926, 43.8788],
            [-93.1859, 43.8483],
            [-93.1694, 43.8483],
            [-93.1695, 43.7705],
            [-93.1496, 43.7758],
            [-93.1245, 43.783],
            [-93.1195, 43.7758],
            [-93.1291, 43.7465],
            [-93.1593, 43.7468],
            [-93.1577, 43.7267],
            [-93.1117, 43.7132],
            [-93.0892, 43.6882],
            [-93.0493, 43.6865],
            [-93.0494, 43.6597],
            [-93.0893, 43.6586],
            [-93.0894, 43.63],
            [-93.1491, 43.6301],
            [-93.1489, 43.6048],
            [-93.1092, 43.6011],
            [-93.0493, 43.5868],
            [-93.0491, 43.5215],
            [-93.0894, 43.5142],
            [-93.0714, 43.4996],
            [-94.0507, 43.4999],
            [-94.0506, 43.4879],
            [-94.0703, 43.4591],
            [-94.1478, 43.459],
            [-94.1473, 43.5001],
            [-94.5323, 43.5004],
            [-94.5324, 43.5438],
            [-94.5522, 43.5581],
            [-94.5818, 43.5471],
            [-94.5969, 43.5512],
            [-94.5916, 43.5728],
            [-94.5922, 43.6378],
            [-94.5725, 43.6503],
            [-94.5325, 43.6448],
            [-94.5323, 43.6734],
            [-94.5719, 43.7172],
            [-94.5721, 43.7428],
            [-94.5611, 43.7423],
            [-94.5722, 43.7608],
            [-94.5922, 43.7754],
            [-94.5923, 43.8333],
            [-94.6396, 43.8625],
            [-94.6794, 43.8771],
            [-94.6947, 43.8524],
            [-94.7094, 43.848],
            [-94.7196, 43.8698],
            [-94.7196, 43.9063],
            [-94.706, 43.9069],
            [-94.713, 43.9204],
            [-94.7028, 43.9191],
            [-94.6993, 43.9354],
            [-94.7222, 43.9322],
            [-94.7391, 43.9397],
            [-94.739, 44.0364],
            [-94.759, 44.0508],
            [-94.739, 44.0655],
            [-94.7793, 44.0654],
            [-94.7793, 44.0945],
            [-94.8395, 44.0794],
            [-94.8195, 44.0756],
            [-94.8395, 44.065],
            [-94.8295, 44.0457],
            [-94.8355, 44.0306],
            [-94.8496, 44.0363],
            [-94.8594, 44.0572],
            [-94.9801, 44.0498],
            [-94.9997, 44.0591],
            [-95.0199, 44.0791],
            [-95.08, 44.0794],
            [-95.08, 44.0938],
            [-95.2005, 44.0938],
            [-95.2014, 44.1373],
            [-95.1912, 44.1491],
            [-95.197, 44.1662],
            [-95.2017, 44.1806],
            [-95.2097, 44.2098],
            [-95.189, 44.2242],
            [-95.1689, 44.2245],
            [-95.1682, 44.2676],
            [-95.2086, 44.2689],
            [-95.2081, 44.2967],
            [-95.2089, 44.317],
            [-95.2079, 44.3257],
            [-95.1272, 44.3691],
            [-95.1069, 44.3398],
            [-94.9671, 44.3406],
            [-94.9473, 44.3261],
            [-94.8765, 44.3258],
            [-94.8363, 44.3405],
            [-94.8427, 44.3549],
            [-94.8661, 44.3907],
            [-94.8056, 44.4017],
            [-94.7854, 44.404],
            [-94.7853, 44.4272],
            [-94.7735, 44.4448],
            [-94.8202, 44.4661],
            [-94.8307, 44.4598],
            [-94.8331, 44.4735],
            [-94.8657, 44.4834],
            [-94.8759, 44.5068],
            [-94.8908, 44.4962],
            [-94.9061, 44.4959],
            [-94.9052, 44.487],
            [-94.9263, 44.4855],
            [-94.9265, 44.5091],
            [-94.9668, 44.527],
            [-94.9507, 44.5463],
            [-94.9571, 44.5672],
            [-94.9346, 44.5696],
            [-94.9555, 44.614],
            [-94.9555, 44.6437],
            [-94.976, 44.687],
            [-94.9761, 44.7307],
            [-94.9354, 44.7311],
            [-94.9156, 44.771],
            [-94.9154, 44.8328],
            [-94.8944, 44.8767],
            [-94.865, 44.8772],
            [-94.865, 44.8922],
            [-94.6918, 44.8919],
            [-94.6108, 44.8919],
            [-94.5844, 44.8922],
            [-94.584, 44.9503],
            [-94.6351, 44.9643],
            [-94.676, 44.9935],
            [-94.676, 45.0369],
            [-94.6757, 45.0805],
            [-94.645, 45.0875],
            [-94.6553, 45.1237],
            [-94.6044, 45.124],
            [-94.6045, 45.1669],
            [-94.6044, 45.2396],
            [-94.6159, 45.2598],
            [-94.6401, 45.2655],
            [-94.6424, 45.2756],
            [-94.6456, 45.2901],
            [-94.6503, 45.3105],
            [-94.6402, 45.3257],
            [-94.6091, 45.3258],
            [-94.6119, 45.3733],
            [-94.6325, 45.3656],
            [-94.6404, 45.3747],
            [-94.6208, 45.3998],
            [-94.6128, 45.4003],
            [-94.6126, 45.3905],
            [-94.5874, 45.3906],
            [-94.5883, 45.4125],
            [-94.5781, 45.4127],
            [-94.5769, 45.3941],
            [-94.5569, 45.3894],
            [-94.5562, 45.3694],
            [-94.5362, 45.3688],
            [-94.5309, 45.3621],
            [-94.4846, 45.3627],
            [-94.4752, 45.3917],
            [-94.429, 45.392],
            [-94.4244, 45.4133],
            [-94.3937, 45.3992],
            [-94.4139, 45.3775],
            [-94.3696, 45.3775],
            [-94.3689, 45.3848],
            [-94.3591, 45.3848],
            [-94.3583, 45.4104],
            [-94.3192, 45.4213],
            [-94.3278, 45.4285],
            [-94.325, 45.4496],
            [-94.3091, 45.4212],
            [-94.2462, 45.4429],
            [-94.244, 45.4247],
            [-94.2157, 45.4223],
            [-94.1964, 45.4313],
            [-94.1692, 45.4278],
            [-94.1381, 45.4145],
            [-94.1356, 45.4493],
            [-94.0806, 45.4257],
            [-94.1, 45.4382],
            [-94.0857, 45.4319],
            [-94.0821, 45.4382],
            [-94.1035, 45.4533],
            [-94.1078, 45.4724],
            [-94.0958, 45.4518],
            [-94.0639, 45.4331],
            [-94.0512, 45.4354],
            [-94.0387, 45.4193],
            [-94.0277, 45.4255],
            [-94.0421, 45.4246],
            [-94.0425, 45.4398],
            [-94.0714, 45.4546],
            [-94.0407, 45.4604],
            [-94.0165, 45.451],
            [-94.0063, 45.4857],
            [-93.9772, 45.4764],
            [-93.9748, 45.5155],
            [-93.9529, 45.509],
            [-93.9223, 45.5598],
            [-93.8215, 45.56],
            [-93.8213, 45.5704],
            [-93.8009, 45.5746],
            [-93.76, 45.589],
            [-93.7605, 45.6262],
            [-93.728, 45.6246],
            [-93.7288, 45.6439],
            [-93.6666, 45.64],
            [-93.6669, 45.6636],
            [-93.6575, 45.6668],
            [-93.6671, 45.6779],
            [-93.6167, 45.6769],
            [-93.5992, 45.6809],
            [-93.5856, 45.6771],
            [-93.5962, 45.6763],
            [-93.5705, 45.6612],
            [-93.5683, 45.681],
            [-93.5465, 45.6693],
            [-93.5441, 45.6751],
            [-93.5127, 45.6778],
            [-93.5124, 45.6884],
            [-93.4933, 45.7128],
            [-93.5127, 45.7144],
            [-93.5138, 45.7344],
            [-93.416, 45.7316],
            [-93.4151, 45.717],
            [-93.4068, 45.7168],
            [-93.3822, 45.7237],
            [-93.379, 45.7309],
            [-93.3086, 45.731],
            [-93.3237, 45.7385],
            [-93.3072, 45.7384],
            [-93.3072, 45.7494],
            [-93.3484, 45.7495],
            [-93.3487, 45.7598],
            [-93.3692, 45.7667],
            [-93.3692, 45.7896],
            [-93.3542, 45.7765],
            [-93.3465, 45.7856],
            [-93.2971, 45.7962],
            [-93.348, 45.7961],
            [-93.3308, 45.8527],
            [-93.3382, 45.8462],
            [-93.3891, 45.846],
            [-93.3891, 45.8892],
            [-93.4329, 45.8908],
            [-93.4148, 45.8977],
            [-93.4106, 45.9093],
            [-93.4332, 45.9005],
            [-93.42, 45.9335],
            [-93.4319, 45.934],
            [-93.431, 45.9687],
            [-93.4518, 45.9819],
            [-93.4478, 45.9918],
            [-93.4326, 45.996],
            [-93.4742, 46.0104],
            [-93.4742, 46.0579],
            [-93.4556, 46.059],
            [-93.4258, 46.0437],
            [-93.3891, 46.0498],
            [-93.3933, 46.0105],
            [-93.3711, 46.0103],
            [-93.3694, 46.0465],
            [-93.338, 46.0462],
            [-93.3259, 46.1155],
            [-93.2217, 46.1146],
            [-93.2213, 46.125],
            [-93.1856, 46.1264],
            [-93.179, 46.1585],
            [-93.0437, 46.1625],
            [-93.0325, 46.1508],
            [-92.8971, 46.1509],
            [-92.8662, 46.1798],
            [-92.8244, 46.1286],
            [-92.7417, 46.1286],
            [-92.7407, 46.1577],
            [-92.6976, 46.1576],
            [-92.6338, 46.1428],
            [-92.5923, 46.1865],
            [-92.4074, 46.186],
            [-92.3727, 46.2577],
            [-92.3132, 46.2582],
            [-92.2932, 46.2878],
            [-92.294, 46.0744],
            [-92.3343, 46.0625],
            [-92.351, 46.0165],
            [-92.372, 46.0142],
            [-92.4146, 46.0284],
            [-92.434, 46.0227],
            [-92.4686, 45.9746],
            [-92.532, 45.9821],
            [-92.5528, 45.9522],
            [-92.6597, 45.9237],
            [-92.7057, 45.9018],
            [-92.7212, 45.8881],
            [-92.7336, 45.8771],
            [-92.7306, 45.903],
            [-92.7429, 45.9051],
            [-92.7598, 45.9394],
          ],
          [
            [-93.3804, 44.1072],
            [-93.379, 44.1137],
            [-93.3868, 44.1097],
            [-93.3804, 44.1072],
          ],
          [
            [-93.0559, 45.8131],
            [-93.0787, 45.7919],
            [-93.0792, 45.7908],
            [-93.0559, 45.8131],
          ],
          [
            [-93.0391, 45.8657],
            [-93.0473, 45.8616],
            [-93.0423, 45.8214],
            [-93.0391, 45.8657],
          ],
          [
            [-92.864, 46.0994],
            [-92.8638, 46.1038],
            [-92.8654, 46.1001],
            [-92.864, 46.0994],
          ],
          [
            [-92.7598, 45.9394],
            [-92.7608, 45.9415],
            [-92.77, 45.9424],
            [-92.7598, 45.9394],
          ],
          [
            [-92.7726, 45.823],
            [-92.8083, 45.8185],
            [-92.7748, 45.8213],
            [-92.7726, 45.823],
          ],
          [
            [-92.8638, 46.1038],
            [-92.8552, 46.1232],
            [-92.8612, 46.1452],
            [-92.8638, 46.1038],
          ],
          [
            [-92.864, 46.0994],
            [-92.8641, 46.0982],
            [-92.8514, 46.0924],
            [-92.8504, 46.0924],
            [-92.864, 46.0994],
          ],
          [
            [-92.77, 45.9424],
            [-92.8183, 45.9566],
            [-92.8378, 45.9778],
            [-92.8196, 45.9565],
            [-92.7971, 45.9451],
            [-92.77, 45.9424],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Quincy Mkt": {
    type: "Feature",
    properties: { code: "IL_QUI", name: "Quincy Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-91.0477, 40.5317],
            [-91.0475, 40.5329],
            [-91.0406, 40.5311],
            [-91.0477, 40.5317],
          ],
        ],
        [
          [
            [-91.0477, 40.5317],
            [-91.0498, 40.5162],
            [-91.0228, 40.5282],
            [-91.012, 40.5101],
            [-91.0043, 40.4883],
            [-91.0211, 40.4892],
            [-91.0202, 40.4802],
            [-90.9694, 40.4774],
            [-90.9749, 40.4969],
            [-90.9456, 40.4991],
            [-90.9456, 40.4759],
            [-90.9647, 40.4753],
            [-90.965, 40.4605],
            [-90.9076, 40.4627],
            [-90.9076, 40.453],
            [-90.8782, 40.4438],
            [-90.8766, 40.4983],
            [-90.8382, 40.5082],
            [-90.8379, 40.5242],
            [-90.7997, 40.5227],
            [-90.7906, 40.5005],
            [-90.7832, 40.4787],
            [-90.7735, 40.4565],
            [-90.7213, 40.4631],
            [-90.7182, 40.451],
            [-90.7436, 40.4454],
            [-90.7246, 40.4329],
            [-90.74, 40.4228],
            [-90.7351, 40.3943],
            [-90.7539, 40.3937],
            [-90.7494, 40.3707],
            [-90.7349, 40.3755],
            [-90.7577, 40.3611],
            [-90.7538, 40.3019],
            [-90.7349, 40.3036],
            [-90.7542, 40.2809],
            [-90.6779, 40.2787],
            [-90.6785, 40.2713],
            [-90.7008, 40.2713],
            [-90.7011, 40.2439],
            [-90.7407, 40.2238],
            [-90.7198, 40.2207],
            [-90.6656, 40.175],
            [-90.6809, 40.1524],
            [-90.6959, 40.1038],
            [-90.6956, 40.0796],
            [-90.6751, 40.046],
            [-90.6019, 40.0033],
            [-90.5111, 39.9676],
            [-90.5382, 39.9182],
            [-90.5756, 39.8913],
            [-90.5657, 39.8301],
            [-90.5972, 39.7949],
            [-90.6458, 39.7046],
            [-90.6089, 39.6403],
            [-90.5724, 39.543],
            [-90.6105, 39.4574],
            [-90.6137, 39.3958],
            [-90.7058, 39.397],
            [-90.7667, 39.3256],
            [-90.8061, 39.3773],
            [-90.8268, 39.3729],
            [-90.8475, 39.3453],
            [-90.9408, 39.404],
            [-91.0383, 39.4484],
            [-91.1003, 39.5387],
            [-91.195, 39.5448],
            [-91.2304, 39.5205],
            [-91.2325, 39.4456],
            [-91.2574, 39.4149],
            [-91.3392, 39.4009],
            [-91.3532, 39.4186],
            [-91.4591, 39.4433],
            [-91.4699, 39.4365],
            [-91.5441, 39.4241],
            [-91.5885, 39.3818],
            [-91.5892, 39.3525],
            [-91.718, 39.3391],
            [-91.7695, 39.3387],
            [-91.7907, 39.3685],
            [-91.7972, 39.3669],
            [-91.7994, 39.3859],
            [-91.8171, 39.3908],
            [-91.8096, 39.4116],
            [-91.8294, 39.4006],
            [-91.8231, 39.3932],
            [-91.8321, 39.3902],
            [-91.824, 39.3933],
            [-91.8314, 39.3999],
            [-91.8152, 39.4213],
            [-91.8344, 39.4253],
            [-91.8355, 39.4337],
            [-91.8236, 39.4443],
            [-91.8253, 39.4545],
            [-91.8127, 39.4527],
            [-91.8143, 39.463],
            [-91.8025, 39.4582],
            [-91.8199, 39.4714],
            [-91.798, 39.4693],
            [-91.7868, 39.4822],
            [-91.7755, 39.4758],
            [-91.7717, 39.4992],
            [-91.7135, 39.4992],
            [-91.7135, 39.5117],
            [-91.7182, 39.5076],
            [-91.7323, 39.5161],
            [-91.7253, 39.5178],
            [-91.7797, 39.5719],
            [-91.8087, 39.5864],
            [-91.8184, 39.5899],
            [-91.8439, 39.59],
            [-91.8509, 39.5817],
            [-91.8633, 39.582],
            [-91.8646, 39.5744],
            [-91.9012, 39.5748],
            [-91.8972, 39.6132],
            [-91.9326, 39.6083],
            [-91.9333, 39.597],
            [-91.9804, 39.6017],
            [-91.9798, 39.5954],
            [-91.9918, 39.5954],
            [-91.9973, 39.5653],
            [-92.0547, 39.5833],
            [-92.1021, 39.5891],
            [-92.1159, 39.5893],
            [-92.1202, 39.5747],
            [-92.1346, 39.5931],
            [-92.1681, 39.606],
            [-92.1867, 39.6041],
            [-92.2605, 39.6049],
            [-92.2646, 39.5829],
            [-92.2506, 39.5802],
            [-92.2506, 39.5656],
            [-92.2332, 39.5458],
            [-92.2471, 39.5613],
            [-92.2642, 39.5669],
            [-92.2646, 39.5805],
            [-92.2806, 39.5846],
            [-92.2829, 39.6014],
            [-92.2993, 39.6053],
            [-92.3055, 39.6283],
            [-92.3434, 39.6344],
            [-92.337, 39.6396],
            [-92.3479, 39.6407],
            [-92.3526, 39.655],
            [-92.364, 39.6574],
            [-92.3644, 39.6475],
            [-92.392, 39.6426],
            [-92.417, 39.6576],
            [-92.443, 39.6506],
            [-92.4477, 39.6724],
            [-92.4748, 39.6723],
            [-92.4702, 39.6939],
            [-92.4972, 39.6947],
            [-92.5226, 39.6515],
            [-92.5451, 39.6516],
            [-92.5636, 39.6414],
            [-92.5983, 39.6489],
            [-92.6128, 39.6443],
            [-92.6212, 39.6093],
            [-92.655, 39.6082],
            [-92.6399, 39.5954],
            [-92.6401, 39.5821],
            [-92.6474, 39.588],
            [-92.644, 39.5802],
            [-92.6549, 39.5735],
            [-92.6599, 39.6097],
            [-92.6914, 39.6315],
            [-92.7264, 39.606],
            [-92.7483, 39.611],
            [-92.7523, 39.6407],
            [-92.8405, 39.6489],
            [-92.8586, 39.6677],
            [-92.8441, 39.6731],
            [-92.8173, 39.7457],
            [-92.7988, 39.7683],
            [-92.82, 39.7674],
            [-92.828, 39.7977],
            [-92.8084, 39.8286],
            [-92.8101, 39.8946],
            [-92.8525, 39.8973],
            [-92.8515, 39.9192],
            [-92.9185, 39.9199],
            [-92.9406, 39.8913],
            [-92.9584, 39.8915],
            [-92.9567, 39.928],
            [-92.9731, 39.9286],
            [-92.9731, 39.9509],
            [-93.0007, 39.9533],
            [-92.9944, 39.9853],
            [-93.0075, 40.0077],
            [-92.9982, 40.0229],
            [-93.0016, 40.0365],
            [-92.9692, 40.0365],
            [-92.9699, 40.051],
            [-92.9457, 40.0514],
            [-92.9598, 40.0727],
            [-92.9767, 40.0823],
            [-93.0027, 40.0803],
            [-93.0072, 40.0513],
            [-93.0255, 40.0946],
            [-93.0454, 40.0894],
            [-93.0547, 40.0951],
            [-93.1004, 40.0952],
            [-93.1159, 40.0752],
            [-93.159, 40.0571],
            [-93.1782, 40.0652],
            [-93.1835, 40.0756],
            [-93.2041, 40.0822],
            [-93.2001, 40.0943],
            [-93.2218, 40.105],
            [-93.2207, 40.1363],
            [-93.2284, 40.1305],
            [-93.2634, 40.1303],
            [-93.254, 40.1446],
            [-93.2443, 40.1588],
            [-93.2916, 40.1768],
            [-93.3028, 40.2089],
            [-93.3009, 40.2234],
            [-93.2868, 40.2343],
            [-93.2914, 40.2674],
            [-93.273, 40.2619],
            [-93.2721, 40.2388],
            [-93.2474, 40.2319],
            [-93.2488, 40.2683],
            [-93.2157, 40.2689],
            [-93.2209, 40.3124],
            [-93.2055, 40.3142],
            [-93.2151, 40.338],
            [-93.1962, 40.3289],
            [-93.1958, 40.336],
            [-93.1886, 40.3341],
            [-93.1956, 40.4003],
            [-93.1611, 40.4059],
            [-93.1692, 40.4259],
            [-93.1572, 40.4364],
            [-93.1407, 40.4366],
            [-93.14, 40.475],
            [-93.1791, 40.4736],
            [-93.1852, 40.4859],
            [-93.1657, 40.4931],
            [-93.131, 40.4871],
            [-93.1406, 40.5253],
            [-93.1198, 40.5833],
            [-91.8325, 40.6098],
            [-91.7851, 40.5972],
            [-91.7859, 40.6115],
            [-91.7291, 40.6136],
            [-91.6864, 40.5809],
            [-91.6887, 40.5574],
            [-91.6201, 40.5408],
            [-91.6214, 40.5101],
            [-91.5908, 40.4923],
            [-91.5747, 40.4657],
            [-91.5251, 40.4578],
            [-91.5336, 40.4383],
            [-91.5191, 40.4328],
            [-91.5246, 40.4108],
            [-91.4885, 40.4043],
            [-91.4823, 40.3821],
            [-91.4651, 40.3853],
            [-91.465, 40.3762],
            [-91.4525, 40.3755],
            [-91.4412, 40.3863],
            [-91.4194, 40.3783],
            [-91.4157, 40.3814],
            [-91.382, 40.3876],
            [-91.3726, 40.4012],
            [-91.3818, 40.4426],
            [-91.3642, 40.5],
            [-91.3845, 40.5309],
            [-91.4041, 40.5391],
            [-91.4069, 40.5476],
            [-91.3487, 40.6097],
            [-91.2531, 40.638],
            [-91.187, 40.6373],
            [-91.1031, 40.6669],
            [-91.096, 40.6666],
            [-91.0995, 40.6594],
            [-91.0488, 40.6556],
            [-91.0338, 40.6351],
            [-90.9819, 40.6365],
            [-90.9824, 40.6219],
            [-91.0065, 40.6208],
            [-91.0155, 40.6086],
            [-91.0413, 40.6057],
            [-91.0902, 40.5479],
            [-91.054, 40.547],
            [-91.0566, 40.5325],
            [-91.0477, 40.5317],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Taylorville Mkt": {
    type: "Feature",
    properties: { code: "IL_TAY", name: "Taylorville Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-90.6959, 40.1038],
          [-90.6809, 40.1524],
          [-90.6656, 40.175],
          [-90.6479, 40.1685],
          [-90.6219, 40.2267],
          [-90.6157, 40.2342],
          [-90.5838, 40.238],
          [-90.571, 40.2648],
          [-90.5492, 40.2562],
          [-90.5367, 40.2638],
          [-90.5152, 40.2629],
          [-90.5179, 40.254],
          [-90.5082, 40.2481],
          [-90.5043, 40.2652],
          [-90.4918, 40.2613],
          [-90.4749, 40.2694],
          [-90.4796, 40.2547],
          [-90.4506, 40.2543],
          [-90.4515, 40.1889],
          [-90.1996, 40.1839],
          [-90.1635, 40.2166],
          [-90.1251, 40.2289],
          [-90.1073, 40.2609],
          [-90.0859, 40.2769],
          [-90.1082, 40.2608],
          [-90.1274, 40.2284],
          [-90.1647, 40.217],
          [-90.1717, 40.2239],
          [-90.205, 40.2252],
          [-90.1979, 40.2322],
          [-90.2097, 40.2915],
          [-90.1896, 40.3046],
          [-90.1473, 40.3063],
          [-90.1535, 40.312],
          [-90.1243, 40.3024],
          [-90.0699, 40.2943],
          [-90.0687, 40.3139],
          [-90.0531, 40.3279],
          [-90.0424, 40.3666],
          [-89.9829, 40.3921],
          [-89.9548, 40.3922],
          [-89.9795, 40.3737],
          [-89.9513, 40.393],
          [-89.9236, 40.3923],
          [-89.9282, 40.3331],
          [-89.9418, 40.3325],
          [-89.9836, 40.3342],
          [-89.9565, 40.3256],
          [-89.9894, 40.3197],
          [-89.9894, 40.3125],
          [-89.9563, 40.3125],
          [-89.9607, 40.2931],
          [-89.9514, 40.2756],
          [-89.9038, 40.2752],
          [-89.9045, 40.2968],
          [-89.8294, 40.296],
          [-89.8285, 40.3032],
          [-89.8189, 40.2891],
          [-89.8047, 40.2886],
          [-89.7434, 40.3042],
          [-89.7339, 40.3079],
          [-89.7339, 40.3188],
          [-89.3966, 40.3239],
          [-89.3902, 40.2689],
          [-89.4041, 40.2648],
          [-89.4035, 40.2355],
          [-89.3375, 40.2364],
          [-89.3187, 40.2438],
          [-89.3278, 40.2294],
          [-89.2592, 40.2152],
          [-89.2588, 40.1997],
          [-89.2459, 40.1999],
          [-89.2456, 40.1856],
          [-89.215, 40.1859],
          [-89.1943, 40.2005],
          [-89.1755, 40.2081],
          [-89.143, 40.2101],
          [-89.1373, 40.195],
          [-89.1246, 40.1861],
          [-89.1472, 40.1882],
          [-89.146, 40.1362],
          [-89.174, 40.136],
          [-89.1594, 40.0849],
          [-89.1452, 40.0852],
          [-89.1444, 40.02],
          [-89.105, 40.0054],
          [-89.029, 40.0063],
          [-89.0288, 39.9625],
          [-88.9936, 39.9633],
          [-88.9505, 39.9643],
          [-88.9184, 39.9651],
          [-88.8984, 39.9803],
          [-88.8991, 40.0094],
          [-88.8798, 40.0537],
          [-88.7648, 40.0551],
          [-88.784, 40],
          [-88.7643, 39.997],
          [-88.7643, 39.9539],
          [-88.7798, 39.9519],
          [-88.7731, 39.9315],
          [-88.7454, 39.9464],
          [-88.7454, 39.8948],
          [-88.7739, 39.8948],
          [-88.7762, 39.8818],
          [-88.7645, 39.8798],
          [-88.779, 39.8601],
          [-88.7648, 39.8604],
          [-88.7939, 39.8504],
          [-88.8028, 39.8353],
          [-88.7461, 39.8363],
          [-88.746, 39.7994],
          [-88.7835, 39.799],
          [-88.7775, 39.7805],
          [-88.7966, 39.7807],
          [-88.7933, 39.7992],
          [-88.8149, 39.8057],
          [-88.8135, 39.7627],
          [-88.8513, 39.7631],
          [-88.8422, 39.7447],
          [-88.8692, 39.7115],
          [-88.87, 39.7519],
          [-88.9233, 39.751],
          [-88.9225, 39.6965],
          [-88.9014, 39.6966],
          [-88.8884, 39.6912],
          [-88.8869, 39.6818],
          [-88.8222, 39.6823],
          [-88.8113, 39.67],
          [-88.8671, 39.6529],
          [-88.8477, 39.6238],
          [-88.8101, 39.6236],
          [-88.8093, 39.5802],
          [-88.7175, 39.5793],
          [-88.7169, 39.521],
          [-88.6978, 39.5207],
          [-88.6996, 39.5077],
          [-88.6751, 39.508],
          [-88.696, 39.5064],
          [-88.685, 39.5006],
          [-88.6879, 39.4885],
          [-88.7098, 39.47],
          [-88.7091, 39.4593],
          [-88.7016, 39.4667],
          [-88.697, 39.4619],
          [-88.7308, 39.4484],
          [-88.6873, 39.4337],
          [-88.6966, 39.4184],
          [-88.7156, 39.4116],
          [-88.6865, 39.4044],
          [-88.6853, 39.3535],
          [-88.7085, 39.3463],
          [-88.7084, 39.3172],
          [-88.7316, 39.3031],
          [-88.7846, 39.2952],
          [-88.8059, 39.2997],
          [-88.8135, 39.3256],
          [-88.8163, 39.3402],
          [-88.8524, 39.3322],
          [-88.8359, 39.3655],
          [-88.8458, 39.3784],
          [-88.8992, 39.3709],
          [-88.8987, 39.3328],
          [-88.9319, 39.3253],
          [-88.9291, 39.3184],
          [-88.9358, 39.3178],
          [-88.8895, 39.3108],
          [-88.8985, 39.2963],
          [-88.9732, 39.2849],
          [-88.9983, 39.2887],
          [-89.004, 39.2995],
          [-89.0249, 39.2955],
          [-89.0336, 39.3058],
          [-89.0483, 39.289],
          [-89.0442, 39.2672],
          [-89.0185, 39.2604],
          [-89.0144, 39.2485],
          [-89.0536, 39.2542],
          [-89.0585, 39.2379],
          [-89.0727, 39.2382],
          [-89.0681, 39.2164],
          [-89.1401, 39.2172],
          [-89.1684, 39.247],
          [-89.1772, 39.349],
          [-89.1396, 39.3637],
          [-89.1672, 39.4],
          [-89.2786, 39.407],
          [-89.3261, 39.3745],
          [-89.3636, 39.3745],
          [-89.3822, 39.343],
          [-89.4202, 39.3343],
          [-89.5161, 39.2611],
          [-89.5912, 39.2585],
          [-89.6378, 39.2874],
          [-89.6753, 39.2642],
          [-89.7389, 39.2493],
          [-89.7557, 39.2047],
          [-89.7954, 39.1969],
          [-89.8594, 39.2095],
          [-89.8914, 39.1729],
          [-89.9091, 39.1001],
          [-89.9513, 39.1006],
          [-89.9608, 39.0718],
          [-90.057, 39.0654],
          [-90.0706, 39.0872],
          [-90.1234, 39.0868],
          [-90.1234, 39.1308],
          [-90.0544, 39.1693],
          [-90.0547, 39.2053],
          [-90.1426, 39.2433],
          [-90.1697, 39.2694],
          [-90.1487, 39.3055],
          [-90.1537, 39.5202],
          [-90.2645, 39.5199],
          [-90.3287, 39.5421],
          [-90.4117, 39.5573],
          [-90.4354, 39.5247],
          [-90.5811, 39.5217],
          [-90.5723, 39.5392],
          [-90.5766, 39.5595],
          [-90.6458, 39.7046],
          [-90.5972, 39.7949],
          [-90.5657, 39.8301],
          [-90.5756, 39.8913],
          [-90.5382, 39.9182],
          [-90.5111, 39.9676],
          [-90.6019, 40.0033],
          [-90.6751, 40.046],
          [-90.6956, 40.0796],
          [-90.6959, 40.1038],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "St Louis Mkt": {
    type: "Feature",
    properties: { code: "MO_STL", name: "St Louis Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-89.2776, 38.5411],
            [-89.2634, 38.5522],
            [-89.2496, 38.5442],
            [-89.2637, 38.5303],
            [-89.2731, 38.5303],
            [-89.2776, 38.5411],
          ],
        ],
        [
          [
            [-90.2546, 38.1289],
            [-90.27, 38.1375],
            [-90.3077, 38.1092],
            [-90.3002, 38.0893],
            [-90.3292, 38.0945],
            [-90.3496, 38.0823],
            [-90.3372, 38.0644],
            [-90.342, 38.0335],
            [-90.316, 38.0289],
            [-90.3146, 38.0111],
            [-90.3276, 38.008],
            [-90.329, 37.9981],
            [-90.294, 38.0019],
            [-90.2769, 37.9966],
            [-90.2921, 37.9889],
            [-90.288, 37.9837],
            [-90.303, 37.9666],
            [-90.3283, 37.9548],
            [-90.3188, 37.9224],
            [-90.3436, 37.9134],
            [-90.3818, 37.9212],
            [-90.3818, 37.9416],
            [-90.4165, 37.9412],
            [-90.4173, 37.9582],
            [-90.4646, 37.9486],
            [-90.4689, 37.9948],
            [-90.4897, 38.0125],
            [-90.4919, 38.0252],
            [-90.5833, 38.0035],
            [-90.6282, 38.0078],
            [-90.6128, 38.0161],
            [-90.6214, 38.0406],
            [-90.6123, 38.0703],
            [-90.6565, 38.081],
            [-90.6489, 38.0763],
            [-90.6604, 38.0636],
            [-90.686, 38.0767],
            [-90.6833, 38.0935],
            [-90.6947, 38.1009],
            [-90.7305, 38.0862],
            [-90.7287, 38.094],
            [-90.7403, 38.093],
            [-90.7395, 38.0857],
            [-90.7475, 38.0956],
            [-90.7533, 38.0881],
            [-90.762, 38.0974],
            [-90.7969, 38.083],
            [-90.842, 38.0999],
            [-90.8572, 38.0815],
            [-90.8804, 38.0815],
            [-90.8953, 38.0673],
            [-90.9131, 38.0908],
            [-90.9241, 38.0815],
            [-90.9426, 38.0933],
            [-90.9478, 38.1005],
            [-90.9416, 38.102],
            [-90.9666, 38.1219],
            [-90.9669, 38.1074],
            [-90.9929, 38.0905],
            [-91.0071, 38.1124],
            [-91.0081, 38.1336],
            [-91.0289, 38.1369],
            [-91.0559, 38.1208],
            [-91.0439, 38.0941],
            [-91.0894, 38.0808],
            [-91.0958, 38.1815],
            [-91.1613, 38.1777],
            [-91.1861, 38.1953],
            [-91.2052, 38.1804],
            [-91.2274, 38.1815],
            [-91.2414, 38.1887],
            [-91.2485, 38.2041],
            [-91.3458, 38.204],
            [-91.367, 38.2288],
            [-91.366, 38.2928],
            [-91.399, 38.3],
            [-91.4033, 38.3288],
            [-91.4491, 38.3442],
            [-91.4479, 38.3641],
            [-91.4277, 38.368],
            [-91.4525, 38.4017],
            [-91.4409, 38.427],
            [-91.4429, 38.4496],
            [-91.4303, 38.4604],
            [-91.413, 38.4576],
            [-91.4103, 38.4691],
            [-91.3725, 38.4791],
            [-91.3897, 38.5009],
            [-91.3685, 38.5145],
            [-91.3684, 38.5353],
            [-91.3872, 38.5569],
            [-91.377, 38.5625],
            [-91.3729, 38.5837],
            [-91.3947, 38.5812],
            [-91.3966, 38.598],
            [-91.3796, 38.6046],
            [-91.3688, 38.6012],
            [-91.3688, 38.6419],
            [-91.39, 38.6613],
            [-91.3796, 38.6738],
            [-91.3858, 38.6829],
            [-91.3683, 38.6859],
            [-91.3692, 38.6993],
            [-91.3491, 38.6954],
            [-91.3244, 38.7084],
            [-91.307, 38.7045],
            [-91.337, 38.7197],
            [-91.3675, 38.75],
            [-91.3448, 38.7742],
            [-91.3154, 38.7821],
            [-91.3231, 38.7939],
            [-91.3184, 38.8037],
            [-91.3292, 38.8087],
            [-91.3446, 38.8047],
            [-91.3553, 38.7888],
            [-91.3704, 38.7889],
            [-91.4044, 38.7716],
            [-91.417, 38.7814],
            [-91.4165, 38.8032],
            [-91.462, 38.7885],
            [-91.4861, 38.8028],
            [-91.4868, 38.8127],
            [-91.5244, 38.8237],
            [-91.5367, 38.8192],
            [-91.5621, 38.8355],
            [-91.6127, 38.8436],
            [-91.6429, 38.8318],
            [-91.642, 38.8523],
            [-91.6548, 38.8571],
            [-91.7268, 38.8352],
            [-91.7291, 38.8188],
            [-91.7175, 38.8147],
            [-91.7189, 38.8078],
            [-91.7469, 38.8089],
            [-91.7646, 38.8195],
            [-91.7748, 38.8429],
            [-91.8084, 38.8433],
            [-91.7917, 38.8463],
            [-91.8002, 38.8871],
            [-91.814, 38.8947],
            [-91.8156, 38.9054],
            [-91.7856, 38.9218],
            [-91.805, 38.9343],
            [-91.8066, 38.9633],
            [-91.7714, 38.9625],
            [-91.7498, 38.9719],
            [-91.7497, 38.9801],
            [-91.7131, 38.985],
            [-91.6945, 39.0133],
            [-91.6756, 39.0136],
            [-91.6702, 39.0354],
            [-91.6509, 39.0354],
            [-91.651, 39.0428],
            [-91.6137, 39.0586],
            [-91.6332, 39.0778],
            [-91.6324, 39.0975],
            [-91.6139, 39.0929],
            [-91.6118, 39.1474],
            [-91.6297, 39.1478],
            [-91.6295, 39.1624],
            [-91.7059, 39.1491],
            [-91.7137, 39.1881],
            [-91.7039, 39.1899],
            [-91.7029, 39.2371],
            [-91.7641, 39.237],
            [-91.7627, 39.2496],
            [-91.7743, 39.2648],
            [-91.7673, 39.2952],
            [-91.7865, 39.2949],
            [-91.7811, 39.3081],
            [-91.7949, 39.3064],
            [-91.7991, 39.3383],
            [-91.718, 39.3391],
            [-91.5892, 39.3525],
            [-91.5885, 39.3818],
            [-91.5441, 39.4241],
            [-91.4699, 39.4365],
            [-91.4591, 39.4433],
            [-91.3532, 39.4186],
            [-91.3392, 39.4009],
            [-91.2574, 39.4149],
            [-91.2325, 39.4456],
            [-91.2304, 39.5205],
            [-91.195, 39.5448],
            [-91.1003, 39.5387],
            [-91.0383, 39.4484],
            [-90.9408, 39.404],
            [-90.8475, 39.3453],
            [-90.8268, 39.3729],
            [-90.8061, 39.3773],
            [-90.7667, 39.3256],
            [-90.7058, 39.397],
            [-90.6137, 39.3958],
            [-90.6105, 39.4574],
            [-90.5811, 39.5217],
            [-90.4354, 39.5247],
            [-90.4117, 39.5573],
            [-90.3287, 39.5421],
            [-90.2645, 39.5199],
            [-90.1537, 39.5202],
            [-90.1487, 39.3055],
            [-90.1697, 39.2694],
            [-90.1426, 39.2433],
            [-90.0547, 39.2053],
            [-90.0544, 39.1693],
            [-90.1234, 39.1308],
            [-90.1234, 39.0868],
            [-90.0706, 39.0872],
            [-90.057, 39.0654],
            [-89.9608, 39.0718],
            [-89.9513, 39.1006],
            [-89.9091, 39.1001],
            [-89.8914, 39.1729],
            [-89.8594, 39.2095],
            [-89.7954, 39.1969],
            [-89.7557, 39.2047],
            [-89.7389, 39.2493],
            [-89.6753, 39.2642],
            [-89.6378, 39.2874],
            [-89.5912, 39.2585],
            [-89.5161, 39.2611],
            [-89.4202, 39.3343],
            [-89.3822, 39.343],
            [-89.3636, 39.3745],
            [-89.3261, 39.3745],
            [-89.2786, 39.407],
            [-89.1672, 39.4],
            [-89.1396, 39.3637],
            [-89.1772, 39.349],
            [-89.1684, 39.247],
            [-89.1401, 39.2172],
            [-89.063, 39.2163],
            [-89.0584, 39.1981],
            [-89.039, 39.2006],
            [-89.0496, 39.1727],
            [-89.0263, 39.1721],
            [-89.023, 39.182],
            [-89.0086, 39.1833],
            [-89.0108, 39.1574],
            [-88.9846, 39.1576],
            [-88.9826, 39.1796],
            [-88.9639, 39.1652],
            [-88.9411, 39.1516],
            [-88.9551, 39.1143],
            [-88.9874, 39.1134],
            [-89.0139, 39.0883],
            [-89.0326, 39.0922],
            [-89.0294, 39.0747],
            [-89.0419, 39.0495],
            [-89.0855, 39.0277],
            [-89.0822, 39.0173],
            [-89.0984, 39.0176],
            [-89.0937, 39.0395],
            [-89.1167, 39.0339],
            [-89.1214, 39.0427],
            [-89.195, 39.0715],
            [-89.237, 39.079],
            [-89.2291, 39.0562],
            [-89.2432, 39.0491],
            [-89.2489, 39.0336],
            [-89.2445, 39.0012],
            [-89.2131, 38.9664],
            [-89.2196, 38.94],
            [-89.1708, 38.9564],
            [-89.1697, 38.9438],
            [-89.1938, 38.9434],
            [-89.2177, 38.9263],
            [-89.2002, 38.9236],
            [-89.2215, 38.9207],
            [-89.2312, 38.8476],
            [-89.2146, 38.8461],
            [-89.2313, 38.8255],
            [-89.2181, 38.8009],
            [-89.2372, 38.7826],
            [-89.2327, 38.7778],
            [-89.2447, 38.7769],
            [-89.2443, 38.7664],
            [-89.2425, 38.7745],
            [-89.2356, 38.7706],
            [-89.2455, 38.7593],
            [-89.238, 38.7543],
            [-89.2493, 38.746],
            [-89.251, 38.7521],
            [-89.2542, 38.742],
            [-89.1384, 38.7364],
            [-89.1388, 38.6961],
            [-89.1394, 38.6499],
            [-89.1564, 38.6491],
            [-89.1584, 38.6351],
            [-89.1401, 38.6303],
            [-89.1588, 38.6183],
            [-89.1406, 38.6121],
            [-89.15, 38.6054],
            [-89.1779, 38.6069],
            [-89.1699, 38.5772],
            [-89.1785, 38.5776],
            [-89.1862, 38.5645],
            [-89.2347, 38.5658],
            [-89.2546, 38.5863],
            [-89.2772, 38.5815],
            [-89.282, 38.5666],
            [-89.3392, 38.5222],
            [-89.3602, 38.5165],
            [-89.3672, 38.4991],
            [-89.3919, 38.4951],
            [-89.3859, 38.4896],
            [-89.3926, 38.4863],
            [-89.3805, 38.4808],
            [-89.3923, 38.4712],
            [-89.3868, 38.4612],
            [-89.3989, 38.4597],
            [-89.394, 38.4415],
            [-89.37, 38.4417],
            [-89.3641, 38.4566],
            [-89.3486, 38.4422],
            [-89.2707, 38.4421],
            [-89.2744, 38.4347],
            [-89.3297, 38.4278],
            [-89.3399, 38.4141],
            [-89.3214, 38.398],
            [-89.3355, 38.3903],
            [-89.2751, 38.391],
            [-89.3051, 38.3897],
            [-89.2929, 38.3873],
            [-89.2897, 38.347],
            [-89.2991, 38.3454],
            [-89.3098, 38.3168],
            [-89.294, 38.3166],
            [-89.2974, 38.3073],
            [-89.2923, 38.3021],
            [-89.2856, 38.3092],
            [-89.2812, 38.2985],
            [-89.3061, 38.2687],
            [-89.3086, 38.2232],
            [-89.3193, 38.2138],
            [-89.3149, 38.2004],
            [-89.3196, 38.1895],
            [-89.2973, 38.1856],
            [-89.2845, 38.1739],
            [-89.3359, 38.1568],
            [-89.3431, 38.127],
            [-89.3153, 38.1161],
            [-89.2598, 38.1408],
            [-89.2598, 38.1264],
            [-89.2787, 38.1191],
            [-89.292, 38.112],
            [-89.3017, 38.0902],
            [-89.3048, 38.0821],
            [-89.3171, 38.0614],
            [-89.3191, 38.0676],
            [-89.3248, 38.0628],
            [-89.3273, 38.0476],
            [-89.3634, 38.0614],
            [-89.3641, 38.0283],
            [-89.3486, 38.0216],
            [-89.345, 38.0116],
            [-89.3534, 37.9619],
            [-89.346, 37.9619],
            [-89.3432, 37.9517],
            [-89.4302, 37.9532],
            [-89.4287, 37.9793],
            [-89.484, 37.9829],
            [-89.4842, 37.9758],
            [-89.5015, 37.9692],
            [-89.5669, 37.9696],
            [-89.5672, 37.9551],
            [-89.6085, 37.9341],
            [-89.634, 37.9234],
            [-89.64, 37.9093],
            [-89.6765, 37.9163],
            [-89.6853, 37.9118],
            [-89.6806, 37.8883],
            [-89.6576, 37.8846],
            [-89.6463, 37.8744],
            [-89.6378, 37.8878],
            [-89.6246, 37.8741],
            [-89.6303, 37.852],
            [-89.6054, 37.8468],
            [-89.592, 37.8629],
            [-89.5853, 37.8469],
            [-89.5582, 37.823],
            [-89.5793, 37.8042],
            [-89.5989, 37.8081],
            [-89.6037, 37.8013],
            [-89.6186, 37.7597],
            [-89.6118, 37.7596],
            [-89.6024, 37.7365],
            [-89.6173, 37.7497],
            [-89.6634, 37.7501],
            [-89.6604, 37.7863],
            [-89.6696, 37.7999],
            [-89.7175, 37.8257],
            [-89.7399, 37.8469],
            [-89.7864, 37.8517],
            [-89.8004, 37.8686],
            [-89.7993, 37.8815],
            [-89.8426, 37.9052],
            [-89.9018, 37.8698],
            [-89.9506, 37.8815],
            [-89.9749, 37.9267],
            [-89.9596, 37.9402],
            [-89.9325, 37.9475],
            [-89.9251, 37.96],
            [-89.9359, 37.9596],
            [-89.9421, 37.9701],
            [-89.9971, 37.9632],
            [-90.0594, 38.0155],
            [-90.0883, 38.0158],
            [-90.1105, 38.0265],
            [-90.1262, 38.0407],
            [-90.1308, 38.0623],
            [-90.1585, 38.0747],
            [-90.1722, 38.0696],
            [-90.2431, 38.1127],
            [-90.2546, 38.1289],
          ],
          [
            [-89.5948, 37.9699],
            [-89.587, 37.9821],
            [-89.5942, 37.9952],
            [-89.5948, 37.9699],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Baltimore Mkt": {
    type: "Feature",
    properties: { code: "MD_BAL", name: "Baltimore Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-75.9939, 37.9535],
            [-76.0465, 37.9536],
            [-76.0486, 38.0148],
            [-76.0131, 38.0398],
            [-75.9843, 38.004],
            [-75.9939, 37.9535],
          ],
        ],
        [
          [
            [-76.0886, 38.1926],
            [-76.0488, 38.2037],
            [-76.0115, 38.0723],
            [-76.0909, 38.1145],
            [-76.0886, 38.1926],
          ],
        ],
        [
          [
            [-76.6976, 38.6974],
            [-76.7505, 38.6933],
            [-76.8416, 38.756],
            [-76.8605, 38.7363],
            [-77.005, 38.6953],
            [-77.0786, 38.6941],
            [-77.0857, 38.7064],
            [-77.0532, 38.7099],
            [-77.0423, 38.7185],
            [-77.0395, 38.7911],
            [-76.9094, 38.8928],
            [-77.041, 38.9951],
            [-77.1199, 38.9343],
            [-77.1482, 38.965],
            [-77.2215, 38.9713],
            [-77.2498, 38.9859],
            [-77.2557, 39.0024],
            [-77.2484, 39.0269],
            [-77.3403, 39.063],
            [-77.3857, 39.062],
            [-77.4615, 39.0752],
            [-77.4858, 39.1093],
            [-77.5199, 39.1209],
            [-77.5106, 39.1785],
            [-77.4786, 39.1892],
            [-77.4471, 39.2281],
            [-77.47, 39.2468],
            [-77.4706, 39.2568],
            [-77.451, 39.2729],
            [-77.4394, 39.257],
            [-77.42, 39.2888],
            [-77.4227, 39.2976],
            [-77.4026, 39.2925],
            [-77.4056, 39.2983],
            [-77.3937, 39.305],
            [-77.3684, 39.303],
            [-77.3592, 39.2807],
            [-77.3344, 39.2955],
            [-77.3253, 39.2811],
            [-77.298, 39.3093],
            [-77.279, 39.3035],
            [-77.2005, 39.3393],
            [-77.2144, 39.3296],
            [-77.1825, 39.3152],
            [-77.1913, 39.3296],
            [-77.182, 39.3304],
            [-77.1657, 39.3097],
            [-77.1657, 39.2874],
            [-77.1449, 39.288],
            [-77.1313, 39.2697],
            [-77.0834, 39.2602],
            [-77.0559, 39.2385],
            [-77.0555, 39.2503],
            [-77.0721, 39.2649],
            [-77.0724, 39.2761],
            [-77.064, 39.2758],
            [-77.0554, 39.2574],
            [-77.0456, 39.2554],
            [-77.0483, 39.2377],
            [-77.0412, 39.2344],
            [-77.0168, 39.2553],
            [-77.0067, 39.2493],
            [-76.9985, 39.2629],
            [-76.9848, 39.2642],
            [-76.9805, 39.2798],
            [-76.9589, 39.2876],
            [-76.938, 39.2837],
            [-76.9526, 39.309],
            [-76.9495, 39.3204],
            [-76.9264, 39.3213],
            [-76.9275, 39.3333],
            [-76.9138, 39.3511],
            [-76.9534, 39.358],
            [-76.9616, 39.3691],
            [-76.9425, 39.3704],
            [-76.9404, 39.3822],
            [-76.9312, 39.3748],
            [-76.9319, 39.3938],
            [-76.9213, 39.3847],
            [-76.9044, 39.392],
            [-76.8907, 39.377],
            [-76.8758, 39.4205],
            [-76.9389, 39.437],
            [-76.9382, 39.4449],
            [-76.9565, 39.4549],
            [-76.9509, 39.4759],
            [-76.9773, 39.4684],
            [-76.9988, 39.479],
            [-77.0057, 39.4729],
            [-77.0013, 39.457],
            [-77.0158, 39.4628],
            [-77.0328, 39.4553],
            [-77.0427, 39.4608],
            [-77.0702, 39.4421],
            [-77.0668, 39.4501],
            [-77.0988, 39.4604],
            [-77.0913, 39.4762],
            [-77.0973, 39.4926],
            [-77.0729, 39.4919],
            [-77.0533, 39.5014],
            [-77.0275, 39.5413],
            [-77.0551, 39.5485],
            [-77.0598, 39.5677],
            [-77.076, 39.5573],
            [-77.0815, 39.5681],
            [-77.1179, 39.5687],
            [-77.1031, 39.591],
            [-77.119, 39.5932],
            [-77.1224, 39.6018],
            [-77.1052, 39.624],
            [-77.1131, 39.6392],
            [-77.0993, 39.6404],
            [-77.1203, 39.6566],
            [-77.0725, 39.6841],
            [-77.067, 39.7201],
            [-75.7736, 39.7224],
            [-75.7531, 39.7576],
            [-75.717, 39.792],
            [-75.6347, 39.8302],
            [-75.5705, 39.839],
            [-75.5184, 39.8363],
            [-75.428, 39.8092],
            [-75.4053, 39.7962],
            [-75.4483, 39.7744],
            [-75.4473, 39.7733],
            [-75.4633, 39.7612],
            [-75.4776, 39.715],
            [-75.4886, 39.7148],
            [-75.5097, 39.6861],
            [-75.6132, 39.621],
            [-75.6119, 39.5974],
            [-75.563, 39.5622],
            [-75.5894, 39.4608],
            [-75.5385, 39.4165],
            [-75.4904, 39.3428],
            [-75.439, 39.3134],
            [-75.403, 39.2546],
            [-75.393, 39.2045],
            [-75.4106, 39.1562],
            [-75.402, 39.0669],
            [-75.3458, 39.0249],
            [-75.3184, 38.9882],
            [-75.3041, 38.9132],
            [-75.1906, 38.8069],
            [-75.134, 38.7822],
            [-75.0895, 38.7972],
            [-75.06, 38.6077],
            [-75.0489, 38.4513],
            [-75.0855, 38.3243],
            [-75.1029, 38.3115],
            [-75.1938, 38.096],
            [-75.2423, 38.0272],
            [-75.6243, 37.9942],
            [-75.6338, 37.9845],
            [-75.6295, 37.976],
            [-75.6382, 37.9794],
            [-75.6482, 37.9668],
            [-75.6476, 37.947],
            [-75.6557, 37.9454],
            [-75.6697, 37.9508],
            [-75.7132, 37.9766],
            [-75.7838, 37.9726],
            [-75.8324, 37.9333],
            [-75.885, 37.9117],
            [-75.899, 37.9745],
            [-75.8753, 38.012],
            [-75.8129, 38.0589],
            [-75.8805, 38.075],
            [-75.8376, 38.1138],
            [-75.9004, 38.1412],
            [-75.9596, 38.1371],
            [-75.9424, 38.1871],
            [-75.8464, 38.2105],
            [-75.8703, 38.2434],
            [-75.9386, 38.2723],
            [-75.9843, 38.2652],
            [-76.0174, 38.3091],
            [-75.9642, 38.3243],
            [-75.9739, 38.3659],
            [-76.0496, 38.3096],
            [-76.0275, 38.2801],
            [-76.058, 38.2271],
            [-76.0997, 38.2536],
            [-76.1113, 38.2869],
            [-76.1802, 38.277],
            [-76.2582, 38.3184],
            [-76.283, 38.4135],
            [-76.3316, 38.4735],
            [-76.3273, 38.5001],
            [-76.264, 38.5035],
            [-76.29, 38.5692],
            [-76.2796, 38.6095],
            [-76.2367, 38.6286],
            [-76.2124, 38.6067],
            [-76.1472, 38.6368],
            [-76.2003, 38.6708],
            [-76.2551, 38.7365],
            [-76.275, 38.7127],
            [-76.3161, 38.7296],
            [-76.3346, 38.7729],
            [-76.3009, 38.8262],
            [-76.2193, 38.8124],
            [-76.1911, 38.8297],
            [-76.2036, 38.9284],
            [-76.2731, 38.9419],
            [-76.3361, 38.906],
            [-76.331, 38.8643],
            [-76.3611, 38.852],
            [-76.3617, 38.9392],
            [-76.3227, 38.9996],
            [-76.2189, 38.9705],
            [-76.164, 38.9995],
            [-76.1842, 39.0463],
            [-76.1452, 39.0928],
            [-76.2335, 39.0914],
            [-76.2746, 39.1655],
            [-76.2193, 39.262],
            [-76.1857, 39.2854],
            [-76.1704, 39.3321],
            [-76.1332, 39.3405],
            [-76.1105, 39.3723],
            [-76.023, 39.3619],
            [-76.0025, 39.385],
            [-76.0123, 39.4531],
            [-75.967, 39.5386],
            [-75.9703, 39.5576],
            [-76.0961, 39.5369],
            [-76.1173, 39.4961],
            [-76.072, 39.475],
            [-76.1023, 39.4356],
            [-76.1801, 39.3776],
            [-76.2761, 39.3229],
            [-76.3644, 39.3118],
            [-76.4024, 39.2583],
            [-76.3844, 39.2427],
            [-76.4425, 39.1954],
            [-76.5359, 39.211],
            [-76.5258, 39.1779],
            [-76.4287, 39.1317],
            [-76.4231, 39.0742],
            [-76.4389, 39.0528],
            [-76.3941, 39.0113],
            [-76.4742, 38.9726],
            [-76.4503, 38.9411],
            [-76.4937, 38.91],
            [-76.5194, 38.8631],
            [-76.4989, 38.8175],
            [-76.5599, 38.7674],
            [-76.5272, 38.7271],
            [-76.5324, 38.6784],
            [-76.5113, 38.6159],
            [-76.5432, 38.6242],
            [-76.5644, 38.6302],
            [-76.571, 38.6762],
            [-76.6059, 38.6762],
            [-76.6413, 38.6613],
            [-76.6866, 38.6305],
            [-76.6976, 38.6974],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Richmond Mkt": {
    type: "Feature",
    properties: { code: "VA_RCH", name: "Richmond Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-78.1267, 38.3363],
            [-78.1488, 38.3808],
            [-78.0922, 38.3898],
            [-78.1267, 38.3363],
          ],
        ],
        [
          [
            [-78.2095, 38.27],
            [-78.1575, 38.3392],
            [-78.1267, 38.3363],
            [-78.0702, 38.31],
            [-78.0387, 38.2645],
            [-78.0186, 38.2696],
            [-77.9525, 38.314],
            [-77.9207, 38.2947],
            [-77.8821, 38.3064],
            [-77.883, 38.3368],
            [-77.8688, 38.3521],
            [-77.85, 38.3792],
            [-77.7876, 38.3779],
            [-77.7692, 38.3929],
            [-77.6958, 38.3565],
            [-77.6372, 38.3722],
            [-77.6187, 38.3678],
            [-77.6344, 38.4],
            [-77.6281, 38.4504],
            [-77.6339, 38.4669],
            [-77.5676, 38.5274],
            [-77.5533, 38.525],
            [-77.5353, 38.5082],
            [-77.4794, 38.4828],
            [-77.3604, 38.4922],
            [-77.3168, 38.4797],
            [-77.3254, 38.4488],
            [-77.2796, 38.3394],
            [-77.2401, 38.3316],
            [-77.1627, 38.346],
            [-77.1382, 38.3679],
            [-77.0848, 38.3683],
            [-77.0435, 38.4005],
            [-77.0118, 38.3746],
            [-77.0307, 38.3116],
            [-76.9622, 38.2565],
            [-76.9623, 38.2141],
            [-76.8388, 38.1635],
            [-76.7602, 38.1666],
            [-76.6139, 38.1486],
            [-76.6009, 38.1101],
            [-76.5432, 38.077],
            [-76.5165, 38.0266],
            [-76.4693, 38.0135],
            [-76.4163, 37.9668],
            [-76.3438, 37.9473],
            [-76.2367, 37.8892],
            [-76.2514, 37.8331],
            [-76.3074, 37.8124],
            [-76.3129, 37.7203],
            [-76.3001, 37.6954],
            [-76.3399, 37.656],
            [-76.3092, 37.6219],
            [-76.3623, 37.6104],
            [-76.4724, 37.6658],
            [-76.5102, 37.6423],
            [-76.5365, 37.6636],
            [-76.5372, 37.6989],
            [-76.5843, 37.7689],
            [-76.7016, 37.8227],
            [-76.7476, 37.8759],
            [-76.7846, 37.8696],
            [-76.7239, 37.7885],
            [-76.677, 37.7561],
            [-76.6197, 37.7448],
            [-76.5972, 37.7173],
            [-76.574, 37.6468],
            [-76.5272, 37.6113],
            [-76.4355, 37.6128],
            [-76.4108, 37.5818],
            [-76.3001, 37.5617],
            [-76.3594, 37.5134],
            [-76.3295, 37.4949],
            [-76.281, 37.5078],
            [-76.2524, 37.4473],
            [-76.2453, 37.3868],
            [-76.2756, 37.31],
            [-76.3375, 37.364],
            [-76.3871, 37.3851],
            [-76.4453, 37.3665],
            [-76.4064, 37.3329],
            [-76.3811, 37.2853],
            [-76.4291, 37.2533],
            [-76.4989, 37.2411],
            [-76.6007, 37.314],
            [-76.6158, 37.3],
            [-76.5974, 37.288],
            [-76.588, 37.2967],
            [-76.5774, 37.2895],
            [-76.5813, 37.2778],
            [-76.5922, 37.2832],
            [-76.6065, 37.2734],
            [-76.5864, 37.2792],
            [-76.5771, 37.2592],
            [-76.5866, 37.2735],
            [-76.6063, 37.2725],
            [-76.6268, 37.2644],
            [-76.6346, 37.2431],
            [-76.6263, 37.2333],
            [-76.6011, 37.2382],
            [-76.5777, 37.1904],
            [-76.611, 37.1671],
            [-76.6499, 37.2209],
            [-76.731, 37.2138],
            [-76.7505, 37.1901],
            [-76.7476, 37.1505],
            [-76.6803, 37.1268],
            [-76.6752, 37.1084],
            [-76.6842, 37.1039],
            [-76.6845, 37.0882],
            [-76.6956, 37.0833],
            [-76.6993, 37.0584],
            [-76.7928, 37.021],
            [-76.7742, 37.0022],
            [-76.78, 36.9871],
            [-76.7652, 36.9879],
            [-76.7603, 36.9711],
            [-76.7685, 36.9553],
            [-76.7822, 36.9498],
            [-76.7701, 36.9262],
            [-76.8094, 36.9112],
            [-76.7941, 36.8832],
            [-76.7755, 36.8777],
            [-76.7766, 36.8521],
            [-76.8051, 36.8483],
            [-76.7643, 36.837],
            [-76.763, 36.8211],
            [-76.7789, 36.8312],
            [-76.8054, 36.8268],
            [-76.7951, 36.8154],
            [-76.8014, 36.8077],
            [-76.7921, 36.802],
            [-76.7987, 36.7924],
            [-76.8429, 36.7825],
            [-76.8532, 36.7739],
            [-76.8607, 36.779],
            [-76.8679, 36.7746],
            [-76.8738, 36.7837],
            [-76.8841, 36.7808],
            [-76.8848, 36.7646],
            [-76.9014, 36.7573],
            [-76.8947, 36.7468],
            [-76.9168, 36.7341],
            [-76.9029, 36.7387],
            [-76.9088, 36.7219],
            [-76.8826, 36.7201],
            [-76.8758, 36.7417],
            [-76.8649, 36.741],
            [-76.8689, 36.721],
            [-76.8562, 36.7221],
            [-76.8545, 36.7118],
            [-76.8319, 36.7094],
            [-76.8603, 36.6852],
            [-76.8832, 36.6804],
            [-76.8565, 36.6738],
            [-76.8977, 36.644],
            [-76.8933, 36.6262],
            [-76.9115, 36.6177],
            [-76.9093, 36.5797],
            [-76.9203, 36.5776],
            [-76.9293, 36.5606],
            [-76.9163, 36.5542],
            [-76.916, 36.5438],
            [-78.0462, 36.5442],
            [-78.044, 36.5704],
            [-77.9911, 36.5784],
            [-77.9566, 36.6265],
            [-77.8981, 36.6473],
            [-77.917, 36.6784],
            [-77.8788, 36.6995],
            [-77.855, 36.7503],
            [-77.9093, 36.7866],
            [-77.9302, 36.8359],
            [-78.0147, 36.8094],
            [-78.0254, 36.7976],
            [-78.0181, 36.8742],
            [-77.9699, 36.8559],
            [-77.9683, 36.9309],
            [-78.0098, 36.9599],
            [-78.0036, 37.0228],
            [-78.0793, 37.0105],
            [-78.1007, 37.0253],
            [-78.1104, 37.0304],
            [-78.1253, 37.0927],
            [-78.0573, 37.0865],
            [-77.9858, 37.2092],
            [-77.9937, 37.2195],
            [-77.9845, 37.2393],
            [-78.0721, 37.2597],
            [-78.1288, 37.2684],
            [-78.13, 37.2724],
            [-78.2312, 37.2962],
            [-78.221, 37.3107],
            [-78.1866, 37.3363],
            [-78.2093, 37.3707],
            [-78.2197, 37.44],
            [-78.252, 37.4196],
            [-78.3472, 37.4243],
            [-78.3909, 37.4428],
            [-78.4155, 37.484],
            [-78.3526, 37.5296],
            [-78.2893, 37.5548],
            [-78.2818, 37.6161],
            [-78.3141, 37.6133],
            [-78.3617, 37.6048],
            [-78.3869, 37.6322],
            [-78.4426, 37.609],
            [-78.4598, 37.6211],
            [-78.4944, 37.658],
            [-78.4804, 37.6705],
            [-78.4678, 37.7136],
            [-78.3611, 37.8036],
            [-78.3927, 37.8329],
            [-78.398, 37.8536],
            [-78.3661, 37.8772],
            [-78.461, 37.9654],
            [-78.5002, 37.9359],
            [-78.4982, 37.8718],
            [-78.5386, 37.8931],
            [-78.5553, 37.8649],
            [-78.5399, 37.8107],
            [-78.6481, 37.734],
            [-78.6852, 37.7389],
            [-78.7337, 37.7328],
            [-78.8837, 37.6193],
            [-78.9044, 37.6439],
            [-78.9557, 37.6489],
            [-78.9878, 37.6982],
            [-79.0579, 37.6926],
            [-79.1015, 37.7208],
            [-79.1265, 37.7575],
            [-79.1141, 37.7846],
            [-79.138, 37.8141],
            [-79.1577, 37.8199],
            [-79.159, 37.8386],
            [-79.0484, 37.859],
            [-79.0623, 37.9175],
            [-79.0278, 37.9797],
            [-78.979, 37.9658],
            [-78.9739, 38.0347],
            [-78.9968, 38.0446],
            [-79.0122, 38.0726],
            [-79.032, 38.0958],
            [-78.9779, 38.1413],
            [-78.9455, 38.1569],
            [-78.8868, 38.1669],
            [-78.8145, 38.1433],
            [-78.7961, 38.1889],
            [-78.7683, 38.1973],
            [-78.7066, 38.2464],
            [-78.6819, 38.2549],
            [-78.6698, 38.2473],
            [-78.6563, 38.2605],
            [-78.6639, 38.2767],
            [-78.6519, 38.2936],
            [-78.6025, 38.3072],
            [-78.5878, 38.3249],
            [-78.5516, 38.3363],
            [-78.5543, 38.3491],
            [-78.5451, 38.3624],
            [-78.5264, 38.368],
            [-78.5154, 38.3938],
            [-78.4989, 38.4002],
            [-78.4529, 38.4755],
            [-78.4352, 38.4506],
            [-78.4291, 38.3665],
            [-78.3829, 38.3188],
            [-78.3408, 38.2804],
            [-78.2947, 38.2764],
            [-78.2507, 38.234],
            [-78.2295, 38.2575],
            [-78.2095, 38.27],
          ],
          [
            [-77.9475, 36.5877],
            [-77.9095, 36.5563],
            [-77.8733, 36.6136],
            [-77.9347, 36.61],
            [-77.9475, 36.5877],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Alexandria Mkt": {
    type: "Feature",
    properties: { code: "VA_ALE", name: "Alexandria Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-77.0855, 38.7061],
          [-77.0786, 38.6941],
          [-77.005, 38.6953],
          [-76.8605, 38.7363],
          [-76.8416, 38.756],
          [-76.7505, 38.6933],
          [-76.6976, 38.6974],
          [-76.6866, 38.6305],
          [-76.6413, 38.6613],
          [-76.6059, 38.6762],
          [-76.5798, 38.6774],
          [-76.5646, 38.6676],
          [-76.5432, 38.6242],
          [-76.5113, 38.6159],
          [-76.5175, 38.5391],
          [-76.4927, 38.4828],
          [-76.3862, 38.382],
          [-76.4093, 38.3259],
          [-76.3745, 38.2963],
          [-76.3993, 38.2593],
          [-76.3852, 38.2178],
          [-76.3201, 38.1383],
          [-76.3385, 38.1195],
          [-76.3195, 38.0433],
          [-76.3612, 38.0595],
          [-76.3923, 38.1029],
          [-76.4398, 38.1389],
          [-76.5299, 38.1341],
          [-76.553, 38.1872],
          [-76.5906, 38.2142],
          [-76.6735, 38.2344],
          [-76.7786, 38.2285],
          [-76.9209, 38.2916],
          [-76.9296, 38.3211],
          [-76.9755, 38.3473],
          [-77.0164, 38.4456],
          [-77.0442, 38.443],
          [-77.0911, 38.4075],
          [-77.2073, 38.3599],
          [-77.2502, 38.3828],
          [-77.2742, 38.4818],
          [-77.2377, 38.5519],
          [-77.1838, 38.6007],
          [-77.1291, 38.6144],
          [-77.1315, 38.6351],
          [-77.202, 38.6172],
          [-77.2467, 38.6352],
          [-77.247, 38.5906],
          [-77.3168, 38.4797],
          [-77.3604, 38.4922],
          [-77.4794, 38.4828],
          [-77.5353, 38.5082],
          [-77.5558, 38.5265],
          [-77.572, 38.525],
          [-77.5712, 38.518],
          [-77.6338, 38.4669],
          [-77.6281, 38.4504],
          [-77.6344, 38.4],
          [-77.6187, 38.3678],
          [-77.6372, 38.3722],
          [-77.6958, 38.3565],
          [-77.7692, 38.3929],
          [-77.7876, 38.3779],
          [-77.85, 38.3792],
          [-77.8688, 38.3521],
          [-77.883, 38.3368],
          [-77.8821, 38.3064],
          [-77.9207, 38.2947],
          [-77.9525, 38.314],
          [-78.0186, 38.2696],
          [-78.0387, 38.2645],
          [-78.0702, 38.31],
          [-78.1267, 38.3363],
          [-78.1575, 38.3392],
          [-78.2095, 38.27],
          [-78.2295, 38.2575],
          [-78.2507, 38.234],
          [-78.2947, 38.2764],
          [-78.3408, 38.2804],
          [-78.3829, 38.3188],
          [-78.4291, 38.3665],
          [-78.4352, 38.4506],
          [-78.4529, 38.4755],
          [-78.4445, 38.4894],
          [-78.4362, 38.5217],
          [-78.4429, 38.5278],
          [-78.4303, 38.5269],
          [-78.4133, 38.5477],
          [-78.3854, 38.5576],
          [-78.3833, 38.5883],
          [-78.3627, 38.6122],
          [-78.3259, 38.6307],
          [-78.3156, 38.6516],
          [-78.324, 38.6727],
          [-78.3144, 38.6825],
          [-78.3322, 38.7056],
          [-78.3188, 38.7379],
          [-78.3035, 38.7389],
          [-78.2844, 38.7598],
          [-78.2533, 38.757],
          [-78.2155, 38.7741],
          [-78.166, 38.778],
          [-78.158, 38.7702],
          [-78.1632, 38.7565],
          [-78.1344, 38.7405],
          [-78.1299, 38.7274],
          [-78.1175, 38.7208],
          [-78.0972, 38.7252],
          [-78.0666, 38.719],
          [-78.0734, 38.7349],
          [-78.0454, 38.7597],
          [-78.017, 38.762],
          [-78.0012, 38.7754],
          [-77.9777, 38.7639],
          [-77.9855, 38.7821],
          [-77.9745, 38.7936],
          [-77.9874, 38.806],
          [-77.9899, 38.8251],
          [-77.9887, 38.8332],
          [-77.9675, 38.833],
          [-77.9652, 38.8476],
          [-77.9791, 38.8535],
          [-77.9654, 38.8711],
          [-77.9949, 38.8692],
          [-78.0039, 38.88],
          [-78.0021, 38.9035],
          [-77.971, 38.9087],
          [-77.9982, 38.9079],
          [-78.0009, 38.9455],
          [-77.9908, 38.9565],
          [-77.9732, 38.9602],
          [-77.949, 38.9853],
          [-77.9541, 39.0086],
          [-77.9828, 39.03],
          [-77.9566, 39.0476],
          [-77.9464, 39.0712],
          [-77.9591, 39.0766],
          [-77.9532, 39.0883],
          [-77.973, 39.0998],
          [-77.9419, 39.0936],
          [-77.9072, 39.1053],
          [-77.8609, 39.1535],
          [-77.8282, 39.1323],
          [-77.7936, 39.2101],
          [-77.7714, 39.2368],
          [-77.7707, 39.2553],
          [-77.73, 39.3157],
          [-77.6758, 39.3242],
          [-77.6159, 39.3027],
          [-77.5666, 39.3061],
          [-77.5609, 39.2862],
          [-77.5406, 39.2649],
          [-77.4868, 39.2476],
          [-77.4577, 39.225],
          [-77.4786, 39.1892],
          [-77.5106, 39.1785],
          [-77.5199, 39.1209],
          [-77.4858, 39.1093],
          [-77.4626, 39.0762],
          [-77.3857, 39.062],
          [-77.3403, 39.063],
          [-77.2931, 39.0465],
          [-77.246, 39.0249],
          [-77.2557, 39.0024],
          [-77.2498, 38.9859],
          [-77.2215, 38.9713],
          [-77.1466, 38.9642],
          [-77.1179, 38.9324],
          [-77.041, 38.9951],
          [-76.9094, 38.8928],
          [-77.0401, 38.7899],
          [-77.0423, 38.7185],
          [-77.0532, 38.7099],
          [-77.0855, 38.7061],
        ],
        [
          [-78.1267, 38.3363],
          [-78.0922, 38.3898],
          [-78.1488, 38.3808],
          [-78.1267, 38.3363],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Roanoke Mkt": {
    type: "Feature",
    properties: { code: "VA_ROA", name: "Roanoke Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-77.9475, 36.5877],
            [-77.9347, 36.61],
            [-77.8733, 36.6136],
            [-77.9095, 36.5563],
            [-77.9475, 36.5877],
          ],
        ],
        [
          [
            [-80.2239, 37.6253],
            [-80.2675, 37.6461],
            [-80.2961, 37.6918],
            [-80.2531, 37.7259],
            [-80.2628, 37.7383],
            [-80.2574, 37.7561],
            [-80.2305, 37.7783],
            [-80.2157, 37.7775],
            [-80.2271, 37.7989],
            [-80.1794, 37.8398],
            [-80.1831, 37.8506],
            [-80.1622, 37.8751],
            [-80.1177, 37.8977],
            [-80.1029, 37.9189],
            [-79.9994, 37.9958],
            [-79.9544, 38.0804],
            [-79.9263, 38.1072],
            [-79.939, 38.1116],
            [-79.9448, 38.1316],
            [-79.9187, 38.1548],
            [-79.9163, 38.1863],
            [-79.8984, 38.193],
            [-79.8452, 38.2411],
            [-79.8196, 38.2482],
            [-79.812, 38.2604],
            [-79.7889, 38.2687],
            [-79.8102, 38.3067],
            [-79.7731, 38.3355],
            [-79.7644, 38.3565],
            [-79.7406, 38.3541],
            [-79.7256, 38.3638],
            [-79.7317, 38.3734],
            [-79.6897, 38.4314],
            [-79.6976, 38.4872],
            [-79.6804, 38.5106],
            [-79.6705, 38.5077],
            [-79.6621, 38.5155],
            [-79.673, 38.5287],
            [-79.6491, 38.5915],
            [-79.5369, 38.5509],
            [-79.4766, 38.4572],
            [-79.2982, 38.4124],
            [-79.2824, 38.3989],
            [-79.2825, 38.3778],
            [-79.2403, 38.3365],
            [-79.2459, 38.3344],
            [-79.2195, 38.325],
            [-79.2023, 38.3311],
            [-79.1917, 38.3143],
            [-79.1638, 38.2964],
            [-79.1029, 38.2862],
            [-79.0913, 38.2736],
            [-79.0544, 38.2622],
            [-79.0499, 38.2497],
            [-78.9978, 38.2911],
            [-79.0157, 38.3014],
            [-79.004, 38.3136],
            [-78.9873, 38.3164],
            [-78.984, 38.3032],
            [-78.9704, 38.3034],
            [-78.9602, 38.3255],
            [-78.9305, 38.3135],
            [-78.9333, 38.3456],
            [-78.9314, 38.3386],
            [-78.9252, 38.3448],
            [-78.9126, 38.34],
            [-78.9173, 38.3342],
            [-78.9252, 38.3387],
            [-78.9261, 38.3305],
            [-78.9106, 38.3242],
            [-78.9146, 38.3043],
            [-78.8937, 38.306],
            [-78.8554, 38.2785],
            [-78.8529, 38.2948],
            [-78.8329, 38.3087],
            [-78.8105, 38.2979],
            [-78.8217, 38.3049],
            [-78.8215, 38.3171],
            [-78.8353, 38.3237],
            [-78.842, 38.3181],
            [-78.8554, 38.3448],
            [-78.8258, 38.3717],
            [-78.8083, 38.3372],
            [-78.8133, 38.3179],
            [-78.7952, 38.3105],
            [-78.7786, 38.3224],
            [-78.7708, 38.312],
            [-78.7549, 38.3218],
            [-78.7319, 38.3195],
            [-78.7557, 38.3172],
            [-78.7349, 38.2977],
            [-78.751, 38.2904],
            [-78.77, 38.2573],
            [-78.7168, 38.2493],
            [-78.7683, 38.1973],
            [-78.7961, 38.1889],
            [-78.8145, 38.1433],
            [-78.8868, 38.1669],
            [-78.9455, 38.1569],
            [-78.9779, 38.1413],
            [-79.032, 38.0958],
            [-79.0122, 38.0726],
            [-78.9968, 38.0446],
            [-78.9739, 38.0347],
            [-78.979, 37.9658],
            [-79.0278, 37.9797],
            [-79.0623, 37.9175],
            [-79.0484, 37.859],
            [-79.159, 37.8386],
            [-79.1577, 37.8199],
            [-79.138, 37.8141],
            [-79.1141, 37.7846],
            [-79.1265, 37.7575],
            [-79.1015, 37.7208],
            [-79.0579, 37.6926],
            [-78.9878, 37.6982],
            [-78.9557, 37.6489],
            [-78.9044, 37.6439],
            [-78.8837, 37.6193],
            [-78.7337, 37.7328],
            [-78.6852, 37.7389],
            [-78.6481, 37.734],
            [-78.5399, 37.8107],
            [-78.5553, 37.8649],
            [-78.5386, 37.8931],
            [-78.4982, 37.8718],
            [-78.5002, 37.9359],
            [-78.461, 37.9654],
            [-78.3661, 37.8772],
            [-78.398, 37.8536],
            [-78.3927, 37.8329],
            [-78.3611, 37.8036],
            [-78.4678, 37.7136],
            [-78.4804, 37.6705],
            [-78.4944, 37.658],
            [-78.4598, 37.6211],
            [-78.4426, 37.609],
            [-78.3869, 37.6322],
            [-78.3617, 37.6048],
            [-78.3141, 37.6133],
            [-78.2818, 37.6161],
            [-78.2893, 37.5548],
            [-78.3526, 37.5296],
            [-78.4155, 37.484],
            [-78.3909, 37.4428],
            [-78.3472, 37.4243],
            [-78.252, 37.4196],
            [-78.2197, 37.44],
            [-78.2093, 37.3707],
            [-78.1866, 37.3363],
            [-78.221, 37.3107],
            [-78.2312, 37.2962],
            [-78.13, 37.2724],
            [-78.1288, 37.2684],
            [-78.0721, 37.2597],
            [-77.9844, 37.2398],
            [-77.9937, 37.2195],
            [-77.9858, 37.2092],
            [-78.0573, 37.0865],
            [-78.1253, 37.0927],
            [-78.1104, 37.0304],
            [-78.1007, 37.0253],
            [-78.0826, 37.0111],
            [-78.0036, 37.0228],
            [-78.0098, 36.9599],
            [-77.9683, 36.9309],
            [-77.9699, 36.8559],
            [-78.0181, 36.8741],
            [-78.0147, 36.8094],
            [-77.9302, 36.8359],
            [-77.9093, 36.7866],
            [-77.855, 36.7503],
            [-77.8788, 36.6995],
            [-77.917, 36.6784],
            [-77.8981, 36.6473],
            [-77.9566, 36.6265],
            [-77.9911, 36.5784],
            [-78.044, 36.5704],
            [-78.0462, 36.5442],
            [-79.2497, 36.5411],
            [-80.2952, 36.544],
            [-80.7048, 36.5623],
            [-80.8376, 36.5591],
            [-81.5223, 36.5806],
            [-81.5259, 36.6559],
            [-81.5655, 36.6578],
            [-81.6191, 36.6343],
            [-81.6484, 36.6909],
            [-81.7031, 36.6648],
            [-81.7527, 36.6794],
            [-81.7714, 36.6611],
            [-81.8509, 36.6571],
            [-81.8553, 36.6903],
            [-81.8855, 36.7466],
            [-81.9253, 36.7489],
            [-81.8671, 36.8237],
            [-81.9771, 36.8321],
            [-81.9989, 36.8537],
            [-82.0211, 36.8589],
            [-81.9762, 36.8698],
            [-81.9469, 36.8648],
            [-81.8847, 36.8896],
            [-81.8059, 36.9523],
            [-81.6778, 36.9837],
            [-81.6315, 37.0091],
            [-81.6059, 37.01],
            [-81.5597, 37.0329],
            [-81.4904, 37.046],
            [-81.4819, 37.035],
            [-81.4572, 37.0307],
            [-81.4575, 37.0392],
            [-81.3992, 37.0578],
            [-81.377, 37.0536],
            [-81.2893, 37.0833],
            [-81.2705, 37.0926],
            [-81.264, 37.1243],
            [-81.3281, 37.139],
            [-81.3282, 37.1509],
            [-81.3417, 37.1562],
            [-81.2975, 37.1768],
            [-81.3054, 37.1868],
            [-81.2754, 37.1968],
            [-81.2848, 37.2159],
            [-81.2434, 37.2242],
            [-81.167, 37.2629],
            [-81.1126, 37.2785],
            [-80.9796, 37.3023],
            [-80.9813, 37.2935],
            [-80.9666, 37.2922],
            [-80.9005, 37.315],
            [-80.8495, 37.3469],
            [-80.8832, 37.3839],
            [-80.8596, 37.4296],
            [-80.8377, 37.4257],
            [-80.8061, 37.3981],
            [-80.7842, 37.3946],
            [-80.7701, 37.3724],
            [-80.6459, 37.4221],
            [-80.552, 37.4736],
            [-80.5114, 37.4817],
            [-80.493, 37.4577],
            [-80.4949, 37.4351],
            [-80.4758, 37.4231],
            [-80.372, 37.4741],
            [-80.3668, 37.4849],
            [-80.2998, 37.5083],
            [-80.3303, 37.5362],
            [-80.3124, 37.5462],
            [-80.3285, 37.5643],
            [-80.2589, 37.5955],
            [-80.2239, 37.6253],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Austin Mkt": {
    type: "Feature",
    properties: { code: "TX_AUS", name: "Austin Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-100.0729, 31.385],
            [-100.044, 31.3769],
            [-100.0739, 31.3698],
            [-100.0729, 31.385],
          ],
        ],
        [
          [
            [-99.4573, 32.0801],
            [-99.1959, 32.0792],
            [-99.1969, 32.0384],
            [-99.1483, 32.012],
            [-99.0752, 32.0022],
            [-98.9829, 32.0367],
            [-98.904, 32.0302],
            [-98.7881, 31.8782],
            [-98.8363, 31.8774],
            [-98.9006, 31.8295],
            [-98.9036, 31.7594],
            [-98.8691, 31.7743],
            [-98.7516, 31.7747],
            [-98.7258, 31.7854],
            [-98.6586, 31.6873],
            [-98.6135, 31.689],
            [-98.5511, 31.6737],
            [-98.5467, 31.6963],
            [-98.4868, 31.6773],
            [-98.4951, 31.6506],
            [-98.4221, 31.6274],
            [-98.4637, 31.684],
            [-98.4436, 31.7349],
            [-98.3938, 31.7201],
            [-98.2401, 31.7989],
            [-98.2654, 31.8244],
            [-98.2551, 31.9244],
            [-98.2039, 31.9109],
            [-98.1585, 31.8674],
            [-98.0594, 31.8321],
            [-98.0008, 31.8269],
            [-98.0197, 31.7507],
            [-97.9421, 31.7902],
            [-97.9529, 31.7522],
            [-97.9169, 31.7036],
            [-97.8696, 31.6765],
            [-97.8108, 31.7119],
            [-97.7414, 31.6526],
            [-97.7117, 31.6514],
            [-97.7198, 31.6304],
            [-97.6453, 31.6264],
            [-97.625, 31.5995],
            [-97.6223, 31.5465],
            [-97.5807, 31.5513],
            [-97.5239, 31.4714],
            [-97.4829, 31.4429],
            [-97.4984, 31.3596],
            [-97.4612, 31.3396],
            [-97.465, 31.3991],
            [-97.3832, 31.3975],
            [-97.3604, 31.3361],
            [-97.3175, 31.3743],
            [-97.3513, 31.4136],
            [-97.314, 31.4218],
            [-97.2656, 31.3925],
            [-97.2277, 31.3047],
            [-97.1977, 31.3203],
            [-97.1471, 31.2524],
            [-97.1248, 31.1776],
            [-97.1712, 31.1714],
            [-97.1668, 31.1229],
            [-97.1293, 31.0703],
            [-97.0871, 31.0879],
            [-97.0911, 31.1275],
            [-97.0207, 31.1667],
            [-96.9708, 31.0893],
            [-96.9515, 31.1355],
            [-96.9786, 31.1647],
            [-96.9305, 31.1895],
            [-96.874, 31.1815],
            [-96.8283, 31.1436],
            [-96.8307, 31.1004],
            [-96.7861, 31.0615],
            [-96.7797, 31.0327],
            [-96.7696, 31.025],
            [-96.7711, 31.0021],
            [-96.7557, 31.0054],
            [-96.746, 30.995],
            [-96.7726, 30.9677],
            [-96.7559, 30.9455],
            [-96.7569, 30.9279],
            [-96.7437, 30.9157],
            [-96.731, 30.9165],
            [-96.7344, 30.9045],
            [-96.7264, 30.8987],
            [-96.6924, 30.9093],
            [-96.6832, 30.902],
            [-96.6973, 30.8977],
            [-96.6833, 30.8815],
            [-96.695, 30.8781],
            [-96.6956, 30.8672],
            [-96.679, 30.8441],
            [-96.6961, 30.8499],
            [-96.6974, 30.8413],
            [-96.7105, 30.8449],
            [-96.711, 30.8356],
            [-96.7213, 30.8479],
            [-96.7456, 30.8146],
            [-96.7269, 30.7815],
            [-96.7659, 30.7629],
            [-96.7629, 30.7552],
            [-96.739, 30.772],
            [-96.72, 30.762],
            [-96.7145, 30.7534],
            [-96.7234, 30.7465],
            [-96.7007, 30.6901],
            [-96.8779, 30.6007],
            [-96.8449, 30.5951],
            [-96.8208, 30.6012],
            [-96.7861, 30.588],
            [-96.8169, 30.5682],
            [-96.8106, 30.5458],
            [-96.8663, 30.5173],
            [-96.8499, 30.4799],
            [-96.871, 30.474],
            [-96.8796, 30.4885],
            [-96.9019, 30.4819],
            [-96.8999, 30.4602],
            [-96.8749, 30.4576],
            [-96.8682, 30.4338],
            [-96.8893, 30.4207],
            [-96.8764, 30.3849],
            [-96.8907, 30.396],
            [-96.8989, 30.394],
            [-96.9016, 30.4001],
            [-96.9121, 30.3856],
            [-96.9172, 30.3545],
            [-96.9047, 30.3394],
            [-96.9249, 30.3387],
            [-96.9225, 30.3247],
            [-96.9069, 30.336],
            [-96.899, 30.3305],
            [-96.8976, 30.3391],
            [-96.8771, 30.3302],
            [-96.8662, 30.3358],
            [-96.8446, 30.3128],
            [-96.8284, 30.3087],
            [-96.8325, 30.2845],
            [-96.8167, 30.2705],
            [-96.7892, 30.2812],
            [-96.8134, 30.2953],
            [-96.8157, 30.3118],
            [-96.8011, 30.311],
            [-96.7729, 30.3271],
            [-96.7492, 30.3183],
            [-96.7309, 30.3242],
            [-96.6949, 30.3203],
            [-96.6642, 30.2957],
            [-96.6406, 30.2967],
            [-96.6577, 30.2879],
            [-96.6856, 30.2492],
            [-96.7101, 30.2452],
            [-96.7181, 30.2504],
            [-96.7304, 30.2433],
            [-96.7442, 30.2175],
            [-96.7307, 30.1923],
            [-96.7082, 30.1841],
            [-96.7079, 30.1615],
            [-96.6834, 30.151],
            [-96.6472, 30.1452],
            [-96.6795, 30.1062],
            [-96.6653, 30.0939],
            [-96.6788, 30.0778],
            [-96.6549, 30.0757],
            [-96.6347, 30.0914],
            [-96.622, 30.0443],
            [-96.594, 30.0711],
            [-96.586, 30.0647],
            [-96.541, 30.065],
            [-96.5414, 30.0573],
            [-96.5157, 30.0585],
            [-96.5167, 30.0451],
            [-96.5022, 30.0412],
            [-96.4902, 30.0262],
            [-96.4833, 30.0373],
            [-96.4705, 30.0269],
            [-96.4842, 30.0132],
            [-96.4804, 29.9994],
            [-96.4665, 29.9917],
            [-96.448, 29.9994],
            [-96.4449, 29.9927],
            [-96.464, 29.9805],
            [-96.4481, 29.9705],
            [-96.4185, 29.9883],
            [-96.4069, 29.9789],
            [-96.4079, 29.9722],
            [-96.3902, 29.9603],
            [-96.3968, 29.9444],
            [-96.3684, 29.9419],
            [-96.3631, 29.9353],
            [-96.3841, 29.9263],
            [-96.3733, 29.9166],
            [-96.361, 29.9171],
            [-96.3576, 29.9038],
            [-96.3696, 29.8946],
            [-96.3901, 29.8998],
            [-96.3963, 29.8814],
            [-96.4262, 29.8863],
            [-96.4389, 29.8844],
            [-96.4352, 29.8779],
            [-96.446, 29.8747],
            [-96.4884, 29.8887],
            [-96.4952, 29.8752],
            [-96.4582, 29.8553],
            [-96.4411, 29.8536],
            [-96.4128, 29.8246],
            [-96.4106, 29.8386],
            [-96.3982, 29.847],
            [-96.4052, 29.8589],
            [-96.3964, 29.868],
            [-96.3751, 29.856],
            [-96.3269, 29.8467],
            [-96.3448, 29.8217],
            [-96.3391, 29.8167],
            [-96.3488, 29.8178],
            [-96.3579, 29.8014],
            [-96.3336, 29.7814],
            [-96.3367, 29.7744],
            [-96.3242, 29.759],
            [-96.3408, 29.7706],
            [-96.3485, 29.7539],
            [-96.2966, 29.7485],
            [-96.2915, 29.7285],
            [-96.27, 29.7163],
            [-96.2776, 29.6998],
            [-96.2695, 29.6891],
            [-96.2908, 29.6874],
            [-96.3433, 29.6616],
            [-96.3736, 29.6902],
            [-96.4057, 29.6613],
            [-96.3992, 29.6507],
            [-96.4039, 29.6043],
            [-96.4071, 29.587],
            [-96.4175, 29.58],
            [-96.4277, 29.5911],
            [-96.4501, 29.593],
            [-96.4639, 29.6083],
            [-96.5044, 29.617],
            [-96.5175, 29.6474],
            [-96.5328, 29.6346],
            [-96.5324, 29.6142],
            [-96.5509, 29.6185],
            [-96.5418, 29.5478],
            [-96.6241, 29.5147],
            [-96.6266, 29.5223],
            [-96.6285, 29.5129],
            [-96.6389, 29.5088],
            [-96.6516, 29.5181],
            [-96.6574, 29.5386],
            [-96.7074, 29.5173],
            [-96.7015, 29.512],
            [-96.7057, 29.4941],
            [-96.7152, 29.5104],
            [-96.7328, 29.5214],
            [-96.7387, 29.5207],
            [-96.7648, 29.5479],
            [-96.7723, 29.5772],
            [-96.8329, 29.5769],
            [-96.8351, 29.5683],
            [-96.8256, 29.5587],
            [-96.8471, 29.5606],
            [-96.8535, 29.5551],
            [-96.9052, 29.5753],
            [-96.9069, 29.5815],
            [-96.9256, 29.5815],
            [-96.9356, 29.5957],
            [-96.9567, 29.598],
            [-96.9658, 29.5901],
            [-96.9377, 29.5873],
            [-96.9526, 29.5793],
            [-96.9948, 29.5898],
            [-96.9965, 29.6142],
            [-97.0208, 29.6171],
            [-97.0209, 29.6302],
            [-97.1433, 29.6281],
            [-97.2041, 29.5806],
            [-97.2039, 29.572],
            [-97.2082, 29.5853],
            [-97.2538, 29.6004],
            [-97.2675, 29.5928],
            [-97.2724, 29.5708],
            [-97.2853, 29.5669],
            [-97.26, 29.5544],
            [-97.219, 29.5558],
            [-97.2377, 29.5234],
            [-97.2219, 29.519],
            [-97.2313, 29.5081],
            [-97.2411, 29.5132],
            [-97.2576, 29.5042],
            [-97.3149, 29.5073],
            [-97.3144, 29.4195],
            [-97.325, 29.3989],
            [-97.3106, 29.4045],
            [-97.2987, 29.3874],
            [-97.2865, 29.4004],
            [-97.2844, 29.3878],
            [-97.2664, 29.3767],
            [-97.269, 29.3635],
            [-97.387, 29.2771],
            [-97.3859, 29.2885],
            [-97.4547, 29.3387],
            [-97.4613, 29.3409],
            [-97.4752, 29.3135],
            [-97.4836, 29.3156],
            [-97.4776, 29.335],
            [-97.5065, 29.3528],
            [-97.495, 29.3727],
            [-97.5183, 29.3719],
            [-97.5268, 29.3728],
            [-97.5508, 29.3839],
            [-97.6292, 29.315],
            [-97.6671, 29.3535],
            [-97.6407, 29.3987],
            [-97.6189, 29.4101],
            [-97.6523, 29.417],
            [-97.6691, 29.4413],
            [-97.6843, 29.4424],
            [-97.6713, 29.4787],
            [-97.6935, 29.4967],
            [-97.7225, 29.5051],
            [-97.7476, 29.4988],
            [-97.7224, 29.5319],
            [-97.7749, 29.5456],
            [-97.7732, 29.5728],
            [-97.8005, 29.5753],
            [-97.8049, 29.6104],
            [-97.8292, 29.6172],
            [-97.8283, 29.649],
            [-97.8446, 29.6608],
            [-97.8702, 29.6686],
            [-97.8831, 29.6684],
            [-97.8882, 29.6808],
            [-97.9008, 29.6742],
            [-97.8985, 29.684],
            [-97.8982, 29.6999],
            [-97.9251, 29.7252],
            [-97.962, 29.7157],
            [-97.9482, 29.753],
            [-97.9753, 29.7561],
            [-97.986, 29.7654],
            [-97.9993, 29.7524],
            [-98.0305, 29.8485],
            [-98.1516, 29.9343],
            [-98.1841, 29.9304],
            [-98.2026, 29.9372],
            [-98.2257, 29.9522],
            [-98.2353, 29.9691],
            [-98.2765, 29.9782],
            [-98.325, 30.0141],
            [-98.3563, 29.9872],
            [-98.3805, 29.9663],
            [-98.3852, 29.975],
            [-98.4115, 29.972],
            [-98.4055, 30.0019],
            [-98.4194, 29.9986],
            [-98.4646, 30.0235],
            [-98.4846, 30.0194],
            [-98.5537, 30.099],
            [-98.5769, 30.0899],
            [-98.5769, 30.1019],
            [-98.6045, 30.1087],
            [-98.6122, 30.0875],
            [-98.6301, 30.0708],
            [-98.6404, 30.0891],
            [-98.6533, 30.0896],
            [-98.6544, 30.1001],
            [-98.6734, 30.092],
            [-98.6567, 30.1098],
            [-98.69, 30.1387],
            [-98.7302, 30.1386],
            [-98.7345, 30.1198],
            [-98.7524, 30.1048],
            [-98.7395, 30.0737],
            [-98.751, 30.0672],
            [-98.7381, 30.0667],
            [-98.7314, 30.0323],
            [-98.749, 30.03],
            [-98.7552, 30.0132],
            [-98.7742, 30.0093],
            [-98.7784, 30.016],
            [-98.7837, 30.0075],
            [-98.8132, 30.0041],
            [-98.8189, 30.0098],
            [-98.8326, 30.0079],
            [-98.835, 30.0145],
            [-98.8209, 30.026],
            [-98.8324, 30.0404],
            [-98.8298, 30.0502],
            [-98.8089, 30.0504],
            [-98.8231, 30.0592],
            [-98.8107, 30.074],
            [-98.8226, 30.0751],
            [-98.8192, 30.122],
            [-98.8381, 30.1191],
            [-98.8631, 30.1364],
            [-98.8824, 30.1307],
            [-98.9007, 30.1383],
            [-99.1248, 30.1361],
            [-99.1097, 30.1384],
            [-99.1089, 30.153],
            [-99.1202, 30.1942],
            [-99.1647, 30.1944],
            [-99.1737, 30.1861],
            [-99.2231, 30.1751],
            [-99.2269, 30.1512],
            [-99.2145, 30.1352],
            [-99.301, 30.1344],
            [-99.3014, 30.2009],
            [-99.3268, 30.214],
            [-99.3267, 30.2428],
            [-99.3869, 30.2461],
            [-99.3934, 30.2412],
            [-99.3905, 30.2268],
            [-99.4041, 30.2218],
            [-99.3953, 30.2588],
            [-99.3992, 30.2879],
            [-99.7542, 30.2906],
            [-100.1165, 30.2903],
            [-100.1167, 30.3729],
            [-100.1537, 30.3697],
            [-100.1574, 30.3782],
            [-100.1901, 30.379],
            [-100.1794, 30.4245],
            [-100.1167, 30.4485],
            [-100.1167, 30.4664],
            [-100.1911, 30.4484],
            [-100.1783, 30.4713],
            [-100.1671, 30.4753],
            [-100.1818, 30.5027],
            [-100.174, 30.5256],
            [-100.1674, 30.5326],
            [-100.1397, 30.5044],
            [-100.145, 30.484],
            [-100.123, 30.4787],
            [-100.1166, 30.4836],
            [-100.1167, 30.8099],
            [-100.1135, 31.2511],
            [-100.0985, 31.2583],
            [-100.0978, 31.3052],
            [-100.0689, 31.3154],
            [-100.0392, 31.3397],
            [-100.0366, 31.3635],
            [-100.0191, 31.3873],
            [-100.0562, 31.3996],
            [-100.056, 31.4146],
            [-100.0894, 31.4141],
            [-100.1126, 31.4296],
            [-100.112, 31.4892],
            [-100.1578, 31.5042],
            [-100.1578, 31.4822],
            [-100.1405, 31.482],
            [-100.141, 31.4595],
            [-100.158, 31.4748],
            [-100.2258, 31.4761],
            [-100.2255, 31.4546],
            [-100.236, 31.4558],
            [-100.2596, 31.4909],
            [-100.2694, 31.4926],
            [-100.2681, 31.4978],
            [-100.2566, 31.4964],
            [-100.2563, 31.5267],
            [-100.2196, 31.5091],
            [-100.2178, 31.5353],
            [-100.2313, 31.541],
            [-100.271, 31.5188],
            [-100.2836, 31.5348],
            [-100.2723, 31.5426],
            [-100.2763, 31.5598],
            [-100.294, 31.5525],
            [-100.2848, 31.5865],
            [-100.2996, 31.5891],
            [-100.3002, 31.6528],
            [-100.2827, 31.6928],
            [-100.2125, 31.6984],
            [-100.1248, 31.6989],
            [-100.118, 31.7156],
            [-100.1909, 31.7502],
            [-100.2313, 31.7791],
            [-100.2361, 31.7728],
            [-100.236, 31.8513],
            [-100.2196, 31.8434],
            [-100.2229, 31.8683],
            [-100.2062, 31.8729],
            [-100.2012, 31.8851],
            [-100.1709, 31.8802],
            [-100.1643, 31.918],
            [-100.1709, 31.9184],
            [-100.171, 31.9269],
            [-100.0936, 31.9058],
            [-100.1005, 31.8864],
            [-100.0913, 31.8744],
            [-100.0507, 31.8716],
            [-100.0356, 31.8501],
            [-99.9599, 31.849],
            [-99.9658, 31.8369],
            [-99.9471, 31.8385],
            [-99.947, 31.8497],
            [-99.928, 31.846],
            [-99.9136, 31.8339],
            [-99.9002, 31.8461],
            [-99.9061, 31.8518],
            [-99.8981, 31.862],
            [-99.8765, 31.8626],
            [-99.8253, 31.8932],
            [-99.8235, 31.8889],
            [-99.7689, 31.9031],
            [-99.7162, 31.8991],
            [-99.7152, 31.9633],
            [-99.6024, 31.9536],
            [-99.6, 31.9673],
            [-99.5761, 31.9645],
            [-99.5919, 31.9976],
            [-99.579, 31.9978],
            [-99.5737, 32.0208],
            [-99.5543, 32.019],
            [-99.5404, 32.037],
            [-99.5414, 32.0423],
            [-99.5653, 32.043],
            [-99.5454, 32.0445],
            [-99.5286, 32.0372],
            [-99.5081, 32.0417],
            [-99.507, 32.0486],
            [-99.4873, 32.0408],
            [-99.4734, 32.0598],
            [-99.4828, 32.0684],
            [-99.4803, 32.0801],
            [-99.4573, 32.0801],
          ],
          [
            [-98.7971, 30.0717],
            [-98.7847, 30.083],
            [-98.7948, 30.0848],
            [-98.8038, 30.075],
            [-98.7971, 30.0717],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Dallas Mkt": {
    type: "Feature",
    properties: { code: "TX_DAL", name: "Dallas Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-96.7869, 33.8652],
          [-96.7806, 33.8601],
          [-96.7707, 33.8296],
          [-96.7616, 33.8244],
          [-96.7124, 33.8316],
          [-96.6996, 33.839],
          [-96.6907, 33.85],
          [-96.6672, 33.9169],
          [-96.6283, 33.8945],
          [-96.5886, 33.8949],
          [-96.5901, 33.8807],
          [-96.612, 33.869],
          [-96.6293, 33.8455],
          [-96.5729, 33.8191],
          [-96.5267, 33.8209],
          [-96.5159, 33.7878],
          [-96.5003, 33.7726],
          [-96.448, 33.781],
          [-96.4226, 33.776],
          [-96.4085, 33.7512],
          [-96.3696, 33.7168],
          [-96.3622, 33.6918],
          [-96.3483, 33.6864],
          [-96.3169, 33.699],
          [-96.2925, 33.7664],
          [-96.2699, 33.7684],
          [-96.2205, 33.7474],
          [-96.1781, 33.7605],
          [-96.1621, 33.7961],
          [-96.179, 33.8106],
          [-96.1508, 33.817],
          [-96.1481, 33.8378],
          [-96.123, 33.84],
          [-96.0994, 33.8305],
          [-96.1001, 33.848],
          [-96.0488, 33.8365],
          [-96.0295, 33.8524],
          [-96.0196, 33.8406],
          [-95.9984, 33.851],
          [-95.9915, 33.8669],
          [-95.9722, 33.8564],
          [-95.9443, 33.8598],
          [-95.9352, 33.8871],
          [-95.8375, 33.8356],
          [-95.8208, 33.8406],
          [-95.8206, 33.8585],
          [-95.8051, 33.8613],
          [-95.7721, 33.8438],
          [-95.7535, 33.8565],
          [-95.7626, 33.8744],
          [-95.7564, 33.8926],
          [-95.7375, 33.896],
          [-95.697, 33.8852],
          [-95.6653, 33.9081],
          [-95.637, 33.9066],
          [-95.5997, 33.9342],
          [-95.5594, 33.9302],
          [-95.5452, 33.8803],
          [-95.5153, 33.8911],
          [-95.5023, 33.8747],
          [-95.4649, 33.8867],
          [-95.4633, 33.8723],
          [-95.3391, 33.8689],
          [-95.3335, 33.8863],
          [-95.2879, 33.8749],
          [-95.2778, 33.9009],
          [-95.2531, 33.9054],
          [-95.2529, 33.9336],
          [-95.2264, 33.962],
          [-95.1611, 33.9376],
          [-95.1247, 33.9347],
          [-95.1224, 33.9186],
          [-95.1008, 33.9122],
          [-95.0904, 33.8933],
          [-95.0655, 33.8996],
          [-95.0466, 33.8626],
          [-95.0223, 33.8598],
          [-95.0084, 33.8661],
          [-94.9885, 33.851],
          [-94.9689, 33.8609],
          [-94.9644, 33.837],
          [-94.9443, 33.8121],
          [-94.9194, 33.8102],
          [-94.9196, 33.7863],
          [-94.8792, 33.7649],
          [-94.8693, 33.7459],
          [-94.8308, 33.7401],
          [-94.8174, 33.7522],
          [-94.7986, 33.7445],
          [-94.785, 33.749],
          [-94.7831, 33.7408],
          [-94.7886, 33.7357],
          [-94.7975, 33.7266],
          [-94.7968, 33.7096],
          [-94.8087, 33.6933],
          [-94.8668, 33.6698],
          [-94.8867, 33.641],
          [-94.9431, 33.5989],
          [-94.9299, 33.5403],
          [-94.9447, 33.483],
          [-94.9187, 33.445],
          [-94.9109, 33.3936],
          [-94.8302, 33.3828],
          [-94.8088, 33.3636],
          [-94.828, 33.3071],
          [-94.8523, 33.3151],
          [-94.9319, 33.2653],
          [-94.9395, 33.2262],
          [-94.8919, 33.2241],
          [-94.8895, 33.1935],
          [-94.855, 33.1598],
          [-94.8531, 33.1296],
          [-94.8162, 33.0941],
          [-94.8169, 33.0742],
          [-94.9021, 33.0405],
          [-94.9362, 33.0739],
          [-95.0408, 33.0721],
          [-95.0984, 33.035],
          [-95.0805, 32.992],
          [-95.1047, 32.9026],
          [-95.1521, 32.9025],
          [-95.1522, 32.83],
          [-95.1245, 32.7666],
          [-95.0901, 32.7586],
          [-95.1189, 32.6987],
          [-95.0805, 32.659],
          [-95.0746, 32.6215],
          [-95.0379, 32.6146],
          [-94.9887, 32.6076],
          [-94.9772, 32.563],
          [-94.9977, 32.539],
          [-95.0761, 32.5375],
          [-95.0292, 32.4378],
          [-95.0356, 32.3374],
          [-94.9854, 32.2634],
          [-95.0153, 32.2535],
          [-94.9854, 32.1864],
          [-94.9854, 31.9884],
          [-94.9738, 31.9418],
          [-94.9056, 31.9108],
          [-94.8296, 31.9174],
          [-94.8143, 31.8894],
          [-94.7767, 31.8885],
          [-94.7772, 31.844],
          [-94.7453, 31.8385],
          [-94.7574, 31.7823],
          [-94.8366, 31.7022],
          [-94.9725, 31.7374],
          [-95.0047, 31.759],
          [-95.0453, 31.7326],
          [-95.0997, 31.7209],
          [-95.1225, 31.6769],
          [-95.161, 31.6854],
          [-95.1629, 31.6366],
          [-95.2331, 31.637],
          [-95.2058, 31.5946],
          [-95.1498, 31.5695],
          [-95.1435, 31.5398],
          [-95.1, 31.519],
          [-95.1007, 31.4684],
          [-95.057, 31.4649],
          [-94.958, 31.387],
          [-95.0224, 31.3417],
          [-95.0424, 31.2539],
          [-95.0646, 31.2465],
          [-95.056, 31.1874],
          [-95.0206, 31.1781],
          [-94.8849, 31.1738],
          [-94.8428, 31.1467],
          [-94.9391, 31.042],
          [-95.0152, 30.9797],
          [-95.2002, 30.8246],
          [-95.231, 30.8411],
          [-95.2431, 30.8669],
          [-95.2308, 30.887],
          [-95.2513, 30.9065],
          [-95.3074, 30.9005],
          [-95.314, 30.8964],
          [-95.3142, 30.8723],
          [-95.3275, 30.8595],
          [-95.344, 30.8758],
          [-95.3521, 30.857],
          [-95.3833, 30.853],
          [-95.3986, 30.8605],
          [-95.3916, 30.876],
          [-95.3994, 30.8815],
          [-95.3814, 30.8793],
          [-95.3813, 30.8871],
          [-95.4155, 30.9079],
          [-95.4108, 30.9301],
          [-95.4597, 30.9395],
          [-95.5494, 30.9182],
          [-95.5729, 30.8867],
          [-95.5738, 30.8734],
          [-95.6227, 30.8506],
          [-95.6252, 30.8219],
          [-95.6709, 30.7904],
          [-95.7396, 30.8523],
          [-95.7331, 30.8641],
          [-95.7167, 30.8702],
          [-95.7256, 30.8862],
          [-95.7603, 30.8733],
          [-95.7725, 30.8886],
          [-95.7621, 30.8977],
          [-95.7127, 30.9108],
          [-95.7157, 30.95],
          [-95.7342, 30.9395],
          [-95.7943, 30.9563],
          [-95.7764, 30.9688],
          [-95.7717, 30.9839],
          [-95.782, 30.9833],
          [-95.797, 31.0008],
          [-95.8166, 31.0061],
          [-95.8724, 31.0436],
          [-95.8882, 31.0694],
          [-95.8823, 31.0844],
          [-95.925, 31.0836],
          [-95.9277, 31.0926],
          [-95.9889, 31.0862],
          [-95.9857, 31.0977],
          [-95.9934, 31.1101],
          [-95.9774, 31.1143],
          [-95.9897, 31.1445],
          [-95.998, 31.1424],
          [-95.9853, 31.1523],
          [-95.9929, 31.1891],
          [-96.0377, 31.1933],
          [-96.0866, 31.2062],
          [-96.1197, 31.2547],
          [-96.2118, 31.248],
          [-96.2199, 31.2708],
          [-96.2115, 31.3028],
          [-96.227, 31.2918],
          [-96.2362, 31.3047],
          [-96.2029, 31.3348],
          [-96.2147, 31.3523],
          [-96.2334, 31.3457],
          [-96.2588, 31.3495],
          [-96.2727, 31.3595],
          [-96.276, 31.3805],
          [-96.3078, 31.36],
          [-96.2933, 31.3692],
          [-96.302, 31.3589],
          [-96.2959, 31.353],
          [-96.308, 31.3557],
          [-96.309, 31.33],
          [-96.3169, 31.3253],
          [-96.3202, 31.3752],
          [-96.3582, 31.4653],
          [-96.3285, 31.5074],
          [-96.2994, 31.5069],
          [-96.3815, 31.6276],
          [-96.3538, 31.7139],
          [-96.3325, 31.7269],
          [-96.3415, 31.7844],
          [-96.3185, 31.8477],
          [-96.3542, 31.9368],
          [-96.4782, 31.9659],
          [-96.5047, 31.9227],
          [-96.5358, 31.9349],
          [-96.5428, 31.9671],
          [-96.5016, 31.9786],
          [-96.5504, 32.0305],
          [-96.6406, 31.9743],
          [-96.675, 31.9763],
          [-96.7156, 32.0252],
          [-96.6722, 32.0599],
          [-96.6892, 32.1076],
          [-96.6646, 32.1327],
          [-96.666, 32.174],
          [-96.7091, 32.188],
          [-96.7728, 32.1802],
          [-96.8467, 32.2651],
          [-96.8756, 32.2465],
          [-96.9513, 32.2596],
          [-96.9609, 32.2819],
          [-97.0293, 32.3392],
          [-97.0143, 32.3728],
          [-96.9802, 32.3649],
          [-96.9337, 32.3852],
          [-96.9138, 32.4132],
          [-96.9081, 32.5027],
          [-96.9198, 32.5478],
          [-97.0384, 32.5486],
          [-97.0373, 32.6301],
          [-97.0581, 32.6233],
          [-97.0627, 32.7059],
          [-97.0457, 32.7446],
          [-97.0632, 32.7643],
          [-97.0589, 32.8171],
          [-97.0341, 32.8165],
          [-97.0324, 32.9846],
          [-97.0832, 32.9847],
          [-97.1832, 33.0367],
          [-97.1453, 33.0668],
          [-97.1076, 33.0726],
          [-97.0954, 33.1107],
          [-97.0586, 33.1016],
          [-97.0024, 33.1425],
          [-97.0197, 33.2006],
          [-96.9759, 33.222],
          [-96.8966, 33.2307],
          [-96.8633, 33.2588],
          [-96.8633, 33.2955],
          [-96.8356, 33.3213],
          [-96.8342, 33.4053],
          [-96.8066, 33.4049],
          [-96.7967, 33.4678],
          [-96.7733, 33.4967],
          [-96.7852, 33.5468],
          [-96.7735, 33.6057],
          [-96.8339, 33.6433],
          [-96.8469, 33.6822],
          [-96.7955, 33.6992],
          [-96.7755, 33.7424],
          [-96.8068, 33.8],
          [-96.805, 33.8222],
          [-96.7963, 33.847],
          [-96.7869, 33.8652],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Ft Worth Mkt": {
    type: "Feature",
    properties: { code: "TX_FTW", name: "Ft Worth Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-99.1881, 32.2042],
            [-99.1694, 32.2041],
            [-99.168, 32.1967],
            [-99.1881, 32.2042],
          ],
        ],
        [
          [
            [-96.7869, 33.8652],
            [-96.7963, 33.847],
            [-96.805, 33.8222],
            [-96.8068, 33.8],
            [-96.7755, 33.7424],
            [-96.7955, 33.6992],
            [-96.8469, 33.6822],
            [-96.8339, 33.6433],
            [-96.7735, 33.6057],
            [-96.7852, 33.5468],
            [-96.7733, 33.4967],
            [-96.7967, 33.4678],
            [-96.8066, 33.4049],
            [-96.8342, 33.4053],
            [-96.8356, 33.3213],
            [-96.8633, 33.2955],
            [-96.8633, 33.2588],
            [-96.8966, 33.2307],
            [-96.9759, 33.222],
            [-97.0197, 33.2006],
            [-97.0024, 33.1425],
            [-97.0586, 33.1016],
            [-97.0954, 33.1107],
            [-97.1076, 33.0726],
            [-97.1453, 33.0668],
            [-97.1832, 33.0367],
            [-97.0832, 32.9847],
            [-97.0324, 32.9846],
            [-97.0341, 32.8165],
            [-97.0589, 32.8171],
            [-97.0632, 32.7643],
            [-97.0457, 32.7446],
            [-97.0627, 32.7059],
            [-97.0581, 32.6233],
            [-97.0373, 32.6301],
            [-97.0384, 32.5486],
            [-96.9198, 32.5478],
            [-96.9081, 32.5027],
            [-96.9138, 32.4132],
            [-96.9337, 32.3852],
            [-96.9802, 32.3649],
            [-97.0143, 32.3728],
            [-97.0293, 32.3392],
            [-96.9609, 32.2819],
            [-96.9513, 32.2596],
            [-96.8756, 32.2465],
            [-96.8467, 32.2651],
            [-96.7728, 32.1802],
            [-96.7091, 32.188],
            [-96.666, 32.174],
            [-96.6646, 32.1327],
            [-96.6892, 32.1076],
            [-96.6722, 32.0599],
            [-96.7156, 32.0252],
            [-96.675, 31.9763],
            [-96.6406, 31.9743],
            [-96.5504, 32.0305],
            [-96.5016, 31.9786],
            [-96.5428, 31.9671],
            [-96.5358, 31.9349],
            [-96.5047, 31.9227],
            [-96.4782, 31.9659],
            [-96.3542, 31.9368],
            [-96.3185, 31.8477],
            [-96.3415, 31.7844],
            [-96.3325, 31.7269],
            [-96.3538, 31.7139],
            [-96.3815, 31.6276],
            [-96.2994, 31.5069],
            [-96.3285, 31.5074],
            [-96.3582, 31.4653],
            [-96.3202, 31.3752],
            [-96.3169, 31.3253],
            [-96.3257, 31.311],
            [-96.3171, 31.3017],
            [-96.3281, 31.2988],
            [-96.3275, 31.2852],
            [-96.314, 31.2714],
            [-96.3312, 31.2525],
            [-96.3143, 31.2291],
            [-96.3111, 31.2069],
            [-96.3221, 31.2115],
            [-96.3351, 31.234],
            [-96.347, 31.2343],
            [-96.3419, 31.2558],
            [-96.3718, 31.265],
            [-96.4042, 31.3019],
            [-96.4058, 31.3069],
            [-96.378, 31.3202],
            [-96.383, 31.3257],
            [-96.4674, 31.2841],
            [-96.445, 31.262],
            [-96.4504, 31.2476],
            [-96.436, 31.2259],
            [-96.4545, 31.2179],
            [-96.4672, 31.194],
            [-96.5024, 31.1622],
            [-96.5215, 31.1674],
            [-96.5412, 31.1619],
            [-96.5526, 31.1785],
            [-96.5884, 31.165],
            [-96.6148, 31.122],
            [-96.5872, 31.0998],
            [-96.6212, 31.0844],
            [-96.658, 31.1016],
            [-96.6582, 31.0898],
            [-96.6812, 31.0786],
            [-96.7008, 31.1025],
            [-96.7112, 31.0974],
            [-96.7142, 31.1039],
            [-96.7147, 31.0949],
            [-96.7707, 31.0594],
            [-96.7629, 31.0571],
            [-96.7658, 31.0438],
            [-96.7797, 31.0327],
            [-96.7861, 31.0615],
            [-96.8307, 31.1004],
            [-96.8283, 31.1436],
            [-96.874, 31.1815],
            [-96.9305, 31.1895],
            [-96.9786, 31.1647],
            [-96.9515, 31.1355],
            [-96.9708, 31.0893],
            [-97.0207, 31.1667],
            [-97.0911, 31.1275],
            [-97.0871, 31.0879],
            [-97.1293, 31.0703],
            [-97.1668, 31.1229],
            [-97.1712, 31.1714],
            [-97.1248, 31.1776],
            [-97.1471, 31.2524],
            [-97.1977, 31.3203],
            [-97.2277, 31.3047],
            [-97.2656, 31.3925],
            [-97.314, 31.4218],
            [-97.3513, 31.4136],
            [-97.3175, 31.3743],
            [-97.3604, 31.3361],
            [-97.3832, 31.3975],
            [-97.465, 31.3991],
            [-97.4612, 31.3396],
            [-97.4984, 31.3596],
            [-97.4829, 31.4429],
            [-97.5239, 31.4714],
            [-97.5807, 31.5513],
            [-97.6223, 31.5465],
            [-97.625, 31.5995],
            [-97.6453, 31.6264],
            [-97.7198, 31.6304],
            [-97.7117, 31.6514],
            [-97.7414, 31.6526],
            [-97.8108, 31.7119],
            [-97.8696, 31.6765],
            [-97.9169, 31.7036],
            [-97.9529, 31.7522],
            [-97.9421, 31.7902],
            [-98.0197, 31.7507],
            [-98.0008, 31.8269],
            [-98.0594, 31.8321],
            [-98.1585, 31.8674],
            [-98.2039, 31.9109],
            [-98.2551, 31.9244],
            [-98.2654, 31.8244],
            [-98.2401, 31.7989],
            [-98.3938, 31.7201],
            [-98.4436, 31.7349],
            [-98.4637, 31.684],
            [-98.4221, 31.6274],
            [-98.4951, 31.6506],
            [-98.4868, 31.6773],
            [-98.5467, 31.6963],
            [-98.5511, 31.6737],
            [-98.6135, 31.689],
            [-98.6586, 31.6873],
            [-98.7258, 31.7854],
            [-98.7516, 31.7747],
            [-98.8691, 31.7743],
            [-98.9036, 31.7594],
            [-98.9006, 31.8295],
            [-98.8363, 31.8774],
            [-98.7881, 31.8782],
            [-98.904, 32.0302],
            [-98.9829, 32.0367],
            [-99.0752, 32.0022],
            [-99.1483, 32.012],
            [-99.1969, 32.0384],
            [-99.1959, 32.0792],
            [-99.4573, 32.0801],
            [-99.4567, 32.1105],
            [-99.4063, 32.1103],
            [-99.4094, 32.1672],
            [-99.3922, 32.1794],
            [-99.3766, 32.1806],
            [-99.363, 32.2001],
            [-99.3626, 32.1756],
            [-99.3401, 32.1758],
            [-99.3429, 32.168],
            [-99.3107, 32.1533],
            [-99.3019, 32.1608],
            [-99.3017, 32.1794],
            [-99.2862, 32.1803],
            [-99.285, 32.2109],
            [-99.2952, 32.2178],
            [-99.283, 32.2402],
            [-99.2412, 32.1834],
            [-99.2245, 32.1831],
            [-99.2188, 32.175],
            [-99.192, 32.1676],
            [-99.168, 32.1807],
            [-99.1472, 32.1805],
            [-99.1437, 32.1929],
            [-99.149, 32.2301],
            [-99.1353, 32.2384],
            [-99.1346, 32.2508],
            [-99.1891, 32.2498],
            [-99.1885, 32.2743],
            [-99.1738, 32.3078],
            [-99.174, 32.344],
            [-99.1833, 32.3476],
            [-99.1869, 32.3715],
            [-99.2174, 32.3692],
            [-99.2977, 32.3786],
            [-99.2983, 32.3858],
            [-99.2529, 32.3776],
            [-99.249, 32.3784],
            [-99.2487, 32.3807],
            [-99.2398, 32.3782],
            [-99.2254, 32.3734],
            [-99.2131, 32.3709],
            [-99.204, 32.3714],
            [-99.2043, 32.3776],
            [-99.195, 32.3775],
            [-99.2064, 32.4072],
            [-99.2067, 32.4506],
            [-99.2559, 32.45],
            [-99.2605, 32.4775],
            [-99.2829, 32.4892],
            [-99.3001, 32.4855],
            [-99.303, 32.5151],
            [-99.459, 32.5192],
            [-99.4628, 32.5544],
            [-99.475, 32.5586],
            [-99.464, 32.5663],
            [-99.4641, 32.6383],
            [-99.4716, 32.6399],
            [-99.4783, 32.6963],
            [-99.5277, 32.6986],
            [-99.53, 32.7084],
            [-99.5381, 32.703],
            [-99.5471, 32.6979],
            [-99.57, 32.7239],
            [-99.5695, 32.7348],
            [-99.5579, 32.7344],
            [-99.5532, 32.7491],
            [-99.5407, 32.7461],
            [-99.5348, 32.7345],
            [-99.5316, 32.7441],
            [-99.4686, 32.7157],
            [-99.4436, 32.7144],
            [-99.4279, 32.7379],
            [-99.4222, 32.7335],
            [-99.4197, 32.7475],
            [-99.4087, 32.7472],
            [-99.4091, 32.7622],
            [-99.395, 32.7643],
            [-99.413, 32.764],
            [-99.4158, 32.7715],
            [-99.4066, 32.7736],
            [-99.4144, 32.7756],
            [-99.411, 32.789],
            [-99.4539, 32.8027],
            [-99.4694, 32.8201],
            [-99.4535, 32.8354],
            [-99.4526, 32.8569],
            [-99.46, 32.8633],
            [-99.4499, 32.8717],
            [-99.4597, 32.8866],
            [-99.4569, 32.9207],
            [-99.4782, 32.9429],
            [-99.4713, 32.957],
            [-99.4721, 33.2408],
            [-99.5115, 33.2564],
            [-99.6009, 33.2621],
            [-99.6188, 33.2497],
            [-99.6606, 33.2416],
            [-99.6613, 33.2339],
            [-99.7259, 33.2524],
            [-99.7246, 33.3208],
            [-99.7623, 33.3203],
            [-99.7698, 33.3472],
            [-99.7526, 33.3476],
            [-99.7458, 33.3983],
            [-99.7026, 33.3984],
            [-99.7135, 33.4066],
            [-99.716, 33.4341],
            [-99.7553, 33.4347],
            [-99.7558, 33.4886],
            [-99.802, 33.5001],
            [-99.7798, 33.4948],
            [-99.7267, 33.5113],
            [-99.7238, 33.5214],
            [-99.713, 33.5245],
            [-99.7198, 33.5462],
            [-99.7139, 33.5529],
            [-99.6606, 33.548],
            [-99.6692, 33.6122],
            [-99.6663, 33.7439],
            [-99.5993, 33.7462],
            [-99.5965, 33.7873],
            [-99.5823, 33.7845],
            [-99.5784, 33.8025],
            [-99.5565, 33.7984],
            [-99.5418, 33.7941],
            [-99.5463, 33.7813],
            [-99.5243, 33.7836],
            [-99.5202, 33.7684],
            [-99.5065, 33.7723],
            [-99.5007, 33.7637],
            [-99.4838, 33.7501],
            [-99.476, 33.7519],
            [-99.4745, 33.7335],
            [-99.4754, 34.274],
            [-99.4544, 34.2792],
            [-99.4571, 34.2923],
            [-99.4285, 34.292],
            [-99.4212, 34.2992],
            [-99.3742, 34.3162],
            [-99.3969, 34.37],
            [-99.3915, 34.4056],
            [-99.395, 34.4421],
            [-99.3696, 34.4587],
            [-99.3547, 34.4519],
            [-99.3567, 34.4421],
            [-99.3196, 34.4089],
            [-99.2946, 34.4154],
            [-99.2613, 34.4035],
            [-99.259, 34.3912],
            [-99.2749, 34.3849],
            [-99.2587, 34.3726],
            [-99.2429, 34.3727],
            [-99.2326, 34.3424],
            [-99.2131, 34.3404],
            [-99.2116, 34.2922],
            [-99.1956, 34.2808],
            [-99.1895, 34.2143],
            [-99.159, 34.2089],
            [-99.1306, 34.2194],
            [-99.1266, 34.203],
            [-99.0795, 34.2115],
            [-99.0435, 34.1982],
            [-99.0363, 34.2069],
            [-99.0131, 34.2032],
            [-98.9873, 34.2212],
            [-98.9741, 34.2036],
            [-98.9663, 34.2013],
            [-98.9608, 34.213],
            [-98.9525, 34.2126],
            [-98.9183, 34.1818],
            [-98.8729, 34.1666],
            [-98.8681, 34.1496],
            [-98.8601, 34.1499],
            [-98.8556, 34.1616],
            [-98.813, 34.1584],
            [-98.7656, 34.1364],
            [-98.757, 34.1246],
            [-98.742, 34.1255],
            [-98.7343, 34.1358],
            [-98.6901, 34.1332],
            [-98.6481, 34.1644],
            [-98.6167, 34.1564],
            [-98.5998, 34.1606],
            [-98.5725, 34.1451],
            [-98.5282, 34.095],
            [-98.4863, 34.0626],
            [-98.449, 34.0735],
            [-98.4401, 34.0843],
            [-98.4144, 34.0851],
            [-98.3998, 34.1],
            [-98.3984, 34.1285],
            [-98.3812, 34.1495],
            [-98.364, 34.1571],
            [-98.3254, 34.151],
            [-98.2939, 34.133],
            [-98.241, 34.1331],
            [-98.2037, 34.1177],
            [-98.1691, 34.1142],
            [-98.1308, 34.1505],
            [-98.1095, 34.1541],
            [-98.0898, 34.1282],
            [-98.1202, 34.0721],
            [-98.0962, 34.0446],
            [-98.1055, 34.0313],
            [-98.0882, 34.0055],
            [-98.0277, 33.9934],
            [-97.9742, 34.0067],
            [-97.9583, 33.9908],
            [-97.9465, 33.9907],
            [-97.9604, 33.9519],
            [-97.9742, 33.9428],
            [-97.9534, 33.9364],
            [-97.9572, 33.9145],
            [-97.9699, 33.906],
            [-97.9788, 33.9125],
            [-97.9838, 33.8972],
            [-97.9678, 33.8824],
            [-97.9367, 33.8792],
            [-97.8714, 33.849],
            [-97.8343, 33.8577],
            [-97.7847, 33.8906],
            [-97.7837, 33.9106],
            [-97.7594, 33.9188],
            [-97.7628, 33.9344],
            [-97.7253, 33.941],
            [-97.6931, 33.9837],
            [-97.673, 33.991],
            [-97.6338, 33.9813],
            [-97.5896, 33.9536],
            [-97.5971, 33.9179],
            [-97.5874, 33.9025],
            [-97.5515, 33.8979],
            [-97.501, 33.9196],
            [-97.4604, 33.9039],
            [-97.451, 33.8914],
            [-97.4515, 33.8709],
            [-97.4629, 33.8418],
            [-97.4531, 33.8285],
            [-97.4265, 33.8194],
            [-97.3655, 33.8238],
            [-97.3329, 33.8744],
            [-97.3182, 33.8651],
            [-97.2992, 33.8802],
            [-97.2791, 33.8646],
            [-97.2556, 33.8637],
            [-97.2449, 33.9031],
            [-97.2109, 33.9161],
            [-97.1808, 33.8952],
            [-97.1666, 33.8473],
            [-97.1716, 33.8353],
            [-97.1975, 33.8298],
            [-97.2054, 33.8015],
            [-97.1722, 33.7375],
            [-97.1551, 33.7244],
            [-97.1261, 33.7169],
            [-97.0972, 33.7278],
            [-97.0862, 33.7439],
            [-97.0921, 33.8041],
            [-97.0586, 33.8188],
            [-97.0576, 33.8401],
            [-97.0239, 33.8442],
            [-96.9856, 33.8865],
            [-96.9962, 33.9417],
            [-96.9813, 33.9564],
            [-96.9738, 33.9357],
            [-96.9163, 33.9578],
            [-96.9024, 33.942],
            [-96.8957, 33.8964],
            [-96.8753, 33.8605],
            [-96.8506, 33.8472],
            [-96.8322, 33.8748],
            [-96.7943, 33.8689],
            [-96.7869, 33.8652],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Texarkana Mkt": {
    type: "Feature",
    properties: { code: "TX_TEX", name: "Texarkana Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-94.785, 33.749],
          [-94.7681, 33.7534],
          [-94.763, 33.7318],
          [-94.7426, 33.727],
          [-94.7324, 33.7003],
          [-94.711, 33.7057],
          [-94.7079, 33.6869],
          [-94.6523, 33.691],
          [-94.6429, 33.6684],
          [-94.6212, 33.681],
          [-94.5796, 33.6776],
          [-94.5708, 33.6549],
          [-94.5521, 33.6535],
          [-94.5527, 33.6382],
          [-94.5333, 33.6377],
          [-94.5263, 33.6192],
          [-94.4875, 33.6289],
          [-94.4703, 34.1899],
          [-94.3336, 34.191],
          [-94.308, 34.2111],
          [-94.244, 34.2112],
          [-94.2561, 34.2693],
          [-94.2547, 34.3555],
          [-94.1666, 34.3534],
          [-94.1724, 34.286],
          [-94.1185, 34.2401],
          [-94.064, 34.2508],
          [-94.0313, 34.2758],
          [-93.9856, 34.2541],
          [-93.95, 34.1828],
          [-93.8207, 34.1838],
          [-93.8138, 34.1335],
          [-93.7694, 34.1082],
          [-93.7093, 34.0212],
          [-93.6668, 34.0221],
          [-93.6341, 33.9945],
          [-93.5289, 33.9436],
          [-93.468, 33.9643],
          [-93.373, 33.9548],
          [-93.3247, 33.9178],
          [-93.2894, 33.8441],
          [-93.2201, 33.829],
          [-93.167, 33.8047],
          [-93.1427, 33.8146],
          [-93.1043, 33.7773],
          [-93.1065, 33.7191],
          [-93.1814, 33.7123],
          [-93.14, 33.6576],
          [-93.1082, 33.6685],
          [-93.1131, 33.5389],
          [-93.1645, 33.5052],
          [-93.2414, 33.4699],
          [-93.239, 33.4419],
          [-93.3655, 33.4445],
          [-93.4291, 33.3158],
          [-93.442, 33.2594],
          [-93.3913, 33.2052],
          [-93.3971, 33.1205],
          [-93.3659, 33.0959],
          [-93.3679, 33.0467],
          [-93.3922, 33.0183],
          [-94.043, 33.0192],
          [-94.0453, 32.8442],
          [-94.1131, 32.8402],
          [-94.1216, 32.8809],
          [-94.2805, 32.874],
          [-94.339, 32.9425],
          [-94.3695, 32.9481],
          [-94.3875, 32.9007],
          [-94.4148, 32.8834],
          [-94.4897, 32.9239],
          [-94.4877, 33.0332],
          [-94.5243, 33.0381],
          [-94.5579, 33.0924],
          [-94.6175, 33.1109],
          [-94.7494, 33.1104],
          [-94.7408, 33.0721],
          [-94.8162, 33.0941],
          [-94.8531, 33.1296],
          [-94.855, 33.1598],
          [-94.8895, 33.1935],
          [-94.8919, 33.2241],
          [-94.9395, 33.2262],
          [-94.9319, 33.2653],
          [-94.8523, 33.3151],
          [-94.828, 33.3071],
          [-94.8088, 33.3636],
          [-94.8302, 33.3828],
          [-94.9109, 33.3936],
          [-94.9187, 33.445],
          [-94.9447, 33.483],
          [-94.9299, 33.5403],
          [-94.9431, 33.5989],
          [-94.8867, 33.641],
          [-94.8668, 33.6698],
          [-94.8087, 33.6933],
          [-94.7968, 33.7096],
          [-94.7975, 33.7266],
          [-94.7886, 33.7357],
          [-94.7831, 33.7408],
          [-94.785, 33.749],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Salt Lake City Mkt": {
    type: "Feature",
    properties: { code: "UT_SLC", name: "Salt Lake City Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-114.0467, 40.1169],
          [-114.0462, 40.3983],
          [-114.0432, 40.7717],
          [-114.0414, 41.22],
          [-114.0402, 41.4917],
          [-114.0396, 41.8848],
          [-114.0417, 41.9937],
          [-113.8933, 41.9881],
          [-113.2492, 41.9962],
          [-112.7094, 42.0003],
          [-112.193, 42.0012],
          [-112.1734, 41.9966],
          [-112.164, 41.9967],
          [-112.1095, 41.9976],
          [-112.0122, 41.9984],
          [-111.7508, 41.9993],
          [-111.599, 41.9994],
          [-111.5073, 41.9995],
          [-111.4714, 41.9997],
          [-111.0467, 42.0016],
          [-111.0459, 41.6357],
          [-111.0467, 40.998],
          [-110.5007, 40.9947],
          [-110.1216, 40.9971],
          [-109.5349, 40.9981],
          [-109.0501, 41.0007],
          [-109.048, 40.6192],
          [-109.0501, 40.541],
          [-109.05, 40.5399],
          [-109.0507, 40.5],
          [-109.0503, 40.4951],
          [-109.0509, 40.4444],
          [-109.051, 40.2732],
          [-109.0509, 40.1807],
          [-109.0508, 40.0596],
          [-109.0509, 40.0589],
          [-109.0506, 39.875],
          [-109.0514, 39.4977],
          [-109.0731, 39.4998],
          [-109.107, 39.462],
          [-109.5007, 39.4626],
          [-110.0241, 39.4617],
          [-110.0146, 39.4916],
          [-110.0268, 39.5236],
          [-110.0476, 39.5332],
          [-110.0297, 39.5393],
          [-110.038, 39.5667],
          [-110.0187, 39.5864],
          [-110.029, 39.6007],
          [-110.0109, 39.625],
          [-109.9974, 39.6262],
          [-110.0109, 39.6453],
          [-109.996, 39.6466],
          [-109.9843, 39.6641],
          [-109.9864, 39.6827],
          [-110.0045, 39.6875],
          [-109.9937, 39.7028],
          [-109.9706, 39.7102],
          [-109.9937, 39.7364],
          [-109.9668, 39.7427],
          [-109.9606, 39.7234],
          [-109.9498, 39.7197],
          [-109.9545, 39.7324],
          [-109.9453, 39.7507],
          [-109.9304, 39.7481],
          [-109.9201, 39.7754],
          [-109.9302, 39.796],
          [-109.9211, 39.7991],
          [-109.9192, 39.7862],
          [-109.8955, 39.7909],
          [-109.8778, 39.7831],
          [-109.8757, 39.7931],
          [-109.8933, 39.8003],
          [-109.8847, 39.8057],
          [-110.5182, 39.8066],
          [-110.5181, 39.8116],
          [-111.079, 39.8142],
          [-111.2475, 39.8131],
          [-111.2473, 39.4672],
          [-111.3012, 39.4673],
          [-111.2999, 39.125],
          [-111.2986, 38.75],
          [-111.3071, 38.6723],
          [-111.3055, 38.5858],
          [-111.3059, 38.5],
          [-110.0254, 38.5],
          [-110.0023, 38.4819],
          [-110.014, 38.4464],
          [-110.0334, 38.4597],
          [-110.0444, 38.4536],
          [-110.0397, 38.4463],
          [-110.027, 38.4474],
          [-110.0034, 38.4162],
          [-110.0332, 38.3878],
          [-110.0098, 38.3774],
          [-110.0147, 38.3643],
          [-109.976, 38.3883],
          [-109.9845, 38.3539],
          [-109.9677, 38.3412],
          [-109.9574, 38.3418],
          [-109.9521, 38.3547],
          [-109.9412, 38.3536],
          [-109.9481, 38.3384],
          [-109.9649, 38.3317],
          [-109.9637, 38.3206],
          [-109.9468, 38.3096],
          [-109.9491, 38.2904],
          [-109.9281, 38.2802],
          [-109.9349, 38.2625],
          [-109.9264, 38.26],
          [-109.9098, 38.2712],
          [-109.9044, 38.267],
          [-109.9022, 38.256],
          [-109.9177, 38.2415],
          [-109.8993, 38.2426],
          [-109.8884, 38.2297],
          [-109.9141, 38.2273],
          [-109.92, 38.2204],
          [-109.8989, 38.2068],
          [-109.8884, 38.208],
          [-109.9039, 38.1902],
          [-109.8864, 38.1899],
          [-109.8871, 38.1837],
          [-109.9317, 38.1576],
          [-109.9294, 38.1399],
          [-109.956, 38.13],
          [-109.9925, 38.0999],
          [-110.0113, 38.1093],
          [-110.0267, 38.0968],
          [-110.0472, 38.0706],
          [-110.0456, 38.0503],
          [-110.0639, 38.043],
          [-110.0759, 38.0231],
          [-110.0774, 37.9989],
          [-110.0926, 37.9947],
          [-110.1134, 38.0083],
          [-110.1367, 38.0054],
          [-110.1622, 37.981],
          [-110.1663, 37.9663],
          [-110.2024, 37.9465],
          [-110.1985, 37.9176],
          [-110.2106, 37.8931],
          [-110.268, 37.8983],
          [-110.2844, 37.8661],
          [-110.2949, 37.886],
          [-110.3074, 37.8906],
          [-110.3641, 37.8922],
          [-110.4046, 37.8799],
          [-110.4024, 37.8522],
          [-110.4312, 37.8368],
          [-110.4375, 37.8069],
          [-110.4703, 37.8091],
          [-110.4765, 37.8008],
          [-110.4631, 37.7719],
          [-110.4266, 37.7761],
          [-110.4465, 37.7586],
          [-110.449, 37.7352],
          [-110.4879, 37.7022],
          [-110.4841, 37.6817],
          [-110.4977, 37.6522],
          [-110.5139, 37.6566],
          [-110.5381, 37.6329],
          [-110.5587, 37.642],
          [-110.5663, 37.6159],
          [-110.601, 37.6016],
          [-110.6036, 37.5713],
          [-110.6363, 37.5542],
          [-110.6627, 37.5211],
          [-110.6673, 37.4957],
          [-110.6429, 37.4921],
          [-110.6409, 37.4852],
          [-110.6663, 37.4775],
          [-110.7192, 37.4814],
          [-110.7262, 37.4595],
          [-110.7475, 37.458],
          [-110.7255, 37.4356],
          [-110.7087, 37.4374],
          [-110.6992, 37.4307],
          [-110.7157, 37.4017],
          [-110.7538, 37.404],
          [-110.7223, 37.3732],
          [-110.7361, 37.3452],
          [-110.7522, 37.352],
          [-110.7581, 37.3336],
          [-110.7753, 37.3237],
          [-110.8007, 37.3213],
          [-110.8547, 37.3478],
          [-110.8655, 37.3504],
          [-110.8734, 37.3439],
          [-110.8676, 37.3285],
          [-110.8406, 37.3188],
          [-110.8364, 37.3042],
          [-110.8454, 37.2935],
          [-110.8737, 37.287],
          [-110.8609, 37.2603],
          [-110.887, 37.25],
          [-110.8757, 37.2275],
          [-110.8965, 37.2138],
          [-110.8887, 37.2003],
          [-110.8988, 37.1877],
          [-110.8987, 37.1779],
          [-110.8995, 37.1762],
          [-110.9012, 37.1751],
          [-110.9224, 37.1782],
          [-110.9345, 37.1494],
          [-110.9549, 37.1514],
          [-110.9507, 37.135],
          [-110.9583, 37.1244],
          [-110.9821, 37.1275],
          [-110.9887, 37.1117],
          [-111.0137, 37.1157],
          [-111.0515, 37.0991],
          [-111.0656, 37.108],
          [-111.1004, 37.1026],
          [-111.1209, 37.108],
          [-111.1408, 37.0885],
          [-111.1648, 37.1053],
          [-111.1793, 37.1019],
          [-111.2167, 37.0576],
          [-111.2259, 37.0573],
          [-111.2332, 37.0697],
          [-111.2409, 37.0671],
          [-111.2473, 37.0477],
          [-111.2299, 37.0386],
          [-111.2418, 37.0208],
          [-111.2678, 37.0536],
          [-111.3017, 37.0664],
          [-111.3029, 37.0467],
          [-111.2815, 37.0419],
          [-111.3066, 37.0259],
          [-111.3041, 37.0145],
          [-111.3149, 37.0121],
          [-111.3286, 37.021],
          [-111.3675, 37.0021],
          [-111.6923, 37.0013],
          [-112.2128, 37.0011],
          [-112.5404, 37.0007],
          [-113.0103, 37.0002],
          [-113.4692, 37.0001],
          [-114.0506, 37.0004],
          [-114.053, 37.5928],
          [-114.0497, 37.8236],
          [-114.0494, 38.2647],
          [-114.0505, 38.5],
          [-114.0495, 38.8749],
          [-114.0477, 39.3408],
          [-114.0473, 39.7594],
          [-114.0467, 40.1169],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Norfolk Mkt": {
    type: "Feature",
    properties: { code: "VA_NOR", name: "Norfolk Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-75.7269, 35.9355],
            [-75.6911, 35.9369],
            [-75.6277, 35.8831],
            [-75.6144, 35.8157],
            [-75.6679, 35.8235],
            [-75.6606, 35.8625],
            [-75.7269, 35.9355],
          ],
        ],
        [
          [
            [-76.015, 35.0653],
            [-75.9839, 35.12],
            [-75.9547, 35.1196],
            [-75.8939, 35.1504],
            [-75.718, 35.2094],
            [-75.6875, 35.2312],
            [-75.6409, 35.2339],
            [-75.5819, 35.2639],
            [-75.5357, 35.2729],
            [-75.5259, 35.2338],
            [-75.6101, 35.2275],
            [-75.7697, 35.1804],
            [-75.9447, 35.1051],
            [-76.015, 35.0653],
          ],
        ],
        [
          [
            [-75.6697, 37.9508],
            [-75.6557, 37.9454],
            [-75.6476, 37.947],
            [-75.6482, 37.9668],
            [-75.6382, 37.9794],
            [-75.6295, 37.976],
            [-75.6338, 37.9845],
            [-75.6243, 37.9942],
            [-75.2423, 38.0272],
            [-75.2969, 37.959],
            [-75.3493, 37.8731],
            [-75.4527, 37.8635],
            [-75.5149, 37.7991],
            [-75.5725, 37.7016],
            [-75.6108, 37.6059],
            [-75.594, 37.5697],
            [-75.6662, 37.4721],
            [-75.6726, 37.4299],
            [-75.7654, 37.3056],
            [-75.7984, 37.2963],
            [-75.7909, 37.2251],
            [-75.8008, 37.1973],
            [-75.9067, 37.1142],
            [-75.9255, 37.1336],
            [-75.9704, 37.1186],
            [-75.9781, 37.1573],
            [-76.0131, 37.2054],
            [-76.0258, 37.2574],
            [-76.0143, 37.3319],
            [-75.9871, 37.3685],
            [-75.9816, 37.4341],
            [-75.9403, 37.5346],
            [-75.9412, 37.5638],
            [-75.9096, 37.6227],
            [-75.8685, 37.6682],
            [-75.803, 37.7625],
            [-75.8181, 37.7917],
            [-75.7431, 37.8067],
            [-75.6898, 37.8618],
            [-75.7121, 37.9361],
            [-75.6697, 37.9508],
          ],
        ],
        [
          [
            [-76.6268, 37.2644],
            [-76.6063, 37.2725],
            [-76.5866, 37.2735],
            [-76.5771, 37.2592],
            [-76.5864, 37.2792],
            [-76.6065, 37.2734],
            [-76.5922, 37.2832],
            [-76.5813, 37.2778],
            [-76.5774, 37.2895],
            [-76.588, 37.2967],
            [-76.5974, 37.288],
            [-76.6158, 37.3],
            [-76.6007, 37.314],
            [-76.4989, 37.2411],
            [-76.4721, 37.2161],
            [-76.3941, 37.2251],
            [-76.3997, 37.1603],
            [-76.3432, 37.1662],
            [-76.2923, 37.1266],
            [-76.2713, 37.0845],
            [-76.3043, 37.0014],
            [-76.3407, 37.0152],
            [-76.4289, 36.9699],
            [-76.4645, 37.0275],
            [-76.5551, 37.0759],
            [-76.6183, 37.1193],
            [-76.611, 37.1671],
            [-76.5777, 37.1904],
            [-76.6011, 37.2382],
            [-76.6263, 37.2333],
            [-76.6346, 37.2431],
            [-76.6268, 37.2644],
          ],
        ],
        [
          [
            [-77.1831, 36.1037],
            [-77.1674, 36.112],
            [-77.1511, 36.1089],
            [-77.1211, 36.1239],
            [-77.0952, 36.0955],
            [-77.0789, 36.0915],
            [-77.0478, 36.0991],
            [-77.0137, 36.12],
            [-76.9714, 36.1098],
            [-76.964, 36.1208],
            [-77.002, 36.1454],
            [-77.0046, 36.1892],
            [-77.0357, 36.1964],
            [-77.048, 36.2244],
            [-77.0724, 36.2419],
            [-77.0658, 36.2542],
            [-77.0718, 36.2605],
            [-77.0344, 36.2934],
            [-77.0249, 36.2883],
            [-77.0256, 36.3004],
            [-77.0421, 36.2995],
            [-77.0344, 36.3066],
            [-77.0736, 36.316],
            [-77.0913, 36.3082],
            [-77.1106, 36.3236],
            [-77.1321, 36.3285],
            [-77.1354, 36.3537],
            [-77.0937, 36.3658],
            [-77.092, 36.3545],
            [-77.0709, 36.3552],
            [-77.0631, 36.3626],
            [-77.0681, 36.3737],
            [-77.0501, 36.392],
            [-77.0137, 36.4084],
            [-77.0282, 36.4198],
            [-77.0207, 36.4278],
            [-76.9997, 36.4228],
            [-76.9953, 36.4358],
            [-76.9784, 36.4225],
            [-76.9783, 36.4306],
            [-76.9527, 36.4366],
            [-76.9466, 36.4311],
            [-76.9506, 36.4709],
            [-76.9343, 36.4714],
            [-76.935, 36.4839],
            [-76.9032, 36.5188],
            [-76.9246, 36.53],
            [-76.916, 36.5438],
            [-76.9163, 36.5542],
            [-76.9293, 36.5606],
            [-76.9203, 36.5776],
            [-76.9093, 36.5797],
            [-76.9115, 36.6177],
            [-76.8933, 36.6262],
            [-76.8977, 36.644],
            [-76.8565, 36.6738],
            [-76.8832, 36.6804],
            [-76.8603, 36.6852],
            [-76.8319, 36.7094],
            [-76.8545, 36.7118],
            [-76.8562, 36.7221],
            [-76.8689, 36.721],
            [-76.8649, 36.741],
            [-76.8758, 36.7417],
            [-76.8826, 36.7201],
            [-76.9088, 36.7219],
            [-76.9029, 36.7387],
            [-76.9168, 36.7341],
            [-76.8947, 36.7468],
            [-76.9014, 36.7573],
            [-76.8848, 36.7646],
            [-76.8841, 36.7808],
            [-76.8738, 36.7837],
            [-76.8679, 36.7746],
            [-76.8607, 36.779],
            [-76.8532, 36.7739],
            [-76.8429, 36.7825],
            [-76.7987, 36.7924],
            [-76.7921, 36.802],
            [-76.8014, 36.8077],
            [-76.7951, 36.8154],
            [-76.8054, 36.8268],
            [-76.7789, 36.8312],
            [-76.763, 36.8211],
            [-76.7643, 36.837],
            [-76.8051, 36.8483],
            [-76.7766, 36.8521],
            [-76.7755, 36.8777],
            [-76.7941, 36.8832],
            [-76.8094, 36.9112],
            [-76.7701, 36.9262],
            [-76.7822, 36.9498],
            [-76.7685, 36.9553],
            [-76.7603, 36.9711],
            [-76.7652, 36.9879],
            [-76.78, 36.9871],
            [-76.7742, 37.0022],
            [-76.7928, 37.021],
            [-76.6993, 37.0584],
            [-76.6956, 37.0833],
            [-76.6845, 37.0882],
            [-76.6842, 37.1039],
            [-76.6752, 37.1084],
            [-76.6803, 37.1268],
            [-76.6569, 37.1098],
            [-76.6683, 37.0549],
            [-76.646, 37.0362],
            [-76.5865, 37.0287],
            [-76.4876, 36.9524],
            [-76.4834, 36.8962],
            [-76.3876, 36.8995],
            [-76.3859, 36.9232],
            [-76.3289, 36.9184],
            [-76.3274, 36.9594],
            [-76.268, 36.9645],
            [-76.235, 36.9451],
            [-76.0955, 36.9088],
            [-76.0335, 36.9319],
            [-75.9963, 36.922],
            [-75.9496, 36.7612],
            [-75.8741, 36.5839],
            [-75.8187, 36.3576],
            [-75.7725, 36.2294],
            [-75.7183, 36.1137],
            [-75.6585, 36.0204],
            [-75.5698, 35.8633],
            [-75.6192, 35.8894],
            [-75.6201, 35.9253],
            [-75.6789, 35.9939],
            [-75.7237, 36.0031],
            [-75.7371, 36.0408],
            [-75.7557, 36.1539],
            [-75.7837, 36.2159],
            [-75.8116, 36.244],
            [-75.8145, 36.2853],
            [-75.8453, 36.3056],
            [-75.8319, 36.339],
            [-75.8515, 36.4158],
            [-75.8883, 36.4416],
            [-75.8999, 36.4821],
            [-76.0193, 36.5035],
            [-76.0319, 36.4828],
            [-76.0039, 36.4419],
            [-75.9623, 36.4172],
            [-75.9236, 36.4258],
            [-75.9235, 36.3678],
            [-75.8953, 36.3196],
            [-75.8399, 36.1771],
            [-75.8004, 36.1127],
            [-75.8478, 36.102],
            [-75.8674, 36.1512],
            [-76.0318, 36.1891],
            [-76.0635, 36.1735],
            [-76.0717, 36.1402],
            [-76.1789, 36.1234],
            [-76.2166, 36.0954],
            [-76.2871, 36.1049],
            [-76.3235, 36.0849],
            [-76.4109, 36.078],
            [-76.4593, 36.0243],
            [-76.5143, 36.0056],
            [-76.5807, 36.0072],
            [-76.6154, 36.0378],
            [-76.6533, 36.0351],
            [-76.6849, 36.0099],
            [-76.7002, 35.9646],
            [-76.6739, 35.9351],
            [-76.5286, 35.944],
            [-76.3982, 35.9843],
            [-76.363, 35.9422],
            [-76.3177, 35.9469],
            [-76.2724, 35.9727],
            [-76.1766, 35.9933],
            [-76.0621, 35.993],
            [-76.0242, 35.9709],
            [-76.0047, 35.9051],
            [-75.9626, 35.9014],
            [-75.8994, 35.9772],
            [-75.8499, 35.9762],
            [-75.7825, 35.9356],
            [-75.7267, 35.8114],
            [-75.7394, 35.771],
            [-75.7135, 35.694],
            [-75.7416, 35.6721],
            [-75.7298, 35.626],
            [-75.7781, 35.5923],
            [-75.8372, 35.5709],
            [-75.8698, 35.5828],
            [-75.8798, 35.6068],
            [-75.8768, 35.6281],
            [-75.9012, 35.669],
            [-76.0275, 35.6691],
            [-76.0353, 35.662],
            [-76.0594, 35.6751],
            [-76.0952, 35.6693],
            [-76.1045, 35.6784],
            [-76.1191, 35.6795],
            [-76.1176, 35.6914],
            [-76.1308, 35.6913],
            [-76.1448, 35.7024],
            [-76.173, 35.6962],
            [-76.1698, 35.6722],
            [-76.1815, 35.6664],
            [-76.1779, 35.6546],
            [-76.1933, 35.6457],
            [-76.1945, 35.6355],
            [-76.1937, 35.6458],
            [-76.18, 35.6556],
            [-76.1959, 35.7135],
            [-76.2046, 35.6722],
            [-76.2453, 35.6692],
            [-76.2647, 35.6926],
            [-76.504, 35.7033],
            [-76.494, 35.7458],
            [-76.525, 35.7664],
            [-76.5445, 35.7359],
            [-76.5679, 35.7435],
            [-76.5605, 35.7966],
            [-76.5836, 35.7522],
            [-76.5937, 35.7501],
            [-76.6237, 35.7674],
            [-76.6555, 35.7053],
            [-76.8451, 35.7054],
            [-76.8469, 35.7186],
            [-76.8174, 35.7478],
            [-76.8038, 35.7837],
            [-76.7926, 35.7891],
            [-76.8034, 35.8065],
            [-76.7826, 35.8245],
            [-76.7893, 35.8377],
            [-76.7838, 35.849],
            [-76.7608, 35.8648],
            [-76.7809, 35.8639],
            [-76.8062, 35.8838],
            [-76.8302, 35.886],
            [-76.839, 35.8452],
            [-76.8753, 35.8137],
            [-76.8973, 35.8137],
            [-76.9043, 35.8249],
            [-76.8942, 35.8318],
            [-76.9037, 35.8383],
            [-76.8933, 35.864],
            [-76.9223, 35.898],
            [-76.9411, 35.9013],
            [-76.9583, 35.8839],
            [-76.9804, 35.8726],
            [-76.9872, 35.8768],
            [-77.0049, 35.8584],
            [-77.0338, 35.8572],
            [-77.0447, 35.8621],
            [-77.0453, 35.8752],
            [-77.0312, 35.8814],
            [-77.0378, 35.8904],
            [-77.0222, 35.8912],
            [-77.0306, 35.9052],
            [-77.0277, 35.9295],
            [-77.0391, 35.931],
            [-77.0452, 35.944],
            [-77.0718, 35.9443],
            [-77.0678, 35.9374],
            [-77.0817, 35.9382],
            [-77.0932, 35.9258],
            [-77.1036, 35.934],
            [-77.125, 35.9276],
            [-77.1316, 35.9417],
            [-77.1412, 35.9347],
            [-77.1638, 35.9385],
            [-77.1767, 35.9296],
            [-77.1958, 35.9378],
            [-77.2001, 35.9501],
            [-77.1493, 35.9593],
            [-77.1422, 35.9712],
            [-77.1642, 35.9796],
            [-77.1668, 35.9885],
            [-77.1526, 35.9957],
            [-77.1378, 35.9899],
            [-77.1329, 36.0004],
            [-77.1223, 35.9961],
            [-77.1386, 36.0096],
            [-77.1191, 36.0243],
            [-77.1379, 36.0392],
            [-77.1561, 36.0404],
            [-77.1631, 36.0468],
            [-77.1527, 36.0555],
            [-77.1908, 36.0802],
            [-77.1831, 36.1037],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Winchester Mkt": {
    type: "Feature",
    properties: { code: "VA_WIN", name: "Winchester Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-78.5181, 39.7226],
          [-78.0932, 39.7223],
          [-77.5348, 39.7201],
          [-77.067, 39.7201],
          [-77.0725, 39.6841],
          [-77.1203, 39.6566],
          [-77.0993, 39.6404],
          [-77.1131, 39.6392],
          [-77.1052, 39.624],
          [-77.1224, 39.6018],
          [-77.119, 39.5932],
          [-77.1031, 39.591],
          [-77.1179, 39.5687],
          [-77.0815, 39.5681],
          [-77.076, 39.5573],
          [-77.0598, 39.5677],
          [-77.0551, 39.5485],
          [-77.0275, 39.5413],
          [-77.0533, 39.5014],
          [-77.0729, 39.4919],
          [-77.0973, 39.4926],
          [-77.0913, 39.4762],
          [-77.0988, 39.4604],
          [-77.0668, 39.4501],
          [-77.0702, 39.4421],
          [-77.0427, 39.4608],
          [-77.0328, 39.4553],
          [-77.0158, 39.4628],
          [-77.0013, 39.457],
          [-77.0057, 39.4729],
          [-76.9988, 39.479],
          [-76.9773, 39.4684],
          [-76.9509, 39.4759],
          [-76.9565, 39.4549],
          [-76.9382, 39.4449],
          [-76.9389, 39.437],
          [-76.8758, 39.4205],
          [-76.8907, 39.377],
          [-76.9044, 39.392],
          [-76.9213, 39.3847],
          [-76.9319, 39.3938],
          [-76.9312, 39.3748],
          [-76.9404, 39.3822],
          [-76.9425, 39.3704],
          [-76.9616, 39.3691],
          [-76.9534, 39.358],
          [-76.9138, 39.3511],
          [-76.9275, 39.3333],
          [-76.9264, 39.3213],
          [-76.9495, 39.3204],
          [-76.9526, 39.309],
          [-76.938, 39.2837],
          [-76.9589, 39.2876],
          [-76.9805, 39.2798],
          [-76.9848, 39.2642],
          [-76.9985, 39.2629],
          [-77.0067, 39.2493],
          [-77.0168, 39.2553],
          [-77.0412, 39.2344],
          [-77.0483, 39.2377],
          [-77.0456, 39.2554],
          [-77.0554, 39.2574],
          [-77.064, 39.2758],
          [-77.0724, 39.2761],
          [-77.0721, 39.2649],
          [-77.0555, 39.2503],
          [-77.0559, 39.2385],
          [-77.0834, 39.2602],
          [-77.1313, 39.2697],
          [-77.1449, 39.288],
          [-77.1657, 39.2874],
          [-77.1657, 39.3097],
          [-77.182, 39.3304],
          [-77.1913, 39.3296],
          [-77.1825, 39.3152],
          [-77.2144, 39.3296],
          [-77.2005, 39.3393],
          [-77.279, 39.3035],
          [-77.298, 39.3093],
          [-77.3253, 39.2811],
          [-77.3344, 39.2955],
          [-77.3592, 39.2807],
          [-77.3684, 39.303],
          [-77.3937, 39.305],
          [-77.4056, 39.2983],
          [-77.4026, 39.2925],
          [-77.4227, 39.2976],
          [-77.42, 39.2888],
          [-77.4394, 39.257],
          [-77.451, 39.2729],
          [-77.4706, 39.2568],
          [-77.47, 39.2468],
          [-77.4471, 39.2281],
          [-77.4577, 39.225],
          [-77.4868, 39.2476],
          [-77.5406, 39.2649],
          [-77.5609, 39.2862],
          [-77.5666, 39.3061],
          [-77.6159, 39.3027],
          [-77.6758, 39.3242],
          [-77.73, 39.3157],
          [-77.7707, 39.2553],
          [-77.7714, 39.2368],
          [-77.7936, 39.2101],
          [-77.8282, 39.1323],
          [-77.8609, 39.1535],
          [-77.9072, 39.1053],
          [-77.9419, 39.0936],
          [-77.973, 39.0998],
          [-77.9532, 39.0883],
          [-77.9591, 39.0766],
          [-77.9464, 39.0712],
          [-77.9566, 39.0476],
          [-77.9828, 39.03],
          [-77.9541, 39.0086],
          [-77.949, 38.9853],
          [-77.9732, 38.9602],
          [-77.9908, 38.9565],
          [-78.0009, 38.9455],
          [-77.9982, 38.9079],
          [-77.971, 38.9087],
          [-78.0021, 38.9035],
          [-78.0039, 38.88],
          [-77.9949, 38.8692],
          [-77.9654, 38.8711],
          [-77.9791, 38.8535],
          [-77.9652, 38.8476],
          [-77.9675, 38.833],
          [-77.9887, 38.8332],
          [-77.9899, 38.8251],
          [-77.9874, 38.806],
          [-77.9745, 38.7936],
          [-77.9855, 38.7821],
          [-77.9777, 38.7639],
          [-78.0012, 38.7754],
          [-78.017, 38.762],
          [-78.0454, 38.7597],
          [-78.0734, 38.7349],
          [-78.0666, 38.719],
          [-78.0972, 38.7252],
          [-78.1175, 38.7208],
          [-78.1299, 38.7274],
          [-78.1344, 38.7405],
          [-78.1632, 38.7565],
          [-78.158, 38.7702],
          [-78.166, 38.778],
          [-78.2155, 38.7741],
          [-78.2533, 38.757],
          [-78.2844, 38.7598],
          [-78.3035, 38.7389],
          [-78.3188, 38.7379],
          [-78.3322, 38.7056],
          [-78.3144, 38.6825],
          [-78.324, 38.6727],
          [-78.3156, 38.6516],
          [-78.3259, 38.6307],
          [-78.3627, 38.6122],
          [-78.3833, 38.5883],
          [-78.3854, 38.5576],
          [-78.4133, 38.5477],
          [-78.4303, 38.5269],
          [-78.4429, 38.5278],
          [-78.4362, 38.5217],
          [-78.4445, 38.4894],
          [-78.4529, 38.4755],
          [-78.4989, 38.4002],
          [-78.5154, 38.3938],
          [-78.5264, 38.368],
          [-78.5451, 38.3624],
          [-78.5543, 38.3491],
          [-78.5516, 38.3363],
          [-78.5878, 38.3249],
          [-78.6025, 38.3072],
          [-78.6519, 38.2936],
          [-78.6639, 38.2767],
          [-78.6563, 38.2605],
          [-78.6698, 38.2473],
          [-78.6819, 38.2549],
          [-78.7066, 38.2464],
          [-78.7168, 38.2493],
          [-78.77, 38.2573],
          [-78.751, 38.2904],
          [-78.7349, 38.2977],
          [-78.7557, 38.3172],
          [-78.7319, 38.3195],
          [-78.7549, 38.3218],
          [-78.7708, 38.312],
          [-78.7786, 38.3224],
          [-78.7952, 38.3105],
          [-78.8133, 38.3179],
          [-78.8083, 38.3372],
          [-78.8258, 38.3717],
          [-78.8554, 38.3448],
          [-78.842, 38.3181],
          [-78.8353, 38.3237],
          [-78.8215, 38.3171],
          [-78.8217, 38.3049],
          [-78.8105, 38.2979],
          [-78.8329, 38.3087],
          [-78.8529, 38.2948],
          [-78.8554, 38.2785],
          [-78.8937, 38.306],
          [-78.9146, 38.3043],
          [-78.9106, 38.3242],
          [-78.9261, 38.3305],
          [-78.9252, 38.3387],
          [-78.9173, 38.3342],
          [-78.9126, 38.34],
          [-78.9252, 38.3448],
          [-78.9314, 38.3386],
          [-78.9333, 38.3456],
          [-78.9305, 38.3135],
          [-78.9602, 38.3255],
          [-78.9704, 38.3034],
          [-78.984, 38.3032],
          [-78.9873, 38.3164],
          [-79.004, 38.3136],
          [-79.0157, 38.3014],
          [-78.9978, 38.2911],
          [-79.0499, 38.2497],
          [-79.0544, 38.2622],
          [-79.0913, 38.2736],
          [-79.1029, 38.2862],
          [-79.1638, 38.2964],
          [-79.1917, 38.3143],
          [-79.2023, 38.3311],
          [-79.2195, 38.325],
          [-79.2459, 38.3344],
          [-79.2403, 38.3365],
          [-79.2825, 38.3778],
          [-79.2824, 38.3989],
          [-79.2982, 38.4124],
          [-79.4766, 38.4572],
          [-79.5369, 38.5509],
          [-79.6491, 38.5915],
          [-79.6342, 38.636],
          [-79.5102, 38.7806],
          [-79.5382, 38.8434],
          [-79.4829, 38.8863],
          [-79.457, 38.9246],
          [-79.3749, 38.9208],
          [-79.3102, 39.0303],
          [-79.3147, 39.0703],
          [-79.3555, 39.0865],
          [-79.3318, 39.1478],
          [-79.2929, 39.1918],
          [-79.3332, 39.2148],
          [-79.3929, 39.1929],
          [-79.4738, 39.1817],
          [-79.5076, 39.2162],
          [-79.603, 39.2584],
          [-79.6341, 39.2922],
          [-79.6088, 39.3232],
          [-79.6552, 39.3333],
          [-79.6137, 39.3816],
          [-79.6798, 39.4023],
          [-79.6807, 39.4399],
          [-79.636, 39.4753],
          [-79.5924, 39.4619],
          [-79.5858, 39.5177],
          [-79.5411, 39.5313],
          [-79.4782, 39.5947],
          [-79.4767, 39.7211],
          [-79.0527, 39.7229],
          [-78.5181, 39.7226],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Seattle Mkt": {
    type: "Feature",
    properties: { code: "WA_SEA", name: "Seattle Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-122.6525, 48.5327],
            [-122.6524, 48.5834],
            [-122.5789, 48.5481],
            [-122.6525, 48.5327],
          ],
        ],
        [
          [
            [-122.724, 48.5499],
            [-122.7399, 48.5839],
            [-122.7145, 48.6088],
            [-122.6706, 48.5688],
            [-122.724, 48.5499],
          ],
        ],
        [
          [
            [-122.77, 48.2244],
            [-122.7226, 48.3043],
            [-122.6737, 48.3547],
            [-122.6647, 48.4015],
            [-122.5851, 48.3952],
            [-122.5852, 48.3533],
            [-122.516, 48.3204],
            [-122.531, 48.2824],
            [-122.6189, 48.2939],
            [-122.6684, 48.224],
            [-122.6064, 48.2083],
            [-122.5582, 48.1196],
            [-122.5719, 48.1021],
            [-122.5451, 48.0525],
            [-122.4911, 48.0942],
            [-122.4313, 48.045],
            [-122.3763, 48.0345],
            [-122.3507, 47.9532],
            [-122.3758, 47.9103],
            [-122.431, 47.9147],
            [-122.4727, 47.9884],
            [-122.5212, 47.973],
            [-122.6073, 48.031],
            [-122.5983, 48.1106],
            [-122.6175, 48.1591],
            [-122.656, 48.1625],
            [-122.77, 48.2244],
          ],
        ],
        [
          [
            [-123.1632, 48.5295],
            [-123.1784, 48.6221],
            [-123.1074, 48.6225],
            [-123.1016, 48.5978],
            [-123.0484, 48.5692],
            [-123.0234, 48.5995],
            [-123.0487, 48.621],
            [-122.9183, 48.7135],
            [-122.8759, 48.7121],
            [-122.743, 48.662],
            [-122.8093, 48.6193],
            [-122.7712, 48.5624],
            [-122.7791, 48.5089],
            [-122.8163, 48.4878],
            [-122.8025, 48.4331],
            [-122.8741, 48.4182],
            [-122.9273, 48.4607],
            [-122.962, 48.4512],
            [-123.0392, 48.46],
            [-123.1195, 48.4926],
            [-123.1632, 48.5295],
          ],
        ],
        [
          [
            [-123.9437, 46.4772],
            [-123.8943, 46.537],
            [-123.9606, 46.6364],
            [-123.9233, 46.6727],
            [-123.8514, 46.7026],
            [-123.8767, 46.7307],
            [-123.9797, 46.7247],
            [-124.0224, 46.709],
            [-124.0965, 46.7462],
            [-124.1081, 46.8364],
            [-124.1382, 46.9056],
            [-124.0904, 46.8955],
            [-124.0893, 46.8677],
            [-123.9851, 46.9219],
            [-123.8602, 46.9486],
            [-123.8982, 46.9719],
            [-123.9916, 46.9802],
            [-124.0263, 47.0302],
            [-124.1221, 47.0416],
            [-124.1513, 47.0211],
            [-124.1244, 46.9439],
            [-124.1801, 46.9264],
            [-124.1691, 46.9945],
            [-124.1767, 47.093],
            [-124.2095, 47.2194],
            [-124.2363, 47.2873],
            [-124.3194, 47.3556],
            [-124.3367, 47.416],
            [-124.356, 47.5457],
            [-124.3822, 47.6323],
            [-124.4305, 47.7462],
            [-124.4766, 47.7697],
            [-124.4897, 47.817],
            [-124.5399, 47.837],
            [-124.5624, 47.8662],
            [-124.6255, 47.888],
            [-124.6724, 47.9644],
            [-124.6871, 48.0987],
            [-124.7317, 48.17],
            [-124.7042, 48.1844],
            [-124.6589, 48.3311],
            [-124.6967, 48.3497],
            [-124.7169, 48.3898],
            [-124.5973, 48.3819],
            [-124.3809, 48.2847],
            [-124.2386, 48.2625],
            [-124.1018, 48.2169],
            [-124.0507, 48.1777],
            [-123.981, 48.1648],
            [-123.7781, 48.1555],
            [-123.7027, 48.1668],
            [-123.5908, 48.1349],
            [-123.5606, 48.1507],
            [-123.5072, 48.1318],
            [-123.395, 48.1142],
            [-123.2486, 48.1157],
            [-123.165, 48.1654],
            [-123.0662, 48.1205],
            [-123.0387, 48.0811],
            [-122.9461, 48.0986],
            [-122.9186, 48.0582],
            [-122.8776, 48.047],
            [-122.8763, 48.1109],
            [-122.8332, 48.1344],
            [-122.7604, 48.1432],
            [-122.7489, 48.117],
            [-122.8014, 48.0876],
            [-122.7706, 48.0534],
            [-122.6985, 48.1031],
            [-122.6699, 48.0172],
            [-122.7181, 47.988],
            [-122.6576, 47.931],
            [-122.6364, 47.8662],
            [-122.6938, 47.868],
            [-122.6886, 47.8314],
            [-122.7481, 47.8005],
            [-122.7402, 47.7362],
            [-122.682, 47.8009],
            [-122.5731, 47.8741],
            [-122.5962, 47.9202],
            [-122.5491, 47.9191],
            [-122.514, 47.8808],
            [-122.4885, 47.7436],
            [-122.5545, 47.7457],
            [-122.5432, 47.7109],
            [-122.5046, 47.6991],
            [-122.5183, 47.6513],
            [-122.4932, 47.6351],
            [-122.4939, 47.589],
            [-122.5431, 47.5563],
            [-122.523, 47.524],
            [-122.4524, 47.5035],
            [-122.4334, 47.4664],
            [-122.4401, 47.4176],
            [-122.4018, 47.3813],
            [-122.454, 47.3433],
            [-122.5333, 47.3166],
            [-122.4442, 47.2667],
            [-122.4092, 47.2886],
            [-122.4235, 47.3191],
            [-122.3248, 47.3485],
            [-122.3257, 47.3915],
            [-122.3965, 47.5152],
            [-122.4098, 47.5689],
            [-122.3429, 47.5912],
            [-122.3678, 47.6242],
            [-122.4298, 47.6589],
            [-122.3731, 47.7292],
            [-122.392, 47.8077],
            [-122.3399, 47.8467],
            [-122.307, 47.9491],
            [-122.2263, 47.9764],
            [-122.2318, 48.0299],
            [-122.2811, 48.0498],
            [-122.3651, 48.1258],
            [-122.3725, 48.193],
            [-122.3961, 48.2292],
            [-122.4785, 48.1881],
            [-122.4424, 48.1308],
            [-122.512, 48.1339],
            [-122.5372, 48.1837],
            [-122.531, 48.2498],
            [-122.4065, 48.2518],
            [-122.3717, 48.2878],
            [-122.4087, 48.3264],
            [-122.5074, 48.3647],
            [-122.5816, 48.4292],
            [-122.6498, 48.4085],
            [-122.6789, 48.4395],
            [-122.6646, 48.4781],
            [-122.7016, 48.4976],
            [-122.6152, 48.5214],
            [-122.5681, 48.5082],
            [-122.5374, 48.4667],
            [-122.4835, 48.4924],
            [-122.4784, 48.5593],
            [-122.4253, 48.5994],
            [-122.5003, 48.6562],
            [-122.5192, 48.7131],
            [-122.4904, 48.7511],
            [-122.5358, 48.7761],
            [-122.6474, 48.774],
            [-122.6987, 48.8005],
            [-122.7227, 48.8529],
            [-122.7857, 48.8851],
            [-122.7466, 48.9307],
            [-122.8172, 48.956],
            [-122.7969, 48.975],
            [-122.7743, 48.991],
            [-122.7564, 48.9968],
            [-122.756, 48.9995],
            [-122.758, 49.0024],
            [-122.0984, 49.0021],
            [-122.0026, 49.0008],
            [-121.9303, 48.9998],
            [-121.7513, 48.9974],
            [-121.3955, 48.9999],
            [-121.3453, 49.0008],
            [-121.2569, 49.0001],
            [-121.2299, 49.0012],
            [-120.8514, 49.0003],
            [-120.8555, 48.9981],
            [-120.8531, 48.9945],
            [-120.8517, 48.9931],
            [-120.864, 48.9807],
            [-120.8811, 48.9661],
            [-120.8497, 48.9559],
            [-120.8308, 48.938],
            [-120.7981, 48.9587],
            [-120.7676, 48.9544],
            [-120.756, 48.9426],
            [-120.7558, 48.9316],
            [-120.7718, 48.9265],
            [-120.7635, 48.9001],
            [-120.77, 48.8813],
            [-120.7374, 48.8642],
            [-120.7325, 48.8532],
            [-120.7434, 48.82],
            [-120.7352, 48.7857],
            [-120.6547, 48.7242],
            [-120.6779, 48.7181],
            [-120.678, 48.6953],
            [-120.7033, 48.6972],
            [-120.7119, 48.6893],
            [-120.7168, 48.6649],
            [-120.7501, 48.6593],
            [-120.7524, 48.6487],
            [-120.7839, 48.6022],
            [-120.7601, 48.583],
            [-120.7349, 48.5811],
            [-120.7189, 48.5968],
            [-120.6886, 48.5755],
            [-120.6854, 48.5661],
            [-120.7089, 48.542],
            [-120.7016, 48.5316],
            [-120.7086, 48.5237],
            [-120.7877, 48.5064],
            [-120.8094, 48.5296],
            [-120.824, 48.5452],
            [-120.8576, 48.5485],
            [-120.8628, 48.53],
            [-120.9041, 48.5333],
            [-120.9055, 48.5233],
            [-120.9499, 48.53],
            [-120.9506, 48.4999],
            [-121.0358, 48.4947],
            [-121.0567, 48.4772],
            [-121.062, 48.4594],
            [-121.056, 48.4124],
            [-121.0663, 48.393],
            [-121.0406, 48.3481],
            [-121.0694, 48.3389],
            [-121.0715, 48.3179],
            [-121.0035, 48.2992],
            [-121.0116, 48.2804],
            [-121.0039, 48.2672],
            [-120.9808, 48.2495],
            [-120.9722, 48.2351],
            [-120.9646, 48.2306],
            [-120.962, 48.2301],
            [-120.9596, 48.2282],
            [-120.9593, 48.227],
            [-120.9639, 48.211],
            [-120.9325, 48.1972],
            [-120.9231, 48.1913],
            [-120.9195, 48.1881],
            [-120.9088, 48.1728],
            [-120.9062, 48.1638],
            [-120.9545, 48.1525],
            [-120.9641, 48.1333],
            [-120.9464, 48.1134],
            [-120.971, 48.1092],
            [-120.9809, 48.0944],
            [-121.015, 48.0752],
            [-121.0314, 48.0782],
            [-121.0806, 48.0618],
            [-121.0945, 48.0646],
            [-121.1315, 48.044],
            [-121.1535, 48.0407],
            [-121.1172, 47.9998],
            [-121.1468, 47.9815],
            [-121.1646, 47.9562],
            [-121.1619, 47.9447],
            [-121.1795, 47.9188],
            [-121.1712, 47.9126],
            [-121.1801, 47.8992],
            [-121.1751, 47.8857],
            [-121.1525, 47.8699],
            [-121.1537, 47.8458],
            [-121.1074, 47.8288],
            [-121.0701, 47.8301],
            [-121.0936, 47.7997],
            [-121.1204, 47.7841],
            [-121.1103, 47.7581],
            [-121.094, 47.7583],
            [-121.0789, 47.7392],
            [-121.0839, 47.7233],
            [-121.0661, 47.7141],
            [-121.0671, 47.7062],
            [-121.09, 47.703],
            [-121.1275, 47.6768],
            [-121.123, 47.6638],
            [-121.1334, 47.6456],
            [-121.1139, 47.6216],
            [-121.1204, 47.6128],
            [-121.1112, 47.5994],
            [-121.1158, 47.5977],
            [-121.1201, 47.5912],
            [-121.1383, 47.5955],
            [-121.1708, 47.5899],
            [-121.1804, 47.5648],
            [-121.2281, 47.5658],
            [-121.2425, 47.5359],
            [-121.2832, 47.519],
            [-121.3022, 47.4864],
            [-121.3488, 47.4459],
            [-121.3807, 47.3895],
            [-121.3716, 47.3679],
            [-121.3869, 47.3915],
            [-121.3901, 47.3778],
            [-121.3973, 47.3719],
            [-121.3915, 47.3814],
            [-121.3965, 47.3871],
            [-121.3993, 47.3896],
            [-121.4408, 47.385],
            [-121.4421, 47.3731],
            [-121.4662, 47.368],
            [-121.4638, 47.3536],
            [-121.4309, 47.3344],
            [-121.4455, 47.3096],
            [-121.4336, 47.305],
            [-121.4269, 47.2885],
            [-121.4157, 47.2934],
            [-121.3729, 47.2852],
            [-121.362, 47.2917],
            [-121.3411, 47.2816],
            [-121.3323, 47.2556],
            [-121.3591, 47.2402],
            [-121.365, 47.2251],
            [-121.331, 47.2178],
            [-121.3093, 47.2044],
            [-121.306, 47.1877],
            [-121.318, 47.1735],
            [-121.298, 47.1482],
            [-121.315, 47.1345],
            [-121.3647, 47.1418],
            [-121.3939, 47.133],
            [-121.4076, 47.1191],
            [-121.4029, 47.0978],
            [-121.3769, 47.0902],
            [-121.3836, 47.083],
            [-121.3735, 47.0569],
            [-121.3971, 47.0359],
            [-121.4072, 46.9983],
            [-121.4454, 46.98],
            [-121.4419, 46.973],
            [-121.4566, 46.9645],
            [-121.4493, 46.9344],
            [-121.4565, 46.9227],
            [-121.4966, 46.9088],
            [-121.5233, 46.8733],
            [-121.4954, 46.8629],
            [-121.4709, 46.818],
            [-121.4437, 46.7945],
            [-121.4555, 46.7807],
            [-121.4251, 46.7578],
            [-121.4296, 46.7395],
            [-121.3681, 46.7261],
            [-121.3523, 46.7124],
            [-121.3795, 46.7025],
            [-121.3762, 46.6859],
            [-121.4009, 46.6861],
            [-121.4136, 46.6753],
            [-121.4161, 46.6517],
            [-121.3947, 46.6457],
            [-121.3877, 46.6178],
            [-121.4078, 46.6022],
            [-121.4067, 46.5814],
            [-121.4243, 46.5702],
            [-121.4129, 46.5585],
            [-121.4167, 46.5468],
            [-121.4509, 46.5339],
            [-121.4558, 46.5147],
            [-121.4487, 46.5026],
            [-121.4232, 46.4927],
            [-121.4366, 46.4782],
            [-121.412, 46.4595],
            [-121.4073, 46.4299],
            [-121.3866, 46.4106],
            [-121.3939, 46.3898],
            [-121.5236, 46.3882],
            [-122.2417, 46.3864],
            [-122.9887, 46.3874],
            [-122.9887, 46.3533],
            [-123.036, 46.3233],
            [-123.1268, 46.3102],
            [-123.1446, 46.331],
            [-123.214, 46.341],
            [-123.2178, 46.3851],
            [-123.5607, 46.3839],
            [-123.5487, 46.4166],
            [-123.4631, 46.4301],
            [-123.4647, 46.4811],
            [-123.5343, 46.4761],
            [-123.5706, 46.5129],
            [-123.6473, 46.4881],
            [-123.6453, 46.4461],
            [-123.6847, 46.4428],
            [-123.7196, 46.4703],
            [-123.7925, 46.4839],
            [-123.8024, 46.4456],
            [-123.8657, 46.4313],
            [-123.9437, 46.4772],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Eau Claire Mkt": {
    type: "Feature",
    properties: { code: "WI_EAU", name: "Eau Claire Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-89.7781, 44.112],
            [-89.798, 44.1119],
            [-89.8078, 44.083],
            [-89.833, 44.0828],
            [-89.8332, 44.0465],
            [-89.8848, 44.0249],
            [-89.9615, 43.9663],
            [-89.9524, 43.9201],
            [-90.0191, 43.9056],
            [-90.0549, 43.9734],
            [-90.1224, 43.9669],
            [-90.1493, 43.9958],
            [-90.2121, 43.9579],
            [-90.2283, 43.9273],
            [-90.219, 43.8806],
            [-90.2619, 43.8781],
            [-90.2589, 43.8286],
            [-90.3118, 43.8301],
            [-90.3122, 43.769],
            [-90.4328, 43.7293],
            [-90.3772, 43.7172],
            [-90.3125, 43.6725],
            [-90.3117, 43.6016],
            [-90.3549, 43.5533],
            [-90.3611, 43.5147],
            [-90.4308, 43.5144],
            [-90.5651, 43.4301],
            [-90.6426, 43.4209],
            [-90.6636, 43.342],
            [-90.6465, 43.3075],
            [-90.6945, 43.2739],
            [-90.7602, 43.2481],
            [-90.7495, 43.2233],
            [-90.8348, 43.2196],
            [-90.8101, 43.1765],
            [-90.867, 43.182],
            [-90.8641, 43.1024],
            [-90.9118, 43.1089],
            [-90.9147, 43.1801],
            [-91.0121, 43.1716],
            [-91.04, 43.1475],
            [-91.1562, 43.1429],
            [-91.1191, 43.2004],
            [-91.0579, 43.254],
            [-91.1291, 43.3263],
            [-91.2018, 43.3491],
            [-91.2004, 43.4127],
            [-91.2322, 43.46],
            [-91.2177, 43.5005],
            [-91.9109, 43.5006],
            [-92.4964, 43.5003],
            [-93.0714, 43.4996],
            [-93.0894, 43.5142],
            [-93.0491, 43.5215],
            [-93.0493, 43.5868],
            [-93.1092, 43.6011],
            [-93.1489, 43.6048],
            [-93.1491, 43.6301],
            [-93.0894, 43.63],
            [-93.0893, 43.6586],
            [-93.0494, 43.6597],
            [-93.0493, 43.6865],
            [-93.0892, 43.6882],
            [-93.1117, 43.7132],
            [-93.1577, 43.7267],
            [-93.1593, 43.7468],
            [-93.1291, 43.7465],
            [-93.1195, 43.7758],
            [-93.1245, 43.783],
            [-93.1496, 43.7758],
            [-93.1695, 43.7705],
            [-93.1694, 43.8483],
            [-93.1859, 43.8483],
            [-93.1926, 43.8788],
            [-93.2335, 43.9012],
            [-93.2353, 43.9211],
            [-93.1859, 43.9209],
            [-93.1659, 43.9646],
            [-93.1261, 43.9648],
            [-93.1171, 43.9683],
            [-93.1277, 43.9804],
            [-93.1037, 43.9814],
            [-93.1059, 43.9938],
            [-93.1059, 44.0663],
            [-93.086, 44.0916],
            [-93.0456, 44.1024],
            [-93.0456, 44.146],
            [-93.0658, 44.1459],
            [-93.1063, 44.1605],
            [-93.0862, 44.1749],
            [-93.0914, 44.211],
            [-93.1115, 44.2114],
            [-93.1115, 44.2181],
            [-93.1015, 44.2186],
            [-93.1009, 44.298],
            [-93.0818, 44.2982],
            [-93.0808, 44.3126],
            [-92.9794, 44.3125],
            [-92.9752, 44.3483],
            [-92.935, 44.3738],
            [-92.9225, 44.3895],
            [-92.9062, 44.3701],
            [-92.8507, 44.3699],
            [-92.8048, 44.3698],
            [-92.8103, 44.3844],
            [-92.7741, 44.3841],
            [-92.7965, 44.3698],
            [-92.7666, 44.3446],
            [-92.7619, 44.3552],
            [-92.7212, 44.3547],
            [-92.7222, 44.3692],
            [-92.701, 44.3545],
            [-92.5861, 44.3538],
            [-92.5861, 44.3399],
            [-92.5759, 44.3393],
            [-92.4913, 44.3538],
            [-92.476, 44.3211],
            [-92.4386, 44.3117],
            [-92.4296, 44.3273],
            [-92.411, 44.3246],
            [-92.4111, 44.303],
            [-92.3807, 44.2994],
            [-92.3232, 44.3103],
            [-92.3066, 44.2992],
            [-92.2906, 44.2985],
            [-92.2675, 44.3072],
            [-92.2117, 44.2969],
            [-92.1884, 44.2857],
            [-92.1832, 44.2948],
            [-92.1783, 44.2974],
            [-92.1776, 44.2999],
            [-92.1788, 44.3014],
            [-92.178, 44.3023],
            [-92.1713, 44.3043],
            [-92.1622, 44.3033],
            [-92.147, 44.3142],
            [-92.132, 44.3024],
            [-92.1231, 44.3095],
            [-92.1393, 44.3307],
            [-92.15, 44.3319],
            [-92.1687, 44.3272],
            [-92.1624, 44.3098],
            [-92.1653, 44.3074],
            [-92.1684, 44.3058],
            [-92.178, 44.3026],
            [-92.1851, 44.2988],
            [-92.1898, 44.2875],
            [-92.1998, 44.2951],
            [-92.1896, 44.3308],
            [-92.1556, 44.3444],
            [-92.1695, 44.3668],
            [-92.2171, 44.3877],
            [-92.1846, 44.3906],
            [-92.1637, 44.4097],
            [-92.1636, 44.4274],
            [-92.2211, 44.4404],
            [-92.2824, 44.4777],
            [-92.329, 44.5509],
            [-92.4311, 44.5658],
            [-92.4938, 44.5661],
            [-92.5184, 44.5752],
            [-92.5481, 44.5678],
            [-92.5694, 44.6035],
            [-92.5862, 44.6001],
            [-92.6015, 44.6121],
            [-92.6215, 44.615],
            [-92.6198, 44.6342],
            [-92.6321, 44.649],
            [-92.661, 44.6609],
            [-92.7373, 44.7172],
            [-92.808, 44.7515],
            [-92.7653, 44.8372],
            [-92.7634, 44.8742],
            [-92.7746, 44.8981],
            [-92.7587, 44.909],
            [-92.7506, 44.9373],
            [-92.7694, 44.9721],
            [-92.7712, 45.0014],
            [-92.7621, 45.0243],
            [-92.8031, 45.061],
            [-92.7915, 45.0796],
            [-92.7396, 45.1156],
            [-92.7577, 45.1555],
            [-92.7524, 45.1739],
            [-92.7668, 45.1855],
            [-92.7517, 45.2187],
            [-92.7602, 45.2496],
            [-92.7517, 45.2659],
            [-92.7619, 45.287],
            [-92.71, 45.3213],
            [-92.699, 45.3364],
            [-92.7027, 45.3585],
            [-92.6504, 45.3985],
            [-92.6466, 45.4416],
            [-92.6535, 45.4553],
            [-92.6802, 45.4643],
            [-92.7267, 45.5145],
            [-92.7248, 45.5386],
            [-92.7456, 45.553],
            [-92.7527, 45.5567],
            [-92.7553, 45.558],
            [-92.7611, 45.5635],
            [-92.769, 45.5678],
            [-92.76, 45.571],
            [-92.7735, 45.5846],
            [-92.7717, 45.5964],
            [-92.733, 45.6209],
            [-92.7553, 45.6287],
            [-92.7723, 45.6288],
            [-92.7802, 45.6434],
            [-92.6104, 45.6429],
            [-92.6106, 45.6068],
            [-92.5902, 45.6069],
            [-92.5897, 45.5704],
            [-92.61, 45.5535],
            [-92.6099, 45.5286],
            [-92.5896, 45.5267],
            [-92.5998, 45.5123],
            [-92.6111, 45.4908],
            [-92.5705, 45.4689],
            [-92.5699, 45.4257],
            [-92.4714, 45.4244],
            [-92.4694, 45.396],
            [-92.4462, 45.3956],
            [-92.4487, 45.409],
            [-92.387, 45.4176],
            [-92.387, 45.4348],
            [-92.3779, 45.4383],
            [-92.3785, 45.4282],
            [-92.3663, 45.4243],
            [-92.3107, 45.4246],
            [-92.305, 45.4029],
            [-92.26, 45.4082],
            [-92.2603, 45.3745],
            [-92.2082, 45.3811],
            [-92.2087, 45.3648],
            [-92.1566, 45.3598],
            [-92.1152, 45.3674],
            [-92.1145, 45.3541],
            [-92.0946, 45.3527],
            [-92.0742, 45.3385],
            [-92.0747, 45.3599],
            [-91.9896, 45.3611],
            [-91.9979, 45.3388],
            [-91.9718, 45.338],
            [-91.9724, 45.3163],
            [-91.9412, 45.3131],
            [-91.9285, 45.3229],
            [-91.8952, 45.3229],
            [-91.9105, 45.3373],
            [-91.93, 45.3591],
            [-91.8588, 45.3517],
            [-91.8078, 45.3513],
            [-91.7714, 45.3511],
            [-91.7648, 45.337],
            [-91.7459, 45.3425],
            [-91.7413, 45.3222],
            [-91.7255, 45.3434],
            [-91.7468, 45.3509],
            [-91.7459, 45.3654],
            [-91.7254, 45.3693],
            [-91.7457, 45.3794],
            [-91.7253, 45.3724],
            [-91.7157, 45.3723],
            [-91.7222, 45.3805],
            [-91.7049, 45.3732],
            [-91.7112, 45.3792],
            [-91.6991, 45.3791],
            [-91.6842, 45.3685],
            [-91.6842, 45.3577],
            [-91.6588, 45.3604],
            [-91.6377, 45.3502],
            [-91.6232, 45.3899],
            [-91.5578, 45.3898],
            [-91.555, 45.4079],
            [-91.5208, 45.3898],
            [-91.5006, 45.3893],
            [-91.5176, 45.3637],
            [-91.4817, 45.3632],
            [-91.4741, 45.3384],
            [-91.4596, 45.3491],
            [-91.3883, 45.3487],
            [-91.3884, 45.3402],
            [-91.4077, 45.3363],
            [-91.4221, 45.3233],
            [-91.4211, 45.3137],
            [-91.4089, 45.3199],
            [-91.4086, 45.3104],
            [-91.4284, 45.3011],
            [-91.373, 45.3179],
            [-91.3729, 45.334],
            [-91.3585, 45.3342],
            [-91.3502, 45.3521],
            [-91.341, 45.3534],
            [-91.3231, 45.3333],
            [-91.2892, 45.3279],
            [-91.2656, 45.3064],
            [-91.2613, 45.3281],
            [-91.2358, 45.3285],
            [-91.237, 45.298],
            [-91.2278, 45.3366],
            [-91.213, 45.3497],
            [-91.2063, 45.3424],
            [-91.1939, 45.3454],
            [-91.1934, 45.3356],
            [-91.1319, 45.3354],
            [-91.1375, 45.375],
            [-91.1101, 45.3931],
            [-91.0695, 45.3642],
            [-91.0487, 45.4044],
            [-91.0404, 45.388],
            [-91.0282, 45.3947],
            [-91.0283, 45.4009],
            [-90.9984, 45.4012],
            [-90.9975, 45.4229],
            [-90.9825, 45.423],
            [-90.9969, 45.404],
            [-90.9871, 45.401],
            [-90.9869, 45.3867],
            [-90.9042, 45.4058],
            [-90.9045, 45.3856],
            [-90.8597, 45.3842],
            [-90.8445, 45.3901],
            [-90.8401, 45.407],
            [-90.7302, 45.4294],
            [-90.7303, 45.399],
            [-90.7199, 45.3996],
            [-90.7199, 45.3838],
            [-90.7403, 45.3782],
            [-90.6209, 45.3774],
            [-90.6202, 45.3665],
            [-90.6101, 45.3501],
            [-90.6563, 45.3491],
            [-90.6579, 45.3307],
            [-90.6792, 45.3031],
            [-90.6947, 45.2942],
            [-90.7077, 45.2963],
            [-90.7207, 45.2776],
            [-90.7323, 45.2778],
            [-90.7344, 45.2927],
            [-90.751, 45.2988],
            [-90.7334, 45.3202],
            [-90.7336, 45.3306],
            [-90.7484, 45.3306],
            [-90.7588, 45.3453],
            [-90.7827, 45.356],
            [-90.835, 45.3478],
            [-90.8024, 45.3504],
            [-90.8025, 45.3206],
            [-90.7828, 45.317],
            [-90.8024, 45.3133],
            [-90.8438, 45.2989],
            [-90.8443, 45.278],
            [-90.8853, 45.2781],
            [-90.9079, 45.2635],
            [-90.9254, 45.2771],
            [-90.9256, 45.2523],
            [-90.9569, 45.2485],
            [-90.9259, 45.2285],
            [-90.9259, 45.1916],
            [-90.9517, 45.1937],
            [-90.9632, 45.1774],
            [-90.9942, 45.1772],
            [-91.0116, 45.1655],
            [-90.9881, 45.163],
            [-90.9922, 45.1368],
            [-91.0127, 45.134],
            [-91.0005, 45.1097],
            [-90.9827, 45.1194],
            [-90.926, 45.123],
            [-90.926, 45.1336],
            [-90.8985, 45.1267],
            [-90.8947, 45.1195],
            [-90.8404, 45.1193],
            [-90.8405, 45.0754],
            [-90.7999, 45.0752],
            [-90.7809, 45.0316],
            [-90.6994, 45.0313],
            [-90.6789, 44.9624],
            [-90.7197, 44.9591],
            [-90.7013, 44.9267],
            [-90.7062, 44.8969],
            [-90.714, 44.8862],
            [-90.7603, 44.8827],
            [-90.7603, 44.8288],
            [-90.7933, 44.829],
            [-90.7834, 44.8184],
            [-90.7921, 44.7725],
            [-90.8009, 44.7671],
            [-90.8416, 44.7745],
            [-90.8415, 44.786],
            [-90.8612, 44.7712],
            [-90.9215, 44.7703],
            [-90.9219, 44.669],
            [-90.8989, 44.6363],
            [-90.8638, 44.6601],
            [-90.8365, 44.6663],
            [-90.8415, 44.6228],
            [-90.8723, 44.5962],
            [-90.7903, 44.5922],
            [-90.8034, 44.5607],
            [-90.7819, 44.5579],
            [-90.8003, 44.5384],
            [-90.8208, 44.5182],
            [-90.8019, 44.5095],
            [-90.8018, 44.472],
            [-90.7797, 44.4749],
            [-90.7619, 44.4642],
            [-90.7437, 44.466],
            [-90.7412, 44.444],
            [-90.6989, 44.4439],
            [-90.6852, 44.4552],
            [-90.7112, 44.4222],
            [-90.5587, 44.4222],
            [-90.5584, 44.3786],
            [-90.637, 44.3898],
            [-90.6773, 44.3786],
            [-90.6755, 44.374],
            [-90.6122, 44.3405],
            [-90.5884, 44.3401],
            [-90.5916, 44.3357],
            [-90.553, 44.3104],
            [-90.4541, 44.2938],
            [-90.4322, 44.2794],
            [-90.4276, 44.2368],
            [-90.399, 44.2457],
            [-90.3984, 44.274],
            [-90.3848, 44.2827],
            [-90.3444, 44.2775],
            [-90.3446, 44.2699],
            [-90.318, 44.2602],
            [-90.3183, 44.2488],
            [-89.9764, 44.249],
            [-89.996, 44.213],
            [-90.0001, 44.2275],
            [-90.0565, 44.1986],
            [-90.0679, 44.1989],
            [-90.0714, 44.1663],
            [-90.0618, 44.1672],
            [-90.0438, 44.1908],
            [-90.0361, 44.1696],
            [-90.0159, 44.1709],
            [-90.0156, 44.1605],
            [-89.9954, 44.1698],
            [-89.9754, 44.1732],
            [-89.9461, 44.1699],
            [-89.9451, 44.176],
            [-89.9442, 44.1696],
            [-89.9745, 44.1688],
            [-89.9761, 44.1625],
            [-89.9659, 44.1679],
            [-89.9415, 44.1641],
            [-89.9328, 44.1523],
            [-89.9047, 44.1604],
            [-89.9044, 44.1743],
            [-89.8967, 44.1734],
            [-89.8938, 44.1554],
            [-89.8984, 44.1408],
            [-89.8784, 44.1262],
            [-89.7778, 44.1265],
            [-89.7781, 44.112],
          ],
        ],
        [
          [
            [-93.379, 44.1137],
            [-93.3804, 44.1072],
            [-93.3868, 44.1097],
            [-93.379, 44.1137],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Madison Mkt": {
    type: "Feature",
    properties: { code: "WI_MAD", name: "Madison Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-88.6236, 43.7199],
            [-88.6442, 43.7201],
            [-88.639, 43.7379],
            [-88.6236, 43.7199],
          ],
        ],
        [
          [
            [-89.7781, 44.112],
            [-89.7784, 44.0983],
            [-89.7566, 44.112],
            [-89.758, 44.0999],
            [-89.6386, 44.112],
            [-89.6183, 44.1195],
            [-89.6183, 44.0922],
            [-89.7297, 44.0858],
            [-89.7481, 44.0755],
            [-89.6585, 44.0684],
            [-89.6612, 44.0573],
            [-89.7482, 44.055],
            [-89.748, 44.0396],
            [-89.7177, 44.0387],
            [-89.7442, 44.0332],
            [-89.7482, 44.0251],
            [-89.6184, 44.0221],
            [-89.6431, 43.9704],
            [-89.648, 43.9523],
            [-89.598, 43.9822],
            [-89.5479, 43.9823],
            [-89.5424, 43.9966],
            [-89.5223, 43.9966],
            [-89.5119, 43.9821],
            [-89.4455, 43.9822],
            [-89.4465, 43.976],
            [-89.4233, 43.97],
            [-89.3774, 43.969],
            [-89.3653, 43.9534],
            [-89.3588, 43.9118],
            [-89.3289, 43.9182],
            [-89.3471, 43.925],
            [-89.3472, 43.9376],
            [-89.3159, 43.9343],
            [-89.3059, 43.8614],
            [-89.2581, 43.8616],
            [-89.2384, 43.854],
            [-89.2381, 43.8264],
            [-89.2215, 43.8232],
            [-89.1981, 43.8302],
            [-89.1906, 43.8048],
            [-89.1485, 43.8124],
            [-89.1096, 43.7744],
            [-89.1226, 43.8066],
            [-89.0658, 43.7841],
            [-89.0607, 43.8054],
            [-89.026, 43.8051],
            [-89.0359, 43.8148],
            [-89.0149, 43.8148],
            [-88.993, 43.831],
            [-88.9593, 43.8241],
            [-88.956, 43.8173],
            [-88.9722, 43.8126],
            [-88.9562, 43.8154],
            [-88.9557, 43.7714],
            [-88.9055, 43.7715],
            [-88.8054, 43.7786],
            [-88.7985, 43.7713],
            [-88.8057, 43.7931],
            [-88.7482, 43.7931],
            [-88.7251, 43.8078],
            [-88.7207, 43.7856],
            [-88.6753, 43.7773],
            [-88.6748, 43.7462],
            [-88.6632, 43.7398],
            [-88.6573, 43.7202],
            [-88.6442, 43.7201],
            [-88.6444, 43.6553],
            [-88.6747, 43.6332],
            [-88.6445, 43.6052],
            [-88.644, 43.4587],
            [-88.7611, 43.4589],
            [-88.7805, 43.4335],
            [-88.802, 43.358],
            [-88.7734, 43.3263],
            [-88.854, 43.2419],
            [-88.9, 43.1611],
            [-88.846, 43.0725],
            [-88.7942, 43.0717],
            [-88.8085, 43.0321],
            [-88.7388, 43.0357],
            [-88.7098, 42.9748],
            [-88.639, 42.9685],
            [-88.6359, 42.9151],
            [-88.669, 42.8953],
            [-88.7779, 42.9125],
            [-88.7772, 42.8614],
            [-88.8555, 42.8446],
            [-88.856, 42.7107],
            [-88.8069, 42.6998],
            [-88.7909, 42.5913],
            [-88.735, 42.5764],
            [-88.6784, 42.5793],
            [-88.6683, 42.4944],
            [-88.746, 42.4926],
            [-89.2508, 42.498],
            [-89.6676, 42.505],
            [-90.0737, 42.5083],
            [-90.3627, 42.507],
            [-90.3532, 42.5424],
            [-90.3088, 42.5366],
            [-90.3083, 42.6697],
            [-90.3668, 42.6827],
            [-90.3479, 42.7337],
            [-90.373, 42.806],
            [-90.4271, 42.8276],
            [-90.4473, 42.8564],
            [-90.5066, 42.8568],
            [-90.5467, 42.881],
            [-90.5465, 42.9065],
            [-90.4779, 42.9406],
            [-90.4783, 42.9933],
            [-90.5468, 43.0148],
            [-90.5245, 43.0502],
            [-90.552, 43.0919],
            [-90.4912, 43.1687],
            [-90.5153, 43.181],
            [-90.6197, 43.1842],
            [-90.6668, 43.1718],
            [-90.6669, 43.2295],
            [-90.6945, 43.2739],
            [-90.6465, 43.3075],
            [-90.6636, 43.342],
            [-90.6426, 43.4209],
            [-90.5651, 43.4301],
            [-90.4308, 43.5144],
            [-90.3611, 43.5147],
            [-90.3549, 43.5533],
            [-90.3117, 43.6016],
            [-90.3125, 43.6725],
            [-90.3772, 43.7172],
            [-90.4328, 43.7293],
            [-90.3122, 43.769],
            [-90.3118, 43.8301],
            [-90.2589, 43.8286],
            [-90.2619, 43.8781],
            [-90.219, 43.8806],
            [-90.2283, 43.9273],
            [-90.2121, 43.9579],
            [-90.1493, 43.9958],
            [-90.1224, 43.9669],
            [-90.0549, 43.9734],
            [-90.0191, 43.9056],
            [-89.9524, 43.9201],
            [-89.9615, 43.9663],
            [-89.8848, 44.0249],
            [-89.8332, 44.0465],
            [-89.833, 44.0828],
            [-89.8078, 44.083],
            [-89.798, 44.1119],
            [-89.7781, 44.112],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Milwaukee Mkt": {
    type: "Feature",
    properties: { code: "WI_MIL", name: "Milwaukee Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-88.6442, 43.7201],
          [-88.6236, 43.7199],
          [-88.6125, 43.7198],
          [-88.6138, 43.7344],
          [-88.5634, 43.7334],
          [-88.5134, 43.7359],
          [-88.5164, 43.7231],
          [-88.5485, 43.7119],
          [-88.5578, 43.6978],
          [-88.5228, 43.6975],
          [-88.5224, 43.7084],
          [-88.493, 43.7085],
          [-88.4793, 43.6755],
          [-88.4514, 43.6752],
          [-88.4413, 43.6314],
          [-88.4133, 43.6311],
          [-88.4011, 43.6382],
          [-88.4013, 43.6601],
          [-88.4019, 43.7169],
          [-88.4001, 43.7169],
          [-88.3766, 43.704],
          [-88.3635, 43.7039],
          [-88.3744, 43.7173],
          [-88.326, 43.7198],
          [-88.3209, 43.7325],
          [-88.2912, 43.7323],
          [-88.2873, 43.7395],
          [-88.267, 43.7323],
          [-88.2659, 43.754],
          [-88.2512, 43.7823],
          [-88.2812, 43.7751],
          [-88.2813, 43.7823],
          [-88.3216, 43.79],
          [-88.3216, 43.8189],
          [-88.3616, 43.8148],
          [-88.3617, 43.8264],
          [-88.3421, 43.834],
          [-88.3429, 43.863],
          [-88.2941, 43.8666],
          [-88.2927, 43.8812],
          [-88.3503, 43.8738],
          [-88.4035, 43.8779],
          [-88.4043, 44.071],
          [-88.3214, 44.0717],
          [-88.2992, 44.0788],
          [-88.2979, 44.0718],
          [-88.251, 44.0734],
          [-88.2292, 44.0951],
          [-88.2066, 44.0878],
          [-88.1896, 44.0966],
          [-88.1837, 44.0877],
          [-88.1636, 44.0875],
          [-88.1596, 44.1094],
          [-88.1039, 44.0952],
          [-88.0934, 44.0736],
          [-88.0928, 44.0808],
          [-88.0465, 44.0811],
          [-88.0431, 44.0955],
          [-87.9927, 44.0909],
          [-87.9766, 44.0719],
          [-87.9932, 44.0663],
          [-87.9997, 44.0485],
          [-87.983, 44.0081],
          [-87.9225, 44.0052],
          [-87.902, 43.9644],
          [-87.8619, 43.9642],
          [-87.862, 43.979],
          [-87.7893, 44.0227],
          [-87.7815, 44.0009],
          [-87.6872, 44.0106],
          [-87.7362, 43.8804],
          [-87.7262, 43.8101],
          [-87.7003, 43.7674],
          [-87.7061, 43.6797],
          [-87.7901, 43.5631],
          [-87.7932, 43.4928],
          [-87.865, 43.3936],
          [-87.9118, 43.2504],
          [-87.8811, 43.1706],
          [-87.9003, 43.1373],
          [-87.8665, 43.0744],
          [-87.8951, 43.0423],
          [-87.8877, 43.0005],
          [-87.8427, 42.9441],
          [-87.8478, 42.8892],
          [-87.8233, 42.8353],
          [-87.7667, 42.7849],
          [-87.7824, 42.7082],
          [-87.8147, 42.644],
          [-87.8123, 42.5298],
          [-87.8005, 42.4919],
          [-88.1153, 42.4962],
          [-88.6683, 42.4944],
          [-88.6784, 42.5793],
          [-88.735, 42.5764],
          [-88.7909, 42.5913],
          [-88.8069, 42.6998],
          [-88.856, 42.7107],
          [-88.8555, 42.8446],
          [-88.7772, 42.8614],
          [-88.7779, 42.9125],
          [-88.669, 42.8953],
          [-88.6359, 42.9151],
          [-88.639, 42.9685],
          [-88.7098, 42.9748],
          [-88.7388, 43.0357],
          [-88.8085, 43.0321],
          [-88.7942, 43.0717],
          [-88.846, 43.0725],
          [-88.9, 43.1611],
          [-88.854, 43.2419],
          [-88.7734, 43.3263],
          [-88.802, 43.358],
          [-88.7805, 43.4335],
          [-88.7611, 43.4589],
          [-88.644, 43.4587],
          [-88.6445, 43.6052],
          [-88.6747, 43.6332],
          [-88.6444, 43.6553],
          [-88.6442, 43.7201],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Charleston Mkt": {
    type: "Feature",
    properties: { code: "WV_CHA", name: "Charleston Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-82.9407, 37.7163],
          [-82.9008, 37.7032],
          [-82.8506, 37.7288],
          [-82.7784, 37.7225],
          [-82.7235, 37.7492],
          [-82.6433, 37.7225],
          [-82.5755, 37.6879],
          [-82.3966, 37.6849],
          [-82.4085, 37.7175],
          [-82.3176, 37.7649],
          [-82.3774, 37.803],
          [-82.4089, 37.8366],
          [-82.4215, 37.8857],
          [-82.5019, 37.9332],
          [-82.4633, 37.9738],
          [-82.4044, 37.9449],
          [-82.4045, 37.9839],
          [-82.3459, 37.988],
          [-82.2548, 37.9575],
          [-82.1907, 37.9748],
          [-82.1573, 37.9063],
          [-82.0534, 37.8808],
          [-82.0282, 37.9114],
          [-81.9837, 37.882],
          [-81.9268, 37.9017],
          [-81.938, 37.9624],
          [-81.8526, 37.963],
          [-81.9162, 38.032],
          [-81.9712, 38.0482],
          [-81.9467, 38.0969],
          [-81.8913, 38.1131],
          [-81.7901, 38.0973],
          [-81.755, 38.1153],
          [-81.7938, 38.1426],
          [-81.8209, 38.1867],
          [-81.838, 38.2512],
          [-81.8672, 38.2619],
          [-81.8603, 38.3033],
          [-81.9152, 38.3256],
          [-81.9002, 38.3986],
          [-81.8508, 38.4219],
          [-81.8612, 38.4792],
          [-81.9035, 38.5042],
          [-81.9334, 38.4755],
          [-82.0313, 38.5241],
          [-82.0911, 38.5346],
          [-82.0719, 38.5648],
          [-82.0681, 38.6291],
          [-82.0436, 38.6763],
          [-82.0968, 38.7245],
          [-82.0632, 38.7742],
          [-82.1171, 38.7754],
          [-82.1167, 38.8206],
          [-82.0302, 38.7982],
          [-82.0163, 38.8346],
          [-82.0159, 38.8967],
          [-82.1091, 38.9456],
          [-82.0955, 39.0028],
          [-82.2766, 39.0135],
          [-82.3212, 39.0454],
          [-82.3104, 39.1633],
          [-82.3186, 39.2066],
          [-82.3058, 39.253],
          [-82.3462, 39.2605],
          [-82.2977, 39.3042],
          [-82.2894, 39.3842],
          [-82.334, 39.4066],
          [-82.3473, 39.4461],
          [-82.336, 39.4719],
          [-82.2802, 39.4726],
          [-82.2755, 39.5404],
          [-82.2401, 39.5608],
          [-82.1592, 39.5563],
          [-82.0701, 39.5984],
          [-81.9897, 39.5645],
          [-81.9592, 39.484],
          [-81.9212, 39.4483],
          [-81.8017, 39.4483],
          [-81.7159, 39.4607],
          [-81.7051, 39.531],
          [-81.7235, 39.5476],
          [-81.6973, 39.5866],
          [-81.7209, 39.652],
          [-81.6785, 39.6691],
          [-81.6612, 39.6414],
          [-81.5508, 39.6453],
          [-81.4967, 39.6575],
          [-81.4658, 39.6889],
          [-81.3756, 39.6618],
          [-81.3357, 39.7127],
          [-81.2809, 39.7071],
          [-81.2584, 39.641],
          [-81.2307, 39.6608],
          [-81.2408, 39.7147],
          [-81.1971, 39.7044],
          [-81.1757, 39.6743],
          [-81.1349, 39.6675],
          [-81.0649, 39.6251],
          [-80.994, 39.6084],
          [-80.8804, 39.6207],
          [-80.8582, 39.6955],
          [-80.814, 39.6982],
          [-80.7445, 39.6674],
          [-80.6981, 39.6813],
          [-80.7104, 39.7213],
          [-80.5876, 39.7218],
          [-80.5778, 39.6237],
          [-80.5923, 39.581],
          [-80.5375, 39.5546],
          [-80.4982, 39.5633],
          [-80.4735, 39.4927],
          [-80.4352, 39.4796],
          [-80.3651, 39.434],
          [-80.3128, 39.4376],
          [-80.2584, 39.4241],
          [-80.2425, 39.391],
          [-80.1686, 39.3812],
          [-80.1054, 39.4088],
          [-80.0871, 39.3864],
          [-79.9136, 39.4633],
          [-79.8671, 39.5235],
          [-79.7918, 39.4519],
          [-79.7186, 39.4498],
          [-79.6798, 39.4023],
          [-79.6137, 39.3816],
          [-79.6552, 39.3333],
          [-79.6088, 39.3232],
          [-79.6341, 39.2922],
          [-79.603, 39.2584],
          [-79.5076, 39.2162],
          [-79.4738, 39.1817],
          [-79.3929, 39.1929],
          [-79.3332, 39.2148],
          [-79.2929, 39.1918],
          [-79.3318, 39.1478],
          [-79.3555, 39.0865],
          [-79.3147, 39.0703],
          [-79.3102, 39.0303],
          [-79.3749, 38.9208],
          [-79.457, 38.9246],
          [-79.4829, 38.8863],
          [-79.5382, 38.8434],
          [-79.5102, 38.7806],
          [-79.6342, 38.636],
          [-79.6491, 38.5915],
          [-79.673, 38.5287],
          [-79.6621, 38.5155],
          [-79.6705, 38.5077],
          [-79.6804, 38.5106],
          [-79.6976, 38.4872],
          [-79.6897, 38.4314],
          [-79.7317, 38.3734],
          [-79.7256, 38.3638],
          [-79.7406, 38.3541],
          [-79.7644, 38.3565],
          [-79.7731, 38.3355],
          [-79.8102, 38.3067],
          [-79.7889, 38.2687],
          [-79.812, 38.2604],
          [-79.8196, 38.2482],
          [-79.8452, 38.2411],
          [-79.8984, 38.193],
          [-79.9163, 38.1863],
          [-79.9187, 38.1548],
          [-79.9448, 38.1316],
          [-79.939, 38.1116],
          [-79.9263, 38.1072],
          [-79.9544, 38.0804],
          [-79.9994, 37.9958],
          [-80.1029, 37.9189],
          [-80.1177, 37.8977],
          [-80.1622, 37.8751],
          [-80.1831, 37.8506],
          [-80.1794, 37.8398],
          [-80.2271, 37.7989],
          [-80.2157, 37.7775],
          [-80.2305, 37.7783],
          [-80.2574, 37.7561],
          [-80.2628, 37.7383],
          [-80.2531, 37.7259],
          [-80.2961, 37.6918],
          [-80.2675, 37.6461],
          [-80.221, 37.6278],
          [-80.2403, 37.607],
          [-80.2589, 37.5955],
          [-80.3285, 37.5643],
          [-80.3124, 37.5462],
          [-80.3303, 37.5362],
          [-80.2998, 37.5083],
          [-80.3668, 37.4849],
          [-80.372, 37.4741],
          [-80.4758, 37.4231],
          [-80.4949, 37.4351],
          [-80.493, 37.4577],
          [-80.5114, 37.4817],
          [-80.552, 37.4736],
          [-80.6459, 37.4221],
          [-80.7701, 37.3724],
          [-80.7842, 37.3946],
          [-80.8061, 37.3981],
          [-80.8377, 37.4257],
          [-80.8596, 37.4296],
          [-80.8832, 37.3839],
          [-80.8495, 37.3469],
          [-80.9005, 37.315],
          [-80.9666, 37.2922],
          [-80.9813, 37.2935],
          [-80.9796, 37.3023],
          [-81.1126, 37.2785],
          [-81.167, 37.2629],
          [-81.2434, 37.2242],
          [-81.2848, 37.2159],
          [-81.2754, 37.1968],
          [-81.3054, 37.1868],
          [-81.2975, 37.1768],
          [-81.3417, 37.1562],
          [-81.3282, 37.1509],
          [-81.3281, 37.139],
          [-81.264, 37.1243],
          [-81.2705, 37.0926],
          [-81.2893, 37.0833],
          [-81.377, 37.0536],
          [-81.3992, 37.0578],
          [-81.4575, 37.0392],
          [-81.4572, 37.0307],
          [-81.4819, 37.035],
          [-81.4904, 37.046],
          [-81.5597, 37.0329],
          [-81.6059, 37.01],
          [-81.6315, 37.0091],
          [-81.6778, 36.9837],
          [-81.8059, 36.9523],
          [-81.8847, 36.8896],
          [-81.9469, 36.8648],
          [-81.9762, 36.8698],
          [-81.9684, 36.8972],
          [-81.9813, 36.8994],
          [-81.9786, 36.9061],
          [-81.9285, 36.9469],
          [-81.9005, 36.9572],
          [-81.9108, 36.9703],
          [-81.9317, 36.9601],
          [-81.9412, 36.9707],
          [-81.9308, 36.9997],
          [-81.8961, 37.0083],
          [-81.8889, 37.0265],
          [-81.8748, 37.0192],
          [-81.8565, 37.0327],
          [-81.8159, 37.038],
          [-81.837, 37.0623],
          [-81.857, 37.0546],
          [-81.8626, 37.0637],
          [-81.8581, 37.0516],
          [-81.8683, 37.0549],
          [-81.8669, 37.0481],
          [-81.8867, 37.0482],
          [-81.9142, 37.0366],
          [-81.9058, 37.0371],
          [-81.9039, 37.0121],
          [-81.907, 37.0075],
          [-81.9121, 37.0137],
          [-81.9109, 37.0057],
          [-81.9265, 37.002],
          [-81.9228, 37.0183],
          [-81.9316, 37.0219],
          [-81.9281, 37.0327],
          [-81.9406, 37.0357],
          [-81.9389, 37.0503],
          [-81.9617, 37.0439],
          [-81.9522, 37.0549],
          [-81.978, 37.0944],
          [-81.9697, 37.0981],
          [-81.9726, 37.1056],
          [-82.0001, 37.0987],
          [-81.9975, 37.1148],
          [-82.0074, 37.12],
          [-82.0162, 37.1028],
          [-82.0359, 37.1173],
          [-82.0576, 37.1216],
          [-82.0733, 37.1076],
          [-82.1276, 37.1099],
          [-82.136, 37.1009],
          [-82.1706, 37.1147],
          [-82.1775, 37.1365],
          [-82.1913, 37.1559],
          [-82.2026, 37.1561],
          [-82.2221, 37.1893],
          [-82.226, 37.2],
          [-82.2134, 37.1946],
          [-82.2077, 37.1995],
          [-82.2121, 37.2106],
          [-82.2362, 37.2124],
          [-82.2481, 37.2616],
          [-82.2778, 37.2483],
          [-82.2761, 37.261],
          [-82.2893, 37.2706],
          [-82.2894, 37.2864],
          [-82.2997, 37.2956],
          [-82.2876, 37.3005],
          [-82.2949, 37.3061],
          [-82.3246, 37.2832],
          [-82.3418, 37.2809],
          [-82.3553, 37.2652],
          [-82.4492, 37.2439],
          [-82.5504, 37.2045],
          [-82.6335, 37.1543],
          [-82.6422, 37.1531],
          [-82.6437, 37.2218],
          [-82.6717, 37.2272],
          [-82.7528, 37.3107],
          [-82.7626, 37.425],
          [-82.8664, 37.4568],
          [-82.9145, 37.42],
          [-82.9968, 37.4277],
          [-83.0373, 37.4105],
          [-83.0379, 37.4755],
          [-82.9939, 37.4647],
          [-82.9505, 37.5108],
          [-82.9976, 37.5284],
          [-83.0181, 37.5679],
          [-82.9838, 37.6102],
          [-82.9302, 37.5732],
          [-82.8969, 37.5813],
          [-82.9089, 37.6492],
          [-82.945, 37.6809],
          [-82.9407, 37.7163],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Huntington Mkt": {
    type: "Feature",
    properties: { code: "WV_HUN", name: "Huntington Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-83.3531, 39.4027],
          [-83.3258, 39.4391],
          [-83.2857, 39.4579],
          [-83.234, 39.4491],
          [-83.1104, 39.4702],
          [-83.0769, 39.4551],
          [-83.0371, 39.5078],
          [-82.9165, 39.4766],
          [-82.8725, 39.5146],
          [-82.8216, 39.5131],
          [-82.7472, 39.3856],
          [-82.7517, 39.3337],
          [-82.7249, 39.3123],
          [-82.691, 39.3308],
          [-82.6416, 39.331],
          [-82.6196, 39.3588],
          [-82.5531, 39.3651],
          [-82.5508, 39.3953],
          [-82.4461, 39.3924],
          [-82.4022, 39.4433],
          [-82.3473, 39.4461],
          [-82.334, 39.4066],
          [-82.2894, 39.3842],
          [-82.2977, 39.3042],
          [-82.3462, 39.2605],
          [-82.3058, 39.253],
          [-82.3186, 39.2066],
          [-82.3104, 39.1633],
          [-82.3212, 39.0454],
          [-82.2766, 39.0135],
          [-82.0955, 39.0028],
          [-82.1091, 38.9456],
          [-82.0159, 38.8967],
          [-82.0163, 38.8346],
          [-82.0302, 38.7982],
          [-82.1167, 38.8206],
          [-82.1171, 38.7754],
          [-82.0632, 38.7742],
          [-82.0968, 38.7245],
          [-82.0436, 38.6763],
          [-82.0681, 38.6291],
          [-82.0719, 38.5648],
          [-82.0911, 38.5346],
          [-82.0313, 38.5241],
          [-81.9334, 38.4755],
          [-81.9035, 38.5042],
          [-81.8612, 38.4792],
          [-81.8508, 38.4219],
          [-81.9002, 38.3986],
          [-81.9152, 38.3256],
          [-81.8603, 38.3033],
          [-81.8672, 38.2619],
          [-81.838, 38.2512],
          [-81.8209, 38.1867],
          [-81.7938, 38.1426],
          [-81.755, 38.1153],
          [-81.7901, 38.0973],
          [-81.8913, 38.1131],
          [-81.9467, 38.0969],
          [-81.9712, 38.0482],
          [-81.9162, 38.032],
          [-81.8526, 37.963],
          [-81.938, 37.9624],
          [-81.9268, 37.9017],
          [-81.9837, 37.882],
          [-82.0282, 37.9114],
          [-82.0534, 37.8808],
          [-82.1573, 37.9063],
          [-82.1907, 37.9748],
          [-82.2548, 37.9575],
          [-82.3459, 37.988],
          [-82.4045, 37.9839],
          [-82.4044, 37.9449],
          [-82.4633, 37.9738],
          [-82.5019, 37.9332],
          [-82.4215, 37.8857],
          [-82.4089, 37.8366],
          [-82.3774, 37.803],
          [-82.3176, 37.7649],
          [-82.4085, 37.7175],
          [-82.3966, 37.6849],
          [-82.5755, 37.6879],
          [-82.6433, 37.7225],
          [-82.7235, 37.7492],
          [-82.7784, 37.7225],
          [-82.8506, 37.7288],
          [-82.9008, 37.7032],
          [-82.9407, 37.7163],
          [-82.9507, 37.7947],
          [-82.9895, 37.8182],
          [-82.9879, 37.8709],
          [-82.9615, 37.9127],
          [-82.9934, 37.9615],
          [-83.0651, 38.0079],
          [-83.1224, 38.0024],
          [-83.2131, 38.0273],
          [-83.2342, 38.0646],
          [-83.2087, 38.1202],
          [-83.1732, 38.1562],
          [-83.2995, 38.2091],
          [-83.3069, 38.2612],
          [-83.3315, 38.3031],
          [-83.4171, 38.396],
          [-83.5235, 38.4088],
          [-83.574, 38.4435],
          [-83.5977, 38.5001],
          [-83.642, 38.5254],
          [-83.6245, 38.6114],
          [-83.6466, 38.6362],
          [-83.615, 38.6843],
          [-83.521, 38.703],
          [-83.4681, 38.6755],
          [-83.455, 38.7249],
          [-83.5242, 38.7566],
          [-83.5601, 38.7432],
          [-83.6422, 38.7437],
          [-83.6966, 38.7573],
          [-83.69, 38.8279],
          [-83.7437, 38.8525],
          [-83.7767, 38.8839],
          [-83.7496, 38.954],
          [-83.7282, 38.9657],
          [-83.7022, 39.0213],
          [-83.7035, 39.0624],
          [-83.6618, 39.0589],
          [-83.623, 39.0311],
          [-83.5697, 39.0264],
          [-83.4434, 39.0465],
          [-83.4259, 39.073],
          [-83.3823, 39.0692],
          [-83.3622, 39.1186],
          [-83.3561, 39.1863],
          [-83.4212, 39.2189],
          [-83.3806, 39.2444],
          [-83.3867, 39.3227],
          [-83.3531, 39.4027],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Green River Mkt": {
    type: "Feature",
    properties: { code: "WY_GRE", name: "Green River Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-111.0467, 42.0016],
          [-111.0468, 42.5049],
          [-111.0438, 42.7867],
          [-111.0706, 42.785],
          [-111.1065, 42.834],
          [-111.125, 42.8424],
          [-111.1395, 42.846],
          [-111.1803, 42.8341],
          [-111.2202, 42.8588],
          [-111.2225, 42.8786],
          [-111.2449, 42.8986],
          [-111.2572, 42.9277],
          [-111.2869, 42.9355],
          [-111.2774, 42.9653],
          [-111.2564, 42.9705],
          [-111.2878, 42.9878],
          [-111.2615, 43.0071],
          [-111.2525, 43.0206],
          [-111.0442, 43.0201],
          [-111.0442, 43.0232],
          [-111.0441, 43.0233],
          [-111.044, 43.0246],
          [-111.0441, 43.0293],
          [-111.044, 43.0414],
          [-111.0442, 43.0662],
          [-111.0442, 43.1956],
          [-111.0446, 43.3157],
          [-110.9279, 43.316],
          [-110.9282, 43.2995],
          [-110.814, 43.2995],
          [-110.814, 43.2352],
          [-110.5787, 43.2352],
          [-110.5784, 43.2916],
          [-110.344, 43.2912],
          [-110.3442, 43.3779],
          [-110.0573, 43.3778],
          [-110.0516, 43.4648],
          [-110.0532, 44.008],
          [-109.9939, 43.9496],
          [-109.9589, 43.9646],
          [-109.9221, 43.9461],
          [-109.8584, 43.942],
          [-109.8166, 43.8805],
          [-109.8542, 43.8259],
          [-109.7892, 43.8027],
          [-109.6725, 43.8681],
          [-109.6418, 43.9228],
          [-109.5423, 43.9614],
          [-109.5106, 43.9386],
          [-109.4424, 43.9304],
          [-109.3802, 43.8828],
          [-109.3678, 43.8447],
          [-109.336, 43.8421],
          [-109.3095, 43.8055],
          [-109.275, 43.7972],
          [-109.197, 43.7124],
          [-109.1714, 43.6993],
          [-109.1716, 43.6198],
          [-108.9341, 43.6192],
          [-108.9338, 43.6047],
          [-108.8143, 43.6053],
          [-108.8143, 43.5756],
          [-108.6947, 43.5758],
          [-108.6951, 43.5323],
          [-108.5762, 43.5326],
          [-108.5762, 43.5033],
          [-108.457, 43.5032],
          [-108.457, 43.4743],
          [-108.3378, 43.4741],
          [-108.3282, 43.4597],
          [-108.3571, 43.4367],
          [-108.3145, 43.4008],
          [-108.1884, 43.4162],
          [-108.1887, 43.3895],
          [-108.2534, 43.3461],
          [-108.1832, 43.338],
          [-108.2135, 43.3014],
          [-108.1925, 43.2379],
          [-108.2411, 43.2073],
          [-108.3647, 43.196],
          [-108.3883, 43.1541],
          [-108.2964, 43.1531],
          [-108.1807, 43.119],
          [-108.1449, 43.1241],
          [-108.1451, 43.0715],
          [-108.0712, 43.1151],
          [-108.0316, 43.1056],
          [-107.9828, 43.0402],
          [-107.8849, 43.0577],
          [-107.8083, 43.0108],
          [-107.6234, 42.9653],
          [-107.5021, 43.0246],
          [-107.5014, 42.7815],
          [-107.5435, 42.7816],
          [-107.542, 42.4344],
          [-107.2875, 42.4348],
          [-106.625, 42.4305],
          [-106.3222, 42.4332],
          [-105.6987, 42.4311],
          [-105.7114, 42.278],
          [-105.7691, 42.0422],
          [-105.8085, 42.0421],
          [-105.8636, 42.017],
          [-106.0732, 41.9831],
          [-106.0729, 41.7717],
          [-106.1438, 41.7478],
          [-106.1621, 41.7235],
          [-106.2176, 41.7407],
          [-106.243, 41.713],
          [-106.2441, 41.6702],
          [-106.2743, 41.6285],
          [-106.2328, 41.6172],
          [-106.2848, 41.576],
          [-106.3036, 41.535],
          [-106.3467, 41.5157],
          [-106.3617, 41.4649],
          [-106.3229, 41.3939],
          [-106.3212, 40.9991],
          [-106.4396, 41.002],
          [-107.0304, 41.0034],
          [-107.6256, 41.0021],
          [-108.2506, 41.0001],
          [-108.8841, 41.0001],
          [-109.0501, 41.0007],
          [-109.5349, 40.9981],
          [-110.1216, 40.9971],
          [-110.5007, 40.9947],
          [-111.0467, 40.998],
          [-111.0459, 41.6357],
          [-111.0467, 42.0016],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Birmingham Mkt": {
    type: "Feature",
    properties: { code: "AL_BIR", name: "Birmingham Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-88.2717, 33.5573],
          [-88.2347, 33.5158],
          [-88.2331, 33.4874],
          [-88.1418, 33.5281],
          [-88.1503, 33.4759],
          [-88.1436, 33.4092],
          [-88.0968, 33.3978],
          [-88.0654, 33.4382],
          [-88.079, 33.4769],
          [-87.9684, 33.493],
          [-87.9503, 33.459],
          [-87.9004, 33.4733],
          [-87.8386, 33.4319],
          [-87.7614, 33.4486],
          [-87.7618, 33.4957],
          [-87.6945, 33.4993],
          [-87.6781, 33.459],
          [-87.6274, 33.4662],
          [-87.653, 33.5],
          [-87.6519, 33.5302],
          [-87.5925, 33.5462],
          [-87.5538, 33.494],
          [-87.4948, 33.4884],
          [-87.4591, 33.5347],
          [-87.4155, 33.55],
          [-87.3734, 33.5107],
          [-87.2472, 33.5196],
          [-87.1872, 33.5517],
          [-87.2363, 33.6105],
          [-87.232, 33.6313],
          [-87.1201, 33.663],
          [-87.1469, 33.6985],
          [-87.1342, 33.7358],
          [-87.0986, 33.7376],
          [-87.0709, 33.8248],
          [-87.0996, 33.8815],
          [-87.0819, 33.9075],
          [-87.1095, 33.9563],
          [-87.1341, 33.9651],
          [-87.112, 34.0167],
          [-87.1411, 34.0433],
          [-87.1367, 34.1008],
          [-87.1023, 34.1392],
          [-87.1113, 34.1567],
          [-87.1101, 34.3134],
          [-87.0087, 34.3117],
          [-86.9777, 34.3376],
          [-86.9379, 34.3158],
          [-86.929, 34.2761],
          [-86.8464, 34.2915],
          [-86.7529, 34.3259],
          [-86.717, 34.3065],
          [-86.6744, 34.3265],
          [-86.654, 34.3701],
          [-86.6051, 34.3711],
          [-86.6155, 34.4109],
          [-86.5857, 34.4722],
          [-86.5281, 34.4983],
          [-86.465, 34.4712],
          [-86.4663, 34.4705],
          [-86.4437, 34.4585],
          [-86.4374, 34.4509],
          [-86.4335, 34.4472],
          [-86.4278, 34.4434],
          [-86.4212, 34.437],
          [-86.4148, 34.4264],
          [-86.4311, 34.409],
          [-86.424, 34.3906],
          [-86.4364, 34.3737],
          [-86.4235, 34.3682],
          [-86.4029, 34.3414],
          [-86.4157, 34.328],
          [-86.4109, 34.3232],
          [-86.4312, 34.3206],
          [-86.4258, 34.3101],
          [-86.4421, 34.3044],
          [-86.4529, 34.2593],
          [-86.4897, 34.2401],
          [-86.4751, 34.2338],
          [-86.4936, 34.221],
          [-86.4931, 34.203],
          [-86.4405, 34.2137],
          [-86.4369, 34.2395],
          [-86.4148, 34.2122],
          [-86.4594, 34.1746],
          [-86.4875, 34.1289],
          [-86.472, 34.1018],
          [-86.4552, 34.1003],
          [-86.4504, 34.086],
          [-86.4284, 34.0836],
          [-86.4333, 34.0599],
          [-86.374, 34.0551],
          [-86.3585, 34.0615],
          [-86.3587, 34.0093],
          [-86.4006, 33.9671],
          [-86.3906, 33.9654],
          [-86.3702, 33.9398],
          [-86.3256, 33.9401],
          [-86.3238, 33.9344],
          [-86.3465, 33.9147],
          [-86.3412, 33.8916],
          [-86.3234, 33.8861],
          [-86.3388, 33.8786],
          [-86.3251, 33.874],
          [-86.3236, 33.8642],
          [-86.3595, 33.8342],
          [-86.3333, 33.8237],
          [-86.3403, 33.8262],
          [-86.3716, 33.8006],
          [-86.3518, 33.7879],
          [-86.338, 33.7939],
          [-86.3762, 33.7624],
          [-86.3408, 33.7635],
          [-86.3274, 33.7546],
          [-86.3806, 33.6977],
          [-86.3655, 33.6844],
          [-86.3425, 33.7124],
          [-86.3553, 33.693],
          [-86.3305, 33.6837],
          [-86.3365, 33.6721],
          [-86.3302, 33.6674],
          [-86.324, 33.677],
          [-86.2959, 33.6851],
          [-86.3083, 33.6921],
          [-86.3069, 33.6999],
          [-86.2892, 33.7217],
          [-86.2746, 33.761],
          [-86.1704, 33.7977],
          [-86.1491, 33.7952],
          [-86.1499, 33.8025],
          [-86.1154, 33.8117],
          [-86.1165, 33.8046],
          [-86.0613, 33.8141],
          [-86.0514, 33.8252],
          [-86.0653, 33.8422],
          [-86.0467, 33.8526],
          [-86.0213, 33.8515],
          [-85.995, 33.8796],
          [-85.9868, 33.8721],
          [-85.9762, 33.8813],
          [-85.9446, 33.8768],
          [-85.9172, 33.8566],
          [-85.9183, 33.898],
          [-85.9112, 33.9064],
          [-85.8997, 33.943],
          [-85.8537, 33.9501],
          [-85.858, 33.9615],
          [-85.8371, 33.9662],
          [-85.8279, 33.957],
          [-85.8063, 33.9602],
          [-85.7937, 33.9469],
          [-85.766, 33.9467],
          [-85.7975, 33.9542],
          [-85.7895, 33.9718],
          [-85.8065, 33.9978],
          [-85.7714, 34.0106],
          [-85.8096, 34.0484],
          [-85.8055, 34.0606],
          [-85.7764, 34.0486],
          [-85.7297, 34.0465],
          [-85.7112, 34.06],
          [-85.7265, 34.0592],
          [-85.7325, 34.0482],
          [-85.7568, 34.0716],
          [-85.7451, 34.0691],
          [-85.7366, 34.076],
          [-85.7126, 34.0701],
          [-85.7131, 34.0812],
          [-85.6987, 34.1019],
          [-85.6839, 34.1033],
          [-85.6691, 34.1027],
          [-85.6366, 34.0587],
          [-85.6302, 34.0708],
          [-85.6067, 34.0633],
          [-85.5736, 34.0712],
          [-85.565, 34.0654],
          [-85.5551, 34.0689],
          [-85.5404, 34.0531],
          [-85.4936, 34.0732],
          [-85.4876, 34.0631],
          [-85.503, 34.06],
          [-85.501, 34.0543],
          [-85.4911, 34.0578],
          [-85.4947, 34.0306],
          [-85.4569, 34.0495],
          [-85.4403, 34.043],
          [-85.4244, 34.0473],
          [-85.4236, 34.0635],
          [-85.4193, 34.0672],
          [-85.3044, 33.4829],
          [-85.2324, 33.1081],
          [-85.2857, 33.1082],
          [-85.3618, 33.0687],
          [-85.4884, 33.0622],
          [-85.5302, 33.05],
          [-85.5374, 33.0202],
          [-85.5932, 33.0012],
          [-85.6466, 32.9653],
          [-85.7021, 32.9823],
          [-85.7652, 32.9747],
          [-85.8144, 32.9534],
          [-85.8612, 32.9617],
          [-85.9013, 32.8596],
          [-85.879, 32.8263],
          [-85.8976, 32.7823],
          [-85.8799, 32.7545],
          [-85.9077, 32.6987],
          [-85.9647, 32.7425],
          [-86.0071, 32.755],
          [-86.0211, 32.827],
          [-86.0888, 32.8287],
          [-86.2514, 32.7836],
          [-86.2811, 32.7534],
          [-86.3367, 32.7681],
          [-86.3715, 32.7536],
          [-86.424, 32.7918],
          [-86.4263, 32.8311],
          [-86.5169, 32.8248],
          [-86.5642, 32.805],
          [-86.5532, 32.7637],
          [-86.6083, 32.7388],
          [-86.7617, 32.7121],
          [-86.761, 32.7775],
          [-86.7922, 32.8454],
          [-86.803, 32.894],
          [-86.8483, 32.8927],
          [-86.864, 32.9301],
          [-86.8354, 32.9635],
          [-86.8588, 33.0007],
          [-86.891, 33.0071],
          [-86.9251, 32.9614],
          [-86.9587, 32.9725],
          [-87.0248, 32.9125],
          [-87.0379, 32.8546],
          [-87.0847, 32.8217],
          [-87.1039, 32.7732],
          [-87.1728, 32.8004],
          [-87.1875, 32.8354],
          [-87.3157, 32.8237],
          [-87.3536, 32.8684],
          [-87.5679, 32.8559],
          [-87.6322, 32.8106],
          [-87.6658, 32.8057],
          [-87.7538, 32.8199],
          [-87.7696, 32.7774],
          [-87.8082, 32.7531],
          [-87.8404, 32.7053],
          [-87.8896, 32.6799],
          [-87.8806, 32.6433],
          [-87.8984, 32.5923],
          [-87.8738, 32.5443],
          [-87.9451, 32.4913],
          [-87.9541, 32.473],
          [-88.0365, 32.4299],
          [-88.0988, 32.4543],
          [-88.1551, 32.4532],
          [-88.1743, 32.4745],
          [-88.1526, 32.551],
          [-88.1855, 32.5703],
          [-88.2496, 32.5552],
          [-88.3534, 32.5539],
          [-88.3541, 32.6105],
          [-88.3854, 32.6072],
          [-88.3309, 33.0731],
          [-88.2717, 33.5573],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Decatur Mkt": {
    type: "Feature",
    properties: { code: "AL_DEC", name: "Decatur Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-89.3006, 34.1614],
          [-89.2455, 34.1612],
          [-89.2627, 34.2111],
          [-89.2464, 34.351],
          [-89.2993, 34.3816],
          [-89.2342, 34.411],
          [-89.2019, 34.3797],
          [-89.0873, 34.3801],
          [-89.0587, 34.3885],
          [-88.9775, 34.3797],
          [-88.9291, 34.4173],
          [-88.9404, 34.457],
          [-88.9238, 34.4854],
          [-88.8592, 34.487],
          [-88.8579, 34.534],
          [-88.774, 34.578],
          [-88.7523, 34.611],
          [-88.7466, 34.6732],
          [-88.7187, 34.6779],
          [-88.7185, 34.7568],
          [-88.7531, 34.757],
          [-88.753, 34.8588],
          [-88.7333, 34.8937],
          [-88.7343, 34.9583],
          [-88.7536, 34.9953],
          [-88.2001, 34.9956],
          [-88.203, 35.008],
          [-87.9849, 35.0059],
          [-87.9836, 35.1802],
          [-88.0891, 35.256],
          [-88.118, 35.3073],
          [-88.0837, 35.3551],
          [-88.0869, 35.4045],
          [-88.0662, 35.4167],
          [-88.0187, 35.3883],
          [-88.0077, 35.4234],
          [-88.0421, 35.4505],
          [-87.9619, 35.4926],
          [-87.8989, 35.4642],
          [-87.8644, 35.4758],
          [-87.7338, 35.4917],
          [-87.6907, 35.5053],
          [-87.6858, 35.5481],
          [-87.6517, 35.565],
          [-87.6582, 35.6093],
          [-87.6181, 35.6194],
          [-87.5869, 35.6533],
          [-87.5493, 35.6301],
          [-87.4596, 35.6151],
          [-87.39, 35.6413],
          [-87.4361, 35.6994],
          [-87.4099, 35.7536],
          [-87.3356, 35.7001],
          [-87.329, 35.7544],
          [-87.3902, 35.7694],
          [-87.3871, 35.7936],
          [-87.341, 35.8189],
          [-87.3136, 35.7995],
          [-87.2793, 35.8573],
          [-87.212, 35.869],
          [-87.181, 35.9201],
          [-87.1479, 35.8984],
          [-87.1638, 35.8384],
          [-87.0041, 35.7807],
          [-86.9801, 35.7204],
          [-86.9357, 35.6881],
          [-86.9384, 35.651],
          [-86.8967, 35.644],
          [-86.8307, 35.6918],
          [-86.817, 35.6583],
          [-86.8364, 35.6031],
          [-86.8381, 35.5509],
          [-86.8713, 35.5025],
          [-86.8839, 35.4499],
          [-86.9259, 35.4014],
          [-86.901, 35.2787],
          [-86.8283, 35.27],
          [-86.8287, 35.2388],
          [-86.7792, 35.2373],
          [-86.7527, 35.1979],
          [-86.7521, 35.1922],
          [-86.7904, 35.1981],
          [-86.7736, 35.186],
          [-86.7788, 35.159],
          [-86.7822, 35.1158],
          [-86.7742, 35.0918],
          [-86.7684, 35.0975],
          [-86.7785, 35.1057],
          [-86.7707, 35.1128],
          [-86.7633, 35.0993],
          [-86.7248, 35.083],
          [-86.6887, 35.1052],
          [-86.6967, 35.1196],
          [-86.6863, 35.1239],
          [-86.6552, 35.0899],
          [-86.6359, 35.0881],
          [-86.6571, 35.0613],
          [-86.6317, 35.0698],
          [-86.6271, 35.0575],
          [-86.6085, 35.0612],
          [-86.6075, 35.0421],
          [-86.6172, 35.0412],
          [-86.6024, 35.0334],
          [-86.5995, 35.0168],
          [-86.5809, 35.0035],
          [-86.5785, 34.9911],
          [-86.153, 34.9901],
          [-85.6954, 34.9861],
          [-85.6918, 34.9245],
          [-85.7794, 34.8358],
          [-85.7767, 34.8108],
          [-85.7844, 34.8097],
          [-85.791, 34.7907],
          [-85.7856, 34.7783],
          [-85.7453, 34.7805],
          [-85.7493, 34.7506],
          [-85.7239, 34.756],
          [-85.7287, 34.7426],
          [-85.7119, 34.7434],
          [-85.704, 34.7298],
          [-85.7669, 34.6482],
          [-85.7724, 34.6605],
          [-85.7885, 34.6635],
          [-85.8248, 34.6455],
          [-85.8745, 34.6339],
          [-85.8567, 34.6045],
          [-85.8738, 34.6022],
          [-85.8628, 34.5807],
          [-85.9341, 34.5281],
          [-85.9367, 34.5032],
          [-85.9601, 34.5022],
          [-86.0038, 34.48],
          [-86.0577, 34.476],
          [-86.0908, 34.4457],
          [-86.1078, 34.4533],
          [-86.1412, 34.4542],
          [-86.1746, 34.4405],
          [-86.1788, 34.4509],
          [-86.19, 34.4541],
          [-86.1796, 34.4795],
          [-86.1685, 34.4802],
          [-86.1755, 34.4843],
          [-86.1714, 34.4903],
          [-86.1629, 34.4954],
          [-86.1636, 34.4885],
          [-86.1541, 34.4888],
          [-86.1513, 34.5043],
          [-86.1445, 34.4985],
          [-86.1218, 34.5246],
          [-86.1172, 34.5179],
          [-86.1049, 34.5188],
          [-86.1098, 34.5286],
          [-86.1196, 34.5245],
          [-86.1277, 34.5339],
          [-86.185, 34.49],
          [-86.2218, 34.4431],
          [-86.2382, 34.4474],
          [-86.2211, 34.4602],
          [-86.2206, 34.4701],
          [-86.2644, 34.4661],
          [-86.2754, 34.4637],
          [-86.2777, 34.4591],
          [-86.2673, 34.4638],
          [-86.2727, 34.457],
          [-86.2996, 34.4581],
          [-86.3319, 34.4484],
          [-86.336, 34.4338],
          [-86.375, 34.4226],
          [-86.4125, 34.433],
          [-86.4431, 34.4599],
          [-86.465, 34.4712],
          [-86.5281, 34.4983],
          [-86.5857, 34.4722],
          [-86.6155, 34.4109],
          [-86.6051, 34.3711],
          [-86.654, 34.3701],
          [-86.6744, 34.3265],
          [-86.717, 34.3065],
          [-86.7529, 34.3259],
          [-86.8464, 34.2915],
          [-86.929, 34.2761],
          [-86.9379, 34.3158],
          [-86.9777, 34.3376],
          [-87.0087, 34.3117],
          [-87.1101, 34.3134],
          [-87.1113, 34.1567],
          [-87.1023, 34.1392],
          [-87.1367, 34.1008],
          [-87.1411, 34.0433],
          [-87.112, 34.0167],
          [-87.1341, 33.9651],
          [-87.1095, 33.9563],
          [-87.0819, 33.9075],
          [-87.0996, 33.8815],
          [-87.0709, 33.8248],
          [-87.0986, 33.7376],
          [-87.1342, 33.7358],
          [-87.1469, 33.6985],
          [-87.1201, 33.663],
          [-87.232, 33.6313],
          [-87.2363, 33.6105],
          [-87.1872, 33.5517],
          [-87.2472, 33.5196],
          [-87.3734, 33.5107],
          [-87.4155, 33.55],
          [-87.4591, 33.5347],
          [-87.4948, 33.4884],
          [-87.5538, 33.494],
          [-87.5925, 33.5462],
          [-87.6519, 33.5302],
          [-87.653, 33.5],
          [-87.6274, 33.4662],
          [-87.6781, 33.459],
          [-87.6945, 33.4993],
          [-87.7618, 33.4957],
          [-87.7614, 33.4486],
          [-87.8386, 33.4319],
          [-87.9004, 33.4733],
          [-87.9503, 33.459],
          [-87.9684, 33.493],
          [-88.079, 33.4769],
          [-88.0654, 33.4382],
          [-88.0968, 33.3978],
          [-88.1436, 33.4092],
          [-88.1503, 33.4759],
          [-88.1418, 33.5281],
          [-88.2331, 33.4874],
          [-88.2347, 33.5158],
          [-88.2717, 33.5573],
          [-88.2416, 33.8029],
          [-88.283, 33.8066],
          [-88.2927, 33.8408],
          [-88.3755, 33.8089],
          [-88.3588, 33.8763],
          [-88.3898, 33.9339],
          [-88.4798, 33.8829],
          [-88.5497, 33.898],
          [-88.5173, 33.9894],
          [-88.6212, 34.0106],
          [-88.6972, 34.0014],
          [-88.6572, 33.9378],
          [-88.7201, 33.9506],
          [-88.72, 33.811],
          [-88.7672, 33.8402],
          [-88.8266, 33.8117],
          [-88.9312, 33.8124],
          [-88.9551, 33.7966],
          [-89.0176, 33.8464],
          [-89.0858, 33.848],
          [-89.1906, 33.7891],
          [-89.2471, 33.7798],
          [-89.2246, 33.8246],
          [-89.2489, 33.8397],
          [-89.2431, 33.8826],
          [-89.2629, 33.9167],
          [-89.2534, 33.9482],
          [-89.1906, 33.9687],
          [-89.1903, 34.0152],
          [-89.2606, 34.0508],
          [-89.2682, 34.0941],
          [-89.3154, 34.1256],
          [-89.3006, 34.1614],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Mobile Mkt": {
    type: "Feature",
    properties: { code: "AL_MOB", name: "Mobile Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-88.8926, 31.8038],
          [-88.9281, 31.7537],
          [-88.9076, 31.7316],
          [-88.9037, 31.6728],
          [-88.8866, 31.6658],
          [-88.877, 31.5539],
          [-88.8402, 31.554],
          [-88.8307, 31.5064],
          [-88.7964, 31.4437],
          [-88.7671, 31.4351],
          [-88.7312, 31.4756],
          [-88.6733, 31.4649],
          [-88.624, 31.4341],
          [-88.6205, 31.2852],
          [-88.6114, 31.2287],
          [-88.5674, 31.2388],
          [-88.5321, 31.2203],
          [-88.4957, 31.2485],
          [-88.4388, 31.2527],
          [-88.4666, 31.7501],
          [-88.3947, 31.7557],
          [-88.3623, 31.6982],
          [-88.0883, 31.6993],
          [-88.1049, 31.7608],
          [-88.1586, 31.7661],
          [-88.1805, 31.8201],
          [-88.1303, 31.8761],
          [-88.0879, 31.866],
          [-88.0623, 31.8826],
          [-88.017, 31.8342],
          [-87.9985, 31.8679],
          [-87.91, 31.8848],
          [-87.8471, 31.854],
          [-87.7959, 31.8063],
          [-87.7404, 31.8236],
          [-87.6864, 31.7944],
          [-87.5345, 31.8509],
          [-87.5175, 31.9123],
          [-87.4655, 31.9358],
          [-87.4103, 31.9063],
          [-87.3317, 31.9158],
          [-87.2777, 31.8566],
          [-87.284, 31.8357],
          [-87.2141, 31.8285],
          [-86.9896, 31.83],
          [-86.9857, 31.7921],
          [-87.0166, 31.7405],
          [-86.9468, 31.7017],
          [-86.9256, 31.6665],
          [-86.7921, 31.6014],
          [-86.8011, 31.5666],
          [-86.6944, 31.5851],
          [-86.6422, 31.5238],
          [-86.5027, 31.5251],
          [-86.5301, 31.4556],
          [-86.4653, 31.4606],
          [-86.4405, 31.4475],
          [-86.388, 31.4575],
          [-86.3296, 31.4109],
          [-86.3021, 31.4407],
          [-86.194, 31.4401],
          [-86.195, 31.3528],
          [-86.1487, 31.3525],
          [-86.1342, 31.3202],
          [-86.1027, 31.3055],
          [-86.1153, 31.2743],
          [-86.0544, 31.2788],
          [-86.0473, 31.2303],
          [-86.0132, 31.199],
          [-86.0014, 31.118],
          [-85.9535, 31.1041],
          [-85.9557, 31.0566],
          [-85.9225, 31.0363],
          [-85.9359, 30.9932],
          [-86.2383, 30.9944],
          [-86.2841, 30.9917],
          [-86.3051, 30.919],
          [-86.3527, 30.8664],
          [-86.3905, 30.8404],
          [-86.3909, 30.813],
          [-86.359, 30.7857],
          [-86.3585, 30.7301],
          [-86.3916, 30.7219],
          [-86.3942, 30.4673],
          [-86.3271, 30.4779],
          [-86.3212, 30.4096],
          [-86.305, 30.3764],
          [-86.2651, 30.3545],
          [-86.3642, 30.3745],
          [-86.633, 30.3963],
          [-86.7507, 30.3919],
          [-86.9097, 30.3724],
          [-87.1554, 30.3277],
          [-87.2678, 30.3155],
          [-87.2954, 30.3235],
          [-87.4199, 30.2971],
          [-87.5814, 30.2693],
          [-87.6569, 30.2497],
          [-87.8385, 30.2272],
          [-87.9668, 30.2356],
          [-87.936, 30.2615],
          [-87.8932, 30.2392],
          [-87.7666, 30.2624],
          [-87.7555, 30.2912],
          [-87.8093, 30.3327],
          [-87.8451, 30.3774],
          [-87.9063, 30.4094],
          [-87.9334, 30.4874],
          [-87.9017, 30.5509],
          [-87.9125, 30.6158],
          [-87.9367, 30.6574],
          [-88.0084, 30.685],
          [-88.062, 30.6449],
          [-88.054, 30.6125],
          [-88.0855, 30.5633],
          [-88.1009, 30.5098],
          [-88.1073, 30.3772],
          [-88.1362, 30.3207],
          [-88.1915, 30.317],
          [-88.2045, 30.3621],
          [-88.2607, 30.3824],
          [-88.3165, 30.37],
          [-88.3747, 30.3856],
          [-88.4538, 30.3296],
          [-88.5048, 30.3215],
          [-88.5225, 30.3401],
          [-88.5963, 30.3584],
          [-88.6638, 30.3621],
          [-88.7009, 30.3437],
          [-88.7464, 30.3475],
          [-88.8413, 30.4096],
          [-88.8578, 30.3929],
          [-88.922, 30.3938],
          [-89.0163, 30.3839],
          [-89.0832, 30.3681],
          [-89.2718, 30.3055],
          [-89.2798, 30.3498],
          [-89.3151, 30.3754],
          [-89.3299, 30.3029],
          [-89.4193, 30.2543],
          [-89.4479, 30.1854],
          [-89.4802, 30.1938],
          [-89.5245, 30.1808],
          [-89.5702, 30.1803],
          [-89.6171, 30.2275],
          [-89.6417, 30.3038],
          [-89.6297, 30.3393],
          [-89.6572, 30.3565],
          [-89.6837, 30.4059],
          [-89.6834, 30.4518],
          [-89.7246, 30.4919],
          [-89.7681, 30.515],
          [-89.8197, 30.5968],
          [-89.8247, 30.649],
          [-89.8472, 30.67],
          [-89.8281, 30.725],
          [-89.8315, 30.7676],
          [-89.7686, 30.8666],
          [-89.7501, 30.9129],
          [-89.7563, 30.9435],
          [-89.7277, 30.9933],
          [-89.7526, 31.0019],
          [-89.941, 31.0014],
          [-89.9634, 31.0671],
          [-89.9295, 31.0892],
          [-89.9506, 31.1431],
          [-89.9991, 31.1686],
          [-89.9727, 31.1982],
          [-89.91, 31.2154],
          [-89.951, 31.2406],
          [-90.0045, 31.2192],
          [-90.0235, 31.2886],
          [-90.0404, 31.2996],
          [-90.0405, 31.3946],
          [-89.9215, 31.3914],
          [-89.8754, 31.4212],
          [-89.8572, 31.4504],
          [-89.857, 31.4926],
          [-89.8911, 31.5287],
          [-89.9477, 31.4986],
          [-89.9762, 31.5417],
          [-89.9374, 31.636],
          [-89.9748, 31.6855],
          [-89.9101, 31.7054],
          [-89.9034, 31.6785],
          [-89.8479, 31.7122],
          [-89.8242, 31.7449],
          [-89.7906, 31.7247],
          [-89.8105, 31.6841],
          [-89.7554, 31.6897],
          [-89.7217, 31.6618],
          [-89.6754, 31.6478],
          [-89.6208, 31.7172],
          [-89.6104, 31.7537],
          [-89.5545, 31.7558],
          [-89.5412, 31.7339],
          [-89.4845, 31.7722],
          [-89.4566, 31.7373],
          [-89.4706, 31.7182],
          [-89.3883, 31.694],
          [-89.3537, 31.7059],
          [-89.3378, 31.7488],
          [-89.3133, 31.7647],
          [-89.3171, 31.8743],
          [-89.3712, 31.9245],
          [-89.4043, 31.9171],
          [-89.4162, 32.0005],
          [-89.3351, 32.0344],
          [-89.2922, 32.0401],
          [-89.2762, 32.0072],
          [-89.238, 32.0047],
          [-89.2045, 32.0252],
          [-89.1448, 32.0073],
          [-89.0876, 32.0288],
          [-89.1075, 31.9654],
          [-89.0298, 31.9407],
          [-88.978, 31.9343],
          [-88.9698, 31.9027],
          [-88.9341, 31.9021],
          [-88.8668, 31.8658],
          [-88.9018, 31.8272],
          [-88.8926, 31.8038],
        ],
        [
          [-87.5184, 31.8144],
          [-87.5163, 31.7098],
          [-87.441, 31.7414],
          [-87.4213, 31.7929],
          [-87.4818, 31.8143],
          [-87.5184, 31.8144],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Montgomery Mkt": {
    type: "Feature",
    properties: { code: "AL_MON", name: "Montgomery Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-87.5184, 31.8144],
            [-87.4818, 31.8143],
            [-87.4213, 31.7929],
            [-87.441, 31.7414],
            [-87.5163, 31.7098],
            [-87.5184, 31.8144],
          ],
        ],
        [
          [
            [-88.3854, 32.6072],
            [-88.3541, 32.6105],
            [-88.3534, 32.5539],
            [-88.2496, 32.5552],
            [-88.1855, 32.5703],
            [-88.1526, 32.551],
            [-88.1743, 32.4745],
            [-88.1551, 32.4532],
            [-88.0988, 32.4543],
            [-88.0365, 32.4299],
            [-87.9541, 32.473],
            [-87.9451, 32.4913],
            [-87.8738, 32.5443],
            [-87.8984, 32.5923],
            [-87.8806, 32.6433],
            [-87.8896, 32.6799],
            [-87.8404, 32.7053],
            [-87.8082, 32.7531],
            [-87.7696, 32.7774],
            [-87.7538, 32.8199],
            [-87.6658, 32.8057],
            [-87.6322, 32.8106],
            [-87.5679, 32.8559],
            [-87.3536, 32.8684],
            [-87.3157, 32.8237],
            [-87.1875, 32.8354],
            [-87.1728, 32.8004],
            [-87.1039, 32.7732],
            [-87.0847, 32.8217],
            [-87.0379, 32.8546],
            [-87.0248, 32.9125],
            [-86.9587, 32.9725],
            [-86.9251, 32.9614],
            [-86.891, 33.0071],
            [-86.8588, 33.0007],
            [-86.8354, 32.9635],
            [-86.864, 32.9301],
            [-86.8483, 32.8927],
            [-86.803, 32.894],
            [-86.7922, 32.8454],
            [-86.761, 32.7775],
            [-86.7617, 32.7121],
            [-86.6083, 32.7388],
            [-86.5532, 32.7637],
            [-86.5642, 32.805],
            [-86.5169, 32.8248],
            [-86.4263, 32.8311],
            [-86.424, 32.7918],
            [-86.3715, 32.7536],
            [-86.3367, 32.7681],
            [-86.2811, 32.7534],
            [-86.2514, 32.7836],
            [-86.0888, 32.8287],
            [-86.0211, 32.827],
            [-86.0071, 32.755],
            [-85.9647, 32.7425],
            [-85.9077, 32.6987],
            [-85.8799, 32.7545],
            [-85.8976, 32.7823],
            [-85.879, 32.8263],
            [-85.9013, 32.8596],
            [-85.8612, 32.9617],
            [-85.8144, 32.9534],
            [-85.7652, 32.9747],
            [-85.7021, 32.9823],
            [-85.6466, 32.9653],
            [-85.5932, 33.0012],
            [-85.5374, 33.0202],
            [-85.5302, 33.05],
            [-85.4884, 33.0622],
            [-85.3618, 33.0687],
            [-85.2857, 33.1082],
            [-85.2324, 33.1081],
            [-85.1961, 32.9264],
            [-85.1851, 32.9159],
            [-85.1536, 32.9225],
            [-85.1424, 32.9145],
            [-85.1224, 32.9308],
            [-85.1112, 32.9236],
            [-85.0829, 32.9607],
            [-85.0683, 32.9465],
            [-85.0701, 32.9203],
            [-85.0217, 32.9162],
            [-84.9957, 32.9263],
            [-84.9817, 32.9263],
            [-84.977, 32.919],
            [-84.9224, 32.9477],
            [-84.8616, 32.9332],
            [-84.8615, 32.9456],
            [-84.8325, 32.9418],
            [-84.8219, 32.949],
            [-84.7963, 32.9334],
            [-84.7904, 32.9383],
            [-84.8025, 32.9489],
            [-84.7752, 32.9601],
            [-84.7611, 32.9595],
            [-84.7657, 32.9413],
            [-84.744, 32.9599],
            [-84.7224, 32.9593],
            [-84.7316, 32.9354],
            [-84.6998, 32.9365],
            [-84.6942, 32.9222],
            [-84.7139, 32.9064],
            [-84.7152, 32.8968],
            [-84.6925, 32.9027],
            [-84.6845, 32.9182],
            [-84.6693, 32.9211],
            [-84.6579, 32.9365],
            [-84.6573, 32.9222],
            [-84.6479, 32.9319],
            [-84.6492, 32.9236],
            [-84.637, 32.924],
            [-84.6357, 32.9298],
            [-84.6175, 32.9186],
            [-84.6078, 32.9237],
            [-84.6017, 32.9015],
            [-84.583, 32.8999],
            [-84.5844, 32.8906],
            [-84.5543, 32.9168],
            [-84.5453, 32.9095],
            [-84.5395, 32.9185],
            [-84.5155, 32.9146],
            [-84.5099, 32.8837],
            [-84.4759, 32.8769],
            [-84.4901, 32.8569],
            [-84.4673, 32.853],
            [-84.4566, 32.8287],
            [-84.4427, 32.8252],
            [-84.4342, 32.8381],
            [-84.4212, 32.8388],
            [-84.3825, 32.7803],
            [-84.3556, 32.7736],
            [-84.3572, 32.7826],
            [-84.3511, 32.7829],
            [-84.3357, 32.7596],
            [-84.3251, 32.7624],
            [-84.3161, 32.7555],
            [-84.3014, 32.7613],
            [-84.2865, 32.7475],
            [-84.3379, 32.7209],
            [-84.3406, 32.6608],
            [-84.3127, 32.6084],
            [-84.4085, 32.5981],
            [-84.4089, 32.5619],
            [-84.4444, 32.5621],
            [-84.443, 32.5186],
            [-84.4813, 32.4734],
            [-84.5501, 32.4883],
            [-84.5849, 32.465],
            [-84.4748, 32.4225],
            [-84.4486, 32.4389],
            [-84.3624, 32.428],
            [-84.3632, 32.3976],
            [-84.3238, 32.3725],
            [-84.2546, 32.3721],
            [-84.2559, 32.2962],
            [-84.2192, 32.2951],
            [-84.2201, 32.2312],
            [-84.1999, 32.2304],
            [-84.1817, 32.2297],
            [-84.1836, 32.1585],
            [-84.4302, 32.1663],
            [-84.433, 32.042],
            [-84.4445, 31.9464],
            [-84.5213, 31.9468],
            [-84.5991, 31.9644],
            [-84.6059, 31.9202],
            [-84.8815, 31.924],
            [-84.8725, 31.9489],
            [-84.9028, 31.9767],
            [-84.9269, 31.9501],
            [-84.9639, 31.9669],
            [-85.042, 31.9757],
            [-85.0868, 31.9578],
            [-85.1401, 31.8588],
            [-85.1418, 31.7821],
            [-85.1189, 31.7327],
            [-85.1255, 31.695],
            [-85.0595, 31.6217],
            [-85.058, 31.5708],
            [-85.0413, 31.541],
            [-85.0716, 31.4684],
            [-85.0922, 31.3646],
            [-85.0898, 31.295],
            [-85.1133, 31.2643],
            [-85.0968, 31.2257],
            [-85.1, 31.1653],
            [-85.0778, 31.1579],
            [-85.0356, 31.1082],
            [-85.0088, 31.0456],
            [-85.0024, 31.0007],
            [-85.2436, 31.0009],
            [-85.4983, 30.9969],
            [-85.9359, 30.9932],
            [-85.9225, 31.0363],
            [-85.9557, 31.0566],
            [-85.9535, 31.1041],
            [-86.0014, 31.118],
            [-86.0132, 31.199],
            [-86.0473, 31.2303],
            [-86.0544, 31.2788],
            [-86.1153, 31.2743],
            [-86.1027, 31.3055],
            [-86.1342, 31.3202],
            [-86.1487, 31.3525],
            [-86.195, 31.3528],
            [-86.194, 31.4401],
            [-86.3021, 31.4407],
            [-86.3296, 31.4109],
            [-86.388, 31.4575],
            [-86.4405, 31.4475],
            [-86.4653, 31.4606],
            [-86.5301, 31.4556],
            [-86.5027, 31.5251],
            [-86.6422, 31.5238],
            [-86.6944, 31.5851],
            [-86.8011, 31.5666],
            [-86.7921, 31.6014],
            [-86.9256, 31.6665],
            [-86.9468, 31.7017],
            [-87.0166, 31.7405],
            [-86.9857, 31.7921],
            [-86.9896, 31.83],
            [-87.2141, 31.8285],
            [-87.284, 31.8357],
            [-87.2777, 31.8566],
            [-87.3317, 31.9158],
            [-87.4103, 31.9063],
            [-87.4655, 31.9358],
            [-87.5175, 31.9123],
            [-87.5345, 31.8509],
            [-87.6864, 31.7944],
            [-87.7404, 31.8236],
            [-87.7959, 31.8063],
            [-87.8471, 31.854],
            [-87.91, 31.8848],
            [-87.9985, 31.8679],
            [-88.017, 31.8342],
            [-88.0623, 31.8826],
            [-88.0879, 31.866],
            [-88.1303, 31.8761],
            [-88.1805, 31.8201],
            [-88.1586, 31.7661],
            [-88.1049, 31.7608],
            [-88.0883, 31.6993],
            [-88.3623, 31.6982],
            [-88.3947, 31.7557],
            [-88.4666, 31.7501],
            [-88.4732, 31.8939],
            [-88.4387, 32.1721],
            [-88.3854, 32.6072],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Fayetteville Mkt": {
    type: "Feature",
    properties: { code: "AR_FAY", name: "Fayetteville Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-95.1112, 35.3842],
            [-95.0874, 35.4047],
            [-95.0981, 35.3871],
            [-95.1112, 35.3842],
          ],
        ],
        [
          [
            [-95.1333, 35.3801],
            [-95.1257, 35.3753],
            [-95.1534, 35.3623],
            [-95.1333, 35.3801],
          ],
        ],
        [
          [
            [-95.1663, 34.8873],
            [-95.1386, 34.8912],
            [-95.1209, 34.8908],
            [-95.0687, 34.8983],
            [-95.067, 34.8782],
            [-95.0784, 34.8696],
            [-95.0681, 34.8517],
            [-95.0595, 34.8506],
            [-95.0594, 34.832],
            [-95.0857, 34.8328],
            [-95.0858, 34.8398],
            [-95.1129, 34.8521],
            [-95.1334, 34.8516],
            [-95.1346, 34.86],
            [-95.1472, 34.8602],
            [-95.1596, 34.8715],
            [-95.1663, 34.8873],
          ],
        ],
        [
          [
            [-94.6179, 36.4994],
            [-94.1115, 36.4986],
            [-93.4398, 36.4986],
            [-92.8542, 36.498],
            [-92.8331, 36.454],
            [-92.7597, 36.4963],
            [-92.7431, 36.4281],
            [-92.6911, 36.4364],
            [-92.6258, 36.4858],
            [-92.6306, 36.4981],
            [-92.2595, 36.4982],
            [-92.2527, 36.4767],
            [-92.2988, 36.4334],
            [-92.2698, 36.3877],
            [-92.2234, 36.3484],
            [-92.2582, 36.3244],
            [-92.2327, 36.266],
            [-92.2294, 36.2223],
            [-92.1817, 36.2326],
            [-92.2237, 36.169],
            [-92.3107, 36.1555],
            [-92.296, 36.1242],
            [-92.3705, 36.0776],
            [-92.3383, 36.0493],
            [-92.3056, 36.0494],
            [-92.3071, 35.9785],
            [-92.2646, 35.9237],
            [-92.2239, 35.9051],
            [-92.2777, 35.847],
            [-92.3218, 35.8569],
            [-92.3774, 35.8908],
            [-92.4069, 35.8425],
            [-92.3479, 35.7696],
            [-92.3974, 35.7376],
            [-92.4306, 35.7716],
            [-92.5225, 35.746],
            [-92.5172, 35.6712],
            [-92.5862, 35.6823],
            [-92.6082, 35.7093],
            [-92.6627, 35.6808],
            [-92.7796, 35.67],
            [-92.8108, 35.6792],
            [-92.8352, 35.7228],
            [-92.9513, 35.7247],
            [-92.943, 35.9279],
            [-92.9888, 35.878],
            [-93.0444, 35.8962],
            [-93.0841, 35.8935],
            [-93.1304, 35.8691],
            [-93.1835, 35.8773],
            [-93.2017, 35.7866],
            [-93.2415, 35.7301],
            [-93.3066, 35.7555],
            [-93.3064, 35.779],
            [-93.3578, 35.7833],
            [-93.3725, 35.8433],
            [-93.4626, 35.7793],
            [-93.5493, 35.7643],
            [-93.5512, 35.7345],
            [-93.595, 35.7242],
            [-93.6232, 35.6641],
            [-93.5943, 35.615],
            [-93.5957, 35.5777],
            [-93.631, 35.5786],
            [-93.6728, 35.5007],
            [-93.7302, 35.5037],
            [-93.7793, 35.4878],
            [-93.7759, 35.3827],
            [-93.809, 35.3628],
            [-93.8114, 35.2738],
            [-93.7393, 35.2714],
            [-93.7262, 35.2471],
            [-93.6769, 35.2328],
            [-93.6859, 35.1832],
            [-93.7277, 35.1515],
            [-93.7073, 35.1131],
            [-93.7061, 34.963],
            [-93.7722, 34.9316],
            [-93.8453, 34.9109],
            [-93.8258, 34.8561],
            [-93.7777, 34.8323],
            [-93.8255, 34.7864],
            [-93.7457, 34.7319],
            [-93.817, 34.7113],
            [-93.8702, 34.7127],
            [-93.8782, 34.6653],
            [-94.0182, 34.6925],
            [-94.1769, 34.695],
            [-94.1567, 34.7692],
            [-94.1307, 34.8017],
            [-94.1787, 34.8224],
            [-94.2785, 34.8316],
            [-94.2819, 34.8512],
            [-94.4512, 34.8278],
            [-94.4664, 34.3329],
            [-94.5009, 34.3192],
            [-94.5011, 34.3341],
            [-94.5213, 34.3272],
            [-94.5716, 34.3276],
            [-94.6304, 34.3356],
            [-94.6353, 34.3434],
            [-94.681, 34.3537],
            [-94.6951, 34.3704],
            [-94.6963, 34.3768],
            [-94.6955, 34.3819],
            [-94.6961, 34.387],
            [-94.6915, 34.3917],
            [-94.6881, 34.3938],
            [-94.6865, 34.3965],
            [-94.6839, 34.405],
            [-94.6774, 34.4018],
            [-94.674, 34.402],
            [-94.6672, 34.4088],
            [-94.6651, 34.4153],
            [-94.6667, 34.4182],
            [-94.6682, 34.4227],
            [-94.6643, 34.4258],
            [-94.6603, 34.4374],
            [-94.6552, 34.4444],
            [-94.6503, 34.4453],
            [-94.6441, 34.4427],
            [-94.6428, 34.4394],
            [-94.6358, 34.4386],
            [-94.6304, 34.4404],
            [-94.6327, 34.4545],
            [-94.6334, 34.4553],
            [-94.6367, 34.4566],
            [-94.6378, 34.4585],
            [-94.6373, 34.4596],
            [-94.6189, 34.4593],
            [-94.6328, 34.4624],
            [-94.6386, 34.4593],
            [-94.6376, 34.4565],
            [-94.6335, 34.4543],
            [-94.6342, 34.4474],
            [-94.6321, 34.445],
            [-94.6308, 34.4425],
            [-94.6309, 34.441],
            [-94.6326, 34.44],
            [-94.6419, 34.4397],
            [-94.644, 34.4439],
            [-94.6494, 34.4459],
            [-94.6567, 34.4444],
            [-94.6689, 34.423],
            [-94.6673, 34.4101],
            [-94.6741, 34.4026],
            [-94.6818, 34.406],
            [-94.6848, 34.4052],
            [-94.6873, 34.3954],
            [-94.6915, 34.3923],
            [-94.696, 34.388],
            [-94.6976, 34.3768],
            [-94.6966, 34.3875],
            [-94.6936, 34.391],
            [-94.6995, 34.3917],
            [-94.7016, 34.3883],
            [-94.7087, 34.3903],
            [-94.7077, 34.3841],
            [-94.7297, 34.372],
            [-94.7704, 34.4065],
            [-94.7918, 34.401],
            [-94.7754, 34.4135],
            [-94.7751, 34.4269],
            [-94.7833, 34.43],
            [-94.788, 34.4491],
            [-94.7956, 34.4317],
            [-94.7917, 34.4481],
            [-94.8093, 34.4583],
            [-94.8037, 34.4665],
            [-94.8176, 34.4877],
            [-94.8373, 34.5001],
            [-94.8273, 34.5073],
            [-94.9376, 34.5069],
            [-94.9376, 34.5146],
            [-94.9328, 34.5149],
            [-94.9278, 34.5165],
            [-94.9267, 34.5272],
            [-94.8744, 34.5316],
            [-94.8771, 34.5376],
            [-94.8675, 34.535],
            [-94.855, 34.5433],
            [-94.8372, 34.5388],
            [-94.8349, 34.5536],
            [-94.8484, 34.5653],
            [-94.8516, 34.5834],
            [-94.8684, 34.593],
            [-94.8452, 34.6141],
            [-94.786, 34.6087],
            [-94.7734, 34.6134],
            [-94.8003, 34.6304],
            [-94.7783, 34.6301],
            [-94.78, 34.6487],
            [-94.771, 34.6603],
            [-94.7914, 34.6894],
            [-94.8072, 34.6826],
            [-94.8138, 34.6875],
            [-94.8197, 34.6729],
            [-94.84, 34.6744],
            [-94.8283, 34.7061],
            [-94.838, 34.734],
            [-94.8267, 34.7393],
            [-94.7845, 34.7341],
            [-94.7839, 34.7407],
            [-94.8374, 34.755],
            [-94.8651, 34.7706],
            [-94.8863, 34.7701],
            [-94.8867, 34.8217],
            [-94.9713, 34.8367],
            [-95.0064, 34.8541],
            [-94.9976, 34.9494],
            [-94.9278, 34.9501],
            [-94.9279, 35.0583],
            [-95.1787, 35.0579],
            [-95.1193, 35.0844],
            [-95.1284, 35.1114],
            [-95.1527, 35.1077],
            [-95.1589, 35.1125],
            [-95.1431, 35.1302],
            [-95.1454, 35.1509],
            [-95.129, 35.1513],
            [-95.1046, 35.163],
            [-95.1037, 35.1412],
            [-95.0782, 35.1445],
            [-95.0873, 35.152],
            [-95.0851, 35.172],
            [-95.0588, 35.1993],
            [-95.0405, 35.2064],
            [-95.0256, 35.1994],
            [-95.0137, 35.2104],
            [-95.0014, 35.2059],
            [-94.9891, 35.2158],
            [-94.9917, 35.2282],
            [-94.9501, 35.2823],
            [-94.9645, 35.2873],
            [-94.9566, 35.3138],
            [-94.9735, 35.3185],
            [-94.9819, 35.3331],
            [-94.9765, 35.3453],
            [-94.9516, 35.355],
            [-94.9571, 35.3778],
            [-94.947, 35.3767],
            [-94.9342, 35.3955],
            [-94.9513, 35.4083],
            [-94.9868, 35.4084],
            [-95.0092, 35.4164],
            [-95.0242, 35.4314],
            [-95.0277, 35.4548],
            [-95.0614, 35.4651],
            [-95.0856, 35.4614],
            [-95.1008, 35.4949],
            [-95.0409, 35.5404],
            [-95.0181, 35.5434],
            [-95.0203, 35.5662],
            [-94.9848, 35.5663],
            [-94.9853, 35.5793],
            [-94.9766, 35.5808],
            [-94.9806, 35.5918],
            [-94.9917, 35.5925],
            [-94.9934, 35.5793],
            [-95.027, 35.5771],
            [-95.0373, 35.5827],
            [-95.0317, 35.5897],
            [-95.0401, 35.6024],
            [-95.0371, 35.6169],
            [-95.0177, 35.6333],
            [-94.9782, 35.6518],
            [-94.9611, 35.6441],
            [-94.9266, 35.6768],
            [-94.9045, 35.6545],
            [-94.9005, 35.6388],
            [-94.8073, 35.6386],
            [-94.8077, 35.6744],
            [-94.8253, 35.656],
            [-94.8469, 35.6812],
            [-94.8862, 35.7046],
            [-94.8801, 35.7259],
            [-94.9041, 35.7295],
            [-94.8917, 35.7494],
            [-94.8774, 35.7553],
            [-94.8348, 35.7411],
            [-94.8295, 35.776],
            [-94.8469, 35.7928],
            [-94.8295, 35.8219],
            [-94.8135, 35.8058],
            [-94.8075, 35.8116],
            [-94.8072, 35.8735],
            [-94.865, 35.8897],
            [-94.8645, 35.9036],
            [-94.8482, 35.9083],
            [-94.8271, 35.9279],
            [-94.8257, 35.9395],
            [-94.8031, 35.9627],
            [-94.7597, 35.9588],
            [-94.7728, 35.9618],
            [-94.7613, 35.9661],
            [-94.7656, 35.976],
            [-94.7457, 35.9982],
            [-94.7251, 36.0058],
            [-94.7339, 36.0112],
            [-94.7534, 36.007],
            [-94.7507, 36.033],
            [-94.7299, 36.0384],
            [-94.7311, 36.0515],
            [-94.7444, 36.0713],
            [-94.7769, 36.0749],
            [-94.7688, 36.0951],
            [-94.7897, 36.1],
            [-94.7971, 36.1122],
            [-94.783, 36.1043],
            [-94.7686, 36.1195],
            [-94.7548, 36.1198],
            [-94.7617, 36.1273],
            [-94.7353, 36.1617],
            [-94.5942, 36.1617],
            [-94.5836, 36.1763],
            [-94.5653, 36.1765],
            [-94.6179, 36.4994],
          ],
          [
            [-94.9265, 35.3652],
            [-94.938, 35.3738],
            [-94.9268, 35.3728],
            [-94.9357, 35.3757],
            [-94.9293, 35.3778],
            [-94.9447, 35.3751],
            [-94.9265, 35.3652],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Little Rock Mkt": {
    type: "Feature",
    properties: { code: "AR_LIT", name: "Little Rock Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-94.4664, 34.3329],
          [-94.4512, 34.8278],
          [-94.2819, 34.8512],
          [-94.2785, 34.8316],
          [-94.1787, 34.8224],
          [-94.1307, 34.8017],
          [-94.1567, 34.7692],
          [-94.1769, 34.695],
          [-94.0182, 34.6925],
          [-93.8782, 34.6653],
          [-93.8702, 34.7127],
          [-93.817, 34.7113],
          [-93.7457, 34.7319],
          [-93.8255, 34.7864],
          [-93.7777, 34.8323],
          [-93.8258, 34.8561],
          [-93.8453, 34.9109],
          [-93.7722, 34.9316],
          [-93.7061, 34.963],
          [-93.7073, 35.1131],
          [-93.7277, 35.1515],
          [-93.6859, 35.1832],
          [-93.6769, 35.2328],
          [-93.7262, 35.2471],
          [-93.7393, 35.2714],
          [-93.8114, 35.2738],
          [-93.809, 35.3628],
          [-93.7759, 35.3827],
          [-93.7793, 35.4878],
          [-93.7302, 35.5037],
          [-93.6728, 35.5007],
          [-93.631, 35.5786],
          [-93.5957, 35.5777],
          [-93.5943, 35.615],
          [-93.6232, 35.6641],
          [-93.595, 35.7242],
          [-93.5512, 35.7345],
          [-93.5493, 35.7643],
          [-93.4626, 35.7793],
          [-93.3725, 35.8433],
          [-93.3578, 35.7833],
          [-93.3064, 35.779],
          [-93.3066, 35.7555],
          [-93.2415, 35.7301],
          [-93.2017, 35.7866],
          [-93.1835, 35.8773],
          [-93.1304, 35.8691],
          [-93.0841, 35.8935],
          [-93.0444, 35.8962],
          [-92.9888, 35.878],
          [-92.943, 35.9279],
          [-92.9513, 35.7247],
          [-92.8352, 35.7228],
          [-92.8108, 35.6792],
          [-92.7796, 35.67],
          [-92.6627, 35.6808],
          [-92.6082, 35.7093],
          [-92.5862, 35.6823],
          [-92.5172, 35.6712],
          [-92.5225, 35.746],
          [-92.4306, 35.7716],
          [-92.3974, 35.7376],
          [-92.3479, 35.7696],
          [-92.4069, 35.8425],
          [-92.3774, 35.8908],
          [-92.3218, 35.8569],
          [-92.2777, 35.847],
          [-92.2239, 35.9051],
          [-92.2646, 35.9237],
          [-92.3071, 35.9785],
          [-92.3056, 36.0494],
          [-92.3383, 36.0493],
          [-92.3705, 36.0776],
          [-92.296, 36.1242],
          [-92.3107, 36.1555],
          [-92.2237, 36.169],
          [-92.1817, 36.2326],
          [-92.2294, 36.2223],
          [-92.2327, 36.266],
          [-92.2582, 36.3244],
          [-92.2234, 36.3484],
          [-92.2698, 36.3877],
          [-92.2988, 36.4334],
          [-92.2527, 36.4767],
          [-92.2595, 36.4982],
          [-91.6723, 36.4995],
          [-91.4071, 36.4971],
          [-91.362, 36.4723],
          [-91.3491, 36.4209],
          [-91.3189, 36.3874],
          [-91.312, 36.3531],
          [-91.3907, 36.359],
          [-91.3919, 36.319],
          [-91.4239, 36.2867],
          [-91.3999, 36.2385],
          [-91.441, 36.2266],
          [-91.4086, 36.1474],
          [-91.4036, 36.0979],
          [-91.4263, 36.0677],
          [-91.3954, 36.0336],
          [-91.3832, 35.9455],
          [-91.4241, 35.9209],
          [-91.4053, 35.8913],
          [-91.3572, 35.8906],
          [-91.3294, 35.9124],
          [-91.2032, 35.9192],
          [-91.1988, 35.8863],
          [-91.2389, 35.8191],
          [-91.2881, 35.7798],
          [-91.2927, 35.7495],
          [-91.245, 35.7488],
          [-91.2364, 35.6757],
          [-91.1384, 35.7254],
          [-91.0366, 35.7231],
          [-91.0381, 35.655],
          [-91.0741, 35.62],
          [-91.0742, 35.561],
          [-91.0389, 35.5458],
          [-91.0392, 35.4943],
          [-91.074, 35.4942],
          [-91.0917, 35.4287],
          [-91.0398, 35.3543],
          [-91.0453, 35.0009],
          [-91.1498, 35.0025],
          [-91.1524, 34.9119],
          [-91.1166, 34.9118],
          [-91.0998, 34.8673],
          [-91.1024, 34.7349],
          [-91.0493, 34.7341],
          [-91.0548, 34.4709],
          [-91.0025, 34.3745],
          [-91.0025, 34.3363],
          [-91.0563, 34.3366],
          [-91.0731, 34.2958],
          [-91.0631, 34.2542],
          [-91.0705, 34.2014],
          [-91.0938, 34.1777],
          [-91.0846, 34.1463],
          [-91.1479, 34.0888],
          [-91.1837, 34.0837],
          [-91.1969, 34.0494],
          [-91.1558, 33.9764],
          [-91.079, 33.9542],
          [-91.0108, 33.9256],
          [-91.073, 33.8574],
          [-91.0468, 33.8154],
          [-91.0001, 33.7995],
          [-91.0268, 33.7636],
          [-91.0539, 33.7787],
          [-91.1073, 33.7706],
          [-91.1447, 33.7268],
          [-91.1177, 33.7053],
          [-91.0683, 33.7165],
          [-91.0346, 33.673],
          [-91.0841, 33.6573],
          [-91.1304, 33.6745],
          [-91.1609, 33.7071],
          [-91.2206, 33.6929],
          [-91.219, 33.6615],
          [-91.1392, 33.6257],
          [-91.1316, 33.5994],
          [-91.2241, 33.5674],
          [-91.2323, 33.5528],
          [-91.1856, 33.4964],
          [-91.2352, 33.439],
          [-91.1694, 33.4526],
          [-91.1319, 33.4301],
          [-91.209, 33.4036],
          [-91.1663, 33.3797],
          [-91.0582, 33.4287],
          [-91.1422, 33.349],
          [-91.1416, 33.2995],
          [-91.1001, 33.2381],
          [-91.0785, 33.2833],
          [-91.0436, 33.2746],
          [-91.0917, 33.2208],
          [-91.0876, 33.1452],
          [-91.1043, 33.1316],
          [-91.1837, 33.1417],
          [-91.2015, 33.1096],
          [-91.1246, 33.0641],
          [-91.1257, 33.0383],
          [-91.1661, 33.0041],
          [-91.5001, 33.0068],
          [-91.952, 33.0074],
          [-92.2009, 33.0089],
          [-92.692, 33.0141],
          [-93.0707, 33.0178],
          [-93.3922, 33.0183],
          [-93.3679, 33.0467],
          [-93.3659, 33.0959],
          [-93.3971, 33.1205],
          [-93.3913, 33.2052],
          [-93.442, 33.2594],
          [-93.4291, 33.3158],
          [-93.3655, 33.4445],
          [-93.239, 33.4419],
          [-93.2414, 33.4699],
          [-93.1645, 33.5052],
          [-93.1131, 33.5389],
          [-93.1082, 33.6685],
          [-93.14, 33.6576],
          [-93.1814, 33.7123],
          [-93.1065, 33.7191],
          [-93.1043, 33.7773],
          [-93.1427, 33.8146],
          [-93.167, 33.8047],
          [-93.2201, 33.829],
          [-93.2894, 33.8441],
          [-93.3247, 33.9178],
          [-93.373, 33.9548],
          [-93.468, 33.9643],
          [-93.5289, 33.9436],
          [-93.6341, 33.9945],
          [-93.6668, 34.0221],
          [-93.7093, 34.0212],
          [-93.7694, 34.1082],
          [-93.8138, 34.1335],
          [-93.8207, 34.1838],
          [-93.95, 34.1828],
          [-93.9856, 34.2541],
          [-94.0313, 34.2758],
          [-94.064, 34.2508],
          [-94.1185, 34.2401],
          [-94.1724, 34.286],
          [-94.1666, 34.3534],
          [-94.2547, 34.3555],
          [-94.2561, 34.2693],
          [-94.244, 34.2112],
          [-94.308, 34.2111],
          [-94.3336, 34.191],
          [-94.4703, 34.1899],
          [-94.4664, 34.3329],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Tucson Mkt": {
    type: "Feature",
    properties: { code: "AZ_TUC", name: "Tucson Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-112.4656, 31.932],
          [-112.4627, 31.9469],
          [-112.4423, 31.9661],
          [-112.4349, 32.1818],
          [-112.3812, 32.3192],
          [-112.3129, 32.352],
          [-112.2469, 32.3638],
          [-112.2219, 32.3913],
          [-112.2144, 32.386],
          [-112.18, 32.4253],
          [-112.1794, 32.4519],
          [-112.1955, 32.4574],
          [-112.185, 32.4669],
          [-112.2161, 32.5068],
          [-111.5693, 32.5068],
          [-111.5693, 32.5014],
          [-111.3384, 32.5013],
          [-111.3126, 32.5016],
          [-111.3278, 32.5191],
          [-111.2979, 32.506],
          [-111.3042, 32.5012],
          [-111.2664, 32.5014],
          [-111.3374, 32.5918],
          [-111.2067, 32.7],
          [-111.1553, 32.685],
          [-111.1493, 32.6953],
          [-111.1178, 32.7004],
          [-111.0892, 32.6966],
          [-111.075, 32.6854],
          [-111.0509, 32.6854],
          [-111.055, 32.6741],
          [-111.0635, 32.6745],
          [-111.0588, 32.6704],
          [-111.0017, 32.6603],
          [-110.9613, 32.6733],
          [-110.9276, 32.704],
          [-110.8944, 32.7105],
          [-110.8877, 32.7219],
          [-110.8984, 32.7538],
          [-110.8807, 32.751],
          [-110.8369, 32.7179],
          [-110.8059, 32.7681],
          [-110.7952, 32.7702],
          [-110.7691, 32.7609],
          [-110.7543, 32.7636],
          [-110.7422, 32.7509],
          [-110.7104, 32.7406],
          [-110.6692, 32.7601],
          [-110.6549, 32.7345],
          [-110.6443, 32.7492],
          [-110.6035, 32.6823],
          [-110.5844, 32.6926],
          [-110.5408, 32.6973],
          [-110.4975, 32.7363],
          [-110.4789, 32.7266],
          [-110.4733, 32.7317],
          [-110.4543, 32.7272],
          [-110.4486, 32.73],
          [-110.4494, 32.95],
          [-110.4467, 32.9473],
          [-110.4341, 32.95],
          [-110.4353, 32.9302],
          [-110.4182, 32.9358],
          [-110.4132, 32.9295],
          [-110.3999, 32.9447],
          [-110.3587, 32.9431],
          [-110.3495, 32.9491],
          [-110.3539, 32.9634],
          [-110.3427, 32.9567],
          [-110.3281, 32.9651],
          [-110.3359, 32.9863],
          [-110.3192, 32.9832],
          [-110.2815, 32.9888],
          [-110.2302, 32.9895],
          [-110.1427, 32.9595],
          [-110.0561, 32.9675],
          [-110.0547, 32.878],
          [-110.0402, 32.8784],
          [-110.0391, 32.8691],
          [-110.0521, 32.8634],
          [-110.0554, 32.8525],
          [-110.0431, 32.8211],
          [-110.0684, 32.7611],
          [-110.0411, 32.746],
          [-110.0406, 32.7361],
          [-110.0234, 32.7417],
          [-109.9626, 32.7118],
          [-109.9404, 32.7164],
          [-109.9239, 32.699],
          [-109.914, 32.7049],
          [-109.8313, 32.6226],
          [-109.8469, 32.5966],
          [-109.8546, 32.5918],
          [-109.8746, 32.5977],
          [-109.9092, 32.5759],
          [-109.9169, 32.5877],
          [-109.9505, 32.5819],
          [-109.9734, 32.5872],
          [-109.9734, 32.529],
          [-109.9454, 32.5385],
          [-109.9362, 32.5283],
          [-109.9304, 32.5436],
          [-109.9052, 32.491],
          [-109.9093, 32.4847],
          [-109.9276, 32.4853],
          [-109.9269, 32.4269],
          [-109.432, 32.4276],
          [-109.3594, 32.4264],
          [-109.3475, 32.4262],
          [-109.2278, 32.4245],
          [-109.2272, 32.4265],
          [-109.114, 32.4262],
          [-109.0476, 32.4264],
          [-109.0483, 32.0891],
          [-109.0491, 31.6342],
          [-109.0492, 31.621],
          [-109.0498, 31.5119],
          [-109.0502, 31.48],
          [-109.05, 31.3325],
          [-109.2785, 31.334],
          [-109.5614, 31.334],
          [-110.0006, 31.3331],
          [-110.4602, 31.3328],
          [-111.0748, 31.3322],
          [-111.6231, 31.508],
          [-111.9794, 31.6207],
          [-112.3881, 31.748],
          [-112.4055, 31.8569],
          [-112.4313, 31.8585],
          [-112.4656, 31.932],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "San Diego Mkt": {
    type: "Feature",
    properties: { code: "CA_SDI", name: "San Diego Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-117.5097, 33.505],
          [-117.5037, 33.505],
          [-117.4782, 33.505],
          [-117.3643, 33.505],
          [-117.3708, 33.4907],
          [-117.2417, 33.4489],
          [-117.2415, 33.4319],
          [-117.0309, 33.4269],
          [-116.5008, 33.4268],
          [-116.1976, 33.4289],
          [-116.0852, 33.4259],
          [-116.0819, 33.2288],
          [-116.0008, 33.2147],
          [-116.0008, 33.2286],
          [-115.9325, 33.243],
          [-115.9043, 33.2869],
          [-115.8839, 33.2781],
          [-115.8862, 33.2588],
          [-115.8598, 33.2424],
          [-115.8341, 33.1925],
          [-115.8272, 33.1624],
          [-115.8391, 33.1313],
          [-115.8311, 33.1253],
          [-115.8134, 33.1319],
          [-115.8219, 33.1189],
          [-115.8135, 33.1188],
          [-115.8135, 33.1052],
          [-115.8287, 33.1073],
          [-115.732, 33.0085],
          [-115.6688, 32.9263],
          [-115.6956, 32.9263],
          [-115.7092, 32.9155],
          [-115.7181, 32.8809],
          [-115.755, 32.8861],
          [-115.9102, 32.9605],
          [-115.9536, 32.9862],
          [-116.0381, 33.0332],
          [-116.0794, 33.042],
          [-116.1032, 33.0345],
          [-116.1031, 32.7265],
          [-116.1062, 32.6186],
          [-116.627, 32.5763],
          [-117.1249, 32.5342],
          [-117.1395, 32.6271],
          [-117.1968, 32.6889],
          [-117.2461, 32.6694],
          [-117.2611, 32.8031],
          [-117.2822, 32.8395],
          [-117.2517, 32.8743],
          [-117.2808, 33.0123],
          [-117.3284, 33.1218],
          [-117.4456, 33.2685],
          [-117.5057, 33.3341],
          [-117.5959, 33.3866],
          [-117.5785, 33.4539],
          [-117.5384, 33.4557],
          [-117.5086, 33.4697],
          [-117.5097, 33.505],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Denver Mkt": {
    type: "Feature",
    properties: { code: "CO_DEN", name: "Denver Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-107.0304, 41.0034],
          [-106.4396, 41.002],
          [-106.3212, 40.9991],
          [-106.3229, 41.3939],
          [-106.3617, 41.4649],
          [-106.3467, 41.5157],
          [-106.3036, 41.535],
          [-106.2848, 41.576],
          [-106.2328, 41.6172],
          [-106.2743, 41.6285],
          [-106.2441, 41.6702],
          [-106.243, 41.713],
          [-106.2176, 41.7407],
          [-106.1621, 41.7235],
          [-106.1438, 41.7478],
          [-106.0729, 41.7717],
          [-106.0732, 41.9831],
          [-105.8636, 42.017],
          [-105.8085, 42.0421],
          [-105.7691, 42.0422],
          [-105.7114, 42.278],
          [-105.6987, 42.4311],
          [-105.556, 42.4306],
          [-105.5756, 42.3773],
          [-105.6147, 42.3774],
          [-105.6139, 42.3045],
          [-105.5264, 42.2915],
          [-105.4956, 42.2523],
          [-105.5493, 42.1703],
          [-105.6122, 42.1265],
          [-105.589, 42.0885],
          [-105.5131, 42.0885],
          [-105.4822, 42.058],
          [-105.5695, 41.9809],
          [-105.5704, 41.9028],
          [-105.5601, 41.8305],
          [-105.566, 41.7863],
          [-105.5245, 41.7657],
          [-105.4828, 41.7699],
          [-105.4446, 41.743],
          [-105.5059, 41.6997],
          [-105.5219, 41.6671],
          [-105.4215, 41.6246],
          [-105.376, 41.6733],
          [-105.3032, 41.6943],
          [-105.2782, 41.6567],
          [-104.6533, 41.653],
          [-104.653, 41.565],
          [-104.0525, 41.5643],
          [-104.0532, 41.1979],
          [-103.9574, 41.2062],
          [-103.9762, 41.1748],
          [-103.962, 41.138],
          [-103.9885, 41.1053],
          [-104.0531, 41.105],
          [-104.0532, 41.0014],
          [-104.6222, 41.0012],
          [-104.6001, 40.9928],
          [-104.6009, 40.8697],
          [-104.542, 40.8566],
          [-104.3345, 40.8574],
          [-104.3348, 40.7548],
          [-104.2591, 40.7559],
          [-104.2399, 40.7841],
          [-104.1606, 40.7835],
          [-104.0669, 40.695],
          [-104.0697, 40.5679],
          [-104.0318, 40.5535],
          [-104.0324, 40.5243],
          [-103.9509, 40.5239],
          [-103.9562, 40.5035],
          [-103.8759, 40.4352],
          [-103.848, 40.3198],
          [-103.9227, 40.3268],
          [-103.9995, 40.2484],
          [-103.9996, 40.1891],
          [-103.9809, 40.1785],
          [-103.9809, 40.0736],
          [-103.9467, 40.0011],
          [-104.0389, 40.0012],
          [-104.0383, 39.9716],
          [-103.9783, 39.972],
          [-103.986, 39.8683],
          [-103.9808, 39.8264],
          [-103.9239, 39.8263],
          [-103.9246, 39.7977],
          [-103.7979, 39.7982],
          [-103.7945, 39.8519],
          [-103.7475, 39.8685],
          [-103.7065, 39.8575],
          [-103.7071, 39.5663],
          [-103.598, 39.5661],
          [-103.5934, 39.6672],
          [-103.4255, 39.6659],
          [-103.4244, 39.566],
          [-103.2744, 39.5664],
          [-103.2735, 39.682],
          [-103.2923, 39.7109],
          [-103.2931, 39.8128],
          [-103.219, 39.8142],
          [-103.2185, 39.7703],
          [-103.1427, 39.7708],
          [-103.1044, 39.7422],
          [-103.076, 39.7861],
          [-103.0287, 39.7721],
          [-103.0099, 39.8013],
          [-102.9723, 39.8009],
          [-102.9724, 39.7426],
          [-102.9349, 39.7276],
          [-102.8587, 39.7278],
          [-102.8393, 39.7571],
          [-102.8389, 39.801],
          [-102.8009, 39.8014],
          [-102.8001, 39.6881],
          [-102.6323, 39.723],
          [-102.6509, 39.7581],
          [-102.6512, 39.8305],
          [-102.6142, 39.831],
          [-102.5947, 39.7586],
          [-102.5153, 39.7515],
          [-102.4633, 39.73],
          [-102.4628, 39.7012],
          [-102.4058, 39.7015],
          [-102.3482, 39.6872],
          [-102.3439, 39.6071],
          [-102.2796, 39.612],
          [-102.1739, 39.6024],
          [-102.1375, 39.5877],
          [-102.0815, 39.5882],
          [-102.0821, 39.6316],
          [-102.0635, 39.6443],
          [-102.0597, 39.6452],
          [-102.0504, 39.646],
          [-102.0466, 39.047],
          [-102.0453, 38.4536],
          [-102.0444, 38.2509],
          [-102.0443, 38.113],
          [-102.0446, 38.0546],
          [-102.0446, 38.04],
          [-102.0438, 37.9261],
          [-102.0433, 37.8785],
          [-102.043, 37.8035],
          [-102.0416, 37.6804],
          [-102.0416, 37.3892],
          [-102.042, 37.2582],
          [-102.042, 37.1939],
          [-102.042, 37.1417],
          [-102.0421, 37.125],
          [-102.042, 37.1226],
          [-102.0418, 37.112],
          [-102.042, 37.1066],
          [-102.0419, 37.0351],
          [-102.042, 37.0247],
          [-102.0422, 36.9931],
          [-102.6981, 36.9951],
          [-102.7421, 36.9977],
          [-102.7599, 37],
          [-103.0022, 37.0001],
          [-103.4257, 36.9992],
          [-104.0079, 36.9962],
          [-104.3388, 36.9935],
          [-105.0006, 36.9933],
          [-105.155, 36.9953],
          [-105.1542, 37.2931],
          [-105.169, 37.375],
          [-105.1386, 37.4007],
          [-105.1327, 37.4036],
          [-105.1297, 37.4093],
          [-105.1313, 37.4115],
          [-105.136, 37.4125],
          [-105.1394, 37.4156],
          [-105.141, 37.4286],
          [-105.1522, 37.4516],
          [-105.1755, 37.4565],
          [-105.1767, 37.4756],
          [-105.1748, 37.4852],
          [-105.1757, 37.4895],
          [-105.174, 37.4961],
          [-105.1733, 37.5105],
          [-105.1716, 37.5181],
          [-105.1838, 37.5552],
          [-105.2183, 37.5824],
          [-105.1844, 37.616],
          [-105.2261, 37.6204],
          [-105.2987, 37.6566],
          [-105.3705, 37.6386],
          [-105.3866, 37.6264],
          [-105.3898, 37.6218],
          [-105.3914, 37.6184],
          [-105.3999, 37.6142],
          [-105.4011, 37.6143],
          [-105.4084, 37.618],
          [-105.4423, 37.6119],
          [-105.4947, 37.5855],
          [-105.5013, 37.5986],
          [-105.487, 37.6535],
          [-105.4814, 37.6592],
          [-105.479, 37.6639],
          [-105.478, 37.6677],
          [-105.4769, 37.6758],
          [-105.4767, 37.6799],
          [-105.4777, 37.6846],
          [-105.4813, 37.6932],
          [-105.4775, 37.7076],
          [-105.4665, 37.7119],
          [-105.4572, 37.7191],
          [-105.4537, 37.7969],
          [-105.4287, 37.8437],
          [-105.4407, 37.8696],
          [-105.4732, 37.896],
          [-105.4403, 37.8975],
          [-105.4187, 37.8897],
          [-105.3245, 37.9384],
          [-105.3071, 37.9325],
          [-105.2805, 37.9012],
          [-105.2498, 37.9515],
          [-105.1988, 37.9902],
          [-105.1989, 38.0038],
          [-105.1371, 38.0392],
          [-105.1333, 38.0641],
          [-105.0965, 38.0669],
          [-105.081, 38.028],
          [-105.0498, 38.0178],
          [-105.0492, 38.258],
          [-104.9404, 38.2583],
          [-104.9419, 38.5681],
          [-104.9506, 38.5528],
          [-104.9615, 38.5727],
          [-105.0012, 38.5652],
          [-105.0122, 38.5703],
          [-105.0219, 38.5613],
          [-105.0117, 38.5766],
          [-105.0547, 38.6529],
          [-105.2404, 38.6476],
          [-105.2378, 38.6969],
          [-105.6674, 38.6948],
          [-105.828, 38.6943],
          [-105.8427, 38.6941],
          [-105.8498, 38.6939],
          [-105.8816, 38.6941],
          [-105.8892, 38.6951],
          [-105.8984, 38.6938],
          [-105.9445, 38.6931],
          [-105.9698, 38.6936],
          [-105.9435, 38.7231],
          [-105.9425, 38.7685],
          [-105.9295, 38.7744],
          [-105.9079, 38.8063],
          [-105.9429, 38.8505],
          [-105.9253, 38.8718],
          [-105.9429, 38.8855],
          [-105.9743, 38.8825],
          [-105.9794, 38.8945],
          [-105.9682, 38.9078],
          [-105.9857, 38.918],
          [-105.9982, 38.9152],
          [-106.0281, 38.9428],
          [-106.0659, 38.9319],
          [-106.111, 38.9401],
          [-106.1095, 38.9859],
          [-106.1454, 38.9979],
          [-106.1691, 38.9913],
          [-106.1788, 39.0108],
          [-106.193, 39.0177],
          [-106.1825, 39.0386],
          [-106.191, 39.0561],
          [-106.2713, 39.0564],
          [-106.3145, 39.129],
          [-106.3199, 39.1642],
          [-106.3539, 39.158],
          [-106.3578, 39.1492],
          [-106.3341, 39.1438],
          [-106.3495, 39.1291],
          [-106.3301, 39.1271],
          [-106.3185, 39.1061],
          [-106.3488, 39.107],
          [-106.3672, 39.0999],
          [-106.4006, 39.065],
          [-106.4108, 39.0668],
          [-106.4059, 39.0618],
          [-106.3818, 39.0751],
          [-106.3737, 39.0708],
          [-106.3537, 39.0789],
          [-106.3173, 39.0738],
          [-106.3121, 39.0802],
          [-106.3041, 39.0747],
          [-106.3138, 39.0692],
          [-106.2909, 39.0642],
          [-106.2926, 39.0564],
          [-106.578, 39.0579],
          [-106.5633, 39.1073],
          [-106.5613, 39.1557],
          [-106.5111, 39.1635],
          [-106.4938, 39.1986],
          [-106.4897, 39.228],
          [-106.4987, 39.2349],
          [-106.484, 39.255],
          [-106.483, 39.2724],
          [-106.504, 39.2848],
          [-106.5016, 39.2998],
          [-106.4841, 39.3129],
          [-106.4687, 39.3079],
          [-106.4325, 39.3164],
          [-106.4335, 39.3542],
          [-106.4001, 39.381],
          [-106.3806, 39.3762],
          [-106.3708, 39.3606],
          [-106.3517, 39.3537],
          [-106.3124, 39.3623],
          [-106.2832, 39.3505],
          [-106.2395, 39.3657],
          [-106.2256, 39.3796],
          [-106.2067, 39.3796],
          [-106.2304, 39.417],
          [-106.2327, 39.4262],
          [-106.2516, 39.465],
          [-106.2456, 39.4897],
          [-106.2596, 39.495],
          [-106.2464, 39.5128],
          [-106.2558, 39.5322],
          [-106.2372, 39.5485],
          [-106.2249, 39.5314],
          [-106.2121, 39.5307],
          [-106.2099, 39.5469],
          [-106.1963, 39.5569],
          [-106.2057, 39.5582],
          [-106.2149, 39.5748],
          [-106.209, 39.5994],
          [-106.1776, 39.6091],
          [-106.1761, 39.634],
          [-106.1838, 39.6522],
          [-106.2348, 39.662],
          [-106.2387, 39.6803],
          [-106.2586, 39.68],
          [-106.2641, 39.7013],
          [-106.2903, 39.7036],
          [-106.2983, 39.7206],
          [-106.3185, 39.7287],
          [-106.3413, 39.7523],
          [-106.394, 39.7194],
          [-106.4375, 39.7089],
          [-106.452, 39.7166],
          [-106.4645, 39.7434],
          [-106.4882, 39.766],
          [-106.5236, 39.7795],
          [-106.5417, 39.7786],
          [-106.553, 39.7917],
          [-106.5892, 39.8005],
          [-106.6322, 39.8265],
          [-106.6748, 39.8145],
          [-106.6834, 39.7741],
          [-106.6712, 39.7532],
          [-106.679, 39.7371],
          [-106.6954, 39.7438],
          [-106.7234, 39.7367],
          [-106.7397, 39.74],
          [-106.754, 39.7326],
          [-106.7631, 39.7144],
          [-106.7845, 39.7189],
          [-106.8108, 39.7491],
          [-106.826, 39.7461],
          [-106.83, 39.7372],
          [-106.8472, 39.7482],
          [-106.8581, 39.7401],
          [-106.9034, 39.7857],
          [-106.9128, 39.778],
          [-106.9954, 39.8118],
          [-107.0257, 39.849],
          [-107.0565, 39.8637],
          [-107.0579, 39.8719],
          [-107.093, 39.8737],
          [-107.1135, 39.8668],
          [-107.1137, 39.9191],
          [-107.0318, 39.9189],
          [-107.0374, 40.0031],
          [-107.0379, 40.2253],
          [-107.1014, 40.2251],
          [-107.0957, 40.2407],
          [-107.1645, 40.2808],
          [-107.1554, 40.3298],
          [-107.1052, 40.3556],
          [-107.1378, 40.3887],
          [-107.1468, 40.4105],
          [-107.1671, 40.4161],
          [-107.1719, 40.4261],
          [-107.1646, 40.4365],
          [-107.2003, 40.4379],
          [-107.1676, 40.469],
          [-107.1699, 40.4882],
          [-107.1549, 40.4881],
          [-107.1834, 40.5283],
          [-107.2212, 40.522],
          [-107.2221, 40.5411],
          [-107.2085, 40.5841],
          [-107.1721, 40.6084],
          [-107.1703, 40.6389],
          [-107.1484, 40.6594],
          [-107.1374, 40.6911],
          [-107.1194, 40.7031],
          [-107.1235, 40.7349],
          [-107.1348, 40.7434],
          [-107.1231, 40.8072],
          [-107.1372, 40.8384],
          [-107.1289, 40.8457],
          [-107.0938, 40.8465],
          [-107.0927, 40.853],
          [-107.1194, 40.873],
          [-107.1034, 40.9138],
          [-107.1075, 40.9349],
          [-107.055, 40.9696],
          [-107.0536, 40.988],
          [-107.0304, 41.0034],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Hartford Mkt": {
    type: "Feature",
    properties: { code: "CT_HAR", name: "Hartford Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-73.4873, 42.0496],
          [-73.4328, 42.0506],
          [-72.9995, 42.0387],
          [-72.8135, 42.0365],
          [-72.8167, 41.9976],
          [-72.7667, 42.003],
          [-72.7517, 42.0302],
          [-72.6959, 42.0368],
          [-72.1988, 42.031],
          [-71.8007, 42.0236],
          [-71.787, 41.656],
          [-71.7977, 41.4167],
          [-71.8396, 41.4121],
          [-71.8312, 41.3446],
          [-71.8596, 41.3224],
          [-71.9457, 41.3378],
          [-72.0219, 41.3168],
          [-72.0845, 41.3196],
          [-72.1342, 41.2994],
          [-72.1776, 41.3225],
          [-72.204, 41.2991],
          [-72.3866, 41.2618],
          [-72.4519, 41.2789],
          [-72.5472, 41.2505],
          [-72.5711, 41.2681],
          [-72.6723, 41.267],
          [-72.6947, 41.245],
          [-72.7544, 41.2669],
          [-72.8814, 41.2426],
          [-72.9082, 41.2829],
          [-72.962, 41.2516],
          [-73.0149, 41.2043],
          [-73.0507, 41.2102],
          [-73.1303, 41.1468],
          [-73.1778, 41.1667],
          [-73.2624, 41.1175],
          [-73.2868, 41.1279],
          [-73.3723, 41.104],
          [-73.4351, 41.0567],
          [-73.4933, 41.0482],
          [-73.5677, 41.0109],
          [-73.6435, 41.0022],
          [-73.6865, 40.9452],
          [-73.7568, 40.9126],
          [-73.7836, 40.881],
          [-73.9179, 40.9176],
          [-73.894, 40.9972],
          [-73.9027, 40.9973],
          [-74.302, 41.1726],
          [-74.6073, 41.3178],
          [-74.5612, 41.3631],
          [-74.5634, 41.4192],
          [-74.5932, 41.4313],
          [-74.5556, 41.5013],
          [-74.4688, 41.5138],
          [-74.4028, 41.55],
          [-74.2871, 41.5231],
          [-74.2765, 41.4866],
          [-74.2023, 41.4809],
          [-74.1888, 41.4427],
          [-74.1419, 41.4511],
          [-74.1198, 41.4018],
          [-74.0442, 41.3566],
          [-73.9652, 41.4139],
          [-73.9815, 41.4389],
          [-73.9338, 41.4883],
          [-73.6837, 41.5133],
          [-73.6548, 41.4839],
          [-73.5341, 41.4789],
          [-73.4873, 42.0496],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Jacksonville Mkt": {
    type: "Feature",
    properties: { code: "FL_JAX", name: "Jacksonville Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-82.0162, 28.9601],
            [-81.9451, 28.9601],
            [-81.954, 28.972],
            [-81.9501, 28.9819],
            [-81.9625, 28.9856],
            [-81.9374, 28.9892],
            [-81.9539, 28.9951],
            [-81.9764, 28.9887],
            [-81.9766, 29.0003],
            [-81.9647, 29.0016],
            [-81.9767, 29.0062],
            [-81.9666, 29.0062],
            [-81.9881, 29.0147],
            [-81.9749, 29.0275],
            [-81.983, 29.0326],
            [-81.9625, 29.0348],
            [-81.9717, 29.0588],
            [-81.9522, 29.0678],
            [-81.9717, 29.0923],
            [-81.9869, 29.095],
            [-81.9906, 29.1207],
            [-81.9842, 29.1047],
            [-81.9675, 29.1024],
            [-81.9652, 29.1077],
            [-81.9888, 29.142],
            [-81.9826, 29.1592],
            [-81.9928, 29.1835],
            [-81.9153, 29.1531],
            [-81.8979, 29.1587],
            [-81.8905, 29.1832],
            [-81.8716, 29.1754],
            [-81.8726, 29.1678],
            [-81.8641, 29.1717],
            [-81.8573, 29.1598],
            [-81.8599, 29.1718],
            [-81.8442, 29.1648],
            [-81.8364, 29.1762],
            [-81.7584, 29.1667],
            [-81.7584, 29.1519],
            [-81.7719, 29.1516],
            [-81.7247, 29.0502],
            [-81.7449, 29.0501],
            [-81.7489, 29.0292],
            [-81.7596, 29.0192],
            [-81.7821, 29.019],
            [-81.775, 29.0291],
            [-81.7898, 29.0288],
            [-81.7805, 29.0368],
            [-81.7907, 29.038],
            [-81.7939, 29.0501],
            [-81.8349, 29.0376],
            [-81.8293, 29.0167],
            [-81.8083, 29.0083],
            [-81.8076, 28.9966],
            [-81.836, 28.9913],
            [-81.8429, 28.9685],
            [-81.8319, 28.934],
            [-81.8398, 28.9253],
            [-81.8392, 28.894],
            [-81.937, 28.8869],
            [-81.937, 28.916],
            [-82.0163, 28.9273],
            [-82.0162, 28.9601],
          ],
        ],
        [
          [
            [-83.3095, 30.6344],
            [-82.6986, 30.5982],
            [-82.4189, 30.5817],
            [-82.2148, 30.5686],
            [-82.2356, 30.5449],
            [-82.2014, 30.4852],
            [-82.2103, 30.4246],
            [-82.1701, 30.3589],
            [-82.1048, 30.3683],
            [-82.0479, 30.3633],
            [-82.0372, 30.4345],
            [-82.0178, 30.4751],
            [-82.0055, 30.5635],
            [-82.0461, 30.6518],
            [-82.0396, 30.7477],
            [-81.9813, 30.7768],
            [-81.9498, 30.8275],
            [-81.8929, 30.8193],
            [-81.8686, 30.7928],
            [-81.8085, 30.79],
            [-81.7199, 30.7446],
            [-81.6948, 30.7484],
            [-81.5409, 30.7133],
            [-81.4895, 30.7261],
            [-81.4441, 30.7097],
            [-81.4274, 30.698],
            [-81.4431, 30.6009],
            [-81.4342, 30.5224],
            [-81.4108, 30.482],
            [-81.3974, 30.4006],
            [-81.3855, 30.2738],
            [-81.309, 29.9694],
            [-81.2647, 29.8582],
            [-81.2567, 29.7847],
            [-81.1636, 29.5553],
            [-81.0448, 29.304],
            [-80.968, 29.1516],
            [-80.9073, 29.0643],
            [-80.8783, 29.0106],
            [-80.7322, 28.7912],
            [-80.7883, 28.7911],
            [-80.8533, 28.9008],
            [-80.8739, 28.9007],
            [-80.8773, 28.9108],
            [-80.8752, 28.9007],
            [-80.8841, 28.8997],
            [-80.8704, 28.8644],
            [-80.9138, 28.864],
            [-80.9126, 28.8322],
            [-80.9386, 28.8164],
            [-80.9647, 28.8117],
            [-80.9899, 28.8221],
            [-80.9904, 28.8419],
            [-81.0225, 28.8441],
            [-81.0172, 28.8602],
            [-81.0263, 28.8759],
            [-81.0498, 28.8833],
            [-81.0587, 28.8772],
            [-81.0692, 28.8841],
            [-81.0696, 28.9243],
            [-81.0791, 28.9374],
            [-81.0914, 28.9422],
            [-81.0795, 28.9264],
            [-81.0984, 28.9227],
            [-81.1006, 28.9123],
            [-81.1101, 28.9323],
            [-81.1342, 28.8999],
            [-81.1429, 28.9056],
            [-81.1614, 28.9309],
            [-81.1648, 28.9646],
            [-81.1853, 28.9801],
            [-81.1979, 29.01],
            [-81.2061, 29.0715],
            [-81.2273, 29.0924],
            [-81.2131, 29.1022],
            [-81.2276, 29.1207],
            [-81.2159, 29.1247],
            [-81.2288, 29.1598],
            [-81.2035, 29.1633],
            [-81.2052, 29.1843],
            [-81.2449, 29.1804],
            [-81.2641, 29.1689],
            [-81.2701, 29.1615],
            [-81.2556, 29.1237],
            [-81.2614, 29.1181],
            [-81.2772, 29.1272],
            [-81.2865, 29.1211],
            [-81.2899, 29.1304],
            [-81.3338, 29.1145],
            [-81.3426, 29.1185],
            [-81.3489, 29.1116],
            [-81.3545, 29.1065],
            [-81.3638, 29.1081],
            [-81.3687, 29.1066],
            [-81.3753, 29.1067],
            [-81.3762, 29.1093],
            [-81.3824, 29.0984],
            [-81.3774, 29.0618],
            [-81.406, 29.0816],
            [-81.4039, 29.0894],
            [-81.4036, 29.1098],
            [-81.4006, 29.1172],
            [-81.4036, 29.1197],
            [-81.4045, 29.1224],
            [-81.4036, 29.1259],
            [-81.3977, 29.1263],
            [-81.3931, 29.1299],
            [-81.385, 29.1331],
            [-81.3741, 29.1342],
            [-81.3735, 29.1321],
            [-81.3755, 29.1275],
            [-81.3764, 29.1228],
            [-81.3739, 29.1164],
            [-81.3748, 29.1124],
            [-81.3726, 29.1085],
            [-81.3695, 29.1098],
            [-81.3683, 29.1197],
            [-81.3701, 29.1253],
            [-81.3699, 29.1329],
            [-81.3652, 29.1363],
            [-81.3927, 29.1335],
            [-81.4036, 29.1269],
            [-81.4047, 29.1218],
            [-81.4034, 29.1189],
            [-81.4008, 29.1169],
            [-81.4047, 29.112],
            [-81.4229, 29.109],
            [-81.4339, 29.115],
            [-81.4411, 29.1076],
            [-81.5026, 29.1065],
            [-81.505, 29.0985],
            [-81.5046, 29.0904],
            [-81.5012, 29.0867],
            [-81.4969, 29.085],
            [-81.4947, 29.0872],
            [-81.4852, 29.0893],
            [-81.4909, 29.0883],
            [-81.4972, 29.0908],
            [-81.5024, 29.0944],
            [-81.5037, 29.0987],
            [-81.5022, 29.0966],
            [-81.487, 29.0924],
            [-81.4792, 29.0935],
            [-81.4779, 29.0875],
            [-81.4808, 29.0712],
            [-81.509, 29.0455],
            [-81.5201, 29.0564],
            [-81.5644, 29.068],
            [-81.5684, 29.0861],
            [-81.6215, 29.0965],
            [-81.6179, 29.117],
            [-81.6261, 29.108],
            [-81.6286, 29.1161],
            [-81.6188, 29.1231],
            [-81.6409, 29.165],
            [-81.6477, 29.2447],
            [-81.6895, 29.253],
            [-81.7271, 29.2483],
            [-81.727, 29.269],
            [-81.8199, 29.2683],
            [-81.8316, 29.2971],
            [-81.8732, 29.2763],
            [-81.9001, 29.2866],
            [-81.9048, 29.2838],
            [-81.8977, 29.2813],
            [-81.9156, 29.2811],
            [-81.9146, 29.2922],
            [-81.8891, 29.2935],
            [-81.8802, 29.3279],
            [-81.8856, 29.3392],
            [-81.9214, 29.2876],
            [-81.9655, 29.2533],
            [-81.9671, 29.239],
            [-81.9866, 29.2149],
            [-82.0162, 29.2179],
            [-81.9818, 29.2814],
            [-82.04, 29.2794],
            [-82.0539, 29.2849],
            [-82.054, 29.2415],
            [-82.0712, 29.2586],
            [-82.0941, 29.2594],
            [-82.103, 29.2775],
            [-82.1149, 29.2777],
            [-82.1159, 29.2886],
            [-82.1237, 29.2886],
            [-82.1157, 29.2957],
            [-82.1362, 29.2959],
            [-82.1347, 29.3229],
            [-82.1517, 29.3229],
            [-82.154, 29.338],
            [-82.1594, 29.3309],
            [-82.1917, 29.3306],
            [-82.2046, 29.3124],
            [-82.1952, 29.296],
            [-82.2038, 29.2962],
            [-82.2123, 29.3232],
            [-82.2221, 29.3197],
            [-82.2416, 29.3093],
            [-82.251, 29.2965],
            [-82.2511, 29.2672],
            [-82.2676, 29.2723],
            [-82.2926, 29.2963],
            [-82.2916, 29.3064],
            [-82.3299, 29.2934],
            [-82.3496, 29.2966],
            [-82.3428, 29.2459],
            [-82.383, 29.2424],
            [-82.3832, 29.2348],
            [-82.4037, 29.2424],
            [-82.4032, 29.2156],
            [-82.536, 29.2147],
            [-82.5359, 29.2061],
            [-82.5416, 29.2148],
            [-82.5696, 29.2148],
            [-82.5936, 29.2074],
            [-82.5996, 29.1772],
            [-82.6098, 29.1818],
            [-82.6365, 29.1634],
            [-82.6378, 29.1968],
            [-82.6584, 29.2306],
            [-82.6493, 29.232],
            [-82.6748, 29.2449],
            [-82.6973, 29.2106],
            [-82.7361, 29.2017],
            [-82.7498, 29.1818],
            [-82.8031, 29.1696],
            [-82.8001, 29.1648],
            [-82.8114, 29.1571],
            [-82.7994, 29.1536],
            [-82.805, 29.1408],
            [-82.7936, 29.1379],
            [-82.8053, 29.1371],
            [-82.8271, 29.1584],
            [-82.9453, 29.1678],
            [-82.9961, 29.1781],
            [-83.0305, 29.134],
            [-83.0878, 29.2164],
            [-83.089, 29.2665],
            [-83.1696, 29.2904],
            [-83.1755, 29.3447],
            [-83.2181, 29.4205],
            [-83.2947, 29.4379],
            [-83.3115, 29.4757],
            [-83.3898, 29.5145],
            [-83.3636, 29.5537],
            [-83.2306, 29.5609],
            [-83.2332, 29.5898],
            [-83.2872, 29.6495],
            [-83.2907, 29.706],
            [-83.3182, 29.7378],
            [-83.3189, 29.8226],
            [-83.3529, 29.8222],
            [-83.3709, 29.8879],
            [-83.3671, 30.2605],
            [-83.2665, 30.2603],
            [-83.2834, 30.3409],
            [-83.3165, 30.4221],
            [-83.249, 30.4863],
            [-83.3, 30.4941],
            [-83.3159, 30.5236],
            [-83.265, 30.5624],
            [-83.2725, 30.628],
            [-83.3095, 30.6344],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Tallahassee Mkt": {
    type: "Feature",
    properties: { code: "FL_TAL", name: "Tallahassee Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-85.2225, 29.6781],
            [-85.1143, 29.6887],
            [-85.0772, 29.6709],
            [-85.0972, 29.633],
            [-85.1249, 29.6284],
            [-85.2225, 29.6781],
          ],
        ],
        [
          [
            [-85.9359, 30.9932],
            [-85.4983, 30.9969],
            [-85.2436, 31.0009],
            [-85.0024, 31.0007],
            [-84.9836, 30.937],
            [-84.9368, 30.8847],
            [-84.9354, 30.8172],
            [-84.9143, 30.7536],
            [-84.8647, 30.7115],
            [-84.4744, 30.6928],
            [-84.0466, 30.6742],
            [-83.3095, 30.6344],
            [-83.2725, 30.628],
            [-83.265, 30.5624],
            [-83.3159, 30.5236],
            [-83.3, 30.4941],
            [-83.249, 30.4863],
            [-83.3165, 30.4221],
            [-83.2834, 30.3409],
            [-83.2665, 30.2603],
            [-83.3671, 30.2605],
            [-83.3709, 29.8879],
            [-83.3529, 29.8222],
            [-83.3189, 29.8226],
            [-83.3182, 29.7378],
            [-83.2907, 29.706],
            [-83.2872, 29.6495],
            [-83.2332, 29.5898],
            [-83.2306, 29.5609],
            [-83.3636, 29.5537],
            [-83.3898, 29.5145],
            [-83.4053, 29.5783],
            [-83.3995, 29.613],
            [-83.4128, 29.6685],
            [-83.4554, 29.6764],
            [-83.4937, 29.7084],
            [-83.5376, 29.7231],
            [-83.5861, 29.7846],
            [-83.5859, 29.8118],
            [-83.6186, 29.8423],
            [-83.638, 29.8861],
            [-83.6864, 29.9237],
            [-83.7887, 29.977],
            [-83.8454, 29.9981],
            [-84.0243, 30.1033],
            [-84.1249, 30.0906],
            [-84.1573, 30.0727],
            [-84.2694, 30.0977],
            [-84.2772, 30.0603],
            [-84.3153, 30.0695],
            [-84.3589, 30.0582],
            [-84.362, 29.9877],
            [-84.343, 29.9751],
            [-84.3337, 29.9237],
            [-84.3789, 29.8931],
            [-84.4343, 29.9063],
            [-84.4517, 29.929],
            [-84.5359, 29.9101],
            [-84.6033, 29.8761],
            [-84.6697, 29.8289],
            [-84.6921, 29.8291],
            [-84.8242, 29.7583],
            [-84.9151, 29.7833],
            [-84.9384, 29.7502],
            [-84.9937, 29.7149],
            [-85.1017, 29.7187],
            [-85.2597, 29.6813],
            [-85.3114, 29.6976],
            [-85.3013, 29.7971],
            [-85.3637, 29.8989],
            [-85.4051, 29.9385],
            [-85.4878, 29.9612],
            [-85.5412, 29.9958],
            [-85.5882, 30.0555],
            [-85.6968, 30.0969],
            [-85.7754, 30.1563],
            [-85.8781, 30.2156],
            [-85.9999, 30.2708],
            [-86.09, 30.3036],
            [-86.2651, 30.3545],
            [-86.305, 30.3764],
            [-86.3212, 30.4096],
            [-86.3271, 30.4779],
            [-86.3942, 30.4673],
            [-86.3916, 30.7219],
            [-86.3585, 30.7301],
            [-86.359, 30.7857],
            [-86.3909, 30.813],
            [-86.3905, 30.8404],
            [-86.3527, 30.8664],
            [-86.3051, 30.919],
            [-86.2841, 30.9917],
            [-86.2383, 30.9944],
            [-85.9359, 30.9932],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Macon Mkt": {
    type: "Feature",
    properties: { code: "GA_MAC", name: "Macon Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-84.2865, 32.7475],
          [-84.2776, 32.7437],
          [-84.2718, 32.7505],
          [-84.2792, 32.7475],
          [-84.2882, 32.7634],
          [-84.2866, 32.7778],
          [-84.1719, 32.8154],
          [-84.1709, 32.8178],
          [-84.1706, 32.8208],
          [-84.1751, 32.8221],
          [-84.1755, 32.8253],
          [-84.1743, 32.8271],
          [-84.1739, 32.8293],
          [-84.1754, 32.835],
          [-84.1891, 32.8561],
          [-84.2079, 32.861],
          [-84.206, 32.8938],
          [-84.183, 32.9058],
          [-84.1588, 32.9343],
          [-84.1599, 32.9401],
          [-84.1775, 32.939],
          [-84.1946, 32.9482],
          [-84.2018, 32.9835],
          [-84.1361, 32.9696],
          [-84.1381, 32.9901],
          [-84.1214, 32.9901],
          [-84.1127, 32.9727],
          [-84.1077, 32.9942],
          [-84.0867, 32.9865],
          [-84.081, 32.9741],
          [-84.0739, 32.9756],
          [-84.0756, 32.9679],
          [-84.0468, 32.9646],
          [-84.0529, 32.9795],
          [-84.0437, 32.9685],
          [-84.0414, 33.1301],
          [-84.019, 33.1363],
          [-84.0321, 33.1499],
          [-84.0049, 33.1629],
          [-83.9942, 33.161],
          [-83.9936, 33.2023],
          [-83.9335, 33.202],
          [-83.9215, 33.2075],
          [-83.9215, 33.2165],
          [-83.9085, 33.2162],
          [-83.903, 33.2017],
          [-83.8425, 33.1967],
          [-83.8221, 33.1803],
          [-83.8076, 33.1921],
          [-83.8169, 33.1941],
          [-83.8123, 33.1988],
          [-83.8254, 33.2098],
          [-83.8343, 33.2095],
          [-83.8134, 33.2485],
          [-83.8324, 33.273],
          [-83.8427, 33.3183],
          [-83.8398, 33.3278],
          [-83.8626, 33.3413],
          [-83.8573, 33.3543],
          [-83.8542, 33.3673],
          [-83.8571, 33.372],
          [-83.832, 33.3927],
          [-83.8452, 33.4144],
          [-83.8284, 33.415],
          [-83.8306, 33.4187],
          [-83.8304, 33.4219],
          [-83.8289, 33.4224],
          [-83.8285, 33.426],
          [-83.8237, 33.4333],
          [-83.8239, 33.4353],
          [-83.8251, 33.4366],
          [-83.8251, 33.4375],
          [-83.816, 33.4366],
          [-83.8246, 33.4371],
          [-83.8228, 33.4351],
          [-83.8232, 33.4323],
          [-83.8256, 33.4301],
          [-83.828, 33.4254],
          [-83.8282, 33.4214],
          [-83.8302, 33.4213],
          [-83.8296, 33.4183],
          [-83.8283, 33.4158],
          [-83.8264, 33.4141],
          [-83.8314, 33.4006],
          [-83.8248, 33.3945],
          [-83.823, 33.4031],
          [-83.8185, 33.4053],
          [-83.8224, 33.3999],
          [-83.8213, 33.3986],
          [-83.8187, 33.3968],
          [-83.8141, 33.3945],
          [-83.8117, 33.385],
          [-83.7905, 33.4061],
          [-83.7354, 33.4164],
          [-83.7431, 33.4297],
          [-83.7285, 33.4386],
          [-83.7251, 33.4627],
          [-83.7421, 33.4766],
          [-83.7345, 33.4815],
          [-83.7006, 33.4627],
          [-83.6961, 33.4475],
          [-83.6751, 33.4551],
          [-83.6666, 33.4368],
          [-83.6652, 33.4659],
          [-83.6494, 33.4628],
          [-83.6405, 33.4723],
          [-83.6338, 33.4631],
          [-83.6189, 33.486],
          [-83.5337, 33.4345],
          [-83.3918, 33.4632],
          [-83.3792, 33.4557],
          [-83.3669, 33.4652],
          [-83.3339, 33.4384],
          [-83.3222, 33.4627],
          [-83.3134, 33.4613],
          [-83.3067, 33.4738],
          [-83.28, 33.4712],
          [-83.2799, 33.4834],
          [-83.2762, 33.466],
          [-83.2573, 33.4561],
          [-83.2397, 33.4187],
          [-83.2438, 33.3985],
          [-83.2262, 33.4046],
          [-83.2124, 33.3999],
          [-83.2241, 33.3798],
          [-83.1972, 33.3959],
          [-83.1903, 33.3934],
          [-83.1645, 33.355],
          [-83.1587, 33.3594],
          [-83.0947, 33.4074],
          [-83.0917, 33.4088],
          [-83.0949, 33.3864],
          [-83.0787, 33.3402],
          [-83.0711, 33.3661],
          [-83.0791, 33.3801],
          [-83.0667, 33.4116],
          [-83.0549, 33.4285],
          [-83.0453, 33.4301],
          [-83.0427, 33.4131],
          [-83.0297, 33.4317],
          [-83.0107, 33.4352],
          [-82.9993, 33.4597],
          [-82.9842, 33.4477],
          [-82.9541, 33.4528],
          [-82.9396, 33.4427],
          [-82.8541, 33.4423],
          [-82.8466, 33.4361],
          [-82.8227, 33.426],
          [-82.8278, 33.3931],
          [-82.8183, 33.3878],
          [-82.82, 33.374],
          [-82.8001, 33.3621],
          [-82.7995, 33.3305],
          [-82.7905, 33.3193],
          [-82.7959, 33.3161],
          [-82.7837, 33.3093],
          [-82.7825, 33.2966],
          [-82.7711, 33.2954],
          [-82.7687, 33.3087],
          [-82.753, 33.3108],
          [-82.7528, 33.3178],
          [-82.7367, 33.3187],
          [-82.7499, 33.2981],
          [-82.7758, 33.2649],
          [-82.7884, 33.2747],
          [-82.7896, 33.2946],
          [-82.8275, 33.2677],
          [-82.8426, 33.2647],
          [-82.8384, 33.2571],
          [-82.858, 33.2575],
          [-82.8518, 33.2367],
          [-82.9078, 33.2426],
          [-82.8917, 33.2353],
          [-82.9027, 33.2281],
          [-82.9096, 33.2056],
          [-82.8866, 33.2017],
          [-82.8669, 33.2067],
          [-82.8558, 33.2017],
          [-82.8568, 33.1942],
          [-82.8315, 33.202],
          [-82.7782, 33.2382],
          [-82.7484, 33.2383],
          [-82.733, 33.2049],
          [-82.7411, 33.1964],
          [-82.7395, 33.1744],
          [-82.721, 33.1714],
          [-82.7023, 33.146],
          [-82.6617, 33.1294],
          [-82.6472, 33.1019],
          [-82.6378, 33.1002],
          [-82.6414, 33.0843],
          [-82.6271, 33.073],
          [-82.6285, 33.065],
          [-82.615, 33.063],
          [-82.6035, 33.0445],
          [-82.5479, 33.008],
          [-82.5394, 32.9478],
          [-82.5991, 32.8712],
          [-82.6596, 32.8536],
          [-82.6161, 32.8157],
          [-82.5022, 32.8009],
          [-82.4341, 32.7623],
          [-82.4545, 32.6258],
          [-82.4361, 32.6082],
          [-82.467, 32.563],
          [-82.4618, 32.5337],
          [-82.4945, 32.5035],
          [-82.5675, 32.4638],
          [-82.6115, 32.4881],
          [-82.6384, 32.4627],
          [-82.73, 32.4735],
          [-82.7751, 32.4286],
          [-82.8024, 32.4255],
          [-82.7932, 32.3772],
          [-82.8091, 32.2946],
          [-82.8557, 32.2545],
          [-82.9068, 32.2495],
          [-82.9263, 32.2069],
          [-83.0029, 32.2117],
          [-83.006, 32.1752],
          [-82.9558, 32.172],
          [-82.887, 32.198],
          [-82.885, 32.1715],
          [-82.8315, 32.1258],
          [-82.8882, 32.0808],
          [-82.801, 32.0439],
          [-82.787, 32.0228],
          [-82.7204, 32.0005],
          [-82.7075, 31.9656],
          [-82.7658, 31.9633],
          [-82.8382, 31.9329],
          [-82.9058, 31.8684],
          [-82.9288, 31.87],
          [-82.9781, 31.9213],
          [-83.0241, 31.887],
          [-83.1013, 31.812],
          [-83.1803, 31.8505],
          [-83.21, 31.8447],
          [-83.3566, 31.8465],
          [-83.3854, 31.7997],
          [-83.4533, 31.8015],
          [-83.4442, 31.847],
          [-83.6123, 31.8541],
          [-83.6998, 31.8896],
          [-83.767, 31.8355],
          [-83.8161, 31.8542],
          [-83.8573, 31.8361],
          [-83.9243, 31.8484],
          [-83.9341, 31.8814],
          [-83.9193, 31.9225],
          [-83.9892, 32.0951],
          [-84.0437, 32.1794],
          [-84.0513, 32.2419],
          [-84.108, 32.2539],
          [-84.1826, 32.2491],
          [-84.1999, 32.2304],
          [-84.2201, 32.2312],
          [-84.2192, 32.2951],
          [-84.2559, 32.2962],
          [-84.2546, 32.3721],
          [-84.3238, 32.3725],
          [-84.3632, 32.3976],
          [-84.3624, 32.428],
          [-84.4486, 32.4389],
          [-84.4748, 32.4225],
          [-84.5849, 32.465],
          [-84.5501, 32.4883],
          [-84.4813, 32.4734],
          [-84.443, 32.5186],
          [-84.4444, 32.5621],
          [-84.4089, 32.5619],
          [-84.4085, 32.5981],
          [-84.3127, 32.6084],
          [-84.3406, 32.6608],
          [-84.3379, 32.7209],
          [-84.2865, 32.7475],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Savannah Mkt": {
    type: "Feature",
    properties: { code: "GA_SAV", name: "Savannah Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-82.6035, 33.0445],
          [-82.581, 33.0759],
          [-82.5634, 33.0735],
          [-82.5508, 33.04],
          [-82.5393, 33.0399],
          [-82.523, 33.0603],
          [-82.5134, 33.0548],
          [-82.5003, 33.0612],
          [-82.5202, 33.0699],
          [-82.5239, 33.0801],
          [-82.5312, 33.0773],
          [-82.5496, 33.0872],
          [-82.5579, 33.0851],
          [-82.559, 33.0756],
          [-82.5659, 33.1035],
          [-82.4994, 33.1016],
          [-82.4726, 33.1248],
          [-82.4638, 33.1212],
          [-82.4722, 33.1137],
          [-82.4709, 33.0935],
          [-82.448, 33.0824],
          [-82.4419, 33.0933],
          [-82.4229, 33.1015],
          [-82.4491, 33.1245],
          [-82.4347, 33.1314],
          [-82.4373, 33.1478],
          [-82.4168, 33.1482],
          [-82.4102, 33.1233],
          [-82.4038, 33.1204],
          [-82.3989, 33.1371],
          [-82.3918, 33.1137],
          [-82.358, 33.1037],
          [-82.3478, 33.0932],
          [-82.3406, 33.1052],
          [-82.3538, 33.1111],
          [-82.3225, 33.114],
          [-82.3082, 33.1266],
          [-82.2984, 33.1123],
          [-82.2818, 33.1065],
          [-82.279, 33.0874],
          [-82.2667, 33.0623],
          [-82.244, 33.0571],
          [-82.2476, 33.0407],
          [-82.1969, 33.0159],
          [-82.195, 32.9685],
          [-82.1718, 32.9694],
          [-82.131, 32.9547],
          [-82.0817, 32.9166],
          [-82.0078, 32.9206],
          [-81.9998, 32.9101],
          [-81.9667, 32.9108],
          [-81.9613, 32.8701],
          [-81.9456, 32.8718],
          [-81.9496, 32.8919],
          [-81.9355, 32.8953],
          [-81.931, 32.9064],
          [-81.9233, 32.8958],
          [-81.9081, 32.8932],
          [-81.8921, 32.9088],
          [-81.8865, 32.9036],
          [-81.8218, 32.9085],
          [-81.8369, 32.8954],
          [-81.8378, 32.883],
          [-81.8543, 32.8763],
          [-81.8313, 32.8748],
          [-81.8097, 32.8587],
          [-81.7895, 32.8605],
          [-81.7677, 32.9093],
          [-81.772, 32.9118],
          [-81.7731, 32.9181],
          [-81.7748, 32.9208],
          [-81.7839, 32.9284],
          [-81.8146, 32.937],
          [-81.8193, 32.946],
          [-81.8285, 32.9423],
          [-81.8577, 32.954],
          [-81.9563, 32.9238],
          [-81.9625, 32.9536],
          [-81.9535, 32.9744],
          [-81.936, 32.9745],
          [-81.9238, 32.9996],
          [-81.9123, 33.0045],
          [-81.8992, 33.0085],
          [-81.8715, 33.0266],
          [-81.839, 33.015],
          [-81.8101, 33.03],
          [-81.8038, 33.0412],
          [-81.8288, 33.0598],
          [-81.8195, 33.0908],
          [-81.7841, 33.081],
          [-81.7826, 33.1202],
          [-81.7761, 33.1259],
          [-81.7636, 33.1152],
          [-81.7446, 33.1215],
          [-81.7046, 33.1165],
          [-81.6423, 33.0932],
          [-81.6127, 33.094],
          [-81.6098, 33.0822],
          [-81.5982, 33.0811],
          [-81.5992, 33.0718],
          [-81.5885, 33.0709],
          [-81.5729, 33.0542],
          [-81.5605, 33.0552],
          [-81.557, 33.0451],
          [-81.5443, 33.0469],
          [-81.5112, 33.0278],
          [-81.4923, 33.0093],
          [-81.4947, 32.979],
          [-81.4994, 32.945],
          [-81.5024, 32.9354],
          [-81.4832, 32.9218],
          [-81.4792, 32.9056],
          [-81.4641, 32.8978],
          [-81.4794, 32.8811],
          [-81.4539, 32.8741],
          [-81.4436, 32.8506],
          [-81.4216, 32.8352],
          [-81.4248, 32.8026],
          [-81.4131, 32.8127],
          [-81.4199, 32.8411],
          [-81.379, 32.8535],
          [-81.3698, 32.8743],
          [-81.3455, 32.8748],
          [-81.3311, 32.8591],
          [-81.3108, 32.8644],
          [-81.2987, 32.8534],
          [-81.2827, 32.8627],
          [-81.2679, 32.8606],
          [-81.2767, 32.8921],
          [-81.2446, 32.9079],
          [-81.2269, 32.9047],
          [-81.2224, 32.9123],
          [-81.2361, 32.9203],
          [-81.2358, 32.9402],
          [-81.2221, 32.9338],
          [-81.2196, 32.9534],
          [-81.0826, 33.0264],
          [-81.0557, 33.0018],
          [-81.053, 32.9896],
          [-81.0494, 32.9978],
          [-81.02, 32.9946],
          [-81.0233, 33.0054],
          [-81.0094, 33.0176],
          [-80.9988, 33.0142],
          [-80.976, 33.0239],
          [-80.9679, 33.0138],
          [-80.94, 33.0151],
          [-80.9255, 32.991],
          [-80.9047, 32.9974],
          [-80.8976, 32.9804],
          [-80.8747, 32.991],
          [-80.8713, 32.8472],
          [-80.8798, 32.7919],
          [-80.7523, 32.8073],
          [-80.6682, 32.7755],
          [-80.687, 32.7213],
          [-80.7259, 32.6892],
          [-80.6845, 32.6546],
          [-80.6478, 32.5964],
          [-80.5828, 32.5662],
          [-80.5515, 32.5658],
          [-80.5365, 32.5292],
          [-80.477, 32.4853],
          [-80.4676, 32.4253],
          [-80.4293, 32.3897],
          [-80.4674, 32.3187],
          [-80.5711, 32.2733],
          [-80.6586, 32.2486],
          [-80.6692, 32.2168],
          [-80.7215, 32.1604],
          [-80.8125, 32.1097],
          [-80.8444, 32.1097],
          [-80.9054, 32.0519],
          [-80.8405, 32.0113],
          [-80.8628, 31.9693],
          [-80.9111, 31.9438],
          [-80.9485, 31.9572],
          [-80.9685, 31.9158],
          [-80.9345, 31.9092],
          [-80.9924, 31.8579],
          [-81.0415, 31.8762],
          [-81.0758, 31.829],
          [-81.0398, 31.823],
          [-81.0681, 31.7687],
          [-81.1306, 31.7227],
          [-81.1928, 31.7332],
          [-81.1863, 31.7015],
          [-81.1364, 31.6748],
          [-81.1335, 31.6233],
          [-81.1604, 31.5704],
          [-81.2542, 31.5559],
          [-81.2589, 31.5291],
          [-81.1773, 31.5171],
          [-81.2469, 31.4228],
          [-81.2787, 31.3675],
          [-81.2747, 31.2895],
          [-81.2934, 31.2063],
          [-81.3145, 31.2077],
          [-81.3681, 31.1367],
          [-81.3991, 31.1341],
          [-81.4013, 31.0728],
          [-81.4205, 31.0167],
          [-81.4514, 31.0155],
          [-81.4906, 30.985],
          [-81.4474, 30.9567],
          [-81.4034, 30.9578],
          [-81.4052, 30.9082],
          [-81.4469, 30.8104],
          [-81.4595, 30.742],
          [-81.4441, 30.7097],
          [-81.4895, 30.7261],
          [-81.5409, 30.7133],
          [-81.6948, 30.7484],
          [-81.7199, 30.7446],
          [-81.8085, 30.79],
          [-81.8686, 30.7928],
          [-81.8929, 30.8193],
          [-81.9498, 30.8275],
          [-81.9813, 30.7768],
          [-82.0396, 30.7477],
          [-82.0461, 30.6518],
          [-82.1328, 30.6619],
          [-82.1899, 30.651],
          [-82.2148, 30.5686],
          [-82.4189, 30.5817],
          [-82.4309, 30.613],
          [-82.436, 30.8201],
          [-82.4922, 30.8326],
          [-82.4906, 30.9632],
          [-82.5203, 30.9631],
          [-82.5506, 31.0002],
          [-82.5922, 31.0186],
          [-82.6911, 31.2235],
          [-82.6996, 31.2718],
          [-82.6299, 31.2748],
          [-82.6292, 31.3638],
          [-82.8175, 31.3643],
          [-82.8375, 31.4005],
          [-82.8789, 31.4174],
          [-82.9036, 31.4469],
          [-83.0015, 31.424],
          [-83.0534, 31.4469],
          [-83.0805, 31.476],
          [-83.0842, 31.5146],
          [-83.06, 31.5677],
          [-83.0589, 31.6113],
          [-83.0137, 31.6358],
          [-83.0132, 31.6611],
          [-82.9534, 31.6569],
          [-82.9447, 31.7137],
          [-82.997, 31.7391],
          [-83.0023, 31.7758],
          [-83.1013, 31.812],
          [-83.0241, 31.887],
          [-82.9781, 31.9213],
          [-82.9288, 31.87],
          [-82.9058, 31.8684],
          [-82.8382, 31.9329],
          [-82.7658, 31.9633],
          [-82.7075, 31.9656],
          [-82.7204, 32.0005],
          [-82.787, 32.0228],
          [-82.801, 32.0439],
          [-82.8882, 32.0808],
          [-82.8315, 32.1258],
          [-82.885, 32.1715],
          [-82.887, 32.198],
          [-82.9558, 32.172],
          [-83.006, 32.1752],
          [-83.0029, 32.2117],
          [-82.9263, 32.2069],
          [-82.9068, 32.2495],
          [-82.8557, 32.2545],
          [-82.8091, 32.2946],
          [-82.7932, 32.3772],
          [-82.8024, 32.4255],
          [-82.7751, 32.4286],
          [-82.73, 32.4735],
          [-82.6384, 32.4627],
          [-82.6115, 32.4881],
          [-82.5675, 32.4638],
          [-82.4945, 32.5035],
          [-82.4618, 32.5337],
          [-82.467, 32.563],
          [-82.4361, 32.6082],
          [-82.4545, 32.6258],
          [-82.4341, 32.7623],
          [-82.5022, 32.8009],
          [-82.6161, 32.8157],
          [-82.6596, 32.8536],
          [-82.5991, 32.8712],
          [-82.5394, 32.9478],
          [-82.5479, 33.008],
          [-82.6035, 33.0445],
        ],
        [
          [-82.4203, 30.7983],
          [-82.2498, 30.7918],
          [-82.2522, 30.815],
          [-82.3211, 30.8359],
          [-82.3647, 30.8322],
          [-82.4203, 30.7983],
        ],
        [
          [-81.4226, 32.7826],
          [-81.427, 32.7876],
          [-81.4251, 32.7976],
          [-81.429, 32.7855],
          [-81.4226, 32.7826],
        ],
        [
          [-81.4167, 32.7526],
          [-81.4078, 32.7552],
          [-81.4118, 32.7647],
          [-81.4262, 32.7684],
          [-81.4167, 32.7526],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Tifton Mkt": {
    type: "Feature",
    properties: { code: "GA_TIF", name: "Tifton Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-82.2148, 30.5686],
            [-82.1899, 30.651],
            [-82.1328, 30.6619],
            [-82.0461, 30.6518],
            [-82.0055, 30.5635],
            [-82.0178, 30.4751],
            [-82.0372, 30.4345],
            [-82.0479, 30.3633],
            [-82.1048, 30.3683],
            [-82.1701, 30.3589],
            [-82.2103, 30.4246],
            [-82.2014, 30.4852],
            [-82.2356, 30.5449],
            [-82.2148, 30.5686],
          ],
        ],
        [
          [
            [-82.4203, 30.7983],
            [-82.3647, 30.8322],
            [-82.3211, 30.8359],
            [-82.2522, 30.815],
            [-82.2498, 30.7918],
            [-82.4203, 30.7983],
          ],
        ],
        [
          [
            [-84.1999, 32.2304],
            [-84.1826, 32.2491],
            [-84.108, 32.2539],
            [-84.0513, 32.2419],
            [-84.0437, 32.1794],
            [-83.9892, 32.0951],
            [-83.9193, 31.9225],
            [-83.9341, 31.8814],
            [-83.9243, 31.8484],
            [-83.8573, 31.8361],
            [-83.8161, 31.8542],
            [-83.767, 31.8355],
            [-83.6998, 31.8896],
            [-83.6123, 31.8541],
            [-83.4442, 31.847],
            [-83.4533, 31.8015],
            [-83.3854, 31.7997],
            [-83.3566, 31.8465],
            [-83.21, 31.8447],
            [-83.1803, 31.8505],
            [-83.1013, 31.812],
            [-83.0023, 31.7758],
            [-82.997, 31.7391],
            [-82.9447, 31.7137],
            [-82.9534, 31.6569],
            [-83.0132, 31.6611],
            [-83.0137, 31.6358],
            [-83.0589, 31.6113],
            [-83.06, 31.5677],
            [-83.0842, 31.5146],
            [-83.0805, 31.476],
            [-83.0534, 31.4469],
            [-83.0015, 31.424],
            [-82.9036, 31.4469],
            [-82.8789, 31.4174],
            [-82.8375, 31.4005],
            [-82.8175, 31.3643],
            [-82.6292, 31.3638],
            [-82.6299, 31.2748],
            [-82.6996, 31.2718],
            [-82.6911, 31.2235],
            [-82.5922, 31.0186],
            [-82.5506, 31.0002],
            [-82.5203, 30.9631],
            [-82.4906, 30.9632],
            [-82.4922, 30.8326],
            [-82.436, 30.8201],
            [-82.4309, 30.613],
            [-82.4189, 30.5817],
            [-82.6986, 30.5982],
            [-83.3095, 30.6344],
            [-84.0466, 30.6742],
            [-84.4744, 30.6928],
            [-84.8647, 30.7115],
            [-84.9143, 30.7536],
            [-84.9354, 30.8172],
            [-84.9368, 30.8847],
            [-84.9836, 30.937],
            [-85.0024, 31.0007],
            [-85.0088, 31.0456],
            [-85.0356, 31.1082],
            [-85.0778, 31.1579],
            [-85.1, 31.1653],
            [-85.0968, 31.2257],
            [-85.1133, 31.2643],
            [-85.0898, 31.295],
            [-85.0922, 31.3646],
            [-85.0716, 31.4684],
            [-85.0413, 31.541],
            [-85.058, 31.5708],
            [-85.0595, 31.6217],
            [-85.1255, 31.695],
            [-85.1189, 31.7327],
            [-85.1418, 31.7821],
            [-85.1401, 31.8588],
            [-85.0868, 31.9578],
            [-85.042, 31.9757],
            [-84.9639, 31.9669],
            [-84.9269, 31.9501],
            [-84.9028, 31.9767],
            [-84.8725, 31.9489],
            [-84.8815, 31.924],
            [-84.6059, 31.9202],
            [-84.5991, 31.9644],
            [-84.5213, 31.9468],
            [-84.4445, 31.9464],
            [-84.433, 32.042],
            [-84.4302, 32.1663],
            [-84.1836, 32.1585],
            [-84.1817, 32.2297],
            [-84.1999, 32.2304],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Cedar Rapids Mkt": {
    type: "Feature",
    properties: { code: "IA_CED", name: "Cedar Rapids Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-92.6497, 42.029],
          [-92.6503, 42.138],
          [-92.6305, 42.1525],
          [-92.5622, 42.1231],
          [-92.4945, 42.1231],
          [-92.4554, 42.1038],
          [-92.397, 42.1523],
          [-92.3966, 42.1958],
          [-92.3623, 42.2118],
          [-92.3624, 42.2592],
          [-92.2595, 42.2545],
          [-92.2401, 42.2108],
          [-92.1623, 42.2105],
          [-92.1621, 42.2829],
          [-92.1424, 42.2974],
          [-92.0648, 42.2973],
          [-92.0645, 42.3709],
          [-91.9479, 42.3849],
          [-91.8944, 42.3703],
          [-91.8836, 42.3981],
          [-91.7967, 42.4077],
          [-91.761, 42.3994],
          [-91.7365, 42.3523],
          [-91.6911, 42.3402],
          [-91.6567, 42.3547],
          [-91.5492, 42.3545],
          [-91.5396, 42.4202],
          [-91.5005, 42.3909],
          [-91.3883, 42.4014],
          [-91.3828, 42.4696],
          [-91.2834, 42.4713],
          [-91.2516, 42.4171],
          [-91.1699, 42.4225],
          [-91.1451, 42.3826],
          [-91.1502, 42.3466],
          [-91.121, 42.3237],
          [-91.1098, 42.2823],
          [-91.0481, 42.2527],
          [-91.0714, 42.2239],
          [-91.0404, 42.1923],
          [-91.0035, 42.2035],
          [-90.9704, 42.1803],
          [-90.8983, 42.1741],
          [-90.8741, 42.1944],
          [-90.8257, 42.1666],
          [-90.7822, 42.0619],
          [-90.782, 42.0035],
          [-90.7332, 42.0037],
          [-90.7326, 41.9308],
          [-90.7331, 41.9019],
          [-90.7958, 41.8656],
          [-90.8163, 41.9165],
          [-90.8744, 41.9168],
          [-90.8985, 41.8151],
          [-90.9656, 41.8208],
          [-91.0153, 41.8081],
          [-91.1081, 41.838],
          [-91.1772, 41.839],
          [-91.191, 41.7866],
          [-91.2348, 41.8235],
          [-91.3076, 41.8163],
          [-91.3176, 41.7934],
          [-91.2523, 41.7416],
          [-91.2201, 41.6849],
          [-91.2882, 41.6634],
          [-91.2881, 41.6273],
          [-91.3664, 41.5984],
          [-91.3669, 41.5538],
          [-91.4448, 41.5546],
          [-91.5065, 41.511],
          [-91.4839, 41.4278],
          [-91.4855, 41.2496],
          [-91.6106, 41.2507],
          [-91.6016, 41.2023],
          [-91.7113, 41.1919],
          [-91.7916, 41.2021],
          [-91.8335, 41.2563],
          [-91.888, 41.2574],
          [-91.9367, 41.2218],
          [-91.9937, 41.2594],
          [-92.0618, 41.2688],
          [-92.064, 41.3232],
          [-92.1223, 41.3668],
          [-92.1223, 41.3988],
          [-92.1761, 41.3949],
          [-92.2199, 41.4238],
          [-92.2202, 41.4817],
          [-92.2583, 41.5315],
          [-92.2975, 41.5097],
          [-92.3744, 41.5096],
          [-92.4121, 41.5384],
          [-92.4161, 41.6305],
          [-92.4356, 41.6739],
          [-92.508, 41.674],
          [-92.5085, 41.746],
          [-92.533, 41.746],
          [-92.5332, 41.8479],
          [-92.572, 41.8622],
          [-92.6497, 41.8484],
          [-92.6496, 41.8914],
          [-92.6884, 41.8917],
          [-92.7085, 41.9357],
          [-92.6716, 41.964],
          [-92.6497, 42.029],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Des Moines Mkt": {
    type: "Feature",
    properties: { code: "IA_DES", name: "Des Moines Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-94.5323, 43.5004],
          [-94.1473, 43.5003],
          [-94.1479, 43.4882],
          [-94.1478, 43.459],
          [-94.0703, 43.4591],
          [-94.0506, 43.4879],
          [-94.0507, 43.5001],
          [-94.0308, 43.4999],
          [-93.9708, 43.4996],
          [-93.8524, 43.4995],
          [-93.6171, 43.4995],
          [-93.0714, 43.4996],
          [-92.4964, 43.5003],
          [-92.4954, 43.4289],
          [-92.3958, 43.4289],
          [-92.3963, 43.3857],
          [-92.4261, 43.3712],
          [-92.4358, 43.3283],
          [-92.4855, 43.3127],
          [-92.5052, 43.2843],
          [-92.5742, 43.2698],
          [-92.5743, 43.2127],
          [-92.5942, 43.1983],
          [-92.6858, 43.198],
          [-92.7135, 43.1146],
          [-92.8104, 43.1266],
          [-92.8493, 43.0974],
          [-92.8498, 43.0668],
          [-92.7903, 43.0601],
          [-92.7903, 43.0091],
          [-92.9058, 43.0087],
          [-92.9457, 42.98],
          [-93.0032, 42.9801],
          [-93.005, 42.9366],
          [-92.9659, 42.9076],
          [-93.0251, 42.8911],
          [-93.0549, 42.8476],
          [-93.0647, 42.7885],
          [-93.0467, 42.7505],
          [-93.1137, 42.7308],
          [-93.183, 42.6869],
          [-93.1843, 42.6001],
          [-93.204, 42.5715],
          [-93.164, 42.5427],
          [-93.1298, 42.4866],
          [-93.1582, 42.4047],
          [-93.2375, 42.4113],
          [-93.2703, 42.3831],
          [-93.232, 42.3389],
          [-93.1898, 42.3294],
          [-93.1718, 42.2957],
          [-93.0604, 42.2961],
          [-93.0019, 42.2673],
          [-93.0015, 42.2092],
          [-92.924, 42.2098],
          [-92.8641, 42.1522],
          [-92.7869, 42.1813],
          [-92.7862, 42.0928],
          [-92.7665, 42.0314],
          [-92.6497, 42.029],
          [-92.6716, 41.964],
          [-92.7085, 41.9357],
          [-92.6884, 41.8917],
          [-92.6496, 41.8914],
          [-92.6497, 41.8484],
          [-92.572, 41.8622],
          [-92.5332, 41.8479],
          [-92.533, 41.746],
          [-92.5085, 41.746],
          [-92.508, 41.674],
          [-92.4356, 41.6739],
          [-92.4161, 41.6305],
          [-92.4121, 41.5384],
          [-92.3744, 41.5096],
          [-92.2975, 41.5097],
          [-92.2583, 41.5315],
          [-92.2202, 41.4817],
          [-92.2199, 41.4238],
          [-92.2968, 41.351],
          [-92.3303, 41.3387],
          [-92.3544, 41.3725],
          [-92.4574, 41.3834],
          [-92.498, 41.4085],
          [-92.5362, 41.3602],
          [-92.5748, 41.3358],
          [-92.6805, 41.3787],
          [-92.7327, 41.3573],
          [-92.7319, 41.3143],
          [-92.8134, 41.3139],
          [-92.826, 41.2583],
          [-92.8512, 41.2408],
          [-92.7751, 41.1966],
          [-92.8329, 41.1568],
          [-92.8411, 41.1036],
          [-92.9073, 41.0858],
          [-92.9601, 41.0924],
          [-92.977, 41.0737],
          [-93.0606, 41.0665],
          [-93.0985, 41.1023],
          [-93.0976, 40.8985],
          [-93.1549, 40.8846],
          [-93.1547, 40.8414],
          [-93.2111, 40.8411],
          [-93.2117, 40.7794],
          [-93.174, 40.7464],
          [-93.2125, 40.6741],
          [-93.2127, 40.5939],
          [-93.1838, 40.5821],
          [-93.5483, 40.5804],
          [-94.0802, 40.5729],
          [-94.4893, 40.5707],
          [-94.9149, 40.5751],
          [-94.915, 40.6497],
          [-94.8768, 40.6855],
          [-94.8286, 40.6959],
          [-94.8336, 40.8279],
          [-94.9756, 40.8211],
          [-94.986, 40.8463],
          [-95.1188, 40.8428],
          [-95.1182, 40.9154],
          [-95.08, 40.9153],
          [-95.0043, 40.9731],
          [-95.0041, 41.0338],
          [-95.0419, 41.034],
          [-95.0993, 41.0714],
          [-94.985, 41.0807],
          [-94.9848, 41.1728],
          [-94.9664, 41.1728],
          [-94.9656, 41.2736],
          [-95.0411, 41.2954],
          [-95.0413, 41.3314],
          [-95.0986, 41.3461],
          [-95.1466, 41.3895],
          [-95.0986, 41.4319],
          [-95.0794, 41.476],
          [-95.0795, 41.5483],
          [-95.041, 41.5483],
          [-95.0343, 41.6258],
          [-95.0538, 41.6883],
          [-95.1509, 41.688],
          [-95.1514, 41.7133],
          [-95.2726, 41.7033],
          [-95.2865, 41.8197],
          [-95.3056, 41.8641],
          [-95.3633, 41.8782],
          [-95.3629, 41.9358],
          [-95.4213, 41.9356],
          [-95.4551, 41.952],
          [-95.4104, 41.9938],
          [-95.4988, 42.0084],
          [-95.5376, 41.9938],
          [-95.6136, 41.9937],
          [-95.6268, 41.9793],
          [-95.6539, 41.9792],
          [-95.6535, 42.0549],
          [-95.634, 42.0579],
          [-95.6281, 42.1833],
          [-95.5368, 42.2254],
          [-95.5371, 42.2836],
          [-95.5552, 42.3126],
          [-95.5549, 42.3992],
          [-95.468, 42.4555],
          [-95.4203, 42.4573],
          [-95.4008, 42.4139],
          [-95.3032, 42.4003],
          [-95.2449, 42.4146],
          [-95.2448, 42.4695],
          [-95.2095, 42.4747],
          [-95.2101, 42.5326],
          [-95.1902, 42.561],
          [-95.2893, 42.5607],
          [-95.2698, 42.6189],
          [-95.2697, 42.7343],
          [-95.2495, 42.7343],
          [-95.2494, 42.822],
          [-95.21, 42.8366],
          [-95.2035, 42.9098],
          [-95.2112, 43.0101],
          [-95.1514, 43.0103],
          [-95.0532, 42.9098],
          [-94.9537, 42.9244],
          [-94.9141, 42.9098],
          [-94.9141, 42.9823],
          [-94.9736, 42.9826],
          [-94.973, 43.0513],
          [-94.8753, 43.024],
          [-94.8366, 43.1121],
          [-94.8367, 43.1837],
          [-94.7247, 43.1837],
          [-94.7236, 43.2132],
          [-94.6395, 43.2268],
          [-94.5604, 43.2126],
          [-94.5799, 43.2695],
          [-94.6397, 43.2696],
          [-94.6595, 43.2916],
          [-94.6597, 43.3563],
          [-94.6202, 43.3708],
          [-94.6405, 43.4305],
          [-94.6984, 43.4933],
          [-94.5323, 43.5004],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Dubuque Mkt": {
    type: "Feature",
    properties: { code: "IA_DUB", name: "Dubuque Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-92.4964, 43.5003],
          [-91.9109, 43.5006],
          [-91.2177, 43.5005],
          [-91.2322, 43.46],
          [-91.2004, 43.4127],
          [-91.2018, 43.3491],
          [-91.1291, 43.3263],
          [-91.0579, 43.254],
          [-91.1191, 43.2004],
          [-91.1562, 43.1429],
          [-91.04, 43.1475],
          [-91.0121, 43.1716],
          [-90.9147, 43.1801],
          [-90.9118, 43.1089],
          [-90.8641, 43.1024],
          [-90.867, 43.182],
          [-90.8101, 43.1765],
          [-90.8348, 43.2196],
          [-90.7495, 43.2233],
          [-90.7602, 43.2481],
          [-90.6945, 43.2739],
          [-90.6669, 43.2295],
          [-90.6668, 43.1718],
          [-90.6197, 43.1842],
          [-90.5153, 43.181],
          [-90.4912, 43.1687],
          [-90.552, 43.0919],
          [-90.5245, 43.0502],
          [-90.5468, 43.0148],
          [-90.4783, 42.9933],
          [-90.4779, 42.9406],
          [-90.5465, 42.9065],
          [-90.5467, 42.881],
          [-90.5066, 42.8568],
          [-90.4473, 42.8564],
          [-90.4271, 42.8276],
          [-90.373, 42.806],
          [-90.3479, 42.7337],
          [-90.3668, 42.6827],
          [-90.3083, 42.6697],
          [-90.3088, 42.5366],
          [-90.3532, 42.5424],
          [-90.3627, 42.507],
          [-90.6409, 42.5083],
          [-90.654, 42.4785],
          [-90.5707, 42.4417],
          [-90.555, 42.4161],
          [-90.4775, 42.3839],
          [-90.4159, 42.3227],
          [-90.4309, 42.2782],
          [-90.3911, 42.2255],
          [-90.2095, 42.1527],
          [-90.1629, 42.1167],
          [-90.1542, 42.0331],
          [-90.1692, 41.9826],
          [-90.2284, 42.0332],
          [-90.3691, 42.0042],
          [-90.4714, 42.0086],
          [-90.4885, 42.0411],
          [-90.5294, 42.033],
          [-90.5486, 41.9842],
          [-90.5484, 41.9018],
          [-90.6454, 41.9016],
          [-90.7326, 41.9308],
          [-90.7332, 42.0037],
          [-90.782, 42.0035],
          [-90.7822, 42.0619],
          [-90.8257, 42.1666],
          [-90.8741, 42.1944],
          [-90.8983, 42.1741],
          [-90.9704, 42.1803],
          [-91.0035, 42.2035],
          [-91.0404, 42.1923],
          [-91.0714, 42.2239],
          [-91.0481, 42.2527],
          [-91.1098, 42.2823],
          [-91.121, 42.3237],
          [-91.1502, 42.3466],
          [-91.1451, 42.3826],
          [-91.1699, 42.4225],
          [-91.2516, 42.4171],
          [-91.2834, 42.4713],
          [-91.3828, 42.4696],
          [-91.3883, 42.4014],
          [-91.5005, 42.3909],
          [-91.5396, 42.4202],
          [-91.5492, 42.3545],
          [-91.6567, 42.3547],
          [-91.6911, 42.3402],
          [-91.7365, 42.3523],
          [-91.761, 42.3994],
          [-91.7967, 42.4077],
          [-91.8836, 42.3981],
          [-91.8944, 42.3703],
          [-91.9479, 42.3849],
          [-92.0645, 42.3709],
          [-92.0648, 42.2973],
          [-92.1424, 42.2974],
          [-92.1621, 42.2829],
          [-92.1623, 42.2105],
          [-92.2401, 42.2108],
          [-92.2595, 42.2545],
          [-92.3624, 42.2592],
          [-92.3623, 42.2118],
          [-92.3966, 42.1958],
          [-92.397, 42.1523],
          [-92.4554, 42.1038],
          [-92.4945, 42.1231],
          [-92.5622, 42.1231],
          [-92.6305, 42.1525],
          [-92.6503, 42.138],
          [-92.6497, 42.029],
          [-92.7665, 42.0314],
          [-92.7862, 42.0928],
          [-92.7869, 42.1813],
          [-92.8641, 42.1522],
          [-92.924, 42.2098],
          [-93.0015, 42.2092],
          [-93.0019, 42.2673],
          [-93.0604, 42.2961],
          [-93.1718, 42.2957],
          [-93.1898, 42.3294],
          [-93.232, 42.3389],
          [-93.2703, 42.3831],
          [-93.2375, 42.4113],
          [-93.1582, 42.4047],
          [-93.1298, 42.4866],
          [-93.164, 42.5427],
          [-93.204, 42.5715],
          [-93.1843, 42.6001],
          [-93.183, 42.6869],
          [-93.1137, 42.7308],
          [-93.0467, 42.7505],
          [-93.0647, 42.7885],
          [-93.0549, 42.8476],
          [-93.0251, 42.8911],
          [-92.9659, 42.9076],
          [-93.005, 42.9366],
          [-93.0032, 42.9801],
          [-92.9457, 42.98],
          [-92.9058, 43.0087],
          [-92.7903, 43.0091],
          [-92.7903, 43.0601],
          [-92.8498, 43.0668],
          [-92.8493, 43.0974],
          [-92.8104, 43.1266],
          [-92.7135, 43.1146],
          [-92.6858, 43.198],
          [-92.5942, 43.1983],
          [-92.5743, 43.2127],
          [-92.5742, 43.2698],
          [-92.5052, 43.2843],
          [-92.4855, 43.3127],
          [-92.4358, 43.3283],
          [-92.4261, 43.3712],
          [-92.3963, 43.3857],
          [-92.3958, 43.4289],
          [-92.4954, 43.4289],
          [-92.4964, 43.5003],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Bloomington Mkt": {
    type: "Feature",
    properties: { code: "IL_BLO", name: "Bloomington Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-88.663, 40.9728],
            [-88.586, 40.9616],
            [-88.491, 40.9336],
            [-88.4152, 40.9345],
            [-88.4347, 40.9634],
            [-88.3746, 41.022],
            [-88.3248, 41.0226],
            [-88.2682, 41.0524],
            [-88.2697, 41.1135],
            [-88.2432, 41.1287],
            [-88.2064, 41.1862],
            [-88.0893, 41.1901],
            [-88.0118, 41.2056],
            [-88.0139, 41.2923],
            [-87.9587, 41.3079],
            [-87.9004, 41.2943],
            [-87.5268, 41.2982],
            [-87.5266, 41.0576],
            [-87.5264, 40.4916],
            [-87.5316, 40.133],
            [-87.5331, 39.9104],
            [-87.5575, 39.8687],
            [-87.6578, 39.8662],
            [-87.7103, 39.8811],
            [-87.7769, 39.8809],
            [-87.8808, 39.8509],
            [-87.9186, 39.8654],
            [-87.9846, 39.8505],
            [-87.9943, 39.8795],
            [-88.0847, 39.8652],
            [-88.1321, 39.8801],
            [-88.4624, 39.8791],
            [-88.5566, 39.85],
            [-88.6885, 39.8505],
            [-88.6939, 39.8141],
            [-88.7082, 39.8142],
            [-88.7461, 39.8142],
            [-88.7461, 39.8363],
            [-88.8028, 39.8353],
            [-88.7939, 39.8504],
            [-88.7648, 39.8604],
            [-88.779, 39.8601],
            [-88.7645, 39.8798],
            [-88.7762, 39.8818],
            [-88.7739, 39.8948],
            [-88.7454, 39.8948],
            [-88.7454, 39.9464],
            [-88.7731, 39.9315],
            [-88.7798, 39.9519],
            [-88.7643, 39.9539],
            [-88.7643, 39.997],
            [-88.784, 40],
            [-88.7648, 40.0551],
            [-88.8798, 40.0537],
            [-88.8991, 40.0094],
            [-88.8984, 39.9803],
            [-88.9184, 39.9651],
            [-88.9505, 39.9643],
            [-88.9936, 39.9633],
            [-89.0288, 39.9625],
            [-89.029, 40.0063],
            [-89.105, 40.0054],
            [-89.1444, 40.02],
            [-89.1452, 40.0852],
            [-89.1594, 40.0849],
            [-89.174, 40.136],
            [-89.146, 40.1362],
            [-89.1472, 40.1882],
            [-89.1246, 40.1861],
            [-89.1373, 40.195],
            [-89.143, 40.2101],
            [-89.1755, 40.2081],
            [-89.1943, 40.2005],
            [-89.215, 40.1859],
            [-89.2456, 40.1856],
            [-89.2459, 40.1999],
            [-89.2588, 40.1997],
            [-89.2592, 40.2152],
            [-89.3278, 40.2294],
            [-89.3187, 40.2438],
            [-89.3375, 40.2364],
            [-89.4035, 40.2355],
            [-89.4041, 40.2648],
            [-89.3902, 40.2689],
            [-89.3966, 40.3239],
            [-89.7339, 40.3188],
            [-89.7339, 40.3079],
            [-89.7434, 40.3042],
            [-89.8047, 40.2886],
            [-89.8189, 40.2891],
            [-89.8285, 40.3032],
            [-89.8294, 40.296],
            [-89.9045, 40.2968],
            [-89.9038, 40.2752],
            [-89.9514, 40.2756],
            [-89.9607, 40.2931],
            [-89.9563, 40.3125],
            [-89.9894, 40.3125],
            [-89.9894, 40.3197],
            [-89.9565, 40.3256],
            [-89.9836, 40.3342],
            [-89.9418, 40.3325],
            [-89.9282, 40.3331],
            [-89.9236, 40.3923],
            [-89.9513, 40.393],
            [-89.9795, 40.3737],
            [-89.9548, 40.3922],
            [-89.9829, 40.3921],
            [-90.0424, 40.3666],
            [-90.0531, 40.3279],
            [-90.0687, 40.3139],
            [-90.0699, 40.2943],
            [-90.1243, 40.3024],
            [-90.1535, 40.312],
            [-90.1473, 40.3063],
            [-90.1896, 40.3046],
            [-90.2037, 40.2956],
            [-90.2133, 40.3546],
            [-90.2382, 40.4008],
            [-90.2872, 40.4442],
            [-90.2726, 40.4624],
            [-90.1825, 40.4366],
            [-90.1047, 40.4629],
            [-90.1066, 40.5234],
            [-90.1246, 40.5603],
            [-90.235, 40.5822],
            [-90.1402, 40.6262],
            [-90.102, 40.6259],
            [-90.1006, 40.8039],
            [-89.9673, 40.8847],
            [-89.9659, 40.9169],
            [-89.908, 40.9167],
            [-89.8866, 40.9751],
            [-89.812, 40.9748],
            [-89.7693, 41.0175],
            [-89.7159, 41.0144],
            [-89.7161, 40.974],
            [-89.6387, 40.9738],
            [-89.6104, 40.9881],
            [-89.6303, 41.0612],
            [-89.581, 41.0756],
            [-89.592, 41.1122],
            [-89.5632, 41.1487],
            [-89.505, 41.1486],
            [-89.4665, 41.1849],
            [-89.4665, 41.2339],
            [-89.3581, 41.2333],
            [-89.3202, 41.1654],
            [-89.3422, 41.1114],
            [-89.3028, 41.0902],
            [-89.3159, 41.0405],
            [-89.3151, 40.967],
            [-89.2135, 40.9575],
            [-89.1846, 40.9395],
            [-89.0859, 40.9409],
            [-89.067, 40.9262],
            [-88.9314, 40.9278],
            [-88.9312, 40.9571],
            [-88.8548, 40.9441],
            [-88.8472, 40.9735],
            [-88.7866, 40.996],
            [-88.7153, 40.9607],
            [-88.663, 40.9728],
          ],
        ],
        [
          [
            [-90.315, 40.645],
            [-90.2664, 40.7137],
            [-90.1387, 40.7133],
            [-90.139, 40.6914],
            [-90.2148, 40.6913],
            [-90.2147, 40.6474],
            [-90.315, 40.645],
          ],
        ],
        [
          [
            [-90.4506, 40.2543],
            [-90.4246, 40.2763],
            [-90.3646, 40.2644],
            [-90.3166, 40.287],
            [-90.2097, 40.2915],
            [-90.1979, 40.2322],
            [-90.205, 40.2252],
            [-90.1717, 40.2239],
            [-90.1647, 40.217],
            [-90.1274, 40.2284],
            [-90.1082, 40.2608],
            [-90.0859, 40.2769],
            [-90.1073, 40.2609],
            [-90.1251, 40.2289],
            [-90.1635, 40.2166],
            [-90.1996, 40.1839],
            [-90.4515, 40.1889],
            [-90.4506, 40.2543],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Joliet Mkt": {
    type: "Feature",
    properties: { code: "IL_JOL", name: "Joliet Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-88.9414, 41.8918],
          [-88.9415, 41.8629],
          [-88.9251, 41.8484],
          [-88.848, 41.8486],
          [-88.8317, 41.8342],
          [-88.8282, 41.8456],
          [-88.7543, 41.8347],
          [-88.7543, 41.8055],
          [-88.6996, 41.8058],
          [-88.6991, 41.8494],
          [-88.6409, 41.8498],
          [-88.6024, 41.8212],
          [-88.6062, 41.7998],
          [-88.5862, 41.807],
          [-88.5465, 41.8075],
          [-88.5404, 41.7934],
          [-88.5284, 41.8077],
          [-88.4799, 41.8077],
          [-88.475, 41.8237],
          [-88.4393, 41.8229],
          [-88.414, 41.8313],
          [-88.4067, 41.8465],
          [-88.3716, 41.865],
          [-88.2627, 41.871],
          [-88.2615, 41.8453],
          [-88.241, 41.856],
          [-88.2045, 41.8564],
          [-88.2027, 41.8424],
          [-88.1056, 41.8291],
          [-88.0735, 41.8152],
          [-88.0479, 41.8316],
          [-87.9579, 41.8463],
          [-87.9604, 41.8562],
          [-87.9483, 41.8607],
          [-87.9298, 41.8567],
          [-87.9201, 41.8616],
          [-87.9186, 41.8264],
          [-87.9002, 41.8264],
          [-87.8818, 41.8338],
          [-87.8791, 41.8478],
          [-87.8586, 41.8427],
          [-87.8428, 41.8539],
          [-87.8087, 41.8496],
          [-87.779, 41.8653],
          [-87.7633, 41.8137],
          [-87.8019, 41.8014],
          [-87.8002, 41.7555],
          [-87.742, 41.7591],
          [-87.7412, 41.7347],
          [-87.7216, 41.735],
          [-87.7203, 41.6915],
          [-87.7789, 41.6905],
          [-87.7782, 41.6719],
          [-87.7678, 41.6609],
          [-87.7631, 41.6666],
          [-87.7138, 41.6518],
          [-87.6999, 41.6675],
          [-87.7097, 41.6695],
          [-87.6719, 41.6628],
          [-87.6515, 41.6554],
          [-87.6612, 41.6394],
          [-87.6482, 41.6285],
          [-87.6171, 41.63],
          [-87.5863, 41.6447],
          [-87.5626, 41.636],
          [-87.5637, 41.63],
          [-87.5247, 41.6306],
          [-87.5268, 41.2982],
          [-87.9004, 41.2943],
          [-87.9587, 41.3079],
          [-88.0139, 41.2923],
          [-88.0118, 41.2056],
          [-88.0893, 41.1901],
          [-88.2064, 41.1862],
          [-88.2432, 41.1287],
          [-88.2697, 41.1135],
          [-88.2682, 41.0524],
          [-88.3248, 41.0226],
          [-88.3746, 41.022],
          [-88.4347, 40.9634],
          [-88.4152, 40.9345],
          [-88.491, 40.9336],
          [-88.586, 40.9616],
          [-88.663, 40.9728],
          [-88.6824, 41.0041],
          [-88.6434, 41.0479],
          [-88.5697, 41.108],
          [-88.6629, 41.1072],
          [-88.6832, 41.1799],
          [-88.7216, 41.1939],
          [-88.722, 41.2231],
          [-88.6452, 41.2238],
          [-88.6253, 41.1948],
          [-88.5869, 41.2097],
          [-88.5709, 41.2463],
          [-88.5892, 41.2895],
          [-88.5927, 41.3548],
          [-88.577, 41.3893],
          [-88.6156, 41.4712],
          [-88.6928, 41.456],
          [-88.7455, 41.4568],
          [-88.7741, 41.4846],
          [-88.935, 41.4832],
          [-88.9652, 41.5266],
          [-89.0138, 41.5388],
          [-89.0518, 41.6266],
          [-88.9779, 41.6422],
          [-88.9784, 41.6767],
          [-88.9396, 41.688],
          [-88.9615, 41.7326],
          [-89.0179, 41.7424],
          [-89.064, 41.8034],
          [-89.1125, 41.8175],
          [-89.1025, 41.8467],
          [-89.0249, 41.862],
          [-89.0128, 41.8909],
          [-88.9804, 41.8914],
          [-88.9517, 41.8917],
          [-88.9414, 41.8918],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Rock Island Mkt": {
    type: "Feature",
    properties: { code: "IL_ROC", name: "Rock Island Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-92.2199, 41.4238],
          [-92.1761, 41.3949],
          [-92.1223, 41.3988],
          [-92.1223, 41.3668],
          [-92.064, 41.3232],
          [-92.0618, 41.2688],
          [-91.9937, 41.2594],
          [-91.9367, 41.2218],
          [-91.888, 41.2574],
          [-91.8335, 41.2563],
          [-91.7916, 41.2021],
          [-91.7113, 41.1919],
          [-91.6016, 41.2023],
          [-91.6106, 41.2507],
          [-91.4855, 41.2496],
          [-91.4839, 41.4278],
          [-91.5065, 41.511],
          [-91.4448, 41.5546],
          [-91.3669, 41.5538],
          [-91.3664, 41.5984],
          [-91.2881, 41.6273],
          [-91.2882, 41.6634],
          [-91.2201, 41.6849],
          [-91.2523, 41.7416],
          [-91.3176, 41.7934],
          [-91.3076, 41.8163],
          [-91.2348, 41.8235],
          [-91.191, 41.7866],
          [-91.1772, 41.839],
          [-91.1081, 41.838],
          [-91.0153, 41.8081],
          [-90.9656, 41.8208],
          [-90.8985, 41.8151],
          [-90.8744, 41.9168],
          [-90.8163, 41.9165],
          [-90.7958, 41.8656],
          [-90.7331, 41.9019],
          [-90.7326, 41.9308],
          [-90.6454, 41.9016],
          [-90.5484, 41.9018],
          [-90.5486, 41.9842],
          [-90.5294, 42.033],
          [-90.4885, 42.0411],
          [-90.4714, 42.0086],
          [-90.3691, 42.0042],
          [-90.2284, 42.0332],
          [-90.1692, 41.9826],
          [-90.1542, 42.0331],
          [-90.0695, 42.0226],
          [-90.0675, 41.9959],
          [-90.0081, 42.0038],
          [-89.9882, 41.9312],
          [-89.8821, 41.9157],
          [-89.8478, 41.8695],
          [-89.8481, 41.7257],
          [-89.8257, 41.6857],
          [-89.7288, 41.6857],
          [-89.7093, 41.6452],
          [-89.6324, 41.6568],
          [-89.621, 41.6065],
          [-89.569, 41.6267],
          [-89.4968, 41.6134],
          [-89.4966, 41.7075],
          [-89.4106, 41.7075],
          [-89.3944, 41.7384],
          [-89.4192, 41.7669],
          [-89.3423, 41.7798],
          [-89.1685, 41.7841],
          [-89.1125, 41.8175],
          [-89.064, 41.8034],
          [-89.0179, 41.7424],
          [-88.9615, 41.7326],
          [-88.9396, 41.688],
          [-88.9784, 41.6767],
          [-88.9779, 41.6422],
          [-89.0518, 41.6266],
          [-89.0138, 41.5388],
          [-88.9652, 41.5266],
          [-88.935, 41.4832],
          [-88.7741, 41.4846],
          [-88.7455, 41.4568],
          [-88.6928, 41.456],
          [-88.6156, 41.4712],
          [-88.577, 41.3893],
          [-88.5927, 41.3548],
          [-88.5892, 41.2895],
          [-88.5709, 41.2463],
          [-88.5869, 41.2097],
          [-88.6253, 41.1948],
          [-88.6452, 41.2238],
          [-88.722, 41.2231],
          [-88.7216, 41.1939],
          [-88.6832, 41.1799],
          [-88.6629, 41.1072],
          [-88.5697, 41.108],
          [-88.6434, 41.0479],
          [-88.6824, 41.0041],
          [-88.663, 40.9728],
          [-88.7153, 40.9607],
          [-88.7866, 40.996],
          [-88.8472, 40.9735],
          [-88.8548, 40.9441],
          [-88.9312, 40.9571],
          [-88.9314, 40.9278],
          [-89.067, 40.9262],
          [-89.0859, 40.9409],
          [-89.1846, 40.9395],
          [-89.2135, 40.9575],
          [-89.3151, 40.967],
          [-89.3159, 41.0405],
          [-89.3028, 41.0902],
          [-89.3422, 41.1114],
          [-89.3202, 41.1654],
          [-89.3581, 41.2333],
          [-89.4665, 41.2339],
          [-89.4665, 41.1849],
          [-89.505, 41.1486],
          [-89.5632, 41.1487],
          [-89.592, 41.1122],
          [-89.581, 41.0756],
          [-89.6303, 41.0612],
          [-89.6104, 40.9881],
          [-89.6387, 40.9738],
          [-89.7161, 40.974],
          [-89.7159, 41.0144],
          [-89.7693, 41.0175],
          [-89.812, 40.9748],
          [-89.8866, 40.9751],
          [-89.908, 40.9167],
          [-89.9659, 40.9169],
          [-89.9673, 40.8847],
          [-90.1006, 40.8039],
          [-90.102, 40.6259],
          [-90.1402, 40.6262],
          [-90.235, 40.5822],
          [-90.1246, 40.5603],
          [-90.1066, 40.5234],
          [-90.1047, 40.4629],
          [-90.1825, 40.4366],
          [-90.2726, 40.4624],
          [-90.2872, 40.4442],
          [-90.2382, 40.4008],
          [-90.2133, 40.3546],
          [-90.2037, 40.2956],
          [-90.2097, 40.2915],
          [-90.3166, 40.287],
          [-90.3646, 40.2644],
          [-90.4246, 40.2763],
          [-90.4506, 40.2543],
          [-90.4796, 40.2547],
          [-90.4749, 40.2694],
          [-90.4918, 40.2613],
          [-90.5043, 40.2652],
          [-90.5082, 40.2481],
          [-90.5179, 40.254],
          [-90.5152, 40.2629],
          [-90.5367, 40.2638],
          [-90.5492, 40.2562],
          [-90.571, 40.2648],
          [-90.5838, 40.238],
          [-90.6157, 40.2342],
          [-90.6219, 40.2267],
          [-90.6479, 40.1685],
          [-90.6656, 40.175],
          [-90.7198, 40.2207],
          [-90.7407, 40.2238],
          [-90.7011, 40.2439],
          [-90.7008, 40.2713],
          [-90.6785, 40.2713],
          [-90.6779, 40.2787],
          [-90.7542, 40.2809],
          [-90.7349, 40.3036],
          [-90.7538, 40.3019],
          [-90.7577, 40.3611],
          [-90.7349, 40.3755],
          [-90.7494, 40.3707],
          [-90.7539, 40.3937],
          [-90.7351, 40.3943],
          [-90.74, 40.4228],
          [-90.7246, 40.4329],
          [-90.7436, 40.4454],
          [-90.7182, 40.451],
          [-90.7213, 40.4631],
          [-90.7735, 40.4565],
          [-90.7832, 40.4787],
          [-90.7906, 40.5005],
          [-90.7997, 40.5227],
          [-90.8379, 40.5242],
          [-90.8382, 40.5082],
          [-90.8766, 40.4983],
          [-90.8782, 40.4438],
          [-90.9076, 40.453],
          [-90.9076, 40.4627],
          [-90.965, 40.4605],
          [-90.9647, 40.4753],
          [-90.9456, 40.4759],
          [-90.9456, 40.4991],
          [-90.9749, 40.4969],
          [-90.9694, 40.4774],
          [-91.0202, 40.4802],
          [-91.0211, 40.4892],
          [-91.0043, 40.4883],
          [-91.012, 40.5101],
          [-91.0228, 40.5282],
          [-91.0498, 40.5162],
          [-91.0521, 40.5229],
          [-91.0475, 40.5329],
          [-91.0406, 40.5311],
          [-91.0463, 40.5338],
          [-91.0566, 40.5325],
          [-91.054, 40.547],
          [-91.0902, 40.5479],
          [-91.0413, 40.6057],
          [-91.0155, 40.6086],
          [-91.0065, 40.6208],
          [-90.9824, 40.6219],
          [-90.9819, 40.6365],
          [-91.0338, 40.6351],
          [-91.0488, 40.6556],
          [-91.0995, 40.6594],
          [-91.096, 40.6666],
          [-91.1031, 40.6669],
          [-91.187, 40.6373],
          [-91.2531, 40.638],
          [-91.3487, 40.6097],
          [-91.4069, 40.5476],
          [-91.4041, 40.5391],
          [-91.3845, 40.5309],
          [-91.3642, 40.5],
          [-91.3818, 40.4426],
          [-91.3726, 40.4012],
          [-91.382, 40.3876],
          [-91.4157, 40.3814],
          [-91.4194, 40.3783],
          [-91.4412, 40.3863],
          [-91.4525, 40.3755],
          [-91.465, 40.3762],
          [-91.4651, 40.3853],
          [-91.4823, 40.3821],
          [-91.4885, 40.4043],
          [-91.5246, 40.4108],
          [-91.5191, 40.4328],
          [-91.5336, 40.4383],
          [-91.5251, 40.4578],
          [-91.5747, 40.4657],
          [-91.5908, 40.4923],
          [-91.6214, 40.5101],
          [-91.6201, 40.5408],
          [-91.6887, 40.5574],
          [-91.6864, 40.5809],
          [-91.7291, 40.6136],
          [-91.7859, 40.6115],
          [-91.7851, 40.5972],
          [-91.8325, 40.6098],
          [-92.0964, 40.6018],
          [-92.7574, 40.5889],
          [-93.1198, 40.5833],
          [-93.1838, 40.5821],
          [-93.2127, 40.5939],
          [-93.2125, 40.6741],
          [-93.174, 40.7464],
          [-93.2117, 40.7794],
          [-93.2111, 40.8411],
          [-93.1547, 40.8414],
          [-93.1549, 40.8846],
          [-93.0976, 40.8985],
          [-93.0985, 41.1023],
          [-93.0606, 41.0665],
          [-92.977, 41.0737],
          [-92.9601, 41.0924],
          [-92.9073, 41.0858],
          [-92.8411, 41.1036],
          [-92.8329, 41.1568],
          [-92.7751, 41.1966],
          [-92.8512, 41.2408],
          [-92.826, 41.2583],
          [-92.8134, 41.3139],
          [-92.7319, 41.3143],
          [-92.7327, 41.3573],
          [-92.6805, 41.3787],
          [-92.5748, 41.3358],
          [-92.5362, 41.3602],
          [-92.498, 41.4085],
          [-92.4574, 41.3834],
          [-92.3544, 41.3725],
          [-92.3303, 41.3387],
          [-92.2968, 41.351],
          [-92.2199, 41.4238],
        ],
        [
          [-90.315, 40.645],
          [-90.2147, 40.6474],
          [-90.2148, 40.6913],
          [-90.139, 40.6914],
          [-90.1387, 40.7133],
          [-90.2664, 40.7137],
          [-90.315, 40.645],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Rockford Mkt": {
    type: "Feature",
    properties: { code: "IL_RFD", name: "Rockford Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-90.3627, 42.507],
          [-90.0737, 42.5083],
          [-89.6676, 42.505],
          [-89.2508, 42.498],
          [-88.746, 42.4926],
          [-88.7453, 42.4729],
          [-88.7331, 42.4689],
          [-88.7355, 42.4585],
          [-88.7062, 42.4295],
          [-88.7057, 42.3424],
          [-88.6573, 42.3424],
          [-88.6572, 42.3279],
          [-88.7057, 42.3279],
          [-88.7056, 42.2695],
          [-88.7253, 42.2611],
          [-88.7055, 42.2564],
          [-88.7056, 42.1536],
          [-88.7401, 42.168],
          [-88.7756, 42.168],
          [-88.8033, 42.157],
          [-88.87, 42.1528],
          [-88.8771, 42.1602],
          [-88.8614, 42.1598],
          [-88.9005, 42.189],
          [-88.8871, 42.1601],
          [-88.9643, 42.1521],
          [-88.9392, 42.1015],
          [-88.9683, 42.0975],
          [-88.9904, 42.0364],
          [-88.9856, 42.0219],
          [-89.0225, 42.0017],
          [-88.9871, 41.9785],
          [-88.9066, 41.9307],
          [-88.9305, 41.9314],
          [-88.942, 41.9439],
          [-88.9414, 41.8918],
          [-88.9517, 41.8917],
          [-88.9804, 41.8914],
          [-89.0128, 41.8909],
          [-89.0249, 41.862],
          [-89.1025, 41.8467],
          [-89.1125, 41.8175],
          [-89.1685, 41.7841],
          [-89.3423, 41.7798],
          [-89.4192, 41.7669],
          [-89.3944, 41.7384],
          [-89.4106, 41.7075],
          [-89.4966, 41.7075],
          [-89.4968, 41.6134],
          [-89.569, 41.6267],
          [-89.621, 41.6065],
          [-89.6324, 41.6568],
          [-89.7093, 41.6452],
          [-89.7288, 41.6857],
          [-89.8257, 41.6857],
          [-89.8481, 41.7257],
          [-89.8478, 41.8695],
          [-89.8821, 41.9157],
          [-89.9882, 41.9312],
          [-90.0081, 42.0038],
          [-90.0675, 41.9959],
          [-90.0695, 42.0226],
          [-90.1542, 42.0331],
          [-90.1629, 42.1167],
          [-90.2095, 42.1527],
          [-90.3911, 42.2255],
          [-90.4309, 42.2782],
          [-90.4159, 42.3227],
          [-90.4775, 42.3839],
          [-90.555, 42.4161],
          [-90.5707, 42.4417],
          [-90.654, 42.4785],
          [-90.6409, 42.5083],
          [-90.3627, 42.507],
        ],
        [
          [-88.9614, 41.9288],
          [-88.9627, 41.9352],
          [-88.9686, 41.9322],
          [-88.9614, 41.9288],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Evansville Mkt": {
    type: "Feature",
    properties: { code: "IN_EVA", name: "Evansville Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-89.2181, 38.8009],
          [-89.1898, 38.8024],
          [-89.1599, 38.8709],
          [-89.1123, 38.8923],
          [-89.0854, 38.9256],
          [-89.0485, 38.9252],
          [-89.0301, 38.8811],
          [-88.9762, 38.8782],
          [-88.9149, 38.9451],
          [-88.8511, 38.9793],
          [-88.8066, 38.9554],
          [-88.7596, 38.9063],
          [-88.6749, 38.8448],
          [-88.644, 38.8451],
          [-88.5754, 38.8784],
          [-88.4566, 38.9128],
          [-88.3803, 38.8968],
          [-88.3801, 38.838],
          [-88.3407, 38.7905],
          [-88.2996, 38.7904],
          [-88.2955, 38.8344],
          [-88.2245, 38.8178],
          [-88.2245, 38.7526],
          [-88.1682, 38.7372],
          [-88.1673, 38.6712],
          [-88.1492, 38.6131],
          [-88.1479, 38.534],
          [-88.257, 38.5555],
          [-88.2755, 38.522],
          [-88.2581, 38.4408],
          [-88.2351, 38.4011],
          [-88.2038, 38.3862],
          [-88.1677, 38.403],
          [-88.1941, 38.4385],
          [-88.1479, 38.4969],
          [-88.0081, 38.5042],
          [-87.9524, 38.4752],
          [-87.9299, 38.5043],
          [-87.8733, 38.4964],
          [-87.8645, 38.5701],
          [-87.8131, 38.5695],
          [-87.7653, 38.499],
          [-87.714, 38.4799],
          [-87.6542, 38.5119],
          [-87.6607, 38.5411],
          [-87.5937, 38.6674],
          [-87.5196, 38.6972],
          [-87.4965, 38.7427],
          [-87.497, 38.7791],
          [-87.5506, 38.8596],
          [-87.5441, 38.8951],
          [-87.4672, 38.9023],
          [-87.2408, 38.9017],
          [-87.2407, 38.9654],
          [-87.1673, 38.96],
          [-87.1534, 38.9075],
          [-87.0358, 38.9025],
          [-86.7944, 38.9044],
          [-86.7802, 38.8101],
          [-86.6832, 38.8056],
          [-86.7525, 38.7085],
          [-86.6833, 38.719],
          [-86.6824, 38.5663],
          [-86.729, 38.569],
          [-86.7426, 38.5262],
          [-86.722, 38.4704],
          [-86.682, 38.4739],
          [-86.6875, 38.3955],
          [-86.6424, 38.395],
          [-86.6522, 38.3112],
          [-86.6795, 38.2631],
          [-86.6561, 38.2329],
          [-86.5283, 38.2318],
          [-86.5031, 38.2071],
          [-86.4604, 38.2068],
          [-86.4662, 38.1068],
          [-86.434, 38.0868],
          [-86.4522, 38.0505],
          [-86.5118, 38.0444],
          [-86.5252, 37.9682],
          [-86.507, 37.9364],
          [-86.5342, 37.917],
          [-86.5886, 37.9212],
          [-86.5981, 37.8674],
          [-86.6661, 37.8358],
          [-86.678, 37.7928],
          [-86.6222, 37.6551],
          [-86.6407, 37.6194],
          [-86.6066, 37.5191],
          [-86.6254, 37.4815],
          [-86.6009, 37.4595],
          [-86.6215, 37.4232],
          [-86.612, 37.3953],
          [-86.741, 37.316],
          [-86.8156, 37.329],
          [-86.8182, 37.3044],
          [-86.7862, 37.2399],
          [-86.8614, 37.2519],
          [-86.9064, 37.2085],
          [-86.9157, 37.1737],
          [-86.8704, 37.1385],
          [-86.8942, 37.0894],
          [-86.9413, 37.0691],
          [-86.9764, 37.0737],
          [-87.1178, 37.0447],
          [-87.25, 37.0404],
          [-87.2844, 37.108],
          [-87.3343, 37.0847],
          [-87.3969, 37.1211],
          [-87.4202, 37.0938],
          [-87.5247, 37.0833],
          [-87.5488, 37.1133],
          [-87.625, 37.1361],
          [-87.5971, 37.0755],
          [-87.6403, 37.0559],
          [-87.6836, 37.0609],
          [-87.7479, 36.9746],
          [-87.877, 36.9601],
          [-87.9392, 36.9909],
          [-87.9419, 37.0304],
          [-87.975, 37.0675],
          [-88.0008, 37.0599],
          [-88.0402, 37.1388],
          [-88.0839, 37.1494],
          [-88.0768, 37.1767],
          [-88.1327, 37.1885],
          [-88.1352, 37.2136],
          [-88.0138, 37.2493],
          [-87.9553, 37.2747],
          [-87.9349, 37.3038],
          [-87.8636, 37.344],
          [-87.864, 37.3793],
          [-87.9288, 37.4086],
          [-87.9419, 37.4468],
          [-87.9887, 37.4354],
          [-88.0421, 37.4684],
          [-88.0341, 37.5135],
          [-88.088, 37.5351],
          [-88.1334, 37.5742],
          [-88.1582, 37.6645],
          [-88.125, 37.7071],
          [-88.0638, 37.7386],
          [-88.0294, 37.8036],
          [-88.0648, 37.8492],
          [-88.2012, 37.8562],
          [-88.2336, 37.8279],
          [-88.3557, 37.8281],
          [-88.3851, 37.8886],
          [-88.4149, 37.9075],
          [-88.5867, 37.9069],
          [-88.6718, 37.9215],
          [-88.6701, 37.851],
          [-88.7068, 37.8474],
          [-88.7071, 37.7844],
          [-88.7484, 37.7976],
          [-88.7706, 37.8419],
          [-88.9014, 37.8485],
          [-89.0074, 37.8403],
          [-89.056, 37.862],
          [-89.0405, 37.8961],
          [-89.0953, 37.9142],
          [-89.0958, 37.9501],
          [-89.3432, 37.9517],
          [-89.346, 37.9619],
          [-89.3534, 37.9619],
          [-89.345, 38.0116],
          [-89.3486, 38.0216],
          [-89.3641, 38.0283],
          [-89.3634, 38.0614],
          [-89.3273, 38.0476],
          [-89.3248, 38.0628],
          [-89.3191, 38.0676],
          [-89.3171, 38.0614],
          [-89.3048, 38.0821],
          [-89.3017, 38.0902],
          [-89.292, 38.112],
          [-89.2787, 38.1191],
          [-89.2598, 38.1264],
          [-89.2598, 38.1408],
          [-89.3153, 38.1161],
          [-89.3431, 38.127],
          [-89.3359, 38.1568],
          [-89.2845, 38.1739],
          [-89.2973, 38.1856],
          [-89.3196, 38.1895],
          [-89.3149, 38.2004],
          [-89.3193, 38.2138],
          [-89.3086, 38.2232],
          [-89.3061, 38.2687],
          [-89.2812, 38.2985],
          [-89.2856, 38.3092],
          [-89.2923, 38.3021],
          [-89.2974, 38.3073],
          [-89.294, 38.3166],
          [-89.3098, 38.3168],
          [-89.2991, 38.3454],
          [-89.2897, 38.347],
          [-89.2929, 38.3873],
          [-89.3051, 38.3897],
          [-89.2751, 38.391],
          [-89.3355, 38.3903],
          [-89.3214, 38.398],
          [-89.3399, 38.4141],
          [-89.3297, 38.4278],
          [-89.2744, 38.4347],
          [-89.2707, 38.4421],
          [-89.3486, 38.4422],
          [-89.3641, 38.4566],
          [-89.37, 38.4417],
          [-89.394, 38.4415],
          [-89.3989, 38.4597],
          [-89.3868, 38.4612],
          [-89.3923, 38.4712],
          [-89.3805, 38.4808],
          [-89.3926, 38.4863],
          [-89.3859, 38.4896],
          [-89.3919, 38.4951],
          [-89.3672, 38.4991],
          [-89.3602, 38.5165],
          [-89.3392, 38.5222],
          [-89.282, 38.5666],
          [-89.2772, 38.5815],
          [-89.2546, 38.5863],
          [-89.2347, 38.5658],
          [-89.1862, 38.5645],
          [-89.1785, 38.5776],
          [-89.1699, 38.5772],
          [-89.1779, 38.6069],
          [-89.15, 38.6054],
          [-89.1406, 38.6121],
          [-89.1588, 38.6183],
          [-89.1401, 38.6303],
          [-89.1584, 38.6351],
          [-89.1564, 38.6491],
          [-89.1394, 38.6499],
          [-89.1388, 38.6961],
          [-89.1384, 38.7364],
          [-89.2542, 38.742],
          [-89.251, 38.7521],
          [-89.2493, 38.746],
          [-89.238, 38.7543],
          [-89.2455, 38.7593],
          [-89.2443, 38.7664],
          [-89.2447, 38.7769],
          [-89.2327, 38.7778],
          [-89.2372, 38.7826],
          [-89.2181, 38.8009],
        ],
        [
          [-89.2776, 38.5411],
          [-89.2731, 38.5303],
          [-89.2637, 38.5303],
          [-89.2496, 38.5442],
          [-89.2634, 38.5522],
          [-89.2776, 38.5411],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "St Cloud Mkt": {
    type: "Feature",
    properties: { code: "MN_STC", name: "St Cloud Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-93.2789, 46.6132],
            [-93.2428, 46.6083],
            [-93.2429, 46.6232],
            [-93.1802, 46.623],
            [-93.1582, 46.601],
            [-93.1597, 46.5719],
            [-93.1791, 46.5778],
            [-93.1758, 46.5539],
            [-93.1798, 46.5651],
            [-93.2005, 46.5637],
            [-93.2644, 46.5586],
            [-93.2841, 46.5485],
            [-93.2836, 46.5808],
            [-93.2891, 46.5971],
            [-93.2789, 46.6132],
          ],
        ],
        [
          [
            [-93.7742, 47.1597],
            [-93.7742, 47.149],
            [-93.7759, 47.1466],
            [-93.7795, 47.1467],
            [-93.7778, 47.1497],
            [-93.7777, 47.1567],
            [-93.7742, 47.1597],
          ],
        ],
        [
          [
            [-93.7797, 47.2988],
            [-93.7833, 47.2994],
            [-93.7836, 47.3054],
            [-93.7777, 47.3037],
            [-93.7753, 47.3024],
            [-93.7749, 47.3006],
            [-93.7797, 47.2988],
          ],
        ],
        [
          [
            [-94.4163, 47.444],
            [-94.3226, 47.4156],
            [-94.118, 47.4807],
            [-94.1525, 47.388],
            [-94.1848, 47.3549],
            [-94.2449, 47.3582],
            [-94.3493, 47.4232],
            [-94.4163, 47.444],
          ],
        ],
        [
          [
            [-95.9869, 45.8612],
            [-95.9743, 45.9632],
            [-95.8111, 45.9628],
            [-95.8635, 46.0065],
            [-95.8925, 46.0502],
            [-95.8966, 46.1082],
            [-95.9984, 46.1083],
            [-96.0191, 46.1955],
            [-95.9372, 46.2243],
            [-95.869, 46.2242],
            [-95.8026, 46.2072],
            [-95.7083, 46.1367],
            [-95.5869, 46.1367],
            [-95.5112, 46.1479],
            [-95.512, 46.2239],
            [-95.3903, 46.195],
            [-95.3637, 46.2459],
            [-95.2506, 46.2664],
            [-95.25, 46.4211],
            [-95.2703, 46.4489],
            [-95.1973, 46.4852],
            [-95.2382, 46.5357],
            [-95.2398, 46.5721],
            [-95.2803, 46.5753],
            [-95.2887, 46.7167],
            [-95.3924, 46.7163],
            [-95.4111, 46.7478],
            [-95.373, 46.7743],
            [-95.3729, 46.8033],
            [-95.3288, 46.8322],
            [-95.2685, 46.8331],
            [-95.2677, 46.8621],
            [-95.2262, 46.9204],
            [-95.2249, 46.9781],
            [-95.2539, 46.9842],
            [-95.2547, 47.0286],
            [-95.2917, 47.028],
            [-95.2973, 47.1518],
            [-95.3191, 47.2243],
            [-95.2757, 47.2531],
            [-95.2124, 47.2536],
            [-95.1909, 47.2752],
            [-95.115, 47.2829],
            [-95.0677, 47.3163],
            [-95.0499, 47.3684],
            [-94.9736, 47.3682],
            [-94.9052, 47.3386],
            [-94.8628, 47.3672],
            [-94.756, 47.3668],
            [-94.6976, 47.3515],
            [-94.7371, 47.3097],
            [-94.6637, 47.3084],
            [-94.6637, 47.2246],
            [-94.5651, 47.222],
            [-94.5804, 47.1863],
            [-94.5462, 47.1702],
            [-94.5109, 47.2118],
            [-94.434, 47.2544],
            [-94.3753, 47.2235],
            [-94.3172, 47.2698],
            [-94.2662, 47.294],
            [-94.2483, 47.2618],
            [-94.3109, 47.2138],
            [-94.3223, 47.1748],
            [-94.2507, 47.1554],
            [-94.2502, 47.0969],
            [-94.2855, 47.0928],
            [-94.3282, 47.021],
            [-94.3044, 46.9909],
            [-94.2484, 46.9746],
            [-94.2439, 46.9511],
            [-94.3094, 46.9179],
            [-94.3212, 46.8634],
            [-94.228, 46.8342],
            [-94.1652, 46.8338],
            [-94.1592, 46.8047],
            [-94.0739, 46.8032],
            [-93.9568, 46.8036],
            [-93.9468, 46.8036],
            [-93.9411, 46.8088],
            [-93.9382, 46.8074],
            [-93.9408, 46.8036],
            [-93.776, 46.8029],
            [-93.7773, 46.7248],
            [-93.7665, 46.7224],
            [-93.7033, 46.7255],
            [-93.6737, 46.7396],
            [-93.6773, 46.7471],
            [-93.7072, 46.7441],
            [-93.6755, 46.7541],
            [-93.6748, 46.7984],
            [-93.6767, 46.8246],
            [-93.678, 46.8641],
            [-93.6778, 46.8754],
            [-93.6304, 46.8746],
            [-93.614, 46.8815],
            [-93.6141, 46.8261],
            [-93.5713, 46.8265],
            [-93.5711, 46.7866],
            [-93.5314, 46.7802],
            [-93.5275, 46.7547],
            [-93.4855, 46.7535],
            [-93.4848, 46.7894],
            [-93.4429, 46.7895],
            [-93.4424, 46.7963],
            [-93.4338, 46.7916],
            [-93.4481, 46.7729],
            [-93.4423, 46.7535],
            [-93.4218, 46.7419],
            [-93.4137, 46.7421],
            [-93.4065, 46.7461],
            [-93.4012, 46.747],
            [-93.4005, 46.7536],
            [-93.3989, 46.7569],
            [-93.3907, 46.7596],
            [-93.3913, 46.7658],
            [-93.3761, 46.7828],
            [-93.3607, 46.825],
            [-93.4323, 46.8266],
            [-93.4305, 46.8694],
            [-93.3896, 46.8695],
            [-93.3896, 46.9125],
            [-93.3681, 46.889],
            [-93.3694, 46.8814],
            [-93.359, 46.8806],
            [-93.3618, 46.8685],
            [-93.3565, 46.8493],
            [-93.3477, 46.8395],
            [-93.3406, 46.8202],
            [-93.3277, 46.7909],
            [-93.3462, 46.7961],
            [-93.35, 46.7835],
            [-93.3748, 46.7807],
            [-93.3783, 46.7663],
            [-93.3846, 46.7713],
            [-93.3908, 46.7629],
            [-93.3862, 46.7543],
            [-93.3942, 46.7555],
            [-93.3974, 46.7573],
            [-93.3983, 46.7569],
            [-93.3996, 46.7528],
            [-93.3963, 46.7503],
            [-93.3993, 46.7411],
            [-93.4071, 46.7372],
            [-93.4224, 46.7415],
            [-93.4202, 46.7325],
            [-93.4319, 46.7353],
            [-93.44, 46.7273],
            [-93.4319, 46.7178],
            [-93.4015, 46.7215],
            [-93.3592, 46.7097],
            [-93.3482, 46.6666],
            [-93.4047, 46.6402],
            [-93.3971, 46.6237],
            [-93.4223, 46.6088],
            [-93.3955, 46.6065],
            [-93.4669, 46.5594],
            [-93.4238, 46.5506],
            [-93.3676, 46.5643],
            [-93.3662, 46.5386],
            [-93.3138, 46.5385],
            [-93.3197, 46.5317],
            [-93.2914, 46.5178],
            [-93.2894, 46.4997],
            [-93.275, 46.4797],
            [-93.3059, 46.4638],
            [-93.3059, 46.4524],
            [-93.2829, 46.4531],
            [-93.2694, 46.4444],
            [-93.3161, 46.4105],
            [-93.3106, 46.3623],
            [-93.2618, 46.3904],
            [-93.1991, 46.3834],
            [-93.1837, 46.3835],
            [-93.1711, 46.4051],
            [-93.1403, 46.4193],
            [-93.0966, 46.4191],
            [-93.0541, 46.3903],
            [-93.0538, 46.3321],
            [-93.135, 46.3324],
            [-93.1548, 46.3178],
            [-93.1994, 46.3098],
            [-93.2029, 46.2818],
            [-93.2615, 46.2736],
            [-93.2626, 46.2385],
            [-93.2407, 46.2224],
            [-93.2402, 46.1951],
            [-93.2202, 46.1669],
            [-93.233, 46.1588],
            [-93.179, 46.1585],
            [-93.1856, 46.1264],
            [-93.2213, 46.125],
            [-93.2217, 46.1146],
            [-93.3259, 46.1155],
            [-93.338, 46.0462],
            [-93.3694, 46.0465],
            [-93.3711, 46.0103],
            [-93.3933, 46.0105],
            [-93.3891, 46.0498],
            [-93.4258, 46.0437],
            [-93.4556, 46.059],
            [-93.4742, 46.0579],
            [-93.4742, 46.0104],
            [-93.4326, 45.996],
            [-93.4478, 45.9918],
            [-93.4518, 45.9819],
            [-93.431, 45.9687],
            [-93.4319, 45.934],
            [-93.42, 45.9335],
            [-93.4332, 45.9005],
            [-93.4106, 45.9093],
            [-93.4148, 45.8977],
            [-93.4329, 45.8908],
            [-93.3891, 45.8892],
            [-93.3891, 45.846],
            [-93.3382, 45.8462],
            [-93.3308, 45.8527],
            [-93.348, 45.7961],
            [-93.2971, 45.7962],
            [-93.3465, 45.7856],
            [-93.3542, 45.7765],
            [-93.3692, 45.7896],
            [-93.3692, 45.7667],
            [-93.3487, 45.7598],
            [-93.3484, 45.7495],
            [-93.3072, 45.7494],
            [-93.3072, 45.7384],
            [-93.3237, 45.7385],
            [-93.3086, 45.731],
            [-93.379, 45.7309],
            [-93.3822, 45.7237],
            [-93.4068, 45.7168],
            [-93.4151, 45.717],
            [-93.416, 45.7316],
            [-93.5138, 45.7344],
            [-93.5127, 45.7144],
            [-93.4933, 45.7128],
            [-93.5124, 45.6884],
            [-93.5127, 45.6778],
            [-93.5441, 45.6751],
            [-93.5465, 45.6693],
            [-93.5683, 45.681],
            [-93.5705, 45.6612],
            [-93.5962, 45.6763],
            [-93.5856, 45.6771],
            [-93.5992, 45.6809],
            [-93.6167, 45.6769],
            [-93.6671, 45.6779],
            [-93.6575, 45.6668],
            [-93.6669, 45.6636],
            [-93.6666, 45.64],
            [-93.7288, 45.6439],
            [-93.728, 45.6246],
            [-93.7605, 45.6262],
            [-93.76, 45.589],
            [-93.8009, 45.5746],
            [-93.8213, 45.5704],
            [-93.8215, 45.56],
            [-93.9223, 45.5598],
            [-93.9529, 45.509],
            [-93.9748, 45.5155],
            [-93.9772, 45.4764],
            [-94.0063, 45.4857],
            [-94.0165, 45.451],
            [-94.0407, 45.4604],
            [-94.0714, 45.4546],
            [-94.0425, 45.4398],
            [-94.0421, 45.4246],
            [-94.0277, 45.4255],
            [-94.0387, 45.4193],
            [-94.0512, 45.4354],
            [-94.0639, 45.4331],
            [-94.0958, 45.4518],
            [-94.1078, 45.4724],
            [-94.1035, 45.4533],
            [-94.0821, 45.4382],
            [-94.0857, 45.4319],
            [-94.1, 45.4382],
            [-94.0806, 45.4257],
            [-94.1356, 45.4493],
            [-94.1381, 45.4145],
            [-94.1692, 45.4278],
            [-94.1964, 45.4313],
            [-94.2157, 45.4223],
            [-94.244, 45.4247],
            [-94.2462, 45.4429],
            [-94.3091, 45.4212],
            [-94.325, 45.4496],
            [-94.3278, 45.4285],
            [-94.3192, 45.4213],
            [-94.3583, 45.4104],
            [-94.3591, 45.3848],
            [-94.3689, 45.3848],
            [-94.3696, 45.3775],
            [-94.4139, 45.3775],
            [-94.3937, 45.3992],
            [-94.4244, 45.4133],
            [-94.429, 45.392],
            [-94.4752, 45.3917],
            [-94.4846, 45.3627],
            [-94.5309, 45.3621],
            [-94.5362, 45.3688],
            [-94.5562, 45.3694],
            [-94.5569, 45.3894],
            [-94.5769, 45.3941],
            [-94.5781, 45.4127],
            [-94.5883, 45.4125],
            [-94.5874, 45.3906],
            [-94.6126, 45.3905],
            [-94.6128, 45.4003],
            [-94.6208, 45.3998],
            [-94.6404, 45.3747],
            [-94.6325, 45.3656],
            [-94.6119, 45.3733],
            [-94.6091, 45.3258],
            [-94.6402, 45.3257],
            [-94.6503, 45.3105],
            [-94.6456, 45.2901],
            [-94.6424, 45.2756],
            [-94.6426, 45.2723],
            [-94.7636, 45.2682],
            [-94.8048, 45.2829],
            [-94.7838, 45.3261],
            [-94.7838, 45.3886],
            [-94.825, 45.3994],
            [-94.8457, 45.3696],
            [-94.9518, 45.3488],
            [-94.9684, 45.3691],
            [-95.0498, 45.3835],
            [-95.1316, 45.3838],
            [-95.1935, 45.3693],
            [-95.2198, 45.4128],
            [-95.521, 45.4121],
            [-95.5312, 45.4665],
            [-95.593, 45.4703],
            [-95.6035, 45.4921],
            [-95.6861, 45.4922],
            [-95.6935, 45.5717],
            [-95.7891, 45.5715],
            [-95.7585, 45.6327],
            [-95.7588, 45.7163],
            [-95.8154, 45.7162],
            [-95.8415, 45.7599],
            [-95.9232, 45.7596],
            [-95.9239, 45.8321],
            [-95.9861, 45.832],
            [-95.9869, 45.8612],
          ],
          [
            [-93.8104, 46.2508],
            [-93.8041, 46.2472],
            [-93.8012, 46.2483],
            [-93.7973, 46.2475],
            [-93.7961, 46.2432],
            [-93.5307, 46.2471],
            [-93.5368, 46.2851],
            [-93.5258, 46.3334],
            [-93.5375, 46.3489],
            [-93.5705, 46.363],
            [-93.6933, 46.3553],
            [-93.789, 46.3247],
            [-93.8107, 46.3099],
            [-93.8104, 46.2508],
          ],
          [
            [-93.6654, 46.5493],
            [-93.5802, 46.5492],
            [-93.5596, 46.595],
            [-93.5802, 46.6021],
            [-93.64, 46.595],
            [-93.6388, 46.603],
            [-93.6409, 46.6036],
            [-93.6435, 46.6021],
            [-93.6448, 46.6019],
            [-93.6458, 46.6046],
            [-93.6453, 46.6062],
            [-93.6397, 46.6079],
            [-93.6392, 46.609],
            [-93.6461, 46.6163],
            [-93.6466, 46.6224],
            [-93.642, 46.6274],
            [-93.6405, 46.6279],
            [-93.6371, 46.6257],
            [-93.6283, 46.6239],
            [-93.6262, 46.6246],
            [-93.6263, 46.627],
            [-93.6314, 46.6316],
            [-93.6325, 46.6337],
            [-93.6325, 46.635],
            [-93.598, 46.6649],
            [-93.5989, 46.6673],
            [-93.6059, 46.6669],
            [-93.6111, 46.6625],
            [-93.6122, 46.6625],
            [-93.612, 46.6637],
            [-93.6056, 46.6718],
            [-93.602, 46.6717],
            [-93.6016, 46.6701],
            [-93.6002, 46.6686],
            [-93.5953, 46.6671],
            [-93.5923, 46.6671],
            [-93.5911, 46.6681],
            [-93.5895, 46.6719],
            [-93.5868, 46.676],
            [-93.5768, 46.683],
            [-93.5747, 46.6832],
            [-93.5695, 46.6818],
            [-93.567, 46.6797],
            [-93.5637, 46.6791],
            [-93.5583, 46.682],
            [-93.5548, 46.6874],
            [-93.5349, 46.6848],
            [-93.5211, 46.6952],
            [-93.5127, 46.691],
            [-93.4976, 46.696],
            [-93.4871, 46.7082],
            [-93.4971, 46.6967],
            [-93.5215, 46.6956],
            [-93.5548, 46.688],
            [-93.5611, 46.6806],
            [-93.566, 46.6798],
            [-93.5677, 46.681],
            [-93.5734, 46.6834],
            [-93.5771, 46.6835],
            [-93.5876, 46.6768],
            [-93.5925, 46.6677],
            [-93.5939, 46.6674],
            [-93.5994, 46.669],
            [-93.6008, 46.6702],
            [-93.6019, 46.6729],
            [-93.6068, 46.6718],
            [-93.6125, 46.6646],
            [-93.6133, 46.6624],
            [-93.6103, 46.6623],
            [-93.6065, 46.6656],
            [-93.5997, 46.6668],
            [-93.5987, 46.6653],
            [-93.6, 46.6639],
            [-93.6045, 46.663],
            [-93.6108, 46.6583],
            [-93.633, 46.6358],
            [-93.633, 46.632],
            [-93.6272, 46.6267],
            [-93.6269, 46.6261],
            [-93.6271, 46.6246],
            [-93.6362, 46.6261],
            [-93.6398, 46.6283],
            [-93.6414, 46.6284],
            [-93.6454, 46.6254],
            [-93.6476, 46.6213],
            [-93.6474, 46.6167],
            [-93.6463, 46.6154],
            [-93.6439, 46.6139],
            [-93.6436, 46.6132],
            [-93.645, 46.6127],
            [-93.6475, 46.6129],
            [-93.6474, 46.6111],
            [-93.6421, 46.6108],
            [-93.6402, 46.6093],
            [-93.6406, 46.608],
            [-93.6452, 46.6069],
            [-93.6464, 46.6029],
            [-93.6442, 46.6014],
            [-93.6401, 46.603],
            [-93.6391, 46.6021],
            [-93.6406, 46.5951],
            [-93.6422, 46.5934],
            [-93.6422, 46.5891],
            [-93.6354, 46.5892],
            [-93.6468, 46.5854],
            [-93.663, 46.5897],
            [-93.6452, 46.5807],
            [-93.661, 46.5767],
            [-93.6613, 46.562],
            [-93.6654, 46.5493],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Cape Girardeau Mkt": {
    type: "Feature",
    properties: { code: "MO_GIR", name: "Cape Girardeau Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-89.5942, 37.9952],
            [-89.587, 37.9821],
            [-89.5948, 37.9699],
            [-89.5942, 37.9952],
          ],
        ],
        [
          [
            [-90.9929, 38.0905],
            [-90.9669, 38.1074],
            [-90.9666, 38.1219],
            [-90.9416, 38.102],
            [-90.9478, 38.1005],
            [-90.9426, 38.0933],
            [-90.9241, 38.0815],
            [-90.9131, 38.0908],
            [-90.8953, 38.0673],
            [-90.8804, 38.0815],
            [-90.8572, 38.0815],
            [-90.842, 38.0999],
            [-90.7969, 38.083],
            [-90.762, 38.0974],
            [-90.7533, 38.0881],
            [-90.7475, 38.0956],
            [-90.7395, 38.0857],
            [-90.7403, 38.093],
            [-90.7287, 38.094],
            [-90.7305, 38.0862],
            [-90.6947, 38.1009],
            [-90.6833, 38.0935],
            [-90.686, 38.0767],
            [-90.6604, 38.0636],
            [-90.6489, 38.0763],
            [-90.6565, 38.081],
            [-90.6123, 38.0703],
            [-90.6214, 38.0406],
            [-90.6128, 38.0161],
            [-90.6282, 38.0078],
            [-90.5833, 38.0035],
            [-90.4919, 38.0252],
            [-90.4897, 38.0125],
            [-90.4689, 37.9948],
            [-90.4646, 37.9486],
            [-90.4173, 37.9582],
            [-90.4165, 37.9412],
            [-90.3818, 37.9416],
            [-90.3818, 37.9212],
            [-90.3436, 37.9134],
            [-90.3188, 37.9224],
            [-90.3283, 37.9548],
            [-90.303, 37.9666],
            [-90.288, 37.9837],
            [-90.2921, 37.9889],
            [-90.2769, 37.9966],
            [-90.294, 38.0019],
            [-90.329, 37.9981],
            [-90.3276, 38.008],
            [-90.3146, 38.0111],
            [-90.316, 38.0289],
            [-90.342, 38.0335],
            [-90.3372, 38.0644],
            [-90.3496, 38.0823],
            [-90.3292, 38.0945],
            [-90.3002, 38.0893],
            [-90.3077, 38.1092],
            [-90.27, 38.1375],
            [-90.265, 38.1368],
            [-90.2527, 38.1278],
            [-90.2431, 38.1127],
            [-90.1722, 38.0696],
            [-90.1585, 38.0747],
            [-90.1308, 38.0623],
            [-90.1262, 38.0407],
            [-90.1105, 38.0265],
            [-90.0883, 38.0158],
            [-90.0594, 38.0155],
            [-89.9971, 37.9632],
            [-89.9421, 37.9701],
            [-89.9359, 37.9596],
            [-89.9251, 37.96],
            [-89.9325, 37.9475],
            [-89.9596, 37.9402],
            [-89.9749, 37.9267],
            [-89.9506, 37.8815],
            [-89.9018, 37.8698],
            [-89.8426, 37.9052],
            [-89.7993, 37.8815],
            [-89.8004, 37.8686],
            [-89.7864, 37.8517],
            [-89.7399, 37.8469],
            [-89.7175, 37.8257],
            [-89.6696, 37.7999],
            [-89.6604, 37.7863],
            [-89.6634, 37.7501],
            [-89.6173, 37.7497],
            [-89.6024, 37.7365],
            [-89.6118, 37.7596],
            [-89.6186, 37.7597],
            [-89.6037, 37.8013],
            [-89.5989, 37.8081],
            [-89.5793, 37.8042],
            [-89.5582, 37.823],
            [-89.5853, 37.8469],
            [-89.592, 37.8629],
            [-89.6054, 37.8468],
            [-89.6303, 37.852],
            [-89.6246, 37.8741],
            [-89.6378, 37.8878],
            [-89.6463, 37.8744],
            [-89.6576, 37.8846],
            [-89.6806, 37.8883],
            [-89.6853, 37.9118],
            [-89.6765, 37.9163],
            [-89.64, 37.9093],
            [-89.634, 37.9234],
            [-89.6085, 37.9341],
            [-89.5672, 37.9551],
            [-89.5669, 37.9696],
            [-89.5015, 37.9692],
            [-89.4842, 37.9758],
            [-89.484, 37.9829],
            [-89.4287, 37.9793],
            [-89.4302, 37.9532],
            [-89.3432, 37.9517],
            [-89.0958, 37.9501],
            [-89.0953, 37.9142],
            [-89.0405, 37.8961],
            [-89.056, 37.862],
            [-89.0074, 37.8403],
            [-88.9014, 37.8485],
            [-88.7706, 37.8419],
            [-88.7484, 37.7976],
            [-88.7071, 37.7844],
            [-88.7068, 37.8474],
            [-88.6701, 37.851],
            [-88.6718, 37.9215],
            [-88.5867, 37.9069],
            [-88.4149, 37.9075],
            [-88.3851, 37.8886],
            [-88.3557, 37.8281],
            [-88.2336, 37.8279],
            [-88.2012, 37.8562],
            [-88.0648, 37.8492],
            [-88.0294, 37.8036],
            [-88.0638, 37.7386],
            [-88.125, 37.7071],
            [-88.1582, 37.6645],
            [-88.1334, 37.5742],
            [-88.088, 37.5351],
            [-88.0341, 37.5135],
            [-88.0421, 37.4684],
            [-87.9887, 37.4354],
            [-87.9419, 37.4468],
            [-87.9288, 37.4086],
            [-87.864, 37.3793],
            [-87.8636, 37.344],
            [-87.9349, 37.3038],
            [-87.9553, 37.2747],
            [-88.0138, 37.2493],
            [-88.1352, 37.2136],
            [-88.1327, 37.1885],
            [-88.0768, 37.1767],
            [-88.0839, 37.1494],
            [-88.0402, 37.1388],
            [-88.0008, 37.0599],
            [-87.975, 37.0675],
            [-87.9419, 37.0304],
            [-87.9392, 36.9909],
            [-87.877, 36.9601],
            [-88, 36.9112],
            [-88.1573, 36.8672],
            [-88.1023, 36.7332],
            [-88.0705, 36.6781],
            [-88.0325, 36.5407],
            [-88.0532, 36.4971],
            [-88.3308, 36.5005],
            [-89.1175, 36.5036],
            [-89.3003, 36.5071],
            [-89.4173, 36.499],
            [-89.4642, 36.4572],
            [-89.4935, 36.4787],
            [-89.4654, 36.5362],
            [-89.4848, 36.5718],
            [-89.5425, 36.5806],
            [-89.5712, 36.5473],
            [-89.5197, 36.467],
            [-89.5451, 36.4349],
            [-89.5097, 36.3736],
            [-89.5318, 36.3392],
            [-89.6107, 36.3404],
            [-89.6118, 36.3091],
            [-89.5355, 36.2705],
            [-89.5873, 36.2395],
            [-89.6952, 36.2528],
            [-89.6926, 36.225],
            [-89.607, 36.1712],
            [-89.594, 36.1272],
            [-89.68, 36.0825],
            [-89.7069, 36.001],
            [-90.3779, 35.9957],
            [-90.2931, 36.1144],
            [-90.2488, 36.1298],
            [-90.2204, 36.1848],
            [-90.1422, 36.2275],
            [-90.1149, 36.2656],
            [-90.0837, 36.2723],
            [-90.0645, 36.3821],
            [-90.135, 36.4137],
            [-90.1373, 36.4554],
            [-90.1939, 36.4978],
            [-90.7112, 36.4983],
            [-91.1725, 36.4976],
            [-91.1932, 36.5212],
            [-91.1694, 36.5927],
            [-91.1746, 36.6541],
            [-91.2211, 36.7394],
            [-91.2738, 36.7663],
            [-91.2694, 36.7921],
            [-91.2248, 36.8257],
            [-91.2172, 37.0862],
            [-91.1526, 37.0964],
            [-91.1787, 37.1377],
            [-91.1653, 37.1664],
            [-91.1897, 37.1897],
            [-91.2404, 37.1757],
            [-91.3136, 37.1984],
            [-91.2947, 37.242],
            [-91.4094, 37.2862],
            [-91.3104, 37.3431],
            [-91.2927, 37.3854],
            [-91.2632, 37.4081],
            [-91.3744, 37.4183],
            [-91.3148, 37.4694],
            [-91.3663, 37.5163],
            [-91.3855, 37.5891],
            [-91.3132, 37.5521],
            [-91.3125, 37.5928],
            [-91.2216, 37.5893],
            [-91.1942, 37.5747],
            [-91.1825, 37.5164],
            [-91.0923, 37.5431],
            [-91.0368, 37.5441],
            [-91.055, 37.5904],
            [-91.0973, 37.5882],
            [-91.0846, 37.6317],
            [-91.0423, 37.6087],
            [-91.0172, 37.6224],
            [-91.0397, 37.6718],
            [-91.0046, 37.6939],
            [-91.0166, 37.7398],
            [-91.0564, 37.7394],
            [-91.0994, 37.7721],
            [-91.0976, 37.8405],
            [-90.9977, 37.8391],
            [-90.9784, 37.8664],
            [-91.0051, 37.9034],
            [-91.0544, 37.9102],
            [-91.0913, 37.8931],
            [-91.0946, 37.9752],
            [-91.0461, 37.9801],
            [-91.0112, 37.9599],
            [-90.9776, 37.987],
            [-90.9744, 38.0579],
            [-90.9929, 38.0905],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Jefferson City Mkt": {
    type: "Feature",
    properties: { code: "MO_JEF", name: "Jefferson City Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-93.6357, 38.6625],
          [-93.5981, 38.7393],
          [-93.6236, 38.7674],
          [-93.6674, 38.7756],
          [-93.6455, 38.8381],
          [-93.6241, 38.8594],
          [-93.6333, 38.8967],
          [-93.509, 38.9074],
          [-93.4765, 38.9603],
          [-93.522, 39.0389],
          [-93.4989, 39.0706],
          [-93.4806, 39.2348],
          [-93.4442, 39.2685],
          [-93.4029, 39.2601],
          [-93.3865, 39.2276],
          [-93.3291, 39.2541],
          [-93.3369, 39.307],
          [-93.2311, 39.3285],
          [-93.192, 39.3657],
          [-93.2202, 39.4309],
          [-93.2766, 39.4861],
          [-93.2843, 39.5335],
          [-93.2495, 39.5874],
          [-93.2182, 39.5719],
          [-93.1524, 39.5718],
          [-93.1468, 39.4989],
          [-93.0908, 39.497],
          [-93.0891, 39.5259],
          [-92.9777, 39.5242],
          [-92.958, 39.582],
          [-92.8393, 39.5768],
          [-92.8042, 39.6265],
          [-92.8405, 39.6489],
          [-92.7523, 39.6407],
          [-92.7483, 39.611],
          [-92.7264, 39.606],
          [-92.6914, 39.6315],
          [-92.6599, 39.6097],
          [-92.6549, 39.5735],
          [-92.644, 39.5802],
          [-92.6474, 39.588],
          [-92.6401, 39.5821],
          [-92.6399, 39.5954],
          [-92.655, 39.6082],
          [-92.6212, 39.6093],
          [-92.6128, 39.6443],
          [-92.5983, 39.6489],
          [-92.5636, 39.6414],
          [-92.5451, 39.6516],
          [-92.5226, 39.6515],
          [-92.4972, 39.6947],
          [-92.4702, 39.6939],
          [-92.4748, 39.6723],
          [-92.4477, 39.6724],
          [-92.443, 39.6506],
          [-92.417, 39.6576],
          [-92.392, 39.6426],
          [-92.3644, 39.6475],
          [-92.364, 39.6574],
          [-92.3526, 39.655],
          [-92.3479, 39.6407],
          [-92.337, 39.6396],
          [-92.3434, 39.6344],
          [-92.3055, 39.6283],
          [-92.2993, 39.6053],
          [-92.2829, 39.6014],
          [-92.2806, 39.5846],
          [-92.2646, 39.5805],
          [-92.2642, 39.5669],
          [-92.2471, 39.5613],
          [-92.2332, 39.5458],
          [-92.2506, 39.5656],
          [-92.2506, 39.5802],
          [-92.2646, 39.5829],
          [-92.2605, 39.6049],
          [-92.1867, 39.6041],
          [-92.1681, 39.606],
          [-92.1346, 39.5931],
          [-92.1202, 39.5747],
          [-92.1159, 39.5893],
          [-92.1021, 39.5891],
          [-92.0547, 39.5833],
          [-91.9973, 39.5653],
          [-91.9918, 39.5954],
          [-91.9798, 39.5954],
          [-91.9804, 39.6017],
          [-91.9333, 39.597],
          [-91.9326, 39.6083],
          [-91.8972, 39.6132],
          [-91.9012, 39.5748],
          [-91.8646, 39.5744],
          [-91.8633, 39.582],
          [-91.8509, 39.5817],
          [-91.8439, 39.59],
          [-91.8184, 39.5899],
          [-91.8087, 39.5864],
          [-91.7797, 39.5719],
          [-91.7253, 39.5178],
          [-91.7323, 39.5161],
          [-91.7182, 39.5076],
          [-91.7135, 39.5117],
          [-91.7135, 39.4992],
          [-91.7717, 39.4992],
          [-91.7755, 39.4758],
          [-91.7868, 39.4822],
          [-91.798, 39.4693],
          [-91.8199, 39.4714],
          [-91.8025, 39.4582],
          [-91.8143, 39.463],
          [-91.8127, 39.4527],
          [-91.8253, 39.4545],
          [-91.8236, 39.4443],
          [-91.8355, 39.4337],
          [-91.8344, 39.4253],
          [-91.8152, 39.4213],
          [-91.8314, 39.3999],
          [-91.824, 39.3933],
          [-91.8321, 39.3902],
          [-91.8231, 39.3932],
          [-91.8294, 39.4006],
          [-91.8096, 39.4116],
          [-91.8171, 39.3908],
          [-91.7994, 39.3859],
          [-91.7972, 39.3669],
          [-91.7907, 39.3685],
          [-91.7695, 39.3387],
          [-91.7991, 39.3383],
          [-91.7949, 39.3064],
          [-91.7811, 39.3081],
          [-91.7865, 39.2949],
          [-91.7673, 39.2952],
          [-91.7743, 39.2648],
          [-91.7627, 39.2496],
          [-91.7641, 39.237],
          [-91.7029, 39.2371],
          [-91.7039, 39.1899],
          [-91.7137, 39.1881],
          [-91.7059, 39.1491],
          [-91.6295, 39.1624],
          [-91.6297, 39.1478],
          [-91.6118, 39.1474],
          [-91.6139, 39.0929],
          [-91.6324, 39.0975],
          [-91.6332, 39.0778],
          [-91.6137, 39.0586],
          [-91.651, 39.0428],
          [-91.6509, 39.0354],
          [-91.6702, 39.0354],
          [-91.6756, 39.0136],
          [-91.6945, 39.0133],
          [-91.7131, 38.985],
          [-91.7497, 38.9801],
          [-91.7498, 38.9719],
          [-91.7714, 38.9625],
          [-91.8066, 38.9633],
          [-91.805, 38.9343],
          [-91.7856, 38.9218],
          [-91.8156, 38.9054],
          [-91.814, 38.8947],
          [-91.8002, 38.8871],
          [-91.7917, 38.8463],
          [-91.8084, 38.8433],
          [-91.7748, 38.8429],
          [-91.7646, 38.8195],
          [-91.7469, 38.8089],
          [-91.7189, 38.8078],
          [-91.7175, 38.8147],
          [-91.7291, 38.8188],
          [-91.7268, 38.8352],
          [-91.6548, 38.8571],
          [-91.642, 38.8523],
          [-91.6429, 38.8318],
          [-91.6127, 38.8436],
          [-91.5621, 38.8355],
          [-91.5367, 38.8192],
          [-91.5244, 38.8237],
          [-91.4868, 38.8127],
          [-91.4861, 38.8028],
          [-91.462, 38.7885],
          [-91.4165, 38.8032],
          [-91.417, 38.7814],
          [-91.4044, 38.7716],
          [-91.3704, 38.7889],
          [-91.3553, 38.7888],
          [-91.3446, 38.8047],
          [-91.3292, 38.8087],
          [-91.3184, 38.8037],
          [-91.3231, 38.7939],
          [-91.3154, 38.7821],
          [-91.3448, 38.7742],
          [-91.3675, 38.75],
          [-91.337, 38.7197],
          [-91.307, 38.7045],
          [-91.3244, 38.7084],
          [-91.3491, 38.6954],
          [-91.3692, 38.6993],
          [-91.3683, 38.6859],
          [-91.3858, 38.6829],
          [-91.3796, 38.6738],
          [-91.39, 38.6613],
          [-91.3688, 38.6419],
          [-91.3688, 38.6012],
          [-91.3796, 38.6046],
          [-91.3966, 38.598],
          [-91.3947, 38.5812],
          [-91.3729, 38.5837],
          [-91.377, 38.5625],
          [-91.3872, 38.5569],
          [-91.3684, 38.5353],
          [-91.3685, 38.5145],
          [-91.3897, 38.5009],
          [-91.3725, 38.4791],
          [-91.4103, 38.4691],
          [-91.413, 38.4576],
          [-91.4303, 38.4604],
          [-91.4429, 38.4496],
          [-91.4409, 38.427],
          [-91.4525, 38.4017],
          [-91.4277, 38.368],
          [-91.4479, 38.3641],
          [-91.4491, 38.3442],
          [-91.4033, 38.3288],
          [-91.399, 38.3],
          [-91.366, 38.2928],
          [-91.3665, 38.2631],
          [-91.4001, 38.2553],
          [-91.4245, 38.1901],
          [-91.4541, 38.1916],
          [-91.4768, 38.1548],
          [-91.5803, 38.1528],
          [-91.6449, 38.1647],
          [-91.709, 38.1509],
          [-91.7306, 38.1841],
          [-91.7997, 38.1795],
          [-91.8485, 38.1944],
          [-91.7997, 38.2425],
          [-91.8627, 38.2929],
          [-92.006, 38.2878],
          [-91.9908, 38.2427],
          [-92.0391, 38.1805],
          [-92.0836, 38.1843],
          [-92.1573, 38.1606],
          [-92.1646, 38.1875],
          [-92.2876, 38.1729],
          [-92.343, 38.1842],
          [-92.4593, 38.1021],
          [-92.4231, 38.083],
          [-92.4243, 38.037],
          [-92.516, 38.0223],
          [-92.5336, 38.0518],
          [-92.5713, 38.049],
          [-92.582, 37.9798],
          [-92.6393, 37.9927],
          [-92.6656, 37.915],
          [-92.7014, 37.8939],
          [-92.8441, 37.8956],
          [-92.8328, 37.9626],
          [-92.8357, 38.0605],
          [-92.9052, 38.0726],
          [-92.9192, 38.0979],
          [-92.871, 38.137],
          [-92.9193, 38.1561],
          [-92.9528, 38.1318],
          [-92.9515, 38.057],
          [-93.0186, 38.0222],
          [-93.0677, 38.0162],
          [-93.0652, 38.0625],
          [-93.4661, 38.0717],
          [-93.4732, 38.1722],
          [-93.4466, 38.1985],
          [-93.5235, 38.2074],
          [-93.4933, 38.3147],
          [-93.5341, 38.369],
          [-93.5825, 38.3719],
          [-93.5882, 38.4053],
          [-93.6654, 38.4271],
          [-93.6992, 38.4592],
          [-93.6972, 38.5233],
          [-93.6584, 38.5598],
          [-93.6078, 38.5589],
          [-93.5977, 38.6434],
          [-93.6357, 38.6625],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Joplin Mkt": {
    type: "Feature",
    properties: { code: "MO_JOP", name: "Joplin Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-95.5191, 38.0672],
          [-95.5191, 37.9883],
          [-95.427, 37.9948],
          [-95.4266, 38.0384],
          [-95.0777, 38.0377],
          [-95.0233, 38.015],
          [-94.9332, 38.0371],
          [-94.9333, 38.0806],
          [-94.8603, 38.0808],
          [-94.8605, 38.0371],
          [-94.8055, 38.0374],
          [-94.7689, 38.1244],
          [-94.6769, 38.11],
          [-94.614, 38.0858],
          [-94.6134, 38.4034],
          [-94.6098, 38.7239],
          [-94.5727, 38.7219],
          [-94.5281, 38.6703],
          [-94.4675, 38.6432],
          [-94.404, 38.6406],
          [-94.3917, 38.6726],
          [-94.4055, 38.708],
          [-94.3761, 38.729],
          [-94.3305, 38.7276],
          [-94.2798, 38.6967],
          [-94.1453, 38.6816],
          [-94.0864, 38.6622],
          [-94.0714, 38.6134],
          [-94.0082, 38.5662],
          [-93.9622, 38.565],
          [-93.916, 38.5933],
          [-93.9159, 38.6487],
          [-93.8072, 38.635],
          [-93.7046, 38.6426],
          [-93.7029, 38.6716],
          [-93.6357, 38.6625],
          [-93.5977, 38.6434],
          [-93.6078, 38.5589],
          [-93.6584, 38.5598],
          [-93.6972, 38.5233],
          [-93.6992, 38.4592],
          [-93.6654, 38.4271],
          [-93.5882, 38.4053],
          [-93.5825, 38.3719],
          [-93.5341, 38.369],
          [-93.4933, 38.3147],
          [-93.5235, 38.2074],
          [-93.4466, 38.1985],
          [-93.4732, 38.1722],
          [-93.4661, 38.0717],
          [-93.0652, 38.0625],
          [-93.0677, 38.0162],
          [-93.0186, 38.0222],
          [-92.9515, 38.057],
          [-92.9528, 38.1318],
          [-92.9193, 38.1561],
          [-92.871, 38.137],
          [-92.9192, 38.0979],
          [-92.9052, 38.0726],
          [-92.8357, 38.0605],
          [-92.8328, 37.9626],
          [-92.8441, 37.8956],
          [-92.7014, 37.8939],
          [-92.6656, 37.915],
          [-92.6393, 37.9927],
          [-92.582, 37.9798],
          [-92.5713, 38.049],
          [-92.5336, 38.0518],
          [-92.516, 38.0223],
          [-92.4243, 38.037],
          [-92.4231, 38.083],
          [-92.4593, 38.1021],
          [-92.343, 38.1842],
          [-92.2876, 38.1729],
          [-92.1646, 38.1875],
          [-92.1573, 38.1606],
          [-92.0836, 38.1843],
          [-92.0391, 38.1805],
          [-91.9908, 38.2427],
          [-92.006, 38.2878],
          [-91.8627, 38.2929],
          [-91.7997, 38.2425],
          [-91.8485, 38.1944],
          [-91.7997, 38.1795],
          [-91.7306, 38.1841],
          [-91.709, 38.1509],
          [-91.6449, 38.1647],
          [-91.5803, 38.1528],
          [-91.4768, 38.1548],
          [-91.4541, 38.1916],
          [-91.4245, 38.1901],
          [-91.4001, 38.2553],
          [-91.3665, 38.2631],
          [-91.367, 38.2288],
          [-91.3458, 38.204],
          [-91.2485, 38.2041],
          [-91.2414, 38.1887],
          [-91.2274, 38.1815],
          [-91.2052, 38.1804],
          [-91.1861, 38.1953],
          [-91.1613, 38.1777],
          [-91.0958, 38.1815],
          [-91.0894, 38.0808],
          [-91.0439, 38.0941],
          [-91.0559, 38.1208],
          [-91.0289, 38.1369],
          [-91.0081, 38.1336],
          [-91.0071, 38.1124],
          [-90.9929, 38.0905],
          [-90.9744, 38.0579],
          [-90.9776, 37.987],
          [-91.0112, 37.9599],
          [-91.0461, 37.9801],
          [-91.0946, 37.9752],
          [-91.0913, 37.8931],
          [-91.0544, 37.9102],
          [-91.0051, 37.9034],
          [-90.9784, 37.8664],
          [-90.9977, 37.8391],
          [-91.0976, 37.8405],
          [-91.0994, 37.7721],
          [-91.0564, 37.7394],
          [-91.0166, 37.7398],
          [-91.0046, 37.6939],
          [-91.0397, 37.6718],
          [-91.0172, 37.6224],
          [-91.0423, 37.6087],
          [-91.0846, 37.6317],
          [-91.0973, 37.5882],
          [-91.055, 37.5904],
          [-91.0368, 37.5441],
          [-91.0923, 37.5431],
          [-91.1825, 37.5164],
          [-91.1942, 37.5747],
          [-91.2216, 37.5893],
          [-91.3125, 37.5928],
          [-91.3132, 37.5521],
          [-91.3855, 37.5891],
          [-91.3663, 37.5163],
          [-91.3148, 37.4694],
          [-91.3744, 37.4183],
          [-91.2632, 37.4081],
          [-91.2927, 37.3854],
          [-91.3104, 37.3431],
          [-91.4094, 37.2862],
          [-91.2947, 37.242],
          [-91.3136, 37.1984],
          [-91.2404, 37.1757],
          [-91.1897, 37.1897],
          [-91.1653, 37.1664],
          [-91.1787, 37.1377],
          [-91.1526, 37.0964],
          [-91.2172, 37.0862],
          [-91.2248, 36.8257],
          [-91.2694, 36.7921],
          [-91.2738, 36.7663],
          [-91.2211, 36.7394],
          [-91.1746, 36.6541],
          [-91.1694, 36.5927],
          [-91.1932, 36.5212],
          [-91.1725, 36.4976],
          [-91.4071, 36.4971],
          [-91.6723, 36.4995],
          [-92.2595, 36.4982],
          [-92.6306, 36.4981],
          [-92.6258, 36.4858],
          [-92.6911, 36.4364],
          [-92.7431, 36.4281],
          [-92.7597, 36.4963],
          [-92.8331, 36.454],
          [-92.8542, 36.498],
          [-93.4398, 36.4986],
          [-94.1115, 36.4986],
          [-94.6179, 36.4994],
          [-94.6181, 36.9981],
          [-94.9318, 36.9992],
          [-94.9951, 37.0466],
          [-95.0136, 37.1046],
          [-94.9954, 37.1349],
          [-95.0319, 37.1352],
          [-95.0742, 37.165],
          [-95.0742, 37.2303],
          [-95.0679, 37.2376],
          [-95.0437, 37.2375],
          [-95.0413, 37.2595],
          [-95.0753, 37.2668],
          [-95.0752, 37.296],
          [-95.0862, 37.3032],
          [-95.1083, 37.2958],
          [-95.1094, 37.3107],
          [-95.1035, 37.3134],
          [-95.1024, 37.3192],
          [-95.1054, 37.3233],
          [-95.1046, 37.3261],
          [-95.101, 37.3288],
          [-95.1004, 37.3323],
          [-95.1023, 37.3363],
          [-95.1287, 37.3629],
          [-95.1153, 37.3664],
          [-95.1213, 37.3871],
          [-95.1381, 37.3909],
          [-95.1578, 37.4108],
          [-95.1649, 37.4146],
          [-95.1575, 37.4177],
          [-95.1723, 37.4201],
          [-95.17, 37.4416],
          [-95.1792, 37.442],
          [-95.1791, 37.4566],
          [-95.2883, 37.4564],
          [-95.3249, 37.4275],
          [-95.3543, 37.4268],
          [-95.3487, 37.4132],
          [-95.3792, 37.3842],
          [-95.4346, 37.384],
          [-95.5003, 37.384],
          [-95.5088, 37.384],
          [-95.5256, 37.384],
          [-95.5622, 37.3839],
          [-95.5622, 37.3986],
          [-95.5972, 37.3986],
          [-95.6021, 37.3526],
          [-95.6564, 37.3532],
          [-95.6751, 37.3412],
          [-95.6868, 37.3485],
          [-95.6753, 37.3558],
          [-95.7022, 37.356],
          [-95.7108, 37.3281],
          [-95.729, 37.3272],
          [-95.7347, 37.3417],
          [-95.7741, 37.342],
          [-95.7795, 37.3565],
          [-95.8195, 37.3858],
          [-95.8535, 37.386],
          [-95.8559, 37.3714],
          [-95.8742, 37.3716],
          [-95.8857, 37.3863],
          [-95.9614, 37.3867],
          [-95.9625, 37.4458],
          [-95.9809, 37.4449],
          [-95.9826, 37.4595],
          [-96.042, 37.4599],
          [-96.0441, 37.4594],
          [-96.0437, 37.5028],
          [-96.0367, 37.5028],
          [-96.0406, 37.5478],
          [-96.0256, 37.5627],
          [-96.0219, 37.5812],
          [-95.9613, 37.582],
          [-95.961, 37.6037],
          [-95.9429, 37.6036],
          [-95.942, 37.6182],
          [-95.8969, 37.618],
          [-95.8969, 37.6397],
          [-95.9106, 37.6405],
          [-95.9101, 37.6551],
          [-95.897, 37.6586],
          [-95.898, 37.684],
          [-95.9423, 37.6725],
          [-95.9604, 37.6761],
          [-95.9603, 37.6908],
          [-95.9968, 37.6909],
          [-96.0196, 37.6768],
          [-96.0244, 37.6404],
          [-96.0422, 37.6407],
          [-96.0422, 37.6661],
          [-96.0428, 37.7497],
          [-96.1068, 37.7506],
          [-96.1071, 37.807],
          [-96.0896, 37.8088],
          [-96.0888, 37.819],
          [-96.0766, 37.8228],
          [-95.9756, 37.8221],
          [-95.9806, 37.8367],
          [-96.0135, 37.8675],
          [-95.977, 37.8502],
          [-95.9586, 37.8801],
          [-95.8853, 37.8801],
          [-95.8486, 37.8653],
          [-95.8664, 37.9238],
          [-95.8669, 37.938],
          [-95.8856, 37.9595],
          [-95.8679, 37.9608],
          [-95.8675, 37.9743],
          [-95.8491, 37.9815],
          [-95.8857, 37.9961],
          [-95.8859, 38.0401],
          [-95.5377, 38.0382],
          [-95.5191, 38.0672],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Kansas City Mkt": {
    type: "Feature",
    properties: { code: "MO_KAN", name: "Kansas City Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-96.8053, 40.0014],
          [-96.3046, 40.0006],
          [-95.9581, 40.0005],
          [-95.3083, 40],
          [-95.3466, 40.0283],
          [-95.3959, 40.028],
          [-95.3933, 40.1192],
          [-95.4763, 40.182],
          [-95.4697, 40.2279],
          [-95.5563, 40.2708],
          [-95.5533, 40.2912],
          [-95.6104, 40.314],
          [-95.6591, 40.4087],
          [-95.6446, 40.4584],
          [-95.6962, 40.4821],
          [-95.6617, 40.5173],
          [-95.6374, 40.5841],
          [-95.3083, 40.5796],
          [-95.2402, 40.5522],
          [-95.2024, 40.5785],
          [-94.9149, 40.5751],
          [-94.4893, 40.5707],
          [-94.0802, 40.5729],
          [-93.5483, 40.5804],
          [-93.1838, 40.5821],
          [-93.1198, 40.5833],
          [-93.1406, 40.5253],
          [-93.131, 40.4871],
          [-93.1657, 40.4931],
          [-93.1852, 40.4859],
          [-93.1791, 40.4736],
          [-93.14, 40.475],
          [-93.1407, 40.4366],
          [-93.1572, 40.4364],
          [-93.1692, 40.4259],
          [-93.1611, 40.4059],
          [-93.1956, 40.4003],
          [-93.1886, 40.3341],
          [-93.1958, 40.336],
          [-93.1962, 40.3289],
          [-93.2151, 40.338],
          [-93.2055, 40.3142],
          [-93.2209, 40.3124],
          [-93.2157, 40.2689],
          [-93.2488, 40.2683],
          [-93.2474, 40.2319],
          [-93.2721, 40.2388],
          [-93.273, 40.2619],
          [-93.2914, 40.2674],
          [-93.2868, 40.2343],
          [-93.3009, 40.2234],
          [-93.3028, 40.2089],
          [-93.2916, 40.1768],
          [-93.2443, 40.1588],
          [-93.254, 40.1446],
          [-93.2634, 40.1303],
          [-93.2284, 40.1305],
          [-93.2207, 40.1363],
          [-93.2218, 40.105],
          [-93.2001, 40.0943],
          [-93.2041, 40.0822],
          [-93.1835, 40.0756],
          [-93.1782, 40.0652],
          [-93.159, 40.0571],
          [-93.1159, 40.0752],
          [-93.1004, 40.0952],
          [-93.0547, 40.0951],
          [-93.0454, 40.0894],
          [-93.0255, 40.0946],
          [-93.0072, 40.0513],
          [-93.0027, 40.0803],
          [-92.9767, 40.0823],
          [-92.9598, 40.0727],
          [-92.9457, 40.0514],
          [-92.9699, 40.051],
          [-92.9692, 40.0365],
          [-93.0016, 40.0365],
          [-92.9982, 40.0229],
          [-93.0075, 40.0077],
          [-92.9944, 39.9853],
          [-93.0007, 39.9533],
          [-92.9731, 39.9509],
          [-92.9731, 39.9286],
          [-92.9567, 39.928],
          [-92.9584, 39.8915],
          [-92.9406, 39.8913],
          [-92.9185, 39.9199],
          [-92.8515, 39.9192],
          [-92.8525, 39.8973],
          [-92.8101, 39.8946],
          [-92.8084, 39.8286],
          [-92.828, 39.7977],
          [-92.82, 39.7674],
          [-92.7988, 39.7683],
          [-92.8173, 39.7457],
          [-92.8441, 39.6731],
          [-92.8586, 39.6677],
          [-92.8405, 39.6489],
          [-92.8042, 39.6265],
          [-92.8393, 39.5768],
          [-92.958, 39.582],
          [-92.9777, 39.5242],
          [-93.0891, 39.5259],
          [-93.0908, 39.497],
          [-93.1468, 39.4989],
          [-93.1524, 39.5718],
          [-93.2182, 39.5719],
          [-93.2495, 39.5874],
          [-93.2843, 39.5335],
          [-93.2766, 39.4861],
          [-93.2202, 39.4309],
          [-93.192, 39.3657],
          [-93.2311, 39.3285],
          [-93.3369, 39.307],
          [-93.3291, 39.2541],
          [-93.3865, 39.2276],
          [-93.4029, 39.2601],
          [-93.4442, 39.2685],
          [-93.4806, 39.2348],
          [-93.4989, 39.0706],
          [-93.522, 39.0389],
          [-93.4765, 38.9603],
          [-93.509, 38.9074],
          [-93.6333, 38.8967],
          [-93.6241, 38.8594],
          [-93.6455, 38.8381],
          [-93.6674, 38.7756],
          [-93.6236, 38.7674],
          [-93.5981, 38.7393],
          [-93.6357, 38.6625],
          [-93.7029, 38.6716],
          [-93.7046, 38.6426],
          [-93.8072, 38.635],
          [-93.9159, 38.6487],
          [-93.916, 38.5933],
          [-93.9622, 38.565],
          [-94.0082, 38.5662],
          [-94.0714, 38.6134],
          [-94.0864, 38.6622],
          [-94.1453, 38.6816],
          [-94.2798, 38.6967],
          [-94.3305, 38.7276],
          [-94.3761, 38.729],
          [-94.4055, 38.708],
          [-94.3917, 38.6726],
          [-94.404, 38.6406],
          [-94.4675, 38.6432],
          [-94.5281, 38.6703],
          [-94.5727, 38.7219],
          [-94.6098, 38.7239],
          [-94.6134, 38.4034],
          [-94.614, 38.0858],
          [-94.6769, 38.11],
          [-94.7689, 38.1244],
          [-94.8055, 38.0374],
          [-94.8605, 38.0371],
          [-94.8603, 38.0808],
          [-94.9333, 38.0806],
          [-94.9332, 38.0371],
          [-95.0233, 38.015],
          [-95.0777, 38.0377],
          [-95.4266, 38.0384],
          [-95.427, 37.9948],
          [-95.5191, 37.9883],
          [-95.5191, 38.0672],
          [-95.5187, 38.0963],
          [-95.5553, 38.111],
          [-95.5548, 38.1695],
          [-95.592, 38.1765],
          [-95.5822, 38.2131],
          [-95.5816, 38.2886],
          [-95.5447, 38.2886],
          [-95.5455, 38.3174],
          [-95.508, 38.3468],
          [-95.5083, 38.4633],
          [-95.6186, 38.4489],
          [-95.6184, 38.4344],
          [-95.6739, 38.4345],
          [-95.6741, 38.4491],
          [-95.6853, 38.4494],
          [-95.6863, 38.4663],
          [-95.6781, 38.4711],
          [-95.6918, 38.5071],
          [-95.7104, 38.4929],
          [-95.7153, 38.5018],
          [-95.7959, 38.4829],
          [-95.8217, 38.488],
          [-95.8327, 38.4762],
          [-95.8313, 38.4912],
          [-95.8626, 38.4844],
          [-95.8643, 38.4779],
          [-95.888, 38.4778],
          [-95.8844, 38.4905],
          [-95.9072, 38.4925],
          [-95.8992, 38.5044],
          [-95.9105, 38.5016],
          [-95.9139, 38.5103],
          [-95.9255, 38.5121],
          [-95.9131, 38.5105],
          [-95.9127, 38.5935],
          [-95.9312, 38.608],
          [-95.9495, 38.6518],
          [-95.9913, 38.6518],
          [-95.9867, 38.6952],
          [-96.0012, 38.6953],
          [-96.0018, 38.7319],
          [-96.0198, 38.739],
          [-96.3526, 38.7393],
          [-96.3164, 38.7681],
          [-96.3169, 38.7974],
          [-96.2983, 38.7976],
          [-96.2989, 38.8262],
          [-96.3538, 38.8261],
          [-96.3722, 38.8406],
          [-96.3722, 38.9131],
          [-96.4278, 38.9131],
          [-96.5017, 38.9275],
          [-96.5012, 39.0145],
          [-96.5413, 39.0149],
          [-96.5935, 39.0364],
          [-96.6232, 39.0365],
          [-96.603, 39.0066],
          [-96.6291, 39.0069],
          [-96.612, 38.9742],
          [-96.633, 38.9704],
          [-96.6573, 38.9826],
          [-96.6709, 38.9727],
          [-96.6505, 38.9521],
          [-96.6558, 38.9278],
          [-96.6318, 38.9069],
          [-96.6313, 38.8703],
          [-96.895, 38.8703],
          [-96.899, 38.8636],
          [-96.9087, 38.8413],
          [-96.927, 38.8451],
          [-96.9262, 38.9789],
          [-96.9364, 38.9722],
          [-96.9636, 38.9781],
          [-96.9633, 39.045],
          [-97.0003, 39.0596],
          [-97.0003, 39.0889],
          [-97.0008, 39.1326],
          [-96.9614, 39.1323],
          [-96.9613, 39.1466],
          [-96.9397, 39.1471],
          [-96.9267, 39.1376],
          [-96.9317, 39.1817],
          [-96.9608, 39.1829],
          [-96.9609, 39.3796],
          [-96.9235, 39.3791],
          [-96.9236, 39.4082],
          [-96.9234, 39.4226],
          [-96.9246, 39.4372],
          [-96.9562, 39.4377],
          [-96.9603, 39.4522],
          [-96.9048, 39.4518],
          [-96.9136, 39.4847],
          [-96.9126, 39.5411],
          [-96.897, 39.5271],
          [-96.8846, 39.5421],
          [-96.8843, 39.5664],
          [-96.8567, 39.5664],
          [-96.8064, 39.5665],
          [-96.8065, 39.7113],
          [-96.806, 39.813],
          [-96.8431, 39.8129],
          [-96.8437, 39.8272],
          [-96.8248, 39.8273],
          [-96.8241, 39.8997],
          [-96.8431, 39.904],
          [-96.8057, 39.9082],
          [-96.8053, 40.0014],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Halifax Mkt": {
    type: "Feature",
    properties: { code: "NS_HAL", name: "Halifax Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-60.1272, 43.9535],
            [-60.0592, 43.9325],
            [-59.8616, 43.9383],
            [-59.8644, 43.9266],
            [-60.0369, 43.9235],
            [-60.1272, 43.9535],
          ],
        ],
        [
          [
            [-60.2994, 46.2817],
            [-60.2977, 46.2706],
            [-60.3458, 46.2245],
            [-60.4119, 46.2264],
            [-60.4626, 46.181],
            [-60.528, 46.148],
            [-60.5521, 46.1874],
            [-60.513, 46.2048],
            [-60.3614, 46.325],
            [-60.2919, 46.3371],
            [-60.2994, 46.2817],
          ],
        ],
        [
          [
            [-60.9156, 45.5613],
            [-60.9768, 45.5382],
            [-60.9685, 45.4961],
            [-61.092, 45.5138],
            [-61.1094, 45.5548],
            [-61.0383, 45.589],
            [-60.9497, 45.5937],
            [-60.9156, 45.5613],
          ],
        ],
        [
          [
            [-60.3551, 46.7121],
            [-60.4058, 46.672],
            [-60.3519, 46.6151],
            [-60.4082, 46.5494],
            [-60.4592, 46.4544],
            [-60.4581, 46.4307],
            [-60.5223, 46.3741],
            [-60.5177, 46.3483],
            [-60.592, 46.2566],
            [-60.4469, 46.3459],
            [-60.424, 46.301],
            [-60.5313, 46.2219],
            [-60.5984, 46.1479],
            [-60.6486, 46.1162],
            [-60.8397, 46.0709],
            [-60.8822, 46.0944],
            [-60.9001, 46.0392],
            [-60.7753, 46.0718],
            [-60.7371, 46.068],
            [-60.8058, 46.0006],
            [-60.8136, 45.9449],
            [-60.8662, 45.9417],
            [-60.9101, 45.9188],
            [-60.9693, 45.9157],
            [-60.992, 45.9376],
            [-60.9426, 45.8491],
            [-61.0214, 45.8321],
            [-61.1503, 45.7579],
            [-61.1149, 45.7374],
            [-61.1694, 45.7101],
            [-61.0985, 45.6936],
            [-61.0213, 45.729],
            [-60.9629, 45.7683],
            [-60.8503, 45.7537],
            [-60.81, 45.7347],
            [-60.8181, 45.6877],
            [-60.7564, 45.7047],
            [-60.7388, 45.7424],
            [-60.7391, 45.798],
            [-60.6745, 45.8223],
            [-60.64, 45.8585],
            [-60.5507, 45.9021],
            [-60.5005, 45.9465],
            [-60.5601, 45.9589],
            [-60.5605, 45.9588],
            [-60.639, 45.9334],
            [-60.6585, 45.9095],
            [-60.7325, 45.9046],
            [-60.7694, 45.9612],
            [-60.6932, 46.0126],
            [-60.6471, 46.0223],
            [-60.5131, 46.0937],
            [-60.4294, 46.1524],
            [-60.3886, 46.2024],
            [-60.3229, 46.213],
            [-60.2977, 46.2706],
            [-60.2994, 46.2817],
            [-60.2827, 46.289],
            [-60.2052, 46.2515],
            [-60.1977, 46.2101],
            [-60.1262, 46.2676],
            [-59.953, 46.2172],
            [-59.9149, 46.1782],
            [-59.8442, 46.1777],
            [-59.8866, 46.1185],
            [-59.8904, 46.0589],
            [-59.9543, 46.048],
            [-59.9598, 46.0145],
            [-59.8425, 46.0067],
            [-59.7883, 45.951],
            [-59.8837, 45.9425],
            [-59.9437, 45.9171],
            [-59.9921, 45.8752],
            [-60.1172, 45.8844],
            [-60.1718, 45.8557],
            [-60.0791, 45.8055],
            [-60.1151, 45.7814],
            [-60.2052, 45.767],
            [-60.2272, 45.7245],
            [-60.294, 45.7106],
            [-60.4233, 45.6424],
            [-60.5416, 45.6308],
            [-60.685, 45.5882],
            [-60.7381, 45.5878],
            [-60.764, 45.6085],
            [-60.8721, 45.6198],
            [-60.8764, 45.6537],
            [-60.9776, 45.618],
            [-61.0007, 45.6263],
            [-61.0881, 45.5851],
            [-61.1616, 45.5824],
            [-61.1761, 45.6018],
            [-61.2535, 45.5955],
            [-61.2883, 45.548],
            [-61.331, 45.5647],
            [-61.3531, 45.6084],
            [-61.4335, 45.674],
            [-61.4662, 45.7168],
            [-61.4638, 45.7442],
            [-61.4961, 45.8027],
            [-61.5113, 45.9064],
            [-61.553, 46.0417],
            [-61.4838, 46.0931],
            [-61.4484, 46.1602],
            [-61.4157, 46.1927],
            [-61.3233, 46.2289],
            [-61.271, 46.2675],
            [-61.2584, 46.3057],
            [-61.1855, 46.3569],
            [-61.1713, 46.3931],
            [-61.0783, 46.4896],
            [-61.0778, 46.5243],
            [-61.0295, 46.5809],
            [-61.0551, 46.619],
            [-61.0288, 46.6509],
            [-60.9624, 46.6736],
            [-60.8912, 46.7904],
            [-60.8475, 46.8223],
            [-60.7987, 46.8317],
            [-60.7046, 46.9037],
            [-60.6711, 46.9757],
            [-60.599, 47.0429],
            [-60.4695, 47.0041],
            [-60.4235, 47.0348],
            [-60.4003, 46.999],
            [-60.4594, 46.9498],
            [-60.4514, 46.904],
            [-60.4013, 46.8715],
            [-60.3519, 46.8746],
            [-60.3011, 46.8475],
            [-60.3297, 46.8068],
            [-60.3205, 46.7537],
            [-60.3551, 46.7121],
          ],
        ],
        [
          [
            [-65.5875, 43.4467],
            [-65.6152, 43.4243],
            [-65.6552, 43.4577],
            [-65.5915, 43.4995],
            [-65.5574, 43.4873],
            [-65.5875, 43.4467],
          ],
        ],
        [
          [
            [-66.0874, 44.3727],
            [-66.0126, 44.4505],
            [-65.9458, 44.4858],
            [-65.8472, 44.5645],
            [-65.9376, 44.579],
            [-65.982, 44.558],
            [-66.1974, 44.3773],
            [-66.2059, 44.4058],
            [-66.113, 44.4947],
            [-65.9518, 44.6085],
            [-65.9188, 44.6094],
            [-65.7859, 44.6923],
            [-65.6975, 44.7386],
            [-65.5091, 44.8254],
            [-65.4715, 44.8515],
            [-65.3003, 44.9348],
            [-65.2333, 44.9599],
            [-65.1633, 45.0026],
            [-65.0837, 45.0302],
            [-64.9505, 45.1037],
            [-64.8299, 45.1503],
            [-64.6054, 45.201],
            [-64.4596, 45.2428],
            [-64.399, 45.2792],
            [-64.3798, 45.319],
            [-64.3285, 45.2917],
            [-64.3621, 45.2175],
            [-64.3512, 45.1655],
            [-64.3937, 45.1483],
            [-64.3354, 45.1033],
            [-64.2869, 45.1383],
            [-64.2297, 45.1099],
            [-64.1697, 45.1591],
            [-64.155, 45.1962],
            [-64.0136, 45.231],
            [-64.0027, 45.244],
            [-63.9005, 45.2777],
            [-63.8724, 45.2669],
            [-63.7433, 45.3025],
            [-63.7154, 45.3233],
            [-63.5608, 45.3121],
            [-63.5346, 45.3363],
            [-63.4897, 45.315],
            [-63.438, 45.3493],
            [-63.6016, 45.393],
            [-63.6947, 45.3872],
            [-63.7522, 45.4031],
            [-63.8684, 45.3803],
            [-63.8854, 45.3487],
            [-63.948, 45.3964],
            [-64.0383, 45.383],
            [-64.0604, 45.41],
            [-64.1765, 45.4032],
            [-64.2511, 45.3781],
            [-64.319, 45.3844],
            [-64.3923, 45.3644],
            [-64.4659, 45.396],
            [-64.5548, 45.4083],
            [-64.6881, 45.3789],
            [-64.7059, 45.3191],
            [-64.7701, 45.3291],
            [-64.8493, 45.3554],
            [-64.9449, 45.3294],
            [-64.914, 45.4305],
            [-64.8383, 45.4592],
            [-64.8361, 45.4801],
            [-64.7263, 45.5282],
            [-64.6861, 45.5363],
            [-64.6536, 45.579],
            [-64.5795, 45.6081],
            [-64.4888, 45.6738],
            [-64.4526, 45.6851],
            [-64.4491, 45.7362],
            [-64.375, 45.7706],
            [-64.3309, 45.8202],
            [-64.2954, 45.8056],
            [-64.2547, 45.8796],
            [-64.2271, 45.8957],
            [-64.1708, 45.954],
            [-64.1591, 45.9753],
            [-64.0469, 45.9769],
            [-64.0256, 46.0051],
            [-63.9133, 45.9839],
            [-63.8631, 45.9627],
            [-63.8693, 45.9345],
            [-63.8189, 45.9196],
            [-63.7608, 45.8761],
            [-63.6661, 45.8499],
            [-63.6785, 45.8709],
            [-63.4685, 45.8783],
            [-63.4149, 45.8607],
            [-63.4284, 45.8217],
            [-63.5141, 45.823],
            [-63.5427, 45.8396],
            [-63.5882, 45.8246],
            [-63.5469, 45.8303],
            [-63.5003, 45.8113],
            [-63.4113, 45.8135],
            [-63.3649, 45.7979],
            [-63.2961, 45.8093],
            [-63.2355, 45.7982],
            [-63.3849, 45.7445],
            [-63.1395, 45.7511],
            [-63.0804, 45.7806],
            [-62.9952, 45.7941],
            [-62.9524, 45.7809],
            [-62.7755, 45.7601],
            [-62.7363, 45.7234],
            [-62.6878, 45.74],
            [-62.6414, 45.7187],
            [-62.6421, 45.6679],
            [-62.6253, 45.6856],
            [-62.5483, 45.6711],
            [-62.5596, 45.6478],
            [-62.4601, 45.6531],
            [-62.4414, 45.6677],
            [-62.2941, 45.6936],
            [-62.2243, 45.7254],
            [-62.1722, 45.7623],
            [-62.0879, 45.7865],
            [-61.9787, 45.8727],
            [-61.9235, 45.8859],
            [-61.9096, 45.7672],
            [-61.8891, 45.7358],
            [-61.8988, 45.6928],
            [-61.8332, 45.6742],
            [-61.8161, 45.6428],
            [-61.7414, 45.6319],
            [-61.6267, 45.6448],
            [-61.57, 45.6916],
            [-61.4881, 45.6928],
            [-61.4341, 45.645],
            [-61.3991, 45.6315],
            [-61.3709, 45.5835],
            [-61.3128, 45.5396],
            [-61.2487, 45.5221],
            [-61.2292, 45.4831],
            [-61.2896, 45.4448],
            [-61.3957, 45.4206],
            [-61.4327, 45.4026],
            [-61.505, 45.3998],
            [-61.4652, 45.3532],
            [-61.2023, 45.3485],
            [-61.1406, 45.3583],
            [-61.1042, 45.3424],
            [-61.0285, 45.3446],
            [-61.0077, 45.3413],
            [-60.9712, 45.3082],
            [-61.0356, 45.2538],
            [-61.1521, 45.2111],
            [-61.1511, 45.2461],
            [-61.1506, 45.2466],
            [-61.1578, 45.2625],
            [-61.2612, 45.2434],
            [-61.3438, 45.2448],
            [-61.3704, 45.1718],
            [-61.4855, 45.1597],
            [-61.5417, 45.1694],
            [-61.607, 45.1472],
            [-61.7012, 45.0896],
            [-61.7796, 45.0861],
            [-61.8524, 45.0667],
            [-61.8528, 45.0363],
            [-61.9503, 45.0848],
            [-61.9834, 45.1345],
            [-61.9495, 45.0797],
            [-61.959, 45.042],
            [-62.0411, 45.0027],
            [-62.0535, 44.9706],
            [-62.1366, 44.9658],
            [-62.2094, 44.9443],
            [-62.2644, 44.942],
            [-62.3731, 44.8932],
            [-62.4086, 44.9024],
            [-62.4263, 44.8572],
            [-62.4859, 44.843],
            [-62.5453, 44.8565],
            [-62.635, 44.8224],
            [-62.6574, 44.7858],
            [-62.6969, 44.7986],
            [-62.7994, 44.7742],
            [-62.8205, 44.7253],
            [-62.8783, 44.7112],
            [-62.9484, 44.7252],
            [-62.996, 44.72],
            [-63.0392, 44.7782],
            [-63.0377, 44.7322],
            [-63.0047, 44.7219],
            [-63.0396, 44.6764],
            [-63.0844, 44.6949],
            [-63.1656, 44.6871],
            [-63.2376, 44.6805],
            [-63.2794, 44.6498],
            [-63.3618, 44.6462],
            [-63.4051, 44.6458],
            [-63.4443, 44.6087],
            [-63.4943, 44.6066],
            [-63.5404, 44.6376],
            [-63.5667, 44.6177],
            [-63.5439, 44.5852],
            [-63.5596, 44.5578],
            [-63.5188, 44.502],
            [-63.5347, 44.4812],
            [-63.6, 44.4806],
            [-63.7051, 44.4609],
            [-63.8405, 44.4907],
            [-63.9168, 44.4903],
            [-63.9485, 44.5284],
            [-63.9125, 44.5823],
            [-63.9309, 44.6039],
            [-63.9191, 44.6443],
            [-63.9238, 44.6708],
            [-64.0539, 44.621],
            [-64.006, 44.5076],
            [-64.066, 44.5027],
            [-64.1138, 44.4789],
            [-64.1169, 44.5344],
            [-64.1701, 44.5845],
            [-64.264, 44.5391],
            [-64.3006, 44.5097],
            [-64.3042, 44.4659],
            [-64.3372, 44.4218],
            [-64.3369, 44.421],
            [-64.3316, 44.4058],
            [-64.2289, 44.3749],
            [-64.3438, 44.3642],
            [-64.2544, 44.2692],
            [-64.3688, 44.2597],
            [-64.434, 44.2281],
            [-64.4362, 44.1989],
            [-64.4796, 44.1531],
            [-64.6139, 44.156],
            [-64.5644, 44.0983],
            [-64.5757, 44.0578],
            [-64.6196, 44.074],
            [-64.7324, 43.9522],
            [-64.7945, 43.9481],
            [-64.835, 43.9244],
            [-64.7822, 43.8661],
            [-64.8309, 43.8374],
            [-64.8573, 43.853],
            [-64.9454, 43.7627],
            [-64.9905, 43.7522],
            [-65.0278, 43.6952],
            [-65.0812, 43.7215],
            [-65.1233, 43.7053],
            [-65.217, 43.6994],
            [-65.2575, 43.6575],
            [-65.3233, 43.6905],
            [-65.3344, 43.5783],
            [-65.3957, 43.5469],
            [-65.4199, 43.5787],
            [-65.457, 43.5525],
            [-65.4474, 43.5286],
            [-65.4935, 43.4928],
            [-65.5457, 43.5137],
            [-65.5551, 43.5507],
            [-65.6062, 43.5457],
            [-65.6533, 43.5112],
            [-65.6551, 43.4817],
            [-65.7308, 43.4923],
            [-65.7412, 43.5717],
            [-65.7865, 43.5664],
            [-65.7635, 43.6658],
            [-65.8399, 43.7181],
            [-65.9212, 43.8103],
            [-65.987, 43.8289],
            [-66.0085, 43.8141],
            [-65.9948, 43.7401],
            [-66.0277, 43.731],
            [-66.0676, 43.7548],
            [-66.1155, 43.7552],
            [-66.1398, 43.7948],
            [-66.1353, 43.8387],
            [-66.1694, 43.9046],
            [-66.1513, 43.9245],
            [-66.1687, 43.9721],
            [-66.1542, 44.0032],
            [-66.1798, 44.0765],
            [-66.2131, 44.1088],
            [-66.1804, 44.178],
            [-66.1409, 44.2222],
            [-66.1261, 44.3324],
            [-66.0874, 44.3727],
          ],
        ],
        [
          [
            [-66.3361, 44.2735],
            [-66.2154, 44.4047],
            [-66.2146, 44.3755],
            [-66.2868, 44.2899],
            [-66.3361, 44.2735],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Moncton Mkt": {
    type: "Feature",
    properties: { code: "NB_MON", name: "Moncton Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-63.7026, 46.5554],
            [-63.6676, 46.5679],
            [-63.505, 46.535],
            [-63.4615, 46.4628],
            [-63.4194, 46.5012],
            [-63.3122, 46.49],
            [-63.2676, 46.4232],
            [-63.2278, 46.4318],
            [-63.1366, 46.431],
            [-63.0438, 46.4141],
            [-63.0038, 46.3947],
            [-62.9709, 46.4124],
            [-62.8521, 46.4347],
            [-62.7283, 46.444],
            [-62.6293, 46.4676],
            [-62.4728, 46.4752],
            [-62.3769, 46.4681],
            [-62.1648, 46.4858],
            [-61.9707, 46.4518],
            [-62.05, 46.4155],
            [-62.1069, 46.378],
            [-62.289, 46.3331],
            [-62.3596, 46.351],
            [-62.3766, 46.2516],
            [-62.409, 46.2132],
            [-62.5173, 46.1978],
            [-62.536, 46.1458],
            [-62.4665, 46.1447],
            [-62.4499, 46.0896],
            [-62.4817, 46.0453],
            [-62.5367, 46.0231],
            [-62.4576, 46.0036],
            [-62.5169, 45.9729],
            [-62.6632, 45.959],
            [-62.8242, 45.965],
            [-62.9653, 46.0595],
            [-62.8865, 46.109],
            [-62.9629, 46.1227],
            [-62.954, 46.1913],
            [-63.0522, 46.1863],
            [-63.0819, 46.1993],
            [-63.253, 46.1294],
            [-63.3077, 46.1511],
            [-63.3978, 46.1684],
            [-63.496, 46.2045],
            [-63.6218, 46.2143],
            [-63.6938, 46.2749],
            [-63.815, 46.3388],
            [-63.7154, 46.3507],
            [-63.803, 46.3959],
            [-63.8853, 46.3929],
            [-63.9361, 46.419],
            [-64.0027, 46.3852],
            [-64.134, 46.4016],
            [-64.1396, 46.4307],
            [-64.1017, 46.4692],
            [-64.1128, 46.5234],
            [-64.0556, 46.5764],
            [-64.0899, 46.6287],
            [-64.1258, 46.5892],
            [-64.2179, 46.6304],
            [-64.3009, 46.6307],
            [-64.382, 46.615],
            [-64.4115, 46.6581],
            [-64.3988, 46.7246],
            [-64.2646, 46.8284],
            [-64.226, 46.8967],
            [-64.1887, 46.9347],
            [-64.0574, 46.9999],
            [-63.9939, 47.0612],
            [-63.9933, 46.9478],
            [-63.9739, 46.881],
            [-64.017, 46.8445],
            [-64.0319, 46.8045],
            [-64.0774, 46.7529],
            [-64.0178, 46.7406],
            [-63.9232, 46.68],
            [-63.8791, 46.639],
            [-63.8777, 46.6062],
            [-63.8325, 46.5797],
            [-63.8822, 46.5133],
            [-63.8033, 46.4865],
            [-63.7946, 46.4379],
            [-63.7087, 46.4419],
            [-63.7027, 46.4747],
            [-63.7476, 46.4973],
            [-63.698, 46.5284],
            [-63.7026, 46.5554],
          ],
        ],
        [
          [
            [-64.4835, 47.9955],
            [-64.4741, 47.9415],
            [-64.5366, 47.9148],
            [-64.5908, 47.9384],
            [-64.5477, 47.9724],
            [-64.5423, 48.0161],
            [-64.4835, 47.9955],
          ],
        ],
        [
          [
            [-64.7065, 47.8339],
            [-64.6311, 47.8866],
            [-64.5685, 47.8782],
            [-64.5076, 47.8481],
            [-64.568, 47.7783],
            [-64.6153, 47.741],
            [-64.6964, 47.7618],
            [-64.6677, 47.8192],
            [-64.7065, 47.8339],
          ],
        ],
        [
          [
            [-64.7723, 47.7848],
            [-64.7743, 47.7853],
            [-64.7965, 47.8211],
            [-64.7723, 47.7848],
          ],
        ],
        [
          [
            [-66.8861, 44.6689],
            [-66.8277, 44.7715],
            [-66.7869, 44.7998],
            [-66.7352, 44.7145],
            [-66.7363, 44.6856],
            [-66.8174, 44.6493],
            [-66.8375, 44.6537],
            [-66.887, 44.6002],
            [-66.9082, 44.611],
            [-66.8861, 44.6689],
          ],
        ],
        [
          [
            [-66.98, 44.8669],
            [-66.9358, 44.9294],
            [-66.8892, 44.9138],
            [-66.9226, 44.8362],
            [-66.98, 44.8669],
          ],
        ],
        [
          [
            [-67.0198, 44.9754],
            [-66.9855, 45.0089],
            [-66.9517, 44.9719],
            [-66.98, 44.9389],
            [-67.0198, 44.9754],
          ],
        ],
        [
          [
            [-66.3879, 48.0286],
            [-66.2768, 47.9879],
            [-66.1099, 47.9559],
            [-66.0826, 47.9249],
            [-65.8999, 47.9213],
            [-65.8308, 47.9081],
            [-65.7322, 47.8303],
            [-65.6988, 47.7117],
            [-65.622, 47.6525],
            [-65.5694, 47.6691],
            [-65.5047, 47.6688],
            [-65.4498, 47.6894],
            [-65.3588, 47.756],
            [-65.3144, 47.7611],
            [-65.1903, 47.8177],
            [-65.0141, 47.8372],
            [-64.9858, 47.8219],
            [-65.0669, 47.7886],
            [-65.0011, 47.7727],
            [-64.9648, 47.793],
            [-64.8552, 47.8087],
            [-64.8022, 47.8038],
            [-64.7688, 47.7441],
            [-64.7249, 47.7553],
            [-64.6758, 47.7157],
            [-64.7564, 47.6919],
            [-64.8438, 47.5851],
            [-64.9054, 47.5542],
            [-64.8991, 47.5054],
            [-64.8749, 47.4801],
            [-64.8748, 47.4801],
            [-64.9081, 47.3719],
            [-64.9445, 47.3697],
            [-64.9749, 47.2769],
            [-65.0512, 47.266],
            [-65.1138, 47.2063],
            [-65.2667, 47.1163],
            [-65.3449, 47.0908],
            [-65.3017, 47.0608],
            [-65.2055, 47.0821],
            [-65.214, 47.0471],
            [-65.1632, 47.0367],
            [-65.1015, 47.0743],
            [-65.0298, 47.0854],
            [-64.9828, 47.0508],
            [-64.9479, 47.0762],
            [-64.8616, 47.0817],
            [-64.8269, 47.0493],
            [-64.8123, 46.9754],
            [-64.8823, 46.9283],
            [-64.9062, 46.8958],
            [-64.9041, 46.8845],
            [-64.964, 46.8422],
            [-64.9134, 46.8312],
            [-64.9004, 46.746],
            [-64.8178, 46.7279],
            [-64.8497, 46.6948],
            [-64.7937, 46.6836],
            [-64.719, 46.6885],
            [-64.7076, 46.6238],
            [-64.715, 46.5586],
            [-64.6652, 46.5148],
            [-64.6669, 46.4662],
            [-64.6298, 46.4389],
            [-64.612, 46.381],
            [-64.6161, 46.3362],
            [-64.5096, 46.3137],
            [-64.5739, 46.2852],
            [-64.5303, 46.2424],
            [-64.3736, 46.2223],
            [-64.2657, 46.2365],
            [-64.0682, 46.1695],
            [-63.977, 46.1823],
            [-63.8836, 46.1436],
            [-63.8335, 46.1504],
            [-63.7847, 46.1288],
            [-63.7728, 46.1019],
            [-63.8956, 46.0588],
            [-64.014, 46.0605],
            [-64.0921, 46.0291],
            [-64.0469, 45.9769],
            [-64.1591, 45.9753],
            [-64.2203, 45.9017],
            [-64.2547, 45.8796],
            [-64.273, 45.8524],
            [-64.3354, 45.8675],
            [-64.4052, 45.8215],
            [-64.4241, 45.791],
            [-64.4869, 45.7607],
            [-64.489, 45.8068],
            [-64.5182, 45.8388],
            [-64.5681, 45.8346],
            [-64.6691, 45.7386],
            [-64.6985, 45.6984],
            [-64.6907, 45.6879],
            [-64.7793, 45.5934],
            [-64.7912, 45.6226],
            [-64.866, 45.6321],
            [-64.9467, 45.5985],
            [-64.9671, 45.5663],
            [-65.0203, 45.5416],
            [-65.1325, 45.5223],
            [-65.1934, 45.4931],
            [-65.2785, 45.4705],
            [-65.4533, 45.3968],
            [-65.5316, 45.3551],
            [-65.5346, 45.324],
            [-65.7408, 45.2518],
            [-65.8165, 45.2643],
            [-65.8539, 45.215],
            [-65.909, 45.194],
            [-65.9836, 45.2071],
            [-66.0261, 45.2732],
            [-66.0988, 45.2751],
            [-66.0965, 45.225],
            [-66.1512, 45.2204],
            [-66.1455, 45.1823],
            [-66.2187, 45.1415],
            [-66.2637, 45.1446],
            [-66.3286, 45.1221],
            [-66.3787, 45.1188],
            [-66.4499, 45.0728],
            [-66.4991, 45.1448],
            [-66.6912, 45.0708],
            [-66.7842, 45.0405],
            [-66.8525, 45.0595],
            [-66.8922, 45.0382],
            [-66.9216, 45.0936],
            [-66.8958, 45.1303],
            [-66.9531, 45.1575],
            [-67.0037, 45.1452],
            [-67.065, 45.1058],
            [-67.1314, 45.1399],
            [-67.1813, 45.1899],
            [-67.1608, 45.1687],
            [-67.2071, 45.1733],
            [-67.2255, 45.1649],
            [-67.2557, 45.1904],
            [-67.2861, 45.1925],
            [-67.3027, 45.1607],
            [-67.296, 45.1478],
            [-67.32, 45.1424],
            [-67.3208, 45.1313],
            [-67.3409, 45.125],
            [-67.405, 45.1601],
            [-67.4293, 45.1935],
            [-67.4393, 45.2261],
            [-67.4764, 45.2754],
            [-67.4601, 45.3004],
            [-67.4565, 45.3254],
            [-67.4289, 45.3437],
            [-67.4255, 45.3572],
            [-67.4342, 45.365],
            [-67.4192, 45.3771],
            [-67.4613, 45.4222],
            [-67.4741, 45.4251],
            [-67.4821, 45.4594],
            [-67.5035, 45.4896],
            [-67.4675, 45.4952],
            [-67.4636, 45.5083],
            [-67.4497, 45.4991],
            [-67.4501, 45.5103],
            [-67.4162, 45.5013],
            [-67.4349, 45.5281],
            [-67.4207, 45.5497],
            [-67.424, 45.5774],
            [-67.4567, 45.6043],
            [-67.499, 45.5866],
            [-67.6084, 45.6061],
            [-67.6391, 45.6251],
            [-67.6456, 45.6133],
            [-67.6752, 45.6307],
            [-67.7096, 45.6813],
            [-67.7444, 45.6729],
            [-67.7543, 45.6676],
            [-67.7687, 45.6775],
            [-67.8036, 45.6776],
            [-67.8181, 45.6937],
            [-67.8031, 45.696],
            [-67.8097, 45.7294],
            [-67.782, 45.7311],
            [-67.7932, 45.7505],
            [-67.8063, 45.7552],
            [-67.8061, 45.7951],
            [-67.7781, 45.8192],
            [-67.7553, 45.8233],
            [-67.7971, 45.86],
            [-67.8039, 45.8829],
            [-67.7681, 45.8981],
            [-67.7653, 45.9098],
            [-67.7506, 45.9176],
            [-67.7813, 45.9435],
            [-67.7902, 47.0672],
            [-67.8829, 47.1044],
            [-67.8908, 47.1265],
            [-67.9287, 47.1562],
            [-67.9572, 47.1996],
            [-67.9897, 47.21],
            [-68.0197, 47.2385],
            [-68.1359, 47.2957],
            [-68.1547, 47.3252],
            [-68.2146, 47.3396],
            [-68.2345, 47.3553],
            [-68.2649, 47.3522],
            [-68.2848, 47.3603],
            [-68.3632, 47.3547],
            [-68.3847, 47.3241],
            [-68.3779, 47.2874],
            [-68.4488, 47.282],
            [-68.475, 47.2972],
            [-68.5168, 47.2961],
            [-68.5498, 47.2822],
            [-68.5776, 47.2876],
            [-68.6113, 47.2454],
            [-68.6594, 47.2364],
            [-68.717, 47.2406],
            [-68.9028, 47.1795],
            [-69.0338, 47.2404],
            [-69.0506, 47.2563],
            [-69.051, 47.3001],
            [-68.7996, 47.3489],
            [-68.5693, 47.4271],
            [-68.3835, 47.5532],
            [-68.3823, 47.9161],
            [-68.1228, 47.9163],
            [-68.1226, 47.9998],
            [-67.5981, 47.9999],
            [-67.5947, 47.925],
            [-67.4832, 47.9031],
            [-67.38, 47.8567],
            [-67.3523, 47.874],
            [-67.3367, 47.8728],
            [-67.2878, 47.8989],
            [-67.2282, 47.8873],
            [-67.1427, 47.8925],
            [-67.133, 47.9153],
            [-67.0593, 47.9358],
            [-66.9792, 47.8942],
            [-66.9646, 47.9407],
            [-66.9213, 47.9846],
            [-66.8563, 48.0007],
            [-66.7942, 47.9914],
            [-66.6936, 48.0068],
            [-66.5281, 48.0484],
            [-66.5024, 48.077],
            [-66.4822, 48.0835],
            [-66.3821, 48.0882],
            [-66.3259, 48.0608],
            [-66.3879, 48.0286],
          ],
          [
            [-66.0989, 45.2751],
            [-66.1347, 45.2947],
            [-66.0511, 45.3366],
            [-66, 45.3814],
            [-66.0037, 45.424],
            [-65.979, 45.4535],
            [-65.886, 45.5139],
            [-66.0327, 45.4239],
            [-66.088, 45.3735],
            [-66.1703, 45.3275],
            [-66.1905, 45.3043],
            [-66.1406, 45.2723],
            [-66.0989, 45.2751],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Quebec Mkt": {
    type: "Feature",
    properties: { code: "PQ_QUE", name: "Quebec Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-58.4269, 51.2097],
            [-58.3648, 51.247],
            [-58.3437, 51.2073],
            [-58.4269, 51.2097],
          ],
        ],
        [
          [
            [-58.5665, 51.2281],
            [-58.5159, 51.2592],
            [-58.4132, 51.2673],
            [-58.4402, 51.214],
            [-58.475, 51.2081],
            [-58.5665, 51.2281],
          ],
        ],
        [
          [
            [-58.7979, 51.0487],
            [-58.6685, 51.1055],
            [-58.6841, 51.0696],
            [-58.7979, 51.0487],
          ],
        ],
        [
          [
            [-59.3707, 50.6321],
            [-59.3264, 50.6327],
            [-59.2877, 50.5822],
            [-59.2822, 50.5465],
            [-59.356, 50.5274],
            [-59.3196, 50.5886],
            [-59.3562, 50.5897],
            [-59.3707, 50.6321],
          ],
        ],
        [
          [
            [-61.9604, 47.3852],
            [-61.9204, 47.4139],
            [-61.8622, 47.4295],
            [-61.8005, 47.4634],
            [-61.7139, 47.5343],
            [-61.5593, 47.6199],
            [-61.4241, 47.6352],
            [-61.3914, 47.6239],
            [-61.4935, 47.5785],
            [-61.4823, 47.6177],
            [-61.5569, 47.6167],
            [-61.6141, 47.5759],
            [-61.697, 47.537],
            [-61.7806, 47.4703],
            [-61.8717, 47.4215],
            [-61.8469, 47.3964],
            [-61.8794, 47.3447],
            [-61.9281, 47.3408],
            [-61.9359, 47.3108],
            [-61.896, 47.2398],
            [-61.9514, 47.2123],
            [-62.0192, 47.2239],
            [-61.9522, 47.3403],
            [-61.9604, 47.3852],
          ],
        ],
        [
          [
            [-63.9238, 50.2246],
            [-63.8804, 50.2348],
            [-63.8556, 50.1843],
            [-63.9066, 50.1851],
            [-63.9238, 50.2246],
          ],
        ],
        [
          [
            [-64.5181, 49.8808],
            [-64.4621, 49.9126],
            [-64.3525, 49.9231],
            [-64.3072, 49.9387],
            [-64.1484, 49.9508],
            [-64.0771, 49.926],
            [-64, 49.9188],
            [-63.9228, 49.881],
            [-63.832, 49.8749],
            [-63.6164, 49.8423],
            [-63.3865, 49.8223],
            [-63.0267, 49.7489],
            [-62.9481, 49.728],
            [-62.9265, 49.7039],
            [-62.8581, 49.6969],
            [-62.7633, 49.6537],
            [-62.7042, 49.6551],
            [-62.6429, 49.6152],
            [-62.5206, 49.5857],
            [-62.444, 49.543],
            [-62.4444, 49.5089],
            [-62.4012, 49.4817],
            [-62.3566, 49.4872],
            [-62.3135, 49.4593],
            [-62.2567, 49.4501],
            [-62.2485, 49.4174],
            [-62.1058, 49.3743],
            [-62.0691, 49.3828],
            [-61.9797, 49.3628],
            [-61.9697, 49.3395],
            [-61.8993, 49.3463],
            [-61.8345, 49.3063],
            [-61.8019, 49.2458],
            [-61.7226, 49.1928],
            [-61.6807, 49.1178],
            [-61.7129, 49.0936],
            [-61.844, 49.0649],
            [-61.9919, 49.0764],
            [-62.052, 49.0674],
            [-62.2084, 49.0668],
            [-62.2501, 49.0568],
            [-62.4728, 49.1174],
            [-62.6178, 49.1393],
            [-62.7567, 49.1513],
            [-62.8531, 49.178],
            [-62.9192, 49.1827],
            [-63.1087, 49.2287],
            [-63.2389, 49.2758],
            [-63.3056, 49.33],
            [-63.3357, 49.3167],
            [-63.4249, 49.3511],
            [-63.5666, 49.3833],
            [-63.6192, 49.4544],
            [-63.6358, 49.511],
            [-63.768, 49.585],
            [-63.8315, 49.6357],
            [-63.9128, 49.6602],
            [-64, 49.7002],
            [-64.2, 49.7375],
            [-64.3185, 49.7801],
            [-64.3298, 49.807],
            [-64.3972, 49.8188],
            [-64.5215, 49.8609],
            [-64.5181, 49.8808],
          ],
        ],
        [
          [
            [-66.2484, 54.9998],
            [-65, 55],
            [-63.5587, 55.0001],
            [-63.5575, 54.99],
            [-63.5763, 54.9881],
            [-63.5731, 54.9788],
            [-63.5869, 54.9738],
            [-63.6113, 54.9489],
            [-63.5778, 54.93],
            [-63.6123, 54.92],
            [-63.5914, 54.8966],
            [-63.6196, 54.8996],
            [-63.6307, 54.9092],
            [-63.6634, 54.9105],
            [-63.6613, 54.9],
            [-63.6848, 54.9046],
            [-63.6964, 54.9254],
            [-63.716, 54.9256],
            [-63.7917, 54.9559],
            [-63.8205, 54.948],
            [-63.8241, 54.9343],
            [-63.7995, 54.9262],
            [-63.8377, 54.9145],
            [-63.8526, 54.8761],
            [-63.8171, 54.817],
            [-63.869, 54.8071],
            [-63.8718, 54.8005],
            [-63.9036, 54.8011],
            [-63.8908, 54.7888],
            [-63.91, 54.7886],
            [-63.924, 54.7784],
            [-63.8897, 54.7684],
            [-63.8029, 54.7257],
            [-63.7631, 54.6737],
            [-63.7238, 54.6598],
            [-63.7126, 54.625],
            [-63.7326, 54.6232],
            [-63.8134, 54.6508],
            [-63.8196, 54.6407],
            [-63.8343, 54.6412],
            [-63.8346, 54.6299],
            [-63.87, 54.6234],
            [-63.8978, 54.6007],
            [-63.9744, 54.6201],
            [-63.992, 54.6049],
            [-64.0351, 54.6071],
            [-64.0543, 54.6009],
            [-64.0799, 54.6216],
            [-64.113, 54.6335],
            [-64.1027, 54.6556],
            [-64.1807, 54.6936],
            [-64.1965, 54.7299],
            [-64.2882, 54.7311],
            [-64.2947, 54.7534],
            [-64.3975, 54.7905],
            [-64.4928, 54.7911],
            [-64.4715, 54.7703],
            [-64.4855, 54.771],
            [-64.4748, 54.7522],
            [-64.5569, 54.7137],
            [-64.5976, 54.7287],
            [-64.6229, 54.7219],
            [-64.68, 54.7357],
            [-64.6994, 54.7236],
            [-64.7309, 54.7256],
            [-64.7349, 54.736],
            [-64.7697, 54.7301],
            [-64.801, 54.7558],
            [-64.7611, 54.8296],
            [-64.8159, 54.8491],
            [-64.9026, 54.9017],
            [-64.915, 54.92],
            [-64.9719, 54.9374],
            [-65.0177, 54.9327],
            [-65.0481, 54.9555],
            [-65.0858, 54.9696],
            [-65.1264, 54.9615],
            [-65.1345, 54.9282],
            [-65.1736, 54.9143],
            [-65.2096, 54.848],
            [-65.2827, 54.8444],
            [-65.2963, 54.8176],
            [-65.3358, 54.852],
            [-65.3558, 54.8188],
            [-65.3846, 54.83],
            [-65.4075, 54.8199],
            [-65.4114, 54.8419],
            [-65.4563, 54.8493],
            [-65.4873, 54.8344],
            [-65.4346, 54.7842],
            [-65.4291, 54.7481],
            [-65.4555, 54.7286],
            [-65.4873, 54.7285],
            [-65.504, 54.7363],
            [-65.5574, 54.7303],
            [-65.5621, 54.7398],
            [-65.6213, 54.7385],
            [-65.6321, 54.7545],
            [-65.647, 54.7532],
            [-65.6485, 54.7352],
            [-65.6932, 54.7082],
            [-65.757, 54.7868],
            [-65.8506, 54.8508],
            [-65.8364, 54.8747],
            [-65.8572, 54.893],
            [-65.8386, 54.908],
            [-65.8498, 54.9272],
            [-65.888, 54.9254],
            [-65.9305, 54.941],
            [-66.0312, 54.9425],
            [-66.049, 54.9374],
            [-66.0364, 54.9234],
            [-66.0484, 54.9204],
            [-66.1436, 54.9823],
            [-66.1616, 54.9878],
            [-66.168, 54.973],
            [-66.1886, 54.9808],
            [-66.2101, 54.9792],
            [-66.2355, 54.9918],
            [-66.2423, 54.9745],
            [-66.2594, 54.9998],
            [-66.2484, 54.9998],
          ],
        ],
        [
          [
            [-70.4353, 47.3919],
            [-70.3889, 47.4225],
            [-70.3145, 47.4269],
            [-70.3951, 47.3681],
            [-70.4353, 47.3919],
          ],
        ],
        [
          [
            [-67.1453, 55.0001],
            [-66.766, 55],
            [-66.7402, 54.9851],
            [-66.7422, 54.9695],
            [-66.6814, 54.9237],
            [-66.6897, 54.898],
            [-66.6026, 54.8135],
            [-66.6594, 54.7702],
            [-66.7237, 54.7886],
            [-66.7305, 54.7668],
            [-66.6795, 54.7449],
            [-66.6956, 54.7331],
            [-66.7142, 54.732],
            [-66.7442, 54.75],
            [-66.7538, 54.7456],
            [-66.7732, 54.766],
            [-66.8127, 54.7794],
            [-66.8757, 54.816],
            [-66.927, 54.8022],
            [-66.9478, 54.8223],
            [-67.033, 54.862],
            [-67.0565, 54.895],
            [-67.2152, 54.9908],
            [-67.2192, 55.0001],
            [-67.1453, 55.0001],
          ],
        ],
        [
          [
            [-67.0577, 55.2501],
            [-66.9684, 55.2501],
            [-66.9273, 55.2911],
            [-66.9392, 55.3024],
            [-66.8941, 55.2904],
            [-66.8665, 55.2859],
            [-66.8339, 55.3019],
            [-66.765, 55.2493],
            [-66.7741, 55.2084],
            [-66.7746, 55.2045],
            [-66.7925, 55.1199],
            [-66.7474, 55.065],
            [-66.7563, 55.0427],
            [-66.7788, 55.0366],
            [-66.8621, 55.0924],
            [-67.0691, 55.2393],
            [-67.0577, 55.2501],
          ],
        ],
        [
          [
            [-71.1407, 46.8526],
            [-71.0989, 46.8926],
            [-71.002, 46.9439],
            [-70.9352, 46.9982],
            [-70.8461, 47.024],
            [-70.8081, 46.9966],
            [-70.8792, 46.9271],
            [-70.9571, 46.8964],
            [-71.0031, 46.8583],
            [-71.1304, 46.8449],
            [-71.1407, 46.8526],
          ],
        ],
        [
          [
            [-69.5131, 47.928],
            [-69.4337, 47.9963],
            [-69.3471, 48.0239],
            [-69.2206, 48.104],
            [-69.1865, 48.1347],
            [-69.133, 48.1538],
            [-69.1057, 48.192],
            [-68.9531, 48.2909],
            [-68.8667, 48.3176],
            [-68.8542, 48.334],
            [-68.7592, 48.3541],
            [-68.7269, 48.3792],
            [-68.6696, 48.393],
            [-68.5793, 48.4437],
            [-68.539, 48.4438],
            [-68.5116, 48.4776],
            [-68.3858, 48.5506],
            [-68.3552, 48.5481],
            [-68.2912, 48.5863],
            [-68.1831, 48.6348],
            [-68.0879, 48.645],
            [-68.0631, 48.6734],
            [-67.9603, 48.685],
            [-67.8333, 48.7508],
            [-67.597, 48.8204],
            [-67.5753, 48.8386],
            [-67.4815, 48.856],
            [-67.371, 48.8987],
            [-67.0835, 48.9646],
            [-66.9456, 49.0213],
            [-66.808, 49.0543],
            [-66.7415, 49.0897],
            [-66.6003, 49.1124],
            [-66.5583, 49.1286],
            [-66.4767, 49.1305],
            [-66.4138, 49.1605],
            [-66.2376, 49.1972],
            [-66.0524, 49.2211],
            [-65.7611, 49.2395],
            [-65.5655, 49.2569],
            [-65.331, 49.2515],
            [-65.2781, 49.2331],
            [-65.0476, 49.2237],
            [-64.8521, 49.1873],
            [-64.7455, 49.1393],
            [-64.604, 49.1097],
            [-64.4751, 49.0538],
            [-64.3946, 49.0084],
            [-64.2408, 48.8998],
            [-64.2006, 48.8562],
            [-64.2426, 48.805],
            [-64.3755, 48.8496],
            [-64.4569, 48.8615],
            [-64.4817, 48.8295],
            [-64.4043, 48.8228],
            [-64.3758, 48.7684],
            [-64.2624, 48.7067],
            [-64.2355, 48.6679],
            [-64.231, 48.6253],
            [-64.325, 48.6098],
            [-64.2903, 48.5545],
            [-64.2047, 48.5248],
            [-64.2181, 48.5003],
            [-64.3062, 48.4734],
            [-64.3292, 48.4361],
            [-64.3968, 48.4165],
            [-64.5813, 48.3778],
            [-64.6743, 48.3464],
            [-64.696, 48.3044],
            [-64.7399, 48.281],
            [-64.7742, 48.2056],
            [-64.9356, 48.1769],
            [-64.9504, 48.1513],
            [-65.0307, 48.1067],
            [-65.1198, 48.0737],
            [-65.1402, 48.0549],
            [-65.3245, 48.003],
            [-65.4485, 48.0039],
            [-65.5281, 48.0648],
            [-65.6156, 48.0795],
            [-65.7298, 48.1141],
            [-65.8129, 48.1226],
            [-65.9025, 48.1916],
            [-65.9371, 48.2001],
            [-66.0009, 48.1634],
            [-66.0489, 48.1112],
            [-66.0995, 48.0978],
            [-66.1734, 48.1138],
            [-66.2726, 48.0892],
            [-66.3259, 48.0608],
            [-66.3489, 48.0771],
            [-66.3868, 48.0888],
            [-66.4765, 48.0846],
            [-66.5024, 48.077],
            [-66.5281, 48.0484],
            [-66.6936, 48.0068],
            [-66.7942, 47.9914],
            [-66.8563, 48.0007],
            [-66.9213, 47.9846],
            [-66.9646, 47.9407],
            [-66.9792, 47.8942],
            [-67.0593, 47.9358],
            [-67.133, 47.9153],
            [-67.1427, 47.8925],
            [-67.2282, 47.8873],
            [-67.2878, 47.8989],
            [-67.3367, 47.8728],
            [-67.3523, 47.874],
            [-67.38, 47.8567],
            [-67.4832, 47.9031],
            [-67.5947, 47.925],
            [-67.5981, 47.9999],
            [-68.1226, 47.9998],
            [-68.1228, 47.9163],
            [-68.3823, 47.9161],
            [-68.3835, 47.5532],
            [-68.5693, 47.4271],
            [-68.7996, 47.3489],
            [-69.0507, 47.3001],
            [-69.0546, 47.3757],
            [-69.0397, 47.3838],
            [-69.0424, 47.4047],
            [-69.0316, 47.4022],
            [-69.03, 47.4082],
            [-69.0436, 47.4274],
            [-69.0523, 47.4222],
            [-69.063, 47.4344],
            [-69.0906, 47.4244],
            [-69.1072, 47.4274],
            [-69.1223, 47.4428],
            [-69.1792, 47.4573],
            [-69.2051, 47.4519],
            [-69.2239, 47.4595],
            [-69.9971, 46.6958],
            [-70.0566, 46.4156],
            [-70.0967, 46.4096],
            [-70.1108, 46.3861],
            [-70.128, 46.3793],
            [-70.127, 46.3709],
            [-70.1918, 46.3497],
            [-70.209, 46.3297],
            [-70.2058, 46.2999],
            [-70.2317, 46.291],
            [-70.2716, 46.2101],
            [-70.2927, 46.1915],
            [-70.2363, 46.1452],
            [-70.2531, 46.1001],
            [-70.2843, 46.1006],
            [-70.3019, 46.0822],
            [-70.3102, 46.0632],
            [-70.3178, 46.0186],
            [-70.3034, 45.9991],
            [-70.2843, 45.9956],
            [-70.3078, 45.9823],
            [-70.315, 45.9625],
            [-70.2758, 45.9672],
            [-70.239, 45.944],
            [-70.2622, 45.9244],
            [-70.253, 45.9075],
            [-70.2652, 45.8931],
            [-70.2593, 45.8895],
            [-70.3086, 45.8583],
            [-70.342, 45.8528],
            [-70.3715, 45.8352],
            [-70.3982, 45.7972],
            [-70.4171, 45.7951],
            [-70.4074, 45.7623],
            [-70.3882, 45.7514],
            [-70.3941, 45.7402],
            [-70.384, 45.7343],
            [-70.4002, 45.72],
            [-70.4391, 45.7038],
            [-70.4663, 45.706],
            [-70.5255, 45.6667],
            [-70.5336, 45.6714],
            [-70.5578, 45.6669],
            [-70.5914, 45.6297],
            [-70.645, 45.6067],
            [-70.6773, 45.5518],
            [-70.7235, 45.5124],
            [-70.7112, 45.4987],
            [-70.7172, 45.4883],
            [-70.6917, 45.4738],
            [-70.6802, 45.452],
            [-70.651, 45.4453],
            [-70.63, 45.4265],
            [-70.635, 45.4116],
            [-70.6219, 45.4031],
            [-70.6352, 45.3967],
            [-70.6371, 45.3823],
            [-70.6598, 45.3775],
            [-70.6607, 45.385],
            [-70.6813, 45.3949],
            [-70.7102, 45.3901],
            [-70.722, 45.3946],
            [-70.7541, 45.4277],
            [-70.7962, 45.4276],
            [-70.8261, 45.3984],
            [-70.8022, 45.3667],
            [-70.8196, 45.3396],
            [-70.8077, 45.3271],
            [-70.8118, 45.3038],
            [-70.8344, 45.2935],
            [-70.8329, 45.2777],
            [-70.8491, 45.2621],
            [-70.8495, 45.2446],
            [-70.8387, 45.2339],
            [-70.858, 45.2286],
            [-70.8967, 45.2413],
            [-70.9215, 45.2789],
            [-70.9189, 45.3125],
            [-70.9688, 45.3323],
            [-70.9592, 45.3491],
            [-70.9595, 45.3732],
            [-70.9261, 45.4119],
            [-70.9421, 45.4271],
            [-70.998, 45.4283],
            [-70.9958, 45.4608],
            [-71.0137, 45.4536],
            [-71.1338, 45.4582],
            [-71.1603, 45.4582],
            [-71.1586, 45.5359],
            [-71.2139, 45.5714],
            [-71.2025, 45.6025],
            [-71.2343, 45.6217],
            [-71.2073, 45.6425],
            [-71.3603, 45.7467],
            [-71.3915, 45.7538],
            [-71.4366, 45.7988],
            [-71.5208, 45.8046],
            [-71.542, 45.7873],
            [-71.6171, 45.8306],
            [-71.6368, 45.8145],
            [-71.7932, 45.8923],
            [-71.8275, 45.9272],
            [-71.8588, 45.9415],
            [-71.8743, 45.9505],
            [-71.8601, 45.9572],
            [-71.9867, 46.0262],
            [-72.03, 46.0295],
            [-72.069, 46.0374],
            [-72.1143, 46.0176],
            [-72.1448, 46.0344],
            [-72.194, 46.0394],
            [-72.2417, 46.0394],
            [-72.2826, 46.0427],
            [-72.3218, 46.0853],
            [-72.3326, 46.1168],
            [-72.3937, 46.1602],
            [-72.3899, 46.1832],
            [-72.3319, 46.2251],
            [-72.373, 46.2576],
            [-72.3901, 46.2457],
            [-72.409, 46.2593],
            [-72.4875, 46.1936],
            [-72.6016, 46.2766],
            [-72.5536, 46.2997],
            [-72.4486, 46.3759],
            [-72.2496, 46.417],
            [-72.2065, 46.5075],
            [-72.1063, 46.561],
            [-72.0149, 46.5628],
            [-71.9603, 46.6118],
            [-71.9155, 46.6239],
            [-71.8466, 46.6704],
            [-71.7684, 46.6284],
            [-71.6424, 46.6377],
            [-71.4458, 46.7031],
            [-71.3965, 46.7046],
            [-71.2518, 46.7513],
            [-71.2063, 46.7744],
            [-71.1638, 46.8332],
            [-71.094, 46.8276],
            [-70.9871, 46.8348],
            [-70.9323, 46.8719],
            [-70.8709, 46.894],
            [-70.8405, 46.8847],
            [-70.7688, 46.9061],
            [-70.6281, 46.9801],
            [-70.503, 47.0085],
            [-70.4541, 47.0631],
            [-70.3085, 47.1777],
            [-70.2406, 47.2386],
            [-70.1943, 47.3091],
            [-70.1088, 47.3328],
            [-70.0411, 47.3921],
            [-70.0336, 47.4654],
            [-69.9327, 47.5337],
            [-69.9039, 47.5251],
            [-69.8578, 47.5798],
            [-69.7839, 47.6166],
            [-69.6759, 47.7263],
            [-69.6478, 47.7316],
            [-69.5504, 47.8443],
            [-69.5503, 47.8779],
            [-69.5131, 47.928],
          ],
          [
            [-71.7176, 48.9515],
            [-71.6813, 48.9069],
            [-71.6425, 48.9105],
            [-71.5587, 48.9608],
            [-71.5276, 48.9777],
            [-71.5401, 49.0287],
            [-71.6293, 49.0619],
            [-71.6392, 49.0527],
            [-71.6397, 49.0157],
            [-71.6254, 48.9899],
            [-71.6685, 48.9765],
            [-71.7176, 48.9515],
          ],
          [
            [-71.4257, 48.0968],
            [-71.4493, 48.1245],
            [-71.4535, 48.1667],
            [-71.4652, 48.2193],
            [-71.514, 48.2184],
            [-71.5414, 48.1857],
            [-71.6025, 48.1566],
            [-71.5239, 48.093],
            [-71.504, 48.1018],
            [-71.4766, 48.1014],
            [-71.4257, 48.0968],
          ],
        ],
        [
          [
            [-71.782, 48.6417],
            [-71.7642, 48.6881],
            [-71.5946, 48.6585],
            [-71.6246, 48.5877],
            [-71.6167, 48.5436],
            [-71.5338, 48.5325],
            [-71.5556, 48.4893],
            [-71.7399, 48.524],
            [-71.7256, 48.5682],
            [-71.7499, 48.5619],
            [-71.8093, 48.5801],
            [-71.782, 48.6417],
          ],
        ],
        [
          [
            [-58.6349, 51.2016],
            [-58.6369, 51.1997],
            [-58.5593, 51.169],
            [-58.7183, 51.0901],
            [-58.7812, 51.0748],
            [-58.8739, 51.0274],
            [-58.9176, 51.0461],
            [-58.964, 51.0039],
            [-58.9409, 50.9837],
            [-58.9424, 50.8795],
            [-58.9771, 50.8486],
            [-58.9431, 50.819],
            [-59.0094, 50.7627],
            [-59.0619, 50.7477],
            [-59.1048, 50.7839],
            [-59.1348, 50.7618],
            [-59.2207, 50.7325],
            [-59.2486, 50.7062],
            [-59.3117, 50.695],
            [-59.3301, 50.6474],
            [-59.3808, 50.6446],
            [-59.4654, 50.5534],
            [-59.5078, 50.5479],
            [-59.6012, 50.4631],
            [-59.8242, 50.3999],
            [-59.8887, 50.3648],
            [-59.8075, 50.3178],
            [-59.913, 50.2689],
            [-60.0085, 50.2754],
            [-60.0552, 50.2375],
            [-60.0919, 50.2334],
            [-60.1631, 50.1879],
            [-60.1993, 50.2754],
            [-60.2525, 50.22],
            [-60.2968, 50.2483],
            [-60.3878, 50.2111],
            [-60.4158, 50.2389],
            [-60.5304, 50.2291],
            [-60.597, 50.1943],
            [-60.6445, 50.2101],
            [-60.7368, 50.2099],
            [-60.8308, 50.234],
            [-60.8924, 50.2098],
            [-60.9537, 50.2324],
            [-60.9971, 50.2282],
            [-61.111, 50.1751],
            [-61.2221, 50.1946],
            [-61.3718, 50.1764],
            [-61.4892, 50.1491],
            [-61.6464, 50.0968],
            [-61.7442, 50.081],
            [-61.7964, 50.1004],
            [-61.8014, 50.1563],
            [-61.8389, 50.2085],
            [-61.9635, 50.2243],
            [-61.9911, 50.2026],
            [-62.0977, 50.212],
            [-62.1579, 50.228],
            [-62.2092, 50.2212],
            [-62.2603, 50.246],
            [-62.36, 50.2571],
            [-62.4098, 50.2831],
            [-62.4472, 50.2466],
            [-62.4977, 50.2655],
            [-62.6107, 50.2582],
            [-62.8257, 50.2681],
            [-62.8787, 50.2839],
            [-62.9409, 50.268],
            [-62.9856, 50.2744],
            [-63.0184, 50.3081],
            [-63.0687, 50.2693],
            [-63.1556, 50.2823],
            [-63.1537, 50.2428],
            [-63.2973, 50.2159],
            [-63.3567, 50.2284],
            [-63.3995, 50.1942],
            [-63.4461, 50.1977],
            [-63.4715, 50.2424],
            [-63.548, 50.2493],
            [-63.6109, 50.2289],
            [-63.6411, 50.2587],
            [-63.7425, 50.2869],
            [-63.7847, 50.2786],
            [-63.9455, 50.2964],
            [-64.0913, 50.2904],
            [-64.1617, 50.2553],
            [-64.3425, 50.2838],
            [-64.4582, 50.323],
            [-64.5467, 50.2838],
            [-64.6315, 50.2752],
            [-64.7804, 50.2754],
            [-64.8761, 50.2599],
            [-64.9586, 50.2637],
            [-65.025, 50.281],
            [-65.23, 50.2866],
            [-65.271, 50.3019],
            [-65.3504, 50.3027],
            [-65.513, 50.2829],
            [-65.5405, 50.2719],
            [-65.8236, 50.2493],
            [-65.9486, 50.2908],
            [-66.0027, 50.2661],
            [-66.0595, 50.2019],
            [-66.1507, 50.1891],
            [-66.2333, 50.2133],
            [-66.319, 50.2035],
            [-66.3651, 50.1853],
            [-66.3955, 50.2315],
            [-66.4099, 50.2586],
            [-66.4599, 50.2711],
            [-66.5468, 50.2111],
            [-66.5139, 50.1601],
            [-66.6651, 50.1241],
            [-66.6988, 50.1061],
            [-66.7453, 50.0442],
            [-66.9212, 50.0071],
            [-66.9573, 49.9817],
            [-67.0136, 49.8605],
            [-67.0407, 49.8243],
            [-67.09, 49.8478],
            [-67.1434, 49.8032],
            [-67.1702, 49.7572],
            [-67.1661, 49.6702],
            [-67.2345, 49.5929],
            [-67.2295, 49.5146],
            [-67.2388, 49.4585],
            [-67.3806, 49.3173],
            [-67.5856, 49.326],
            [-67.7732, 49.3047],
            [-67.8183, 49.2689],
            [-67.8936, 49.2904],
            [-68.0281, 49.2798],
            [-68.0848, 49.2527],
            [-68.1278, 49.2717],
            [-68.1459, 49.2016],
            [-68.2647, 49.1794],
            [-68.2309, 49.1563],
            [-68.1826, 49.1548],
            [-68.1906, 49.0987],
            [-68.3131, 49.0925],
            [-68.4122, 49.0429],
            [-68.4623, 49.043],
            [-68.4788, 49.0706],
            [-68.528, 49.0704],
            [-68.599, 49.0454],
            [-68.6374, 49.0001],
            [-68.6372, 48.8997],
            [-68.6991, 48.9014],
            [-68.7801, 48.887],
            [-68.879, 48.8191],
            [-68.942, 48.8051],
            [-69.0663, 48.7594],
            [-69.0868, 48.6391],
            [-69.1488, 48.5654],
            [-69.2334, 48.5707],
            [-69.2814, 48.5148],
            [-69.2833, 48.4698],
            [-69.3227, 48.4122],
            [-69.41, 48.3433],
            [-69.4267, 48.2972],
            [-69.5149, 48.2412],
            [-69.5826, 48.2133],
            [-69.6111, 48.1845],
            [-69.7286, 48.1093],
            [-69.7774, 48.0259],
            [-69.7627, 48.0045],
            [-69.8021, 47.9147],
            [-69.8438, 47.8871],
            [-69.8999, 47.8063],
            [-69.9056, 47.77],
            [-69.9582, 47.7542],
            [-69.9917, 47.7031],
            [-70.1266, 47.6509],
            [-70.1403, 47.623],
            [-70.1989, 47.579],
            [-70.2027, 47.5241],
            [-70.2313, 47.4878],
            [-70.3491, 47.4627],
            [-70.4529, 47.4262],
            [-70.4899, 47.4299],
            [-70.4978, 47.3852],
            [-70.5516, 47.3302],
            [-70.5723, 47.2735],
            [-70.6856, 47.1412],
            [-70.7427, 47.0874],
            [-70.8064, 47.0499],
            [-70.8835, 47.0415],
            [-71.0077, 46.9792],
            [-71.0677, 46.9229],
            [-71.1392, 46.8883],
            [-71.2039, 46.8061],
            [-71.2716, 46.7549],
            [-71.3429, 46.7473],
            [-71.6281, 46.694],
            [-71.6934, 46.6673],
            [-71.7714, 46.667],
            [-71.8437, 46.6921],
            [-71.899, 46.682],
            [-71.9277, 46.6461],
            [-71.9884, 46.6239],
            [-72.0388, 46.5849],
            [-72.1004, 46.5869],
            [-72.178, 46.568],
            [-72.2424, 46.5177],
            [-72.2679, 46.4507],
            [-72.3461, 46.4396],
            [-72.3835, 46.4152],
            [-72.4653, 46.3919],
            [-72.5808, 46.301],
            [-72.6666, 46.2773],
            [-72.7294, 46.2911],
            [-72.8126, 46.258],
            [-72.8764, 46.261],
            [-72.9131, 46.2957],
            [-72.9146, 46.3394],
            [-72.9878, 46.4552],
            [-73.0952, 46.5362],
            [-73.005, 46.6007],
            [-73.0541, 46.6376],
            [-73.1153, 46.6532],
            [-73.0291, 46.6986],
            [-73.0702, 46.7488],
            [-73.0332, 46.7612],
            [-73.1438, 46.8393],
            [-73.1939, 46.84],
            [-73.302, 46.9008],
            [-73.32, 46.9461],
            [-73.358, 46.9609],
            [-73.3773, 46.9565],
            [-73.3809, 46.9434],
            [-73.4238, 46.9499],
            [-73.4285, 46.9259],
            [-73.4381, 46.9355],
            [-73.4591, 46.9385],
            [-73.4689, 46.928],
            [-73.46, 46.9164],
            [-73.4859, 46.9115],
            [-73.5342, 46.9172],
            [-73.5787, 46.8713],
            [-73.6658, 46.9281],
            [-73.6924, 46.9175],
            [-73.6872, 46.8931],
            [-73.7315, 46.9126],
            [-73.7829, 46.9167],
            [-73.7846, 46.9618],
            [-73.7287, 46.9715],
            [-74.0173, 47.1684],
            [-74.0233, 47.2053],
            [-74, 47.3423],
            [-73.9567, 47.3886],
            [-74.0164, 47.4283],
            [-74.0323, 47.4193],
            [-74.0274, 47.3733],
            [-74.0506, 47.3473],
            [-74.0907, 47.3573],
            [-74.146, 47.3548],
            [-74.1678, 47.3464],
            [-74.1746, 47.3627],
            [-74.222, 47.3634],
            [-74.2236, 47.3504],
            [-74.2509, 47.328],
            [-74.4512, 47.4642],
            [-74.5204, 47.396],
            [-74.5534, 47.328],
            [-74.6062, 47.2866],
            [-74.6197, 47.2517],
            [-74.6601, 47.2448],
            [-74.6852, 47.194],
            [-74.7705, 47.1735],
            [-74.769, 47.1424],
            [-74.757, 47.1364],
            [-74.7649, 47.1282],
            [-74.9348, 47.2446],
            [-74.9852, 47.2304],
            [-75.0344, 47.1845],
            [-75.0772, 47.1647],
            [-75.0965, 47.1387],
            [-75.1143, 47.1358],
            [-75.1516, 47.1647],
            [-75.1694, 47.2107],
            [-75.2145, 47.2174],
            [-75.2331, 47.3006],
            [-75.1504, 47.3495],
            [-75.1682, 47.3739],
            [-75.1668, 47.3904],
            [-75.1837, 47.3991],
            [-75.1753, 47.4079],
            [-74.997, 47.2871],
            [-74.9808, 47.3023],
            [-74.966, 47.2964],
            [-74.9355, 47.3064],
            [-74.9191, 47.2956],
            [-74.8955, 47.297],
            [-74.8784, 47.3322],
            [-74.9122, 47.4178],
            [-74.9525, 47.4667],
            [-75.3927, 47.7619],
            [-75.4412, 47.868],
            [-75.4826, 47.8636],
            [-75.4752, 47.888],
            [-75.442, 47.9017],
            [-75.4741, 47.8938],
            [-75.4945, 47.8828],
            [-75.4966, 47.8719],
            [-75.5207, 47.8632],
            [-75.5225, 48.956],
            [-75.5062, 48.985],
            [-75.4842, 49],
            [-74.6765, 49],
            [-74.6083, 48.9565],
            [-74.6074, 48.9091],
            [-74.5866, 48.912],
            [-74.5469, 48.9381],
            [-74.5313, 48.9213],
            [-74.4582, 48.9],
            [-74.4381, 48.8729],
            [-74.4024, 48.8641],
            [-74.3564, 48.8738],
            [-73.3165, 48.1992],
            [-73.2989, 48.2187],
            [-73.2642, 48.2262],
            [-73.2948, 48.2558],
            [-73.2732, 48.2963],
            [-73.1977, 48.3007],
            [-73.1452, 48.3589],
            [-73.1219, 48.373],
            [-73.0831, 48.3801],
            [-73.0541, 48.3731],
            [-73.0119, 48.3751],
            [-72.9881, 48.4012],
            [-72.9543, 48.3941],
            [-72.925, 48.4009],
            [-72.8802, 48.3707],
            [-72.8345, 48.3665],
            [-72.811, 48.3786],
            [-72.7329, 48.3671],
            [-72.7088, 48.3794],
            [-72.6603, 48.3668],
            [-72.6776, 48.3341],
            [-72.672, 48.277],
            [-72.6482, 48.2425],
            [-72.6633, 48.1609],
            [-72.5673, 48.138],
            [-72.5492, 48.136],
            [-72.5339, 48.172],
            [-72.5182, 48.1813],
            [-72.493, 48.1713],
            [-72.4439, 48.1518],
            [-72.4165, 48.1215],
            [-72.4306, 48.1028],
            [-72.4144, 48.048],
            [-72.3897, 48.0526],
            [-72.3633, 48.0333],
            [-72.3332, 48.0208],
            [-72.3166, 47.9998],
            [-72.0835, 47.9992],
            [-72.0667, 47.9988],
            [-71.7228, 47.9972],
            [-71.7175, 48.0157],
            [-71.7132, 48.0478],
            [-71.6677, 48.0899],
            [-71.6471, 48.1329],
            [-71.654, 48.1717],
            [-71.6114, 48.2501],
            [-71.7737, 48.2804],
            [-71.7857, 48.2739],
            [-71.7958, 48.2838],
            [-71.9274, 48.3043],
            [-71.9022, 48.3674],
            [-71.9654, 48.4002],
            [-71.9373, 48.4027],
            [-71.9022, 48.4927],
            [-71.7955, 48.4863],
            [-71.7892, 48.4597],
            [-71.7288, 48.4604],
            [-71.6781, 48.4209],
            [-71.5709, 48.4055],
            [-71.5772, 48.3912],
            [-71.5503, 48.395],
            [-71.4889, 48.3991],
            [-71.4862, 48.4527],
            [-71.4632, 48.5068],
            [-71.4833, 48.5134],
            [-71.3729, 48.7806],
            [-71.2553, 48.763],
            [-71.2609, 48.8257],
            [-71.2623, 48.8373],
            [-71.2661, 48.8799],
            [-71.2987, 48.9283],
            [-71.3473, 48.9496],
            [-71.3468, 48.9281],
            [-71.386, 48.9351],
            [-71.4483, 48.8859],
            [-71.4834, 48.8251],
            [-71.4903, 48.7876],
            [-71.5415, 48.7857],
            [-71.6436, 48.7521],
            [-71.6913, 48.771],
            [-71.727, 48.8063],
            [-71.7538, 48.8155],
            [-71.7638, 48.8422],
            [-71.745, 48.9142],
            [-71.7402, 48.9259],
            [-71.7738, 48.9485],
            [-71.8624, 48.9631],
            [-71.9172, 48.9381],
            [-71.8782, 48.9671],
            [-71.8442, 49.0156],
            [-71.9296, 49.013],
            [-72.1363, 48.9115],
            [-72.1104, 48.8884],
            [-72.1694, 48.8595],
            [-72.1827, 48.8343],
            [-72.1009, 48.7615],
            [-72.0741, 48.7567],
            [-72.1022, 48.7308],
            [-72.3199, 48.6783],
            [-72.2958, 48.6109],
            [-72.2708, 48.5908],
            [-72.2488, 48.5403],
            [-72.2122, 48.5591],
            [-72.1898, 48.5128],
            [-72.1432, 48.4869],
            [-72.1751, 48.4247],
            [-72.1684, 48.3948],
            [-72.1718, 48.3758],
            [-72.248, 48.3897],
            [-72.2626, 48.4188],
            [-72.3457, 48.4919],
            [-72.326, 48.5022],
            [-72.3552, 48.5083],
            [-72.3416, 48.5254],
            [-72.3718, 48.5283],
            [-72.4038, 48.556],
            [-72.4284, 48.527],
            [-72.4841, 48.5281],
            [-72.6174, 48.6444],
            [-72.6409, 48.6326],
            [-72.642, 48.6244],
            [-72.85, 48.7001],
            [-72.7926, 48.739],
            [-72.8346, 48.761],
            [-72.885, 48.7534],
            [-72.9227, 48.7842],
            [-72.8041, 48.8204],
            [-72.723, 48.8381],
            [-72.7115, 48.8523],
            [-72.6953, 48.8512],
            [-72.5819, 48.9078],
            [-72.5566, 48.8856],
            [-72.5653, 48.8793],
            [-72.5383, 48.8704],
            [-72.5078, 48.893],
            [-72.4907, 48.9011],
            [-72.5289, 48.9341],
            [-72.3983, 48.9987],
            [-72.3696, 48.9897],
            [-72.331, 48.9937],
            [-72.3279, 48.9354],
            [-72.2837, 48.9155],
            [-72.2476, 48.9349],
            [-72.2128, 48.9653],
            [-72.2462, 49.0174],
            [-72.2874, 49.0527],
            [-72.2332, 49.0801],
            [-72.2004, 49.1234],
            [-72.1, 49.1304],
            [-72.0861, 49.1577],
            [-72.1589, 49.2527],
            [-72.1299, 49.3355],
            [-72.1046, 49.3692],
            [-72.0463, 49.3807],
            [-72.0302, 49.3944],
            [-71.9924, 49.377],
            [-71.9798, 49.3663],
            [-71.9441, 49.3856],
            [-71.9286, 49.4326],
            [-71.9343, 49.5087],
            [-72.0135, 49.5687],
            [-72.0419, 49.6223],
            [-72.0468, 49.6712],
            [-72.0016, 49.6966],
            [-72.0264, 49.7492],
            [-72.0391, 49.7822],
            [-72.0351, 49.8144],
            [-72.0599, 49.8262],
            [-72.1329, 49.9458],
            [-72.1704, 50.0511],
            [-72.1569, 50.0685],
            [-72.1758, 50.1046],
            [-72.1627, 50.1452],
            [-72.1721, 50.1842],
            [-72.1782, 50.1974],
            [-72.151, 50.2311],
            [-72.1664, 50.2668],
            [-72.1594, 50.3337],
            [-72.1849, 50.3775],
            [-72.1629, 50.413],
            [-72.1531, 50.4876],
            [-72.1618, 50.5412],
            [-72.1896, 50.5984],
            [-72.1884, 50.6416],
            [-72.1502, 50.7528],
            [-72.1554, 50.8285],
            [-72.0981, 50.8828],
            [-72.1047, 50.9574],
            [-72.0923, 51.022],
            [-72.0544, 51.0819],
            [-72.0743, 51.1495],
            [-72.0176, 51.1368],
            [-71.9792, 51.2107],
            [-71.9569, 51.2403],
            [-71.9371, 51.2715],
            [-71.8856, 51.2733],
            [-71.8811, 51.3055],
            [-71.8643, 51.3492],
            [-71.8364, 51.3594],
            [-71.8161, 51.371],
            [-71.817, 51.3535],
            [-71.7491, 51.3505],
            [-71.7064, 51.3941],
            [-71.6865, 51.4078],
            [-71.6655, 51.4772],
            [-71.5835, 51.5005],
            [-71.5793, 51.5323],
            [-71.5932, 51.5511],
            [-71.5812, 51.5869],
            [-71.6096, 51.6065],
            [-71.6132, 51.6591],
            [-71.5782, 51.7483],
            [-71.5712, 51.8138],
            [-71.5306, 51.8343],
            [-71.378, 51.8545],
            [-71.3536, 51.9053],
            [-71.3152, 51.9669],
            [-71.2461, 52.0215],
            [-71.2054, 52.0396],
            [-71.2688, 52.0401],
            [-71.2685, 52.0462],
            [-71.2029, 52.09],
            [-71.1404, 52.17],
            [-71.0838, 52.1895],
            [-71.0812, 52.213],
            [-71.0381, 52.2115],
            [-70.9208, 52.2437],
            [-70.8821, 52.2798],
            [-70.858, 52.2687],
            [-70.8067, 52.2641],
            [-70.8206, 52.2589],
            [-70.8245, 52.2462],
            [-70.7991, 52.2339],
            [-70.7279, 52.2565],
            [-70.6996, 52.2798],
            [-70.6503, 52.2823],
            [-70.562, 52.3166],
            [-70.5243, 52.3062],
            [-70.4857, 52.3042],
            [-70.3793, 52.3387],
            [-70.2705, 52.3616],
            [-70.2317, 52.4175],
            [-70.2656, 52.4123],
            [-70.2815, 52.4257],
            [-70.2591, 52.4541],
            [-70.2235, 52.4674],
            [-70.1764, 52.545],
            [-70.1759, 52.5794],
            [-70.1753, 52.5944],
            [-70.0798, 52.6502],
            [-70.0492, 52.6855],
            [-70.0239, 52.6964],
            [-70.0248, 52.9188],
            [-70, 52.9184],
            [-70, 55],
            [-68.75, 55],
            [-67.4225, 55.0001],
            [-67.4019, 54.989],
            [-67.4144, 54.9761],
            [-67.3998, 54.9579],
            [-67.3577, 54.9397],
            [-67.3351, 54.9035],
            [-67.2827, 54.8677],
            [-67.2592, 54.8338],
            [-67.2214, 54.8249],
            [-67.2259, 54.8083],
            [-67.2134, 54.7928],
            [-67.1802, 54.7759],
            [-67.1775, 54.764],
            [-67.1249, 54.7422],
            [-67.0629, 54.693],
            [-67.129, 54.6577],
            [-67.1386, 54.6246],
            [-67.2295, 54.6152],
            [-67.2803, 54.5918],
            [-67.2449, 54.5],
            [-67.2689, 54.4951],
            [-67.2726, 54.4866],
            [-67.2866, 54.4903],
            [-67.2898, 54.5087],
            [-67.3183, 54.5129],
            [-67.3343, 54.5051],
            [-67.3296, 54.5182],
            [-67.3578, 54.5174],
            [-67.429, 54.5693],
            [-67.4918, 54.5849],
            [-67.5183, 54.5522],
            [-67.4741, 54.5274],
            [-67.5004, 54.4815],
            [-67.5817, 54.4941],
            [-67.6279, 54.4736],
            [-67.7275, 54.4574],
            [-67.769, 54.4196],
            [-67.7247, 54.3869],
            [-67.748, 54.3503],
            [-67.68, 54.3119],
            [-67.6808, 54.247],
            [-67.6231, 54.2084],
            [-67.6444, 54.1608],
            [-67.765, 54.157],
            [-67.8103, 54.1281],
            [-67.7618, 54.0861],
            [-67.8206, 54.02],
            [-67.6813, 53.9719],
            [-67.6693, 53.9303],
            [-67.5963, 53.9217],
            [-67.6091, 53.8693],
            [-67.5691, 53.8412],
            [-67.4978, 53.8367],
            [-67.5573, 53.7803],
            [-67.5, 53.7573],
            [-67.5063, 53.7191],
            [-67.4256, 53.7042],
            [-67.3769, 53.5972],
            [-67.3136, 53.5842],
            [-67.3187, 53.553],
            [-67.2863, 53.5435],
            [-67.2574, 53.5574],
            [-67.2188, 53.5303],
            [-67.1891, 53.5328],
            [-67.1891, 53.5508],
            [-67.18, 53.553],
            [-67.1299, 53.5302],
            [-67.0948, 53.5271],
            [-67.062, 53.5371],
            [-67.0359, 53.5288],
            [-67.0067, 53.5],
            [-66.9856, 53.4962],
            [-66.9958, 53.4642],
            [-66.9379, 53.4731],
            [-66.925, 53.4545],
            [-66.935, 53.4415],
            [-66.9244, 53.4187],
            [-66.9661, 53.3646],
            [-67.0271, 53.3347],
            [-66.9584, 53.3065],
            [-66.9781, 53.2133],
            [-66.9596, 53.1817],
            [-67.0135, 53.16],
            [-66.9952, 53.1385],
            [-66.9587, 53.1371],
            [-66.9909, 53.1175],
            [-66.9895, 53.0844],
            [-67.0416, 53.0761],
            [-67.0685, 53.0789],
            [-67.069, 53.1008],
            [-67.0468, 53.1185],
            [-67.0753, 53.1393],
            [-67.1162, 53.1051],
            [-67.1542, 53.1472],
            [-67.2177, 53.1497],
            [-67.263, 53.1813],
            [-67.3027, 53.1589],
            [-67.2881, 53.1403],
            [-67.3854, 53.132],
            [-67.3905, 53.0768],
            [-67.3327, 52.9598],
            [-67.3557, 52.9286],
            [-67.3329, 52.9072],
            [-67.3361, 52.8896],
            [-67.3168, 52.8755],
            [-67.2998, 52.8845],
            [-67.2685, 52.8777],
            [-67.2275, 52.8418],
            [-67.2148, 52.8436],
            [-67.1611, 52.8175],
            [-67.1495, 52.8544],
            [-67.1342, 52.851],
            [-67.1226, 52.8624],
            [-67.0632, 52.8755],
            [-67.0477, 52.8372],
            [-67.0688, 52.7777],
            [-67.0396, 52.7675],
            [-67.0343, 52.747],
            [-67.0198, 52.755],
            [-67.0161, 52.7441],
            [-66.9898, 52.7622],
            [-66.979, 52.7531],
            [-66.9496, 52.7596],
            [-66.9442, 52.7369],
            [-66.9176, 52.7152],
            [-66.8948, 52.6767],
            [-66.8727, 52.681],
            [-66.856, 52.7173],
            [-66.8602, 52.7684],
            [-66.8171, 52.7113],
            [-66.8013, 52.7087],
            [-66.7914, 52.6785],
            [-66.765, 52.6663],
            [-66.7579, 52.6733],
            [-66.7724, 52.6962],
            [-66.7479, 52.7031],
            [-66.7541, 52.7515],
            [-66.6866, 52.7637],
            [-66.6474, 52.7837],
            [-66.6651, 52.8309],
            [-66.6636, 52.8903],
            [-66.6506, 52.9219],
            [-66.6332, 52.9314],
            [-66.6259, 52.9543],
            [-66.5703, 52.937],
            [-66.5378, 52.944],
            [-66.5203, 52.964],
            [-66.4753, 53.0425],
            [-66.4595, 53.0552],
            [-66.4321, 53.0482],
            [-66.3706, 53.0248],
            [-66.327, 52.9726],
            [-66.3223, 52.939],
            [-66.2581, 52.8691],
            [-66.4166, 52.8583],
            [-66.3545, 52.8026],
            [-66.3086, 52.7286],
            [-66.3391, 52.6846],
            [-66.2777, 52.6345],
            [-66.2802, 52.6174],
            [-66.2908, 52.6001],
            [-66.3093, 52.6046],
            [-66.3408, 52.6455],
            [-66.3887, 52.6823],
            [-66.4022, 52.6745],
            [-66.4164, 52.642],
            [-66.4403, 52.6364],
            [-66.3711, 52.552],
            [-66.3556, 52.4732],
            [-66.3606, 52.4664],
            [-66.3939, 52.4864],
            [-66.3931, 52.4761],
            [-66.3588, 52.4308],
            [-66.3729, 52.413],
            [-66.3417, 52.3972],
            [-66.344, 52.3604],
            [-66.3638, 52.3514],
            [-66.381, 52.3676],
            [-66.4199, 52.383],
            [-66.4411, 52.3758],
            [-66.4366, 52.3588],
            [-66.4905, 52.3416],
            [-66.4027, 52.2169],
            [-66.3568, 52.1766],
            [-66.3798, 52.1626],
            [-66.358, 52.1466],
            [-66.3332, 52.1507],
            [-66.3236, 52.1318],
            [-66.2679, 52.1504],
            [-66.2641, 52.1652],
            [-66.3126, 52.2855],
            [-66.2835, 52.2854],
            [-66.2148, 52.2549],
            [-66.2008, 52.227],
            [-66.1723, 52.2135],
            [-66.16, 52.2317],
            [-66.1521, 52.2305],
            [-66.1039, 52.1985],
            [-66.0759, 52.155],
            [-66.0964, 52.1078],
            [-66.0156, 52.0799],
            [-65.9962, 52.0547],
            [-65.9704, 52.0565],
            [-65.9613, 52.1046],
            [-65.8977, 52.0761],
            [-65.877, 52.1104],
            [-65.842, 52.1165],
            [-65.7594, 52.1037],
            [-65.753, 52.1078],
            [-65.7339, 52.0829],
            [-65.6683, 52.1173],
            [-65.6512, 52.0553],
            [-65.6774, 52.0077],
            [-65.6502, 51.9868],
            [-65.634, 52],
            [-65.5793, 52.0328],
            [-65.5399, 52.0446],
            [-65.4993, 52.1105],
            [-65.4901, 52.1021],
            [-65.4961, 52.0502],
            [-65.4562, 52.0111],
            [-65.4232, 52.0021],
            [-65.4123, 51.9713],
            [-65.3493, 51.9424],
            [-65.3995, 51.9019],
            [-65.3626, 51.8642],
            [-65.3598, 51.8228],
            [-65.3461, 51.8197],
            [-65.3315, 51.829],
            [-65.3178, 51.8508],
            [-65.3233, 51.87],
            [-65.3002, 51.878],
            [-65.2539, 51.8615],
            [-65.2644, 51.8367],
            [-65.2046, 51.8128],
            [-65.1804, 51.7656],
            [-65.0912, 51.746],
            [-65.0492, 51.7696],
            [-65.0303, 51.762],
            [-65.01, 51.7651],
            [-65.0205, 51.7586],
            [-64.9992, 51.75],
            [-64.9724, 51.7216],
            [-64.9509, 51.7154],
            [-64.9307, 51.7143],
            [-64.9098, 51.7367],
            [-64.9276, 51.7464],
            [-64.9198, 51.7608],
            [-64.9386, 51.7644],
            [-64.9247, 51.7779],
            [-64.8478, 51.7625],
            [-64.8054, 51.7781],
            [-64.794, 51.7612],
            [-64.7731, 51.7531],
            [-64.7394, 51.7615],
            [-64.7097, 51.756],
            [-64.6664, 51.7007],
            [-64.6399, 51.705],
            [-64.6404, 51.674],
            [-64.6182, 51.6611],
            [-64.6011, 51.6329],
            [-64.5485, 51.5739],
            [-64.4553, 51.6246],
            [-64.423, 51.6761],
            [-64.391, 51.6629],
            [-64.3371, 51.6653],
            [-64.274, 51.7423],
            [-64.3019, 51.757],
            [-64.3024, 51.8036],
            [-64.3527, 51.8493],
            [-64.3573, 51.9274],
            [-64.343, 51.9677],
            [-64.3588, 51.9791],
            [-64.3396, 51.9891],
            [-64.361, 52.0055],
            [-64.3228, 52.0049],
            [-64.323, 51.9944],
            [-64.2801, 51.9748],
            [-64.2371, 51.978],
            [-64.2339, 51.9909],
            [-64.2794, 52.0376],
            [-64.2515, 52.0798],
            [-64.2538, 52.1215],
            [-64.2363, 52.1294],
            [-64.2499, 52.1447],
            [-64.1803, 52.115],
            [-64.1664, 52.1161],
            [-64.1774, 52.1338],
            [-64.1608, 52.146],
            [-64.2007, 52.177],
            [-64.2175, 52.2571],
            [-64.2466, 52.2754],
            [-64.2162, 52.313],
            [-64.1694, 52.3067],
            [-64.1583, 52.339],
            [-64.1092, 52.3941],
            [-64.1519, 52.5199],
            [-64.2058, 52.5772],
            [-64.1545, 52.6034],
            [-64.153, 52.6473],
            [-64.1276, 52.6864],
            [-64.1405, 52.7267],
            [-64.0795, 52.7366],
            [-64.0535, 52.7232],
            [-64.0393, 52.7259],
            [-64.0373, 52.7383],
            [-64.007, 52.7371],
            [-63.9827, 52.7255],
            [-63.9374, 52.731],
            [-63.9195, 52.7578],
            [-63.8886, 52.7501],
            [-63.8939, 52.7639],
            [-63.8729, 52.774],
            [-63.819, 52.7585],
            [-63.7215, 52.7703],
            [-63.693, 52.8146],
            [-63.5964, 52.7956],
            [-63.528, 52.7276],
            [-63.45, 52.7136],
            [-63.3964, 52.6824],
            [-63.408, 52.6617],
            [-63.4294, 52.6455],
            [-63.5835, 52.6487],
            [-63.8717, 52.6188],
            [-64.0326, 52.5683],
            [-64.0935, 52.4661],
            [-64.0206, 52.401],
            [-64.0127, 52.3626],
            [-63.9652, 52.3578],
            [-63.8732, 52.3225],
            [-63.8269, 52.3308],
            [-63.7706, 52.2839],
            [-63.726, 52.213],
            [-63.6906, 52.1298],
            [-63.6464, 52.0424],
            [-63.6782, 52.0547],
            [-63.7063, 52.0497],
            [-63.7793, 52.0836],
            [-63.824, 52.0847],
            [-63.8401, 52.065],
            [-63.8123, 52.0336],
            [-63.8081, 51.9979],
            [-61.8065, 51.9958],
            [-60.4416, 51.9965],
            [-59.0342, 51.997],
            [-57.9645, 51.9965],
            [-57.1074, 51.9985],
            [-57.1082, 51.4154],
            [-57.1348, 51.4276],
            [-57.1699, 51.4073],
            [-57.2469, 51.4562],
            [-57.2514, 51.4988],
            [-57.4469, 51.4757],
            [-57.6089, 51.4183],
            [-57.6358, 51.4363],
            [-57.6986, 51.4202],
            [-57.7017, 51.4546],
            [-57.7718, 51.4304],
            [-57.774, 51.3966],
            [-57.8339, 51.4036],
            [-57.955, 51.3457],
            [-58.018, 51.2952],
            [-58.1173, 51.2835],
            [-58.2187, 51.2557],
            [-58.2491, 51.2799],
            [-58.2953, 51.2541],
            [-58.4644, 51.2943],
            [-58.582, 51.2217],
            [-58.6349, 51.2016],
          ],
          [
            [-74.8802, 48.7811],
            [-74.8751, 48.7605],
            [-74.8539, 48.7501],
            [-74.8182, 48.7718],
            [-74.7568, 48.7842],
            [-74.7754, 48.8045],
            [-74.8152, 48.8169],
            [-74.8516, 48.8049],
            [-74.8802, 48.7811],
          ],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "Montreal Mkt": {
    type: "Feature",
    properties: { code: "PQ_MON", name: "Montreal Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-71.5414, 48.1857],
            [-71.5139, 48.2184],
            [-71.4652, 48.2193],
            [-71.4535, 48.1667],
            [-71.4493, 48.1245],
            [-71.4257, 48.0968],
            [-71.5238, 48.093],
            [-71.6025, 48.1566],
            [-71.5414, 48.1857],
          ],
        ],
        [
          [
            [-78.4751, 58.9429],
            [-78.3996, 58.9582],
            [-78.3859, 58.93],
            [-78.4353, 58.9112],
            [-78.4751, 58.9429],
          ],
        ],
        [
          [
            [-77.7602, 61.2366],
            [-77.7422, 61.26],
            [-77.7694, 61.3124],
            [-77.7621, 61.3526],
            [-77.8122, 61.462],
            [-77.7222, 61.4388],
            [-77.5592, 61.4808],
            [-77.7049, 61.5162],
            [-77.6405, 61.558],
            [-77.6402, 61.5586],
            [-77.6684, 61.5884],
            [-77.7879, 61.6482],
            [-77.7953, 61.6795],
            [-77.9439, 61.6802],
            [-78.027, 61.7288],
            [-78.0155, 61.765],
            [-78.095, 61.868],
            [-78.0885, 61.9397],
            [-78.1593, 62.0139],
            [-78.1524, 62.1122],
            [-78.1836, 62.1482],
            [-78.1498, 62.2148],
            [-78.1697, 62.244],
            [-78.1254, 62.2944],
            [-78.1059, 62.3482],
            [-78.0982, 62.3558],
            [-77.9398, 62.3933],
            [-77.9263, 62.4251],
            [-77.8327, 62.4295],
            [-77.7721, 62.4624],
            [-77.7209, 62.4652],
            [-77.6506, 62.5083],
            [-77.518, 62.5542],
            [-77.5095, 62.5824],
            [-77.4127, 62.5737],
            [-77.3998, 62.5253],
            [-77.3779, 62.5549],
            [-77.0342, 62.5312],
            [-76.9232, 62.537],
            [-76.7168, 62.5042],
            [-76.6466, 62.4548],
            [-76.5545, 62.4583],
            [-76.2893, 62.4166],
            [-76.2478, 62.3957],
            [-76.1012, 62.3767],
            [-76.0874, 62.3602],
            [-75.9459, 62.3514],
            [-75.8741, 62.3212],
            [-75.7772, 62.3026],
            [-75.7113, 62.3043],
            [-75.6131, 62.2766],
            [-75.512, 62.2709],
            [-75.4515, 62.303],
            [-75.3191, 62.3139],
            [-75.274, 62.2969],
            [-74.9148, 62.253],
            [-74.7879, 62.1818],
            [-74.7017, 62.1912],
            [-74.7132, 62.2524],
            [-74.6107, 62.2587],
            [-74.5153, 62.2424],
            [-74.3584, 62.2548],
            [-74.2199, 62.3317],
            [-74.1314, 62.337],
            [-73.9789, 62.3643],
            [-73.9541, 62.4028],
            [-73.8479, 62.4634],
            [-73.6714, 62.4796],
            [-73.5453, 62.3865],
            [-73.3863, 62.3741],
            [-73.3167, 62.3298],
            [-73.1962, 62.3161],
            [-73.1919, 62.2706],
            [-73.0588, 62.2083],
            [-73.0597, 62.1839],
            [-72.9119, 62.1608],
            [-72.8675, 62.1107],
            [-72.7505, 62.1465],
            [-72.6093, 62.1137],
            [-72.5819, 62.0553],
            [-72.5978, 62.001],
            [-72.5645, 61.9508],
            [-72.3798, 61.8868],
            [-72.2623, 61.8888],
            [-72.208, 61.8743],
            [-72.1084, 61.7954],
            [-72.2145, 61.7684],
            [-72.1572, 61.7454],
            [-72.1127, 61.7673],
            [-71.9372, 61.7083],
            [-71.8082, 61.6951],
            [-71.7122, 61.6605],
            [-71.6466, 61.6579],
            [-71.5535, 61.6167],
            [-71.5711, 61.5743],
            [-71.7098, 61.545],
            [-71.8097, 61.5451],
            [-71.7345, 61.4726],
            [-71.8387, 61.4471],
            [-71.6627, 61.4061],
            [-71.6364, 61.3778],
            [-71.7682, 61.3632],
            [-71.8269, 61.3431],
            [-71.7438, 61.3562],
            [-71.6774, 61.3503],
            [-71.7345, 61.2821],
            [-71.5791, 61.2546],
            [-71.5477, 61.2244],
            [-71.4308, 61.2116],
            [-71.4429, 61.1709],
            [-71.3133, 61.154],
            [-71.2188, 61.1629],
            [-71.1797, 61.1201],
            [-71.0426, 61.1364],
            [-70.9306, 61.116],
            [-70.8615, 61.0691],
            [-70.7613, 61.084],
            [-70.6211, 61.0407],
            [-70.494, 61.0708],
            [-70.3337, 61.0544],
            [-70.3054, 61.1018],
            [-70.1299, 61.0914],
            [-70.1614, 61.0027],
            [-70.0868, 60.9623],
            [-70.0803, 60.889],
            [-70.0422, 60.8446],
            [-69.9879, 60.8444],
            [-69.8964, 60.7998],
            [-69.8337, 60.8881],
            [-69.7666, 60.9059],
            [-69.7369, 60.8748],
            [-69.6689, 60.8733],
            [-69.6306, 60.9122],
            [-69.6858, 60.9501],
            [-69.6839, 60.9891],
            [-69.6287, 61.0246],
            [-69.6497, 61.0536],
            [-69.6115, 61.0791],
            [-69.5022, 61.0674],
            [-69.4847, 61.0095],
            [-69.4442, 60.9557],
            [-69.3738, 60.9205],
            [-69.4038, 60.8905],
            [-69.3672, 60.8068],
            [-69.4878, 60.7766],
            [-69.6124, 60.73],
            [-69.6993, 60.6864],
            [-69.6453, 60.6282],
            [-69.7125, 60.5399],
            [-69.7796, 60.5446],
            [-69.7844, 60.4875],
            [-69.7134, 60.4589],
            [-69.7717, 60.4176],
            [-69.711, 60.4037],
            [-69.688, 60.34],
            [-69.7412, 60.322],
            [-69.6462, 60.2623],
            [-69.5937, 60.1654],
            [-69.6404, 60.1397],
            [-69.6277, 60.0923],
            [-69.7385, 60.0554],
            [-69.8393, 60.0719],
            [-69.8508, 60.0229],
            [-69.7735, 60.0135],
            [-69.7772, 59.9659],
            [-69.7129, 59.9654],
            [-69.7084, 59.921],
            [-69.6664, 59.8769],
            [-69.6053, 59.8825],
            [-69.6064, 59.7938],
            [-69.5781, 59.7446],
            [-69.6419, 59.7003],
            [-69.497, 59.6702],
            [-69.517, 59.6174],
            [-69.6378, 59.5882],
            [-69.7438, 59.5097],
            [-69.6749, 59.4794],
            [-69.6337, 59.4223],
            [-69.6606, 59.3863],
            [-69.6371, 59.3586],
            [-69.6656, 59.3193],
            [-69.5194, 59.3318],
            [-69.4302, 59.3642],
            [-69.3476, 59.3342],
            [-69.2454, 59.3356],
            [-69.2246, 59.2296],
            [-69.3224, 59.2256],
            [-69.37, 59.1901],
            [-69.4717, 59.2184],
            [-69.5361, 59.1531],
            [-69.5017, 59.1091],
            [-69.343, 59.0951],
            [-69.4031, 59.0653],
            [-69.4627, 59.0097],
            [-69.4672, 58.9537],
            [-69.4375, 58.9045],
            [-69.5201, 58.8614],
            [-69.5452, 58.8101],
            [-69.6509, 58.79],
            [-69.6802, 58.8093],
            [-69.6948, 58.8789],
            [-69.655, 58.9076],
            [-69.6832, 58.9509],
            [-69.767, 58.959],
            [-69.8252, 59.0214],
            [-69.8887, 58.9552],
            [-69.8082, 58.9575],
            [-69.7853, 58.8317],
            [-69.9241, 58.8254],
            [-70.0362, 58.8063],
            [-70.0793, 58.7867],
            [-70.0288, 58.7342],
            [-69.8982, 58.7733],
            [-69.9317, 58.7065],
            [-69.8391, 58.6743],
            [-69.8651, 58.6421],
            [-69.8123, 58.5833],
            [-69.7511, 58.6284],
            [-69.7242, 58.6803],
            [-69.641, 58.7104],
            [-69.6109, 58.7678],
            [-69.439, 58.8046],
            [-69.4138, 58.8512],
            [-69.2507, 58.9049],
            [-69.2008, 58.8986],
            [-68.9889, 58.9074],
            [-68.9732, 58.8831],
            [-68.8656, 58.886],
            [-68.7606, 58.9202],
            [-68.7536, 58.8823],
            [-68.6324, 58.9111],
            [-68.5833, 58.8988],
            [-68.5369, 58.8606],
            [-68.3447, 58.7707],
            [-68.3804, 58.7438],
            [-68.3722, 58.7019],
            [-68.3305, 58.6836],
            [-68.3402, 58.596],
            [-68.2837, 58.5871],
            [-68.2771, 58.5375],
            [-68.1973, 58.5571],
            [-68.1176, 58.5302],
            [-68.0263, 58.5188],
            [-67.9615, 58.5619],
            [-67.9166, 58.5064],
            [-67.7721, 58.472],
            [-67.7573, 58.468],
            [-67.705, 58.4023],
            [-67.7439, 58.3253],
            [-67.714, 58.2859],
            [-67.6425, 58.2588],
            [-67.5266, 58.2541],
            [-67.4586, 58.2896],
            [-67.3792, 58.2731],
            [-67.3439, 58.3131],
            [-67.2678, 58.3438],
            [-67.2112, 58.3359],
            [-67.1662, 58.3798],
            [-67.0894, 58.3647],
            [-67.0667, 58.4151],
            [-66.9257, 58.4963],
            [-66.9022, 58.4547],
            [-66.8528, 58.4293],
            [-66.7334, 58.5102],
            [-66.6844, 58.4794],
            [-66.6023, 58.4705],
            [-66.6616, 58.5475],
            [-66.6218, 58.5784],
            [-66.6131, 58.6171],
            [-66.5614, 58.6305],
            [-66.5775, 58.6709],
            [-66.4884, 58.7207],
            [-66.4451, 58.8103],
            [-66.3548, 58.8528],
            [-66.2504, 58.804],
            [-66.1641, 58.7429],
            [-66.1167, 58.7278],
            [-66.0814, 58.6804],
            [-66.0299, 58.7034],
            [-66.0329, 58.764],
            [-66.0839, 58.8175],
            [-66.0283, 58.8578],
            [-65.9747, 58.8606],
            [-65.8795, 58.8271],
            [-65.8072, 58.859],
            [-65.741, 58.8128],
            [-65.7007, 58.815],
            [-65.7816, 58.8457],
            [-65.7813, 58.8704],
            [-65.9208, 58.8731],
            [-65.998, 58.912],
            [-65.8234, 58.9575],
            [-65.8371, 58.9895],
            [-65.8353, 58.9893],
            [-65.7705, 58.9841],
            [-65.6652, 58.9354],
            [-65.6596, 58.9747],
            [-65.7613, 58.9984],
            [-65.7821, 59.0187],
            [-65.6824, 59.0491],
            [-65.6224, 59.0452],
            [-65.6506, 59.1035],
            [-65.737, 59.1794],
            [-65.709, 59.2407],
            [-65.6348, 59.2558],
            [-65.6088, 59.2262],
            [-65.5052, 59.2907],
            [-65.5562, 59.2995],
            [-65.5692, 59.3435],
            [-65.5432, 59.3828],
            [-65.436, 59.3832],
            [-65.3872, 59.4168],
            [-65.3875, 59.4999],
            [-65.5193, 59.6859],
            [-65.5361, 59.7465],
            [-65.4504, 59.7478],
            [-65.43, 59.809],
            [-65.3819, 59.8015],
            [-65.3256, 59.8444],
            [-65.2346, 59.8319],
            [-65.2714, 59.8801],
            [-65.2126, 59.8914],
            [-65.2243, 59.9326],
            [-65.1584, 59.9406],
            [-65.1583, 59.9406],
            [-65.1234, 59.9727],
            [-65.1383, 60.0523],
            [-65.0672, 60.0421],
            [-65.0714, 60.0917],
            [-65.0034, 60.1245],
            [-64.916, 60.2807],
            [-64.8134, 60.3073],
            [-64.7991, 60.3678],
            [-64.6367, 60.311],
            [-64.6249, 60.3441],
            [-64.5337, 60.3092],
            [-64.5294, 60.2969],
            [-64.5809, 60.3086],
            [-64.5969, 60.3009],
            [-64.6549, 60.295],
            [-64.7231, 60.2917],
            [-64.8534, 60.2696],
            [-64.8619, 60.2587],
            [-64.8524, 60.2529],
            [-64.8731, 60.2375],
            [-64.8636, 60.2284],
            [-64.7495, 60.1884],
            [-64.6021, 60.1672],
            [-64.5934, 60.1173],
            [-64.7076, 60.0848],
            [-64.7424, 60.0564],
            [-64.8195, 60.0507],
            [-64.8956, 60.0664],
            [-64.9233, 60.0617],
            [-64.8874, 60.0422],
            [-64.8399, 59.9705],
            [-64.8065, 59.971],
            [-64.7824, 59.9806],
            [-64.7468, 59.9747],
            [-64.7471, 59.9549],
            [-64.7319, 59.9507],
            [-64.6928, 59.9591],
            [-64.6619, 59.9528],
            [-64.6406, 59.9077],
            [-64.6834, 59.8774],
            [-64.7412, 59.8895],
            [-64.7558, 59.8862],
            [-64.7662, 59.8699],
            [-64.7946, 59.8343],
            [-64.8249, 59.8258],
            [-64.7897, 59.7605],
            [-64.8099, 59.7299],
            [-64.7491, 59.7174],
            [-64.898, 59.642],
            [-64.8393, 59.5535],
            [-64.7638, 59.5151],
            [-64.7282, 59.4668],
            [-64.6795, 59.4471],
            [-64.5894, 59.4664],
            [-64.505, 59.5015],
            [-64.5028, 59.5216],
            [-64.4626, 59.5236],
            [-64.4334, 59.5422],
            [-64.3855, 59.524],
            [-64.3493, 59.5031],
            [-64.3866, 59.474],
            [-64.4577, 59.4594],
            [-64.5113, 59.4232],
            [-64.538, 59.371],
            [-64.493, 59.3498],
            [-64.5432, 59.3106],
            [-64.4883, 59.2573],
            [-64.5215, 59.1969],
            [-64.4724, 59.1806],
            [-64.491, 59.1366],
            [-64.4837, 59.1183],
            [-64.4977, 59.1066],
            [-64.49, 59.0992],
            [-64.4501, 59.1031],
            [-64.4167, 59.0878],
            [-64.3605, 59.0675],
            [-64.3149, 59.0658],
            [-64.2615, 59.0131],
            [-64.3874, 59.0064],
            [-64.46, 58.9817],
            [-64.481, 58.9885],
            [-64.471, 59.0002],
            [-64.4975, 59.0196],
            [-64.5225, 59.0027],
            [-64.5955, 59.0352],
            [-64.67, 59.0388],
            [-64.683, 59.0749],
            [-64.779, 59.0751],
            [-64.8647, 59.0156],
            [-64.8792, 58.952],
            [-64.856, 58.9331],
            [-64.839, 58.9372],
            [-64.8204, 58.917],
            [-64.7655, 58.924],
            [-64.7304, 58.9509],
            [-64.6514, 58.9439],
            [-64.6145, 58.9077],
            [-64.5793, 58.9049],
            [-64.5499, 58.8876],
            [-64.5086, 58.8877],
            [-64.4555, 58.9084],
            [-64.3784, 58.904],
            [-64.35, 58.892],
            [-64.3322, 58.9047],
            [-64.2873, 58.8969],
            [-64.2871, 58.8836],
            [-64.2333, 58.8643],
            [-64.2294, 58.7838],
            [-64.1998, 58.7796],
            [-64.1785, 58.7892],
            [-64.1375, 58.7463],
            [-64.0868, 58.7562],
            [-64.045, 58.8105],
            [-63.8118, 58.8472],
            [-63.7773, 58.8842],
            [-63.7251, 58.8774],
            [-63.7096, 58.8872],
            [-63.6796, 58.8787],
            [-63.68, 58.8666],
            [-63.6608, 58.8549],
            [-63.6356, 58.8489],
            [-63.595, 58.8574],
            [-63.5504, 58.8367],
            [-63.5499, 58.8225],
            [-63.4918, 58.7687],
            [-63.5613, 58.7279],
            [-63.754, 58.7169],
            [-63.7939, 58.7035],
            [-63.8107, 58.7124],
            [-63.8353, 58.7103],
            [-63.8548, 58.6966],
            [-63.9096, 58.7104],
            [-63.9499, 58.6854],
            [-64.0469, 58.7028],
            [-64.0785, 58.6541],
            [-64.1076, 58.6398],
            [-64.1046, 58.5634],
            [-64.0669, 58.5346],
            [-64.0466, 58.5352],
            [-64.0338, 58.5236],
            [-63.9733, 58.5298],
            [-63.9464, 58.559],
            [-63.907, 58.5736],
            [-63.8868, 58.5718],
            [-63.8579, 58.564],
            [-63.8584, 58.5047],
            [-63.8075, 58.4899],
            [-63.8808, 58.4296],
            [-63.9239, 58.445],
            [-63.9638, 58.439],
            [-63.9863, 58.4445],
            [-64.0129, 58.4241],
            [-64.0302, 58.3661],
            [-64.0614, 58.3611],
            [-64.0977, 58.377],
            [-64.1189, 58.3649],
            [-64.1582, 58.3712],
            [-64.1865, 58.3369],
            [-64.2175, 58.2479],
            [-64.2472, 58.2276],
            [-64.3733, 58.2085],
            [-64.4215, 58.1868],
            [-64.4328, 58.07],
            [-64.3202, 58.0616],
            [-64.299, 58.0446],
            [-64.2507, 58.0388],
            [-64.2384, 58.0252],
            [-64.2272, 57.9897],
            [-64.2044, 57.9738],
            [-64.1985, 57.9377],
            [-64.1622, 57.9231],
            [-64.1164, 57.8113],
            [-64.0717, 57.7701],
            [-64.0257, 57.7645],
            [-64.0337, 57.7916],
            [-64.0206, 57.8096],
            [-64.0116, 57.8026],
            [-63.997, 57.8122],
            [-63.9383, 57.7887],
            [-63.8831, 57.7127],
            [-63.8472, 57.7191],
            [-63.8039, 57.6846],
            [-63.7533, 57.7209],
            [-63.7482, 57.6744],
            [-63.735, 57.6629],
            [-63.7025, 57.6639],
            [-63.6848, 57.697],
            [-63.6591, 57.7019],
            [-63.6677, 57.7241],
            [-63.6522, 57.733],
            [-63.6322, 57.7291],
            [-63.6229, 57.7396],
            [-63.5964, 57.7414],
            [-63.6092, 57.6922],
            [-63.5942, 57.6858],
            [-63.5984, 57.664],
            [-63.6226, 57.6421],
            [-63.6446, 57.6344],
            [-63.6496, 57.6202],
            [-63.67, 57.6213],
            [-63.6843, 57.6036],
            [-63.6992, 57.6155],
            [-63.7303, 57.6012],
            [-63.7453, 57.5796],
            [-63.7749, 57.4937],
            [-63.761, 57.4394],
            [-63.71, 57.4227],
            [-63.701, 57.3684],
            [-63.8555, 57.3344],
            [-63.8831, 57.2777],
            [-63.8667, 57.2262],
            [-63.8838, 57.1964],
            [-63.8721, 57.1926],
            [-63.8457, 57.2043],
            [-63.8444, 57.2261],
            [-63.8088, 57.2623],
            [-63.7595, 57.2518],
            [-63.7368, 57.2217],
            [-63.7707, 57.1961],
            [-63.7646, 57.14],
            [-63.8115, 57.079],
            [-63.8898, 57.0862],
            [-63.8917, 57.0129],
            [-63.9139, 56.9777],
            [-63.9178, 56.9042],
            [-63.8992, 56.8774],
            [-64.0032, 56.8618],
            [-64.0125, 56.7788],
            [-64.0786, 56.7671],
            [-64.1451, 56.6998],
            [-64.0906, 56.6539],
            [-64.0246, 56.6531],
            [-64.0502, 56.6064],
            [-63.9308, 56.5338],
            [-63.9441, 56.4932],
            [-63.9016, 56.4408],
            [-63.9655, 56.4107],
            [-63.9928, 56.4083],
            [-64.0203, 56.418],
            [-64.1191, 56.4289],
            [-64.1525, 56.4412],
            [-64.1777, 56.4253],
            [-64.1151, 56.4022],
            [-64.1102, 56.386],
            [-64.1276, 56.37],
            [-64.0979, 56.3337],
            [-64.1301, 56.3024],
            [-64.0902, 56.28],
            [-64.0871, 56.257],
            [-64.0219, 56.2679],
            [-63.9669, 56.2565],
            [-63.9346, 56.2712],
            [-63.9126, 56.2636],
            [-63.9112, 56.2309],
            [-63.8741, 56.2248],
            [-63.8627, 56.2127],
            [-64.0045, 56.1734],
            [-64.0019, 56.1028],
            [-64.0355, 56.0822],
            [-64.0344, 56.0702],
            [-64.0178, 56.0673],
            [-63.9479, 56.0912],
            [-63.9054, 56.1238],
            [-63.8798, 56.1361],
            [-63.8559, 56.1372],
            [-63.838, 56.1226],
            [-63.8394, 56.1007],
            [-63.8108, 56.0847],
            [-63.8376, 56.0451],
            [-63.7739, 56.0536],
            [-63.7146, 56.0717],
            [-63.6828, 56.0596],
            [-63.6901, 56.0439],
            [-63.6364, 56.0236],
            [-63.5773, 56.0251],
            [-63.4955, 56.0401],
            [-63.4432, 56.0296],
            [-63.4669, 56.012],
            [-63.4699, 55.9984],
            [-63.4717, 55.9878],
            [-63.4871, 55.9875],
            [-63.5047, 56.0015],
            [-63.5627, 56.0059],
            [-63.5842, 55.99],
            [-63.6055, 55.9879],
            [-63.598, 55.9822],
            [-63.6862, 55.9618],
            [-63.6878, 55.9482],
            [-63.7034, 55.9461],
            [-63.7237, 55.9306],
            [-63.7923, 55.9315],
            [-63.8542, 55.9101],
            [-63.8248, 55.9045],
            [-63.8448, 55.8791],
            [-63.7526, 55.8466],
            [-63.7801, 55.8131],
            [-63.7169, 55.7804],
            [-63.7051, 55.7556],
            [-63.7276, 55.6955],
            [-63.7157, 55.6577],
            [-63.6682, 55.6523],
            [-63.6824, 55.6091],
            [-63.6652, 55.546],
            [-63.6912, 55.5088],
            [-63.7924, 55.4673],
            [-63.7364, 55.4383],
            [-63.6651, 55.4231],
            [-63.6307, 55.4303],
            [-63.5781, 55.4205],
            [-63.5488, 55.4208],
            [-63.5341, 55.4275],
            [-63.5104, 55.4203],
            [-63.4783, 55.4293],
            [-63.4498, 55.4208],
            [-63.4346, 55.4004],
            [-63.3515, 55.4184],
            [-63.3195, 55.4119],
            [-63.3245, 55.3661],
            [-63.442, 55.3441],
            [-63.5741, 55.3396],
            [-63.5223, 55.3099],
            [-63.6438, 55.2878],
            [-63.6792, 55.2669],
            [-63.6302, 55.2562],
            [-63.601, 55.2512],
            [-63.6222, 55.2426],
            [-63.6088, 55.2302],
            [-63.563, 55.2434],
            [-63.5471, 55.2255],
            [-63.5081, 55.2321],
            [-63.4623, 55.2553],
            [-63.4456, 55.2515],
            [-63.4023, 55.2578],
            [-63.4025, 55.249],
            [-63.4224, 55.2431],
            [-63.4122, 55.2337],
            [-63.4523, 55.202],
            [-63.5027, 55.2025],
            [-63.5626, 55.1877],
            [-63.5557, 55.1729],
            [-63.5731, 55.1558],
            [-63.5649, 55.1403],
            [-63.6026, 55.0764],
            [-63.5561, 55.0345],
            [-63.5585, 55.0001],
            [-64.9998, 55],
            [-66.2593, 54.9998],
            [-66.3331, 55.0671],
            [-66.4446, 55.141],
            [-66.4952, 55.2051],
            [-66.5163, 55.1953],
            [-66.5455, 55.2354],
            [-66.5656, 55.2384],
            [-66.6179, 55.2782],
            [-66.6217, 55.2687],
            [-66.6443, 55.2671],
            [-66.6586, 55.2552],
            [-66.7006, 55.29],
            [-66.7002, 55.3121],
            [-66.7345, 55.3212],
            [-66.7784, 55.3524],
            [-66.8078, 55.3568],
            [-66.8183, 55.3484],
            [-66.7998, 55.3233],
            [-66.8236, 55.307],
            [-66.7071, 55.2271],
            [-66.738, 55.1911],
            [-66.7549, 55.1136],
            [-66.735, 55.0441],
            [-66.6716, 55.0256],
            [-66.6278, 54.9884],
            [-66.65, 54.9746],
            [-66.6893, 55.0001],
            [-66.7087, 55.0047],
            [-66.7368, 55],
            [-66.7474, 55.0094],
            [-66.7493, 55],
            [-67.2192, 55],
            [-67.2727, 55.0242],
            [-67.2632, 55.0455],
            [-67.2339, 55.0512],
            [-67.263, 55.0719],
            [-67.3228, 55.0891],
            [-67.3684, 55.0913],
            [-67.4281, 55.0839],
            [-67.4432, 55.0649],
            [-67.4706, 55.049],
            [-67.4396, 55.0325],
            [-67.4224, 55.0001],
            [-68.75, 55],
            [-70, 55],
            [-70, 52.9184],
            [-70.0248, 52.9188],
            [-70.0239, 52.6964],
            [-70.0492, 52.6855],
            [-70.0798, 52.6502],
            [-70.1753, 52.5944],
            [-70.1759, 52.5794],
            [-70.1764, 52.545],
            [-70.2235, 52.4674],
            [-70.259, 52.4541],
            [-70.2814, 52.4256],
            [-70.2655, 52.4123],
            [-70.2317, 52.4175],
            [-70.2705, 52.3616],
            [-70.3793, 52.3386],
            [-70.4857, 52.3042],
            [-70.5243, 52.3062],
            [-70.562, 52.3166],
            [-70.6503, 52.2823],
            [-70.6996, 52.2798],
            [-70.7279, 52.2564],
            [-70.799, 52.2339],
            [-70.8245, 52.2461],
            [-70.8205, 52.2589],
            [-70.8067, 52.2641],
            [-70.882, 52.2798],
            [-70.9208, 52.2437],
            [-71.0381, 52.2115],
            [-71.0812, 52.213],
            [-71.0837, 52.1895],
            [-71.1404, 52.17],
            [-71.2029, 52.09],
            [-71.2685, 52.0462],
            [-71.2688, 52.0401],
            [-71.2054, 52.0396],
            [-71.2461, 52.0215],
            [-71.3151, 51.9669],
            [-71.3536, 51.9053],
            [-71.378, 51.8545],
            [-71.5305, 51.8343],
            [-71.5711, 51.8138],
            [-71.5781, 51.7483],
            [-71.6132, 51.6591],
            [-71.6096, 51.6065],
            [-71.5811, 51.5868],
            [-71.5793, 51.5323],
            [-71.5835, 51.5004],
            [-71.6654, 51.4772],
            [-71.6865, 51.4078],
            [-71.7064, 51.3941],
            [-71.7491, 51.3505],
            [-71.817, 51.3535],
            [-71.8161, 51.371],
            [-71.8364, 51.3594],
            [-71.8643, 51.3492],
            [-71.8811, 51.3055],
            [-71.8856, 51.2733],
            [-71.937, 51.2715],
            [-71.9569, 51.2403],
            [-71.9792, 51.2107],
            [-72.0176, 51.1368],
            [-72.0743, 51.1494],
            [-72.0544, 51.0819],
            [-72.0922, 51.022],
            [-72.1046, 50.9574],
            [-72.098, 50.8828],
            [-72.1553, 50.8285],
            [-72.1502, 50.7528],
            [-72.1883, 50.6416],
            [-72.1896, 50.5984],
            [-72.1618, 50.5412],
            [-72.1531, 50.4875],
            [-72.1628, 50.413],
            [-72.1849, 50.3775],
            [-72.1594, 50.3337],
            [-72.1664, 50.2668],
            [-72.151, 50.2311],
            [-72.1782, 50.1974],
            [-72.1721, 50.1842],
            [-72.1627, 50.1452],
            [-72.1757, 50.1046],
            [-72.1569, 50.0685],
            [-72.1703, 50.0511],
            [-72.1328, 49.9458],
            [-72.0599, 49.8262],
            [-72.0351, 49.8143],
            [-72.0263, 49.7492],
            [-72.0016, 49.6966],
            [-72.0468, 49.6712],
            [-72.0419, 49.6223],
            [-72.0135, 49.5687],
            [-71.9342, 49.5087],
            [-71.9286, 49.4326],
            [-71.944, 49.3856],
            [-71.9798, 49.3663],
            [-71.9924, 49.377],
            [-72.0302, 49.3944],
            [-72.0463, 49.3807],
            [-72.1045, 49.3692],
            [-72.1298, 49.3355],
            [-72.1588, 49.2527],
            [-72.0861, 49.1577],
            [-72.1, 49.1304],
            [-72.2004, 49.1234],
            [-72.2331, 49.0801],
            [-72.2873, 49.0527],
            [-72.2462, 49.0174],
            [-72.2127, 48.9653],
            [-72.2476, 48.9349],
            [-72.2837, 48.9155],
            [-72.3279, 48.9354],
            [-72.3309, 48.9937],
            [-72.3696, 48.9897],
            [-72.3983, 48.9987],
            [-72.5288, 48.9341],
            [-72.4907, 48.9011],
            [-72.5078, 48.893],
            [-72.5383, 48.8704],
            [-72.5653, 48.8793],
            [-72.5565, 48.8856],
            [-72.5818, 48.9078],
            [-72.6952, 48.8512],
            [-72.7114, 48.8523],
            [-72.723, 48.8381],
            [-72.8041, 48.8204],
            [-72.9226, 48.7842],
            [-72.8849, 48.7534],
            [-72.8345, 48.761],
            [-72.7926, 48.739],
            [-72.85, 48.7001],
            [-72.6419, 48.6244],
            [-72.6408, 48.6326],
            [-72.6173, 48.6444],
            [-72.484, 48.5281],
            [-72.4284, 48.527],
            [-72.4095, 48.54],
            [-72.4037, 48.556],
            [-72.3717, 48.5283],
            [-72.3415, 48.5254],
            [-72.3552, 48.5083],
            [-72.326, 48.5022],
            [-72.3457, 48.4919],
            [-72.2626, 48.4188],
            [-72.2479, 48.3896],
            [-72.1718, 48.3758],
            [-72.1684, 48.3948],
            [-72.175, 48.4247],
            [-72.1431, 48.4869],
            [-72.1897, 48.5128],
            [-72.2121, 48.5591],
            [-72.2488, 48.5403],
            [-72.2708, 48.5908],
            [-72.2957, 48.6109],
            [-72.3198, 48.6783],
            [-72.1022, 48.7308],
            [-72.0741, 48.7567],
            [-72.1009, 48.7615],
            [-72.1827, 48.8343],
            [-72.1693, 48.8594],
            [-72.1103, 48.8884],
            [-72.1363, 48.9115],
            [-71.9295, 49.013],
            [-71.8442, 49.0156],
            [-71.8782, 48.9671],
            [-71.9172, 48.9381],
            [-71.8624, 48.9631],
            [-71.7738, 48.9485],
            [-71.7402, 48.9259],
            [-71.745, 48.9142],
            [-71.7638, 48.8422],
            [-71.7538, 48.8155],
            [-71.727, 48.8063],
            [-71.6912, 48.771],
            [-71.6436, 48.7521],
            [-71.5415, 48.7857],
            [-71.4903, 48.7876],
            [-71.4834, 48.8251],
            [-71.4482, 48.8859],
            [-71.3859, 48.9351],
            [-71.3467, 48.9281],
            [-71.3472, 48.9496],
            [-71.2986, 48.9283],
            [-71.2661, 48.8799],
            [-71.2623, 48.8373],
            [-71.2609, 48.8257],
            [-71.2553, 48.763],
            [-71.3729, 48.7806],
            [-71.4833, 48.5134],
            [-71.4632, 48.5068],
            [-71.4861, 48.4527],
            [-71.4889, 48.3991],
            [-71.5503, 48.395],
            [-71.5772, 48.3912],
            [-71.5709, 48.4055],
            [-71.6781, 48.4209],
            [-71.7288, 48.4604],
            [-71.7892, 48.4597],
            [-71.7955, 48.4863],
            [-71.9022, 48.4927],
            [-71.9372, 48.4027],
            [-71.9654, 48.4002],
            [-71.9021, 48.3674],
            [-71.9274, 48.3043],
            [-71.7957, 48.2838],
            [-71.7857, 48.2739],
            [-71.7736, 48.2804],
            [-71.6114, 48.2501],
            [-71.654, 48.1717],
            [-71.6471, 48.1329],
            [-71.6676, 48.0899],
            [-71.7132, 48.0478],
            [-71.7175, 48.0157],
            [-71.7227, 47.9972],
            [-72.0666, 47.9988],
            [-72.0835, 47.9992],
            [-72.3165, 47.9998],
            [-72.3331, 48.0208],
            [-72.3896, 48.0526],
            [-72.4144, 48.048],
            [-72.4305, 48.1028],
            [-72.4165, 48.1215],
            [-72.4439, 48.1518],
            [-72.493, 48.1713],
            [-72.5338, 48.172],
            [-72.5492, 48.136],
            [-72.5673, 48.138],
            [-72.6632, 48.1609],
            [-72.6481, 48.2425],
            [-72.672, 48.277],
            [-72.6775, 48.3341],
            [-72.6602, 48.3668],
            [-72.7088, 48.3794],
            [-72.7328, 48.3671],
            [-72.8109, 48.3786],
            [-72.8345, 48.3665],
            [-72.8801, 48.3707],
            [-72.925, 48.4009],
            [-72.988, 48.4012],
            [-72.9904, 48.3897],
            [-73.0118, 48.3751],
            [-73.054, 48.3731],
            [-73.083, 48.3801],
            [-73.1218, 48.373],
            [-73.1451, 48.3589],
            [-73.1976, 48.3007],
            [-73.2731, 48.2963],
            [-73.2947, 48.2558],
            [-73.2641, 48.2262],
            [-73.3164, 48.1992],
            [-74.3562, 48.8738],
            [-74.4022, 48.8641],
            [-74.438, 48.8729],
            [-74.458, 48.9],
            [-74.5312, 48.9213],
            [-74.5467, 48.9381],
            [-74.5865, 48.912],
            [-74.6072, 48.9091],
            [-74.6081, 48.9565],
            [-74.6763, 49],
            [-75.484, 49],
            [-75.5059, 48.9851],
            [-75.5246, 48.9534],
            [-75.5205, 47.8632],
            [-75.4963, 47.8719],
            [-75.4943, 47.8828],
            [-75.4739, 47.8938],
            [-75.4418, 47.9017],
            [-75.475, 47.8881],
            [-75.4824, 47.8637],
            [-75.4409, 47.8681],
            [-75.3925, 47.7619],
            [-74.9523, 47.4668],
            [-74.912, 47.4178],
            [-74.8782, 47.3322],
            [-74.8953, 47.297],
            [-74.9189, 47.2957],
            [-74.9353, 47.3065],
            [-74.9659, 47.2964],
            [-74.9806, 47.3023],
            [-74.9968, 47.2871],
            [-75.1751, 47.408],
            [-75.1835, 47.3992],
            [-75.1666, 47.3904],
            [-75.168, 47.3739],
            [-75.1502, 47.3496],
            [-75.2328, 47.3006],
            [-75.2143, 47.2174],
            [-75.1692, 47.2107],
            [-75.1514, 47.1647],
            [-75.1141, 47.1358],
            [-75.0963, 47.1387],
            [-75.077, 47.1648],
            [-75.0342, 47.1845],
            [-74.9851, 47.2304],
            [-74.9581, 47.2252],
            [-74.9346, 47.2447],
            [-74.7647, 47.1282],
            [-74.7568, 47.1364],
            [-74.7688, 47.1424],
            [-74.7703, 47.1735],
            [-74.685, 47.194],
            [-74.6599, 47.2448],
            [-74.6195, 47.2517],
            [-74.606, 47.2866],
            [-74.5532, 47.328],
            [-74.5203, 47.396],
            [-74.451, 47.4642],
            [-74.2507, 47.328],
            [-74.2234, 47.3504],
            [-74.2218, 47.3635],
            [-74.1745, 47.3627],
            [-74.1676, 47.3464],
            [-74.1459, 47.3548],
            [-74.0905, 47.3573],
            [-74.0505, 47.3473],
            [-74.0273, 47.3734],
            [-74.0322, 47.4193],
            [-74.0162, 47.4283],
            [-73.9565, 47.3886],
            [-73.9998, 47.3423],
            [-74.0231, 47.2053],
            [-74.0171, 47.1684],
            [-73.7286, 46.9715],
            [-73.7845, 46.9618],
            [-73.7828, 46.9167],
            [-73.7313, 46.9126],
            [-73.6871, 46.8932],
            [-73.6923, 46.9176],
            [-73.6657, 46.9281],
            [-73.5786, 46.8713],
            [-73.5341, 46.9172],
            [-73.4858, 46.9116],
            [-73.4598, 46.9164],
            [-73.4688, 46.9281],
            [-73.459, 46.9385],
            [-73.438, 46.9355],
            [-73.4284, 46.9259],
            [-73.4237, 46.9499],
            [-73.3808, 46.9434],
            [-73.3772, 46.9565],
            [-73.3579, 46.961],
            [-73.3199, 46.9462],
            [-73.3019, 46.9008],
            [-73.1939, 46.84],
            [-73.1437, 46.8393],
            [-73.0331, 46.7612],
            [-73.0702, 46.7488],
            [-73.0291, 46.6986],
            [-73.1152, 46.6532],
            [-73.054, 46.6376],
            [-73.0049, 46.6007],
            [-73.0951, 46.5362],
            [-72.9877, 46.4552],
            [-72.9145, 46.3394],
            [-72.913, 46.2957],
            [-72.8764, 46.261],
            [-72.9189, 46.2187],
            [-73.0038, 46.1925],
            [-73.0049, 46.1704],
            [-73.0928, 46.1426],
            [-73.1536, 46.106],
            [-73.1764, 46.0769],
            [-73.1953, 45.9806],
            [-73.27, 45.8865],
            [-73.3248, 45.8358],
            [-73.3653, 45.8202],
            [-73.4711, 45.7132],
            [-73.5926, 45.6919],
            [-73.6035, 45.6909],
            [-73.6505, 45.6958],
            [-73.7281, 45.682],
            [-73.8319, 45.609],
            [-73.8342, 45.5873],
            [-73.8856, 45.5614],
            [-73.8842, 45.5358],
            [-73.8845, 45.5339],
            [-73.947, 45.5182],
            [-73.9863, 45.4793],
            [-74.0494, 45.4588],
            [-74.1161, 45.4655],
            [-74.1739, 45.4957],
            [-74.1813, 45.5288],
            [-74.2543, 45.5324],
            [-74.3295, 45.5217],
            [-74.3316, 45.5212],
            [-74.0672, 45.4421],
            [-74.0038, 45.3876],
            [-74.0063, 45.3648],
            [-73.9704, 45.3348],
            [-74.0467, 45.304],
            [-74.1298, 45.3097],
            [-74.1738, 45.2979],
            [-74.1943, 45.2598],
            [-74.344, 45.2051],
            [-74.4722, 45.3026],
            [-74.3813, 45.566],
            [-74.4362, 45.5728],
            [-74.4724, 45.5937],
            [-74.5351, 45.5948],
            [-74.6359, 45.6385],
            [-74.7241, 45.628],
            [-74.7864, 45.6387],
            [-74.8352, 45.6357],
            [-74.9368, 45.6449],
            [-74.989, 45.6107],
            [-75.0355, 45.5937],
            [-75.1279, 45.5767],
            [-75.2364, 45.5868],
            [-75.3425, 45.5369],
            [-75.3911, 45.5241],
            [-75.4596, 45.5187],
            [-75.5756, 45.4747],
            [-75.6776, 45.4607],
            [-75.7047, 45.4246],
            [-75.7564, 45.4123],
            [-75.7916, 45.3811],
            [-75.8179, 45.3726],
            [-75.8426, 45.3759],
            [-75.8952, 45.4015],
            [-75.9872, 45.4795],
            [-76.0899, 45.5137],
            [-76.201, 45.5188],
            [-76.2335, 45.5077],
            [-76.2451, 45.4685],
            [-76.366, 45.4577],
            [-76.5011, 45.5189],
            [-76.5217, 45.5162],
            [-76.608, 45.5317],
            [-76.6601, 45.5605],
            [-76.6713, 45.6245],
            [-76.712, 45.6654],
            [-76.6894, 45.6808],
            [-76.6929, 45.709],
            [-76.705, 45.7212],
            [-76.7446, 45.7228],
            [-76.7679, 45.7333],
            [-76.7814, 45.7852],
            [-76.7658, 45.8109],
            [-76.7648, 45.8509],
            [-76.7832, 45.8734],
            [-76.8949, 45.898],
            [-76.9276, 45.8921],
            [-76.9228, 45.87],
            [-76.9024, 45.8539],
            [-76.9059, 45.8474],
            [-76.9275, 45.8467],
            [-76.9134, 45.8035],
            [-76.9354, 45.789],
            [-76.9877, 45.7865],
            [-77.0537, 45.8105],
            [-77.0816, 45.8355],
            [-77.1904, 45.8637],
            [-77.2363, 45.9204],
            [-77.2785, 45.9488],
            [-77.2792, 46.0154],
            [-77.3624, 46.0594],
            [-77.5662, 46.1591],
            [-77.6758, 46.1986],
            [-77.7957, 46.1974],
            [-77.9269, 46.224],
            [-78, 46.2488],
            [-78.056, 46.2437],
            [-78.13, 46.2744],
            [-78.2606, 46.2738],
            [-78.3125, 46.2522],
            [-78.3885, 46.2915],
            [-78.5028, 46.2944],
            [-78.5972, 46.3187],
            [-78.6974, 46.3196],
            [-78.7284, 46.3422],
            [-78.7272, 46.3827],
            [-78.8928, 46.4617],
            [-78.99, 46.5487],
            [-79.0154, 46.6267],
            [-79.0945, 46.6881],
            [-79.171, 46.8251],
            [-79.214, 46.8331],
            [-79.3298, 46.957],
            [-79.3469, 47.0077],
            [-79.4351, 47.0841],
            [-79.4423, 47.1383],
            [-79.427, 47.2499],
            [-79.5846, 47.4225],
            [-79.5866, 47.4455],
            [-79.5609, 47.4775],
            [-79.5534, 47.511],
            [-79.518, 47.5582],
            [-79.5182, 47.7541],
            [-79.5191, 51.0622],
            [-79.5158, 51.4608],
            [-79.5053, 51.4752],
            [-79.5416, 51.5486],
            [-79.312, 51.6643],
            [-79.2256, 51.6252],
            [-79.2572, 51.5804],
            [-79.2544, 51.538],
            [-79.1698, 51.5195],
            [-79.095, 51.5314],
            [-78.9811, 51.4586],
            [-78.9764, 51.4024],
            [-78.949, 51.3326],
            [-78.8721, 51.3427],
            [-78.9004, 51.3769],
            [-78.7589, 51.4869],
            [-78.8299, 51.5138],
            [-78.8262, 51.5724],
            [-78.8571, 51.6225],
            [-78.9455, 51.6637],
            [-78.9417, 51.6904],
            [-78.9933, 51.7042],
            [-78.9992, 51.7386],
            [-79.0454, 51.7638],
            [-78.9751, 51.7989],
            [-78.8985, 51.7997],
            [-78.8405, 51.8346],
            [-78.8653, 51.8875],
            [-78.8561, 51.94],
            [-78.7286, 51.9704],
            [-78.6879, 52.0218],
            [-78.6208, 52.0814],
            [-78.5795, 52.1023],
            [-78.5367, 52.1964],
            [-78.5789, 52.254],
            [-78.5643, 52.2896],
            [-78.4994, 52.3415],
            [-78.5365, 52.4342],
            [-78.5039, 52.4598],
            [-78.6036, 52.5308],
            [-78.6918, 52.5388],
            [-78.7541, 52.5616],
            [-78.6851, 52.6054],
            [-78.7168, 52.6311],
            [-78.7121, 52.6783],
            [-78.7655, 52.7178],
            [-78.8529, 52.7453],
            [-78.8253, 52.7646],
            [-78.7377, 52.766],
            [-78.7693, 52.8054],
            [-78.745, 52.8478],
            [-78.8193, 52.8553],
            [-78.8695, 52.9036],
            [-78.8245, 52.9581],
            [-78.8553, 52.9877],
            [-78.9532, 52.991],
            [-78.985, 53.0256],
            [-78.9225, 53.0613],
            [-78.967, 53.0951],
            [-78.922, 53.1652],
            [-78.8972, 53.2407],
            [-78.9337, 53.2765],
            [-78.9465, 53.3216],
            [-78.9416, 53.3864],
            [-79.0386, 53.4553],
            [-79.0609, 53.4369],
            [-79.0931, 53.4549],
            [-79.0744, 53.5189],
            [-78.9913, 53.558],
            [-78.9464, 53.5388],
            [-78.885, 53.5563],
            [-78.9998, 53.6035],
            [-79.0079, 53.6832],
            [-79.0655, 53.6956],
            [-79.0773, 53.7006],
            [-79.073, 53.7415],
            [-79.0354, 53.7874],
            [-79.0688, 53.8564],
            [-79.0046, 53.9169],
            [-79.1319, 53.9445],
            [-79.0687, 53.9464],
            [-79.0376, 53.9675],
            [-79.0723, 53.995],
            [-78.9901, 54.0404],
            [-79.0759, 54.0329],
            [-79.098, 54.0514],
            [-79.1603, 54.0946],
            [-79.1331, 54.0861],
            [-79.0416, 54.103],
            [-79.1093, 54.1297],
            [-79.1018, 54.1623],
            [-79.3406, 54.1816],
            [-79.3545, 54.2069],
            [-79.2812, 54.2211],
            [-79.3591, 54.2714],
            [-79.4165, 54.2754],
            [-79.4147, 54.3013],
            [-79.4835, 54.339],
            [-79.4969, 54.3886],
            [-79.4738, 54.4532],
            [-79.515, 54.5382],
            [-79.5025, 54.5887],
            [-79.556, 54.6202],
            [-79.6563, 54.6118],
            [-79.6752, 54.6618],
            [-79.5654, 54.7226],
            [-79.5328, 54.7208],
            [-79.3876, 54.7642],
            [-79.3408, 54.7582],
            [-79.2263, 54.798],
            [-78.9804, 54.8339],
            [-78.9448, 54.8712],
            [-78.8338, 54.9124],
            [-78.7669, 54.9086],
            [-78.5785, 54.9608],
            [-78.5524, 54.9904],
            [-78.3881, 55.0204],
            [-78.3157, 55.0431],
            [-78.1297, 55.1493],
            [-77.9178, 55.2175],
            [-77.8461, 55.2579],
            [-77.7833, 55.2716],
            [-77.7444, 55.3129],
            [-77.6239, 55.3836],
            [-77.5543, 55.3959],
            [-77.4203, 55.4911],
            [-77.3482, 55.5182],
            [-77.3033, 55.5612],
            [-77.2002, 55.6118],
            [-77.1603, 55.6559],
            [-77.1888, 55.6789],
            [-77.1304, 55.71],
            [-77.001, 55.8035],
            [-76.9934, 55.8228],
            [-76.8302, 55.9298],
            [-76.7996, 56.0008],
            [-76.7294, 56.0167],
            [-76.691, 56.0479],
            [-76.687, 56.0861],
            [-76.6495, 56.1246],
            [-76.6517, 56.1893],
            [-76.6266, 56.2369],
            [-76.5573, 56.2879],
            [-76.5272, 56.3797],
            [-76.5395, 56.4053],
            [-76.5192, 56.4595],
            [-76.5525, 56.4772],
            [-76.5404, 56.536],
            [-76.5568, 56.556],
            [-76.5396, 56.6587],
            [-76.5335, 56.8201],
            [-76.5701, 56.9241],
            [-76.5594, 56.989],
            [-76.5751, 57.0486],
            [-76.5508, 57.0958],
            [-76.5827, 57.1381],
            [-76.5988, 57.2307],
            [-76.6281, 57.313],
            [-76.6984, 57.436],
            [-76.7762, 57.4923],
            [-76.8543, 57.6264],
            [-76.8432, 57.6504],
            [-76.8927, 57.7015],
            [-76.9255, 57.7643],
            [-76.9949, 57.8143],
            [-76.9973, 57.8423],
            [-77.0509, 57.8678],
            [-77.0938, 57.9421],
            [-77.1603, 57.9676],
            [-77.2691, 58.0557],
            [-77.368, 58.0893],
            [-77.4468, 58.1641],
            [-77.5109, 58.1938],
            [-77.5166, 58.226],
            [-77.6767, 58.2747],
            [-77.7497, 58.2803],
            [-77.7877, 58.3016],
            [-77.964, 58.3397],
            [-78.0585, 58.3755],
            [-78.0527, 58.4251],
            [-78.2006, 58.4706],
            [-78.231, 58.5077],
            [-78.2913, 58.5086],
            [-78.3442, 58.5539],
            [-78.37, 58.6125],
            [-78.5839, 58.6432],
            [-78.5039, 58.7161],
            [-78.5744, 58.7743],
            [-78.5215, 58.7836],
            [-78.538, 58.8383],
            [-78.5815, 58.8983],
            [-78.4384, 58.9023],
            [-78.368, 58.907],
            [-78.3508, 58.946],
            [-78.2866, 59.0044],
            [-78.3177, 59.0497],
            [-78.2223, 59.0472],
            [-78.1474, 59.1619],
            [-78.1476, 59.1621],
            [-78.1266, 59.1954],
            [-78.0556, 59.2212],
            [-77.9679, 59.2051],
            [-77.9723, 59.2558],
            [-77.874, 59.2659],
            [-77.8295, 59.2878],
            [-77.8122, 59.3557],
            [-77.6987, 59.3855],
            [-77.7222, 59.4177],
            [-77.8437, 59.431],
            [-77.8997, 59.4805],
            [-77.733, 59.5668],
            [-77.7921, 59.6225],
            [-77.7971, 59.6647],
            [-77.7628, 59.6965],
            [-77.6271, 59.7046],
            [-77.5796, 59.7364],
            [-77.5107, 59.7434],
            [-77.4267, 59.7885],
            [-77.3422, 59.7826],
            [-77.337, 59.8377],
            [-77.3847, 59.861],
            [-77.4048, 59.9325],
            [-77.3764, 59.9391],
            [-77.3028, 59.9734],
            [-77.4068, 60.0606],
            [-77.57, 60.0397],
            [-77.6075, 60.0985],
            [-77.4904, 60.1416],
            [-77.5739, 60.1877],
            [-77.5146, 60.2104],
            [-77.5104, 60.2425],
            [-77.563, 60.254],
            [-77.5896, 60.3035],
            [-77.6532, 60.3669],
            [-77.7284, 60.3696],
            [-77.7743, 60.4053],
            [-77.6975, 60.4611],
            [-77.5268, 60.5373],
            [-77.5877, 60.5629],
            [-77.6766, 60.5426],
            [-77.7301, 60.5813],
            [-77.7921, 60.5998],
            [-77.8335, 60.6589],
            [-77.7089, 60.6914],
            [-77.7162, 60.7109],
            [-77.62, 60.7711],
            [-77.6562, 60.7862],
            [-77.7751, 60.7899],
            [-77.8713, 60.7605],
            [-77.9903, 60.7754],
            [-78.0438, 60.7973],
            [-78.2249, 60.8093],
            [-78.0732, 60.9382],
            [-77.9126, 61.0426],
            [-77.8258, 61.0549],
            [-77.8241, 61.1468],
            [-77.7178, 61.2215],
            [-77.7602, 61.2366],
          ],
          [
            [-71.7499, 48.5619],
            [-71.7256, 48.5682],
            [-71.7398, 48.524],
            [-71.5555, 48.4893],
            [-71.5337, 48.5325],
            [-71.6167, 48.5436],
            [-71.6245, 48.5877],
            [-71.5945, 48.6585],
            [-71.7642, 48.6881],
            [-71.782, 48.6417],
            [-71.8092, 48.5801],
            [-71.7499, 48.5619],
          ],
          [
            [-66.7746, 55.2045],
            [-66.7741, 55.2084],
            [-66.7649, 55.2493],
            [-66.8339, 55.3019],
            [-66.8665, 55.2859],
            [-66.9272, 55.2911],
            [-66.928, 55.2533],
            [-66.9684, 55.2501],
            [-67.0577, 55.2501],
            [-67.069, 55.2393],
            [-66.9793, 55.1727],
            [-66.7787, 55.0366],
            [-66.7562, 55.0427],
            [-66.7473, 55.065],
            [-66.7924, 55.1199],
            [-66.7746, 55.2045],
          ],
          [
            [-64.2199, 58.6316],
            [-64.1874, 58.6449],
            [-64.151, 58.6772],
            [-64.1303, 58.7151],
            [-64.1759, 58.6561],
            [-64.2199, 58.6316],
          ],
          [
            [-64.5831, 58.6099],
            [-64.4055, 58.5967],
            [-64.3186, 58.6021],
            [-64.3955, 58.5998],
            [-64.5831, 58.6099],
          ],
          [
            [-65.0538, 58.5994],
            [-64.9341, 58.5849],
            [-64.7782, 58.6053],
            [-64.8941, 58.603],
            [-64.933, 58.5871],
            [-65.0538, 58.5994],
          ],
          [
            [-65.325, 58.6517],
            [-65.2605, 58.6414],
            [-65.2308, 58.6175],
            [-65.1968, 58.624],
            [-65.0845, 58.5976],
            [-65.1493, 58.619],
            [-65.1975, 58.6251],
            [-65.2346, 58.6213],
            [-65.2577, 58.6419],
            [-65.325, 58.6517],
          ],
          [
            [-65.7002, 58.815],
            [-65.5958, 58.7377],
            [-65.4531, 58.6891],
            [-65.4054, 58.6419],
            [-65.3297, 58.6506],
            [-65.407, 58.6458],
            [-65.4395, 58.6866],
            [-65.499, 58.7215],
            [-65.5846, 58.742],
            [-65.5825, 58.7712],
            [-65.7002, 58.815],
          ],
        ],
        [
          [
            [-71.7176, 48.9515],
            [-71.6685, 48.9765],
            [-71.6254, 48.9899],
            [-71.6396, 49.0157],
            [-71.6392, 49.0526],
            [-71.6292, 49.0619],
            [-71.5401, 49.0287],
            [-71.5276, 48.9777],
            [-71.5587, 48.9608],
            [-71.6425, 48.9105],
            [-71.6813, 48.9069],
            [-71.7176, 48.9515],
          ],
        ],
        [
          [
            [-72.9514, 46.093],
            [-72.96, 46.0934],
            [-72.9596, 46.0951],
            [-72.9514, 46.093],
          ],
        ],
        [
          [
            [-72.96, 46.0937],
            [-72.96, 46.0934],
            [-72.9625, 46.091],
            [-72.96, 46.0937],
          ],
        ],
        [
          [
            [-72.9724, 46.0813],
            [-72.9741, 46.0795],
            [-73.002, 46.0672],
            [-72.9724, 46.0813],
          ],
        ],
        [
          [
            [-67.673, 58.3283],
            [-67.5471, 58.3652],
            [-67.5144, 58.3237],
            [-67.6362, 58.2843],
            [-67.673, 58.3283],
          ],
        ],
        [
          [
            [-73.6362, 45.4043],
            [-73.5886, 45.407],
            [-73.6247, 45.4026],
            [-73.6362, 45.4043],
          ],
        ],
        [
          [
            [-73.6786, 45.4161],
            [-73.6849, 45.4168],
            [-73.6854, 45.4168],
            [-73.6858, 45.4169],
            [-73.6832, 45.4174],
            [-73.6786, 45.4161],
          ],
        ],
        [
          [
            [-73.6035, 45.6909],
            [-73.5926, 45.6919],
            [-73.5514, 45.6775],
            [-73.6353, 45.631],
            [-73.6784, 45.5566],
            [-73.7674, 45.5126],
            [-73.8784, 45.5212],
            [-73.8845, 45.5339],
            [-73.8842, 45.5358],
            [-73.8813, 45.5588],
            [-73.83, 45.5797],
            [-73.7722, 45.6449],
            [-73.7265, 45.6791],
            [-73.6035, 45.6909],
          ],
        ],
        [
          [
            [-73.9413, 45.4836],
            [-73.9019, 45.5174],
            [-73.8638, 45.5192],
            [-73.8643, 45.491],
            [-73.9016, 45.4677],
            [-73.9413, 45.4836],
          ],
        ],
        [
          [
            [-73.629, 45.4174],
            [-73.7926, 45.4482],
            [-73.9229, 45.4029],
            [-73.9725, 45.4368],
            [-73.8776, 45.4727],
            [-73.8547, 45.4935],
            [-73.8462, 45.5164],
            [-73.7638, 45.5098],
            [-73.6792, 45.5486],
            [-73.6191, 45.6314],
            [-73.5363, 45.6764],
            [-73.4896, 45.6836],
            [-73.4871, 45.6387],
            [-73.5058, 45.5805],
            [-73.5524, 45.5043],
            [-73.5394, 45.4783],
            [-73.596, 45.4227],
            [-73.629, 45.4174],
          ],
        ],
        [
          [
            [-73.9438, 45.3969],
            [-73.902, 45.3958],
            [-73.9064, 45.3452],
            [-73.9927, 45.3599],
            [-73.9987, 45.3849],
            [-73.9438, 45.3969],
          ],
        ],
        [
          [
            [-74.0741, 45.277],
            [-74.0306, 45.3018],
            [-73.9162, 45.3241],
            [-73.9634, 45.2598],
            [-74.0039, 45.234],
            [-74.0883, 45.2227],
            [-74.1632, 45.2358],
            [-74.1691, 45.2801],
            [-74.0982, 45.3002],
            [-74.0741, 45.277],
          ],
        ],
        [
          [
            [-72.9521, 46.0892],
            [-72.8211, 46.1431],
            [-72.7713, 46.1473],
            [-72.6523, 46.2128],
            [-72.6531, 46.2538],
            [-72.6017, 46.2766],
            [-72.4874, 46.1936],
            [-72.4089, 46.2593],
            [-72.373, 46.2576],
            [-72.3319, 46.2251],
            [-72.3898, 46.1832],
            [-72.3936, 46.1602],
            [-72.3326, 46.1168],
            [-72.3218, 46.0853],
            [-72.2825, 46.0427],
            [-72.2417, 46.0394],
            [-72.194, 46.0394],
            [-72.1448, 46.0344],
            [-72.1142, 46.0175],
            [-72.069, 46.0374],
            [-72.03, 46.0295],
            [-71.9867, 46.0262],
            [-71.86, 45.9571],
            [-71.8588, 45.9415],
            [-71.8275, 45.9272],
            [-71.7932, 45.8923],
            [-71.6367, 45.8145],
            [-71.617, 45.8305],
            [-71.5419, 45.7873],
            [-71.5208, 45.8046],
            [-71.4366, 45.7988],
            [-71.3915, 45.7538],
            [-71.3602, 45.7467],
            [-71.2072, 45.6424],
            [-71.2025, 45.6025],
            [-71.2138, 45.5714],
            [-71.1586, 45.5358],
            [-71.1603, 45.4582],
            [-71.1338, 45.4582],
            [-71.0137, 45.4535],
            [-70.9958, 45.4608],
            [-70.998, 45.4282],
            [-70.9374, 45.4263],
            [-70.927, 45.4125],
            [-70.959, 45.3913],
            [-70.9595, 45.3732],
            [-70.9592, 45.349],
            [-70.9688, 45.3323],
            [-70.9847, 45.3319],
            [-71.0104, 45.3474],
            [-71.0089, 45.3192],
            [-71.0976, 45.3017],
            [-71.1246, 45.2548],
            [-71.147, 45.2395],
            [-71.1814, 45.24],
            [-71.1977, 45.2541],
            [-71.23, 45.2494],
            [-71.2841, 45.3021],
            [-71.3364, 45.2728],
            [-71.3846, 45.233],
            [-71.4206, 45.2323],
            [-71.4024, 45.2148],
            [-71.3975, 45.2029],
            [-71.4376, 45.1412],
            [-71.4286, 45.1231],
            [-71.4694, 45.0838],
            [-71.4981, 45.0703],
            [-71.5056, 45.0498],
            [-71.491, 45.0428],
            [-71.5006, 45.014],
            [-72.3145, 45.0038],
            [-72.8446, 45.0167],
            [-74.1501, 44.9911],
            [-74.6376, 44.9992],
            [-74.6175, 45.0036],
            [-74.6026, 45.018],
            [-74.5356, 45.0367],
            [-74.4741, 45.0398],
            [-74.422, 45.0842],
            [-74.408, 45.1259],
            [-74.3528, 45.1414],
            [-74.322, 45.1671],
            [-74.2224, 45.1756],
            [-74.1638, 45.2007],
            [-74.1579, 45.2249],
            [-74.0493, 45.2146],
            [-73.9924, 45.2287],
            [-73.9067, 45.303],
            [-73.8212, 45.3324],
            [-73.7555, 45.4021],
            [-73.6854, 45.4168],
            [-73.6849, 45.4168],
            [-73.633, 45.4016],
            [-73.6247, 45.4026],
            [-73.5775, 45.4064],
            [-73.5267, 45.4004],
            [-73.4911, 45.4311],
            [-73.527, 45.5334],
            [-73.4596, 45.5981],
            [-73.4325, 45.7152],
            [-73.3652, 45.7749],
            [-73.2307, 45.8648],
            [-73.2087, 45.8965],
            [-73.2033, 45.9493],
            [-73.1491, 46.0409],
            [-73.002, 46.0672],
            [-72.9741, 46.0795],
            [-72.9521, 46.0892],
          ],
        ],
        [
          [
            [-69.3314, 58.9478],
            [-69.272, 59.0748],
            [-69.3425, 59.1249],
            [-69.2513, 59.1661],
            [-69.1627, 59.1472],
            [-69.1509, 59.0877],
            [-69.1158, 59.063],
            [-69.1488, 59.0167],
            [-69.1951, 59.0044],
            [-69.2123, 58.9593],
            [-69.2735, 58.9836],
            [-69.3314, 58.9478],
          ],
        ],
        [
          [
            [-74.8514, 48.8049],
            [-74.815, 48.8169],
            [-74.7752, 48.8045],
            [-74.7566, 48.7842],
            [-74.818, 48.7718],
            [-74.8538, 48.7501],
            [-74.8749, 48.7605],
            [-74.8775, 48.7863],
            [-74.8514, 48.8049],
          ],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "Ottawa Mkt": {
    type: "Feature",
    properties: { code: "ON_OTT", name: "Ottawa Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-75.0645, 44.9463],
            [-75.0644, 44.9463],
            [-75.0635, 44.9461],
            [-75.0653, 44.9454],
            [-75.0645, 44.9463],
          ],
        ],
        [
          [
            [-75.3623, 44.8003],
            [-75.3397, 44.8152],
            [-75.3626, 44.8001],
            [-75.3623, 44.8003],
          ],
        ],
        [
          [
            [-75.3974, 44.7784],
            [-75.3966, 44.7789],
            [-75.396, 44.7792],
            [-75.3879, 44.7833],
            [-75.3974, 44.7784],
          ],
        ],
        [
          [
            [-76.2903, 44.2154],
            [-76.3676, 44.1971],
            [-76.3466, 44.1714],
            [-76.3844, 44.126],
            [-76.4354, 44.1021],
            [-76.5064, 44.1372],
            [-76.5055, 44.1676],
            [-76.4255, 44.2154],
            [-76.3246, 44.2339],
            [-76.2903, 44.2154],
          ],
        ],
        [
          [
            [-76.807, 44.1331],
            [-76.6415, 44.1918],
            [-76.6277, 44.1719],
            [-76.6986, 44.1134],
            [-76.7538, 44.1018],
            [-76.807, 44.1331],
          ],
        ],
        [
          [
            [-77.3726, 44.1295],
            [-77.3453, 44.1071],
            [-77.3079, 44.0773],
            [-77.2567, 44.0928],
            [-77.2571, 44.1287],
            [-77.0725, 44.1744],
            [-77.0588, 44.1625],
            [-77.0839, 44.0461],
            [-77.0238, 44.0334],
            [-76.9369, 44.0607],
            [-76.9, 44.0342],
            [-77.0267, 43.9911],
            [-77.0555, 43.9505],
            [-76.9286, 43.9226],
            [-77.0094, 43.8903],
            [-77.0451, 43.8902],
            [-77.1553, 43.8388],
            [-77.2076, 43.8561],
            [-77.2311, 43.9008],
            [-77.2793, 43.8983],
            [-77.2456, 43.9604],
            [-77.2646, 43.9528],
            [-77.2825, 43.9493],
            [-77.2845, 43.9542],
            [-77.3165, 43.9581],
            [-77.4028, 43.935],
            [-77.4896, 43.9255],
            [-77.5606, 43.9857],
            [-77.6007, 44.0383],
            [-77.4968, 44.107],
            [-77.3726, 44.1295],
          ],
        ],
        [
          [
            [-74.9352, 45.0245],
            [-74.9559, 45.0139],
            [-75.0635, 44.9461],
            [-75.0644, 44.9463],
            [-75.0645, 44.9463],
            [-75.0658, 44.9452],
            [-75.0865, 44.9392],
            [-75.0923, 44.9395],
            [-75.1672, 44.8992],
            [-75.29, 44.8534],
            [-75.3377, 44.8159],
            [-75.3174, 44.8347],
            [-75.3623, 44.8003],
            [-75.3644, 44.7989],
            [-75.3859, 44.7844],
            [-75.396, 44.7792],
            [-75.3966, 44.7789],
            [-75.3974, 44.7784],
            [-75.5367, 44.6984],
            [-75.5782, 44.658],
            [-75.7442, 44.5473],
            [-75.8326, 44.4762],
            [-75.9073, 44.4046],
            [-75.9376, 44.3755],
            [-76.0615, 44.3434],
            [-76.2165, 44.3196],
            [-76.2254, 44.3204],
            [-76.3134, 44.2953],
            [-76.38, 44.2615],
            [-76.4387, 44.2335],
            [-76.5651, 44.2085],
            [-76.6311, 44.222],
            [-76.725, 44.2037],
            [-76.7582, 44.1824],
            [-76.9274, 44.1051],
            [-76.9694, 44.0637],
            [-77.0507, 44.048],
            [-77.0455, 44.078],
            [-77.0064, 44.1337],
            [-76.9149, 44.1583],
            [-76.9017, 44.1774],
            [-76.9128, 44.1786],
            [-76.9383, 44.1817],
            [-76.9564, 44.1567],
            [-77.0642, 44.129],
            [-77.0182, 44.1858],
            [-77.0399, 44.1991],
            [-77.0984, 44.1774],
            [-77.1963, 44.1599],
            [-77.2551, 44.1797],
            [-77.3179, 44.1661],
            [-77.3176, 44.1707],
            [-77.3821, 44.1601],
            [-77.4131, 44.1406],
            [-77.5312, 44.1033],
            [-77.5715, 44.1026],
            [-77.5877, 44.0594],
            [-77.6703, 44.0352],
            [-77.7151, 44.003],
            [-77.7207, 43.9962],
            [-77.7506, 44.0108],
            [-77.8178, 44.0037],
            [-77.9348, 43.9702],
            [-78.0069, 43.9631],
            [-78.0894, 43.9678],
            [-78.1155, 43.9572],
            [-78.2638, 43.9511],
            [-78.2831, 43.9899],
            [-78.2684, 44.0317],
            [-78.2741, 44.0502],
            [-78.3256, 44.0762],
            [-78.3709, 44.1681],
            [-78.4366, 44.1533],
            [-78.4505, 44.1586],
            [-78.4892, 44.1676],
            [-78.4911, 44.1601],
            [-78.5, 44.1652],
            [-78.4988, 44.1521],
            [-78.5327, 44.1436],
            [-78.5423, 44.1563],
            [-78.5477, 44.1685],
            [-78.4627, 44.1857],
            [-78.4406, 44.2062],
            [-78.3925, 44.2174],
            [-78.4255, 44.2905],
            [-78.5826, 44.253],
            [-78.5968, 44.2776],
            [-78.621, 44.2648],
            [-78.6176, 44.2385],
            [-78.6572, 44.2563],
            [-78.7716, 44.2311],
            [-78.792, 44.2042],
            [-78.8422, 44.1692],
            [-78.8565, 44.2167],
            [-78.8804, 44.2179],
            [-78.9047, 44.2016],
            [-78.9173, 44.2328],
            [-78.9539, 44.2243],
            [-78.9941, 44.3196],
            [-79.0133, 44.3798],
            [-79.0277, 44.3932],
            [-79.1012, 44.5589],
            [-79.0466, 44.5711],
            [-79.0405, 44.578],
            [-78.9997, 44.6109],
            [-79.036, 44.6888],
            [-78.989, 44.7026],
            [-79.0593, 44.8309],
            [-79.091, 44.8131],
            [-79.0971, 44.8238],
            [-79.0629, 44.8401],
            [-79.1188, 44.9501],
            [-79.0936, 44.9429],
            [-78.9344, 44.9856],
            [-78.9765, 45.0618],
            [-79.0055, 45.0689],
            [-79.0157, 45.0755],
            [-79.0252, 45.0736],
            [-79.0318, 45.0747],
            [-79.0342, 45.0769],
            [-79.0324, 45.079],
            [-79.0281, 45.0799],
            [-79.0282, 45.0844],
            [-79.0358, 45.1102],
            [-79.0342, 45.1155],
            [-79.0296, 45.1176],
            [-79.0237, 45.1244],
            [-79.0253, 45.1343],
            [-79.017, 45.1363],
            [-79.001, 45.1053],
            [-78.8426, 45.1451],
            [-78.8535, 45.1687],
            [-78.8542, 45.1799],
            [-78.8444, 45.1768],
            [-78.8352, 45.1714],
            [-78.823, 45.1722],
            [-78.8278, 45.1787],
            [-78.8228, 45.1867],
            [-78.8061, 45.196],
            [-78.808, 45.1998],
            [-78.8053, 45.2022],
            [-78.799, 45.1998],
            [-78.7927, 45.1998],
            [-78.7883, 45.2014],
            [-78.7853, 45.2051],
            [-78.783, 45.2046],
            [-78.7813, 45.2098],
            [-78.7772, 45.21],
            [-78.7588, 45.227],
            [-78.7419, 45.2359],
            [-78.6881, 45.2112],
            [-78.7406, 45.3108],
            [-78.4283, 45.3929],
            [-78.4553, 45.4549],
            [-78.4255, 45.4952],
            [-78.4284, 45.5109],
            [-78.4217, 45.5226],
            [-78.4222, 45.5321],
            [-78.777, 45.4368],
            [-78.8249, 45.4048],
            [-78.842, 45.4135],
            [-78.8525, 45.4095],
            [-78.8583, 45.4094],
            [-78.8642, 45.4046],
            [-78.8619, 45.4082],
            [-78.863, 45.4106],
            [-78.8594, 45.4145],
            [-79.0082, 45.6796],
            [-78.9444, 45.6728],
            [-78.9094, 45.6949],
            [-78.9118, 45.7054],
            [-78.9094, 45.7063],
            [-78.9094, 45.7097],
            [-78.9202, 45.7121],
            [-78.9544, 45.7101],
            [-78.9932, 45.6969],
            [-79.0014, 45.6999],
            [-79.0097, 45.7004],
            [-79.0126, 45.6988],
            [-79.0199, 45.701],
            [-79.0544, 45.764],
            [-79.063, 45.7694],
            [-79.1304, 45.7514],
            [-79.156, 45.7984],
            [-79.1554, 45.8017],
            [-79.1473, 45.8049],
            [-79.1453, 45.8073],
            [-79.1473, 45.81],
            [-79.1479, 45.8176],
            [-79.1452, 45.8278],
            [-79.1698, 45.8414],
            [-79.163, 45.8454],
            [-79.1576, 45.8463],
            [-79.1543, 45.8491],
            [-79.1399, 45.852],
            [-79.1202, 45.8669],
            [-79.1448, 45.876],
            [-79.1518, 45.8769],
            [-79.1672, 45.874],
            [-79.1719, 45.869],
            [-79.1777, 45.8679],
            [-79.1874, 45.8625],
            [-79.1882, 45.8587],
            [-79.1953, 45.8705],
            [-79.1907, 45.8718],
            [-79.1954, 45.8801],
            [-79.2046, 45.8789],
            [-79.2129, 45.8683],
            [-79.2197, 45.8672],
            [-79.2195, 45.8645],
            [-79.2251, 45.8651],
            [-79.2302, 45.8639],
            [-79.2343, 45.8666],
            [-79.247, 45.8692],
            [-79.2272, 45.9248],
            [-79.258, 45.9944],
            [-79.0937, 46.0399],
            [-79.1302, 46.1081],
            [-79.1082, 46.1132],
            [-78.9771, 46.1876],
            [-78.9059, 46.2063],
            [-78.9077, 46.1966],
            [-78.912, 46.1943],
            [-78.9122, 46.1915],
            [-78.9149, 46.1902],
            [-78.9175, 46.1849],
            [-78.9153, 46.1652],
            [-78.9287, 46.1555],
            [-78.9435, 46.1227],
            [-78.9312, 46.1148],
            [-78.9182, 46.1123],
            [-78.9115, 46.1034],
            [-78.9522, 46.0872],
            [-78.9201, 46.0475],
            [-78.8745, 46.0345],
            [-78.8689, 46.0267],
            [-78.8706, 46.0166],
            [-78.8631, 46.0074],
            [-78.8592, 46.0063],
            [-78.8507, 46.0075],
            [-78.8384, 46.0023],
            [-78.8254, 46.0103],
            [-78.8154, 46.0129],
            [-78.8082, 46.0121],
            [-78.7939, 46.015],
            [-78.7523, 46.0095],
            [-78.6997, 46.0225],
            [-78.6921, 46.0589],
            [-78.6879, 46.0617],
            [-78.6841, 46.0619],
            [-78.6724, 46.0668],
            [-78.6689, 46.0697],
            [-78.6689, 46.0761],
            [-78.6727, 46.0847],
            [-78.681, 46.0935],
            [-78.684, 46.1019],
            [-78.694, 46.1092],
            [-78.6998, 46.1182],
            [-78.7193, 46.1382],
            [-78.7731, 46.1727],
            [-78.7693, 46.1773],
            [-78.7679, 46.1778],
            [-78.761, 46.1651],
            [-78.5891, 46.2121],
            [-78.513, 46.0717],
            [-78.3282, 46.1215],
            [-78.4125, 46.2733],
            [-78.397, 46.2736],
            [-78.3971, 46.2818],
            [-78.4036, 46.2927],
            [-78.3884, 46.2917],
            [-78.3124, 46.2524],
            [-78.2605, 46.2739],
            [-78.1299, 46.2745],
            [-78.0559, 46.2439],
            [-77.9999, 46.249],
            [-77.9268, 46.2241],
            [-77.7956, 46.1976],
            [-77.6757, 46.1988],
            [-77.5661, 46.1592],
            [-77.4419, 46.0933],
            [-77.3896, 46.0683],
            [-77.3624, 46.0595],
            [-77.2791, 46.0155],
            [-77.2784, 45.9489],
            [-77.2729, 45.9411],
            [-77.2363, 45.9129],
            [-77.2071, 45.8818],
            [-77.1985, 45.8704],
            [-77.1903, 45.8639],
            [-77.0816, 45.8356],
            [-77.0537, 45.8106],
            [-76.9877, 45.7866],
            [-76.9353, 45.7891],
            [-76.9184, 45.7984],
            [-76.9134, 45.8037],
            [-76.912, 45.8097],
            [-76.9275, 45.8469],
            [-76.9059, 45.8475],
            [-76.9019, 45.8501],
            [-76.9024, 45.854],
            [-76.9228, 45.8701],
            [-76.9268, 45.8848],
            [-76.9299, 45.8878],
            [-76.9276, 45.8923],
            [-76.8948, 45.8981],
            [-76.8416, 45.8904],
            [-76.8023, 45.8758],
            [-76.7871, 45.8744],
            [-76.7802, 45.8724],
            [-76.7647, 45.851],
            [-76.77, 45.8297],
            [-76.7658, 45.811],
            [-76.7814, 45.7853],
            [-76.7752, 45.7745],
            [-76.7658, 45.7468],
            [-76.7446, 45.7229],
            [-76.7049, 45.7213],
            [-76.6929, 45.7092],
            [-76.6893, 45.6809],
            [-76.7078, 45.673],
            [-76.7119, 45.6655],
            [-76.711, 45.6618],
            [-76.699, 45.6534],
            [-76.6712, 45.6247],
            [-76.66, 45.5606],
            [-76.608, 45.5318],
            [-76.5217, 45.5164],
            [-76.5011, 45.519],
            [-76.4815, 45.5048],
            [-76.4536, 45.5004],
            [-76.4335, 45.4921],
            [-76.366, 45.4578],
            [-76.2451, 45.4686],
            [-76.2335, 45.5078],
            [-76.201, 45.5189],
            [-76.0899, 45.5139],
            [-75.9872, 45.4796],
            [-75.8952, 45.4016],
            [-75.8426, 45.376],
            [-75.8178, 45.3727],
            [-75.797, 45.3783],
            [-75.7916, 45.3812],
            [-75.7725, 45.3959],
            [-75.7564, 45.4124],
            [-75.7047, 45.4247],
            [-75.6776, 45.4609],
            [-75.5756, 45.4748],
            [-75.4596, 45.5188],
            [-75.391, 45.5242],
            [-75.3425, 45.537],
            [-75.317, 45.5462],
            [-75.3046, 45.553],
            [-75.2987, 45.5592],
            [-75.2364, 45.5869],
            [-75.1279, 45.5768],
            [-75.081, 45.5897],
            [-75.0355, 45.5938],
            [-75.0111, 45.6008],
            [-74.989, 45.6108],
            [-74.9672, 45.6246],
            [-74.9492, 45.6402],
            [-74.9368, 45.645],
            [-74.9232, 45.6454],
            [-74.8352, 45.6358],
            [-74.7864, 45.6388],
            [-74.7241, 45.6281],
            [-74.6359, 45.6386],
            [-74.5351, 45.5948],
            [-74.4786, 45.595],
            [-74.4623, 45.5896],
            [-74.4361, 45.5728],
            [-74.3813, 45.5661],
            [-74.3943, 45.5212],
            [-74.4293, 45.4171],
            [-74.4722, 45.3027],
            [-74.344, 45.2052],
            [-74.3507, 45.1989],
            [-74.3603, 45.1942],
            [-74.374, 45.1829],
            [-74.3878, 45.1813],
            [-74.3867, 45.172],
            [-74.418, 45.1647],
            [-74.423, 45.1637],
            [-74.4226, 45.1634],
            [-74.5051, 45.1216],
            [-74.5203, 45.0937],
            [-74.5521, 45.0605],
            [-74.6155, 45.0359],
            [-74.76, 45.0102],
            [-74.8313, 45.0324],
            [-74.9352, 45.0245],
          ],
          [
            [-76.2807, 44.2775],
            [-76.2592, 44.2844],
            [-76.226, 44.2976],
            [-76.2807, 44.2775],
          ],
          [
            [-78.2735, 45.6258],
            [-78.2544, 45.6615],
            [-78.2622, 45.7236],
            [-78.3219, 45.7677],
            [-78.3514, 45.7669],
            [-78.2735, 45.6258],
          ],
          [
            [-78.2526, 46.0496],
            [-78.2344, 46.0113],
            [-78.1658, 46.0068],
            [-78.101, 46.0804],
            [-78.1453, 46.1125],
            [-78.2229, 46.1022],
            [-78.2526, 46.0496],
          ],
          [
            [-77.8738, 45.9274],
            [-77.779, 45.9309],
            [-77.7195, 45.8984],
            [-77.6517, 45.9323],
            [-77.5825, 45.9187],
            [-77.6663, 46.0677],
            [-77.6909, 46.0564],
            [-77.7654, 46.0663],
            [-77.7894, 46.0503],
            [-77.8738, 45.9274],
          ],
          [
            [-76.6739, 44.2945],
            [-76.6571, 44.2699],
            [-76.568, 44.2466],
            [-76.5442, 44.2772],
            [-76.6158, 44.3165],
            [-76.6739, 44.2945],
          ],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "Toronto Mkt": {
    type: "Feature",
    properties: { code: "ON_TOR", name: "Toronto Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-80.2361, 44.8833],
            [-80.1813, 44.8748],
            [-80.1519, 44.8288],
            [-80.2085, 44.7996],
            [-80.2452, 44.8335],
            [-80.2361, 44.8833],
          ],
        ],
        [
          [
            [-79.8535, 44.7449],
            [-79.8086, 44.7716],
            [-79.7631, 44.7699],
            [-79.7535, 44.8006],
            [-79.7799, 44.8786],
            [-79.8194, 44.89],
            [-79.8139, 44.9228],
            [-79.8291, 44.9425],
            [-79.8706, 44.9573],
            [-79.8645, 44.9659],
            [-79.8275, 44.9699],
            [-79.8885, 45.0146],
            [-79.8607, 45.0217],
            [-79.8297, 44.9852],
            [-79.7495, 44.9977],
            [-79.7293, 44.9842],
            [-79.7233, 44.9667],
            [-79.6717, 44.9806],
            [-79.6809, 44.998],
            [-79.6159, 45.0111],
            [-79.5706, 44.9911],
            [-79.5645, 44.9653],
            [-79.5077, 44.939],
            [-79.5468, 44.8373],
            [-79.5227, 44.8036],
            [-79.5348, 44.7667],
            [-79.4121, 44.8157],
            [-79.4199, 44.7978],
            [-79.387, 44.7744],
            [-79.3503, 44.7725],
            [-79.2969, 44.7836],
            [-79.2975, 44.7757],
            [-79.2132, 44.7942],
            [-79.1584, 44.8143],
            [-79.1528, 44.8365],
            [-79.1263, 44.8435],
            [-79.1296, 44.8598],
            [-79.1053, 44.8665],
            [-79.0902, 44.8932],
            [-79.0629, 44.8401],
            [-79.0971, 44.8238],
            [-79.091, 44.8131],
            [-79.0593, 44.8309],
            [-78.989, 44.7026],
            [-79.036, 44.6888],
            [-78.9997, 44.6109],
            [-79.0405, 44.578],
            [-79.0466, 44.5711],
            [-79.1012, 44.5589],
            [-79.0277, 44.3932],
            [-79.0133, 44.3798],
            [-78.9941, 44.3196],
            [-78.9539, 44.2243],
            [-78.9173, 44.2328],
            [-78.9047, 44.2016],
            [-78.8804, 44.2179],
            [-78.8565, 44.2167],
            [-78.8422, 44.1692],
            [-78.792, 44.2042],
            [-78.7715, 44.2311],
            [-78.6572, 44.2563],
            [-78.6176, 44.2385],
            [-78.621, 44.2648],
            [-78.5968, 44.2776],
            [-78.5826, 44.253],
            [-78.4255, 44.2905],
            [-78.3925, 44.2174],
            [-78.4406, 44.2062],
            [-78.4627, 44.1857],
            [-78.5477, 44.1685],
            [-78.5423, 44.1563],
            [-78.5327, 44.1436],
            [-78.4988, 44.1521],
            [-78.5, 44.1652],
            [-78.4911, 44.1601],
            [-78.4892, 44.1676],
            [-78.4505, 44.1586],
            [-78.4366, 44.1533],
            [-78.3709, 44.1681],
            [-78.3256, 44.0762],
            [-78.2741, 44.0502],
            [-78.2684, 44.0317],
            [-78.2831, 43.9899],
            [-78.2639, 43.9513],
            [-78.3777, 43.9291],
            [-78.4817, 43.8966],
            [-78.6176, 43.8985],
            [-78.7127, 43.8675],
            [-78.8143, 43.8696],
            [-78.9021, 43.8455],
            [-78.9352, 43.8512],
            [-78.9805, 43.8279],
            [-79.0944, 43.8113],
            [-79.1523, 43.7596],
            [-79.2068, 43.7345],
            [-79.2776, 43.6719],
            [-79.4103, 43.6311],
            [-79.4681, 43.6339],
            [-79.5094, 43.5873],
            [-79.5368, 43.5882],
            [-79.5992, 43.5349],
            [-79.6026, 43.4928],
            [-79.6418, 43.4685],
            [-79.7168, 43.3745],
            [-79.8003, 43.3199],
            [-79.7844, 43.2824],
            [-79.7437, 43.2454],
            [-79.5279, 43.1952],
            [-79.4116, 43.1982],
            [-79.3149, 43.1843],
            [-79.1267, 43.2602],
            [-79.0756, 43.2628],
            [-79.057, 43.252],
            [-79.0572, 43.1727],
            [-79.0432, 43.1428],
            [-79.0791, 43.0753],
            [-79.03, 43.0597],
            [-79.0226, 42.9804],
            [-78.9347, 42.9482],
            [-78.9087, 42.9084],
            [-78.9511, 42.8801],
            [-79.0355, 42.8741],
            [-79.094, 42.8557],
            [-79.1595, 42.8755],
            [-79.2181, 42.8673],
            [-79.2604, 42.8789],
            [-79.3289, 42.8743],
            [-79.3455, 42.8554],
            [-79.4235, 42.8707],
            [-79.458, 42.8995],
            [-79.4814, 42.961],
            [-79.5626, 42.9886],
            [-79.5832, 43.0017],
            [-79.6526, 43.0145],
            [-79.6847, 43.0465],
            [-79.7693, 43.0557],
            [-80.1345, 43.1865],
            [-80.1399, 43.2148],
            [-80.2005, 43.2107],
            [-80.2274, 43.2783],
            [-80.1327, 43.289],
            [-80.079, 43.3164],
            [-80.0909, 43.3509],
            [-80.0701, 43.391],
            [-80.0799, 43.4324],
            [-80.0343, 43.4711],
            [-80.1479, 43.5521],
            [-80.129, 43.5903],
            [-80.0791, 43.5736],
            [-79.9701, 43.4966],
            [-79.9493, 43.516],
            [-80.0029, 43.5542],
            [-80.0153, 43.5462],
            [-80.1066, 43.6102],
            [-80.0856, 43.629],
            [-80.2615, 43.7521],
            [-80.2401, 43.7713],
            [-80.2844, 43.819],
            [-80.351, 43.866],
            [-80.3986, 43.856],
            [-80.4384, 44.026],
            [-80.2779, 44.0636],
            [-80.3014, 44.1038],
            [-80.2699, 44.1312],
            [-80.3095, 44.1546],
            [-80.2665, 44.1924],
            [-80.3003, 44.2205],
            [-80.2538, 44.2481],
            [-80.2611, 44.2807],
            [-80.2204, 44.2893],
            [-80.2338, 44.35],
            [-80.2614, 44.3946],
            [-80.2739, 44.4487],
            [-80.3399, 44.4343],
            [-80.3474, 44.4673],
            [-80.3244, 44.5185],
            [-80.4083, 44.5061],
            [-80.4145, 44.5392],
            [-80.3516, 44.5387],
            [-80.2885, 44.5334],
            [-80.2293, 44.5062],
            [-80.1809, 44.5015],
            [-80.1192, 44.4702],
            [-80.047, 44.5001],
            [-79.9953, 44.5588],
            [-80.0101, 44.599],
            [-79.9792, 44.6677],
            [-80.033, 44.7231],
            [-80.1191, 44.7468],
            [-80.1245, 44.8098],
            [-80.0526, 44.8183],
            [-80.0279, 44.8553],
            [-79.9512, 44.867],
            [-79.8656, 44.7845],
            [-79.8535, 44.7449],
          ],
          [
            [-80.191, 43.7715],
            [-80.2125, 43.7522],
            [-80.1612, 43.7164],
            [-80.1231, 43.7079],
            [-80.0705, 43.722],
            [-80.0226, 43.6862],
            [-80.0011, 43.7056],
            [-80.0279, 43.7254],
            [-79.9957, 43.7543],
            [-80.1398, 43.8616],
            [-80.2184, 43.7907],
            [-80.191, 43.7715],
          ],
        ],
      ],
    },
    countryCode: ["CA"],
  },
  "Greensboro Mkt": {
    type: "Feature",
    properties: { code: "NC_GRE", name: "Greensboro Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-79.5744, 35.4026],
          [-79.5723, 35.3952],
          [-79.5946, 35.371],
          [-79.5913, 35.3521],
          [-79.5848, 35.325],
          [-79.5488, 35.3272],
          [-79.5261, 35.2987],
          [-79.5112, 35.3048],
          [-79.5076, 35.3218],
          [-79.4571, 35.3174],
          [-79.4395, 35.3333],
          [-79.4255, 35.3257],
          [-79.4254, 35.313],
          [-79.4313, 35.2821],
          [-79.4425, 35.2675],
          [-79.4474, 35.2375],
          [-79.4785, 35.2192],
          [-79.4762, 35.2051],
          [-79.4862, 35.2039],
          [-79.4901, 35.2137],
          [-79.5172, 35.2138],
          [-79.5247, 35.1956],
          [-79.5371, 35.1979],
          [-79.5357, 35.2077],
          [-79.5538, 35.1938],
          [-79.5426, 35.1649],
          [-79.5118, 35.1632],
          [-79.5471, 35.1531],
          [-79.5419, 35.1179],
          [-79.5164, 35.0916],
          [-79.5077, 35.063],
          [-79.5713, 35.0659],
          [-79.5829, 35.0841],
          [-79.5748, 35.1213],
          [-79.6143, 35.1637],
          [-79.6422, 35.1577],
          [-79.6945, 35.1793],
          [-79.7498, 35.173],
          [-79.8358, 35.1772],
          [-79.8717, 35.1722],
          [-79.8478, 35.1301],
          [-79.8732, 35.1333],
          [-79.8884, 35.1159],
          [-79.8987, 35.1151],
          [-79.8983, 35.093],
          [-79.9091, 35.0711],
          [-79.9107, 35.0946],
          [-79.9279, 35.1177],
          [-79.9574, 35.1139],
          [-79.9939, 35.0855],
          [-80.0246, 35.0908],
          [-80.0678, 35.1119],
          [-80.0754, 35.1431],
          [-80.0533, 35.1786],
          [-80.0984, 35.2581],
          [-80.0819, 35.3215],
          [-80.0663, 35.3406],
          [-80.0654, 35.3605],
          [-80.0512, 35.3715],
          [-80.0688, 35.3941],
          [-80.0847, 35.3966],
          [-80.0887, 35.4213],
          [-80.0825, 35.4303],
          [-80.0716, 35.4204],
          [-80.0605, 35.4332],
          [-80.0432, 35.4283],
          [-80.0388, 35.4342],
          [-80.0694, 35.4522],
          [-80.0702, 35.4666],
          [-80.0554, 35.4882],
          [-80.065, 35.5116],
          [-80.0876, 35.5304],
          [-80.1207, 35.5372],
          [-80.1448, 35.5147],
          [-80.138, 35.505],
          [-80.1827, 35.5043],
          [-80.1991, 35.5454],
          [-80.21, 35.5528],
          [-80.2082, 35.5804],
          [-80.2588, 35.6258],
          [-80.2888, 35.6363],
          [-80.3078, 35.6671],
          [-80.3301, 35.6787],
          [-80.334, 35.6932],
          [-80.3241, 35.7081],
          [-80.3322, 35.719],
          [-80.3727, 35.7165],
          [-80.4537, 35.7346],
          [-80.4786, 35.775],
          [-80.4878, 35.7662],
          [-80.4958, 35.7823],
          [-80.5084, 35.7749],
          [-80.534, 35.7864],
          [-80.5355, 35.7713],
          [-80.565, 35.7572],
          [-80.5725, 35.7407],
          [-80.608, 35.7385],
          [-80.6132, 35.7253],
          [-80.6271, 35.7204],
          [-80.6423, 35.7247],
          [-80.6396, 35.7145],
          [-80.6496, 35.7018],
          [-80.6656, 35.6995],
          [-80.7099, 35.7148],
          [-80.7385, 35.6925],
          [-80.7227, 35.6753],
          [-80.7275, 35.6726],
          [-80.7472, 35.6679],
          [-80.7517, 35.6596],
          [-80.7599, 35.6617],
          [-80.76, 35.6715],
          [-80.7936, 35.6664],
          [-80.8065, 35.7126],
          [-80.7933, 35.7303],
          [-80.7525, 35.7286],
          [-80.7295, 35.7925],
          [-80.739, 35.8042],
          [-80.7232, 35.8102],
          [-80.7129, 35.8388],
          [-80.7277, 35.8507],
          [-80.7133, 35.8495],
          [-80.7114, 35.8431],
          [-80.7058, 35.89],
          [-80.6976, 35.9039],
          [-80.6721, 35.9163],
          [-80.6873, 35.9314],
          [-80.6864, 35.9628],
          [-80.7002, 35.9624],
          [-80.6927, 36.0511],
          [-80.7541, 36.0526],
          [-80.7452, 36.0443],
          [-80.7545, 36.0294],
          [-80.7491, 36.0163],
          [-80.7543, 36.012],
          [-80.7612, 36.019],
          [-80.7667, 36.0049],
          [-80.7888, 36.0001],
          [-80.7861, 36.0261],
          [-80.7621, 36.0221],
          [-80.7596, 36.0474],
          [-80.7681, 36.0529],
          [-80.8568, 36.0551],
          [-80.8641, 36.0843],
          [-80.8807, 36.0841],
          [-80.8882, 36.0813],
          [-80.8964, 36.0832],
          [-80.9194, 36.112],
          [-80.9069, 36.1059],
          [-80.9008, 36.1153],
          [-80.9062, 36.1283],
          [-80.8922, 36.148],
          [-80.8778, 36.1424],
          [-80.8689, 36.172],
          [-80.8417, 36.1581],
          [-80.8329, 36.1696],
          [-80.8386, 36.1815],
          [-80.809, 36.1816],
          [-80.8099, 36.1959],
          [-80.7751, 36.2048],
          [-80.7532, 36.2235],
          [-80.7366, 36.2432],
          [-80.7447, 36.2486],
          [-80.7391, 36.271],
          [-80.7343, 36.26],
          [-80.7225, 36.2603],
          [-80.7227, 36.2696],
          [-80.734, 36.2675],
          [-80.7366, 36.28],
          [-80.7432, 36.276],
          [-80.7533, 36.2773],
          [-80.7402, 36.2952],
          [-80.731, 36.2902],
          [-80.7178, 36.3003],
          [-80.7409, 36.3152],
          [-80.7351, 36.3306],
          [-80.7235, 36.3352],
          [-80.7432, 36.3486],
          [-80.7368, 36.3576],
          [-80.7599, 36.3603],
          [-80.7788, 36.3871],
          [-80.7718, 36.3978],
          [-80.7868, 36.3944],
          [-80.8028, 36.4006],
          [-80.8122, 36.3918],
          [-80.8393, 36.3977],
          [-80.8425, 36.3895],
          [-80.8577, 36.3951],
          [-80.8599, 36.4186],
          [-80.8729, 36.435],
          [-80.9114, 36.4356],
          [-80.9273, 36.4525],
          [-80.929, 36.4863],
          [-80.9186, 36.5404],
          [-80.9017, 36.5618],
          [-80.7048, 36.5623],
          [-80.2952, 36.544],
          [-78.9649, 36.5421],
          [-78.9722, 36.5265],
          [-78.9968, 36.5214],
          [-79.039, 36.494],
          [-79.0968, 36.4834],
          [-79.0822, 36.3971],
          [-79.1485, 36.3484],
          [-79.1537, 36.2422],
          [-79.1182, 36.2417],
          [-79.0966, 36.2128],
          [-79.0887, 36.1658],
          [-78.9978, 36.1496],
          [-78.9429, 36.1244],
          [-78.9715, 36.104],
          [-79.0037, 36.1155],
          [-79.0119, 36.0496],
          [-79.0545, 36.0501],
          [-79.031, 36.0409],
          [-79.1432, 35.9777],
          [-79.2092, 35.9739],
          [-79.2642, 35.9296],
          [-79.2507, 35.8574],
          [-79.2292, 35.8358],
          [-79.1897, 35.8711],
          [-79.1245, 35.8528],
          [-79.1381, 35.8171],
          [-79.1004, 35.7937],
          [-79.0905, 35.7634],
          [-79.0535, 35.7543],
          [-79.0185, 35.7175],
          [-79.0668, 35.6801],
          [-79.109, 35.6759],
          [-79.1305, 35.7093],
          [-79.2022, 35.6491],
          [-79.2056, 35.6151],
          [-79.1174, 35.6281],
          [-79.0524, 35.596],
          [-79.0242, 35.5482],
          [-78.9706, 35.5222],
          [-79.0766, 35.4146],
          [-79.0376, 35.336],
          [-79.0518, 35.2971],
          [-79.0222, 35.2869],
          [-79.0267, 35.2712],
          [-79.0418, 35.259],
          [-79.052, 35.264],
          [-79.0724, 35.2784],
          [-79.0745, 35.3011],
          [-79.0832, 35.2997],
          [-79.1125, 35.307],
          [-79.1165, 35.3243],
          [-79.1323, 35.3334],
          [-79.1387, 35.3129],
          [-79.159, 35.3113],
          [-79.1682, 35.322],
          [-79.1833, 35.307],
          [-79.193, 35.3115],
          [-79.1911, 35.3214],
          [-79.2046, 35.3369],
          [-79.1876, 35.3606],
          [-79.2027, 35.3679],
          [-79.2163, 35.3682],
          [-79.2441, 35.3372],
          [-79.2656, 35.3454],
          [-79.2896, 35.4004],
          [-79.3298, 35.4236],
          [-79.3268, 35.4349],
          [-79.3485, 35.4636],
          [-79.3596, 35.4694],
          [-79.3647, 35.4588],
          [-79.3813, 35.4559],
          [-79.3915, 35.4731],
          [-79.4088, 35.4798],
          [-79.4366, 35.4672],
          [-79.4399, 35.4736],
          [-79.4166, 35.4825],
          [-79.4436, 35.4991],
          [-79.4451, 35.4841],
          [-79.4654, 35.4951],
          [-79.4713, 35.4811],
          [-79.4839, 35.4854],
          [-79.4838, 35.4667],
          [-79.517, 35.4577],
          [-79.522, 35.4082],
          [-79.5043, 35.3929],
          [-79.5744, 35.4026],
        ],
        [
          [-79.1215, 35.347],
          [-79.0982, 35.3378],
          [-79.0944, 35.351],
          [-79.0693, 35.348],
          [-79.0923, 35.3645],
          [-79.1184, 35.3594],
          [-79.1215, 35.347],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Raleigh Mkt": {
    type: "Feature",
    properties: { code: "NC_RAL", name: "Raleigh Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-78.7842, 35.3353],
          [-78.8148, 35.3376],
          [-78.8505, 35.3682],
          [-78.8604, 35.3517],
          [-78.8653, 35.3564],
          [-78.8713, 35.3491],
          [-78.8879, 35.3486],
          [-78.8964, 35.3331],
          [-78.8922, 35.3128],
          [-78.9224, 35.3012],
          [-78.9311, 35.3064],
          [-78.9261, 35.3224],
          [-78.9357, 35.3211],
          [-78.9403, 35.3054],
          [-78.9324, 35.2987],
          [-78.9149, 35.2992],
          [-78.913, 35.2889],
          [-78.9419, 35.2865],
          [-78.9675, 35.2989],
          [-78.9865, 35.2973],
          [-78.9994, 35.28],
          [-79.0217, 35.2691],
          [-79.0222, 35.2869],
          [-79.0518, 35.2971],
          [-79.0376, 35.336],
          [-79.0766, 35.4146],
          [-78.9706, 35.5222],
          [-79.0242, 35.5482],
          [-79.0524, 35.596],
          [-79.1174, 35.6281],
          [-79.2056, 35.6151],
          [-79.2022, 35.6491],
          [-79.1305, 35.7093],
          [-79.109, 35.6759],
          [-79.0668, 35.6801],
          [-79.0185, 35.7175],
          [-79.0535, 35.7543],
          [-79.0905, 35.7634],
          [-79.1004, 35.7937],
          [-79.1381, 35.8171],
          [-79.1245, 35.8528],
          [-79.1897, 35.8711],
          [-79.2292, 35.8358],
          [-79.2497, 35.8557],
          [-79.2494, 35.8769],
          [-79.2646, 35.9077],
          [-79.2642, 35.9296],
          [-79.2092, 35.9739],
          [-79.1432, 35.9777],
          [-79.031, 36.0409],
          [-79.0545, 36.0501],
          [-79.0119, 36.0496],
          [-79.0037, 36.1155],
          [-78.9715, 36.104],
          [-78.9429, 36.1244],
          [-78.9978, 36.1496],
          [-79.0887, 36.1658],
          [-79.0966, 36.2128],
          [-79.1182, 36.2417],
          [-79.1537, 36.2421],
          [-79.1485, 36.3484],
          [-79.0822, 36.3971],
          [-79.0968, 36.4834],
          [-79.039, 36.494],
          [-78.9968, 36.5214],
          [-78.9722, 36.5265],
          [-78.9649, 36.5421],
          [-78.0462, 36.5442],
          [-76.916, 36.5438],
          [-76.9246, 36.53],
          [-76.9032, 36.5188],
          [-76.935, 36.4839],
          [-76.9343, 36.4714],
          [-76.9506, 36.4709],
          [-76.9466, 36.4311],
          [-76.9527, 36.4366],
          [-76.9783, 36.4306],
          [-76.9784, 36.4225],
          [-76.9953, 36.4358],
          [-76.9997, 36.4228],
          [-77.0207, 36.4278],
          [-77.0282, 36.4198],
          [-77.0137, 36.4084],
          [-77.0501, 36.392],
          [-77.0681, 36.3737],
          [-77.0631, 36.3626],
          [-77.0709, 36.3552],
          [-77.092, 36.3545],
          [-77.0937, 36.3658],
          [-77.1354, 36.3537],
          [-77.1321, 36.3285],
          [-77.1106, 36.3236],
          [-77.0913, 36.3082],
          [-77.0736, 36.316],
          [-77.0344, 36.3066],
          [-77.0421, 36.2995],
          [-77.0256, 36.3004],
          [-77.0249, 36.2883],
          [-77.0344, 36.2934],
          [-77.0718, 36.2605],
          [-77.0658, 36.2542],
          [-77.0724, 36.2419],
          [-77.048, 36.2244],
          [-77.0357, 36.1964],
          [-77.0046, 36.1892],
          [-77.002, 36.1454],
          [-76.964, 36.1208],
          [-76.9714, 36.1098],
          [-77.0137, 36.12],
          [-77.0478, 36.0991],
          [-77.0789, 36.0915],
          [-77.0952, 36.0955],
          [-77.1211, 36.1239],
          [-77.1511, 36.1089],
          [-77.1674, 36.112],
          [-77.1831, 36.1037],
          [-77.1908, 36.0802],
          [-77.1527, 36.0555],
          [-77.1631, 36.0468],
          [-77.1561, 36.0404],
          [-77.1379, 36.0392],
          [-77.1191, 36.0243],
          [-77.1386, 36.0096],
          [-77.1223, 35.9961],
          [-77.1329, 36.0004],
          [-77.1378, 35.9899],
          [-77.1526, 35.9957],
          [-77.1668, 35.9885],
          [-77.1642, 35.9796],
          [-77.1422, 35.9712],
          [-77.1493, 35.9593],
          [-77.2001, 35.9501],
          [-77.1958, 35.9378],
          [-77.1767, 35.9296],
          [-77.1638, 35.9385],
          [-77.1412, 35.9347],
          [-77.1316, 35.9417],
          [-77.125, 35.9276],
          [-77.1036, 35.934],
          [-77.0932, 35.9258],
          [-77.0817, 35.9382],
          [-77.0678, 35.9374],
          [-77.0718, 35.9443],
          [-77.0452, 35.944],
          [-77.0391, 35.931],
          [-77.0277, 35.9295],
          [-77.0306, 35.9052],
          [-77.0222, 35.8912],
          [-77.0378, 35.8904],
          [-77.0312, 35.8814],
          [-77.0453, 35.8752],
          [-77.0447, 35.8621],
          [-77.0338, 35.8572],
          [-77.0049, 35.8584],
          [-76.9872, 35.8768],
          [-76.9804, 35.8726],
          [-76.9583, 35.8839],
          [-76.9411, 35.9013],
          [-76.9223, 35.898],
          [-76.8933, 35.864],
          [-76.9037, 35.8383],
          [-76.8942, 35.8318],
          [-76.9043, 35.8249],
          [-76.8973, 35.8137],
          [-76.8753, 35.8137],
          [-76.839, 35.8452],
          [-76.8302, 35.886],
          [-76.8062, 35.8838],
          [-76.7809, 35.8639],
          [-76.7608, 35.8648],
          [-76.7838, 35.849],
          [-76.7893, 35.8377],
          [-76.7826, 35.8245],
          [-76.8034, 35.8065],
          [-76.7926, 35.7891],
          [-76.8038, 35.7837],
          [-76.8174, 35.7478],
          [-76.8469, 35.7186],
          [-76.8451, 35.7054],
          [-76.6555, 35.7053],
          [-76.6237, 35.7674],
          [-76.5937, 35.7501],
          [-76.5836, 35.7522],
          [-76.5605, 35.7966],
          [-76.5679, 35.7435],
          [-76.5445, 35.7359],
          [-76.525, 35.7664],
          [-76.494, 35.7458],
          [-76.504, 35.7033],
          [-76.2647, 35.6926],
          [-76.2453, 35.6692],
          [-76.2046, 35.6722],
          [-76.1959, 35.7135],
          [-76.18, 35.6556],
          [-76.1937, 35.6458],
          [-76.1945, 35.6355],
          [-76.1933, 35.6457],
          [-76.1779, 35.6546],
          [-76.1815, 35.6664],
          [-76.1698, 35.6722],
          [-76.173, 35.6962],
          [-76.1448, 35.7024],
          [-76.1308, 35.6913],
          [-76.1176, 35.6914],
          [-76.1191, 35.6795],
          [-76.1045, 35.6784],
          [-76.0952, 35.6693],
          [-76.0594, 35.6751],
          [-76.0353, 35.662],
          [-76.0275, 35.6691],
          [-75.9012, 35.669],
          [-75.8768, 35.6281],
          [-75.8798, 35.6068],
          [-75.8698, 35.5828],
          [-75.9164, 35.5383],
          [-75.9546, 35.5265],
          [-75.9631, 35.4939],
          [-75.9957, 35.4754],
          [-76.0209, 35.4107],
          [-76.0597, 35.4102],
          [-76.0693, 35.3708],
          [-76.1328, 35.3495],
          [-76.1497, 35.3264],
          [-76.2351, 35.35],
          [-76.2654, 35.3433],
          [-76.3273, 35.3568],
          [-76.4082, 35.35],
          [-76.4487, 35.3837],
          [-76.4858, 35.3714],
          [-76.5217, 35.3916],
          [-76.606, 35.3871],
          [-76.6285, 35.368],
          [-76.6052, 35.3379],
          [-76.617, 35.3309],
          [-76.6122, 35.2794],
          [-76.6325, 35.2352],
          [-76.8455, 35.216],
          [-76.9591, 35.3009],
          [-77.1259, 35.386],
          [-77.1285, 35.4284],
          [-77.1436, 35.395],
          [-77.1904, 35.419],
          [-77.1968, 35.4105],
          [-77.2067, 35.4182],
          [-77.2181, 35.4086],
          [-77.2126, 35.4264],
          [-77.2393, 35.4179],
          [-77.2794, 35.4239],
          [-77.2746, 35.4452],
          [-77.2809, 35.4583],
          [-77.3039, 35.4719],
          [-77.3326, 35.4753],
          [-77.3202, 35.4834],
          [-77.3519, 35.4995],
          [-77.3268, 35.5037],
          [-77.3185, 35.5125],
          [-77.3466, 35.5247],
          [-77.3511, 35.557],
          [-77.3626, 35.5536],
          [-77.3651, 35.559],
          [-77.394, 35.5484],
          [-77.3885, 35.5626],
          [-77.4428, 35.563],
          [-77.4432, 35.5428],
          [-77.484, 35.5486],
          [-77.4801, 35.5428],
          [-77.491, 35.5417],
          [-77.4885, 35.5348],
          [-77.5221, 35.5251],
          [-77.5134, 35.5181],
          [-77.5244, 35.519],
          [-77.5226, 35.5249],
          [-77.5303, 35.5185],
          [-77.5237, 35.4992],
          [-77.5366, 35.4794],
          [-77.5523, 35.4822],
          [-77.5529, 35.4742],
          [-77.5732, 35.484],
          [-77.5634, 35.5262],
          [-77.5833, 35.5274],
          [-77.6151, 35.5162],
          [-77.6115, 35.526],
          [-77.6494, 35.5364],
          [-77.6628, 35.5319],
          [-77.6692, 35.5104],
          [-77.709, 35.5063],
          [-77.7395, 35.5195],
          [-77.7449, 35.5084],
          [-77.8268, 35.4702],
          [-77.831, 35.4096],
          [-77.8427, 35.4014],
          [-77.8525, 35.4075],
          [-77.8607, 35.4032],
          [-77.8638, 35.3809],
          [-77.8927, 35.3641],
          [-77.8485, 35.3436],
          [-77.8518, 35.3048],
          [-77.8798, 35.2502],
          [-77.8773, 35.2587],
          [-77.885, 35.2551],
          [-77.8863, 35.2705],
          [-77.8948, 35.2751],
          [-77.9061, 35.262],
          [-77.9407, 35.2686],
          [-77.9404, 35.2797],
          [-77.9323, 35.282],
          [-77.9523, 35.3031],
          [-77.9436, 35.3186],
          [-77.9653, 35.3174],
          [-77.9873, 35.3368],
          [-78.0103, 35.334],
          [-78.0254, 35.3407],
          [-78.0362, 35.3399],
          [-78.0501, 35.3194],
          [-78.0799, 35.3102],
          [-78.0845, 35.2919],
          [-78.1071, 35.2872],
          [-78.0998, 35.2725],
          [-78.1143, 35.254],
          [-78.1235, 35.2661],
          [-78.1166, 35.2687],
          [-78.1154, 35.2925],
          [-78.1291, 35.2757],
          [-78.1656, 35.2718],
          [-78.2216, 35.285],
          [-78.2106, 35.2915],
          [-78.2235, 35.2986],
          [-78.2367, 35.2999],
          [-78.2356, 35.2926],
          [-78.258, 35.2841],
          [-78.2705, 35.2966],
          [-78.2917, 35.2878],
          [-78.293, 35.2744],
          [-78.3047, 35.2861],
          [-78.2851, 35.3178],
          [-78.3062, 35.3103],
          [-78.3061, 35.295],
          [-78.3145, 35.2991],
          [-78.3203, 35.2942],
          [-78.3261, 35.3056],
          [-78.3542, 35.3141],
          [-78.3462, 35.3438],
          [-78.3637, 35.3385],
          [-78.3671, 35.3039],
          [-78.38, 35.3106],
          [-78.3816, 35.3038],
          [-78.4037, 35.3055],
          [-78.401, 35.2918],
          [-78.429, 35.3092],
          [-78.4286, 35.3032],
          [-78.4518, 35.3055],
          [-78.4319, 35.286],
          [-78.4495, 35.2835],
          [-78.4587, 35.2956],
          [-78.496, 35.2878],
          [-78.5414, 35.3513],
          [-78.563, 35.3645],
          [-78.5672, 35.3596],
          [-78.5709, 35.3685],
          [-78.581, 35.3668],
          [-78.5796, 35.3855],
          [-78.6132, 35.3867],
          [-78.6252, 35.3759],
          [-78.645, 35.3894],
          [-78.6474, 35.3762],
          [-78.6593, 35.3745],
          [-78.6704, 35.393],
          [-78.6728, 35.3769],
          [-78.6806, 35.3819],
          [-78.685, 35.3726],
          [-78.6977, 35.3752],
          [-78.7046, 35.3665],
          [-78.735, 35.4002],
          [-78.7499, 35.3972],
          [-78.7543, 35.3821],
          [-78.7086, 35.3296],
          [-78.7245, 35.3287],
          [-78.7487, 35.3309],
          [-78.7599, 35.326],
          [-78.7826, 35.3296],
          [-78.7842, 35.3353],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Knoxville Mkt": {
    type: "Feature",
    properties: { code: "TN_KNO", name: "Knoxville Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-84.7822, 35.6324],
            [-84.7807, 35.6291],
            [-84.7754, 35.6341],
            [-84.7826, 35.6212],
            [-84.7822, 35.6324],
          ],
        ],
        [
          [
            [-84.782, 35.6377],
            [-84.7822, 35.6324],
            [-84.7837, 35.6356],
            [-84.782, 35.6377],
          ],
        ],
        [
          [
            [-84.2962, 37.1643],
            [-84.2997, 37.1705],
            [-84.2655, 37.1819],
            [-84.2786, 37.1874],
            [-84.2808, 37.1982],
            [-84.2592, 37.2055],
            [-84.2721, 37.2131],
            [-84.2547, 37.2388],
            [-84.2404, 37.2284],
            [-84.2334, 37.2481],
            [-84.2219, 37.2391],
            [-84.2149, 37.2815],
            [-84.1909, 37.2855],
            [-84.1802, 37.3041],
            [-84.1477, 37.3028],
            [-84.1526, 37.3106],
            [-84.137, 37.31],
            [-84.1242, 37.3357],
            [-84.1081, 37.3265],
            [-84.1071, 37.3157],
            [-84.0941, 37.3139],
            [-84.1086, 37.3101],
            [-84.1004, 37.3015],
            [-84.0728, 37.2853],
            [-84.0526, 37.2829],
            [-84.0563, 37.2706],
            [-84.0057, 37.2534],
            [-83.9435, 37.2506],
            [-83.9397, 37.2589],
            [-83.9225, 37.2617],
            [-83.9, 37.2669],
            [-83.8769, 37.2947],
            [-83.8632, 37.3066],
            [-83.8335, 37.3243],
            [-83.8174, 37.3171],
            [-83.7979, 37.3223],
            [-83.8013, 37.3301],
            [-83.7918, 37.3303],
            [-83.7819, 37.3436],
            [-83.8105, 37.373],
            [-83.773, 37.37],
            [-83.7637, 37.3467],
            [-83.7597, 37.3588],
            [-83.7382, 37.3698],
            [-83.7094, 37.3683],
            [-83.7075, 37.3571],
            [-83.6939, 37.3536],
            [-83.683, 37.3591],
            [-83.6895, 37.3368],
            [-83.6759, 37.3388],
            [-83.6674, 37.3558],
            [-83.6536, 37.3581],
            [-83.6093, 37.3013],
            [-83.574, 37.2883],
            [-83.5661, 37.2654],
            [-83.5304, 37.2558],
            [-83.5215, 37.2645],
            [-83.5235, 37.2778],
            [-83.4942, 37.2787],
            [-83.5134, 37.2604],
            [-83.5077, 37.236],
            [-83.5051, 37.2042],
            [-83.5515, 37.1676],
            [-83.5512, 37.1193],
            [-83.5354, 37.1031],
            [-83.5413, 37.0855],
            [-83.5262, 37.0854],
            [-83.5152, 37.0965],
            [-83.4949, 37.0902],
            [-83.4892, 37.1016],
            [-83.46, 37.0874],
            [-83.4309, 37.1005],
            [-83.4262, 37.1106],
            [-83.3831, 37.1159],
            [-83.3732, 37.1316],
            [-83.3494, 37.1178],
            [-83.3771, 37.0937],
            [-83.3698, 37.0706],
            [-83.3339, 37.0724],
            [-83.3195, 37.0819],
            [-83.3151, 37.0528],
            [-83.2549, 37.0368],
            [-83.2407, 37.0242],
            [-83.2251, 37.0241],
            [-83.226, 37.0056],
            [-83.186, 37.0059],
            [-83.1822, 37.0209],
            [-83.1646, 37.0212],
            [-83.1413, 37.0056],
            [-83.1239, 37.0057],
            [-83.1265, 36.995],
            [-83.1478, 36.9851],
            [-83.1505, 36.9542],
            [-83.0379, 36.9843],
            [-83.0205, 36.9779],
            [-83.0058, 36.9931],
            [-82.9654, 37.0001],
            [-82.8164, 37.0709],
            [-82.7618, 37.1046],
            [-82.6335, 37.1543],
            [-82.5504, 37.2045],
            [-82.4492, 37.2439],
            [-82.3553, 37.2652],
            [-82.3418, 37.2809],
            [-82.3246, 37.2832],
            [-82.2949, 37.3061],
            [-82.2876, 37.3005],
            [-82.2997, 37.2956],
            [-82.2894, 37.2864],
            [-82.2893, 37.2706],
            [-82.2761, 37.261],
            [-82.2778, 37.2483],
            [-82.2481, 37.2616],
            [-82.2362, 37.2124],
            [-82.2121, 37.2106],
            [-82.2077, 37.1995],
            [-82.2134, 37.1946],
            [-82.226, 37.2],
            [-82.2221, 37.1893],
            [-82.2026, 37.1561],
            [-82.1913, 37.1559],
            [-82.1775, 37.1365],
            [-82.1706, 37.1147],
            [-82.136, 37.1009],
            [-82.1276, 37.1099],
            [-82.0733, 37.1076],
            [-82.0576, 37.1216],
            [-82.0359, 37.1173],
            [-82.0162, 37.1028],
            [-82.0074, 37.12],
            [-81.9975, 37.1148],
            [-82.0001, 37.0987],
            [-81.9726, 37.1056],
            [-81.9697, 37.0981],
            [-81.978, 37.0944],
            [-81.9522, 37.0549],
            [-81.9617, 37.0439],
            [-81.9389, 37.0503],
            [-81.9406, 37.0357],
            [-81.9281, 37.0327],
            [-81.9316, 37.0219],
            [-81.9228, 37.0183],
            [-81.9265, 37.002],
            [-81.9109, 37.0057],
            [-81.9121, 37.0137],
            [-81.907, 37.0075],
            [-81.9039, 37.0121],
            [-81.9058, 37.0371],
            [-81.9142, 37.0366],
            [-81.8867, 37.0482],
            [-81.8669, 37.0481],
            [-81.8683, 37.0549],
            [-81.8581, 37.0516],
            [-81.8626, 37.0637],
            [-81.857, 37.0546],
            [-81.837, 37.0623],
            [-81.8159, 37.038],
            [-81.8565, 37.0327],
            [-81.8748, 37.0192],
            [-81.8889, 37.0265],
            [-81.8961, 37.0083],
            [-81.9308, 36.9997],
            [-81.9412, 36.9707],
            [-81.9317, 36.9601],
            [-81.9108, 36.9703],
            [-81.9005, 36.9572],
            [-81.9285, 36.9469],
            [-81.9786, 36.9061],
            [-81.9813, 36.8994],
            [-81.9684, 36.8972],
            [-81.9762, 36.8698],
            [-82.0211, 36.8589],
            [-81.9989, 36.8537],
            [-81.9771, 36.8321],
            [-81.8671, 36.8237],
            [-81.9253, 36.7489],
            [-81.8855, 36.7466],
            [-81.8553, 36.6903],
            [-81.8509, 36.6571],
            [-81.7714, 36.6611],
            [-81.7527, 36.6794],
            [-81.7031, 36.6648],
            [-81.6484, 36.6909],
            [-81.6191, 36.6343],
            [-81.5655, 36.6578],
            [-81.5259, 36.6559],
            [-81.5223, 36.5806],
            [-81.6801, 36.5855],
            [-81.677, 36.5707],
            [-81.6902, 36.5687],
            [-81.69, 36.5522],
            [-81.708, 36.5362],
            [-81.6953, 36.4679],
            [-81.7149, 36.4572],
            [-81.7207, 36.4225],
            [-81.7396, 36.4067],
            [-81.7329, 36.3765],
            [-81.7213, 36.3531],
            [-81.7078, 36.346],
            [-81.7074, 36.3352],
            [-81.731, 36.3412],
            [-81.7661, 36.3385],
            [-81.8008, 36.3581],
            [-81.8413, 36.3433],
            [-81.8794, 36.3138],
            [-81.9081, 36.302],
            [-82.0331, 36.1204],
            [-82.0541, 36.1268],
            [-82.0613, 36.1131],
            [-82.0801, 36.1057],
            [-82.1271, 36.1044],
            [-82.1479, 36.1495],
            [-82.2139, 36.1591],
            [-82.2604, 36.1337],
            [-82.2895, 36.1357],
            [-82.3552, 36.1156],
            [-82.4095, 36.0834],
            [-82.4607, 36.0078],
            [-82.5497, 35.9643],
            [-82.6109, 35.9674],
            [-82.5949, 36.0296],
            [-82.6372, 36.0658],
            [-82.7501, 36.006],
            [-82.8219, 35.9218],
            [-82.8526, 35.9491],
            [-82.8985, 35.9451],
            [-82.9119, 35.9216],
            [-82.8997, 35.8746],
            [-82.9641, 35.79],
            [-83.0015, 35.7738],
            [-83.074, 35.79],
            [-83.1202, 35.7662],
            [-83.1592, 35.7649],
            [-83.1857, 35.7299],
            [-83.2407, 35.7268],
            [-83.2892, 35.6745],
            [-83.3128, 35.6548],
            [-83.335, 35.6655],
            [-83.4216, 35.6112],
            [-83.4458, 35.6118],
            [-83.4983, 35.563],
            [-83.5856, 35.5629],
            [-83.6089, 35.5795],
            [-83.7717, 35.5621],
            [-83.8256, 35.5238],
            [-83.8801, 35.5187],
            [-83.9118, 35.476],
            [-83.9611, 35.4641],
            [-84.0206, 35.4098],
            [-84.0076, 35.3717],
            [-84.0529, 35.3443],
            [-84.1117, 35.3654],
            [-84.1347, 35.3598],
            [-84.0997, 35.4369],
            [-84.1266, 35.4713],
            [-84.1617, 35.455],
            [-84.2255, 35.4678],
            [-84.2484, 35.4748],
            [-84.2417, 35.5137],
            [-84.2927, 35.5681],
            [-84.3025, 35.6162],
            [-84.3585, 35.5711],
            [-84.3898, 35.5669],
            [-84.4228, 35.5284],
            [-84.4533, 35.5335],
            [-84.4762, 35.499],
            [-84.6012, 35.4956],
            [-84.6031, 35.5261],
            [-84.6537, 35.5321],
            [-84.6898, 35.5776],
            [-84.671, 35.602],
            [-84.7554, 35.6514],
            [-84.7721, 35.6498],
            [-84.782, 35.6377],
            [-84.7804, 35.6801],
            [-84.7636, 35.7015],
            [-84.7523, 35.6978],
            [-84.7472, 35.6782],
            [-84.7232, 35.6897],
            [-84.7134, 35.7361],
            [-84.7239, 35.7529],
            [-84.7329, 35.7486],
            [-84.7528, 35.7725],
            [-84.7382, 35.7781],
            [-84.7893, 35.8144],
            [-84.7981, 35.8419],
            [-84.8395, 35.849],
            [-84.8587, 35.8889],
            [-84.8456, 35.8933],
            [-84.879, 35.9024],
            [-84.8841, 35.8896],
            [-84.8985, 35.8857],
            [-84.913, 35.8916],
            [-84.892, 35.9097],
            [-84.9061, 35.9093],
            [-84.9198, 35.9192],
            [-84.9081, 35.9331],
            [-84.8872, 35.932],
            [-84.8887, 35.9124],
            [-84.8606, 35.9574],
            [-84.8461, 35.9565],
            [-84.8519, 35.9857],
            [-84.8224, 35.9986],
            [-84.8503, 36.0077],
            [-84.832, 36.0196],
            [-84.831, 36.0433],
            [-84.8941, 36.0474],
            [-84.8936, 36.0551],
            [-84.8664, 36.0555],
            [-84.8382, 36.0465],
            [-84.791, 36.0577],
            [-84.792, 36.0594],
            [-84.8055, 36.0906],
            [-84.811, 36.087],
            [-84.8243, 36.0947],
            [-84.8324, 36.084],
            [-84.8709, 36.1369],
            [-84.9062, 36.1561],
            [-84.9336, 36.1462],
            [-84.937, 36.1548],
            [-84.9537, 36.1556],
            [-84.959, 36.1478],
            [-84.9701, 36.158],
            [-84.9531, 36.1657],
            [-84.9432, 36.1627],
            [-84.9421, 36.1784],
            [-84.924, 36.1963],
            [-84.9237, 36.2147],
            [-84.9135, 36.2228],
            [-84.9115, 36.2435],
            [-84.9007, 36.2497],
            [-84.9042, 36.265],
            [-84.8826, 36.2743],
            [-84.8658, 36.2931],
            [-84.8434, 36.287],
            [-84.8227, 36.2994],
            [-84.8139, 36.2935],
            [-84.7961, 36.2976],
            [-84.7954, 36.3091],
            [-84.782, 36.3193],
            [-84.7867, 36.3253],
            [-84.7741, 36.3347],
            [-84.7605, 36.3277],
            [-84.7399, 36.3365],
            [-84.7454, 36.3512],
            [-84.7269, 36.3527],
            [-84.7324, 36.3659],
            [-84.7246, 36.3764],
            [-84.7056, 36.3682],
            [-84.6905, 36.3741],
            [-84.6855, 36.3882],
            [-84.6591, 36.3953],
            [-84.6683, 36.4065],
            [-84.6644, 36.413],
            [-84.6744, 36.4138],
            [-84.6926, 36.4356],
            [-84.7151, 36.4989],
            [-84.7853, 36.6034],
            [-85.2641, 36.6264],
            [-85.2667, 36.6502],
            [-85.2489, 36.6634],
            [-85.2456, 36.6846],
            [-85.2264, 36.6937],
            [-85.2354, 36.6905],
            [-85.2558, 36.7218],
            [-85.2314, 36.7974],
            [-85.2131, 36.8052],
            [-85.2266, 36.8145],
            [-85.2155, 36.8545],
            [-85.2435, 36.8575],
            [-85.2467, 36.8769],
            [-85.2752, 36.8978],
            [-85.232, 36.9251],
            [-85.218, 36.9607],
            [-85.211, 36.9594],
            [-85.2057, 36.9967],
            [-85.1733, 37.0272],
            [-85.1819, 37.0299],
            [-85.1903, 37.0528],
            [-85.1776, 37.0597],
            [-85.2169, 37.0713],
            [-85.1971, 37.0816],
            [-85.176, 37.0789],
            [-85.1724, 37.0869],
            [-85.1306, 37.0892],
            [-85.1195, 37.0993],
            [-85.1235, 37.1075],
            [-85.1046, 37.108],
            [-85.118, 37.1236],
            [-85.1055, 37.1486],
            [-85.0909, 37.1565],
            [-85.0911, 37.1886],
            [-85.1224, 37.1827],
            [-85.129, 37.1919],
            [-85.1134, 37.2032],
            [-85.0908, 37.1947],
            [-85.0878, 37.2121],
            [-85.0544, 37.211],
            [-85.057, 37.2571],
            [-85.1471, 37.3042],
            [-85.1378, 37.329],
            [-85.1203, 37.3631],
            [-85.1365, 37.3269],
            [-85.1271, 37.3221],
            [-85.0911, 37.371],
            [-85.072, 37.3778],
            [-85.0379, 37.4072],
            [-85.0448, 37.4122],
            [-85.0436, 37.4418],
            [-84.9993, 37.4461],
            [-84.9754, 37.4566],
            [-84.9639, 37.4716],
            [-84.9198, 37.4649],
            [-84.9205, 37.4512],
            [-84.9466, 37.4349],
            [-84.9267, 37.3798],
            [-84.9364, 37.3668],
            [-84.9168, 37.3519],
            [-84.8973, 37.3567],
            [-84.8913, 37.3759],
            [-84.8687, 37.3772],
            [-84.863, 37.4007],
            [-84.8337, 37.3891],
            [-84.8337, 37.4156],
            [-84.7961, 37.3905],
            [-84.7266, 37.3621],
            [-84.7469, 37.345],
            [-84.7639, 37.3636],
            [-84.7949, 37.3402],
            [-84.8165, 37.3345],
            [-84.7865, 37.3213],
            [-84.7725, 37.2992],
            [-84.7369, 37.2853],
            [-84.7227, 37.2823],
            [-84.6924, 37.3115],
            [-84.6868, 37.3305],
            [-84.6727, 37.331],
            [-84.6781, 37.31],
            [-84.6745, 37.305],
            [-84.665, 37.3111],
            [-84.6478, 37.2903],
            [-84.5741, 37.3432],
            [-84.5545, 37.3262],
            [-84.5394, 37.3282],
            [-84.538, 37.3054],
            [-84.5168, 37.327],
            [-84.501, 37.3251],
            [-84.2962, 37.1643],
          ],
          [
            [-84.6361, 37.2592],
            [-84.6306, 37.253],
            [-84.5825, 37.2568],
            [-84.5773, 37.2739],
            [-84.5915, 37.2705],
            [-84.6315, 37.2902],
            [-84.6361, 37.2592],
          ],
          [
            [-84.2962, 37.1643],
            [-84.289, 37.1517],
            [-84.292, 37.161],
            [-84.2962, 37.1643],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Chattanooga Mkt": {
    type: "Feature",
    properties: { code: "TN_CHA", name: "Chattanooga Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-86.4346, 34.4485],
            [-86.4663, 34.4705],
            [-86.4437, 34.4585],
            [-86.4346, 34.4485],
          ],
        ],
        [
          [
            [-84.7819, 35.6375],
            [-84.7554, 35.6514],
            [-84.671, 35.602],
            [-84.6898, 35.5776],
            [-84.6537, 35.5321],
            [-84.6031, 35.5261],
            [-84.6012, 35.4956],
            [-84.4762, 35.499],
            [-84.4533, 35.5335],
            [-84.4228, 35.5284],
            [-84.3898, 35.5669],
            [-84.3585, 35.5711],
            [-84.3025, 35.6162],
            [-84.2927, 35.5681],
            [-84.2417, 35.5137],
            [-84.2484, 35.4748],
            [-84.2255, 35.4678],
            [-84.1617, 35.455],
            [-84.1266, 35.4713],
            [-84.0997, 35.4369],
            [-84.1347, 35.3598],
            [-84.1117, 35.3654],
            [-84.0529, 35.3443],
            [-84.0294, 35.3332],
            [-84.0214, 35.3014],
            [-84.0095, 35.273],
            [-84.0021, 35.2337],
            [-83.9588, 35.2169],
            [-83.9013, 35.2385],
            [-83.7952, 35.2574],
            [-83.7569, 35.2425],
            [-83.7085, 35.2576],
            [-83.6904, 35.2348],
            [-83.7289, 35.2006],
            [-83.7381, 35.1559],
            [-83.6598, 35.1602],
            [-83.6452, 35.1444],
            [-83.6178, 35.1056],
            [-83.567, 35.071],
            [-83.5104, 35.0052],
            [-83.4834, 34.9935],
            [-83.6202, 34.9921],
            [-83.62, 34.9866],
            [-84.6215, 34.9881],
            [-84.6227, 34.8571],
            [-84.5822, 34.8246],
            [-84.641, 34.7781],
            [-84.6571, 34.7289],
            [-84.6544, 34.5489],
            [-84.6218, 34.4686],
            [-84.6807, 34.4768],
            [-84.7326, 34.4709],
            [-84.7914, 34.506],
            [-84.7934, 34.4586],
            [-84.8513, 34.4344],
            [-84.8831, 34.4501],
            [-84.9342, 34.4332],
            [-84.9542, 34.4098],
            [-85.0021, 34.4333],
            [-85.0477, 34.4013],
            [-85.1071, 34.4157],
            [-85.1386, 34.4533],
            [-85.0974, 34.5789],
            [-85.1077, 34.5875],
            [-85.1721, 34.4541],
            [-85.2141, 34.5076],
            [-85.2462, 34.4543],
            [-85.2414, 34.4083],
            [-85.3359, 34.3689],
            [-85.3874, 34.2861],
            [-85.4332, 34.2863],
            [-85.462, 34.2864],
            [-85.4193, 34.0672],
            [-85.4244, 34.0473],
            [-85.4403, 34.043],
            [-85.4569, 34.0495],
            [-85.4947, 34.0306],
            [-85.4911, 34.0578],
            [-85.501, 34.0543],
            [-85.503, 34.06],
            [-85.4876, 34.0631],
            [-85.4936, 34.0732],
            [-85.5404, 34.0531],
            [-85.5551, 34.0689],
            [-85.565, 34.0654],
            [-85.5736, 34.0712],
            [-85.6067, 34.0633],
            [-85.6302, 34.0708],
            [-85.6366, 34.0587],
            [-85.6691, 34.1027],
            [-85.6839, 34.1033],
            [-85.6987, 34.1019],
            [-85.7131, 34.0812],
            [-85.7126, 34.0701],
            [-85.7366, 34.076],
            [-85.7451, 34.0691],
            [-85.7568, 34.0716],
            [-85.7325, 34.0482],
            [-85.7265, 34.0592],
            [-85.7112, 34.06],
            [-85.7297, 34.0465],
            [-85.7764, 34.0486],
            [-85.8055, 34.0606],
            [-85.8096, 34.0484],
            [-85.7714, 34.0106],
            [-85.8065, 33.9978],
            [-85.7895, 33.9718],
            [-85.7975, 33.9542],
            [-85.766, 33.9467],
            [-85.7937, 33.9469],
            [-85.8063, 33.9602],
            [-85.8279, 33.957],
            [-85.8371, 33.9662],
            [-85.858, 33.9615],
            [-85.8537, 33.9501],
            [-85.8997, 33.943],
            [-85.9112, 33.9064],
            [-85.9183, 33.898],
            [-85.9172, 33.8566],
            [-85.9446, 33.8768],
            [-85.9762, 33.8813],
            [-85.9868, 33.8721],
            [-85.995, 33.8796],
            [-86.0213, 33.8515],
            [-86.0467, 33.8526],
            [-86.0653, 33.8422],
            [-86.0514, 33.8252],
            [-86.0613, 33.8141],
            [-86.1165, 33.8046],
            [-86.1154, 33.8117],
            [-86.1499, 33.8025],
            [-86.1491, 33.7952],
            [-86.1704, 33.7977],
            [-86.2746, 33.761],
            [-86.2892, 33.7217],
            [-86.3069, 33.6999],
            [-86.3083, 33.6921],
            [-86.2959, 33.6851],
            [-86.324, 33.677],
            [-86.3302, 33.6674],
            [-86.3365, 33.6721],
            [-86.3305, 33.6837],
            [-86.3553, 33.693],
            [-86.3425, 33.7124],
            [-86.3655, 33.6844],
            [-86.3806, 33.6977],
            [-86.3274, 33.7546],
            [-86.3408, 33.7635],
            [-86.3762, 33.7624],
            [-86.338, 33.7939],
            [-86.3518, 33.7879],
            [-86.3716, 33.8006],
            [-86.3403, 33.8262],
            [-86.3333, 33.8237],
            [-86.3595, 33.8342],
            [-86.3236, 33.8642],
            [-86.3251, 33.874],
            [-86.3388, 33.8786],
            [-86.3234, 33.8861],
            [-86.3412, 33.8916],
            [-86.3465, 33.9147],
            [-86.3238, 33.9344],
            [-86.3256, 33.9401],
            [-86.3702, 33.9398],
            [-86.3906, 33.9654],
            [-86.4006, 33.9671],
            [-86.3587, 34.0093],
            [-86.3585, 34.0615],
            [-86.374, 34.0551],
            [-86.4333, 34.0599],
            [-86.4284, 34.0836],
            [-86.4504, 34.086],
            [-86.4552, 34.1003],
            [-86.472, 34.1018],
            [-86.4875, 34.1289],
            [-86.4594, 34.1746],
            [-86.4148, 34.2122],
            [-86.4369, 34.2395],
            [-86.4405, 34.2137],
            [-86.4931, 34.203],
            [-86.4936, 34.221],
            [-86.4751, 34.2338],
            [-86.4897, 34.2401],
            [-86.4529, 34.2593],
            [-86.4421, 34.3044],
            [-86.4258, 34.3101],
            [-86.4312, 34.3206],
            [-86.4109, 34.3232],
            [-86.4157, 34.328],
            [-86.4029, 34.3414],
            [-86.4235, 34.3682],
            [-86.4364, 34.3737],
            [-86.424, 34.3906],
            [-86.4311, 34.409],
            [-86.4148, 34.4264],
            [-86.4346, 34.4485],
            [-86.4125, 34.433],
            [-86.375, 34.4226],
            [-86.336, 34.4338],
            [-86.3319, 34.4484],
            [-86.2996, 34.4581],
            [-86.2727, 34.457],
            [-86.2673, 34.4638],
            [-86.2777, 34.4591],
            [-86.2754, 34.4637],
            [-86.2644, 34.4661],
            [-86.2206, 34.4701],
            [-86.2211, 34.4602],
            [-86.2382, 34.4474],
            [-86.2218, 34.4431],
            [-86.185, 34.49],
            [-86.1277, 34.5339],
            [-86.1196, 34.5245],
            [-86.1098, 34.5286],
            [-86.1049, 34.5188],
            [-86.1172, 34.5179],
            [-86.1218, 34.5246],
            [-86.1445, 34.4985],
            [-86.1513, 34.5043],
            [-86.1541, 34.4888],
            [-86.1636, 34.4885],
            [-86.1629, 34.4954],
            [-86.1714, 34.4903],
            [-86.1755, 34.4843],
            [-86.1685, 34.4802],
            [-86.1796, 34.4795],
            [-86.19, 34.4541],
            [-86.1788, 34.4509],
            [-86.1746, 34.4405],
            [-86.1412, 34.4542],
            [-86.1078, 34.4533],
            [-86.0908, 34.4457],
            [-86.0577, 34.476],
            [-86.0038, 34.48],
            [-85.9601, 34.5022],
            [-85.9367, 34.5032],
            [-85.9341, 34.5281],
            [-85.8628, 34.5807],
            [-85.8738, 34.6022],
            [-85.8567, 34.6045],
            [-85.8745, 34.6339],
            [-85.8248, 34.6455],
            [-85.7885, 34.6635],
            [-85.7724, 34.6605],
            [-85.7669, 34.6482],
            [-85.704, 34.7298],
            [-85.7119, 34.7434],
            [-85.7287, 34.7426],
            [-85.7239, 34.756],
            [-85.7493, 34.7506],
            [-85.7453, 34.7805],
            [-85.7856, 34.7783],
            [-85.791, 34.7907],
            [-85.7844, 34.8097],
            [-85.7767, 34.8108],
            [-85.7794, 34.8358],
            [-85.6918, 34.9245],
            [-85.6954, 34.9855],
            [-86.5785, 34.9911],
            [-86.5809, 35.0035],
            [-86.5995, 35.0168],
            [-86.6024, 35.0334],
            [-86.6172, 35.0412],
            [-86.6075, 35.0421],
            [-86.6085, 35.0612],
            [-86.6271, 35.0575],
            [-86.6317, 35.0698],
            [-86.6571, 35.0613],
            [-86.6359, 35.0881],
            [-86.6552, 35.0899],
            [-86.6863, 35.1239],
            [-86.6967, 35.1196],
            [-86.6887, 35.1052],
            [-86.7248, 35.083],
            [-86.7633, 35.0993],
            [-86.7707, 35.1128],
            [-86.7785, 35.1057],
            [-86.7684, 35.0975],
            [-86.7742, 35.0918],
            [-86.7822, 35.1158],
            [-86.7788, 35.159],
            [-86.7736, 35.186],
            [-86.7904, 35.1981],
            [-86.7521, 35.1922],
            [-86.741, 35.2072],
            [-86.701, 35.2077],
            [-86.6838, 35.218],
            [-86.6596, 35.2164],
            [-86.6598, 35.2215],
            [-86.6325, 35.2223],
            [-86.6294, 35.2132],
            [-86.6195, 35.2259],
            [-86.6087, 35.2222],
            [-86.6097, 35.2302],
            [-86.5965, 35.2293],
            [-86.5896, 35.2447],
            [-86.5663, 35.2347],
            [-86.5649, 35.2577],
            [-86.5828, 35.2773],
            [-86.5795, 35.2902],
            [-86.531, 35.3187],
            [-86.5273, 35.3115],
            [-86.4614, 35.2928],
            [-86.4614, 35.2846],
            [-86.4368, 35.2752],
            [-86.4154, 35.2983],
            [-86.4211, 35.3216],
            [-86.4144, 35.3245],
            [-86.3942, 35.3131],
            [-86.379, 35.3212],
            [-86.4007, 35.3282],
            [-86.4027, 35.338],
            [-86.3809, 35.3363],
            [-86.3658, 35.3232],
            [-86.3728, 35.3434],
            [-86.362, 35.3441],
            [-86.3497, 35.3314],
            [-86.3408, 35.3415],
            [-86.3469, 35.3455],
            [-86.3386, 35.3536],
            [-86.3387, 35.3807],
            [-86.3588, 35.3754],
            [-86.3621, 35.3802],
            [-86.3293, 35.3955],
            [-86.329, 35.4061],
            [-86.2999, 35.3979],
            [-86.3039, 35.3695],
            [-86.2575, 35.3709],
            [-86.2535, 35.3843],
            [-86.2799, 35.3875],
            [-86.2714, 35.3944],
            [-86.2832, 35.4089],
            [-86.2734, 35.4109],
            [-86.303, 35.4434],
            [-86.3022, 35.4823],
            [-86.2849, 35.4942],
            [-86.2687, 35.4837],
            [-86.245, 35.5092],
            [-86.241, 35.5381],
            [-86.2221, 35.5349],
            [-86.2154, 35.5412],
            [-86.2295, 35.5557],
            [-86.2294, 35.5707],
            [-86.2008, 35.5566],
            [-86.2314, 35.5765],
            [-86.1926, 35.5741],
            [-86.168, 35.591],
            [-86.1511, 35.6224],
            [-86.0979, 35.6267],
            [-86.0889, 35.6468],
            [-86.0532, 35.644],
            [-86.0338, 35.6525],
            [-86.0395, 35.6762],
            [-86.0185, 35.6762],
            [-86.0256, 35.6864],
            [-86.0134, 35.6877],
            [-86.0181, 35.7269],
            [-85.9993, 35.7321],
            [-86.0012, 35.7123],
            [-85.9896, 35.7114],
            [-85.9868, 35.7283],
            [-85.9642, 35.7199],
            [-85.9545, 35.687],
            [-85.9311, 35.6716],
            [-85.9078, 35.6762],
            [-85.8981, 35.6662],
            [-85.8864, 35.6701],
            [-85.8617, 35.657],
            [-85.8426, 35.6693],
            [-85.8346, 35.6644],
            [-85.8383, 35.6559],
            [-85.8192, 35.6613],
            [-85.8128, 35.6554],
            [-85.8841, 35.6167],
            [-85.8399, 35.5987],
            [-85.8345, 35.5733],
            [-85.8236, 35.5653],
            [-85.8291, 35.5503],
            [-85.8217, 35.552],
            [-85.7959, 35.5286],
            [-85.7913, 35.5179],
            [-85.8165, 35.5136],
            [-85.7938, 35.4855],
            [-85.7983, 35.476],
            [-85.7884, 35.4808],
            [-85.7811, 35.4724],
            [-85.7399, 35.4681],
            [-85.7378, 35.492],
            [-85.6735, 35.5176],
            [-85.6297, 35.4623],
            [-85.6157, 35.4792],
            [-85.5994, 35.4841],
            [-85.6054, 35.4929],
            [-85.5698, 35.4944],
            [-85.5606, 35.5016],
            [-85.5673, 35.5069],
            [-85.5345, 35.5212],
            [-85.5247, 35.5337],
            [-85.4944, 35.5339],
            [-85.4922, 35.5476],
            [-85.4595, 35.5476],
            [-85.4535, 35.5581],
            [-85.4238, 35.5674],
            [-85.2653, 35.7214],
            [-85.2703, 35.7475],
            [-85.259, 35.7518],
            [-85.2655, 35.7608],
            [-85.1873, 35.7676],
            [-85.1798, 35.7842],
            [-85.1777, 35.7679],
            [-85.1034, 35.7699],
            [-85.0738, 35.7605],
            [-85.041, 35.7716],
            [-85.0307, 35.7466],
            [-84.9897, 35.8352],
            [-84.9834, 35.8299],
            [-84.9918, 35.8036],
            [-84.9864, 35.7929],
            [-84.9557, 35.786],
            [-84.9622, 35.7997],
            [-84.9794, 35.7981],
            [-84.9842, 35.805],
            [-84.9662, 35.8353],
            [-84.9462, 35.8453],
            [-84.9344, 35.8769],
            [-84.9197, 35.8583],
            [-84.9283, 35.8325],
            [-84.915, 35.8345],
            [-84.8803, 35.8084],
            [-84.8497, 35.8182],
            [-84.8661, 35.8492],
            [-84.853, 35.8643],
            [-84.8395, 35.849],
            [-84.7981, 35.8419],
            [-84.7893, 35.8144],
            [-84.7382, 35.7781],
            [-84.7528, 35.7725],
            [-84.7329, 35.7486],
            [-84.7239, 35.7529],
            [-84.7119, 35.725],
            [-84.7211, 35.6917],
            [-84.7406, 35.6795],
            [-84.7516, 35.6805],
            [-84.7523, 35.6978],
            [-84.7663, 35.7001],
            [-84.7816, 35.677],
            [-84.7776, 35.6476],
            [-84.7819, 35.6375],
          ],
          [
            [-84.7819, 35.6375],
            [-84.7837, 35.6356],
            [-84.7832, 35.6346],
            [-84.7819, 35.6375],
          ],
          [
            [-84.7832, 35.6346],
            [-84.7863, 35.6275],
            [-84.7778, 35.6234],
            [-84.7754, 35.6341],
            [-84.7807, 35.6291],
            [-84.7832, 35.6346],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Greenville Mkt": {
    type: "Feature",
    properties: { code: "SC_GRE", name: "Greenville Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-81.4262, 32.7684],
            [-81.4118, 32.7647],
            [-81.4078, 32.7552],
            [-81.4167, 32.7526],
            [-81.4262, 32.7684],
          ],
        ],
        [
          [
            [-81.429, 32.7855],
            [-81.4251, 32.7976],
            [-81.427, 32.7876],
            [-81.4226, 32.7826],
            [-81.429, 32.7855],
          ],
        ],
        [
          [
            [-81.8844, 35.7428],
            [-81.8616, 35.7434],
            [-81.856, 35.7298],
            [-81.8444, 35.7385],
            [-81.8558, 35.7275],
            [-81.8844, 35.7428],
          ],
        ],
        [
          [
            [-84.0076, 35.3717],
            [-84.0206, 35.4098],
            [-83.9611, 35.4641],
            [-83.9118, 35.476],
            [-83.8801, 35.5187],
            [-83.8256, 35.5238],
            [-83.7717, 35.5621],
            [-83.6089, 35.5795],
            [-83.5856, 35.5629],
            [-83.4983, 35.563],
            [-83.4458, 35.6118],
            [-83.4216, 35.6112],
            [-83.335, 35.6655],
            [-83.3128, 35.6548],
            [-83.2892, 35.6745],
            [-83.2407, 35.7268],
            [-83.1857, 35.7299],
            [-83.1592, 35.7649],
            [-83.1202, 35.7662],
            [-83.074, 35.79],
            [-83.0015, 35.7738],
            [-82.9641, 35.79],
            [-82.8997, 35.8746],
            [-82.9119, 35.9216],
            [-82.8985, 35.9451],
            [-82.8526, 35.9491],
            [-82.8219, 35.9218],
            [-82.7501, 36.006],
            [-82.6372, 36.0658],
            [-82.5949, 36.0296],
            [-82.6109, 35.9674],
            [-82.5497, 35.9643],
            [-82.4607, 36.0078],
            [-82.4095, 36.0834],
            [-82.3552, 36.1156],
            [-82.2895, 36.1357],
            [-82.2604, 36.1337],
            [-82.2139, 36.1591],
            [-82.1479, 36.1495],
            [-82.1271, 36.1044],
            [-82.0804, 36.1057],
            [-82.0706, 36.0686],
            [-82.0388, 36.0079],
            [-82.0554, 35.9991],
            [-82.0588, 35.9873],
            [-82.0377, 35.9475],
            [-82.041, 35.9574],
            [-82.0263, 35.9645],
            [-82.0343, 35.9423],
            [-82.0136, 35.9543],
            [-82.005, 35.9453],
            [-81.9894, 35.9489],
            [-81.9995, 35.9223],
            [-81.9817, 35.9115],
            [-81.9545, 35.9267],
            [-81.963, 35.9417],
            [-81.9433, 35.9594],
            [-81.9148, 35.9163],
            [-81.9066, 35.8834],
            [-81.9196, 35.8481],
            [-81.9353, 35.8374],
            [-81.9286, 35.8372],
            [-81.9289, 35.8278],
            [-81.9384, 35.8063],
            [-81.9023, 35.7717],
            [-81.8929, 35.773],
            [-81.8707, 35.757],
            [-81.874, 35.7506],
            [-81.8898, 35.7525],
            [-81.8819, 35.747],
            [-81.8941, 35.7407],
            [-81.8694, 35.7196],
            [-81.8418, 35.6314],
            [-81.8551, 35.6063],
            [-81.8471, 35.5964],
            [-81.8553, 35.5931],
            [-81.8658, 35.5661],
            [-81.858, 35.5712],
            [-81.8516, 35.5659],
            [-81.8563, 35.5405],
            [-81.9736, 35.5246],
            [-82.0028, 35.5518],
            [-82.0226, 35.5371],
            [-82.0681, 35.5371],
            [-82.1279, 35.5237],
            [-82.102, 35.5132],
            [-82.1127, 35.5031],
            [-82.1053, 35.4996],
            [-82.1027, 35.4781],
            [-82.1149, 35.4432],
            [-82.1093, 35.4318],
            [-82.1202, 35.4324],
            [-82.1144, 35.4202],
            [-82.1751, 35.4217],
            [-82.1958, 35.4009],
            [-82.1848, 35.3965],
            [-82.1685, 35.408],
            [-82.151, 35.4071],
            [-82.1084, 35.3822],
            [-82.0798, 35.353],
            [-82.0778, 35.3427],
            [-82.0532, 35.3347],
            [-82.0736, 35.3245],
            [-82.0706, 35.3153],
            [-82.0574, 35.3217],
            [-82.0561, 35.3001],
            [-82.0709, 35.3018],
            [-82.0801, 35.2931],
            [-82.0343, 35.2754],
            [-82.0293, 35.2824],
            [-81.9927, 35.2553],
            [-82.0014, 35.1885],
            [-81.6096, 35.1743],
            [-81.6187, 35.1677],
            [-81.6079, 35.1571],
            [-81.6147, 35.1508],
            [-81.5714, 35.1146],
            [-81.5726, 35.0853],
            [-81.534, 35.051],
            [-81.4966, 35.0408],
            [-81.4808, 35.0073],
            [-81.4848, 34.9973],
            [-81.4761, 34.9813],
            [-81.4924, 34.9468],
            [-81.4738, 34.9359],
            [-81.4703, 34.9138],
            [-81.4788, 34.8984],
            [-81.4688, 34.8657],
            [-81.4533, 34.8467],
            [-81.4618, 34.831],
            [-81.4879, 34.8151],
            [-81.4806, 34.8084],
            [-81.469, 34.811],
            [-81.4592, 34.7975],
            [-81.4528, 34.7628],
            [-81.4883, 34.733],
            [-81.4541, 34.7209],
            [-81.4542, 34.6752],
            [-81.4183, 34.6233],
            [-81.4659, 34.6273],
            [-81.5278, 34.6093],
            [-81.5708, 34.5737],
            [-81.5848, 34.5739],
            [-81.6204, 34.6024],
            [-81.6646, 34.6146],
            [-81.6631, 34.5724],
            [-81.7159, 34.5909],
            [-81.7122, 34.5225],
            [-81.6729, 34.4947],
            [-81.7041, 34.4536],
            [-81.6592, 34.4253],
            [-81.657, 34.3955],
            [-81.6383, 34.3851],
            [-81.69, 34.3932],
            [-81.7491, 34.3669],
            [-81.7605, 34.3179],
            [-81.7916, 34.2985],
            [-81.8174, 34.2487],
            [-81.8791, 34.3064],
            [-81.9487, 34.2788],
            [-81.9156, 34.2406],
            [-81.9291, 34.2066],
            [-81.9205, 34.192],
            [-81.8718, 34.0676],
            [-81.9257, 34.0636],
            [-81.967, 34.0488],
            [-81.9689, 34.0147],
            [-81.932, 33.9769],
            [-81.9001, 33.9756],
            [-81.8626, 33.9099],
            [-81.7799, 33.9209],
            [-81.7355, 33.907],
            [-81.7418, 33.8745],
            [-81.7068, 33.8288],
            [-81.6518, 33.8144],
            [-81.7405, 33.7461],
            [-81.7179, 33.6918],
            [-81.6106, 33.7143],
            [-81.5859, 33.7532],
            [-81.5303, 33.7542],
            [-81.5416, 33.7009],
            [-81.5292, 33.6412],
            [-81.5411, 33.5998],
            [-81.4657, 33.5387],
            [-81.4355, 33.5368],
            [-81.3446, 33.4809],
            [-81.2969, 33.4796],
            [-81.2533, 33.4459],
            [-81.2067, 33.4377],
            [-81.189, 33.411],
            [-81.1852, 33.397],
            [-81.2132, 33.3979],
            [-81.2208, 33.2592],
            [-81.1674, 33.2437],
            [-81.1534, 33.2728],
            [-81.0991, 33.22],
            [-81.0936, 33.1688],
            [-81.1178, 33.1448],
            [-81.1465, 33.0778],
            [-81.0987, 33.0475],
            [-81.0826, 33.0264],
            [-81.2196, 32.9534],
            [-81.2221, 32.9338],
            [-81.2358, 32.9402],
            [-81.2361, 32.9203],
            [-81.2224, 32.9123],
            [-81.2269, 32.9047],
            [-81.2446, 32.9079],
            [-81.2767, 32.8921],
            [-81.2679, 32.8606],
            [-81.2827, 32.8627],
            [-81.2987, 32.8534],
            [-81.3108, 32.8644],
            [-81.3311, 32.8591],
            [-81.3455, 32.8748],
            [-81.3698, 32.8743],
            [-81.379, 32.8535],
            [-81.4199, 32.8411],
            [-81.4131, 32.8127],
            [-81.4248, 32.8026],
            [-81.4216, 32.8352],
            [-81.4436, 32.8506],
            [-81.4539, 32.8741],
            [-81.4794, 32.8811],
            [-81.4641, 32.8978],
            [-81.4792, 32.9056],
            [-81.4832, 32.9218],
            [-81.5024, 32.9354],
            [-81.4994, 32.945],
            [-81.4947, 32.979],
            [-81.4923, 33.0093],
            [-81.5112, 33.0278],
            [-81.5443, 33.0469],
            [-81.557, 33.0451],
            [-81.5605, 33.0552],
            [-81.5729, 33.0542],
            [-81.5885, 33.0709],
            [-81.5992, 33.0718],
            [-81.5982, 33.0811],
            [-81.6098, 33.0822],
            [-81.6127, 33.094],
            [-81.6423, 33.0932],
            [-81.7046, 33.1165],
            [-81.7446, 33.1215],
            [-81.7636, 33.1152],
            [-81.7761, 33.1259],
            [-81.7826, 33.1202],
            [-81.7841, 33.081],
            [-81.8195, 33.0908],
            [-81.8288, 33.0598],
            [-81.8038, 33.0412],
            [-81.8101, 33.03],
            [-81.839, 33.015],
            [-81.8715, 33.0266],
            [-81.8992, 33.0085],
            [-81.9123, 33.0045],
            [-81.9238, 32.9996],
            [-81.936, 32.9745],
            [-81.9535, 32.9744],
            [-81.9625, 32.9536],
            [-81.9563, 32.9238],
            [-81.8577, 32.954],
            [-81.8285, 32.9423],
            [-81.8193, 32.946],
            [-81.8146, 32.937],
            [-81.7839, 32.9284],
            [-81.7677, 32.9094],
            [-81.7895, 32.8605],
            [-81.8097, 32.8587],
            [-81.8313, 32.8748],
            [-81.8543, 32.8763],
            [-81.8378, 32.883],
            [-81.8369, 32.8954],
            [-81.8218, 32.9085],
            [-81.8865, 32.9036],
            [-81.8921, 32.9088],
            [-81.9081, 32.8932],
            [-81.9233, 32.8958],
            [-81.931, 32.9064],
            [-81.9355, 32.8953],
            [-81.9496, 32.8919],
            [-81.9456, 32.8718],
            [-81.9613, 32.8701],
            [-81.9667, 32.9108],
            [-81.9998, 32.9101],
            [-82.0078, 32.9206],
            [-82.0817, 32.9166],
            [-82.131, 32.9547],
            [-82.1718, 32.9694],
            [-82.195, 32.9685],
            [-82.1969, 33.0159],
            [-82.2476, 33.0407],
            [-82.244, 33.0571],
            [-82.2667, 33.0623],
            [-82.279, 33.0874],
            [-82.2818, 33.1065],
            [-82.2984, 33.1123],
            [-82.3082, 33.1266],
            [-82.3225, 33.114],
            [-82.3538, 33.1111],
            [-82.3406, 33.1052],
            [-82.3478, 33.0932],
            [-82.358, 33.1037],
            [-82.3918, 33.1137],
            [-82.3989, 33.1371],
            [-82.4038, 33.1204],
            [-82.4102, 33.1233],
            [-82.4168, 33.1482],
            [-82.4373, 33.1478],
            [-82.4347, 33.1314],
            [-82.4491, 33.1245],
            [-82.4229, 33.1015],
            [-82.4419, 33.0933],
            [-82.448, 33.0824],
            [-82.4709, 33.0935],
            [-82.4722, 33.1137],
            [-82.4638, 33.1212],
            [-82.4726, 33.1248],
            [-82.4994, 33.1016],
            [-82.5659, 33.1035],
            [-82.559, 33.0756],
            [-82.5579, 33.0851],
            [-82.5496, 33.0872],
            [-82.5312, 33.0773],
            [-82.5239, 33.0801],
            [-82.5202, 33.0699],
            [-82.5003, 33.0612],
            [-82.5134, 33.0548],
            [-82.523, 33.0603],
            [-82.5393, 33.0399],
            [-82.5508, 33.04],
            [-82.5634, 33.0735],
            [-82.581, 33.0759],
            [-82.6035, 33.0445],
            [-82.615, 33.063],
            [-82.6285, 33.065],
            [-82.6271, 33.073],
            [-82.6414, 33.0843],
            [-82.6378, 33.1002],
            [-82.6472, 33.1019],
            [-82.6617, 33.1294],
            [-82.7023, 33.146],
            [-82.721, 33.1714],
            [-82.7395, 33.1744],
            [-82.7411, 33.1964],
            [-82.733, 33.2049],
            [-82.7484, 33.2383],
            [-82.7782, 33.2382],
            [-82.8315, 33.202],
            [-82.8568, 33.1942],
            [-82.8558, 33.2017],
            [-82.8669, 33.2067],
            [-82.8866, 33.2017],
            [-82.9096, 33.2056],
            [-82.9027, 33.2281],
            [-82.8917, 33.2353],
            [-82.9078, 33.2426],
            [-82.8518, 33.2367],
            [-82.858, 33.2575],
            [-82.8384, 33.2571],
            [-82.8426, 33.2647],
            [-82.8275, 33.2677],
            [-82.7896, 33.2946],
            [-82.7884, 33.2747],
            [-82.7758, 33.2649],
            [-82.7499, 33.2981],
            [-82.7367, 33.3187],
            [-82.7528, 33.3178],
            [-82.753, 33.3108],
            [-82.7687, 33.3087],
            [-82.7711, 33.2954],
            [-82.7825, 33.2966],
            [-82.7837, 33.3093],
            [-82.7959, 33.3161],
            [-82.7905, 33.3193],
            [-82.7995, 33.3305],
            [-82.8001, 33.3621],
            [-82.82, 33.374],
            [-82.8183, 33.3878],
            [-82.8278, 33.3931],
            [-82.8227, 33.426],
            [-82.8466, 33.4361],
            [-82.8641, 33.4646],
            [-82.8363, 33.4979],
            [-82.8246, 33.5021],
            [-82.8199, 33.5191],
            [-82.7488, 33.5108],
            [-82.7245, 33.5539],
            [-82.7046, 33.5885],
            [-82.6698, 33.6042],
            [-82.5822, 33.652],
            [-82.5436, 33.6386],
            [-82.5072, 33.66],
            [-82.5958, 33.8291],
            [-82.573, 33.8583],
            [-82.5795, 33.8924],
            [-82.5545, 33.9438],
            [-82.5946, 34.0287],
            [-82.644, 34.0722],
            [-82.6773, 34.1317],
            [-82.7175, 34.1505],
            [-82.7419, 34.2101],
            [-82.7467, 34.2664],
            [-82.7803, 34.2967],
            [-82.7941, 34.3398],
            [-82.835, 34.3661],
            [-82.8607, 34.4574],
            [-82.9027, 34.4859],
            [-83.0029, 34.4721],
            [-83.0872, 34.5159],
            [-83.1229, 34.5601],
            [-83.1703, 34.5924],
            [-83.2318, 34.6113],
            [-83.2553, 34.6377],
            [-83.3387, 34.682],
            [-83.3532, 34.7286],
            [-83.3201, 34.7596],
            [-83.3239, 34.7897],
            [-83.2682, 34.8214],
            [-83.2375, 34.8771],
            [-83.1211, 34.9391],
            [-83.1085, 35.0008],
            [-83.4834, 34.9935],
            [-83.5104, 35.0052],
            [-83.567, 35.071],
            [-83.6178, 35.1056],
            [-83.6452, 35.1444],
            [-83.6598, 35.1602],
            [-83.7381, 35.1559],
            [-83.7289, 35.2006],
            [-83.6904, 35.2348],
            [-83.7085, 35.2576],
            [-83.7569, 35.2425],
            [-83.7952, 35.2574],
            [-83.9013, 35.2385],
            [-83.9343, 35.223],
            [-83.9671, 35.218],
            [-84.0038, 35.236],
            [-84.0095, 35.273],
            [-84.0214, 35.3014],
            [-84.0294, 35.3332],
            [-84.0076, 35.3717],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Columbia Mkt": {
    type: "Feature",
    properties: { code: "SC_COL", name: "Columbia Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-79.4565, 33.4817],
          [-79.4494, 33.4821],
          [-79.4565, 33.4811],
          [-79.4572, 33.4662],
          [-79.4402, 33.4637],
          [-79.4512, 33.4058],
          [-79.4688, 33.3915],
          [-79.4796, 33.3987],
          [-79.4669, 33.3731],
          [-79.4973, 33.3818],
          [-79.5084, 33.3781],
          [-79.5119, 33.3661],
          [-79.5032, 33.3737],
          [-79.4836, 33.3687],
          [-79.4998, 33.368],
          [-79.4963, 33.3571],
          [-79.515, 33.3644],
          [-79.5279, 33.3573],
          [-79.5241, 33.3488],
          [-79.5402, 33.3472],
          [-79.5759, 33.3267],
          [-79.5702, 33.3108],
          [-79.5854, 33.2961],
          [-79.5623, 33.2733],
          [-79.6049, 33.2794],
          [-79.7096, 33.3212],
          [-79.7348, 33.3202],
          [-79.7562, 33.3297],
          [-79.7842, 33.3761],
          [-79.8066, 33.3727],
          [-79.8368, 33.3911],
          [-79.861, 33.4095],
          [-79.8613, 33.4178],
          [-79.8782, 33.4252],
          [-79.8682, 33.4345],
          [-79.8941, 33.4537],
          [-79.9031, 33.4504],
          [-79.9641, 33.4982],
          [-79.9898, 33.4983],
          [-79.997, 33.5069],
          [-80.0018, 33.4948],
          [-80.0121, 33.5052],
          [-80.0205, 33.4984],
          [-80.0239, 33.5063],
          [-80.0417, 33.5009],
          [-80.0799, 33.5069],
          [-80.0849, 33.4999],
          [-80.0934, 33.5033],
          [-80.0948, 33.4958],
          [-80.1253, 33.5025],
          [-80.1277, 33.4929],
          [-80.1522, 33.4851],
          [-80.1318, 33.4635],
          [-80.1442, 33.4641],
          [-80.1396, 33.4539],
          [-80.1507, 33.4603],
          [-80.1483, 33.4499],
          [-80.1611, 33.4488],
          [-80.1635, 33.4604],
          [-80.1725, 33.4489],
          [-80.181, 33.4546],
          [-80.1817, 33.4485],
          [-80.1991, 33.4434],
          [-80.1974, 33.4509],
          [-80.2074, 33.4526],
          [-80.204, 33.4419],
          [-80.2224, 33.4478],
          [-80.2252, 33.4093],
          [-80.216, 33.395],
          [-80.2299, 33.3916],
          [-80.2302, 33.3841],
          [-80.2385, 33.3912],
          [-80.2527, 33.306],
          [-80.2856, 33.2978],
          [-80.2809, 33.282],
          [-80.3116, 33.2646],
          [-80.3522, 33.2775],
          [-80.3183, 33.2521],
          [-80.3277, 33.2442],
          [-80.3089, 33.2101],
          [-80.3088, 33.1904],
          [-80.3308, 33.1984],
          [-80.3354, 33.2245],
          [-80.36, 33.2565],
          [-80.3919, 33.2646],
          [-80.4271, 33.2578],
          [-80.4846, 33.28],
          [-80.5028, 33.3345],
          [-80.542, 33.3136],
          [-80.5212, 33.2882],
          [-80.5474, 33.2914],
          [-80.5682, 33.2804],
          [-80.5777, 33.2946],
          [-80.6795, 33.2403],
          [-80.7058, 33.2613],
          [-80.6974, 33.2664],
          [-80.7032, 33.2903],
          [-80.7196, 33.2862],
          [-80.7403, 33.3108],
          [-80.7559, 33.3103],
          [-80.7637, 33.3179],
          [-80.7783, 33.3131],
          [-80.7764, 33.3244],
          [-80.7978, 33.3353],
          [-80.8268, 33.3272],
          [-80.8273, 33.3333],
          [-80.8453, 33.3313],
          [-80.8474, 33.3178],
          [-80.8607, 33.3094],
          [-80.8559, 33.2674],
          [-80.8825, 33.2793],
          [-80.8837, 33.2482],
          [-80.8689, 33.2373],
          [-80.8964, 33.2342],
          [-80.8878, 33.2262],
          [-80.8958, 33.2148],
          [-80.8885, 33.2013],
          [-80.913, 33.1912],
          [-80.8979, 33.1813],
          [-80.9027, 33.1703],
          [-80.9221, 33.1621],
          [-80.9297, 33.1754],
          [-80.9385, 33.1749],
          [-80.9568, 33.1593],
          [-80.9367, 33.1295],
          [-80.9367, 33.1009],
          [-80.917, 33.0791],
          [-80.9246, 33.0727],
          [-80.9165, 33.0535],
          [-80.8835, 33.0303],
          [-80.8673, 32.9996],
          [-80.8976, 32.9804],
          [-80.9047, 32.9974],
          [-80.9255, 32.991],
          [-80.94, 33.0151],
          [-80.9679, 33.0138],
          [-80.976, 33.0239],
          [-80.9988, 33.0142],
          [-81.0094, 33.0176],
          [-81.0233, 33.0054],
          [-81.02, 32.9946],
          [-81.0494, 32.9978],
          [-81.053, 32.9896],
          [-81.0557, 33.0018],
          [-81.0987, 33.0475],
          [-81.1465, 33.0778],
          [-81.1178, 33.1448],
          [-81.0936, 33.1688],
          [-81.0991, 33.22],
          [-81.1534, 33.2728],
          [-81.1674, 33.2437],
          [-81.2208, 33.2592],
          [-81.2132, 33.3979],
          [-81.1852, 33.397],
          [-81.189, 33.411],
          [-81.2067, 33.4377],
          [-81.2533, 33.4459],
          [-81.2969, 33.4796],
          [-81.3446, 33.4809],
          [-81.4355, 33.5368],
          [-81.4657, 33.5387],
          [-81.5411, 33.5998],
          [-81.5292, 33.6412],
          [-81.5416, 33.7009],
          [-81.5303, 33.7542],
          [-81.5859, 33.7532],
          [-81.6106, 33.7143],
          [-81.7179, 33.6918],
          [-81.7405, 33.7461],
          [-81.6518, 33.8144],
          [-81.7068, 33.8288],
          [-81.7418, 33.8745],
          [-81.7355, 33.907],
          [-81.7799, 33.9209],
          [-81.8626, 33.9099],
          [-81.9001, 33.9756],
          [-81.932, 33.9769],
          [-81.9689, 34.0147],
          [-81.967, 34.0488],
          [-81.9257, 34.0636],
          [-81.8718, 34.0676],
          [-81.9205, 34.192],
          [-81.9291, 34.2066],
          [-81.9156, 34.2406],
          [-81.9487, 34.2788],
          [-81.8791, 34.3064],
          [-81.8174, 34.2487],
          [-81.7916, 34.2985],
          [-81.7605, 34.3179],
          [-81.7491, 34.3669],
          [-81.69, 34.3932],
          [-81.6383, 34.3851],
          [-81.657, 34.3955],
          [-81.6592, 34.4253],
          [-81.7041, 34.4536],
          [-81.6729, 34.4947],
          [-81.7122, 34.5225],
          [-81.7159, 34.5909],
          [-81.6631, 34.5724],
          [-81.6646, 34.6146],
          [-81.6204, 34.6024],
          [-81.5848, 34.5739],
          [-81.5708, 34.5737],
          [-81.5278, 34.6093],
          [-81.4659, 34.6273],
          [-81.4183, 34.6233],
          [-81.4542, 34.6752],
          [-81.4541, 34.7209],
          [-81.4883, 34.733],
          [-81.4531, 34.7609],
          [-81.4504, 34.7876],
          [-81.4239, 34.7717],
          [-81.4332, 34.7701],
          [-81.4151, 34.7448],
          [-81.3744, 34.7442],
          [-81.387, 34.7127],
          [-81.3389, 34.6937],
          [-81.3214, 34.6517],
          [-81.3241, 34.6249],
          [-81.3518, 34.6079],
          [-81.4201, 34.5951],
          [-81.4221, 34.572],
          [-81.2451, 34.5638],
          [-81.2399, 34.5712],
          [-81.2531, 34.5987],
          [-81.2472, 34.6156],
          [-81.2153, 34.6072],
          [-81.2068, 34.585],
          [-81.193, 34.5819],
          [-81.1912, 34.6034],
          [-81.1791, 34.6074],
          [-81.1776, 34.6371],
          [-81.147, 34.6373],
          [-81.133, 34.6464],
          [-81.1126, 34.6378],
          [-81.1168, 34.6279],
          [-81.1091, 34.6237],
          [-81.0904, 34.6302],
          [-81.0438, 34.6108],
          [-81.0191, 34.6101],
          [-80.9976, 34.5945],
          [-80.9741, 34.5898],
          [-80.9947, 34.6042],
          [-80.9741, 34.6138],
          [-80.9641, 34.5962],
          [-80.948, 34.5975],
          [-80.9627, 34.6101],
          [-80.9356, 34.6282],
          [-80.9504, 34.665],
          [-80.9425, 34.6666],
          [-80.9319, 34.6633],
          [-80.9307, 34.6355],
          [-80.9233, 34.6477],
          [-80.8984, 34.6446],
          [-80.8835, 34.5854],
          [-80.8714, 34.5919],
          [-80.8575, 34.5822],
          [-80.8468, 34.5841],
          [-80.8413, 34.5946],
          [-80.7974, 34.5921],
          [-80.7664, 34.6216],
          [-80.7328, 34.6218],
          [-80.7194, 34.632],
          [-80.7206, 34.6488],
          [-80.6887, 34.6523],
          [-80.6662, 34.6375],
          [-80.6692, 34.6526],
          [-80.6543, 34.6585],
          [-80.663, 34.6732],
          [-80.6433, 34.6841],
          [-80.6302, 34.6754],
          [-80.6344, 34.6884],
          [-80.594, 34.6927],
          [-80.5805, 34.6792],
          [-80.5738, 34.684],
          [-80.5614, 34.6771],
          [-80.5534, 34.6829],
          [-80.5446, 34.679],
          [-80.5362, 34.69],
          [-80.5155, 34.6907],
          [-80.4896, 34.7204],
          [-80.4708, 34.687],
          [-80.4317, 34.6599],
          [-80.4352, 34.6426],
          [-80.4084, 34.6148],
          [-80.4707, 34.591],
          [-80.4397, 34.5691],
          [-80.4422, 34.5773],
          [-80.4231, 34.5859],
          [-80.4206, 34.5783],
          [-80.398, 34.5767],
          [-80.3925, 34.5699],
          [-80.3779, 34.574],
          [-80.3717, 34.5459],
          [-80.3567, 34.5337],
          [-80.3236, 34.5455],
          [-80.3232, 34.5543],
          [-80.2959, 34.5582],
          [-80.2967, 34.5666],
          [-80.2364, 34.5733],
          [-80.2313, 34.6093],
          [-80.218, 34.6256],
          [-80.2293, 34.6382],
          [-80.2122, 34.635],
          [-80.2117, 34.6542],
          [-80.1995, 34.6506],
          [-80.1924, 34.6277],
          [-80.1706, 34.6417],
          [-80.1752, 34.6512],
          [-80.1474, 34.6533],
          [-80.1356, 34.6643],
          [-80.1241, 34.6546],
          [-80.1514, 34.6366],
          [-80.1176, 34.6488],
          [-80.1185, 34.6227],
          [-80.1061, 34.6252],
          [-80.0895, 34.6474],
          [-80.072, 34.6396],
          [-80.0669, 34.6358],
          [-80.0725, 34.6267],
          [-80.0609, 34.6373],
          [-80.0559, 34.6328],
          [-80.0262, 34.6409],
          [-80.0195, 34.6609],
          [-80.0034, 34.6644],
          [-79.994, 34.684],
          [-80.0214, 34.7061],
          [-80.05, 34.7514],
          [-80.0338, 34.7937],
          [-80.0183, 34.8018],
          [-79.9998, 34.8036],
          [-80.0013, 34.8082],
          [-79.6753, 34.8047],
          [-78.5411, 33.8511],
          [-78.6723, 33.8176],
          [-78.8129, 33.7435],
          [-78.9381, 33.6398],
          [-79.0074, 33.5666],
          [-79.0285, 33.5334],
          [-79.0846, 33.4837],
          [-79.1387, 33.397],
          [-79.1373, 33.412],
          [-79.1526, 33.4195],
          [-79.1793, 33.419],
          [-79.2055, 33.3848],
          [-79.2253, 33.3646],
          [-79.2534, 33.3658],
          [-79.2116, 33.4236],
          [-79.1693, 33.4507],
          [-79.1401, 33.5039],
          [-79.1467, 33.5049],
          [-79.1443, 33.5163],
          [-79.1606, 33.522],
          [-79.1543, 33.5392],
          [-79.1711, 33.5407],
          [-79.1753, 33.548],
          [-79.1713, 33.5601],
          [-79.1629, 33.5613],
          [-79.1653, 33.5742],
          [-79.1381, 33.5929],
          [-79.1486, 33.5938],
          [-79.1455, 33.6031],
          [-79.1621, 33.6282],
          [-79.1616, 33.6535],
          [-79.1861, 33.6526],
          [-79.1878, 33.6319],
          [-79.2106, 33.6248],
          [-79.2306, 33.6223],
          [-79.2478, 33.63],
          [-79.2821, 33.5936],
          [-79.2943, 33.5722],
          [-79.3304, 33.5894],
          [-79.3582, 33.5844],
          [-79.362, 33.5892],
          [-79.3709, 33.5776],
          [-79.3767, 33.5801],
          [-79.3389, 33.6136],
          [-79.3591, 33.6357],
          [-79.3788, 33.6179],
          [-79.4059, 33.6127],
          [-79.4078, 33.6269],
          [-79.4245, 33.6384],
          [-79.435, 33.6174],
          [-79.4531, 33.617],
          [-79.4346, 33.5773],
          [-79.446, 33.5856],
          [-79.4614, 33.5724],
          [-79.4639, 33.5526],
          [-79.4396, 33.5202],
          [-79.469, 33.4866],
          [-79.4563, 33.4823],
          [-79.4565, 33.4817],
        ],
        [
          [-79.4739, 33.4576],
          [-79.4737, 33.458],
          [-79.4864, 33.4609],
          [-79.4739, 33.4576],
        ],
        [
          [-79.4565, 33.4817],
          [-79.4582, 33.4816],
          [-79.4614, 33.468],
          [-79.4565, 33.4817],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Atlanta Mkt": {
    type: "Feature",
    properties: { code: "GA_ATL", name: "Atlanta Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-83.822, 33.3993],
          [-83.8248, 33.3945],
          [-83.8314, 33.4006],
          [-83.8287, 33.415],
          [-83.8452, 33.4144],
          [-83.832, 33.3927],
          [-83.8571, 33.372],
          [-83.8542, 33.3673],
          [-83.8573, 33.3543],
          [-83.8626, 33.3413],
          [-83.8398, 33.3278],
          [-83.8427, 33.3183],
          [-83.8324, 33.273],
          [-83.8134, 33.2485],
          [-83.8343, 33.2095],
          [-83.8254, 33.2098],
          [-83.8123, 33.1988],
          [-83.8169, 33.1941],
          [-83.8076, 33.1921],
          [-83.8221, 33.1803],
          [-83.8425, 33.1967],
          [-83.903, 33.2017],
          [-83.9085, 33.2162],
          [-83.9215, 33.2165],
          [-83.9215, 33.2075],
          [-83.9335, 33.202],
          [-83.9936, 33.2023],
          [-83.9942, 33.161],
          [-84.0049, 33.1629],
          [-84.0321, 33.1499],
          [-84.019, 33.1363],
          [-84.0414, 33.1301],
          [-84.0437, 32.9685],
          [-84.0529, 32.9795],
          [-84.0468, 32.9646],
          [-84.0756, 32.9679],
          [-84.0739, 32.9756],
          [-84.081, 32.9741],
          [-84.0867, 32.9865],
          [-84.1077, 32.9942],
          [-84.1127, 32.9727],
          [-84.1214, 32.9901],
          [-84.1381, 32.9901],
          [-84.1361, 32.9696],
          [-84.2018, 32.9835],
          [-84.1946, 32.9482],
          [-84.1775, 32.939],
          [-84.1599, 32.9401],
          [-84.1588, 32.9343],
          [-84.183, 32.9058],
          [-84.206, 32.8938],
          [-84.2079, 32.861],
          [-84.1891, 32.8561],
          [-84.1708, 32.8178],
          [-84.2866, 32.7778],
          [-84.2882, 32.7634],
          [-84.2792, 32.7475],
          [-84.2718, 32.7505],
          [-84.2776, 32.7437],
          [-84.3014, 32.7613],
          [-84.3161, 32.7555],
          [-84.3251, 32.7624],
          [-84.3357, 32.7596],
          [-84.3511, 32.7829],
          [-84.3572, 32.7826],
          [-84.3556, 32.7736],
          [-84.3825, 32.7803],
          [-84.4212, 32.8388],
          [-84.4342, 32.8381],
          [-84.4427, 32.8252],
          [-84.4566, 32.8287],
          [-84.4673, 32.853],
          [-84.4901, 32.8569],
          [-84.4759, 32.8769],
          [-84.5099, 32.8837],
          [-84.5155, 32.9146],
          [-84.5395, 32.9185],
          [-84.5453, 32.9095],
          [-84.5543, 32.9168],
          [-84.5844, 32.8906],
          [-84.583, 32.8999],
          [-84.6017, 32.9015],
          [-84.6078, 32.9237],
          [-84.6175, 32.9186],
          [-84.6357, 32.9298],
          [-84.637, 32.924],
          [-84.6492, 32.9236],
          [-84.6479, 32.9319],
          [-84.6573, 32.9222],
          [-84.6579, 32.9365],
          [-84.6693, 32.9211],
          [-84.6845, 32.9182],
          [-84.6925, 32.9027],
          [-84.7152, 32.8968],
          [-84.7139, 32.9064],
          [-84.6942, 32.9222],
          [-84.6998, 32.9365],
          [-84.7316, 32.9354],
          [-84.7224, 32.9593],
          [-84.744, 32.9599],
          [-84.7657, 32.9413],
          [-84.7611, 32.9595],
          [-84.7752, 32.9601],
          [-84.8025, 32.9489],
          [-84.7904, 32.9383],
          [-84.7963, 32.9334],
          [-84.8219, 32.949],
          [-84.8325, 32.9418],
          [-84.8615, 32.9456],
          [-84.8616, 32.9332],
          [-84.9224, 32.9477],
          [-84.977, 32.919],
          [-84.9817, 32.9263],
          [-84.9957, 32.9263],
          [-85.0217, 32.9162],
          [-85.0701, 32.9203],
          [-85.0683, 32.9465],
          [-85.0829, 32.9607],
          [-85.1112, 32.9236],
          [-85.1224, 32.9308],
          [-85.1424, 32.9145],
          [-85.1536, 32.9225],
          [-85.1851, 32.9159],
          [-85.1961, 32.9264],
          [-85.2324, 33.1081],
          [-85.4621, 34.2864],
          [-85.4332, 34.2863],
          [-85.3874, 34.2861],
          [-85.3359, 34.3689],
          [-85.2414, 34.4083],
          [-85.2462, 34.4543],
          [-85.2141, 34.5076],
          [-85.1721, 34.4541],
          [-85.1077, 34.5875],
          [-85.0974, 34.5789],
          [-85.1386, 34.4533],
          [-85.1071, 34.4157],
          [-85.0477, 34.4013],
          [-85.0021, 34.4333],
          [-84.9542, 34.4098],
          [-84.9342, 34.4332],
          [-84.8831, 34.4501],
          [-84.8513, 34.4344],
          [-84.7934, 34.4586],
          [-84.7914, 34.506],
          [-84.7326, 34.4709],
          [-84.6807, 34.4768],
          [-84.6218, 34.4686],
          [-84.6544, 34.5489],
          [-84.6571, 34.7289],
          [-84.641, 34.7781],
          [-84.5822, 34.8246],
          [-84.6227, 34.8571],
          [-84.6215, 34.9883],
          [-83.62, 34.9866],
          [-83.6202, 34.9921],
          [-83.1085, 35.0008],
          [-83.1211, 34.9391],
          [-83.2375, 34.8771],
          [-83.2682, 34.8214],
          [-83.3239, 34.7897],
          [-83.3201, 34.7596],
          [-83.3532, 34.7286],
          [-83.3387, 34.682],
          [-83.2553, 34.6377],
          [-83.2318, 34.6113],
          [-83.1703, 34.5924],
          [-83.1229, 34.5601],
          [-83.0872, 34.5159],
          [-83.0029, 34.4721],
          [-82.9027, 34.4859],
          [-82.8607, 34.4574],
          [-82.835, 34.3661],
          [-82.7941, 34.3398],
          [-82.7803, 34.2967],
          [-82.7467, 34.2664],
          [-82.7419, 34.2101],
          [-82.7175, 34.1505],
          [-82.6773, 34.1317],
          [-82.644, 34.0722],
          [-82.5946, 34.0287],
          [-82.5545, 33.9438],
          [-82.5795, 33.8924],
          [-82.573, 33.8583],
          [-82.5958, 33.8291],
          [-82.5072, 33.66],
          [-82.5436, 33.6386],
          [-82.5822, 33.652],
          [-82.6698, 33.6042],
          [-82.7005, 33.5916],
          [-82.7245, 33.5539],
          [-82.7488, 33.5108],
          [-82.8199, 33.5191],
          [-82.8246, 33.5021],
          [-82.8624, 33.4686],
          [-82.8541, 33.4423],
          [-82.9396, 33.4427],
          [-82.9541, 33.4528],
          [-82.9842, 33.4477],
          [-82.9993, 33.4597],
          [-83.0107, 33.4352],
          [-83.0297, 33.4317],
          [-83.0427, 33.4131],
          [-83.0453, 33.4301],
          [-83.0549, 33.4285],
          [-83.0667, 33.4116],
          [-83.0791, 33.3801],
          [-83.0711, 33.3661],
          [-83.0787, 33.3402],
          [-83.0949, 33.3864],
          [-83.0917, 33.4088],
          [-83.1644, 33.355],
          [-83.1903, 33.3934],
          [-83.1972, 33.3959],
          [-83.2241, 33.3798],
          [-83.2124, 33.3999],
          [-83.2262, 33.4046],
          [-83.2438, 33.3985],
          [-83.2397, 33.4187],
          [-83.2573, 33.4561],
          [-83.2762, 33.466],
          [-83.2799, 33.4834],
          [-83.28, 33.4712],
          [-83.3067, 33.4738],
          [-83.3134, 33.4613],
          [-83.3222, 33.4627],
          [-83.3339, 33.4384],
          [-83.3669, 33.4652],
          [-83.3792, 33.4557],
          [-83.3918, 33.4632],
          [-83.5337, 33.4345],
          [-83.6189, 33.486],
          [-83.6338, 33.4631],
          [-83.6405, 33.4723],
          [-83.6494, 33.4628],
          [-83.6652, 33.4659],
          [-83.6666, 33.4368],
          [-83.6751, 33.4551],
          [-83.6961, 33.4475],
          [-83.7006, 33.4627],
          [-83.7345, 33.4815],
          [-83.7421, 33.4766],
          [-83.7251, 33.4627],
          [-83.7285, 33.4386],
          [-83.7431, 33.4297],
          [-83.7354, 33.4164],
          [-83.7905, 33.4061],
          [-83.8117, 33.385],
          [-83.822, 33.3993],
        ],
        [
          [-83.822, 33.3993],
          [-83.8185, 33.4053],
          [-83.8224, 33.3999],
          [-83.822, 33.3993],
        ],
        [
          [-83.8273, 33.4225],
          [-83.8246, 33.4371],
          [-83.816, 33.4366],
          [-83.8251, 33.4375],
          [-83.8273, 33.4225],
        ],
        [
          [-83.8287, 33.415],
          [-83.8284, 33.415],
          [-83.8273, 33.4225],
          [-83.8287, 33.415],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Miami Mkt": {
    type: "Feature",
    properties: { code: "FL_MIA", name: "Miami Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-80.6613, 24.8997],
            [-80.6217, 24.9443],
            [-80.5588, 24.9715],
            [-80.5441, 24.9999],
            [-80.4449, 25.093],
            [-80.3498, 25.2106],
            [-80.3287, 25.2865],
            [-80.2681, 25.3207],
            [-80.3514, 25.1906],
            [-80.3499, 25.1688],
            [-80.3998, 25.1085],
            [-80.4739, 25.0603],
            [-80.4948, 25.023],
            [-80.5658, 24.9582],
            [-80.5961, 24.9482],
            [-80.6613, 24.8997],
          ],
        ],
        [
          [
            [-81.8114, 24.5698],
            [-81.7941, 24.586],
            [-81.687, 24.5925],
            [-81.5829, 24.6587],
            [-81.5629, 24.6929],
            [-81.4519, 24.7145],
            [-81.3929, 24.7434],
            [-81.3604, 24.7088],
            [-81.3193, 24.7012],
            [-81.298, 24.6568],
            [-81.3951, 24.6211],
            [-81.4142, 24.6472],
            [-81.4704, 24.642],
            [-81.4986, 24.665],
            [-81.5186, 24.6203],
            [-81.6853, 24.5587],
            [-81.8103, 24.5447],
            [-81.8114, 24.5698],
          ],
        ],
        [
          [
            [-82.1644, 24.5634],
            [-82.1354, 24.5969],
            [-82.101, 24.5847],
            [-82.1168, 24.5491],
            [-82.1644, 24.5634],
          ],
        ],
        [
          [
            [-82.2644, 26.6985],
            [-82.2183, 26.6264],
            [-82.1775, 26.5023],
            [-82.0884, 26.4552],
            [-82.0626, 26.4701],
            [-82.0139, 26.4521],
            [-82.0829, 26.4221],
            [-82.1267, 26.4363],
            [-82.1864, 26.4892],
            [-82.2055, 26.5665],
            [-82.268, 26.6828],
            [-82.2644, 26.6985],
          ],
        ],
        [
          [
            [-82.3363, 26.9078],
            [-82.2977, 26.8928],
            [-82.2965, 26.9074],
            [-82.2585, 26.925],
            [-82.2528, 27.0329],
            [-81.5628, 27.0334],
            [-81.5624, 27.1228],
            [-81.4862, 27.1031],
            [-81.4099, 27.1208],
            [-81.3942, 27.0873],
            [-81.3555, 27.1149],
            [-81.334, 27.0913],
            [-81.272, 27.0873],
            [-81.2677, 27.1211],
            [-81.1683, 27.1217],
            [-81.1689, 27.2097],
            [-81.1891, 27.2522],
            [-81.1673, 27.2799],
            [-81.108, 27.2815],
            [-81.0809, 27.2358],
            [-81.0538, 27.2362],
            [-81.0538, 27.2986],
            [-81.0348, 27.3592],
            [-81.0685, 27.3824],
            [-81.1, 27.3807],
            [-81.1435, 27.4161],
            [-81.1433, 27.4433],
            [-81.2051, 27.4898],
            [-81.2028, 27.5471],
            [-81.1403, 27.6009],
            [-81.146, 27.7105],
            [-81.1651, 27.7422],
            [-81.1813, 27.8037],
            [-81.0233, 27.7334],
            [-81.0118, 27.7768],
            [-80.9702, 27.7556],
            [-80.9306, 27.7684],
            [-80.8884, 27.7449],
            [-80.8803, 27.7337],
            [-80.8732, 27.6423],
            [-80.778, 27.6432],
            [-80.7772, 27.5587],
            [-80.3213, 27.5574],
            [-80.2932, 27.5003],
            [-80.2537, 27.3798],
            [-80.1615, 27.1928],
            [-80.1596, 27.1633],
            [-80.0939, 27.0186],
            [-80.0314, 26.7963],
            [-80.0389, 26.5693],
            [-80.1096, 26.0872],
            [-80.128, 25.7722],
            [-80.1662, 25.7289],
            [-80.1977, 25.7444],
            [-80.2404, 25.7242],
            [-80.2671, 25.6518],
            [-80.3015, 25.6133],
            [-80.3021, 25.5676],
            [-80.3287, 25.5326],
            [-80.337, 25.4656],
            [-80.3068, 25.3844],
            [-80.3353, 25.3387],
            [-80.3741, 25.3174],
            [-80.386, 25.2883],
            [-80.4678, 25.2325],
            [-80.4953, 25.1995],
            [-80.5485, 25.2365],
            [-80.5892, 25.2238],
            [-80.6096, 25.1819],
            [-80.6884, 25.1647],
            [-80.7037, 25.1391],
            [-80.7429, 25.1426],
            [-80.7576, 25.1667],
            [-80.8265, 25.1605],
            [-80.8582, 25.1766],
            [-80.9001, 25.162],
            [-80.9006, 25.1397],
            [-80.9992, 25.1242],
            [-81.0799, 25.1188],
            [-81.1336, 25.1563],
            [-81.172, 25.2223],
            [-81.1481, 25.3328],
            [-81.1173, 25.355],
            [-81.1687, 25.4638],
            [-81.2082, 25.5049],
            [-81.254, 25.6382],
            [-81.2903, 25.6875],
            [-81.3289, 25.7172],
            [-81.3619, 25.7727],
            [-81.3527, 25.822],
            [-81.4175, 25.865],
            [-81.5277, 25.9015],
            [-81.5845, 25.8888],
            [-81.6446, 25.898],
            [-81.6848, 25.8472],
            [-81.7497, 25.9605],
            [-81.7624, 26.0061],
            [-81.8017, 26.0882],
            [-81.8331, 26.2945],
            [-81.8692, 26.3788],
            [-81.9117, 26.4272],
            [-82.009, 26.4841],
            [-82.0436, 26.5196],
            [-82.1057, 26.4839],
            [-82.112, 26.5409],
            [-82.1379, 26.6374],
            [-82.1816, 26.6817],
            [-82.1735, 26.7018],
            [-82.1258, 26.6995],
            [-82.093, 26.6656],
            [-82.0551, 26.8025],
            [-82.0591, 26.8766],
            [-82.0907, 26.8887],
            [-82.0763, 26.9583],
            [-82.108, 26.9577],
            [-82.1373, 26.9261],
            [-82.1752, 26.9169],
            [-82.1471, 26.7898],
            [-82.1787, 26.7726],
            [-82.2511, 26.7559],
            [-82.3363, 26.9078],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Lakeland Mkt": {
    type: "Feature",
    properties: { code: "FL_LAK", name: "Lakeland Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-81.3725, 29.1088],
            [-81.3726, 29.1088],
            [-81.3741, 29.1341],
            [-81.3652, 29.1363],
            [-81.3725, 29.1088],
          ],
        ],
        [
          [
            [-81.3741, 29.1341],
            [-81.4039, 29.1227],
            [-81.4036, 29.1269],
            [-81.3741, 29.1341],
          ],
        ],
        [
          [
            [-81.4039, 29.1227],
            [-81.4047, 29.112],
            [-81.4049, 29.1119],
            [-81.4045, 29.1224],
            [-81.4039, 29.1227],
          ],
        ],
        [
          [
            [-81.502, 29.0979],
            [-81.4852, 29.0893],
            [-81.5012, 29.0867],
            [-81.502, 29.0979],
          ],
        ],
        [
          [
            [-81.502, 29.0984],
            [-81.502, 29.0979],
            [-81.5037, 29.0987],
            [-81.502, 29.0984],
          ],
        ],
        [
          [
            [-81.502, 29.0984],
            [-81.5026, 29.1065],
            [-81.4411, 29.1076],
            [-81.4339, 29.115],
            [-81.4229, 29.109],
            [-81.4049, 29.1119],
            [-81.406, 29.0816],
            [-81.3774, 29.0618],
            [-81.3824, 29.0984],
            [-81.3762, 29.1093],
            [-81.3726, 29.1088],
            [-81.3726, 29.1085],
            [-81.3725, 29.1088],
            [-81.3545, 29.1065],
            [-81.3489, 29.1116],
            [-81.3426, 29.1185],
            [-81.3338, 29.1145],
            [-81.2899, 29.1304],
            [-81.2865, 29.1211],
            [-81.2772, 29.1272],
            [-81.2614, 29.1181],
            [-81.2556, 29.1237],
            [-81.2701, 29.1615],
            [-81.2641, 29.1689],
            [-81.2449, 29.1804],
            [-81.2052, 29.1843],
            [-81.2035, 29.1633],
            [-81.2288, 29.1598],
            [-81.2159, 29.1247],
            [-81.2276, 29.1207],
            [-81.2131, 29.1022],
            [-81.2273, 29.0924],
            [-81.2061, 29.0715],
            [-81.1979, 29.01],
            [-81.1853, 28.9801],
            [-81.1648, 28.9646],
            [-81.1614, 28.9309],
            [-81.1429, 28.9056],
            [-81.1342, 28.8999],
            [-81.1101, 28.9323],
            [-81.1006, 28.9123],
            [-81.0984, 28.9227],
            [-81.0795, 28.9264],
            [-81.0914, 28.9422],
            [-81.0791, 28.9374],
            [-81.0696, 28.9243],
            [-81.0692, 28.8841],
            [-81.0587, 28.8772],
            [-81.0498, 28.8833],
            [-81.0263, 28.8759],
            [-81.0172, 28.8602],
            [-81.0225, 28.8441],
            [-80.9904, 28.8419],
            [-80.9899, 28.8221],
            [-80.9647, 28.8117],
            [-80.9386, 28.8164],
            [-80.9126, 28.8322],
            [-80.9138, 28.864],
            [-80.8704, 28.8644],
            [-80.8841, 28.8997],
            [-80.8752, 28.9007],
            [-80.8773, 28.9108],
            [-80.8739, 28.9007],
            [-80.8533, 28.9008],
            [-80.7883, 28.7911],
            [-80.7322, 28.7912],
            [-80.5749, 28.5852],
            [-80.561, 28.5307],
            [-80.5251, 28.4595],
            [-80.5741, 28.4278],
            [-80.6034, 28.364],
            [-80.6042, 28.2577],
            [-80.59, 28.178],
            [-80.5477, 28.0488],
            [-80.3837, 27.74],
            [-80.3213, 27.5574],
            [-80.7772, 27.5587],
            [-80.778, 27.6432],
            [-80.8732, 27.6423],
            [-80.8803, 27.7337],
            [-80.8884, 27.7449],
            [-80.9306, 27.7684],
            [-80.9702, 27.7556],
            [-81.0118, 27.7768],
            [-81.0233, 27.7334],
            [-81.1813, 27.8037],
            [-81.1651, 27.7422],
            [-81.146, 27.7105],
            [-81.1403, 27.6009],
            [-81.2028, 27.5471],
            [-81.2051, 27.4898],
            [-81.1433, 27.4433],
            [-81.1435, 27.4161],
            [-81.1, 27.3807],
            [-81.0685, 27.3824],
            [-81.0348, 27.3592],
            [-81.0538, 27.2986],
            [-81.0538, 27.2362],
            [-81.0809, 27.2358],
            [-81.108, 27.2815],
            [-81.1673, 27.2799],
            [-81.1891, 27.2522],
            [-81.1689, 27.2097],
            [-81.1683, 27.1217],
            [-81.2677, 27.1211],
            [-81.272, 27.0873],
            [-81.334, 27.0913],
            [-81.3555, 27.1149],
            [-81.3942, 27.0873],
            [-81.4099, 27.1208],
            [-81.4862, 27.1031],
            [-81.5624, 27.1228],
            [-81.5628, 27.0334],
            [-82.2528, 27.0329],
            [-82.2585, 26.925],
            [-82.2965, 26.9074],
            [-82.2977, 26.8928],
            [-82.3363, 26.9078],
            [-82.3516, 26.9084],
            [-82.4457, 27.0606],
            [-82.477, 27.1412],
            [-82.5397, 27.2543],
            [-82.5976, 27.3358],
            [-82.6918, 27.4372],
            [-82.7145, 27.5004],
            [-82.6507, 27.5231],
            [-82.5706, 27.6089],
            [-82.5143, 27.7056],
            [-82.4823, 27.7426],
            [-82.432, 27.7681],
            [-82.3934, 27.8375],
            [-82.4139, 27.9014],
            [-82.4898, 27.9196],
            [-82.4898, 27.8226],
            [-82.5539, 27.8485],
            [-82.5299, 27.8775],
            [-82.5337, 27.933],
            [-82.5539, 27.967],
            [-82.6195, 27.9697],
            [-82.7205, 27.9558],
            [-82.7201, 27.9364],
            [-82.6712, 27.913],
            [-82.6281, 27.9104],
            [-82.5865, 27.8167],
            [-82.6227, 27.7799],
            [-82.6257, 27.727],
            [-82.6525, 27.7003],
            [-82.7136, 27.6987],
            [-82.7216, 27.6639],
            [-82.705, 27.6253],
            [-82.7331, 27.613],
            [-82.738, 27.7068],
            [-82.7831, 27.7838],
            [-82.8286, 27.8223],
            [-82.8491, 27.8632],
            [-82.8385, 27.9091],
            [-82.8055, 27.9602],
            [-82.7827, 28.0559],
            [-82.7813, 28.1276],
            [-82.8085, 28.1548],
            [-82.7978, 28.1878],
            [-82.7626, 28.219],
            [-82.7641, 28.2443],
            [-82.7328, 28.2919],
            [-82.7315, 28.3251],
            [-82.7061, 28.3681],
            [-82.7063, 28.4013],
            [-82.6778, 28.4344],
            [-82.6694, 28.5199],
            [-82.6541, 28.5908],
            [-82.6641, 28.6066],
            [-82.6455, 28.6976],
            [-82.6622, 28.7373],
            [-82.643, 28.7825],
            [-82.6529, 28.8309],
            [-82.6449, 28.8893],
            [-82.6889, 28.9056],
            [-82.7358, 28.9737],
            [-82.7606, 28.9931],
            [-82.7597, 29.0542],
            [-82.8169, 29.0762],
            [-82.8053, 29.1371],
            [-82.7936, 29.1379],
            [-82.805, 29.1408],
            [-82.7994, 29.1536],
            [-82.8114, 29.1571],
            [-82.8001, 29.1648],
            [-82.8031, 29.1696],
            [-82.7498, 29.1818],
            [-82.7361, 29.2017],
            [-82.6973, 29.2106],
            [-82.6748, 29.2449],
            [-82.6493, 29.232],
            [-82.6584, 29.2306],
            [-82.6378, 29.1968],
            [-82.6365, 29.1634],
            [-82.6098, 29.1818],
            [-82.5996, 29.1772],
            [-82.5936, 29.2074],
            [-82.5696, 29.2148],
            [-82.5416, 29.2148],
            [-82.5359, 29.2061],
            [-82.536, 29.2147],
            [-82.4032, 29.2156],
            [-82.4037, 29.2424],
            [-82.3832, 29.2348],
            [-82.383, 29.2424],
            [-82.3428, 29.2459],
            [-82.3496, 29.2966],
            [-82.3299, 29.2934],
            [-82.2916, 29.3064],
            [-82.2926, 29.2963],
            [-82.2676, 29.2723],
            [-82.2511, 29.2672],
            [-82.251, 29.2965],
            [-82.2416, 29.3093],
            [-82.2221, 29.3197],
            [-82.2123, 29.3232],
            [-82.2038, 29.2962],
            [-82.1952, 29.296],
            [-82.2046, 29.3124],
            [-82.1917, 29.3306],
            [-82.1594, 29.3309],
            [-82.154, 29.338],
            [-82.1517, 29.3229],
            [-82.1347, 29.3229],
            [-82.1362, 29.2959],
            [-82.1157, 29.2957],
            [-82.1237, 29.2886],
            [-82.1159, 29.2886],
            [-82.1149, 29.2777],
            [-82.103, 29.2775],
            [-82.0941, 29.2594],
            [-82.0712, 29.2586],
            [-82.054, 29.2415],
            [-82.0539, 29.2849],
            [-82.04, 29.2794],
            [-81.9818, 29.2814],
            [-82.0162, 29.2179],
            [-81.9866, 29.2149],
            [-81.9671, 29.239],
            [-81.9655, 29.2533],
            [-81.9214, 29.2876],
            [-81.8856, 29.3392],
            [-81.8802, 29.3279],
            [-81.8891, 29.2935],
            [-81.9146, 29.2922],
            [-81.9156, 29.2811],
            [-81.8977, 29.2813],
            [-81.9048, 29.2838],
            [-81.9001, 29.2866],
            [-81.8732, 29.2763],
            [-81.8316, 29.2971],
            [-81.8199, 29.2683],
            [-81.727, 29.269],
            [-81.7271, 29.2483],
            [-81.6895, 29.253],
            [-81.6477, 29.2447],
            [-81.6409, 29.165],
            [-81.6188, 29.1231],
            [-81.6286, 29.1161],
            [-81.6261, 29.108],
            [-81.6179, 29.117],
            [-81.6215, 29.0965],
            [-81.5684, 29.0861],
            [-81.5644, 29.068],
            [-81.5201, 29.0564],
            [-81.509, 29.0455],
            [-81.4808, 29.0712],
            [-81.4779, 29.0875],
            [-81.4792, 29.0935],
            [-81.502, 29.0984],
          ],
          [
            [-82.0162, 28.9601],
            [-82.0163, 28.9273],
            [-81.937, 28.916],
            [-81.937, 28.8869],
            [-81.8392, 28.894],
            [-81.8398, 28.9253],
            [-81.8319, 28.934],
            [-81.8429, 28.9685],
            [-81.836, 28.9913],
            [-81.8076, 28.9966],
            [-81.8083, 29.0083],
            [-81.8293, 29.0167],
            [-81.8349, 29.0376],
            [-81.7939, 29.0501],
            [-81.7907, 29.038],
            [-81.7805, 29.0368],
            [-81.7898, 29.0288],
            [-81.775, 29.0291],
            [-81.7821, 29.019],
            [-81.7596, 29.0192],
            [-81.7489, 29.0292],
            [-81.7449, 29.0501],
            [-81.7247, 29.0502],
            [-81.7719, 29.1516],
            [-81.7584, 29.1519],
            [-81.7584, 29.1667],
            [-81.8364, 29.1762],
            [-81.8442, 29.1648],
            [-81.8599, 29.1718],
            [-81.8573, 29.1598],
            [-81.8641, 29.1717],
            [-81.8726, 29.1678],
            [-81.8716, 29.1754],
            [-81.8905, 29.1832],
            [-81.8979, 29.1587],
            [-81.9153, 29.1531],
            [-81.9928, 29.1835],
            [-81.9826, 29.1592],
            [-81.9888, 29.142],
            [-81.9652, 29.1077],
            [-81.9675, 29.1024],
            [-81.9842, 29.1047],
            [-81.9906, 29.1207],
            [-81.9869, 29.095],
            [-81.9717, 29.0923],
            [-81.9522, 29.0678],
            [-81.9717, 29.0588],
            [-81.9625, 29.0348],
            [-81.983, 29.0326],
            [-81.9749, 29.0275],
            [-81.9881, 29.0147],
            [-81.9666, 29.0062],
            [-81.9767, 29.0062],
            [-81.9647, 29.0016],
            [-81.9766, 29.0003],
            [-81.9764, 28.9887],
            [-81.9539, 28.9951],
            [-81.9374, 28.9892],
            [-81.9625, 28.9856],
            [-81.9501, 28.9819],
            [-81.954, 28.972],
            [-81.9451, 28.9601],
            [-82.0162, 28.9601],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Grand Jct Mkt": {
    type: "Feature",
    properties: { code: "CO_GRA", name: "Grand Jct Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-110.3476, 36.9982],
            [-110.1263, 36.9985],
            [-110.1137, 36.985],
            [-110.1455, 36.964],
            [-110.1821, 36.9824],
            [-110.2355, 36.975],
            [-110.2332, 36.9851],
            [-110.2868, 36.9645],
            [-110.3068, 36.9296],
            [-110.3567, 36.9438],
            [-110.3328, 36.9694],
            [-110.3457, 36.9789],
            [-110.3476, 36.9982],
          ],
        ],
        [
          [
            [-110.2864, 37.285],
            [-110.3133, 37.3055],
            [-110.351, 37.284],
            [-110.4088, 37.2971],
            [-110.4298, 37.2703],
            [-110.523, 37.2549],
            [-110.5386, 37.2191],
            [-110.6216, 37.2246],
            [-110.6949, 37.2678],
            [-110.713, 37.2522],
            [-110.6641, 37.2362],
            [-110.6764, 37.2206],
            [-110.6681, 37.2101],
            [-110.7002, 37.1925],
            [-110.7477, 37.1756],
            [-110.7608, 37.172],
            [-110.8369, 37.1783],
            [-110.8542, 37.1677],
            [-110.8719, 37.1513],
            [-110.8646, 37.166],
            [-110.8774, 37.177],
            [-110.8849, 37.1744],
            [-110.9012, 37.1751],
            [-110.8887, 37.2003],
            [-110.8965, 37.2138],
            [-110.8757, 37.2275],
            [-110.887, 37.25],
            [-110.8609, 37.2603],
            [-110.8737, 37.287],
            [-110.8454, 37.2935],
            [-110.8364, 37.3042],
            [-110.8406, 37.3188],
            [-110.8676, 37.3285],
            [-110.8734, 37.3439],
            [-110.8655, 37.3504],
            [-110.8547, 37.3478],
            [-110.8007, 37.3213],
            [-110.7753, 37.3237],
            [-110.7581, 37.3336],
            [-110.7522, 37.352],
            [-110.7361, 37.3452],
            [-110.7223, 37.3732],
            [-110.7538, 37.404],
            [-110.7157, 37.4017],
            [-110.6992, 37.4307],
            [-110.7087, 37.4374],
            [-110.7255, 37.4356],
            [-110.7475, 37.458],
            [-110.7262, 37.4595],
            [-110.7192, 37.4814],
            [-110.6663, 37.4775],
            [-110.6409, 37.4852],
            [-110.6429, 37.4921],
            [-110.6673, 37.4957],
            [-110.6627, 37.5211],
            [-110.6363, 37.5542],
            [-110.6036, 37.5713],
            [-110.601, 37.6016],
            [-110.5663, 37.6159],
            [-110.5587, 37.642],
            [-110.5381, 37.6329],
            [-110.5139, 37.6566],
            [-110.4977, 37.6522],
            [-110.4841, 37.6817],
            [-110.4879, 37.7022],
            [-110.449, 37.7352],
            [-110.4465, 37.7586],
            [-110.4266, 37.7761],
            [-110.4631, 37.7719],
            [-110.4765, 37.8008],
            [-110.4703, 37.8091],
            [-110.4375, 37.8069],
            [-110.4312, 37.8368],
            [-110.4024, 37.8522],
            [-110.4046, 37.8799],
            [-110.3641, 37.8922],
            [-110.3074, 37.8906],
            [-110.2949, 37.886],
            [-110.2844, 37.8661],
            [-110.268, 37.8983],
            [-110.2106, 37.8931],
            [-110.1985, 37.9176],
            [-110.2024, 37.9465],
            [-110.1663, 37.9663],
            [-110.1622, 37.981],
            [-110.1367, 38.0054],
            [-110.1134, 38.0083],
            [-110.0926, 37.9947],
            [-110.0774, 37.9989],
            [-110.0759, 38.0231],
            [-110.0639, 38.043],
            [-110.0456, 38.0503],
            [-110.0472, 38.0706],
            [-110.0267, 38.0968],
            [-110.0113, 38.1093],
            [-109.9925, 38.0999],
            [-109.956, 38.13],
            [-109.9294, 38.1399],
            [-109.9317, 38.1576],
            [-109.8871, 38.1837],
            [-109.8864, 38.1899],
            [-109.9039, 38.1902],
            [-109.8884, 38.208],
            [-109.8989, 38.2068],
            [-109.92, 38.2204],
            [-109.9141, 38.2273],
            [-109.8884, 38.2297],
            [-109.8993, 38.2426],
            [-109.9177, 38.2415],
            [-109.9022, 38.256],
            [-109.9044, 38.267],
            [-109.9098, 38.2712],
            [-109.9264, 38.26],
            [-109.9349, 38.2625],
            [-109.9281, 38.2802],
            [-109.9491, 38.2904],
            [-109.9468, 38.3096],
            [-109.9637, 38.3206],
            [-109.9649, 38.3317],
            [-109.9481, 38.3384],
            [-109.9412, 38.3536],
            [-109.9521, 38.3547],
            [-109.9574, 38.3418],
            [-109.9677, 38.3412],
            [-109.9845, 38.3539],
            [-109.976, 38.3883],
            [-110.0147, 38.3643],
            [-110.0098, 38.3774],
            [-110.0332, 38.3878],
            [-110.0034, 38.4162],
            [-110.027, 38.4474],
            [-110.0397, 38.4463],
            [-110.0444, 38.4536],
            [-110.0334, 38.4597],
            [-110.014, 38.4464],
            [-110.0023, 38.4819],
            [-110.0254, 38.5],
            [-111.3059, 38.5],
            [-111.3055, 38.5858],
            [-111.2986, 38.75],
            [-111.3012, 39.4673],
            [-111.2473, 39.4672],
            [-111.2475, 39.8131],
            [-109.8847, 39.8057],
            [-109.8933, 39.8003],
            [-109.8757, 39.7931],
            [-109.8778, 39.7831],
            [-109.8955, 39.7909],
            [-109.9192, 39.7862],
            [-109.9211, 39.7991],
            [-109.9302, 39.796],
            [-109.9201, 39.7754],
            [-109.9304, 39.7481],
            [-109.9453, 39.7507],
            [-109.9545, 39.7324],
            [-109.9498, 39.7197],
            [-109.9606, 39.7234],
            [-109.9668, 39.7427],
            [-109.9937, 39.7364],
            [-109.9706, 39.7102],
            [-109.9937, 39.7028],
            [-110.0045, 39.6875],
            [-109.9864, 39.6827],
            [-109.9843, 39.6641],
            [-109.996, 39.6466],
            [-110.0109, 39.6453],
            [-109.9974, 39.6262],
            [-110.0109, 39.625],
            [-110.029, 39.6007],
            [-110.0187, 39.5864],
            [-110.038, 39.5667],
            [-110.0297, 39.5393],
            [-110.0476, 39.5332],
            [-110.0268, 39.5236],
            [-110.0146, 39.4916],
            [-110.0241, 39.4617],
            [-109.107, 39.462],
            [-109.0731, 39.4998],
            [-109.0514, 39.4977],
            [-109.0501, 41.0007],
            [-108.2506, 41.0001],
            [-107.0304, 41.0034],
            [-107.0536, 40.988],
            [-107.055, 40.9696],
            [-107.1075, 40.9349],
            [-107.1034, 40.9138],
            [-107.1194, 40.873],
            [-107.0927, 40.853],
            [-107.0938, 40.8465],
            [-107.1289, 40.8457],
            [-107.1372, 40.8384],
            [-107.1231, 40.8072],
            [-107.1348, 40.7434],
            [-107.1235, 40.7349],
            [-107.1194, 40.7031],
            [-107.1374, 40.6911],
            [-107.1484, 40.6594],
            [-107.1703, 40.6389],
            [-107.1721, 40.6084],
            [-107.2085, 40.5841],
            [-107.2221, 40.5411],
            [-107.2212, 40.522],
            [-107.1834, 40.5283],
            [-107.1549, 40.4881],
            [-107.1699, 40.4882],
            [-107.1676, 40.469],
            [-107.2003, 40.4379],
            [-107.1646, 40.4365],
            [-107.1719, 40.4261],
            [-107.1671, 40.4161],
            [-107.1468, 40.4105],
            [-107.1378, 40.3887],
            [-107.1052, 40.3556],
            [-107.1554, 40.3298],
            [-107.1645, 40.2808],
            [-107.0957, 40.2407],
            [-107.1014, 40.2251],
            [-107.0379, 40.2253],
            [-107.0318, 39.9189],
            [-107.1137, 39.9191],
            [-107.1135, 39.8668],
            [-107.093, 39.8737],
            [-107.0579, 39.8719],
            [-107.0565, 39.8637],
            [-107.0257, 39.849],
            [-106.9954, 39.8118],
            [-106.9128, 39.778],
            [-106.9034, 39.7857],
            [-106.8581, 39.7401],
            [-106.8472, 39.7482],
            [-106.83, 39.7372],
            [-106.826, 39.7461],
            [-106.8108, 39.7491],
            [-106.7845, 39.7189],
            [-106.7631, 39.7144],
            [-106.754, 39.7326],
            [-106.7397, 39.74],
            [-106.7234, 39.7367],
            [-106.6954, 39.7438],
            [-106.679, 39.7371],
            [-106.6712, 39.7532],
            [-106.6834, 39.7741],
            [-106.6748, 39.8145],
            [-106.6322, 39.8265],
            [-106.5892, 39.8005],
            [-106.553, 39.7917],
            [-106.5417, 39.7786],
            [-106.5236, 39.7795],
            [-106.4882, 39.766],
            [-106.4645, 39.7434],
            [-106.452, 39.7166],
            [-106.4375, 39.7089],
            [-106.394, 39.7194],
            [-106.3413, 39.7523],
            [-106.3185, 39.7287],
            [-106.2983, 39.7206],
            [-106.2903, 39.7036],
            [-106.2641, 39.7013],
            [-106.2586, 39.68],
            [-106.2387, 39.6803],
            [-106.2348, 39.662],
            [-106.1838, 39.6522],
            [-106.1761, 39.634],
            [-106.1776, 39.6091],
            [-106.209, 39.5994],
            [-106.2149, 39.5748],
            [-106.2057, 39.5582],
            [-106.1963, 39.5569],
            [-106.2099, 39.5469],
            [-106.2121, 39.5307],
            [-106.2249, 39.5314],
            [-106.2372, 39.5485],
            [-106.2558, 39.5322],
            [-106.2464, 39.5128],
            [-106.2596, 39.495],
            [-106.2456, 39.4897],
            [-106.2516, 39.465],
            [-106.2327, 39.4262],
            [-106.2304, 39.417],
            [-106.2067, 39.3796],
            [-106.2256, 39.3796],
            [-106.2395, 39.3657],
            [-106.2832, 39.3505],
            [-106.3124, 39.3623],
            [-106.3517, 39.3537],
            [-106.3708, 39.3606],
            [-106.3806, 39.3762],
            [-106.4001, 39.381],
            [-106.4335, 39.3542],
            [-106.4325, 39.3164],
            [-106.4687, 39.3079],
            [-106.4841, 39.3129],
            [-106.5016, 39.2998],
            [-106.504, 39.2848],
            [-106.483, 39.2724],
            [-106.484, 39.255],
            [-106.4987, 39.2349],
            [-106.4897, 39.228],
            [-106.4938, 39.1986],
            [-106.5111, 39.1635],
            [-106.5613, 39.1557],
            [-106.5633, 39.1073],
            [-106.578, 39.0579],
            [-106.2926, 39.0564],
            [-106.2909, 39.0642],
            [-106.3138, 39.0692],
            [-106.3041, 39.0747],
            [-106.3121, 39.0802],
            [-106.3173, 39.0738],
            [-106.3537, 39.0789],
            [-106.3737, 39.0708],
            [-106.3818, 39.0751],
            [-106.4059, 39.0618],
            [-106.4108, 39.0668],
            [-106.4006, 39.065],
            [-106.3672, 39.0999],
            [-106.3488, 39.107],
            [-106.3185, 39.1061],
            [-106.3301, 39.1271],
            [-106.3495, 39.1291],
            [-106.3341, 39.1438],
            [-106.3578, 39.1492],
            [-106.3539, 39.158],
            [-106.3199, 39.1642],
            [-106.3145, 39.129],
            [-106.2713, 39.0564],
            [-106.191, 39.0561],
            [-106.1825, 39.0386],
            [-106.193, 39.0177],
            [-106.1788, 39.0108],
            [-106.1691, 38.9913],
            [-106.1454, 38.9979],
            [-106.1095, 38.9859],
            [-106.111, 38.9401],
            [-106.0659, 38.9319],
            [-106.0281, 38.9428],
            [-105.9982, 38.9152],
            [-105.9857, 38.918],
            [-105.9682, 38.9078],
            [-105.9794, 38.8945],
            [-105.9743, 38.8825],
            [-105.9429, 38.8855],
            [-105.9253, 38.8718],
            [-105.9429, 38.8505],
            [-105.9079, 38.8063],
            [-105.9295, 38.7744],
            [-105.9425, 38.7685],
            [-105.9435, 38.7231],
            [-105.9697, 38.6936],
            [-105.2378, 38.6969],
            [-105.2404, 38.6476],
            [-105.0547, 38.6529],
            [-105.0117, 38.5766],
            [-105.0219, 38.5613],
            [-105.0122, 38.5703],
            [-105.0012, 38.5652],
            [-104.9615, 38.5727],
            [-104.9506, 38.5528],
            [-104.9419, 38.5681],
            [-104.9404, 38.2583],
            [-105.0492, 38.258],
            [-105.0498, 38.0178],
            [-105.081, 38.028],
            [-105.0965, 38.0669],
            [-105.1333, 38.0641],
            [-105.1371, 38.0392],
            [-105.1989, 38.0038],
            [-105.1988, 37.9902],
            [-105.2498, 37.9515],
            [-105.2805, 37.9012],
            [-105.3071, 37.9325],
            [-105.3245, 37.9384],
            [-105.4187, 37.8897],
            [-105.4403, 37.8975],
            [-105.4732, 37.896],
            [-105.5304, 37.9534],
            [-105.5603, 37.9519],
            [-105.5826, 37.9659],
            [-105.5819, 37.978],
            [-105.6046, 38.005],
            [-105.5988, 38.0229],
            [-105.6272, 38.0673],
            [-105.6613, 38.1005],
            [-105.6728, 38.1568],
            [-105.7881, 38.2424],
            [-105.7991, 38.2686],
            [-105.8583, 38.2984],
            [-105.8935, 38.3463],
            [-105.9968, 38.4203],
            [-106.019, 38.4544],
            [-106.0586, 38.4569],
            [-106.0755, 38.424],
            [-106.2469, 38.4228],
            [-106.2532, 38.4008],
            [-106.2396, 38.3812],
            [-106.2438, 38.3523],
            [-106.3152, 38.3425],
            [-106.3392, 38.2902],
            [-106.4308, 38.2813],
            [-106.4739, 38.3221],
            [-106.505, 38.3149],
            [-106.5546, 38.2292],
            [-106.5862, 38.1923],
            [-106.611, 38.1343],
            [-106.6723, 38.0867],
            [-106.7436, 38.0933],
            [-106.7667, 38.0802],
            [-106.8104, 38.0167],
            [-106.8174, 37.9559],
            [-106.8714, 37.9419],
            [-106.8814, 37.9622],
            [-106.968, 37.975],
            [-107.009, 37.9465],
            [-107.0678, 37.9613],
            [-107.1382, 37.9388],
            [-107.1389, 37.7847],
            [-107.1772, 37.7889],
            [-107.2135, 37.7378],
            [-107.2817, 37.7172],
            [-107.34, 37.7458],
            [-107.4185, 37.6977],
            [-107.4651, 37.6901],
            [-107.4822, 37.6395],
            [-107.6908, 37.6432],
            [-107.7109, 37.6176],
            [-107.7714, 37.6003],
            [-107.7827, 37.5219],
            [-107.7333, 37.5129],
            [-107.6749, 37.4696],
            [-107.6292, 37.4829],
            [-107.6174, 37.4569],
            [-107.6456, 37.3786],
            [-107.6306, 37.3277],
            [-107.6868, 37.3161],
            [-107.7288, 37.2807],
            [-107.7307, 37.2539],
            [-107.6785, 37.2236],
            [-107.6136, 37.2245],
            [-107.7255, 37.2143],
            [-107.7221, 37.1615],
            [-107.7764, 37.1316],
            [-107.8407, 37.1229],
            [-107.8459, 37.062],
            [-107.7837, 37.0568],
            [-107.7806, 37],
            [-109.0452, 36.9991],
            [-109.0648, 37.0481],
            [-109.0836, 37.0833],
            [-109.107, 37.1015],
            [-109.1137, 37.1322],
            [-109.1177, 37.1511],
            [-109.1475, 37.1345],
            [-109.1693, 37.1323],
            [-109.2255, 37.0793],
            [-109.3223, 37.0912],
            [-109.3544, 37.0422],
            [-109.3626, 36.9992],
            [-110.4702, 36.998],
            [-110.5439, 37.0039],
            [-110.5672, 37.1035],
            [-110.5575, 37.2172],
            [-110.5234, 37.2185],
            [-110.5035, 37.2539],
            [-110.4611, 37.2626],
            [-110.4213, 37.2384],
            [-110.4066, 37.2927],
            [-110.3497, 37.2831],
            [-110.3137, 37.3049],
            [-110.2864, 37.285],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Hutchinson Mkt": {
    type: "Feature",
    properties: { code: "KS_HUT", name: "Hutchinson Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-101.9741, 36.9934],
            [-101.6859, 36.9947],
            [-101.6767, 36.9503],
            [-101.8299, 36.9361],
            [-101.857, 36.9796],
            [-101.9381, 36.9652],
            [-101.9602, 36.9508],
            [-101.9742, 36.9383],
            [-101.9741, 36.9934],
          ],
        ],
        [
          [
            [-102.0416, 37.3892],
            [-102.0518, 40.0031],
            [-100.9374, 40.0021],
            [-99.8134, 40.0014],
            [-98.274, 40.0025],
            [-96.8053, 40.0014],
            [-96.8057, 39.9082],
            [-96.8431, 39.904],
            [-96.8241, 39.8997],
            [-96.8248, 39.8273],
            [-96.8437, 39.8272],
            [-96.8431, 39.8129],
            [-96.806, 39.813],
            [-96.8065, 39.7113],
            [-96.8064, 39.5665],
            [-96.8567, 39.5664],
            [-96.8843, 39.5664],
            [-96.8846, 39.5421],
            [-96.897, 39.5271],
            [-96.9126, 39.5411],
            [-96.9136, 39.4847],
            [-96.9048, 39.4518],
            [-96.9603, 39.4522],
            [-96.9562, 39.4377],
            [-96.9246, 39.4372],
            [-96.9234, 39.4226],
            [-96.9236, 39.4082],
            [-96.9235, 39.3791],
            [-96.9609, 39.3796],
            [-96.9608, 39.1829],
            [-96.9317, 39.1817],
            [-96.9267, 39.1376],
            [-96.9397, 39.1471],
            [-96.9613, 39.1466],
            [-96.9614, 39.1323],
            [-97.0008, 39.1326],
            [-97.0003, 39.0889],
            [-97.0003, 39.0596],
            [-96.9633, 39.045],
            [-96.9636, 38.9781],
            [-96.9364, 38.9722],
            [-96.9262, 38.9789],
            [-96.927, 38.8451],
            [-96.9087, 38.8413],
            [-96.899, 38.8636],
            [-96.895, 38.8703],
            [-96.6313, 38.8703],
            [-96.6318, 38.9069],
            [-96.6558, 38.9278],
            [-96.6505, 38.9521],
            [-96.6709, 38.9727],
            [-96.6573, 38.9826],
            [-96.633, 38.9704],
            [-96.612, 38.9742],
            [-96.6291, 39.0069],
            [-96.603, 39.0066],
            [-96.6232, 39.0365],
            [-96.5935, 39.0364],
            [-96.5413, 39.0149],
            [-96.5012, 39.0145],
            [-96.5017, 38.9275],
            [-96.4278, 38.9131],
            [-96.3722, 38.9131],
            [-96.3722, 38.8406],
            [-96.3538, 38.8261],
            [-96.2989, 38.8262],
            [-96.2983, 38.7976],
            [-96.3169, 38.7974],
            [-96.3164, 38.7681],
            [-96.3526, 38.7393],
            [-96.0198, 38.739],
            [-96.0018, 38.7319],
            [-96.0012, 38.6953],
            [-95.9867, 38.6952],
            [-95.9913, 38.6518],
            [-95.9495, 38.6518],
            [-95.9312, 38.608],
            [-95.9127, 38.5935],
            [-95.9131, 38.5105],
            [-95.9255, 38.5121],
            [-95.9139, 38.5103],
            [-95.9105, 38.5016],
            [-95.8992, 38.5044],
            [-95.9072, 38.4925],
            [-95.8844, 38.4905],
            [-95.888, 38.4778],
            [-95.8643, 38.4779],
            [-95.8626, 38.4844],
            [-95.8313, 38.4912],
            [-95.8327, 38.4762],
            [-95.8217, 38.488],
            [-95.7959, 38.4829],
            [-95.7153, 38.5018],
            [-95.7104, 38.4929],
            [-95.6918, 38.5071],
            [-95.6781, 38.4711],
            [-95.6863, 38.4663],
            [-95.6853, 38.4494],
            [-95.6741, 38.4491],
            [-95.6739, 38.4345],
            [-95.6184, 38.4344],
            [-95.6186, 38.4489],
            [-95.5083, 38.4633],
            [-95.508, 38.3468],
            [-95.5455, 38.3174],
            [-95.5447, 38.2886],
            [-95.5816, 38.2886],
            [-95.5822, 38.2131],
            [-95.592, 38.1765],
            [-95.5548, 38.1695],
            [-95.5553, 38.111],
            [-95.5187, 38.0963],
            [-95.5191, 38.0672],
            [-95.5377, 38.0382],
            [-95.8859, 38.0401],
            [-95.8857, 37.9961],
            [-95.8491, 37.9815],
            [-95.8675, 37.9743],
            [-95.8679, 37.9608],
            [-95.8856, 37.9595],
            [-95.8669, 37.938],
            [-95.8664, 37.9238],
            [-95.8486, 37.8653],
            [-95.8853, 37.8801],
            [-95.9586, 37.8801],
            [-95.977, 37.8502],
            [-96.0135, 37.8675],
            [-95.9806, 37.8367],
            [-95.9756, 37.8221],
            [-96.0766, 37.8228],
            [-96.0888, 37.819],
            [-96.0896, 37.8088],
            [-96.1071, 37.807],
            [-96.1068, 37.7506],
            [-96.0428, 37.7497],
            [-96.0422, 37.6661],
            [-96.0422, 37.6407],
            [-96.0244, 37.6404],
            [-96.0196, 37.6768],
            [-95.9968, 37.6909],
            [-95.9603, 37.6908],
            [-95.9604, 37.6761],
            [-95.9423, 37.6725],
            [-95.898, 37.684],
            [-95.897, 37.6586],
            [-95.9101, 37.6551],
            [-95.9106, 37.6405],
            [-95.8969, 37.6397],
            [-95.8969, 37.618],
            [-95.942, 37.6182],
            [-95.9429, 37.6036],
            [-95.961, 37.6037],
            [-95.9612, 37.582],
            [-96.0219, 37.5812],
            [-96.0256, 37.5627],
            [-96.0406, 37.5478],
            [-96.0367, 37.5028],
            [-96.0437, 37.5028],
            [-96.044, 37.4594],
            [-95.9826, 37.4595],
            [-95.9809, 37.4449],
            [-95.9625, 37.4458],
            [-95.9614, 37.3867],
            [-95.8857, 37.3863],
            [-95.8742, 37.3716],
            [-95.8559, 37.3714],
            [-95.8535, 37.386],
            [-95.8195, 37.3858],
            [-95.7795, 37.3565],
            [-95.7741, 37.342],
            [-95.7347, 37.3417],
            [-95.729, 37.3272],
            [-95.7108, 37.3281],
            [-95.7022, 37.356],
            [-95.6753, 37.3558],
            [-95.6868, 37.3485],
            [-95.6751, 37.3412],
            [-95.6564, 37.3532],
            [-95.6021, 37.3526],
            [-95.5972, 37.3986],
            [-95.5622, 37.3986],
            [-95.5621, 37.3839],
            [-95.3792, 37.3842],
            [-95.3487, 37.4132],
            [-95.3543, 37.4268],
            [-95.3249, 37.4275],
            [-95.2883, 37.4564],
            [-95.1791, 37.4566],
            [-95.1792, 37.442],
            [-95.17, 37.4416],
            [-95.1723, 37.4201],
            [-95.1575, 37.4177],
            [-95.1649, 37.4146],
            [-95.1578, 37.4108],
            [-95.1381, 37.3909],
            [-95.1213, 37.3871],
            [-95.1153, 37.3664],
            [-95.1287, 37.3629],
            [-95.1227, 37.3502],
            [-95.1004, 37.3322],
            [-95.1083, 37.2958],
            [-95.0862, 37.3032],
            [-95.0752, 37.296],
            [-95.0753, 37.2668],
            [-95.0413, 37.2595],
            [-95.0437, 37.2375],
            [-95.0679, 37.2376],
            [-95.0742, 37.2303],
            [-95.0742, 37.165],
            [-95.0319, 37.1352],
            [-94.9954, 37.1349],
            [-95.0136, 37.1046],
            [-94.9951, 37.0466],
            [-94.9318, 36.9992],
            [-96.0008, 36.9989],
            [-97.2377, 36.999],
            [-98.2097, 36.998],
            [-99.6487, 36.9996],
            [-102.0422, 36.9931],
            [-102.0416, 37.3892],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Tulsa Mkt": {
    type: "Feature",
    properties: { code: "OK_TUL", name: "Tulsa Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-94.9447, 35.3751],
            [-94.9293, 35.3778],
            [-94.9357, 35.3757],
            [-94.9268, 35.3728],
            [-94.938, 35.3738],
            [-94.9265, 35.3652],
            [-94.9447, 35.3751],
          ],
        ],
        [
          [
            [-97.0455, 36.4075],
            [-97.0451, 36.4076],
            [-97.0455, 36.4072],
            [-97.0455, 36.4075],
          ],
        ],
        [
          [
            [-96.7129, 35.6377],
            [-96.7337, 35.6951],
            [-96.7872, 35.6952],
            [-96.8051, 35.7243],
            [-96.765, 35.7402],
            [-96.7695, 35.782],
            [-96.8229, 35.7822],
            [-96.8234, 35.8842],
            [-96.8561, 35.9274],
            [-96.927, 35.9565],
            [-96.9627, 35.9275],
            [-97.0165, 35.9274],
            [-97.0188, 35.8696],
            [-97.0524, 35.8405],
            [-97.0881, 35.8404],
            [-97.1243, 35.8692],
            [-97.177, 35.8691],
            [-97.1939, 35.9273],
            [-97.1584, 35.9445],
            [-97.1581, 36.029],
            [-97.211, 36.058],
            [-97.2649, 36.058],
            [-97.2649, 36.087],
            [-97.218, 36.1592],
            [-97.1586, 36.1592],
            [-97.2119, 36.2027],
            [-97.1588, 36.2173],
            [-97.1572, 36.2609],
            [-97.069, 36.2466],
            [-96.9612, 36.2464],
            [-96.9589, 36.2763],
            [-96.9249, 36.3333],
            [-96.9608, 36.3333],
            [-96.9606, 36.4058],
            [-96.9122, 36.4479],
            [-96.9113, 36.4678],
            [-96.9117, 36.468],
            [-96.9812, 36.4659],
            [-97.032, 36.4486],
            [-97.0321, 36.4096],
            [-97.0451, 36.4076],
            [-97.0356, 36.4182],
            [-97.0456, 36.415],
            [-97.0455, 36.4075],
            [-97.0535, 36.4062],
            [-97.1066, 36.3914],
            [-97.1752, 36.3912],
            [-97.1843, 36.4491],
            [-97.2483, 36.4576],
            [-97.2828, 36.4489],
            [-97.3902, 36.4485],
            [-97.426, 36.4339],
            [-97.4442, 36.4052],
            [-97.4615, 36.405],
            [-97.4616, 36.5063],
            [-97.5154, 36.5193],
            [-97.5157, 36.5638],
            [-97.5421, 36.5428],
            [-97.5866, 36.5636],
            [-97.6044, 36.5347],
            [-97.658, 36.5202],
            [-97.765, 36.5208],
            [-97.7564, 36.5497],
            [-97.7469, 36.5932],
            [-97.6781, 36.6079],
            [-97.6906, 36.6657],
            [-97.6426, 36.694],
            [-97.6422, 36.7382],
            [-97.6063, 36.7672],
            [-97.5884, 36.7817],
            [-97.5883, 36.8252],
            [-97.5525, 36.8543],
            [-97.5346, 36.9268],
            [-97.4624, 36.927],
            [-97.4623, 36.9987],
            [-96.0008, 36.9989],
            [-94.6181, 36.9981],
            [-94.6179, 36.4994],
            [-94.5653, 36.1765],
            [-94.5836, 36.1763],
            [-94.5942, 36.1617],
            [-94.7353, 36.1617],
            [-94.7617, 36.1273],
            [-94.7548, 36.1198],
            [-94.7686, 36.1195],
            [-94.783, 36.1043],
            [-94.7971, 36.1122],
            [-94.7897, 36.1],
            [-94.7688, 36.0951],
            [-94.7769, 36.0749],
            [-94.7444, 36.0713],
            [-94.7311, 36.0515],
            [-94.7299, 36.0384],
            [-94.7507, 36.033],
            [-94.7534, 36.007],
            [-94.7339, 36.0112],
            [-94.7251, 36.0058],
            [-94.7457, 35.9982],
            [-94.7656, 35.976],
            [-94.7613, 35.9661],
            [-94.7728, 35.9618],
            [-94.7597, 35.9588],
            [-94.8031, 35.9627],
            [-94.8257, 35.9395],
            [-94.8271, 35.9279],
            [-94.8482, 35.9083],
            [-94.8645, 35.9036],
            [-94.865, 35.8897],
            [-94.8072, 35.8735],
            [-94.8075, 35.8116],
            [-94.8135, 35.8058],
            [-94.8295, 35.8219],
            [-94.8469, 35.7928],
            [-94.8295, 35.776],
            [-94.8348, 35.7411],
            [-94.8774, 35.7553],
            [-94.8917, 35.7494],
            [-94.9041, 35.7295],
            [-94.8801, 35.7259],
            [-94.8862, 35.7046],
            [-94.8469, 35.6812],
            [-94.8253, 35.656],
            [-94.8077, 35.6744],
            [-94.8073, 35.6386],
            [-94.9005, 35.6388],
            [-94.9045, 35.6545],
            [-94.9266, 35.6768],
            [-94.9611, 35.6441],
            [-94.9782, 35.6518],
            [-95.0177, 35.6333],
            [-95.0371, 35.6169],
            [-95.0401, 35.6024],
            [-95.0317, 35.5897],
            [-95.0373, 35.5827],
            [-95.027, 35.5771],
            [-94.9934, 35.5793],
            [-94.9917, 35.5925],
            [-94.9806, 35.5918],
            [-94.9766, 35.5808],
            [-94.9853, 35.5793],
            [-94.9848, 35.5663],
            [-95.0203, 35.5662],
            [-95.0181, 35.5434],
            [-95.0409, 35.5404],
            [-95.1008, 35.4949],
            [-95.0856, 35.4614],
            [-95.0614, 35.4651],
            [-95.0277, 35.4548],
            [-95.0242, 35.4314],
            [-95.0092, 35.4164],
            [-94.9868, 35.4084],
            [-94.9513, 35.4083],
            [-94.9342, 35.3955],
            [-94.947, 35.3767],
            [-94.9571, 35.3778],
            [-94.9516, 35.355],
            [-94.9765, 35.3453],
            [-94.9819, 35.3331],
            [-94.9735, 35.3185],
            [-94.9566, 35.3138],
            [-94.9645, 35.2873],
            [-94.9501, 35.2823],
            [-94.9917, 35.2282],
            [-94.9891, 35.2158],
            [-95.0014, 35.2059],
            [-95.0137, 35.2104],
            [-95.0256, 35.1994],
            [-95.0405, 35.2064],
            [-95.0588, 35.1993],
            [-95.0851, 35.172],
            [-95.0873, 35.152],
            [-95.0782, 35.1445],
            [-95.1037, 35.1412],
            [-95.1046, 35.163],
            [-95.129, 35.1513],
            [-95.1561, 35.1534],
            [-95.1503, 35.2026],
            [-95.2269, 35.1884],
            [-95.24, 35.2026],
            [-95.3575, 35.201],
            [-95.3271, 35.2244],
            [-95.327, 35.2462],
            [-95.4156, 35.214],
            [-95.451, 35.2284],
            [-95.4729, 35.1806],
            [-95.5169, 35.1456],
            [-95.6087, 35.136],
            [-95.642, 35.1056],
            [-95.6789, 35.1166],
            [-95.7598, 35.0978],
            [-95.7802, 35.0686],
            [-95.8345, 35.1015],
            [-95.8784, 35.1014],
            [-95.8775, 35.0586],
            [-95.8688, 35.0434],
            [-95.9307, 35.0435],
            [-95.9131, 35.1296],
            [-95.8707, 35.186],
            [-95.7651, 35.2025],
            [-95.787, 35.2459],
            [-95.7871, 35.2967],
            [-95.8218, 35.3046],
            [-95.8226, 35.371],
            [-95.8949, 35.3767],
            [-95.8978, 35.328],
            [-95.9363, 35.3005],
            [-95.9813, 35.3021],
            [-95.9813, 35.3768],
            [-96.0168, 35.3915],
            [-96.0875, 35.3915],
            [-96.0878, 35.5521],
            [-96.1928, 35.5519],
            [-96.1927, 35.6391],
            [-96.4056, 35.6393],
            [-96.5835, 35.639],
            [-96.6056, 35.6101],
            [-96.6787, 35.6084],
            [-96.7129, 35.6377],
          ],
          [
            [-95.1534, 35.3623],
            [-95.1257, 35.3753],
            [-95.1333, 35.3801],
            [-95.1534, 35.3623],
          ],
          [
            [-95.1112, 35.3842],
            [-95.0981, 35.3871],
            [-95.0874, 35.4047],
            [-95.1112, 35.3842],
          ],
        ],
        [
          [
            [-97.7857, 36.6675],
            [-97.7567, 36.6551],
            [-97.7561, 36.6548],
            [-97.7857, 36.6675],
          ],
        ],
        [
          [
            [-97.7857, 36.6675],
            [-97.7859, 36.6676],
            [-97.7857, 36.6792],
            [-97.7857, 36.6675],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Oklahoma City Mkt": {
    type: "Feature",
    properties: { code: "OK_OKC", name: "Oklahoma City Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-94.6372, 34.4593],
            [-94.6373, 34.4596],
            [-94.6189, 34.4593],
            [-94.6372, 34.4593],
          ],
        ],
        [
          [
            [-94.6372, 34.4593],
            [-94.6304, 34.4404],
            [-94.6329, 34.4408],
            [-94.6386, 34.4593],
            [-94.6372, 34.4593],
          ],
        ],
        [
          [
            [-94.6329, 34.4408],
            [-94.6326, 34.44],
            [-94.6553, 34.4441],
            [-94.6552, 34.4444],
            [-94.6329, 34.4408],
          ],
        ],
        [
          [
            [-94.6553, 34.4441],
            [-94.6672, 34.4088],
            [-94.674, 34.402],
            [-94.6836, 34.4049],
            [-94.6741, 34.4026],
            [-94.6673, 34.4101],
            [-94.6567, 34.4444],
            [-94.6553, 34.4441],
          ],
        ],
        [
          [
            [-94.6941, 34.3909],
            [-94.6848, 34.4052],
            [-94.6839, 34.405],
            [-94.6939, 34.391],
            [-94.6941, 34.3909],
          ],
        ],
        [
          [
            [-97.0425, 36.408],
            [-97.0431, 36.4079],
            [-97.0456, 36.415],
            [-97.0356, 36.4182],
            [-97.0425, 36.408],
          ],
        ],
        [
          [
            [-97.0431, 36.4079],
            [-97.0425, 36.408],
            [-97.0321, 36.4096],
            [-97.032, 36.4486],
            [-96.9812, 36.4659],
            [-96.9117, 36.468],
            [-96.9113, 36.4678],
            [-96.9122, 36.4479],
            [-96.9606, 36.4058],
            [-96.9608, 36.3333],
            [-96.9249, 36.3333],
            [-96.9589, 36.2763],
            [-96.9612, 36.2464],
            [-97.069, 36.2466],
            [-97.1572, 36.2609],
            [-97.1588, 36.2173],
            [-97.2119, 36.2027],
            [-97.1586, 36.1592],
            [-97.218, 36.1592],
            [-97.2649, 36.087],
            [-97.2649, 36.058],
            [-97.211, 36.058],
            [-97.1581, 36.029],
            [-97.1584, 35.9445],
            [-97.1939, 35.9273],
            [-97.177, 35.8691],
            [-97.1243, 35.8692],
            [-97.0881, 35.8404],
            [-97.0524, 35.8405],
            [-97.0188, 35.8696],
            [-97.0165, 35.9274],
            [-96.9627, 35.9275],
            [-96.927, 35.9565],
            [-96.8561, 35.9274],
            [-96.8234, 35.8842],
            [-96.8229, 35.7822],
            [-96.7695, 35.782],
            [-96.765, 35.7402],
            [-96.8051, 35.7243],
            [-96.7872, 35.6952],
            [-96.7337, 35.6951],
            [-96.7129, 35.6377],
            [-96.6787, 35.6084],
            [-96.6056, 35.6101],
            [-96.5835, 35.639],
            [-96.4056, 35.6393],
            [-96.1926, 35.6391],
            [-96.1928, 35.5519],
            [-96.0878, 35.5521],
            [-96.0875, 35.3915],
            [-96.0168, 35.3915],
            [-95.9813, 35.3768],
            [-95.9813, 35.3021],
            [-95.9363, 35.3005],
            [-95.8978, 35.328],
            [-95.8949, 35.3767],
            [-95.8226, 35.371],
            [-95.8218, 35.3046],
            [-95.7871, 35.2967],
            [-95.787, 35.2459],
            [-95.7651, 35.2025],
            [-95.8707, 35.186],
            [-95.9131, 35.1296],
            [-95.9307, 35.0435],
            [-95.8688, 35.0434],
            [-95.8775, 35.0586],
            [-95.8784, 35.1014],
            [-95.8345, 35.1015],
            [-95.7802, 35.0686],
            [-95.7598, 35.0978],
            [-95.6789, 35.1166],
            [-95.642, 35.1056],
            [-95.6087, 35.136],
            [-95.5169, 35.1456],
            [-95.4729, 35.1806],
            [-95.451, 35.2284],
            [-95.4156, 35.214],
            [-95.327, 35.2462],
            [-95.3271, 35.2244],
            [-95.3575, 35.201],
            [-95.24, 35.2026],
            [-95.2269, 35.1884],
            [-95.1503, 35.2026],
            [-95.1561, 35.1534],
            [-95.1454, 35.1509],
            [-95.1431, 35.1302],
            [-95.1589, 35.1125],
            [-95.1527, 35.1077],
            [-95.1284, 35.1114],
            [-95.1193, 35.0844],
            [-95.1787, 35.0579],
            [-94.9279, 35.0583],
            [-94.9278, 34.9501],
            [-94.9975, 34.9494],
            [-95.0064, 34.8541],
            [-94.9713, 34.8367],
            [-94.8867, 34.8217],
            [-94.8863, 34.7701],
            [-94.8651, 34.7706],
            [-94.8374, 34.755],
            [-94.7839, 34.7407],
            [-94.7845, 34.7341],
            [-94.8267, 34.7393],
            [-94.838, 34.734],
            [-94.8283, 34.7061],
            [-94.84, 34.6744],
            [-94.8197, 34.6729],
            [-94.8138, 34.6875],
            [-94.8072, 34.6826],
            [-94.7914, 34.6894],
            [-94.771, 34.6603],
            [-94.78, 34.6487],
            [-94.7783, 34.6301],
            [-94.8003, 34.6304],
            [-94.7734, 34.6134],
            [-94.786, 34.6087],
            [-94.8452, 34.6141],
            [-94.8684, 34.593],
            [-94.8516, 34.5834],
            [-94.8484, 34.5653],
            [-94.8349, 34.5536],
            [-94.8372, 34.5388],
            [-94.855, 34.5433],
            [-94.8675, 34.535],
            [-94.8771, 34.5376],
            [-94.8744, 34.5316],
            [-94.9267, 34.5272],
            [-94.9376, 34.5073],
            [-94.8273, 34.5073],
            [-94.8373, 34.5001],
            [-94.8176, 34.4877],
            [-94.8037, 34.4665],
            [-94.8093, 34.4583],
            [-94.7917, 34.4481],
            [-94.7956, 34.4317],
            [-94.788, 34.4491],
            [-94.7833, 34.43],
            [-94.7751, 34.4269],
            [-94.7754, 34.4135],
            [-94.7918, 34.401],
            [-94.7704, 34.4065],
            [-94.7297, 34.372],
            [-94.7077, 34.3841],
            [-94.7087, 34.3903],
            [-94.6941, 34.3909],
            [-94.696, 34.388],
            [-94.6939, 34.391],
            [-94.6936, 34.391],
            [-94.6958, 34.3831],
            [-94.6951, 34.3704],
            [-94.681, 34.3537],
            [-94.6353, 34.3434],
            [-94.6304, 34.3356],
            [-94.5716, 34.3276],
            [-94.5213, 34.3272],
            [-94.5011, 34.3341],
            [-94.5009, 34.3192],
            [-94.4664, 34.3329],
            [-94.4875, 33.6289],
            [-94.5263, 33.6192],
            [-94.5333, 33.6377],
            [-94.5527, 33.6382],
            [-94.5521, 33.6535],
            [-94.5708, 33.6549],
            [-94.5796, 33.6776],
            [-94.6212, 33.681],
            [-94.6429, 33.6684],
            [-94.6523, 33.691],
            [-94.7079, 33.6869],
            [-94.711, 33.7057],
            [-94.7324, 33.7003],
            [-94.7426, 33.727],
            [-94.763, 33.7318],
            [-94.7681, 33.7534],
            [-94.7986, 33.7445],
            [-94.8174, 33.7522],
            [-94.8308, 33.7401],
            [-94.8693, 33.7459],
            [-94.8792, 33.7649],
            [-94.9196, 33.7863],
            [-94.9194, 33.8102],
            [-94.9443, 33.8121],
            [-94.9644, 33.837],
            [-94.9689, 33.8609],
            [-94.9885, 33.851],
            [-95.0084, 33.8661],
            [-95.0223, 33.8598],
            [-95.0466, 33.8626],
            [-95.0655, 33.8996],
            [-95.0904, 33.8933],
            [-95.1008, 33.9122],
            [-95.1224, 33.9186],
            [-95.1247, 33.9347],
            [-95.1611, 33.9376],
            [-95.2264, 33.962],
            [-95.2529, 33.9336],
            [-95.2531, 33.9054],
            [-95.2778, 33.9009],
            [-95.2879, 33.8749],
            [-95.3335, 33.8863],
            [-95.3391, 33.8689],
            [-95.4633, 33.8723],
            [-95.4649, 33.8867],
            [-95.5023, 33.8747],
            [-95.5153, 33.8911],
            [-95.5452, 33.8803],
            [-95.5594, 33.9302],
            [-95.5997, 33.9342],
            [-95.637, 33.9066],
            [-95.6653, 33.9081],
            [-95.697, 33.8852],
            [-95.7375, 33.896],
            [-95.7564, 33.8926],
            [-95.7626, 33.8744],
            [-95.7535, 33.8565],
            [-95.7721, 33.8438],
            [-95.8051, 33.8613],
            [-95.8206, 33.8585],
            [-95.8208, 33.8406],
            [-95.8375, 33.8356],
            [-95.9352, 33.8871],
            [-95.9443, 33.8598],
            [-95.9722, 33.8564],
            [-95.9915, 33.8669],
            [-95.9984, 33.851],
            [-96.0196, 33.8406],
            [-96.0295, 33.8524],
            [-96.0488, 33.8365],
            [-96.1001, 33.848],
            [-96.0994, 33.8305],
            [-96.123, 33.84],
            [-96.1481, 33.8378],
            [-96.1508, 33.817],
            [-96.179, 33.8106],
            [-96.1621, 33.7961],
            [-96.1781, 33.7605],
            [-96.2205, 33.7474],
            [-96.2699, 33.7684],
            [-96.2925, 33.7664],
            [-96.3169, 33.699],
            [-96.3483, 33.6864],
            [-96.3622, 33.6918],
            [-96.3696, 33.7168],
            [-96.4085, 33.7512],
            [-96.4226, 33.776],
            [-96.448, 33.781],
            [-96.5003, 33.7726],
            [-96.5159, 33.7878],
            [-96.5267, 33.8209],
            [-96.5729, 33.8191],
            [-96.6293, 33.8455],
            [-96.612, 33.869],
            [-96.5901, 33.8807],
            [-96.5886, 33.8949],
            [-96.6283, 33.8945],
            [-96.6672, 33.9169],
            [-96.6996, 33.8391],
            [-96.7616, 33.8244],
            [-96.7707, 33.8296],
            [-96.7806, 33.8601],
            [-96.7943, 33.8689],
            [-96.8322, 33.8748],
            [-96.8506, 33.8472],
            [-96.8753, 33.8605],
            [-96.8957, 33.8964],
            [-96.9024, 33.942],
            [-96.9163, 33.9578],
            [-96.9738, 33.9357],
            [-96.9813, 33.9564],
            [-96.9962, 33.9417],
            [-96.9856, 33.8865],
            [-97.0239, 33.8442],
            [-97.0576, 33.8401],
            [-97.0586, 33.8188],
            [-97.0921, 33.8041],
            [-97.0862, 33.7439],
            [-97.0972, 33.7278],
            [-97.1261, 33.7169],
            [-97.1551, 33.7244],
            [-97.1722, 33.7375],
            [-97.2054, 33.8015],
            [-97.1975, 33.8298],
            [-97.1716, 33.8353],
            [-97.1666, 33.8473],
            [-97.1808, 33.8952],
            [-97.2109, 33.9161],
            [-97.2449, 33.9031],
            [-97.2556, 33.8637],
            [-97.2791, 33.8646],
            [-97.2992, 33.8802],
            [-97.3182, 33.8651],
            [-97.3329, 33.8744],
            [-97.3655, 33.8238],
            [-97.4265, 33.8194],
            [-97.4531, 33.8285],
            [-97.4629, 33.8418],
            [-97.4515, 33.8709],
            [-97.451, 33.8914],
            [-97.4604, 33.9039],
            [-97.501, 33.9196],
            [-97.5515, 33.8979],
            [-97.5874, 33.9025],
            [-97.5971, 33.9179],
            [-97.5896, 33.9536],
            [-97.6338, 33.9813],
            [-97.673, 33.991],
            [-97.6931, 33.9837],
            [-97.7253, 33.941],
            [-97.7628, 33.9344],
            [-97.7594, 33.9188],
            [-97.7837, 33.9106],
            [-97.7847, 33.8906],
            [-97.8343, 33.8577],
            [-97.8714, 33.849],
            [-97.9367, 33.8792],
            [-97.9678, 33.8824],
            [-97.9838, 33.8972],
            [-97.9788, 33.9125],
            [-97.9699, 33.906],
            [-97.9572, 33.9145],
            [-97.9534, 33.9364],
            [-97.9742, 33.9428],
            [-97.9604, 33.9519],
            [-97.9465, 33.9907],
            [-97.9583, 33.9908],
            [-97.9742, 34.0067],
            [-98.0277, 33.9934],
            [-98.0882, 34.0055],
            [-98.1055, 34.0313],
            [-98.0962, 34.0446],
            [-98.1202, 34.0721],
            [-98.0898, 34.1282],
            [-98.1095, 34.1541],
            [-98.1308, 34.1505],
            [-98.1691, 34.1142],
            [-98.2037, 34.1177],
            [-98.241, 34.1331],
            [-98.2939, 34.133],
            [-98.3254, 34.151],
            [-98.364, 34.1571],
            [-98.3812, 34.1495],
            [-98.3984, 34.1285],
            [-98.3998, 34.1],
            [-98.4144, 34.0851],
            [-98.4401, 34.0843],
            [-98.449, 34.0735],
            [-98.4863, 34.0626],
            [-98.5282, 34.095],
            [-98.5725, 34.1451],
            [-98.5998, 34.1606],
            [-98.6167, 34.1564],
            [-98.6481, 34.1644],
            [-98.6901, 34.1332],
            [-98.7343, 34.1358],
            [-98.742, 34.1255],
            [-98.757, 34.1246],
            [-98.7656, 34.1364],
            [-98.813, 34.1584],
            [-98.8556, 34.1616],
            [-98.8601, 34.1499],
            [-98.8681, 34.1496],
            [-98.8729, 34.1666],
            [-98.9183, 34.1818],
            [-98.9525, 34.2126],
            [-98.9608, 34.213],
            [-98.9663, 34.2013],
            [-98.9741, 34.2036],
            [-98.9873, 34.2212],
            [-99.0131, 34.2032],
            [-99.0363, 34.2069],
            [-99.0435, 34.1982],
            [-99.0795, 34.2115],
            [-99.1266, 34.203],
            [-99.1306, 34.2194],
            [-99.159, 34.2089],
            [-99.1895, 34.2143],
            [-99.1956, 34.2808],
            [-99.2116, 34.2922],
            [-99.2131, 34.3404],
            [-99.2326, 34.3424],
            [-99.2429, 34.3727],
            [-99.2587, 34.3726],
            [-99.2749, 34.3849],
            [-99.259, 34.3912],
            [-99.2613, 34.4035],
            [-99.2946, 34.4154],
            [-99.3196, 34.4089],
            [-99.3567, 34.4421],
            [-99.3547, 34.4519],
            [-99.3696, 34.4587],
            [-99.395, 34.4421],
            [-99.3915, 34.4056],
            [-99.4204, 34.3805],
            [-99.4526, 34.3883],
            [-99.5143, 34.414],
            [-99.5697, 34.4184],
            [-99.6, 34.3747],
            [-99.6965, 34.381],
            [-99.7672, 34.4305],
            [-99.8253, 34.4976],
            [-99.8531, 34.5116],
            [-99.9232, 34.5746],
            [-100.0004, 34.5605],
            [-100.0004, 36.4997],
            [-100.325, 36.5],
            [-100.2892, 36.5146],
            [-100.2892, 36.5675],
            [-100.2714, 36.6163],
            [-100.2537, 36.6597],
            [-100.1996, 36.6893],
            [-100.1458, 36.6889],
            [-100.1095, 36.7613],
            [-100.1815, 36.7617],
            [-100.2705, 36.7859],
            [-100.2879, 36.8419],
            [-100.334, 36.8484],
            [-100.3225, 36.8904],
            [-100.3395, 36.9075],
            [-100.3362, 36.9685],
            [-100.4042, 36.9934],
            [-100.4126, 37.0012],
            [-100.0895, 37.0021],
            [-98.3541, 36.998],
            [-97.4623, 36.9987],
            [-97.4624, 36.927],
            [-97.5346, 36.9268],
            [-97.5525, 36.8543],
            [-97.5883, 36.8252],
            [-97.5884, 36.7817],
            [-97.6063, 36.7672],
            [-97.6422, 36.7382],
            [-97.6426, 36.694],
            [-97.6906, 36.6657],
            [-97.6781, 36.6079],
            [-97.7469, 36.5932],
            [-97.7564, 36.5497],
            [-97.765, 36.5208],
            [-97.658, 36.5202],
            [-97.6044, 36.5347],
            [-97.5866, 36.5636],
            [-97.5421, 36.5428],
            [-97.5157, 36.5638],
            [-97.5154, 36.5193],
            [-97.4616, 36.5063],
            [-97.4614, 36.405],
            [-97.4442, 36.4052],
            [-97.426, 36.4339],
            [-97.3902, 36.4485],
            [-97.2828, 36.4489],
            [-97.2483, 36.4576],
            [-97.1843, 36.4491],
            [-97.1752, 36.3912],
            [-97.1066, 36.3914],
            [-97.0535, 36.4062],
            [-97.0431, 36.4079],
          ],
          [
            [-95.1596, 34.8715],
            [-95.1472, 34.8602],
            [-95.1346, 34.86],
            [-95.1334, 34.8516],
            [-95.1129, 34.8521],
            [-95.0858, 34.8398],
            [-95.0857, 34.8328],
            [-95.0594, 34.832],
            [-95.0595, 34.8506],
            [-95.0681, 34.8517],
            [-95.0784, 34.8696],
            [-95.067, 34.8782],
            [-95.0687, 34.8983],
            [-95.1209, 34.8908],
            [-95.1386, 34.8912],
            [-95.1663, 34.8873],
            [-95.1596, 34.8715],
          ],
          [
            [-97.7859, 36.6704],
            [-97.7859, 36.6676],
            [-97.7567, 36.6551],
            [-97.7566, 36.6551],
            [-97.7859, 36.6704],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Houston Mkt": {
    type: "Feature",
    properties: { code: "TX_HOU", name: "Houston Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-96.9249, 30.3387],
          [-96.9047, 30.3394],
          [-96.9172, 30.3545],
          [-96.9121, 30.3856],
          [-96.9016, 30.4001],
          [-96.8989, 30.394],
          [-96.8907, 30.396],
          [-96.8764, 30.3849],
          [-96.8893, 30.4207],
          [-96.8682, 30.4338],
          [-96.8749, 30.4576],
          [-96.8999, 30.4602],
          [-96.9019, 30.4819],
          [-96.8796, 30.4885],
          [-96.871, 30.474],
          [-96.8499, 30.4799],
          [-96.8663, 30.5173],
          [-96.8106, 30.5458],
          [-96.8169, 30.5682],
          [-96.7861, 30.588],
          [-96.8208, 30.6012],
          [-96.8449, 30.5951],
          [-96.8779, 30.6007],
          [-96.7007, 30.6901],
          [-96.7234, 30.7465],
          [-96.7145, 30.7534],
          [-96.72, 30.762],
          [-96.739, 30.772],
          [-96.7629, 30.7552],
          [-96.7659, 30.7629],
          [-96.7269, 30.7815],
          [-96.7456, 30.8146],
          [-96.7213, 30.8479],
          [-96.711, 30.8356],
          [-96.7105, 30.8449],
          [-96.6974, 30.8413],
          [-96.6961, 30.8499],
          [-96.679, 30.8441],
          [-96.6956, 30.8672],
          [-96.695, 30.8781],
          [-96.6833, 30.8815],
          [-96.6973, 30.8977],
          [-96.6832, 30.902],
          [-96.6924, 30.9093],
          [-96.7264, 30.8987],
          [-96.7344, 30.9045],
          [-96.731, 30.9165],
          [-96.7437, 30.9157],
          [-96.7569, 30.9279],
          [-96.7559, 30.9455],
          [-96.7726, 30.9677],
          [-96.746, 30.995],
          [-96.7557, 31.0054],
          [-96.7711, 31.0021],
          [-96.7696, 31.025],
          [-96.7797, 31.0327],
          [-96.7658, 31.0438],
          [-96.7629, 31.0571],
          [-96.7707, 31.0594],
          [-96.7147, 31.0949],
          [-96.7142, 31.1039],
          [-96.7112, 31.0974],
          [-96.7008, 31.1025],
          [-96.6812, 31.0786],
          [-96.6582, 31.0898],
          [-96.658, 31.1016],
          [-96.6212, 31.0844],
          [-96.5872, 31.0998],
          [-96.6148, 31.122],
          [-96.5884, 31.165],
          [-96.5526, 31.1785],
          [-96.5412, 31.1619],
          [-96.5215, 31.1674],
          [-96.5024, 31.1622],
          [-96.4672, 31.194],
          [-96.4545, 31.2179],
          [-96.436, 31.2259],
          [-96.4504, 31.2476],
          [-96.445, 31.262],
          [-96.4674, 31.2841],
          [-96.383, 31.3257],
          [-96.378, 31.3202],
          [-96.4058, 31.3069],
          [-96.4042, 31.3019],
          [-96.3718, 31.265],
          [-96.3419, 31.2558],
          [-96.347, 31.2343],
          [-96.3351, 31.234],
          [-96.3221, 31.2115],
          [-96.3111, 31.2069],
          [-96.3143, 31.2291],
          [-96.3312, 31.2525],
          [-96.314, 31.2714],
          [-96.3275, 31.2852],
          [-96.3281, 31.2988],
          [-96.3171, 31.3017],
          [-96.3257, 31.311],
          [-96.309, 31.33],
          [-96.308, 31.3557],
          [-96.2959, 31.353],
          [-96.302, 31.3589],
          [-96.2933, 31.3692],
          [-96.3078, 31.36],
          [-96.276, 31.3805],
          [-96.2727, 31.3595],
          [-96.2588, 31.3495],
          [-96.2334, 31.3457],
          [-96.2147, 31.3523],
          [-96.2029, 31.3348],
          [-96.2362, 31.3047],
          [-96.227, 31.2918],
          [-96.2115, 31.3028],
          [-96.2199, 31.2708],
          [-96.2118, 31.248],
          [-96.1197, 31.2547],
          [-96.0866, 31.2062],
          [-96.0377, 31.1933],
          [-95.9929, 31.1891],
          [-95.9853, 31.1523],
          [-95.998, 31.1424],
          [-95.9897, 31.1445],
          [-95.9774, 31.1143],
          [-95.9934, 31.1101],
          [-95.9857, 31.0977],
          [-95.9889, 31.0862],
          [-95.9277, 31.0926],
          [-95.925, 31.0836],
          [-95.8823, 31.0844],
          [-95.8882, 31.0694],
          [-95.8724, 31.0436],
          [-95.8166, 31.0061],
          [-95.797, 31.0008],
          [-95.782, 30.9833],
          [-95.7717, 30.9839],
          [-95.7764, 30.9688],
          [-95.7943, 30.9563],
          [-95.7342, 30.9395],
          [-95.7157, 30.95],
          [-95.7127, 30.9108],
          [-95.7621, 30.8977],
          [-95.7725, 30.8886],
          [-95.7603, 30.8733],
          [-95.7256, 30.8862],
          [-95.7167, 30.8702],
          [-95.7331, 30.8641],
          [-95.7396, 30.8523],
          [-95.6709, 30.7904],
          [-95.6252, 30.8219],
          [-95.6227, 30.8506],
          [-95.5738, 30.8734],
          [-95.5729, 30.8867],
          [-95.5494, 30.9182],
          [-95.4597, 30.9395],
          [-95.4108, 30.9301],
          [-95.4155, 30.9079],
          [-95.3813, 30.8871],
          [-95.3814, 30.8793],
          [-95.3994, 30.8815],
          [-95.3916, 30.876],
          [-95.3986, 30.8605],
          [-95.3833, 30.853],
          [-95.3521, 30.857],
          [-95.344, 30.8758],
          [-95.3275, 30.8595],
          [-95.3142, 30.8723],
          [-95.314, 30.8964],
          [-95.3074, 30.9005],
          [-95.2513, 30.9065],
          [-95.2308, 30.887],
          [-95.2431, 30.8669],
          [-95.231, 30.8411],
          [-95.2002, 30.8246],
          [-95.0152, 30.9797],
          [-95.0079, 30.9604],
          [-94.9746, 30.9604],
          [-94.9707, 30.953],
          [-94.9567, 30.9599],
          [-94.9467, 30.9441],
          [-94.9582, 30.9339],
          [-94.9598, 30.9106],
          [-94.9702, 30.8955],
          [-94.9535, 30.8644],
          [-94.9603, 30.8467],
          [-94.9525, 30.8364],
          [-94.9349, 30.8308],
          [-94.897, 30.8421],
          [-94.8797, 30.874],
          [-94.8871, 30.8886],
          [-94.8655, 30.884],
          [-94.8683, 30.864],
          [-94.8516, 30.8621],
          [-94.8451, 30.8709],
          [-94.8322, 30.8569],
          [-94.8208, 30.8619],
          [-94.7974, 30.8574],
          [-94.7992, 30.8501],
          [-94.7854, 30.8501],
          [-94.7862, 30.8605],
          [-94.7714, 30.8676],
          [-94.7585, 30.8651],
          [-94.7523, 30.8719],
          [-94.7354, 30.8485],
          [-94.7374, 30.8566],
          [-94.7146, 30.8711],
          [-94.6948, 30.8671],
          [-94.68, 30.8513],
          [-94.6889, 30.8318],
          [-94.7044, 30.8233],
          [-94.6758, 30.8277],
          [-94.6764, 30.8432],
          [-94.6625, 30.844],
          [-94.6594, 30.8508],
          [-94.6328, 30.8118],
          [-94.616, 30.8301],
          [-94.5766, 30.6605],
          [-94.5533, 30.6512],
          [-94.4972, 30.6565],
          [-94.4914, 30.6782],
          [-94.5014, 30.6886],
          [-94.4891, 30.7022],
          [-94.4722, 30.6791],
          [-94.4274, 30.6613],
          [-94.3922, 30.6613],
          [-94.3936, 30.6704],
          [-94.3501, 30.6758],
          [-94.3531, 30.6982],
          [-94.3427, 30.6979],
          [-94.334, 30.6871],
          [-94.3346, 30.6986],
          [-94.3276, 30.7014],
          [-94.3151, 30.6889],
          [-94.3156, 30.6998],
          [-94.3033, 30.7054],
          [-94.3194, 30.7458],
          [-94.3106, 30.7637],
          [-94.2978, 30.758],
          [-94.303, 30.7446],
          [-94.2947, 30.7379],
          [-94.2871, 30.7424],
          [-94.2825, 30.7348],
          [-94.266, 30.7349],
          [-94.2304, 30.7047],
          [-94.2012, 30.6978],
          [-94.1921, 30.6984],
          [-94.1991, 30.7092],
          [-94.18, 30.7087],
          [-94.1784, 30.6998],
          [-94.1644, 30.7083],
          [-94.1632, 30.7003],
          [-94.1347, 30.7061],
          [-94.1378, 30.7148],
          [-94.1299, 30.7195],
          [-94.1386, 30.7323],
          [-94.1283, 30.7457],
          [-94.1382, 30.7319],
          [-94.1295, 30.7194],
          [-94.1374, 30.7143],
          [-94.123, 30.7009],
          [-94.1258, 30.6841],
          [-94.0901, 30.6876],
          [-94.0823, 30.6669],
          [-94.0864, 30.6573],
          [-94.0732, 30.6559],
          [-94.0795, 30.6608],
          [-94.0742, 30.6678],
          [-94.0559, 30.6546],
          [-94.0591, 30.6442],
          [-94.0511, 30.6401],
          [-94.0583, 30.6247],
          [-94.0758, 30.618],
          [-94.0708, 30.6086],
          [-94.0812, 30.6072],
          [-94.0793, 30.5981],
          [-94.0931, 30.5844],
          [-94.0859, 30.5473],
          [-94.0481, 30.5561],
          [-94.0359, 30.537],
          [-94.0131, 30.5337],
          [-94.0002, 30.5457],
          [-93.9669, 30.5393],
          [-93.9498, 30.5213],
          [-93.9482, 30.5017],
          [-93.9373, 30.5327],
          [-93.9166, 30.521],
          [-93.8833, 30.5338],
          [-93.8829, 30.5411],
          [-93.8522, 30.5254],
          [-93.8203, 30.5329],
          [-93.8272, 30.5223],
          [-93.814, 30.5177],
          [-93.8056, 30.4638],
          [-93.797, 30.4649],
          [-93.8046, 30.4238],
          [-93.7842, 30.4229],
          [-93.758, 30.3835],
          [-93.7564, 30.3562],
          [-93.7658, 30.3333],
          [-93.7387, 30.3038],
          [-93.7086, 30.2883],
          [-93.7051, 30.2428],
          [-93.7209, 30.2099],
          [-93.6882, 30.1414],
          [-93.7013, 30.1374],
          [-93.7024, 30.1127],
          [-93.7341, 30.0861],
          [-93.7022, 30.0655],
          [-93.7008, 30.0563],
          [-93.7208, 30.053],
          [-93.7374, 30.0373],
          [-93.7411, 30.0216],
          [-93.7869, 29.9906],
          [-93.8384, 29.8829],
          [-93.928, 29.8096],
          [-93.9265, 29.7896],
          [-93.8939, 29.7673],
          [-93.8916, 29.7446],
          [-93.87, 29.7355],
          [-93.838, 29.6906],
          [-93.8529, 29.6759],
          [-94.0014, 29.6815],
          [-94.1326, 29.6462],
          [-94.6311, 29.4515],
          [-94.6942, 29.4156],
          [-94.731, 29.3691],
          [-94.7831, 29.3756],
          [-94.7064, 29.4368],
          [-94.6815, 29.4714],
          [-94.6086, 29.4833],
          [-94.5667, 29.532],
          [-94.5323, 29.5178],
          [-94.495, 29.525],
          [-94.554, 29.5739],
          [-94.7407, 29.5259],
          [-94.7809, 29.5311],
          [-94.7087, 29.6252],
          [-94.6953, 29.7231],
          [-94.7353, 29.7854],
          [-94.8161, 29.7567],
          [-94.8511, 29.7214],
          [-94.8726, 29.6712],
          [-94.9213, 29.6582],
          [-94.9361, 29.6927],
          [-94.966, 29.7003],
          [-95.0156, 29.6395],
          [-94.9827, 29.6013],
          [-95.0183, 29.5549],
          [-94.9819, 29.5111],
          [-94.9095, 29.4968],
          [-94.9309, 29.4505],
          [-94.8908, 29.4334],
          [-94.8865, 29.3664],
          [-94.894, 29.3082],
          [-94.876, 29.293],
          [-94.825, 29.306],
          [-94.8223, 29.3443],
          [-94.7311, 29.3318],
          [-94.8037, 29.2792],
          [-95.0818, 29.1112],
          [-95.1251, 29.0673],
          [-95.2389, 28.9886],
          [-95.2971, 28.9341],
          [-95.3535, 28.8981],
          [-95.3824, 28.8663],
          [-95.4396, 28.859],
          [-95.8125, 28.6649],
          [-96.2204, 28.492],
          [-96.215, 28.5097],
          [-96.0462, 28.587],
          [-95.9862, 28.6063],
          [-95.9785, 28.6506],
          [-96.0477, 28.6491],
          [-96.2218, 28.5804],
          [-96.2309, 28.6414],
          [-96.1958, 28.6989],
          [-96.2879, 28.6832],
          [-96.3037, 28.645],
          [-96.3902, 28.6318],
          [-96.4054, 28.6658],
          [-96.4087, 28.6948],
          [-96.4399, 28.7187],
          [-96.4423, 28.7427],
          [-96.4456, 28.7669],
          [-96.3814, 28.7727],
          [-96.3153, 28.8291],
          [-96.3128, 28.9215],
          [-96.3516, 28.937],
          [-96.3386, 28.9887],
          [-96.6585, 29.2639],
          [-96.5606, 29.3354],
          [-96.7387, 29.5207],
          [-96.7152, 29.5104],
          [-96.7057, 29.4941],
          [-96.7015, 29.512],
          [-96.7074, 29.5173],
          [-96.6574, 29.5386],
          [-96.6516, 29.5181],
          [-96.6389, 29.5088],
          [-96.6285, 29.5129],
          [-96.6266, 29.5223],
          [-96.6241, 29.5147],
          [-96.5418, 29.5478],
          [-96.5509, 29.6185],
          [-96.5324, 29.6142],
          [-96.5328, 29.6346],
          [-96.5175, 29.6474],
          [-96.5044, 29.617],
          [-96.4639, 29.6083],
          [-96.4501, 29.593],
          [-96.4277, 29.5911],
          [-96.4175, 29.58],
          [-96.4071, 29.587],
          [-96.4039, 29.6043],
          [-96.3992, 29.6507],
          [-96.4057, 29.6613],
          [-96.3736, 29.6902],
          [-96.3433, 29.6616],
          [-96.2908, 29.6874],
          [-96.2695, 29.6891],
          [-96.2776, 29.6998],
          [-96.27, 29.7163],
          [-96.2915, 29.7285],
          [-96.2966, 29.7485],
          [-96.3485, 29.7539],
          [-96.3408, 29.7706],
          [-96.3242, 29.759],
          [-96.3367, 29.7744],
          [-96.3336, 29.7814],
          [-96.3579, 29.8014],
          [-96.3488, 29.8178],
          [-96.3391, 29.8167],
          [-96.3448, 29.8217],
          [-96.3269, 29.8467],
          [-96.3751, 29.856],
          [-96.3964, 29.868],
          [-96.4052, 29.8589],
          [-96.3982, 29.847],
          [-96.4106, 29.8386],
          [-96.4128, 29.8246],
          [-96.4411, 29.8536],
          [-96.4582, 29.8553],
          [-96.4952, 29.8752],
          [-96.4884, 29.8887],
          [-96.446, 29.8747],
          [-96.4352, 29.8779],
          [-96.4389, 29.8844],
          [-96.4262, 29.8863],
          [-96.3963, 29.8814],
          [-96.3901, 29.8998],
          [-96.3696, 29.8946],
          [-96.3576, 29.9038],
          [-96.361, 29.9171],
          [-96.3733, 29.9166],
          [-96.3841, 29.9263],
          [-96.3631, 29.9353],
          [-96.3684, 29.9419],
          [-96.3968, 29.9444],
          [-96.3902, 29.9603],
          [-96.4079, 29.9722],
          [-96.4069, 29.9789],
          [-96.4185, 29.9883],
          [-96.4481, 29.9705],
          [-96.464, 29.9805],
          [-96.4449, 29.9927],
          [-96.448, 29.9994],
          [-96.4665, 29.9917],
          [-96.4804, 29.9994],
          [-96.4842, 30.0132],
          [-96.4705, 30.0269],
          [-96.4833, 30.0373],
          [-96.4902, 30.0262],
          [-96.5022, 30.0412],
          [-96.5167, 30.0451],
          [-96.5157, 30.0585],
          [-96.5414, 30.0573],
          [-96.541, 30.065],
          [-96.586, 30.0647],
          [-96.594, 30.0711],
          [-96.622, 30.0443],
          [-96.6347, 30.0914],
          [-96.6549, 30.0757],
          [-96.6788, 30.0778],
          [-96.6653, 30.0939],
          [-96.6795, 30.1062],
          [-96.6472, 30.1452],
          [-96.6834, 30.151],
          [-96.7079, 30.1615],
          [-96.7082, 30.1841],
          [-96.7307, 30.1923],
          [-96.7442, 30.2175],
          [-96.7304, 30.2433],
          [-96.7181, 30.2504],
          [-96.7101, 30.2452],
          [-96.6856, 30.2492],
          [-96.6577, 30.2879],
          [-96.6406, 30.2967],
          [-96.6642, 30.2957],
          [-96.6949, 30.3203],
          [-96.7309, 30.3242],
          [-96.7492, 30.3183],
          [-96.7729, 30.3271],
          [-96.8011, 30.311],
          [-96.8157, 30.3118],
          [-96.8134, 30.2953],
          [-96.7892, 30.2812],
          [-96.8167, 30.2705],
          [-96.8325, 30.2845],
          [-96.8284, 30.3087],
          [-96.8446, 30.3128],
          [-96.8662, 30.3358],
          [-96.8771, 30.3302],
          [-96.8976, 30.3391],
          [-96.899, 30.3305],
          [-96.9069, 30.336],
          [-96.9225, 30.3247],
          [-96.9249, 30.3387],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "San Antonio Mkt": {
    type: "Feature",
    properties: { code: "TX_ANT", name: "San Antonio Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-96.7993, 28.2728],
            [-96.7872, 28.2501],
            [-96.81, 28.2171],
            [-96.7993, 28.2728],
          ],
        ],
        [
          [
            [-96.8554, 28.0595],
            [-96.8312, 28.1116],
            [-96.8104, 28.126],
            [-96.8203, 28.1635],
            [-96.792, 28.1887],
            [-96.7038, 28.1982],
            [-96.6519, 28.2513],
            [-96.5929, 28.297],
            [-96.5289, 28.3225],
            [-96.451, 28.337],
            [-96.4137, 28.3273],
            [-96.5478, 28.2708],
            [-96.6215, 28.2297],
            [-96.7581, 28.1369],
            [-96.8554, 28.0595],
          ],
        ],
        [
          [
            [-98.5143, 29.7422],
            [-98.5233, 29.745],
            [-98.5265, 29.7402],
            [-98.5511, 29.7606],
            [-98.5828, 29.7413],
            [-98.6461, 29.7453],
            [-98.5484, 29.8246],
            [-98.4693, 29.7915],
            [-98.3408, 29.8141],
            [-98.3443, 29.8649],
            [-98.3682, 29.888],
            [-98.3381, 29.8884],
            [-98.3312, 29.9046],
            [-98.3366, 29.9225],
            [-98.3563, 29.9872],
            [-98.325, 30.0141],
            [-98.2765, 29.9782],
            [-98.2353, 29.9691],
            [-98.2257, 29.9522],
            [-98.2026, 29.9372],
            [-98.1841, 29.9304],
            [-98.1516, 29.9343],
            [-98.0305, 29.8485],
            [-97.9993, 29.7524],
            [-97.986, 29.7654],
            [-97.9753, 29.7561],
            [-97.9482, 29.753],
            [-97.962, 29.7157],
            [-97.9251, 29.7252],
            [-97.8982, 29.6999],
            [-97.8985, 29.684],
            [-97.9008, 29.6742],
            [-97.8882, 29.6808],
            [-97.8831, 29.6684],
            [-97.8702, 29.6686],
            [-97.8446, 29.6608],
            [-97.8283, 29.649],
            [-97.8292, 29.6172],
            [-97.8049, 29.6104],
            [-97.8005, 29.5753],
            [-97.7732, 29.5728],
            [-97.7749, 29.5456],
            [-97.7224, 29.5319],
            [-97.7476, 29.4988],
            [-97.7225, 29.5051],
            [-97.6935, 29.4967],
            [-97.6713, 29.4787],
            [-97.6843, 29.4424],
            [-97.6691, 29.4413],
            [-97.6523, 29.417],
            [-97.6189, 29.4101],
            [-97.6407, 29.3987],
            [-97.6671, 29.3535],
            [-97.6292, 29.315],
            [-97.5508, 29.3839],
            [-97.5268, 29.3728],
            [-97.5183, 29.3719],
            [-97.495, 29.3727],
            [-97.5065, 29.3528],
            [-97.4776, 29.335],
            [-97.4836, 29.3156],
            [-97.4752, 29.3135],
            [-97.4613, 29.3409],
            [-97.4547, 29.3387],
            [-97.3859, 29.2885],
            [-97.387, 29.2771],
            [-97.269, 29.3635],
            [-97.2664, 29.3767],
            [-97.2844, 29.3878],
            [-97.2865, 29.4004],
            [-97.2987, 29.3874],
            [-97.3106, 29.4045],
            [-97.325, 29.3989],
            [-97.3144, 29.4195],
            [-97.3149, 29.5073],
            [-97.2576, 29.5042],
            [-97.2411, 29.5132],
            [-97.2313, 29.5081],
            [-97.2219, 29.519],
            [-97.2377, 29.5234],
            [-97.219, 29.5558],
            [-97.26, 29.5544],
            [-97.2853, 29.5669],
            [-97.2724, 29.5708],
            [-97.2675, 29.5928],
            [-97.2538, 29.6004],
            [-97.2082, 29.5853],
            [-97.2039, 29.572],
            [-97.2041, 29.5806],
            [-97.1433, 29.6281],
            [-97.0209, 29.6302],
            [-97.0208, 29.6171],
            [-96.9965, 29.6142],
            [-96.9948, 29.5898],
            [-96.9526, 29.5793],
            [-96.9377, 29.5873],
            [-96.9658, 29.5901],
            [-96.9567, 29.598],
            [-96.9356, 29.5957],
            [-96.9256, 29.5815],
            [-96.9069, 29.5815],
            [-96.9052, 29.5753],
            [-96.8535, 29.5551],
            [-96.8471, 29.5606],
            [-96.8256, 29.5587],
            [-96.8351, 29.5683],
            [-96.8329, 29.5769],
            [-96.7723, 29.5772],
            [-96.7648, 29.5479],
            [-96.5606, 29.3354],
            [-96.6585, 29.2639],
            [-96.3386, 28.9887],
            [-96.3516, 28.937],
            [-96.3128, 28.9215],
            [-96.3153, 28.8291],
            [-96.3814, 28.7727],
            [-96.4456, 28.7669],
            [-96.4423, 28.7427],
            [-96.4399, 28.7187],
            [-96.4087, 28.6948],
            [-96.4054, 28.6658],
            [-96.3902, 28.6318],
            [-96.3765, 28.6201],
            [-96.4879, 28.5697],
            [-96.4803, 28.5966],
            [-96.4996, 28.6358],
            [-96.5633, 28.6445],
            [-96.5597, 28.6913],
            [-96.5841, 28.7229],
            [-96.6645, 28.6969],
            [-96.6106, 28.6389],
            [-96.6111, 28.586],
            [-96.5653, 28.5824],
            [-96.5261, 28.558],
            [-96.5058, 28.5259],
            [-96.4197, 28.4674],
            [-96.404, 28.4424],
            [-96.5429, 28.3855],
            [-96.5918, 28.3575],
            [-96.6727, 28.3356],
            [-96.7052, 28.3488],
            [-96.7103, 28.4068],
            [-96.7684, 28.4104],
            [-96.7902, 28.3839],
            [-96.7912, 28.3191],
            [-96.9831, 28.2921],
            [-97.0029, 28.3487],
            [-97.0323, 28.3773],
            [-97.0995, 28.4723],
            [-97.0406, 28.5192],
            [-97.1037, 28.5546],
            [-97.1606, 28.5535],
            [-97.3757, 28.3885],
            [-97.4928, 28.2299],
            [-97.5232, 28.289],
            [-97.5625, 28.3119],
            [-97.533, 28.3365],
            [-97.5641, 28.354],
            [-97.5912, 28.3348],
            [-97.6073, 28.3216],
            [-97.6746, 28.3012],
            [-97.6883, 28.2605],
            [-97.7282, 28.2741],
            [-97.7927, 28.2411],
            [-97.8108, 28.2695],
            [-97.8531, 28.2587],
            [-97.9386, 28.4053],
            [-97.9779, 28.3783],
            [-98.0114, 28.4167],
            [-97.9923, 28.4724],
            [-98.0898, 28.663],
            [-98.0053, 28.6902],
            [-98.2762, 28.9752],
            [-98.314, 28.9479],
            [-98.35, 28.9555],
            [-98.3725, 29.0173],
            [-98.3308, 29.0335],
            [-98.4073, 29.1144],
            [-98.4838, 29.1407],
            [-98.5503, 29.1409],
            [-98.5688, 29.2337],
            [-98.5654, 29.2825],
            [-98.6254, 29.338],
            [-98.6314, 29.3015],
            [-98.6671, 29.2861],
            [-98.6872, 29.3277],
            [-98.7674, 29.3226],
            [-98.8048, 29.3657],
            [-98.805, 29.4295],
            [-98.8863, 29.4312],
            [-98.8627, 29.4388],
            [-98.8214, 29.4465],
            [-98.8325, 29.4781],
            [-98.8811, 29.4724],
            [-98.8853, 29.4969],
            [-98.8071, 29.5226],
            [-98.8057, 29.5544],
            [-98.7672, 29.5743],
            [-98.7515, 29.5387],
            [-98.6825, 29.5364],
            [-98.6626, 29.5926],
            [-98.6346, 29.6137],
            [-98.6759, 29.6449],
            [-98.7326, 29.6544],
            [-98.7287, 29.6805],
            [-98.7088, 29.6964],
            [-98.6485, 29.6943],
            [-98.522, 29.6717],
            [-98.5143, 29.7422],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Stockton Mkt": {
    type: "Feature",
    properties: { code: "CA_STK", name: "Stockton Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-120.2803, 39.4088],
            [-120.2807, 39.4171],
            [-120.2794, 39.437],
            [-120.2653, 39.4342],
            [-120.2356, 39.4457],
            [-120.1863, 39.4455],
            [-120.246, 39.4071],
            [-120.2589, 39.4119],
            [-120.2589, 39.4061],
            [-120.2701, 39.4161],
            [-120.2803, 39.4088],
          ],
        ],
        [
          [
            [-119.9324, 38.617],
            [-119.9327, 38.6211],
            [-119.9254, 38.608],
            [-119.9324, 38.617],
          ],
        ],
        [
          [
            [-120.1173, 38.8573],
            [-120.1113, 38.8676],
            [-120.0533, 38.8842],
            [-120.0641, 38.8675],
            [-120.0416, 38.8794],
            [-120.0425, 38.8408],
            [-120.0276, 38.8454],
            [-120.031, 38.8143],
            [-120.0554, 38.8242],
            [-120.0848, 38.8142],
            [-120.1078, 38.8394],
            [-120.1173, 38.8573],
          ],
        ],
        [
          [
            [-120.5887, 39.537],
            [-120.5892, 39.5458],
            [-120.5864, 39.5385],
            [-120.5887, 39.537],
          ],
        ],
        [
          [
            [-121.6704, 37.7894],
            [-121.6234, 37.7994],
            [-121.5978, 37.7629],
            [-121.6298, 37.7342],
            [-121.6719, 37.7397],
            [-121.6704, 37.7894],
          ],
        ],
        [
          [
            [-120.5887, 39.537],
            [-120.5879, 39.5234],
            [-120.5566, 39.5136],
            [-120.5491, 39.4945],
            [-120.5342, 39.4837],
            [-120.5343, 39.4642],
            [-120.5098, 39.4548],
            [-120.5054, 39.4461],
            [-120.455, 39.4461],
            [-120.4556, 39.4073],
            [-120.4704, 39.3949],
            [-120.4558, 39.3942],
            [-120.4563, 39.3606],
            [-120.4841, 39.3366],
            [-120.4729, 39.3364],
            [-120.4907, 39.3281],
            [-120.4945, 39.316],
            [-120.379, 39.3164],
            [-120.3805, 39.277],
            [-120.3713, 39.2685],
            [-120.3639, 39.2725],
            [-120.3719, 39.2543],
            [-120.3557, 39.2486],
            [-120.3637, 39.2417],
            [-120.352, 39.2247],
            [-120.394, 39.2154],
            [-120.4163, 39.1915],
            [-120.4376, 39.1413],
            [-120.4995, 39.1077],
            [-120.5259, 39.0654],
            [-120.5268, 39.0606],
            [-120.5172, 39.0647],
            [-120.5137, 39.0515],
            [-120.5288, 39.0284],
            [-120.5612, 39.0119],
            [-120.557, 38.9995],
            [-120.5685, 38.9901],
            [-120.5998, 38.9805],
            [-120.5411, 38.9805],
            [-120.5413, 38.9663],
            [-120.5225, 38.957],
            [-120.5475, 38.9468],
            [-120.5682, 38.9603],
            [-120.6128, 38.9536],
            [-120.6241, 38.9447],
            [-120.6059, 38.9346],
            [-120.5944, 38.9361],
            [-120.5793, 38.9169],
            [-120.5637, 38.9137],
            [-120.5448, 38.9271],
            [-120.5051, 38.935],
            [-120.4696, 38.9697],
            [-120.4483, 38.9542],
            [-120.3994, 38.9544],
            [-120.4008, 38.9524],
            [-120.3871, 38.9443],
            [-120.3336, 38.9578],
            [-120.3068, 38.9501],
            [-120.3103, 38.9374],
            [-120.2887, 38.9383],
            [-120.3098, 38.9223],
            [-120.3193, 38.8989],
            [-120.3463, 38.8819],
            [-120.322, 38.877],
            [-120.3341, 38.8745],
            [-120.3123, 38.8643],
            [-120.3003, 38.8462],
            [-120.3065, 38.8405],
            [-120.2858, 38.829],
            [-120.3087, 38.8298],
            [-120.3204, 38.8201],
            [-120.2984, 38.8249],
            [-120.2731, 38.8107],
            [-120.2619, 38.8153],
            [-120.2409, 38.8106],
            [-120.2344, 38.8018],
            [-120.1873, 38.821],
            [-120.2005, 38.8032],
            [-120.1949, 38.7907],
            [-120.1577, 38.7869],
            [-120.1368, 38.8083],
            [-120.1204, 38.8115],
            [-120.1228, 38.8057],
            [-120.112, 38.8027],
            [-120.1035, 38.8083],
            [-120.1112, 38.7995],
            [-120.1027, 38.7878],
            [-120.0672, 38.7681],
            [-120.0504, 38.7531],
            [-120.0506, 38.7406],
            [-120.0327, 38.7298],
            [-120.0479, 38.7194],
            [-120.0479, 38.726],
            [-120.078, 38.7185],
            [-120.1168, 38.7239],
            [-120.1467, 38.7054],
            [-120.1468, 38.7336],
            [-120.162, 38.7225],
            [-120.2219, 38.7111],
            [-120.2449, 38.7294],
            [-120.246, 38.7129],
            [-120.2358, 38.7158],
            [-120.2321, 38.7042],
            [-120.22, 38.7032],
            [-120.2296, 38.6814],
            [-120.2153, 38.6734],
            [-120.2145, 38.6622],
            [-120.2012, 38.6948],
            [-120.1169, 38.6792],
            [-120.1225, 38.6954],
            [-120.0983, 38.709],
            [-120.0776, 38.709],
            [-120.0825, 38.7006],
            [-120.0513, 38.7037],
            [-120.0619, 38.7009],
            [-120.0371, 38.6836],
            [-120.0332, 38.697],
            [-119.9813, 38.691],
            [-119.9663, 38.6991],
            [-119.9593, 38.6908],
            [-119.9679, 38.6629],
            [-119.9324, 38.617],
            [-119.9318, 38.607],
            [-119.9452, 38.6117],
            [-119.9755, 38.6016],
            [-119.9906, 38.5833],
            [-120.0403, 38.5627],
            [-120.0724, 38.5741],
            [-120.0726, 38.4471],
            [-120.0536, 38.4556],
            [-119.8847, 38.3562],
            [-119.8147, 38.3875],
            [-119.8017, 38.4014],
            [-119.7502, 38.4173],
            [-119.7053, 38.416],
            [-119.6936, 38.3789],
            [-119.7, 38.3652],
            [-119.6392, 38.3269],
            [-119.6425, 38.2938],
            [-119.6046, 38.2388],
            [-119.6327, 38.1989],
            [-119.5468, 38.1433],
            [-119.5065, 38.1371],
            [-119.4885, 38.1322],
            [-119.4405, 38.0957],
            [-119.397, 38.1068],
            [-119.3459, 38.0831],
            [-119.3046, 38.0239],
            [-119.3226, 37.971],
            [-119.264, 37.9265],
            [-119.2373, 37.9113],
            [-119.2133, 37.8733],
            [-119.2039, 37.7939],
            [-119.2884, 37.745],
            [-119.309, 37.778],
            [-119.5836, 37.5602],
            [-119.5842, 37.4947],
            [-119.6151, 37.4947],
            [-119.658, 37.5338],
            [-119.7206, 37.4473],
            [-119.7462, 37.446],
            [-119.7822, 37.4009],
            [-119.8764, 37.3253],
            [-119.9183, 37.3517],
            [-119.9289, 37.4066],
            [-119.9687, 37.3702],
            [-119.9986, 37.3727],
            [-120.0412, 37.3336],
            [-120.1083, 37.3145],
            [-120.1614, 37.2794],
            [-120.1432, 37.2282],
            [-120.1473, 37.1709],
            [-120.2383, 37.1753],
            [-120.2908, 37.153],
            [-120.3792, 37.2253],
            [-120.408, 37.1999],
            [-120.4191, 37.12],
            [-120.55, 37.0559],
            [-120.6041, 37.1424],
            [-120.6568, 37.186],
            [-120.8401, 37.2865],
            [-120.9264, 37.3077],
            [-120.9343, 37.3178],
            [-120.9454, 37.3224],
            [-120.9521, 37.3313],
            [-120.9464, 37.2206],
            [-120.9121, 37.1977],
            [-120.8736, 37.1436],
            [-120.9425, 37.1369],
            [-121.011, 37.1005],
            [-120.9607, 37.047],
            [-121.0006, 37.0101],
            [-121.0235, 37.0122],
            [-121.0526, 37.0175],
            [-121.0677, 37.0302],
            [-121.0971, 37.0325],
            [-121.117, 37.0231],
            [-121.1062, 37.0102],
            [-121.117, 37.0058],
            [-121.1903, 37.0178],
            [-121.2161, 37.0542],
            [-121.2096, 37.0682],
            [-121.2455, 37.0891],
            [-121.2173, 37.123],
            [-121.2371, 37.1572],
            [-121.2964, 37.1675],
            [-121.3596, 37.1845],
            [-121.3836, 37.1515],
            [-121.4222, 37.2219],
            [-121.4558, 37.2494],
            [-121.4436, 37.2966],
            [-121.4058, 37.311],
            [-121.4237, 37.3588],
            [-121.4567, 37.3955],
            [-121.4629, 37.4515],
            [-121.4863, 37.4743],
            [-121.4987, 37.5226],
            [-121.5567, 37.5427],
            [-121.5567, 37.8203],
            [-121.5361, 37.8514],
            [-121.5805, 37.862],
            [-121.5575, 37.9239],
            [-121.5812, 37.9776],
            [-121.5734, 38.0737],
            [-121.5853, 38.0964],
            [-121.6366, 38.0864],
            [-121.6731, 38.0935],
            [-121.6876, 38.1297],
            [-121.6595, 38.1833],
            [-121.69, 38.2436],
            [-121.7604, 38.2604],
            [-121.6949, 38.2553],
            [-121.727, 38.2902],
            [-121.8049, 38.2851],
            [-121.7755, 38.2609],
            [-121.7694, 38.2607],
            [-121.7874, 38.2418],
            [-121.9153, 38.2428],
            [-121.9148, 38.265],
            [-121.9709, 38.3158],
            [-122.0653, 38.3232],
            [-122.0734, 38.3609],
            [-122.1264, 38.4289],
            [-122.1033, 38.5133],
            [-122.1394, 38.6092],
            [-122.2501, 38.7553],
            [-122.288, 38.8399],
            [-122.35, 38.8358],
            [-122.3905, 38.8575],
            [-122.422, 38.9009],
            [-122.404, 38.9253],
            [-122.4091, 38.9628],
            [-122.4377, 39.0224],
            [-122.492, 39.0535],
            [-122.5009, 39.1211],
            [-122.4773, 39.174],
            [-122.5153, 39.2092],
            [-122.5702, 39.2039],
            [-122.6235, 39.2232],
            [-122.6797, 39.2245],
            [-122.6895, 39.2456],
            [-122.7575, 39.2858],
            [-122.7653, 39.3427],
            [-122.7417, 39.3589],
            [-122.7743, 39.3747],
            [-122.7391, 39.3833],
            [-122.7356, 39.5807],
            [-122.8854, 39.5801],
            [-122.8927, 39.7089],
            [-122.9384, 39.7481],
            [-122.9377, 39.7982],
            [-122.429, 39.7996],
            [-122.4083, 39.8124],
            [-122.4053, 39.8312],
            [-122.389, 39.8221],
            [-122.3484, 39.8307],
            [-122.3659, 39.8409],
            [-122.3543, 39.8603],
            [-122.3043, 39.8925],
            [-122.2791, 39.9],
            [-122.2726, 39.8593],
            [-122.2051, 39.8524],
            [-122.1973, 39.8396],
            [-122.1788, 39.8444],
            [-122.1787, 39.834],
            [-122.16, 39.8344],
            [-122.1601, 39.8285],
            [-122.0715, 39.8261],
            [-122.065, 39.8153],
            [-122.0565, 39.8257],
            [-122.0693, 39.8405],
            [-122.0431, 39.8838],
            [-121.9794, 39.8858],
            [-122.0117, 39.9128],
            [-121.9899, 39.916],
            [-121.9852, 39.9677],
            [-121.9652, 39.9785],
            [-121.953, 40.0118],
            [-121.9626, 40.0097],
            [-121.9699, 39.9898],
            [-121.9654, 40.0096],
            [-121.9834, 40.0047],
            [-122.007, 40.0321],
            [-121.9821, 40.0375],
            [-121.959, 40.0597],
            [-121.9058, 40.0586],
            [-121.8066, 40.1002],
            [-121.8867, 40.1006],
            [-121.8604, 40.1245],
            [-121.847, 40.1508],
            [-121.8159, 40.1562],
            [-121.8041, 40.1759],
            [-121.7912, 40.1815],
            [-121.7263, 40.1801],
            [-121.6686, 40.1977],
            [-121.6354, 40.2167],
            [-121.6351, 40.1888],
            [-121.6089, 40.201],
            [-121.583, 40.1956],
            [-121.5941, 40.1659],
            [-121.6222, 40.1618],
            [-121.5888, 40.1493],
            [-121.5721, 40.1267],
            [-121.5749, 40.1173],
            [-121.5513, 40.1266],
            [-121.5417, 40.1193],
            [-121.5497, 40.1004],
            [-121.4909, 40.108],
            [-121.481, 40.1224],
            [-121.453, 40.1218],
            [-121.4367, 40.1519],
            [-121.3768, 40.0861],
            [-121.3573, 40.0836],
            [-121.3445, 40.1078],
            [-121.3602, 40.1154],
            [-121.3451, 40.1174],
            [-121.3463, 40.1282],
            [-121.3326, 40.1224],
            [-121.3232, 40.1381],
            [-121.3115, 40.1286],
            [-121.2754, 40.1403],
            [-121.2748, 40.1337],
            [-121.2721, 40.1414],
            [-121.2618, 40.1421],
            [-121.2874, 40.1654],
            [-121.2273, 40.1442],
            [-121.2342, 40.1719],
            [-121.2193, 40.1653],
            [-121.2062, 40.1751],
            [-121.2093, 40.183],
            [-121.1986, 40.1882],
            [-121.1933, 40.1814],
            [-121.1901, 40.1905],
            [-121.2058, 40.2005],
            [-121.1999, 40.1981],
            [-121.2012, 40.2172],
            [-121.1796, 40.2238],
            [-121.1484, 40.2062],
            [-121.1215, 40.2038],
            [-121.0873, 40.1747],
            [-121.0727, 40.2031],
            [-121.0421, 40.2093],
            [-120.9867, 40.1925],
            [-120.9651, 40.2004],
            [-120.9587, 40.1934],
            [-120.9458, 40.1973],
            [-120.9432, 40.2065],
            [-120.9281, 40.2065],
            [-120.9286, 40.1919],
            [-120.8718, 40.1994],
            [-120.8532, 40.2488],
            [-120.8341, 40.2562],
            [-120.8309, 40.2707],
            [-120.796, 40.2777],
            [-120.7968, 40.3009],
            [-120.7738, 40.3014],
            [-120.7644, 40.316],
            [-120.7264, 40.3084],
            [-120.6522, 40.3077],
            [-120.6425, 40.2932],
            [-120.6227, 40.293],
            [-120.6228, 40.2857],
            [-120.5768, 40.2854],
            [-120.5767, 40.2638],
            [-120.5523, 40.2556],
            [-120.5495, 40.2365],
            [-120.5145, 40.2059],
            [-120.5355, 40.1939],
            [-120.5286, 40.1673],
            [-120.5495, 40.1506],
            [-120.5634, 40.1104],
            [-120.5557, 40.1069],
            [-120.5548, 40.0864],
            [-120.5399, 40.0866],
            [-120.5529, 40.0722],
            [-120.5147, 40.0635],
            [-120.5063, 40.048],
            [-120.5458, 40.0492],
            [-120.5516, 40.0441],
            [-120.4948, 40.035],
            [-120.4708, 39.9893],
            [-120.4957, 39.9756],
            [-120.507, 39.9785],
            [-120.5332, 39.9681],
            [-120.543, 39.9575],
            [-120.5688, 39.9583],
            [-120.5833, 39.9707],
            [-120.5768, 39.9811],
            [-120.5903, 39.9809],
            [-120.5941, 39.9655],
            [-120.5747, 39.9559],
            [-120.5949, 39.9618],
            [-120.5987, 39.9334],
            [-120.6496, 39.9281],
            [-120.6454, 39.9107],
            [-120.6905, 39.9245],
            [-120.7112, 39.8995],
            [-120.7324, 39.8968],
            [-120.7278, 39.881],
            [-120.7362, 39.8667],
            [-120.7708, 39.8657],
            [-120.78, 39.8769],
            [-120.8269, 39.8783],
            [-120.8581, 39.8561],
            [-120.8482, 39.8358],
            [-120.8664, 39.8286],
            [-120.8767, 39.8135],
            [-120.8873, 39.8166],
            [-120.8881, 39.8055],
            [-120.8791, 39.7964],
            [-120.8335, 39.7926],
            [-120.8337, 39.7807],
            [-120.8655, 39.7783],
            [-120.8639, 39.7716],
            [-120.8394, 39.7463],
            [-120.8263, 39.742],
            [-120.8156, 39.747],
            [-120.8256, 39.7056],
            [-120.7868, 39.6912],
            [-120.7747, 39.6738],
            [-120.7736, 39.6135],
            [-120.8109, 39.5848],
            [-120.7902, 39.595],
            [-120.7581, 39.5921],
            [-120.7528, 39.6009],
            [-120.7358, 39.593],
            [-120.6974, 39.5966],
            [-120.6695, 39.6138],
            [-120.6781, 39.5857],
            [-120.7223, 39.5688],
            [-120.7089, 39.5483],
            [-120.716, 39.5258],
            [-120.6897, 39.5254],
            [-120.6787, 39.5372],
            [-120.6252, 39.5372],
            [-120.6191, 39.5278],
            [-120.6028, 39.5277],
            [-120.5887, 39.537],
          ],
          [
            [-120.1382, 38.7652],
            [-120.1154, 38.7667],
            [-120.1462, 38.7834],
            [-120.1475, 38.7728],
            [-120.1382, 38.7652],
          ],
        ],
        [
          [
            [-122.1905, 37.3848],
            [-122.1903, 37.4299],
            [-122.1418, 37.4578],
            [-122.1394, 37.4768],
            [-122.1419, 37.4868],
            [-122.1161, 37.5054],
            [-122.0683, 37.4695],
            [-122.0867, 37.4235],
            [-122.1157, 37.4125],
            [-122.138, 37.3971],
            [-122.168, 37.3934],
            [-122.184, 37.3351],
            [-122.1905, 37.3848],
          ],
        ],
        [
          [
            [-122.3339, 37.5915],
            [-122.2627, 37.5729],
            [-122.2622, 37.5343],
            [-122.3119, 37.5242],
            [-122.352, 37.5096],
            [-122.3339, 37.5915],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "San Francisco Mkt": {
    type: "Feature",
    properties: { code: "CA_SFR", name: "San Francisco Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-121.6949, 38.2553],
            [-121.7604, 38.2604],
            [-121.7694, 38.2607],
            [-121.7755, 38.2609],
            [-121.8049, 38.2851],
            [-121.727, 38.2902],
            [-121.6949, 38.2553],
          ],
        ],
        [
          [
            [-121.7694, 38.2607],
            [-121.7604, 38.2604],
            [-121.69, 38.2436],
            [-121.6595, 38.1833],
            [-121.6876, 38.1297],
            [-121.6731, 38.0935],
            [-121.6366, 38.0864],
            [-121.5734, 38.0737],
            [-121.5812, 37.9776],
            [-121.5575, 37.9239],
            [-121.5805, 37.862],
            [-121.5361, 37.8514],
            [-121.5538, 37.8332],
            [-121.5571, 37.8155],
            [-121.5567, 37.5427],
            [-121.4987, 37.5226],
            [-121.4863, 37.4743],
            [-121.4629, 37.4515],
            [-121.4567, 37.3955],
            [-121.4236, 37.3588],
            [-121.4058, 37.311],
            [-121.4436, 37.2966],
            [-121.4558, 37.2494],
            [-121.4169, 37.2113],
            [-121.406, 37.1579],
            [-121.3836, 37.1515],
            [-121.3596, 37.1845],
            [-121.2964, 37.1675],
            [-121.2371, 37.1572],
            [-121.2173, 37.123],
            [-121.2455, 37.0891],
            [-121.2082, 37.062],
            [-121.2121, 37.0553],
            [-121.2245, 37.0562],
            [-121.2245, 37.0397],
            [-121.2459, 36.983],
            [-121.2118, 36.9571],
            [-121.2334, 36.932],
            [-121.2231, 36.9139],
            [-121.1843, 36.909],
            [-121.1415, 36.8367],
            [-120.9337, 36.7519],
            [-120.5972, 36.4882],
            [-120.5966, 36.3285],
            [-120.6588, 36.3162],
            [-120.6826, 36.2941],
            [-120.673, 36.2577],
            [-120.6269, 36.2032],
            [-120.6675, 36.1384],
            [-120.7103, 36.1175],
            [-120.723, 36.0782],
            [-120.7776, 36.0681],
            [-120.7706, 36.108],
            [-120.81, 36.1293],
            [-120.9387, 36.0736],
            [-120.9117, 36.036],
            [-120.9219, 36.0131],
            [-120.9489, 36.0199],
            [-120.9664, 35.9835],
            [-121.0027, 35.9688],
            [-121.0381, 35.9153],
            [-121.0652, 35.9368],
            [-121.141, 35.9475],
            [-121.1969, 35.898],
            [-121.2093, 35.8215],
            [-121.1849, 35.7942],
            [-121.3464, 35.7952],
            [-121.4131, 35.8553],
            [-121.4623, 35.8856],
            [-121.5031, 36.0003],
            [-121.5746, 36.0252],
            [-121.6296, 36.1145],
            [-121.7172, 36.1951],
            [-121.8264, 36.2419],
            [-121.8885, 36.3028],
            [-121.9032, 36.3936],
            [-121.9416, 36.4856],
            [-121.9325, 36.5599],
            [-121.9786, 36.5805],
            [-121.9239, 36.6346],
            [-121.8891, 36.6018],
            [-121.8606, 36.6111],
            [-121.8145, 36.6829],
            [-121.7883, 36.804],
            [-121.8623, 36.9316],
            [-121.9301, 36.9782],
            [-121.9728, 36.9542],
            [-122.0124, 36.9646],
            [-122.0501, 36.9485],
            [-122.106, 36.956],
            [-122.2062, 37.0139],
            [-122.2849, 37.1017],
            [-122.3378, 37.1359],
            [-122.4051, 37.1958],
            [-122.4191, 37.2415],
            [-122.4008, 37.3592],
            [-122.4437, 37.4359],
            [-122.4521, 37.4805],
            [-122.4993, 37.4953],
            [-122.5195, 37.5373],
            [-122.5172, 37.5906],
            [-122.4941, 37.644],
            [-122.5145, 37.7808],
            [-122.4638, 37.8047],
            [-122.4075, 37.8114],
            [-122.3853, 37.7907],
            [-122.3759, 37.735],
            [-122.3932, 37.7075],
            [-122.3743, 37.6622],
            [-122.3861, 37.6377],
            [-122.3339, 37.5915],
            [-122.352, 37.5096],
            [-122.3119, 37.5242],
            [-122.2622, 37.5343],
            [-122.2465, 37.5605],
            [-122.1419, 37.4868],
            [-122.1394, 37.4768],
            [-122.1418, 37.4578],
            [-122.1903, 37.4299],
            [-122.1905, 37.3848],
            [-122.184, 37.3351],
            [-122.168, 37.3934],
            [-122.138, 37.3971],
            [-122.1157, 37.4125],
            [-122.0867, 37.4235],
            [-122.0683, 37.4695],
            [-122.1161, 37.5054],
            [-122.112, 37.5289],
            [-122.147, 37.5884],
            [-122.163, 37.6679],
            [-122.2468, 37.7219],
            [-122.2426, 37.7537],
            [-122.3308, 37.7838],
            [-122.3039, 37.8301],
            [-122.31, 37.8928],
            [-122.3859, 37.9081],
            [-122.4137, 37.9373],
            [-122.3401, 38.0037],
            [-122.3008, 38.0109],
            [-122.2629, 38.0446],
            [-122.273, 38.0744],
            [-122.3146, 38.1153],
            [-122.3964, 38.15],
            [-122.4396, 38.1169],
            [-122.49, 38.112],
            [-122.4838, 38.0718],
            [-122.4978, 38.0194],
            [-122.4628, 38.0034],
            [-122.4887, 37.9667],
            [-122.4723, 37.9026],
            [-122.4792, 37.8254],
            [-122.5054, 37.8221],
            [-122.6565, 37.9045],
            [-122.7026, 37.8938],
            [-122.7832, 37.9513],
            [-122.8214, 37.9967],
            [-122.8821, 38.0253],
            [-122.9568, 38.0287],
            [-122.9924, 38.0418],
            [-122.9491, 38.1541],
            [-122.994, 38.2376],
            [-122.9771, 38.2679],
            [-123.0243, 38.3106],
            [-123.0637, 38.3022],
            [-123.0683, 38.3599],
            [-123.1288, 38.4504],
            [-123.2023, 38.4943],
            [-123.2498, 38.511],
            [-123.3319, 38.5655],
            [-123.3719, 38.6072],
            [-123.4418, 38.6997],
            [-123.5148, 38.742],
            [-123.5418, 38.7768],
            [-123.6474, 38.8455],
            [-123.6598, 38.8725],
            [-123.7254, 38.9174],
            [-123.7389, 38.9541],
            [-123.7111, 38.9773],
            [-123.6901, 39.0312],
            [-123.7134, 39.1084],
            [-123.775, 39.2121],
            [-123.8038, 39.2788],
            [-123.8263, 39.3687],
            [-123.8147, 39.4465],
            [-123.7665, 39.5528],
            [-123.7874, 39.6046],
            [-123.7927, 39.6841],
            [-123.8295, 39.7231],
            [-123.8517, 39.832],
            [-123.8398, 39.8199],
            [-123.8059, 39.8178],
            [-123.7761, 39.8179],
            [-123.765, 39.8534],
            [-123.7348, 39.8339],
            [-123.742, 39.8431],
            [-123.7271, 39.8455],
            [-123.7256, 39.856],
            [-123.7095, 39.8386],
            [-123.6798, 39.834],
            [-123.6912, 39.8447],
            [-123.6635, 39.8567],
            [-123.6893, 39.8569],
            [-123.6967, 39.8471],
            [-123.7182, 39.8517],
            [-123.7108, 39.8746],
            [-123.7269, 39.8841],
            [-123.7101, 39.9067],
            [-123.7465, 39.9119],
            [-123.7599, 39.9044],
            [-123.7673, 39.9187],
            [-123.737, 39.9331],
            [-123.7344, 39.9496],
            [-123.6835, 39.9562],
            [-123.6705, 39.9398],
            [-123.6702, 39.9208],
            [-123.6585, 39.9249],
            [-123.6515, 39.9079],
            [-123.5988, 39.9058],
            [-123.5939, 39.9162],
            [-123.5758, 39.9158],
            [-123.5729, 39.9355],
            [-123.5845, 39.9505],
            [-123.6241, 39.9756],
            [-123.6113, 39.9857],
            [-123.5945, 39.9873],
            [-123.5944, 40.0019],
            [-123.5446, 39.977],
            [-122.934, 39.9781],
            [-122.9255, 39.9689],
            [-122.9268, 39.9451],
            [-122.9098, 39.9364],
            [-122.9227, 39.9151],
            [-122.9515, 39.9063],
            [-122.9601, 39.8452],
            [-122.9467, 39.8335],
            [-122.9377, 39.7982],
            [-122.9384, 39.7481],
            [-122.8927, 39.7089],
            [-122.8854, 39.5801],
            [-122.7356, 39.5807],
            [-122.7391, 39.3833],
            [-122.7743, 39.3747],
            [-122.7417, 39.3589],
            [-122.7653, 39.3427],
            [-122.7575, 39.2858],
            [-122.6895, 39.2456],
            [-122.6797, 39.2245],
            [-122.6235, 39.2232],
            [-122.5702, 39.2039],
            [-122.5153, 39.2092],
            [-122.4773, 39.174],
            [-122.5009, 39.1211],
            [-122.492, 39.0535],
            [-122.4377, 39.0224],
            [-122.4091, 38.9628],
            [-122.404, 38.9253],
            [-122.422, 38.9009],
            [-122.3905, 38.8575],
            [-122.35, 38.8358],
            [-122.288, 38.8399],
            [-122.2501, 38.7553],
            [-122.1394, 38.6092],
            [-122.1033, 38.5133],
            [-122.1264, 38.4289],
            [-122.0734, 38.3609],
            [-122.0653, 38.3232],
            [-121.9709, 38.3158],
            [-121.9148, 38.265],
            [-121.9153, 38.2428],
            [-121.7874, 38.2418],
            [-121.7694, 38.2607],
          ],
          [
            [-121.6719, 37.7397],
            [-121.6298, 37.7342],
            [-121.5978, 37.7629],
            [-121.6234, 37.7994],
            [-121.6704, 37.7894],
            [-121.6719, 37.7397],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Los Angeles Mkt": {
    type: "Feature",
    properties: { code: "CA_LAX", name: "Los Angeles Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-118.604, 33.4765],
            [-118.5445, 33.4741],
            [-118.4997, 33.4479],
            [-118.3703, 33.4093],
            [-118.3651, 33.3884],
            [-118.3102, 33.3358],
            [-118.3252, 33.2991],
            [-118.3748, 33.3201],
            [-118.4654, 33.3261],
            [-118.4888, 33.3566],
            [-118.4888, 33.4198],
            [-118.5634, 33.4344],
            [-118.604, 33.4765],
          ],
        ],
        [
          [
            [-118.6055, 33.031],
            [-118.569, 33.0292],
            [-118.4968, 32.9338],
            [-118.37, 32.8393],
            [-118.4256, 32.8006],
            [-118.4963, 32.8516],
            [-118.5358, 32.9063],
            [-118.6055, 33.031],
          ],
        ],
        [
          [
            [-119.5646, 34.4454],
            [-119.5876, 34.4703],
            [-119.5403, 34.4871],
            [-119.588, 34.5132],
            [-119.5876, 34.5645],
            [-119.5496, 34.6389],
            [-119.504, 34.6581],
            [-119.4487, 34.6594],
            [-119.3943, 34.6116],
            [-119.3677, 34.6364],
            [-119.3722, 34.6534],
            [-119.3625, 34.6673],
            [-119.3713, 34.6775],
            [-119.3315, 34.6953],
            [-119.2999, 34.6946],
            [-119.2243, 34.7265],
            [-119.1581, 34.7283],
            [-119.1274, 34.7151],
            [-119.0797, 34.7461],
            [-119.056, 34.7385],
            [-119.087, 34.7342],
            [-119.1183, 34.6865],
            [-119.1005, 34.6379],
            [-119.0673, 34.6488],
            [-119.0005, 34.6995],
            [-118.9412, 34.7049],
            [-118.875, 34.7325],
            [-118.848, 34.7215],
            [-118.7939, 34.611],
            [-118.7176, 34.6258],
            [-118.6607, 34.5926],
            [-118.6071, 34.5592],
            [-118.5345, 34.5788],
            [-118.5579, 34.5844],
            [-118.557, 34.6033],
            [-118.501, 34.5944],
            [-118.4568, 34.6018],
            [-118.4461, 34.6472],
            [-118.3795, 34.6448],
            [-118.3454, 34.6024],
            [-118.287, 34.5802],
            [-118.2504, 34.5362],
            [-118.2517, 34.5061],
            [-118.32, 34.4674],
            [-118.3085, 34.4406],
            [-118.3421, 34.4286],
            [-118.3347, 34.3881],
            [-118.2633, 34.3993],
            [-118.2555, 34.375],
            [-118.151, 34.3839],
            [-118.0965, 34.3805],
            [-118.1097, 34.3596],
            [-118.1095, 34.3378],
            [-118.106, 34.3153],
            [-118.0435, 34.283],
            [-118.0707, 34.2682],
            [-118.0315, 34.2479],
            [-117.9784, 34.2379],
            [-117.9767, 34.2318],
            [-117.9056, 34.2461],
            [-117.8516, 34.2402],
            [-117.8655, 34.1904],
            [-117.9811, 34.109],
            [-118.0516, 34.1044],
            [-118.0682, 34.1204],
            [-118.0912, 34.1268],
            [-118.1154, 34.1132],
            [-118.1083, 34.0717],
            [-118.1694, 34.0619],
            [-118.1699, 34.0443],
            [-118.162, 34.0371],
            [-118.0706, 34.0296],
            [-118.0222, 34.0183],
            [-117.9948, 33.9746],
            [-117.9181, 33.97],
            [-117.9258, 33.946],
            [-117.7833, 33.9464],
            [-117.6737, 33.8708],
            [-117.6554, 33.9251],
            [-117.6104, 33.9716],
            [-117.5581, 33.9885],
            [-117.5484, 33.9646],
            [-117.526, 33.9577],
            [-117.4989, 33.926],
            [-117.513, 33.8736],
            [-117.4647, 33.8099],
            [-117.4157, 33.793],
            [-117.4025, 33.7299],
            [-117.4012, 33.7012],
            [-117.4562, 33.6892],
            [-117.413, 33.659],
            [-117.5102, 33.534],
            [-117.5032, 33.5204],
            [-117.5086, 33.4697],
            [-117.5384, 33.4557],
            [-117.5785, 33.4539],
            [-117.5959, 33.3866],
            [-117.6456, 33.4407],
            [-117.7265, 33.4834],
            [-117.7849, 33.5415],
            [-117.8768, 33.5923],
            [-117.9271, 33.6055],
            [-118.0297, 33.6764],
            [-118.0889, 33.7298],
            [-118.1808, 33.7631],
            [-118.1814, 33.7174],
            [-118.2587, 33.7037],
            [-118.3172, 33.7128],
            [-118.3605, 33.7368],
            [-118.4112, 33.742],
            [-118.4284, 33.7747],
            [-118.3943, 33.8043],
            [-118.3921, 33.8409],
            [-118.4606, 33.9691],
            [-118.5195, 34.0275],
            [-118.5692, 34.0416],
            [-118.745, 34.0321],
            [-118.8051, 34.0012],
            [-118.8547, 34.0342],
            [-118.9381, 34.0434],
            [-119.0885, 34.0983],
            [-119.1302, 34.1001],
            [-119.2164, 34.1461],
            [-119.2786, 34.2669],
            [-119.313, 34.2757],
            [-119.3704, 34.3195],
            [-119.3904, 34.3182],
            [-119.461, 34.3741],
            [-119.5768, 34.4157],
            [-119.5646, 34.4454],
          ],
        ],
        [
          [
            [-119.5789, 33.2786],
            [-119.5281, 33.2849],
            [-119.4657, 33.2592],
            [-119.4296, 33.2282],
            [-119.476, 33.2155],
            [-119.5459, 33.2334],
            [-119.5789, 33.2786],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Amarillo Mkt": {
    type: "Feature",
    properties: { code: "TX_AMA", name: "Amarillo Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-102.9483, 35.3986],
          [-102.9603, 35.4226],
          [-102.9051, 35.4822],
          [-102.9458, 35.4948],
          [-102.9493, 35.5306],
          [-102.8956, 35.5498],
          [-102.9152, 35.5843],
          [-102.8917, 35.6086],
          [-102.9066, 35.6159],
          [-102.9059, 35.623],
          [-103.0416, 35.6225],
          [-103.0419, 36.5004],
          [-103.0024, 36.5005],
          [-103.0022, 37.0001],
          [-101.9741, 36.9934],
          [-101.9742, 36.9383],
          [-101.9602, 36.9508],
          [-101.9381, 36.9652],
          [-101.857, 36.9796],
          [-101.8299, 36.9361],
          [-101.6767, 36.9503],
          [-101.6859, 36.9947],
          [-100.4125, 37.0012],
          [-100.4042, 36.9934],
          [-100.3362, 36.9685],
          [-100.3395, 36.9075],
          [-100.3225, 36.8904],
          [-100.334, 36.8484],
          [-100.2879, 36.8419],
          [-100.2705, 36.7859],
          [-100.1815, 36.7617],
          [-100.1095, 36.7613],
          [-100.1458, 36.6889],
          [-100.1996, 36.6893],
          [-100.2537, 36.6597],
          [-100.2714, 36.6163],
          [-100.2892, 36.5675],
          [-100.2892, 36.5146],
          [-100.325, 36.5],
          [-100.0004, 36.4997],
          [-100.0004, 34.8031],
          [-100.0103, 34.8124],
          [-100.0462, 34.8051],
          [-100.0467, 34.774],
          [-100.1172, 34.7711],
          [-100.1183, 34.6743],
          [-100.1888, 34.6633],
          [-100.2374, 34.7174],
          [-100.3106, 34.7108],
          [-100.3293, 34.7471],
          [-100.458, 34.7475],
          [-100.4579, 34.7793],
          [-100.4843, 34.8235],
          [-100.4343, 34.8923],
          [-100.3636, 34.8926],
          [-100.3632, 34.9506],
          [-100.3976, 35.0811],
          [-100.4508, 35.0699],
          [-100.4506, 35.1194],
          [-100.4503, 35.1303],
          [-100.45, 35.1831],
          [-100.539, 35.1831],
          [-100.5393, 35.0369],
          [-100.5918, 35.0529],
          [-100.6788, 35.0629],
          [-100.6905, 35.0414],
          [-100.7099, 35.0622],
          [-100.7855, 35.053],
          [-100.7815, 35.1392],
          [-100.7564, 35.1831],
          [-100.974, 35.1832],
          [-101.0273, 35.1658],
          [-101.0863, 35.18],
          [-101.0908, 35.0756],
          [-101.116, 35.0774],
          [-101.1282, 35.136],
          [-101.1817, 35.1358],
          [-101.1867, 35.1056],
          [-101.2391, 35.1052],
          [-101.2391, 35.076],
          [-101.1867, 35.0765],
          [-101.1862, 35.0402],
          [-101.2203, 35.0414],
          [-101.2387, 35.0322],
          [-101.3087, 35.0031],
          [-101.3085, 34.973],
          [-101.2903, 34.9589],
          [-101.2316, 34.9499],
          [-101.2449, 34.8998],
          [-101.2102, 34.8843],
          [-101.2529, 34.8061],
          [-101.2797, 34.7748],
          [-101.2661, 34.7483],
          [-101.4716, 34.7475],
          [-101.4716, 34.3123],
          [-101.5649, 34.3125],
          [-101.5643, 33.9829],
          [-101.6704, 33.9777],
          [-101.6964, 33.9632],
          [-101.7658, 33.9634],
          [-101.7655, 33.9345],
          [-101.8709, 33.9343],
          [-101.8709, 33.9561],
          [-101.9579, 33.9778],
          [-101.9579, 33.9053],
          [-101.9942, 33.9139],
          [-102.0867, 33.9106],
          [-102.1194, 33.9828],
          [-102.1189, 34.0271],
          [-102.2053, 34.0718],
          [-102.2594, 34.0724],
          [-102.2977, 34.0877],
          [-102.3798, 34.0883],
          [-102.4209, 34.1036],
          [-102.4194, 34.1284],
          [-102.4469, 34.1924],
          [-102.5473, 34.1733],
          [-102.6154, 34.1775],
          [-102.6154, 34.2413],
          [-102.5444, 34.2479],
          [-102.4976, 34.2641],
          [-102.4795, 34.3133],
          [-102.4787, 34.3651],
          [-102.5411, 34.3978],
          [-102.6115, 34.3846],
          [-102.6133, 34.341],
          [-102.6483, 34.3415],
          [-102.6296, 34.3703],
          [-102.6634, 34.3997],
          [-102.7452, 34.4007],
          [-102.7369, 34.4674],
          [-102.7867, 34.4523],
          [-102.8247, 34.4445],
          [-102.8334, 34.4518],
          [-102.956, 34.4503],
          [-102.9565, 34.5255],
          [-103.0434, 34.5252],
          [-103.0425, 34.8995],
          [-103.0421, 35.3951],
          [-103.0348, 35.4032],
          [-102.9832, 35.4109],
          [-102.9483, 35.3986],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "McAllen Mkt": {
    type: "Feature",
    properties: { code: "TX_MCA", name: "McAllen Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-96.8312, 28.1116],
            [-96.8554, 28.0595],
            [-97.0014, 27.9114],
            [-97.046, 27.835],
            [-97.1163, 27.7526],
            [-97.2019, 27.6149],
            [-97.2761, 27.4721],
            [-97.3265, 27.3476],
            [-97.3709, 27.1612],
            [-97.3784, 26.9929],
            [-97.3647, 26.8717],
            [-97.333, 26.7365],
            [-97.2298, 26.4336],
            [-97.1946, 26.3065],
            [-97.1543, 26.0668],
            [-97.1698, 26.0779],
            [-97.1945, 26.2716],
            [-97.2149, 26.3536],
            [-97.2403, 26.406],
            [-97.2476, 26.4563],
            [-97.3086, 26.5767],
            [-97.3385, 26.6474],
            [-97.3458, 26.7006],
            [-97.3704, 26.7239],
            [-97.3646, 26.7671],
            [-97.3875, 26.8208],
            [-97.3916, 26.902],
            [-97.3901, 27.1565],
            [-97.3733, 27.2765],
            [-97.3576, 27.3079],
            [-97.3618, 27.36],
            [-97.3312, 27.4124],
            [-97.3173, 27.4637],
            [-97.2369, 27.5983],
            [-97.2314, 27.6323],
            [-97.2007, 27.6501],
            [-97.2031, 27.688],
            [-97.1473, 27.7354],
            [-97.1033, 27.7891],
            [-97.0926, 27.8192],
            [-97.0567, 27.8423],
            [-96.9857, 27.954],
            [-96.9867, 27.9808],
            [-96.9271, 28.0573],
            [-96.8909, 28.0768],
            [-96.875, 28.1332],
            [-96.8312, 28.1116],
          ],
        ],
        [
          [
            [-99.1695, 26.5717],
            [-99.0111, 26.675],
            [-98.9542, 26.7857],
            [-98.9544, 27.166],
            [-99.0264, 27.1586],
            [-99.0398, 27.2151],
            [-98.9546, 27.2694],
            [-99.1647, 27.2716],
            [-99.1652, 27.2914],
            [-99.1657, 27.3113],
            [-99.2079, 27.3412],
            [-99.1959, 27.3628],
            [-99.1929, 27.4376],
            [-99.177, 27.4651],
            [-99.0858, 27.4507],
            [-99.0627, 27.5048],
            [-98.9894, 27.4833],
            [-98.967, 27.5193],
            [-98.9546, 27.6781],
            [-98.9592, 27.7214],
            [-99.028, 27.7226],
            [-98.999, 27.7828],
            [-98.9998, 27.8232],
            [-98.9905, 27.8287],
            [-98.9932, 27.8399],
            [-98.9829, 27.8529],
            [-98.9394, 27.8545],
            [-98.8995, 27.917],
            [-98.8042, 27.9667],
            [-98.8033, 28.0575],
            [-98.0972, 28.0575],
            [-98.1039, 28.1325],
            [-98.0931, 28.1626],
            [-98.0531, 28.1705],
            [-97.9902, 28.2096],
            [-97.96, 28.2495],
            [-97.9726, 28.2958],
            [-97.9665, 28.2996],
            [-97.9368, 28.2745],
            [-97.8833, 28.3009],
            [-97.8531, 28.2587],
            [-97.8108, 28.2695],
            [-97.7927, 28.2411],
            [-97.7282, 28.2741],
            [-97.6883, 28.2605],
            [-97.6746, 28.3012],
            [-97.6073, 28.3216],
            [-97.5912, 28.3348],
            [-97.5641, 28.354],
            [-97.533, 28.3365],
            [-97.5625, 28.3119],
            [-97.5232, 28.289],
            [-97.4928, 28.2299],
            [-97.3757, 28.3885],
            [-97.1606, 28.5535],
            [-97.1037, 28.5546],
            [-97.0406, 28.5192],
            [-97.0995, 28.4723],
            [-97.0323, 28.3773],
            [-97.0029, 28.3487],
            [-96.9831, 28.2921],
            [-96.7912, 28.3191],
            [-96.7993, 28.2728],
            [-96.81, 28.2171],
            [-96.8727, 28.1763],
            [-96.9348, 28.1239],
            [-97.0004, 28.1376],
            [-97.0274, 28.1244],
            [-97.0355, 28.074],
            [-97.0259, 28.0419],
            [-97.1292, 27.9198],
            [-97.1872, 27.8241],
            [-97.2252, 27.8257],
            [-97.2508, 27.876],
            [-97.3251, 27.8679],
            [-97.379, 27.8379],
            [-97.3933, 27.7829],
            [-97.3684, 27.7417],
            [-97.3164, 27.7127],
            [-97.254, 27.6967],
            [-97.3215, 27.5712],
            [-97.3658, 27.4506],
            [-97.3729, 27.4012],
            [-97.4019, 27.3356],
            [-97.5083, 27.275],
            [-97.5175, 27.305],
            [-97.4839, 27.3386],
            [-97.5017, 27.3666],
            [-97.6091, 27.2852],
            [-97.6289, 27.243],
            [-97.52, 27.2312],
            [-97.4241, 27.2641],
            [-97.4437, 27.1162],
            [-97.4958, 27.0941],
            [-97.474, 27.0292],
            [-97.4785, 26.9992],
            [-97.5554, 26.9903],
            [-97.5554, 26.9389],
            [-97.5401, 26.901],
            [-97.5633, 26.8422],
            [-97.5098, 26.8035],
            [-97.4717, 26.7587],
            [-97.4457, 26.6094],
            [-97.417, 26.5536],
            [-97.4414, 26.4554],
            [-97.4116, 26.4473],
            [-97.3696, 26.3946],
            [-97.389, 26.3659],
            [-97.3304, 26.3506],
            [-97.347, 26.3114],
            [-97.3439, 26.2674],
            [-97.2966, 26.2007],
            [-97.3068, 26.1595],
            [-97.2709, 26.0865],
            [-97.1997, 26.077],
            [-97.1951, 26.0419],
            [-97.2261, 26.0244],
            [-97.217, 25.9938],
            [-97.1672, 26.0071],
            [-97.1827, 26.0531],
            [-97.152, 26.0621],
            [-97.1478, 25.9531],
            [-97.1784, 25.9621],
            [-97.2767, 25.9521],
            [-97.366, 25.9024],
            [-97.3729, 25.8401],
            [-97.4451, 25.85],
            [-97.4547, 25.8793],
            [-97.5218, 25.8865],
            [-97.5464, 25.9341],
            [-97.5826, 25.9379],
            [-97.6492, 26.0215],
            [-97.7588, 26.0321],
            [-97.8013, 26.06],
            [-97.8605, 26.0529],
            [-97.8865, 26.0663],
            [-97.9674, 26.0517],
            [-98.0288, 26.0665],
            [-98.0392, 26.0413],
            [-98.1055, 26.0675],
            [-98.1466, 26.0494],
            [-98.1779, 26.0747],
            [-98.2488, 26.0731],
            [-98.3238, 26.1212],
            [-98.3546, 26.153],
            [-98.3867, 26.1579],
            [-98.451, 26.2199],
            [-98.5006, 26.2138],
            [-98.5852, 26.2544],
            [-98.6542, 26.236],
            [-98.7491, 26.3217],
            [-98.7898, 26.3316],
            [-98.8073, 26.3694],
            [-98.891, 26.3576],
            [-98.9676, 26.3983],
            [-99.008, 26.3955],
            [-99.0391, 26.4129],
            [-99.082, 26.3965],
            [-99.1138, 26.434],
            [-99.0916, 26.477],
            [-99.1278, 26.5252],
            [-99.1667, 26.5361],
            [-99.1695, 26.5717],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "El Paso Mkt": {
    type: "Feature",
    properties: { code: "TX_ELP", name: "El Paso Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-108.9824, 32.6099],
          [-108.9285, 32.5548],
          [-108.815, 32.5841],
          [-108.8221, 32.6002],
          [-108.8531, 32.6036],
          [-108.8544, 32.7773],
          [-109.0471, 32.7776],
          [-109.0467, 33.6172],
          [-109.0105, 33.5888],
          [-108.9653, 33.5251],
          [-108.9055, 33.5137],
          [-108.8669, 33.5226],
          [-108.8659, 33.5525],
          [-108.8062, 33.5928],
          [-108.7913, 33.6444],
          [-108.794, 33.6125],
          [-108.7484, 33.615],
          [-108.7293, 33.5654],
          [-108.6882, 33.5631],
          [-108.661, 33.5372],
          [-108.6685, 33.5083],
          [-108.6507, 33.5052],
          [-108.6401, 33.4797],
          [-108.6002, 33.4551],
          [-108.5405, 33.4957],
          [-108.5066, 33.478],
          [-108.4442, 33.4919],
          [-108.3785, 33.4943],
          [-108.3683, 33.5093],
          [-108.3042, 33.4961],
          [-108.2896, 33.5467],
          [-108.2155, 33.5396],
          [-108.1661, 33.5182],
          [-108.1279, 33.4774],
          [-108.0638, 33.4972],
          [-108.117, 33.4608],
          [-108.1013, 33.4205],
          [-108.0006, 33.4481],
          [-108.0006, 33.2016],
          [-107.8666, 33.202],
          [-107.8432, 33.1845],
          [-107.8531, 33.0392],
          [-107.8144, 33.0344],
          [-107.7298, 33.0583],
          [-107.7065, 33.0458],
          [-107.6427, 33.0589],
          [-107.5676, 33.0371],
          [-107.5944, 33.0052],
          [-107.5788, 32.98],
          [-107.5366, 32.9691],
          [-107.5524, 32.9239],
          [-107.4954, 32.9458],
          [-107.3549, 32.9126],
          [-107.3557, 32.9054],
          [-107.3961, 32.9047],
          [-107.3921, 32.9013],
          [-107.404, 32.8918],
          [-107.4233, 32.8879],
          [-107.4053, 32.8685],
          [-107.5435, 32.8765],
          [-107.5327, 32.8481],
          [-107.547, 32.7587],
          [-107.5266, 32.7409],
          [-107.5279, 32.6931],
          [-107.4394, 32.6733],
          [-107.4607, 32.65],
          [-107.5072, 32.6486],
          [-107.4884, 32.6051],
          [-107.2995, 32.6055],
          [-107.2986, 32.2894],
          [-107.2573, 32.3095],
          [-107.1679, 32.3261],
          [-107.1391, 32.341],
          [-107.0772, 32.4096],
          [-107.0982, 32.4526],
          [-107.0452, 32.4723],
          [-107.04, 32.5122],
          [-107.1003, 32.5476],
          [-107.0582, 32.5662],
          [-107.0232, 32.6148],
          [-106.9817, 32.5228],
          [-106.919, 32.5078],
          [-106.9076, 32.5338],
          [-106.9739, 32.6416],
          [-106.9816, 32.6919],
          [-106.9348, 32.6934],
          [-106.8767, 32.762],
          [-106.8879, 32.8228],
          [-106.3405, 33.0528],
          [-106.3407, 32.9582],
          [-106.3766, 32.9579],
          [-106.3772, 32.0813],
          [-106.2727, 32.082],
          [-106.3228, 32.0018],
          [-105.4293, 32.0006],
          [-104.0245, 32],
          [-104.1024, 31.1052],
          [-104.4939, 30.8952],
          [-104.5118, 30.856],
          [-104.4773, 30.8209],
          [-104.4474, 30.7016],
          [-104.4113, 30.6723],
          [-104.3822, 30.6867],
          [-104.3643, 30.6818],
          [-104.316, 30.6443],
          [-104.2529, 30.6374],
          [-104.2883, 30.5819],
          [-104.3485, 30.5695],
          [-104.4097, 30.5194],
          [-104.4018, 30.5133],
          [-103.9011, 30.4122],
          [-103.8007, 30.4125],
          [-103.44, 30.6659],
          [-102.5668, 30.0527],
          [-102.5669, 30.2833],
          [-102.3431, 30.2841],
          [-102.343, 30.5988],
          [-102.1384, 30.5975],
          [-102.1378, 30.656],
          [-101.7685, 30.6533],
          [-101.7605, 30.6583],
          [-101.6985, 30.6487],
          [-101.6527, 30.6204],
          [-101.6635, 30.5888],
          [-101.6466, 30.5614],
          [-101.68, 30.5217],
          [-101.7048, 30.4574],
          [-101.7293, 30.4699],
          [-101.7307, 30.4545],
          [-101.7766, 30.4678],
          [-101.8242, 30.4642],
          [-101.8924, 30.3851],
          [-101.9282, 30.3678],
          [-101.9374, 30.3118],
          [-101.9661, 30.2759],
          [-102.0171, 30.2502],
          [-102.0841, 30.2588],
          [-102.1373, 30.2376],
          [-102.1246, 30.2261],
          [-102.1013, 30.207],
          [-102.1143, 30.1905],
          [-102.1544, 30.2241],
          [-102.1706, 30.203],
          [-102.1843, 30.1857],
          [-102.1646, 30.1252],
          [-102.1711, 30.0592],
          [-102.1791, 30.0527],
          [-102.1101, 30.0431],
          [-102.0666, 30.011],
          [-102.0315, 29.9989],
          [-101.9935, 29.9491],
          [-102.0045, 29.909],
          [-101.9871, 29.868],
          [-101.9681, 29.8513],
          [-101.9844, 29.8144],
          [-102.039, 29.8027],
          [-102.05, 29.7851],
          [-102.1157, 29.7924],
          [-102.1596, 29.8144],
          [-102.1861, 29.8485],
          [-102.2275, 29.8435],
          [-102.3154, 29.8799],
          [-102.341, 29.8693],
          [-102.3867, 29.7669],
          [-102.5083, 29.7832],
          [-102.5394, 29.7516],
          [-102.5657, 29.7616],
          [-102.6301, 29.7343],
          [-102.671, 29.742],
          [-102.6935, 29.6765],
          [-102.7683, 29.5947],
          [-102.7714, 29.5485],
          [-102.8087, 29.5223],
          [-102.8073, 29.494],
          [-102.8325, 29.4331],
          [-102.8246, 29.3996],
          [-102.843, 29.358],
          [-102.8837, 29.3481],
          [-102.8883, 29.2919],
          [-102.9121, 29.2189],
          [-102.9509, 29.1768],
          [-102.9773, 29.1862],
          [-103.0404, 29.0994],
          [-103.0744, 29.0885],
          [-103.1003, 29.0577],
          [-103.1153, 28.9853],
          [-103.1662, 28.9788],
          [-103.2278, 28.9915],
          [-103.2451, 28.9802],
          [-103.331, 29.0218],
          [-103.362, 29.0189],
          [-103.4278, 29.0423],
          [-103.525, 29.1374],
          [-103.6105, 29.1658],
          [-103.6456, 29.1593],
          [-103.7247, 29.1915],
          [-103.789, 29.2575],
          [-103.8569, 29.2819],
          [-103.9752, 29.296],
          [-104.0822, 29.3459],
          [-104.1065, 29.3731],
          [-104.1437, 29.3833],
          [-104.2125, 29.4524],
          [-104.2132, 29.473],
          [-104.2642, 29.514],
          [-104.3381, 29.52],
          [-104.381, 29.5434],
          [-104.3996, 29.5723],
          [-104.5076, 29.6396],
          [-104.5398, 29.6761],
          [-104.5657, 29.7705],
          [-104.6102, 29.8191],
          [-104.6333, 29.8705],
          [-104.6798, 29.9247],
          [-104.6797, 29.9753],
          [-104.7069, 30.0507],
          [-104.685, 30.0856],
          [-104.6954, 30.1321],
          [-104.6873, 30.1795],
          [-104.7132, 30.238],
          [-104.7516, 30.2636],
          [-104.7616, 30.3011],
          [-104.8098, 30.3349],
          [-104.8176, 30.3659],
          [-104.8595, 30.3904],
          [-104.8524, 30.4188],
          [-104.899, 30.5704],
          [-104.9248, 30.6048],
          [-104.9721, 30.6103],
          [-105.0068, 30.686],
          [-105.0623, 30.6863],
          [-105.1138, 30.746],
          [-105.1576, 30.754],
          [-105.1951, 30.7921],
          [-105.2614, 30.7981],
          [-105.2872, 30.8222],
          [-105.3942, 30.853],
          [-105.3996, 30.8889],
          [-105.488, 30.9433],
          [-105.5331, 30.9849],
          [-105.5574, 30.9902],
          [-105.6033, 31.0826],
          [-105.6488, 31.1159],
          [-105.7095, 31.1364],
          [-105.7427, 31.1649],
          [-105.7733, 31.1669],
          [-105.7944, 31.2022],
          [-105.8694, 31.2886],
          [-105.9385, 31.3187],
          [-105.9539, 31.3647],
          [-106.0049, 31.3925],
          [-106.0803, 31.3987],
          [-106.1757, 31.4563],
          [-106.2058, 31.466],
          [-106.2462, 31.5412],
          [-106.2808, 31.5621],
          [-106.3035, 31.6204],
          [-106.3495, 31.6967],
          [-106.381, 31.7321],
          [-106.4515, 31.7648],
          [-106.4895, 31.7484],
          [-106.5285, 31.7839],
          [-107.0006, 31.7835],
          [-108.2084, 31.7836],
          [-108.2086, 31.3334],
          [-109.0501, 31.3325],
          [-109.0477, 32.647],
          [-108.9824, 32.6099],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Phoenix Mkt": {
    type: "Feature",
    properties: { code: "AZ_PHO", name: "Phoenix Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-114.6888, 32.7377],
          [-114.6184, 32.7282],
          [-114.5818, 32.7349],
          [-114.5392, 32.7498],
          [-114.5269, 32.7571],
          [-114.5289, 32.7963],
          [-114.469, 32.8452],
          [-114.4629, 32.9079],
          [-114.4809, 32.9353],
          [-114.4929, 32.9718],
          [-114.5113, 33.0235],
          [-114.5236, 33.031],
          [-114.5717, 33.0366],
          [-114.5898, 33.0262],
          [-114.646, 33.0489],
          [-114.6737, 33.0419],
          [-114.7065, 33.0882],
          [-114.6754, 33.1855],
          [-114.6805, 33.2736],
          [-114.7217, 33.287],
          [-114.698, 33.3524],
          [-114.7253, 33.405],
          [-114.6433, 33.4167],
          [-114.5916, 33.4994],
          [-114.5246, 33.5522],
          [-114.5403, 33.5806],
          [-114.5248, 33.6114],
          [-114.5315, 33.6751],
          [-114.4965, 33.6969],
          [-114.5049, 33.7605],
          [-114.52, 33.8254],
          [-114.5189, 33.8917],
          [-114.535, 33.9285],
          [-114.5287, 33.9478],
          [-114.4999, 33.9618],
          [-114.4383, 34.0226],
          [-114.4377, 34.0719],
          [-114.4205, 34.1035],
          [-114.3665, 34.1186],
          [-114.3208, 34.1386],
          [-114.2873, 34.1705],
          [-114.2541, 34.1738],
          [-114.1618, 34.257],
          [-114.1362, 34.2613],
          [-114.1367, 34.3043],
          [-114.0749, 34.293],
          [-114.0371, 34.2608],
          [-114.0235, 34.2678],
          [-114.0086, 34.2541],
          [-113.9291, 34.2494],
          [-113.8529, 34.252],
          [-113.7746, 34.2289],
          [-113.7341, 34.2412],
          [-113.7058, 34.2121],
          [-113.6427, 34.2275],
          [-113.6386, 34.2232],
          [-113.6057, 34.2294],
          [-113.5867, 34.2434],
          [-113.582, 34.2598],
          [-113.5464, 34.2943],
          [-113.5936, 34.33],
          [-113.6091, 34.3685],
          [-113.65, 34.4123],
          [-113.6863, 34.4194],
          [-113.7178, 34.4525],
          [-113.7596, 34.4437],
          [-113.7948, 34.4579],
          [-113.847, 34.5755],
          [-113.7919, 34.5913],
          [-113.7399, 34.6701],
          [-113.7815, 34.7364],
          [-113.7765, 34.7654],
          [-113.8047, 34.801],
          [-113.795, 34.8468],
          [-113.8094, 34.8726],
          [-113.7546, 34.8823],
          [-113.6851, 34.8542],
          [-113.6434, 34.8866],
          [-113.6085, 34.8976],
          [-113.5634, 34.915],
          [-113.4973, 34.9101],
          [-113.4785, 34.8822],
          [-113.3914, 34.8915],
          [-113.3797, 34.9505],
          [-113.3543, 34.9551],
          [-113.3507, 34.9686],
          [-113.333, 34.9641],
          [-113.3332, 34.4107],
          [-113.2691, 34.4051],
          [-113.2414, 34.4247],
          [-113.1832, 34.3688],
          [-113.1248, 34.3859],
          [-113.1248, 34.4166],
          [-113.0355, 34.433],
          [-112.987, 34.4253],
          [-112.9193, 34.3704],
          [-112.8279, 34.3793],
          [-112.8094, 34.403],
          [-112.7681, 34.3847],
          [-112.7926, 34.3533],
          [-112.765, 34.3247],
          [-112.7145, 34.3057],
          [-112.6637, 34.344],
          [-112.62, 34.3248],
          [-112.5663, 34.3253],
          [-112.5398, 34.2723],
          [-112.5369, 34.1993],
          [-112.4929, 34.1552],
          [-112.5094, 34.1121],
          [-112.4785, 34.0999],
          [-112.4578, 34.1299],
          [-112.2832, 34.1285],
          [-112.2553, 34.175],
          [-112.1976, 34.1997],
          [-112.15, 34.1481],
          [-112.1309, 34.1956],
          [-112.0744, 34.2407],
          [-112.0664, 34.2567],
          [-111.945, 34.2057],
          [-111.8895, 34.2117],
          [-111.8391, 34.1639],
          [-111.7908, 34.1407],
          [-111.7679, 34.1678],
          [-111.7122, 34.1513],
          [-111.4779, 34.1511],
          [-111.4861, 34.0954],
          [-111.4615, 34.0631],
          [-111.4977, 34.0292],
          [-111.4948, 33.9998],
          [-111.4451, 34.0012],
          [-111.4518, 33.9212],
          [-111.4052, 33.9194],
          [-111.4128, 33.8859],
          [-111.3717, 33.8185],
          [-111.3868, 33.75],
          [-111.3364, 33.7204],
          [-111.3308, 33.6882],
          [-111.2624, 33.623],
          [-111.2237, 33.6029],
          [-111.207, 33.6369],
          [-111.1534, 33.6776],
          [-110.9812, 33.3545],
          [-111.0495, 33.3028],
          [-111.0503, 33.2503],
          [-111.0768, 33.2248],
          [-111.0408, 33.1802],
          [-110.9036, 33.1802],
          [-110.9446, 33.2472],
          [-110.8644, 33.2684],
          [-110.8356, 33.2519],
          [-110.7381, 33.2519],
          [-110.7528, 33.1706],
          [-110.7064, 33.17],
          [-110.7062, 33.0936],
          [-110.6565, 33.1136],
          [-110.6338, 33.1424],
          [-110.5248, 33.1595],
          [-110.5344, 33.1673],
          [-110.5047, 33.1862],
          [-110.4651, 33.1825],
          [-110.4642, 33.1924],
          [-110.449, 33.1945],
          [-110.4486, 32.73],
          [-110.4543, 32.7272],
          [-110.4733, 32.7317],
          [-110.4789, 32.7266],
          [-110.4975, 32.7363],
          [-110.5408, 32.6973],
          [-110.5844, 32.6926],
          [-110.6035, 32.6823],
          [-110.6443, 32.7492],
          [-110.6549, 32.7345],
          [-110.6692, 32.7601],
          [-110.7104, 32.7406],
          [-110.7422, 32.7509],
          [-110.7543, 32.7636],
          [-110.7691, 32.7609],
          [-110.7952, 32.7702],
          [-110.8059, 32.7681],
          [-110.8369, 32.7179],
          [-110.8807, 32.751],
          [-110.8984, 32.7538],
          [-110.8877, 32.7219],
          [-110.8944, 32.7105],
          [-110.9276, 32.704],
          [-110.9613, 32.6733],
          [-111.0017, 32.6603],
          [-111.0588, 32.6704],
          [-111.0635, 32.6745],
          [-111.055, 32.6741],
          [-111.0509, 32.6854],
          [-111.075, 32.6854],
          [-111.0892, 32.6966],
          [-111.1178, 32.7004],
          [-111.1493, 32.6953],
          [-111.1553, 32.685],
          [-111.2067, 32.7],
          [-111.3374, 32.5918],
          [-111.2664, 32.5014],
          [-111.3042, 32.5012],
          [-111.2979, 32.506],
          [-111.3278, 32.5191],
          [-111.3126, 32.5016],
          [-111.3384, 32.5013],
          [-112.2161, 32.5068],
          [-112.185, 32.4669],
          [-112.1955, 32.4574],
          [-112.1794, 32.4519],
          [-112.18, 32.4253],
          [-112.2144, 32.386],
          [-112.2219, 32.3913],
          [-112.2469, 32.3638],
          [-112.3129, 32.352],
          [-112.3812, 32.3192],
          [-112.4349, 32.1819],
          [-112.4423, 31.9661],
          [-112.4627, 31.9469],
          [-112.4656, 31.932],
          [-112.4313, 31.8585],
          [-112.4055, 31.8569],
          [-112.3881, 31.748],
          [-113.126, 31.9728],
          [-114.8136, 32.4943],
          [-114.7997, 32.5936],
          [-114.8094, 32.6171],
          [-114.7644, 32.6427],
          [-114.7019, 32.7455],
          [-114.6888, 32.7377],
        ],
        [
          [-113.6278, 34.8593],
          [-113.6284, 34.8874],
          [-113.642, 34.869],
          [-113.6248, 34.7986],
          [-113.6278, 34.8593],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Albuquerque Mkt": {
    type: "Feature",
    properties: { code: "NM_ALB", name: "Albuquerque Mkt" },
    countryCode: ["US"],
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-109.0466, 35.3305],
          [-108.9909, 35.3357],
          [-109.0468, 35.3795],
          [-109.0463, 35.6165],
          [-108.9899, 35.6238],
          [-109.0359, 35.6491],
          [-108.9197, 35.7388],
          [-108.9585, 35.8286],
          [-108.9946, 35.8476],
          [-109.0461, 35.8287],
          [-109.0519, 35.8585],
          [-109.0818, 35.8433],
          [-109.1572, 35.9369],
          [-109.0667, 35.9905],
          [-109.0461, 35.9732],
          [-109.0454, 36.6711],
          [-108.9407, 36.7047],
          [-108.929, 36.7913],
          [-109.0059, 36.999],
          [-107.7808, 37.0003],
          [-107.7837, 37.0568],
          [-107.8459, 37.062],
          [-107.8407, 37.1229],
          [-107.7221, 37.1615],
          [-107.7255, 37.2143],
          [-107.6136, 37.2245],
          [-107.7307, 37.2539],
          [-107.6868, 37.3161],
          [-107.6306, 37.3277],
          [-107.6174, 37.4569],
          [-107.6292, 37.4829],
          [-107.6749, 37.4696],
          [-107.7827, 37.5219],
          [-107.7714, 37.6003],
          [-107.6918, 37.6396],
          [-107.4822, 37.6395],
          [-107.4651, 37.6901],
          [-107.34, 37.7458],
          [-107.2817, 37.7172],
          [-107.2135, 37.7378],
          [-107.1772, 37.7889],
          [-107.1389, 37.7847],
          [-107.1382, 37.9388],
          [-107.0678, 37.9613],
          [-107.009, 37.9465],
          [-106.968, 37.975],
          [-106.8714, 37.9419],
          [-106.8173, 37.9559],
          [-106.7667, 38.0802],
          [-106.6723, 38.0867],
          [-106.611, 38.1342],
          [-106.505, 38.3149],
          [-106.4739, 38.3221],
          [-106.4308, 38.2813],
          [-106.3351, 38.2914],
          [-106.3152, 38.3424],
          [-106.2438, 38.3522],
          [-106.2469, 38.4227],
          [-106.0755, 38.424],
          [-106.0586, 38.4569],
          [-106.019, 38.4544],
          [-105.6728, 38.1568],
          [-105.5839, 37.9672],
          [-105.4407, 37.8696],
          [-105.4947, 37.5855],
          [-105.2987, 37.6566],
          [-105.1844, 37.616],
          [-105.2183, 37.5824],
          [-105.1297, 37.4092],
          [-105.169, 37.375],
          [-105.155, 36.9953],
          [-104.0079, 36.9962],
          [-103.0022, 37.0001],
          [-103.0024, 36.5004],
          [-103.0419, 36.5004],
          [-103.0415, 35.6225],
          [-102.8917, 35.6101],
          [-102.8956, 35.5498],
          [-102.9493, 35.5306],
          [-102.9458, 35.4948],
          [-102.9051, 35.4822],
          [-102.9603, 35.4226],
          [-102.9483, 35.3986],
          [-103.0419, 35.3952],
          [-103.0426, 35.0676],
          [-103.1015, 35.0704],
          [-103.0981, 34.9578],
          [-103.2829, 34.9543],
          [-103.2835, 34.9087],
          [-103.3275, 34.9089],
          [-103.3664, 34.9687],
          [-103.4438, 34.962],
          [-103.4162, 34.974],
          [-103.4348, 34.9903],
          [-103.4051, 34.9789],
          [-103.4637, 35.1129],
          [-103.4376, 35.0926],
          [-103.419, 35.1188],
          [-103.4784, 35.1651],
          [-103.5618, 35.105],
          [-103.525, 35.0241],
          [-103.5478, 34.8942],
          [-103.6892, 34.9022],
          [-103.7605, 34.9382],
          [-103.7579, 34.836],
          [-103.5301, 34.8511],
          [-103.5656, 34.8222],
          [-103.6363, 34.8227],
          [-103.6362, 34.7794],
          [-103.7047, 34.7796],
          [-103.7063, 34.6194],
          [-103.8122, 34.6195],
          [-103.8122, 34.6926],
          [-103.9005, 34.6924],
          [-103.9714, 34.7363],
          [-103.9615, 34.846],
          [-104.0093, 34.8854],
          [-104.1295, 34.8516],
          [-104.1294, 34.7793],
          [-104.3399, 34.779],
          [-104.3397, 34.6922],
          [-104.4449, 34.6917],
          [-104.4449, 34.6048],
          [-104.892, 34.6044],
          [-104.892, 34.5831],
          [-104.9058, 33.1386],
          [-105.1621, 33.1322],
          [-105.1237, 33.0722],
          [-105.1371, 33.0275],
          [-105.0302, 33.0001],
          [-104.9701, 32.9403],
          [-105.003, 32.9096],
          [-105.121, 32.9651],
          [-105.1554, 33.0125],
          [-105.2298, 33.0134],
          [-105.2331, 33.0588],
          [-105.2743, 33.0804],
          [-105.3167, 33.078],
          [-105.3207, 32.962],
          [-105.1777, 32.8844],
          [-105.2033, 32.7944],
          [-105.1154, 32.7813],
          [-105.0817, 32.7099],
          [-105.029, 32.7015],
          [-105.0757, 32.6679],
          [-105.0833, 32.5206],
          [-104.8515, 32.5205],
          [-104.8478, 32.0005],
          [-106.323, 32.0015],
          [-106.2727, 32.082],
          [-106.3772, 32.0813],
          [-106.3766, 32.9579],
          [-106.3407, 32.9583],
          [-106.3406, 33.0528],
          [-106.8879, 32.8229],
          [-106.8767, 32.762],
          [-106.9348, 32.6934],
          [-106.9816, 32.6919],
          [-106.919, 32.5078],
          [-106.9817, 32.5228],
          [-107.0232, 32.6148],
          [-107.1003, 32.5476],
          [-107.04, 32.5122],
          [-107.0452, 32.4723],
          [-107.0981, 32.4526],
          [-107.0772, 32.4096],
          [-107.1391, 32.341],
          [-107.2986, 32.2894],
          [-107.2995, 32.6055],
          [-107.4884, 32.6051],
          [-107.5072, 32.6486],
          [-107.4394, 32.6733],
          [-107.5279, 32.6931],
          [-107.547, 32.7587],
          [-107.5435, 32.8765],
          [-107.4053, 32.8685],
          [-107.4233, 32.888],
          [-107.3549, 32.9126],
          [-107.4954, 32.9458],
          [-107.5524, 32.9239],
          [-107.5366, 32.9691],
          [-107.5944, 33.0052],
          [-107.5676, 33.0371],
          [-107.6427, 33.0589],
          [-107.8531, 33.0392],
          [-107.8432, 33.1845],
          [-108.0006, 33.2016],
          [-108.0006, 33.4481],
          [-108.1013, 33.4205],
          [-108.117, 33.4608],
          [-108.0638, 33.4972],
          [-108.1279, 33.4774],
          [-108.2155, 33.5396],
          [-108.2896, 33.5467],
          [-108.3042, 33.4961],
          [-108.5405, 33.4957],
          [-108.6002, 33.4551],
          [-108.7484, 33.615],
          [-108.794, 33.6125],
          [-108.7913, 33.6444],
          [-108.8669, 33.5226],
          [-108.9653, 33.5251],
          [-108.9798, 33.5728],
          [-109.0467, 33.6172],
          [-109.0466, 35.3305],
        ],
      ],
    },
  },
  "Fresno Mkt": {
    type: "Feature",
    properties: { code: "CA_FRS", name: "Fresno Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-119.9233, 34.0694],
            [-119.8573, 34.0713],
            [-119.8187, 34.053],
            [-119.7555, 34.0567],
            [-119.6865, 34.0198],
            [-119.6377, 34.0132],
            [-119.5932, 34.0496],
            [-119.5206, 34.0343],
            [-119.5605, 33.9955],
            [-119.6628, 33.9859],
            [-119.7212, 33.9596],
            [-119.7581, 33.9592],
            [-119.8734, 33.9804],
            [-119.8763, 34.0321],
            [-119.9233, 34.0694],
          ],
        ],
        [
          [
            [-120.2485, 33.9993],
            [-120.1673, 34.0082],
            [-120.1404, 34.026],
            [-120.0902, 34.0198],
            [-120.0111, 33.9799],
            [-119.9789, 33.9831],
            [-119.9703, 33.9444],
            [-120.1218, 33.8957],
            [-120.169, 33.9191],
            [-120.2245, 33.9891],
            [-120.2485, 33.9993],
          ],
        ],
        [
          [
            [-120.4653, 34.0384],
            [-120.4153, 34.055],
            [-120.3312, 34.0491],
            [-120.3579, 34.015],
            [-120.4653, 34.0384],
          ],
        ],
        [
          [
            [-119.5646, 34.4454],
            [-119.5768, 34.4157],
            [-119.6719, 34.4161],
            [-119.7091, 34.3954],
            [-119.7948, 34.4176],
            [-119.874, 34.4088],
            [-120.0081, 34.4604],
            [-120.0886, 34.4602],
            [-120.1412, 34.4734],
            [-120.2951, 34.4706],
            [-120.3414, 34.4588],
            [-120.4714, 34.4478],
            [-120.5114, 34.523],
            [-120.5813, 34.557],
            [-120.6226, 34.554],
            [-120.6457, 34.581],
            [-120.6004, 34.7046],
            [-120.6374, 34.7559],
            [-120.6103, 34.8582],
            [-120.6708, 34.9041],
            [-120.6299, 35.0615],
            [-120.6443, 35.1396],
            [-120.7142, 35.176],
            [-120.7861, 35.1777],
            [-120.856, 35.2065],
            [-120.8968, 35.2479],
            [-120.8627, 35.3468],
            [-120.8661, 35.393],
            [-120.9079, 35.4491],
            [-121.0034, 35.4607],
            [-121.1016, 35.5488],
            [-121.126, 35.5931],
            [-121.1667, 35.6354],
            [-121.285, 35.6741],
            [-121.3146, 35.7133],
            [-121.3158, 35.7525],
            [-121.3464, 35.7952],
            [-121.1849, 35.7942],
            [-121.2093, 35.8215],
            [-121.1969, 35.898],
            [-121.141, 35.9475],
            [-121.0652, 35.9368],
            [-121.0381, 35.9153],
            [-121.0027, 35.9688],
            [-120.9664, 35.9835],
            [-120.9489, 36.0199],
            [-120.9219, 36.0131],
            [-120.9117, 36.036],
            [-120.9387, 36.0736],
            [-120.81, 36.1293],
            [-120.7706, 36.108],
            [-120.7776, 36.0681],
            [-120.723, 36.0782],
            [-120.7103, 36.1175],
            [-120.6675, 36.1384],
            [-120.6269, 36.2032],
            [-120.673, 36.2577],
            [-120.6826, 36.2941],
            [-120.6588, 36.3162],
            [-120.5966, 36.3285],
            [-120.5972, 36.4882],
            [-120.9337, 36.7519],
            [-121.1415, 36.8367],
            [-121.1843, 36.909],
            [-121.2194, 36.912],
            [-121.2343, 36.9261],
            [-121.2118, 36.9571],
            [-121.2459, 36.983],
            [-121.2245, 37.0397],
            [-121.1903, 37.0178],
            [-121.1283, 36.985],
            [-121.117, 37.0058],
            [-121.1062, 37.0102],
            [-121.117, 37.0231],
            [-121.0971, 37.0325],
            [-121.0677, 37.0302],
            [-121.0526, 37.0175],
            [-121.0235, 37.0122],
            [-121.0006, 37.0101],
            [-120.9642, 37.0426],
            [-120.9699, 37.0565],
            [-120.9635, 37.0565],
            [-121.011, 37.1005],
            [-120.9425, 37.1369],
            [-120.8736, 37.1436],
            [-120.9121, 37.1977],
            [-120.9464, 37.2206],
            [-120.9521, 37.3313],
            [-120.9454, 37.3224],
            [-120.9343, 37.3178],
            [-120.9264, 37.3077],
            [-120.8401, 37.2865],
            [-120.6568, 37.186],
            [-120.6041, 37.1424],
            [-120.55, 37.0559],
            [-120.4191, 37.12],
            [-120.408, 37.1999],
            [-120.3792, 37.2253],
            [-120.2908, 37.153],
            [-120.2383, 37.1753],
            [-120.1473, 37.1709],
            [-120.1432, 37.2282],
            [-120.1614, 37.2794],
            [-120.1083, 37.3145],
            [-120.0412, 37.3336],
            [-119.9986, 37.3727],
            [-119.9687, 37.3702],
            [-119.9289, 37.4066],
            [-119.9183, 37.3517],
            [-119.8764, 37.3253],
            [-119.7822, 37.4009],
            [-119.7462, 37.446],
            [-119.7206, 37.4473],
            [-119.658, 37.5338],
            [-119.6151, 37.4947],
            [-119.5842, 37.4947],
            [-119.5836, 37.5602],
            [-119.309, 37.778],
            [-119.2884, 37.745],
            [-119.2039, 37.7939],
            [-119.2133, 37.8733],
            [-119.2373, 37.9113],
            [-119.264, 37.9265],
            [-119.3226, 37.971],
            [-119.3046, 38.0239],
            [-119.3459, 38.0831],
            [-119.397, 38.1068],
            [-119.4405, 38.0957],
            [-119.4885, 38.1322],
            [-119.5065, 38.1371],
            [-119.5468, 38.1433],
            [-119.6327, 38.1989],
            [-119.6046, 38.2388],
            [-119.6425, 38.2938],
            [-119.6372, 38.3279],
            [-119.6205, 38.3276],
            [-119.6072, 38.3136],
            [-119.5877, 38.3078],
            [-119.5612, 38.3234],
            [-119.5474, 38.3416],
            [-119.5533, 38.3495],
            [-119.5196, 38.3729],
            [-119.5036, 38.3536],
            [-119.4785, 38.3656],
            [-119.4522, 38.3484],
            [-119.4433, 38.36],
            [-119.4548, 38.3894],
            [-119.4466, 38.4195],
            [-119.4594, 38.4545],
            [-119.4404, 38.4503],
            [-119.4437, 38.4435],
            [-119.4265, 38.4272],
            [-119.4195, 38.4061],
            [-119.3685, 38.414],
            [-119.3708, 38.4309],
            [-119.3641, 38.4403],
            [-119.3514, 38.4519],
            [-119.3575, 38.4675],
            [-119.3489, 38.4778],
            [-119.3502, 38.5082],
            [-119.3299, 38.5359],
            [-117.2864, 37.0601],
            [-117.3037, 37.0344],
            [-117.3416, 37.0306],
            [-117.3668, 36.9987],
            [-117.333, 36.9465],
            [-117.2781, 36.8939],
            [-117.5017, 36.8961],
            [-117.4967, 36.8513],
            [-117.538, 36.7626],
            [-117.5588, 36.7517],
            [-117.5324, 36.5836],
            [-117.5124, 36.545],
            [-117.4454, 36.5035],
            [-117.3599, 36.4292],
            [-117.2852, 36.3774],
            [-117.3357, 36.3487],
            [-117.4231, 36.3394],
            [-117.3584, 36.2653],
            [-117.4079, 36.2357],
            [-117.4461, 36.2504],
            [-117.4753, 36.2321],
            [-117.4746, 36.1454],
            [-117.4389, 36.1454],
            [-117.4404, 36.0585],
            [-117.422, 36.0581],
            [-117.4215, 35.7956],
            [-117.2589, 35.795],
            [-117.259, 35.5923],
            [-117.2941, 35.5776],
            [-117.3302, 35.5344],
            [-117.3631, 35.5252],
            [-117.3699, 35.5216],
            [-117.3832, 35.4757],
            [-117.3465, 35.4462],
            [-117.4358, 35.4461],
            [-117.4348, 35.3762],
            [-117.3706, 35.3715],
            [-117.4032, 35.3308],
            [-117.4875, 35.3051],
            [-117.5386, 35.2803],
            [-117.5445, 35.2476],
            [-117.5944, 35.1853],
            [-117.6309, 35.2435],
            [-117.6331, 35.0871],
            [-117.5371, 35.0723],
            [-117.5148, 34.9901],
            [-117.6322, 34.9851],
            [-117.632, 34.8223],
            [-117.6673, 34.8225],
            [-117.667, 34.558],
            [-117.66, 34.5579],
            [-117.6502, 34.3389],
            [-117.6987, 34.3702],
            [-117.7549, 34.3829],
            [-118.1216, 34.379],
            [-118.151, 34.384],
            [-118.2555, 34.375],
            [-118.2633, 34.3993],
            [-118.3347, 34.3881],
            [-118.3421, 34.4286],
            [-118.3085, 34.4406],
            [-118.32, 34.4674],
            [-118.2517, 34.5061],
            [-118.2504, 34.5362],
            [-118.287, 34.5802],
            [-118.3454, 34.6024],
            [-118.3795, 34.6448],
            [-118.4461, 34.6472],
            [-118.4568, 34.6018],
            [-118.501, 34.5944],
            [-118.557, 34.6033],
            [-118.5579, 34.5844],
            [-118.5345, 34.5788],
            [-118.6071, 34.5592],
            [-118.6607, 34.5926],
            [-118.7176, 34.6258],
            [-118.7939, 34.611],
            [-118.848, 34.7216],
            [-118.875, 34.7325],
            [-118.9412, 34.7049],
            [-119.0005, 34.6995],
            [-119.0673, 34.6488],
            [-119.1005, 34.6379],
            [-119.1183, 34.6865],
            [-119.087, 34.7342],
            [-119.1153, 34.7174],
            [-119.1309, 34.7157],
            [-119.1581, 34.7283],
            [-119.2243, 34.7265],
            [-119.2999, 34.6946],
            [-119.3315, 34.6953],
            [-119.3713, 34.6775],
            [-119.3625, 34.6673],
            [-119.3722, 34.6534],
            [-119.3677, 34.6364],
            [-119.3943, 34.6116],
            [-119.4487, 34.6594],
            [-119.504, 34.6581],
            [-119.5496, 34.6389],
            [-119.5876, 34.5645],
            [-119.588, 34.5132],
            [-119.5403, 34.4871],
            [-119.5876, 34.4703],
            [-119.5646, 34.4454],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Ontario Mkt": {
    type: "Feature",
    properties: { code: "CA_ONT", name: "Ontario Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-117.3708, 33.4907],
          [-117.3643, 33.505],
          [-117.5097, 33.505],
          [-117.5032, 33.5204],
          [-117.5102, 33.534],
          [-117.413, 33.659],
          [-117.4562, 33.6892],
          [-117.4012, 33.7012],
          [-117.4025, 33.7299],
          [-117.4157, 33.793],
          [-117.4647, 33.8099],
          [-117.513, 33.8736],
          [-117.4989, 33.926],
          [-117.526, 33.9577],
          [-117.5484, 33.9646],
          [-117.5581, 33.9885],
          [-117.6104, 33.9716],
          [-117.6554, 33.9251],
          [-117.6737, 33.8708],
          [-117.7833, 33.9464],
          [-117.9258, 33.946],
          [-117.9181, 33.97],
          [-117.9948, 33.9746],
          [-118.0222, 34.0183],
          [-118.0706, 34.0296],
          [-118.162, 34.0371],
          [-118.1699, 34.0443],
          [-118.1694, 34.0619],
          [-118.1083, 34.0717],
          [-118.1154, 34.1132],
          [-118.0912, 34.1268],
          [-118.0682, 34.1204],
          [-118.0516, 34.1044],
          [-117.9811, 34.109],
          [-117.8655, 34.1904],
          [-117.8516, 34.2402],
          [-117.9056, 34.2461],
          [-117.9796, 34.2391],
          [-118.0315, 34.2479],
          [-118.0707, 34.2682],
          [-118.0435, 34.283],
          [-118.106, 34.3153],
          [-118.1095, 34.3378],
          [-118.1097, 34.3596],
          [-118.0965, 34.3805],
          [-117.7549, 34.3829],
          [-117.6987, 34.3702],
          [-117.6502, 34.3389],
          [-117.66, 34.5579],
          [-117.667, 34.558],
          [-117.6673, 34.8225],
          [-117.632, 34.8223],
          [-117.6322, 34.9851],
          [-117.5148, 34.9901],
          [-117.5371, 35.0723],
          [-117.6331, 35.0871],
          [-117.6309, 35.2435],
          [-117.5944, 35.1853],
          [-117.5445, 35.2476],
          [-117.5386, 35.2803],
          [-117.4875, 35.3051],
          [-117.4032, 35.3308],
          [-117.3706, 35.3715],
          [-117.4348, 35.3762],
          [-117.4358, 35.4461],
          [-117.3465, 35.4462],
          [-117.3832, 35.4757],
          [-117.3699, 35.5216],
          [-117.3631, 35.5252],
          [-117.3302, 35.5344],
          [-117.2941, 35.5776],
          [-117.259, 35.5923],
          [-117.2589, 35.795],
          [-117.4215, 35.7956],
          [-117.422, 36.0581],
          [-117.4404, 36.0585],
          [-117.4389, 36.1454],
          [-117.4746, 36.1454],
          [-117.4753, 36.2321],
          [-117.4461, 36.2504],
          [-117.4079, 36.2357],
          [-117.3584, 36.2653],
          [-117.4231, 36.3394],
          [-117.3357, 36.3487],
          [-117.2852, 36.3774],
          [-117.3599, 36.4292],
          [-117.4454, 36.5035],
          [-117.5124, 36.545],
          [-117.5324, 36.5836],
          [-117.5588, 36.7516],
          [-117.538, 36.7626],
          [-117.4967, 36.8513],
          [-117.5017, 36.8961],
          [-117.2781, 36.8939],
          [-117.333, 36.9465],
          [-117.3668, 36.9987],
          [-117.3416, 37.0305],
          [-117.3037, 37.0344],
          [-117.2864, 37.0601],
          [-114.633, 35.0021],
          [-114.6344, 34.8729],
          [-114.5868, 34.8357],
          [-114.5527, 34.7669],
          [-114.4705, 34.7114],
          [-114.4387, 34.6215],
          [-114.4242, 34.6105],
          [-114.4052, 34.5696],
          [-114.3808, 34.5297],
          [-114.3867, 34.4579],
          [-114.3737, 34.4469],
          [-114.3354, 34.45],
          [-114.2948, 34.4214],
          [-114.2261, 34.3659],
          [-114.1769, 34.3493],
          [-114.1383, 34.3032],
          [-114.1344, 34.2664],
          [-114.1391, 34.2595],
          [-114.1618, 34.257],
          [-114.2541, 34.1738],
          [-114.2873, 34.1705],
          [-114.3208, 34.1386],
          [-114.3665, 34.1186],
          [-114.4117, 34.11],
          [-114.4342, 34.0874],
          [-114.4383, 34.0226],
          [-114.4999, 33.9618],
          [-114.522, 33.9556],
          [-114.5355, 33.9347],
          [-114.5079, 33.9038],
          [-114.5189, 33.8917],
          [-114.52, 33.8254],
          [-114.5049, 33.7605],
          [-114.4965, 33.6969],
          [-114.5315, 33.6751],
          [-114.5248, 33.6114],
          [-114.5403, 33.5806],
          [-114.5246, 33.5522],
          [-114.5916, 33.4994],
          [-114.6433, 33.4167],
          [-114.7253, 33.405],
          [-114.698, 33.3524],
          [-114.7217, 33.287],
          [-114.6805, 33.2736],
          [-114.6754, 33.1855],
          [-114.7065, 33.0882],
          [-114.6737, 33.0419],
          [-114.646, 33.0489],
          [-114.5898, 33.0262],
          [-114.5752, 33.0365],
          [-114.5201, 33.03],
          [-114.4998, 33.0039],
          [-114.4929, 32.9718],
          [-114.4809, 32.9353],
          [-114.4629, 32.9079],
          [-114.469, 32.8452],
          [-114.5308, 32.7935],
          [-114.5269, 32.7571],
          [-114.5392, 32.7498],
          [-114.5818, 32.7349],
          [-114.6184, 32.7282],
          [-114.6888, 32.7377],
          [-114.7019, 32.7456],
          [-114.7196, 32.7188],
          [-116.1062, 32.6186],
          [-116.1032, 33.0345],
          [-116.0794, 33.042],
          [-116.0381, 33.0332],
          [-115.9536, 32.9862],
          [-115.9102, 32.9605],
          [-115.755, 32.8861],
          [-115.7181, 32.8809],
          [-115.7092, 32.9155],
          [-115.6956, 32.9263],
          [-115.6688, 32.9263],
          [-115.732, 33.0085],
          [-115.8287, 33.1073],
          [-115.8135, 33.1052],
          [-115.8135, 33.1188],
          [-115.8219, 33.1189],
          [-115.8134, 33.1319],
          [-115.8311, 33.1253],
          [-115.8391, 33.1313],
          [-115.8272, 33.1624],
          [-115.8341, 33.1925],
          [-115.8598, 33.2424],
          [-115.8862, 33.2588],
          [-115.8839, 33.2781],
          [-115.9043, 33.2869],
          [-115.9325, 33.243],
          [-116.0008, 33.2286],
          [-116.0008, 33.2147],
          [-116.0819, 33.2289],
          [-116.0852, 33.4259],
          [-117.0309, 33.4269],
          [-117.2415, 33.4319],
          [-117.2417, 33.4489],
          [-117.3708, 33.4907],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Laredo Mkt": {
    type: "Feature",
    properties: { code: "TX_LAR", name: "Laredo Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-98.8038, 30.075],
            [-98.7948, 30.0848],
            [-98.7847, 30.083],
            [-98.7971, 30.0717],
            [-98.8038, 30.075],
          ],
        ],
        [
          [
            [-102.137, 30.2145],
            [-102.1373, 30.2376],
            [-102.0841, 30.2588],
            [-102.0171, 30.2502],
            [-101.9661, 30.2759],
            [-101.9374, 30.3118],
            [-101.9282, 30.3678],
            [-101.8924, 30.3851],
            [-101.8242, 30.4642],
            [-101.8019, 30.4701],
            [-101.7307, 30.4545],
            [-101.7201, 30.4427],
            [-101.7007, 30.4017],
            [-101.6974, 30.3802],
            [-101.7005, 30.3457],
            [-101.7368, 30.3269],
            [-101.7584, 30.2897],
            [-101.7585, 30.2425],
            [-101.6775, 30.2464],
            [-101.6588, 30.2189],
            [-101.6135, 30.2318],
            [-101.5967, 30.2264],
            [-101.5485, 30.2546],
            [-101.5445, 30.2882],
            [-101.0052, 30.2873],
            [-100.9796, 30.2877],
            [-100.9632, 30.2796],
            [-100.9654, 30.249],
            [-100.9873, 30.2445],
            [-100.9595, 30.234],
            [-100.9752, 30.2115],
            [-101.0203, 30.1788],
            [-101.0663, 30.1804],
            [-101.0125, 30.1515],
            [-100.9932, 30.106],
            [-101.0248, 30.0909],
            [-101.0275, 30.0595],
            [-101.0063, 30.0589],
            [-100.9905, 30.0424],
            [-100.9931, 30.0687],
            [-100.9287, 30.0723],
            [-100.9305, 30.0158],
            [-100.9136, 30.0181],
            [-100.8877, 30.0471],
            [-100.8421, 30.0435],
            [-100.7978, 30.0668],
            [-100.778, 30.1262],
            [-100.7002, 30.1053],
            [-100.7004, 30.2883],
            [-100.6454, 30.2882],
            [-100.68, 30.1989],
            [-100.6538, 30.1791],
            [-100.6451, 30.1056],
            [-100.5979, 30.0897],
            [-100.5741, 30.0503],
            [-100.5556, 30.0144],
            [-100.5166, 30.0354],
            [-100.5365, 29.9962],
            [-100.4988, 29.9915],
            [-100.4373, 30.0073],
            [-100.421, 30.0191],
            [-100.4205, 30.0287],
            [-100.4384, 30.0767],
            [-100.4128, 30.1347],
            [-100.4235, 30.1497],
            [-100.3877, 30.164],
            [-100.3702, 30.1759],
            [-100.3206, 30.1425],
            [-100.3032, 30.1746],
            [-100.252, 30.2043],
            [-100.2337, 30.2283],
            [-100.2222, 30.2487],
            [-100.2452, 30.2484],
            [-100.2447, 30.2898],
            [-99.7542, 30.2906],
            [-99.3992, 30.2879],
            [-99.3953, 30.2588],
            [-99.4041, 30.2218],
            [-99.3905, 30.2268],
            [-99.3934, 30.2412],
            [-99.3869, 30.2461],
            [-99.3267, 30.2428],
            [-99.3268, 30.214],
            [-99.3014, 30.2009],
            [-99.301, 30.1344],
            [-99.2145, 30.1352],
            [-99.2269, 30.1512],
            [-99.2231, 30.1751],
            [-99.1737, 30.1861],
            [-99.1647, 30.1944],
            [-99.1202, 30.1942],
            [-99.1089, 30.153],
            [-99.1097, 30.1384],
            [-99.1248, 30.1361],
            [-98.9007, 30.1383],
            [-98.8824, 30.1307],
            [-98.8631, 30.1364],
            [-98.8381, 30.1191],
            [-98.8192, 30.122],
            [-98.8226, 30.0751],
            [-98.8107, 30.074],
            [-98.8231, 30.0592],
            [-98.8089, 30.0504],
            [-98.8298, 30.0502],
            [-98.8324, 30.0404],
            [-98.8209, 30.026],
            [-98.835, 30.0145],
            [-98.8326, 30.0079],
            [-98.8189, 30.0098],
            [-98.8132, 30.0041],
            [-98.7837, 30.0075],
            [-98.7784, 30.016],
            [-98.7742, 30.0093],
            [-98.7552, 30.0132],
            [-98.749, 30.03],
            [-98.7314, 30.0323],
            [-98.7381, 30.0667],
            [-98.751, 30.0672],
            [-98.7395, 30.0737],
            [-98.7524, 30.1048],
            [-98.7345, 30.1198],
            [-98.7302, 30.1386],
            [-98.69, 30.1387],
            [-98.6567, 30.1098],
            [-98.6734, 30.092],
            [-98.6544, 30.1001],
            [-98.6533, 30.0896],
            [-98.6404, 30.0891],
            [-98.6301, 30.0708],
            [-98.6122, 30.0875],
            [-98.6045, 30.1087],
            [-98.5769, 30.1019],
            [-98.5769, 30.0899],
            [-98.5537, 30.099],
            [-98.4846, 30.0194],
            [-98.4646, 30.0235],
            [-98.4194, 29.9986],
            [-98.4055, 30.0019],
            [-98.4115, 29.972],
            [-98.3852, 29.975],
            [-98.3805, 29.9663],
            [-98.3563, 29.9872],
            [-98.3366, 29.9225],
            [-98.3312, 29.9046],
            [-98.3381, 29.8884],
            [-98.3682, 29.888],
            [-98.3444, 29.8649],
            [-98.3408, 29.8141],
            [-98.4693, 29.7915],
            [-98.5484, 29.8246],
            [-98.6461, 29.7453],
            [-98.5828, 29.7413],
            [-98.5511, 29.7606],
            [-98.5265, 29.7402],
            [-98.5143, 29.7425],
            [-98.522, 29.6717],
            [-98.6485, 29.6943],
            [-98.7088, 29.6964],
            [-98.7287, 29.6805],
            [-98.7326, 29.6544],
            [-98.6759, 29.6449],
            [-98.6346, 29.6137],
            [-98.6626, 29.5926],
            [-98.6825, 29.5364],
            [-98.7515, 29.5387],
            [-98.7672, 29.5743],
            [-98.8057, 29.5544],
            [-98.8071, 29.5226],
            [-98.8853, 29.4969],
            [-98.8811, 29.4724],
            [-98.8325, 29.4781],
            [-98.8214, 29.4465],
            [-98.8627, 29.4388],
            [-98.8863, 29.4312],
            [-98.805, 29.4295],
            [-98.8048, 29.3657],
            [-98.7674, 29.3226],
            [-98.6872, 29.3277],
            [-98.6671, 29.2861],
            [-98.6314, 29.3015],
            [-98.6254, 29.338],
            [-98.5654, 29.2825],
            [-98.5688, 29.2337],
            [-98.5503, 29.1409],
            [-98.4838, 29.1407],
            [-98.4073, 29.1144],
            [-98.3308, 29.0335],
            [-98.3725, 29.0173],
            [-98.35, 28.9555],
            [-98.314, 28.9479],
            [-98.2762, 28.9752],
            [-98.0053, 28.6902],
            [-98.0898, 28.663],
            [-97.9923, 28.4724],
            [-98.0114, 28.4167],
            [-97.9779, 28.3783],
            [-97.9386, 28.4053],
            [-97.8833, 28.3009],
            [-97.9368, 28.2745],
            [-97.9665, 28.2996],
            [-97.9726, 28.2958],
            [-97.96, 28.2495],
            [-97.9902, 28.2096],
            [-98.0531, 28.1705],
            [-98.0931, 28.1626],
            [-98.1039, 28.1325],
            [-98.0972, 28.0575],
            [-98.8033, 28.0575],
            [-98.8042, 27.9667],
            [-98.8995, 27.917],
            [-98.9394, 27.8545],
            [-98.9757, 27.8568],
            [-98.9915, 27.8428],
            [-99.003, 27.7999],
            [-98.999, 27.7828],
            [-99.028, 27.7226],
            [-98.9592, 27.7214],
            [-98.9546, 27.6781],
            [-98.967, 27.5193],
            [-98.9894, 27.4833],
            [-99.0627, 27.5048],
            [-99.0858, 27.4507],
            [-99.177, 27.4651],
            [-99.1929, 27.4376],
            [-99.1959, 27.3628],
            [-99.2079, 27.3412],
            [-99.1657, 27.3113],
            [-99.1652, 27.2914],
            [-99.1647, 27.2716],
            [-98.9546, 27.2694],
            [-99.0398, 27.2151],
            [-99.0264, 27.1586],
            [-98.9544, 27.166],
            [-98.9542, 26.7857],
            [-99.0111, 26.675],
            [-99.1695, 26.5717],
            [-99.1781, 26.6206],
            [-99.2005, 26.6564],
            [-99.2089, 26.7248],
            [-99.24, 26.7459],
            [-99.2424, 26.7883],
            [-99.2686, 26.8432],
            [-99.3289, 26.8798],
            [-99.3247, 26.916],
            [-99.3791, 26.9345],
            [-99.3766, 26.9777],
            [-99.4155, 27.0172],
            [-99.4465, 27.023],
            [-99.4523, 27.0627],
            [-99.4292, 27.091],
            [-99.44, 27.1511],
            [-99.4263, 27.1763],
            [-99.4415, 27.2499],
            [-99.4879, 27.2607],
            [-99.5048, 27.3383],
            [-99.4875, 27.4124],
            [-99.4951, 27.4515],
            [-99.4804, 27.4816],
            [-99.5283, 27.4989],
            [-99.5122, 27.5681],
            [-99.5397, 27.6033],
            [-99.5962, 27.6399],
            [-99.6389, 27.6268],
            [-99.6689, 27.66],
            [-99.7115, 27.6584],
            [-99.7707, 27.7321],
            [-99.7963, 27.7356],
            [-99.8131, 27.774],
            [-99.8777, 27.7994],
            [-99.8772, 27.8422],
            [-99.8941, 27.8929],
            [-99.9371, 27.9405],
            [-99.9318, 27.981],
            [-99.9914, 27.9946],
            [-100.0179, 28.0648],
            [-100.0531, 28.0847],
            [-100.0834, 28.144],
            [-100.1606, 28.1682],
            [-100.2121, 28.1968],
            [-100.2203, 28.2322],
            [-100.2516, 28.2362],
            [-100.2935, 28.2785],
            [-100.2865, 28.3123],
            [-100.3444, 28.3897],
            [-100.3378, 28.443],
            [-100.3683, 28.4772],
            [-100.4114, 28.5519],
            [-100.3984, 28.5849],
            [-100.4473, 28.6093],
            [-100.4629, 28.6414],
            [-100.5004, 28.662],
            [-100.5076, 28.7406],
            [-100.533, 28.7633],
            [-100.5473, 28.8258],
            [-100.6316, 28.9028],
            [-100.6515, 28.9434],
            [-100.6459, 28.9864],
            [-100.6747, 29.0998],
            [-100.7275, 29.1291],
            [-100.7726, 29.1685],
            [-100.7671, 29.1953],
            [-100.7977, 29.2469],
            [-100.876, 29.2796],
            [-100.8868, 29.3078],
            [-101.0106, 29.3687],
            [-101.0602, 29.4587],
            [-101.1443, 29.4732],
            [-101.1738, 29.5146],
            [-101.2612, 29.5368],
            [-101.242, 29.5925],
            [-101.2653, 29.6073],
            [-101.3143, 29.5956],
            [-101.3112, 29.6485],
            [-101.3618, 29.6578],
            [-101.397, 29.734],
            [-101.4753, 29.7807],
            [-101.5227, 29.7597],
            [-101.5468, 29.797],
            [-101.5826, 29.7713],
            [-101.7066, 29.7627],
            [-101.7772, 29.7893],
            [-101.8526, 29.8019],
            [-101.9297, 29.7893],
            [-101.9844, 29.8144],
            [-101.9681, 29.8513],
            [-101.9871, 29.868],
            [-102.0045, 29.909],
            [-101.9935, 29.9491],
            [-102.0315, 29.9989],
            [-102.0666, 30.011],
            [-102.1127, 30.0443],
            [-102.1711, 30.0592],
            [-102.1646, 30.1252],
            [-102.1843, 30.1857],
            [-102.1706, 30.203],
            [-102.1544, 30.2241],
            [-102.137, 30.2145],
          ],
          [
            [-102.1367, 30.2141],
            [-102.1143, 30.1905],
            [-102.1013, 30.207],
            [-102.1246, 30.2261],
            [-102.1367, 30.2141],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Flagstaff Mkt": {
    type: "Feature",
    properties: { code: "AZ_FLA", name: "Flagstaff Mkt" },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-109.0452, 36.9939],
            [-109.0452, 36.9991],
            [-109.0059, 36.999],
            [-109.0006, 36.9612],
            [-108.9944, 36.943],
            [-108.9735, 36.9403],
            [-108.9608, 36.9014],
            [-108.9683, 36.8952],
            [-108.9639, 36.8838],
            [-108.9775, 36.865],
            [-108.9628, 36.8267],
            [-108.9419, 36.82],
            [-108.9288, 36.8002],
            [-108.9491, 36.7457],
            [-108.9407, 36.7047],
            [-109.0143, 36.6676],
            [-109.0454, 36.6711],
            [-109.0452, 36.9939],
          ],
        ],
        [
          [
            [-109.0461, 35.8287],
            [-108.9946, 35.8476],
            [-108.9584, 35.8286],
            [-108.9673, 35.8177],
            [-108.9577, 35.8163],
            [-108.9593, 35.7886],
            [-108.9197, 35.7388],
            [-109.0359, 35.6491],
            [-108.9899, 35.6238],
            [-109.0462, 35.617],
            [-109.0461, 35.8287],
          ],
        ],
        [
          [
            [-109.0463, 35.3795],
            [-108.9995, 35.3478],
            [-108.9908, 35.3357],
            [-109.0006, 35.3358],
            [-109.0085, 35.3488],
            [-109.0271, 35.3435],
            [-109.0415, 35.343],
            [-109.0385, 35.3304],
            [-109.0463, 35.3305],
            [-109.0463, 35.3795],
          ],
        ],
        [
          [
            [-113.642, 34.869],
            [-113.6284, 34.8874],
            [-113.6278, 34.8593],
            [-113.6248, 34.7986],
            [-113.642, 34.869],
          ],
        ],
        [
          [
            [-114.0506, 36.7415],
            [-114.029, 36.714],
            [-113.9959, 36.709],
            [-113.9884, 36.7224],
            [-113.9806, 36.7192],
            [-113.9789, 36.7367],
            [-113.9614, 36.7382],
            [-113.9831, 36.7799],
            [-113.9831, 36.8439],
            [-113.9958, 36.8271],
            [-114.0506, 36.8168],
            [-114.0506, 37.0004],
            [-112.5404, 37.0007],
            [-111.3675, 37.0021],
            [-111.3286, 37.021],
            [-111.3149, 37.0121],
            [-111.3041, 37.0145],
            [-111.3066, 37.0259],
            [-111.2815, 37.0419],
            [-111.3029, 37.0467],
            [-111.3017, 37.0664],
            [-111.2678, 37.0536],
            [-111.2418, 37.0208],
            [-111.2299, 37.0386],
            [-111.2473, 37.0477],
            [-111.2409, 37.0671],
            [-111.2332, 37.0697],
            [-111.2259, 37.0573],
            [-111.2167, 37.0576],
            [-111.1793, 37.1019],
            [-111.1648, 37.1053],
            [-111.1408, 37.0885],
            [-111.1209, 37.108],
            [-111.1004, 37.1026],
            [-111.0656, 37.108],
            [-111.0515, 37.0991],
            [-111.0137, 37.1157],
            [-110.9887, 37.1117],
            [-110.9821, 37.1275],
            [-110.9583, 37.1244],
            [-110.9507, 37.135],
            [-110.9549, 37.1514],
            [-110.9345, 37.1494],
            [-110.9224, 37.1782],
            [-110.8849, 37.1744],
            [-110.8774, 37.177],
            [-110.8664, 37.1676],
            [-110.8542, 37.1677],
            [-110.8369, 37.1783],
            [-110.7608, 37.172],
            [-110.7477, 37.1756],
            [-110.7002, 37.1925],
            [-110.6681, 37.2101],
            [-110.6638, 37.2348],
            [-110.713, 37.2522],
            [-110.6949, 37.2678],
            [-110.6216, 37.2246],
            [-110.5386, 37.2191],
            [-110.523, 37.2549],
            [-110.4298, 37.2703],
            [-110.4088, 37.2971],
            [-110.351, 37.284],
            [-110.3133, 37.3055],
            [-110.2864, 37.285],
            [-110.3137, 37.3049],
            [-110.3497, 37.2831],
            [-110.4066, 37.2927],
            [-110.4213, 37.2384],
            [-110.4611, 37.2626],
            [-110.5035, 37.2539],
            [-110.5234, 37.2185],
            [-110.5575, 37.2172],
            [-110.5672, 37.1035],
            [-110.5439, 37.0039],
            [-110.3476, 36.9982],
            [-110.3457, 36.9789],
            [-110.3328, 36.9694],
            [-110.3567, 36.9438],
            [-110.3068, 36.9296],
            [-110.2868, 36.9645],
            [-110.2332, 36.9851],
            [-110.2355, 36.975],
            [-110.1821, 36.9824],
            [-110.1455, 36.964],
            [-110.1137, 36.985],
            [-110.1263, 36.9985],
            [-110.0009, 36.9985],
            [-110.0007, 36.998],
            [-109.3626, 36.9992],
            [-109.3544, 37.0422],
            [-109.3223, 37.0912],
            [-109.2255, 37.0793],
            [-109.1693, 37.1323],
            [-109.1475, 37.1345],
            [-109.1177, 37.1511],
            [-109.1137, 37.1322],
            [-109.107, 37.1015],
            [-109.0836, 37.0833],
            [-109.0648, 37.0481],
            [-109.0452, 36.9991],
            [-109.0452, 36.9939],
            [-109.046, 35.9731],
            [-109.0667, 35.9905],
            [-109.111, 35.9514],
            [-109.158, 35.9352],
            [-109.0818, 35.8433],
            [-109.0519, 35.8585],
            [-109.0461, 35.8287],
            [-109.0463, 35.6165],
            [-109.0462, 35.617],
            [-109.0463, 35.3795],
            [-109.0468, 35.3795],
            [-109.0466, 35.3305],
            [-109.0463, 35.3305],
            [-109.0465, 34.9049],
            [-109.0471, 32.7776],
            [-108.8544, 32.7773],
            [-108.8531, 32.6036],
            [-108.8221, 32.6002],
            [-108.8149, 32.5841],
            [-108.9285, 32.5548],
            [-108.9824, 32.6099],
            [-109.0474, 32.6469],
            [-109.0476, 32.4264],
            [-109.9269, 32.4269],
            [-109.9276, 32.4853],
            [-109.9093, 32.4847],
            [-109.9052, 32.491],
            [-109.9304, 32.5436],
            [-109.9362, 32.5283],
            [-109.9454, 32.5385],
            [-109.9734, 32.529],
            [-109.9734, 32.5872],
            [-109.9505, 32.5819],
            [-109.9169, 32.5877],
            [-109.9092, 32.5759],
            [-109.8746, 32.5977],
            [-109.8546, 32.5918],
            [-109.8469, 32.5966],
            [-109.8313, 32.6226],
            [-109.914, 32.7049],
            [-109.9239, 32.699],
            [-109.9404, 32.7164],
            [-109.9626, 32.7118],
            [-110.0234, 32.7417],
            [-110.0406, 32.7361],
            [-110.0411, 32.746],
            [-110.0684, 32.7611],
            [-110.0431, 32.8211],
            [-110.0554, 32.8525],
            [-110.0521, 32.8634],
            [-110.0391, 32.8691],
            [-110.0402, 32.8784],
            [-110.0547, 32.878],
            [-110.0561, 32.9675],
            [-110.1427, 32.9595],
            [-110.2302, 32.9895],
            [-110.2815, 32.9888],
            [-110.3192, 32.9832],
            [-110.3359, 32.9863],
            [-110.3281, 32.9651],
            [-110.3427, 32.9567],
            [-110.3539, 32.9634],
            [-110.3495, 32.9491],
            [-110.3587, 32.9431],
            [-110.3999, 32.9447],
            [-110.4132, 32.9295],
            [-110.4182, 32.9358],
            [-110.4353, 32.9302],
            [-110.4341, 32.95],
            [-110.4494, 32.95],
            [-110.4539, 33.198],
            [-110.4988, 33.1868],
            [-110.5163, 33.1757],
            [-110.5264, 33.177],
            [-110.5345, 33.1646],
            [-110.5248, 33.1595],
            [-110.6338, 33.1424],
            [-110.6565, 33.1136],
            [-110.7062, 33.0936],
            [-110.7064, 33.17],
            [-110.7528, 33.1706],
            [-110.7381, 33.2519],
            [-110.8356, 33.2519],
            [-110.8644, 33.2684],
            [-110.9447, 33.2472],
            [-110.9036, 33.1802],
            [-111.0408, 33.1802],
            [-111.0768, 33.2248],
            [-111.0503, 33.2503],
            [-111.0495, 33.3028],
            [-110.9812, 33.3545],
            [-111.1534, 33.6776],
            [-111.207, 33.6369],
            [-111.2237, 33.6029],
            [-111.2624, 33.623],
            [-111.3308, 33.6882],
            [-111.3364, 33.7204],
            [-111.3868, 33.75],
            [-111.3717, 33.8185],
            [-111.4128, 33.8859],
            [-111.4052, 33.9194],
            [-111.4518, 33.9212],
            [-111.4451, 34.0012],
            [-111.4948, 33.9998],
            [-111.4977, 34.0292],
            [-111.4615, 34.0631],
            [-111.4861, 34.0954],
            [-111.4779, 34.1511],
            [-111.7122, 34.1513],
            [-111.7679, 34.1678],
            [-111.7908, 34.1407],
            [-111.8391, 34.1639],
            [-111.8895, 34.2117],
            [-111.945, 34.2057],
            [-112.0664, 34.2567],
            [-112.0744, 34.2407],
            [-112.1309, 34.1956],
            [-112.15, 34.1481],
            [-112.1976, 34.1997],
            [-112.2553, 34.175],
            [-112.2832, 34.1285],
            [-112.4578, 34.1299],
            [-112.4785, 34.0999],
            [-112.5094, 34.1121],
            [-112.4929, 34.1552],
            [-112.5369, 34.1993],
            [-112.5398, 34.2723],
            [-112.5663, 34.3253],
            [-112.62, 34.3248],
            [-112.6637, 34.344],
            [-112.7145, 34.3057],
            [-112.765, 34.3247],
            [-112.7926, 34.3533],
            [-112.7681, 34.3847],
            [-112.8094, 34.403],
            [-112.8279, 34.3793],
            [-112.9193, 34.3704],
            [-112.987, 34.4253],
            [-113.0355, 34.433],
            [-113.1248, 34.4166],
            [-113.1248, 34.3859],
            [-113.1832, 34.3688],
            [-113.2414, 34.4247],
            [-113.2691, 34.4051],
            [-113.3332, 34.4107],
            [-113.3333, 34.9642],
            [-113.3507, 34.9686],
            [-113.3543, 34.9551],
            [-113.3797, 34.9505],
            [-113.3914, 34.8915],
            [-113.4785, 34.8822],
            [-113.4973, 34.9101],
            [-113.5634, 34.915],
            [-113.6085, 34.8976],
            [-113.6434, 34.8866],
            [-113.6851, 34.8542],
            [-113.7546, 34.8823],
            [-113.8095, 34.8726],
            [-113.795, 34.8468],
            [-113.8047, 34.801],
            [-113.7765, 34.7654],
            [-113.7815, 34.7364],
            [-113.7399, 34.6701],
            [-113.7919, 34.5913],
            [-113.847, 34.5755],
            [-113.7948, 34.4579],
            [-113.7596, 34.4437],
            [-113.7178, 34.4525],
            [-113.6863, 34.4194],
            [-113.65, 34.4123],
            [-113.6091, 34.3685],
            [-113.5936, 34.33],
            [-113.5464, 34.2943],
            [-113.5994, 34.2333],
            [-113.6386, 34.2232],
            [-113.6427, 34.2275],
            [-113.7058, 34.2121],
            [-113.7341, 34.2412],
            [-113.7746, 34.2289],
            [-113.8529, 34.252],
            [-113.9291, 34.2494],
            [-114.0086, 34.2541],
            [-114.0235, 34.2678],
            [-114.0673, 34.29],
            [-114.1383, 34.3032],
            [-114.1769, 34.3493],
            [-114.2261, 34.3659],
            [-114.2948, 34.4214],
            [-114.3354, 34.45],
            [-114.3758, 34.4478],
            [-114.3874, 34.4605],
            [-114.3808, 34.5297],
            [-114.4052, 34.5696],
            [-114.4242, 34.6105],
            [-114.4387, 34.6215],
            [-114.4705, 34.7114],
            [-114.5527, 34.7669],
            [-114.5868, 34.8357],
            [-114.6307, 34.8664],
            [-114.6368, 34.8857],
            [-114.6319, 35.0338],
            [-114.6029, 35.0686],
            [-114.6131, 35.0831],
            [-114.6468, 35.1019],
            [-114.6299, 35.1183],
            [-114.5785, 35.1288],
            [-114.5692, 35.1835],
            [-114.6043, 35.3536],
            [-114.6271, 35.4095],
            [-114.6621, 35.4442],
            [-114.6776, 35.4897],
            [-114.6772, 35.5135],
            [-114.6569, 35.5344],
            [-114.6662, 35.5776],
            [-114.6534, 35.6108],
            [-114.6894, 35.6514],
            [-114.6806, 35.6855],
            [-114.7054, 35.7083],
            [-114.6957, 35.756],
            [-114.6989, 35.7902],
            [-114.7121, 35.8062],
            [-114.6957, 35.8306],
            [-114.6978, 35.8548],
            [-114.6774, 35.8747],
            [-114.7085, 35.9123],
            [-114.7075, 35.9281],
            [-114.7312, 35.9439],
            [-114.7299, 35.9622],
            [-114.7438, 35.9851],
            [-114.7428, 36.01],
            [-114.7297, 36.0282],
            [-114.7406, 36.041],
            [-114.7363, 36.0585],
            [-114.7556, 36.0872],
            [-114.7362, 36.1044],
            [-114.6665, 36.1173],
            [-114.6317, 36.1423],
            [-114.6167, 36.1301],
            [-114.572, 36.1516],
            [-114.5117, 36.151],
            [-114.5022, 36.1288],
            [-114.4636, 36.1397],
            [-114.4466, 36.126],
            [-114.4055, 36.1474],
            [-114.3721, 36.1431],
            [-114.3084, 36.0824],
            [-114.3156, 36.0595],
            [-114.2802, 36.0464],
            [-114.2527, 36.0202],
            [-114.2137, 36.0156],
            [-114.1768, 36.0277],
            [-114.1482, 36.028],
            [-114.1145, 36.0952],
            [-114.1231, 36.1116],
            [-114.0999, 36.1217],
            [-114.068, 36.1807],
            [-114.0468, 36.1941],
            [-114.0506, 36.7415],
          ],
          [
            [-110.8664, 37.1676],
            [-110.8719, 37.1513],
            [-110.8646, 37.166],
            [-110.8664, 37.1676],
          ],
        ],
      ],
    },
    countryCode: ["US"],
  },
  "Lubbock Mkt": {
    type: "Feature",
    properties: { code: "TX_LUB", name: "Lubbock Mkt" },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-99.2078, 32.3728],
          [-99.1869, 32.3715],
          [-99.1833, 32.3476],
          [-99.174, 32.344],
          [-99.1738, 32.3078],
          [-99.1885, 32.2743],
          [-99.1891, 32.2498],
          [-99.1346, 32.2508],
          [-99.1353, 32.2384],
          [-99.149, 32.2301],
          [-99.1437, 32.1929],
          [-99.1472, 32.1805],
          [-99.168, 32.1807],
          [-99.192, 32.1676],
          [-99.2188, 32.175],
          [-99.2245, 32.1831],
          [-99.2412, 32.1834],
          [-99.283, 32.2402],
          [-99.2952, 32.2178],
          [-99.285, 32.2109],
          [-99.2862, 32.1803],
          [-99.3017, 32.1794],
          [-99.3019, 32.1608],
          [-99.3107, 32.1533],
          [-99.3429, 32.168],
          [-99.3401, 32.1758],
          [-99.3626, 32.1756],
          [-99.363, 32.2001],
          [-99.3766, 32.1806],
          [-99.3922, 32.1794],
          [-99.4094, 32.1672],
          [-99.4063, 32.1103],
          [-99.4567, 32.1105],
          [-99.4573, 32.0801],
          [-99.4803, 32.0801],
          [-99.4828, 32.0684],
          [-99.4734, 32.0598],
          [-99.4873, 32.0408],
          [-99.507, 32.0486],
          [-99.5081, 32.0417],
          [-99.5286, 32.0372],
          [-99.5454, 32.0445],
          [-99.5653, 32.043],
          [-99.5414, 32.0423],
          [-99.5404, 32.037],
          [-99.5543, 32.019],
          [-99.5737, 32.0208],
          [-99.579, 31.9978],
          [-99.5919, 31.9976],
          [-99.5761, 31.9645],
          [-99.6, 31.9673],
          [-99.6024, 31.9536],
          [-99.7152, 31.9633],
          [-99.7162, 31.8991],
          [-99.7689, 31.9031],
          [-99.8235, 31.8889],
          [-99.8253, 31.8932],
          [-99.8765, 31.8626],
          [-99.8981, 31.862],
          [-99.9061, 31.8518],
          [-99.9002, 31.8461],
          [-99.9136, 31.8339],
          [-99.928, 31.846],
          [-99.947, 31.8497],
          [-99.9471, 31.8385],
          [-99.9658, 31.8369],
          [-99.9599, 31.849],
          [-100.0356, 31.8501],
          [-100.0507, 31.8716],
          [-100.0913, 31.8744],
          [-100.1005, 31.8864],
          [-100.0936, 31.9058],
          [-100.171, 31.9269],
          [-100.1709, 31.9184],
          [-100.1643, 31.918],
          [-100.1709, 31.8802],
          [-100.2012, 31.8851],
          [-100.2062, 31.8729],
          [-100.2229, 31.8683],
          [-100.2196, 31.8434],
          [-100.236, 31.8513],
          [-100.2361, 31.7728],
          [-100.2313, 31.7791],
          [-100.1909, 31.7502],
          [-100.118, 31.7156],
          [-100.1248, 31.6989],
          [-100.2125, 31.6984],
          [-100.2827, 31.6928],
          [-100.3002, 31.6528],
          [-100.2996, 31.5891],
          [-100.2848, 31.5865],
          [-100.294, 31.5525],
          [-100.2763, 31.5598],
          [-100.2723, 31.5426],
          [-100.2836, 31.5348],
          [-100.271, 31.5188],
          [-100.2313, 31.541],
          [-100.2178, 31.5353],
          [-100.2196, 31.5091],
          [-100.2563, 31.5267],
          [-100.2566, 31.4964],
          [-100.2681, 31.4978],
          [-100.2694, 31.4926],
          [-100.2596, 31.4909],
          [-100.236, 31.4558],
          [-100.2255, 31.4546],
          [-100.2258, 31.4761],
          [-100.158, 31.4748],
          [-100.141, 31.4595],
          [-100.1405, 31.482],
          [-100.1578, 31.4822],
          [-100.1578, 31.5042],
          [-100.112, 31.4892],
          [-100.1126, 31.4296],
          [-100.0894, 31.4141],
          [-100.056, 31.4146],
          [-100.0562, 31.3996],
          [-100.0191, 31.3873],
          [-100.0366, 31.3635],
          [-100.0392, 31.3397],
          [-100.0689, 31.3154],
          [-100.0978, 31.3052],
          [-100.0985, 31.2583],
          [-100.1135, 31.2511],
          [-100.1166, 30.4836],
          [-100.123, 30.4787],
          [-100.145, 30.484],
          [-100.1397, 30.5044],
          [-100.1674, 30.5326],
          [-100.174, 30.5256],
          [-100.1818, 30.5027],
          [-100.1671, 30.4753],
          [-100.1783, 30.4713],
          [-100.1911, 30.4484],
          [-100.1167, 30.4664],
          [-100.1167, 30.4485],
          [-100.1794, 30.4245],
          [-100.1901, 30.379],
          [-100.1574, 30.3782],
          [-100.1537, 30.3697],
          [-100.1167, 30.3729],
          [-100.1165, 30.2903],
          [-100.2448, 30.2898],
          [-100.2452, 30.2484],
          [-100.2222, 30.2487],
          [-100.2337, 30.2283],
          [-100.252, 30.2043],
          [-100.3032, 30.1746],
          [-100.3206, 30.1425],
          [-100.3702, 30.1759],
          [-100.3877, 30.164],
          [-100.4235, 30.1497],
          [-100.4128, 30.1347],
          [-100.4384, 30.0767],
          [-100.4205, 30.0287],
          [-100.4244, 30.013],
          [-100.4988, 29.9915],
          [-100.5357, 29.995],
          [-100.5166, 30.0354],
          [-100.5556, 30.0144],
          [-100.5741, 30.0503],
          [-100.5979, 30.0897],
          [-100.6451, 30.1056],
          [-100.6538, 30.1791],
          [-100.68, 30.1989],
          [-100.6454, 30.2882],
          [-100.7004, 30.2883],
          [-100.7002, 30.1053],
          [-100.778, 30.1262],
          [-100.7978, 30.0668],
          [-100.8421, 30.0435],
          [-100.8877, 30.0471],
          [-100.9181, 30.016],
          [-100.931, 30.0164],
          [-100.9287, 30.0723],
          [-100.9931, 30.0687],
          [-100.9905, 30.0424],
          [-101.0264, 30.0574],
          [-101.0248, 30.0909],
          [-100.9932, 30.106],
          [-101.0125, 30.1515],
          [-101.0663, 30.1804],
          [-101.0309, 30.1773],
          [-101.0105, 30.1832],
          [-100.9602, 30.227],
          [-100.9595, 30.234],
          [-100.9873, 30.2445],
          [-100.9654, 30.249],
          [-100.9623, 30.2782],
          [-100.9796, 30.2878],
          [-101.0052, 30.2873],
          [-101.5445, 30.2882],
          [-101.5485, 30.2546],
          [-101.5967, 30.2264],
          [-101.6135, 30.2318],
          [-101.6588, 30.2189],
          [-101.6775, 30.2464],
          [-101.7585, 30.2425],
          [-101.7584, 30.2897],
          [-101.7368, 30.3269],
          [-101.7005, 30.3457],
          [-101.6974, 30.3802],
          [-101.7007, 30.4017],
          [-101.7201, 30.4427],
          [-101.7293, 30.4699],
          [-101.7048, 30.4574],
          [-101.68, 30.5217],
          [-101.6466, 30.5614],
          [-101.6635, 30.5888],
          [-101.6542, 30.6227],
          [-101.6985, 30.6487],
          [-101.7605, 30.6583],
          [-101.7685, 30.6534],
          [-102.1378, 30.656],
          [-102.1384, 30.5975],
          [-102.343, 30.5988],
          [-102.3431, 30.2841],
          [-102.5669, 30.2833],
          [-102.5668, 30.0527],
          [-103.44, 30.6659],
          [-103.8007, 30.4125],
          [-103.9011, 30.4122],
          [-104.4097, 30.5194],
          [-104.3485, 30.5695],
          [-104.2883, 30.5819],
          [-104.2529, 30.6374],
          [-104.316, 30.6443],
          [-104.3643, 30.6818],
          [-104.3822, 30.6867],
          [-104.4113, 30.6723],
          [-104.4474, 30.7016],
          [-104.4773, 30.8209],
          [-104.5118, 30.856],
          [-104.4939, 30.8952],
          [-104.1024, 31.1052],
          [-104.0245, 32],
          [-104.8478, 32.0005],
          [-104.8515, 32.5205],
          [-105.0833, 32.5206],
          [-105.0807, 32.5861],
          [-105.0707, 32.6206],
          [-105.0567, 32.6353],
          [-105.0757, 32.6679],
          [-105.029, 32.7015],
          [-105.0817, 32.7098],
          [-105.1144, 32.7482],
          [-105.1154, 32.7813],
          [-105.2033, 32.7944],
          [-105.2013, 32.8322],
          [-105.1777, 32.8844],
          [-105.2403, 32.8982],
          [-105.2568, 32.9144],
          [-105.2512, 32.9277],
          [-105.3162, 32.962],
          [-105.3167, 33.078],
          [-105.2743, 33.0804],
          [-105.2331, 33.0588],
          [-105.2298, 33.0134],
          [-105.1554, 33.0125],
          [-105.121, 32.9651],
          [-105.0414, 32.9392],
          [-105.003, 32.9096],
          [-104.9701, 32.9403],
          [-105.0302, 33.0001],
          [-105.0607, 32.9909],
          [-105.1371, 33.0275],
          [-105.1237, 33.0722],
          [-105.1621, 33.1322],
          [-104.9058, 33.1386],
          [-104.9038, 33.3062],
          [-104.8852, 33.3063],
          [-104.8924, 33.3977],
          [-104.8931, 34.0883],
          [-104.892, 34.6044],
          [-104.4449, 34.6048],
          [-104.4449, 34.6917],
          [-104.3397, 34.6922],
          [-104.3399, 34.779],
          [-104.1294, 34.7793],
          [-104.1295, 34.8516],
          [-104.0772, 34.853],
          [-104.0731, 34.8672],
          [-104.0419, 34.8674],
          [-104.0418, 34.882],
          [-104.0093, 34.8854],
          [-103.9615, 34.846],
          [-103.9741, 34.8284],
          [-103.9714, 34.7363],
          [-103.9183, 34.7252],
          [-103.9005, 34.6924],
          [-103.8122, 34.6926],
          [-103.8122, 34.6195],
          [-103.7063, 34.6194],
          [-103.7047, 34.7796],
          [-103.6362, 34.7794],
          [-103.6363, 34.8227],
          [-103.5656, 34.8222],
          [-103.5301, 34.8511],
          [-103.6421, 34.8566],
          [-103.7037, 34.8524],
          [-103.7579, 34.836],
          [-103.7605, 34.9382],
          [-103.6892, 34.9022],
          [-103.5478, 34.8942],
          [-103.5241, 34.9231],
          [-103.525, 35.0241],
          [-103.5554, 35.0661],
          [-103.5618, 35.105],
          [-103.5085, 35.1331],
          [-103.4784, 35.1651],
          [-103.419, 35.1188],
          [-103.4376, 35.0926],
          [-103.4637, 35.1129],
          [-103.4438, 35.0932],
          [-103.4431, 35.0727],
          [-103.4335, 35.0161],
          [-103.4051, 34.9789],
          [-103.4348, 34.9903],
          [-103.4162, 34.974],
          [-103.4438, 34.962],
          [-103.4013, 34.9544],
          [-103.3664, 34.9687],
          [-103.3275, 34.9089],
          [-103.2835, 34.9087],
          [-103.2829, 34.9543],
          [-103.1015, 34.9541],
          [-103.0958, 34.9607],
          [-103.1015, 35.0704],
          [-103.0426, 35.0675],
          [-103.0431, 34.5252],
          [-102.9565, 34.5255],
          [-102.956, 34.4503],
          [-102.8334, 34.4518],
          [-102.8247, 34.4445],
          [-102.7867, 34.4523],
          [-102.7369, 34.4674],
          [-102.7452, 34.4007],
          [-102.6634, 34.3997],
          [-102.6296, 34.3703],
          [-102.6483, 34.3415],
          [-102.6133, 34.341],
          [-102.6115, 34.3846],
          [-102.5411, 34.3978],
          [-102.4787, 34.3651],
          [-102.4795, 34.3133],
          [-102.4976, 34.2641],
          [-102.5444, 34.2479],
          [-102.6154, 34.2413],
          [-102.6154, 34.1775],
          [-102.5473, 34.1733],
          [-102.4469, 34.1924],
          [-102.4194, 34.1284],
          [-102.421, 34.1036],
          [-102.3798, 34.0883],
          [-102.2977, 34.0877],
          [-102.2594, 34.0724],
          [-102.2053, 34.0718],
          [-102.1189, 34.0271],
          [-102.1194, 33.9828],
          [-102.0867, 33.9106],
          [-101.9942, 33.9138],
          [-101.9579, 33.9053],
          [-101.9579, 33.9778],
          [-101.8709, 33.9561],
          [-101.8709, 33.9343],
          [-101.7655, 33.9344],
          [-101.7658, 33.9634],
          [-101.6964, 33.9632],
          [-101.6704, 33.9777],
          [-101.5643, 33.9829],
          [-101.5649, 34.3125],
          [-101.4716, 34.3123],
          [-101.4716, 34.7475],
          [-101.2661, 34.7483],
          [-101.2797, 34.7748],
          [-101.2529, 34.8061],
          [-101.2102, 34.8843],
          [-101.2449, 34.8998],
          [-101.2316, 34.9499],
          [-101.2903, 34.9589],
          [-101.3085, 34.973],
          [-101.3087, 35.0031],
          [-101.2387, 35.0322],
          [-101.2203, 35.0414],
          [-101.1862, 35.0402],
          [-101.1867, 35.0765],
          [-101.2391, 35.076],
          [-101.2391, 35.1052],
          [-101.1867, 35.1056],
          [-101.1817, 35.1358],
          [-101.1282, 35.136],
          [-101.116, 35.0774],
          [-101.0908, 35.0756],
          [-101.0863, 35.18],
          [-101.0273, 35.1658],
          [-100.974, 35.1832],
          [-100.7564, 35.1831],
          [-100.7815, 35.1392],
          [-100.7855, 35.053],
          [-100.7099, 35.0622],
          [-100.6905, 35.0414],
          [-100.6788, 35.0629],
          [-100.5918, 35.0529],
          [-100.5393, 35.0369],
          [-100.539, 35.1831],
          [-100.45, 35.1831],
          [-100.4503, 35.1303],
          [-100.4506, 35.1194],
          [-100.4508, 35.0699],
          [-100.3976, 35.0811],
          [-100.3632, 34.9506],
          [-100.3636, 34.8926],
          [-100.4343, 34.8923],
          [-100.4843, 34.8235],
          [-100.4579, 34.7793],
          [-100.458, 34.7475],
          [-100.3293, 34.7471],
          [-100.3106, 34.7108],
          [-100.2374, 34.7174],
          [-100.1888, 34.6633],
          [-100.1183, 34.6743],
          [-100.1172, 34.7711],
          [-100.0467, 34.774],
          [-100.0462, 34.8051],
          [-100.0103, 34.8124],
          [-100.0004, 34.8031],
          [-100.0004, 34.5605],
          [-99.9232, 34.5746],
          [-99.8531, 34.5116],
          [-99.8253, 34.4976],
          [-99.7672, 34.4305],
          [-99.6965, 34.381],
          [-99.6, 34.3747],
          [-99.5697, 34.4184],
          [-99.4999, 34.4096],
          [-99.4526, 34.3883],
          [-99.4204, 34.3805],
          [-99.3969, 34.37],
          [-99.3742, 34.3162],
          [-99.4212, 34.2992],
          [-99.4285, 34.292],
          [-99.4571, 34.2923],
          [-99.4544, 34.2792],
          [-99.4754, 34.274],
          [-99.4745, 33.7335],
          [-99.476, 33.7519],
          [-99.4838, 33.7501],
          [-99.5007, 33.7637],
          [-99.5065, 33.7723],
          [-99.5202, 33.7684],
          [-99.5243, 33.7836],
          [-99.5463, 33.7813],
          [-99.5418, 33.7941],
          [-99.5565, 33.7984],
          [-99.5784, 33.8025],
          [-99.5823, 33.7845],
          [-99.5965, 33.7873],
          [-99.5993, 33.7462],
          [-99.6663, 33.7439],
          [-99.6692, 33.6122],
          [-99.6606, 33.548],
          [-99.7139, 33.5529],
          [-99.7198, 33.5462],
          [-99.713, 33.5245],
          [-99.7238, 33.5214],
          [-99.7267, 33.5113],
          [-99.7798, 33.4948],
          [-99.802, 33.5001],
          [-99.7558, 33.4886],
          [-99.7553, 33.4347],
          [-99.716, 33.4341],
          [-99.7135, 33.4066],
          [-99.7026, 33.3984],
          [-99.7458, 33.3983],
          [-99.7526, 33.3476],
          [-99.7698, 33.3472],
          [-99.7623, 33.3203],
          [-99.7246, 33.3208],
          [-99.7259, 33.2524],
          [-99.6613, 33.2339],
          [-99.6606, 33.2416],
          [-99.6188, 33.2497],
          [-99.6009, 33.2621],
          [-99.5115, 33.2564],
          [-99.4721, 33.2408],
          [-99.4713, 32.957],
          [-99.4782, 32.9429],
          [-99.4569, 32.9207],
          [-99.4597, 32.8866],
          [-99.4499, 32.8717],
          [-99.46, 32.8633],
          [-99.4526, 32.8569],
          [-99.4535, 32.8354],
          [-99.4694, 32.8201],
          [-99.4539, 32.8027],
          [-99.411, 32.789],
          [-99.4144, 32.7756],
          [-99.4066, 32.7736],
          [-99.4158, 32.7715],
          [-99.413, 32.764],
          [-99.395, 32.7643],
          [-99.4091, 32.7622],
          [-99.4087, 32.7472],
          [-99.4197, 32.7475],
          [-99.4222, 32.7335],
          [-99.4279, 32.7379],
          [-99.4436, 32.7144],
          [-99.4686, 32.7157],
          [-99.5316, 32.7441],
          [-99.5348, 32.7345],
          [-99.5407, 32.7461],
          [-99.5532, 32.7491],
          [-99.5579, 32.7344],
          [-99.5695, 32.7348],
          [-99.57, 32.7239],
          [-99.5471, 32.6979],
          [-99.5381, 32.703],
          [-99.53, 32.7084],
          [-99.5277, 32.6986],
          [-99.4783, 32.6963],
          [-99.4716, 32.6399],
          [-99.4641, 32.6383],
          [-99.464, 32.5663],
          [-99.475, 32.5586],
          [-99.4628, 32.5544],
          [-99.4589, 32.5192],
          [-99.303, 32.5151],
          [-99.3001, 32.4855],
          [-99.2829, 32.4892],
          [-99.2605, 32.4775],
          [-99.2559, 32.45],
          [-99.2067, 32.4506],
          [-99.2064, 32.4072],
          [-99.195, 32.3775],
          [-99.2078, 32.3728],
        ],
        [
          [-100.0739, 31.3698],
          [-100.044, 31.3769],
          [-100.0729, 31.385],
          [-100.0739, 31.3698],
        ],
        [
          [-99.168, 32.1967],
          [-99.1694, 32.2041],
          [-99.1881, 32.2042],
          [-99.168, 32.1967],
        ],
        [
          [-99.2078, 32.3728],
          [-99.2338, 32.3745],
          [-99.2131, 32.3709],
          [-99.2078, 32.3728],
        ],
        [
          [-99.2338, 32.3745],
          [-99.2983, 32.3858],
          [-99.2977, 32.3786],
          [-99.2338, 32.3745],
        ],
      ],
    },
    countryCode: ["US"],
  },
};
