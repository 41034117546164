import { parseDateToLocal } from "../../reuse/Dates";
import { getInvoiceToFilter } from "../../reuse/Invoice";
import { paddingZerosLeft } from "../../reuse/Strings";
import {
  Invoice,
  InvoiceStatus,
  InvoiceToFilter,
  InvoiceToFilterMultiSelect,
} from "../../types/Invoice";

const sortInvoicesByDate = (invoices: Invoice[]): Invoice[] =>
  invoices?.sort((invoiceA, invoiceB) =>
    new Date(invoiceA.deliveryDate) < new Date(invoiceB.deliveryDate) ? 1 : -1,
  );

const getFilteredTableData = (
  invoices: Invoice[],
  status: InvoiceStatus,
): Invoice[] => invoices.filter((invoice) => invoice.status === status) ?? [];

export const getInvoices = (
  temporalList: Invoice[],
  status: InvoiceStatus,
): Invoice[] => sortInvoicesByDate(getFilteredTableData(temporalList, status));

const includeInvoice = (invoice: Invoice, term: string): boolean => {
  return (
    paddingZerosLeft(invoice.mvmntInvoiceId ?? 1)
      .toString()
      .toLowerCase()
      .includes(term.toLowerCase()) ||
    (invoice.shipper?.contactInformation.companyName ?? "")
      .toString()
      .toLowerCase()
      .includes(term.toLowerCase()) ||
    (
      invoice.shipment?.shipmentStops[0].address.city +
        ", " +
        invoice.shipment?.shipmentStops[0].address.state ?? ""
    )
      .toString()
      .toLowerCase()
      .includes(term.toLowerCase()) ||
    (
      invoice.shipment?.shipmentStops[
        invoice.shipment?.shipmentStops.length - 1
      ].address.city +
      ", " +
      invoice.shipment?.shipmentStops[
        invoice.shipment?.shipmentStops.length - 1
      ].address.state
    )
      .toString()
      .toLowerCase()
      .includes(term.toLowerCase())
  );
};

export const filterRowsByTerm = (list: Invoice[], term: string): Invoice[] =>
  list.filter((row) => includeInvoice(row, term));

export const removeDuplicatesArr = <T>(arr: T[]): T[] =>
  arr?.filter((item, pos) => arr.indexOf(item) === pos);

export const filterRowsByMultiSelect = (
  invoices: Invoice[],
  multiselectFilters: InvoiceToFilterMultiSelect,
  isCarrier: boolean,
): Invoice[] => {
  return invoices.filter((row) => {
    const parsedInvoice = getInvoiceToFilter(row, isCarrier);
    const parsedDate = parseDateToLocal(new Date(parsedInvoice.date));

    const filterValues = Object.values(multiselectFilters).filter(
      (v) => v.length > 0,
    );

    const invoiceWithParsedDate: InvoiceToFilter = {
      ...parsedInvoice,
      date: parsedDate,
    };

    return filterValues.some((v) =>
      v.some((item) =>
        Object.values(invoiceWithParsedDate).join(",").includes(item),
      ),
    );
  });
};

export const getFileExtensionFromUrl = (url: string): string =>
  url[url.indexOf("?") - 4] === "."
    ? url.substr(url.indexOf("?") - 3, 3)
    : url.substr(url.indexOf("?") - 4, 4);
