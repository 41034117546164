import {
  VStack,
  HStack,
  FormControl,
  Input,
  IconButton,
  FormLabel,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
} from "@chakra-ui/react";
import { AddIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { useFieldArray } from "react-hook-form";
import { FormAddressProps } from "../../types/Address";
import { borderStyling } from "../../reuse/Forms";
import { useEffect, useState } from "react";
import {
  getAddressFromLatLng,
  getPlaceLatLng,
  getValuesFromAddressComponents,
} from "../../reuse/Maps";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export const FormAddress = ({
  formHook,
  label,
  index,
  isReadOnly,
}: FormAddressProps): JSX.Element => {
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      language: "en",
    });
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (placePredictions.length > 0 && !isMenuOpen) {
      setMenuOpen(true);
    } else if (!placePredictions.length) {
      setMenuOpen(false);
    }
  }, [placePredictions]);

  const {
    control,
    register,
    setValue,
    formState: { errors },
    watch,
  } = formHook;
  watch();
  const { fields, append, remove } = useFieldArray({
    name: "address",
    control,
    keyName: "key",
  });

  const onAddAddress = (): void => {
    append({
      addressName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    });
  };

  const autoCompleteAddess = async (placeId: string): Promise<void> => {
    if (!placesService) return;
    const latLng = await getPlaceLatLng(placesService, placeId);
    if (latLng) {
      const address = await getAddressFromLatLng(latLng);
      if (address) {
        const { streetNumber, route, city, state, country, postalCode } =
          getValuesFromAddressComponents(address);
        setValue(
          `address.${index}.address1` as const,
          `${streetNumber} ${route}` as never,
        );
        setValue(`address.${index}.city` as const, city as never);
        setValue(`address.${index}.country` as const, country as never);
        setValue(`address.${index}.state` as const, state as never);
        setValue(`address.${index}.postalCode` as const, postalCode as never);
      }
    }
  };

  const onSelectAddress = (
    place: google.maps.places.AutocompletePrediction,
  ): void => {
    setMenuOpen(false);
    void autoCompleteAddess(place.place_id);
  };

  return (
    <FormControl>
      <HStack alignItems="end">
        <FormLabel>{label}</FormLabel>
        <IconButton
          icon={<AddIcon />}
          aria-label="Add address"
          onClick={onAddAddress}
        />
      </HStack>
      {fields.map((address, index) => (
        <VStack key={address.id ?? index}>
          <FormControl>
            <Input
              style={{
                borderColor: borderStyling(
                  errors.address?.[index]!.addressName,
                ),
              }}
              placeholder="Address Name"
              {...register(`address.${index}.addressName` as const, {
                required: true,
              })}
            ></Input>
          </FormControl>
          <VStack w="100%" alignItems="start" spacing={0}>
            <Input
              placeholder="Address 1"
              autoComplete="new-password"
              {...register(`address.${index}.address1` as const, {
                required: {
                  value: true,
                  message: "You must enter an address",
                },
                onChange: (e) => getPlacePredictions({ input: e.target.value }),
              })}
            ></Input>
            <Menu isOpen={isMenuOpen} flip={false} gutter={0}>
              <MenuButton aria-label="Account Options" visibility="hidden" />
              <MenuList maxW="25vw" pos="absolute">
                {placePredictions.map((item) => (
                  <MenuItem
                    key={item.place_id}
                    onClick={() => onSelectAddress(item)}
                  >
                    {item.description}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </VStack>
          <FormControl>
            <Input
              placeholder="Address 2 (Optional)"
              {...register(`address.${index}.address2` as const, {
                required: false,
              })}
            ></Input>
          </FormControl>
          <FormControl>
            <Input
              placeholder="City"
              {...register(`address.${index}.city` as const, {
                required: false,
              })}
              disabled
            ></Input>
          </FormControl>
          <FormControl>
            <Input
              placeholder="State"
              {...register(`address.${index}.state` as const, {
                required: false,
              })}
              disabled
            ></Input>
          </FormControl>
          <FormControl>
            <Input
              placeholder="Postal Code"
              {...register(`address.${index}.postalCode` as const, {
                required: false,
              })}
              disabled
            ></Input>
          </FormControl>
          <FormControl>
            <Input
              placeholder="Country"
              {...register(`address.${index}.country` as const, {
                required: false,
              })}
              disabled
            ></Input>
          </FormControl>
          <IconButton
            icon={<SmallCloseIcon />}
            aria-label="Remove address"
            onClick={() => remove(index)}
          />
        </VStack>
      ))}
    </FormControl>
  );
};

export default FormAddress;
