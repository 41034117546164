import { Skeleton, VStack, HStack, Heading, Button } from "@chakra-ui/react";
import { Fragment } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { useActiveRfpContext } from "../../pages/ActiveRfp/ActiveRfpContext";
import { IRfp } from "../../types/Rfp";
import { UserType } from "../../types/User";
import EmptySection from "../EmptySection";
import RfpCard from "../RfpCard";
import { RfpFormMode } from "../RfpModalForm/RfpModalForm";

interface RfpListProps {
  data: IRfp[] | null;
}

export const RfpList = ({ data }: RfpListProps): JSX.Element => {
  const context = useActiveRfpContext();
  const { selectedRfp, setSelectedRfp, toggleRfpForm, setFormMode } = context!;

  const openRfpFormModal = (): void => {
    // TODO - fix this slow query on the backend so that the below is not needed
    // if (!carriers || carriers.length === 0) {
    //   toast({
    //     status: "success",
    //     description: "No Active carriers available.",
    //     duration: 3000,
    //   });
    // } else {
    setFormMode(RfpFormMode.CREATE);
    toggleRfpForm(true);
  };

  const { id, type } = useAppSelector(selectUserData);
  const shipperUser =
    (id && type === UserType.SHIPPER) || type === UserType.BROKER;

  return (
    <Skeleton minW="20%" h="100%" isLoaded overflowY="auto">
      <VStack bgColor="white !important" h="100%" spacing="1vh">
        <HStack
          layerStyle="yellow"
          minH="32px"
          w="100%"
          justifyContent="start"
          px="15px"
        >
          <Heading size="md" textStyle="uppercase">
            RFPS
          </Heading>
        </HStack>
        <VStack
          mt="0 !important"
          alignItems="start"
          w="100%"
          h={shipperUser ? "calc(100% - 130px)" : "100%"}
          overflowY="auto"
          spacing={0}
        >
          {!data || data.length === 0 ? (
            <EmptySection title="No RFP data" />
          ) : (
            <Fragment>
              {data.map((rfp) => (
                <RfpCard
                  key={rfp.id}
                  rfp={rfp}
                  isSelected={selectedRfp?.id === rfp.id}
                  onClick={() => setSelectedRfp(rfp)}
                />
              ))}
            </Fragment>
          )}
        </VStack>
        {shipperUser && (
          <Button
            style={{ marginTop: "2rem" }}
            layerStyle="yellow"
            size="large"
            w="90%"
            onClick={openRfpFormModal}
          >
            Create New RFP
          </Button>
        )}
      </VStack>
    </Skeleton>
  );
};

export default RfpList;
