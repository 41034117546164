import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Input,
  ModalBody,
  VStack,
  HStack,
  Button,
  FormControl,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLazyGetCarrierContactQuery } from "../../api/carrier";
import { Search2Icon } from "@chakra-ui/icons";
import { DefaultModalProps } from "../../types/DefaultModal";
import useToastHook from "../useToastHook";
import { useAddCarriersToRfpMutation } from "../../api/rfp";
import { useActiveRfpContext } from "../../pages/ActiveRfp/ActiveRfpContext";

interface AddLaneFormProps extends DefaultModalProps {
  toggleBulkUpload: () => void;
  addFromNetwork: () => void;
}

export const AddToRfpForm = ({
  isOpen,
  onClose,
  toggleBulkUpload,
  addFromNetwork,
}: AddLaneFormProps): JSX.Element => {
  const [dotNumber, setDotNumber] = useState("");
  const [emailAddressForNotifications, setEmailAddressForNotifications] =
    useState<string>("");

  const context = useActiveRfpContext();
  const { selectedRfp } = context!;
  const [searchCarrier, { data: carrier, isFetching: isSearching, isError }] =
    useLazyGetCarrierContactQuery();
  const [addCarriersToRfp, { isLoading: isAddingCarrier }] =
    useAddCarriersToRfpMutation();

  const toast = useToastHook();

  useEffect(() => {
    if (isError) {
      toast.error({ description: "Carrier not found" });
    }
  }, [isError]);

  const onClickAddCarrier = async (): Promise<void> => {
    if (carrier?.DOTNumber) {
      const response = await addCarriersToRfp({
        rfpId: selectedRfp?.id ?? "",
        carriers: [
          {
            carrierId: carrier.carrierId,
            fmcsaCarrierId: carrier.id,
            emailAddressForNotifications:
              emailAddressForNotifications.length > 0
                ? emailAddressForNotifications
                : undefined,
          },
        ],
      });

      if ("data" in response) {
        toast.success({
          description: "Carrier added successfully",
        });
        onClose();
      } else {
        toast.error({
          description: "Carrier not added to RFP",
        });
      }
    }
  };

  const onClickSearchCarrier = async (): Promise<void> => {
    if (dotNumber.length === 0) return;
    await searchCarrier(dotNumber);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"xl"} isCentered>
      <ModalOverlay />
      <ModalContent p="1rem">
        <ModalHeader textStyle="uppercase" fontSize="25px">
          <HStack justifyContent="space-between">
            <Text>Add to Rfp</Text>
            <Button color="mvmntRed" variant="link" onClick={addFromNetwork}>
              + ADD FROM NETWORK
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <VStack>
            <FormControl isDisabled={isSearching || isAddingCarrier}>
              <InputGroup>
                <Input
                  type="number"
                  placeholder="Carrier DOT number"
                  value={dotNumber}
                  onChange={(e) => setDotNumber(e.target.value)}
                  onKeyPress={(e) =>
                    e.key === "Enter" ? onClickSearchCarrier() : null
                  }
                />
                <InputRightElement cursor="pointer">
                  <Button
                    onClick={onClickSearchCarrier}
                    isLoading={isSearching}
                    isDisabled={isAddingCarrier}
                  >
                    <Search2Icon />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <HStack w="100%">
              <FormControl isReadOnly isDisabled={!carrier}>
                <Input
                  value={carrier?.companyRep1?.split(" ")[0] ?? ""}
                  placeholder="Contact First Name"
                />
              </FormControl>

              <FormControl isReadOnly isDisabled={!carrier}>
                <Input
                  value={carrier?.companyRep1?.split(" ")[1] ?? ""}
                  placeholder="Contact Last Name"
                />
              </FormControl>
            </HStack>
            <HStack w="100%">
              <FormControl isReadOnly isDisabled={!carrier}>
                <Input
                  value={carrier?.telephoneNumber ?? ""}
                  placeholder="Contact Phone"
                />
              </FormControl>

              <FormControl isReadOnly isDisabled={!carrier}>
                <Input
                  value={carrier?.emailAddress ?? ""}
                  placeholder="Contact Email Address"
                />
              </FormControl>
            </HStack>
            <FormControl>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Email Address for Notifications (optional)"
                  value={emailAddressForNotifications}
                  onChange={(e) =>
                    setEmailAddressForNotifications(e.target.value)
                  }
                />
                <InputRightElement cursor="pointer"></InputRightElement>
              </InputGroup>{" "}
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter mb="1rem" justifyContent="start">
          <Button
            isDisabled={isSearching || isError || !carrier}
            isLoading={isAddingCarrier}
            layerStyle="red"
            onClick={onClickAddCarrier}
          >
            Add Carrier
          </Button>

          <Button
            isDisabled={isSearching || isAddingCarrier}
            ml="20px"
            size="sm"
            color="mvmntRed"
            variant="link"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            layerStyle="yellow"
            ml="auto"
            onClick={toggleBulkUpload}
            isDisabled={isSearching}
          >
            Bulk Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddToRfpForm;
