import { createApi } from "@reduxjs/toolkit/query/react";
import { BidCreate, BidUpdate, TenderResponse } from "../types/Bid";
import { baseUrl } from "./utils/baseUrl";
import { invalidateTags } from "./shipment";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";

export const bidApi = createApi({
  reducerPath: "bidApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}/bids`),
  tagTypes: [],
  endpoints: (builder) => ({
    createMarketPlaceBid: builder.mutation<void, BidCreate>({
      query: (body) => ({
        url: "/marketplace",
        method: "POST",
        body,
      }),
    }),
    createQuoteRequestBid: builder.mutation<void, BidCreate>({
      query: (body) => ({
        url: "/active-requests",
        method: "POST",
        body,
      }),
      onQueryStarted: async (_arg, api): Promise<void> => {
        try {
          const { dispatch, queryFulfilled } = api;
          await queryFulfilled;
          dispatch(invalidateTags(["QuoteRequests"]));
        } catch (err) {}
      },
    }),
    updateBid: builder.mutation<void, BidUpdate>({
      query: (body) => ({
        url: "/active-requests",
        method: "PUT",
        body,
      }),
    }),
    acceptTender: builder.mutation<void, TenderResponse>({
      query: (body) => ({
        url: "/tender/accept",
        method: "PUT",
        body,
      }),
      onQueryStarted: async (_arg, api): Promise<void> => {
        try {
          const { dispatch, queryFulfilled } = api;
          await queryFulfilled;
          dispatch(invalidateTags(["QuoteRequests"]));
          dispatch(invalidateTags(["Tracking"]));
        } catch (err) {}
      },
    }),
    rejectTender: builder.mutation<void, TenderResponse>({
      query: (body) => ({
        url: "/tender/reject",
        method: "PUT",
        body,
      }),
      onQueryStarted: async (_arg, api): Promise<void> => {
        try {
          const { dispatch, queryFulfilled } = api;
          await queryFulfilled;
          dispatch(invalidateTags(["QuoteRequests"]));
        } catch (err) {}
      },
    }),
  }),
});

export const {
  useCreateMarketPlaceBidMutation,
  useCreateQuoteRequestBidMutation,
  useUpdateBidMutation,
  useAcceptTenderMutation,
  useRejectTenderMutation,
} = bidApi;
