import { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Text,
} from "@chakra-ui/react";
import { sortText } from "../../reuse/Sorting";
import SortButton from "../SortButton";
import { CarrierInformation } from "../../types/Rfp";

interface CarrierProps {
  data: CarrierInformation[];
}

const initialSortValue = {
  carrierName: false,
  volume: false,
};

export const CarrierTable = ({ data }: CarrierProps): JSX.Element => {
  const [sortState, setSortState] = useState(initialSortValue);
  const [carriers, setCarriers] = useState<CarrierInformation[]>(data);

  const sortByCarrierName = (): void => {
    const sort = (a: CarrierInformation, b: CarrierInformation): number =>
      sortText(a.companyName, b.companyName, sortState.carrierName);
    setCarriers([...carriers].sort(sort));
    setSortState({ ...sortState, carrierName: !sortState.carrierName });
  };

  useEffect(() => {
    setCarriers(data);
  }, [data]);

  return (
    <Table variant="unstyled">
      <Thead>
        <Tr>
          <Th textAlign="center" cursor="pointer">
            <HStack
              onClick={sortByCarrierName}
              w="100%"
              justifyContent="center"
            >
              <Text>Carrier Name</Text>
              <SortButton asc={sortState.carrierName} />
            </HStack>
          </Th>
          <Th textAlign="center">Contact Name</Th>
          <Th textAlign="center">Email</Th>
          <Th textAlign="center">Phone Number</Th>
          <Th color="mvmntRed" textAlign="center">
            DOT#
          </Th>
          <Th color="mvmntRed" textAlign="center">
            MC#
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {carriers.map((carrier) => (
          <Tr key={carrier.id}>
            <Td textAlign="center">{carrier.companyName}</Td>
            <Td textAlign="center">{carrier.companyContactName}</Td>
            <Td textAlign="center">{carrier.companyEmail}</Td>
            <Td textAlign="center">{carrier.companyPhoneNumber}</Td>
            <Td textAlign="center">{carrier.dotNumber}</Td>
            <Td textAlign="center">{carrier.mcNumber}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default CarrierTable;
