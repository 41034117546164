import { Search2Icon } from "@chakra-ui/icons";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  FormControl,
  Input,
  ModalBody,
  VStack,
  InputGroup,
  InputRightElement,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLazyGetCarrierContactQuery } from "../../api/carrier";
import { useAddCarrierToNetworkMutation } from "../../api/network";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { DefaultModalProps } from "../../types/DefaultModal";
import useToastHook from "../../components/useToastHook";

interface AddNetworkCarrierProps extends DefaultModalProps {
  onClickBulkUpload: () => void;
}

export const AddNetworkCarrier = ({
  isOpen,
  onClose,
  onClickBulkUpload,
}: AddNetworkCarrierProps): JSX.Element => {
  const [dotNumber, setDotNumber] = useState("");
  const [searchCarrier, { data: carrier, isFetching: isSearching, isError }] =
    useLazyGetCarrierContactQuery();
  const [addNetworkCarrier, { isLoading: isAdding }] =
    useAddCarrierToNetworkMutation();

  const toast = useToastHook();

  useEffect(() => {
    if (isError) {
      toast.error({ description: "Carrier not found" });
    }
  }, [isError]);

  const onClickAddCarrier = async (): Promise<void> => {
    if (carrier?.DOTNumber) {
      const response = await addNetworkCarrier(carrier.DOTNumber);
      if ("data" in response) {
        toast.success({ description: "Carrier added successfully" });
        onClose();
      } else {
        toast.error({ description: `${parseErrorResponse(response.error)}` });
      }
    }
  };

  const onClickSearchCarrier = async (): Promise<void> => {
    if (dotNumber.length === 0) return;
    await searchCarrier(dotNumber);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader textStyle="uppercase">Add Network Partner</ModalHeader>

        <ModalBody>
          <VStack>
            <FormControl isDisabled={isSearching || isAdding}>
              <InputGroup>
                <Input
                  type="number"
                  placeholder="Carrier DOT number"
                  value={dotNumber}
                  onChange={(e) => setDotNumber(e.target.value)}
                  onKeyPress={(e) =>
                    e.key === "Enter" ? onClickSearchCarrier() : null
                  }
                />
                <InputRightElement cursor="pointer">
                  <Button
                    onClick={onClickSearchCarrier}
                    isLoading={isSearching}
                    isDisabled={isAdding}
                  >
                    <Search2Icon />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            {/**
             * TODO:
             * If these inputs are going to be permanently in isReadOnly
             * They should at least have an external label (not just placeholder)
             * Or, not be an input at all
             */}
            <HStack w="100%">
              <FormControl isReadOnly isDisabled={!carrier}>
                <Input
                  value={carrier?.companyRep1?.split(" ")[0] ?? ""}
                  placeholder="Contact First Name"
                />
              </FormControl>

              <FormControl isReadOnly isDisabled={!carrier}>
                <Input
                  value={carrier?.companyRep1?.split(" ")[1] ?? ""}
                  placeholder="Contact Last Name"
                />
              </FormControl>
            </HStack>

            <HStack w="100%">
              <FormControl isReadOnly isDisabled={!carrier}>
                <Input
                  value={carrier?.telephoneNumber ?? ""}
                  placeholder="Contact Phone"
                />
              </FormControl>

              <FormControl isReadOnly isDisabled={!carrier}>
                <Input
                  value={carrier?.emailAddress ?? ""}
                  placeholder="Contact Email Address"
                />
              </FormControl>
            </HStack>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={isSearching || isError || !carrier}
            isLoading={isAdding}
            layerStyle="red"
            onClick={onClickAddCarrier}
          >
            Add Carrier
          </Button>

          <Button
            isDisabled={isSearching || isAdding}
            ml="20px"
            size="sm"
            color="mvmntRed"
            variant="link"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            layerStyle="yellow"
            ml="auto"
            onClick={onClickBulkUpload}
            isDisabled={isSearching || isAdding}
          >
            Bulk Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNetworkCarrier;
