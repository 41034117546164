import SharedButton from "../../components/SharedButton";

interface QuickPayActionsProps {
  title: string;
  onClick: () => void;
}

export const QuickPayActions = ({
  title,
  onClick,
}: QuickPayActionsProps): JSX.Element => {
  return (
    <SharedButton
      title={title}
      size="small"
      color="white"
      bgColor="black"
      onClick={onClick}
    />
  );
};

export default QuickPayActions;
