import {
  VStack,
  FormControl,
  Input,
  Button,
  FormLabel,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { ShipperAccountFormProps } from "../../types/ShipperAccountForm";
import ErrorMessage from "../ErrorMessage";
import { ContactInformation } from "../../types/ShipperContactInformation";
import { validateEmail, validatePhoneNumber } from "../../reuse/UserData";

type ContactInformationUpdate = Omit<ContactInformation, "companyName">;

export const ShipperContactInformation = ({
  shipperUser,
  handleUpdate,
  isUpdating,
}: ShipperAccountFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
    control,
  } = useForm<ContactInformationUpdate>();

  useEffect(() => {
    if (shipperUser) {
      const { companyName, ...contactInformation } =
        shipperUser.contactInformation;
      reset(contactInformation);
    }
  }, [shipperUser]);

  const onSubmit = handleSubmit(
    async (contactInformation: ContactInformationUpdate) =>
      await handleUpdate({
        contactInformation: contactInformation as ContactInformation,
      }),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const untypedControl = control as Control<any>;

  return (
    <form onSubmit={onSubmit}>
      <VStack alignItems="center">
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Shipper ID"
            value={shipperUser?.shipperCode}
            readOnly
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <FormLabel>Primary Admin</FormLabel>
          <Input
            placeholder="First Name"
            {...register("firstName", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Last Name"
            {...register("lastName", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Email"
            {...register("email", {
              required: false,
              validate: validateEmail,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <FormLabel>Company</FormLabel>
          <Input
            placeholder="Company Name"
            value={shipperUser?.contactInformation.companyName}
            readOnly
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Company Email"
            {...register("companyEmail", {
              required: false,
              validate: validateEmail,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Company website"
            {...register("website", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Controller
            control={control}
            name="companyPhoneNumber"
            rules={{
              required: false,
              validate: validatePhoneNumber,
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <PhoneInput
                placeholder="Phone Number"
                control={untypedControl}
                name={name}
                value={value}
                onChange={onChange}
                country="US"
              />
            )}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="DUNS code"
            {...register("dunsCode", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="NAICS code"
            {...register("naicsCode", {
              required: false,
            })}
          />
        </FormControl>

        <Button
          layerStyle="red"
          w="20%"
          onClick={onSubmit}
          isLoading={isUpdating}
        >
          Update
        </Button>

        <ErrorMessage errors={errors} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default ShipperContactInformation;
