import { createContext, useContext } from "react";
import {
  QuickPayRequest,
  QuickPayRequestStatus,
  QuickPayToDropdownEnum,
  QuickPayToFilterMultiSelect,
} from "../../types/QuickPayRequest";

interface QuickPay {
  currentStatus: QuickPayRequestStatus;
  setCurrentStatus: React.Dispatch<React.SetStateAction<QuickPayRequestStatus>>;
  term: string;
  selectedQuickPay: string[];
  setSelectedQuickPay: React.Dispatch<React.SetStateAction<string[]>>;
  quickPays: QuickPayRequest[] | undefined;
  multiselectFilters: QuickPayToFilterMultiSelect;
  setMultiselectFilters: React.Dispatch<
    React.SetStateAction<QuickPayToFilterMultiSelect>
  >;
  sortCriteria: string;
  sortByCriteria: (criteria: QuickPayToDropdownEnum) => void;
}

const QuickPayContext = createContext<QuickPay | null>(null);

const useQuickPayContext = (): QuickPay | null => useContext(QuickPayContext);

export { QuickPayContext, useQuickPayContext };
