import {
  Input,
  VStack,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {
  getAddressFromLatLng,
  getPlaceLatLng,
  getValuesFromAddressComponents,
} from "../../reuse/Maps";
import { ShipmentFormProps } from "../../types/ShipmentForm";

interface ShipmentAddressFieldProps extends ShipmentFormProps {
  index: number;
}

export const ShipmentAddressField = ({
  formHook,
  index,
  isReadOnly,
}: ShipmentAddressFieldProps): JSX.Element => {
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      language: "en",
    });
  const { register, setValue } = formHook;
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (placePredictions.length > 0 && !isMenuOpen) {
      setMenuOpen(true);
    } else if (!placePredictions.length) {
      setMenuOpen(false);
    }
  }, [placePredictions]);

  const autoCompleteAddess = async (placeId: string): Promise<void> => {
    if (!placesService) return;
    const latLng = await getPlaceLatLng(placesService, placeId);
    if (latLng) {
      const address = await getAddressFromLatLng(latLng);
      if (address) {
        const { streetNumber, route, city, state, country, postalCode } =
          getValuesFromAddressComponents(address);
        setValue(
          `shipmentStops.${index}.address.address1` as const,
          `${streetNumber} ${route}` as never,
        );
        setValue(`shipmentStops.${index}.address.city` as const, city as never);
        setValue(
          `shipmentStops.${index}.address.country` as const,
          country as never,
        );
        setValue(
          `shipmentStops.${index}.address.state` as const,
          state as never,
        );
        setValue(
          `shipmentStops.${index}.address.postalCode` as const,
          postalCode as never,
        );
      }
    }
  };

  const onSelectAddress = (
    place: google.maps.places.AutocompletePrediction,
  ): void => {
    setMenuOpen(false);
    void autoCompleteAddess(place.place_id);
  };

  return (
    <VStack w="50%" alignItems="start" spacing={0}>
      <Input
        placeholder="Address 1"
        autoComplete="new-password"
        {...register(`shipmentStops.${index}.address.address1` as const, {
          required: {
            value: true,
            message: "You must enter an address",
          },
          onChange: (e) => getPlacePredictions({ input: e.target.value }),
        })}
        disabled={isReadOnly}
      />
      <Menu isOpen={isMenuOpen} flip={false} gutter={0}>
        <MenuButton aria-label="Account Options" visibility="hidden" />
        <MenuList maxW="25vw">
          {placePredictions.map((item) => (
            <MenuItem key={item.place_id} onClick={() => onSelectAddress(item)}>
              {item.description}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </VStack>
  );
};

export default ShipmentAddressField;
