import { createApi, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import {
  DocumentUpload,
  DriverDocumentUpload,
  IRfpDocumentUpload,
  MvmntDocument,
  S3File,
} from "../types/Files";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";
import { invalidateTags } from "./rfp";
import {
  IShipmentDocument,
  ShipmentDocumentType,
} from "../types/ShipmentDocument";
import axios from "axios";

interface GetNetworkSCA {
  networkId: string;
  documentId: string;
}

interface S3Upload {
  file: File;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

export const documentsApi = createApi({
  reducerPath: "documentsApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}/documents`),
  tagTypes: ["Documents", "ShipmentDocuments"],
  endpoints: (builder) => ({
    getUserDocuments: builder.query<MvmntDocument[], void>({
      query: () => "/",
      providesTags: ["Documents"],
    }),
    uploadDocument: builder.mutation<string, DocumentUpload>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Documents"],
    }),
    uploadS3File: builder.mutation<boolean, S3File>({
      queryFn: async (args) => {
        const { url, file } = args;
        const upload = await fetch(url, {
          method: "PUT",
          body: file,
          headers: { "Content-Type": file.type },
        });
        return { data: upload.ok };
      },
    }),
    getDocumentUrl: builder.query<string, string>({
      query: (id) => `/${id}`,
    }),
    getShipperCarrierAgreement: builder.query<string, GetNetworkSCA>({
      query: ({ networkId, documentId }) => `/sca/${networkId}/${documentId}`,
    }),
    deleteDocument: builder.mutation<void, string>({
      query: (documentId) => ({
        url: `/${documentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Documents"],
    }),
    getShipmentUploadTemplate: builder.query<string, void>({
      query: () => "/shipment-template",
    }),
    getLoadUploadTemplate: builder.query<string, void>({
      query: () => "/load_template",
    }),
    getNetworkUploadTemplate: builder.query<string, void>({
      query: () => "/network-template",
    }),
    getLaneUploadTemplate: builder.query<string, void>({
      query: () => "/lane-template",
    }),
    uploadDriverDocument: builder.mutation<string, DriverDocumentUpload>({
      query: ({ id, ...rest }) => ({
        url: `/shipment/${id}`,
        method: "POST",
        body: { ...rest },
      }),
      invalidatesTags: ["Documents"],
    }),
    uploadS3DriverFile: builder.mutation<boolean, S3File>({
      queryFn: async (args) => {
        const { url, file } = args;
        const upload = await fetch(url, {
          method: "PUT",
          body: file,
          headers: { "Content-Type": file.type },
        });
        return { data: upload.ok };
      },
    }),
    getDriverDocumentsUrl: builder.query<string, string>({
      query: (id) => `/${id}/shipment/`,
    }),
    uploadRfpDocument: builder.mutation<string, IRfpDocumentUpload>({
      query: ({ rfpId, ...rest }) => ({
        url: `/rfp/${rfpId}`,
        method: "POST",
        body: { ...rest },
      }),
    }),
    uploadS3RfpFile: builder.mutation<boolean, S3File>({
      queryFn: async (args) => {
        const { url, file } = args;
        const upload = await fetch(url, {
          method: "PUT",
          body: file,
          headers: { "Content-Type": file.type },
        });
        return { data: upload.ok };
      },
      onQueryStarted: async (_arg, api): Promise<void> => {
        try {
          const { dispatch, queryFulfilled } = api;
          await queryFulfilled;
          dispatch(invalidateTags(["proposals"]));
        } catch (err) {}
      },
    }),
    deleteRfpDocument: builder.mutation<void, string>({
      query: (documentId) => ({
        url: `/${documentId}/rfp`,
        method: "DELETE",
      }),
      onQueryStarted: async (_arg, api): Promise<void> => {
        try {
          const { dispatch, queryFulfilled } = api;
          await queryFulfilled;
          dispatch(invalidateTags(["proposals"]));
        } catch (err) {}
      },
    }),
    getRfpDocumentUrl: builder.query<string, string>({
      query: (id) => `/${id}/rfp`,
    }),
    getCarriersRfpTemplate: builder.query<string, void>({
      query: () => "/carrier-rfp-template",
    }),
    deleteTrackingDocument: builder.mutation<void, string>({
      query: (documentId) => ({
        url: `/${documentId}/tracking`,
        method: "DELETE",
      }),
    }),
    getShipmentDocsUrlsByShipmentId: builder.query<string[], string>({
      query: (shipmentId) => `/shipment-documents/${shipmentId}`,
    }),
    getDocumentsByShipmentId: builder.query<IShipmentDocument[], string>({
      query: (shipmentId) => `/shipment/${shipmentId}`,
      providesTags: ["ShipmentDocuments"],
    }),
    uploadShipmentDocument: builder.mutation<
      boolean,
      S3Upload & {
        documentType: ShipmentDocumentType;
        shipmentId: string;
      }
    >({
      async queryFn(
        { file, shipmentId, documentType, onUploadProgress },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        const result = await fetchWithBQ({
          url: `/shipment/${shipmentId}`,
          method: "POST",
          body: { fileName: file.name, contentType: file.type, documentType },
        });
        if (result.error) return { error: result.error as FetchBaseQueryError };
        const upload = await axios.put(result.data as string, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (progressEvent) => {
            onUploadProgress?.(progressEvent);
          },
        });
        return { data: upload.status === 200 };
      },
      invalidatesTags: ["ShipmentDocuments"],
    }),
    uploadLoadDocument: builder.mutation<
      boolean,
      S3Upload & { loadId: string }
    >({
      async queryFn(
        { file, loadId, onUploadProgress },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        const result = await fetchWithBQ({
          url: `/load/${loadId}`,
          method: "POST",
          body: {
            fileName: file.name,
            contentType: file.type,
          },
        });
        if (result.error) return { error: result.error as FetchBaseQueryError };
        const upload = await axios.put(result.data as string, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (progressEvent) => {
            onUploadProgress?.(progressEvent);
          },
        });
        return { data: upload.status === 200 };
      },
      invalidatesTags: [],
    }),
    deleteShipmentDocument: builder.mutation<void, string>({
      query: (documentId) => ({
        url: `/${documentId}/tracking`,
        method: "DELETE",
      }),
      invalidatesTags: ["ShipmentDocuments"],
    }),
  }),
});

export const {
  useLazyGetShipmentDocsUrlsByShipmentIdQuery,
  useGetShipmentDocsUrlsByShipmentIdQuery,
  useGetDocumentsByShipmentIdQuery,
  useLazyGetDocumentsByShipmentIdQuery,
  useUploadDocumentMutation,
  useUploadS3FileMutation,
  useGetUserDocumentsQuery,
  useLazyGetUserDocumentsQuery,
  useGetDocumentUrlQuery,
  useLazyGetDocumentUrlQuery,
  useDeleteDocumentMutation,
  useGetLaneUploadTemplateQuery,
  useLazyGetLaneUploadTemplateQuery,
  useGetNetworkUploadTemplateQuery,
  useGetShipmentUploadTemplateQuery,
  useGetLoadUploadTemplateQuery,
  useGetShipperCarrierAgreementQuery,
  useUploadDriverDocumentMutation,
  useUploadS3DriverFileMutation,
  useLazyGetDriverDocumentsUrlQuery,
  useUploadRfpDocumentMutation,
  useUploadS3RfpFileMutation,
  useDeleteRfpDocumentMutation,
  useLazyGetRfpDocumentUrlQuery,
  useLazyGetCarriersRfpTemplateQuery,
  useDeleteTrackingDocumentMutation,
  useUploadShipmentDocumentMutation,
  useDeleteShipmentDocumentMutation,
  useUploadLoadDocumentMutation,
} = documentsApi;
