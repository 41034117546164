import { VStack, FormControl, Input, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ShipperAccountFormProps } from "../../types/ShipperAccountForm";
import { Insurance } from "../../types/ShipperInsurance";
import ErrorMessage from "../ErrorMessage";

export const ShipperInsurance = ({
  shipperUser,
  handleUpdate,
  isUpdating,
}: ShipperAccountFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm<Insurance>();

  useEffect(() => {
    if (shipperUser) {
      reset(shipperUser.insuranceRequirements);
    }
  }, [shipperUser]);

  const onSubmit = handleSubmit(async (insuranceRequirements: Insurance) => {
    await handleUpdate({ insuranceRequirements });
  });

  return (
    <form onSubmit={onSubmit}>
      <VStack alignItems="center">
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Auto liability"
            {...register("autoLiability", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="General Liability Name"
            {...register("generalLiability", {
              required: false,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Cargo Insurance"
            {...register("cargoInsurance", {
              required: false,
            })}
          />
        </FormControl>

        <Button
          layerStyle="red"
          w="20%"
          onClick={onSubmit}
          isLoading={isUpdating}
        >
          Update
        </Button>

        <ErrorMessage errors={errors} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default ShipperInsurance;
