import {
  VStack,
  FormControl,
  Input,
  Button,
  Select,
  FormLabel,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import {
  Option,
  parseValues,
  toMultiselectOptions,
} from "../../reuse/MultiSelect";
import { CarrierAccountFormProps } from "../../types/CarrierAccountForm";
import { CompanyInformation } from "../../types/CompanyInformation";
import { EquipmentType } from "../../types/Equipment";
import { FormError } from "../../types/FormError";
import ErrorMessage from "../ErrorMessage";

export const CarrierCompanyForm = ({
  carrierUser,
  handleUpdate,
  isUpdating,
}: CarrierAccountFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm<CompanyInformation>();
  const [equipments, setEquipments] = useState<Option[]>([]);

  useEffect(() => {
    if (carrierUser) {
      reset(carrierUser.companyInformation);
      setEquipments(
        toMultiselectOptions(
          carrierUser.companyInformation.equipmentTypes ?? [],
        ),
      );
    }
  }, [carrierUser]);

  const onSubmit = handleSubmit(
    async (companyInformation: CompanyInformation) => {
      const equipmentTypes = parseValues(equipments) as EquipmentType[];
      await handleUpdate({
        companyInformation: {
          ...companyInformation,
          equipmentTypes,
        },
      });
    },
  );

  const equipmentOptions = toMultiselectOptions(Object.values(EquipmentType));

  return (
    <form onSubmit={onSubmit}>
      <VStack alignItems="center">
        <FormControl isDisabled={isUpdating}>
          <Input
            readOnly
            placeholder="Company Name"
            value={carrierUser?.companyName}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="About us"
            {...register("aboutUs", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Website domain"
            {...register("website", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="MC number"
            {...register("mcNumber", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="Federal ID number"
            {...register("federalId", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            readOnly
            placeholder="DOT number"
            value={carrierUser?.companyInformation.dotNumber}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="SCAC"
            {...register("scac", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="EIN number"
            {...register("einNumber", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            type="number"
            placeholder="Year Incorporated"
            {...register("yearIncorporated", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            placeholder="State Incorporated"
            {...register("stateIncorporated", {
              required: false,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <FormLabel>Purchase Orders Required?</FormLabel>
          <Select
            {...register("purchaseOrderRequired", {
              required: false,
              setValueAs: (value) => (value ? value === "true" : undefined),
            })}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Select>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <Input
            type="number"
            placeholder="Number of Trucks"
            {...register("numberOfTrucks", {
              required: false,
              valueAsNumber: true,
            })}
          ></Input>
        </FormControl>
        <FormControl isDisabled={isUpdating}>
          <MultiSelect
            overrideStrings={{ selectSomeItems: "Equipment Types" }}
            disableSearch
            hasSelectAll={false}
            value={equipments}
            options={equipmentOptions}
            labelledBy="Select"
            onChange={setEquipments}
          />
        </FormControl>
        <Button
          layerStyle="red"
          w="20%"
          onClick={onSubmit}
          isLoading={isUpdating}
        >
          Update
        </Button>
        <ErrorMessage errors={errors as FormError} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default CarrierCompanyForm;
