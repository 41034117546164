import {
  Input,
  VStack,
  HStack,
  Textarea,
  FormControl,
  FormLabel,
  ButtonGroup,
  Button,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { useActiveRfpContext } from "../../pages/ActiveRfp/ActiveRfpContext";
import { getMinimumDate } from "../../reuse/Dates";
import { RfpFormMode } from "./RfpModalForm";
import { IRfpForm } from "./types";
interface RfpFirstStepProps {
  formHook: UseFormReturn<IRfpForm>;
  isReadOnly?: boolean;
  type: string;
}

export const RfpFirstStep = ({
  formHook,
  type,
}: RfpFirstStepProps): JSX.Element => {
  const { setValue, register } = formHook;
  const context = useActiveRfpContext();
  const { formMode } = context!;

  return (
    <VStack>
      <HStack w="100%" justifyContent="flex-start">
        <ButtonGroup m="0 0 10px 0" size="sm" isAttached variant="outline">
          <Button
            borderColor="yellow"
            bgColor={type === "Annual" ? "yellow" : "white"}
            onClick={() => setValue("rfpType", "Annual")}
          >
            {"Annual"}
          </Button>
          <Button
            borderColor="yellow"
            bgColor={type === "Seasonal" ? "yellow" : "white"}
            onClick={() => setValue("rfpType", "Seasonal")}
          >
            {"Seasonal"}
          </Button>
        </ButtonGroup>
      </HStack>
      <VStack>
        <FormControl>
          <Input
            type="text"
            placeholder="RFP Name"
            {...register("rfpName", {
              required: {
                value: true,
                message: "You must enter RFP name",
              },
            })}
          ></Input>
        </FormControl>
        <HStack w="100%">
          <FormControl>
            <FormLabel>Bidding Start Date</FormLabel>
            <Input
              placeholder="Bidding Start Date"
              type="date"
              min={getMinimumDate()}
              {...register("biddingStartDate", {
                required: {
                  value: true,
                  message: "You must enter bidding start date",
                },
              })}
            ></Input>
          </FormControl>
          <FormControl>
            <FormLabel>Bidding End Date</FormLabel>
            <Input
              placeholder="Bidding End Date"
              type="date"
              min={getMinimumDate()}
              {...register("biddingEndDate", {
                required: {
                  value: true,
                  message: "You must enter bidding end date",
                },
              })}
            ></Input>
          </FormControl>
        </HStack>
        <HStack w="100%">
          <FormControl>
            <FormLabel>Contract Start Date</FormLabel>
            <Input
              placeholder="Contract Start Date"
              type="date"
              min={getMinimumDate()}
              {...register("contractStartDate", {
                required: {
                  value: false,
                  message: "You must enter contract start date",
                },
              })}
            ></Input>
          </FormControl>
          <FormControl>
            <FormLabel>Contract End Date</FormLabel>
            <Input
              placeholder="Contract End Date"
              type="date"
              min={getMinimumDate()}
              {...register("contractEndDate", {
                required: {
                  value: false,
                  message: "You must enter contract end date",
                },
              })}
            ></Input>
          </FormControl>
        </HStack>
        <HStack w="100%">
          <FormControl>
            <Textarea
              isDisabled={formMode === RfpFormMode.EDIT}
              placeholder="Notes"
              {...register("notes", {
                required: {
                  value: false,
                  message: "You must enter notes",
                },
              })}
            />
          </FormControl>
        </HStack>
      </VStack>
    </VStack>
  );
};
