export interface AppRoutesProps {
  BASE: string;
  LOGIN: string;
  CREATE_ACCOUNT: string;
  SHIPPER_ACCOUNT_REQUEST: string;
  BROKER_ACCOUNT_REQUEST: string;
  RESET_PASSWORD: string;
  ACCOUNT_DETAILS: string;
  MANAGE_NETWORK: string;
  SOURCING_SEARCH: string;
  LOAD_BUILD: string;
  LOADS_BOARD: string;
  CREATE_LOAD: string;
  ACTIVE_QUOTES: string;
  QUOTE_REQUESTS: string;
  TRACKING: string;
  PAST: string;
  ACTIVE_RFP: string;
  HISTORY_RFP: string;
  RFP: string;
  ACCOUNTS_PAYABLE: string;
  REPORTING: string;
  MANAGE_DOCUMENTS: string;
  MANAGE_USERS: string;
  UPDATE_PASSWORD: string;
  QUICKPAY: string;
  INVOICES: string;
}

export const routes: AppRoutesProps = {
  BASE: "/",
  LOGIN: "/login",
  CREATE_ACCOUNT: "/register/carrier",
  SHIPPER_ACCOUNT_REQUEST: "/register/shipper",
  BROKER_ACCOUNT_REQUEST: "/register/broker",
  RESET_PASSWORD: "/resetpassword",
  ACCOUNT_DETAILS: "/account",
  MANAGE_NETWORK: "/network",
  SOURCING_SEARCH: "/sourcing",
  LOAD_BUILD: "/load-build",
  LOADS_BOARD: "/load-board",
  CREATE_LOAD: "/load-build/create",
  ACTIVE_QUOTES: "/active-quotes",
  QUOTE_REQUESTS: "/quote-requests",
  TRACKING: "/tracking",
  PAST: "/past",
  ACTIVE_RFP: "/active-rfp",
  HISTORY_RFP: "/history-rfp",
  RFP: "/rfp",
  ACCOUNTS_PAYABLE: "/accounts-payable",
  REPORTING: "/reports",
  MANAGE_DOCUMENTS: "/documents",
  MANAGE_USERS: "/manage-users",
  UPDATE_PASSWORD: "/updatepassword",
  QUICKPAY: "/quickpay",
  INVOICES: "/invoices",
};

export default routes;
