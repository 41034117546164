import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  Button,
} from "@chakra-ui/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CarrierUser } from "../../../types/Carrier";
import { Shipment } from "../../../types/Shipment";
import TenderPDF from "./TenderPDF";

export interface TenderDocumentData {
  carrierData: CarrierUser;
  shipmentData: Shipment;
}

interface TenderPDFProps {
  docData: TenderDocumentData;
  isOpen: boolean;
  onClose: () => void;
}

export const TenderPDFModal = ({
  docData,
  isOpen,
  onClose,
}: TenderPDFProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered>
      <ModalOverlay />
      <ModalContent mt="auto">
        <ModalBody pb={0} mx={10}>
          <TenderPDF
            carrierData={docData.carrierData}
            shipmentData={docData.shipmentData}
          />
        </ModalBody>
        <ModalBody display="flex" justifyContent="space-between" mx={12} mb={1}>
          <PDFDownloadLink
            document={
              <TenderPDF
                carrierData={docData.carrierData}
                shipmentData={docData.shipmentData}
              />
            }
            fileName="RateConfirmation.pdf"
          >
            <Button layerStyle="red">Download</Button>
          </PDFDownloadLink>
          <Button layerStyle="black" onClick={onClose}>
            Close
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TenderPDFModal;
