import { DownloadIcon } from "@chakra-ui/icons";
import { VStack, Image, Link, Button } from "@chakra-ui/react";
import { useMemo } from "react";
import { useMarketPlaceContext } from "../../pages/CarrierMarketPlace/MarketPlaceContext";
import MarketPlaceLogo from "../../resources/svg/marketplace_logo.svg";
import { createCsvFromObjectsArray } from "../../reuse/Files";

export const SpotShipmentTopBar = (): JSX.Element => {
  const context = useMarketPlaceContext();

  const shipmentCsv = useMemo(() => {
    const shipmentData = context?.shipments.map((shipment) => {
      const accessorials = shipment.accessorials.join(", ");
      const shipmentData: Record<string, string | number> = {
        equipmentType: shipment.equipmentType,
        length: shipment.length,
        width: shipment.width,
        height: shipment.height,
        weight: shipment.weight,
        unitType: shipment.unitType,
        unitCount: shipment.unitCount,
        value: shipment.value,
        bidNotes: shipment.bidNotes ?? "",
        tenderNotes: shipment.tenderNotes ?? "",
        accessorials,
      };
      for (let stopNumber = 0; stopNumber < 10; stopNumber += 1) {
        shipmentData[`stop ${stopNumber + 1}`] =
          shipment.shipmentStops[stopNumber]?.address.address1 ?? "";
      }
      return shipmentData;
    });
    return createCsvFromObjectsArray(shipmentData ?? []);
  }, [context?.shipments]);

  return (
    <VStack w="100%" bgColor="realWhite" p="1%">
      <Image src={MarketPlaceLogo} />
      <Link
        ml="auto !important"
        href={shipmentCsv}
        download="shipment_search.csv"
      >
        <Button
          size="sm"
          layerStyle="black"
          my="1vh !important"
          w="12rem"
          leftIcon={<DownloadIcon />}
        >
          Download CSV
        </Button>
      </Link>
    </VStack>
  );
};

export default SpotShipmentTopBar;
