import { Fragment } from "react";
import { useQuickPayContext } from "./QuickPayContext";
import SharedButton from "../../components/SharedButton";
import { QuickPayRequestStatus } from "../../types/QuickPayRequest";
import { DownloadIcon } from "@chakra-ui/icons";
import { getRequests } from "./reuse";

interface DownloadOptionProps {
  downloadTable: () => void;
}

export const DownloadOption = ({
  downloadTable,
}: DownloadOptionProps): JSX.Element => {
  const context = useQuickPayContext();
  const { quickPays, currentStatus } = context!;

  const requestedData = getRequests(
    quickPays ?? [],
    QuickPayRequestStatus.REQUESTED,
  );

  const approvedData = getRequests(
    quickPays ?? [],
    QuickPayRequestStatus.APPROVED,
  );

  const rejectedData = getRequests(
    quickPays ?? [],
    QuickPayRequestStatus.REJECTED,
  );

  return (
    <Fragment>
      {(currentStatus.includes(QuickPayRequestStatus.REQUESTED) &&
        requestedData.length !== 0) ||
      (currentStatus.includes(QuickPayRequestStatus.APPROVED) &&
        approvedData.length !== 0) ||
      (currentStatus.includes(QuickPayRequestStatus.REJECTED) &&
        rejectedData.length !== 0) ? (
        <SharedButton
          title="Download"
          leftIcon={<DownloadIcon />}
          layerStyle="yellow"
          onClick={downloadTable}
        />
      ) : undefined}
    </Fragment>
  );
};
