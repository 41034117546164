export interface Option {
  label: string;
  value: string;
}

export const toMultiselectOptions = (options: string[]): Option[] => {
  return options.map((option) => ({
    label: option,
    value: option,
  }));
};

export const parseValues = (values: Option[]): string[] => {
  return values.map((option) => option.value);
};
