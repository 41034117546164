import { HStack, VStack, Text } from "@chakra-ui/react";
import Background from "../../resources/svg/login_chevron.svg";

interface ContainerProps {
  text?: string;
}

const NoDataContainer: React.FC<ContainerProps> = ({
  text,
}: ContainerProps) => (
  <HStack
    display="flex"
    h="100%"
    w="100%"
    bgColor="grey"
    bgImage={Background}
    bgPos="right"
    bgRepeat="no-repeat"
    alignItems="center"
    overflowY="auto"
    backgroundSize="contain"
  >
    <VStack alignItems="start" w="100%" ml="5%" spacing="3%">
      {text && (
        <HStack>
          <Text textStyle="subTitle" textTransform="uppercase">
            {text}
          </Text>
        </HStack>
      )}
    </VStack>
  </HStack>
);

export default NoDataContainer;
