import { HStack, Skeleton } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Shipment } from "../../types/Shipment";
import { TrackingContext } from "../Tracking/TrackingContext";
import { useGetPastShipmentsQuery } from "../../api/shipment";
import TrackingShipmentContent from "../../components/TrackingShipmentContent/TrackingShipmentContent";
import { MapLocations } from "../SourcingSearch/SourcingSearchContext";
import { MarkerInfo } from "../../components/SharedMap/SharedMap";
import TrackingSidebar from "../../components/TrackingSidebar/TrackingSidebar";
import {
  setLocationsFromTracking,
  setMarkerFromTracking,
} from "../../reuse/Maps";

export const PastShipment = (): JSX.Element => {
  const [currentTrackingShipment, setCurrentTrackingShipment] = useState<
    Shipment | undefined
  >();

  const { data: pastShipments, isFetching } = useGetPastShipmentsQuery();

  const [locations, setLocations] = useState<MapLocations>();
  const [markerInfo, setMarkerInfo] = useState<MarkerInfo>();

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    if (pastShipments && pastShipments.length > 0) {
      setCurrentTrackingShipment(pastShipments?.[0]);
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const updateMapInfo = async (): Promise<void> => {
      await setLocationsFromTracking(currentTrackingShipment!, setLocations);
      await setMarkerFromTracking(currentTrackingShipment!, setMarkerInfo);
    };

    void updateMapInfo();
  }, [currentTrackingShipment]);

  return (
    <TrackingContext.Provider
      value={{
        currentTrackingShipment,
        setCurrentTrackingShipment,
        locations,
        setLocations,
        markerInfo,
        setMarkerInfo,
        setLocationsFromTracking,
        setMarkerFromTracking,
      }}
    >
      <Skeleton h="100%" isLoaded={!isFetching}>
        <HStack h="100%">
          <TrackingSidebar trackingShipments={pastShipments} />
          <TrackingShipmentContent title="PAST" />
        </HStack>
      </Skeleton>
    </TrackingContext.Provider>
  );
};

export default PastShipment;
