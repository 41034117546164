import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  Divider,
  ModalBody,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import {
  useUploadDriverDocumentMutation,
  useUploadS3DriverFileMutation,
} from "../../api/documents";
import { useUpdateShipmentStatusMutation } from "../../api/shipment";
import { useTrackingContext } from "../../pages/Tracking/TrackingContext";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { OneMegaByte } from "../../reuse/Files";
import { DefaultModalProps } from "../../types/DefaultModal";
import { ShipmentDocumentType } from "../../types/Files";
import { ShipmentStatus } from "../../types/Shipment";
import MarkDeliveredDocument from "../MarkDeliveredDocument";
import MarkDeliveredOtherDocuments from "../MarkDeliveredOtherDocuments";
import useToastHook from "../useToastHook";
import { initialFileData } from "./reuse";

interface MarkDeliveredModalProps extends DefaultModalProps {
  title: string;
  shipmentId: string | undefined;
}

export const MarkDeliveredModal = ({
  isOpen,
  onClose,
  title,
  shipmentId,
}: MarkDeliveredModalProps): JSX.Element => {
  const trackingContext = useTrackingContext();
  const { setLocations } = trackingContext!;

  const toast = useToastHook();
  const [uploadDoc, { isLoading: isUploading }] =
    useUploadDriverDocumentMutation();
  const [uploadS3Doc, { isLoading: isUploadingS3 }] =
    useUploadS3DriverFileMutation();

  const [updateShipmentStatus, { isLoading }] =
    useUpdateShipmentStatusMutation();

  const fileBillOfLadingInputRef = useRef<HTMLInputElement | null>(null);

  const [fileData, setFileData] = useState(initialFileData);
  const [handlingType, setHandlingTpye] = useState<string>("");

  const determinateDocumentType = (fileType: string): ShipmentDocumentType => {
    return fileType === "billOfLading"
      ? ShipmentDocumentType.BillOfLading
      : ShipmentDocumentType.OtherDoc;
  };

  const removeDocument = (): void =>
    setFileData({ ...fileData, billOfLading: "" });

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event?.target?.files![0];
    const { name, type, size } = file;
    if (size >= 100 * OneMegaByte) {
      alert("File size is too large.");
      return;
    }
    setFileData({ ...fileData, [event.target.name]: name });
    const fileType = event.target.name;
    const docType = determinateDocumentType(fileType) as ShipmentDocumentType;
    setHandlingTpye(event.target.name);

    const documentUpload = await uploadDoc({
      id: shipmentId!,
      fileName: name,
      contentType: type,
      documentType: docType,
    });
    if ("data" in documentUpload) {
      await uploadS3Doc({ url: documentUpload.data, file });
      toast.success({
        description: "Document uploaded successfully !",
      });
    } else {
      toast.error({
        description: parseErrorResponse(documentUpload.error),
      });
    }
  };

  const onClickMarkDelivered = async (): Promise<void> => {
    if (shipmentId) {
      const response = await updateShipmentStatus({
        id: shipmentId!,
        status: ShipmentStatus.DeliveredPending,
      });
      if ("data" in response) {
        setLocations(undefined!);
        toast.success({ description: "Shipment marked as pending delivered." });
      } else {
        toast.error({ description: `${parseErrorResponse(response.error)}` });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="uppercase">{title}</ModalHeader>
        <Divider orientation="horizontal" />
        <ModalBody>
          <MarkDeliveredDocument
            title="UPLOAD BILL OF LADING"
            name="billOfLading"
            reference={fileBillOfLadingInputRef}
            valueDocument={fileData.billOfLading}
            handleFileInput={handleFileInput}
            proccesingDocument={
              (isUploading || isUploadingS3) && handlingType === "billOfLading"
            }
            removeDocument={removeDocument}
          />
          <MarkDeliveredOtherDocuments shipmentId={shipmentId} />
        </ModalBody>
        <Divider orientation="horizontal" />
        <ModalFooter justifyContent="space-between" px="1.5rem">
          <Button size="sm" color="mvmntRed" variant="link" onClick={onClose}>
            Cancel
          </Button>
          <Button
            layerStyle="red"
            w="200px"
            onClick={onClickMarkDelivered}
            isDisabled={isLoading}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MarkDeliveredModal;
