import { HStack, Text, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { getDateDiffInMinutes } from "../../../reuse/Dates";
import { parseValueToMoney } from "../../../reuse/Money";
import { Bid } from "../../../types/Bid";
import MvmntLogo from "../../../resources/svg/mvmnt_logo.svg";

interface MainSectionProps {
  bid: Bid;
  marketBids?: Bid[];
}

// Note: change the bid.shipmentId by bid ---> carrier ---> logo
const MainSection = ({ bid, marketBids }: MainSectionProps): JSX.Element => {
  return (
    <HStack w="100%" alignItems="start" justifyContent="space-between" mt={4}>
      <VStack marginLeft={5} alignSelf="center">
        {marketBids ? (
          <Image width={200} height={20} src={MvmntLogo} />
        ) : (
          <Text textStyle="title" textTransform="uppercase">
            {bid.carrier.companyName}
          </Text>
        )}
      </VStack>
      <VStack
        marginRight="15px !important"
        h="100%"
        justifyContent="center"
        alignItems="end"
        spacing={0}
      >
        {!marketBids ? (
          <Text textColor="#707070" fontSize="xl" marginBottom={4}>
            {getDateDiffInMinutes(bid.createDate || new Date(), false)}
          </Text>
        ) : null}
        <Text fontSize="4xl">{parseValueToMoney(bid.totalCost ?? 0)}</Text>
        <Text textColor="#707070" fontSize="xl">
          {`$${Number(bid.ratePerMile).toFixed(2)}/mile`}
        </Text>
      </VStack>
    </HStack>
  );
};

export default MainSection;
