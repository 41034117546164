import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  VStack,
  ModalBody,
  Button,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { Fragment } from "react";
import useToastHook from "../useToastHook";
import { DefaultModalProps } from "../../types/DefaultModal";
import { Shipment, ShipmentStatus } from "../../types/Shipment";
import DeliveredDocument from "../DeliveredDocument";
import { useUpdateShipmentStatusMutation } from "../../api/shipment";
import { parseErrorResponse } from "../../reuse/ErrorHandler";

interface ConfirmationDeliveredModalProps extends DefaultModalProps {
  title: string;
  shipment: Shipment;
}

export const ConfirmationDeliveredModal = ({
  isOpen,
  onClose,
  title,
  shipment,
}: ConfirmationDeliveredModalProps): JSX.Element => {
  const toast = useToastHook();
  const [updateShipmentStatus, { isLoading }] =
    useUpdateShipmentStatusMutation();

  const onConfirmDeliveredShipment = async (): Promise<void> => {
    const response = await updateShipmentStatus({
      id: shipment.id!,
      status: ShipmentStatus.DeliveredShipment,
    });
    if ("data" in response) {
      toast.success({ description: "Shipment marked as delivered." });
    } else {
      toast.error({ description: `${parseErrorResponse(response.error)}` });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="uppercase">{title}</ModalHeader>
        <ModalBody>
          <VStack maxHeight="300px" overflow="scroll">
            {shipment.shipmentDocuments?.map((document) => (
              <DeliveredDocument key={document.id} document={document} />
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="space-around">
          {isLoading ? (
            <Flex w="100%" alignItems="center" justifyContent="center">
              <Spinner color="mvmntRed" size="lg" />
            </Flex>
          ) : (
            <Fragment>
              <Button layerStyle="red" onClick={onConfirmDeliveredShipment}>
                Confirm
              </Button>
              <Button
                size="sm"
                color="mvmntRed"
                variant="link"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Fragment>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationDeliveredModal;
