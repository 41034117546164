import { Button, FormControl, Input, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useChangePasswordMutation, UserChangePassword } from "../../api/auth";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { noContent, validatePassword } from "../../reuse/UserData";
import { RegexPoliceProp } from "../../types/Regex";
import ErrorMessage from "../ErrorMessage";
import useToastHook from "../../components/useToastHook";
import RegexPolicesList from "../RegexPolicesList";

export const ChangePassword = (): JSX.Element => {
  const {
    register,
    watch,
    handleSubmit,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<UserChangePassword>();
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const toast = useToastHook();
  const [regexList, setRegexList] = useState<[] | RegexPoliceProp[]>([]);

  const newPassword = getValues().newPassword;
  const passwordNoContent = noContent(newPassword);

  const onSubmit = handleSubmit(
    async ({ password, newPassword }: UserChangePassword) => {
      const response = await changePassword({
        password,
        newPassword,
      });
      if ("data" in response) {
        toast.success({ description: `${response.data}` });
      } else {
        toast.error({ description: `${parseErrorResponse(response.error)}` });
      }
    },
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "newPassword") {
        const regexList = validatePassword(value.newPassword!);
        setRegexList(regexList);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form onSubmit={onSubmit}>
      <VStack>
        <FormControl isDisabled={isLoading}>
          <Input
            type="password"
            placeholder="Previous Password"
            {...register("password", {
              required: true,
            })}
          />
        </FormControl>
        <FormControl isDisabled={isLoading}>
          <Input
            type="password"
            placeholder="New Password"
            {...register("newPassword", {
              required: true,
            })}
          />
        </FormControl>

        <FormControl isDisabled={isLoading}>
          <Input
            type="password"
            placeholder="Confirm Password"
            {...register("confirmPassword", {
              required: {
                value: true,
                message: "You must confirm your password",
              },
              validate: (confirm) =>
                confirm === getValues().newPassword
                  ? true
                  : "Passwords do not match",
            })}
          />
        </FormControl>

        {!passwordNoContent && (
          <RegexPolicesList regexList={regexList} columns={"1fr 1fr"} />
        )}

        <Button
          layerStyle="red"
          w="20%"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Update
        </Button>

        <ErrorMessage errors={errors} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default ChangePassword;
