import { VStack, Input, FormLabel, Divider } from "@chakra-ui/react";
import { formatPhoneNumber } from "react-phone-number-input";
import Header from "../../../components/Header";
import { ShipperUser } from "../../../types/Shipper";
import "./AdminShipperAccountForm.scss";

interface AdminShipperAccountFormProps {
  shipper: ShipperUser;
}

export const AdminShipperAccountForm = ({
  shipper,
}: AdminShipperAccountFormProps): JSX.Element => {
  const renderAdministratorInfo = (): JSX.Element => {
    return (
      <VStack alignItems="baseline">
        <Header headingText="Account Administrator" headingLevel="h4" />
        <FormLabel>First Name</FormLabel>
        <Input
          readOnly
          placeholder="First Name"
          defaultValue={shipper.contactInformation.firstName}
        />

        <FormLabel>Last Name</FormLabel>
        <Input
          readOnly
          placeholder="Last Name"
          defaultValue={shipper.contactInformation.lastName}
        />

        <FormLabel>Email</FormLabel>
        <Input
          readOnly
          placeholder="Email"
          defaultValue={shipper.contactInformation.email}
        />
      </VStack>
    );
  };
  const renderCompanyInfo = (): JSX.Element => {
    return (
      <VStack alignItems="baseline">
        <Header headingText="Company Information" headingLevel="h4" />
        <FormLabel>Company Name</FormLabel>
        <Input
          readOnly
          placeholder="Company Name"
          defaultValue={shipper.contactInformation.companyName}
        />

        <FormLabel>Company website</FormLabel>
        <Input
          readOnly
          placeholder="Company website"
          defaultValue={shipper.contactInformation.website}
        />

        <FormLabel>Company Email</FormLabel>
        <Input
          readOnly
          placeholder="Company Email"
          defaultValue={shipper.contactInformation.companyEmail}
        />

        <FormLabel>Phone Number</FormLabel>
        <Input
          name="companyPhoneNumber"
          readOnly
          placeholder="Phone Number"
          defaultValue={formatPhoneNumber(
            shipper.contactInformation.companyPhoneNumber,
          )}
        />

        <FormLabel>DUNS code</FormLabel>
        <Input
          readOnly
          placeholder="DUNS code"
          defaultValue={shipper.contactInformation.dunsCode}
        />

        <FormLabel>NAICS code</FormLabel>
        <Input
          readOnly
          placeholder="NAICS code"
          defaultValue={shipper.contactInformation.naicsCode}
        />
      </VStack>
    );
  };
  const renderInsuranceInfo = (): JSX.Element => {
    return (
      <VStack alignItems="baseline">
        <Header headingText="Insurance Information" headingLevel="h4" />
        <FormLabel>Auto liability</FormLabel>
        <Input
          readOnly
          defaultValue={shipper.insuranceRequirements.autoLiability}
          placeholder="Auto liability"
        />

        <FormLabel>General Liability Name</FormLabel>
        <Input
          readOnly
          defaultValue={shipper.insuranceRequirements.generalLiability}
          placeholder="General Liability Name"
        />

        <FormLabel>Cargo Insurance</FormLabel>
        <Input
          readOnly
          defaultValue={shipper.insuranceRequirements.cargoInsurance}
          placeholder="Cargo Insurance"
        />
      </VStack>
    );
  };
  const renderAccountsReceivable = (): JSX.Element => {
    return (
      <VStack alignItems="baseline">
        <Header headingText="Accounts Receivable" headingLevel="h4" />
        <FormLabel>Company Name</FormLabel>
        <Input
          readOnly
          placeholder="Company Name"
          defaultValue={shipper.accountsReceivable.companyName}
        />

        <FormLabel>Contact Name</FormLabel>
        <Input
          readOnly
          placeholder="Contact Name"
          defaultValue={shipper.accountsReceivable.companyName}
        />

        <FormLabel>Email</FormLabel>
        <Input
          readOnly
          placeholder="Email"
          defaultValue={shipper.accountsReceivable.companyName}
        />

        <FormLabel>Phone Number</FormLabel>
        <Input
          name="accountsReceivablePhoneNumber"
          readOnly
          placeholder="Phone Number"
          defaultValue={formatPhoneNumber(
            shipper.accountsReceivable.phoneNumber,
          )}
        />

        <FormLabel>Address 1</FormLabel>
        <Input
          readOnly
          placeholder="Address 1"
          defaultValue={shipper.accountsReceivable.address1}
        />

        <FormLabel>Address 2</FormLabel>
        <Input
          readOnly
          placeholder="Address 2"
          defaultValue={shipper.accountsReceivable.address2}
        />

        <FormLabel>City</FormLabel>
        <Input
          readOnly
          placeholder="City"
          defaultValue={shipper.accountsReceivable.city}
        />

        <FormLabel>State</FormLabel>
        <Input
          readOnly
          placeholder="State"
          defaultValue={shipper.accountsReceivable.state}
        />

        <FormLabel>Postal Code</FormLabel>
        <Input
          readOnly
          placeholder="Postal Code"
          defaultValue={shipper.accountsReceivable.postalCode}
        />

        <FormLabel>Country</FormLabel>
        <Input
          readOnly
          placeholder="Country"
          defaultValue={shipper.accountsReceivable.country}
        />
      </VStack>
    );
  };
  return (
    <form className="admin-shipper-account-summary-form">
      {renderAdministratorInfo()}
      <Divider />

      {renderCompanyInfo()}

      <Divider />

      {renderInsuranceInfo()}

      <Divider />

      {renderAccountsReceivable()}
    </form>
  );
};

export default AdminShipperAccountForm;
