export interface HeaderProps {
  id: number;
  header: string;
  field?: string;
  color?: string;
}

export const activeRfpShipperHeaders: HeaderProps[] = [
  { id: 0, header: "Lane ID", color: "mvmntRed" },
  { id: 1, header: "Origin" },
  { id: 2, header: "Pickup" },
  { id: 3, header: "Destination" },
  { id: 4, header: "Pickup" },
  { id: 5, header: "Mileage" },
  { id: 6, header: "Volume" },
  { id: 7, header: "Frequency" },
  { id: 8, header: "Mode" },
  { id: 10, header: "Equip Type" },
  { id: 11, header: "Equip Size" },
  { id: 12, header: "# of Bids", color: "mvmntRed" },
  { id: 13, header: "Lowest Effective Bid", color: "mvmntRed" },
];

export const activeRfpCarrierHeaders: HeaderProps[] = [
  { id: 0, header: "Lane ID", color: "mvmntRed" },
  { id: 1, header: "Origin" },
  { id: 2, header: "Pickup" },
  { id: 3, header: "Destination" },
  { id: 4, header: "Pickup" },
  { id: 5, header: "Mileage" },
  { id: 6, header: "Volume" },
  { id: 7, header: "Frequency" },
  { id: 8, header: "Mode" },
  { id: 9, header: "Equip Size" },
  { id: 10, header: "Equip Type" },
  { id: 11, header: "RPM", color: "mvmntRed" },
  { id: 12, header: "Minimum", color: "mvmntRed" },
  { id: 13, header: "Commitment", color: "mvmntRed" },
];
