import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalBody,
} from "@chakra-ui/react";
import { CarrierUser } from "../../types/Carrier";
import { DefaultModalProps } from "../../types/DefaultModal";
import { ShipmentCreate } from "../../types/Shipment";
import TenderContent from "./TenderContent";

interface TenderModalProps extends DefaultModalProps {
  onConfirm: () => void;
  carrier: CarrierUser;
  shipment: ShipmentCreate;
}

export const TenderModal = ({
  isOpen,
  onClose,
  onConfirm,
  carrier,
  shipment,
}: TenderModalProps): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="uppercase">Confirm Tender Details</ModalHeader>
        <ModalBody>
          <TenderContent carrier={carrier} shipment={shipment} />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button layerStyle="black" textColor="white" onClick={onClose}>
            Go Back
          </Button>
          <Button layerStyle="red" onClick={onConfirm}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TenderModal;
