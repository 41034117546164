import { VStack, Text } from "@chakra-ui/react";

interface NoDataDisplayedProps {
  searching: boolean;
}

export const NoDataDisplayed = ({
  searching,
}: NoDataDisplayedProps): JSX.Element => {
  return (
    <>
      <VStack
        w="100%"
        h="40vh"
        backgroundColor="rgba(255,255,255,0.1)"
        paddingTop="4rem"
        justify="flex-strt"
        align="center"
        minW="740px"
      >
        <Text variant="title">No Data to Display</Text>
        <Text variant="subTitle">
          Please try again with different{" "}
          {searching ? "search terms." : "filters."}{" "}
        </Text>
      </VStack>
    </>
  );
};

export default NoDataDisplayed;
