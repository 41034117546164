import { VStack, HStack, Text } from "@chakra-ui/layout";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/tabs";
import BidsTabContent from "../../pages/ShipperActiveQuoteRequests/BidsTabContent";
import { useQuoteRequestsContext } from "../../pages/ShipperActiveQuoteRequests/QuoteRequestsContext";
import Background from "../../resources/svg/login_chevron.svg";
import ShipmentForm from "../ShipmentForm";

const ShipmentTabs = (): JSX.Element => {
  const context = useQuoteRequestsContext();
  const { selectedShipment, setSelectedShipment } = context!;

  const shipmentHasBids = selectedShipment!.bids;

  return (
    <VStack alignItems="center" w="100%" h="100%" spacing="3%">
      <Tabs isFitted variant="enclosed" h="100%" w="100%">
        <TabList bgColor="darkgray" textColor="white">
          <Tab _selected={{ color: "yellow", bg: "black" }}>BIDS</Tab>
          <Tab _selected={{ color: "yellow", bg: "black" }}>
            SHIPMENT DETAILS
          </Tab>
        </TabList>
        <TabPanels h="calc(100% - 50px)">
          <TabPanel
            display="flex"
            h="100%"
            bgColor="grey"
            bgImage={shipmentHasBids ? undefined : Background}
            bgPos="right"
            bgRepeat="no-repeat"
            alignItems={shipmentHasBids ? "start" : "center"}
            overflowY="auto"
            padding="0"
          >
            <VStack alignItems="start" w="100%" h="100%" spacing="3%">
              {shipmentHasBids ? (
                <BidsTabContent />
              ) : (
                <HStack>
                  <Text textStyle="subTitle">
                    NO BIDS RECEIVED FOR THIS SHIPMENT
                  </Text>
                </HStack>
              )}
            </VStack>
          </TabPanel>
          <TabPanel h="100%" w="100%" overflowY="auto">
            <VStack w="100%">
              <ShipmentForm
                initialValues={selectedShipment}
                setSelectedShipment={setSelectedShipment}
              />
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default ShipmentTabs;
