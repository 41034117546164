import { Text } from "@chakra-ui/react";
import ReusableUploadSumary from "../../components/ReusableUploadSumary";
import { SummaryCmp } from "../../components/ReusableUploadSumary/ReusableUploadSumary";
import { parseDateToShortDate } from "../../reuse/Dates";
import { ValidationResults } from "../../types/BulkUpload";
import { ShipmentRowToAdd } from "../../types/ShipmentUpload";

interface UploadLanesSummaryProps {
  validationResult: ValidationResults<ShipmentRowToAdd>;
}

const ReadyToAddSummary = (
  validationResult: ValidationResults<ShipmentRowToAdd>,
): JSX.Element => (
  <>
    {validationResult.readyToAdd?.slice(0, 10).map((shipment, index) => (
      <Text key={index}>
        Shipment Id: {shipment.externalShipmentId ?? "---"} Origin:{" "}
        {shipment.shipmentStops[0].stopCity} Pickup Date:{" "}
        {parseDateToShortDate(shipment.shipmentStops[0].stopDate)}
        Destination:{" "}
        {shipment.shipmentStops[shipment.shipmentStops.length - 1].stopCity}
        Delivery Date:{" "}
        {parseDateToShortDate(
          shipment.shipmentStops[shipment.shipmentStops.length - 1].stopDate,
        )}
      </Text>
    ))}
  </>
);

export const UploadLanesSummary = ({
  validationResult,
}: UploadLanesSummaryProps): JSX.Element => {
  const { misFormattedRows, readyToAdd } = validationResult;
  const hiddenRowsAmount = readyToAdd!.length - 10;

  return (
    <>
      <ReusableUploadSumary
        MisFormattedRowsCmp={() =>
          misFormattedRows!.length > 0 ? (
            <SummaryCmp
              text="The following rows are not correctly formatted and will be ignored:"
              array={misFormattedRows}
            />
          ) : (
            <></>
          )
        }
        ReadyToAddCmp={() =>
          readyToAdd!.length > 0 ? (
            <SummaryCmp text="The following lanes will be added:">
              {() => ReadyToAddSummary(validationResult)}
            </SummaryCmp>
          ) : (
            <></>
          )
        }
      />
      {hiddenRowsAmount > 0 && <Text>{hiddenRowsAmount} more rows</Text>}
    </>
  );
};

export default UploadLanesSummary;
