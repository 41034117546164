import { useState, useEffect } from "react";
import { HStack, Skeleton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Shipment } from "../../types/Shipment";
import { useGetShipmentsByShipperIdQuery } from "../../api/shipment";
import ShipperShipmentSidebar from "../../components/ShipperShipmentSidebar";
import CreateShipmentModal from "../../components/CreateShipmentModal";
import CarrierInfoModal from "../../components/CarrierInfoModal";
import AppRoutes from "../../routes";
import ShipmentTabs from "../../components/ShipmentTabs";
import { BidCarrierInfo } from "../../api/carrier";
import { QuoteRequestsContext } from "./QuoteRequestsContext";

const ShipperActiveQuoteRequests = (): JSX.Element => {
  const navigate = useNavigate();
  const gotToCreateShipment = (): void => {
    navigate(AppRoutes.LOAD_BUILD);
  };

  const { data: shipments, isFetching } = useGetShipmentsByShipperIdQuery();
  const shipperShipments = shipments ? [...shipments] : [];

  const [selectedShipment, setSelectedShipment] = useState<Shipment>();
  const [isCreateShipmentModalOpen, toggleCreateShipmentModal] =
    useState(false);

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [bidCarrierInfo, setBidCarrierInfo] = useState<BidCarrierInfo>();

  const buttons = [
    {
      color: "black",
      text: "Close",
      minW: "120px",
      h: "30px",
      fontSize: 12,
      textColor: "white",
      onClick: () => setIsInfoModalOpen(false),
    },
  ];

  const renderInfoModal = (): JSX.Element => {
    return bidCarrierInfo && isInfoModalOpen ? (
      <CarrierInfoModal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        buttons={buttons}
        carrier={bidCarrierInfo}
        columns={1}
      />
    ) : (
      <></>
    );
  };

  useEffect(() => {
    if (shipperShipments.length === 0 && !isFetching) {
      toggleCreateShipmentModal(true);
    }
  }, [shipperShipments]);

  return (
    <QuoteRequestsContext.Provider
      value={{
        isCreateShipmentModalOpen,
        toggleCreateShipmentModal,
        selectedShipment,
        setSelectedShipment,
        bidCarrierInfo,
        isInfoModalOpen,
        setBidCarrierInfo,
        setIsInfoModalOpen,
      }}
    >
      <Skeleton isLoaded={!isFetching} w="100%" h="100%">
        <HStack h="100%" alignItems="start" spacing={0}>
          <CreateShipmentModal
            isOpen={isCreateShipmentModalOpen}
            onClose={gotToCreateShipment}
            onClickCTA={gotToCreateShipment}
            title="No Shipments"
            content="There are no shipments yet. Please create one!"
          />
          {renderInfoModal()}
          {shipperShipments.length > 0 ? (
            <ShipperShipmentSidebar shipments={shipperShipments} />
          ) : null}
          {selectedShipment ? <ShipmentTabs /> : null}
        </HStack>
      </Skeleton>
    </QuoteRequestsContext.Provider>
  );
};

export default ShipperActiveQuoteRequests;
