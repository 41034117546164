import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import { HStack } from "@chakra-ui/layout";
import SharedButton from "../../components/SharedButton";

interface AdditionalContentProps {
  term: string;
  setTerm: (t: string) => void;
}

const TabAdditionalContent = ({
  term,
  setTerm,
}: AdditionalContentProps): JSX.Element => (
  <HStack justifyContent="space-between" m="1rem">
    <HStack w="50%">
      <Input
        w="80%"
        mr="1rem"
        placeholder="Search by name"
        value={term}
        onChange={(e) => setTerm(e.target.value)}
      />
      <CloseIcon
        mx="5rem"
        cursor="pointer"
        // hidden={term === ""}
        onClick={() => setTerm("")}
      />
    </HStack>
    <SharedButton title="Add User" leftIcon={<AddIcon />} layerStyle="yellow" />
  </HStack>
);

export default TabAdditionalContent;
