import { Image } from "@chakra-ui/react";
import ChevronPin from "../../resources/png/ChevronPin.png";

export const MarkerPin = (): JSX.Element => {
  return (
    <Image
      src={ChevronPin}
      w="30px"
      h="30px"
      position="absolute"
      transform="translate(-50%, -100%)"
    />
  );
};
