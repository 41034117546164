import { VStack } from "@chakra-ui/react";
import TabsContainer from "../../components/TabsContainer";
import { ShipperStatus } from "../../types/Shipper";
import { useManageUserContext } from "./ManageUserContext";
import AdminShipperAccountComments from "./ShipperAccountComments/AdminShipperAccountComments";
import AdminShipperAccountForm from "./ShipperAccountSummary/AdminShipperAccountForm";

export const AdminShipperAccountTabContainter = (): JSX.Element => {
  const context = useManageUserContext();
  const { selectedShipper, setCurrentStatus } = context!;
  const handleClickTab = (title: string): void =>
    setCurrentStatus(title as ShipperStatus);

  return (
    <VStack mt={8}>
      {selectedShipper && (
        <TabsContainer
          containerBg="white"
          tabList={["Summary", "Comments"]}
          tabPanels={[
            <AdminShipperAccountForm
              key="Summary Tab"
              shipper={selectedShipper}
            />,
            <AdminShipperAccountComments
              key="Comments tab"
              shipperId={selectedShipper.id}
            />,
          ]}
          onClickTab={handleClickTab}
        />
      )}
    </VStack>
  );
};

export default AdminShipperAccountTabContainter;
